import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { storeTitle } from "../../../../store/actions/headerTitleSlice";
import { mdiFile } from "@mdi/js";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CardInfo from "../../../../components/Card/CardInfo";
import CustomTable from "../../../../components/Table/Table";
import "./pagemanagement.css";

export const PageLists = [
  {
    sno: 1,
    name: "Term Privacy",
    created_date: "10 November 2022",
    status: "Active",
  },
];

function PageManagement() {
  const dispatch = useDispatch();

  const headers = [
    {
      Header: "Page Name",
      maxWidth: 800,
      minWidth: 200,
      width: 800,
      Cell: ({ row }) => {
        const data = row.original;

        const navigate = useNavigate();

        function handleClick() {
          navigate("/pages/" + data.sno);
        }

        return (
          <div className="form-container-default">
            <Button
              variant="link"
              className="no-underline"
              onClick={handleClick}
            >
              {data.name}
            </Button>
          </div>
        );
      },
    },
    {
      Header: "Created Date",
      maxWidth: 800,
      minWidth: 200,
      width: 800,
      Cell: ({ row }) => {
        const data = row.original;

        const date = new Date(data.created_date);
        const longMonth = date.toLocaleString("en-us", { month: "long" });

        return (
          <div>
            {date.getDate()} {longMonth} {date.getFullYear()}
          </div>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      maxWidth: 150,
      minWidth: 150,
      width: 150,
      Cell: ({ row }) => {
        const data = row.original;

        return data.status === "Not Active" ? (
          <CardInfo
            cardTextSize="card_text--small"
            cardTextStyle="card_text--danger--solid"
          >
            {data.status}
          </CardInfo>
        ) : (
          <CardInfo
            cardTextSize="card_text--small"
            cardTextStyle="card_text--primary--solid"
          >
            {data.status}
          </CardInfo>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(storeTitle({ title: "Page Management", icon: mdiFile }));
  }, [dispatch]);

  return (
    <div className="main-div page-mgt">
      <div className="container-div">
        <CustomTable
          headers={headers}
          items={PageLists}
          paginate={PageLists.length}
          maxRows={15}
        ></CustomTable>
      </div>
    </div>
  );
}

export default PageManagement;
