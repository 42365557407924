import {createSlice} from '@reduxjs/toolkit';


const initialState = {
    indexTab:null,
    cliActiveTab: null,
    cliPotentialTab: null,
    candTab: null,
}

const tabActiveSlice = createSlice({
    name: 'tabSlice',
    initialState,
    reducers:{
        storeIndexTab: (state, action) => {
            state.indexTab = action.payload;
        },
        indCliActTab: (state, action) => {
            state.cliActiveTab = action.payload;
        },
        indCliPotTab: (state, action) => {
            state.cliPotentialTab = action.payload;
        },
        indCandTab: (state, action) => {
            state.candTab = action.payload;
        },
    },
})

const {reducer, actions} = tabActiveSlice;

export const { storeIndexTab, indCliActTab, indCliPotTab, indCandTab } = actions;

export default reducer;