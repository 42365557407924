
export const rptJobDescription = (props) => {

    // console.log("props", props.requirement)
    // const proposalBody = props.requirement;

    const jdBody = `
    <html>
        <head></head>
        <body>
            <div>  
                <p style="text-align:center; font-size:14.0pt"><b>`+ props.job_title +`</b></p>
                <br>
                <p class=MsoNormal style='margin-bottom:0cm;line-height:normal;text-autospace:none; font-size:10.0pt; font-weight:bold;'><u>COMPANY BACKGROUND</u></p>
                <br>
                <p style='text-align:justify;text-justify:inter-ideograph;line-height:1.0;margin-top:-5px;'>` + props.company_background + `</p>
                <br>
                <p class=MsoNormal style='margin-bottom:0cm;line-height:normal;text-autospace:none; font-size:10.0pt; font-weight:bold;'><u>DESCRIPTION OF ROLE</u></p>
                <br>
                <p style='text-align:justify;text-justify:inter-ideograph;line-height:1.0;margin-top:-5px;'>` + props.purpose_job + `</p>
                <br>
                <p class=MsoNormal style='margin-bottom:0cm;line-height:normal;text-autospace:none; font-size:10.0pt; font-weight:bold;'><u>KEY ACCOUNTABILITIES</u></p>
                <br>
                ` + props.key_accountibility +`
                <br>
                <p class=MsoNormal style='margin-bottom:0cm;line-height:normal;text-autospace:none; font-size:10.0pt; font-weight:bold;'><u>DESIRED KNOWLEDGE, SKILLS and EXPERIENCE</u></p>
                <br>
                ` + props.skill_experience +`
                <br>
                <p class=MsoNormal style='margin-bottom:0cm;line-height:normal;text-autospace:none; font-size:10.0pt; font-weight:bold;'><u>COMPENSATION</u></p>
                <br>
                <p style='text-align:justify;text-justify:inter-ideograph;line-height:1.0;margin-top:-5px;'>` + props.compensation + `</p>
                <br>
                <p style='text-align:justify;text-justify:inter-ideograph;line-height:1.0;margin-top:-5px; font-size:10.0pt'><b>For consideration as a candidate or to suggest a prospective candidate, please contact in confidence:</b></p>
                <br>
                ` + props.contact_person + `


            </div>
        </body>
    
    </html>
    `
    
    return jdBody
}

export default rptJobDescription;