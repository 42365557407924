import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Form, Col, Row, Button, Modal } from "react-bootstrap";
import Api from "../../../../core/services/api.service";
import Apiform from "../../../../core/services/apiform.service";

import SwalAlert from "../../../../components/Swal/SwalAlert";
import FileSaver from "file-saver";

// import { Prompt } from "react-router-dom";
import {
  mdiFile,
  mdiPlusCircle,
  mdiPencil,
  mdiDelete,
  mdiCheckCircle,
  mdiArrowLeft,
  mdiAccountSearch,
} from "@mdi/js";
import Icon from "@mdi/react";

import BreadCrumb from "../../../../components/Breadcrumb/BreadCrumb";
import CustomTable from "../../../../components/Table/TableFunction";
import CardCandidate from "../../../../components/Card/CardCandidate";
import RichTextEditor from "../../../../components/RichTextArea/RichTextEditor";
import Searchable from "../../../../components/DropDown/SearchableSelect";
import TextFieldFormat from "../../../../components/TextField/TextFieldFormat";
import Creatable from "../../../../components/DropDown/CreateableSelect";
import Popup from "../../../../components/Popup/ModalPopup";
// import Btn from '../../../components/Button/Button';

import { storeTitle } from "../../../../store/actions/headerTitleSlice";
import { getListOptions } from "../../../../store/actions/optionSlice";

import "../../../../components/Tab/Tab.css";
import { monthList, noticeList } from "../../dataList";

function AddCandidate() {
  // const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    dataYear,
    dataSalute,
    dataGender,
    dataLanguages,
    // dataPhoneCode,
    dataCountry,
    dataCitizenship,
    dataMarital,
    dataCurrency,
    dataJobFunction,
    dataIndustry,
    dataAllowance,
  } = useSelector((state) => state.option);

  // const [crumbs] = useState(["Add New Candidate"]);
  const [clickedEdu, setClickedEdu] = useState(false);
  const [clickedExp, setClickedExp] = useState(false);
  const [clickedRef, setClickedRef] = useState(false);
  const [clickedMisc, setClickedMisc] = useState(false);
  const [checked, setChecked] = useState(false);
  const [rbRelocate, setRbRelocate] = useState("No");
  const [rbTravel, setRbTravel] = useState("No");

  const customSwal = SwalAlert();

  const [candidateState] = useState({
    ddlSalutation: 0,
  });

  const [edustate] = useState({
    txtschool: "",
    txtdegree: "",
    txtfromyear: "",
    txttoyear: "",
    txtcertificate: "",
  });

  const [expstate] = useState({
    txtjobtitle: "",
    ddljobfunction: "",
    txtcompanyname: "",
    ddlIndustry: "",
    ddlCountry: {},
    cbWorkingCurrent: false,

    ddlpremonth: "",
    ddlpreyear: "",
    ddlstartmonth: "",
    ddlendmonth: "",
    ddlstartyear: "",
    ddlendyear: "",
    total_experience: 0,

    txtofficephone: "",
    txtofficeemail: "",
    ddlperiod: "",
    txtjobresponsibility: "",
    ddlcurrency: "",
    txtsalary: "",
    txtothbenefit: "",
    txtreason: "",
  });

  const [allowancestate] = useState([
    {
      ddlEarning: "",
      txtamount: "",
      ddlcurrencyallowance: "",
    },
  ]);

  const [refstate] = useState({
    txtrefname: null,
    txtrefjobtitle: null,
    txtrefemail: null,
    txtrefphone: null,
    txtrefcompany: null,
    txtrefrelationship: null,
  });

  const [miscstate] = useState({
    txtmiscdesc: "",
  });

  const [showOption, setShowOption] = useState(false);

  const handleEarning = (event, param1) => {
    if (event.target.value === "others") {
      setShowOption(true);
      setErrorOpt({});
    } else {
      const filteredEarn = allowanceList[param1];

      filteredEarn.earn = event.target.value;

      allowancestate.ddlEarning = filteredEarn.earn;
    }
  };

  const handleAmount = (event, param1) => {
    const filteredEarn = allowanceList[param1];

    filteredEarn.amount = Number(
      event.target.value.replace(/[^0-9.-]+/g, "")
    );

    allowancestate.txtamount = filteredEarn.amount;
  };

  const handleCurrency = (event, param1) => {

    const filteredEarn = allowanceList[param1];

    filteredEarn.currency = event.target.value;

    allowancestate.ddlcurrencyallowance = filteredEarn.currency;

    // console.log("filteredEarn", filteredEarn);
  };

  const headers = [
    {
      Header: "Earning",
      accessor: (row) => `${row.earn}`,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            <Form.Select
              name="ddlEarning"
              onChange={(event) => handleEarning(event, row.index)}
              defaultValue={data.earn}
            >
              <option value="">Select</option>
              {dataAllowance.map((earn, index) => (
                <option key={index} value={earn.id}>
                  {earn.value}
                </option>
              ))}
              <option value="others">Others</option>
            </Form.Select>
          </div>
        );
      },
    },
    {
      Header: "Amount",
      accessor: (row) => `${row.amount}`,
      Cell: ({ row }) => {
        const data = row.original;
        return (
          <div>
            <TextFieldFormat
              name="txtamount"
              type="number"
              onChange={(event) => handleAmount(event, row.index)}
              value={data.amount}
              thousandSeparator={true}
            ></TextFieldFormat>
          </div>
        );
      },
    },
    {
      Header: "Currency",
      accessor: (row) => `${row.currency}`,
      Cell: ({ row }) => {
        const data = row.original;
        return (
          <div>
            <Form.Select
              name="ddlcurrencyallowance"
              onChange={(event) => handleCurrency(event, row.index)}
              defaultValue={data.currency}
            >
              <option value="">Select</option>
              {dataCurrency.map((currency, index) => (
                <option key={index} value={currency.value}>
                  {currency.value}
                </option>
              ))}
            </Form.Select>
          </div>
        );
      },
    },
    {
      Header: "Action",
      accessor: (row) => `${row.id}`,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            <Button
              variant="link"
              className="delete-link"
              onClick={() => btnDelAllowance(row.index)}
            >
              <Icon path={mdiDelete} size={0.8} />
            </Button>
          </div>
        );
      },
    },
  ];

  const [educationList, setEducationList] = useState([]);
  const [experienceList, setExperienceList] = useState([]);
  const [miscellaneousList, setMiscellaneousList] = useState([]);
  const [refereeList, setRefereeList] = useState([]);
  const [allowanceList, setAllowanceList] = useState([]);

  const [errorsBasic, setErrorsBasic] = useState({});
  const [errorsEdu, setErrorsEdu] = useState({});
  const [errorsExp, setErrorsExp] = useState({});

  const [eduStatus, setEduStatus] = useState("");
  const [expStatus, setExpStatus] = useState("");
  const [refStatus, setRefStatus] = useState("");
  const [miscStatus, setMiscStatus] = useState("");

  const [characterLimit] = useState(500);

  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedPreLoc, setSelectedPreLoc] = useState([]);

  const selectedCrumb = (crumb) => {
    if(crumb === "List of Candidates"){
      navigate("/candidates/listCandidates");
    }
  };

  // const btnBack = () => {
  //   console.log("amm");
  // };

  // Save Candidate
  const findFormErrorsAll = (object, arraySelected) => {
    const newBasicErrors = {};

    // const regEmail = /\S+@\S+\.\S+/;

    // if (!object.ddlSalutation || object.ddlSalutation === "")
    //   newBasicErrors.ddlSalutation = "Fill in the blank!";

    if (!object.txtfirstname || object.txtfirstname === "")
      newBasicErrors.txtfirstname = "Fill in the blank!";

    if (!object.txtlastname || object.txtlastname === "")
      newBasicErrors.txtlastname = "Fill in the blank!";

    // if (arraySelected.length === 0)
    //   newBasicErrors.ddllang = "Fill in the blank!";

    // if (!object.txtcountrycode || object.txtcountrycode === "")
    //   newBasicErrors.txtcountrycode = "Fill in the blank!";

    // if (!object.txtemail || object.txtemail === "")
    //   newBasicErrors.txtemail = "Fill in the blank!";
    // else if (!regEmail.test(object.txtemail))
    //   newBasicErrors.txtemail = "Invalid Email Address";

    // if (!object.txtphone || object.txtphone === "")
    //   newBasicErrors.txtphone = "Fill in the blank!";

    return newBasicErrors;
  };

  const [showUpload, setShowUpload] = useState(false);
  const [newIdCand, setNewIdCand] = useState("");

  const btnSaveCandidate = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtCandidate = Object.fromEntries(data.entries());

    // console.log("dtCandidate", dtCandidate);
    // console.log("setEducationList", educationList);
    // console.log("setExperienceList", experienceList);
    // console.log("setRefereeList", refereeList);
    // console.log("miscellaneousList", miscellaneousList);

    const newErrors = findFormErrorsAll(dtCandidate, selectedValue);

    if (Object.keys(newErrors).length > 0) {
      setErrorsBasic(newErrors);
    } else {

      let linkedInCheck = !dtCandidate.txtlinked.substring(0,dtCandidate.txtlinked.indexOf(":"))? !dtCandidate.txtlinked ? "" : "http://" + dtCandidate.txtlinked : dtCandidate.txtlinked;
      let instaCheck = !dtCandidate.txtinsta.substring(0,dtCandidate.txtinsta.indexOf(":"))? !dtCandidate.txtinsta ? "" : "http://" + dtCandidate.txtinsta : dtCandidate.txtinsta;
      let facebookCheck = !dtCandidate.txtfacebook.substring(0,dtCandidate.txtfacebook.indexOf(":"))? !dtCandidate.txtfacebook ? "" : "http://" + dtCandidate.txtfacebook : dtCandidate.txtfacebook;
      let othwebCheck = !dtCandidate.txtothweb.substring(0,dtCandidate.txtothweb.indexOf(":"))? !dtCandidate.txtothweb ? "" : "http://" + dtCandidate.txtothweb : dtCandidate.txtothweb;

      customSwal
        .fire({
          title: "Do you want to save new candidate?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();

            formData.append("profile_pic", dtCandidate.txtprofile);
            formData.append("salutation", dtCandidate.ddlSalutation);
            formData.append("first_name", dtCandidate.txtfirstname);
            formData.append("last_name", dtCandidate.txtlastname);

            formData.append("nickname", dtCandidate.txtnickname);
            formData.append("gender", dtCandidate.ddlgender);
            formData.append("dob", dtCandidate.txtdob);

            if (selectedValue.length > 0) {
              selectedValue.forEach((item, index) => {
                formData.append(`languages[${index}]`, item);
              });
            } else {
              formData.append(`languages[0]`, []);
            }

            // formData.append("mobile_no_code", dtCandidate.txtcountrycode);
            formData.append("mobile_no", dtCandidate.txtphone);
            formData.append("email", dtCandidate.txtemail);
            formData.append("comp_email", dtCandidate.txtcompemail);
            formData.append("permanent_addr", dtCandidate.txtpermanentaddr);

            formData.append("posting_addr", dtCandidate.txtpostingaddr);
            formData.append("citizenship_main", dtCandidate.ddlcitimain);
            formData.append("citizenship_other", dtCandidate.ddlcitioth);
            formData.append("marital_status", dtCandidate.ddlmartialsta);

            formData.append("no_of_child", Number(dtCandidate.txtnochild));
            formData.append("interest_hobby[0]", dtCandidate.txthobbie);
            formData.append("expected_salary_curr", dtCandidate.ddlcurrency);
            formData.append(
              "expected_salary",
              Number(dtCandidate.txtsalary.replace(/[^0-9.-]+/g, ""))
            );

            formData.append("willing_relocate", dtCandidate.grpRelocate);

            if (dtCandidate.grpRelocate === "false") {
              formData.append(`prefer_location[0]`, []);
            } else {
              if (selectedPreLoc.length > 0) {
                selectedPreLoc.forEach((item, index) => {
                  formData.append(`prefer_location[${index}]`, item);
                });
              } else {
                formData.append(`prefer_location[0]`, []);
              }
            }

            formData.append("willing_travel", dtCandidate.grpTravel);
            formData.append("linkedln_url", linkedInCheck);
            formData.append("instagram_url", instaCheck);
            formData.append("facebook_url", facebookCheck);
            formData.append("website_url", othwebCheck);
            
            formData.append("status", "available");

            if (educationList.length > 0) {
              educationList.forEach((item, index) => {
                formData.append(`education[${index}][name]`, item.name);
                formData.append(
                  `education[${index}][qualification]`,
                  item.qualification
                );
                formData.append(
                  `education[${index}][start_year]`,
                  item.start_year
                );
                formData.append(`education[${index}][end_year]`, item.end_year);
                formData.append(
                  `education[${index}][certificate_attachment]`,
                  item.certificate_attachment
                );
              });
            } else {
              formData.append("education", []);
            }

            if (experienceList.length > 0) {
              experienceList.forEach((exp, index) => {
                formData.append(`experience[${index}][title]`, exp.title);
                formData.append(
                  `experience[${index}][company_name]`,
                  exp.company_name
                );
                formData.append(`experience[${index}][location]`, exp.location);
                formData.append(
                  `experience[${index}][start_date_year]`,
                  exp.start_date_year
                );

                formData.append(
                  `experience[${index}][start_date_month]`,
                  exp.start_date_month
                );
                formData.append(
                  `experience[${index}][end_date_year]`,
                  exp.end_date_year
                );
                formData.append(
                  `experience[${index}][end_date_month]`,
                  exp.end_date_month
                );
                formData.append(
                  `experience[${index}][current_working]`,
                  exp.current_working
                );

                formData.append(
                  `experience[${index}][present_month]`,
                  exp.present_month
                );
                formData.append(
                  `experience[${index}][present_year]`,
                  exp.present_year
                );
                formData.append(`experience[${index}][total_experience]`,exp.total_experience);
                formData.append(
                  `experience[${index}][office_number]`,
                  exp.office_number
                );
                formData.append(
                  `experience[${index}][office_email]`,
                  exp.office_email
                );

                formData.append(
                  `experience[${index}][notice_period]`,
                  exp.notice_period
                );

                if (exp.industry.length > 0) {
                  exp.industry.forEach((ind, index2) => {
                    formData.append(
                      `experience[${index}][industry][${index2}]`,
                      ind
                    );
                  });
                } else {
                  formData.append(`experience[${index}][industry]`, []);
                }

                if (exp.job_function.length > 0) {
                  exp.job_function.forEach((job, index2) => {
                    formData.append(
                      `experience[${index}][job_function][${index2}]`,
                      job
                    );
                  });
                } else {
                  formData.append(`experience[${index}][job_function]`, []);
                }

                formData.append(
                  `experience[${index}][job_responsibility]`,
                  exp.job_responsibility
                );

                formData.append(`experience[${index}][salary]`, exp.salary);
                formData.append(`experience[${index}][currency]`, exp.currency);
                formData.append(
                  `experience[${index}][other_benefit]`,
                  exp.other_benefits
                );
                formData.append(
                  `experience[${index}][reason_leaving]`,
                  exp.reason_leaving
                );

                if (exp.allowance.length > 0) {
                  exp.allowance.forEach((allow, index2) => {
                    formData.append(
                      `experience[${index}][allowance][${index2}][earning]`,
                      allow.earn
                    );
                    formData.append(
                      `experience[${index}][allowance][${index2}][amount]`,
                      allow.amount
                    );
                    formData.append(
                      `experience[${index}][allowance][${index2}][currency]`,
                      allow.currency
                    );
                  });
                } else {
                  formData.append(`experience[${index}][allowance]`, []);
                }
              });
            } else {
              formData.append("experience", []);
            }

            if (refereeList.length > 0) {
              refereeList.forEach((ref, index) => {
                formData.append(`reference[${index}][name]`, ref.name);
                formData.append(
                  `reference[${index}][job_title]`,
                  ref.job_title
                );
                formData.append(`reference[${index}][email]`, ref.email);
                formData.append(`reference[${index}][phone]`, ref.phone);
                formData.append(
                  `reference[${index}][current_company]`,
                  ref.current_company
                );
                formData.append(
                  `reference[${index}][relationship]`,
                  ref.relationship
                );
              });
            } else {
              formData.append("reference", []);
            }

            if (miscellaneousList.length > 0) {
              miscellaneousList.forEach((miscell, index) => {
                formData.append(`miscellaneous[${index}][misc]`, miscell.misc);
              });
            } else {
              formData.append("miscellaneous", []);
            }

            Apiform.post("candidate/store", formData)
              .then((response) => {
                // console.log(response);

                setNewIdCand(response.data.id);
                setShowUpload(true);
              })
              .catch((error) => {
                console.log("error btnSavecanidate", error);
              });
          }
        });
    }
  };

  const btnUploadLater = (e) => {
    navigate("/candidates/listCandidates");
  };

  const btnUploadResume = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtResume = Object.fromEntries(data.entries());

    const formData = new FormData();

    formData.append("file", dtResume.txtresume);
    formData.append("type_doc", "resume");
    formData.append("file_name", "Resume 1");

    Apiform.post("candidate/" + newIdCand + "/upload", formData)
      .then((response) => {
        navigate("/candidates/listCandidates");

        customSwal.fire({
          title: "Saved Successfully!",
          icon: "success",
          confirmButtonText: "Okay",
        });
      })
      .catch((error) => {
        console.log("error btnUploadResume", error);
      });
  };

  const [salaryExpectedTxt, setSalaryExpectedTxt] = useState(0.0);

  const handleExpectedSalary = (e) => {
    setSalaryExpectedTxt(e.target.value);
  };

  // };

  //End Save Candidate

  // Education Function

  const findFormErrors = (object) => {
    const newErrors = {};
    // school errors
    if (!object.txtschool || object.txtschool === "")
      newErrors.txtschool = "Fill in the blank!";

    return newErrors;
  };

  const btnAddEdu = (index) => {
    if (clickedEdu === index) {
      //if clicked is already active, then close
      return setClickedEdu(null);
    }

    btnResetEdu();
    setEduStatus("New");
    setErrorsEdu({});

    setClickedEdu(index);
  };

  const btnSaveEdu = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dataEducation = Object.fromEntries(data.entries());

    const newErrors = findFormErrors(dataEducation);

    if (Object.keys(newErrors).length > 0) {
      setErrorsEdu(newErrors);
    } else {
      if (eduStatus === "New") {
        const eduLength = educationList.length;

        setEducationList((items) => [
          ...items,
          {
            id: eduLength + 1,
            name: dataEducation.txtschool,
            qualification: dataEducation.txtdegree,
            start_year: dataEducation.txtfromyear,
            end_year: dataEducation.txttoyear,
            certificate_attachment: dataEducation.txtcertificate,
          },
        ]);

        setClickedEdu(false);
      } else {

        const filteredEdu = educationList[eduStatus];

        filteredEdu.name = dataEducation.txtschool;
        filteredEdu.qualification = dataEducation.txtdegree;
        filteredEdu.start_year = dataEducation.txtfromyear;
        filteredEdu.end_year = dataEducation.txttoyear;
        filteredEdu.certificate_attachment = dataEducation.txtcertificate;

        setClickedEdu(false);
      }
    }
  };

  const btnEditEdu = (id) => (e) => {

    const filteredEdu = educationList[id];

    edustate.txtschool = filteredEdu.name;
    edustate.txtdegree = filteredEdu.qualification;
    edustate.txtfromyear = filteredEdu.start_year;
    edustate.txttoyear = filteredEdu.end_year;
    edustate.txtcertificate = filteredEdu.certificate_attachment;

    setEduStatus(id);
    setClickedEdu(true);
  };

  const btnResetEdu = (event) => {
    edustate.txtschool = null;
    edustate.txtdegree = null;
    edustate.txtfromyear = null;
    edustate.txttoyear = null;
  };

  const btnDelEdu = (index) => {
    let data = [...educationList];
    data.splice(index, 1);
    setEducationList(data);
  };

  const btnDownload = (id, file) => (e) => {
    // console.log("id", id);
    // console.log("file", file);
    FileSaver.saveAs(file);
  };
  // End Function

  // Experience Function

  const [isReadMore, setReadMore] = useState({
    id: "",
    more: false,
  });

  const findFormErrorsExp = (object, company) => {
    const newErrors = {};

    if (!object.txtjobtitle || object.txtjobtitle === "")
      newErrors.txtjobtitle = "Fill in the blank!";

    // if (!object.txtcompanyname || object.txtcompanyname === "")
    //   newErrors.txtcompanyname = "Fill in the blank!";

    if (!company || company === "")
      newErrors.txtcompanyname = "Fill in the blank!";

    if (!object.ddlCountry || object.ddlCountry === "")
      newErrors.ddlCountry = "Fill in the blank!";

    // if (checked === true) {
    //   if (!object.ddlpreyear || object.ddlpreyear === "")
    //     newErrors.ddlpreyear = "Fill in the blank!";

    //   if (!object.ddlpremonth || object.ddlpremonth === "")
    //     newErrors.ddlpremonth = "Fill in the blank!";
    // } else {
    //   if (!object.ddlstartyear || object.ddlstartyear === "")
    //     newErrors.ddlstartyear = "Fill in the blank!";

    //   if (!object.ddlstartmonth || object.ddlstartmonth === "")
    //     newErrors.ddlstartmonth = "Fill in the blank!";

    //   if (!object.ddlendyear || object.ddlendyear === "")
    //     newErrors.ddlendyear = "Fill in the blank!";

    //   if (!object.ddlendmonth || object.ddlendmonth === "")
    //     newErrors.ddlendmonth = "Fill in the blank!";
    // }

    // if (!object.txtofficeemail || object.txtofficeemail === "")
    //   newErrors.txtofficeemail = "Fill in the blank!";

    // if (!object.ddlcurrency || object.ddlcurrency === "")
    //   newErrors.ddlcurrency = "Fill in the blank!";

    // if (!object.txtsalary || object.txtsalary === "")
    //   newErrors.txtsalary = "Fill in the blank!";

    return newErrors;
  };

  const btnAddExp = (index) => {
    if (clickedExp === index) {
      //if clicked is already active, then close
      return setClickedExp(null);
    }

    btnResetExp();
    setChecked(false);
    setAllowanceList([]);
    setCompanyValue();
    setErrorsExp({});
    setExpStatus("New");
    setClickedExp(index);
  };

  function getMonthDifference(startDate, endDate) {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }

  const btnSaveExp = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dataExperience = Object.fromEntries(data.entries());

    var monthDuration = 0;
    // console.log("dataExperience", dataExperience);
    // console.log("allowanceList", Number(salaryTxt.current.replace(/[^0-9.-]+/g, "")));

    const newErrors = findFormErrorsExp(dataExperience, companyValue);

    if (expStatus === "New") {
      if (Object.keys(newErrors).length > 0) {
        setErrorsExp(newErrors);
      } else {
        const expLength = experienceList.length;

        let startMonthDate = "";
        let endMonthDate = "";
        let startYearDate = "";
        let endYearDate = "";

        let preMonth = "";
        let preYear = "";

        if (checked === true) {
          preMonth = dataExperience.ddlpremonth;
          preYear = dataExperience.ddlpreyear;
          startMonthDate = "";
          endMonthDate = "";
          startYearDate = "";
          endYearDate = "";
          monthDuration = getMonthDifference(
            new Date(
              dataExperience.ddlpreyear + "-" + dataExperience.ddlpremonth
            ),
            new Date()
          );
        } else {
          preMonth = "";
          preYear = "";
          startMonthDate = dataExperience.ddlstartmonth;
          endMonthDate = dataExperience.ddlendmonth;
          startYearDate = dataExperience.ddlstartyear;
          endYearDate = dataExperience.ddlendyear;
          monthDuration = getMonthDifference(
            new Date(
              dataExperience.ddlstartyear + "-" + dataExperience.ddlstartmonth
            ),
            new Date(
              dataExperience.ddlendyear + "-" + dataExperience.ddlendmonth
            )
          );
        }

        // let mnthDur = monthDuration ? monthDuration : 0;

        // console.log("monthDuration", mnthDur )

        // setSalaryTxt(
        //   Number(dataExperience.txtsalary.replace(/[^0-9.-]+/g, ""))
        // );

        setExperienceList((items) => [
          ...items,
          {
            id: expLength + 1,
            title: dataExperience.txtjobtitle,
            company_name: companyValue.value,
            location: dataExperience.ddlCountry,
            start_date_year: startYearDate,
            start_date_month: startMonthDate,
            desc_start_date_month: startMon,
            end_date_year: endYearDate,
            end_date_month: endMonthDate,
            desc_end_date_month: endMon,
            current_working: checked,
            present_month: preMonth,
            present_year: preYear,
            total_experience: monthDuration ? monthDuration : 0,
            desc_present_month: preDescMon,
            office_number: dataExperience.txtofficephone,
            office_email: dataExperience.txtofficeemail,
            notice_period: dataExperience.ddlperiod,
            industry: dataExperience.ddlIndustry !== "" ? selectedInd : [],
            job_function:
              dataExperience.ddljobfunction !== "" ? selectedJob : [],
            job_responsibility: dataExperience.txtjobresponsibility,
            salary: Number(salaryTxt.current.replace(/[^0-9.-]+/g, "")),
            currency: dataExperience.ddlcurrency,
            other_benefits: dataExperience.txtothbenefit,
            reason_leaving: dataExperience.txtreason,
            allowance: allowanceList,
          },
        ]);

        // console.log("dataExperience", experienceList);

        setClickedExp(false);
      }
    } else {
      if (Object.keys(newErrors).length > 0) {
        setErrorsExp(newErrors);
      } else {
        const filteredExp = experienceList[expStatus];

        // console.log("filteredExp", filteredExp);

        let startMonthDate = "";
        let endMonthDate = "";
        let startYearDate = "";
        let endYearDate = "";

        let preMonth = "";
        let preYear = "";

        // var monthDuration = 0;

        if (checked === true) {
          preMonth = dataExperience.ddlpremonth;
          preYear = dataExperience.ddlpreyear;
          startMonthDate = "";
          endMonthDate = "";
          startYearDate = "";
          endYearDate = "";
          monthDuration = getMonthDifference(
            new Date(
              dataExperience.ddlpreyear + "-" + dataExperience.ddlpremonth
            ),
            new Date()
          );
        } else {
          preMonth = "";
          preYear = "";
          startMonthDate = dataExperience.ddlstartmonth;
          endMonthDate = dataExperience.ddlendmonth;
          startYearDate = dataExperience.ddlstartyear;
          endYearDate = dataExperience.ddlendyear;
          monthDuration = getMonthDifference(
            new Date(
              dataExperience.ddlstartyear + "-" + dataExperience.ddlstartmonth
            ),
            new Date(
              dataExperience.ddlendyear + "-" + dataExperience.ddlendmonth
            )
          );
        }

        filteredExp.title = dataExperience.txtjobtitle;
        filteredExp.job_function =
          dataExperience.ddljobfunction !== "" ? selectedJob : [];
        filteredExp.company_name = companyValue.value;
        filteredExp.industry =
          dataExperience.ddlIndustry !== "" ? selectedInd : [];
        filteredExp.start_date_year = startYearDate;
        filteredExp.start_date_month = startMonthDate;
        filteredExp.desc_start_date_month = startMon;

        filteredExp.end_date_year = endYearDate;
        filteredExp.end_date_month = endMonthDate;
        filteredExp.desc_end_date_month = endMon;

        filteredExp.current_working = checked;
        filteredExp.present_month = preMonth;
        filteredExp.present_year = preYear;
        filteredExp.desc_present_month = preDescMon;
        filteredExp.total_experience = monthDuration ? monthDuration : 0;

        filteredExp.office_number = dataExperience.txtofficephone;
        filteredExp.office_email = dataExperience.txtofficeemail;
        filteredExp.notice_period = dataExperience.ddlperiod;
        filteredExp.job_responsibility = dataExperience.txtjobresponsibility;

        if (Number.isInteger(salaryTxt.current) === false) {
          filteredExp.salary = Number(
            salaryTxt.current.replace(/[^0-9.-]+/g, "")
          );
        }

        filteredExp.currency = dataExperience.ddlcurrency;
        filteredExp.other_benefits = dataExperience.txtothbenefit;
        filteredExp.reason_leaving = dataExperience.txtreason;
        filteredExp.allowance = allowanceList;

        setClickedExp(false);
      }
    }
  };

  const btnDelExp = (index) => {
    // console.log("idExp", index);

    let data = [...experienceList];
    data.splice(index, 1);
    setExperienceList(data);

  };

  const btnEditExp = (id) => (e) => {
    setErrorsExp({});

    const filteredExp = experienceList[id];

    salaryTxt.current = filteredExp.salary;

    expstate.txtjobtitle = filteredExp.title;
    expstate.ddljobfunction = filteredExp.job_function;
    setCompanyValue({
      label: filteredExp.company_name,
      value: filteredExp.company_name,
    });
    expstate.ddlIndustry = filteredExp.industry;
    expstate.ddlCountry = {
      label: filteredExp.location,
      value: filteredExp.location,
    };

    expstate.ddlstartmonth = filteredExp.start_date_month;
    expstate.ddlstartyear = filteredExp.start_date_year;
    expstate.ddlendmonth = filteredExp.end_date_month;
    expstate.ddlendyear = filteredExp.end_date_year;

    expstate.ddlpremonth = filteredExp.present_month;
    expstate.ddlpreyear = filteredExp.present_year;
    expstate.total_experience = filteredExp.total_experience;

    expstate.cbWorkingCurrent = filteredExp.current_working;
    expstate.txtofficephone = filteredExp.office_number;
    expstate.txtofficeemail = filteredExp.office_email;
    expstate.ddlperiod = filteredExp.notice_period;
    expstate.txtjobresponsibility = filteredExp.job_responsibility;
    expstate.ddlcurrency = filteredExp.currency;
    // expstate.txtsalary = filteredExp[0].salary;
    expstate.txtothbenefit = filteredExp.other_benefits;
    expstate.txtreason = filteredExp.reason_leaving;

    setAllowanceList(filteredExp.allowance);

    setChecked(filteredExp.current_working);
    setExpStatus(id);
    setClickedExp(true);
  };

  const btnDelAllowance = (index) => {

    let data = [...allowanceList];
    data.splice(index, 1);
    setAllowanceList(data);
  };

  const [selectedJob, setSelectedJob] = useState([]);

  const handleJobFunc = (e) => {
    setSelectedJob(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const btnAddNewAllowance = (e) => {
    const allowanceLength = allowanceList.length + 1;

    setAllowanceList((items) => [
      ...items,
      {
        id: allowanceLength,
        earn: "",
        amount: "",
        currency: "",
      },
    ]);
  };

  const salaryTxt = useRef(null);

  // const handleSalary = (e) => {
  //   // setSalaryTxt(e.target.value);
  // };

  const handleSalary = useCallback((event) => {
    const value = event.target.value;

    salaryTxt.current = value;
  }, []);

  const [startMon, setStartMon] = useState("");

  const handleStartMonth = (e) => {
    let index = e.nativeEvent.target.selectedIndex;
    let label = e.nativeEvent.target[index].text;

    setStartMon(label);
  };

  const [endMon, setEndMon] = useState("");

  const handleEndMonth = (e) => {
    let index = e.nativeEvent.target.selectedIndex;
    let label = e.nativeEvent.target[index].text;

    setEndMon(label);
  };

  const [preDescMon, setPreDescMon] = useState("");

  const handlePreMonth = (e) => {
    let index = e.nativeEvent.target.selectedIndex;
    let label = e.nativeEvent.target[index].text;

    setPreDescMon(label);
  };

  const [selectedInd, setSelectedInd] = useState([]);

  const handleIndustry = (e) => {
    setSelectedInd(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const btnResetExp = (event) => {
    expstate.txtjobtitle = "";
    expstate.txtcompanyname = "";
    expstate.ddljobfunction = "";
    expstate.ddlIndustry = "";
    expstate.ddlCountry = {};

    expstate.ddlstartmonth = "";
    expstate.ddlstartyear = "";
    expstate.ddlendmonth = "";
    expstate.ddlendyear = "";

    expstate.ddlpremonth = "";
    expstate.ddlpreyear = "";

    expstate.cbWorkingCurrent = false;
    expstate.txtofficephone = "";
    expstate.txtofficeemail = "";
    expstate.ddlperiod = "";
    expstate.txtjobresponsibility = "";
    expstate.ddlcurrency = "";

    expstate.txtothbenefit = "";
    expstate.txtreason = "";
    salaryTxt.current = "";

    setChecked(false);
  };

  const [companyValue, setCompanyValue] = useState();

  const handleCompanyName = useCallback((inputValue) => {
    // console.log("inputValue", inputValue);
    setCompanyValue(inputValue);
  }, []);

  function toMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", {
      month: "long",
    });
  }

  function toAllowanceDesc(idallow) {
    let nameAllow = "";

    let array_allow = dataAllowance.filter((grp) => {
      return grp.id === Number(idallow);
    });

    if (array_allow.length > 0) {
      nameAllow = array_allow[0].value;
    }

    return nameAllow;
  }

  const toggleBtnReadMore = (index, bool) => (e) => {
    setReadMore({
      id: index,
      more: bool,
    });
  };

  const btnCloseOption = () => {
    setShowOption(false);
  };

  const [errorOpt, setErrorOpt] = useState({});

  const formExpOption = (object) => {
    const newErrors = {};

    if (!object.txtAllowDesc || object.txtAllowDesc === "")
      newErrors.txtAllowDesc = "Fill in the blank!";

    return newErrors;
  };

  const btnNewOption = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dataOpt = Object.fromEntries(data.entries());

    // console.log("dataOpt",dataOpt)
    const newErrors = formExpOption(dataOpt);

    if (Object.keys(newErrors).length > 0) {
      setErrorOpt(newErrors);
    } else {
      // console.log("dataOpt.txtAllowDesc", allowList)

      // const allowCode = allowList.find(e => e.value === dataOpt.txtAllowDesc.trim());

      var allowCode = dataAllowance.filter(function (allow) {
        return (
          allow.value.toLowerCase() ===
          dataOpt.txtAllowDesc.trim().toLowerCase()
        );
      });

      // console.log("allowCode", allowCode)

      if (allowCode.length > 0) {
        customSwal.fire({
          title: "This allowance description duplicate!",
          icon: "error",
          confirmButtonText: "Okay",
        });

        // setShowOption(false);
      } else {
        customSwal
          .fire({
            title: "Do you want to save new allowance?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post("option/store", {
                group: "Allowance",
                code: dataOpt.txtAllowDesc,
                value: dataOpt.txtAllowDesc,
                status: "active",
              })
                .then((response) => {
                  // console.log("option/store", response.data);
                  dispatch(getListOptions());
                  setShowOption(false);
                })
                .catch((error) => {
                  console.log("option/update", error);
                });
            }
          });
      }
    }
  };

  // End Experience Function

  // Referee Function
  const btnAddRef = (index) => {
    if (clickedRef === index) {
      //if clicked is already active, then close
      return setClickedRef(null);
    }

    setRefStatus("New");
    btnResetRef();
    setClickedRef(index);
  };

  const btnSaveRef = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);

    const dataReferee = Object.fromEntries(data.entries());

    if (refStatus === "New") {
      const refLength = refereeList.length + 1;

      setRefereeList((items) => [
        ...items,
        {
          id: refLength,
          name: dataReferee.txtrefname,
          job_title: dataReferee.txtrefjobtitle,
          email: dataReferee.txtrefemail,
          phone: dataReferee.txtrefphone,
          current_company: dataReferee.txtrefcompany,
          relationship: dataReferee.txtrefrelationship,
        },
      ]);

      setClickedRef(false);
    } else {
      const filteredRef = refereeList[refStatus];


      filteredRef.name = dataReferee.txtrefname;
      filteredRef.job_title = dataReferee.txtrefjobtitle;
      filteredRef.email = dataReferee.txtrefemail;
      filteredRef.phone = dataReferee.txtrefphone;
      filteredRef.current_company = dataReferee.txtrefcompany;
      filteredRef.relationship = dataReferee.txtrefrelationship;

      setClickedRef(false);
    }
  };

  const btnEditRef = (id) => (e) => {
    const filteredRef = refereeList[id];

    refstate.txtrefname = filteredRef.name;
    refstate.txtrefjobtitle = filteredRef.job_title;
    refstate.txtrefemail = filteredRef.email;
    refstate.txtrefphone = filteredRef.phone;
    refstate.txtrefcompany = filteredRef.current_company;
    refstate.txtrefrelationship = filteredRef.relationship;

    setRefStatus(id);
    setClickedRef(true);
  };

  const btnResetRef = (event) => {
    refstate.txtrefname = null;
    refstate.txtrefjobtitle = null;
    refstate.txtrefemail = null;
    refstate.txtrefphone = null;
    refstate.txtrefrelationship = null;
    refstate.txtrefcompany = null;
  };

  const btnDelRef = (index) => {
    // console.log("idref", id);
    let data = [...refereeList];
    data.splice(index, 1);
    setRefereeList(data);
  };
  // End Referee Function

  // Miscellaneous Function
  const btnAddMisc = (index) => {
    if (clickedMisc === index) {
      //if clicked is already active, then close
      return setClickedMisc(null);
    }

    setMiscStatus("New");
    btnResetMisc();
    setClickedMisc(index);
  };

  const btnSaveMisc = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);

    const dataMisc = Object.fromEntries(data.entries());

    if (miscStatus === "New") {
      const miscellLength = miscellaneousList.length + 1;

      setMiscellaneousList((items) => [
        ...items,
        {
          id: miscellLength,
          misc: dataMisc.txtmiscdesc,
        },
      ]);

      setClickedMisc(false);
    } else {
      const filteredMisc = miscellaneousList[miscStatus]

      filteredMisc.misc = dataMisc.txtmiscdesc;

      setClickedMisc(false);
    }
  };

  const btnEditMisc = (id) => (e) => {
    const filteredMisc = miscellaneousList[id];

    miscstate.txtmiscdesc = filteredMisc.misc;

    setMiscStatus(id);
    setClickedMisc(true);
  };

  const btnResetMisc = (event) => {
    miscstate.txtmiscdesc = null;
  };

  const btnDelMisc = (index) => {
    let data = [...miscellaneousList];
    data.splice(index, 1);
    setMiscellaneousList(data);
  };
  // End Miscellaneous Function

  const handleLanguages = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const handlePreferLoc = (e) => {
    setSelectedPreLoc(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  // Function Event

  const [clientList, setClientList] = useState([]);

  function getListClients() {
    setClientList([]);

    Api.get("client")
      .then((response) => {
        let sortListClient = response.data.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });

        sortListClient.map((cli) => {
          return setClientList((items) => [
            ...items,
            {
              label: cli.name,
              value: cli.name,
            },
          ]);
        });
      })
      .catch((error) => {
        console.log("error getListClients", error);
      });
  }

  useEffect(() => {
    getListClients();
    dispatch(
      storeTitle({ title: "List of Candidates", icon: mdiAccountSearch })
    );
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  return (
    <>
      {/* <Prompt
        // when={shouldBlockNavigation}
        message="You have unsaved changes, are you sure you want to leave?" t
      /> */}
      <div className="main-div">
        <div className="top-title-div both-side">
          <BreadCrumb crumbs={["List of Candidates", "Add Candidate"]} selected={selectedCrumb}></BreadCrumb>
          <Button variant="secondary" onClick={() => navigate(-1)}>
            <Icon path={mdiArrowLeft} size={0.7} color="#212529" />
            Back
          </Button>
        </div>

        <div className="top-title-div">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Basic Information</Accordion.Header>
              <Accordion.Body>
                <Form id="basic-form" onSubmit={btnSaveCandidate}>
                  {/* 1 */}
                  <Row>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Profile Picture
                        </Form.Label>
                        <Col>
                          <Form.Control
                            name="txtprofile"
                            type="file"
                            accept=".png,.jpg,.jpeg"
                          />
                          <Form.Text className="text-muted">
                            Allowed file types: jpg, jpeg, png.
                          </Form.Text>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col xs={6}></Col>
                  </Row>
                  {/* 2 */}
                  <Row>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Salutation
                          {/* <label className="validation-star">*</label> */}
                        </Form.Label>
                        <Col>
                          <Form.Select
                            name="ddlSalutation"
                            defaultValue={candidateState.ddlSalutation}
                            // isInvalid={!!errorsBasic.ddlSalutation}
                          >
                            <option value="">Select</option>
                            {dataSalute.map((salute, index) => (
                              <option key={index} value={salute.code}>
                                {salute.value}
                              </option>
                            ))}
                          </Form.Select>
                          {/* <Form.Control.Feedback type="invalid">
                            {errorsBasic.ddlSalutation}
                          </Form.Control.Feedback> */}
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Nickname/Alias
                        </Form.Label>
                        <Col>
                          <Form.Control name="txtnickname" type="text" />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* 3 */}
                  <Row>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          First Name{" "}
                          <label className="validation-star">*</label>
                        </Form.Label>
                        <Col>
                          <Form.Control
                            name="txtfirstname"
                            type="text"
                            isInvalid={!!errorsBasic.txtfirstname}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errorsBasic.txtfirstname}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Last Name <label className="validation-star">*</label>
                        </Form.Label>
                        <Col>
                          <Form.Control
                            name="txtlastname"
                            type="text"
                            isInvalid={!!errorsBasic.txtlastname}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errorsBasic.txtlastname}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* 4 */}
                  <Row>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Date of Birth
                        </Form.Label>
                        <Col>
                          <Form.Control name="txtdob" type="date" />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Gender
                        </Form.Label>
                        <Col>
                          <Form.Select name="ddlgender">
                            <option value="">Select</option>
                            {dataGender.map((gndr, index) => (
                              <option key={index} value={gndr.code}>
                                {gndr.value}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* 5 */}
                  <Row>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Mobile Number{" "}
                        </Form.Label>
                        <Col>
                          <Form.Control
                            name="txtphone"
                            as="textarea"
                            rows={1}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Languages
                        </Form.Label>
                        <Col>
                          <Searchable
                            isMulti={true}
                            // isInvalid={!!errorsBasic.ddllang}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                            options={dataLanguages}
                            value={dataLanguages.filter((obj) =>
                              selectedValue.includes(obj.value)
                            )}
                            onChange={handleLanguages}
                            // errorText={errorsBasic.ddllang}
                          ></Searchable>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* 6 */}
                  <Row>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Personal Email Address{" "}
                        </Form.Label>
                        <Col>
                          <Form.Control
                            name="txtemail"
                            type="email"
                            // isInvalid={!!errorsBasic.txtemail}
                          ></Form.Control>
                          {/* <Form.Control.Feedback type="invalid">
                            {errorsBasic.txtemail}
                          </Form.Control.Feedback> */}
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Company Email Address{" "}
                        </Form.Label>
                        <Col>
                          <Form.Control
                            name="txtcompemail"
                            type="email"
                            // isInvalid={!!errorsBasic.txtemail}
                          ></Form.Control>
                          {/* <Form.Control.Feedback type="invalid">
                            {errorsBasic.txtemail}
                          </Form.Control.Feedback> */}
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* 7 */}
                  <Row>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Permanent Address
                        </Form.Label>
                        <Col>
                          <Form.Control
                            name="txtpermanentaddr"
                            as="textarea"
                            rows={3}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Posting Address
                        </Form.Label>
                        <Col>
                          <Form.Control
                            name="txtpostingaddr"
                            as="textarea"
                            rows={3}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* 8 */}
                  <Row>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Citizenship(Main)
                        </Form.Label>
                        <Col>
                          <Form.Select name="ddlcitimain">
                            <option value="">Select</option>
                            {dataCitizenship.map((ctzn, index) => (
                              <option key={index} value={ctzn.value}>
                                {ctzn.value}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Citizenship(Others)
                        </Form.Label>
                        <Col>
                          <Form.Select name="ddlcitioth">
                            <option value="">Select</option>
                            {dataCitizenship.map((ctzn, index) => (
                              <option key={index} value={ctzn.value}>
                                {ctzn.value}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* 9 */}
                  <Row>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Marital Status
                        </Form.Label>
                        <Col>
                          <Form.Select name="ddlmartialsta">
                            <option value="">Select</option>
                            {dataMarital.map((mari, index) => (
                              <option key={index} value={mari.code}>
                                {mari.value}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Number of Children
                        </Form.Label>
                        <Col>
                          <Form.Control
                            name="txtnochild"
                            type="number"
                            min="0"
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* 10 */}
                  <Row>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Interest/Hobbies
                        </Form.Label>
                        <Col>
                          <Form.Control
                            name="txthobbie"
                            type="text"
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Expected Salary
                        </Form.Label>
                        <Col xs={2}>
                          <Form.Select name="ddlcurrency">
                            <option value="">Select</option>
                            {dataCurrency.map((currency, index) => (
                              <option key={index} value={currency.code}>
                                {currency.value}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                        <Col xs={6}>
                          <TextFieldFormat
                            name="txtsalary"
                            value={salaryExpectedTxt}
                            onChange={handleExpectedSalary}
                            thousandSeparator={true}
                          ></TextFieldFormat>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* 11 */}
                  <Row>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Willing to relocate
                        </Form.Label>
                        <Col>
                          <div className="mb-3">
                            <Form.Check
                              inline
                              label="Yes"
                              name="grpRelocate"
                              value={true}
                              type="radio"
                              checked={rbRelocate === "Yes"}
                              onChange={() => setRbRelocate("Yes")}
                            />
                            <Form.Check
                              inline
                              label="No"
                              name="grpRelocate"
                              type="radio"
                              value={false}
                              checked={rbRelocate === "No"}
                              onChange={() => setRbRelocate("No")}
                            />
                          </div>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Willing to travel
                        </Form.Label>
                        <Col>
                          <div className="mb-3">
                            <Form.Check
                              inline
                              label="Yes"
                              name="grpTravel"
                              value={true}
                              type="radio"
                              checked={rbTravel === "Yes"}
                              onChange={() => setRbTravel("Yes")}
                            />
                            <Form.Check
                              inline
                              label="No"
                              name="grpTravel"
                              value={false}
                              type="radio"
                              checked={rbTravel === "No"}
                              onChange={() => setRbTravel("No")}
                            />
                          </div>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* 12 */}
                  {rbRelocate === "Yes" ? (
                    <Row>
                      <Col xs={6}>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column lg={4}>
                            Preferred Locations
                          </Form.Label>
                          <Col>
                            <Searchable
                              name="ddlpreferloc"
                              isMulti={true}
                              options={dataCountry}
                              getOptionValue={(option) => option.value}
                              getOptionLabel={(option) => option.value}
                              onChange={handlePreferLoc}
                            ></Searchable>
                            {/* <option value="">Please Select</option>
                              {countryList.map((cntry, index) => (
                                <option key={index} value={cntry.value}>
                                    {cntry.label}
                                </option>
                            ))} */}
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col xs={6}></Col>
                    </Row>
                  ) : null}

                  {/* 13 */}
                  <Row>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Linkedln Profile
                        </Form.Label>
                        <Col>
                          <Form.Control
                            name="txtlinked"
                            type="text"
                            placeholder="http://"
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Instagram Profile
                        </Form.Label>
                        <Col>
                          <Form.Control
                            name="txtinsta"
                            type="text"
                            placeholder="http://"
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* 14 */}
                  <Row>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Facebook Profile
                        </Form.Label>
                        <Col>
                          <Form.Control
                            name="txtfacebook"
                            type="text"
                            placeholder="http://"
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Others Website
                        </Form.Label>
                        <Col>
                          <Form.Control
                            name="txtothweb"
                            type="text"
                            placeholder="http://"
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>Education Information</Accordion.Header>
              <Accordion.Body>
                <div className="container-info">
                  {clickedEdu === false ? (
                    <Button
                      onClick={() => btnAddEdu(true)}
                      variant="link"
                      className="modal-link"
                    >
                      <Icon path={mdiPlusCircle} size={0.8} />
                      Add Education
                    </Button>
                  ) : (
                    <div
                      className={
                        "add-edu-container" + (clickedEdu ? " in" : "")
                      }
                    >
                      {/* 1 */}
                      <div className="container-bg-col-div">
                        <div className="container-form-div">
                          <Form noValidate onSubmit={btnSaveEdu}>
                            {/* 1.1 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                School{" "}
                                <label className="validation-star">*</label>
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  name="txtschool"
                                  type="text"
                                  defaultValue={edustate.txtschool}
                                  isInvalid={!!errorsEdu.txtschool}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errorsEdu.txtschool}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                            {/* 1.2 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Degree
                                {/* {" "} */}
                                {/* <label className="validation-star">*</label> */}
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  name="txtdegree"
                                  type="text"
                                  defaultValue={edustate.txtdegree}
                                  // isInvalid={!!errorsEdu.txtdegree}
                                />
                                {/* <Form.Control.Feedback type="invalid">
                                  {errorsEdu.txtdegree}
                                </Form.Control.Feedback> */}
                              </Col>
                            </Form.Group>
                            {/* 1.3 */}
                            <Row>
                              <Col xs={6}>
                                <Form.Group as={Row} className="mb-3">
                                  <Form.Label column lg={4}>
                                    Duration
                                    {/* {" "}
                                    <label className="validation-star">*</label> */}
                                  </Form.Label>
                                  <Col>
                                    <Form.Select
                                      name="txtfromyear"
                                      defaultValue={edustate.txtfromyear}
                                      // isInvalid={!!errorsEdu.txtfromyear}
                                    >
                                      <option value="">Select</option>
                                      {dataYear.map((year, index) => (
                                        <option key={index} value={year.value}>
                                          {year.label}
                                        </option>
                                      ))}
                                    </Form.Select>
                                    {/* <Form.Control.Feedback type="invalid">
                                      {errorsEdu.txtfromyear}
                                    </Form.Control.Feedback> */}
                                  </Col>
                                </Form.Group>
                              </Col>
                              <Col xs={6}>
                                <Form.Group as={Row} className="mb-3">
                                  <Form.Label column lg={4}>
                                    to
                                  </Form.Label>
                                  <Col>
                                    <Form.Select
                                      name="txttoyear"
                                      defaultValue={edustate.txttoyear}
                                      // isInvalid={!!errorsEdu.txttoyear}
                                    >
                                      <option value="">Select</option>
                                      {dataYear.map((year, index) => (
                                        <option key={index} value={year.value}>
                                          {year.label}
                                        </option>
                                      ))}
                                    </Form.Select>
                                    {/* <Form.Control.Feedback type="invalid">
                                      {errorsEdu.txttoyear}
                                    </Form.Control.Feedback> */}
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>
                            {/* 1.4 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Certificates
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  name="txtcertificate"
                                  type="file"
                                />
                              </Col>
                            </Form.Group>

                            <div className="row-btn-bottom">
                              <Button
                                onClick={() => btnAddEdu(false)}
                                variant="secondary"
                              >
                                Cancel
                              </Button>
                              <Button variant="primary" type="submit">
                                Save
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="container-info">
                  {clickedEdu === false ? (
                    <div className="column-card">
                      {educationList.map((education, index) => (
                        <CardCandidate key={index}>
                          <div className="card-education__row-flex5">
                            <div className="sub-text">{education.name}</div>
                            <div className="sub-text">
                              {education.qualification}
                            </div>
                            <div
                              className="sub-text"
                              style={{ fontWeight: 400 }}
                            >
                              {education.start_year} - {education.end_year}
                            </div>
                          </div>
                          <div className="card-education__row-flex1">
                            <div>
                              <Button
                                variant="link"
                                className="no-underline"
                                onClick={btnDownload(
                                  education.id,
                                  education.certificate_attachment
                                )}
                              >
                                <Icon path={mdiFile} size={0.8} />
                                Show Certificate
                              </Button>
                            </div>
                          </div>
                          <div className="card-education__row-flex1">
                            <div className="card-edu-btn">
                              <Button
                                onClick={btnEditEdu(index)}
                                variant="link"
                                className="modal-link"
                              >
                                <Icon path={mdiPencil} size={0.8} />
                              </Button>
                              <Button
                                variant="link"
                                className="delete-link"
                                onClick={() => btnDelEdu(index)}
                              >
                                <Icon path={mdiDelete} size={0.8} />
                              </Button>
                            </div>
                          </div>
                        </CardCandidate>
                      ))}
                    </div>
                  ) : null}
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>Experience Information</Accordion.Header>
              <Accordion.Body>
                <div className="container-info">
                  {clickedExp === false ? (
                    <Button
                      onClick={() => btnAddExp(true)}
                      variant="link"
                      className="modal-link"
                    >
                      <Icon path={mdiPlusCircle} size={0.8} />
                      Add Work Experience
                    </Button>
                  ) : (
                    <div
                      className={
                        "add-edu-container" + (clickedExp ? " in" : "")
                      }
                    >
                      {/* 2 */}
                      <div className="container-bg-col-div">
                        <div className="container-form-div">
                          {/* onSubmit={btnSaveExp} */}
                          <Form noValidate onSubmit={btnSaveExp}>
                            {/* 2.1 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Job Title{" "}
                                <label className="validation-star">*</label>
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  name="txtjobtitle"
                                  type="text"
                                  defaultValue={expstate.txtjobtitle}
                                  isInvalid={!!errorsExp.txtjobtitle}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errorsExp.txtjobtitle}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                            {/* 2.2 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Job Function
                              </Form.Label>
                              <Col>
                                <Searchable
                                  name="ddljobfunction"
                                  isMulti={true}
                                  options={dataJobFunction}
                                  getOptionValue={(option) => option.value}
                                  getOptionLabel={(option) => option.value}
                                  onChange={handleJobFunc}
                                  defaultValue={dataJobFunction.filter((obj) =>
                                    expstate.ddljobfunction.includes(obj.value)
                                  )}
                                ></Searchable>
                              </Col>
                            </Form.Group>
                            {/* 2.3 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Company Name{" "}
                                <label className="validation-star">*</label>
                              </Form.Label>
                              <Col>
                                <Creatable
                                  onChange={handleCompanyName}
                                  options={clientList}
                                  value={companyValue}
                                  // isDisabled={disableGroup}
                                  isInvalid={!!errorsExp.txtcompanyname}
                                  errorText={errorsExp.txtcompanyname}
                                  // getOptionValue={(option) => option.name}
                                  // getOptionLabel={(option) =>
                                  //   option.name
                                  // }
                                />
                                {/* <Form.Control
                                  name="txtcompanyname"
                                  type="text"
                                  defaultValue={expstate.txtcompanyname}
                                  isInvalid={!!errorsExp.txtcompanyname}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errorsExp.txtcompanyname}
                                </Form.Control.Feedback> */}
                              </Col>
                            </Form.Group>
                            {/* 2.4 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Industry
                              </Form.Label>
                              <Col>
                                <Searchable
                                  name="ddlIndustry"
                                  isMulti={true}
                                  options={dataIndustry}
                                  getOptionValue={(option) => option.value}
                                  getOptionLabel={(option) => option.value}
                                  onChange={handleIndustry}
                                  defaultValue={dataIndustry.filter((obj) =>
                                    expstate.ddlIndustry.includes(obj.value)
                                  )}
                                ></Searchable>
                              </Col>
                            </Form.Group>
                            {/* 2.5 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Country{" "}
                                <label className="validation-star">*</label>
                              </Form.Label>
                              <Col>
                                <Searchable
                                  name="ddlCountry"
                                  isMulti={false}
                                  options={dataCountry}
                                  getOptionValue={(option) => option.value}
                                  getOptionLabel={(option) => option.value}
                                  isInvalid={!!errorsExp.ddlCountry}
                                  errorText={errorsExp.ddlCountry}
                                  defaultValue={expstate.ddlCountry}
                                ></Searchable>
                              </Col>
                            </Form.Group>
                            {checked === true ? (
                              <Form.Group as={Row} className="mb-3">
                                <Form.Label column lg={2}>
                                  Present
                                </Form.Label>
                                <Col>
                                  <Form.Select
                                    name="ddlpremonth"
                                    onChange={handlePreMonth}
                                    defaultValue={expstate.ddlpremonth}
                                    // isInvalid={!!errorsExp.ddlpremonth}
                                  >
                                    <option value="">Select</option>
                                    {monthList.map((month, index) => (
                                      <option key={index} value={month.value}>
                                        {month.label}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  {/* <Form.Control.Feedback type="invalid">
                                    {errorsExp.ddlpremonth}
                                  </Form.Control.Feedback> */}
                                </Col>
                                <Col>
                                  <Form.Select
                                    name="ddlpreyear"
                                    defaultValue={expstate.ddlpreyear}
                                    // isInvalid={!!errorsExp.ddlpreyear}
                                  >
                                    <option value="">Select</option>
                                    {dataYear.map((year, index) => (
                                      <option key={index} value={year.value}>
                                        {year.label}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  {/* <Form.Control.Feedback type="invalid">
                                    {errorsExp.ddlpreyear}
                                  </Form.Control.Feedback> */}
                                </Col>
                              </Form.Group>
                            ) : (
                              <Form.Group as={Row} className="mb-3">
                                <Form.Label column lg={2}>
                                  Joined Date
                                  {/* {" "}
                                  <label className="validation-star">*</label> */}
                                </Form.Label>
                                <Col>
                                  <Form.Select
                                    name="ddlstartmonth"
                                    onChange={handleStartMonth}
                                    defaultValue={expstate.ddlstartmonth}
                                    // isInvalid={!!errorsExp.ddlstartmonth}
                                  >
                                    <option value="">Select</option>
                                    {monthList.map((month, index) => (
                                      <option key={index} value={month.value}>
                                        {month.label}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  {/* <Form.Control.Feedback type="invalid">
                                    {errorsExp.ddlstartmonth}
                                  </Form.Control.Feedback> */}
                                </Col>
                                <Col>
                                  <Form.Select
                                    name="ddlstartyear"
                                    defaultValue={expstate.ddlstartyear}
                                    // isInvalid={!!errorsExp.ddlstartyear}
                                  >
                                    <option value="">Select</option>
                                    {dataYear.map((year, index) => (
                                      <option key={index} value={year.value}>
                                        {year.label}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  {/* <Form.Control.Feedback type="invalid">
                                    {errorsExp.ddlstartyear}
                                  </Form.Control.Feedback> */}
                                </Col>
                              </Form.Group>
                            )}
                            {checked === true ? (
                              ""
                            ) : (
                              <Form.Group as={Row} className="mb-3">
                                <Form.Label column lg={2}></Form.Label>
                                <Col>to</Col>
                              </Form.Group>
                            )}
                            {checked === true ? (
                              ""
                            ) : (
                              <Form.Group as={Row} className="mb-3">
                                <Form.Label column lg={2}>
                                  End Date
                                  {/* {" "}
                                  <label className="validation-star">*</label> */}
                                </Form.Label>
                                <Col>
                                  <Form.Select
                                    name="ddlendmonth"
                                    onChange={handleEndMonth}
                                    defaultValue={expstate.ddlendmonth}
                                    // isInvalid={!!errorsExp.ddlendmonth}
                                  >
                                    <option value="">Select</option>
                                    {monthList.map((month, index) => (
                                      <option key={index} value={month.value}>
                                        {month.label}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  {/* <Form.Control.Feedback type="invalid">
                                    {errorsExp.ddlendmonth}
                                  </Form.Control.Feedback> */}
                                </Col>
                                <Col>
                                  <Form.Select
                                    name="ddlendyear"
                                    defaultValue={expstate.ddlendyear}
                                    // isInvalid={!!errorsExp.ddlendyear}
                                  >
                                    <option value="">Select</option>
                                    {dataYear.map((year, index) => (
                                      <option key={index} value={year.value}>
                                        {year.label}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  {/* <Form.Control.Feedback type="invalid">
                                    {errorsExp.ddlendyear}
                                  </Form.Control.Feedback>
                                   */}
                                </Col>
                              </Form.Group>
                            )}

                            {/* 2.6 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}></Form.Label>
                              <Col>
                                <Form.Check
                                  type="checkbox"
                                  id="default-checkbox"
                                  label="Currently Working"
                                  defaultChecked={expstate.cbWorkingCurrent}
                                  onChange={(e) =>
                                    setChecked(e.currentTarget.checked)
                                  }
                                />
                              </Col>
                            </Form.Group>

                            {/* 2.7 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Office Number
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  name="txtofficephone"
                                  type="number"
                                  defaultValue={expstate.txtofficephone}
                                ></Form.Control>
                              </Col>
                            </Form.Group>

                            {/* 2.8 */}
                            {/* {checked === true ? ( */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Office Email
                                {/* {" "}
                                  <label className="validation-star">*</label> */}
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  name="txtofficeemail"
                                  type="text"
                                  defaultValue={expstate.txtofficeemail}
                                  // isInvalid={!!errorsExp.txtofficeemail}
                                ></Form.Control>
                                {/* <Form.Control.Feedback type="invalid">
                                    {errorsExp.txtofficeemail}
                                  </Form.Control.Feedback> */}
                              </Col>
                            </Form.Group>
                            {/*) : null}*/}

                            {/* 2.9 */}
                            {checked === true ? (
                              <Form.Group as={Row} className="mb-3">
                                <Form.Label column lg={2}>
                                  Notice Period
                                </Form.Label>
                                <Col>
                                  <Form.Select
                                    name="ddlperiod"
                                    defaultValue={expstate.ddlperiod}
                                  >
                                    <option value="">Select</option>
                                    {noticeList.map((notice, index) => (
                                      <option key={index} value={notice.label}>
                                        {notice.label}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Col>
                              </Form.Group>
                            ) : null}
                            {/* 2.10 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Job Responsibility
                              </Form.Label>
                              <Col>
                                <RichTextEditor
                                  name="txtjobresponsibility"
                                  htmlContent={expstate.txtjobresponsibility}
                                />
                              </Col>
                            </Form.Group>

                            {/* 2.11 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Salary
                                {/* {" "}
                                <label className="validation-star">*</label> */}
                              </Form.Label>
                              <Col>
                                <Form.Select
                                  name="ddlcurrency"
                                  defaultValue={expstate.ddlcurrency}
                                  isInvalid={!!errorsExp.ddlcurrency}
                                >
                                  <option value="">Select</option>
                                  {dataCurrency.map((currency, index) => (
                                    <option key={index} value={currency.value}>
                                      {currency.value}
                                    </option>
                                  ))}
                                </Form.Select>
                                {/* <Form.Control.Feedback type="invalid">
                                  {errorsExp.ddlcurrency}
                                </Form.Control.Feedback> */}
                              </Col>
                              <Col>
                                <TextFieldFormat
                                  name="txtsalary"
                                  value={salaryTxt.current}
                                  onChange={handleSalary}
                                  thousandSeparator={true}
                                ></TextFieldFormat>
                                {/* <Form.Control.Feedback type="invalid">
                                  {errorsExp.txtsalary}
                                </Form.Control.Feedback> */}
                              </Col>
                            </Form.Group>

                            {/* 2.12 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Allowance
                              </Form.Label>
                              <Col>
                                <CustomTable
                                  headers={headers}
                                  items={allowanceList}
                                ></CustomTable>
                              </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}></Form.Label>
                              <Col>
                                <Button
                                  onClick={btnAddNewAllowance}
                                  variant="primary"
                                >
                                  Add New Allowance
                                </Button>
                              </Col>
                            </Form.Group>

                            {/* 2.13 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Other Benefits
                              </Form.Label>
                              <Col>
                                <RichTextEditor
                                  name="txtothbenefit"
                                  htmlContent={expstate.txtothbenefit}
                                />
                              </Col>
                            </Form.Group>

                            {/* 2.14 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Reason for leaving
                              </Form.Label>
                              <Col>
                                <RichTextEditor
                                  name="txtreason"
                                  htmlContent={expstate.txtreason}
                                />
                              </Col>
                            </Form.Group>

                            {/* button Experience */}
                            <div className="row-btn-bottom">
                              <Button
                                onClick={() => btnAddExp(false)}
                                variant="secondary"
                              >
                                Cancel
                              </Button>
                              <Button type="submit" variant="primary">
                                Save
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="container-info">
                  {clickedExp === false ? (
                    <div className="column-card">
                      {experienceList.map((experience, index) => (
                        <div key={index}>
                          <div className="cdt-row">
                            <div className="col-5">
                              <div className="big-text">{experience.title}</div>
                              <div className="sub-text">
                                {experience.company_name}
                              </div>
                              {experience.current_working ? (
                                <div
                                  className="sub-text"
                                  style={{ fontWeight: 400 }}
                                >
                                  {experience.present_month
                                    ? toMonthName(experience.present_month)
                                    : ""}{" "}
                                  {experience.present_year
                                    ? experience.present_year
                                    : ""}{" "}
                                  - Current
                                </div>
                              ) : (
                                <div
                                  className="sub-text"
                                  style={{ fontWeight: 400 }}
                                >
                                  {experience.start_date_month
                                    ? toMonthName(experience.start_date_month)
                                    : ""}{" "}
                                  {experience.start_date_year
                                    ? experience.start_date_year
                                    : ""}{" "}
                                  -{" "}
                                  {experience.end_date_month
                                    ? toMonthName(experience.end_date_month)
                                    : ""}{" "}
                                  {experience.end_date_year
                                    ? experience.end_date_year
                                    : ""}
                                </div>
                              )}
                            </div>
                            <div className="col-6" style={{ display: "flex" }}>
                              <div className="col-11">
                                <div className="more-section-row">
                                  <div className="mini-text flex-left">
                                    Salary
                                  </div>
                                  <div className="sub-text flex-right">
                                    {experience.currency}{" "}
                                    {Number(experience.salary)
                                      .toFixed(2)
                                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                  </div>
                                </div>
                                <div className="more-section-row">
                                  <div className="mini-text flex-left">
                                    Direct Line
                                  </div>
                                  <div className="sub-text flex-right">
                                    {experience.office_number
                                      ? experience.office_number
                                      : "-"}
                                  </div>
                                </div>
                                <div className="more-section-row">
                                  <div className="mini-text flex-left">
                                    Office Email
                                  </div>
                                  <div className="sub-text flex-right">
                                    {experience.office_email
                                      ? experience.office_email
                                      : "-"}
                                  </div>
                                </div>

                                {experience.current_working ? (
                                  <div className="more-section-row">
                                    <div className="mini-text flex-left">
                                      Notice Period
                                    </div>
                                    <div className="sub-text flex-right">
                                      {experience.notice_period
                                        ? experience.notice_period
                                        : "-"}
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-1">
                                {/* {editExp === false ? null : ( */}
                                <div className="exp-button">
                                  <Button
                                    variant="link"
                                    className="modal-link"
                                    onClick={btnEditExp(index)}
                                  >
                                    <Icon path={mdiPencil} size={0.8} />
                                  </Button>
                                  <Button
                                    variant="link"
                                    className="delete-link"
                                    style={{ margin: 0 }}
                                    onClick={() => btnDelExp(index)}
                                  >
                                    <Icon path={mdiDelete} size={0.8} />
                                  </Button>
                                </div>
                                {/* )} */}
                              </div>
                            </div>
                          </div>

                          {/* Display if Read More button is clicked */}
                          {isReadMore.more === false &&
                          isReadMore.id === index ? (
                            <div className="cdt-row">
                              <div className="col-5">
                                {experience.reason_leaving ? (
                                  <>
                                    <div className="text">
                                      Reason for leaving
                                    </div>
                                    <div
                                      className="mini-text"
                                      dangerouslySetInnerHTML={{
                                        __html: experience.reason_leaving,
                                      }}
                                    ></div>
                                  </>
                                ) : null}

                                {experience.job_responsibility ? (
                                  <>
                                    <div className="text">
                                      Job Responsiblity
                                    </div>
                                    <div
                                      className="mini-text"
                                      dangerouslySetInnerHTML={{
                                        __html: experience.job_responsibility,
                                      }}
                                    ></div>
                                  </>
                                ) : null}
                              </div>
                              <div className="col-6">
                                <div className="col-11">
                                  {experience.allowance.length > 0 ? (
                                    <div className="more-section-row">
                                      <div className="mini-text flex-left">
                                        Allowance
                                      </div>
                                      <div style={{ flex: "1 1 60%" }}>
                                        {experience.allowance.map(
                                          (allow, index) => (
                                            <div
                                              className="sub-text flex-right"
                                              key={index}
                                            >
                                              {toAllowanceDesc(allow.earn)} -{" "}
                                              {allow.currency}{" "}
                                              {Number(allow.amount)
                                                .toFixed(2)
                                                .replace(
                                                  /\d(?=(\d{3})+\.)/g,
                                                  "$&,"
                                                )}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  ) : null}

                                  {experience.other_benefits ? (
                                    <div className="more-section-row">
                                      <div className="mini-text flex-left">
                                        Other Benefits
                                      </div>
                                      <div
                                        className="sub-text flex-right"
                                        dangerouslySetInnerHTML={{
                                          __html: experience.other_benefits
                                            ? experience.other_benefits
                                            : "-",
                                        }}
                                      ></div>
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-1"></div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {experience.other_benefits ||
                          experience.job_responsibility ||
                          experience.reason_leaving ||
                          experience.allowance.length > 0 ? (
                            <div className="cdt-row">
                              <div className="col-6"></div>
                              <div className="col-6">
                                <div className="col-11">
                                  <div className="more-section-row">
                                    <div className="mini-text flex-left">
                                      {""}
                                    </div>
                                    <div className="sub-text flex-right">
                                      {isReadMore.more === false &&
                                      isReadMore.id === index ? (
                                        <Button
                                          variant="link"
                                          className="read-btn"
                                          onClick={toggleBtnReadMore(
                                            index,
                                            true
                                          )}
                                        >
                                          Less details
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="link"
                                          className="read-btn"
                                          onClick={toggleBtnReadMore(
                                            index,
                                            false
                                          )}
                                        >
                                          More details
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-1"></div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>Reference Information</Accordion.Header>
              <Accordion.Body>
                <div className="container-info">
                  {clickedRef === false ? (
                    <Button
                      onClick={() => btnAddRef(true)}
                      variant="link"
                      className="modal-link"
                    >
                      <Icon path={mdiPlusCircle} size={0.8} />
                      Add Reference
                    </Button>
                  ) : (
                    <div
                      className={
                        "add-edu-container" + (clickedRef ? " in" : "")
                      }
                    >
                      {/* 3 */}
                      <div className="container-bg-col-div">
                        <div className="container-form-div">
                          <Form onSubmit={btnSaveRef}>
                            {/* 3.1 */}
                            <Row>
                              <Col xs={6}>
                                <Form.Group as={Row} className="mb-3">
                                  <Form.Label column lg={4}>
                                    Referee Name
                                  </Form.Label>
                                  <Col xs={8}>
                                    <Form.Control
                                      name="txtrefname"
                                      type="text"
                                      defaultValue={refstate.txtrefname}
                                    />
                                  </Col>
                                </Form.Group>
                              </Col>
                              <Col xs={6}>
                                <Form.Group as={Row} className="mb-3">
                                  <Form.Label column lg={4}>
                                    Referee Job Title
                                  </Form.Label>
                                  <Col>
                                    <Form.Control
                                      name="txtrefjobtitle"
                                      type="text"
                                      defaultValue={refstate.txtrefjobtitle}
                                    />
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>
                            {/* 3.2 */}
                            <Row>
                              <Col xs={6}>
                                <Form.Group as={Row} className="mb-3">
                                  <Form.Label column lg={4}>
                                    Email Address
                                  </Form.Label>
                                  <Col xs={8}>
                                    <Form.Control
                                      name="txtrefemail"
                                      type="text"
                                      defaultValue={refstate.txtrefemail}
                                    />
                                  </Col>
                                </Form.Group>
                              </Col>
                              <Col xs={6}>
                                <Form.Group as={Row} className="mb-3">
                                  <Form.Label column lg={4}>
                                    Contact Number
                                  </Form.Label>
                                  <Col>
                                    <Form.Control
                                      name="txtrefphone"
                                      type="number"
                                      defaultValue={refstate.txtrefphone}
                                    />
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>
                            {/* 3.3 */}
                            <Row>
                              <Col xs={6}>
                                <Form.Group as={Row} className="mb-3">
                                  <Form.Label column lg={4}>
                                    Current Company
                                  </Form.Label>
                                  <Col xs={8}>
                                    <Form.Control
                                      name="txtrefcompany"
                                      type="text"
                                      defaultValue={refstate.txtrefcompany}
                                    />
                                  </Col>
                                </Form.Group>
                              </Col>
                              <Col xs={6}>
                                <Form.Group as={Row} className="mb-3">
                                  <Form.Label column lg={4}>
                                    Relationship
                                  </Form.Label>
                                  <Col>
                                    <Form.Control
                                      name="txtrefrelationship"
                                      type="text"
                                      defaultValue={refstate.txtrefrelationship}
                                    />
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>

                            {/* button Reference */}
                            <div className="row-btn-bottom">
                              <Button
                                onClick={() => btnAddRef(false)}
                                variant="secondary"
                              >
                                Cancel
                              </Button>
                              <Button variant="primary" type="submit">
                                Save
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="container-info">
                  {clickedRef === false ? (
                    <div className="column-card">
                      {refereeList.map((referee, index) => (
                        <CardCandidate key={index}>
                          <div className="card-education__row-flex5">
                            <div className="card-referee">
                              <div>Referee {index + 1} </div>
                            </div>
                            <div className="card-text-style">
                              {referee.name}
                            </div>
                            <div className="card-text-style">
                              {referee.job_title}, {referee.current_company}
                            </div>
                            <div className="card-text-style">
                              {referee.email}
                            </div>
                            <div className="card-text-style">
                              {referee.phone}
                            </div>
                            {/* <div>{education.qualification}</div>
                                                        <div>{education.fromyear} - {education.toyear}</div> */}
                          </div>
                          <div className="card-education__row-flex1">
                            <div className="card-referee-button">
                              <Button
                                onClick={btnEditRef(index)}
                                variant="link"
                                className="modal-link"
                              >
                                <Icon path={mdiPencil} size={0.8} />
                              </Button>
                              <Button
                                onClick={() => btnDelRef(index)}
                                variant="link"
                                className="delete-link"
                              >
                                <Icon path={mdiDelete} size={0.8} />
                              </Button>
                            </div>
                          </div>
                        </CardCandidate>
                      ))}
                    </div>
                  ) : null}
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>Miscellaneous Information</Accordion.Header>
              <Accordion.Body>
                <div className="container-info">
                  {clickedMisc === false ? (
                    <Button
                      onClick={() => btnAddMisc(true)}
                      variant="link"
                      className="modal-link"
                    >
                      <Icon path={mdiPlusCircle} size={0.8} />
                      Add Miscellaneous
                    </Button>
                  ) : (
                    <div
                      className={
                        "add-edu-container" + (clickedMisc ? " in" : "")
                      }
                    >
                      {/* 4 */}
                      <div className="container-bg-col-div">
                        <div className="container-form-div">
                          <Form onSubmit={btnSaveMisc}>
                            {/* 4.1 */}
                            <Form.Group as={Row} className="mb-3">
                              <Col xs={12}>
                                {/* <Form.Control
                                  name="txtmiscdesc"
                                  as="textarea"
                                  rows={5}
                                  placeholder="Must be less than 500 characters"
                                  maxLength={characterLimit}
                                  defaultValue={miscstate.txtmiscdesc}
                                /> */}
                                <RichTextEditor
                                  name="txtmiscdesc"
                                  htmlContent={miscstate.txtmiscdesc}
                                />
                              </Col>
                            </Form.Group>

                            {/* button Reference */}
                            <div className="row-btn-bottom">
                              <Button
                                onClick={() => btnAddMisc(false)}
                                variant="secondary"
                              >
                                Cancel
                              </Button>
                              <Button variant="primary" type="submit">
                                Save
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* {console.log("sdsd", miscellaneousList)} */}
                <div className="container-info">
                  {clickedMisc === false ? (
                    <div className="column-card">
                      {miscellaneousList.map((miscel, index) => (
                        <CardCandidate key={index}>
                          <div className="card-education__row-flex5">
                            <div dangerouslySetInnerHTML={{__html: miscel.misc}}>
                              {/* {miscel.misc}  */}
                              {/* <Form.Group as={Row} className="mb-3">
                                <Col xs={12}>
                                  <Form.Control
                                    name="txtmiscview"
                                    as="textarea"
                                    className="read-num"
                                    rows={10}
                                    defaultValue={miscel.misc}
                                    readOnly
                                  />
                                </Col>
                              </Form.Group> */}
                            </div>
                          </div>
                          <div className="card-education__row-flex1">
                            <div className="card-referee-button">
                              <Button
                                onClick={btnEditMisc(index)}
                                icon={mdiPencil}
                                variant="link"
                                className="modal-link"
                              >
                                <Icon path={mdiPencil} size={0.8} />
                              </Button>
                              <Button
                                onClick={() => btnDelMisc(index)}
                                variant="link"
                                className="delete-link"
                              >
                                <Icon path={mdiDelete} size={0.8} />
                              </Button>
                            </div>
                          </div>
                          <div className="card-education__row-flex1"></div>
                        </CardCandidate>
                      ))}
                    </div>
                  ) : null}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>

        <div className="row-btn-center">
          <Button onClick={() => navigate(-1)} variant="secondary">
            Discard
          </Button>
          <Button variant="primary" type="submit" form="basic-form">
            Save Candidate
          </Button>
        </div>

        {/* Modal Candidate Upload Resume */}
        <Modal
          show={showUpload}
          size="md"
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Body className="modal-center">
            <div className="modal-center-column">
              <div className="modal-center-icon">
                <Icon path={mdiCheckCircle} size={5} color="#42BA96" />
              </div>
              <div className="modal-center-icon">
                <div className="title-page-text">
                  Candidate has been created!
                </div>
              </div>
              <hr />
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  paddingBottom: "15px",
                  textAlign: "center",
                }}
              >
                Please upload a file of candidate's latest CV/Resume. <br /> You
                may upload now or later.
              </div>
              <div>
                <Form id="upload-resume-form" onSubmit={btnUploadResume}>
                  <Form.Group as={Row} className="mb-1">
                    <Col>
                      <Form.Control name="txtresume" type="file" />
                    </Col>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer> */}
          <div className="row-btn-center" style={{ paddingBottom: "20px" }}>
            <Button variant="secondary" onClick={btnUploadLater}>
              Upload Later
            </Button>
            <Button variant="primary" type="submit" form="upload-resume-form">
              Submit
            </Button>
          </div>
          {/* </Modal.Footer> */}
        </Modal>

        {/* Modal Allowance */}
        <Popup
          show={showOption}
          size="md"
          backdrop="static"
          keyboard={false}
          onHide={btnCloseOption}
          title="Add Allowance"
          content={
            <>
              <Form id="option-form" onSubmit={btnNewOption}>
                {/* 1 */}
                <Form.Group as={Row} className="mb-3">
                  <Form.Label>Allowance Description</Form.Label>
                  <Col>
                    <Form.Control
                      name="txtAllowDesc"
                      type="text"
                      // defaultValue={expState.txtjobtitle}
                      isInvalid={!!errorOpt.txtAllowDesc}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorOpt.txtAllowDesc}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Form>
            </>
          }
          button={
            <>
              <Button onClick={btnCloseOption} variant="secondary">
                Cancel
              </Button>
              <Button type="submit" variant="primary" form="option-form">
                Save
              </Button>
            </>
          }
        />
      </div>
    </>
  );
}

export default AddCandidate;
