import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button, Tab, Nav, Card } from "react-bootstrap";
import Api from "../../../core/services/api.service";
import AuthAbilities from "../Auth/AuthAbilities";

import moment from "moment";
import SwalAlert from "../../../components/Swal/SwalAlert";

import Icon from "@mdi/react";
import { mdiFileDocumentMultiple, mdiAccountGroup } from "@mdi/js";

import BreadCrumb from "../../../components/Breadcrumb/BreadCrumb";
import DropDown from "../../../components/DropDown/DropDown";

import { TabCandidates } from "../../../components/Tab/TabCandidates";
import { TabJobDescription } from "../../../components/Tab/TabJobDescription";
import { TabNotes } from "../../../components/Tab/TabNotes";
import TabCompetency from "../../../components/Tab/TabCompetency";
import { TabStatistics } from "../../../components/Tab/TabStatistics";
// import { TabProgress } from "../../../components/Tab/TabProgress";
// import { TabFileAssignments } from "../../../components/Tab/TabFileAssignments";
// import TabParticipant from "../../../components/Tab/TabParticipant";
// import TabTraining from "../../../components/Tab/TabTraining";
// import TabForm from "../../../components/Tab/TabForm";
// import TabCertificate from "../../../components/Tab/TabCertificate";

import { statusAssignList } from "./dataList";

import { storeIndAssignTab } from "../../../store/actions/tabAssignSlice";
import { getClientDetails } from "../../../store/actions/clientSlice";
import { getAssignmentDetails } from "../../../store/actions/assignmentSlice";
import { storeTitle } from "../../../store/actions/headerTitleSlice";
import { getListStatistics } from "../../../store/actions/statisticsSlice";

const ViewAssignment = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const customSwal = SwalAlert();
  const abilities = AuthAbilities();

  const { indAsgnTab } = useSelector((state) => state.tabAssignment);
  const { clientDetails } = useSelector((state) => state.client);
  const { assignDetails, assignCountry, assignStaff } = useSelector(
    (state) => state.assignment
  );

  const [status, setStatus] = useState("");
  // const [setTxtStatus] = useState("");

  // const [crumbs, setCrumbs] = ueStte([]);

  const selectedCrumb = (crumb) => {
    // console.log("crumb", crumb)
    if (crumb === "Clients") {
      navigate("/clients/listActiveC");
    }else if (crumb === "Potential Clients") {
      navigate("/clients/listPotentialC");
    }
    else if (crumb === clientDetails.name) {
      if(params.page === "active"){
        navigate("/clients/viewActiveC/" + params.id);
      }else{
        navigate("/clients/view-potential-client/" + params.id);
      }
      
    }
    // navigate(-1);
  };

  const changeStatus = (event) => {
    let statusChange = event.target.value;
    // setTxtStatus(statusChage);

    setStatus("primary-color");

    customSwal
      .fire({
        title:
          "Do you want to update status to " +
          statusChange.replace(/_/g, " ") +
          "?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.post(
            "client/" +
              params.id +
              "/assignment/" +
              params.idassign +
              "/updateStatus",
            {
              status: statusChange,
            }
          )
            .then((response) => {
              dispatch(getAssignmentDetails(params.id, params.idassign));

              customSwal.fire({
                title: "Status Updated Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("user/store", error);
            });
        } else {
          // getSelectedClient();
        }
      });
  };

  const viewProposal = (id) => () => {
    // console.log("id", id);
    navigate("/clients/edit-proposal/active/" + params.id + "/" + id);
  };

  const handleSelectTab = (key) => {
    dispatch(
      storeIndAssignTab({
        id: params.idassign,
        index: key,
      })
    );
  };

  useEffect(() => {
    dispatch(getClientDetails(params.id));
    dispatch(getAssignmentDetails(params.id, params.idassign));
    dispatch(getListStatistics(params.id, params.idassign));
    if(params.page === "active"){
      dispatch(storeTitle({ title: "Clients", icon: mdiAccountGroup }));
    }else{
      dispatch(storeTitle({ title: "Potential Clients", icon: mdiAccountGroup }));
    }
    

    setStatus("primary-color");
  }, [dispatch, params.id, params.idassign]);

  return (
    <div className="main-div list-cli">
      <div className="container-div both-side">
        <BreadCrumb
          crumbs={[params.page === 'active' ? "Clients" : "Potential Clients", clientDetails.name, assignDetails.title]}
          selected={selectedCrumb}
        ></BreadCrumb>
        {assignDetails.proposal_id ? (
          <Button
            variant="primary"
            onClick={viewProposal(assignDetails.proposal_id)}
            hidden={!abilities.includes("list proposal")}
          >
            <Icon path={mdiFileDocumentMultiple} color="#FFF" />
            <div>View Proposal</div>
          </Button>
        ) : (
          ""
        )}
      </div>

      {/* ES Screens */}
      <Card className="mb-3">
        <Card.Body>
          <div className="top-card">
            <div className="top-left-card">
              <div className="big-text">{assignDetails.title}</div>
              <div>&bull;</div>
              <div className="mini-text no-mb">
                {assignCountry ? assignCountry : "-"}
              </div>
              <div>&bull;</div>
              <div className="mini-text no-mb">
                {moment(assignDetails.created_at).format("DD MMMM YYYY")}
              </div>
            </div>
            <div className="top-right-card">
              <div className="mini-text no-mb">
                Assign to:{" "}
                <span className="text">
                  {assignStaff.map((obj, index) => {
                    let nameStaff = obj.first_name + " " + obj.last_name + ",";
                    return nameStaff;
                  })}
                </span>
              </div>
              <DropDown
                options={statusAssignList}
                styleDrop="select--status--style"
                sizeDrop="select--medium"
                colorDrop={status}
                value={assignDetails.status}
                onChange={changeStatus}
              />
            </div>
          </div>
        </Card.Body>
      </Card>

      {/* ES Screens */}
      <Tab.Container
        defaultActiveKey={indAsgnTab ? indAsgnTab.index : "1"}
        transition={true}
        onSelect={handleSelectTab}
      >
        <div className="nav-container scrollable">
          <Nav variant="tabs" justify>
            <Nav.Item>
              <Nav.Link eventKey="1">Candidates</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="2">Critical Competencies</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="3">Role Notes</Nav.Link>
            </Nav.Item>
            <Nav.Item hidden={!abilities.includes("tab attachment")}>
              <Nav.Link eventKey="4">Attachments</Nav.Link>
            </Nav.Item>
            <Nav.Item hidden={!abilities.includes("tab statistics")}>
              <Nav.Link eventKey="5">Statistics</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>

        <Tab.Content style={{ background: "none" }}>
          <Tab.Pane eventKey="1">
            <TabCandidates></TabCandidates>
          </Tab.Pane>
          <Tab.Pane eventKey="2">
            <TabCompetency></TabCompetency>
          </Tab.Pane>
          <Tab.Pane eventKey="3">
            <TabNotes></TabNotes>
          </Tab.Pane>
          <Tab.Pane eventKey="4">
            <TabJobDescription page={params.page}></TabJobDescription>
          </Tab.Pane>
          <Tab.Pane eventKey="5">
            <TabStatistics></TabStatistics>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default ViewAssignment;
