import React, { useState } from "react";
import SideMenu from "./SideMenu";
import Header from "./Header";
import styled from "styled-components";

const NavBarContainer = styled.div`
  /* width: 260px; */
  /* position: fixed; */
  z-index: 999;
  display: flex;
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
  margin-left: ${({ sidebar }) => (sidebar ? "62px" : "300px")};
  width: ${({ sidebar }) =>
    sidebar ? "calc(100vw - 300px)" : "calc(100vw - 62px)"};
  transition: 350ms;
`;

// const HeaderContainer = styled.div`
//   position: sticky;
//   height: 70px;
//   top: 0;
//   background-color: red;
// `;

const InfoContainer = styled.div`
  /* margin-top: -90px; */
  /* padding: 20px 10px 0px; */
  transition: 0.3s;
  height: 100%;
  background: var(--menu-background);
`;

function ClientLayout(props) {
  const [getHeader, setHeader] = React.useState("");
  const [getIconH, setIconH] = React.useState("");
  const [sidebar, setSidebar] = useState(false);

  const toggleSidebarIn = () => {
    if (sidebar) {
      // console.log(" besar");
      document.getElementById("content").style.width = "calc(100vw - 300px)";
      document.getElementById("content").style.marginLeft = "300px";
    }
  };

  const toggleSidebarOut = () => {
    if (sidebar) {
      // console.log(" kecik");
      document.getElementById("content").style.width = "calc(100vw - 62px)";
      document.getElementById("content").style.marginLeft = "62px";
    }
  };

  return (
    <>
      <div className="main_container">
        <NavBarContainer
          onMouseOver={toggleSidebarIn}
          onMouseOut={toggleSidebarOut}
          className="navbar-def"
        >
          <SideMenu
            setHeader={setHeader}
            setIconH={setIconH}
            sidebar={sidebar}
            setSidebar={setSidebar}
          />
        </NavBarContainer>

        <ContentContainer id="content">
          <Header
            title={getHeader}
            icon={getIconH}
            setHeader={setHeader}
            setIconH={setIconH}
          />
          <InfoContainer>
            <main>{props.children}</main>
          </InfoContainer>
        </ContentContainer>
      </div>
    </>
  );
}

export default ClientLayout;
