import React, { useEffect, useState, useCallback } from "react";
import { Card, Button, Dropdown, Form, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Api from "../../core/services/api.service";

import moment from "moment";
import SwalAlert from "../Swal/SwalAlert";

import Icon from "@mdi/react";
import { mdiFilePlus, mdiPencil, mdiDotsHorizontal, mdiDelete } from "@mdi/js";

import CustomTable from "../Table/Table";
import Modal from "../Popup/ModalPopup";

function TabForm() {
  // const navigate = useNavigate();
  const params = useParams();
  const customSwal = SwalAlert();

  const [errorForm, setErrorForm] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [modeModal, setModeModal] = useState({
    title: null,
    button: null,
  });

  const [listFeedback, setListFeedback] = useState([]);
  const [listSurvey, setListSurvey] = useState([]);

  const [formState, setFormState] = useState({
    idform: "",
    ddlType: "",
    txtname: "",
    txtgoogle: "",
    txtresponseurl: "",
  });

  const header = [
    {
      Header: "Form Name",
      accessor: "name",
      minWidth: 100,
      width: 200,
    },
    {
      Header: "Created Date",
      accessor: (row) => `${row.created_at}`,
      minWidth: 100,
      width: 150,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="form-container-default">
            {moment(data.created_at).format("DD MMMM YYYY")}
          </div>
        );
      },
    },
    {
      Header: "Google Form URL",
      accessor: (row) => `${row.url_gform_edit}`,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <>
            {data.url_gform_edit ? (
              <div>
                <a href={data.url_gform_edit} target="_blank" rel="noreferrer">
                  {data.url_gform_edit}
                </a>
              </div>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      Header: "Response URL",
      accessor: (row) => `${row.url_gform_response}`,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <>
            {data.url_gform_response ? (
              <div>
                <a href={data.url_gform_response} target="_blank" rel="noreferrer">
                  {data.url_gform_response}
                </a>
              </div>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      Header: "",
      accessor: "actions",
      minWidth: 80,
      width: 80,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={btnEditForm(data)}>
                  <Icon path={mdiPencil} color="#467FD0" />
                  Edit Form
                </Dropdown.Item>
                <Dropdown.Item onClick={btnDeleteForm(data)}>
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete Form
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const btnOpenForm = () => {
    setShowForm(true);
    setErrorForm({});
    setFormState({
      idform: "new",
      ddlType: "",
      txtname: "",
      txtgoogle: "",
      txtresponseurl: "",
    });

    setModeModal({
      title: "Create Form",
      button: "Create",
    });
  };

  const btnEditForm = (object) => (e) => {
    setShowForm(true);
    setErrorForm({});

    setFormState({
      idform: object.id,
      ddlType: object.type,
      txtname: object.name,
      txtgoogle: object.url_gform_edit,
      txtresponseurl: object.url_gform_response,
    });

    setModeModal({
      title: "Update Form",
      button: "Update",
    });
  };

  const btnCloseForm = () => {
    setShowForm(false);
  };

  const formError = (object) => {
    const newErrors = {};

    if (!object.ddlType || object.ddlType === "")
      newErrors.ddlType = "Choose form type!";

    if (!object.txtname || object.txtname === "")
      newErrors.txtname = "Fill in the blank!";

    if (!object.txtgoogle || object.txtgoogle === "")
      newErrors.txtgoogle = "Fill in the blank!";

    if (!object.txtresponseurl || object.txtresponseurl === "")
      newErrors.txtresponseurl = "Fill in the blank!";

    return newErrors;
  };

  const btnSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);

    const dtForm = Object.fromEntries(data.entries());

    const newErrors = formError(dtForm);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    }else{
      setErrorForm({});

      if(formState.idform === "new"){
        customSwal
        .fire({
          title: "Do you want to add new form?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Api.post("client/" + params.id + "/assignment/" + params.idassign + "/tmdm/addSurveyFeedback",
              {
                type: dtForm.ddlType,
                name: dtForm.txtname,
                url_gform_edit: dtForm.txtgoogle,
                url_gform_response: dtForm.txtresponseurl,
              }
            )
              .then((response) => {
                //   let data = response.data;

                setShowForm(false);
                getListForm();
                customSwal.fire({
                  title: "Saved Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("error form feedback", error);
              });
          }
        });
      }else{
        customSwal
        .fire({
          title: "Do you want to update form?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {

            Api.patch("client/" + params.id + "/assignment/" + params.idassign + "/tmdm/updateSurveyFeedback/" + formState.idform,
              {
                type: dtForm.ddlType,
                name: dtForm.txtname,
                url_gform_edit: dtForm.txtgoogle,
                url_gform_response: dtForm.txtresponseurl,
              }
            )
              .then((response) => {
                //   let data = response.data;
                setShowForm(false);
                getListForm();
                customSwal.fire({
                  title: "Saved Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("error update form feedback", error);
              });
          }
        });
      }
      
    }
  };

  const btnDeleteForm = (object) => (e) =>{
    customSwal
      .fire({
        title: "Do you want to delete this form?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("client/" + params.id + "/assignment/" + params.idassign + "/tmdm/deleteSurveyFeedback/" + object.id)
            .then((response) => {
              getListForm();
              customSwal.fire({
                title: "Deleted Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error delete feedback", error);
            });
        }
      });
  };

  const getListForm = useCallback(() => {
    Api.get("client/" + params.id + "/assignment/" + params.idassign + "/tmdm/listSurveyFeedback")
      .then((response) => {
        let data = response.data;

        let dtfeedback = data.filter((form) =>form.type === "feedback");
        setListFeedback(dtfeedback)

        let dtsurvey = data.filter((form) =>form.type === "survey");
        setListSurvey(dtsurvey);
       
      })
      .catch((error) => {
        console.log("error getListForm", error);
      });
  }, [params]);

  useEffect(() => {
    getListForm();
  }, [getListForm]);

  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <div className="row-div">
          <div className="left-div"></div>
          <div className="right-div">
            <Button variant="primary" onClick={btnOpenForm}>
              <Icon path={mdiFilePlus} />
              <div>Create Form</div>
            </Button>
          </div>
        </div>
      </div>

      <Card style={{ marginBottom: "20px" }}>
        <Card.Body>
          <div className="row-div">
            <div className="left-div">
              <div className="title-text report">Survey Form</div>
            </div>
          </div>

          <CustomTable
            headers={header}
            items={listSurvey}
            paginate={listSurvey.length}
            maxRows={15}
          ></CustomTable>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <div className="row-div">
            <div className="left-div">
              <div className="title-text report">Feedback Form</div>
            </div>
          </div>

          <CustomTable
            headers={header}
            items={listFeedback}
            paginate={listFeedback.length}
            maxRows={15}
          ></CustomTable>
        </Card.Body>
      </Card>

      {/* Modal */}
      <Modal
        show={showForm}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseForm}
        title={modeModal.title}
        content={
          <>
            <Form id="feedback-form" onSubmit={btnSubmit}>
              {/* 1 */}
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg={3}>
                  Form Type <label className="validation-star">*</label>
                </Form.Label>
                <Col>
                  <Form.Select 
                    name="ddlType" 
                    isInvalid={!!errorForm.ddlType}
                    defaultValue={formState.ddlType}
                  >
                    <option value="">Select Form</option>
                    <option value="survey">Survey Form</option>
                    <option value="feedback">Feedback Form</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errorForm.ddlType}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              {/* 2 */}
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg={3}>
                  Form Name <label className="validation-star">*</label>
                </Form.Label>
                <Col>
                  <Form.Control
                    name="txtname"
                    type="text"
                    defaultValue={formState.txtname}
                    isInvalid={!!errorForm.txtname}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorForm.txtname}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              {/* 3 */}
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg={3}>
                  Google Form URL <label className="validation-star">*</label>
                </Form.Label>
                <Col>
                  <Form.Control
                    name="txtgoogle"
                    type="text"
                    defaultValue={formState.txtgoogle}
                    isInvalid={!!errorForm.txtgoogle}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorForm.txtgoogle}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              {/* 4 */}
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg={3}>
                  Response URL <label className="validation-star">*</label>
                </Form.Label>
                <Col>
                  <Form.Control
                    name="txtresponseurl"
                    type="text"
                    defaultValue={formState.txtresponseurl}
                    isInvalid={!!errorForm.txtresponseurl}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorForm.txtresponseurl}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseForm}>
              Cancel
            </Button>
            <Button type="submit" form="feedback-form" variant="primary">
              {modeModal.button}
            </Button>
          </>
        }
      ></Modal>
    </>
  );
}

export default TabForm;
