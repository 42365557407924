import React from "react";
import { Route, Routes } from "react-router-dom";

import AdminLogin from "../../pages/Admin/Auth/Login/Admin";
import ClientLogin from "../../pages/Admin/Auth/Login/Client";
import CandidateLogin from "../../pages/Admin/Auth/Login/Candidate";
import ForgotPassword from "../../pages/Admin/Auth/Password/ForgotPassword";
import CandidateRegister from "../../pages/Admin/Auth/Register/Candidate";
import ClientRegister from "../../pages/Admin/Auth/Register/Client";
import NewPassword from "../../pages/Admin/Auth/Password/NewPassword";
import Verify from "../../pages/Admin/Auth/Register/Verify";
import ViewPolicy from "../../pages/Admin/Auth/Register/ViewPolicy";

import Error from "../../pages/Admin/Error";
import Access from "../../pages/Access";

const RouteAuth = () => {

  return (
    <Routes>
      <Route path="/admin" element={<AdminLogin />} />
      <Route path="/forgot-password/:type" element={<ForgotPassword />} />
      <Route path="/new-password/:id" element={<NewPassword />} />
      <Route path="/verified/:id" element={<Verify />} />

      <Route path="/client-register" element={<ClientRegister />} />
      <Route path="/" element={<ClientLogin />} />

      {/* <Route
          path="/"
          element={<Navigate replace to="/client-login" /> }
      /> */}

      <Route path="/candidate-login" element={<CandidateLogin />} />
      <Route path="/candidate-register" element={<CandidateRegister />} />

      <Route path="/view-policy" element={<ViewPolicy />} />

      <Route path="*" element={<Error />} />
      <Route path="/no-access" element={<Access />} />
    </Routes>
  );
};


export default RouteAuth;
