import React from "react";
import "./DropDown.css";

const SIZES = ["select--candidate", "select--large", "select--medium", "select--small"];
const STYLE = ["select--default--style", "select--status--style"];
const COLOR = ["default-color", "primary-color", "error-color"];

function DropDown({
  name,
  value,
  defaultValue,
  options,
  sizeDrop,
  styleDrop,
  colorDrop,
  onChange,
}) {
  // const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];

  const checkInputSize = SIZES.includes(sizeDrop) ? sizeDrop : SIZES[2];
  const checkDropStyle = STYLE.includes(styleDrop) ? styleDrop : STYLE[0];
  const checkColor = COLOR.includes(colorDrop) ? colorDrop : COLOR[0];

  return (
    <div className="dd-div">
      <select
        name={name}
        value={value}
        defaultValue={defaultValue}
        className={`select ${checkInputSize} ${checkDropStyle} ${checkColor}`}
        onChange={onChange}
      >
        {options.map((option) => (
          <option className="rang" key={option.code} value={option.code}>
            {option.value}
          </option>
        ))}
      </select>

      {/* <input 
        type={type}
        placeholder={placeholder}
        className={`input ${checkInputSize}`} 
        name={name}
    /> */}
    </div>
  );
}

export default DropDown;
