import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Col, Row, Card } from "react-bootstrap";

import SwalAlert from "../../../components/Swal/SwalAlert";
import Api from "../../../core/services/api.service";

import Icon from "@mdi/react";
import { mdiArrowLeft, mdiAccountGroup } from "@mdi/js";

import BreadCrumb from "../../../components/Breadcrumb/BreadCrumb";
import RichTextEditor from "../../../components/RichTextArea/RichTextEditor";

import { storeTitle } from "../../../store/actions/headerTitleSlice";
import { getClientDetails } from "../../../store/actions/clientSlice";
import { getAssignmentDetails } from "../../../store/actions/assignmentSlice";

const ViewJD = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const customSwal = SwalAlert();

  const { clientDetails } = useSelector((state) => state.client);
  const { assignDetails } = useSelector((state) => state.assignment);

  const [selJD, setSelJD] = useState({});

  const selectedCrumb = (crumb) => {
    if(crumb === "Clients"){
      navigate("/clients/listActiveC");
    }else if(crumb === "Potential Clients"){
        navigate("/clients/listPotentialC");
    }else if(crumb === clientDetails.name){
      if(params.page === "active"){
        navigate("/clients/viewActiveC/" + params.id);
      }else{
        navigate("/clients/view-potential-client/" + params.id);
      }
    }else if(crumb === assignDetails.title){
        navigate("/clients/view-assignment/" + params.page + "/" + params.id + "/" + params.idassign)
    }
  };

  // Button Event
  const btnCancel = () => {
    navigate("/clients/view-assignment/" + params.id + "/" + params.idassign);
  };

  const btnUpdateJD = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dataJD = Object.fromEntries(data.entries());

    customSwal
      .fire({
        title: "Do you want to update Job Description?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.patch(
            "client/" +
              params.id +
              "/assignment/" +
              params.idassign +
              "/jd/" +
              params.idjd +
              "/update",
            {
              file_name: dataJD.txtFileName,
              job_title: dataJD.txtJobTitle,
              company_background: dataJD.txtCompanyBg,
              purpose_job: dataJD.txtPurposeJob,
              key_accountibility: dataJD.txtKeyaccess,
              skill_experience: dataJD.txtKnowledge,
              compensation: dataJD.txtCompensation,
              contact_person: dataJD.txtContact,
              status: "draft",
            }
          )
            .then((response) => {
              //   console.log("btnUpdateJD", response);

              navigate(
                "/clients/view-assignment/" + params.page  + "/" + params.id + "/" + params.idassign
              );

              customSwal.fire({
                title: "Job Description Updated Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("btnUpdateJD", error);
            });
        }
      });
  };

  // Function Evenet
  const getSelectedJD = useCallback(() => {
    Api.get(
      "client/" +
        params.id +
        "/assignment/" +
        params.idassign +
        "/jd/" +
        params.idjd
    )
      .then((response) => {
        let data = response.data;

        // console.log("data getSelectedJD", data);
        setSelJD(data);
      })
      .catch((error) => {
        console.log("getSelectedJD", error);
      });
  }, [params]);

  useEffect(() => {
    getSelectedJD();
    dispatch(getClientDetails(params.id));
    dispatch(getAssignmentDetails(params.id, params.idassign));
    dispatch(storeTitle({title: params.page === "active" ? "Clients" : "Potential Clients", icon: mdiAccountGroup}));
  }, [getSelectedJD, dispatch, params.id, params.idassign]);

  return (
    <>
      <div className="main-div create-props">
        <div className="top-title-div both-side">
          <BreadCrumb
            crumbs={[
              params.page === "active" ? "Clients" : "Potential Clients",
              clientDetails.name,
              assignDetails.title,
              "Edit Job Description",
            ]}
            selected={selectedCrumb}
          ></BreadCrumb>
          <Button variant="secondary" onClick={() => navigate(-1)}>
            <Icon path={mdiArrowLeft} color="#212529" />
            Back
          </Button>
        </div>
        <Card>
          <Card.Body>
            <Form id="update-jd-form" onSubmit={btnUpdateJD}>
              {/* 1 */}
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg={2}>
                  File name
                </Form.Label>
                <Col>
                  <Form.Control
                    name="txtFileName"
                    defaultValue={selJD.file_name}
                    type="text"
                  />
                </Col>
              </Form.Group>
              {/* 2 */}
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg={2}>
                  Job title
                </Form.Label>
                <Col>
                  <Form.Control
                    name="txtJobTitle"
                    defaultValue={selJD.job_title}
                    type="text"
                  />
                </Col>
              </Form.Group>
              {/* 3 */}
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg={2}>
                  Company background
                </Form.Label>
                <Col>
                  <RichTextEditor
                    name="txtCompanyBg"
                    htmlContent={selJD.company_background}
                  />
                </Col>
              </Form.Group>
              {/* 4 */}
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg={2}>
                  Purpose of the job
                </Form.Label>
                <Col>
                  <RichTextEditor
                    name="txtPurposeJob"
                    htmlContent={selJD.purpose_job}
                  />
                </Col>
              </Form.Group>
              {/* 5 */}
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg={2}>
                  Key accountabilities
                </Form.Label>
                <Col>
                  <RichTextEditor
                    name="txtKeyaccess"
                    htmlContent={selJD.key_accountibility}
                  />
                </Col>
              </Form.Group>
              {/* 6 */}
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg={2}>
                  Desired knowledge, skills and experience
                </Form.Label>
                <Col>
                  <RichTextEditor
                    name="txtKnowledge"
                    htmlContent={selJD.skill_experience}
                  />
                </Col>
              </Form.Group>
              {/* 7 */}
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg={2}>
                  Compensation
                </Form.Label>
                <Col>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    name="txtCompensation"
                    defaultValue={selJD.compensation}
                  />
                </Col>
              </Form.Group>
              {/* 8 */}
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg={2}>
                  Contact Person
                </Form.Label>
                <Col>
                  <RichTextEditor
                    name="txtContact"
                    htmlContent={selJD.contact_person}
                  />
                </Col>
              </Form.Group>
            </Form>

            <div className="row-btn-center">
              <Button variant="secondary" onClick={btnCancel}>
                Cancel
              </Button>

              <Button variant="primary" type="submit" form="update-jd-form">
                Update Job Description
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default ViewJD;
