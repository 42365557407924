import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button, Dropdown, Form, Row, Col, Card } from "react-bootstrap";
import Api from "../../core/services/api.service";
import Apiform from "../../core/services/apiform.service";

import AuthAbilities from "../../pages/Admin/Auth/AuthAbilities";
import SwalAlert from "../Swal/SwalAlert";
import moment from "moment";

import {
  mdiFilePlus,
  mdiCalendar,
  mdiDotsHorizontal,
  mdiDownload,
  mdiDelete,
  mdiUpload,
  mdiPencil,
} from "@mdi/js";
import Icon from "@mdi/react";

import Table from "../Table/Table";
import Modal from "../Popup/ModalPopup";
import { MeetingForm } from "../Report/Template/MeetingForm";

import { getClientMeeting } from "../../store/actions/clientSlice";

export const TabMeeting = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const { clientListMeeting } = useSelector((state) => state.client);
  const { dataReminder } = useSelector((state) => state.option);

  const abilities = AuthAbilities();
  const customSwal = SwalAlert();

  const [errorForm, setErrorForm] = useState({});
  const [errorUpload, setErrorUpload] = useState({});

  const [showUpload, setShowUpload] = useState(false);
  const [rowMeeting, setRowMeeting] = useState({});
  const [showReminder, setShowReminder] = useState(false);
  const [remindMeet, setRemindMeet] = useState({
    txtmeetName: "",
    ddlmeetDuration: "",
  });
  const [sentDateTime, setSentDateTime] = useState("");

  const btnCloseReminder = () => {
    setShowReminder(false);
  };

  const handleChangeReminder = (e) => {
    var sentDateTimeC = moment()
      .add(e.target.value, "minutes")
      .format("DD MMMM YYYY, hh:mm A");
    setSentDateTime("Notification will be sent to you at " + sentDateTimeC);
  };

  const btnReminder = (data) => (e) => {
    //
    setShowReminder(true);
    setRemindMeet({
      idmeet: data.id,
      txtmeetName: data.name,
      ddlmeetDuration: data.reminder,
    });

    if (!data.reminder || data.reminder === "") {
      setSentDateTime("");
    } else {
      var sentDateTimeC = moment()
        .add(data.reminder, "minutes")
        .format("DD MMMM YYYY, hh:mm A");
      setSentDateTime("Notification will be sent to you at " + sentDateTimeC);
    }

    setErrorUpload({});
  };

  const btnSaveReminder = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtRemind = Object.fromEntries(data.entries());

    const newErrors = formErrorRemind(dtRemind);

    if (Object.keys(newErrors).length > 0) {
      setErrorUpload(newErrors);
    } else {
      Api.post(
        "client/" +
          params.id +
          "/meeting/" +
          remindMeet.idmeet +
          "/addReminder",
        {
          reminder: dtRemind.ddlRemindOption,
        }
      )
        .then((response) => {
          // let data = response.data;

          dispatch(getClientMeeting(params.id));

          customSwal.fire({
            title: "Reminder Updated Successfully!",
            icon: "success",
            confirmButtonText: "Okay",
          });

          setShowReminder(false);
        })
        .catch((error) => {
          console.log("error update meeting reminder", error);
        });
    }
  };

  const formErrorRemind = (object) => {
    const newErrors = {};

    if (!object.ddlRemindOption || object.ddlRemindOption === "")
      newErrors.ddlRemindOption = "Fill in the blank!";

    return newErrors;
  };

  const headerMeeting = [
    {
      Header: "No",
      maxWidth: 50,
      minWidth: 10,
      width: 70,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Meeting Name",
      maxWidth: 700,
      minWidth: 100,
      width: 700,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{data.name}</div>;
      },
    },
    {
      Header: "Meeting Date",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{moment(data.date_meeting).format("DD MMMM YYYY")}</div>;
      },
    },
    {
      Header: "Meeting Time",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div>{moment(data.time_meeting, ["HH:mm"]).format("hh:mm A")}</div>
        );
      },
    },
    {
      Header: " ",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <Button
            variant="link"
            className="reminder-btn set-rem"
            onClick={btnReminder(data)}
          >
            <Icon path={mdiCalendar} />
            Set Reminder
          </Button>
        );
      },
    },
    {
      Header: " ",
      accessor: "actions",
      maxWidth: 100,
      minWidth: 10,
      width: 70,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={downloadMeeting(data)}>
                  <Icon path={mdiDownload} color="#467FD0" />
                  Download Meeting
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={btnEditMeeting(data.id)}
                  hidden={
                    props.page === "active"
                      ? !abilities.includes("edit meeting")
                      : !abilities.includes("edit meeting")
                  }
                >
                  <Icon path={mdiPencil} color="#467FD0" />
                  Edit Meeting
                </Dropdown.Item>
                {/* <Dropdown.Item
                  onClick={btnViewMeeting(data.id)}
                  hidden={
                    props.page === "active"
                      ? !abilities.includes("edit clients")
                      : !abilities.includes("edit potential")
                  }
                >
                  <Icon path={mdiPencil} color="#467FD0" />
                  View Meeting
                </Dropdown.Item> */}
                <Dropdown.Item
                  onClick={btnDelMeeting(data.id)}
                  hidden={
                    props.page === "active"
                      ? !abilities.includes("delete meeting")
                      : !abilities.includes("delete meeting")
                  }
                >
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete Meeting
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={btnUploadMeeting(data)}>
                  <Icon path={mdiUpload} color="#467FD0" />
                  Upload File
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const downloadMeeting = (data) => () => {
    // console.log("data", data);
    MeetingForm(data);
  };

  const createMeeting = () => {
    navigate("/clients/create-meeting/" + props.id);
  };

  const btnEditMeeting = (idrow) => () => {
    navigate(
      "/clients/edit-meeting/" + props.id + "/" + idrow
    );
  };

  // const btnViewMeeting = (idrow) => () => {
  //   navigate(
  //     "/clients/view-meeting/" + props.id + "/" + idrow
  //   );
  // };

  const btnDelMeeting = (idrow) => () => {
    // console.log(idrow);
    customSwal
      .fire({
        title: "Do you want to delete meeting?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("client/" + params.id + "/meeting/" + idrow)
            .then((response) => {
              let data = response.data;

              console.log("Response", data);

              dispatch(getClientMeeting(params.id));

              customSwal.fire({
                title: "Deleted Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error delete meeting", error);
            });
        }
      });
  };

  const btnCloseUpload = () => {
    setShowUpload(false);
  };

  const btnUploadMeeting = (data) => () => {
    setShowUpload(true);
    setRowMeeting(data);
    setErrorForm({});
  };

  const formErrorUpload = (object) => {
    const newErrors = {};

    if (!object.txtuploadfile.name || object.txtuploadfile.name === "")
      newErrors.txtuploadfile = "Fill in the blank!";

    return newErrors;
  };

  const btnSaveUpload = (idmeet) => (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtUpload = Object.fromEntries(data.entries());

    const newErrors = formErrorUpload(dtUpload);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      customSwal
        .fire({
          title: "Do you want to upload file attachment ?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();

            formData.append("file", dtUpload.txtuploadfile);

            Apiform.post(
              "client/" +
                params.id +
                "/meeting/" +
                idmeet +
                "/uploadFileMeeting",
              formData
            )
              .then((response) => {
                dispatch(getClientMeeting(params.id));

                setShowUpload(false);
                customSwal.fire({
                  title: "File Uploaded Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("error btnSaveUpload", error);
              });
          }
        });
    }
  };

  // Load Meeting
  useEffect(() => {
    dispatch(getClientMeeting(params.id));
    // getListMeeting();
  }, [dispatch, params.id]);

  return (
    <>
      <Card>
        <Card.Body>
          <div className="row-div">
            <div className="left-div"></div>
            <div className="right-div">
              <Button
                variant="link"
                className="modal-link"
                onClick={createMeeting}
                hidden={
                  props.page === "active"
                    ? !abilities.includes("add meeting")
                    : !abilities.includes("add meeting")
                }
              >
                <Icon path={mdiFilePlus} />
                Add Meeting
              </Button>
            </div>
          </div>

          <Table
            headers={headerMeeting}
            items={clientListMeeting}
            paginate={clientListMeeting.length}
            maxRows={15}
          ></Table>
        </Card.Body>
      </Card>

      {/* Modal upload meeting attachment */}
      <Modal
        show={showUpload}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseUpload}
        title="Upload File"
        content={
          <>
            <Form id="upload-form" onSubmit={btnSaveUpload(rowMeeting.id)}>
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label className="col-3">Meeting Name</Form.Label>
                  <div className="col-9" style={{ display: "inline-flex" }}>
                    {rowMeeting.name}
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label className="col-3">File Upload</Form.Label>
                  <div className="col-9" style={{ display: "inline-flex" }}>
                    <Form.Control
                      name="txtuploadfile"
                      type="file"
                      accept=".pdf,.docx"
                      isInvalid={!!errorForm.txtuploadfile}
                    />
                  </div>
                </Form.Group>
              </Row>
              {/* <Form.Group as={Row} className="mb-1">
                <Form.Control
                  type="file"
                  name="txtuploadfile"
                  accept=".pdf,.docx"
                />
                <Form.Text className="text-muted">
                  Allowed file types: .pdf, .docx
                </Form.Text>
              </Form.Group> */}
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseUpload}>
              Cancel
            </Button>
            <Button type="submit" form="upload-form" variant="primary">
              Upload
            </Button>
          </>
        }
      ></Modal>

      {/* Modal set reminder */}
      <Modal
        show={showReminder}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseReminder}
        title="Set Reminder"
        content={
          <>
            <Form id="remind-form" onSubmit={btnSaveReminder}>
              <Row>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="col-3">Meeting Name</Form.Label>
                  <Col sm="9">{remindMeet.txtmeetName}</Col>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="col-3">Reminder Options</Form.Label>
                  <Col sm="9">
                    <Form.Select
                      name="ddlRemindOption"
                      isInvalid={!!errorUpload.ddlRemindOption}
                      defaultValue={remindMeet.ddlmeetDuration}
                      onChange={handleChangeReminder}
                    >
                      <option value="">Select</option>
                      {dataReminder.map((remind, index) => (
                        <option key={index} value={remind.value}>
                          {remind.label}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errorUpload.ddlRemindOption}
                    </Form.Control.Feedback>
                    <Form.Text muted>{sentDateTime}</Form.Text>
                  </Col>
                </Form.Group>
              </Row>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseReminder}>
              Cancel
            </Button>
            <Button type="submit" variant="primary" form="remind-form">
              Set Reminder
            </Button>
          </>
        }
      ></Modal>
    </>
  );
};

// function decodeHTMLEntities(text) {
//   var txt = document.createElement('textrea');
//   txt.innerText  = text;
//   return txt.value;
// }
