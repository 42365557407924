import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, Nav, Button, Spinner } from "react-bootstrap";
import AuthAbilities from "../../Auth/AuthAbilities";

import Icon from "@mdi/react";
import { mdiArrowLeft, mdiAccountSearch } from "@mdi/js";

import "../../../../App.css";
import BreadCrumb from "../../../../components/Breadcrumb/BreadCrumb";

import { TabResume } from "../../../../components/Tab/TabResume";
import { TabDocuments } from "../../../../components/Tab/TabDocuments";
import { TabNoteCandidate } from "../../../../components/Tab/TabNoteCandidate";
import { TabAssignCandidate } from "../../../../components/Tab/TabAssignCandidate";
import { TabViewCandidate } from "../../../../components/Tab/TabViewCandidate";

import { getCandidateDetail } from "../../../../store/actions/candidateSlice";
import { storeTitle } from "../../../../store/actions/headerTitleSlice";
import { getListYears } from "../../../../store/actions/optionSlice";
import { indCandTab } from "../../../../store/actions/tabActiveSlice";

function ViewCandidate() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const abilities = AuthAbilities();

  const { candidateDetails } = useSelector((state) => state.candidate);
  const { candTab } = useSelector((state) => state.tabActive);

  const [crumbs, setCrumbs] = useState([]);

  const selectedCrumb = (crumb) => {
    if (crumb === "List of Candidates") {
      navigate("/candidates/listCandidates");
    }
  };

  const handleSelectTab = (key) => {
    // console.log("keyTab", key)
    dispatch(
      indCandTab({
        id: params.id,
        index: key,
      })
    );
  };

  const getSelectedCandidate = useCallback(() => {
    dispatch(getCandidateDetail(params.id));
  }, [params, dispatch]);

  useEffect(() => {
    getSelectedCandidate();

    dispatch(getListYears());

    dispatch(
      storeTitle({ title: "List of Candidates", icon: mdiAccountSearch })
    );
    const name = candidateDetails.last_name
      ? candidateDetails.first_name + " " + candidateDetails.last_name
      : candidateDetails.first_name;
    setCrumbs(["List of Candidates", name]);
  }, [
    getSelectedCandidate,
    candidateDetails.first_name,
    candidateDetails.last_name,
    dispatch,
  ]);

  return (
    <>
      {Object.keys(candidateDetails).length === 0 ? 
      <div id="main-overlay" className="main-overlay">
        <Spinner animation="border" variant="light" />
      </div>:""}
      <div className="main-div list-cli">
        <div className="container-div both-side">
          <BreadCrumb crumbs={crumbs} selected={selectedCrumb}></BreadCrumb>
          <Button variant="secondary" onClick={() => navigate(-1)}>
            <Icon path={mdiArrowLeft} />
            Back
          </Button>
        </div>

        <div className="top-title-div">
          <Tab.Container
            defaultActiveKey={candTab ? candTab.index : "1"}
            transition={true}
            onSelect={handleSelectTab}
          >
            {!abilities.includes("tab resume") &&
            !abilities.includes("tab document") &&
            !abilities.includes("tab note") &&
            !abilities.includes("tab assignment") ? null : (
              <div className="nav-container scrollable">
                <Nav variant="tabs" justify>
                  <Nav.Item>
                    <Nav.Link eventKey="1">Profile Details</Nav.Link>
                  </Nav.Item>
                  <Nav.Item hidden={!abilities.includes("tab resume")}>
                    <Nav.Link eventKey="2">Resume</Nav.Link>
                  </Nav.Item>
                  <Nav.Item hidden={!abilities.includes("tab document")}>
                    <Nav.Link eventKey="3">Documents & Attachments</Nav.Link>
                  </Nav.Item>
                  <Nav.Item hidden={!abilities.includes("tab note")}>
                    <Nav.Link eventKey="4">Notes</Nav.Link>
                  </Nav.Item>
                  <Nav.Item hidden={!abilities.includes("tab assignment")}>
                    <Nav.Link eventKey="5">Assignments</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            )}

            <Tab.Content style={{ background: "none" }}>
              <Tab.Pane eventKey="1">
                <TabViewCandidate></TabViewCandidate>
              </Tab.Pane>
              <Tab.Pane eventKey="2">
                <TabResume></TabResume>
              </Tab.Pane>
              <Tab.Pane eventKey="3">
                <TabDocuments></TabDocuments>
              </Tab.Pane>
              <Tab.Pane eventKey="4">
                <TabNoteCandidate></TabNoteCandidate>
              </Tab.Pane>
              <Tab.Pane eventKey="5">
                <TabAssignCandidate></TabAssignCandidate>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </>
  );
}

export default ViewCandidate;
