import React, { useState, useRef } from "react";
import { Accordion, Form, Row, Button, Col, Card } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Apiform from "../../../core/services/apiform.service";
import Api from "../../../core/services/api.service";

import SwalAlert from "../../Swal/SwalAlert";
import FileSaver from "file-saver";

import { mdiFile, mdiPlusCircle, mdiPencil, mdiDelete } from "@mdi/js";
import Icon from "@mdi/react";

import Searchable from "../../DropDown/SearchableSelect";
import ProfileImage from "../../../assets/no-profile-photo.jpg";
import CardCandidate from "../../Card/CardCandidate";
import RichTextEditor from "../../RichTextArea/RichTextEditor";
import TextFieldFormat from "../../TextField/TextFieldFormat";
import CustomTable from "../../Table/TableFunction";

import { monthList, noticeList } from "../../../pages/Admin/dataList";

import {
  getDataCandidate,
  getDataCandidate_languages,
  getCandidateDetail,
} from "../../../store/actions/candidateSlice";
import { getUserProfileHeaderClient } from "../../../store/actions/userProfileSlice";

export const TabPersonalDetailClient = () => {
  const dispatch = useDispatch();

  const customSwal = SwalAlert();

  const { user } = useSelector((state) => state.login);
  const {
    candidateDetails,
    candidateLanguages,
    candidateEducation,
    candidateExperience,
    candidateReference,
    candidateMiscellaneous,
  } = useSelector((state) => state.candidate);
  const {
    dataSalute,
    dataPhoneCode,
    dataLanguages,
    dataCountry,
    dataJobFunction,
    dataIndustry,
    dataCurrency,
    dataAllowance,
    dataYear,
  } = useSelector((state) => state.option);

  // Start Basic Information
  const [errorsBasic, setErrorsBasic] = useState({});

  const handleChangeCountry = (e) => {
    dispatch(
      getDataCandidate({
        citizenship_main: e.value,
      })
    );
  };

  const handleChangeLanguages = (e) => {
    const array_lang = e.map((x) => ({ value: x.value }));
    dispatch(getDataCandidate_languages(array_lang));
  };

  const btnSaveBasic = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtBasic = Object.fromEntries(data.entries());

    const newErrors = formErrorsBasic(candidateDetails, candidateLanguages);

    // console.log("dtBasic.txtprofile", dtBasic.txtprofile.name)

    if (Object.keys(newErrors).length > 0) {
      setErrorsBasic(newErrors);
    } else {
      customSwal
        .fire({
          title: "Do you want to update profile?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();

            formData.append("profile_pic", dtBasic.txtprofile);
            formData.append("salutation", candidateDetails.salutation);
            formData.append("first_name", candidateDetails.first_name);
            formData.append("last_name", candidateDetails.last_name);
            if (candidateLanguages.length > 0) {
              candidateLanguages.forEach((item, index) => {
                formData.append(`languages[${index}]`, item.value);
              });
            } else {
              formData.append(`languages`, []);
            }

            formData.append("mobile_no", candidateDetails.mobile_no);
            formData.append("mobile_no_code", candidateDetails.mobile_no_code);
            formData.append("comp_email", candidateDetails.comp_email);
            formData.append(
              "citizenship_main",
              candidateDetails.citizenship_main
            );
            formData.append("direct_line_no", candidateDetails.direct_line_no);
            formData.append("job_title", candidateDetails.job_title);
            // formData.append("linkedln_url", dtBasic.txtlinked);
            // formData.append("instagram_url", dtBasic.txtinsta);
            // formData.append("facebook_url", dtBasic.txtfacebook);
            // formData.append("website_url", dtBasic.txtothweb);

            Apiform.patch(
              "client/" + user.clientid + "/staff/" + user.sub,
              formData
            )
              .then((response) => {
                dispatch(getCandidateDetail(user.sub));
                dispatch(getUserProfileHeaderClient());
                customSwal.fire({
                  title: "Profile Updated Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
                setErrorsBasic({});
                // dtBasic.txtprofile.name ="";
              })
              .catch((error) => {
                console.log("error btnSaveBasic", error);
              });
          }
        });
    }
  };

  const btnResetBasic = () => {
    dispatch(getCandidateDetail(user.sub));
    setErrorsBasic({});
  };

  const formErrorsBasic = (object, ddllang) => {
    const newBasicErrors = {};

    const regEmail = /\S+@\S+\.\S+/;

    if (!object.salutation || object.salutation === "")
      newBasicErrors.salutation = "Fill in the blank!";

    if (!object.first_name || object.first_name === "")
      newBasicErrors.first_name = "Fill in the blank!";

    if (!object.last_name || object.last_name === "")
      newBasicErrors.last_name = "Fill in the blank!";

    if (!object.job_title || object.job_title === "")
      newBasicErrors.job_title = "Fill in the blank!";

    if (!object.comp_email || object.comp_email === "")
      newBasicErrors.comp_email = "Fill in the blank!";
    else if (!regEmail.test(object.comp_email))
      newBasicErrors.comp_email = "Invalid Email Address";

    if (!object.mobile_no_code || object.mobile_no_code === "")
      newBasicErrors.mobile_no_code = "Fill in the blank!";

    if (!object.mobile_no || object.mobile_no === "")
      newBasicErrors.mobile_no = "Fill in the blank!";

    if (!object.citizenship_main || object.citizenship_main === "")
      newBasicErrors.citizenship_main = "Fill in the blank!";

    if (ddllang.length === 0) newBasicErrors.ddllang = "Fill in the blank!";

    return newBasicErrors;
  };

  const btnCloseTabBasic = () => {
    // console.log("am")
    setClickedEdu(false);
    setClickedExp(false);
    setClickedRef(false);
    setClickedMis(false);
  };
  // End basic Information

  // Start Education
  const [clickedEdu, setClickedEdu] = useState(false);
  const [eduState] = useState({
    txtschool: "",
    txtdegree: "",
    txtfromyear: "",
    txttoyear: "",
    txtcertificate: "",
  });
  const [idEdu, setIdEdu] = useState("");
  const [errorsEdu, setErrorsEdu] = useState({});
  // const [yearList] = useState([]);

  // useEffect(() => {
  //   // setYearList([]);
  //   let currentYear = new Date().getFullYear();
  //   let earliestYear = 1970;

  //   while (currentYear >= earliestYear) {
  //     yearList.push({
  //       value: currentYear,
  //       label: currentYear,
  //     });

  //     currentYear -= 1;
  //   }
  //   // Run! Like go get some data from an API.
  // }, []);

  const btnDownload = (file) => (e) => {
    // console.log("file", file);
    FileSaver.saveAs(file);
  };

  const btnAddEdu = (index) => {
    setClickedEdu(index);
    setIdEdu("new");

    eduState.txtschool = "";
    eduState.txtdegree = "";
    eduState.txtfromyear = "";
    eduState.txttoyear = "";

    setErrorsEdu({});
  };

  const btnEditEdu = (id) => (e) => {
    const filteredEdu = candidateEducation.find((edu) => edu.id === id);

    eduState.txtschool = filteredEdu.name;
    eduState.txtdegree = filteredEdu.qualification;
    eduState.txtfromyear = filteredEdu.start_year;
    eduState.txttoyear = filteredEdu.end_year;

    setClickedEdu(true);
    setErrorsEdu({});
    setIdEdu(id);
  };

  const btnSaveEdu = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtEdu = Object.fromEntries(data.entries());

    const newErrors = formEduErrors(dtEdu);

    if (Object.keys(newErrors).length > 0) {
      setErrorsEdu(newErrors);
    } else {
      if (idEdu === "new") {
        customSwal
          .fire({
            title: "Do you want to save new education?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              const formData = new FormData();

              formData.append("name", dtEdu.txtschool);
              formData.append("qualification", dtEdu.txtdegree);
              formData.append("start_year", dtEdu.txtfromyear);
              formData.append("end_year", dtEdu.txttoyear);
              formData.append("certificate_attachment", dtEdu.txtcertificate);

              Apiform.post(
                "candidate/" + user.sub + "/education/store",
                formData
              )
                .then((response) => {
                  dispatch(getCandidateDetail(user.sub));
                  setClickedEdu(false);
                })
                .catch((error) => {
                  console.log("Error save eduction", error);
                });
            }
          });
      } else {
        customSwal
          .fire({
            title: "Do you want to update education?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              const formData = new FormData();

              formData.append("name", dtEdu.txtschool);
              formData.append("qualification", dtEdu.txtdegree);
              formData.append("start_year", dtEdu.txtfromyear);
              formData.append("end_year", dtEdu.txttoyear);
              formData.append("certificate_attachment", dtEdu.txtcertificate);

              Apiform.patch(
                "candidate/" + user.sub + "/education/" + idEdu + "/update",
                formData
              )
                .then((response) => {
                  dispatch(getCandidateDetail(user.sub));
                  setClickedEdu(false);
                })
                .catch((error) => {
                  console.log("Error update education", error);
                });
            }
          });
      }
    }
  };

  const btnDelEdu = (idedu) => (e) => {
    customSwal
      .fire({
        title: "Do you want to remove this education?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("candidate/" + user.sub + "/education/" + idedu)
            .then((response) => {
              dispatch(getCandidateDetail(user.sub));
            })
            .catch((error) => {
              console.log("error btnDelEdu", error);
            });
        }
      });
  };

  const formEduErrors = (object) => {
    const newErrors = {};

    if (!object.txtschool || object.txtschool === "")
      newErrors.txtschool = "Fill in the blank!";

    return newErrors;
  };

  const btnCloseTabEdu = () => {
    setClickedRef(false);
    setClickedMis(false);
    setClickedExp(false);
  };

  // End Education

  // Star Experience
  const [clickedExp, setClickedExp] = useState(false);
  const [expState] = useState({
    txtjobtitle: "",
    ddljobfunction: "",
    txtcompanyname: "",
    ddlIndustry: "",

    ddlCountry: {},
    cbWorkingCurrent: false,
    ddlpremonth: "",
    ddlpreyear: "",

    ddlstartmonth: "",
    ddlendmonth: "",
    ddlstartyear: "",
    ddlendyear: "",

    txtofficephone: "",
    txtofficeemail: "",
    ddlperiod: "",
    txtjobresponsibility: "",

    ddlcurrency: "",
    txtsalary: "",
    txtothbenefit: "",
    txtreason: "",
  });
  const [idExp, setIdExp] = useState("");
  const [errorsExp, setErrorsExp] = useState({});
  const [isReadMore, setReadMore] = useState({
    id: "",
    more: false,
  });
  const [checked, setChecked] = useState(false);
  const salaryTxt = useRef(0.0);
  const [selectedJob, setSelectedJob] = useState([]);
  const [selectedInd, setSelectedInd] = useState([]);

  const [allowanceList, setAllowanceList] = useState([]);
  const [allowanceState] = useState([
    {
      ddlEarning: "",
      txtamount: "",
      ddlcurrencyallowance: "",
    },
  ]);

  const handleEarning = (event, param1) => {
    // if(event.target.value === "others"){
    //   setShowOption(true);
    //   setErrorOpt({});
    // }else{
    const filteredEarn = allowanceList.find((earn) => earn.id === param1);

    filteredEarn.earning = Number(event.target.value.replace(/[^0-9.-]+/g, ""));

    allowanceState.ddlEarning = filteredEarn.earning;
    // }
  };

  const handleAmount = (event, param1) => {
    const filteredEarn = allowanceList.find((earn) => earn.id === param1);

    filteredEarn.amount = Number(event.target.value.replace(/[^0-9.-]+/g, ""));

    allowanceState.txtamount = filteredEarn.amount;
  };

  const handleCurrency = (event, param1) => {
    const filteredEarn = allowanceList.find((earn) => earn.id === param1);

    filteredEarn.currency = event.target.value;

    allowanceState.ddlcurrencyallowance = filteredEarn.currency;
  };

  const headers = [
    {
      Header: "Earning",
      accessor: (row) => `${row.earn}`,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            <Form.Select
              name="ddlEarning"
              onChange={(event) => handleEarning(event, data.id)}
              defaultValue={data.earning}
            >
              <option value="">Select</option>
              {dataAllowance.map((earn, index) => (
                <option key={index} value={earn.id}>
                  {earn.value}
                </option>
              ))}
              {/* <option value="others">Others</option> */}
            </Form.Select>
          </div>
        );
      },
    },
    {
      Header: "Amount",
      accessor: (row) => `${row.amount}`,
      Cell: ({ row }) => {
        const data = row.original;
        return (
          <div>
            <TextFieldFormat
              name="txtamount"
              type="number"
              onChange={(event) => handleAmount(event, data.id)}
              value={data.amount}
              thousandSeparator={true}
            ></TextFieldFormat>
          </div>
        );
      },
    },
    {
      Header: "Currency",
      accessor: (row) => `${row.currency}`,
      Cell: ({ row }) => {
        const data = row.original;
        return (
          <div>
            <Form.Select
              name="ddlcurrencyallowance"
              onChange={(event) => handleCurrency(event, data.id)}
              defaultValue={data.currency}
            >
              <option value="">Select</option>
              {dataCurrency.map((currency, index) => (
                <option key={index} value={currency.value}>
                  {currency.value}
                </option>
              ))}
            </Form.Select>
          </div>
        );
      },
    },
    {
      Header: "Action",
      accessor: (row) => `${row.id}`,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            <Button
              variant="link"
              className="delete-link"
              onClick={() => btnDelAllowance(data.id)}
            >
              <Icon path={mdiDelete} />
            </Button>
          </div>
        );
      },
    },
  ];

  function toMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", {
      month: "long",
    });
  }

  function toAllowanceDesc(idallow) {
    let nameAllow = "";

    let array_allow = dataAllowance.filter((grp) => {
      return grp.id === idallow;
    });

    if (array_allow.length > 0) {
      nameAllow = array_allow[0].value;
    }

    return nameAllow;
  }

  const toggleBtnReadMore = (index, bool) => (e) => {
    setReadMore({
      id: index,
      more: bool,
    });
  };

  const handleSalary = (e) => {
    salaryTxt.current = e.target.value;
  };

  const handleJobFunc = (e) => {
    setSelectedJob(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const handleIndustry = (e) => {
    setSelectedInd(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  function btnDelAllowance(id) {
    setAllowanceList((current) =>
      current.filter((allow) => {
        // 👇️ remove object that has id equal to 2
        return allow.id !== id;
      })
    );
  }

  const btnAddNewAllowance = (e) => {
    const allowanceLength = allowanceList.length + 1;

    setAllowanceList((items) => [
      ...items,
      {
        id: allowanceLength,
        earning: 0,
        amount: 0,
        currency: "",
      },
    ]);
  };

  const btnAddExp = (index) => {
    setClickedExp(index);
    setIdExp("new");

    setSelectedJob([]);
    setSelectedInd([]);

    expState.txtjobtitle = "";
    expState.ddljobfunction = "";
    expState.txtcompanyname = "";
    expState.ddlIndustry = "";

    expState.ddlCountry = {};
    expState.ddlstartmonth = "";
    expState.ddlstartyear = "";
    expState.ddlendmonth = "";

    expState.ddlendyear = "";
    expState.ddlpremonth = "";
    expState.ddlpreyear = "";

    expState.cbWorkingCurrent = false;
    setChecked(false);

    expState.txtofficephone = "";
    expState.txtofficeemail = "";
    expState.ddlperiod = "";

    expState.txtjobresponsibility = "";
    expState.ddlcurrency = "";
    salaryTxt.current = 0;

    expState.txtothbenefit = "";
    expState.txtreason = "";

    setAllowanceList([]);

    setErrorsExp({});
  };

  const btnEditExp = (id) => (e) => {
    const filteredExp = candidateExperience.find((exp) => exp.id === id);

    // console.log("filteredExp", filteredExp);

    setSelectedJob(filteredExp.job_function);
    setSelectedInd(filteredExp.industry);

    expState.txtjobtitle = filteredExp.title;
    expState.ddljobfunction = filteredExp.job_function;
    expState.txtcompanyname = filteredExp.company_name;
    expState.ddlIndustry = filteredExp.industry;

    expState.ddlCountry = {
      label: filteredExp.location,
      value: filteredExp.location,
    };
    expState.ddlstartmonth = filteredExp.start_date_month;
    expState.ddlstartyear = filteredExp.start_date_year;
    expState.ddlendmonth = filteredExp.end_date_month;

    expState.ddlendyear = filteredExp.end_date_year;
    expState.ddlpremonth = filteredExp.present_month;
    expState.ddlpreyear = filteredExp.present_year;

    expState.cbWorkingCurrent = filteredExp.current_working;
    setChecked(filteredExp.current_working);

    expState.txtofficephone = filteredExp.office_number;
    expState.txtofficeemail = filteredExp.office_email;
    expState.ddlperiod = filteredExp.notice_period;

    expState.txtjobresponsibility = filteredExp.job_responsibility;
    expState.ddlcurrency = filteredExp.currency;
    salaryTxt.current = filteredExp.salary;

    expState.txtothbenefit = filteredExp.other_benefits;
    expState.txtreason = filteredExp.reason_leaving;
    setAllowanceList(filteredExp.candidate_exp_allowance);

    setClickedExp(true);
    setErrorsExp({});
    setIdExp(id);
  };

  function getMonthDifference(startDate, endDate) {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }

  const btnSaveExp = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtExp = Object.fromEntries(data.entries());

    var monthDuration = 0;

    if (checked === true) {
      monthDuration = getMonthDifference(
        new Date(dtExp.ddlpreyear + "-" + dtExp.ddlpremonth),
        new Date()
      );
    } else {
      monthDuration = getMonthDifference(
        new Date(dtExp.ddlstartyear + "-" + dtExp.ddlstartmonth),
        new Date(dtExp.ddlendyear + "-" + dtExp.ddlendmonth)
      );
    }

    const newErrors = formExpErrors(dtExp);

    // console.log("allowanceList", allowanceList)
    const filteredAllowance = allowanceList.filter(
      (allow) => {return allow.earning !== 0}
    );

    if (Object.keys(newErrors).length > 0) {
      setErrorsExp(newErrors);
    } else {
      if (idExp === "new") {
        customSwal
          .fire({
            title: "Do you want to save new experience?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post("candidate/" + user.sub + "/experience/store", {
                title: dtExp.txtjobtitle,
                company_name: dtExp.txtcompanyname,
                location: dtExp.ddlCountry,
                start_date_year: checked ? null : dtExp.ddlstartyear,
                start_date_month: checked ? null : dtExp.ddlstartmonth,
                end_date_year: checked ? null : dtExp.ddlendyear,
                end_date_month: checked ? null : dtExp.ddlendmonth,
                current_working: checked.toString(),
                present_month: checked ? dtExp.ddlpremonth : null,
                present_year: checked ? dtExp.ddlpreyear : null,
                total_experience: monthDuration,
                office_number: dtExp.txtofficephone,
                office_email: dtExp.txtofficeemail,
                notice_period: dtExp.ddlperiod,
                industry: selectedInd,
                job_function: selectedJob,
                job_responsibility:
                  dtExp.txtjobresponsibility === "<p><br></p>"
                    ? ""
                    : dtExp.txtjobresponsibility,
                salary:
                  salaryTxt.current === 0.0 || salaryTxt.current === null
                    ? 0.0
                    : Number(salaryTxt.current.replace(/[^0-9.-]+/g, "")),
                currency: dtExp.ddlcurrency,
                other_benefits:
                  dtExp.txtothbenefit === "<p><br></p>"
                    ? ""
                    : dtExp.txtothbenefit,
                reason_leaving:
                  dtExp.txtreason === "<p><br></p>" ? "" : dtExp.txtreason,
                allowance: filteredAllowance,
              })
                .then((response) => {
                  dispatch(getCandidateDetail(user.sub));
                  setClickedExp(false);
                })
                .catch((error) => {
                  console.log("error save Experience", error);
                });
            }
          });
      } else {
        customSwal
          .fire({
            title: "Do you want to update this experience?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.patch(
                "candidate/" + user.sub + "/experience/" + idExp + "/update",
                {
                  title: dtExp.txtjobtitle,
                  company_name: dtExp.txtcompanyname,
                  location: dtExp.ddlCountry,
                  start_date_year: checked ? null : dtExp.ddlstartyear,
                  start_date_month: checked ? null : dtExp.ddlstartmonth,
                  end_date_year: checked ? null : dtExp.ddlendyear,
                  end_date_month: checked ? null : dtExp.ddlendmonth,
                  current_working: checked.toString(),
                  present_month: checked ? dtExp.ddlpremonth : null,
                  present_year: checked ? dtExp.ddlpreyear : null,
                  total_experience: monthDuration,
                  office_number: dtExp.txtofficephone,
                  office_email: dtExp.txtofficeemail,
                  notice_period: dtExp.ddlperiod,
                  industry: selectedInd,
                  job_function: selectedJob,
                  job_responsibility:
                    dtExp.txtjobresponsibility === "<p><br></p>"
                      ? ""
                      : dtExp.txtjobresponsibility,
                  salary:
                    salaryTxt.current === 0.0 || salaryTxt.current === null
                      ? 0.0
                      : Number(salaryTxt.current.replace(/[^0-9.-]+/g, "")),
                  currency: dtExp.ddlcurrency,
                  other_benefits:
                    dtExp.txtothbenefit === "<p><br></p>"
                      ? ""
                      : dtExp.txtothbenefit,
                  reason_leaving:
                    dtExp.txtreason === "<p><br></p>" ? "" : dtExp.txtreason,
                  allowance: filteredAllowance,
                }
              )
                .then((response) => {
                  dispatch(getCandidateDetail(user.sub));
                  setClickedExp(false);
                })
                .catch((error) => {
                  console.log("error update Experience", error);
                });
            }
          });
      }
    }
  };

  const btnDelExp = (idexp) => (e) => {
    customSwal
      .fire({
        title: "Do you want to remove this experience?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("candidate/" + user.sub + "/experience/" + idexp)
            .then((response) => {
              dispatch(getCandidateDetail(user.sub));
            })
            .catch((error) => {
              console.log("error btnDelExp", error);
            });
        }
      });
  };

  const formExpErrors = (object) => {
    const newErrors = {};

    if (!object.txtjobtitle || object.txtjobtitle === "")
      newErrors.txtjobtitle = "Fill in the blank!";

    if (!object.txtcompanyname || object.txtcompanyname === "")
      newErrors.txtcompanyname = "Fill in the blank!";

    if (!object.ddlCountry || object.ddlCountry === "")
      newErrors.ddlCountry = "Fill in the blank!";

    // if (!object.txtofficeemail || object.txtofficeemail === "")
    //   newErrors.txtofficeemail = "Fill in the blank!";
    return newErrors;
  };

  const btnCloseTabExp = () => {
    setClickedRef(false);
    setClickedMis(false);
    setClickedEdu(false);
  };
  // End Experience

  // Start Referee
  const [clickedRef, setClickedRef] = useState(false);
  const [refState] = useState({
    txtrefname: "",
    txtrefjobtitle: "",
    txtrefemail: "",
    txtrefphone: "",
    txtrefcompany: "",
    txtrefrelationship: "",
  });
  const [idRef, setIdRef] = useState("");
  const [errorsRef, setErrorsRef] = useState({});

  const btnAddRef = (index) => {
    setClickedRef(index);
    setIdRef("new");

    refState.txtrefname = "";
    refState.txtrefjobtitle = "";
    refState.txtrefemail = "";
    refState.txtrefphone = "";
    refState.txtrefcompany = "";
    refState.txtrefrelationship = "";

    setErrorsRef({});
  };

  const btnEditRef = (id) => (e) => {
    const filteredRef = candidateReference.find((ref) => ref.id === id);

    refState.txtrefname = filteredRef.name;
    refState.txtrefjobtitle = filteredRef.job_title;
    refState.txtrefemail = filteredRef.email;
    refState.txtrefphone = filteredRef.phone;
    refState.txtrefcompany = filteredRef.current_company;
    refState.txtrefrelationship = filteredRef.relationship;

    setClickedRef(true);
    setIdRef(id);
    setErrorsRef({});
  };

  const btnSaveRef = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtRef = Object.fromEntries(data.entries());

    const newErrors = formRefErrors(dtRef);

    if (Object.keys(newErrors).length > 0) {
      setErrorsRef(newErrors);
    } else {
      if (idRef === "new") {
        customSwal
          .fire({
            title: "Do you want to save new reference?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post("candidate/" + user.sub + "/reference/store", {
                name: dtRef.txtrefname,
                job_title: dtRef.txtrefjobtitle,
                email: dtRef.txtrefemail,
                phone: dtRef.txtrefphone,
                current_company: dtRef.txtrefcompany,
                relationship: dtRef.txtrefrelationship,
              })
                .then((response) => {
                  dispatch(getCandidateDetail(user.sub));
                  setClickedRef(false);
                })
                .catch((error) => {
                  console.log("error save Referee", error);
                });
            }
          });
      } else {
        customSwal
          .fire({
            title: "Do you want to update this reference?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.patch(
                "candidate/" + user.sub + "/reference/" + idRef + "/update",
                {
                  name: dtRef.txtrefname,
                  job_title: dtRef.txtrefjobtitle,
                  email: dtRef.txtrefemail,
                  phone: dtRef.txtrefphone,
                  current_company: dtRef.txtrefcompany,
                  relationship: dtRef.txtrefrelationship,
                }
              )
                .then((response) => {
                  dispatch(getCandidateDetail(user.sub));
                  setClickedRef(false);
                })
                .catch((error) => {
                  console.log("error update Referee", error);
                });
            }
          });
      }
    }
  };

  const btnDelRef = (idref) => (e) => {
    customSwal
      .fire({
        title: "Do you want to remove this reference?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("candidate/" + user.sub + "/reference/" + idref)
            .then((response) => {
              dispatch(getCandidateDetail(user.sub));
            })
            .catch((error) => {
              console.log("error btnDelEdu", error);
            });
        }
      });
  };

  const formRefErrors = (object) => {
    const newErrors = {};

    if (!object.txtrefname || object.txtrefname === "")
      newErrors.txtrefname = "Fill in the blank!";

    return newErrors;
  };

  const btnCloseTabRef = () => {
    setClickedEdu(false);
    setClickedMis(false);
    setClickedExp(false);
  };
  // End Referee

  // Start Miscellaneous
  const [clickedMis, setClickedMis] = useState(false);
  const [misState] = useState({
    txtmiscell: "",
  });
  const [idMis, setIdMis] = useState("");
  const [errorsMis, setErrorsMis] = useState({});
  const [characterLimit] = useState(500);

  const btnAddMis = (index) => {
    setClickedMis(index);
    setIdMis("new");

    misState.txtmiscell = "";

    setErrorsMis({});
  };

  const btnEditMis = (id) => (e) => {
    const filteredMis = candidateMiscellaneous.find((mis) => mis.id === id);

    misState.txtmiscell = filteredMis.misc;

    setClickedMis(true);
    setIdMis(id);
    setErrorsMis({});
  };

  const btnSaveMis = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtMis = Object.fromEntries(data.entries());

    const newErrors = formMisErrors(dtMis);

    if (Object.keys(newErrors).length > 0) {
      setErrorsMis(newErrors);
    } else {
      if (idMis === "new") {
        customSwal
          .fire({
            title: "Do you want to save new miscellaneous?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post("candidate/" + user.sub + "/miscellaneous/store", {
                misc: dtMis.txtmiscell,
                status: null,
              })
                .then((response) => {
                  dispatch(getCandidateDetail(user.sub));
                  setClickedMis(false);
                })
                .catch((error) => {
                  console.log("error save miscellaneous", error);
                });
            }
          });
      } else {
        customSwal
          .fire({
            title: "Do you want to update miscellaneous?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.patch(
                "candidate/" + user.sub + "/miscellaneous/" + idMis + "/update",
                {
                  misc: dtMis.txtmiscell,
                  status: null,
                }
              )
                .then((response) => {
                  dispatch(getCandidateDetail(user.sub));
                  setClickedMis(false);
                })
                .catch((error) => {
                  console.log("error Update miscellaneous", error);
                });
            }
          });
      }
    }
  };

  const btnDelMis = (idmis) => (e) => {
    customSwal
      .fire({
        title: "Do you want to remove this miscellaneous?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("candidate/" + user.sub + "/miscellaneous/" + idmis)
            .then((response) => {
              dispatch(getCandidateDetail(user.sub));
              setClickedMis(false);
            })
            .catch((error) => {
              console.log("error delete miscellaneous", error);
            });
        }
      });
  };

  const formMisErrors = (object) => {
    const newErrors = {};

    if (!object.txtmiscell || object.txtmiscell === "")
      newErrors.txtmiscell = "Fill in the blank!";

    return newErrors;
  };

  const btnCloseTabMis = () => {
    setClickedEdu(false);
    setClickedRef(false);
    setClickedExp(false);
  };
  // End Miscellaneous

  return (
    <>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0" onClick={btnCloseTabBasic}>
          <Accordion.Header>Basic Information</Accordion.Header>
          <Accordion.Body>
            <Form id="basic-form" onSubmit={btnSaveBasic}>
              {/* 1 */}
              <Row className="row-upload-pic mb-3">
                <div className="img-box">
                  {candidateDetails.profile_pic ? (
                    <img src={candidateDetails.profile_pic} alt="" />
                  ) : (
                    <img src={ProfileImage} alt="" />
                  )}
                </div>
                <Form.Group controlId="formFile" className="mb-3 col-4">
                  <Form.Control
                    // onChange={uploadSingleFile}
                    name="txtprofile"
                    type="file"
                    accept=".png,.jpg,.jpeg"
                  />
                  <Form.Text className="text-muted">
                    Allowed file types: jpg, jpeg, png.
                  </Form.Text>
                </Form.Group>
              </Row>
              {/* 2 */}
              <Row className="create-st-form">
                <Form.Group className="mb-3 col-6">
                  <Form.Label>
                    Salutation <label className="validation-star">*</label>
                  </Form.Label>
                  <Form.Select
                    name="ddlSalutation"
                    value={candidateDetails.salutation}
                    onChange={(e) =>
                      dispatch(
                        getDataCandidate({
                          salutation: e.currentTarget.value,
                        })
                      )
                    }
                    isInvalid={!!errorsBasic.salutation}
                  >
                    <option value="">Select</option>
                    {dataSalute.map((salute, index) => (
                      <option key={index} value={salute.code}>
                        {salute.value}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errorsBasic.salutation}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              {/* 3 */}
              <Row className="create-st-form">
                <Form.Group className="mb-3 col-6">
                  <Form.Label>
                    First Name <label className="validation-star">*</label>
                  </Form.Label>
                  <Form.Control
                    name="txtfirstname"
                    type="text"
                    placeholder="Please enter your first name"
                    value={candidateDetails.first_name}
                    onChange={(e) =>
                      dispatch(
                        getDataCandidate({
                          first_name: e.currentTarget.value,
                        })
                      )
                    }
                    isInvalid={!!errorsBasic.first_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorsBasic.first_name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3 col-6">
                  <Form.Label>
                    Last Name <label className="validation-star">*</label>
                  </Form.Label>
                  <Form.Control
                    name="txtlastname"
                    type="text"
                    placeholder="Please enter your last name"
                    value={candidateDetails.last_name}
                    onChange={(e) =>
                      dispatch(
                        getDataCandidate({
                          last_name: e.currentTarget.value,
                        })
                      )
                    }
                    isInvalid={!!errorsBasic.last_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorsBasic.last_name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              {/* 4 */}
              <Row className="create-st-form">
                <Form.Group className="mb-3 col-6">
                  <Form.Label>
                    Job Title <label className="validation-star">*</label>
                  </Form.Label>
                  <Form.Control
                    name="txtjobtitle"
                    type="text"
                    placeholder="Please enter job title"
                    value={candidateDetails.job_title}
                    onChange={(e) =>
                      dispatch(
                        getDataCandidate({
                          job_title: e.currentTarget.value,
                        })
                      )
                    }
                    isInvalid={!!errorsBasic.job_title}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorsBasic.job_title}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3 col-6">
                  <Form.Label>
                    Email Address <label className="validation-star">*</label>
                  </Form.Label>
                  <Form.Control
                    name="txtemail"
                    type="email"
                    value={candidateDetails.comp_email}
                    onChange={(e) =>
                      dispatch(
                        getDataCandidate({
                          comp_email: e.currentTarget.value,
                        })
                      )
                    }
                    isInvalid={!!errorsBasic.comp_email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorsBasic.comp_email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              {/* 5 */}
              <Row className="create-st-form">
                <Form.Group className="mb-3 col-6">
                  <Form.Label>Direct Line Number</Form.Label>
                  <Form.Control
                    name="txtdirectline"
                    type="number"
                    value={candidateDetails.direct_line_no}
                    placeholder="Please enter direct line number"
                    onChange={(e) =>
                      dispatch(
                        getDataCandidate({
                          direct_line_no: e.currentTarget.value,
                        })
                      )
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-3 col-6">
                  <Form.Label>
                    Mobile Number <label className="validation-star">*</label>
                  </Form.Label>
                  <Row>
                    <div className="col-3">
                      <Form.Select
                        name="ddlPhoneCode"
                        value={candidateDetails.mobile_no_code}
                        onChange={(e) =>
                          dispatch(
                            getDataCandidate({
                              mobile_no_code: e.currentTarget.value,
                            })
                          )
                        }
                        isInvalid={!!errorsBasic.mobile_no_code}
                      >
                        <option value="">Select</option>
                        {dataPhoneCode.map((salute, index) => (
                          <option key={index} value={salute.value}>
                            {salute.value}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errorsBasic.mobile_no_code}
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-9">
                      <Form.Control
                        name="txtPhone"
                        type="number"
                        value={candidateDetails.mobile_no}
                        onChange={(e) =>
                          dispatch(
                            getDataCandidate({
                              mobile_no: e.currentTarget.value,
                            })
                          )
                        }
                        isInvalid={!!errorsBasic.mobile_no}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorsBasic.mobile_no}
                      </Form.Control.Feedback>
                    </div>
                  </Row>
                </Form.Group>
              </Row>
              {/* 6 */}
              <Row className="create-st-form">
                <Form.Group className="mb-3 col-6">
                  <Form.Label>
                    Country <label className="validation-star">*</label>
                  </Form.Label>
                  <Searchable
                    isMulti={false}
                    options={dataCountry}
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.value}
                    value={{ value: candidateDetails.citizenship_main }}
                    onChange={handleChangeCountry}
                    isInvalid={!!errorsBasic.citizenship_main}
                    errorText={errorsBasic.citizenship_main}
                  ></Searchable>
                  {/* <Form.Control.Feedback type="invalid">
                                    {errorsBasic.txtcountry}
                                    </Form.Control.Feedback> */}
                </Form.Group>
                <Form.Group className="mb-3 col-6">
                  <Form.Label>
                    Languages <label className="validation-star">*</label>
                  </Form.Label>
                  <Searchable
                    isMulti={true}
                    options={dataLanguages}
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.value}
                    value={candidateLanguages}
                    onChange={handleChangeLanguages}
                    isInvalid={!!errorsBasic.ddllang}
                    errorText={errorsBasic.ddllang}
                  ></Searchable>
                </Form.Group>
              </Row>
            </Form>
            <div className="row-btn-center">
              <Button variant="secondary" onClick={btnResetBasic}>
                Reset
              </Button>
              <Button variant="primary" type="submit" form="basic-form">
                Save Basic Information
              </Button>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" onClick={btnCloseTabEdu}>
          <Accordion.Header>Education Information</Accordion.Header>
          <Accordion.Body>
            <div className="container-info">
              {clickedEdu === false ? (
                <Button
                  onClick={() => btnAddEdu(true)}
                  variant="link"
                  className="modal-link"
                >
                  <Icon path={mdiPlusCircle} />
                  Add Education
                </Button>
              ) : (
                <div
                  className={"add-edu-container" + (clickedEdu ? " in" : "")}
                >
                  <div className="container-bg-col-div">
                    <div className="container-form-div">
                      <Form id="education-form" onSubmit={btnSaveEdu}>
                        {/* 1.1 */}
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column lg={2}>
                            School <label className="validation-star">*</label>
                          </Form.Label>
                          <Col>
                            <Form.Control
                              name="txtschool"
                              type="text"
                              defaultValue={eduState.txtschool}
                              isInvalid={!!errorsEdu.txtschool}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errorsEdu.txtschool}
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                        {/* 1.2 */}
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column lg={2}>
                            Degree
                          </Form.Label>
                          <Col>
                            <Form.Control
                              name="txtdegree"
                              type="text"
                              defaultValue={eduState.txtdegree}
                            />
                          </Col>
                        </Form.Group>
                        {/* 1.3 */}
                        <Row>
                          <Col xs={6}>
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={4}>
                                Duration
                              </Form.Label>
                              <Col>
                                <Form.Select
                                  name="txtfromyear"
                                  defaultValue={eduState.txtfromyear}
                                >
                                  <option value="">Select</option>
                                  {dataYear.map((year, index) => (
                                    <option key={index} value={year.value}>
                                      {year.label}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Col>
                            </Form.Group>
                          </Col>
                          <Col xs={6}>
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label
                                column
                                lg={4}
                                style={{ textAlign: "center" }}
                              >
                                to
                              </Form.Label>
                              <Col>
                                <Form.Select
                                  name="txttoyear"
                                  defaultValue={eduState.txttoyear}
                                >
                                  <option value="">Select</option>
                                  {dataYear.map((year, index) => (
                                    <option key={index} value={year.value}>
                                      {year.label}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Col>
                            </Form.Group>
                          </Col>
                        </Row>
                        {/* 1.4 */}
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column lg={2}>
                            Certificates
                          </Form.Label>
                          <Col>
                            <Form.Control name="txtcertificate" type="file" />
                          </Col>
                        </Form.Group>

                        <div className="row-btn-bottom">
                          <Button
                            onClick={() => btnAddEdu(false)}
                            variant="secondary"
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="primary"
                            type="submit"
                            form="education-form"
                          >
                            Save
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="container-info edu-added">
              {clickedEdu === false ? (
                <div className="column-card">
                  {candidateEducation.map((education, index) => (
                    <Card key={index} style={{ marginBottom: "10px" }}>
                      <div className="card-edu-row">
                        <div className="card-edu-info">
                          <div className="sub-text">{education.name}</div>
                          <div className="sub-text">
                            {education.qualification}
                          </div>
                          <div className="sub-text" style={{ fontWeight: 400 }}>
                            {education.start_year} - {education.end_year}
                          </div>
                        </div>
                        <div className="card-edu-show-cert">
                          <div>
                            {education.certificate_attachment ? (
                              <Button
                                onClick={btnDownload(
                                  education.certificate_attachment
                                )}
                                variant="link"
                                className="no-underline"
                              >
                                <Icon path={mdiFile} />
                                Show Certificate
                              </Button>
                            ) : (
                              <Button variant="link" className="no-underline">
                                No Certificate
                              </Button>
                            )}
                          </div>
                        </div>
                        <div className="card-edu-btn">
                          <Button
                            onClick={btnEditEdu(education.id)}
                            variant="link"
                            className="modal-link"
                          >
                            <Icon path={mdiPencil} />
                          </Button>
                          <Button
                            onClick={btnDelEdu(education.id)}
                            variant="link"
                            className="delete-link"
                          >
                            <Icon path={mdiDelete} />
                          </Button>
                        </div>
                      </div>
                    </Card>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" onClick={btnCloseTabExp}>
          <Accordion.Header>Experience Information</Accordion.Header>
          <Accordion.Body>
            <div className="container-info">
              {clickedExp === false ? (
                <Button
                  onClick={() => btnAddExp(true)}
                  variant="link"
                  className="modal-link"
                >
                  <Icon path={mdiPlusCircle} />
                  Add Experience
                </Button>
              ) : (
                <div
                  className={"add-edu-container" + (clickedExp ? " in" : "")}
                >
                  <div className="container-bg-col-div">
                    <div className="container-form-div">
                      <Form id="experience-form" onSubmit={btnSaveExp}>
                        {/* 2.1 */}
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column lg={2}>
                            Job Title{" "}
                            <label className="validation-star">*</label>
                          </Form.Label>
                          <Col>
                            <Form.Control
                              name="txtjobtitle"
                              type="text"
                              defaultValue={expState.txtjobtitle}
                              isInvalid={!!errorsExp.txtjobtitle}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errorsExp.txtjobtitle}
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                        {/* 2.2 */}
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column lg={2}>
                            Job Function
                          </Form.Label>
                          <Col>
                            <Searchable
                              name="ddljobfunction"
                              isMulti={true}
                              options={dataJobFunction}
                              getOptionValue={(option) => option.value}
                              getOptionLabel={(option) => option.value}
                              defaultValue={dataJobFunction.filter((obj) =>
                                expState.ddljobfunction.includes(obj.value)
                              )}
                              onChange={handleJobFunc}
                            ></Searchable>
                          </Col>
                        </Form.Group>
                        {/* 2.3 */}
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column lg={2}>
                            Company Name{" "}
                            <label className="validation-star">*</label>
                          </Form.Label>
                          <Col>
                            <Form.Control
                              name="txtcompanyname"
                              type="text"
                              defaultValue={expState.txtcompanyname}
                              isInvalid={!!errorsExp.txtcompanyname}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errorsExp.txtcompanyname}
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                        {/* 2.4 */}
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column lg={2}>
                            Industry
                          </Form.Label>
                          <Col>
                            <Searchable
                              name="ddlIndustry"
                              isMulti={true}
                              options={dataIndustry}
                              getOptionValue={(option) => option.value}
                              getOptionLabel={(option) => option.value}
                              defaultValue={dataIndustry.filter((obj) =>
                                expState.ddlIndustry.includes(obj.value)
                              )}
                              onChange={handleIndustry}
                            ></Searchable>
                          </Col>
                        </Form.Group>
                        {/* 2.5 */}
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column lg={2}>
                            Country <label className="validation-star">*</label>
                          </Form.Label>
                          <Col>
                            <Searchable
                              name="ddlCountry"
                              isMulti={false}
                              options={dataCountry}
                              getOptionValue={(option) => option.value}
                              getOptionLabel={(option) => option.value}
                              defaultValue={expState.ddlCountry}
                              isInvalid={!!errorsExp.ddlCountry}
                              errorText={errorsExp.ddlCountry}
                              // value={languageList.filter(obj => selectedValue.includes(obj.value))}
                              // onChange={handleChange}
                            ></Searchable>
                          </Col>
                        </Form.Group>
                        {/* 2.6 */}
                        {checked === true ? (
                          <Form.Group as={Row} className="mb-3">
                            <Form.Label column lg={2}>
                              Present
                            </Form.Label>
                            <Col>
                              <Form.Select
                                name="ddlpremonth"
                                defaultValue={expState.ddlpremonth}
                              >
                                <option value="">Select</option>
                                {monthList.map((month, index) => (
                                  <option key={index} value={month.value}>
                                    {month.label}
                                  </option>
                                ))}
                              </Form.Select>
                            </Col>
                            <Col>
                              <Form.Select
                                name="ddlpreyear"
                                defaultValue={expState.ddlpreyear}
                              >
                                <option value="">Select</option>
                                {dataYear.map((year, index) => (
                                  <option key={index} value={year.value}>
                                    {year.label}
                                  </option>
                                ))}
                              </Form.Select>
                            </Col>
                          </Form.Group>
                        ) : (
                          <Form.Group as={Row} className="mb-1">
                            <Form.Label column lg={2}>
                              Joined Date
                            </Form.Label>
                            <Col>
                              <Form.Select
                                name="ddlstartmonth"
                                defaultValue={expState.ddlstartmonth}
                              >
                                <option value="">Select</option>
                                {monthList.map((month, index) => (
                                  <option key={index} value={month.value}>
                                    {month.label}
                                  </option>
                                ))}
                              </Form.Select>
                            </Col>
                            <Col>
                              <Form.Select
                                name="ddlstartyear"
                                defaultValue={expState.ddlstartyear}
                              >
                                <option value="">Select</option>
                                {dataYear.map((year, index) => (
                                  <option key={index} value={year.value}>
                                    {year.label}
                                  </option>
                                ))}
                              </Form.Select>
                            </Col>
                          </Form.Group>
                        )}
                        {checked === true ? (
                          ""
                        ) : (
                          <Form.Group as={Row} className="mb-1">
                            <Form.Label column lg={2}></Form.Label>
                            <Col
                              className="exp-duration"
                              style={{ marginLeft: "15px" }}
                            >
                              to
                            </Col>
                          </Form.Group>
                        )}
                        {checked === true ? (
                          ""
                        ) : (
                          <Form.Group as={Row} className="mb-1">
                            <Form.Label column lg={2}>
                              End Date
                            </Form.Label>
                            <Col>
                              <Form.Select
                                name="ddlendmonth"
                                defaultValue={expState.ddlendmonth}
                              >
                                <option value="">Select</option>
                                {monthList.map((month, index) => (
                                  <option key={index} value={month.value}>
                                    {month.label}
                                  </option>
                                ))}
                              </Form.Select>
                            </Col>
                            <Col>
                              <Form.Select
                                name="ddlendyear"
                                defaultValue={expState.ddlendyear}
                              >
                                <option value="">Select</option>
                                {dataYear.map((year, index) => (
                                  <option key={index} value={year.value}>
                                    {year.label}
                                  </option>
                                ))}
                              </Form.Select>
                              {/* <Form.Control.Feedback type="invalid">
                                                                {errorsExp.ddlendmonth}
                                                                </Form.Control.Feedback> */}
                            </Col>
                          </Form.Group>
                        )}
                        {/* 2.7 */}
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column lg={2}></Form.Label>
                          <Col>
                            <Form.Check
                              type="checkbox"
                              id="default-checkbox"
                              label="Currently Working"
                              defaultChecked={expState.cbWorkingCurrent}
                              // onChange={handleCheckDate}
                              onChange={(e) =>
                                setChecked(e.currentTarget.checked)
                              }
                            />
                          </Col>
                        </Form.Group>
                        {/* 2.8 */}
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column lg={2}>
                            Office Number
                          </Form.Label>
                          <Col>
                            <Form.Control
                              name="txtofficephone"
                              type="number"
                              defaultValue={expState.txtofficephone}
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        {/* 2.9 */}
                        {checked === true ? (
                          <Form.Group as={Row} className="mb-3">
                            <Form.Label column lg={2}>
                              Office Email
                            </Form.Label>
                            <Col>
                              <Form.Control
                                name="txtofficeemail"
                                type="text"
                                defaultValue={expState.txtofficeemail}
                              ></Form.Control>
                            </Col>
                          </Form.Group>
                        ) : (
                          <></>
                        )}
                        {/* 2.10 */}
                        {checked === true ? (
                          <Form.Group as={Row} className="mb-3">
                            <Form.Label column lg={2}>
                              Notice Period
                            </Form.Label>
                            <Col>
                              <Form.Select
                                name="ddlperiod"
                                defaultValue={expState.ddlperiod}
                              >
                                <option value="">Select</option>
                                {noticeList.map((notice, index) => (
                                  <option key={index} value={notice.label}>
                                    {notice.label}
                                  </option>
                                ))}
                              </Form.Select>
                            </Col>
                          </Form.Group>
                        ) : (
                          <></>
                        )}
                        {/* 2.11 */}
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column lg={2}>
                            Job Responsibility
                          </Form.Label>
                          <Col>
                            <RichTextEditor
                              name="txtjobresponsibility"
                              htmlContent={expState.txtjobresponsibility}
                            />
                          </Col>
                        </Form.Group>
                        {/* 2.12*/}
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column lg={2}>
                            Salary
                          </Form.Label>
                          <Col>
                            <Form.Select
                              name="ddlcurrency"
                              defaultValue={expState.ddlcurrency}
                            >
                              <option value="">Select</option>
                              {dataCurrency.map((currency, index) => (
                                <option key={index} value={currency.value}>
                                  {currency.value}
                                </option>
                              ))}
                            </Form.Select>
                          </Col>
                          <Col>
                            <TextFieldFormat
                              name="txtsalary"
                              value={
                                salaryTxt.current === null
                                  ? 0
                                  : salaryTxt.current
                              }
                              onChange={handleSalary}
                              thousandSeparator={true}
                            ></TextFieldFormat>
                          </Col>
                        </Form.Group>
                        {/* 2.13 */}
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column lg={2}>
                            Allowance
                          </Form.Label>
                          <Col>
                            <CustomTable
                              headers={headers}
                              items={allowanceList}
                            ></CustomTable>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column lg={2}></Form.Label>
                          <Col>
                            <Button
                              onClick={btnAddNewAllowance}
                              variant="primary"
                            >
                              Add New Allowance
                            </Button>
                          </Col>
                        </Form.Group>
                        {/* 2.14 */}
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column lg={2}>
                            Other Benefits
                          </Form.Label>
                          <Col>
                            <RichTextEditor
                              name="txtothbenefit"
                              htmlContent={expState.txtothbenefit}
                            />
                          </Col>
                        </Form.Group>
                        {/* 2.15 */}
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column lg={2}>
                            Reason for leaving
                          </Form.Label>
                          <Col>
                            <RichTextEditor
                              name="txtreason"
                              htmlContent={expState.txtreason}
                            />
                          </Col>
                        </Form.Group>
                        {/* button Experience */}
                        <div className="row-btn-bottom">
                          <Button
                            onClick={() => btnAddExp(false)}
                            variant="secondary"
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            variant="primary"
                            form="experience-form"
                          >
                            Save
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="container-info">
              {clickedExp === false ? (
                <div className="column-card">
                  {candidateExperience.map((experience, index) => (
                    <div key={index} className="cand-exp-loop">
                      <div className="cdt-row">
                        <div className="col-5">
                          <div className="big-text">{experience.title}</div>
                          <div className="sub-text">
                            {experience.company_name}
                          </div>
                          {experience.current_working ? (
                            <div
                              className="sub-text"
                              style={{ fontWeight: 400 }}
                            >
                              {experience.present_month
                                ? toMonthName(experience.present_month)
                                : " "}{" "}
                              {experience.present_year} - Current
                            </div>
                          ) : (
                            <>
                              {experience.start_date_month === "" &&
                              experience.end_date_month === "" &&
                              experience.start_date_year === "" &&
                              experience.end_date_year === "" ? null : (
                                <div
                                  className="sub-text"
                                  style={{ fontWeight: 400 }}
                                >
                                  {toMonthName(experience.start_date_month)}{" "}
                                  {experience.start_date_year} -{" "}
                                  {toMonthName(experience.end_date_month)}{" "}
                                  {experience.end_date_year}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        <div className="col-6" style={{ display: "flex" }}>
                          <div className="col-11">
                            <div className="more-section-row">
                              <div className="mini-text flex-left">Salary</div>
                              <div className="sub-text flex-right">
                                {experience.currency}{" "}
                                {Number(experience.salary)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </div>
                            </div>
                            <div className="more-section-row">
                              <div className="mini-text flex-left">
                                Direct Line
                              </div>
                              <div className="sub-text flex-right">
                                {experience.office_number
                                  ? experience.office_number
                                  : "-"}
                              </div>
                            </div>
                            <div className="more-section-row">
                              <div className="mini-text flex-left">
                                Office Email
                              </div>
                              <div className="sub-text flex-right">
                                {experience.office_email
                                  ? experience.office_email
                                  : "-"}
                              </div>
                            </div>
                            {experience.current_working ? (
                              <div className="more-section-row">
                                <div className="mini-text flex-left">
                                  Notice Period
                                </div>
                                <div className="sub-text flex-right">
                                  {experience.notice_period
                                    ? experience.notice_period
                                    : "-"}
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="col-1">
                            <div className="exp-button">
                              <Button
                                variant="link"
                                className="modal-link"
                                onClick={btnEditExp(experience.id)}
                              >
                                <Icon path={mdiPencil} />
                              </Button>
                              <Button
                                variant="link"
                                className="delete-link"
                                style={{ margin: 0 }}
                                onClick={btnDelExp(experience.id)}
                              >
                                <Icon path={mdiDelete} />
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>

                      {isReadMore.more === false && isReadMore.id === index ? (
                        <div className="cdt-row">
                          <div className="col-5">
                            {experience.reason_leaving ? (
                              <>
                                <div className="text">Reason for leaving</div>
                                <div
                                  className="mini-text"
                                  dangerouslySetInnerHTML={{
                                    __html: experience.reason_leaving
                                      ? experience.reason_leaving
                                      : "-",
                                  }}
                                ></div>
                              </>
                            ) : null}

                            {experience.job_responsibility ? (
                              <>
                                <div className="text">Job Responsiblity</div>
                                <div
                                  className="mini-text"
                                  dangerouslySetInnerHTML={{
                                    __html: experience.job_responsibility,
                                  }}
                                ></div>
                              </>
                            ) : null}
                          </div>
                          <div className="col-6">
                            <div className="col-11">
                              {experience.candidate_exp_allowance.length > 0 ? (
                                <div className="more-section-row">
                                  <div className="mini-text flex-left">
                                    Allowance
                                  </div>
                                  <div style={{ flex: "1 1 60%" }}>
                                    {experience.candidate_exp_allowance.map(
                                      (allowance, index) => (
                                        <div
                                          className="sub-text flex-right"
                                          key={index}
                                        >
                                          {toAllowanceDesc(allowance.earning)} -{" "}
                                          {allowance.currency}{" "}
                                          {Number(allowance.amount)
                                            .toFixed(2)
                                            .replace(
                                              /\d(?=(\d{3})+\.)/g,
                                              "$&,"
                                            )}
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              ) : null}

                              {experience.other_benefits ? (
                                <div className="more-section-row">
                                  <div className="mini-text flex-left">
                                    Other Benefits
                                  </div>
                                  <div
                                    className="sub-text flex-right"
                                    dangerouslySetInnerHTML={{
                                      __html: experience.other_benefits
                                        ? experience.other_benefits
                                        : "-",
                                    }}
                                  ></div>
                                </div>
                              ) : null}
                            </div>
                            <div className="col-1"></div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {experience.other_benefits ||
                      experience.job_responsibility ||
                      experience.reason_leaving ||
                      experience.candidate_exp_allowance.length > 0 ? (
                        <div className="cdt-row">
                          <div className="col-6"></div>
                          <div className="col-6">
                            <div className="col-11">
                              <div className="more-section-row">
                                <div className="mini-text flex-left">{""}</div>
                                <div className="sub-text flex-right">
                                  {isReadMore.more === false &&
                                  isReadMore.id === index ? (
                                    <Button
                                      variant="link"
                                      className="read-btn"
                                      onClick={toggleBtnReadMore(index, true)}
                                    >
                                      Less details
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="link"
                                      className="read-btn"
                                      onClick={toggleBtnReadMore(index, false)}
                                    >
                                      More details
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-1"></div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3" onClick={btnCloseTabRef}>
          <Accordion.Header>Reference Information</Accordion.Header>
          <Accordion.Body>
            <div className="container-info">
              {clickedRef === false ? (
                <Button
                  onClick={() => btnAddRef(true)}
                  variant="link"
                  className="modal-link"
                >
                  <Icon path={mdiPlusCircle} />
                  Add Reference
                </Button>
              ) : (
                <div
                  className={"add-edu-container" + (clickedRef ? " in" : "")}
                >
                  <div className="container-bg-col-div">
                    <div className="container-form-div">
                      <Form id="reference-form" onSubmit={btnSaveRef}>
                        {/* 3.1 */}
                        <Row>
                          <Col xs={6}>
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={4}>
                                Referee Name{" "}
                                <label className="validation-star">*</label>
                              </Form.Label>
                              <Col xs={8}>
                                <Form.Control
                                  name="txtrefname"
                                  type="text"
                                  defaultValue={refState.txtrefname}
                                  isInvalid={!!errorsRef.txtrefname}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errorsRef.txtrefname}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                          </Col>
                          <Col xs={6}>
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={4}>
                                Referee Job Title
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  name="txtrefjobtitle"
                                  type="text"
                                  defaultValue={refState.txtrefjobtitle}
                                />
                              </Col>
                            </Form.Group>
                          </Col>
                        </Row>
                        {/* 3.2 */}
                        <Row>
                          <Col xs={6}>
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={4}>
                                Email Address
                              </Form.Label>
                              <Col xs={8}>
                                <Form.Control
                                  name="txtrefemail"
                                  type="text"
                                  defaultValue={refState.txtrefemail}
                                />
                              </Col>
                            </Form.Group>
                          </Col>
                          <Col xs={6}>
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={4}>
                                Contact Number
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  name="txtrefphone"
                                  type="number"
                                  defaultValue={refState.txtrefphone}
                                />
                              </Col>
                            </Form.Group>
                          </Col>
                        </Row>
                        {/* 3.3 */}
                        <Row>
                          <Col xs={6}>
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={4}>
                                Current Company
                              </Form.Label>
                              <Col xs={8}>
                                <Form.Control
                                  name="txtrefcompany"
                                  type="text"
                                  defaultValue={refState.txtrefcompany}
                                />
                              </Col>
                            </Form.Group>
                          </Col>
                          <Col xs={6}>
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={4}>
                                Relationship
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  name="txtrefrelationship"
                                  type="text"
                                  defaultValue={refState.txtrefrelationship}
                                />
                              </Col>
                            </Form.Group>
                          </Col>
                        </Row>

                        {/* button Reference */}
                        <div className="row-btn-bottom">
                          <Button
                            onClick={() => btnAddRef(false)}
                            variant="secondary"
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="primary"
                            type="submit"
                            form="reference-form"
                          >
                            Save
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="column-card">
              {clickedRef === false ? (
                candidateReference.map((referee, index) => (
                  <>
                    <Card key={index} style={{ display: "inline" }}>
                      <div className="card-ref-box col-4">
                        <div className="card-referee">
                          <div className="big-text">Referee {index + 1}</div>
                          <div style={{ display: "flex" }}>
                            <Button
                              onClick={btnEditRef(referee.id)}
                              variant="link"
                              className="modal-link"
                            >
                              <Icon path={mdiPencil} />
                            </Button>
                            <Button
                              onClick={btnDelRef(referee.id)}
                              variant="link"
                              className="delete-link"
                              style={{ marginLeft: 0 }}
                            >
                              <Icon path={mdiDelete} />
                            </Button>
                          </div>
                        </div>
                        <div className="card-text-style">{referee.name}</div>
                        <div className="card-text-style">
                          {referee.job_title}{" "}
                          {referee.current_company && referee.job_title
                            ? ","
                            : ""}{" "}
                          {referee.current_company}
                        </div>
                        <div className="card-text-style">{referee.email}</div>
                        <div className="card-text-style">{referee.phone}</div>
                      </div>
                    </Card>
                  </>
                ))
              ) : (
                <></>
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4" onClick={btnCloseTabMis}>
          <Accordion.Header>Miscellaneous Information</Accordion.Header>
          <Accordion.Body>
            <div className="container-info">
              {clickedMis === false ? (
                <Button
                  onClick={() => btnAddMis(true)}
                  variant="link"
                  className="modal-link"
                >
                  <Icon path={mdiPlusCircle} />
                  Add Miscellaneous
                </Button>
              ) : (
                <div
                  className={"add-edu-container" + (clickedMis ? " in" : "")}
                >
                  <div className="container-bg-col-div">
                    <div className="container-form-div">
                      <Form id="miscell-form" onSubmit={btnSaveMis}>
                        {/* 4.1 */}
                        <Form.Group as={Row} className="mb-3">
                          <Col xs={12}>
                            <Form.Control
                              name="txtmiscell"
                              as="textarea"
                              rows={5}
                              placeholder="Must be less than 500 characters"
                              maxLength={characterLimit}
                              defaultValue={misState.txtmiscell}
                              isInvalid={!!errorsMis.txtmiscell}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errorsMis.txtmiscell}
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                        {/* button Reference */}
                        <div className="row-btn-bottom">
                          <Button
                            onClick={() => btnAddMis(false)}
                            variant="secondary"
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="primary"
                            type="submit"
                            form="miscell-form"
                          >
                            Save
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="container-info">
              {clickedMis === false ? (
                <div className="column-card">
                  {candidateMiscellaneous.map((mis, index) => (
                    <CardCandidate key={index}>
                      <div className="card-education__row-flex5">
                        <div>{mis.misc} </div>
                      </div>
                      <div className="card-education__row-flex1">
                        <div className="card-referee-button">
                          <Button
                            onClick={btnEditMis(mis.id)}
                            variant="link"
                            className="modal-link"
                          >
                            <Icon path={mdiPencil} />
                          </Button>
                          <Button
                            onClick={btnDelMis(mis.id)}
                            variant="link"
                            className="delete-link"
                            style={{ marginLeft: 0 }}
                          >
                            <Icon path={mdiDelete} />
                          </Button>
                        </div>
                      </div>
                    </CardCandidate>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

// const handleEarning = (event, param1) => {
//   // if(event.target.value === "others"){
//   //   setShowOption(true);
//   //   setErrorOpt({});
//   // }else{
//   const filteredEarn = allowanceList.find((earn) => earn.id === param1);

//   filteredEarn.earning = Number(
//     event.target.value.replace(/[^0-9.-]+/g, "")
//   );

//   allowanceState.ddlEarning = filteredEarn.earning;
//   // }
// };
