import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../../../core/services/api.service";
import { Tab, Nav, Button } from "react-bootstrap";
import AuthAbilities from "../../Auth/AuthAbilities";

import Icon from "@mdi/react";
// import image from "../../../../assets/profile.jpg";
import { mdiAccountSearch, mdiArrowLeft } from "@mdi/js";

import BreadCrumb from "../../../../components/Breadcrumb/BreadCrumb";

import { TabViewCandidate } from "../../../../components/Tab/TabViewCandidate";
import { TabDocuments } from "../../../../components/Tab/TabDocuments";
import { TabNoteCandidate } from "../../../../components/Tab/TabNoteCandidate";
import { TabAssignCandidate } from "../../../../components/Tab/TabAssignCandidate";
import { TabResume } from "../../../../components/Tab/TabResume";
import { TabPlacements } from "../../../../components/Tab/TabPlacements";

import { storeTitle } from "../../../../store/actions/headerTitleSlice";
import { getCandidateDetail } from "../../../../store/actions/candidateSlice";

const ViewPlacement = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const abilities = AuthAbilities();

  const { candidateDetails } = useSelector((state) => state.candidate);

  const selectedCrumb = () => {
    navigate(-1);
  };

  const [resumeList, setResumeList] = useState([]);

  const getSelectedCandidate = useCallback(() => {
    Api.get("candidate/" + params.id)
      .then((response) => {
        let data = response.data;

        // console.log("getSelectedCandidate", data);

        // Resume
        if (data.candidate_resume_attachment.length > 0) {
          setResumeList(data.candidate_resume_attachment);
        } else {
          setResumeList([]);
        }
      })
      .catch((error) => {
        console.log("candidate/all", error);
      });
  }, [params]);

  useEffect(() => {
    getSelectedCandidate();
    dispatch(getCandidateDetail(params.id));
    dispatch(storeTitle({ title: "Placements", icon: mdiAccountSearch }));
  }, [getSelectedCandidate, dispatch, params.id]);

  return (
    <div className="main-div list-cli">
      <div className="container-div both-side">
        <BreadCrumb
          crumbs={[
            "Placements",
            candidateDetails.last_name
              ? candidateDetails.first_name + " " + candidateDetails.last_name
              : candidateDetails.first_name,
          ]}
          selected={selectedCrumb}
        ></BreadCrumb>
        <Button variant="secondary" onClick={() => navigate(-1)}>
          <Icon path={mdiArrowLeft} color="#212529" />
          Back
        </Button>
      </div>

      <div className="top-title-div">
        <Tab.Container id="left-tabs-example" defaultActiveKey="1">
          <div className="nav-container scrollable">
            <Nav variant="tabs" justify>
              <Nav.Item>
                <Nav.Link eventKey="1">Profile Details</Nav.Link>
              </Nav.Item>
              <Nav.Item hidden={!abilities.includes("tab resume")}>
                <Nav.Link eventKey="2">Resume</Nav.Link>
              </Nav.Item>
              <Nav.Item hidden={!abilities.includes("tab document")}>
                <Nav.Link eventKey="3">Documents & Attachments</Nav.Link>
              </Nav.Item>
              <Nav.Item hidden={!abilities.includes("tab note")}>
                <Nav.Link eventKey="4">Notes</Nav.Link>
              </Nav.Item>
              <Nav.Item hidden={!abilities.includes("tab assignment")}>
                <Nav.Link eventKey="5">Assignments</Nav.Link>
              </Nav.Item>
              <Nav.Item hidden={!abilities.includes("tab placement")}>
                <Nav.Link eventKey="6">Placements</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          <Tab.Content>
            <Tab.Pane eventKey="1">
              <TabViewCandidate></TabViewCandidate>
            </Tab.Pane>
            <Tab.Pane eventKey="2">
              <TabResume data={resumeList}></TabResume>
            </Tab.Pane>
            <Tab.Pane eventKey="3">
              <TabDocuments></TabDocuments>
            </Tab.Pane>
            <Tab.Pane eventKey="4">
              <TabNoteCandidate></TabNoteCandidate>
            </Tab.Pane>
            <Tab.Pane eventKey="5">
              <TabAssignCandidate></TabAssignCandidate>
            </Tab.Pane>
            <Tab.Pane eventKey="6">
              <TabPlacements></TabPlacements>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

export default ViewPlacement;
