import { createSlice } from "@reduxjs/toolkit";
import Api from "../../core/services/api.service";

export const mergeSlice = createSlice({
  name: "merge",
  initialState: {
    mergeCandSimilar: {},
    mergeCandNoSimilar: [],
    mergeCandEduOne: [],
    mergeCandEduTwo: [],
    mergeCandExpOne: [],
    mergeCandExpTwo: [],
    mergeCandRefOne: [],
    mergeCandRefTwo: [],
    mergeCandMisOne: [],
    mergeCandMisTwo: [],
    mergeCandCombine: {},
    mergeClientSimilar: {},
    mergeClientNoSimilar: [],
  },
  reducers: {
    getCandSimilar: (state, action) => {
      state.mergeCandSimilar = action.payload;
    },
    getCandNoSimilar: (state, action) => {
      state.mergeCandNoSimilar = action.payload;
    },
    getCandEduOne: (state, action) => {
      state.mergeCandEduOne = action.payload;
    },
    getCandEduTwo: (state, action) => {
      state.mergeCandEduTwo = action.payload;
    },
    getCandExpOne: (state, action) => {
      state.mergeCandExpOne = action.payload;
    },
    getCandExpTwo: (state, action) => {
      state.mergeCandExpTwo = action.payload;
    },
    getCandRefOne: (state, action) => {
      state.mergeCandRefOne = action.payload;
    },
    getCandRefTwo: (state, action) => {
      state.mergeCandRefTwo = action.payload;
    },
    getCandMisOne: (state, action) => {
      state.mergeCandMisOne = action.payload;
    },
    getCandMisTwo: (state, action) => {
      state.mergeCandMisTwo = action.payload;
    },
    getCandStore: (state, action) => {
      state.mergeCandCombine = { ...state.mergeCandCombine, ...action.payload };
    },
    getClientSimilar: (state, action) => {
      state.mergeClientSimilar = action.payload;
    },
    getClientNoSimilar: (state, action) => {
      state.mergeClientNoSimilar = action.payload;
    },
  },
});


export const getMergeCandidate = (id1, id2) => async (dispatch, rejectWithValue) => {
  try{
    const response = await Api.get("candidate/merge/" + id1+ "/" + id2);

    // console.log("response", Object.keys(response.data.non_similar[0]).length)


    dispatch(getCandSimilar(response.data.similar))
    dispatch(getCandNoSimilar(response.data.non_similar))

    dispatch(getCandEduOne(response.data.education[0]));
    dispatch(getCandEduTwo(response.data.education[1]));

    dispatch(getCandExpOne(response.data.experience[0]));
    dispatch(getCandExpTwo(response.data.experience[1]));

    dispatch(getCandRefOne(response.data.reference[0]));
    dispatch(getCandRefTwo(response.data.reference[1]));

    dispatch(getCandMisOne(response.data.additional_information[0]));
    dispatch(getCandMisTwo(response.data.additional_information[1]));

  } catch (err) {
    throw rejectWithValue(err.message);
  }
};

export const getMergeClient = (id1, id2) => async (dispatch, rejectWithValue) => {
  try{
    const response = await Api.get("client/merge/" + id1+ "/" + id2);

    // console.log("response", Object.keys(response.data.non_similar[0]).length)


    dispatch(getClientSimilar(response.data.similar))
    dispatch(getClientNoSimilar(response.data.non_similar))

  } catch (err) {
    throw rejectWithValue(err.message);
  }
};

export const { getCandSimilar, getCandNoSimilar, getCandEduOne, getCandEduTwo, getCandExpOne, getCandExpTwo, getCandRefOne, getCandRefTwo, getCandMisOne, getCandMisTwo, getCandStore, getClientSimilar, getClientNoSimilar } = mergeSlice.actions;
export default mergeSlice.reducer;
