// import React from 'react';
import SignLogo from '../../../assets/signature.jpg';

export const rptProposal = (props) => {

    // console.log("props", props.requirement)
    // const proposalBody = props.requirement;

    const proposalBody = `
    <html>
        <head></head>
    
        <body>
            <div>
                <p style='margin-bottom:0cm;line-height:normal;text-autospace:
                    none'><b><span style='font-size:12.0pt'>PRIVATE &amp; CONFIDENTIAL</span></b>
                    <br><br><span style='color:black;background:white'>` + props.date_rpt + `<br><br>Mr.
                    <br>CEO
                    <br><br>Dear
                    <br>
                    </span>
                </p>
                <p style='margin-bottom:0cm;line-height:normal;text-autospace:none; font-size:12.0pt; font-weight:bold;'><u>RE: Executive Search Assignment – title</u></p>
                <p style='margin-bottom:0cm;line-height:0.2pt;text-autospace:none'><span style='font-size:5.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>
                <p style='text-align:justify;text-justify:inter-ideograph;line-height:1.0;margin-top:-5px;'>Thank you for including Lim-Loges &amp; Masters in the discussion to undertake this confidential search for your ` + props.job_title + `. This letter outlines our understanding of your needs as well as our search process, staffing, compensation parameters, and details of our fee and expense arrangement.
                    <br><br>If you are in agreement with this engagement letter, we ask that you sign and return to us the acknowledgement form, which authorizes us to proceed with the search assignment. Please return the acknowledgement via email.
                </p>

                <p style='margin-bottom:0cm;line-height:0.2pt;text-autospace:none'><span style='font-size:5.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>
                
                <p style='margin-bottom:0cm;line-height:normal;text-autospace:none; font-size:12.0pt; font-weight:bold;'><u>COMPANY OVERVIEW</u></p>
                
                <p style='margin-bottom:0cm;line-height:0.2pt;text-autospace:
                none'><span style='font-size:5.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

                <p style='margin:0cm;text-align:justify;text-justify:inter-ideograph;
                background:white;line-height:1.0'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
                color:black;'>` + props.company_overview + `</span></p>

                <p style='margin-bottom:0cm;line-height:0.2pt;text-autospace:none'><span style='font-size:5.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>
    
                <p style='margin-bottom:0cm;line-height:normal;text-autospace:none; font-size:12.0pt; font-weight:bold;'><u>OUR UNDERSTANDING OF YOUR REQUIREMENT</u></p>

                <p style='margin-bottom:0cm;line-height:0.2pt;text-autospace:none'><span style='font-size:5.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

                <p style='margin-bottom:0cm;line-height:normal;text-autospace:none'>We include a copy of your job description (Appendix 1).</p>
                ` + props.requirement + `

                <p style='margin-bottom:0cm;line-height:normal;text-autospace:none; font-size:12.0pt; font-weight:bold;'><u>THE PARTNERSHIP</u></p>

                <p style='margin-bottom:0cm;line-height:0.2pt;text-autospace:none'><span style='font-size:5.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

                <p style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:1.0;punctuation-wrap:simple;text-autospace:none'>Our Experience have shown that the most successful search assignments are those in which we work closely and partner with our client. While we seek to identify and recommend qualified candidates for a position, you and your colleagues will decide whom to hire. There are several ways in which you can enhance this partnership:</p>

                <ul style='line-height:1.0'>
                    <li>
                        <p style='line-height:1.0; text-align:justify'>Indicate clearly those areas relevant to the search that you wish to keep confidential.</p>
                    </li>
                    <li>
                        <p style='line-height:1.0; text-align:justify'>Provide timely feedback to Lim-Loges & Masters on all aspects of the assignment.</p>
                    </li>
                    <li>
                        <p style='line-height:1.0; text-align:justify'>Schedule interview promptly with candidates and report your findings as soon as possible.</p>
                    </li>
                    <li>
                        <p style='line-height:1.0; text-align:justify'>Provide Lim-Loges & Masters with Information on candidates you may have identified from other sources or from within your organisation, so that they may be evaluated as part of the search process.</p>
                    </li>
                    <li>
                        <p style='line-height:1.0; text-align:justify'>Provide information to candidates about your company that will enable the, to make informed career decisions.</p>
                    </li>
                </ul> 

                <p>&nbsp;</p>
                <p style='margin-bottom:0cm;line-height:normal;text-autospace:none; font-size:12.0pt; font-weight:bold;'><u>THE CONSULTING TEAM AND TIMING</u></p>

                <p style='margin-bottom:0cm;line-height:0.2pt;text-autospace:none'><span style='font-size:5.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

                <p style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;
                margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:1.0;punctuation-wrap:simple;text-autospace:none'>A key component of the Lim-Loges &amp; Masters executive search process is the appointment of the consulting team Lelia Lim, will have the primary responsibility for the assignment, including candidate development, interviews, report writing, references, education verification, compensation negotiation and follow-up.  Lelia will be supported by Victor Kang and our project coordinators.
                <br><br>Depending on the lead time and recruitment size of each recruitment project, various members of our teams across Asia pacific will be added or removed as needed.
                </p>

                <p style='margin-bottom:0cm;line-height:11.95pt;text-autospace:none'><span style='font-family:"Times New Roman",serif'>&nbsp;</span></p>
    
                <p style='margin-bottom:0cm;line-height:99%;text-autospace:none'>Main contact details:</p>
    
                <p style='margin-bottom:0cm;line-height:11.05pt;text-autospace:none'><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

                <table border=1 cellspacing=0 cellpadding=0 width=606 style='width:100%;'>
                `+ props.client_proposal_has_user.map((client) => 
                        `<tr style='border-bottom:1px solid black'>
                        <td width=264 valign=top style='width:197.75pt; padding:0cm 5.4pt 0cm 5.4pt;'>
                        <p style='margin-bottom:0cm;line-height:11.05pt;text-autospace:
                        none'><span style='color:black;'> ` + client.user.first_name + ` ` + client.user.last_name + ` </span></p>
                        <p style='margin-bottom:0cm;line-height:11.05pt;text-autospace:
                        none'><span style='color:black;background:yellow'></span></p>
                        </td>
                        <td width=60 valign=top style='width:45.0pt; padding:0cm 5.4pt 0cm 5.4pt'>
                        <p style='margin-bottom:0cm;line-height:11.05pt;text-autospace:
                        none'><span style='color:black;background:yellow'>Mobile:</span></p>
                        <p style='margin-bottom:0cm;line-height:11.05pt;text-autospace:
                        none'><span style='color:black;background:yellow'>Email: </span></p>
                        </td>
                        <td width=282 valign=bottom style='width:211.5pt;padding:0cm 5.4pt 0cm 5.4pt'>
                        <p style='margin-bottom:0cm;line-height:11.05pt;text-autospace:
                        none'><span style='color:black;background:yellow'>` + client.user.phone_no_code + ` ` + client.user.phone_no +`</span></p>
                        <p style='margin-bottom:0cm;line-height:11.05pt;text-autospace:
                        none'><a href="` + client.user.email + `"><span style='color:black;
                        background:yellow;text-decoration:none'>` + client.user.email + `</span></a></p>
                        </td>
                    </tr>`
                ) +`
                </table>

                <p style='margin-bottom:0cm;text-align:justify;text-justify:inter-ideograph;line-height:99%;text-autospace:none'>&nbsp;</p>
    
                <p style='margin-bottom:0cm;text-align:justify;text-justify:inter-ideograph;line-height:1.0;text-autospace:none'>You will participate in a first calibration update, 2 weeks from your approval and thereafter regular updates on status of candidates every 2 weeks. During these updates we will provide our market feedback and the progress on our mapping. At the end of 4-6 weeks a shortlist of candidates would be presented. You may interview as you see fit. However we note that typically six to 12 weeks’ notice would be served before a candidate joins a new employer.</p>
                
                <p style='margin-bottom:0cm;text-align:justify;text-justify:inter-ideograph;line-height:99%;text-autospace:none'>&nbsp;</p>
                
                <p style='margin-bottom:0cm;line-height:normal;text-autospace:none; font-size:12.0pt; font-weight:bold;'><u>PROFESSIONAL FEES AND EXPENSES</u></p>

                <p style='margin-bottom:0cm;line-height:14.7pt;text-autospace:none'><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>
    
                <p style='text-align:justify;text-justify:inter-ideograph;line-height:1.0;vertical-align:baseline'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black'>Our professional retainer is ` + props.domestic_fee + `% for a domestic engagement and ` + props.regional_fee +`% for a regional or international engagement of the first year’s estimated cash <span style='color:black'>compensation<a href="#fn1" id="ref1" title=""><sup><sup><span
                style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
                color:black'>[1]</span></sup></sup></a> (<span style='background:red'>e.g.</span> salary + annual incentive bonus + signing bonus<a href="#_ftn2" name="_ftnref2"
                title=""><sup><sup><span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;color:black'>[2]</span></sup></sup></a>, <span style='background:red'>etc)</span> for the position with a minimum fee of US$25,000. </span>For this engagement the initial retainer is the minimum fee of US$25,000.</span></p>

                <p style='text-align:justify;text-justify:inter-ideograph;vertical-align:baseline'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>
    
                <p style='text-align:justify;text-justify:inter-ideograph;vertical-align:baseline;line-height:1.0'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Expected compensation range</span></p>
                `+ props.expected_compensation +`

                <p style='margin-bottom:0cm;line-height:normal;text-autospace:none'><b>Payment Terms</b></p>
                <p style='margin-bottom:0cm;line-height:12.1pt;text-autospace:none'><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>
                <p style='margin-bottom:0cm;line-height:normal;text-autospace:none'>The professional fee will be payable <u>according to our milestone deliverables</u> as follows:</p>
    
                <p style='margin-bottom:0cm;line-height:normal;text-autospace:none'><span style='font-family:"Times New Roman",serif'>&nbsp;</span></p>

                <p style='text-align:justify;text-justify:inter-ideograph;line-height:1.0;vertical-align:baseline'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black'>Under our milestone retainer arrangement, you will pay the projected professional retainer in 3 equal amounts of ` + props.payment_term + ` upon signing this engagement letter; upon our sending you the full candidate reports for interview; and the remainder upon 90 days from the first invoice or upon offer acceptance by the selected candidate, whichever earlier. The final invoice will be adjusted to reflect the estimated first year’s compensation as described above in the accepted offer letter for the position, subject to our minimum retainer, if specified. Our clients appreciate us for delivery within 90 days. </p>

                <p style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:normal;punctuation-wrap:simple;text-autospace:none'>&nbsp;</p>

                <p style='text-align:justify;text-justify:inter-ideograph;line-height:1.0;vertical-align:baseline'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black'>This professional fee is net of tax and not subject to any deductions from withholding taxes or any other forms of taxes applicable. All payment of taxes shall remain the responsibility of the client at all times. Any deduction at source will be claimable against the <b>client ()</b> directly with interest compounded. The fee is due seven (7) days from date of invoice.</p>
    
                <p style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:normal;punctuation-wrap:simple;text-autospace:none'>&nbsp;</p>
                
                <p style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:1.0;punctuation-wrap:simple;text-autospace:none'>Any amounts owed by the <b>client()</b> under this Agreement that are not paid when due shall bear interest, from the time the payment was due until the time paid at a rate of 1.5% per day compounded daily. All invoices are payable upon presentation.</p>
    
                <p style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:normal;punctuation-wrap:simple;text-autospace:none'>&nbsp;</p>
    
                <p style='margin-bottom:0cm;line-height:normal;text-autospace:none'><b>Expenses</b></p>

                <p style='margin-bottom:0cm;text-align:justify;text-justify:inter-ideograph;line-height:normal;text-autospace:none'><span style='font-family:"Times New Roman",serif'>&nbsp;</span></p>
    
                <ul style='line-height:200%'>
                    <li>
                        <p style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;margin-left:18.0pt;text-align:justify;text-justify:inter-ideograph;text-indent:-18.0pt;line-height:1.0;punctuation-wrap:simple;text-autospace:none'>Direct expenses: Expenses directly related to the engagement, including all travel expenses associated with interviews, selection, and visits to the client location, are invoiced monthly at cost without mark-up. No significant travel expenses for the search will be incurred without prior client approval.</p>
                    </li>
                    <li>
                        <p style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;margin-left:18.0pt;text-align:justify;text-justify:inter-ideograph;text-indent:-18.0pt;line-height:1.0;punctuation-wrap:simple;text-autospace:none'>Indirect expenses: This category includes incremental costs such as videoconferences and video interviews, internal/external research resources, data/networking communications, and database subscriptions. Because these costs are difficult to allocate in exact amounts to a specific search engagement, indirect expenses are calculated as ` + props.indirect_expenses + ` of the expected first year compensation from which the fee is also determined and equally invoiced with each professional fee.</p>
                    </li>
                </ul>

                <hr></hr>

                <sup id="fn1">1<a href="#ref1" title="Jump back to footnote 1 in the text.">For sake of clarity, “first year” assumes a normal fiscal year.</a></sup>
                <sup id="fn1">2<a href="#ref1" title="Jump back to footnote 1 in the text.">Any portion of the signing bonus that compensates the new employee for foregone bonus, stock option value, and similar variable compensation incentives at their previous employer will not be included in the professional retainer for service calculation. Long-term incentives, stock-based compensation (e.g., stock options), and any payments for relocation expenses to the candidate are not included in the retainer calculation.</a></sup>

                <p style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;margin-left:18.0pt;text-align:justify;text-justify:inter-ideograph;line-height:normal;punctuation-wrap:simple;text-autospace:none'>&nbsp;</p>
    
                <p style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:1.0;punctuation-wrap:simple;text-autospace:none'>This expense invoice is payable within seven (7) days of issuance. This expense is <u>net of tax</u> and not subject to any deductions from withholding taxes or any other forms of taxes applicable. All payment of taxes shall remain the responsibility of the client at all times. Any deduction at source will be claimable against the <b>client ()</b> directly with interest compounded.</p>
    
                <p style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:normal;punctuation-wrap:simple;text-autospace:none'>&nbsp;</p>
                
                <p style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:1.0;punctuation-wrap:simple;text-autospace:none'>Any amounts owed by the <b>client()</b>  under this Agreement that are not paid when due shall bear interest, from the time the payment was due until the time paid at a rate of ` + props.compound_interest + ` per day compounded daily. All invoices are payable upon presentation.</p>

                <p style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;
                margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
                normal;punctuation-wrap:simple;text-autospace:none'>&nbsp;</p>
                
                <p style='text-align:justify;text-justify:inter-ideograph'>In the event the client <b>client()</b> one of its portfolio companies, parent company, subsidiaries, or affiliates hire any candidate for another position within 12 months after receipt of the résumé submitted by Lim-Loges &amp; Masters, that hiring will constitute a separate placement, and you agree to notify Lim-Loges &amp; Masters pay a fee of ` + props.subseq_cand_fee + ` of such candidate’s first year’s estimated cash compensation as defined above. </p>
                
                <p style='margin-bottom:0cm;text-align:justify;text-justify:
                inter-ideograph;line-height:99%;text-autospace:none'>&nbsp;</p>
                
                <p style='margin-bottom:0cm;text-align:justify;text-justify:
                inter-ideograph;line-height:99%;text-autospace:none'>Hence should you work with us for more than a single search, your search fee per hire is increasingly reduced.  </p>
                
                <p style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;
                margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
                normal;punctuation-wrap:simple;text-autospace:none'>&nbsp;</p>
                
                <p style='margin-bottom:0cm;line-height:99%;text-autospace:
                none'><b><span style='font-size:12.0pt;line-height:99%;'>Off-limits restrictions</span></b></p>
                
                <p style='margin-top:0cm;margin-right:5.0pt;margin-bottom:0cm;
                margin-left:0cm;line-height:90%;punctuation-wrap:simple;text-autospace:none'><span
                style='background:yellow'>&nbsp;</span></p>

                ` + props.off_limit_restriction + `

                <p style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:normal;punctuation-wrap:simple;text-autospace:none'>&nbsp;</p>
                
                <p style='margin-bottom:0cm;line-height:normal;text-autospace:none; font-size:12.0pt; font-weight:bold;'><u>OUR ADVANTAGE</u></p>
                
                <p style='margin-bottom:0cm;line-height:12.1pt;text-autospace:none'><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>
                
                <p style='margin-bottom:0cm;line-height:normal;text-autospace:none'>Lim-Loges &amp; Masters offer three services namely:</p>
                
                <p style='margin-bottom:0cm;line-height:normal;text-autospace:none'><span style='font-family:"Times New Roman",serif'>&nbsp;</span></p>

                <ol style='margin-top:0cm' start=1 type=1>
                    <li style='margin-bottom:0cm;text-align:justify;text-justify:inter-ideograph;line-height:normal;punctuation-wrap:simple;text-autospace:none'>Executive Search </li>
                    <li style='margin-bottom:0cm;text-align:justify;text-justify:inter-ideograph;line-height:normal;punctuation-wrap:simple;text-autospace:none'>Transition Management; and </li>
                    <li style='margin-bottom:0cm;text-align:justify;text-justify:inter-ideograph;line-height:normal;punctuation-wrap:simple;text-autospace:none'>Disruption Management </li>
                </ol>
    
                <p style='margin-bottom:0cm;line-height:14.0pt;text-autospace:none'><span style='font-family:"Times New Roman",serif'>&nbsp;</span></p>
    
                <p style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:1.0;punctuation-wrap:simple;text-autospace:none'>As part of our search process, we may assist you in determining the most appropriate Leadership Competencies for the role and we may evaluate candidate fit against these desired criteria, as well as your corporate culture. We would be pleased to engage you with any of our consultants in the said services as your business requires.</p>
    
                <p style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;margin-left:0cm;text-align:justify;text-justify:inter-ideograph;punctuation-wrap:simple;text-autospace:none'>&nbsp;</p>

                <p style='margin-bottom:0cm;line-height:normal;text-autospace:none; font-size:12.0pt; font-weight:bold;'><u>CUSTOMER SERVICE</u></p>
    
                <p style='margin-bottom:0cm;line-height:14.7pt;text-autospace:none'><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>
                
                <p style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:1.0;punctuation-wrap:simple;text-autospace:none'>Lim-Loges &amp; Masters actively seeks client feedback on the quality of our work. At the conclusion of the engagement, we may ask you to participate in a Client Satisfaction Survey conducted by an independent party. We seek your candid assessment of our work so that we may be responsive to any suggestions regarding our professional services.</p>
                
                <p align=right style='margin-top:0cm;margin-right:6.0pt; margin-bottom:0cm;margin-left:0cm;text-align:right;line-height:98%;punctuation-wrap:simple;text-autospace:none'><span style='font-size:12.0pt;line-height:98%;font-family:"Times New Roman",serif'></span></p>
                
                <p style='margin-bottom:0cm;line-height:normal;text-autospace:none; font-size:12.0pt; font-weight:bold;'><u>EXCLUSIVITY &amp; TERMS</u></p>
                
                <p style='margin-bottom:0cm;line-height:12.05pt;text-autospace:none'><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>
                
                <p style='margin-bottom:0cm;line-height:99%;text-autospace:none'><b><span style='font-size:12.0pt;line-height:99%'>Guarantee</span></b></p>
                
                <p style='margin-bottom:0cm;line-height:14.35pt;text-autospace:none'><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>
                
                <p style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:1.0;punctuation-wrap:simple;text-autospace:none'>If you release the placed candidate for performance-related issues during the ` + props.guaranty_period + ` of his/her employment, Lim-Loges &amp; Masters will conduct a search to replace the placed candidate for no additional professional fee, charging only expenses as incurred, if the following conditions are met:</p>

                <p style='margin-bottom:0cm;text-align:justify;text-justify:inter-ideograph;line-height:2.45pt;text-autospace:none'><span style='font-family:"Times New Roman",serif'>&nbsp;</span></p>
    
                ` + props.guaranty_condition + `
                
                <p style='margin-bottom:0cm;line-height:0.2pt;text-autospace:none'><span style='font-size:5.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

                <p style='margin-top:0cm;margin-right:-1.6pt;margin-bottom:
                0cm;margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
                1.0;punctuation-wrap:simple;text-autospace:none'><i>Onboarding Guarantee</i>:In our experience the first three (3) months of a candidate joining the company is a period of adjustment for all parties concerned. <b>During the Guarantee period, the </b><b><span style='font-family:"Times New Roman",serif'> </span></b><b>Client, the Lead Principal will be in contact at regular intervals with client and placement as an on-boarding health check.</b> These contacts can be in the form of constructive feedback sessions with both parties or independently through telephone calls and/or meetings to ensure that parties continue to work towards a successful placement in the company.</p>
                
                <p style='margin-bottom:0cm;line-height:0.2pt;text-autospace:
                none'><span style='font-size:5.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>
                
                <p style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;
                margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:1.0;punctuation-wrap:simple;text-autospace:none'>This guarantee does not apply if the employee leaves the organization voluntarily or is terminated for reasons other than job performance, including but not limited to health, staff reduction, economic circumstances, merger or acquisition, and organizational realignment and restructuring</p>
                
                <p style='margin-bottom:0cm;line-height:0.2pt;text-autospace:
                none'><span style='font-size:5.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>
                
                <p style='margin-bottom:0cm;line-height:99%;text-autospace:
                none'><b><span style='font-size:12.0pt;line-height:99%'>Non-solicitation</span></b>
                <br><br>Any candidate placed by Lim-Loges &amp; Masters into your organization will not be recruited by our firm for as long as he/she is employed by your organization. In addition, for a period of nine (9) months after the initiation date of this engagement, Lim-Loges &amp; Masters will not directly or indirectly recruit the hiring manager(s) or the direct reports to the placed candidate. You agree to attach the list of the applicable direct reports with their titles to this engagement letter so that we may block them accordingly.
                <br><br>Exceptions to this provision include instances where (i) this agreement is cancelled without cause by you prior to Lim-Loges &amp; Masters’ receipt of the final fee payment; (ii) you do not pay Lim-Loges &amp; Masters’ fees and/or expenses; (iii) you authorize an exception; (iv)  you violate this agreement; or (v) there is a change in controlling ownership of the client organization.
                <br>
                </p>

                <p style='margin-bottom:0cm;line-height:normal;text-autospace:
                none'><b><span style='font-size:12.0pt'>Exclusivity</span></b>
                <br><br>By signing this engagement letter, you agree that Lim-Loges &amp; Masters will exclusively conduct this engagement and subject all relevant candidates to the same qualification process, including any possible candidates identified by you who are inside or outside your firm. The professional fee applies even if an internal candidate (or an external candidate referred or identified by you) emerges as your choice.
                <br>
                </p>

                <p style='margin-bottom:0cm;line-height:normal;text-autospace:
                none'><b><span style='font-size:12.0pt'>Term</span></b>
                <br><br>If due to business circumstances, you need to place this recruitment project on hold, you may do so for a period of up to 30 days. If the project is not reinitiated within 30 days, this engagement will be considered cancelled.
                </p>
                
                <p style='margin-bottom:0cm;line-height:normal;text-autospace:
                none'><b><span style='font-size:12.0pt'>Cancellation</span></b>
                <br><br>You may cancel the recruitment engagement at any time. In the event that you cancel the engagement the fees will be prorated to the date of official cancellation, subject to the minimum of the first invoice, which is non-refundable and cannot be prorated.
                <br><br>In the event that (i) you cancel the engagement and (ii) your organization hires an internal or external candidate evaluated or presented by Lim-Loges &amp; Masters for the position within twelve 
                <br><br>(12) months of the date of cancellation, any remaining unpaid balance of the professional fee will be due and payable to Lim-Loges &amp; Masters within seven (7) working days.
                <br><br>Professional fees paid are not refundable or transferable from one recruitment engagement to another in the event of cancellation.
                <br>
                </p>

                <p style='margin-bottom:0cm;line-height:99%;text-autospace:
                none'><b><span style='font-size:12.0pt;line-height:99%'>Publicity</span></b>
                <br><br>Lim-Loges &amp; Masters has the right to post or publicize the result of this engagement. In the event your organization advertises the recruitment engagement, distributes a solicitation for candidates or referrals, and/or publicizes the appointment of the successful candidate, you agree to name Lim-Loges &amp; Masters as the firm conducting the search in such communications.
                <br>
                </p>

                <p style='margin-bottom:0cm;line-height:99%;text-autospace:
                none'><b><span style='font-size:12.0pt;line-height:99%'>Compliance</span></b>
                <br><br>The search will be conducted in compliance with all applicable laws, regulations and guidelines. If your organization is unable to comply, Lim-Loges &amp; Master has the right to cancel the search and collect the fee and expenses due.
                <br>
                </p>
                
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                margin-left:183.0pt;line-height:99%;text-autospace:none'><b><span
                style='font-size:10.0pt;line-height:99%'>*****************</span></b></p>

                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                margin-left:183.0pt;line-height:99%;text-autospace:none'><span
                style='font-size:12.0pt;line-height:99%;font-family:"Times New Roman",serif'>  
                </span></p>
                <div style="page-break-after: always;"></div>

                <p style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;
                margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
                1.0;punctuation-wrap:simple;text-autospace:none'><i>`+ props.sign_name +`,</i><i></i> we are delighted to have the opportunity to work with you on this important assignment for ` + props.company_name + `. We recognize the importance of this recruitment and can assure you of our commitment on your behalf. These terms and conditions remain valid for thirty (30) days from the date of this document.</p>
                <br>
                <p style='margin-bottom:0cm;line-height:1.0;text-autospace:
                none'>Please call me if you have any questions or require any information.</p>
                
                <p style='margin-bottom:0cm;line-height:11.8pt;text-autospace:
                none'><span style='font-family:"Times New Roman",serif'>&nbsp;</span></p>

                <p style='margin-bottom:0cm;line-height:normal;text-autospace:
                none'>Yours sincerely,</p>

                <p style='text-align:left'>
                    <img src="`+ SignLogo +`" alt="" width="21"></img>
                </p>
                
                <p style='margin-bottom:0cm;line-height:normal;text-autospace:none'>Lelia Lim</p>

                <p style='margin-bottom:0cm;line-height:99%;text-autospace:none'>Managing Partner, Asia Pacific</p>
    
                <p style='margin-bottom:0cm;line-height:99%;text-autospace:none'><i>EA Registration Personnel: R1106051 (Lelia Lim Mei Ling<a name=page6></a><aname=page7></a>)</i></p>
    
                <p style='margin-bottom:0cm;line-height:normal;text-autospace:none'><b><u><span style='font-size:12.0pt'></span></u></b></p>
                
                <p style='margin-bottom:0cm;line-height:normal;text-autospace:none; font-size:12.0pt; font-weight:bold;'><u>ACKNOWLEDGEMENT</u></p>

                <p style='margin-bottom:0cm;line-height:0.2pt;text-autospace:none'><span style='font-size:5.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>
    
                <p style='margin-bottom:0cm;line-height:99%;text-autospace:
                none'>`+ props.company_name +` authorize Lim-Loges &amp; Masters to proceed with this Executive Search Assignment for:
                <br><br>Position: `+ props.job_title +`
                <br>Date:<span style='font-family:"Times New Roman",serif'></span>
                <br><br>Please indicate your acceptance of the terms and conditions set forth above by signing and returning a copy of this agreement via email.
                <br><br><br><br><br>
                </p>

                <table border=0 style='width:100%'>
                    <tr>
                        <td style='border-left: none; border-right: none'>
                            <span style='text-align:left'>____________________________________</span>
                        </td>
                        <td style='border-left: none; border-right: none'>
                            <span style='text-align:right'>____________________________________</span>
                        </td>
                    </tr>
                    <tr>
                        <td style='border-left:none; border-right:none;'>
                            <span style='text-align:left'>Name:<br>Title:<br>Company:</span>
                        </td>
                        <td style='border-left:none; border-right:none;'>
                            <span>Date:</span>
                        </td>
                    </tr>    
                        
                </table>

                <p style='margin-bottom:0cm;line-height:normal;text-autospace:none'>Invoices should be addressed for the attention of:</p>
                
                <p style='margin-bottom:0cm;line-height:10.0pt;text-autospace:none'><span style='font-family:"Times New Roman",serif'>&nbsp;</span></p>

                <table border=0 style='width:100%'>
                    <tr>
                        <td style='border-left: none; border-right: none'>Name:</td>
                        <td style='border-left: none; border-right: none'>Mr/Ms________________________________________</td>
                    </tr>
                    <tr>
                        <td style='border-left: none; border-right: none'>Contact No:</td>
                        <td style='border-left: none; border-right: none'>_____________________________________________</td>
                    </tr>
                    <tr>
                        <td style='border-left: none; border-right: none'>Email:</td>
                        <td style='border-left: none; border-right: none'>_____________________________________________</td>
                    </tr>
                    <tr>
                        <td style='border-left: none; border-right: none'>Billing address:</td>
                        <td style='border-left: none; border-right: none'>______________________________________________</td>
                    </tr>
                    <tr>
                        <td style='border-left: none; border-right: none'></td>
                        <td style='border-left: none; border-right: none'>______________________________________________</td>
                    </tr>
                   
                </table>

                <div style="page-break-after: always;"></div>
    
                <p style='margin-bottom:0cm;line-height:10.0pt;text-autospace:none'><b><u><span style='font-size:12.0pt'>Appendix 1</span></u></b></p>
                
                <p style='margin-bottom:0cm;line-height:10.0pt;text-autospace:none'><b><u><span style='font-size:12.0pt'><span style='text-decoration:none'>&nbsp;</span></span></u></b></p>
                
                <p style='margin-top:0cm;margin-right:1.0pt;margin-bottom:0cm;
                margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
                98%;punctuation-wrap:simple;text-autospace:none'>&nbsp;</p>
                
                <p>&nbsp;</p>
                
                <p>&nbsp;</p>
                
                <p>&nbsp;</p>
                
                <p>&nbsp;</p>
                
                <p>&nbsp;</p>
                
                <p>&nbsp;</p>
                
                <p>&nbsp;</p>
                
                <br>
                
                <hr width="33%"></hr>

            </div>
        </body>

    
    </html>
    `
    
    return proposalBody
}

export default rptProposal;