import {createSlice} from '@reduxjs/toolkit';
import Api from '../../core/services/api.service';

export const candidateSlice = createSlice({
    name: "candidate",
    initialState: {
        candidateDetails: {},
        candidateLanguages: [],
        candidateEducation: [],
        candidateExperience: [],
        candidateReference: [],
        candidateMiscellaneous: [],
        candidateConsent: [],
        candFilter: {
          txtsearchCand: "",
          ddlStatus: "",
          txtcompName: "",
          txtjobtitle: "",
          ddlJobFunction: "",
          ddlLanguage: "",
          ddlIndustry: ""
        },
        candidateList: [],
        candidateAllList: [],
        candidateTempData: [],
        candidateTempFilData: [],
        candidateAssignList: [],
    },
    reducers: {
        getDataCandidate: (state, action) => {
            state.candidateDetails = { ...state.candidateDetails, ...action.payload };
        },
        getEmptyCandidate: (state, action) => {
            state.candidateDetails = action.payload;
        },
        getDataCandidate_languages: (state, action) => {
          state.candidateLanguages = action.payload;
        },
        getDataCandidate_education: (state, action) => {
          state.candidateEducation = action.payload;
        },
        getDataCandidate_experience: (state, action) => {
          state.candidateExperience = action.payload;
        },
        getDataCandidate_reference: (state, action) => {
          state.candidateReference = action.payload;
        },
        getDataCandidate_miscellaneous: (state, action) => {
          state.candidateMiscellaneous = action.payload;
        },
        getDataCandidate_consent: (state, action) => {
          state.candidateConsent = action.payload;
        },
        setListCandidate: (state, action) => {
          state.candidateList = action.payload;
        },
        setAllListCandidate: (state, action) => {
          state.candidateAllList = action.payload;
        },
        setTempCandidateData: (state, action) => {
          state.candidateTempData = action.payload;
        },
        setTempCandidateFilData: (state, action) => {
          state.candidateTempFilData = action.payload;
        },
        setStoreCandFilter: (state, action) => {
          state.candFilter = { ...state.candFilter, ...action.payload };
        },
        getListAssignCandidate: (state, action) => {
          state.candidateAssignList = action.payload;
        },
    }
});

export const getCandidateDetail = (id) => async (dispatch, rejectWithValue) => {
    try {
      const response = await Api.get("candidate/" + id);
    
      dispatch(getDataCandidate(response.data));
      dispatch(getDataCandidate_languages(response.data.languages.map(lang => ({ value: lang }))))

      dispatch(getDataCandidate_education(response.data.candidate_education));
      dispatch(getDataCandidate_experience(response.data.candidate_experiences));
      dispatch(getDataCandidate_reference(response.data.candidate_reference));
      dispatch(getDataCandidate_miscellaneous(response.data.candidate_miscellaneous));
      dispatch(getDataCandidate_consent(response.data.candidate_consent_client));


    } catch (err) {
      throw rejectWithValue(err.message);
    }
};

export const getCandidateList = (searchVal, compName, jobTitle, statusCand, jobFunction, language, industryCand) => async (dispatch, rejectWithValue) => {
  try {
    const response = await Api.get("candidate/all");

    let newList = [];

    const vowelARegex = /[à,æ,ã,å,ā,á,â,ä]/gi;
    const vowelERegex = /[ê,é,è,ë,ē]/gi;
    const vowelIRegex = /[ì,ī,ï,í,î]/gi;
    const vowelORegex = /[œ,œ,ø,ō,õ,ô,ö,ò]/gi;
    const vowelURegex = /[ū,ü,ú,û,ù]/gi;
    

    if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language === "" && industryCand ==="") {
      newList = response.data.filter((candidate) => {
        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase())
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language === "" && industryCand ==="") {
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase()))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName !== "" && jobTitle !== "" && statusCand === "" && jobFunction === "" && language === "" && industryCand ==="") {
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase()))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName !== "" && jobTitle !== "" && statusCand !== "" && jobFunction === "" && language === "" && industryCand ==="") {
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName !== "" && jobTitle !== "" && statusCand !== "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find(
            (e) => e.job_function.length !== 0 ? 
              e.job_function.includes(jobFunction)
            :[] 
          )
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName !== "" && jobTitle !== "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find(
            (e) => e.job_function.length !== 0 ? 
              e.job_function.includes(jobFunction)
            :[] 
          ) && 
          candidate.languages.includes(language)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName !== "" && jobTitle !== "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } 
    
      else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language === "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } 
      else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language !== "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.languages.includes(language)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language !== "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language === "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language === "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } 
      else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language !== "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.languages.includes(language)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language !== "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language === "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction === "" && language === "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase()))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName === "" && jobTitle !== "" && statusCand !== "" && jobFunction === "" && language === "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName === "" && jobTitle !== "" && statusCand !== "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction))
        );
      });

      dispatch(setAllListCandidate(newList));
    } 
      
      else if (searchVal !== "" && compName === "" && jobTitle !== "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName === "" && jobTitle !== "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction !== "" && language === "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } 
    
      else if (searchVal !== "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction === "" && language !== "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.languages.includes(language)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction === "" && language !== "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction === "" && language === "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language === "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.status.toLowerCase().includes(statusCand)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) && 
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) 
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language)
        );
      });

      dispatch(setAllListCandidate(newList));
    } 
    
      else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language !== "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.languages.includes(language)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language !== "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language === "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) 
        );
      });

      dispatch(setAllListCandidate(newList));
    } 
      
      else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language === "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language !== "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.languages.includes(language)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language !== "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language === "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } 
    // Next
      else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language === "" && industryCand ==="") {
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase()))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName !== "" && jobTitle !== "" && statusCand === "" && jobFunction === "" && language === "" && industryCand ==="") {
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase()))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName !== "" && jobTitle !== "" && statusCand !== "" && jobFunction === "" && language === "" && industryCand ==="") {
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName !== "" && jobTitle !== "" && statusCand !== "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find(
            (e) => e.job_function.length !== 0 ? 
              e.job_function.includes(jobFunction)
            :[] 
          )
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName !== "" && jobTitle !== "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find(
            (e) => e.job_function.length !== 0 ? 
              e.job_function.includes(jobFunction)
            :[] 
          ) && 
          candidate.languages.includes(language)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName !== "" && jobTitle !== "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } 
    
      else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language === "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language !== "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.languages.includes(language)
        );
      });

      dispatch(setAllListCandidate(newList));
    } 
      else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language !== "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language === "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {
        return(
          
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language === "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {
        return(
          
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } 
      else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language !== "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {
        return(
          
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.languages.includes(language)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language !== "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {

        

        return(
          
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language === "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {

        

        return(
          
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction === "" && language === "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        

        return(
          
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase()))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName === "" && jobTitle !== "" && statusCand !== "" && jobFunction === "" && language === "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        

        return(
          
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName === "" && jobTitle !== "" && statusCand !== "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        

        return(
          
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction))
        );
      });

      dispatch(setAllListCandidate(newList));
    } 
      
      else if (searchVal === "" && compName === "" && jobTitle !== "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {

        

        return(
          
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName === "" && jobTitle !== "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction !== "" && language === "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } 
    
      else if (searchVal === "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction === "" && language !== "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.languages.includes(language)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction === "" && language !== "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction === "" && language === "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language === "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.status.toLowerCase().includes(statusCand)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) 
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language)
        );
      });
      dispatch(setAllListCandidate(newList));
    } 
    
      else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language !== "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.languages.includes(language)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language !== "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language === "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) 
        );
      });

      dispatch(setAllListCandidate(newList));
    } 
      
      else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language === "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language !== "" && industryCand ==="") {
     
      newList = response.data.filter((candidate) => {
        return(
          candidate.languages.includes(language)
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language !== "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {
        return(
          
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    } else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language === "" && industryCand !=="") {
     
      newList = response.data.filter((candidate) => {
        return(
          
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });

      dispatch(setAllListCandidate(newList));
    }    
    else{
      dispatch(setListCandidate(response.data));
      dispatch(setAllListCandidate(response.data));
    }

   
      
  } catch (err) {
    throw rejectWithValue(err.message);
  }
};
  
export const getCandidateAssignList = (id, idassign) => async (dispatch, rejectWithValue) => {
  try {
    const response = await Api.get("client/" + id + "/assignment/" + idassign + "/candidate/all");

    dispatch(getListAssignCandidate(response.data));
   
  } catch (err) {
    throw rejectWithValue(err.message);
  }
};
  
  export const { addTodo, getDataCandidate, getDataCandidate_languages, getDataCandidate_education, getDataCandidate_experience, getDataCandidate_reference, getDataCandidate_miscellaneous, getDataCandidate_consent, setAllListCandidate, setListCandidate, setTempCandidateData, setTempCandidateFilData, setStoreCandFilter, getListAssignCandidate, getEmptyCandidate } = candidateSlice.actions;
  export const showTodo = (state) => state.todo.data;
  export default candidateSlice.reducer;