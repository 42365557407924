import React, { useState, useEffect } from "react";
import {
  OverlayTrigger,
  Popover,
  Spinner,
  Form,
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import { useDispatch } from "react-redux";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";

import axios from "axios";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";

// import { v4 as uuid } from "uuid";

import "./calendar.css";
import { Button } from "react-bootstrap";
import Icon from "@mdi/react";
import {
  mdiAccountOutline,
  mdiCalendar,
  // mdiCalendarBlankOutline,
  mdiClockTimeThreeOutline,
  mdiMicrosoftOutlook,
} from "@mdi/js";

import { storeTitle } from "../../../store/actions/headerTitleSlice";

function WelcomeUser() {
  const { accounts } = useMsal();
  console.log("account", accounts)
  // const username = accounts[0].username;

  return (
    <div className="welcome-text cal">
      Welcome, <b>{accounts[0] ? accounts[0].username : ""}</b>
    </div>
  );
}

function signInClickHandler(instance) {
  instance.loginPopup();
}

function SignInButton() {
  // useMsal hook will return the PublicClientApplication instance you provided to MsalProvider
  const { instance } = useMsal();

  return (
    <Button
      variant="primary"
      style={{ background: "#0078d4" }}
      onClick={() => signInClickHandler(instance)}
      className="cal-btn"
    >
      <Icon path={mdiAccountOutline} />
      Sign In
    </Button>
  );
}

function signOutClickHandler(instance, accounts) {
  const logoutRequest = {
    account: accounts[0],
    mainWindowRedirectUri: "https://jesper.limlogesmasters.com/calendar",
    postLogoutRedirectUri: "https://jesper.limlogesmasters.com/calendar",
  };
  instance.logoutPopup(logoutRequest);

  localStorage.removeItem("id_token_email");
}

function SignOutButton() {
  // useMsal hook will return the PublicClientApplication instance you provided to MsalProvider
  const { instance, accounts } = useMsal();

  return (
    <Button
      variant="danger"
      onClick={() => signOutClickHandler(instance, accounts)}
    >
      <Icon path={mdiAccountOutline} />
      Sign Out
    </Button>
  );
}

async function callApi(accessToken, startDate, endDate) {
  // console.log("startDate", startDate);
  // console.log("endDate", endDate);

  const startDateString =
    startDate !== undefined ? startDate.toISOString() : startDate;
  const endDateString = endDate !== undefined ? endDate.toISOString() : endDate;

  try {
    return await axios.get(
      `https://graph.microsoft.com/v1.0/me/calendarview?startdatetime=${startDateString}&enddatetime=${endDateString}&$top=999`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
          Prefer:
            'outlook.body-content-type="text", outlook.timezone="Singapore Standard Time"',
        },
      }
    );
  } catch (error) {
    console.error("error callApi", error.response);
  }
}

async function createEvent(accessToken) {
  const event = {
    subject: "Let's go for lunch?",
    body: {
      contentType: "HTML",
      content: "Are you available on that time?",
    },
    start: {
      dateTime: "2022-10-03T15:00:00",
      timeZone: "Singapore Standard Time",
    },
    end: {
      dateTime: "2022-10-04T16:00:00",
      timeZone: "Singapore Standard Time",
    },
    location: {
      displayName: "Lignar Engineering",
    },
    attendees: [
      {
        emailAddress: {
          address: "iqbal1410@gmail.com",
          name: "Iqbal Zahid",
        },
        type: "required",
      },
    ],
    // allowNewTimeProposals: true,
    // transactionId: "7E163156-7762-4BEB-A1C6-729EA81755A7",
  };

  try {
    return await axios.post(
      `https://graph.microsoft.com/v1.0/me/events`,
      event,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
          Prefer:
            'outlook.body-content-type="text", outlook.timezone="Singapore Standard Time"',
        },
      }
    );
  } catch (error) {
    console.error("error createEvent", error.response);
  }
}

const Calendar = () => {
  const dispatch = useDispatch();

  const { instance, inProgress, accounts } = useMsal();
  const [apiData, setApiData] = useState();
  const [getStartDate, setStartDate] = useState();
  const [getEndDate, setEndDate] = useState();
  const [getToken, setToken] = useState();
  const [getLoading, setLoading] = useState(false);
  // const [events, setEvents] = useState([]);
  const [switchState, setSwitchState] = useState(false);
  const [show, setShow] = useState(false);

  const btnSaveEvent = (e) => {
    e.preventDefault();
    createEvent(getToken);
  };

  const handleClose = () => {
    setShow(false);
    setSwitchState(false);
  };

  // const handleShow = () => setShow(true);

  const handleChange = (e) => {
    setSwitchState(!switchState);
  };

  // Mohd Fazmin
  useEffect(() => {
    if (!apiData && inProgress === InteractionStatus.None) {
      const accessTokenRequest = {
        scopes: ["user.read", "calendars.readwrite", "calendars.read"],
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;

          localStorage.setItem("id_token_email", accessToken);

          console.log("accessToken");
          setToken(accessToken);

          // var date = new Date();
          // var startDate = new Date(date.getFullYear(), date.getMonth(), 1);
          // var endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

          var startDate = getStartDate;
          var endDate = getEndDate;

          // Call your API with token
          callApi(accessToken, startDate, endDate).then((response) => {
            try {
              const arr = response.data.value.map((e) => {
                return {
                  id: e.id,
                  title: e.subject,
                  start: e.start.dateTime,
                  end: e.end.dateTime,
                  isCancel: e.isCancelled,
                  isOrganizer: e.isOrganizer,
                  attendees: e.attendees,
                  organizer: e.organizer,
                };
              });
              // console.log("asdasdsad", response);
              // console.log("arrrrrrrrr", arr);
              setApiData(arr);
            } catch (error) {
              console.log("asdasdsad", error);
            }
          });
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup(accessTokenRequest)
              .then(function (accessTokenResponse) {
                // Acquire token interactive success
                let accessToken = accessTokenResponse.accessToken;
                setToken(accessToken);

                var startDate = getStartDate;
                var endDate = getEndDate;

                // Call your API with token
                callApi(accessToken, startDate, endDate).then((response) => {
                  const arr = response.data.value.map((e) => {
                    return {
                      id: e.id,
                      title: e.subject,
                      start: e.start.dateTime,
                      end: e.end.dateTime,
                      isCancel: e.isCancelled,
                      isOrganizer: e.isOrganizer,
                      attendees: e.attendees,
                      organizer: e.organizer,
                    };
                  });
                  setApiData(arr);
                });
              })
              .catch(function (error) {
                // Acquire token interactive failure
                console.log(error);
              });
          }
          console.log(error);
        });
    }
  }, [instance, accounts, inProgress, apiData, getStartDate, getEndDate]);

  useEffect(() => {
    dispatch(storeTitle({ title: "Calendar", icon: mdiCalendar }));
  }, [dispatch]);

  async function myFunction(arg) {
    // console.log("arg", JSON.stringify(arg));\meeting

    // console.log("arg.start", arg.start);
    // console.log("arg.end", arg.end);

    setStartDate(arg.start);
    setEndDate(arg.end);
    setLoading(true);

    document.getElementById("cal-overlay").style.display = "block";

    callApi(getToken, arg.start, arg.end).then((response) => {
      try {
        const arr = response.data.value.map((e) => {
          return {
            id: e.id,
            title: e.subject,
            start: e.start.dateTime,
            end: e.end.dateTime,
            isCancel: e.isCancelled,
            isOrganizer: e.isOrganizer,
            attendees: e.attendees,
            organizer: e.organizer,
          };
        });
        // console.log("myfunction", response);
        // console.log("arrrrrrrrr", arr);
        setApiData(arr);
      } catch (error) {
        console.log("error", error);
      }
    });
  }

  const EventItem = ({ info }) => {
    const { event } = info;

    // console.log("event", info);

    const organizer = info.event.extendedProps.isOrganizer;
    const organizerName = info.event.extendedProps.organizer.emailAddress.name;
    const cancel = info.event.extendedProps.isCancel;

    const options = {
      weekday: "short",
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      // hour12: false,
      // timeZone: "America/Los_Angeles",
    };

    const optionsTime = {
      hour: "numeric",
      minute: "numeric",
    };

    const startDateOnly = new Intl.DateTimeFormat(options).format(event.start);
    const endDateOnly = new Intl.DateTimeFormat(options).format(event.end);

    const startDatetimeConvert = new Intl.DateTimeFormat("default", options)
      .format(event.start)
      .replace(",", "");

    const endDatetimeConvert = new Intl.DateTimeFormat("default", options)
      .format(event.end)
      .replace(",", "");

    const startTimeConvert = new Intl.DateTimeFormat(
      "default",
      optionsTime
    ).format(event.start);

    const endTimeConvert = new Intl.DateTimeFormat(
      "default",
      optionsTime
    ).format(event.end);

    const popover = (
      <Popover id="popover-contained">
        <Popover.Header as="h3">{info.event.title}</Popover.Header>
        <Popover.Body>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <Icon path={mdiClockTimeThreeOutline} />
            </div>
            <div style={{ marginLeft: "10px" }}>
              {startDateOnly === endDateOnly ? (
                <div className="dateTimePopover">
                  {startDatetimeConvert} - {endTimeConvert}
                </div>
              ) : (
                <div className="dateTimePopover">
                  {startDatetimeConvert} - {endDatetimeConvert}
                </div>
              )}
            </div>
          </div>
          <hr />
          {organizer ? (
            <div>You're the organizer.</div>
          ) : (
            <div>{organizerName} invited you.</div>
          )}
        </Popover.Body>
      </Popover>
    );

    return (
      <OverlayTrigger
        trigger="click"
        placement="auto"
        rootClose={true}
        overlay={popover}
      >
        <div className="fc-event-title">
          <span>{startTimeConvert}</span>{" "}
          <b
            style={{
              textDecoration: cancel ? "line-through" : "none",
            }}
          >
            {event.title}
          </b>
        </div>
      </OverlayTrigger>
    );
    // render(<EventItem />);
  };

  function LoadingSpinner(isLoading) {
    // console.log("is", isLoading);

    if (isLoading === true) {
      // console.log("tututu");
      setLoading(true);
    } else {
      // console.log("fofofo");
      setLoading(false);
      document.getElementById("cal-overlay").style.display = "none";
    }
  }

  return (
    <div className="main-div cal-div">
      <AuthenticatedTemplate>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "10px",
          }}
        >
          <WelcomeUser />
          <div>
            {/* {getLoading ? (
              <Button
                style={{ marginRight: "10px" }}
                onClick={handleShow}
                disabled
              >
                <Icon path={mdiCalendarBlankOutline} size={0.8} />
                New event
              </Button>
            ) : (
              <Button style={{ marginRight: "10px" }} onClick={handleShow}>
                <Icon path={mdiCalendarBlankOutline} size={0.8} />
                New event
              </Button>
            )} */}

            <SignOutButton />
          </div>

          <Modal
            show={show}
            size="lg"
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Form id="create-event-form" onSubmit={btnSaveEvent}>
                <Form.Group className="mb-3">
                  <Form.Label>Event Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Add a title"
                    name="eventTitle"
                    autoFocus
                  />
                </Form.Group>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control type="date" name="eventStartDate" />
                  </Form.Group>

                  {switchState ? null : (
                    <Form.Group as={Col}>
                      <Form.Label>Start Time</Form.Label>
                      <Form.Control type="time" name="eventStartTime" />
                    </Form.Group>
                  )}

                  <Form.Group as={Col}>
                    <Form.Label></Form.Label>
                    <Form.Check
                      type="switch"
                      label="All day"
                      defaultChecked={switchState}
                      onChange={handleChange}
                      style={{ marginTop: "13px" }}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>End Date</Form.Label>
                    <Form.Control type="date" />
                  </Form.Group>

                  {switchState ? null : (
                    <Form.Group as={Col}>
                      <Form.Label>End Time</Form.Label>
                      <Form.Control type="time" />
                    </Form.Group>
                  )}

                  <Form.Group as={Col}></Form.Group>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>Event Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Add a description"
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit" form="create-event-form">
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        {getLoading === true ? (
          <Spinner animation="border" role="status" className="spinner-calendar">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : null}

        <div id="cal-overlay" className="calendar-overlay">
          {" "}
        </div>
        <FullCalendar
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            listPlugin,
          ]}
          initialView="dayGridMonth"
          headerToolbar={{
            start: "prev,next today",
            center: "title",
            end: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
          }}
          titleFormat={{ year: "numeric", month: "long" }}
          events={apiData}
          forceEventDuration={true}
          nowIndicator
          navLinks={false}
          // dateClick={handleDateSelect}
          // eventClick={(e) => toDisplayEvent(e)}
          displayEventTime={true}
          editable={true}
          selectable={true}
          dayMaxEvents={true}
          // select={handleSelect}
          datesSet={(arg) => {
            myFunction(arg);
          }}
          eventContent={(info) => <EventItem info={info} />}
          loading={(isLoading) => LoadingSpinner(isLoading)}
          height="100vh"
          // stickyHeaderDates={true}
          listDayFormat={true}
          slotEventOverlap={false}
          eventColor="#EC782F"
        />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className="calendar-cont">
          <div className="calendar-center-wording">
            <Icon
              path={mdiMicrosoftOutlook}
              size={3.5}
              color="rgba(0, 120, 212, 0.7)"
            />

            <div>
              <b>Sign in with your Microsoft Outlook Email</b>
              <br /> to sync all events calendar to Jesper System.
            </div>
          </div>
          <div>
            <SignInButton />
          </div>
        </div>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default Calendar;
