import React, { useEffect } from "react";
import logo from "../../../assets/logo.png";
import squareLogo from "../../../assets/square-logo.jpeg";
import Icon from "@mdi/react";
import { Link, NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  mdiAccount,
  mdiFileDocumentMultiple,
  mdiPaperclip,
  mdiArrowRightBox,
  mdiArrowLeftBox,
  mdiClose,
  mdiFileLock,
} from "@mdi/js";
import "../../../App.css";

export const menuItems = [
  {
    name: "Profile",
    exact: true,
    to: "/",
    iconClassName: mdiAccount,
  },
  {
    name: "Documents",
    exact: true,
    to: "/document",
    iconClassName: mdiFileDocumentMultiple,
  },
  {
    name: "Attachments",
    to: "/attachment",
    iconClassName: mdiPaperclip,
  },
  {
    name: "Consents",
    to: "/consent",
    iconClassName: mdiFileLock,
  },
];

const Nav = styled.div`
  /* background: #15171c; */
  /* height: 80px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const NavIcon = styled(Link)`
  margin-left: 1rem;
  /* font-size: 2rem; */
  /* height: 80px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #ec782f;

  &:hover {
    color: #bdbdbd;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const SidebarNav = styled.nav`
  background: #fff;
  width: ${({ sidebar }) => (sidebar ? "62px" : "300px")};
  min-height: 100vh;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  box-shadow: 0px 4px 5px 1px rgba(0, 0, 0, 0.25);
  /* border-radius: 0px 20px 20px 0px; */
  position: fixed;
  top: 0;
  transition: 350ms;
  z-index: 10;
  white-space: nowrap;
  overflow-x: hidden;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const NavScroll = styled.div`
  /* background: red; */
  height: calc(100vh - 7vh);
  overflow: scroll;
`;

const SidebarLink = styled(NavLink)`
  display: flex;
  color: #54595f;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    /* background: #ec782f; */
    color: #ec782f;
    cursor: pointer;
  }

  &.menu-item.active {
    background: #ec782f;
    color: #fff;
  }
`;

const SidebarLabel = styled.div`
  margin-left: 16px;
`;

const SideMenu = ({ setHeader, setIconH, sidebar, setSidebar }) => {
  const location = useLocation();

  const headerName = () => {
    menuItems.map((item, index) => {
      if (item.to === location.pathname) {
        setHeader(item.name);
        setIconH(item.iconClassName);
      }
    });
  };

  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  const toggleSidebarIn = () => {
    if (sidebar) {
      // console.log("sidebar besar");
      document.getElementById("sidebar-nav").style.width = "300px";
      document.getElementById("nav").style.display = "block";
      document.getElementById("imgSquare").style.display = "none";
      document.getElementById("imgLogo").style.display = "block";
    }
  };

  const toggleSidebarOut = () => {
    if (sidebar) {
      // console.log("sidebar kecik");
      document.getElementById("sidebar-nav").style.width = "62px";
      document.getElementById("nav").style.display = "none";
      document.getElementById("imgSquare").style.display = "block";
      document.getElementById("imgLogo").style.display = "none";
    }
  };

  const btnLink = (to, name, iconClassName) => () => {
    // console.log("icon", iconClassName)
    if (to === location.pathname) {
      setHeader(name);
      setIconH(iconClassName);
      // localStorage.setItem("menu_title", name);
      // localStorage.setItem("menu_icon", iconClassName);
    }

    closeMenu();
  };

  const closeMenu = () => {
    let responsive = document.querySelectorAll("#sidebar-nav");

    responsive.forEach((el) => el.classList.remove("active"));
  };

  useEffect(() => {
    headerName();
  }, [headerName]);

  return (
    <>
      <SidebarNav
        id="sidebar-nav"
        className="resp-sidebar"
        sidebar={sidebar}
        onMouseOver={toggleSidebarIn}
        onMouseOut={toggleSidebarOut}
      >
        <SidebarWrap>
          <div className="close-responsive" onClick={closeMenu}>
            <Icon path={mdiClose} size={1} color="#54595F" />
          </div>
          <Logo className="llm-logo-sidebar">
            {!sidebar ? (
              <img
                src={sidebar ? squareLogo : logo}
                alt="Lim-Loges Masters Company Logo"
                height={45}
              />
            ) : (
              <>
                <img
                  id="imgSquare"
                  src={squareLogo}
                  alt="Lim-Loges Masters Company Logo"
                  height={45}
                />
                <img
                  id="imgLogo"
                  src={logo}
                  alt="Lim-Loges Masters Company Logo"
                  height={45}
                />
              </>
            )}

            <Nav id="nav">
              <NavIcon to="#">
                <Icon
                  path={sidebar ? mdiArrowRightBox : mdiArrowLeftBox}
                  size={1}
                  onClick={showSidebar}
                />
              </NavIcon>
            </Nav>
          </Logo>

          <NavScroll className="main-menu">
            <ul className="sidebar-ul">
              {menuItems.map((item, index) => (
                <li
                  key={index}
                  onClick={btnLink(item.to, item.name, item.iconClassName)}
                >
                  <SidebarLink to={item.to} className={`menu-item`}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Icon path={item.iconClassName} size={1} />
                      <SidebarLabel className="sidebar-label" sidebar={sidebar}>
                        {item.name}
                      </SidebarLabel>
                    </div>
                    {/* <Icon path={item.iconDrop} size={1} /> */}
                  </SidebarLink>
                </li>
              ))}
            </ul>
          </NavScroll>
        </SidebarWrap>
      </SidebarNav>
    </>
  );
};

export default SideMenu;
