import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "../../components/layout/Layout";

import Home from "../../pages/Admin/Dashboard/Home";
import Calendar from "../../pages/Admin/Calendar/Calendar";
import Settings from "../../pages/Admin/Settings/Settings";
import ViewAllNoti from "../../pages/Admin/Dashboard/ViewAllNoti";

// Clients Module (Active)
import ListActiveClients from "../../pages/Admin/Clients/ActiveClients/ListActiveClients";
import ViewActiveClients from "../../pages/Admin/Clients/ActiveClients/ViewActiveClients";

//Clients Module (Potential)
import ListPotentialClients from "../../pages/Admin/Clients/PotentialClients/ListPotentialClients";
import ViewPotentialClients from "../../pages/Admin/Clients/PotentialClients/ViewPotentialClients";
import ConvertClient from "../../pages/Admin/Clients/PotentialClients/ConvertClient";

//Clients (ALL)
import ListAllClients from "../../pages/Admin/Clients/AllClients/ListAllClients";

// Clients Module (General)
import CreateProposal from "../../pages/Admin/Clients/CreateProposal";
import CreateMeeting from "../../pages/Admin/Clients/CreateMeeting";
import EditMeeting from "../../pages/Admin/Clients/EditMeeting";
import ViewMeeting from "../../pages/Admin/Clients/ViewMeeting";
import EmergingCP from "../../pages/Admin/Clients/Reports/EmergingCP";
import QualifiedCP from "../../pages/Admin/Clients/Reports/QualifiedCP";
import ViewAssignment from "../../pages/Admin/Clients/ViewAssignment";
import ViewAssignmentTDM from "../../pages/Admin/Clients/ViewAssignmentTDM";
import ViewListProposal from "../../pages/Admin/Clients/ViewListProposal";
import ViewProposal from "../../pages/Admin/Clients/ViewProposal";
import CreateStaff from "../../pages/Admin/Clients/CreateStaff";
import CreateJD from "../../pages/Admin/Clients/CreateJD";
import CreateProgress from "../../pages/Admin/Clients/CreateProgress";
import ViewProgress from "../../pages/Admin/Clients/ViewProgress";
import ComposeEmail from "../../pages/Admin/Clients/ComposeEmail";
import ViewStaffClient from "../../pages/Admin/Clients/ViewStaffClient";

// Candidate Module ()
import ListCandidates from "../../pages/Admin/Candidate/ListofCandidates/ListCandidates";
import AddCandidate from "../../pages/Admin/Candidate/ListofCandidates/AddCandidate";
import ViewCandidate from "../../pages/Admin/Candidate/ListofCandidates/ViewCandidate";

// List of Staff
import Staff from "../../pages/Admin/Staff/Staff";
import ViewStaff from "../../pages/Admin/Staff/ViewStaff";
import AddStaff from "../../pages/Admin/Staff/AddStaff";
import EditStaff from "../../pages/Admin/Staff/EditStaff";

import UserAcces from "../../pages/Admin/Administration/User Access/UserAcces";
import SystemOption from "../../pages/Admin/Administration/System Option/SystemOption";
import Notification from "../../pages/Admin/Administration/Notification/Notification";
import ViewUserAccess from "../../pages/Admin/Administration/User Access/ViewUserAccess";
import PageManagement from "../../pages/Admin/Administration/Page Management/PageManagement";
import ViewPageManagement from "../../pages/Admin/Administration/Page Management/ViewPageManagement";

import ViewCandidateAssg from "../../pages/Admin/Candidate/ListofCandidates/ViewCandidateAssg";
import PlacementList from "../../pages/Admin/Candidate/Placements/PlacementList";
import ViewPlacement from "../../pages/Admin/Candidate/Placements/ViewPlacement";
import ViewJD from "../../pages/Admin/Clients/ViewJD";
import Meeting from "../../pages/Admin/Meeting/Meeting";

import Error from "../../pages/Admin/Error";
import ViewAllClients from "../../pages/Admin/Clients/AllClients/ViewAllClients";
import ListAssignments from "../../pages/Admin/Assignment/ListAssignments";
import Interview from "../../pages/Admin/Interview/Interview";

// import AuthAbilities from "../../pages/Admin/Auth/AuthAbilities";
import Access from "../../pages/Access";
import CreateForm from "../../pages/Admin/Clients/CreateForm";
import ListProposal from "../../pages/Admin/Proposal/ListProposal";
import EditProposal from "../../pages/Admin/Clients/EditProposal";
import ViewProposalApproval from "../../pages/Admin/Proposal/ViewProposalApproval";

const RouteAdmin = () => {
  const { usrError, usrAbilities } = useSelector((state) => state.user);

  // const abilities = AuthAbilities();
  // let navigate = useNavigate();

  // const shouldRedirect = true;
  // useEffect(() => {
  //     navigate("/");
  // },[]);

  return (
    <>
      {usrError === "network error" ? (
        <Routes>
          <Route path="/error" element={<Error />}></Route>
        </Routes>
      ) : (
        <>
          <Routes>
            <Route path="/access" element={<Access />}></Route>
          </Routes>
          <Layout>
            <Routes>
              <Route path="/home" element={<Home />}></Route>
              <Route path="/" element={<Navigate replace to="/home" />} />
              <Route path="/error" element={<Navigate replace to="/home" />} />

              <Route
                path="/all-notifications"
                element={<ViewAllNoti />}
              ></Route>

              <Route path="/calendar" element={<Calendar />}></Route>
              <Route path="/meeting" element={<Meeting />}></Route>
              <Route path="/interview" element={<Interview />}></Route>

              {/* Start All Assignments */}
              <Route
                path="/clients/listAssignments"
                element={<ListAssignments />}
              ></Route>
              {/* End All Assignments */}

              {/* Start All Clients */}
              <Route
                path="/clients/listAllClients"
                element={<ListAllClients />}
              ></Route>
              <Route
                path="/clients/viewAllClients/:id"
                element={<ViewAllClients />}
              ></Route>
              {/* End All Clients */}

              {/* Start Active Client */}
              <Route
                path="/clients/listActiveC"
                element={<ListActiveClients />}
              ></Route>

              <Route
                path="/clients/viewActiveC/:id"
                element={
                  usrAbilities.length === 0 ? (
                    ""
                  ) : usrAbilities.includes("view clients") ? (
                    <ViewActiveClients />
                  ) : (
                    <Access />
                  )
                }
              ></Route>

              <Route
                path="/clients/view-assignment/:page/:id/:idassign"
                element={
                  usrAbilities.length === 0 ? (
                    ""
                  ) : usrAbilities.includes("view assignment") ? (
                    <ViewAssignment />
                  ) : (
                    <Access />
                  )
                }
              ></Route>

              <Route
                path="/clients/view-assignment-tdm/tdm/:page/:id/:idassign"
                element={<ViewAssignmentTDM />}
              ></Route>

              <Route
                path="/clients/view-assignment-tdm/tdm/create-form"
                element={<CreateForm />}
              ></Route>

              <Route
                path="/clients/view-list-proposal/:id"
                element={
                  usrAbilities.length === 0 ? (
                    ""
                  ) : usrAbilities.includes("list proposal") ? (
                    <ViewListProposal />
                  ) : (
                    <Access />
                  )
                }
              ></Route>
              {/* End Active Client */}

              {/* Start Potential Client */}
              <Route
                path="/clients/listPotentialC"
                element={
                  usrAbilities.length === 0 ? (
                    ""
                  ) : usrAbilities.includes("list potential") ? (
                    <ListPotentialClients />
                  ) : (
                    <Access />
                  )
                }
              ></Route>

              <Route
                path="/clients/view-potential-client/:id"
                element={
                  usrAbilities.length === 0 ? (
                    ""
                  ) : usrAbilities.includes("view potential") ? (
                    <ViewPotentialClients />
                  ) : (
                    <Access />
                  )
                }
              ></Route>

              <Route
                path="/clients/convert-client/:id"
                element={
                  usrAbilities.length === 0 ? (
                    ""
                  ) : usrAbilities.includes("convert potential") ? (
                    <ConvertClient />
                  ) : (
                    <Access />
                  )
                }
              ></Route>
              {/* End Potential Client */}

              {/* Start all forms in active/potential client */}
              <Route path="/compose-email" element={<ComposeEmail />}></Route>

              <Route
                path="/clients/create-proposal/:page/:id"
                element={<CreateProposal />}
              ></Route>

              <Route
                path="/clients/create-meeting/:id"
                element={<CreateMeeting />}
              ></Route>

              <Route
                path="/clients/view-meeting/:id/:idrow"
                element={<ViewMeeting />}
              ></Route>

              <Route
                path="/clients/edit-meeting/:id/:idrow"
                element={<EditMeeting />}
              ></Route>

              <Route
                path="/clients/create-jd/:page/:id/:idassign"
                element={<CreateJD />}
              ></Route>

              <Route
                path="/clients/view-jd/:page/:id/:idassign/:idjd"
                element={<ViewJD />}
              ></Route>

              <Route
                path="/clients/create-progress/:page/:type/:id/:idassign"
                element={<CreateProgress />}
              ></Route>

              <Route
                path="/clients/view-progress/:page/:id/:idassign/:idpro"
                element={<ViewProgress />}
              ></Route>

              <Route
                path="/clients/create-staff/:page/:id"
                element={<CreateStaff />}
              ></Route>

              <Route
                path="/clients/view-staff-client/:page/:id/:idsta"
                element={<ViewStaffClient />}
              ></Route>

              <Route
                path="/clients/view-proposal/:page/:id/:idpro"
                element={<ViewProposal />}
              ></Route>

              <Route
                path="/clients/edit-proposal/:page/:id/:idpro"
                element={<EditProposal />}
              ></Route>

              <Route
                path="/clients/ecp/:page/:id/:idassign/:idrpt"
                element={<EmergingCP />}
              ></Route>

              <Route
                path="/clients/qcp/:page/:id/:idassign/:idrpt"
                element={<QualifiedCP />}
              ></Route>
              {/* End all forms in active/potential client */}

              {/* Start Candidate */}
              <Route
                path="/candidates/listCandidates"
                element={
                  usrAbilities.length === 0 ? (
                    ""
                  ) : usrAbilities.includes("list candidate") ? (
                    <ListCandidates />
                  ) : (
                    <Access />
                  )
                }
              ></Route>

              <Route
                path="/candidates/viewCandidate/:id"
                element={
                  usrAbilities.length === 0 ? (
                    ""
                  ) : usrAbilities.includes("view candidate") ? (
                    <ViewCandidate />
                  ) : (
                    <Access />
                  )
                }
              ></Route>

              <Route
                path="/candidates/addCandidate"
                element={<AddCandidate />}
              ></Route>

              <Route
                path="/candidates/view-candidate-assg/:id/:idassign/:idclient"
                element={<ViewCandidateAssg />}
              ></Route>

              <Route
                path="/candidates/placement-list"
                element={
                  usrAbilities.length === 0 ? (
                    ""
                  ) : usrAbilities.includes("list placement") ? (
                    <PlacementList />
                  ) : (
                    <Access />
                  )
                }
              ></Route>

              <Route
                path="/clients/listProposal"
                element={
                  usrAbilities.length === 0 ? (
                    ""
                  ) : usrAbilities.includes("list proposal approval") ? (
                    <ListProposal />
                  ) : (
                    <Access />
                  )
                }
              ></Route>

              <Route
                path="/clients/view-proposal-approval/:id/:idpro"
                element={
                  usrAbilities.length === 0 ? (
                    ""
                  ) : usrAbilities.includes("view proposal approval") ? (
                    <ViewProposalApproval/>
                  ) : (
                    <Access />
                  )
                }
              ></Route>

              <Route
                path="/candidates/view-placement/:id/:idpla"
                element={
                  usrAbilities.length === 0 ? (
                    ""
                  ) : usrAbilities.includes("view placement") ? (
                    <ViewPlacement />
                  ) : (
                    <Access />
                  )
                }
              ></Route>

              <Route
                path="/candidates/ecp/:id/:idassign/:idrpt"
                element={<EmergingCP />}
              ></Route>

              <Route
                path="/candidates/qcp/:id/:idassign/:idrpt"
                element={<QualifiedCP />}
              ></Route>
              {/* End Candidate */}

              <Route path="/settings" element={<Settings />}></Route>

              {/* Start Admin */}
              <Route path="/staff" element={<Staff />}></Route>

              <Route path="/viewStaff/:id" element={<ViewStaff />}></Route>

              <Route
                path="/editStaff/:page/:id"
                element={<EditStaff />}
              ></Route>

              <Route path="/addStaff" element={<AddStaff />}></Route>

              <Route path="/userAccess" element={<UserAcces />}></Route>

              <Route
                path="/viewUserAccess/:id"
                element={<ViewUserAccess />}
              ></Route>

              <Route path="/systemOption" element={<SystemOption />}></Route>

              <Route path="/notification" element={<Notification />}></Route>

              <Route path="/pages" element={<PageManagement />} />

              <Route path="/pages/:id" element={<ViewPageManagement />} />

              {/* End Admin */}
            </Routes>
          </Layout>
        </>
      )}
    </>
  );
};

export default RouteAdmin;
