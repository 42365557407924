import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams} from "react-router-dom";
import { Button } from "react-bootstrap";

import {
  // mdiAccountCheck,
  // mdiAccountCircle,
  mdiCheckCircle,
  mdiEmailCheck,
} from "@mdi/js";
import Icon from "@mdi/react";

import { verifyNewRegister } from "../../../../store/actions/registerCandidateSlice";


function Verify() {
  // const [getVerify, setVerify] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  const { verifyUserLogin } = useSelector((state) => state.registerCand);

  useEffect(() => {
    dispatch(verifyNewRegister(params.id))
  }, [dispatch, params.id]);

  return (
    <div
      className="main-div"
      style={{ height: "100vh", background: "#fdf2ea" }}
    >
      <div className="verify-container">
        {verifyUserLogin.message === "already verified" ? (
          <HasVerified type={verifyUserLogin.type}/>
        ) : (
          <Verified type={verifyUserLogin.type} />
          
        )}
      </div>
    </div>
  );
}

export function Verified(props) {
  const navigate = useNavigate();

  const btnContinue = () => {
    props.type === 'client' ? navigate("/") : navigate("/candidate-login");
  };

  return (
    <>
      <Icon path={mdiEmailCheck} size={4} color={"#4CAF50"} />
      {/* <div className="verify-title">Verification Compeleted!</div> */}
      <div className="verify-text">
        Your email has been verified successfully. <br />
        You can now sign in with your registered email address and password.
      </div>
      <Button variant="primary" onClick={btnContinue}>
        Continue
      </Button>
    </>
  );
}

export function HasVerified(props) {
  const navigate = useNavigate();

  const btnContinue = () => {
    props.type === 'client' ? navigate("/") : navigate("/candidate-login");
    
  };

  return (
    <>
      <Icon path={mdiCheckCircle} size={4} color={"#4CAF50"} />
      <div className="verify-title">Your email has already been verified.</div>
      <div className="verify-text">Please continue to log in.</div>
      <Button variant="primary" onClick={btnContinue}>
        Go to sign in page
      </Button>
    </>
  );
}

export default Verify;
