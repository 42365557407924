import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../../../core/services/api.service";

// import ButtonText from "../../../../components/Button/ButtonText";
import logo from "../../../../assets/logo.png";
import "./forgotpassword.css";

function ForgotPassword() {
  const navigate = useNavigate();
  const params = useParams();

  const [errorForm, setErrorForm] = useState({});
  const [emailSend, setEmailSend] = useState("");

  function register() {
    if (params.type === "client") {
      navigate("/client-register");
    } else {
      navigate("/candidate-register");
    }
  }

  const btnSignin = () => {
    if (params.type === "client") {
      navigate("/");
    } else if (params.type === "candidate") {
      navigate("/candidate-login");
    } else {
      navigate("/admin");
    }
  };

  const formError = (object) => {
    const newErrors = {};

    // console.log("object", object);
    const regEmail = /\S+@\S+\.\S+/;

    if (!object.txtemail || object.txtemail === "")
      newErrors.txtemail = "Fill in the blank!";
    else if (!regEmail.test(object.txtemail))
      newErrors.txtemail = "Invalid Email Address";

    return newErrors;
  };

  const fromErrorNoExistEmail = (object) => {
    const newErrors = {};

    if (object === "no email") newErrors.txtemail = "Email not existing";

    return newErrors;
  };

  const btnResetPassword = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtReset = Object.fromEntries(data.entries());

    const newErrors = formError(dtReset);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      setErrorForm({});

      Api.post("resetPassword", {
        email: dtReset.txtemail,
        type: params.type,
      })
        .then((response) => {
          if (response.data === "no email") {
            const newErrorsExist = fromErrorNoExistEmail(response.data);

            setErrorForm(newErrorsExist);
          } else {
            setEmailSend(
              "We have sent reset password email to your email address!"
            );
            setErrorForm({});
            document.getElementById("reset-form").reset();
          }
        })
        .catch((error) => {
          console.log("error reset paswword", error);
        });
    }
  };

  return (
    <div className="main-div" style={{ height: "100vh" }}>
      <div className="auth-logo-div">
        <img src={logo} alt="" />
      </div>
      <div className="auth-container">
        <div className="login-container">
          <div className="left-welcome-div">
            <div className="left-overlay">
              <div className="mid-section" style={{ textAlign: "center" }}>
                {params.type !== "staff" ? (
                  <>
                    <div
                      className="main-title"
                      style={{
                        color: "#FFF",
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                    >
                      Don't have an account yet?
                    </div>
                    <div className="small-text" style={{ textAlign: "center" }}>
                      Fill up your informations <br /> and start your future
                      journey with us!
                    </div>
                    <div className="btn-register-left-side">
                      <Button
                        variant="outline-primary"
                        type="submit"
                        className="btn-register"
                        onClick={register}
                      >
                        Register Here
                      </Button>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          <div className="right-form-div">
            <div className="top-section forgot-pw">
              <div className="title-form-section">
                <div className="main-title">Forgot your password?</div>
              </div>

              <Form id="reset-form" onSubmit={btnResetPassword}>
                <Form.Group className="mb-3">
                  <Form.Label className="form-setting-label forgot-sentence">
                    Enter your email address you’re using for your account below
                    and we will send you a password reset link.
                  </Form.Label>
                  {emailSend ? (
                    <Alert variant="success">{emailSend}</Alert>
                  ) : (
                    ""
                  )}
                  <Form.Control
                    name="txtemail"
                    type="email"
                    placeholder="Enter your email address"
                    isInvalid={!!errorForm.txtemail}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorForm.txtemail}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
              <div className="row-link-button sign-in">
                <Button
                  variant="link"
                  className="no-underline"
                  onClick={btnSignin}
                >
                  Back to sign in
                </Button>
                {/* <div> */}
                <Button variant="primary" type="submit" form="reset-form">
                  Reset Password
                </Button>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
