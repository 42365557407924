import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Row, Dropdown, Spinner, Table, Accordion } from "react-bootstrap";
import Api from "../../../../core/services/api.service";

import AuthAbilities from "../../Auth/AuthAbilities";
import SwalAlert from "../../../../components/Swal/SwalAlert";

import Icon from "@mdi/react";
import {
  mdiMagnify,
  mdiFilter,
  mdiDelete,
  mdiPlus,
  mdiAccountGroup,
  mdiDotsHorizontal,
  mdiMerge
} from "@mdi/js";

import CustomTable from "../../../../components/Table/Table";
import TextFieldFormat from "../../../../components/TextField/TextFieldFormat";
import DefaultPhoto from "../../../../assets/no-profile-photo.jpg";
import CardInfo from "../../../../components/Card/CardInfo";
import TextboxIcon from "../../../../components/TextField/TextFieldIcon";
import Popup from "../../../../components/Popup/ModalPopup";
import Searchable from "../../../../components/DropDown/SearchableSelect";

import { getListStaff } from "../../../../store/actions/staffSlice";
import { getListClient, getClientView, getListAllCompanies, setAllListAllCompanies, setTempClientAllData, setTempClientAllFilData, setStoreAllFilter } from "../../../../store/actions/clientSlice";
import { storeTitle } from "../../../../store/actions/headerTitleSlice";
import { storePage } from "../../../../store/actions/paginationSlice";
import { getMergeClient } from "../../../../store/actions/mergeSlice";
// import { statusClient } from "../../dataList";

function ListAllClients() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const abilities = AuthAbilities();
  const customSwal = SwalAlert();

  const { dataCountry, dataIndustry, dataStockExchanges } = useSelector((state) => state.option);
  const { usrProfile } = useSelector((state) => state.user);
  const { clientListAll, clientAllListAll, clientAllFilter, clientTempAllFilData, clientTempAllData } = useSelector((state) => state.client);
  const { mergeClientSimilar, mergeClientNoSimilar } = useSelector((state) => state.merge);

  const [errorForm, setErrorForm] = useState({});

  const [selIndNew, setSelIndNew] = useState([]);

  const [clicked, setClicked] = useState(false);
  const [showAddClient, setShowAddClient] = useState(false);
  const [checkedList, setCheckedList] = useState(false);

  const [checkClients, setCheckClients] = useState([]);
  const [cliSelected, setCliSelected] = useState(0);

  const btnOpenAddClient = (e) => {
    setShowAddClient(true);
    setErrorForm({});
    setCheckedList(false);
  };

  const btnCloseAddClient = () => {
    setShowAddClient(false);
  };

  const btnCollapse = (index) => {
    if (clicked === index) {
      //if clicked is already active, then close
      return setClicked(null);
    }
    setClicked(index);
  };

  const handleSearch = (event) => {
    let searchVal = event.target.value.toLowerCase();

    dispatch(setStoreAllFilter({ txtsearchclient: searchVal }));
    dispatch(storePage(0));

    let newList = [];
    

    if (searchVal !== "" && clientAllFilter.ddlCountry === "" && clientAllFilter.txtgenphone === "" && clientAllFilter.ddlStatus === "") {
      newList = clientListAll.filter((client) => {
        return client.name.toLowerCase().includes(searchVal.toLowerCase());
      });
    }else if (searchVal !== "" && clientAllFilter.ddlCountry !== "" && clientAllFilter.txtgenphone === "" && clientAllFilter.ddlStatus === "") {
      newList = clientListAll.filter((client) => {
        return client.name.toLowerCase().includes(searchVal.toLowerCase()) && client.options ? client.options.value.toLowerCase().includes(clientAllFilter.ddlCountry.toLowerCase()) : "";
      });
    }else if (searchVal !== "" && clientAllFilter.ddlCountry !== "" && clientAllFilter.txtgenphone !== "" && clientAllFilter.ddlStatus === "") {
      newList = clientListAll.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          client.name.toLowerCase().includes(searchVal.toLowerCase()) && 
          cntry.includes(clientAllFilter.ddlCountry) && 
          client.general_telephone ? client.general_telephone.includes(clientAllFilter.txtgenphone) : ""
        );
      });
    }else if (searchVal !== "" && clientAllFilter.ddlCountry !== "" && clientAllFilter.txtgenphone !== "" && clientAllFilter.ddlStatus !== "") {
      if(clientAllFilter.ddlStatus === "active"){
        newList = clientListAll.filter((client) => {
          let cntry = "";
          let phone = "";

          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          };

          if (client.general_telephone !== null) {
            phone = client.general_telephone;
          } else {
            phone = "";
          };

          return (
            client.name.toLowerCase().includes(searchVal.toLowerCase()) &&
            cntry.includes(clientAllFilter.ddlCountry) && 
            phone.includes(clientAllFilter.txtgenphone) &&
            client.is_potential === false && 
            (client.status !== null && client.status !== "closed")
          );
        });
      }else if(clientAllFilter.ddlStatus === "potential"){
        newList = clientListAll.filter((client) => {
          let cntry = "";
          let phone = "";

          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          };

          if (client.general_telephone !== null) {
            phone = client.general_telephone;
          } else {
            phone = "";
          };

          return (
            client.name.toLowerCase().includes(searchVal.toLowerCase()) &&
            cntry.includes(clientAllFilter.ddlCountry) && 
            phone.includes(clientAllFilter.txtgenphone) &&
            client.is_potential === true && 
            (client.status !== null && client.status !== "closed")
          );
        });

      }else if(clientAllFilter.ddlStatus === "closed"){
        newList = clientListAll.filter((client) => {
          let cntry = "";
          let phone = "";

          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          };

          if (client.general_telephone !== null) {
            phone = client.general_telephone;
          } else {
            phone = "";
          };

          return (
            client.name.toLowerCase().includes(searchVal.toLowerCase()) &&
            cntry.includes(clientAllFilter.ddlCountry) && 
            phone.includes(clientAllFilter.txtgenphone) &&
            client.is_potential === false && 
            client.status === "closed"
          );
        });
      }else{
        newList = clientListAll.filter((client) => {
          let cntry = "";
          let phone = "";

          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          };

          if (client.general_telephone !== null) {
            phone = client.general_telephone;
          } else {
            phone = "";
          };

          return (
            client.name.toLowerCase().includes(searchVal.toLowerCase()) &&
            cntry.includes(clientAllFilter.ddlCountry) && 
            phone.includes(clientAllFilter.txtgenphone) &&
            client.is_potential === false && 
            (client.status === null && client.status !== "closed")
          );
        });
      }
    }else if (searchVal !== "" && clientAllFilter.ddlCountry !== "" && clientAllFilter.txtgenphone === "" && clientAllFilter.ddlStatus !== "") {
      if(clientAllFilter.ddlStatus === "active"){
        newList = clientListAll.filter((client) => {
          let cntry = "";

          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          };

          return (
            client.name.toLowerCase().includes(searchVal.toLowerCase()) &&
            cntry.includes(clientAllFilter.ddlCountry) &&
            client.is_potential === false && 
            (client.status !== null && client.status !== "closed")
          );
        });
      }else if(clientAllFilter.ddlStatus === "potential"){
        newList = clientListAll.filter((client) => {
          let cntry = "";

          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          };

          return (
            cntry.includes(clientAllFilter.ddlCountry) && 
            client.name.toLowerCase().includes(searchVal.toLowerCase()) &&
            client.is_potential === true && 
            (client.status !== null && client.status !== "closed")
          );
        });

      }else if(clientAllFilter.ddlStatus === "closed"){
        newList = clientListAll.filter((client) => {
          let cntry = "";

          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          };

          return (
            cntry.includes(clientAllFilter.ddlCountry) && 
            client.name.toLowerCase().includes(searchVal.toLowerCase()) &&
            client.is_potential === false && 
            client.status === "closed"
          );
        });
      }else{
        newList = clientListAll.filter((client) => {
          let cntry = "";

          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          };

          return (
            client.name.toLowerCase().includes(searchVal.toLowerCase()) &&
            cntry.includes(clientAllFilter.ddlCountry) && 
            client.is_potential === false && 
            (client.status === null && client.status !== "closed")
          );
        });
      }

    }else if (searchVal !== "" && clientAllFilter.ddlCountry === "" && clientAllFilter.txtgenphone !== "" && clientAllFilter.ddlStatus === "") {
      newList = clientListAll.filter((client) => {
        return (
          client.name.toLowerCase().includes(searchVal.toLowerCase()) && 
          client.general_telephone ? client.general_telephone.includes(clientAllFilter.txtgenphone) : ""
        );
      });
    }else if (searchVal !== "" && clientAllFilter.ddlCountry === "" && clientAllFilter.txtgenphone !== "" && clientAllFilter.ddlStatus !== "") {
      if(clientAllFilter.ddlStatus === "active"){
        newList = clientListAll.filter((client) => {
          let phone = "";

          if (client.general_telephone !== null) {
            phone = client.general_telephone;
          } else {
            phone = "";
          };

          return (
            client.name.toLowerCase().includes(searchVal.toLowerCase()) && 
            phone.includes(clientAllFilter.txtgenphone) &&
            client.is_potential === false && 
            (client.status !== null && client.status !== "closed")
          );
        });
      }else if(clientAllFilter.ddlStatus === "potential"){
        newList = clientListAll.filter((client) => {
          let phone = "";

          if (client.general_telephone !== null) {
            phone = client.general_telephone;
          } else {
            phone = "";
          };

          return (
            client.name.toLowerCase().includes(searchVal.toLowerCase()) &&
            phone.includes(clientAllFilter.txtgenphone) &&
            client.is_potential === true && 
            (client.status !== null && client.status !== "closed")
          );
        });

      }else if(clientAllFilter.ddlStatus === "closed"){
        newList = clientListAll.filter((client) => {
          let phone = "";

          if (client.general_telephone !== null) {
            phone = client.general_telephone;
          } else {
            phone = "";
          };

          return (
            client.name.toLowerCase().includes(searchVal.toLowerCase()) && 
            phone.includes(clientAllFilter.txtgenphone) &&
            client.is_potential === false && 
            client.status === "closed"
          );
        });
      }else{
        newList = clientListAll.filter((client) => {
          let phone = "";

          if (client.general_telephone !== null) {
            phone = client.general_telephone;
          } else {
            phone = "";
          };

          return (
            client.name.toLowerCase().includes(searchVal.toLowerCase()) && 
            phone.includes(clientAllFilter.txtgenphone) &&
            client.is_potential === false && 
            (client.status === null && client.status !== "closed")
          );
        });
      }

    }else if (searchVal !== "" && clientAllFilter.ddlCountry === "" && clientAllFilter.txtgenphone === "" && clientAllFilter.ddlStatus !== "") {
      if(clientAllFilter.ddlStatus === "active"){
        newList = clientListAll.filter((client) => {
          return (
            client.name.toLowerCase().includes(searchVal.toLowerCase()) && 
            client.is_potential === false && 
            (client.status !== null && client.status !== "closed")
          );
        });
      }else if(clientAllFilter.ddlStatus === "potential"){
        newList = clientListAll.filter((client) => {
          return (
            client.name.toLowerCase().includes(searchVal.toLowerCase()) && 
            client.is_potential === true && 
            (client.status !== null && client.status !== "closed")
          );
        });
      }else if(clientAllFilter.ddlStatus === "closed"){
        newList = clientListAll.filter((client) => {
          return (
            client.name.toLowerCase().includes(searchVal.toLowerCase()) && 
            client.is_potential === false && 
            client.status === "closed"
          );
        });
      }else{
        newList = clientListAll.filter((client) => {
          return (
            client.name.toLowerCase().includes(searchVal.toLowerCase()) && 
            client.is_potential === false && 
            (client.status === null && client.status !== "closed")
          );
        });
      }

    }else if (searchVal === "" && clientAllFilter.ddlCountry !== "" && clientAllFilter.txtgenphone === "" && clientAllFilter.ddlStatus === "") {
      newList = clientListAll.filter((client) => {
          // console.log("client.options", client.options)
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          cntry.includes(clientAllFilter.ddlCountry)
        );
      });
    }else if (searchVal === "" && clientAllFilter.ddlCountry !== "" && clientAllFilter.txtgenphone !== "" && clientAllFilter.ddlStatus === "") {
      newList = clientListAll.filter((client) => {
          // console.log("client.options", client.options)
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          cntry.includes(clientAllFilter.ddlCountry) && client.general_telephone ? client.general_telephone.includes(clientAllFilter.txtgenphone) : ""
        );
      });
    }else if (searchVal === "" && clientAllFilter.ddlCountry !== "" && clientAllFilter.txtgenphone !== "" && clientAllFilter.ddlStatus !== "") {
      if(clientAllFilter.ddlStatus === "active"){
        newList = clientListAll.filter((client) => {
          let cntry = "";
          let phone = "";

          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          };

          if (client.general_telephone !== null) {
            phone = client.general_telephone;
          } else {
            phone = "";
          };

          return (
            cntry.includes(clientAllFilter.ddlCountry) && 
            phone.includes(clientAllFilter.txtgenphone) &&
            client.is_potential === false && 
            (client.status !== null && client.status !== "closed")
          );
        });
      }else if(clientAllFilter.ddlStatus === "potential"){
        newList = clientListAll.filter((client) => {
          let cntry = "";
          let phone = "";

          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          };

          if (client.general_telephone !== null) {
            phone = client.general_telephone;
          } else {
            phone = "";
          };

          return (
            cntry.includes(clientAllFilter.ddlCountry) && 
            phone.includes(clientAllFilter.txtgenphone) &&
            client.is_potential === true && 
            (client.status !== null && client.status !== "closed")
          );
        });

      }else if(clientAllFilter.ddlStatus === "closed"){
        newList = clientListAll.filter((client) => {
          let cntry = "";
          let phone = "";

          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          };

          if (client.general_telephone !== null) {
            phone = client.general_telephone;
          } else {
            phone = "";
          };

          return (
            cntry.includes(clientAllFilter.ddlCountry) && 
            phone.includes(clientAllFilter.txtgenphone) &&
            client.is_potential === false && 
            client.status === "closed"
          );
        });
      }else{
        newList = clientListAll.filter((client) => {
          let cntry = "";
          let phone = "";

          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          };

          if (client.general_telephone !== null) {
            phone = client.general_telephone;
          } else {
            phone = "";
          };

          return (
            cntry.includes(clientAllFilter.ddlCountry) && 
            phone.includes(clientAllFilter.txtgenphone) &&
            client.is_potential === false && 
            (client.status === null && client.status !== "closed")
          );
        });
      }
    }else if (searchVal === "" && clientAllFilter.ddlCountry !== "" && clientAllFilter.txtgenphone === "" && clientAllFilter.ddlStatus !== "") {
      if(clientAllFilter.ddlStatus === "active"){
        newList = clientListAll.filter((client) => {
          let cntry = "";

          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          };

          return (
            cntry.includes(clientAllFilter.ddlCountry) && 
            client.is_potential === false && 
            (client.status !== null && client.status !== "closed")
          );
        });
      }else if(clientAllFilter.ddlStatus === "potential"){
        newList = clientListAll.filter((client) => {
          let cntry = "";

          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          };

          return (
            cntry.includes(clientAllFilter.ddlCountry) && 
            client.is_potential === true && 
            (client.status !== null && client.status !== "closed")
          );
        });

      }else if(clientAllFilter.ddlStatus === "closed"){
        newList = clientListAll.filter((client) => {
          let cntry = "";

          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          };

          return (
            cntry.includes(clientAllFilter.ddlCountry) && 
            client.is_potential === false && 
            client.status === "closed"
          );
        });
      }else{
        newList = clientListAll.filter((client) => {
          let cntry = "";

          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          };

          return (
            cntry.includes(clientAllFilter.ddlCountry) && 
            client.is_potential === false && 
            (client.status === null && client.status !== "closed")
          );
        });
      }
    }else if (searchVal === "" && clientAllFilter.ddlCountry === "" && clientAllFilter.txtgenphone !== "" && clientAllFilter.ddlStatus === "") {
      newList = clientListAll.filter((client) => {
        let phone = "";

        if (client.general_telephone !== null) {
          phone = client.general_telephone;
        } else {
          phone = "";
        };

        return (
          phone.includes(clientAllFilter.txtgenphone)
        );
      });
    }else if (searchVal === "" && clientAllFilter.ddlCountry === "" && clientAllFilter.txtgenphone !== "" && clientAllFilter.ddlStatus !== "") {
      if(clientAllFilter.ddlStatus === "active"){
        newList = clientListAll.filter((client) => {
          let phone = "";

          if (client.general_telephone !== null) {
            phone = client.general_telephone;
          } else {
            phone = "";
          };

          return (
            phone.includes(clientAllFilter.txtgenphone) &&
            client.is_potential === false && 
            (client.status !== null && client.status !== "closed")
          );
        });
      }else if(clientAllFilter.ddlStatus === "potential"){
        newList = clientListAll.filter((client) => {
          let phone = "";

          if (client.general_telephone !== null) {
            phone = client.general_telephone;
          } else {
            phone = "";
          };

          return (
            phone.includes(clientAllFilter.txtgenphone) &&
            client.is_potential === true && 
            (client.status !== null && client.status !== "closed")
          );
        });

      }else if(clientAllFilter.ddlStatus === "closed"){
        newList = clientListAll.filter((client) => {
          let phone = "";

          if (client.general_telephone !== null) {
            phone = client.general_telephone;
          } else {
            phone = "";
          };

          return (
            phone.includes(clientAllFilter.txtgenphone) &&
            client.is_potential === false && 
            client.status === "closed"
          );
        });
      }else{
        newList = clientListAll.filter((client) => {
          let phone = "";

          if (client.general_telephone !== null) {
            phone = client.general_telephone;
          } else {
            phone = "";
          };

          return (
            phone.includes(clientAllFilter.txtgenphone) &&
            client.is_potential === false && 
            (client.status === null && client.status !== "closed")
          );
        });
      }
    }else if (searchVal === "" && clientAllFilter.ddlCountry === "" && clientAllFilter.txtgenphone === "" && clientAllFilter.ddlStatus !== "") {
      if(clientAllFilter.ddlStatus === "active"){
        newList = clientListAll.filter((client) => {
          return (
            client.is_potential === false && 
            (client.status !== null && client.status !== "closed")
          );
        });
      }else if(clientAllFilter.ddlStatus === "potential"){
        newList = clientListAll.filter((client) => {
          return (
            client.is_potential === true && 
            (client.status !== null && client.status !== "closed")
          );
        });

      }else if(clientAllFilter.ddlStatus === "closed"){
        newList = clientListAll.filter((client) => {
          return (
            client.is_potential === false && 
            client.status === "closed"
          );
        });
      }else{
        newList = clientListAll.filter((client) => {
          return (
            client.is_potential === false && 
            (client.status === null && client.status !== "closed")
          );
        });
      }
    }else{
      newList = clientListAll;
    }

    dispatch(setAllListAllCompanies(newList));

   
  };

  const btnFilter = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtFilter = Object.fromEntries(data.entries());

    dispatch(storePage(0));
    // console.log("dtFil", dtFilter)
    dispatch(setStoreAllFilter({ddlCountry: dtFilter.ddlCountry, txtgenphone: dtFilter.txtgenphone, ddlStatus: dtFilter.ddlStatus }));

      let newList = [];

            if (clientAllFilter.txtsearchclient !== "" && dtFilter.ddlCountry === "" && dtFilter.txtgenphone === "" && dtFilter.ddlStatus === "") {
        newList = clientListAll.filter((client) => {
          return client.name.toLowerCase().includes(clientAllFilter.txtsearchclient.toLowerCase());
        });
      }else if (clientAllFilter.txtsearchclient !== "" && dtFilter.ddlCountry !== "" && dtFilter.txtgenphone === "" && dtFilter.ddlStatus === "") {
        newList = clientListAll.filter((client) => {
          return client.name.toLowerCase().includes(clientAllFilter.txtsearchclient.toLowerCase()) && client.options ? client.options.value.toLowerCase().includes(dtFilter.ddlCountry.toLowerCase()) : "";
        });
      }else if (clientAllFilter.txtsearchclient !== "" && dtFilter.ddlCountry !== "" && dtFilter.txtgenphone !== "" && dtFilter.ddlStatus === "") {
        newList = clientListAll.filter((client) => {
            // console.log("client.options", client.options)
          let cntry = "";

          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          }

          return (
            client.name.toLowerCase().includes(clientAllFilter.txtsearchclient.toLowerCase()) && 
            cntry.includes(dtFilter.ddlCountry) && 
            client.general_telephone ? client.general_telephone.includes(dtFilter.txtgenphone) : ""
          );
        });
      }else if (clientAllFilter.txtsearchclient !== "" && dtFilter.ddlCountry !== "" && dtFilter.txtgenphone !== "" && dtFilter.ddlStatus !== "") {
        if(dtFilter.ddlStatus === "active"){
          newList = clientListAll.filter((client) => {
            let cntry = "";
            let phone = "";

            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };

            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };

            return (
              client.name.toLowerCase().includes(clientAllFilter.txtsearchclient.toLowerCase()) &&
              cntry.includes(dtFilter.ddlCountry) && 
              phone.includes(dtFilter.txtgenphone) &&
              client.is_potential === false && 
              (client.status !== null && client.status !== "closed")
            );
          });
        }else if(dtFilter.ddlStatus === "potential"){
          newList = clientListAll.filter((client) => {
            let cntry = "";
            let phone = "";

            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };

            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };

            return (
              client.name.toLowerCase().includes(clientAllFilter.txtsearchclient.toLowerCase()) &&
              cntry.includes(dtFilter.ddlCountry) && 
              phone.includes(dtFilter.txtgenphone) &&
              client.is_potential === true && 
              (client.status !== null && client.status !== "closed")
            );
          });

        }else if(dtFilter.ddlStatus === "closed"){
          newList = clientListAll.filter((client) => {
            let cntry = "";
            let phone = "";

            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };

            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };

            return (
              client.name.toLowerCase().includes(clientAllFilter.txtsearchclient.toLowerCase()) &&
              cntry.includes(dtFilter.ddlCountry) && 
              phone.includes(dtFilter.txtgenphone) &&
              client.is_potential === false && 
              client.status === "closed"
            );
          });
        }else{
          newList = clientListAll.filter((client) => {
            let cntry = "";
            let phone = "";

            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };

            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };

            return (
              client.name.toLowerCase().includes(clientAllFilter.txtsearchclient.toLowerCase()) &&
              cntry.includes(dtFilter.ddlCountry) && 
              phone.includes(dtFilter.txtgenphone) &&
              client.is_potential === false && 
              (client.status === null && client.status !== "closed")
            );
          });
        }
      }else if (clientAllFilter.txtsearchclient !== "" && dtFilter.ddlCountry !== "" && dtFilter.txtgenphone === "" && dtFilter.ddlStatus !== "") {
        if(dtFilter.ddlStatus === "active"){
          newList = clientListAll.filter((client) => {
            let cntry = "";

            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };

            return (
              client.name.toLowerCase().includes(clientAllFilter.txtsearchclient.toLowerCase()) &&
              cntry.includes(dtFilter.ddlCountry) &&
              client.is_potential === false && 
              (client.status !== null && client.status !== "closed")
            );
          });
        }else if(dtFilter.ddlStatus === "potential"){
          newList = clientListAll.filter((client) => {
            let cntry = "";

            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };

            return (
              cntry.includes(dtFilter.ddlCountry) && 
              client.name.toLowerCase().includes(clientAllFilter.txtsearchclient.toLowerCase()) &&
              client.is_potential === true && 
              (client.status !== null && client.status !== "closed")
            );
          });

        }else if(dtFilter.ddlStatus === "closed"){
          newList = clientListAll.filter((client) => {
            let cntry = "";

            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };

            return (
              cntry.includes(dtFilter.ddlCountry) && 
              client.name.toLowerCase().includes(clientAllFilter.txtsearchclient.toLowerCase()) &&
              client.is_potential === false && 
              client.status === "closed"
            );
          });
        }else{
          newList = clientListAll.filter((client) => {
            let cntry = "";

            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };

            return (
              client.name.toLowerCase().includes(clientAllFilter.txtsearchclient.toLowerCase()) &&
              cntry.includes(dtFilter.ddlCountry) && 
              client.is_potential === false && 
              (client.status === null && client.status !== "closed")
            );
          });
        }

      }else if (clientAllFilter.txtsearchclient !== "" && dtFilter.ddlCountry === "" && dtFilter.txtgenphone !== "" && dtFilter.ddlStatus === "") {
        newList = clientListAll.filter((client) => {
          return (
            client.name.toLowerCase().includes(clientAllFilter.txtsearchclient.toLowerCase()) && 
            client.general_telephone ? client.general_telephone.includes(dtFilter.txtgenphone) : ""
          );
        });
      }else if (clientAllFilter.txtsearchclient !== "" && dtFilter.ddlCountry === "" && dtFilter.txtgenphone !== "" && dtFilter.ddlStatus !== "") {
        if(dtFilter.ddlStatus === "active"){
          newList = clientListAll.filter((client) => {
            let phone = "";

            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };

            return (
              client.name.toLowerCase().includes(clientAllFilter.txtsearchclient.toLowerCase()) && 
              phone.includes(dtFilter.txtgenphone) &&
              client.is_potential === false && 
              (client.status !== null && client.status !== "closed")
            );
          });
        }else if(dtFilter.ddlStatus === "potential"){
          newList = clientListAll.filter((client) => {
            let phone = "";

            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };

            return (
              client.name.toLowerCase().includes(clientAllFilter.txtsearchclient.toLowerCase()) &&
              phone.includes(dtFilter.txtgenphone) &&
              client.is_potential === true && 
              (client.status !== null && client.status !== "closed")
            );
          });

        }else if(dtFilter.ddlStatus === "closed"){
          newList = clientListAll.filter((client) => {
            let phone = "";

            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };

            return (
              client.name.toLowerCase().includes(clientAllFilter.txtsearchclient.toLowerCase()) && 
              phone.includes(dtFilter.txtgenphone) &&
              client.is_potential === false && 
              client.status === "closed"
            );
          });
        }else{
          newList = clientListAll.filter((client) => {
            let phone = "";

            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };

            return (
              client.name.toLowerCase().includes(clientAllFilter.txtsearchclient.toLowerCase()) && 
              phone.includes(dtFilter.txtgenphone) &&
              client.is_potential === false && 
              (client.status === null && client.status !== "closed")
            );
          });
        }

      }else if (clientAllFilter.txtsearchclient !== "" && dtFilter.ddlCountry === "" && dtFilter.txtgenphone === "" && dtFilter.ddlStatus !== "") {
        if(dtFilter.ddlStatus === "active"){
          newList = clientListAll.filter((client) => {
            return (
              client.name.toLowerCase().includes(clientAllFilter.txtsearchclient.toLowerCase()) && 
              client.is_potential === false && 
              (client.status !== null && client.status !== "closed")
            );
          });
        }else if(dtFilter.ddlStatus === "potential"){
          newList = clientListAll.filter((client) => {
            return (
              client.name.toLowerCase().includes(clientAllFilter.txtsearchclient.toLowerCase()) && 
              client.is_potential === true && 
              (client.status !== null && client.status !== "closed")
            );
          });
        }else if(dtFilter.ddlStatus === "closed"){
          newList = clientListAll.filter((client) => {
            return (
              client.name.toLowerCase().includes(clientAllFilter.txtsearchclient.toLowerCase()) && 
              client.is_potential === false && 
              client.status === "closed"
            );
          });
        }else{
          newList = clientListAll.filter((client) => {
            return (
              client.name.toLowerCase().includes(clientAllFilter.txtsearchclient.toLowerCase()) && 
              client.is_potential === false && 
              (client.status === null && client.status !== "closed")
            );
          });
        }

      }else if (clientAllFilter.txtsearchclient === "" && dtFilter.ddlCountry !== "" && dtFilter.txtgenphone === "" && dtFilter.ddlStatus === "") {
        newList = clientListAll.filter((client) => {
            // console.log("client.options", client.options)
          let cntry = "";

          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          }

          return (
            cntry.includes(dtFilter.ddlCountry)
          );
        });
      }else if (clientAllFilter.txtsearchclient === "" && dtFilter.ddlCountry !== "" && dtFilter.txtgenphone !== "" && dtFilter.ddlStatus === "") {
        newList = clientListAll.filter((client) => {
            // console.log("client.options", client.options)
          let cntry = "";

          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          }

          return (
            cntry.includes(dtFilter.ddlCountry) && client.general_telephone ? client.general_telephone.includes(dtFilter.txtgenphone) : ""
          );
        });
      }else if (clientAllFilter.txtsearchclient === "" && dtFilter.ddlCountry !== "" && dtFilter.txtgenphone !== "" && dtFilter.ddlStatus !== "") {
        if(dtFilter.ddlStatus === "active"){
          newList = clientListAll.filter((client) => {
            let cntry = "";
            let phone = "";

            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };

            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };

            return (
              cntry.includes(dtFilter.ddlCountry) && 
              phone.includes(dtFilter.txtgenphone) &&
              client.is_potential === false && 
              (client.status !== null && client.status !== "closed")
            );
          });
        }else if(dtFilter.ddlStatus === "potential"){
          newList = clientListAll.filter((client) => {
            let cntry = "";
            let phone = "";

            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };

            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };

            return (
              cntry.includes(dtFilter.ddlCountry) && 
              phone.includes(dtFilter.txtgenphone) &&
              client.is_potential === true && 
              (client.status !== null && client.status !== "closed")
            );
          });

        }else if(dtFilter.ddlStatus === "closed"){
          newList = clientListAll.filter((client) => {
            let cntry = "";
            let phone = "";

            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };

            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };

            return (
              cntry.includes(dtFilter.ddlCountry) && 
              phone.includes(dtFilter.txtgenphone) &&
              client.is_potential === false && 
              client.status === "closed"
            );
          });
        }else{
          newList = clientListAll.filter((client) => {
            let cntry = "";
            let phone = "";

            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };

            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };

            return (
              cntry.includes(dtFilter.ddlCountry) && 
              phone.includes(dtFilter.txtgenphone) &&
              client.is_potential === false && 
              (client.status === null && client.status !== "closed")
            );
          });
        }
      }else if (clientAllFilter.txtsearchclient === "" && dtFilter.ddlCountry !== "" && dtFilter.txtgenphone === "" && dtFilter.ddlStatus !== "") {
        if(dtFilter.ddlStatus === "active"){
          newList = clientListAll.filter((client) => {
            let cntry = "";

            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };

            return (
              cntry.includes(dtFilter.ddlCountry) && 
              client.is_potential === false && 
              (client.status !== null && client.status !== "closed")
            );
          });
        }else if(dtFilter.ddlStatus === "potential"){
          newList = clientListAll.filter((client) => {
            let cntry = "";

            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };

            return (
              cntry.includes(dtFilter.ddlCountry) && 
              client.is_potential === true && 
              (client.status !== null && client.status !== "closed")
            );
          });

        }else if(dtFilter.ddlStatus === "closed"){
          newList = clientListAll.filter((client) => {
            let cntry = "";

            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };

            return (
              cntry.includes(dtFilter.ddlCountry) && 
              client.is_potential === false && 
              client.status === "closed"
            );
          });
        }else{
          newList = clientListAll.filter((client) => {
            let cntry = "";

            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };

            return (
              cntry.includes(dtFilter.ddlCountry) && 
              client.is_potential === false && 
              (client.status === null && client.status !== "closed")
            );
          });
        }
      }else if (clientAllFilter.txtsearchclient === "" && dtFilter.ddlCountry === "" && dtFilter.txtgenphone !== "" && dtFilter.ddlStatus === "") {
        newList = clientListAll.filter((client) => {
          let phone = "";

          if (client.general_telephone !== null) {
            phone = client.general_telephone;
          } else {
            phone = "";
          };

          return (
            phone.includes(dtFilter.txtgenphone)
          );
        });
      }else if (clientAllFilter.txtsearchclient === "" && dtFilter.ddlCountry === "" && dtFilter.txtgenphone !== "" && dtFilter.ddlStatus !== "") {
        if(dtFilter.ddlStatus === "active"){
          newList = clientListAll.filter((client) => {
            let phone = "";

            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };

            return (
              phone.includes(dtFilter.txtgenphone) &&
              client.is_potential === false && 
              (client.status !== null && client.status !== "closed")
            );
          });
        }else if(dtFilter.ddlStatus === "potential"){
          newList = clientListAll.filter((client) => {
            let phone = "";

            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };

            return (
              phone.includes(dtFilter.txtgenphone) &&
              client.is_potential === true && 
              (client.status !== null && client.status !== "closed")
            );
          });

        }else if(dtFilter.ddlStatus === "closed"){
          newList = clientListAll.filter((client) => {
            let phone = "";

            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };

            return (
              phone.includes(dtFilter.txtgenphone) &&
              client.is_potential === false && 
              client.status === "closed"
            );
          });
        }else{
          newList = clientListAll.filter((client) => {
            let phone = "";

            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };

            return (
              phone.includes(dtFilter.txtgenphone) &&
              client.is_potential === false && 
              (client.status === null && client.status !== "closed")
            );
          });
        }
      }else if (clientAllFilter.txtsearchclient === "" && dtFilter.ddlCountry === "" && dtFilter.txtgenphone === "" && dtFilter.ddlStatus !== "") {
        if(dtFilter.ddlStatus === "active"){
          newList = clientListAll.filter((client) => {
            return (
              client.is_potential === false && 
              (client.status !== null && client.status !== "closed")
            );
          });
        }else if(dtFilter.ddlStatus === "potential"){
          newList = clientListAll.filter((client) => {
            return (
              client.is_potential === true && 
              (client.status !== null && client.status !== "closed")
            );
          });

        }else if(dtFilter.ddlStatus === "closed"){
          newList = clientListAll.filter((client) => {
            return (
              client.is_potential === false && 
              client.status === "closed"
            );
          });
        }else{
          newList = clientListAll.filter((client) => {
            return (
              client.is_potential === false && 
              (client.status === null && client.status !== "closed")
            );
          });
        }
      }else{
        newList = clientListAll;
      }
      
      dispatch(setTempClientAllFilData(newList));
      dispatch(setAllListAllCompanies(newList));

  };

  const btnReset = () => {
    document.getElementById("filter-potential").reset();
    dispatch(
      setStoreAllFilter({
        ddlCountry: "",
        txtgenphone: "",
        ddlStatus: "",
      })
    );
  };

  const handleChangeDDL = (type) => (e) => {

    if(type === "country"){
      dispatch(setStoreAllFilter({
        ddlCountry: e.target.value,
      }));
    }else if(type === "status"){
      dispatch(setStoreAllFilter({
        ddlStatus: e.target.value,
      }));
    }
  };

  const handleCheck = (event) => {
    // console.log("ssss", event.target.checked);
    setCheckedList(event.target.checked);
  };

  const handleChange = (e) => {
    setSelIndNew(Array.isArray(e) ? e.map((x) => x.id) : []);
  };

  // Checking Form Error
  const formError = (object) => {
    const newErrors = {};

    if (!object.txtComName || object.txtComName === "")
      newErrors.txtComName = "Fill in the blank!";

    return newErrors;
  };

  const formErrorsName = (object) => {
    const newErrors = {};

    if (object === "Company Name Exist!")
      newErrors.txtComName = "Company Name Already Exist!";

    return newErrors;
  };

  // Call Function
  // const getListAllClient = useCallback(() => {
  //   dispatch(getListClient());

  //   // setAllData(clientListPotential);
  //   Api.get("client")
  //     .then((response) => {
  //       let data = response.data;
        
  //       // console.log("data", data)
  //       setClientList(data);
  //       setAllData(data);
  //     })
  //     .catch((error) => {
  //       console.log("client", error);
  //     });
  // }, [dispatch]);

  // Button Event
  const btnSaveCompany = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtCom = Object.fromEntries(data.entries());

    // console.log("stock", dtCom)

    const newErrors = formError(dtCom);

    let websiteCheck = !dtCom.txtComWebsite.substring(0, dtCom.txtComWebsite.indexOf(':')) ? !dtCom.txtComWebsite ? "" : "http://" + dtCom.txtComWebsite : dtCom.txtComWebsite;
    let linkedInCheck = !dtCom.txtlinkedln.substring(0, dtCom.txtlinkedln.indexOf(':')) ? !dtCom.txtlinkedln ? "" : "http://" + dtCom.txtlinkedln : dtCom.txtlinkedln;

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      setErrorForm({});
      customSwal
        .fire({
          title: "Do you want to save this company?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Api.post("client", {
              name: dtCom.txtComName,
              incorporated: dtCom.txtincorporate,
              consultant_name: [Number(usrProfile.id)],
              industry: selIndNew.length > 0 ? selIndNew : [],
              headquarters: dtCom.ddlCorporate ? Number(dtCom.ddlCorporate): null,
              address: dtCom.txtComAddr,
              general_telephone: dtCom.txtphone,
              website_url: websiteCheck,
              linkedln_url: linkedInCheck,
              revenue: dtCom.txtrevenue,
              total_employees: dtCom.txtcompanyEmp,
              overall_background: dtCom.txtoverall,
              important_note: dtCom.txtnotes,
              status: null,
              is_parent: false,
              finance_certificate: checkedList,
              stock_exchange: dtCom.ddlStockExchanges ? Number(dtCom.ddlStockExchanges) : null,
              is_longterm: false,
              is_potential: false,
              reason_blacklist: null,
            })
              .then((response) => {
                // setSelectedIndustry([]);

                //close modal after update
                dispatch(getListAllCompanies(clientAllFilter.txtsearchclient, clientAllFilter.ddlCountry, clientAllFilter.txtgenphone, clientAllFilter.ddlStatus));
                setShowAddClient(false);
                customSwal.fire({
                  title: "Company Saved Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("error save company", error);

                const newErrors = formErrorsName(error.response.data.message);

                if (Object.keys(newErrors).length > 0) {
                  setErrorForm(newErrors);
                }
              });
          }
        });
    }
  };

  const btnDelete = (id) => (e) => {

    customSwal
      .fire({
        title: "Do you want to delete this company?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("client/" + id)
            .then((response) => {
              // setSelectedIndustry([]);

              //close modal after update
              dispatch(getListAllCompanies(clientAllFilter.txtsearchclient, clientAllFilter.ddlCountry, clientAllFilter.txtgenphone, clientAllFilter.ddlStatus));
              customSwal.fire({
                title: "Deleted Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error delete potential client", error);
            });
        }
      });
  };

  const btnDeleteMultiple = () => (e) =>{
    customSwal
    .fire({
      title: checkClients.length > 1 ? "Do you want to delete multiple selected companies?" : "Do you want to delete selected company?",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Yes",
    })
    .then((result) => {
      if (result.isConfirmed) {

        checkClients.map((client, index) => {
          Api.delete("client/" + client.id)
          .then((response) => {
            dispatch(getListAllCompanies(clientAllFilter.txtsearchclient, clientAllFilter.ddlCountry, clientAllFilter.txtgenphone, clientAllFilter.ddlStatus));
          })
          .catch((error) => {
            console.log("error delete active client", error);
          });
        });

        //close modal after update
       
        customSwal.fire({
          title: "Deleted Successfully!",
          icon: "success",
          confirmButtonText: "Okay",
        });
        
      }
    });
  };

  const headers = [
    {
      Header: "",
      accessor: "selection",
      disableSortBy: true,
      maxWidth: 50,
      minWidth: 10,
      width: 50,
      Cell: ({ row }) => {
        const data = row.original;

        const onItemCheck = (data) => (event) => {
          if (checkClients.length > 0) {
            if (event.target.checked === false) {
              const index = checkClients.findIndex(function (chck) {
                return chck.id === data.id;
              });

              checkClients.splice(index, 1);
            } else {
              checkClients.push(data);
            }
          } else {
            checkClients.push(data);
          }

          setCliSelected(checkClients.length);
        };

        const checkedItem = (id) => {
          let potCli = [];

          const checkCli = checkClients.filter((chec) => {
            return chec.id === id;
          });

          potCli = checkCli[0];

          return potCli;
        };

        return (
          <div className="checkbox-container">
            <input
              type="checkbox"
              checked={checkedItem(data.id)}
              id={"row" + data.id}
              onChange={onItemCheck(data)}
            />
          </div>
        );
      },
    },
    {
      Header: "Companies Info",
      accessor: (row) => `${row.name}`,
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        function handleClick() {

          // console.log("datarow", data.status);
          dispatch(getClientView({}));
          if(data.is_potential === true && (data.status !== null && data.status !== "closed")){
            navigate("/clients/view-potential-client/" + data.id);
          }else if(data.is_potential === false && (data.status !== null && data.status !== "closed")){
            navigate("/clients/viewActiveC/" + data.id);
          }else{
            navigate("/clients/viewAllClients/" + data.id);
          }
          // navigate("/clients/viewAllClients/" + data.id);
        }

        return (
          <div className="form-container-default">
            <div className="form-container-default-1">
              {data.logo ? (
                <img src={data.logo} alt="Client Logo" />
              ) : (
                <img src={DefaultPhoto} alt="Client Logo" />
              )}
            </div>
            <div
              className="form-container-default-2"
              style={{ paddingLeft: "10px" }}
            >
              <Button
                variant="link"
                className="no-underline"
                onClick={handleClick}
              >
                {data.name}
              </Button>
            </div>
          </div>
        );
      },
    },
    {
      Header: "Country",
      accessor: (row) => `${row.options ? row.options.value : ""}`,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        let country = "";
        !data.options || data.options === ""
          ? (country = "-")
          : (country = data.options.value);

        return <div>{country}</div>;
      },
    },
    {
      Header: "General Telephone",
      accessor: (row) => `${row.general_telephone}`,
      maxWidth: 200,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <>
            {data.general_telephone ? 
              <div>{data.general_telephone}</div>:"-"
            }
          </>
        );
      },
    },
    {
      Header: "Website",
      accessor: (row) => `${row.website_url}`,
      maxWidth: 200,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <>
            {data.website_url ? (
              <div>
                <a href={data.website_url} target="_blank" rel="noreferrer">
                  {data.website_url}
                </a>
              </div>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      Header: "Status",
      accessor: (row) => {
        // const data = row.original;

        let statusDesc = "";

        if (row.is_potential === false && (row.status !== null && row.status !== "closed")) {
          statusDesc = "Active"
        } else if(row.is_potential === true && (row.status !== null && row.status !== "closed")) {
          statusDesc = "Potential";
        } else if(row.is_potential === false && row.status === "closed"){
          statusDesc = "Closed";
        } else{
          statusDesc = "Available";
        }

        return (`${statusDesc}`);
      },
      minWidth: 150,
      width: 150,
      Cell: ({ row }) => {
        const data = row.original;

        let statusDesc = "";
        let styleType = "";

        if(data.is_potential === false && (data.status !== null && data.status !== "closed")){
          statusDesc = "Active";
          styleType = "card_text--warning--solid";
        }else if(data.is_potential === true && (data.status !== null && data.status !== "closed")){
          statusDesc = "Potential";
          styleType = "card_text--success--solid";
        }else if(data.is_potential === false && data.status === "closed"){
          statusDesc = "Closed";
          styleType = "card_text--danger--solid";
        }else{
          statusDesc = "Available";
          styleType = "card_text--primary--solid";
        };

        return (
          <CardInfo
            cardTextSize="card_text--small"
            cardTextStyle={styleType}
          >
            {statusDesc}
          </CardInfo>
        )
      },
    },
    {
      Header: "",
      accessor: "client_info",
      disableSortBy: true,
      maxWidth: 70,
      minWidth: 10,
      width: 70,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="dropdown-container list-cli">
            {abilities.includes("delete potential") ? (
              <Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  <Icon path={mdiDotsHorizontal} size={1} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    hidden={!abilities.includes("delete potential")}
                    onClick={btnDelete(data.id)}
                  >
                    <Icon path={mdiDelete} color="#DF4759" />
                    Delete Client
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
  ];

  const [showMerge, setShowMerge] = useState(false);

  const [clientOne, setClientOne] = useState({});
  const [clientTwo, setClientTwo] = useState({});

  const [merging, setMerging] = useState({
    staffs: false,
    subsidiaries: false,
    assingments: false,
    meetings: false,
    documents: false,
  });

  const [combineClient, setCombineClient] = useState({
    
  });

  const valueClientIdChange = (e) => {
    var selectedID = parseInt(e.target.value);
    var cliID = checkClients.map((s) => s.id);
    // // console.log("candOne", candid[0]);
    // // console.log("candTwo", parseInt(e.target.value));

    if (selectedID === cliID[0]) {
      Object.assign(combineClient, { merging: merging, id: selectedID, id_remove: cliID[1] });
    } else {
      Object.assign(combineClient, { merging: merging, id: selectedID, id_remove: cliID[0] });
    }

    // console.log("combineCand", combineClient);
  };

  const btnMergeClient = (e) => {
    e.preventDefault();

    // console.log("combineClient", combineClient);
    customSwal
      .fire({
        title: "Do you want to merge these companies?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.post("client/updateMergeClient", combineClient)
            .then((response) => {
              let data = response.data;

              // // console.log("data list candidate", data);
              dispatch(getListAllCompanies(clientAllFilter.txtsearchclient, clientAllFilter.ddlCountry, clientAllFilter.txtgenphone, clientAllFilter.ddlStatus));
              setShowMerge(false);
              setCheckClients([]);

              customSwal.fire({
                title: "Merge Successful!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error merge companies", error);
            });
        }
      });

    // const data = new FormData(e.target);
    // const dtMCli = Object.fromEntries(data.entries());

    
  };

  const btnOpenMerge = (e) => {
    setShowMerge(true);

    var cliId = checkClients.map((s) => s.id);

    dispatch(getMergeClient(cliId[0], cliId[1]));

    setClientOne({
      client_id: checkClients[0].id,
      client_name: checkClients[0].name,
    });

    setClientTwo({
      client_id: checkClients[1].id,
      client_name: checkClients[1].name,
    });

    // setCombineClient({
    //   staffs: false,
    //   subsidiaries: false,
    //   assingments: false,
    //   meetings: false,
    //   documents: false,
    // });
  };

  const btnCloseMerge = () => {
    setShowMerge(false);
  };

  const valueClientCheck = (e) => {

    Object.assign(merging, { [e.target.value]: e.currentTarget.checked });

    // console.log("combineClient", combineClient);
  };

  const [loading, setLoading] = useState(true);
  // First Load page
  useEffect(() => {
    if(clientAllFilter.ddlCountry !== "" || clientAllFilter.txtgenphone !== "" || clientAllFilter.ddlStatus !== ""){
      setClicked(true);
    }else{
      setClicked(false);
    }

    dispatch(getListAllCompanies(clientAllFilter.txtsearchclient, clientAllFilter.ddlCountry, clientAllFilter.txtgenphone, clientAllFilter.ddlStatus));
    dispatch(getListStaff());
    dispatch(storeTitle({ title: "Companies", icon: mdiAccountGroup }));

    setTimeout(() => setLoading(false), 2000);
  }, [dispatch]);

  return (
    <>
      {clientListAll.length === 0 ? 
        loading ? 
          <div id="main-overlay" className="main-overlay">
            <Spinner animation="border" variant="light" />
          </div> : ""
      :""}
      <div className="main-div list-cli">
        <div className="top-title-div cli-page">
          <div className="filter_container">
            <div className="filter_container_totalrow">
              {clientAllListAll.length === 0 || clientAllListAll.length === 1 ? (
                <div className="form-container-default">
                  <div className="total_client_text">{clientAllListAll.length}</div>
                  <div className="client_text">Company</div>
                </div>
              ) : (
                <div className="form-container-default">
                  <div className="total_client_text">{clientAllListAll.length}</div>
                  <div className="client_text">Companies</div>
                </div>
              )}
            </div>

            <div className="upper-divider"></div>

            <div className="filter_container_search">
              <div className="filter_container_search-1">
                <TextboxIcon
                  name="txtsearchclient"
                  type="text"
                  onChange={(event) => handleSearch(event)}
                  placeholder="Search by company name"
                  inputSize="input--medium"
                  icon={mdiMagnify}
                  value={clientAllFilter.txtsearchclient}
                ></TextboxIcon>
              </div>
            </div>

            <div className="filter_container_typelist">
              <div className="form-container-filter-button">
                {cliSelected === 0 ? (
                  <>
                    <Button
                      variant="primary"
                      className="btn-plus"
                      onClick={btnOpenAddClient}
                      hidden={!abilities.includes("add potential")}
                    >
                      <Icon path={mdiPlus} />
                    </Button>
                  </>
                ) : (
                  ""
                )}

                {cliSelected === 2 ? (
                  <Button
                    variant="secondary"
                    onClick={btnOpenMerge}
                    // hidden={true}
                    hidden={!abilities.includes("merge clients")}
                  >
                    <Icon path={mdiMerge} />
                    <div> Merge</div>
                  </Button>
                ) : (
                  ""
                )}

                {cliSelected > 0 ? (
                  <Button 
                    variant="danger"
                    hidden={!abilities.includes("delete potential")}
                    onClick={btnDeleteMultiple()}
                  >
                    <Icon path={mdiDelete} />
                    <div>Delete</div>
                  </Button>
                ) : (
                  ""
                )}

                <Button onClick={() => btnCollapse(true)} variant="secondary">
                  <Icon path={mdiFilter} />
                  <div>Filter</div>
                </Button>
              </div>
            </div>
          </div>
        </div>
        
        {/* Filter Box */}
        <div id="demo" className={"collapses" + (clicked ? " in" : "")}>
          <Form id="filter-potential" onSubmit={btnFilter}>
            <div className="form-container-filter">
              <div className="form-container-filter-1">
                <Form.Group>
                  <Form.Label>Country</Form.Label>
                  <Form.Select name="ddlCountry" value={clientAllFilter.ddlCountry} onChange={handleChangeDDL("country")}>
                    <option value="">Select</option>
                    {dataCountry.map((cntry, index) => (
                      <option key={index} value={cntry.value}>
                        {cntry.value}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="form-container-filter-1">
                <Form.Group>
                  <Form.Label>General Telephone</Form.Label>
                  <Form.Control
                    name="txtgenphone"
                    type="text"
                    defaultValue={clientAllFilter.txtgenphone}
                  />
                </Form.Group>
              </div>
              <div className="form-container-filter-1">
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Select name="ddlStatus" value={clientAllFilter.ddlStatus} onChange={handleChangeDDL("status")}>
                    <option value="">Select</option>
                    <option value="active">Active</option>
                    <option value="available">Available</option>
                    <option value="closed">Closed</option>
                    <option value="potential">Potential</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </div>
            <div className="row-btn-bottom">
              <Button variant="secondary" onClick={btnReset}>
                Reset
              </Button>
              <Button variant="primary" type="submit" form="filter-potential">
                Search
              </Button>
            </div>
          </Form>
        </div>

        <div className="container-div list-cli">
          <CustomTable
            headers={headers}
            items={clientAllListAll}
            paginate={clientAllListAll.length}
            maxRows={15}
            pindex={true}
          ></CustomTable>
        </div>

         {/* Modal Popup Add Potential Client */}
         <Popup
            show={showAddClient}
            size="lg"
            backdrop="static"
            keyboard={false}
            onHide={btnCloseAddClient}
            title="Add New Company"
            content={
              <>
                <Form id="create-company-form" onSubmit={btnSaveCompany}>
                  {/* 1 */}
                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Company Name{" "}
                        <label className="validation-star">*</label>
                      </Form.Label>
                      <Form.Control
                        name="txtComName"
                        type="text"
                        isInvalid={!!errorForm.txtComName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorForm.txtComName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  {/* 2 */}
                  <Row>
                    <Form.Group className="mb-3 col-6">
                      <Form.Label>Incorporated</Form.Label>
                      <Form.Control name="txtincorporate" type="text" />
                    </Form.Group>

                    <Form.Group className="mb-3 col-6">
                      <Form.Label>Corporate HQ</Form.Label>
                      <Form.Select name="ddlCorporate">
                        <option value="">Select</option>
                        {dataCountry.map((cntry, index) => (
                          <option key={index} value={cntry.id}>
                            {cntry.value}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Row>
                  {/* 3 */}
                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Label>Industry</Form.Label>
                      <Searchable
                        isMulti={true}
                        options={dataIndustry}
                        onChange={handleChange}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.value}
                      ></Searchable>
                    </Form.Group>
                  </Row>
                  {/* 4 */}
                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Label>Company Address</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="txtComAddr"
                        rows={3}
                      />
                    </Form.Group>
                  </Row>
                  {/* 5 */}
                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Label>Company General Telephone</Form.Label>
                      <TextFieldFormat
                        name="txtphone"
                        format="+### ### ######## ######"
                      ></TextFieldFormat>
                    </Form.Group>
                  </Row>
                  {/* 6 */}
                  <Row>
                    <Form.Group className="mb-3 col-6">
                      <Form.Label>Company Website</Form.Label>
                      <Form.Control
                        name="txtComWebsite"
                        type="text"
                        placeholder="https://"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3 col-6">
                      <Form.Label>Company LinkedIn Profile</Form.Label>
                      <Form.Control
                        name="txtlinkedln"
                        type="text"
                        placeholder="https://"
                      />
                    </Form.Group>
                  </Row>
                  {/* 7 */}
                  <Row>
                    <Form.Group className="mb-3 col-6">
                      <Form.Label>Company Revenue</Form.Label>
                      <Form.Control name="txtrevenue" type="text" />
                    </Form.Group>

                    <Form.Group className="mb-3 col-6">
                      <Form.Label>Company Employees</Form.Label>
                      <Form.Control name="txtcompanyEmp" type="text" />
                    </Form.Group>
                  </Row>
                  {/* 8 */}
                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Check
                        name="cbListed"
                        type="checkbox"
                        onChange={handleCheck}
                        label="Is this a listed company?"
                      />
                    </Form.Group>
                  </Row>
                  {/* 10 */}
                  {checkedList ? 
                    <Row>
                      <Form.Group className="mb-3">
                        <Form.Label>Stock Exchanges</Form.Label>
                        <Form.Select name="ddlStockExchanges">
                          <option value="">Select</option>
                          {dataStockExchanges.map((stock, index) => (
                            <option key={index} value={stock.id}>
                              {stock.value}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Row> : ""
                  }
                  
                  {/* 11 */}
                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Label>Overall Background</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="txtoverall"
                        rows={3}
                      />
                    </Form.Group>
                  </Row>
                  {/* 12 */}
                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Label>Important Notes</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="txtnotes"
                        rows={2}
                      />
                    </Form.Group>
                  </Row>
                </Form>
              </>
            }
            button={
              <>
                <Button variant="secondary" onClick={btnCloseAddClient}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  form="create-company-form"
                >
                  Create
                </Button>
              </>
            }
          />

          {/* Modal Merge */}
        <Popup
          show={showMerge}
          size="xl"
          backdrop="static"
          keyboard={false}
          onHide={btnCloseMerge}
          title="Merge Company"
          content={
            <>
              {/* onSubmit={btnMergeClient} */}
              <Form id="client_form" onSubmit={btnMergeClient}>
                <Table>
                  <thead className="merge-table-thead">
                    <tr>
                      <th colSpan={3}>Master Record and Field Selection</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th width="20%">Master Record</th>
                      <td width="40%">
                        <Form.Check
                          type="radio"
                          label={clientOne.client_name}
                          value={clientOne.client_id}
                          onChange={valueClientIdChange}
                          name="grpMaster"
                          required
                        />
                      </td>
                      <td width="40%">
                        <Form.Check
                          type="radio"
                          label={clientTwo.client_name}
                          value={clientTwo.client_id}
                          onChange={valueClientIdChange}
                          name="grpMaster"
                          required
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div>
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Similar Fields (Auto Merging)
                      </Accordion.Header>
                      <Accordion.Body>
                        <Table>
                          <tbody>
                            {Object.keys(mergeClientSimilar).map((key, index) => {
                              var nameObj = "";
                              var dataObj = "";

                              if (key === "name") {
                                nameObj = "Company Name";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } 
                              else if (key === "incorporated") {
                                nameObj = "Incorporated";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } 
                              else if (key === "headquarters") {
                                nameObj = "Corporate HQ";

                                const result = dataCountry.find((obj) => obj.id === mergeClientSimilar[key]);

                                dataObj = mergeClientSimilar[key]
                                  ? result.value
                                  : "-";
                              } else if (key === "client_has_user") {
                                nameObj = "Consultant Name";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } 
                              else if (key === "industry_detail") {

                                const industryName = mergeClientSimilar[key].length > 0
                                ? mergeClientSimilar[key].map((s) => s.code).toString()
                                : "-";

                                nameObj = "Industry";
                                dataObj = mergeClientSimilar[key].length > 0 ? industryName : "";
                              } 
                              else if (key === "address") {
                                nameObj = "Address";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } 
                              else if (key === "general_telephone") {
                                nameObj = "General Telephone";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } 
                              else if (key === "website_url") {
                                nameObj = "Website";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } 
                              else if (key === "linkedln_url") {
                                nameObj = "LinkedIn Profile";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } 
                              else if (key === "revenue") {
                                nameObj = "Revenue";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } 
                              else if (key === "total_employees") {
                                nameObj = "Employees";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } 
                              else if (key === "overall_background") {
                                nameObj = "Overall Background";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } 
                              else if (key === "important_note") {
                                nameObj = "Important Notes";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } 
                              else if (key === "status") {
                                nameObj = "Status";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } 
                              else if (key === "finance_certificate") {
                                nameObj = "Finance Certificate";
                                dataObj = mergeClientSimilar[key]
                                  ? "Yes"
                                  : "No";
                              } 
                              else if (key === "is_parent") {
                                nameObj = "Parent";
                                dataObj = mergeClientSimilar[key]
                                  ? "Yes"
                                  : "No";
                              } 
                              else if (key === "parent_id") {
                                nameObj = "Parent Company";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } 
                              else if (key === "reason_blacklist")  {
                                nameObj = "Reason Blacklist";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } 
                              else if (key === "consultant_detail")  {
                                nameObj = "Consultant";
                                dataObj = mergeClientSimilar[key].length > 0
                                  ? 
                                  mergeClientSimilar[key].map((s) =>
                                          s.last_name
                                            ? s.first_name + " " + s.last_name
                                            : s.first_name
                                        )
                                        .toString()
                                  : "-";
                              } 
                              else if (key === "consultant_name") {
                                nameObj = "";
                                dataObj = ""
                              } 
                              else if (key === "industry") {
                                nameObj = "";
                                dataObj = ""
                              } 

                              return (
                                <div key={index}>
                                  {key === "consultant_name" ? 
                                    "": key === "industry" ? "" :
                                    <tr>
                                      <th>{nameObj}</th>
                                      <td>{dataObj}</td>
                                    </tr>
                                  }
                                </div>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Field Selection</Accordion.Header>
                      <Accordion.Body>
                        <Table>
                          <tbody>
                            {mergeClientNoSimilar.map((data, index) => {
                              var nameObj = "";
                              var dataCandOne = "";
                              var dataCandTwo = "";

                              if (data.name === "name") {
                                nameObj = "Company Name";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "incorporated") {
                                nameObj = "Incorporated";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "headquarters") {
                                nameObj = "Corporate HQ";

                                const resultCand1 = dataCountry.find((obj) => obj.id === data.candOne);
                                const resultCand2 = dataCountry.find((obj) => obj.id === data.candTwo);

                                dataCandOne = data.candOne ? resultCand1.value : "-";
                                dataCandTwo = data.candTwo ? resultCand2.value : "-";
                              } else if (data.name === "consultant_detail") {
                                nameObj = "Consultant Name";
                                dataCandOne =
                                  data.candOne.length > 0
                                    ? data.candOne
                                        .map((s) =>
                                          s.last_name
                                            ? s.first_name + " " + s.last_name
                                            : s.first_name
                                        )
                                        .toString()
                                    : "-";
                                dataCandTwo =
                                  data.candTwo.length > 0
                                    ? data.candTwo
                                        .map((s) =>
                                          s.last_name
                                            ? s.first_name + " " + s.last_name
                                            : s.first_name
                                        )
                                        .toString()
                                    : "-";
                                // dataValueOne = data.candOne.length > 0 ? data.candOne.map((s) => s.user.id) : "-";
                              } else if (data.name === "industry_detail") {
                                nameObj = "Industry";
                                dataCandOne =
                                  data.candOne.length > 0
                                    ? data.candOne.map((s) => s.code).toString()
                                    : "-";
                                dataCandTwo =
                                  data.candTwo.length > 0
                                    ? data.candTwo.map((s) => s.code).toString()
                                    : "-";
                              } else if (data.name === "address") {
                                nameObj = "Address";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "general_telephone") {
                                nameObj = "General Telephone";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "website_url") {
                                nameObj = "Website";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "linkedln_url") {
                                nameObj = "LinkedIn Profile";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "revenue") {
                                nameObj = "Revenue";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "total_employees") {
                                nameObj = "Employees";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "overall_background") {
                                nameObj = "Overall Background";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "important_note") {
                                nameObj = "Important Notes";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "status") {
                                nameObj = "Status";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "is_parent") {
                                nameObj = "Parent";
                                dataCandOne = data.candOne ? "Yes" : "No";
                                dataCandTwo = data.candTwo ? "Yes" : "No";
                              } else if (data.name === "finance_certificate") {
                                nameObj = "Finance Certificate";
                                dataCandOne = data.candOne ? "Yes" : "No";
                                dataCandTwo = data.candTwo ? "Yes" : "No";
                              } else if (data.name === "reason_blacklist") {
                                nameObj = "Reason Blacklist";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "parent_id") {
                                nameObj = "Parent Company";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              }else {
                                nameObj = "Parent Company";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              }
                              // else{
                              //   nameObj = "No";
                              //   dataCandOne = data.candOne ? data.candOne : "-";
                              //   dataCandTwo = data.candTwo ? data.candTwo : "-";
                              // }

                              const valueClientObject = (data) => (e) => {
                                const str = e.target.value;

                                const property = str.substring(
                                  0,
                                  str.indexOf(" ")
                                ); // "72"

                                if (property === "industry_detail") {
                                  const industryID = data.map((s) => s.id);

                                  Object.assign(combineClient, {
                                    industry: industryID,
                                  });
                                } else if (property === "consultant_detail") {
                                  const consultID = data.map((s) => s.id);

                                  Object.assign(combineClient, {
                                    consultant_name: consultID,
                                  });
                                } else if (property === "headquarters") {
                                  const dataValue = str.substring(
                                    str.indexOf(" ") + 1
                                  );

                                  Object.assign(combineClient, {
                                    [property]: parseInt(dataValue),
                                  });
                                }else if (property === "finance_certificate") {
                                  const dataValue = str.substring(
                                    str.indexOf(" ") + 1
                                  );

                                  Object.assign(combineClient, {
                                    [property]: dataValue === "true" ? true : false,
                                  });
                                }else if (property === "is_parent") {
                                  const dataValue = str.substring(
                                    str.indexOf(" ") + 1
                                  );

                                  Object.assign(combineClient, {
                                    [property]: dataValue === "true" ? true : false,
                                  });
                                }
                                else {
                                  const dataValue = str.substring(
                                    str.indexOf(" ") + 1
                                  );

                                  Object.assign(combineClient, {
                                    [property]: dataValue,
                                  });
                                }

                                console.log("setCombineCand", combineClient);
                              };

                              return (
                                <>
                                  {
                                    data.name === "industry" ? 
                                      "" : data.name === "consultant_name" ? "" :
                                      <tr key={index}>
                                        <th width="20%">{nameObj}</th>
                                        <td width="40%">
                                          <Form.Check
                                            type="radio"
                                            label={dataCandOne}
                                            name={index}
                                            value={data.name + " " + data.candOne}
                                            onChange={valueClientObject(data.candOne)}
                                            required
                                          />
                                        </td>
                                        <td width="40%">
                                          <Form.Check
                                            type="radio"
                                            label={dataCandTwo}
                                            name={index}
                                            value={data.name + " " + data.candTwo}
                                            onChange={valueClientObject(data.candTwo)}
                                            required
                                          />
                                        </td>
                                      </tr>
                                
                                  }
                                </>
                                
                                
                              );
                            })}
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <Table>
                  <tbody>
                    <tr>
                      <td colSpan={3}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="Staff"
                            value="staffs"
                            onChange={valueClientCheck}
                          />
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="Subsidiaries"
                            value="subsidiaries"
                            onChange={valueClientCheck}
                          />
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="Assignments"
                            value="assignments"
                            onChange={valueClientCheck}
                          />
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="Meetings"
                            value="meetings"
                            onChange={valueClientCheck}
                          />
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="Files/Documents"
                            value="documents"
                            onChange={valueClientCheck}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form>
            </>
          }
          button={
            <>
              <Button variant="secondary" onClick={btnCloseMerge}>
                Cancel
              </Button>
              <Button variant="primary" type="submit" form="client_form">
                Merge
              </Button>
            </>
          }
        />
      </div>
    </>
  );
}

export default ListAllClients;
