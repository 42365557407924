import React from "react";
import "./Form.css";

const STYLES = ["form--text--gray", "form--text--black"];

const SIZESCOL1 = ["col-15", "col-30"];

const SIZESCOL2 = ["col-70", "col-85"];

export const InformationForm = (props) => {
  const checkTextStyle = STYLES.includes(props.textStyle)
    ? props.textStyle
    : STYLES[0];
  const checkCol1SizeStyle = SIZESCOL1.includes(props.sizeCol1Style)
    ? props.sizeCol1Style
    : SIZESCOL1[0];
  const checkCol2SizeStyle = SIZESCOL2.includes(props.sizeCol2Style)
    ? props.sizeCol2Style
    : SIZESCOL2[0];

  return (
    <form>
      <div className="bottom-info-div">
        <div className={`${checkCol1SizeStyle} ${checkTextStyle}`}>
          <label>{props.label}</label>
        </div>
        <div className={`${checkCol2SizeStyle}`}>{props.children}</div>
      </div>
    </form>
  );
};

export const ColumnInformationForm = (props) => {
  const checkTextStyle = STYLES.includes(props.textStyle)
    ? props.textStyle
    : STYLES[0];
  const checkCol1SizeStyle = SIZESCOL1.includes(props.sizeCol1Style)
    ? props.sizeCol1Style
    : SIZESCOL1[0];
  const checkCol2SizeStyle = SIZESCOL2.includes(props.sizeCol2Style)
    ? props.sizeCol2Style
    : SIZESCOL2[0];

  return (
    <form>
      <div className="form-container">
        <div className={`${checkCol1SizeStyle} ${checkTextStyle}`}>
          <label>{props.label}</label>
        </div>
        <div className={`${checkCol2SizeStyle}`}>{props.children}</div>
      </div>
    </form>
  );
};
