import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Form, Button } from "react-bootstrap";
import Api from "../../../../core/services/api.service";

import SwalAlert from "../../../../components/Swal/SwalAlert";

import { mdiMinusCircle, mdiPlusCircle, mdiShieldLock } from "@mdi/js";
import Icon from "@mdi/react";

import { storeTitle } from "../../../../store/actions/headerTitleSlice";

import "./notification.css";

const Notification = () => {
  const dispatch = useDispatch();
  const customSwal = SwalAlert();

  // Start Progress Report Reminders Function
  const [reportReminders, setReportReminders] = useState([]);
  const [emailRemReport, setEmailRemReport] = useState(false);
  const [appRemReport, setAppRemReport] = useState(true);

  const handleDayMultiple = (name, index) => (e) => {
    let newArr = reportReminders.map((item, i) => {
      if (index === i) {
        return { ...item, [name]: e.target.value };
      } else {
        return item;
      }
    });
    setReportReminders(newArr);
  };

  const handleCheckEmailReport = (e) => {
    setEmailRemReport(e.target.checked);

    setReportReminders((current) =>
      current.map((obj) => {
        return { ...obj, email_reminder: e.target.checked };
      })
    );
  };

  const handleCheckAppReport = (e) => {
    setAppRemReport(e.target.checked);

    setReportReminders((current) =>
      current.map((obj) => {
        return { ...obj, in_app_reminder: e.target.checked };
      })
    );
  };

  const btnDelReportReminder = (index) => {
    let data = [...reportReminders];
    data.splice(index, 1);

    setReportReminders(data);
  };

  const btnAddReportReminder = () => {
    const totLength = reportReminders.length + 1;
    setReportReminders((items) => [
      ...items,
      {
        id: totLength,
        type: "Progress",
        duration: "0",
        email_reminder: emailRemReport,
        in_app_reminder: appRemReport,
        created_at: null,
        created_by: null,
      },
    ]);
  };
  // End Progress Report Reminders Function

  // Start Follow-up Reminders Function
  const [followReminders, setFollowReminders] = useState([]);
  const [emailRemFollowup, setEmailRemFollowup] = useState(false);
  const [appRemFollowup, setAppRemFollowup] = useState(true);

  const handleDayMultipleFollowup = (name, index) => (e) => {
    let newArr = followReminders.map((item, i) => {
      if (index === i) {
        return { ...item, [name]: e.target.value };
      } else {
        return item;
      }
    });
    setFollowReminders(newArr);
  };

  const handleCheckEmailFollowup = (e) => {
    setEmailRemFollowup(e.target.checked);

    setFollowReminders((current) =>
      current.map((obj) => {
        return { ...obj, email_reminder: e.target.checked };
      })
    );
  };

  const handleCheckAppFollowup = (e) => {
    setAppRemFollowup(e.target.checked);

    setFollowReminders((current) =>
      current.map((obj) => {
        return { ...obj, in_app_reminder: e.target.checked };
      })
    );
  };

  const btnDelFollowReminder = (index) => {
    let data = [...followReminders];
    data.splice(index, 1);
    setFollowReminders(data);
  };

  const btnAddFollowReminder = () => {
    const totLength = followReminders.length + 1;
    setFollowReminders((items) => [
      ...items,
      {
        id: totLength,
        type: "Follow-up",
        duration: "0",
        email_reminder: emailRemFollowup,
        in_app_reminder: appRemFollowup,
        created_at: null,
        created_by: null,
      },
    ]);
  };

  // End Follow-up Reminders Function

  const btnDiscard = (e) => {
    getSettingNotification();
  };

  const btnSaveSetting = (e) => {
    const dataSettings = [...reportReminders, ...followReminders];

    const newArray = dataSettings.map((item) => ({
      type: item.type,
      duration: item.duration.toString(),
      email_reminder: item.email_reminder,
      in_app_reminder: item.in_app_reminder,
    }));

    customSwal
      .fire({
        title: "Do you want to update notification management?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.post("notification/setting/updateSetting", {
            data: newArray,
          })
            .then((response) => {
              getSettingNotification();

              customSwal.fire({
                title: "Updated Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error btnSaveSetting", error);
            });
        }
      });
  };

  const getSettingNotification = useCallback(() => {
    Api.get("notification/setting/getAllSetting")
      .then((response) => {
        const data = response.data;

        let listprogress = data.filter((pro) => pro.type === "Progress");

        setReportReminders(listprogress);
        setEmailRemReport(
          listprogress.length > 0 ? listprogress[0].email_reminder : false
        );
        setAppRemReport(
          listprogress.length > 0 ? listprogress[0].in_app_reminder : false
        );

        let listfollowup = data.filter((pro) => pro.type === "Follow-up");

        setFollowReminders(listfollowup);
        setEmailRemFollowup(
          listfollowup.length > 0 ? listfollowup[0].email_reminder : false
        );
        setAppRemFollowup(
          listfollowup.length > 0 ? listfollowup[0].in_app_reminder : false
        );
      })
      .catch((error) => {
        console.log("error getSettingNotification", error);
      });
  }, []);

  useEffect(() => {
    getSettingNotification();
    dispatch(
      storeTitle({ title: "Notification Management", icon: mdiShieldLock })
    );
  }, [getSettingNotification, dispatch]);

  return (
    <div className="main-div noti-reminder">
      <div className="bg-white-container">
        {/* Progress Report Setting*/}
        <div className="reminder-container">
          <div className="title-text">Progress Report Reminders</div>
          <div className="writing-text">
            Send reminders to staff assigned to an assignment
          </div>

          {/* Dynamic row */}
          <div className="row-input-column">
            {reportReminders.map((row, index) => {
              return (
                <div key={index} className="row-input">
                  <div>
                    <Form.Control
                      className="form-setting-control"
                      type="number"
                      min="0"
                      value={row.duration}
                      onChange={handleDayMultiple("duration", index)}
                    />
                  </div>
                  <div>days after assignment date</div>
                  <div className="del-noti">
                    {reportReminders.length > 1 ? (
                      <Button
                        variant="link"
                        className="modal-link"
                        onClick={() => btnDelReportReminder(index)}
                      >
                        <Icon path={mdiMinusCircle} />
                        <div>Delete Notification</div>
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          {/* Add button if length < 3*/}
          {reportReminders.length < 3 ? (
            <div className="row-input">
              <div>
                <Button
                  variant="link"
                  className="modal-link"
                  onClick={btnAddReportReminder}
                >
                  <Icon path={mdiPlusCircle} />
                  <div>Add Notification</div>
                </Button>
              </div>
            </div>
          ) : null}

          {/* End dynamic row */}

          <div className="checkbox-list">
            <Form.Check
              type="checkbox"
              label="Send in-app reminders"
              className="form-check-merge"
              defaultChecked={appRemReport}
              onChange={handleCheckAppReport}
              disabled
            />
          </div>
          <div className="checkbox-list">
            <Form.Check
              type="checkbox"
              label="Send email reminders"
              checked={emailRemReport}
              onChange={handleCheckEmailReport}
              className="form-check-merge"
            />
          </div>
        </div>

        {/* Follow-up Reminders */}
        <div className="reminder-container" style={{ marginTop: "40px" }}>
          <div className="title-text">Follow-up Reminders</div>
          <div className="writing-text">Send reminders to assigned staff</div>

          {/* Dynamic row */}
          <div className="row-input-column">
            {followReminders.map((row, index) => {
              return (
                <div className="row-input">
                  <div>
                    <Form.Control
                      className="form-setting-control"
                      type="number"
                      min="0"
                      value={row.duration}
                      onChange={handleDayMultipleFollowup("duration", index)}
                    />
                  </div>
                  <div>days after placement date</div>
                  <div className="del-noti">
                    {followReminders.length > 1 ? (
                      <Button
                        variant="link"
                        className="modal-link"
                        onClick={() => btnDelFollowReminder(index)}
                      >
                        <Icon path={mdiMinusCircle} />
                        <div>Delete Notification</div>
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          {/* Add button if length < 3*/}
          {followReminders.length < 3 ? (
            <div className="row-input">
              <div>
                <Button
                  variant="link"
                  className="modal-link"
                  onClick={btnAddFollowReminder}
                >
                  <Icon path={mdiPlusCircle} />
                  Add Notification
                </Button>
              </div>
            </div>
          ) : null}

          {/* End dynamic row */}

          <div className="checkbox-list">
            <Form.Check
              type="checkbox"
              label="Send in-app reminders"
              className="form-check-merge"
              defaultChecked={appRemFollowup}
              onChange={handleCheckAppFollowup}
              disabled
            />
          </div>
          <div className="checkbox-list">
            <Form.Check
              type="checkbox"
              label="Send email reminders"
              className="form-check-merge"
              checked={emailRemFollowup}
              onChange={handleCheckEmailFollowup}
            />
          </div>
        </div>

        <div className="row-btn-bottom">
          <Button variant="secondary" type="submit" onClick={btnDiscard}>
            Discard
          </Button>
          <Button variant="primary" type="submit" onClick={btnSaveSetting}>
            Save Changes
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Notification;
