import {createSlice} from '@reduxjs/toolkit';


const initialState = {
    indAsgnTab:null
}

const tabAssignSlice = createSlice({
    name: 'tabAsgnSlice',
    initialState,
    reducers:{
        storeIndAssignTab: (state, action) => {
            state.indAsgnTab = action.payload;
        },
    },
})

const {reducer, actions} = tabAssignSlice;

export const { storeIndAssignTab } = actions;

export default reducer;