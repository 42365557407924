import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
// import { useDispatch } from "react-redux";
import { Button, Dropdown, Card } from "react-bootstrap";
import Api from "../../core/services/api.service";

import AuthAbilities from "../../pages/Admin/Auth/AuthAbilities";
import SwalAlert from "../Swal/SwalAlert";

import {
  mdiMagnify,
  mdiAccountPlus,
  mdiDelete,
  mdiDotsHorizontal,
} from "@mdi/js";
import Icon from "@mdi/react";

import Modal from "../Popup/ModalPopup";
import Table from "../Table/Table";
import TextboxIcon from "../../components/TextField/TextFieldIcon";

import DefaultPhoto from "../../assets/no-profile-photo.jpg";

export const TabSubsidiary = () => {
  // const navigate = useNavigate();
  const params = useParams();
  // const dispatch = useDispatch();

  const abilities = AuthAbilities();

  const [countryList, setCountryList] = useState([]);

  const [subsList, setSubsList] = useState([]);
  const [allListSubsidiary, setAllListSubsidiary] = useState([]);

  const [addListCompany, setAddListCompany] = useState([]);
  const [allListNonParent, setAllListNonParent] = useState([]);

  const [showSubs, setShowSubs] = useState(false);

  const [checkAddCompany, setCheckAddCompany] = useState([]);
  const [compSelected, setCompSelected] = useState(0);

  const customSwal = SwalAlert();

  const headerSubsidiary = [
    {
      Header: "No",
      maxWidth: 70,
      minWidth: 70,
      width: 70,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Company Name",
      accessor: "name",
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        function handleClick() {
          if (data.is_potential === true &&  data.status !== null && data.status !== "closed") {
            window.open(process.env.REACT_APP_BASE_URL + "clients/view-potential-client/" + data.id);
          } else if (data.is_potential === false &&  data.status !== null && data.status !== "closed") {
            window.open(process.env.REACT_APP_BASE_URL + "clients/viewActiveC/" + data.id);
          } else{
            window.open(process.env.REACT_APP_BASE_URL + "clients/viewAllClients/" + data.id);
          }
          //
        }

        return (
          <div className="container-div list-cli">
            <Button
              variant="link"
              className="no-underline"
              onClick={handleClick}
            >
              {data.name}
            </Button>
          </div>
        );
      },
    },
    {
      Header: "Country",
      maxWidth: 200,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        let country = "";
        !data.options || data.options === ""
          ? (country = "-")
          : (country = data.options.value);

        return <div>{country}</div>;
      },
    },
    {
      Header: "Website",
      accessor: "website_url",
      maxWidth: 200,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        let website_url = "";
        !data.website_url || data.website_url === ""
          ? (website_url = "-")
          : (website_url = data.website_url);

        return (
          <div>
            <a href={website_url} target="_blank" rel="noreferrer">
              {website_url}
            </a>
          </div>
        );
      },
    },
    {
      Header: "",
      // accessor: row => `${row.oposition} ${row.totPosition}`,
      accessor: "edit",
      disableSortBy: true,
      maxWidth: 100,
      minWidth: 10,
      width: 70,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <>
            {abilities.includes("delete clients") ? (
              <div className="dropdown-container list-cli">
                <Dropdown>
                  <Dropdown.Toggle variant="light" id="dropdown-basic">
                    <Icon path={mdiDotsHorizontal} size={1} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={btnDelAssign(data.id)}
                      hidden={!abilities.includes("delete subsidiary")}
                    >
                      {" "}
                      <Icon path={mdiDelete} color="#DF4759" />
                      Delete Subsidiary
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];

  const headerAddSubsidiary = [
    {
      Header: "Company Name",
      minWidth: 100,
      width: 400,
      accessor: (row) => `${row.com_name} ${row.icon_com}`,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="form-container-default">
            <div className="form-container-default-1">
              {data.logo ? (
                <img src={data.logo} alt="Client Logo" />
              ) : (
                <img src={DefaultPhoto} alt="Client Logo" />
              )}
            </div>
            <div
              className="form-container-default-2"
              style={{ paddingLeft: "10px" }}
            >
              <div>{data.name}</div>
            </div>
          </div>
        );
      },
    },
    {
      Header: "Country",
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        let descLocation = "";

        if (data.headquarters !== null) {
          let filterLoc = countryList.filter((country) => {
            // if (country.id === data.headquarters) {
            return country.id === data.headquarters;
            // }
          });

          descLocation = filterLoc[0].value;
        } else {
          descLocation = "-";
        }

        return (
          <>
            <div>{descLocation}</div>
          </>
        );
      },
    },
    {
      Header: "Website",
      minWidth: 100,
      width: 300,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <>
            {data.website_url !== null ? (
              <a href={data.website_url} target="_blank" rel="noreferrer">
                {data.website_url}
              </a>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      Header: "",
      accessor: "selection",
      disableSortBy: true,
      minWidth: 10,
      width: 50,
      Cell: ({ row }) => {
        const data = row.original;

        const onItemCheck = (data) => (event) => {
          if (checkAddCompany.length > 0) {
            if (event.target.checked === false) {
              const index = checkAddCompany.findIndex(function (chck) {
                return chck.id === data.id;
              });

              checkAddCompany.splice(index, 1);
            } else {
              checkAddCompany.push(data);
            }
          } else {
            checkAddCompany.push(data);
          }

          setCompSelected(checkAddCompany.length);
          // console.log("checkAddCompany", checkAddCompany);
        };

        const checkedItem = (id) => {
          let comp = [];

          const checkcomp = checkAddCompany.filter((chec) => {
            return chec.id === id;
          });

          comp = checkcomp[0];

          return comp;
        };

        return (
          <div className="form-container-default-2">
            <input
              type="checkbox"
              checked={checkedItem(data.id)}
              id={"row" + data.id}
              onChange={onItemCheck(data)}
            />
          </div>
        );
      },
    },
  ];

  const findDataSearchList = (object) => {
    let newList = [];

    newList = subsList.filter((subs) =>
      subs.name.toLowerCase().includes(object.toLowerCase())
    );

    return newList;
  };

  const handleSearchList = (event) => {
    let value = event.target.value;

    const newListSubsidiary = findDataSearchList(value);

    setAllListSubsidiary(newListSubsidiary);
  };

  const findDataSearchListAdd = (object) => {
    let newList = [];

    newList = addListCompany.filter((newSubs) =>
      newSubs.name.toLowerCase().includes(object.toLowerCase())
    );

    return newList;
  };

  const handleSearchAddCom = (event) => {
    let value = event.target.value;

    const newListAddSubsidiary = findDataSearchListAdd(value);

    setAllListNonParent(newListAddSubsidiary);
  };

  const formNewArraySubs = (object) => {
    let newArray;
    newArray = Array.isArray(object) ? object.map((x) => x.id) : [];

    return newArray;
  };

  const btnAssign = () => {
    const newAssignSubs = formNewArraySubs(checkAddCompany);

    // console.log("newAssignSubs", newAssignSubs);
    if (newAssignSubs.length > 0) {
      customSwal
        .fire({
          title: "Do you want to save subsidiary assign?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Api.post("client/" + params.id + "/subsidiaries/store", {
              subsidiary_id: newAssignSubs,
            })
              .then((response) => {
                //   let data = response.data;

                setShowSubs(false);
                getListSubsidiary();
                customSwal.fire({
                  title: "Saved Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("client", error);
              });
          }
        });
    } else {
      customSwal.fire({
        title: "Please select company to assign",
        icon: "warning",
        confirmButtonText: "Okay",
      });
    }
  };

  const btnCloseSubs = () => {
    setShowSubs(false);
  };

  const btnAddSubs = (e) => {
    setCompSelected(0);
    setCheckAddCompany([]);
    setShowSubs(true);
    getListNonParentCompany();
  };

  const btnDelAssign = (idcomp) => () => {
    customSwal
      .fire({
        title: "Do you want to delete subsidiary?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.post("client/" + params.id + "/subsidiaries/delete/" + idcomp)
            .then((response) => {
              setShowSubs(false);
              getListSubsidiary();
              customSwal.fire({
                title: "Deleted Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("client", error);
            });
        }
      });
  };

  const getListOption = () => {
    // console.log("value", hqvalue);
    Api.get("option/list")
      .then((response) => {
        // console.log("option/list", response.data);

        let listCountry = response.data.filter((grp) =>
          grp.group.includes("Country")
        );

        let sortListCountry = listCountry.sort(function (a, b) {
          return a.value.localeCompare(b.value);
        });

        setCountryList(sortListCountry);
      })
      .catch((error) => {
        console.log("error getListOption", error);
      });
  };

  const getListNonParentCompany = useCallback(() => {
    Api.get("client/subsidiaries/listNonParentCompany")
      .then((response) => {
        let data = response.data;

        // console.log("data getListNonParentCompany", data);
        if (data !== "no data") {
          setAddListCompany(data);
          setAllListNonParent(data);
        }
      })
      .catch((error) => {
        console.log("error getListNonParentCompany", error);
      });
  }, []);

  const getListSubsidiary = useCallback(() => {
    Api.get("client/" + params.id + "/subsidiaries")
      .then((response) => {
        let data = response.data;

        if (data !== "no data") {
          setSubsList(data);
          setAllListSubsidiary(data);
        }
      })
      .catch((error) => {
        console.log("error getListSubsidiary", error);
      });
  }, [params]);

  // Load Subsidiary
  useEffect(() => {
    getListSubsidiary();
    getListOption();
  }, [getListSubsidiary]);

  return (
    <>
      <Card>
        <Card.Body>
          <div className="row-div">
            <div className="left-div">
              <TextboxIcon
                name="txtsearchCom"
                type="text"
                placeholder="Search"
                inputSize="input--medium"
                onChange={(event) => handleSearchList(event)}
                icon={mdiMagnify}
              ></TextboxIcon>
            </div>
            <div className="right-div">
              <Button
                onClick={btnAddSubs}
                variant="link"
                className="modal-link"
                hidden={!abilities.includes("add subsidiary")}
              >
                <Icon path={mdiAccountPlus} />
                <div>Add Subsidiary</div>
              </Button>
            </div>
          </div>

          <Table
            headers={headerSubsidiary}
            items={allListSubsidiary}
            paginate={allListSubsidiary.length}
            maxRows={10}
          ></Table>
        </Card.Body>
      </Card>

      {/* Modal */}
      <Modal
        show={showSubs}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseSubs}
        title="Assign to Parent Company"
        content={
          <div className="list-cli">
            <div className="row-div">
              <div className="left-div">
                <TextboxIcon
                  name="txtaddcom"
                  type="text"
                  placeholder="Search"
                  inputSize="input--medium"
                  onChange={(event) => handleSearchAddCom(event)}
                  icon={mdiMagnify}
                ></TextboxIcon>
              </div>
              <div className="right-div comp-selected">
                {compSelected} Company selected
              </div>
            </div>

            <Table
              headers={headerAddSubsidiary}
              items={allListNonParent}
              paginate={allListNonParent.length}
            ></Table>
          </div>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseSubs}>
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={btnAssign}
              variant="primary"
              disabled={compSelected > 0 ? false : true}
            >
              Assign
            </Button>
          </>
        }
      ></Modal>
    </>
  );
};
