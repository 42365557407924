import React from "react";
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import "./Table.css";
import { Table } from "react-bootstrap";

export const TableNote = ({
  headers,
  items,
  type,
  setSelectedRowIds,
  paginate,
}) => {
  const columns = headers;
  // useMemo(() => headers, [])
  const data = items;

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 5 },
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    // setSortBy,
    state: { pageIndex, pageSize, selectedRowIds },
  } = tableInstance;

  if (type === "1") {
    setSelectedRowIds(Object.keys(selectedRowIds).length);
  }

  return (
    <>
      {/* <div className="container_table"> */}
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return column.hideHeader === true ? (
                  <th
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({
                        style: {
                          minWidth: column.minWidth,
                          width: column.width,
                        },
                      })
                    )}
                  >
                    {/* {column.render("Header")} */}
                  </th>
                ) : (
                  <th
                    className="header_text"
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({
                        style: {
                          minWidth: column.minWidth,
                          width: column.width,
                        },
                      })
                    )}
                  >
                    {column.render("Header")}
                  </th>
                );
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
              })}
            </tr>
          ))}
        </thead>
        <tbody className="notes-table" {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      {paginate > pageSize ? (
        <div className="pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>{" "}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>{" "}
          <span>
            Page{"  "}
            {pageIndex + 1} of {pageOptions.length}
            {/* <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "} */}
          </span>
          <span>| Go to page: </span>{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            min="1"
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[15, 25, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
      ) : null}

      <pre hidden>
        <code>
          {JSON.stringify(
            {
              selectedRowIds: selectedRowIds,
              "selectedFlatRows[].original": selectedFlatRows.map(
                (d) => d.original
              ),
            },
            null,
            2
          )}
        </code>
      </pre>
    </>
  );
};

export default TableNote;
