import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import jwt_decode from "jwt-decode";
import {
  loginPending,
  loginSuccess,
  loginFail,
} from "../../../../store/actions/loginSlice";
import { storeSuccess } from "../../../../store/actions/registerSlice";

import "./login.css";
import logo from "../../../../assets/logo.png";
import { Form, Button, Alert } from "react-bootstrap";
import { userLoginClient } from "../../../../routes/Api/userApi";
// import ButtonText from "../../../../components/Button/ButtonText";

import Icon from "@mdi/react";
import { mdiAccountLock } from "@mdi/js";

const ClientLogin = () => {
  const { handleSubmit, register } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { error } = useSelector((state) => state.login);
  const { textSuccess } = useSelector((state) => state.register);

  const [errorForm, setErrorForm] = useState({});

  const candidateLogin = () => {
    navigate("/candidate-login");
  };

  function forgotPassword() {
    navigate("/forgot-password/client");
  }

  // function registerClient() {
  //   navigate("/client-register");
  // }

  const formError = (object) => {
    const newErrors = {};

    if (!object.txtemail || object.txtemail === "")
      newErrors.txtemail = "Please insert your company email!";

    if (!object.txtpassword || object.txtpassword === "")
      newErrors.txtpassword = "Please insert your company password!";

    return newErrors;
  };

  const btnLogin = async (index) => {
    const email = index.txtemail;
    const password = index.txtpassword;

    const newErrors = formError(index);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      dispatch(loginPending());

      try {
        const isAuthen = await userLoginClient({ email, password });

        if (isAuthen.status === "error") {
          return dispatch(loginFail(error.message));
        }

        const tokenid = isAuthen.access_token;
        const decoded = jwt_decode(tokenid);

        dispatch(
          storeSuccess({
            success: false,
          })
        );

        setTimeout(
          () =>
            dispatch(
              loginSuccess({
                sub: decoded.sub,
                clientid: decoded.user_has_roles.id,
                email: index.txtemail,
                type: decoded.type,
                iat: decoded.iat,
                exp: decoded.exp,
              })
            ),
          3500
        );
      } catch (error) {
        // console.log("error", error)
        dispatch(loginFail(error.response.data.message));
        dispatch(storeSuccess({ success: false }));
      }
    }
  };

  useEffect(() => {}, []);

  return (
    <div className="main-div" style={{ height: "100vh" }}>
      <div className="auth-logo-div">
        <img src={logo} alt="" />
      </div>
      <div className="auth-container">
        <div className="login-container">
          <div className="left-welcome-div">
            <div className="left-overlay">
              <div className="mid-section" style={{ textAlign: "center" }}>
                <Icon path={mdiAccountLock} size={2} color="#FFF" />
                <div className="small-text" style={{ textAlign: "center" }}>
                  Not a client? <br />
                  Click<span></span>
                  <Button
                    variant="link"
                    style={{ padding: "0 5px", color: "#FFF" }}
                    onClick={candidateLogin}
                  >
                    here
                  </Button>
                  to login as a candidate.
                </div>
              </div>
            </div>
          </div>

          <div className="right-login-form-div">
            <div className="top-login-section">
              <div className="title-form-section">
                {/* Display for responsive */}
                <div className="auth-logo-resp">
                  <img src={logo} alt="" />
                </div>
                {/* Display for responsive */}
                <div className="main-title">Client Login</div>
                <div className="small-text" style={{ color: "#54595F" }}>
                  Welcome to Jesper Research and Business Development Tool{" "}
                  <br /> by Lim-Loges & Masters. <br />
                </div>
                <div style={{ marginTop: "20px" }}>
                  {textSuccess.success ? (
                    <Alert variant="success" style={{ marginBottom: 0 }}>
                      Register successful, your confirmation email has been
                      sent.
                    </Alert>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {error && <Alert variant="danger">{error}</Alert>}
              <Form id="login-form" onSubmit={handleSubmit(btnLogin)}>
                <Form.Group className="mb-3">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    {...register("txtemail")}
                    type="email"
                    placeholder="Enter your email address"
                    isInvalid={!!errorForm.txtemail}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    {...register("txtpassword")}
                    type="password"
                    placeholder="Enter your password"
                    isInvalid={!!errorForm.txtpassword}
                  />
                </Form.Group>
              </Form>
              <div className="row-link-button">
                <Button
                  variant="link"
                  className="no-underline"
                  onClick={forgotPassword}
                >
                  Forgot Password?
                </Button>
                {/* <div> */}
                <Button form="login-form" variant="primary" type="submit">
                  Sign In
                </Button>
                {/* </div> */}
              </div>

              {/* Display for responsive */}
              <div className="row-link-button login">
                <Button
                  variant="link"
                  onClick={candidateLogin}
                  style={{ fontSize: "12px", color: "#EC782F" }}
                >
                  Login as candidate
                </Button>
              </div>
              {/* Display for responsive */}
            </div>

            {/* <div className="auth-footer right">
              Don't have an account?
              <ButtonText
                buttonStyle="btn--transparent--outline"
                buttonSize="btn--link"
                onClick={registerClient}
              >
                Register Here
              </ButtonText>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientLogin;
