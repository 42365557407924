import React, {useState, useEffect, useCallback} from 'react';
import { useSelector } from "react-redux";
import { Card, Button } from "react-bootstrap";
import Api from '../../../core/services/api.service';
import { useParams } from "react-router-dom";

import { jsPDF } from "jspdf";
import moment from 'moment';

import { CustomTable } from "../../Table/Table";
// import { ProgressReport } from "../../Report/Template/ProgressReport";

import LogoHeader from '../../../assets/report_logo_xxsmall.jpg';

import Icon from "@mdi/react";
import { mdiDownload } from "@mdi/js";

export const TabProgressClient = () => {

    const params = useParams();
    const { user } = useSelector((state) => state.login);

    const [progressList, setProgressList] = useState([]);

    const headerReport = [
        {
          Header: "Report Name",
          maxWidth: 400,
          minWidth: 100,
          width: 400,
          Cell: ({ row }) => {
            const data = row.original;
    
            return <div>{data.report_name}</div>;
          },
        },
        {
          Header: "Date Received",
          maxWidth: 400,
          minWidth: 100,
          width: 200,
          Cell: ({ row }) => {
            const data = row.original;
    
            const date = new Date(data.created_at);
            const longMonth = date.toLocaleString("en-us", { month: "long" });
    
            return (
              <div>
                {date.getDate()} {longMonth} {date.getFullYear()}
              </div>
            );
          },
        },
        {
          Header: "",
          accessor: "actions",
          maxWidth: 100,
          minWidth: 80,
          width: 80,
          Cell: ({ row }) => {
            const data = row.original;
    
            return (
              <Button variant="link" onClick={btnDownload(data)}>
                <Icon path={mdiDownload} size={0.8} />
                Dowload
              </Button>
            );
          },
        },
    ];

    const btnDownload = (data) => () => {

      const llmList = data.progress_has_admin.map((item) => {
        // const container = {};
  
        // container.name = item.user.last_name
        //   ? item.user.first_name + " " + item.user.last_name
        //   : item.user.first_name;
  
        return item.user.first_name + " " + item.user.last_name + ",";;
      });

      var cons_name_llm = llmList.join(" ");
  
      const clientList = data.progress_has_staff.map((item) => {
        const container = {};
  
        container.name = item.candidate.last_name
          ? item.candidate.first_name + " " + item.candidate.last_name
          : item.candidate.first_name;
        container.job_title =
          item.candidate.candidate_experiences.length > 0
            ? item.candidate.candidate_experiences[0].title
            : "";
        container.company =
          item.candidate.candidate_experiences.length > 0
            ? item.candidate.candidate_experiences[0].company_name
            : "";
  
        return container;
      });
  
      const candidateList = data.progress_has_candidate.map((item) => {
        const container = {};
  
        container.name = item.candidate.last_name
          ? item.candidate.first_name + " " + item.candidate.last_name
          : item.candidate.first_name;
        container.job_title =
          item.candidate.candidate_experiences.length > 0
            ? item.candidate.candidate_experiences[0].title
            : "";
        container.company =
          item.candidate.candidate_experiences.length > 0
            ? item.candidate.candidate_experiences[0].company_name
            : "";
        container.location =
          item.candidate.candidate_experiences.length > 0
            ? item.candidate.candidate_experiences[0].location
            : "";
  
        return container;
      });

      // console.log("pr", data)
      const doc = new jsPDF('p', 'mm', [297, 210]);

      var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

      var getContent = `<div style='font-size:5px;padding:5px 0px; text-align:center; width:210px;'><b>Progress Review Agenda</b></div>
      <div style='font-size:5px;text-align:left; width:210px;'>Date: ` + moment(data.created_at).format("DD MMMM YYYY") + `</div>
      <div style='font-size:5px;padding:5px 0px; text-align:left; width:210px;'>Parties Involved:</div>
      <div style='font-size:5px;padding:5px 0px;text-align:left; width:210px;'><u>Client</u></div>
      ` + clientList.map((client) => 
            `<div style='font-size:5px;padding:5px 0px;text-align:left; width:210px;'>`+ client.name + `<br>` + client.job_title + `<br>` + client.company + `</div>`
        )
        + `
        <div style='font-size:5px;padding:5px 0px;text-align:left; width:210px;'><u>Lim-Loges & Masters</u></div>
        <div style='font-size:5px;padding:5px 0px;text-align:left; width:210px;'>`+ cons_name_llm + `</div>
        <div style='font-size:5px;padding:5px 0px;text-align:left; width:210px;'><u>Key Milestones</u></div>
        <div style='font-size:5px;padding:5px 0px;text-align:left; width:210px;'>` + data.key_milestone + `</div>
        <div style='font-size:5px;padding:5px 0px;text-align:left; width:210px;'><u>Progress</u></div>
        <div style='font-size:5px;padding:5px 0px;text-align:left; width:210px;'>` + data.progress + `</div>
        <div style='font-size:5px;padding:5px 0px;text-align:left; width:210px;'><u>Market Feedback</u></div>
        <div style='font-size:5px;padding:5px 0px;text-align:left; width:210px;'>` + data.market_feedback + `</div>
        <div style='font-size:5px;padding:5px 0px;text-align:left; width:210px;'><u>Candidate profiles (information provided as additional documents)</u></div>
        ` + candidateList.map((candidate) => 
            `<div style='font-size:5px;padding:5px 0px;text-align:left; width:210px;'>`+ candidate.name + `<br>` + candidate.job_title + `<br>`+ candidate.company + `, `+  candidate.location + `</div>`
        )
        + `
        <div style='font-size:5px;padding:5px 0px;text-align:left; width:210px;'><u>Objectives of the call:</u></div>
        <div style='font-size:5px;padding:5px 0px;text-align:left; width:210px;'>
          <ul>
            <li>Align targets and objectives of the search.</li>
            <li>Refine target strategies and confirm criteria.</li>
            <li>Calibrate profiles presented and discuss emerging profiles.</li>
            <li>Discuss process and timelines.</li>
            <li>Any other comments.</li>
          </ul>
        </div>
        <div style='font-size:5px;padding:5px 0px;text-align:left; width:210px;'>We do not reveal profiles which are not qualified against your requirements.</div>
        

      `;

      doc.html(getContent, {
        margin: [35, 10, 30, 10],
        callback: function (doc) {
          const pageCount = doc.internal.getNumberOfPages();
  
          for(var i = 1; i <= pageCount; i++) {
          // Go to page i
              doc.setPage(i);
              doc.setFontSize(20);//optional
              
              // header
              doc.setFontSize(14);
              doc.text("Lim-Loges & Masters", 10, pageHeight  - 280, 'left');
  
              doc.setFontSize(10);
              doc.setTextColor(236, 120, 47);
              doc.text("transforming companies, transforming career", 10, pageHeight  - 275, 'left');
  
              doc.setFontSize(10);
              doc.setTextColor(0, 0, 0);
              doc.text("Disruption Management . Executive Search . Transition Management", 10, pageHeight  - 270, 'left');
  
              // doc.text("Image", 200, pageHeight  - 280, 'right');
              doc.addImage(LogoHeader, 'JPEG', 180, pageHeight  - 285, 20, 20);

              // footer
              doc.setFontSize(8);
              doc.text("Lim-Loges & Masters Pte Ltd (Company Reg. No. 201614475K) Address: 6 Battery Road, #30-01, Singapore 049909 Tel: +65 6725 6341", pageWidth / 2, pageHeight  - 18, {align: 'center'},)
              doc.text("www.LimLogesMasters.com China . Hong Kong . India . Malaysia . Singapore", pageWidth / 2, pageHeight  - 14, {align: 'center'},);
  
            
              doc.text(String(i), pageWidth / 2, pageHeight  - 10, {align: 'center'});
          }
          doc.save(data.report_name + ".pdf");
        },
      });

      // ProgressReport(data);
    };

    const getListProgress = useCallback(() => {
        Api.get(
          "client/" + user.clientid + "/assignment/" + params.idassign + "/progress/all"
        )
          .then((response) => {
            let data = response.data;
    
            // console.log("data getListProgress", data);
    
            if (data !== "no data") {
              
              let listProgress = data.filter((grp) =>
                  grp.status.includes("completed")
              );

              let sortListProgress = listProgress.sort(function(a, b) {
                  return new Date(a.created_at) - new Date(b.created_at);
              });

              setProgressList(sortListProgress);
            }
          })
          .catch((error) => {
            console.log("error getListProgress", error);
          });
      }, [params, user.clientid]);

    useEffect(() => {
        getListProgress();
    }, [getListProgress]);

      
    return (
        <>
            <Card>
              <Card.Body>
                <CustomTable
                  headers={headerReport}
                  items={progressList}
                  paginate={progressList.length}
                  maxRows={10}
                />
              </Card.Body>
            </Card> 
        </>
    )
}
