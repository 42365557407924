import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, Button, Alert } from "react-bootstrap";

import FileSaver from "file-saver";

import Icon from "@mdi/react";
import { mdiFile } from "@mdi/js";
import SwalAlert from "../../Swal/SwalAlert";
import Api from "../../../core/services/api.service";

export const TabEduExpClient = (props) => {
  const [showRequest, setShowRequest] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);

  const params = useParams();

  const { candidateDetails, candidateEducation, candidateExperience, candidateConsent } = useSelector(
    (state) => state.candidate
  );
  const { user } = useSelector(
    (state) => state.login
  );

  function toMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", {
      month: "long",
    });
  }

  const btnDownload = (file) => (e) => {
    FileSaver.saveAs(file);
  };

  const customSwal = SwalAlert();

  const btnRequest = (e) => {

    setShowRequest(false);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    const nameCand =  candidateDetails.last_name ? candidateDetails.first_name + " " + candidateDetails.last_name : candidateDetails.first_name;

    customSwal
      .fire({
        title: "Request Consent from " + nameCand + "?",
        text: "You will need to request consent from the candidate before able to view their personal data.",
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Confirm",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.post("candidate/" + candidateDetails.id + "/requestConsent", {
            client_id: user.clientid,
          })
          .then((response) => {
            swalWithBootstrapButtons
            .fire(
              "Request has been sent!",
              "Please wait for " + nameCand + " to grant you permission.",
              "success"
            )
            .then(function () {
              setShowConfirm(true);
            });
          })
          .catch((error) => {
            console.log("error candidate/requestConsent", error);
          });
         
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          setShowRequest(true);
        }
      });
  };

  useEffect(() => {
  //  props.consent.status === 'denied' ? setShowConfirm(true) : setShowConfirm(false);
  //  props.consent.status === 'denied' ? setShowRequest(false) : setShowConfirm(true);
  }, []);

  return (
    <div>
      {/* Alert in blur area */}
      <div
        style={{
          // background: "orange",
          // opacity: 0.7,
          position: "absolute",
          zIndex: 1,
        }}
      >
        <div
          style={{
            // background: "yellow",
            width: "calc(100vw - 360px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "50px",
          }}
        > 
          <Alert show={props.consent === undefined ? showRequest : props.consent.status === 'denied' ? false : props.consent.status === 'granted' ? false : showRequest}>
            <p>
              Please make a request before you can view candidate’s details.
            </p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button onClick={btnRequest} variant="primary">
                Request Consent
              </Button>
            </div>
          </Alert>

          <Alert show={props.consent === undefined ? showConfirm : props.consent.status === 'denied' ? true : props.consent.status === 'granted' ? false : showConfirm}>
            <p>
              Your request has been sent.
              <br />
              Please wait for {candidateDetails.last_name ? candidateDetails.first_name + " " + candidateDetails.last_name : candidateDetails.first_name} to grant you permission.
            </p>
          </Alert>
        </div>
      </div>

      {/* Blur area */}
      <div style={{ filter: props.consent === undefined ? "blur(4px)" : props.consent.status === 'denied' ? "blur(4px)" : props.consent.status === 'granted' ? "blur(0px)" : "blur(4px)" , borderRadius: "10px" }}>
        {/* Educations */}
        <Card style={{ marginBottom: "20px" }}>
          <Card.Body>
            <div className="title-text">Educations</div>

            {candidateEducation.length > 0
              ? candidateEducation.map((education, index) => (
                  <div key={index} className="interview-section mb-3">
                    <div className="card-education__row-flex5">
                      <div className="sub-text">{education.name}</div>
                      <div className="sub-text">{education.qualification}</div>
                      <div className="sub-text" style={{ fontWeight: 400 }}>
                        {education.start_year} - {education.end_year}
                      </div>
                    </div>
                    <div>
                      {education.certificate_attachment ? (
                        <Button
                          onClick={btnDownload(
                            education.certificate_attachment
                          )}
                          variant="link"
                          className="no-underline"
                        >
                          <Icon path={mdiFile} />
                          Show Certificate
                        </Button>
                      ) : (
                        <div className="mini-text">No Certificate</div>
                      )}
                    </div>
                  </div>
                ))
              : "-"}
          </Card.Body>
        </Card>

        {/* Experiences */}
        <Card>
          <Card.Body>
            <div className="title-text">Experiences</div>

            {candidateExperience.length > 0
              ? candidateExperience.map((experience, index) => (
                  <div key={index}>
                    <div className="cdt-row">
                      <div className="left-section-cdt">
                        {experience.current_working ? (
                          <div className="sub-text" style={{ fontWeight: 400 }}>
                            {toMonthName(experience.present_month)}{" "}
                            {experience.present_year} - Current
                          </div>
                        ) : (
                          <div className="sub-text" style={{ fontWeight: 400 }}>
                            {toMonthName(experience.start_date_month)}{" "}
                            {experience.start_date_year} -{" "}
                            {toMonthName(experience.end_date_month)}{" "}
                            {experience.end_date_year}
                          </div>
                        )}
                      </div>
                      <div className="right-section-cdt">
                        <div className="big-text" style={{ color: "black" }}>
                          {experience.title}
                        </div>
                        <div className="sub-text">
                          {experience.company_name}
                        </div>
                      </div>
                    </div>
                    {experience.reason_leaving !== "" ? (
                      <div className="cdt-column">
                        <div className="text">Reason for leaving</div>
                        <div
                          className="mini-text"
                          dangerouslySetInnerHTML={{
                            __html: experience.reason_leaving,
                          }}
                        ></div>
                      </div>
                    ) : (
                      <></>
                    )}

                    <hr />
                  </div>
                ))
              : "-"}
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};
