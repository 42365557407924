import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Col, Row, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Apiform from "../../../core/services/apiform.service";
import Api from "../../../core/services/api.service";

import SwalAlert from "../../../components/Swal/SwalAlert";
import moment from "moment";

import { mdiArrowLeft, mdiCalendar, mdiAccountGroup } from "@mdi/js";
import Icon from "@mdi/react";

import BreadCrumb from "../../../components/Breadcrumb/BreadCrumb";
import RichTextEditor from "../../../components/RichTextArea/RichTextEditor";
import Searchable from "../../../components/DropDown/SearchableSelect";
import Modal from "../../../components/Popup/ModalPopup";

import {
  getClientDetails,
  getClientStaff,
  getClientMeeting,
} from "../../../store/actions/clientSlice";
import { getListStaff } from "../../../store/actions/staffSlice";
import { storeTitle } from "../../../store/actions/headerTitleSlice";

const CreateMeeting = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  // const formRef = useRef();

  const { clientDetails, clientListStaff } = useSelector(
    (state) => state.client
  );
  const { dataStaff } = useSelector((state) => state.staff);
  const { dataReminder, dataDuration } = useSelector((state) => state.option);
  const { getTitle } = useSelector((state) => state.headerTitle);

  const [errorForm, setErrorForm] = useState({});

  const customSwal = SwalAlert();

  const selectedCrumb = (crumb) => {
    // console.log("crum", crumb)
    if(crumb === "Clients"){
      navigate("/clients/listActiveC");
    }else if(crumb === "Potential Clients"){
      navigate("/clients/listPotentialC");
    }else if(crumb === "Companies"){
      navigate("/clients/listAllClients");
    }else if(crumb === clientDetails.name){
      clientDetails.is_potential === false && (clientDetails.status !== null && clientDetails.status !== "closed") ? 
        navigate("/clients/viewActiveC/" + params.id) :
        clientDetails.is_potential === true && (clientDetails.status !== null && clientDetails.status !== "closed") ?
          navigate("/clients/view-potential-client/" + params.id) : 
          navigate("/clients/viewAllClients/" + params.id)
    }
    // navigate(-1);
  };

  const [selStaffLLM, setSelStaffLLM] = useState([]);
  const [selStaffClient, setSelStaffClient] = useState([]);

  const [showReminder, setShowReminder] = useState(false);
  const [errorUpload, setErrorUpload] = useState({});
  const [remindMeet, setRemindMeet] = useState("");
  const [sentDateTime, setSentDateTime] = useState("");

  const handleStaffLLM = (e) => {
    setSelStaffLLM(Array.isArray(e) ? e.map((x) => x.id.toString()) : []);
  };

  const handleStaffClient = (e) => {
    setSelStaffClient(Array.isArray(e) ? e.map((x) => x.id.toString()) : []);
  };

  // Form Error
  // Checking Error Form
  const formError = (object) => {
    const newErrors = {};

    if (!object.ddlMeetingType || object.ddlMeetingType === "")
      newErrors.ddlMeetingType = "Fill in the blank!";

    if (!object.txtname || object.txtname === "")
      newErrors.txtname = "Fill in the blank!";

    if (!object.txtdatemeet || object.txtdatemeet === "")
      newErrors.txtdatemeet = "Fill in the blank!";

    if (!object.txttimemeet || object.txttimemeet === "")
      newErrors.txttimemeet = "Fill in the blank!";

    if (!object.ddlClientStaff || object.ddlClientStaff === "")
      newErrors.ddlClientStaff = "Fill in the blank!";

    if (!object.ddlLLMStaff || object.ddlLLMStaff === "")
      newErrors.ddlLLMStaff = "Fill in the blank!";

    return newErrors;
  };

  // Button Event

  const btnReminder = (e) => {
    setShowReminder(true);

    if (!remindMeet || remindMeet === "") {
      setSentDateTime("");
    } else {
      var sentDateTimeC = moment()
        .add(remindMeet, "minutes")
        .format("DD MMMM YYYY, hh:mm A");
      setSentDateTime("Notification will be sent to you at " + sentDateTimeC);
    }

    setErrorUpload({});
  };

  const btnCloseReminder = () => {
    setShowReminder(false);
  };

  const handleChangeReminder = (e) => {
    var sentDateTimeC = moment()
      .add(e.target.value, "minutes")
      .format("DD MMMM YYYY, hh:mm A");
    setSentDateTime("Notification will be sent to you at " + sentDateTimeC);
  };

  const btnSaveReminder = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtRemind = Object.fromEntries(data.entries());

    const newErrors = formErrorRemind(dtRemind);

    if (Object.keys(newErrors).length > 0) {
      setErrorUpload(newErrors);
    } else {
      setRemindMeet(dtRemind.ddlRemindOption);
      setShowReminder(false);
    }
  };

  const btnSaveMeeting = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const dataMeeting = Object.fromEntries(data.entries());

    // const meetingName = clientListMeeting.length + 1;

    const newErrors = formError(dataMeeting);

    // console.log("aaaa", dataMeeting)

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);

      for (var name in newErrors) {
        // console.log(name);
        const input = document.querySelector(`input[name=${name}]`);
        const inp_select = document.querySelector(`select[name=${name}]`);

        if (input !== null) {
          input.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
          });
        } else if (inp_select !== null) {
          inp_select.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
          });
        }
      }
    } else {
      customSwal
        .fire({
          title: "Do you want to save new meeting?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();

            formData.append("file", dataMeeting.txtattachment);
            formData.append("name", dataMeeting.txtname);
            formData.append("date_meeting", dataMeeting.txtdatemeet);
            formData.append("time_meeting", dataMeeting.txttimemeet);
            formData.append("location", dataMeeting.txtlocation);
            formData.append("duration", dataMeeting.ddlDuration);

            if (selStaffLLM.length > 0) {
              selStaffLLM.forEach((item, index) => {
                formData.append(`staff_involve[${index}]`, item);
              });
            } else {
              formData.append(`staff_involve[0]`, []);
            }

            if (selStaffClient.length > 0) {
              selStaffClient.forEach((item, index) => {
                formData.append(`client_involve[${index}]`, item);
              });
            } else {
              formData.append(`client_involve[0]`, []);
            }

            formData.append("objective_meeting", dataMeeting.txtObjective);
            formData.append(
              "discusion_point_elaboration",
              dataMeeting.txtDiscussion
            );
            formData.append("conclusion_next_step", dataMeeting.txtConclusion);
            formData.append("suggession", dataMeeting.txtSuggestion);
            formData.append("status", "created");
            formData.append("reminder", remindMeet);

            if(dataMeeting.ddlMeetingType !== "BD" && dataMeeting.ddlMeetingType !== "GM"){
              formData.append("assignment_id", dataMeeting.ddlMeetingType);
            }

            if(dataMeeting.ddlMeetingType === "BD" && dataMeeting.ddlMeetingType === "GM"){
              formData.append("type", dataMeeting.ddlMeetingType);
            }
            
            // formData.append("type", dataMeeting.ddlMeetingType === "BD" || dataMeeting.ddlMeetingType === "GM" ? dataMeeting.ddlMeetingType : "" );

            Apiform.post("client/" + params.id + "/meeting", formData)
              .then((response) => {


                if (clientDetails.is_potential === false && (clientDetails.status !== null && clientDetails.status !== "closed")) {
                  navigate("/clients/viewActiveC/" + params.id);
                } else if (clientDetails.is_potential === true && (clientDetails.status !== null && clientDetails.status !== "closed")){
                  navigate("/clients/view-potential-client/" + params.id);
                } else {
                  navigate("/clients/viewAllClients/" + params.id);
                }
                
                customSwal.fire({
                  title: "Meeting Saved Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("error btnSaveMeeting", error);
              });
          }
        });
    }
  };

  const formErrorRemind = (object) => {
    const newErrors = {};

    if (!object.ddlRemindOption || object.ddlRemindOption === "")
      newErrors.ddlRemindOption = "Fill in the blank!";

    return newErrors;
  };

  const [assignList, setAssignList] = useState([]);

  const getListAssignment = useCallback(() => {
    Api.get("client/" + params.id + "/assignment")
      .then((response) => {
        let data = response.data;

        // console.log("data assignment", data);

        if (data !== "no data") {
          setAssignList(data);
        }
      })
      .catch((error) => {
        console.log("error getListAssignment", error);
      });
  }, [params]);

  useEffect(() => {
    getListAssignment();
    dispatch(getListStaff());
    dispatch(getClientStaff(params.id));

    dispatch(getClientMeeting(params.id));
    dispatch(getClientDetails(params.id));

    if (clientDetails.is_potential === false && (clientDetails.status !== null && clientDetails.status !== "closed")) {
      dispatch(storeTitle({ title: "Clients", icon: mdiAccountGroup }));
    } else if (clientDetails.is_potential === true && (clientDetails.status !== null && clientDetails.status !== "closed")){
      dispatch(
        storeTitle({ title: "Potential Clients", icon: mdiAccountGroup })
      );
    } else {
      dispatch(
        storeTitle({ title: "Companies", icon: mdiAccountGroup })
      );
    }
  }, [clientDetails.name, dispatch, params.id, params.page, clientDetails.is_potential, clientDetails.status, getListAssignment]);

  return (
    <div className="main-div create-meet">
      <div className="top-title-div both-side">
        <BreadCrumb crumbs={[getTitle.title, clientDetails.name, "Add Meeting"]} selected={selectedCrumb}></BreadCrumb>
        <Button variant="secondary" onClick={() => navigate(-1)}>
          <Icon path={mdiArrowLeft} color="#212529" />
          Back
        </Button>
      </div>

      <div className="bg-white-container">
        <div className="right-div reminder">
          <Button variant="link" onClick={btnReminder}>
            <Icon path={mdiCalendar} color="#467FD0" />
            Set Reminder
          </Button>
        </div>

        <Form id="create-meeting-form" onSubmit={btnSaveMeeting}>
          {/* 1 */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={2}>
              Meeting Type <label className="validation-star">*</label>
            </Form.Label>
            <Col>
              <Form.Select 
                name="ddlMeetingType"
                isInvalid={!!errorForm.ddlMeetingType}
              >
                <option value="">Select</option>
                <option value="GM">General Meeting</option>
                <option value="BD">BD Meeting</option>
                {assignList.map((assign, index) => (
                  <option key={index} value={assign.id}>
                    {assign.title} Meeting
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errorForm.ddlMeetingType}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          {/* 2 */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={2}>
              Meeting Name <label className="validation-star">*</label>
            </Form.Label>
            <Col>
              <Form.Control
                name="txtname"
                type="text"
                isInvalid={!!errorForm.txtname}
                // ref={formRef}
              />
              <Form.Control.Feedback type="invalid">
                {errorForm.txtname}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          {/* 3 */}
          <Row className="create-meet">
            <Col xs={6}>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg={4}>
                  Date of call / meeting{" "}
                  <label className="validation-star">*</label>
                </Form.Label>
                <Col>
                  <Form.Control
                    name="txtdatemeet"
                    type="date"
                    // min={new Date().toISOString().split("T")[0]}
                    isInvalid={!!errorForm.txtdatemeet}
                    // ref={formRef}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorForm.txtdatemeet}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg={4}>
                  Time <label className="validation-star">*</label>
                </Form.Label>
                <Col>
                  <Form.Control
                    name="txttimemeet"
                    type="time"
                    isInvalid={!!errorForm.txttimemeet}
                    // ref={formRef}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorForm.txttimemeet}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Col>
          </Row>

          {/* 4 */}
          <Row className="create-meet">
            <Col xs={6}>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg={4}>
                  Location
                </Form.Label>
                <Col>
                  <Form.Control name="txtlocation" type="text" />
                </Col>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg={4}>
                  Duration
                </Form.Label>
                <Col>
                  <Form.Select name="ddlDuration">
                    <option value="">Select</option>
                    {dataDuration.map((dur, index) => (
                      <option key={index} value={dur.code}>
                        {dur.value}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>
          </Row>

          {/* 5 */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={2}>
              Parties involved <label className="validation-star">*</label>
            </Form.Label>
            <Col>
              <div className="text mb-3">Client:</div>
              <Searchable
                name="ddlClientStaff"
                isMulti={true}
                options={clientListStaff}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) =>
                  option.first_name + " " + option.last_name
                }
                onChange={handleStaffClient}
                isInvalid={!!errorForm.ddlClientStaff}
                errorText="Fill in the blank!"
                // ref={formRef}
              ></Searchable>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={2}></Form.Label>
            <Col>
              <div className="text mb-3">Lim-Loges & Masters:</div>
              <Searchable
                name="ddlLLMStaff"
                isMulti={true}
                options={dataStaff}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) =>
                  option.first_name + " " + option.last_name
                }
                onChange={handleStaffLLM}
                isInvalid={!!errorForm.ddlLLMStaff}
                errorText="Fill in the blank!"
                // ref={formRef}
              ></Searchable>
            </Col>
          </Form.Group>

          {/* 6 */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={2}>
              Objective of meeting
            </Form.Label>
            <Col>
              <RichTextEditor name="txtObjective"></RichTextEditor>
            </Col>
          </Form.Group>

          {/* 7 */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={2}>
              Discussion points and elaboration
            </Form.Label>
            <Col>
              <RichTextEditor name="txtDiscussion"></RichTextEditor>
            </Col>
          </Form.Group>

          {/* 8 */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={2}>
              Conclusion/next steps
            </Form.Label>
            <Col>
              <RichTextEditor name="txtConclusion"></RichTextEditor>
            </Col>
          </Form.Group>

          {/* 9 */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={2}>
              Suggestion {"(if any)"}
            </Form.Label>
            <Col>
              <RichTextEditor name="txtSuggestion"></RichTextEditor>
            </Col>
          </Form.Group>

          {/* 10 */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={2}>
              Attachment
            </Form.Label>
            <Col>
              <Form.Control name="txtattachment" type="file" />
            </Col>
          </Form.Group>

          <div className="row-btn-center">
            {/* <Button variant="secondary">Discard</Button> */}
            <Button variant="primary" type="submit">
              Save Meeting
            </Button>
          </div>
        </Form>
      </div>

      {/* Modal set reminder */}
      <Modal
        show={showReminder}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseReminder}
        title="Set Reminder"
        content={
          <>
            <Form id="remind-form" onSubmit={btnSaveReminder}>
              <Row>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="col-3">Reminder Options</Form.Label>
                  <Col sm="9">
                    <Form.Select
                      name="ddlRemindOption"
                      isInvalid={!!errorUpload.ddlRemindOption}
                      defaultValue={remindMeet}
                      onChange={handleChangeReminder}
                    >
                      <option value="">Select</option>
                      {dataReminder.map((remind, index) => (
                        <option key={index} value={remind.value}>
                          {remind.label}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errorUpload.ddlRemindOption}
                    </Form.Control.Feedback>
                    <Form.Text muted>{sentDateTime}</Form.Text>
                  </Col>
                </Form.Group>
              </Row>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseReminder}>
              Cancel
            </Button>
            <Button type="submit" variant="primary" form="remind-form">
              Set Reminder
            </Button>
          </>
        }
      ></Modal>
    </div>
  );
};

export default CreateMeeting;
