import {createSlice} from '@reduxjs/toolkit';
import Api from '../../core/services/api.service';


const initialState = {
    formRegCandidate: {
        salutation: null,
        first_name: null,
        last_name: null,
        mobile_no_code: null,
        mobile_no: null,
        languages: [],
        email: null,
        password: "",
        validPassword: false,
        captcha: ""
    },
    textSuccCand: {
        success: false
    },
    verifyUserLogin: {}
}

const registerCandidateSlice = createSlice({
    name: 'registerCandidate',
    initialState,
    reducers:{
        storeRegCand: (state, action) => {
            state.formRegCandidate = { ...state.formRegCandidate, ...action.payload };
        },
        storeSuccCand: (state, action) => {
            state.textSuccCand = { ...state.textSuccCand, ...action.payload };
        },
        storeVerify: (state, action) => {
            state.verifyUserLogin = { ...state.verifyUserLogin, ...action.payload };
        },
    },
})

export const createCandidateRegister = (data) => async (dispatch) => {
    try {
        // console.log(data);
        await Api.post("candidate/register", data);

       
    } catch (err) {
        throw new Error(err);
    }
};

export const verifyNewRegister = (id) => async (dispatch) => {
    try {
        // console.log(data);
        const response = await Api.get("verifyEmail/" + id);

        dispatch(storeVerify(response.data));
       
    } catch (err) {
        throw new Error(err);
    }
};

const {reducer, actions} = registerCandidateSlice;

export const { storeRegCand, storeSuccCand, storeVerify } = actions;

export default reducer;