import {createSlice} from '@reduxjs/toolkit';
import Api from '../../core/services/api.service';

export const consentSlice = createSlice({
    name: "consent",
    initialState: {
        consentList: [],
        consentAllList: [],
        consentFilter: {
            txtSearchComp: "",
        },
    },
    reducers: {
        setConsentList: (state, action) => {
            state.consentList = action.payload;
        }, 
        setConsentAllList: (state, action) => {
            state.consentAllList = action.payload;
        },    
        setConsentStoreFilter: (state, action) => {
            state.consentFilter = { ...state.consentFilter, ...action.payload };
        },
  
    },
});

export const getListingConsentCand = (id, conComp) => async (dispatch, rejectWithValue) => {
    try {
        const response = await Api.post("candidate/" + id + "/listConsent");

        let newList = [];

        if (response.data !== "no data") {

            if (conComp !== ""){
                newList = response.data.filter((company) => {
                  return company.name.toLowerCase().includes(conComp.toLowerCase());
                });

                dispatch(setConsentAllList(newList));
              }else{
                dispatch(setConsentList(response.data));
                dispatch(setConsentAllList(response.data));
              }
        }

        
        
    } catch (err) {
        throw rejectWithValue(err.message);
    }
}

export const { setConsentList, setConsentAllList, setConsentStoreFilter } = consentSlice.actions;
export default consentSlice.reducer;