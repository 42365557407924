import React, { useState, useEffect, useCallback } from "react";
import ReactTooltip from "react-tooltip";
import moment from "moment";

function ButtonStage({ items }) {
  const [listStages, setListStages] = useState([]);

  const totalBox = 7;
  const numberBox = totalBox - items.length - 1;

  const getListStages = useCallback(() => {
    setListStages(items);
  }, [items]);

  useEffect(() => {
    getListStages();
  }, [getListStages]);

  return (
    <>
      <div className="btn-number-container">
        {listStages.map((i, index) => {
          const date = new Date(i.interview_date);
          const longMonth = date.toLocaleString("en-us", { month: "long" });

          return (
            <div className="btn-number-each-div" key={index}>
              <div className="btn-number-each">
                <div>
                  <div
                    data-tip={ i.interview_date ?
                      "Interview Date: " +
                      ("0" + date.getDate()).slice(-2) +
                      " " +
                      longMonth +
                      " " +
                      date.getFullYear() +
                      "<br> Interview Time: " +
                      moment(i.interview_time, ["HH:mm"]).format("hh:mm A") : i.status === 'decline' ? "Decline" : "Waiting Response"
                    }
                  >
                    <button className="btn--inv--stage--solid">
                      {index + 1}
                    </button>
                    <ReactTooltip
                      place="bottom"
                      type="dark"
                      effect="solid"
                      html={true}
                      backgroundColor="#E5E5E5"
                      textColor="#212529"
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        {new Array(numberBox).fill("", 0, numberBox).map((p, index3) => (
          <div className="btn-number-each-div" key={index3}>
            <div className="btn-number-each-text" style={{ cursor: "default" }}>
              <button style={{ cursor: "default" }}>{index3}</button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default ButtonStage;
