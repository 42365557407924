import { useEffect, useState } from 'react';
import jwt_decode from "jwt-decode";
import Api from '../../../core/services/api.service';
import Cookies from 'js-cookie';

export const AuthAbilities = () => {

    const [abilities, setAbilities] = useState([]);
  
    useEffect(()=>{
        const tokenid = Cookies.get("id_token");
        if (tokenid === null) {
        // setTimeout(() => setLoading, 3500);
        // setResetUrl("/");
        } else {
            const decoded = jwt_decode(tokenid);

            let role_id =  decoded.user_has_roles[0].role_id;

            Api.get("role/" + role_id + "/withPermission")
            .then((response) => {
                let dataRole = response.data;

                setAbilities(dataRole);

            // console.log("withPermission", data);
            
                
            })
            .catch((error) => {
                console.log("error withPermission", error);
            });
        
        }
    }, [])

    return abilities
}

export default AuthAbilities;