import {createSlice} from '@reduxjs/toolkit';
import Api from '../../core/services/api.service';
import moment from 'moment';

export const interviewSlice = createSlice({
    name: "interview",
    initialState: {
        dataInterview: [],
        interFeedback: [],
        interReqList: [],
        interReqListPast: []
    },
    reducers: {
        // addTodo: (state, action) => {
        //     state.data.push(action.payload);
        // },
        getTodo: (state, action) => {
          state.dataInterview = action.payload;
        },
        getInterReqList: (state, action) => {
          state.interReqList = action.payload;
        },
        getInterReqListPast: (state, action) => {
          state.interReqListPast = action.payload;
        },
        getInterListFeedback: (state, action) => {
          state.interFeedback = action.payload;
        }
    }
});

export const getListAllInterview = (id, idassign) => async (dispatch) => {
    try {
      const response = await Api.get("candidate/" + id + "/assignment/" + idassign + "/interview");
      
      dispatch(getTodo(response.data));
    } catch (err) {
      throw new Error(err);
    }
};

export const getListInterviewRequest = () => async (dispatch, rejectWithValue) => {
  try {

    const date = new Date();
    const dateFormat = moment(date).format("YYYY-MM-DD");

    const response = await Api.get("interview");

    const upComingInterview = response.data.filter(interview => interview.interview_date > dateFormat || interview.interview_date === null);

    let sortupComingInterview = upComingInterview.sort(function(a, b) {
      return new Date(b.interview_date) - new Date(a.interview_date);
    });
    
    dispatch(getInterReqList(sortupComingInterview));

    const pastInterview = response.data.filter(interview => interview.interview_date < dateFormat);

    let sortpastInterview = pastInterview.sort(function(a, b) {
      return new Date(b.interview_date) - new Date(a.interview_date);
    });
    
    dispatch(getInterReqListPast(sortpastInterview));
  } catch (err) {
    throw rejectWithValue(err.message);
  }
};
  
export const getListFeedback = (id, idInter) => async (dispatch, rejectWithValue) => {
  try {
    const response = await Api.get("client-staff/" + id + "/interview-feedback/" + idInter);
    
    dispatch(getInterListFeedback(response.data));

  } catch (err) {
    throw rejectWithValue(err.message);
  }
};
  
  export const { addTodo, getTodo, getInterReqList, getInterReqListPast, getInterListFeedback } = interviewSlice.actions;
  export const showTodo = (state) => state.todo.data;
  export default interviewSlice.reducer;