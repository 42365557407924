import React, {useEffect, useState} from 'react';
import { Card, Form, Col, Row, Button, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Api from "../../../core/services/api.service";

import SwalAlert from "../../../components/Swal/SwalAlert";
import moment from "moment";

import Icon from "@mdi/react";
import { mdiArrowLeft, mdiAccountSearch } from "@mdi/js";

import BreadCrumb from "../../../components/Breadcrumb/BreadCrumb";
import RichTextEditor from "../../../components/RichTextArea/RichTextEditor";
import Searchable from "../../../components/DropDown/SearchableSelect";
import Modal from "../../../components/Popup/ModalPopup";

import { storeTitle } from "../../../store/actions/headerTitleSlice";
import { getProposalDetailApproval } from "../../../store/actions/proposalSlice";
import { getClientDetails } from "../../../store/actions/clientSlice";
import { getListStaff } from "../../../store/actions/staffSlice";

function ViewProposalApproval() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();

    const customSwal = SwalAlert();

    const { proposal_detail_approval, proposal_detail_created_approval, proposal_detail_contact_approval } = useSelector((state) => state.proposal);
    const { clientDetails } = useSelector((state) => state.client);
    const { dataStaff } = useSelector((state) => state.staff);

    const [showReject, setShowReject] = useState(false);
    const [errorNote, setErrorNote] = useState({});

    const selectedCrumb = (crumb) => {
        if(crumb === "Proposal"){
          navigate("/clients/listProposal");
        }
    };

    const btnOpenReject = () => {
        setShowReject(true);
        setErrorNote({});
    };

    const btnCloseReject = () => {
        setShowReject(false);
    };

    // Checking Error Form
    const formError = (object) => {
        const newErrors = {};

        if (!object.txtreject || object.txtreject === "")
        newErrors.txtreject = "Fill in the blank!";

        return newErrors;
    };

    const btnUpdateReject = (e) => {
        e.preventDefault();

        const data = new FormData(e.target);
        const dtReject = Object.fromEntries(data.entries());

        const newErrors = formError(dtReject);

        if (Object.keys(newErrors).length > 0){
            setErrorNote(newErrors);
        }else{
            setErrorNote({});
            customSwal.fire({
                title: "Do you want to reject this proposal?",
                icon: "warning",
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonText: "Yes",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        Api.post("proposal/updateStatus/" + params.idpro,
                            {
                              status: "rejected",
                              reason_rejected: dtReject.txtreject
                            }
                        ).then((response) => {
                            navigate("/clients/listProposal");
              
                            customSwal.fire({
                                title: "Proposal return back to consultant!",
                                icon: "success",
                                confirmButtonText: "Okay",
                            });
                        })
                        .catch((error) => {
                            console.log("error update reject", error);
                        });
                    }
                });
        }
    };

    const btnApprove = () => {
        customSwal.fire({
            title: "Do you want to approve this proposal?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    Api.post("proposal/updateStatus/" + params.idpro,
                        {
                          status: "approved",
                          reason_rejected: ""
                        }
                    ).then((response) => {
                        navigate("/clients/listProposal");
          
                        customSwal.fire({
                            title: "Proposal Approved",
                            icon: "success",
                            confirmButtonText: "Okay",
                        });
                    })
                    .catch((error) => {
                        console.log("error update reject", error);
                    });
                }
            });
    };

    useEffect(() => {
        dispatch(getProposalDetailApproval(params.id, params.idpro));
        dispatch(getClientDetails(params.id));

        dispatch(storeTitle({ title: "Proposal", icon: mdiAccountSearch }));
        dispatch(getListStaff());
    },[dispatch]);

    return (
        <>
            {Object.keys(proposal_detail_approval).length === 0 ? 
            <div id="main-overlay" className="main-overlay">
                <Spinner animation="border" variant="light" />
            </div>:""}

            <div className="main-div  create-props">
                <div className="top-title-div both-side">
                    <BreadCrumb
                        crumbs={["Proposal", clientDetails.name]}
                        selected={selectedCrumb}
                    ></BreadCrumb>
                    <Button variant="secondary" onClick={() => navigate(-1)}>
                        <Icon path={mdiArrowLeft} />
                        Back
                    </Button>
                </div>

                {/* Form Edit Proposal */}
                <Card>
                    <Card.Body>
                        {/* 1 */}
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column lg={2}>
                                File Name
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    name="txtfilename"
                                    type="text"
                                    value={proposal_detail_approval.file_name}
                                    readOnly
                                />
                            </Col>
                        </Form.Group>
                         {/* 2 */}
                        <Row>
                            <Col xs={6}>
                                <Form.Group as={Row} className="mb-3">
                                <Form.Label column lg={4}>
                                    Creation Date
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                    placeholder={moment(proposal_detail_approval.created_at).format("dddd, DD MMMM YYYY")}
                                    readOnly
                                    />
                                </Col>
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group as={Row} className="mb-3">
                                <Form.Label column lg={4}>
                                    Created By
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                    placeholder={
                                        proposal_detail_created_approval.first_name +
                                        " " +
                                        proposal_detail_created_approval.last_name
                                    }
                                    style={{ textTransform: "capitalize" }}
                                    readOnly
                                    />
                                </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* 3 */}
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column lg={2}>
                                Job Title
                            </Form.Label>
                            <Col>
                                <Form.Control
                                name="txtjobtitle"
                                type="text"
                                value={proposal_detail_approval.job_title}
                                />
                            </Col>
                        </Form.Group>
                        {/* 4 */}
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column lg={2}>
                                Company Overview 
                                {/* <label className="validation-star">*</label> */}
                            </Form.Label>
                            <Col>
                                <Form.Control
                                name="txtoverview"
                                as="textarea"
                                rows={3}
                                value={proposal_detail_approval.company_overview}
                                />
                            </Col>
                        </Form.Group>
                        {/* 5 */}
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column lg={2}>
                                Requirement 
                            </Form.Label>
                            <Col>
                                <RichTextEditor
                                name="txtrequirement"
                                htmlContent={proposal_detail_approval.requirement}
                                readonly={true}
                                />
                            </Col>
                        </Form.Group>
                        {/* 6 */}
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column lg={2}>
                                Main Contact Details
                            </Form.Label>
                            <Col>
                                <Searchable
                                name="ddlcontact"
                                isMulti={true}
                                options={dataStaff}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) =>
                                    option.first_name + " " + option.last_name
                                }
                                value={proposal_detail_contact_approval}
                                isReadOnly={true}
                                ></Searchable>
                            </Col>
                        </Form.Group>
                        {/* 7 */}
                        <Row className="create-props">
                            <Col xs={6}>
                                <Form.Group as={Row} className="mb-3">
                                <Form.Label column lg={4}>
                                    Professional Domestic Fees(%)
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                    name="txtdomestic"
                                    type="number"
                                    value={proposal_detail_approval.domestic_fee}
                                    readOnly
                                    />
                                </Col>
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group as={Row} className="mb-3">
                                <Form.Label column lg={4}>
                                    Professional Regional Fees(%)
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                    name="txtregional"
                                    type="number"
                                    value={proposal_detail_approval.regional_fee}
                                    readOnly
                                    />
                                </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* 8 */}
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column lg={2}>
                                Expected Compensation Range
                            </Form.Label>
                            <Col>
                                <RichTextEditor
                                name="txtcompensation"
                                htmlContent={proposal_detail_approval.expected_compensation}
                                readonly={true}
                                />
                            </Col>
                        </Form.Group>
                        {/* 9 */}
                        <Row className="create-props">
                            <Col xs={6}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column lg={4}>
                                        Payment Terms
                                    </Form.Label>
                                    <Col>
                                        <Form.Control
                                            name="txtpayterms"
                                            type="text"
                                            value={proposal_detail_approval.payment_term}
                                            readOnly
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group as={Row} className="mb-3">
                                <Form.Label column lg={4}>
                                    Indirect Expenses (%)
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                    name="txtindirect"
                                    type="number"
                                    value={proposal_detail_approval.indirect_expenses}
                                    readOnly
                                    />
                                </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                         {/* 10 */}
                        <Row className="create-props">
                            <Col xs={6}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column lg={4}>
                                        Compound Interest (%)
                                    </Form.Label>
                                    <Col>
                                        <Form.Control
                                        name="txtinterest"
                                        type="number"
                                        value={proposal_detail_approval.compound_interest}
                                        readOnly
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group as={Row} className="mb-3">
                                <Form.Label column lg={4}>
                                    Subsequence Candidate Fees (%)
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                    name="txtsubsequence"
                                    type="number"
                                    value={proposal_detail_approval.subseq_cand_fee}
                                    />
                                </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* 11 */}
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column lg={2}>
                                Off-limits Restrictions
                            </Form.Label>
                            <Col>
                                <RichTextEditor
                                    name="txtrestrictions"
                                    htmlContent={proposal_detail_approval.off_limit_restriction}
                                    readonly={true}
                                />
                            </Col>
                        </Form.Group>
                         {/* 12 */}
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column lg={2}>
                                Guarantee Period 
                            </Form.Label>
                            <Col>
                                <Form.Control
                                name="txtperiod"
                                type="text"
                                value={proposal_detail_approval.guaranty_period}
                                readOnly
                                />
                            </Col>
                        </Form.Group>
                        {/* 13 */}
                        <Form.Group as={Row} className="mb-3" controlId="formFileName">
                            <Form.Label column lg={2}>
                                Guarantee Conditions
                            </Form.Label>
                            <Col>
                                <RichTextEditor
                                name="txtguarantee"
                                htmlContent={proposal_detail_approval.guaranty_condition}
                                readonly={true}
                                />
                            </Col>
                        </Form.Group>

                        <div className="row-btn-center">
                            <Button onClick={() => navigate(-1)} variant="secondary">
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={btnOpenReject}>
                                Reject Proposal
                            </Button>
                            <Button variant="success" onClick={btnApprove}>
                                Approve Proposal
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            </div>

            {/* Modal Decline Proposal */}
            <Modal
                show={showReject}
                size="md"
                backdrop="static"
                keyboard={false}
                onHide={btnCloseReject}
                title="Reason Reject Proposal"
                content={
                <>
                    <Form id="reject-form" onSubmit={btnUpdateReject}>
                        <Form.Control
                            name="txtreject"
                            as="textarea"
                            placeholder="Type your message here"
                            rows={5}
                            isInvalid={!!errorNote.txtreject}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errorNote.txtreject}
                        </Form.Control.Feedback>
                    </Form>
                </>
                }
                button={
                <>
                    <Button variant="secondary" onClick={btnCloseReject}>
                    Cancel
                    </Button>
                    <Button type="submit" form="reject-form" variant="primary">
                    Yes
                    </Button>
                </>
                }
            ></Modal>
        </>
    )
}

export default ViewProposalApproval
