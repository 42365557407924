import {createSlice} from '@reduxjs/toolkit';


const initialState = {
    pageindex: 0
}

const paginationSlice = createSlice({
    name: 'breadcrumb',
    initialState,
    reducers:{
        storePage: (state, action) => {
            state.pageindex = action.payload;
        },
    },
})

const {reducer, actions} = paginationSlice;

export const { storePage } = actions;

export default reducer;