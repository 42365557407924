import React, { useEffect, useState, useCallback } from "react";
import { Button, Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../core/services/api.service";
import AuthAbilities from "../../pages/Admin/Auth/AuthAbilities";

import SwalAlert from "../Swal/SwalAlert";

import Table from "../Table/Table";
import CardInfo from "../Card/CardInfo";
import Modal from "../Popup/ModalPopup";
import { CustomTable } from "../Table/Table";
import TextboxIcon from "../TextField/TextFieldIcon";
import Icon from "@mdi/react";
import { mdiFilePlus, mdiMagnify } from "@mdi/js";

// import { assignmentList } from "../../pages/Admin/Candidate/data";

export const TabAssignCandidate = () => {
  const navigate = useNavigate();
  const params = useParams();

  const customSwal = SwalAlert();
  const abilities = AuthAbilities();

  const [listAssignCand, setListAssignCand] = useState([]);
  const [listAssignCandPot, setListAssignCandPot] = useState([]);
  const [showAssignment, setShowAssignment] = useState(false);

  const [assignmentList, setAssignmentList] = useState([]);
  const [allDataAssign, setAllDataAssign] = useState([]);

  const [checkAssign, setCheckAssign] = useState([]);
  const [asignSelected, setAsignSelected] = useState(0);

  const btnCloseAssignment = (e) => {
    setShowAssignment(false);
  };

  const btnAddAssignment = (e) => {
    setAsignSelected(0);
    setShowAssignment(true);
    setCheckAssign([]);
  };

  const btnSaveAssign = (e) => {
    // console.log("checkAssign",  checkAssign.map((s) => s.id))
    customSwal
      .fire({
        title: "Do you want to add new assignment?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.post("candidate/" + params.id + "/assignment/addAssignment", {
            assignment_id: checkAssign.map((s) => s.id),
          })
            .then((response) => {
              setShowAssignment(false);
              getListAssignCandidate();

              customSwal.fire({
                title: "Add Successful!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error add assignment candidate", error);
            });
        }
      });
  };

  const headerAssignment = [
    {
      Header: "Company Name",
      maxWidth: 300,
      minWidth: 100,
      width: 300,
      Cell: ({ row }) => {
        const data = row.original;

        const comName = data.clients.name;

        function viewAssignment() {
          navigate(
            "/candidates/view-candidate-assg/" +
              params.id +
              "/" +
              data.id +
              "/" +
              data.client_id
          );
        }

        return (
          <div className="container-div list-cli">
            <Button
              variant="link"
              className="no-underline"
              onClick={viewAssignment}
            >
              {comName}
            </Button>
          </div>
        );
      },
    },
    {
      Header: "Assignment Name",
      accessor: "title",
      maxWidth: 400,
      minWidth: 100,
      width: 300,
    },
    {
      Header: "Consultant Name",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        var array = data.assignment_has_user.map((consultant, index) => {
          return (
            consultant.user.first_name + " " + consultant.user.last_name + ","
          );
        });

        var cons_name = array.join(" ");

        return <div>{cons_name.slice(0, -1)}</div>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      maxWidth: 150,
      minWidth: 100,
      width: 150,
      Cell: ({ row }) => {
        const data = row.original;

        if (data.status === "Placed") {
          return (
            <CardInfo
              cardTextSize="card_text--small"
              cardTextStyle="card_text--completed--solid"
            >
              {data.status}
            </CardInfo>
          );
        } else {
          return (
            <CardInfo
              cardTextSize="card_text--small"
              cardTextStyle="card_text--progress--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        }
      },
    },
  ];

  const headerAddAssignment = [
    {
      Header: "Company Name",
      accessor: (row) => `${row.clients ? row.clients.name : "-"}`,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{data.clients ? data.clients.name : "-"}</div>;
      },
    },
    {
      Header: "Assignment Name",
      accessor: (row) => `${row.title}`,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{data.title ? data.title : "-"}</div>;
      },
    },
    {
      Header: "Status",
      accessor: (row) => `${row.status}`,
      minWidth: 80,
      width: 80,
      Cell: ({ row }) => {
        const data = row.original;

        if (data.status === "in_discussion") {
          return (
            <CardInfo
              cardTextSize="card_text--small"
              cardTextStyle="card_text--discussion--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else if (data.status === "in_progress") {
          return (
            <CardInfo
              cardTextSize="card_text--small"
              cardTextStyle="card_text--progress--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else if (data.status === "on_hold") {
          return (
            <CardInfo
              cardTextSize="card_text--small"
              cardTextStyle="card_text--hold--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else if (data.status === "completed") {
          return (
            <CardInfo
              cardTextSize="card_text--small"
              cardTextStyle="card_text--completed--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else if (data.status === "proposal") {
          return (
            <CardInfo
              cardTextSize="card_text--small"
              cardTextStyle="card_text--completed--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else {
          return (
            <CardInfo
              cardTextSize="card_text--small"
              cardTextStyle="card_text--warning--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        }
      },
    },
    {
      Header: "",
      accessor: "selection",
      disableSortBy: true,
      maxWidth: 50,
      minWidth: 10,
      width: 50,
      Cell: ({ row }) => {
        const data = row.original;

        const onItemCheck = (data) => (event) => {
          if (checkAssign.length > 0) {
            if (event.target.checked === false) {
              const index = checkAssign.findIndex(function (chck) {
                return chck.id === data.id;
              });

              checkAssign.splice(index, 1);
            } else {
              checkAssign.push(data);
            }
          } else {
            checkAssign.push(data);
          }

          setAsignSelected(checkAssign.length);
        };

        const checkedItem = (id) => {
          let actCli = [];

          const checkAsg = checkAssign.filter((chec) => {
            return chec.id === id;
          });

          actCli = checkAsg[0];

          return actCli;
        };

        return (
          <div className="checkbox-container">
            <input
              type="checkbox"
              checked={checkedItem(data.id)}
              id={"row" + data.id}
              onChange={onItemCheck(data)}
            />
          </div>
        );
      },
    },
  ];

  const handleSearchAssign = (event) => {
    let value = event.target.value.toLowerCase();

    let newList = [];

    if (value !== "") {
      newList = assignmentList.filter((assign) => {
        return assign.clients.name.toLowerCase().includes(value.toLowerCase());
      });

      setAllDataAssign(newList);
    } else {
      setAllDataAssign(assignmentList);
    }
  };

  const getListAssignCandidate = useCallback(() => {
    Api.get("candidate/" + params.id + "/assignment")
      .then((response) => {
        let data = response.data;

        if (data !== "no data") {
          let listAssgnActive = data.filter((cli) => cli.clients.is_potential === false); 

          setListAssignCand(listAssgnActive);

          let listAssgnPot = data.filter((cli) => cli.clients.is_potential === true); 

          setListAssignCandPot(listAssgnPot);
          
        }
      })
      .catch((error) => {
        console.log("error getListAssignCandidate", error);
      });
  }, [params]);

  const getListAssignments = useCallback(() => {
    Api.get("assignment")
      .then((response) => {
        let data = response.data;

        setAssignmentList(data);
        setAllDataAssign(data);
      })
      .catch((error) => {
        console.log("error list assignments", error);
      });
  }, []);

  useEffect(() => {
    getListAssignCandidate();
    getListAssignments();
  }, [getListAssignCandidate, getListAssignments]);

  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <div className="row-div">
          <div className="left-div"></div>
          <div className="right-div">
            <Button
              variant="link"
              className="modal-link"
              onClick={btnAddAssignment}
              hidden={!abilities.includes("add assignment")}
            >
              <Icon path={mdiFilePlus} />
              Add Assignment
            </Button>
          </div>
        </div>
      </div>

      <Card className="list-cli" style={{ marginBottom: "20px" }}>
        <Card.Body>
          <div className="row-div">
            <div className="left-div">
              <div className="title-text report">Assignments</div>
            </div>
          </div>
          <Table headers={headerAssignment} items={listAssignCand}></Table>
        </Card.Body>
       
      </Card>

      <Card className="list-cli" style={{ marginBottom: "20px" }}>
        <Card.Body>
          <div className="row-div">
            <div className="left-div">
              <div className="title-text report">Potential Assignments</div>
            </div>
          </div>
          <Table headers={headerAssignment} items={listAssignCandPot}></Table>
        </Card.Body>
       
      </Card>

      {/* Modal List of Candidate */}
      <Modal
        show={showAssignment}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseAssignment}
        title="Add assignment"
        content={
          <div className="list-cli">
            <div className="row-div">
              <div className="left-div">
                <TextboxIcon
                  name="txtTabCandi"
                  type="text"
                  placeholder="Search"
                  inputSize="input--medium"
                  onChange={(event) => handleSearchAssign(event)}
                  icon={mdiMagnify}
                ></TextboxIcon>
              </div>
              <div className="right-div comp-selected">
                {asignSelected} selected
                <div className="right-div two-btn"></div>
              </div>
            </div>
            <CustomTable
              headers={headerAddAssignment}
              items={allDataAssign}
              paginate={allDataAssign.length}
              maxRows={15}
            ></CustomTable>
          </div>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseAssignment}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              onClick={btnSaveAssign}
              disabled={asignSelected > 0 ? false : true}
            >
              Add
            </Button>
          </>
        }
      />
    </>
  );
};
