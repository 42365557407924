import React from "react";
import Modal from "../Popup/ModalPopup";
import GoogleDocsViewer from "react-google-docs-viewer";

function Viewer(props) {
  return (
    <>
      <Modal
        show={props.showPdf}
        backdrop="static"
        keyboard={false}
        onHide={props.btnClosePdf}
        title={props.modalTitle}
        content={
          <div className="pdf-container">
            <div style={{ width: "40vw", height: "75vh" }}>
              <GoogleDocsViewer
                width="100%"
                height="75vh"
                fileUrl={props.filePath}
              />
            </div>
          </div>
        }
      />
    </>
  );
}

export default Viewer;
