import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Tab, Nav, Card, Button } from "react-bootstrap";

import Icon from "@mdi/react";
import { mdiArrowLeft, mdiClipboardAccount } from "@mdi/js";

import BreadCrumb from "../../components/Breadcrumb/BreadCrumb";

import { TabHiringClient } from "../../components/Tab/TabClients/TabHiringClient";
import { TabEduExpClient } from "../../components/Tab/TabClients/TabEduExpClient";

import { getAssignmentDetails } from "../../store/actions/assignmentSlice";
import { getCandidateDetail } from "../../store/actions/candidateSlice";
import { getListOptions } from "../../store/actions/optionSlice";
import { storeTitle } from "../../store/actions/headerTitleSlice";

function ViewCandidate() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.login);
  const { assignDetails } = useSelector((state) => state.assignment);
  const { candidateDetails, candidateConsent } = useSelector((state) => state.candidate);

  const [candConsent, setCandConsent] = useState({});

  const selectedCrumb = (crumb) => {
    if(crumb === "Assignments"){
      navigate("/");
    }else if(crumb === assignDetails.title){
      navigate("/client-assignment/" + params.idassign);
    }
  };

  const handleSelectTab = (key) => {
    const filteredConsent = candidateConsent.find((consent) => {
      return consent.client_id === user.clientid && consent.candidate_id === parseInt(params.idcand);
    });

    // 

    setCandConsent(filteredConsent);
  };

  useEffect(() => {
    dispatch(getAssignmentDetails(user.clientid, params.idassign));
    dispatch(getCandidateDetail(params.idcand));
    dispatch(getListOptions());
    dispatch(storeTitle({title: "Assignments", icon: mdiClipboardAccount}));
  }, [
    dispatch,
    params.idassign,
    user.clientid,
    params.idcand,
  ]);

  return (
    <div className="main-div list-cli">
      <div className="container-div both-side">
        <BreadCrumb crumbs={["Assignments", assignDetails.title, candidateDetails.last_name ? candidateDetails.first_name + " " + candidateDetails.last_name : candidateDetails.first_name]} selected={selectedCrumb}></BreadCrumb>
        <Button variant="secondary" onClick={() => navigate(-1)}>
          <Icon path={mdiArrowLeft} color="#212529" />
          Back
        </Button>
      </div>
      <div className="top-title-div">
        <Card className="mb-3">
          <Card.Body>
            <div className="top-card">
              <div className="top-left-card">
                <div className="big-text">
                  {candidateDetails.last_name ? candidateDetails.first_name + " " + candidateDetails.last_name : candidateDetails.first_name}
                </div>
                <div>&bull;</div>
                <div className="mini-text no-mb">{assignDetails.title}</div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>

      <Tab.Container 
      defaultActiveKey="1" 
      onSelect={handleSelectTab}
      >
        <div className="nav-container-nf">
          <Nav variant="tabs" justify>
            <Nav.Item>
              <Nav.Link eventKey="1">Hiring Process</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="2">Educations & Experiences</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>

        <Tab.Content style={{ background: "none" }}>
          <Tab.Pane eventKey="1">
            <TabHiringClient></TabHiringClient>
          </Tab.Pane>
          <Tab.Pane eventKey="2">
            <TabEduExpClient consent={candConsent}></TabEduExpClient>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}

export default ViewCandidate;
