import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, Form, Row, Col, Card } from "react-bootstrap";
import Api from "../../core/services/api.service";

import SwalAlert from "../Swal/SwalAlert";
import AuthAbilities from "../../pages/Admin/Auth/AuthAbilities";
import moment from "moment";

import Icon from "@mdi/react";
import {
  mdiAccountPlus,
  mdiDotsHorizontal,
  mdiPencil,
  mdiDelete,
  mdiContentDuplicate,
  mdiFileSend,
  // mdiMagnify,
} from "@mdi/js";

import ButtonNumber from "../Button/ButtonNumber";
import CardInfo from "../Card/CardInfo";
import Table from "../Table/Table";
import Modal from "../Popup/ModalPopup";
import Searchable from "../DropDown/SearchableSelect";
// import TextboxIcon from "../TextField/TextFieldIcon";

import { storeIndAssignTab } from "../../store/actions/tabAssignSlice";
import { storeAsgnMenu } from "../../store/actions/breadAsgnSlice";
import { getListClient } from "../../store/actions/clientSlice";
import { getAssignmentList } from "../../store/actions/assignmentSlice";

export const TabAssignment = (props) => {
  const params = useParams();
  const dispatch = useDispatch();

  const abilities = AuthAbilities();

  const { dataCountry } = useSelector((state) => state.option);
  const { dataStaff } = useSelector((state) => state.staff);
  const { clientActiveList } = useSelector((state) => state.client);
  const { assignListES, assignListTM, assignListDM } = useSelector(
    (state) => state.assignment
  );

  const [errorForm, setErrorForm] = useState({});
  const [showAssgn, setShowAssgn] = useState(false);

  // const [today] = useState(new Date());
  // const [createDate, setCreateDate] = useState("");
  // const [assignESList, setAssignESList] = useState([]);
  // const [assignTMList, setAssignTMList] = useState([]);
  // const [assignDMList, setAssignDMList] = useState([]);

  const [listProposal, setListProposal] = useState([]);
  const [idAssign, setIdAssign] = useState("");

  const [modeAssign, setModeAssign] = useState({
    title: null,
    button: null,
  });

  const [assignstate, setAssignState] = useState({
    txtJobtitle: "",
    ddlCountry: "",
    ddlProposal: "",
    txtstatus: "",
    txtcreatedate: "",
    ddlAssignment: "",
  });

  const [selectedStaff, setSelectedStaff] = useState([]);

  const [showInvoice, setShowInvoice] = useState(false);
  const [showMigrate, setShowMigrate] = useState(false);
  const [modeModal, setModeModal] = useState({
    title: null,
    button: null,
  });
  const [idInvoice, setIdInvoice] = useState(0);
  // const [compSelected, setCompSelected] = useState(0);

  const [errorFormInv, setErrorFormInv] = useState({});

  const addInvoice = (type, id) => () => {
    // console.log("id", id);
    setModeModal({
      title: "Add Invoice",
      button: "Add",
    });
    setIdInvoice(id);
    setErrorFormInv({});
    dispatch(getAssignmentList(params.id));
    setShowInvoice(true);
  };

  const closeInvoice = () => {
    setShowInvoice(false);
  };

  // const closeMigrate = () => {
  //   setShowMigrate(false);
  // };

  // Checking Error Form Invoice
  const formErrorInv = (object) => {
    const newErrors = {};

    console.log("object", object);

    if (!object.txtRefNo || object.txtRefNo === "")
      newErrors.txtRefNo = "Fill in the blank!";

    if (!object.ddlStatus || object.ddlStatus === "")
      newErrors.ddlStatus = "Fill in the blank!";

    return newErrors;
  };

  const customSwal = SwalAlert();

  const btnSaveUpdateInvoice = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtInvoice = Object.fromEntries(data.entries());

    const newErrors = formErrorInv(dtInvoice);

    // console.log("newErros", newErrors);
    if (Object.keys(newErrors).length > 0) {
      setErrorFormInv(newErrors);
    } else {
      customSwal
        .fire({
          title: "Do you want to save new invoice?",
          icon: "warning",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        })
        .then((result) => {
          if (result.isConfirmed) {
            // console.log("idInvoice", idInvoice);
            Api.post(
              "client/" +
                params.id +
                "/assignment/" +
                idInvoice +
                "/invoice/create",
              {
                reference: dtInvoice.txtRefNo,
                status: dtInvoice.ddlStatus,
              }
            )
              .then((response) => {
                // let data = response.data;

                setShowInvoice(false);
                dispatch(getAssignmentList(params.id));
                customSwal.fire({
                  title: "Invoice Saved Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("client", error);
              });
          }
        });
    }
  };

  const headerAssignment = [
    {
      Header: "No",
      accessor: (row) => `${row.index}`,
      maxWidth: 70,
      minWidth: 70,
      width: 70,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Assignment Name",
      accessor: (row) => `${row.position}`,
      maxWidth: 400,
      minWidth: 100,
      width: 350,
      Cell: ({ row }) => {
        const data = row.original;

        const navigate = useNavigate();

        function viewAssignment() {
          dispatch(
            storeAsgnMenu({
              id: data.id,
              menu: data.title,
            })
          );

          dispatch(
            storeIndAssignTab({
              id: data.id,
              index: "1",
            })
          );

          navigate("/clients/view-assignment/" + props.page + "/" + params.id + "/" + data.id);
        }

        return (
          <div className="container-div list-cli">
            <Button
              variant="link"
              className="no-underline"
              onClick={viewAssignment}
            >
              {data.title}
            </Button>
          </div>
        );
      },
    },
    {
      Header: "Created Date",
      accessor: (row) => `${row.created_at}`,
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{moment(data.created_at).format("DD MMMM YYYY")}</div>;
      },
    },
    {
      Header: "Invoice",
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          // <div>
          //   {data.id}
          // </div>
          <ButtonNumber
            idClient={data.client_id}
            idAssign={data.id}
            items={data.assignment_invoices}
            button={
              <>
                <button
                  onClick={addInvoice("Add", data.id)}
                  className="btn-add-inv"
                >
                  <div>+</div>
                </button>
              </>
            }
          ></ButtonNumber>
        );
      },
    },
    {
      Header: "No. of Candidate",
      maxWidth: 400,
      minWidth: 100,
      width: 250,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <>
            <div>
              {data._count.assignment_candidates === 0
                ? "-"
                : data._count.assignment_candidates + " Candidates"}
            </div>
          </>
        );
      },
    },
    {
      Header: "Status",
      accessor: (row) => `${row.status}`,
      maxWidth: 250,
      minWidth: 100,
      width: 250,
      Cell: ({ row }) => {
        const data = row.original;

        if (data.status === "in_discussion") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--discussion--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else if (data.status === "in_progress") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--progress--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else if (data.status === "on_hold") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--hold--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else if (data.status === "completed") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--completed--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else if (data.status === "proposal") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--completed--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--warning--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        }
      },
    },
    {
      Header: "",
      accessor: "edit",
      disableSortBy: true,
      maxWidth: 100,
      minWidth: 10,
      width: 70,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={btnDuplicateAssgn(data)}
                  hidden={!abilities.includes("duplicate assignment")}
                >
                  <Icon path={mdiContentDuplicate} color="#467FD0" />
                  Duplicate Assignment
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={btnOpenMigrate(data.id)}
                  hidden={!abilities.includes("migrate assignment")}
                >
                  <Icon path={mdiFileSend} color="#467FD0" />
                  Migrate Assignment
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={btnEditAssgn(data)}
                  hidden={!abilities.includes("edit assignment")}
                >
                  <Icon path={mdiPencil} color="#467FD0" />
                  Edit Assignment
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={btnDeleteAssgn(data)}
                  hidden={!abilities.includes("delete assignment")}
                >
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete Assignment
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const headerTM = [
    {
      Header: "No",
      accessor: (row) => `${row.index}`,
      maxWidth: 70,
      minWidth: 70,
      width: 70,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Assignment Name",
      accessor: (row) => `${row.title}`,
      maxWidth: 400,
      minWidth: 100,
      width: 350,
      Cell: ({ row }) => {
        const data = row.original;

        const navigate = useNavigate();

        function viewAssignment() {
          dispatch(
            storeAsgnMenu({
              id: data.id,
              menu: data.title,
            })
          );

          dispatch(
            storeIndAssignTab({
              id: data.id,
              index: "1",
            })
          );

          navigate("/clients/view-assignment-tdm/tdm/" + props.page + "/" +  params.id + "/" + data.id);
        }

        return (
          <div className="container-div list-cli">
            <Button
              variant="link"
              className="no-underline"
              onClick={viewAssignment}
            >
              {data.title}
            </Button>
          </div>
        );
      },
    },
    {
      Header: "Created Date",
      accessor: (row) => `${row.created_at}`,
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{moment(data.created_at).format("DD MMMM YYYY")}</div>;
      },
    },
    {
      Header: "Invoice",
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          // <div>
          //   {data.id}
          // </div>
          <ButtonNumber
            idClient={data.client_id}
            idAssign={data.id}
            items={data.assignment_invoices}
            button={
              <>
                <button
                  onClick={addInvoice("Add", data.id)}
                  className="btn-add-inv"
                >
                  <div>+</div>
                </button>
              </>
            }
          ></ButtonNumber>
        );
      },
    },
    {
      Header: "Status",
      accessor: (row) => `${row.status}`,
      maxWidth: 200,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        if (data.status === "BD") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--discussion--solid"
            >
              {data.status}
            </CardInfo>
          );
        } else if (data.status === "confirmed") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--progress--solid"
            >
              {data.status}
            </CardInfo>
          );
        } else if (data.status === "on_hold") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--hold--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else if (data.status === "cancelled") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--completed--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else if (data.status === "in_discussion") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--discussion--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else if (data.status === "proposal") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--completed--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--warning--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        }
      },
    },
    {
      Header: "",
      accessor: "edit",
      disableSortBy: true,
      maxWidth: 100,
      minWidth: 10,
      width: 70,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={btnDuplicateAssgn(data)}>
                  <Icon path={mdiContentDuplicate} color="#467FD0" />
                  Duplicate Assignment
                </Dropdown.Item>
                <Dropdown.Item onClick={btnOpenMigrate}>
                  <Icon path={mdiFileSend} color="#467FD0" />
                  Migrate Assignment
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={btnEditAssgn(data)}
                  hidden={!abilities.includes("edit assignment")}
                >
                  <Icon path={mdiPencil} color="#467FD0" />
                  Edit Assignment
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={btnDeleteAssgn(data)}
                  hidden={!abilities.includes("delete assignment")}
                >
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete Assignment
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const headerDM = [
    {
      Header: "No",
      accessor: (row) => `${row.index}`,
      maxWidth: 70,
      minWidth: 70,
      width: 70,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Assignment Name",
      accessor: (row) => `${row.title}`,
      maxWidth: 400,
      minWidth: 100,
      width: 350,
      Cell: ({ row }) => {
        const data = row.original;

        const navigate = useNavigate();

        function viewAssignment() {
          dispatch(
            storeAsgnMenu({
              id: data.id,
              menu: data.title,
            })
          );

          dispatch(
            storeIndAssignTab({
              id: data.id,
              index: "1",
            })
          );

          navigate("/clients/view-assignment-tdm/tdm/" + props.page + "/" +  params.id + "/" + data.id);
        }

        return (
          <div className="container-div list-cli">
            <Button
              variant="link"
              className="no-underline"
              onClick={viewAssignment}
            >
              {data.title}
            </Button>
          </div>
        );
      },
    },
    {
      Header: "Created Date",
      accessor: (row) => `${row.created_at}`,
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{moment(data.created_at).format("DD MMMM YYYY")}</div>;
      },
    },
    {
      Header: "Invoice",
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          // <div>
          //   {data.id}
          // </div>
          <ButtonNumber
            idClient={data.client_id}
            idAssign={data.id}
            items={data.assignment_invoices}
            button={
              <>
                <button
                  onClick={addInvoice("Add", data.id)}
                  className="btn-add-inv"
                >
                  <div>+</div>
                </button>
              </>
            }
          ></ButtonNumber>
        );
      },
    },
    {
      Header: "Status",
      accessor: (row) => `${row.status}`,
      maxWidth: 200,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        if (data.status === "BD") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--discussion--solid"
            >
              {data.status}
            </CardInfo>
          );
        } else if (data.status === "confirmed") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--progress--solid"
            >
              {data.status}
            </CardInfo>
          );
        } else if (data.status === "on_hold") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--hold--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else if (data.status === "cancelled") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--completed--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else if (data.status === "in_discussion") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--discussion--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else if (data.status === "proposal") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--completed--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--warning--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        }
      },
    },
    {
      Header: "",
      accessor: "edit",
      disableSortBy: true,
      maxWidth: 100,
      minWidth: 10,
      width: 70,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={btnDuplicateAssgn(data)}>
                  <Icon path={mdiContentDuplicate} color="#467FD0" />
                  Duplicate Assignment
                </Dropdown.Item>
                <Dropdown.Item onClick={btnOpenMigrate}>
                  <Icon path={mdiFileSend} color="#467FD0" />
                  Migrate Assignment
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={btnEditAssgn(data)}
                  hidden={!abilities.includes("edit assignment")}
                >
                  <Icon path={mdiPencil} color="#467FD0" />
                  Edit Assignment
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={btnDeleteAssgn(data)}
                  hidden={!abilities.includes("delete assignment")}
                >
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete Assignment
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  // Function Event
  // const urlApi = "client/" + params.id + "/assignment";

  const getListProposal = useCallback(() => {
    Api.get("client/" + params.id + "/proposal/list")
      .then((response) => {
        let data = response.data;
        // console.log("data", data);

        if (data !== "no data") {
          setListProposal(data);
        }
      })
      .catch((error) => {
        console.log("client/proposal/list", error);
      });
  }, [params]);

  const changeStaff = (e) => {
    // let value = e;
    setSelectedStaff(Array.isArray(e) ? e.map((x) => x.id) : []);
  };
  // End Function Event

  // Checking Error Form
  const formError = (object) => {
    const newErrors = {};

    if (!object.txtJobtitle || object.txtJobtitle === "")
      newErrors.txtJobtitle = "Fill in the blank!";

    if (!object.ddlCountry || object.ddlCountry === "")
      newErrors.ddlCountry = "Fill in the blank!";

    if (!object.ddlAssign || object.ddlAssign === "")
      newErrors.ddlAssign = "Fill in the blank!";

    if (object.ddlAssignment !== undefined) {
      if (!object.ddlAssignment || object.ddlAssignment === "")
        newErrors.ddlAssignment = "Fill in the blank!";
    }

    return newErrors;
  };
  // End Checking Form

  // Button Event
  const btnCloseAssgn = () => {
    setShowAssgn(false);
  };

  const btnAddAssgn = (e) => {
    setErrorForm({});

    const formatDate = moment().format("DD MMMM YYYY");

    setAssignState({
      txtJobtitle: "",
      ddlCountry: "",
      ddlProposal: "",
      txtstatus: "In Discussion",
      txtcreatedate: formatDate,
      ddlAssignment: "",
    });
    setModeAssign({
      title: "Add Assignment",
      button: "Add",
    });

    setSelectedStaff([]);
    setShowAssgn(true);
    setIdAssign("New");
    // assignstate.txtstatus = "in_discussion";
  };

  const btnEditAssgn = (object) => (e) => {
    // console.log("e", object);
    setErrorForm({});

    setModeAssign({
      title: "Edit Assignment",
      button: "Update",
    });

    assignstate.txtJobtitle = object.title;
    assignstate.ddlCountry = object.location_id;

    setSelectedStaff(object.assign_to);

    assignstate.ddlProposal = object.proposal_id;
    assignstate.txtstatus = object.status.replace(/_/g, " ");
    assignstate.txtcreatedate = moment(object.created_at).format(
      "DD MMMM YYYY"
    );
    assignstate.ddlAssignment = object.type;
    setIdAssign(object.id);

    setShowAssgn(true);
  };

  const btnDeleteAssgn = (object) => (e) => {
    // console.log("e", object);
    customSwal
      .fire({
        title: "Do you want to delete assignment?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("client/" + object.client_id + "/assignment/" + object.id )
            .then((response) => { 
              let data = response.data;   
              
              dispatch(getAssignmentList(params.id));

              customSwal.fire({
                title: "Deleted Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error delete assignment", error);
            });
        }
      });
  };

  const btnSaveUpdateAssign = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);

    const dataAssign = Object.fromEntries(data.entries());

    // console.log("dataAssign", dataAssign)

    const newErrors = formError(dataAssign);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      setErrorForm({});

      if (idAssign === "New") {
        customSwal
          .fire({
            title: "Do you want to save new assignment?",
            icon: "warning",
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post("client/" + params.id + "/assignment/create", {
                client_id: Number(params.id),
                title: dataAssign.txtJobtitle,
                location_id: Number(dataAssign.ddlCountry),
                assign_to: selectedStaff,
                proposal_id:
                  dataAssign.ddlProposal !== ""
                    ? Number(dataAssign.ddlProposal)
                    : null,
                type: dataAssign.ddlAssignment,
              })
                .then((response) => {
                  // let data = response.data;

                  setShowAssgn(false);
                  dispatch(getAssignmentList(params.id));
                  customSwal.fire({
                    title: "Assignment Saved Successfully!",
                    icon: "success",
                    confirmButtonText: "Okay",
                  });
                })
                .catch((error) => {
                  console.log("client", error);
                });
            }
          });
      } else {
        customSwal
          .fire({
            title: "Do you want to update assignment?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.patch("client/" + params.id + "/assignment/" + idAssign, {
                title: dataAssign.txtJobtitle,
                client_id: Number(params.id),
                location_id: Number(dataAssign.ddlCountry),
                assign_to: selectedStaff,
                proposal_id:
                  dataAssign.ddlProposal !== ""
                    ? Number(dataAssign.ddlProposal)
                    : null,
              })
                .then((response) => {
                  setShowAssgn(false);
                  dispatch(getAssignmentList(params.id));
                  customSwal.fire({
                    title: "Updated Successfully!",
                    icon: "success",
                    confirmButtonText: "Okay",
                  });
                })
                .catch((error) => {
                  console.log("edit assignment error", error);
                });
            }
          })
          .catch((error) => {
            console.log("edit assignment error", error);
          });
      }
    }
  };

  const btnDuplicateAssgn = (object) => (e) => {
    customSwal
      .fire({
        title: "Do you want to duplicate assignment " + object.title + "?",
        icon: "warning",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.post(
            "client/" + params.id + "/assignment/" + object.id + "/duplicate"
          )
            .then((response) => {
              dispatch(getAssignmentList(params.id));
              customSwal.fire({
                title: "Assignment Saved Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error duplicate assignment", error);
            });
        }
      });
  };

  const [errorMigrate, setErrorMigrate] = useState({});
  const [idAssignment, setIdAssignment] = useState("");

  const btnOpenMigrate = (id) => (e) => {
    setIdAssignment(id);
    setShowMigrate(true);
  };

  const btnCloseMigrate = () => {
    setShowMigrate(false);
  };

  const btnSaveMigrate = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtMig = Object.fromEntries(data.entries());

    // console.log("dtMig", dtMig)

    const newErrors = formErrorMigrate(dtMig);

    if (Object.keys(newErrors).length > 0) {
      setErrorMigrate(newErrors);
    } else {
      setErrorMigrate({});

      customSwal
        .fire({
          title: "Do you want to migrate this assignment?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Api.post(
              "/client/" +
                params.id +
                "/assignment/" +
                idAssignment +
                "/migrate/" +
                dtMig.ddlClientName
            )
              .then((response) => {
                setShowMigrate(false);
                dispatch(getAssignmentList(params.id));
                customSwal.fire({
                  title: "Migrate Successfully!",
                  text: "Assignment has been migrated. Please check the assignment at the new client",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("error btnSaveMigrate", error);
              });
          }
        });
    }
    // setShowMigrate(false);
  };

  const formErrorMigrate = (object) => {
    const newErrors = {};

    if (!object.ddlClientName || object.ddlClientName === "")
      newErrors.ddlClientName = "Fill in the blank!";

    return newErrors;
  };
  // End Button Event

  useEffect(() => {
    dispatch(getListClient());
    getListProposal();
  }, [getListProposal, dispatch]);

  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <div className="row-div">
          <div className="left-div"></div>
          <div className="right-div">
            <Button
              variant="primary"
              // className="modal-link"
              onClick={btnAddAssgn}
              hidden={!abilities.includes("add assignment")}
            >
              <Icon path={mdiAccountPlus} />
              <div>Add Assignment</div>
            </Button>
          </div>
        </div>
      </div>

      <Card style={{ marginBottom: "20px" }}>
        <Card.Body>
          <div className="row-div">
            <div className="left-div">
              <div className="title-text report">Executive Service (ES)</div>
            </div>
          </div>

          <Table
            headers={headerAssignment}
            items={assignListES}
            paginate={assignListES.length}
            maxRows={15}
          ></Table>
        </Card.Body>
      </Card>

      <Card style={{ marginBottom: "20px" }}>
        <Card.Body>
          <div className="row-div">
            <div className="left-div">
              <div className="title-text report">
                Transition Management (TM)
              </div>
            </div>
          </div>

          <Table
            headers={headerTM}
            items={assignListTM}
            paginate={assignListTM.length}
            maxRows={15}
          ></Table>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <div className="row-div">
            <div className="left-div">
              <div className="title-text report">
                Distruption Management (DM)
              </div>
            </div>
          </div>

          <Table
            headers={headerDM}
            items={assignListDM}
            paginate={assignListDM.length}
            maxRows={15}
          ></Table>
        </Card.Body>
      </Card>

      {/* Modal */}
      <Modal
        show={showAssgn}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseAssgn}
        title={modeAssign.title}
        content={
          <>
            <Form id="assign-form" onSubmit={btnSaveUpdateAssign}>
              {/* 1 */}
              <Row className="add-assg">
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="col-3">Created Date</Form.Label>
                  <Col sm="3" className="create-date">
                    <Form.Control
                      placeholder={assignstate.txtcreatedate}
                      readOnly
                    />
                  </Col>
                  <Form.Label className="col-3">Status</Form.Label>
                  <Col sm="3">
                    <Form.Control
                      placeholder={assignstate.txtstatus}
                      style={{ textTransform: "capitalize" }}
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Row>

              {/* 2 - TM/DM flow */}
              <Row>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="col-3">
                    Service <label className="validation-star">*</label>
                  </Form.Label>
                  <Col sm="9">
                    <Form.Select
                      name="ddlAssignment"
                      defaultValue={assignstate.ddlAssignment}
                      isInvalid={
                        assignstate.ddlAssignment
                          ? ""
                          : !!errorForm.ddlAssignment
                      }
                      disabled={assignstate.ddlAssignment ? true : false}
                    >
                      <option value="">Select</option>
                      <option value="ES">Executive Search</option>
                      <option value="TM">Transition Management</option>
                      <option value="DM">Distruption Management</option>
                    </Form.Select>
                  </Col>
                  <Form.Control.Feedback type="invalid">
                    {errorForm.ddlAssignment}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              {/* 3 */}
              <Row>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="col-3">
                    Assignment Name <label className="validation-star">*</label>
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      name="txtJobtitle"
                      type="text"
                      defaultValue={assignstate.txtJobtitle}
                      placeholder="Please enter your assignment name"
                      isInvalid={!!errorForm.txtJobtitle}
                    />
                  </Col>
                  <Form.Control.Feedback type="invalid">
                    {errorForm.txtJobtitle}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              {/* 4 */}
              <Row>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="col-3">
                    Country <label className="validation-star">*</label>
                  </Form.Label>
                  <Col sm="9">
                    <Form.Select
                      name="ddlCountry"
                      // onChange={handleCountry}
                      defaultValue={assignstate.ddlCountry}
                      isInvalid={!!errorForm.ddlCountry}
                    >
                      <option value="">Select</option>
                      {dataCountry.map((cntry, index) => (
                        <option key={index} value={cntry.id}>
                          {cntry.value}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Form.Control.Feedback type="invalid">
                    {errorForm.ddlCountry}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              {/* 5 */}
              <Row>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="col-3">
                    Assign To <label className="validation-star">*</label>
                  </Form.Label>
                  <Col sm="9">
                    <Searchable
                      name="ddlAssign"
                      isMulti={true}
                      options={dataStaff}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) =>
                        option.first_name + " " + option.last_name
                      }
                      onChange={changeStaff}
                      defaultValue={dataStaff.filter((obj) =>
                        selectedStaff.includes(obj.id)
                      )}
                      isInvalid={!!errorForm.ddlAssign}
                      // errorText={errorForm.ddlAssign}
                    ></Searchable>
                  </Col>
                </Form.Group>
              </Row>

              {/* 6 */}
              <Row>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="col-3">Proposal</Form.Label>
                  <Col sm="9">
                    <Form.Select
                      name="ddlProposal"
                      // onChange={handleCountry}
                      defaultValue={assignstate.ddlProposal}
                      // isInvalid={!!errorForm.ddlProposal}
                    >
                      <option value="">Select</option>
                      {listProposal.map((pro, index) => (
                        <option key={index} value={pro.id}>
                          {pro.file_name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  {/* <Form.Control.Feedback type="invalid">
                    {errorForm.ddlProposal}
                  </Form.Control.Feedback> */}
                </Form.Group>
              </Row>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseAssgn}>
              Cancel
            </Button>
            <Button type="submit" form="assign-form" variant="primary">
              {modeAssign.button}
            </Button>
          </>
        }
      ></Modal>

      {/* Modal Invoice */}
      <Modal
        show={showInvoice}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={closeInvoice}
        title={modeModal.title}
        content={
          <>
            <Form id="invoice-form" onSubmit={btnSaveUpdateInvoice}>
              <Row>
                <Form.Group className="mb-3 col-6">
                  <Form.Label>Invoice Reference No.</Form.Label>
                  <Form.Control
                    name="txtRefNo"
                    type="text"
                    placeholder="Example: 123456789"
                    isInvalid={!!errorFormInv.txtRefNo}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorFormInv.txtRefNo}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3 col-6">
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    name="ddlStatus"
                    isInvalid={!!errorFormInv.ddlStatus}
                  >
                    <option value="">Select</option>
                    <option value="paid">Paid</option>
                    <option value="unpaid">Unpaid</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errorFormInv.ddlStatus}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={closeInvoice}>
              Cancel
            </Button>
            <Button form="invoice-form" type="submit" variant="primary">
              {modeModal.button}
            </Button>
          </>
        }
      />

      {/* Modal Migrate*/}
      <Modal
        show={showMigrate}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseMigrate}
        title="Migrate Assignment"
        content={
          <>
            <Form id="migrate-form" onSubmit={btnSaveMigrate}>
              {/* 1 */}
              <Row>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="col-3">
                    Client Name <label className="validation-star">*</label>
                  </Form.Label>
                  <Col sm="9">
                    <Searchable
                      name="ddlClientName"
                      isMulti={false}
                      options={clientActiveList}
                      getOptionValue={(option) => option.value}
                      getOptionLabel={(option) => option.label}
                      // onChange={changeStaff}
                      isInvalid={!!errorMigrate.ddlClientName}
                      errorText={errorMigrate.ddlClientName}
                    ></Searchable>
                  </Col>
                </Form.Group>
              </Row>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseMigrate}>
              Cancel
            </Button>
            <Button type="submit" variant="primary" form="migrate-form">
              Migrate
            </Button>
          </>
        }
      ></Modal>
    </>
  );
};
