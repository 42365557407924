import {createSlice} from '@reduxjs/toolkit';
import Api from '../../core/services/api.service';

export const roleSlice = createSlice({
    name: "role",
    initialState: {
        dataRole: [],
    },
    reducers: {
        getRole: (state, action) => {
            state.dataRole = action.payload;
        },
    }
});

export const getListRole = () => async (dispatch) => {
    try {
        const response = await Api.get("role");

        dispatch(getRole(response.data));

    } catch (err) {
      throw new Error(err);
    }
};
  

  
export const { getRole } = roleSlice.actions;
export default roleSlice.reducer;