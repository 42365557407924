import React, { useState, useEffect, useCallback } from "react";
import { Card, Button, Row, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import FileSaver from "file-saver";
import Api from "../../core/services/api.service";
import Apiform from "../../core/services/api.service";

import Icon from "@mdi/react";
import { mdiDownload, mdiUpload } from "@mdi/js";

import { CustomTable } from "../../components/Table/Table";
import Popup from "../../components/Popup/ModalPopup";
import SwalAlert from "../../components/Swal/SwalAlert";
// import Empty from "../../components/Empty/Empty";
// import { docList } from "./data";

const Attachment = () => {
  const { user } = useSelector((state) => state.login);
  const customSwal = SwalAlert();

  const headerAttachment = [
    {
      Header: "No",
      maxWidth: 50,
      minWidth: 10,
      width: 40,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Document Name",
      accessor: "file_name",
      maxWidth: 400,
      minWidth: 100,
      width: 300,
    },
    {
      Header: "Upload Attachment",
      accessor: "upload_att",
      maxWidth: 200,
      minWidth: 160,
      width: 160,
      Cell: ({ row }) => {
        const data = row.original;

        function btnDownAttach() {
          FileSaver.saveAs(data.file_path_received);
        }

        return (
          <>
            {data.file_path_received ? (
              <>
                <Button
                  variant="link"
                  className="reminder-btn set-rem"
                  onClick={btnDownAttach}
                >
                  <Icon path={mdiDownload} color="#467FD0" />
                  Download
                </Button>
              </>
            ) : (
              <div className="has-file-upload">
                <Button
                  variant="link"
                  className="reminder-btn set-rem"
                  onClick={btnUploadAttach(data)}
                >
                  <Icon path={mdiUpload} color="#467FD0" />
                  Upload
                </Button>
              </div>
            )}
          </>
        );
      },
    },
    {
      Header: "Upload Date",
      Cell: ({ row }) => {
        const data = row.original;

        const date = new Date(data.received_date);
        const longMonth = date.toLocaleString("en-us", { month: "long" });

        return (
          <div>
            {data.received_date
              ? date.getDate() + " " + longMonth + " " + date.getFullYear()
              : "-"}
          </div>
        );
      },
    },
    {
      Header: "Received Attachment",
      maxWidth: 200,
      minWidth: 160,
      width: 160,
      Cell: ({ row }) => {
        const data = row.original;

        function btnDownAttach() {
          FileSaver.saveAs(data.file_path_upload);
        }

        return (
          <>
            <Button
              variant="link"
              className="reminder-btn set-rem"
              onClick={btnDownAttach}
            >
              <Icon path={mdiDownload} color="#467FD0" />
              Download
            </Button>
          </>
        );
      },
    },
    {
      Header: "Received Date",
      Cell: ({ row }) => {
        const data = row.original;

        const date = new Date(data.created_at);
        const longMonth = date.toLocaleString("en-us", { month: "long" });

        return (
          <div>
            {date.getDate()} {longMonth} {date.getFullYear()}
          </div>
        );
      },
    },
  ];

  const [errorForm, setErrorForm] = useState({});
  const [showUpload, setShowUpload] = useState(false);
  const [modeModal, setModeModal] = useState({
    title: null,
    button: null,
  });
  const [selRowAttach, setSelRowAttach] = useState({});

  const btnCloseUpload = () => {
    setShowUpload(false);
  };

  const btnUploadAttach = (data) => (e) => {
    console.log("data", data);
    setErrorForm({});
    setSelRowAttach(data);
    setModeModal({
      title: "Upload Attachment",
      button: "Upload",
    });
    setShowUpload(true);
  };

  const btnSaveUpload = (idPro) => (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtUpload = Object.fromEntries(data.entries());

    // console.log("dtUpload", idPro);
    const newErrors = formErrorUpload(dtUpload);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      customSwal
        .fire({
          title: "Do you want to upload signed proposal ?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();

            formData.append("file", dtUpload.txtfile);

            Apiform.post(
              "candidate/document/" + idPro + "/uploadReceive",
              formData
            )
              .then((response) => {
                getListDocuments();

                setShowUpload(false);
                customSwal.fire({
                  title: "File Uploaded Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("user/store", error);
              });
          }
        });
    }
  };

  const formErrorUpload = (object) => {
    const newErrors = {};

    // console.log("object", object);

    if (!object.txtfile.name || object.txtfile.name === "")
      newErrors.txtfile = "Fill in the blank!";

    return newErrors;
  };

  const [listAttachment, setListAttachment] = useState([]);

  const getListDocuments = useCallback(() => {
    Api.get("candidate/" + user.sub + "/listDocument")
      .then((response) => {
        let data = response.data;

        // console.log("data.attachment", data.attachment);

        // Attachment List
        if (data.attachment.length > 0) {
          let sortAttachment = data.attachment.sort(function (a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
          });

          setListAttachment(sortAttachment);
        } else {
          setListAttachment([]);
        }
      })
      .catch((error) => {
        console.log("error getListDocuments", error);
      });
  }, [user.sub]);

  useEffect(() => {
    getListDocuments();
  }, [getListDocuments]);

  return (
    <div className="main-div list-cli">
      <Card>
        <Card.Body>
          <div className="both-side">
            <div className="title-text">Attachments</div>
            {/* <Button
              variant="link"
              className="modal-link"
              onClick={btnUpload("Upload")}
            >
              <Icon path={mdiFilePlus} size={0.8} />
              Upload Attachment
            </Button> */}
          </div>

          <Popup
            show={showUpload}
            size="lg"
            backdrop="static"
            keyboard={false}
            onHide={btnCloseUpload}
            title={modeModal.title}
            content={
              <>
                <Form
                  id="upload-att-form"
                  onSubmit={btnSaveUpload(selRowAttach.id)}
                >
                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Label className="col-3">File Name</Form.Label>
                      <div className="col-9" style={{ display: "inline-flex" }}>
                        {selRowAttach.file_name}
                      </div>
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Label className="col-3">
                        Upload Attachment
                      </Form.Label>
                      <div className="col-9" style={{ display: "inline-flex" }}>
                        <Form.Control
                          name="txtfile"
                          type="file"
                          isInvalid={!!errorForm.txtfile}
                        />
                      </div>
                    </Form.Group>
                  </Row>
                </Form>
              </>
            }
            button={
              <>
                <Button variant="secondary" onClick={btnCloseUpload}>
                  Cancel
                </Button>
                <Button type="submit" form="upload-att-form" variant="primary">
                  {modeModal.button}
                </Button>
              </>
            }
          />

            <CustomTable
              headers={headerAttachment}
              items={listAttachment}
              paginate={listAttachment.length}
              maxRows={10}
            ></CustomTable>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Attachment;
