import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Spinner } from 'react-bootstrap';

import moment from 'moment';

import Icon from '@mdi/react';
import { mdiAccountSearch, mdiMagnify, mdiFilter } from '@mdi/js';

import TextboxIcon from '../../../components/TextField/TextFieldIcon';
import CustomTable from '../../../components/Table/Table';
import CardInfo from "../../../components/Card/CardInfo";

import { storeTitle } from '../../../store/actions/headerTitleSlice';
import { storePage } from '../../../store/actions/paginationSlice';
import {
  getAllProposalList,
  setAllListProposal,
  setStoreProposalFilter,
  setProposalDetailApproval
} from '../../../store/actions/proposalSlice';
import { getClientView} from "../../../store/actions/clientSlice";

function ListProposal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [clicked, setClicked] = useState(false);

  const { proposal_all_list, proposal_temp_all_list, proposal_filter } = useSelector((state) => state.proposal);

  const btnCollapse = (index) => {
    if (clicked === index) {
      //if clicked is already active, then close
      return setClicked(null)
    }
    setClicked(index)
  };

  const headers = [
    {
      Header: 'No',
      accessor: (row) => `${row.index}`,
      maxWidth: 70,
      minWidth: 70,
      width: 70,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>
      },
    },
    {
      Header: 'Company Name',
      accessor: (row) => `${row.clients ? row.clients.name : ''}`,
      maxWidth: 400,
      minWidth: 100,
      width: 350,
      Cell: ({ row }) => {
        const data = row.original

        function viewProposal() {
            dispatch(setProposalDetailApproval({}));
            dispatch(getClientView({}));
            navigate("/clients/view-proposal-approval/" + data.client_id + "/" + data.id);
        };

        return (
          <div className="container-div list-cli">
            <Button
              variant="link"
              className="no-underline"
              onClick={viewProposal}
            >
              {data.clients ? data.clients.name : '-'}
            </Button>
          </div>
        )
      },
    },
    {
      Header: 'File Name',
      accessor: (row) => `${row.file_name}`,
      maxWidth: 400,
      minWidth: 100,
      width: 350,
      Cell: ({ row }) => {
        const data = row.original

        return <div>{data.file_name}</div>
      },
    },
    {
      Header: 'Job Title',
      accessor: (row) => `${row.job_title}`,
      maxWidth: 400,
      minWidth: 100,
      width: 350,
      Cell: ({ row }) => {
        const data = row.original

        return <div>{data.job_title}</div>
      },
    },
    {
      Header: 'Created Date',
      accessor: (row) => `${row.created_at}`,
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original

        return <div>{moment(data.created_at).format('DD MMMM YYYY')}</div>
      },
    },
    {
        Header: "Status",
        accessor: (row) => `${row.status}`,
        maxWidth: 250,
        minWidth: 100,
        width: 250,
        Cell: ({ row }) => {
          const data = row.original;
        
          if (data.status === "proposal"){
            return (
              <CardInfo
                cardTextSize="card_text--medium"
                cardTextStyle="card_text--primary--solid"
              >
                {data.status.replace(/_/g, " ")}
              </CardInfo>
            );
          }else{
            return (
                <CardInfo
                  cardTextSize="card_text--medium"
                  cardTextStyle="card_text--completed--solid"
                >
                  {data.status.replace(/_/g, " ")}
                </CardInfo>
              );
          }
        },
    },
  ]

  const handleSearch = (event) => {
    let searchVal = event.target.value.toLowerCase()

    dispatch(setStoreProposalFilter({ txtsearchpro: searchVal }));
    dispatch(storePage(0));
    dispatch(getAllProposalList(searchVal, proposal_filter.txtfilename, proposal_filter.txtjobtitle, proposal_filter.ddldate))

    let newList = [];

          if(searchVal !== "" && proposal_filter.txtfilename === "" && proposal_filter.txtjobtitle === "" && proposal_filter.ddldate === "" ){
        newList = proposal_temp_all_list.filter((pro) => {
          return pro.clients ? pro.clients.name.toLowerCase().includes(searchVal.toLowerCase()) : "";
        });
    }else if(searchVal !== "" && proposal_filter.txtfilename !== "" && proposal_filter.txtjobtitle === "" && proposal_filter.ddldate === "" ){

        newList = proposal_temp_all_list.filter((pro) => {
            let compName = "";
    
            if (pro.clients !== null) {
                compName = pro.clients.name;
            } else {
                compName = "";
            };
    
            return (
                compName.toLowerCase().includes(searchVal.toLowerCase()) && 
                pro.file_name.toLowerCase().includes(proposal_filter.txtfilename.toLowerCase())
            );
        });
    }else if(searchVal !== "" && proposal_filter.txtfilename !== "" && proposal_filter.txtjobtitle !== "" && proposal_filter.ddldate === "" ){

        newList = proposal_temp_all_list.filter((pro) => {
            let compName = "";
    
            if (pro.clients !== null) {
                compName = pro.clients.name;
            } else {
                compName = "";
            };
    
            return (
                compName.toLowerCase().includes(searchVal.toLowerCase()) && 
                pro.file_name.toLowerCase().includes(proposal_filter.txtfilename.toLowerCase()) && 
                pro.job_title.toLowerCase().includes(proposal_filter.txtjobtitle.toLowerCase())
            );
        });
    }
    // full filter
     else if(searchVal !== "" && proposal_filter.txtfilename !== "" && proposal_filter.txtjobtitle !== "" && proposal_filter.ddldate !== "" ){

        newList = proposal_temp_all_list.filter((pro) => {
            let compName = "";
    
            if (pro.clients !== null) {
                compName = pro.clients.name;
            } else {
                compName = "";
            };
    
            return (
                compName.toLowerCase().includes(searchVal.toLowerCase()) && 
                pro.file_name.toLowerCase().includes(proposal_filter.txtfilename.toLowerCase()) && 
                pro.job_title.toLowerCase().includes(proposal_filter.txtjobtitle.toLowerCase()) &&
                pro.created_at.substring(0, 10) >= proposal_filter.ddldate
            );
        });
    }else if(searchVal !== "" && proposal_filter.txtfilename !== "" && proposal_filter.txtjobtitle === "" && proposal_filter.ddldate !== "" ){

        newList = proposal_temp_all_list.filter((pro) => {
            let compName = "";
    
            if (pro.clients !== null) {
                compName = pro.clients.name;
            } else {
                compName = "";
            };
    
            return (
                compName.toLowerCase().includes(searchVal.toLowerCase()) && 
                pro.file_name.toLowerCase().includes(proposal_filter.txtfilename.toLowerCase()) && 
                pro.created_at.substring(0, 10) >= proposal_filter.ddldate
            );
        });
    }else if(searchVal !== "" && proposal_filter.txtfilename === "" && proposal_filter.txtjobtitle !== "" && proposal_filter.ddldate === "" ){

        newList = proposal_temp_all_list.filter((pro) => {
            let compName = "";
    
            if (pro.clients !== null) {
                compName = pro.clients.name;
            } else {
                compName = "";
            };
    
            return (
                compName.toLowerCase().includes(searchVal.toLowerCase()) && 
                pro.job_title.toLowerCase().includes(proposal_filter.txtjobtitle.toLowerCase())
            );
        });
    }else if(searchVal !== "" && proposal_filter.txtfilename === "" && proposal_filter.txtjobtitle !== "" && proposal_filter.ddldate !== "" ){

        newList = proposal_temp_all_list.filter((pro) => {
            let compName = "";
    
            if (pro.clients !== null) {
                compName = pro.clients.name;
            } else {
                compName = "";
            };
    
            return (
                compName.toLowerCase().includes(searchVal.toLowerCase()) && 
                pro.job_title.toLowerCase().includes(proposal_filter.txtjobtitle.toLowerCase()) &&
                pro.created_at.substring(0, 10) >= proposal_filter.ddldate
            );
        });
    }else if(searchVal !== "" && proposal_filter.txtfilename === "" && proposal_filter.txtjobtitle === "" && proposal_filter.ddldate !== "" ){

        newList = proposal_temp_all_list.filter((pro) => {
            let compName = "";
    
            if (pro.clients !== null) {
                compName = pro.clients.name;
            } else {
                compName = "";
            };
    
            return (
                compName.toLowerCase().includes(searchVal.toLowerCase()) && 
                pro.created_at.substring(0, 10) >= proposal_filter.ddldate
            );
        });
    }else if(searchVal === "" && proposal_filter.txtfilename !== "" && proposal_filter.txtjobtitle === "" && proposal_filter.ddldate === "" ){

        newList = proposal_temp_all_list.filter((pro) => {    
            return (
                pro.file_name.toLowerCase().includes(proposal_filter.txtfilename.toLowerCase())
            );
        });
    }else if(searchVal === "" && proposal_filter.txtfilename !== "" && proposal_filter.txtjobtitle !== "" && proposal_filter.ddldate === "" ){

        newList = proposal_temp_all_list.filter((pro) => {
            return (
                pro.file_name.toLowerCase().includes(proposal_filter.txtfilename.toLowerCase()) && 
                pro.job_title.toLowerCase().includes(proposal_filter.txtjobtitle.toLowerCase())
            );
        });
    }else if(searchVal === "" && proposal_filter.txtfilename !== "" && proposal_filter.txtjobtitle !== "" && proposal_filter.ddldate !== "" ){

        newList = proposal_temp_all_list.filter((pro) => {    
            return (
                pro.file_name.toLowerCase().includes(proposal_filter.txtfilename.toLowerCase()) && 
                pro.job_title.toLowerCase().includes(proposal_filter.txtjobtitle.toLowerCase()) &&
                pro.created_at.substring(0, 10) >= proposal_filter.ddldate
            );
        });
    }else if(searchVal === "" && proposal_filter.txtfilename !== "" && proposal_filter.txtjobtitle === "" && proposal_filter.ddldate !== "" ){

        newList = proposal_temp_all_list.filter((pro) => {
            return (
                pro.file_name.toLowerCase().includes(proposal_filter.txtfilename.toLowerCase()) && 
                pro.created_at.substring(0, 10) >= proposal_filter.ddldate
            );
        });
    }else if(searchVal === "" && proposal_filter.txtfilename === "" && proposal_filter.txtjobtitle !== "" && proposal_filter.ddldate === "" ){

        newList = proposal_temp_all_list.filter((pro) => {
            return (
                pro.job_title.toLowerCase().includes(proposal_filter.txtjobtitle.toLowerCase())
            );
        });
    }else if(searchVal === "" && proposal_filter.txtfilename === "" && proposal_filter.txtjobtitle !== "" && proposal_filter.ddldate !== "" ){

        newList = proposal_temp_all_list.filter((pro) => {   
            return ( 
                pro.job_title.toLowerCase().includes(proposal_filter.txtjobtitle.toLowerCase()) &&
                pro.created_at.substring(0, 10) >= proposal_filter.ddldate
            );
        });
    }else if(searchVal === "" && proposal_filter.txtfilename === "" && proposal_filter.txtjobtitle === "" && proposal_filter.ddldate !== "" ){

        newList = proposal_temp_all_list.filter((pro) => {
            return (
                pro.created_at.substring(0, 10) >= proposal_filter.ddldate
            );
        });
    }else{
        newList = proposal_temp_all_list;
    };

    dispatch(setAllListProposal(newList));
  };

  const btnFilter = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtFilter = Object.fromEntries(data.entries());

    dispatch(setStoreProposalFilter({txtfilename: dtFilter.txtfilename, txtjobtitle: dtFilter.txtjobtitle, ddldate: dtFilter.ddldate }));
    dispatch(storePage(0));
    dispatch(getAllProposalList(proposal_filter.txtsearchpro, dtFilter.txtfilename, dtFilter.txtjobtitle, dtFilter.ddldate))

    let newList = [];

          if(proposal_filter.txtsearchpro !== "" && dtFilter.txtfilename === "" && dtFilter.txtjobtitle === "" && dtFilter.ddldate === ""){
        newList = proposal_temp_all_list.filter((pro) => {
        return pro.clients ? pro.clients.name.toLowerCase().includes(proposal_filter.txtsearchpro.toLowerCase()) : "";
        });
    }else if(proposal_filter.txtsearchpro !== "" && dtFilter.txtfilename !== "" && dtFilter.txtjobtitle === "" && dtFilter.ddldate === "" ){

        newList = proposal_temp_all_list.filter((pro) => {
            let compName = "";
    
            if (pro.clients !== null) {
                compName = pro.clients.name;
            } else {
                compName = "";
            };
    
            return (
                compName.toLowerCase().includes(proposal_filter.txtsearchpro.toLowerCase()) && 
                pro.file_name.toLowerCase().includes(dtFilter.txtfilename.toLowerCase())
            );
        });
    }else if(proposal_filter.txtsearchpro !== "" && dtFilter.txtfilename !== "" && dtFilter.txtjobtitle !== "" && dtFilter.ddldate === "" ){

        newList = proposal_temp_all_list.filter((pro) => {
            let compName = "";
    
            if (pro.clients !== null) {
                compName = pro.clients.name;
            } else {
                compName = "";
            };
    
            return (
                compName.toLowerCase().includes(proposal_filter.txtsearchpro.toLowerCase()) && 
                pro.file_name.toLowerCase().includes(dtFilter.txtfilename.toLowerCase()) && 
                pro.job_title.toLowerCase().includes(dtFilter.txtjobtitle.toLowerCase())
            );
        });
    }
    // full filter
     else if(proposal_filter.txtsearchpro !== "" && dtFilter.txtfilename !== "" && dtFilter.txtjobtitle !== "" && dtFilter.ddldate !== "" ){

        newList = proposal_temp_all_list.filter((pro) => {
            let compName = "";
    
            if (pro.clients !== null) {
                compName = pro.clients.name;
            } else {
                compName = "";
            };
    
            return (
                compName.toLowerCase().includes(proposal_filter.txtsearchpro.toLowerCase()) && 
                pro.file_name.toLowerCase().includes(dtFilter.txtfilename.toLowerCase()) && 
                pro.job_title.toLowerCase().includes(dtFilter.txtjobtitle.toLowerCase()) &&
                pro.created_at.substring(0, 10) >= dtFilter.ddldate
            );
        });
    }else if(proposal_filter.txtsearchpro !== "" && dtFilter.txtfilename !== "" && dtFilter.txtjobtitle === "" && dtFilter.ddldate !== "" ){

        newList = proposal_temp_all_list.filter((pro) => {
            let compName = "";
    
            if (pro.clients !== null) {
                compName = pro.clients.name;
            } else {
                compName = "";
            };
    
            return (
                compName.toLowerCase().includes(proposal_filter.txtsearchpro.toLowerCase()) && 
                pro.file_name.toLowerCase().includes(dtFilter.txtfilename.toLowerCase()) && 
                pro.created_at.substring(0, 10) >= dtFilter.ddldate
            );
        });
    }else if(proposal_filter.txtsearchpro !== "" && dtFilter.txtfilename === "" && dtFilter.txtjobtitle !== "" && dtFilter.ddldate === "" ){

        newList = proposal_temp_all_list.filter((pro) => {
            let compName = "";
    
            if (pro.clients !== null) {
                compName = pro.clients.name;
            } else {
                compName = "";
            };
    
            return (
                compName.toLowerCase().includes(proposal_filter.txtsearchpro.toLowerCase()) && 
                pro.job_title.toLowerCase().includes(dtFilter.txtjobtitle.toLowerCase())
            );
        });
    }else if(proposal_filter.txtsearchpro !== "" && dtFilter.txtfilename === "" && dtFilter.txtjobtitle !== "" && dtFilter.ddldate !== "" ){

        newList = proposal_temp_all_list.filter((pro) => {
            let compName = "";
    
            if (pro.clients !== null) {
                compName = pro.clients.name;
            } else {
                compName = "";
            };
    
            return (
                compName.toLowerCase().includes(proposal_filter.txtsearchpro.toLowerCase()) && 
                pro.job_title.toLowerCase().includes(dtFilter.txtjobtitle.toLowerCase()) &&
                pro.created_at.substring(0, 10) >= dtFilter.ddldate
            );
        });
    }else if(proposal_filter.txtsearchpro !== "" && dtFilter.txtfilename === "" && dtFilter.txtjobtitle === "" && dtFilter.ddldate !== "" ){

        newList = proposal_temp_all_list.filter((pro) => {
            let compName = "";
    
            if (pro.clients !== null) {
                compName = pro.clients.name;
            } else {
                compName = "";
            };
    
            return (
                compName.toLowerCase().includes(proposal_filter.txtsearchpro.toLowerCase()) && 
                pro.created_at.substring(0, 10) >= dtFilter.ddldate
            );
        });
    }else if(proposal_filter.txtsearchpro === "" && dtFilter.txtfilename !== "" && dtFilter.txtjobtitle === "" && dtFilter.ddldate === "" ){

        newList = proposal_temp_all_list.filter((pro) => {
            return (
                pro.file_name.toLowerCase().includes(dtFilter.txtfilename.toLowerCase()) 
            );
        });
    }else if(proposal_filter.txtsearchpro === "" && dtFilter.txtfilename !== "" && dtFilter.txtjobtitle !== "" && dtFilter.ddldate === "" ){

        newList = proposal_temp_all_list.filter((pro) => {
            return (
                pro.file_name.toLowerCase().includes(dtFilter.txtfilename.toLowerCase()) && 
                pro.job_title.toLowerCase().includes(dtFilter.txtjobtitle.toLowerCase())
            );
        });
    }else if(proposal_filter.txtsearchpro === "" && dtFilter.txtfilename !== "" && dtFilter.txtjobtitle !== "" && dtFilter.ddldate !== "" ){

        newList = proposal_temp_all_list.filter((pro) => {   
            return (
                pro.file_name.toLowerCase().includes(dtFilter.txtfilename.toLowerCase()) && 
                pro.job_title.toLowerCase().includes(dtFilter.txtjobtitle.toLowerCase()) &&
                pro.created_at.substring(0, 10) >= dtFilter.ddldate
            );
        });
    }else if(proposal_filter.txtsearchpro === "" && dtFilter.txtfilename !== "" && dtFilter.txtjobtitle === "" && dtFilter.ddldate !== "" ){

        newList = proposal_temp_all_list.filter((pro) => {    
            return (
                pro.file_name.toLowerCase().includes(dtFilter.txtfilename.toLowerCase()) && 
                pro.created_at.substring(0, 10) >= dtFilter.ddldate
            );
        });
    }else if(proposal_filter.txtsearchpro === "" && dtFilter.txtfilename === "" && dtFilter.txtjobtitle !== "" && dtFilter.ddldate === "" ){

        newList = proposal_temp_all_list.filter((pro) => {
            return ( 
                pro.job_title.toLowerCase().includes(dtFilter.txtjobtitle.toLowerCase())
            );
        });
    }else if(proposal_filter.txtsearchpro === "" && dtFilter.txtfilename === "" && dtFilter.txtjobtitle !== "" && dtFilter.ddldate !== "" ){

        newList = proposal_temp_all_list.filter((pro) => {
            return (
                pro.job_title.toLowerCase().includes(dtFilter.txtjobtitle.toLowerCase()) &&
                pro.created_at.substring(0, 10) >= dtFilter.ddldate
            );
        });
    }else if(proposal_filter.txtsearchpro === "" && dtFilter.txtfilename === "" && dtFilter.txtjobtitle === "" && dtFilter.ddldate !== "" ){

        newList = proposal_temp_all_list.filter((pro) => {
            return (
                pro.created_at.substring(0, 10) >= dtFilter.ddldate
            );
        });
    }
    else{
        newList = proposal_temp_all_list;
    };

    dispatch(setAllListProposal(newList));

  };

  const btnReset = () => {
    document.getElementById("filter-proposal").reset();
    dispatch(
      setStoreProposalFilter({
        txtfilename: "",
        txtjobtitle: "",
        ddldate: "",
      })
    );
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(proposal_filter.txtfilename !== "" || proposal_filter.txtjobtitle !== "" || proposal_filter.ddldate !== ""){
      setClicked(true);
    }else{
      setClicked(false);
    }

    dispatch(getAllProposalList(proposal_filter.txtsearchpro, proposal_filter.txtfilename, proposal_filter.txtjobtitle, proposal_filter.ddldate));

    dispatch(storeTitle({ title: 'Proposal', icon: mdiAccountSearch }));

    setTimeout(() => setLoading(false), 2000);
  }, [dispatch])

  return (
    <>
      {proposal_temp_all_list.length === 0 ? 
        loading ? 
          <div id="main-overlay" className="main-overlay">
            <Spinner animation="border" variant="light" />
          </div> : ""
      :""}
      <div className="main-div list-cli">
        <div className="top-title-div cli-page">
          <div className="filter_container">
            <div className="filter_container_totalrow">
              <div className="form-container-default">
                <div className="total_client_text">
                  {proposal_all_list.length}
                </div>
                <div className="client_text">proposal</div>
              </div>
            </div>

            <div className="upper-divider"></div>

            <div className="filter_container_search">
              <div className="filter_container_search-1">
                <TextboxIcon
                  name="txtsearchpro"
                  type="text"
                  onChange={(event) => handleSearch(event)}
                  placeholder="Search by company name"
                  inputSize="input--medium"
                  icon={mdiMagnify}
                  value={proposal_filter.txtsearchpro}
                ></TextboxIcon>
              </div>
            </div>

            <div className="filter_container_typelist">
              <div className="form-container-filter-button">
                <Button onClick={() => btnCollapse(true)} variant="secondary">
                  <Icon path={mdiFilter} />
                  <div>Filter</div>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div id="demo" className={'collapses' + (clicked ? ' in' : '')}>
          <Form id="filter-proposal" onSubmit={btnFilter}>
            <div className="form-container-filter">
                <div className="form-container-filter-1">
                    <Form.Group>
                        <Form.Label>File Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="txtfilename"
                            defaultValue={proposal_filter.txtfilename}
                        />
                    </Form.Group>
                </div>
                <div className="form-container-filter-1">
                    <Form.Group>
                        <Form.Label>Job Title</Form.Label>
                        <Form.Control
                            type="text"
                            name="txtjobtitle"
                            // defaultValue={proposal_filter.txtjobtitle}
                        />
                    </Form.Group>
                </div>
                <div className="form-container-filter-1">
                    <Form.Group>
                        <Form.Label>From Created Date</Form.Label>
                        <Form.Control
                            type="date"
                            name="ddldate"
                            defaultValue={proposal_filter.ddldate}
                        />
                    </Form.Group>
                </div>
            </div>
          {/* <div className="form-container-filter">
              <div className="form-container-filter-1">
                <Form.Group>
                  <Form.Label>Assignment Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="txtassignname"
                    defaultValue={assignFilter.txtassignname}
                  />
                </Form.Group>
              </div>

              <div className="form-container-filter-1">
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    name="ddlStatus"
                    value={assignFilter.ddlStatus}
                    onChange={handleChangeDDL('status')}
                  >
                    <option value="">Select</option>
                    {statusAssignListFilter.map((assign, index) => (
                      <option key={index} value={assign.value}>
                        {assign.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
            </div> */}
            <div className="row-btn-bottom">
                <Button variant="secondary" onClick={btnReset}>Reset</Button>
                <Button variant="primary" type="submit" form="filter-proposal">
                Search
                </Button>
            </div>
          </Form>
        </div>

        <div className="container-div list-cli">
          <CustomTable
            headers={headers}
            items={proposal_all_list}
            paginate={proposal_all_list.length}
            maxRows={15}
            pindex={true}
          ></CustomTable>
        </div>
      </div>
    </>
  )
}

export default ListProposal
