import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Row, Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Api from "../../core/services/api.service";
import AuthAbilities from "../../pages/Admin/Auth/AuthAbilities";
import Swal from "sweetalert2";

import Icon from "@mdi/react";
import {
  mdiPlusCircle,
  mdiDotsHorizontal,
  mdiPencil,
  mdiDelete,
} from "@mdi/js";

import Modal from "../Popup/ModalPopup";
import Table from "../Table/Table";

export const TabPlacements = () => {
  const params = useParams();

  const abilities = AuthAbilities();

  const { usrProfile } = useSelector((state) => state.user);

  const [listFollowup, setListFollowup] = useState([]);

  const [followList, setFollowList] = useState([]);

  const [showFollow, setShowFollow] = useState(false);
  const [modeModal, setModeModal] = useState({
    title: null,
    button: null,
  });
  const [idFollow, setIdFollow] = useState("");
  const [errorForm, setErrorForm] = useState([]);

  const [folState] = useState({
    txtdatefollow: "",
    ddlfollowup: "",
    txtfollownote: "",
  });

  const [showNote, setShowNote] = useState(false);
  const [noteFollow, setNoteFollow] = useState("");

  const customSwal = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-okay",
      cancelButton: "btn btn-cancel",
      title: "swal-title",
      popup: "swal-popup",
    },
    buttonsStyling: false,
  });

  // Header table
  const headerFollowUp = [
    {
      Header: "No",
      maxWidth: 50,
      minWidth: 10,
      width: 40,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Follow-up",
      accessor: "follow_up",
      maxWidth: 400,
      minWidth: 100,
      width: 400,
    },
    {
      Header: "Follow-up Date",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        const date = new Date(data.follow_up_date);
        const longMonth = date.toLocaleString("en-us", { month: "long" });

        return (
          <div>
            {date.getDate()} {longMonth} {date.getFullYear()}
          </div>
        );
      },
    },
    {
      Header: "Follow-up By",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        let followBy =
          data.created_by_users.first_name +
          " " +
          data.created_by_users.last_name;

        return <div>{followBy}</div>;
      },
    },
    {
      Header: "Notes",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        let follownote = data.note;

        const btnShowNote = () => {
          setNoteFollow(follownote);
          setShowNote(true);
        };

        return (
          <>
            {follownote ? (
              <Button
                variant="link"
                className="reminder-btn set-rem"
                onClick={btnShowNote}
              >
                View Notes
              </Button>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      Header: "",
      accessor: "actions",
      maxWidth: 100,
      minWidth: 80,
      width: 80,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={btnEditFollow(data.id)}
                  hidden={!abilities.includes("edit follow-up")}
                >
                  <Icon path={mdiPencil} color="#467FD0" />
                  Edit Follow-up
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={btnDelFollow(data.id)}
                  hidden={!abilities.includes("delete follow-up")}
                >
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete Follow-up
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  // Checking Error Form Invoice
  const formErrorsFollow = (object) => {
    const newErrors = {};

    console.log("object", object);

    if (!object.ddlfollowup || object.ddlfollowup === "")
      newErrors.ddlfollowup = "Fill in the blank!";

    if (!object.txtdatefollow || object.txtdatefollow === "")
      newErrors.txtdatefollow = "Fill in the blank!";

    return newErrors;
  };

  function getListOption() {
    Api.get("option/list")
      .then((response) => {
        let listFollow = response.data.filter((grp) =>
          grp.group.includes("Followup")
        );

        let sortListFollow = listFollow.sort((a, b) => a.id - b.id);

        setFollowList(sortListFollow);
      })
      .catch((error) => {
        console.log("option/list", error);
      });
  }

  // Button Event
  const btnCloseNote = () => {
    setShowNote(false);
  };

  const btnDelFollow = (idfol) => () => {
    customSwal
      .fire({
        title: "Do you want to delete this follow-up?",
        text: "You won't be able to revert this!",
        icon: "warning",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("candidate/" + params.id + "/placement/followup/" + idfol)
            .then((response) => {
              getListFollowup();
              setShowFollow(false);

              customSwal.fire({
                title: "Deleted Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error delete followup", error);
            });
        }
      });
  };

  const btnCloseFollow = () => {
    setShowFollow(false);
  };

  const btnAddFollow = () => {
    folState.txtdatefollow = "";
    folState.ddlfollowup = "";
    folState.txtfollownote = "";

    setModeModal({
      title: "Add Follow-up",
      button: "Add",
    });
    setIdFollow("New");
    setErrorForm([]);
    setShowFollow(true);
  };

  const btnEditFollow = (idfol) => () => {
    console.log("idfol", idfol);

    const filteredFollow = listFollowup.filter((follow) => {
      return follow.id === idfol;
    });

    folState.txtdatefollow = filteredFollow[0].follow_up_date.substring(0, 10);
    folState.ddlfollowup = filteredFollow[0].follow_up;
    folState.txtfollownote = filteredFollow[0].note;

    setModeModal({
      title: "Edit Follow-up",
      button: "Update",
    });
    setErrorForm([]);
    setIdFollow(idfol);
    setShowFollow(true);
  };

  const btnSaveUpdateFollow = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtFollow = Object.fromEntries(data.entries());

    console.log("dtFollow", dtFollow);

    const newErrors = formErrorsFollow(dtFollow);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      if (idFollow === "New") {
        customSwal
          .fire({
            title: "Do you want to save new follow-up?",
            icon: "warning",
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post(
                "candidate/" +
                  params.id +
                  "/placement/" +
                  params.idpla +
                  "/create",
                {
                  follow_up: dtFollow.ddlfollowup,
                  follow_up_date: dtFollow.txtdatefollow,
                  note: dtFollow.txtfollownote,
                  created_by: Number(usrProfile.id),
                }
              )
                .then((response) => {
                  getListFollowup();
                  setShowFollow(false);

                  customSwal.fire({
                    title: "Follow-up Saved Successfully!",
                    icon: "success",
                    confirmButtonText: "Okay",
                  });
                })
                .catch((error) => {
                  console.log("error save followup", error);
                });
            }
          });
      } else {
        customSwal
          .fire({
            title: "Do you want to update follow-up?",
            icon: "warning",
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.patch(
                "candidate/" +
                  params.id +
                  "/placement/" +
                  params.idpla +
                  "/update/" +
                  idFollow,
                {
                  follow_up: dtFollow.ddlfollowup,
                  follow_up_date: dtFollow.txtdatefollow,
                  note: dtFollow.txtfollownote,
                }
              )
                .then((response) => {
                  getListFollowup();
                  setShowFollow(false);

                  customSwal.fire({
                    title: "Follow-up Updated Successfully!",
                    icon: "success",
                    confirmButtonText: "Okay",
                  });
                })
                .catch((error) => {
                  console.log("error update followup", error);
                });
            }
          });
      }
    }
  };

  // List Function
  const getListFollowup = useCallback(() => {
    Api.get(
      "candidate/" + params.id + "/placement/" + params.idpla + "/followUpList"
    )
      .then((response) => {
        let data = response.data;

        console.log("getListFollowup", data);
        if (data !== "no data") {
          setListFollowup(data);
        }
      })
      .catch((error) => {
        console.log("error getListFollowup", error);
      });
  }, [params]);

  useEffect(() => {
    getListFollowup();
    getListOption();
  }, [getListFollowup]);

  return (
    <>
      <div className="tab-pane-div">
        <div className="row-div">
          <div className="left-div"></div>
          <div className="right-div">
            <Button
              variant="link"
              className="modal-link"
              onClick={btnAddFollow}
              hidden={!abilities.includes("add follow-up")}
            >
              <Icon path={mdiPlusCircle} />
              Add Follow-up
            </Button>
          </div>
        </div>

        <Table headers={headerFollowUp} items={listFollowup}></Table>
      </div>

      {/* Modal add followup */}
      <Modal
        show={showFollow}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseFollow}
        title={modeModal.title}
        content={
          <>
            <Form id="follow-form" onSubmit={btnSaveUpdateFollow}>
              <Row>
                <Form.Group className="mb-3 col-6">
                  <Form.Label>Follow-up</Form.Label>
                  <Form.Select
                    name="ddlfollowup"
                    isInvalid={!!errorForm.ddlfollowup}
                    defaultValue={folState.ddlfollowup}
                  >
                    <option value="">Select</option>
                    {followList.map((fol, index) => (
                      <option key={index} value={fol.value}>
                        {fol.value}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errorForm.ddlfollowup}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3 col-6">
                  <Form.Label>Follow-up Date</Form.Label>
                  <Form.Control
                    name="txtdatefollow"
                    type="date"
                    isInvalid={!!errorForm.txtdatefollow}
                    defaultValue={folState.txtdatefollow}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorForm.txtdatefollow}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Form.Group className="mb-3">
                <Form.Label>Follow-up notes</Form.Label>
                <Form.Control
                  name="txtfollownote"
                  as="textarea"
                  rows={5}
                  defaultValue={folState.txtfollownote}
                />
              </Form.Group>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseFollow}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" form="follow-form">
              {modeModal.button}
            </Button>
          </>
        }
      />

      {/* Modal view notes */}
      <Modal
        show={showNote}
        size="md"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseNote}
        title="Notes"
        content={
          <>
            <Form.Group className="mb-3">
              <Form.Label>Follow-up notes</Form.Label>
              <Form.Control
                name="txtfollownote"
                as="textarea"
                rows={5}
                defaultValue={noteFollow}
                readOnly
              />
            </Form.Group>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseNote}>
              Close
            </Button>
          </>
        }
      />
    </>
  );
};
