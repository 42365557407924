import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import RichTextEditor from "../../../components/RichTextArea/RichTextEditor";

const ComposeEmail = () => {
  return (
    <div className="main-div">
      <div className="bg-white-container">
        <Form>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={2}>
              To
            </Form.Label>
            <Col>
              <Form.Control type="text" placeholder="Enter recipient email address" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={2}>
              CC to
            </Form.Label>
            <Col>
              <Form.Control type="text" placeholder="Enter CC email address" />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={2}>
              Bcc to
            </Form.Label>
            <Col>
              <Form.Control type="text" placeholder="Enter Bcc email address" />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={2}>
              Subject
            </Form.Label>
            <Col>
              <Form.Control type="text" placeholder="Enter subject of email" />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={2}>
              Content
            </Form.Label>
            <Col>
              <RichTextEditor />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={2}>
              Attachment
            </Form.Label>
            <Col>
              <Form.Control type="file" />
            </Col>
          </Form.Group>
        </Form>

        <div className="row-btn-center">
          <Button variant="secondary">Cancel</Button>
          <Button variant="primary">Send Email</Button>
        </div>
      </div>
    </div>
  );
};

export default ComposeEmail;
