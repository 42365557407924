import {createSlice} from '@reduxjs/toolkit';
import Api from '../../core/services/api.service';

export const companySlice = createSlice({
    name: "company",
    initialState: {
        dataComp: {},
        companyNonParent: [],
    },
    reducers: {
        // addTodo: (state, action) => {
        //     state.data.push(action.payload);
        // },
        getTodo: (state, action) => {
            state.dataComp = action.payload;
        },
        getCompanyNonParent: (state, action) => {
          state.companyNonParent = action.payload;
        },
    }
});

export const getCompanySelected = (id) => async (dispatch) => {
    try {
      const response = await Api.get("client/" + id + "/view");
      // console.log("res", response)
      dispatch(getTodo(response.data));
    } catch (err) {
      throw new Error(err);
    }
};

export const getListCompanyNonParent = (id) => async (dispatch) => {
  try {
    const response = await Api.get("client/subsidiaries/listNonParentCompany");
    
    let listSubsidiaries = response.data.filter(
      (subs) => subs.id !== parseInt(id)
    );

    let sortListSubsidiaries = listSubsidiaries.sort(function(a, b) {
      return a.name.localeCompare(b.name);
    });

    dispatch(getCompanyNonParent(sortListSubsidiaries));
  } catch (err) {
    throw new Error(err);
  }
};
  
//   export const addTodoAsync = (data) => async (dispatch) => {
//     try {
//       // console.log(data);
//       const response = await axios.post(API_URL, data);
//       // console.log(response);
//       dispatch(addTodo(response.data));
//     } catch (err) {
//       throw new Error(err);
//     }
//   };
  
  export const { addTodo, getTodo, getCompanyNonParent } = companySlice.actions;
  export const showTodo = (state) => state.todo.data;
  export default companySlice.reducer;

  // const initialState = {
//     menuCrumb:null
// }

// export const fetchAllPostsAsync = createAsyncThunk(
//     // "posts/fetchAllPostsAsync",
//     async (_, thunkAPI) => {
//       try {
//         return await axios.get("http://localhost:3333/api/client/3/view");
//       } catch (error) {
//         throw thunkAPI.rejectWithValue({ error: error.message });
//       }
//     }
// );
