import React from "react";
import LoaderImage from "../../assets/logo-gif.gif";
import "./loader.css";

function loader() {
  // useEffect(() => {
  //   const timer = setTimeout(() => console.log('Initial timeout!'), 1000);
  // }, []);

  return (
    <div className="loader-container">
      <img src={LoaderImage} alt="Load Page" width={150} height={150} />
      {/* <Spinner variant="info" animation="border"></Spinner> */}
    </div>
  );
}

export function MiniLoader() {
  return (
    <div className="mini-loader-container">
      <img src={LoaderImage} alt="Load Page" width={70} height={70} />
      {/* <Spinner variant="info" animation="border"></Spinner> */}
    </div>
  );
}

export default loader;
