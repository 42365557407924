import {createSlice} from '@reduxjs/toolkit';
import Api from '../../core/services/api.service';


const initialState = {
    formRegister: {
        salutation: null,
        first_name: null,
        last_name: null,
        mobile_no_code: null,
        mobile_no: null,
        citizenship_main: null,
        languages: [],
        job_title: null,
        company_name: null,
        comp_email: null,
        direct_line_no: null,
        password: "",
        validPassword: false,
    },
    textSuccess: {
        success: false
    }
}

const registerSlice = createSlice({
    name: 'register',
    initialState,
    reducers:{
        storeRegister: (state, action) => {
            state.formRegister = { ...state.formRegister, ...action.payload };
        },
        storeSuccess: (state, action) => {
            state.textSuccess = { ...state.textSuccess, ...action.payload };
        },
    },
})

export const createClientRegister = (data) => async (dispatch) => {
    try {
        // console.log(data);
        await Api.post("client/register", data);

       
    } catch (err) {
        throw new Error(err);
    }
};

const {reducer, actions} = registerSlice;

export const { storeRegister, storeSuccess } = actions;

export default reducer;