import {createSlice} from '@reduxjs/toolkit';
import Api from '../../core/services/api.service';

export const clientSlice = createSlice({
    name: "client",
    initialState: {
        clientList: [],
        clientActiveList: [],
        clientDetails: {},
        clientDetailsConsultant: "",
        clientDetailsIndustry: [],
        clientDetailsFile: [],
        clientStockExchange: {},
        clientListStaff: [],
        clientListCandidate: [],
        clientListMeeting: [],

        clientListAct: [],
        clientAllListAct: [],
        clientTempActData: [],
        clientTempActFilData: [],
        clientActFilter: {
          txtsearchactive: "",
          ddlCountry: "",
          ddlConsultant: "",
          ddlStatus: "",
        },

        clientListPot: [],
        clientAllListPot: [],
        clientPotFilter: {
          txtsearchpot: "",
          ddlCountry: "",
          ddlConsultant: "",
          ddlStatus: "",
        },

        clientListAll: [],
        clientAllListAll: [],
        clientTempAllData: [],
        clientTempAllFilData: [],
        clientAllFilter: {
          txtsearchclient: "",
          ddlCountry: "",
          txtgenphone: "",
          ddlStatus: "",
        },
    },
    reducers: {
        getClientList: (state, action) => {
            state.clientList = action.payload;
        },
        getClientActiveList: (state, action) => {
          state.clientActiveList = action.payload;
        },
        getClientView: (state, action) => {
            state.clientDetails = action.payload;
        },
        getClientViewConsultant: (state, action) => {
            state.clientDetailsConsultant = action.payload;
        },
        getClientViewIndustry: (state, action) => {
            state.clientDetailsIndustry = action.payload;
        },
        getClientFile: (state, action) => {
          state.clientDetailsFile = action.payload;
        },
        getClientSE: (state, action) => {
          state.clientStockExchange = action.payload;
        },
        getClientListMeeting: (state, action) => {
          state.clientListMeeting = action.payload;
        },
        getListClientStaff: (state, action) => {
          state.clientListStaff = action.payload;
        },
        getListClientCandidate: (state, action) => {
          state.clientListCandidate = action.payload;
        },

        // Active Client
        setListActiveClient: (state, action) => {
          state.clientListAct = action.payload;
        },
        setAllListActiveClient: (state, action) => {
          state.clientAllListAct = action.payload;
        },
        setTempClientActData: (state, action) => {
          state.clientTempActData = action.payload;
        },
        setTempClientActFilData: (state, action) => {
          state.clientTempActFilData = action.payload;
        },
        setStoreActFilter: (state, action) => {
          state.clientActFilter = { ...state.clientActFilter, ...action.payload };
        },

        // Potential Client 
        setListPotentialClient: (state, action) => {
          state.clientListPot = action.payload;
        },
        setAllListPotentialClient: (state, action) => {
          state.clientAllListPot = action.payload;
        },
        setStorePotFilter: (state, action) => {
          state.clientPotFilter = { ...state.clientPotFilter, ...action.payload };
        },

        // All Client 
        setListAllCompanies: (state, action) => {
          state.clientListAll = action.payload;
        },
        setAllListAllCompanies: (state, action) => {
          state.clientAllListAll = action.payload;
        },
        setTempClientAllData: (state, action) => {
          state.clientTempAllData = action.payload;
        },
        setTempClientAllFilData: (state, action) => {
          state.clientTempAllFilData = action.payload;
        },
        setStoreAllFilter: (state, action) => {
          state.clientAllFilter = { ...state.clientAllFilter, ...action.payload };
        },
        
    },
});


export const getListClient = () => async (dispatch, rejectWithValue) => {
    try {
        const response = await Api.get("client");

        // let listPotential = response.data.filter((pot) => pot.is_potential === true); 
        // console.log("listPotential", listPotential)
        dispatch(getClientList(response.data));

        let listActive = response.data.filter((act) => act.is_potential === false && act.status !== null);

        let clientList = [];

        listActive.map((cli) => {
          clientList.push({
            value: cli.id,
            label: cli.name,
          });
        });

        dispatch(getClientActiveList(clientList))
        // console.log("sss")

    } catch (err) {
      throw rejectWithValue(err.message);
    }
};


export const getClientDetails = (id) => async (dispatch) => {
    try {
      const response = await Api.get("client/" + id + "/view");
      dispatch(getClientView(response.data));

      var array = response.data.client_has_user.map((obj, index) => {
        return obj.user.first_name + " " + obj.user.last_name + ",";
      });
      
      var cons_name = array.join(" ");

      dispatch(getClientViewConsultant(cons_name ? cons_name.slice(0, -1) : "-"));
      dispatch(getClientViewIndustry(response.data.industry_detail));
      dispatch(getClientFile(response.data.client_documents));
      dispatch(getClientSE(response.data.stock_options));

    } catch (err) {
      throw new Error(err);
    }
};

export const getUpdateClientDetails = (id, data) => async (dispatch) => {
    try {
      await Api.patch("client/" + id, data);

      const response2 = await Api.get("client/" + id + "/view");

      var array = response2.data.client_has_user.map((obj, index) => {
        return obj.user.first_name + " " + obj.user.last_name + ",";
      });
      
      var cons_name = array.join(" ");

      dispatch(getClientViewConsultant(cons_name ? cons_name.slice(0, -1) : "-"));
      dispatch(getClientViewIndustry(response2.data.industry_detail));
      dispatch(getClientSE(response2.data.stock_options));

      dispatch(getClientView(response2.data));
    } catch (err) {
        throw new Error(err);
    }
};

export const getClientMeeting = (id) => async (dispatch, rejectWithValue) => {
  try {
    const response = await Api.get("client/" + id + "/meeting");


    dispatch(getClientListMeeting(response.data));

  } catch (err) {
    throw rejectWithValue(err.message);
  }
};

export const getClientStaff = (id) => async (dispatch, rejectWithValue) => {
  try {
    const response = await Api.get("client/" + id + "/staff/listStaff");

    let listClientActive = response.data.filter((cli) => cli.status === "client"); 

    dispatch(getListClientStaff(listClientActive));

  } catch (err) {
    throw rejectWithValue(err.message);
  }
};

export const getClientCandidate = (id, idassign) => async (dispatch) => {
  try {
    const response = await Api.get("client/" + id + "/assignment/" + idassign + "/candidate/all");

    dispatch(getListClientCandidate(response.data));

  } catch (err) {
      throw new Error(err);
  }
};

export const getListClientActive = (cliName, cliCountry, cliConsultant, cliStatus) => async (dispatch, rejectWithValue) => {
  try {
      const response = await Api.get("client");

      let listActive = response.data.filter((act) => act.is_potential === false && act.status !== null && act.status !== "closed");

      let newList = [];
      let idConsult = Number(cliConsultant);
      
            if (cliName !== "" && cliCountry === "" && cliConsultant === "" && cliStatus === ""){
        newList = listActive.filter((client) => {
          return client.name.toLowerCase().includes(cliName.toLowerCase());
        });

        dispatch(setAllListActiveClient(newList));
      }else if (cliName !== "" && cliCountry !== "" && cliConsultant === "" && cliStatus === ""){
        newList = listActive.filter((client) => {
          let cntry = "";
  
          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          }
  
          return (
            client.name.toLowerCase().includes(cliName.toLowerCase()) &&
            cntry.includes(cliCountry)
          );
        });

        dispatch(setAllListActiveClient(newList));
      }else if (cliName !== "" && cliCountry !== "" && cliConsultant !== "" && cliStatus === ""){
        newList = listActive.filter((client) => {
          let cntry = "";
  
          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          }
  
          return (
            client.name.toLowerCase().includes(cliName.toLowerCase()) &&
            cntry.includes(cliCountry) &&
            client.consultant_name.find((e) => e === idConsult)
          );
        });

        dispatch(setAllListActiveClient(newList));
      }else if (cliName !== "" && cliCountry !== "" && cliConsultant !== "" && cliStatus !== ""){
        newList = listActive.filter((client) => {
          let cntry = "";
  
          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          }
  
          return (
            client.name.toLowerCase().includes(cliName.toLowerCase()) &&
            cntry.includes(cliCountry) &&
            client.consultant_name.find((e) => e === idConsult) &&
            client.status.toLowerCase().includes(cliStatus)
          );
        });

        dispatch(setAllListActiveClient(newList));
      }else if (cliName !== "" && cliCountry !== "" && cliConsultant === "" && cliStatus !== ""){
        newList = listActive.filter((client) => {
          let cntry = "";
  
          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          }
  
          return (
            client.name.toLowerCase().includes(cliName.toLowerCase()) &&
            cntry.includes(cliCountry) &&
            client.status.toLowerCase().includes(cliStatus)
          );
        });
        dispatch(setAllListActiveClient(newList));
      }else if (cliName !== "" && cliCountry === "" && cliConsultant !== "" && cliStatus === ""){
        newList = listActive.filter((client) => {
          return (
            client.name.toLowerCase().includes(cliName.toLowerCase()) &&
            client.consultant_name.find((e) => e === idConsult)
          );
        });

        dispatch(setAllListActiveClient(newList));
      }else if (cliName !== "" && cliCountry === "" && cliConsultant !== "" && cliStatus !== ""){
        newList = listActive.filter((client) => {
          return (
            client.name.toLowerCase().includes(cliName.toLowerCase()) &&
            client.consultant_name.find((e) => e === idConsult) &&
            client.status.toLowerCase().includes(cliStatus)
          );
        });

        dispatch(setAllListActiveClient(newList));
      }else if (cliName !== "" && cliCountry === "" && cliConsultant === "" && cliStatus !== ""){
        newList = listActive.filter((client) => {
          return (
            client.name.toLowerCase().includes(cliName.toLowerCase()) &&
            client.status.toLowerCase().includes(cliStatus)
          );
        });

        dispatch(setAllListActiveClient(newList));
      }else if (cliName === "" && cliCountry !== "" && cliConsultant === "" && cliStatus === ""){
        newList = listActive.filter((client) => {
          let cntry = "";
  
          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          }
  
          return (
            cntry.includes(cliCountry) 
          );
        });

        dispatch(setAllListActiveClient(newList));
      }else if (cliName === "" && cliCountry !== "" && cliConsultant !== "" && cliStatus === ""){
        newList = listActive.filter((client) => {
          let cntry = "";
  
          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          }
  
          return (
            cntry.includes(cliCountry) &&
            client.consultant_name.find((e) => e === idConsult)
          );
        });

        dispatch(setAllListActiveClient(newList));
      }else if (cliName === "" && cliCountry !== "" && cliConsultant !== "" && cliStatus !== ""){
        newList = listActive.filter((client) => {
          let cntry = "";
  
          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          }
  
          return (
            cntry.includes(cliCountry) &&
            client.consultant_name.find((e) => e === idConsult) &&
            client.status.toLowerCase().includes(cliStatus)
          );
        });

        dispatch(setAllListActiveClient(newList));
      }else if (cliName === "" && cliCountry !== "" && cliConsultant === "" && cliStatus !== ""){
        newList = listActive.filter((client) => {
          let cntry = "";
  
          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          }
  
          return (
            cntry.includes(cliCountry) &&
            client.status.toLowerCase().includes(cliStatus)
          );
        });

        dispatch(setAllListActiveClient(newList));
      }else if (cliName === "" && cliCountry === "" && cliConsultant !== "" && cliStatus === ""){
        newList = listActive.filter((client) => {
          return (
            client.consultant_name.find((e) => e === idConsult)
          );
        });

        dispatch(setAllListActiveClient(newList));
      }else if (cliName === "" && cliCountry === "" && cliConsultant !== "" && cliStatus !== ""){
        newList = listActive.filter((client) => {
          return (
            client.consultant_name.find((e) => e === idConsult) &&
            client.status.toLowerCase().includes(cliStatus)
          );
        });
        dispatch(setAllListActiveClient(newList));
      }else if (cliName === "" && cliCountry === "" && cliConsultant === "" && cliStatus !== ""){
        newList = listActive.filter((client) => {
          return (
            client.status.toLowerCase().includes(cliStatus)
          );
        });
        dispatch(setAllListActiveClient(newList));
      }else{
        dispatch(setListActiveClient(listActive));
        dispatch(setAllListActiveClient(listActive));
      }

      
      // console.log("sss")

  } catch (err) {
    throw rejectWithValue(err.message);
  }
};

export const getListClientPotential = (cliName, cliCountry, cliConsultant, cliStatus) => async (dispatch, rejectWithValue) => {
  try {
      const response = await Api.get("client");

      let listPotential = response.data.filter((act) => act.is_potential === true && act.status !== null && act.status !== "closed");

      let newList = [];
      let idConsult = Number(cliConsultant);
      
            if (cliName !== "" && cliCountry === "" && cliConsultant === "" && cliStatus === ""){
        newList = listPotential.filter((client) => {
          return client.name.toLowerCase().includes(cliName.toLowerCase());
        });

        dispatch(setAllListPotentialClient(newList));
      }else if (cliName !== "" && cliCountry !== "" && cliConsultant === "" && cliStatus === ""){
        newList = listPotential.filter((client) => {
          let cntry = "";
  
          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          }
  
          return (
            client.name.toLowerCase().includes(cliName.toLowerCase()) &&
            cntry.includes(cliCountry)
          );
        });

        dispatch(setAllListPotentialClient(newList));
      }else if (cliName !== "" && cliCountry !== "" && cliConsultant !== "" && cliStatus === ""){
        newList = listPotential.filter((client) => {
          let cntry = "";
  
          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          }
  
          return (
            client.name.toLowerCase().includes(cliName.toLowerCase()) &&
            cntry.includes(cliCountry) &&
            client.consultant_name.find((e) => e === idConsult)
          );
        });

        dispatch(setAllListPotentialClient(newList));
      }else if (cliName !== "" && cliCountry !== "" && cliConsultant !== "" && cliStatus !== ""){
        newList = listPotential.filter((client) => {
          let cntry = "";
  
          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          }
  
          return (
            client.name.toLowerCase().includes(cliName.toLowerCase()) &&
            cntry.includes(cliCountry) &&
            client.consultant_name.find((e) => e === idConsult) &&
            client.status.toLowerCase().includes(cliStatus)
          );
        });

        dispatch(setAllListPotentialClient(newList));
      }else if (cliName !== "" && cliCountry !== "" && cliConsultant === "" && cliStatus !== ""){
        newList = listPotential.filter((client) => {
          let cntry = "";
  
          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          }
  
          return (
            client.name.toLowerCase().includes(cliName.toLowerCase()) &&
            cntry.includes(cliCountry) &&
            client.status.toLowerCase().includes(cliStatus)
          );
        });
        dispatch(setAllListPotentialClient(newList));
      }else if (cliName !== "" && cliCountry === "" && cliConsultant !== "" && cliStatus === ""){
        newList = listPotential.filter((client) => {
          return (
            client.name.toLowerCase().includes(cliName.toLowerCase()) &&
            client.consultant_name.find((e) => e === idConsult)
          );
        });

        dispatch(setAllListPotentialClient(newList));
      }else if (cliName !== "" && cliCountry === "" && cliConsultant !== "" && cliStatus !== ""){
        newList = listPotential.filter((client) => {
          return (
            client.name.toLowerCase().includes(cliName.toLowerCase()) &&
            client.consultant_name.find((e) => e === idConsult) &&
            client.status.toLowerCase().includes(cliStatus)
          );
        });

        dispatch(setAllListPotentialClient(newList));
      }else if (cliName !== "" && cliCountry === "" && cliConsultant === "" && cliStatus !== ""){
        newList = listPotential.filter((client) => {
          return (
            client.name.toLowerCase().includes(cliName.toLowerCase()) &&
            client.status.toLowerCase().includes(cliStatus)
          );
        });

        dispatch(setAllListPotentialClient(newList));
      }else if (cliName === "" && cliCountry !== "" && cliConsultant === "" && cliStatus === ""){
        newList = listPotential.filter((client) => {
          let cntry = "";
  
          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          }
  
          return (
            cntry.includes(cliCountry) 
          );
        });

        dispatch(setAllListPotentialClient(newList));
      }else if (cliName === "" && cliCountry !== "" && cliConsultant !== "" && cliStatus === ""){
        newList = listPotential.filter((client) => {
          let cntry = "";
  
          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          }
  
          return (
            cntry.includes(cliCountry) &&
            client.consultant_name.find((e) => e === idConsult)
          );
        });

        dispatch(setAllListPotentialClient(newList));
      }else if (cliName === "" && cliCountry !== "" && cliConsultant !== "" && cliStatus !== ""){
        newList = listPotential.filter((client) => {
          let cntry = "";
  
          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          }
  
          return (
            cntry.includes(cliCountry) &&
            client.consultant_name.find((e) => e === idConsult) &&
            client.status.toLowerCase().includes(cliStatus)
          );
        });

        dispatch(setAllListPotentialClient(newList));
      }else if (cliName === "" && cliCountry !== "" && cliConsultant === "" && cliStatus !== ""){
        newList = listPotential.filter((client) => {
          let cntry = "";
  
          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          }
  
          return (
            cntry.includes(cliCountry) &&
            client.status.toLowerCase().includes(cliStatus)
          );
        });

        dispatch(setAllListPotentialClient(newList));
      }else if (cliName === "" && cliCountry === "" && cliConsultant !== "" && cliStatus === ""){
        newList = listPotential.filter((client) => {
          return (
            client.consultant_name.find((e) => e === idConsult)
          );
        });

        dispatch(setAllListPotentialClient(newList));
      }else if (cliName === "" && cliCountry === "" && cliConsultant !== "" && cliStatus !== ""){
        newList = listPotential.filter((client) => {
          return (
            client.consultant_name.find((e) => e === idConsult) &&
            client.status.toLowerCase().includes(cliStatus)
          );
        });
        dispatch(setAllListPotentialClient(newList));
      }else if (cliName === "" && cliCountry === "" && cliConsultant === "" && cliStatus !== ""){
        newList = listPotential.filter((client) => {
          return (
            client.status.toLowerCase().includes(cliStatus)
          );
        });
        dispatch(setAllListPotentialClient(newList));
      }else{
        dispatch(setListPotentialClient(listPotential));
        dispatch(setAllListPotentialClient(listPotential));
      }

      
      // console.log("sss")

  } catch (err) {
    throw rejectWithValue(err.message);
  }
};

export const getListAllCompanies = (cliName, cliCountry, cliGenPhone, cliStatus) => async (dispatch, rejectWithValue) => {
  try {
      const response = await Api.get("client");

      let data_array = response.data;
      let newList = [];

      if (cliName !== "" && cliCountry === "" && cliGenPhone === "" && cliStatus === "") {
        newList = data_array.filter((client) => {
          return client.name.toLowerCase().includes(cliName.toLowerCase());
        });

        dispatch(setAllListAllCompanies(newList));
      }else if (cliName !== "" && cliCountry !== "" && cliGenPhone === "" && cliStatus === "") {
        newList = data_array.filter((client) => {
          return client.name.toLowerCase().includes(cliName.toLowerCase()) && client.options ? client.options.value.toLowerCase().includes(cliCountry.toLowerCase()) : "";
        });

        dispatch(setAllListAllCompanies(newList));
      }else if (cliName !== "" && cliCountry !== "" && cliGenPhone !== "" && cliStatus === "") {
        newList = data_array.filter((client) => {
          let cntry = "";
  
          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          }
  
          return (
            client.name.toLowerCase().includes(cliName.toLowerCase()) && 
            cntry.includes(cliCountry) && 
            client.general_telephone ? client.general_telephone.includes(cliGenPhone) : ""
          );
        });

        dispatch(setAllListAllCompanies(newList));
      }else if (cliName !== "" && cliCountry !== "" && cliGenPhone !== "" && cliStatus !== "") {
        if(cliStatus === "active"){
          newList = data_array.filter((client) => {
            let cntry = "";
            let phone = "";
  
            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };
  
            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };
  
            return (
              client.name.toLowerCase().includes(cliName.toLowerCase()) &&
              cntry.includes(cliCountry) && 
              phone.includes(cliGenPhone) &&
              client.is_potential === false && 
              (client.status !== null && client.status !== "closed")
            );
          });
        }else if(cliStatus === "potential"){
          newList = data_array.filter((client) => {
            let cntry = "";
            let phone = "";
  
            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };
  
            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };
  
            return (
              client.name.toLowerCase().includes(cliName.toLowerCase()) &&
              cntry.includes(cliCountry) && 
              phone.includes(cliGenPhone) &&
              client.is_potential === true && 
              (client.status !== null && client.status !== "closed")
            );
          });
  
        }else if(cliStatus === "closed"){
          newList = data_array.filter((client) => {
            let cntry = "";
            let phone = "";
  
            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };
  
            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };
  
            return (
              client.name.toLowerCase().includes(cliName.toLowerCase()) &&
              cntry.includes(cliCountry) && 
              phone.includes(cliGenPhone) &&
              client.is_potential === false && 
              client.status === "closed"
            );
          });
        }else{
          newList = data_array.filter((client) => {
            let cntry = "";
            let phone = "";
  
            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };
  
            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };
  
            return (
              client.name.toLowerCase().includes(cliName.toLowerCase()) &&
              cntry.includes(cliCountry) && 
              phone.includes(cliGenPhone) &&
              client.is_potential === false && 
              (client.status === null && client.status !== "closed")
            );
          });
        }

        dispatch(setAllListAllCompanies(newList));
      }else if (cliName !== "" && cliCountry !== "" && cliGenPhone === "" && cliStatus !== "") {
        if(cliStatus === "active"){
          newList = data_array.filter((client) => {
            let cntry = "";
  
            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };
  
            return (
              client.name.toLowerCase().includes(cliName.toLowerCase()) &&
              cntry.includes(cliCountry) &&
              client.is_potential === false && 
              (client.status !== null && client.status !== "closed")
            );
          });
        }else if(cliStatus === "potential"){
          newList = data_array.filter((client) => {
            let cntry = "";
  
            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };
  
            return (
              cntry.includes(cliCountry) && 
              client.name.toLowerCase().includes(cliName.toLowerCase()) &&
              client.is_potential === true && 
              (client.status !== null && client.status !== "closed")
            );
          });
  
        }else if(cliStatus === "closed"){
          newList = data_array.filter((client) => {
            let cntry = "";
  
            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };
  
            return (
              cntry.includes(cliCountry) && 
              client.name.toLowerCase().includes(cliName.toLowerCase()) &&
              client.is_potential === false && 
              client.status === "closed"
            );
          });
        }else{
          newList = data_array.filter((client) => {
            let cntry = "";
  
            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };
  
            return (
              client.name.toLowerCase().includes(cliName.toLowerCase()) &&
              cntry.includes(cliCountry) && 
              client.is_potential === false && 
              (client.status === null && client.status !== "closed")
            );
          });
        }

        dispatch(setAllListAllCompanies(newList));
      }else if (cliName !== "" && cliCountry === "" && cliGenPhone !== "" && cliStatus === "") {
        newList = data_array.filter((client) => {
          return (
            client.name.toLowerCase().includes(cliName.toLowerCase()) && 
            client.general_telephone ? client.general_telephone.includes(cliGenPhone) : ""
          );
        });

        dispatch(setAllListAllCompanies(newList));
      }else if (cliName !== "" && cliCountry === "" && cliGenPhone !== "" && cliStatus !== "") {
        if(cliStatus === "active"){
          newList = data_array.filter((client) => {
            let phone = "";
  
            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };
  
            return (
              client.name.toLowerCase().includes(cliName.toLowerCase()) && 
              phone.includes(cliGenPhone) &&
              client.is_potential === false && 
              (client.status !== null && client.status !== "closed")
            );
          });
        }else if(cliStatus === "potential"){
          newList = data_array.filter((client) => {
            let phone = "";
  
            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };
  
            return (
              client.name.toLowerCase().includes(cliName.toLowerCase()) &&
              phone.includes(cliGenPhone) &&
              client.is_potential === true && 
              (client.status !== null && client.status !== "closed")
            );
          });
  
        }else if(cliStatus === "closed"){
          newList = data_array.filter((client) => {
            let phone = "";
  
            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };
  
            return (
              client.name.toLowerCase().includes(cliName.toLowerCase()) && 
              phone.includes(cliGenPhone) &&
              client.is_potential === false && 
              client.status === "closed"
            );
          });
        }else{
          newList = data_array.filter((client) => {
            let phone = "";
  
            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };
  
            return (
              client.name.toLowerCase().includes(cliName.toLowerCase()) && 
              phone.includes(cliGenPhone) &&
              client.is_potential === false && 
              (client.status === null && client.status !== "closed")
            );
          });
        }

        dispatch(setAllListAllCompanies(newList));
  
      }else if (cliName !== "" && cliCountry === "" && cliGenPhone === "" && cliStatus !== "") {
        if(cliStatus === "active"){
          newList = data_array.filter((client) => {
            return (
              client.name.toLowerCase().includes(cliName.toLowerCase()) && 
              client.is_potential === false && 
              (client.status !== null && client.status !== "closed")
            );
          });
        }else if(cliStatus === "potential"){
          newList = data_array.filter((client) => {
            return (
              client.name.toLowerCase().includes(cliName.toLowerCase()) && 
              client.is_potential === true && 
              (client.status !== null && client.status !== "closed")
            );
          });
        }else if(cliStatus === "closed"){
          newList = data_array.filter((client) => {
            return (
              client.name.toLowerCase().includes(cliName.toLowerCase()) && 
              client.is_potential === false && 
              client.status === "closed"
            );
          });
        }else{
          newList = data_array.filter((client) => {
            return (
              client.name.toLowerCase().includes(cliName.toLowerCase()) && 
              client.is_potential === false && 
              (client.status === null && client.status !== "closed")
            );
          });
        }

        dispatch(setAllListAllCompanies(newList));
      }else if (cliName === "" && cliCountry !== "" && cliGenPhone === "" && cliStatus === "") {
        newList = data_array.filter((client) => {
            // console.log("client.options", client.options)
          let cntry = "";
  
          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          }
  
          return (
            cntry.includes(cliCountry)
          );
        });

        dispatch(setAllListAllCompanies(newList));
      }else if (cliName === "" && cliCountry !== "" && cliGenPhone !== "" && cliStatus === "") {
        newList = data_array.filter((client) => {
            // console.log("client.options", client.options)
          let cntry = "";
  
          if (client.options !== null) {
            cntry = client.options.value;
          } else {
            cntry = "";
          }
  
          return (
            cntry.includes(cliCountry) && client.general_telephone ? client.general_telephone.includes(cliGenPhone) : ""
          );
        });

        dispatch(setAllListAllCompanies(newList));
      }else if (cliName === "" && cliCountry !== "" && cliGenPhone !== "" && cliStatus !== "") {
        if(cliStatus === "active"){
          newList = data_array.filter((client) => {
            let cntry = "";
            let phone = "";
  
            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };
  
            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };
  
            return (
              cntry.includes(cliCountry) && 
              phone.includes(cliGenPhone) &&
              client.is_potential === false && 
              (client.status !== null && client.status !== "closed")
            );
          });
        }else if(cliStatus === "potential"){
          newList = data_array.filter((client) => {
            let cntry = "";
            let phone = "";
  
            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };
  
            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };
  
            return (
              cntry.includes(cliCountry) && 
              phone.includes(cliGenPhone) &&
              client.is_potential === true && 
              (client.status !== null && client.status !== "closed")
            );
          });
  
        }else if(cliStatus === "closed"){
          newList = data_array.filter((client) => {
            let cntry = "";
            let phone = "";
  
            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };
  
            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };
  
            return (
              cntry.includes(cliCountry) && 
              phone.includes(cliGenPhone) &&
              client.is_potential === false && 
              client.status === "closed"
            );
          });
        }else{
          newList = data_array.filter((client) => {
            let cntry = "";
            let phone = "";
  
            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };
  
            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };
  
            return (
              cntry.includes(cliCountry) && 
              phone.includes(cliGenPhone) &&
              client.is_potential === false && 
              (client.status === null && client.status !== "closed")
            );
          });
        }

        dispatch(setAllListAllCompanies(newList));
      }else if (cliName === "" && cliCountry !== "" && cliGenPhone === "" && cliStatus !== "") {
        if(cliStatus === "active"){
          newList = data_array.filter((client) => {
            let cntry = "";
  
            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };
  
            return (
              cntry.includes(cliCountry) && 
              client.is_potential === false && 
              (client.status !== null && client.status !== "closed")
            );
          });
        }else if(cliStatus === "potential"){
          newList = data_array.filter((client) => {
            let cntry = "";
  
            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };
  
            return (
              cntry.includes(cliCountry) && 
              client.is_potential === true && 
              (client.status !== null && client.status !== "closed")
            );
          });
  
        }else if(cliStatus === "closed"){
          newList = data_array.filter((client) => {
            let cntry = "";
  
            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };
  
            return (
              cntry.includes(cliCountry) && 
              client.is_potential === false && 
              client.status === "closed"
            );
          });
        }else{
          newList = data_array.filter((client) => {
            let cntry = "";
  
            if (client.options !== null) {
              cntry = client.options.value;
            } else {
              cntry = "";
            };
  
            return (
              cntry.includes(cliCountry) && 
              client.is_potential === false && 
              (client.status === null && client.status !== "closed")
            );
          });
        }
        dispatch(setAllListAllCompanies(newList));
      }else if (cliName === "" && cliCountry === "" && cliGenPhone !== "" && cliStatus === "") {
        newList = data_array.filter((client) => {
          let phone = "";
  
          if (client.general_telephone !== null) {
            phone = client.general_telephone;
          } else {
            phone = "";
          };
  
          return (
            phone.includes(cliGenPhone)
          );
        });
        dispatch(setAllListAllCompanies(newList));
      }else if (cliName === "" && cliCountry === "" && cliGenPhone !== "" && cliStatus !== "") {
        if(cliStatus === "active"){
          newList = data_array.filter((client) => {
            let phone = "";
  
            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };
  
            return (
              phone.includes(cliGenPhone) &&
              client.is_potential === false && 
              (client.status !== null && client.status !== "closed")
            );
          });
        }else if(cliStatus === "potential"){
          newList = data_array.filter((client) => {
            let phone = "";
  
            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };
  
            return (
              phone.includes(cliGenPhone) &&
              client.is_potential === true && 
              (client.status !== null && client.status !== "closed")
            );
          });
  
        }else if(cliStatus === "closed"){
          newList = data_array.filter((client) => {
            let phone = "";
  
            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };
  
            return (
              phone.includes(cliGenPhone) &&
              client.is_potential === false && 
              client.status === "closed"
            );
          });
        }else{
          newList = data_array.filter((client) => {
            let phone = "";
  
            if (client.general_telephone !== null) {
              phone = client.general_telephone;
            } else {
              phone = "";
            };
  
            return (
              phone.includes(cliGenPhone) &&
              client.is_potential === false && 
              (client.status === null && client.status !== "closed")
            );
          });
        }

        dispatch(setAllListAllCompanies(newList));
      }else if (cliName === "" && cliCountry === "" && cliGenPhone === "" && cliStatus !== "") {
        if(cliStatus === "active"){
          newList = data_array.filter((client) => {
            return (
              client.is_potential === false && 
              (client.status !== null && client.status !== "closed")
            );
          });
        }else if(cliStatus === "potential"){
          newList = data_array.filter((client) => {
            return (
              client.is_potential === true && 
              (client.status !== null && client.status !== "closed")
            );
          });
  
        }else if(cliStatus === "closed"){
          newList = data_array.filter((client) => {
            return (
              client.is_potential === false && 
              client.status === "closed"
            );
          });
        }else{
          newList = data_array.filter((client) => {
            return (
              client.is_potential === false && 
              (client.status === null && client.status !== "closed")
            );
          });
        }

        dispatch(setAllListAllCompanies(newList));
      }else{
        dispatch(setListAllCompanies(data_array));
        dispatch(setAllListAllCompanies(data_array));
      }

      // if(cliStatus !== ""){
      //   if(cliStatus === "active"){
      //     newList = response.data.filter((client) => {
      //       return client.is_potential === false && client.status !== null && client.status !== "closed"
      //     });
      //   }else if(cliStatus === "potential"){
      //     newList = response.data.filter((client) => {
      //       return client.is_potential === true && client.status !== null && client.status !== "closed"
      //     });
      //   }else if(cliStatus === "closed"){
      //     newList = response.data.filter((client) => {
      //       return client.is_potential === false && client.status === "closed"
      //     });
      //   }else{
      //     newList = response.data.filter((client) => {
      //       return client.is_potential === false && client.status === null && client.status !== "closed"
      //     });
      //   }

      //   dispatch(setAllListAllCompanies(newList));
      // }else{
      
      // }
      

  } catch (err) {
    throw rejectWithValue(err.message);
  }
};



export const { getClientList,getClientActiveList, getClientView, getClientViewConsultant, getClientViewIndustry, getClientFile, getClientSE, getClientListMeeting, getClientViewProposal, getListClientStaff, getListClientCandidate,
  setListActiveClient, setAllListActiveClient, setTempClientActData, setTempClientActFilData, setStoreActFilter,
  setListPotentialClient, setAllListPotentialClient, setStorePotFilter,
  setListAllCompanies, setAllListAllCompanies, setTempClientAllData, setTempClientAllFilData, setStoreAllFilter,
  
} = clientSlice.actions;
export default clientSlice.reducer;