import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Apiform from "../../core/services/apiform.service";
import Api from "../../core/services/apiform.service";
import FileSaver from "file-saver";
import {
  mdiFilePlus,
  mdiDotsHorizontal,
  mdiDownload,
  mdiDelete,
  mdiViewAgenda,
} from "@mdi/js";
import Icon from "@mdi/react";

import { Button, Dropdown, Form, Row, Card } from "react-bootstrap";

import { getClientDetails } from "../../store/actions/clientSlice";

import AuthAbilities from "../../pages/Admin/Auth/AuthAbilities";

import Table from "../Table/Table";
import Modal from "../Popup/ModalPopup";
import SwalAlert from "../Swal/SwalAlert";
import Viewer from "../Viewer/Viewer";

export const TabFiles = (props) => {
  const params = useParams();
  const dispatch = useDispatch();

  const { usrProfile } = useSelector((state) => state.user);
  const { clientDetailsFile } = useSelector((state) => state.client);

  const [showFile, setShowFile] = useState(false);
  const [errorForm, setErrorForm] = useState({});

  // PDF useState
  const [pageNumber, setPageNumber] = useState(1);
  const [showPdf, setShowPdf] = useState(false);
  const [filePath, setFilePath] = useState("");
  const [modeModal, setModeModal] = useState({
    title: "",
    Button: "",
  });

  const abilities = AuthAbilities();
  const customSwal = SwalAlert();

  const btnCloseFile = () => {
    setShowFile(false);
  };

  const btnAddFile = (e) => {
    setShowFile(true);
    setErrorForm({});
  };

  const btnClosePdf = () => {
    setShowPdf(false);
    setPageNumber(1);
  };

  // Checking Form Error
  const formError = (object) => {
    const newErrors = {};

    // console.log("object", object);

    if (!object.txtfilename || object.txtfilename === "")
      newErrors.txtfilename = "Fill in the blank!";

    if (!object.txtfile.name || object.txtfile.name === "")
      newErrors.txtfile = "Fill in the blank!";

    return newErrors;
  };

  const btnSaveFile = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dataFile = Object.fromEntries(data.entries());

    const newErrors = formError(dataFile);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      customSwal
        .fire({
          title: "Do you want to upload file ?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();

            formData.append("file_name", dataFile.txtfilename);
            formData.append("file", dataFile.txtfile);
            formData.append("status", "created");
            formData.append("created_by", usrProfile.first_name);

            Apiform.post("client/" + props.id + "/document", formData)
              .then((response) => {
                dispatch(getClientDetails(params.id));

                setShowFile(false);
                customSwal.fire({
                  title: "File Uploaded Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("error upload file", error);
              });
          }
        });
    }
  };

  const headerFiles = [
    {
      Header: "No",
      maxWidth: 70,
      minWidth: 70,
      width: 70,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Document Name",
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{data.file_name}</div>;
      },
    },
    {
      Header: "Uploaded Date",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        const date = new Date(data.created_at);
        const longMonth = date.toLocaleString("en-us", { month: "long" });

        return (
          <div>
            {date.getDate()} {longMonth} {date.getFullYear()}
          </div>
        );
      },
    },
    {
      Header: "Uploaded By",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{data.created_by}</div>;
      },
    },
    {
      Header: "",
      accessor: "actions",
      maxWidth: 100,
      minWidth: 80,
      width: 80,
      Cell: ({ row }) => {
        const data = row.original;

        function btnDownload() {
          FileSaver.saveAs(data.file_path, "download.pdf");
        }

        function btnPreview() {
          setFilePath(data.file_path);
          setShowPdf(true);

          setModeModal({
            title: "View Files/Documents",
            button: "",
          });
        }

        const btnDelFile = () => {
          customSwal
            .fire({
              title: "Do you want to delete this file ?",
              icon: "warning",
              showCancelButton: true,
              reverseButtons: true,
              confirmButtonText: "Yes",
            })
            .then((result) => {
              if (result.isConfirmed) {
                Api.delete("client/" + params.id + "/document/" + data.id)
                  .then((response) => {
                    dispatch(getClientDetails(params.id));

                    customSwal.fire({
                      title: "File Deleted Successfully!",
                      icon: "success",
                      confirmButtonText: "Okay",
                    });
                  })
                  .catch((error) => {
                    console.log("error btnDelFile", error);
                  });
              }
            });
        };

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={btnPreview}>
                  <Icon path={mdiViewAgenda} color="#467FD0" />
                  Preview Document
                </Dropdown.Item>
                <Dropdown.Item onClick={btnDownload}>
                  <Icon path={mdiDownload} color="#467FD0" />
                  Download File
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={btnDelFile}
                  hidden={
                    props.page === "active"
                      ? !abilities.includes("delete file")
                      : !abilities.includes("delete file")
                  }
                >
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete Document
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getClientDetails(params.id));
  }, [dispatch, params.id]);

  return (
    <>
      <Card>
        <Card.Body>
          <div className="row-div">
            <div className="left-div"></div>
            <div className="right-div">
              <Button
                variant="link"
                className="modal-link"
                onClick={btnAddFile}
                hidden={
                  props.page === "active"
                    ? !abilities.includes("upload file")
                    : !abilities.includes("upload file")
                }
              >
                <Icon path={mdiFilePlus} />
                Upload File
              </Button>
            </div>
          </div>

          <Table
            headers={headerFiles}
            items={clientDetailsFile}
            paginate={clientDetailsFile.length}
            maxRows={15}
          ></Table>
        </Card.Body>
      </Card>

      {/* Modal */}
      <Modal
        show={showFile}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseFile}
        title="Upload File"
        content={
          <>
            <Form id="upload-form" onSubmit={btnSaveFile}>
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label className="col-3">
                    File Name <label className="validation-star">*</label>
                  </Form.Label>
                  <div className="col-9" style={{ display: "inline-flex" }}>
                    <Form.Control
                      name="txtfilename"
                      type="text"
                      placeholder="Please enter file name"
                      isInvalid={!!errorForm.txtfilename}
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {errorForm.txtfilename}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label className="col-3">
                    Upload File <label className="validation-star">*</label>
                  </Form.Label>
                  <div className="col-9" style={{ display: "inline-flex" }}>
                    <Form.Control
                      name="txtfile"
                      type="file"
                      isInvalid={!!errorForm.txtfile}
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {errorForm.txtfile}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseFile}>
              Cancel
            </Button>
            <Button type="submit" form="upload-form" variant="primary">
              Upload File
            </Button>
          </>
        }
      ></Modal>

      {/* Modal Pdf */}
      <Viewer
        showPdf={showPdf}
        btnClosePdf={btnClosePdf}
        modalTitle={modeModal.title}
        filePath={filePath}
      />
    </>
  )
}

export default TabFiles