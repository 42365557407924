import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Button, Form } from "react-bootstrap";
import Api from "../../../../core/services/api.service";
import AuthAbilities from "../../Auth/AuthAbilities";
import "./placement.css";

import moment from "moment";

import Icon from "@mdi/react";
import {
  mdiDotsHorizontal,
  mdiDelete,
  mdiMagnify,
  mdiFilter,
  mdiAccountSearch,
} from "@mdi/js";

import { CustomTable } from "../../../../components/Table/Table";
import TextboxIcon from "../../../../components/TextField/TextFieldIcon";

// import { storeMenu } from "../../../../store/actions/breadSlice";
import { storeTitle } from "../../../../store/actions/headerTitleSlice";
import { getListStaff } from "../../../../store/actions/staffSlice";
import { getListYears } from "../../../../store/actions/optionSlice";

import { monthList } from "../../dataList";
import SwalAlert from "../../../../components/Swal/SwalAlert";

const PlacementList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const abilities = AuthAbilities();
  const customSwal = SwalAlert();

  const { dataStaff } = useSelector((state) => state.staff);
  const { dataYear } = useSelector((state) => state.option);

  const [placementList, setPlacementList] = useState([]);
  const [allPlaceData, setAllPlaceData] = useState([]);

  const [clicked, setClicked] = useState(false);

  const [checkClients] = useState([]);
  const [cliSelected, setCliSelected] = useState(0);

  const [valueSearch, setValueSearch] = useState("");
  const [tempPlaceData, setTempPlaceData] = useState([]);
  const [tempPlaceFilData, setTempPlaceFilData] = useState([]);
  const [textCompany, setTextCompany] = useState("");
  const [textConsultant, setTextConsultant] = useState("");
  const [textMonth, setTextMonth] = useState("");
  const [textYear, setTextYear] = useState("");

  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();

    setValueSearch(value);

    // console.log("tempPlaceFiata", tempPlaceFilData)

    let newList = [];

    if (
      textCompany !== "" ||
      textConsultant !== "" ||
      textMonth !== "" ||
      textYear !== ""
    ) {
      newList = tempPlaceFilData.filter((cand) => {
        if (!cand.candidates.last_name || cand.candidates.last_name === "") {
          return cand.candidates.first_name
            .toLowerCase()
            .includes(value.toLowerCase());
        } else {
          return (
            cand.candidates.first_name
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            cand.candidates.last_name
              .toLowerCase()
              .includes(value.toLowerCase())
          );
        }
      });

      setTempPlaceData(newList);
      setAllPlaceData(newList);
    } else {
      if (value !== "") {
        newList = placementList.filter((cand) => {
          if (!cand.candidates.last_name || cand.candidates.last_name === "") {
            return cand.candidates.first_name
              .toLowerCase()
              .includes(value.toLowerCase());
          } else {
            return (
              cand.candidates.first_name
                .toLowerCase()
                .includes(value.toLowerCase()) ||
              cand.candidates.last_name
                .toLowerCase()
                .includes(value.toLowerCase())
            );
          }
        });

        setTempPlaceData(newList);
        setAllPlaceData(newList);
      } else {
        setAllPlaceData(placementList);
      }
    }
  };

  const btnReset = () => {
    document.getElementById("filter-placement").reset();
    setTextCompany("");
    setTextConsultant("");
    setTextMonth("");
    setTextYear("");
  };

  const btnFilterPlaced = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtFilter = Object.fromEntries(data.entries());

    // console.log("dtFilter", dtFilter)

    let idConsult = Number(dtFilter.ddlConsultant);

    setTextCompany(dtFilter.txtcompanyName);
    setTextConsultant(dtFilter.ddlConsultant);
    setTextMonth(dtFilter.ddlMonth);
    setTextYear(dtFilter.ddlYear);

    if (valueSearch === "") {
      let newList = [];

      newList = placementList.filter((cand) => {
        if (idConsult !== 0) {
          return (
            cand.assignments.assign_to.find((e) => e === idConsult) &&
            moment(cand.joined_date)
              .format("MM")
              .toLowerCase()
              .includes(dtFilter.ddlMonth) &&
            moment(cand.joined_date)
              .format("YYYY")
              .toLowerCase()
              .includes(dtFilter.ddlYear) &&
            cand.assignments.clients.name
              .toLowerCase()
              .includes(dtFilter.txtcompanyName)
          );
        } else {
          return (
            moment(cand.joined_date)
              .format("MM")
              .toLowerCase()
              .includes(dtFilter.ddlMonth) &&
            moment(cand.joined_date)
              .format("YYYY")
              .toLowerCase()
              .includes(dtFilter.ddlYear) &&
            cand.assignments.clients.name
              .toLowerCase()
              .includes(dtFilter.txtcompanyName)
          );
        }
      });

      setTempPlaceFilData(newList);
      setAllPlaceData(newList);
    } else {
      if (
        dtFilter.txtcompanyName !== "" ||
        dtFilter.ddlConsultant !== "" ||
        dtFilter.ddlMonth !== "" ||
        dtFilter.ddlYear !== ""
      ) {
        let newList = [];

        newList = tempPlaceData.filter((cand) => {
          if (idConsult !== 0) {
            return (
              cand.assignments.assign_to.find((e) => e === idConsult) &&
              moment(cand.joined_date)
                .format("MM")
                .toLowerCase()
                .includes(dtFilter.ddlMonth) &&
              moment(cand.joined_date)
                .format("YYYY")
                .toLowerCase()
                .includes(dtFilter.ddlYear) &&
              cand.assignments.clients.name
                .toLowerCase()
                .includes(dtFilter.txtcompanyName)
            );
          } else {
            return (
              moment(cand.joined_date)
                .format("MM")
                .toLowerCase()
                .includes(dtFilter.ddlMonth) &&
              moment(cand.joined_date)
                .format("YYYY")
                .toLowerCase()
                .includes(dtFilter.ddlYear) &&
              cand.assignments.clients.name
                .toLowerCase()
                .includes(dtFilter.txtcompanyName)
            );
          }
        });

        setTempPlaceFilData(newList);
        setAllPlaceData(newList);
      } else {
        setAllPlaceData(tempPlaceData);
      }
    }
  };

  // button event
  const btnCollapse = (index) => {
    if (clicked === index) {
      //if clicked is already active, then close
      return setClicked(null);
    }

    setClicked(index);
  };

  const btnDelete = (cand_id, id) => (e) => {
    customSwal
      .fire({
        title: "Do you want to delete this placement?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("candidate/" + cand_id + "/placement/" + id)
            .then((response) => {
              // setSelectedIndustry([]);

              //close modal after update
              getListPlacement();

              customSwal.fire({
                title: "Deleted Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error delete placements", error);
            });
        }
      });
  };

  const btnDeleteMultiple = () => (e) =>{
    customSwal
    .fire({
      title: checkClients.length > 1 ? "Do you want to delete multiple selected companies?" : "Do you want to delete selected company?",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Yes",
    })
    .then((result) => {
      if (result.isConfirmed) {

        checkClients.map((client, index) => {
          Api.delete("candidate/" + client.candidate_id + "/placement/" + client.id)
          .then((response) => {
            getListPlacement();
          })
          .catch((error) => {
            console.log("error delete active client", error);
          });
        });

        //close modal after update
       
        customSwal.fire({
          title: "Deleted Successfully!",
          icon: "success",
          confirmButtonText: "Okay",
        });
        
      }
    });
  };

  const headerPlacement = [
    {
      Header: "",
      accessor: "selection",
      disableSortBy: true,
      minWidth: 10,
      width: 50,
      Cell: ({ row }) => {
        const data = row.original;

        const onItemCheck = (data) => (event) => {
          if (checkClients.length > 0) {
            if (event.target.checked === false) {
              const index = checkClients.findIndex(function (chck) {
                return chck.id === data.id;
              });

              checkClients.splice(index, 1);
            } else {
              checkClients.push(data);
            }
          } else {
            checkClients.push(data);
          }

          setCliSelected(checkClients.length);
        };

        const checkedItem = (id) => {
          let actCli = [];

          const checkCli = checkClients.filter((chec) => {
            return chec.id === id;
          });

          actCli = checkCli[0];

          return actCli;
        };

        return (
          <div className="checkbox-container">
            <input
              type="checkbox"
              checked={checkedItem(data.id)}
              id={"row" + data.id}
              onChange={onItemCheck(data)}
            />
          </div>
        );
      },
    },
    {
      Header: "Candidate Name",
      accessor: (row) => `${row.candidates.first_name}`,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        let candName = "";

        if (data.candidates.last_name !== null) {
          candName =
            data.candidates.first_name + " " + data.candidates.last_name;
        } else {
          candName = data.candidates.first_name;
        }

        function btnViewCand() {
          // dispatch(
          //   storeMenu({
          //     id: data.id,
          //     menu:
          //       data.candidates.first_name + " " + data.candidates.last_name,
          //   })
          // );

          navigate(
            "/candidates/view-placement/" + data.candidate_id + "/" + data.id
          );
        }

        return (
          <Button variant="link" className="no-underline" onClick={btnViewCand}>
            {candName}
          </Button>
        );
      },
    },
    {
      Header: "Company",
      accessor: (row) => `${row.assignments.clients.name}`,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        // let compID = data.assignments.client_id;
        let compInfo = data.assignments.clients;

        function handleClick() {
          // console.log("datarow", data);
          if (compInfo.is_potential === true && compInfo.status !== null) {
            navigate("/clients/view-potential-client/" + compInfo.id);
          } else if (
            compInfo.is_potential === false &&
            compInfo.status !== null
          ) {
            navigate("/clients/viewActiveC/" + compInfo.id);
          } else if (
            compInfo.is_potential === false &&
            compInfo.status === null
          ) {
            navigate("/clients/viewAllClients/" + compInfo.id);
          }
          // navigate("/clients/viewAllClients/" + data.id);
        }

        return (
          <Button variant="link" className="no-underline" onClick={handleClick}>
            {compInfo.name}
          </Button>
        );
      },
    },
    {
      Header: "Job Title",
      accessor: (row) => `${row.assignments.title}`,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        let jobTitle = data.assignments.title;

        return (
          <Button variant="link" className="no-underline">
            {jobTitle}
          </Button>
        );
      },
    },
    {
      Header: "Joined Date",
      accessor: (row) => `${row.joined_date}`,
      minWidth: 100,
      width: 300,
      Cell: ({ row }) => {
        const data = row.original;

        let joinedDate = "";

        if (data.joined_date !== null) {
          joinedDate = moment(data.joined_date).format("DD MMMM YYYY");
        } else {
          joinedDate = "-";
        }

        return <div>{joinedDate}</div>;
      },
    },
    {
      Header: "Placed by consultant",
      minWidth: 150,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        var array = data.assignments.assignment_has_user.map((obj, index) => {
          return obj.user.first_name + " " + obj.user.last_name + ",";
        });

        var cons_name = array.join(" ");

        return <div>{cons_name ? cons_name.slice(0, -1) : "-"}</div>;
      },
    },
    {
      Header: "",
      accessor: "client_info",
      disableSortBy: true,
      minWidth: 10,
      width: 70,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  hidden={!abilities.includes("delete candidate")}
                  onClick={btnDelete(data.candidate_id, data.id)}
                >
                  <Icon
                    path={mdiDelete}
                    color="#DF4759"
                  />
                  Delete Candidate
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const getListPlacement = useCallback(() => {
    Api.get("candidate/placement/placementList")
      .then((response) => {
        let data = response.data;

        // console.log("data getListPlacement", data);
        setPlacementList(data);
        setAllPlaceData(data);
      })
      .catch((error) => {
        console.log("error getListPlacement", error);
      });
  }, []);

  useEffect(() => {
    getListPlacement();
    dispatch(storeTitle({ title: "Placements", icon: mdiAccountSearch }));
    dispatch(getListStaff());
    dispatch(getListYears());
  }, [getListPlacement, dispatch]);

  return (
    <div className="main-div list-placement">
      <div className="top-title-div">
        <div className="filter_container">
          <div className="filter_container_totalrow">
            {allPlaceData.length === 0 || allPlaceData.length === 1 ? (
              <div className="form-container-default">
                <div className="total_client_text">{allPlaceData.length}</div>
                <div className="client_text">placement</div>
              </div>
            ) : (
              <div className="form-container-default">
                <div className="total_client_text">{allPlaceData.length}</div>
                <div className="client_text">placements</div>
              </div>
            )}
          </div>

          <div className="upper-divider"></div>

          <div className="filter_container_search">
            <div className="filter_container_search-1">
              <TextboxIcon
                name="txtplacename"
                type="text"
                onChange={(event) => handleSearch(event)}
                placeholder="Search by candidate name"
                inputSize="input--medium"
                icon={mdiMagnify}
              ></TextboxIcon>
            </div>
          </div>

          <div className="filter_container_typelist">
            <div className="form-container-filter-button">
              {cliSelected > 0 ? (
                <Button
                  variant="danger"
                  hidden={!abilities.includes("delete candidate")}
                  onClick={btnDeleteMultiple()}
                >
                  <Icon path={mdiDelete} />
                  <div>Delete</div>
                </Button>
              ) : (
                ""
              )}

              <Button onClick={() => btnCollapse(true)} variant="secondary">
                <Icon path={mdiFilter} />
                <div>Filter</div>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div id="demo" className={"collapses" + (clicked ? " in" : "")}>
        <Form id="filter-placement" onSubmit={btnFilterPlaced}>
          <div className="form-container-filter">
            <div className="form-container-filter-1">
              <Form.Group>
                <Form.Label>Company Name</Form.Label>
                <Form.Control type="text" name="txtcompanyName" />
              </Form.Group>
            </div>
            <div className="form-container-filter-1">
              <Form.Group>
                <Form.Label>Joined Date (Month)</Form.Label>
                <Form.Select
                  name="ddlMonth"
                  // onChange={handlePreMonth}
                  // defaultValue={expstate.ddlpremonth}
                  // isInvalid={!!errorsExp.ddlpremonth}
                >
                  <option value="">Select</option>
                  {monthList.map((month, index) => (
                    <option key={index} value={month.value}>
                      {month.label}
                    </option>
                  ))}
                </Form.Select>
                {/* <Form.Control type="date" name="txtdatejoined" /> */}
              </Form.Group>
            </div>
            <div className="form-container-filter-1">
              <Form.Group>
                <Form.Label>Joined Date (Year)</Form.Label>
                <Form.Select
                  name="ddlYear"
                  // onChange={handlePreMonth}
                  // defaultValue={expstate.ddlpremonth}
                  // isInvalid={!!errorsExp.ddlpremonth}
                >
                  <option value="">Select</option>
                  {dataYear.map((year, index) => (
                    <option key={index} value={year.value}>
                      {year.label}
                    </option>
                  ))}
                </Form.Select>
                {/* <Form.Control type="date" name="txtdatejoined" /> */}
              </Form.Group>
            </div>
            <div className="form-container-filter-1">
              <Form.Group>
                <Form.Label>Placed by consultant</Form.Label>
                <Form.Select name="ddlConsultant">
                  <option value="">Select</option>
                  {dataStaff.map((staf, index) => (
                    <option key={index} value={staf.id}>
                      {staf.first_name} {staf.last_name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
          </div>

          <div className="row-btn-bottom">
            <Button variant="secondary" onClick={btnReset}>
              Reset
            </Button>
            <Button variant="primary" type="submit" form="filter-placement">
              Search
            </Button>
          </div>
        </Form>
      </div>

      <div className="container-div list-placement">
        <CustomTable
          headers={headerPlacement}
          items={allPlaceData}
          paginate={allPlaceData.length}
          maxRows={15}
        ></CustomTable>
      </div>
    </div>
  );
};

export default PlacementList;
