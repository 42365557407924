import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Accordion, Row, Col, Form, Button, Card } from "react-bootstrap";
import Api from "../../../core/services/api.service";
import Apiform from "../../../core/services/apiform.service";

import FileSaver from "file-saver";
import SwalAlert from "../../../components/Swal/SwalAlert";

import {
  mdiFile,
  mdiPlusCircle,
  mdiPencil,
  mdiDelete,
  mdiArrowLeft,
  mdiAccountGroup,
} from "@mdi/js";
import Icon from "@mdi/react";

import BreadCrumb from "../../../components/Breadcrumb/BreadCrumb";
import Searchable from "../../../components/DropDown/SearchableSelect";
import RichTextEditor from "../../../components/RichTextArea/RichTextEditor";
import Popup from "../../../components/Popup/ModalPopup";
import CustomTable from "../../../components/Table/TableFunction";
import TextFieldFormat from "../../../components/TextField/TextFieldFormat";

import DefaultPhoto from "../../../assets/no-profile-photo.jpg";

import { getClientDetails } from "../../../store/actions/clientSlice";
import { storeTitle } from "../../../store/actions/headerTitleSlice";
import { getListOptions } from "../../../store/actions/optionSlice";

import { monthList, noticeList } from "../dataList";

const CreateStaff = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const formRef = useRef();

  const customSwal = SwalAlert();

  const { clientDetails } = useSelector((state) => state.client);
  const {
    dataYear,
    dataSalute,
    dataCountry,
    dataLanguages,
    dataJobFunction,
    dataIndustry,
    dataAllowance,
    dataCurrency,
  } = useSelector((state) => state.option);

  const backToClientView = (event) => {
    if (params.page === "active") {
      navigate("/clients/viewActiveC/" + params.id);
    } else if (params.page === "potential") {
      navigate("/clients/view-potential-client/" + params.id);
    } else {
      navigate("/clients/viewAllClients/" + params.id);
    }
  };

  const [crumbs, setCrumbs] = useState([]);
  const [clickedEdu, setClickedEdu] = useState(false);
  const [clickedExp, setClickedExp] = useState(false);
  const [clickedRef, setClickedRef] = useState(false);
  const [clickedMisc, setClickedMisc] = useState(false);
  const [checked, setChecked] = useState(false);
  const [profileImg, setProfileImg] = useState(DefaultPhoto);

  const [staffState] = useState({
    ddlSalutation: 0,
  });

  const [edustate] = useState({
    txtschool: "",
    txtdegree: "",
    txtfromyear: "",
    txttoyear: "",
    txtcertificate: "",
  });

  const [expstate] = useState({
    txtjobtitle: "",
    ddljobfunction: "",
    txtcompanyname: "",
    ddlIndustry: "",
    ddlCountry: {},
    cbWorkingCurrent: false,

    ddlpremonth: "",
    ddlpreyear: "",
    ddlstartmonth: "",
    ddlendmonth: "",
    ddlstartyear: "",
    ddlendyear: "",
    total_experience: 0,

    txtofficephone: "",
    txtofficeemail: "",
    ddlperiod: "",
    txtjobresponsibility: "",
    ddlcurrency: "",
    txtsalary: "",
    txtothbenefit: "",
    txtreason: "",
  });

  const [allowancestate] = useState([
    {
      ddlEarning: "",
      txtamount: "",
      ddlcurrencyallowance: "",
    },
  ]);

  const [refstate] = useState({
    txtrefname: null,
    txtrefjobtitle: null,
    txtrefemail: null,
    txtrefphone: null,
    txtrefcompany: null,
    txtrefrelationship: null,
  });

  const [miscstate] = useState({
    txtmiscdesc: "",
  });

  const [showOption, setShowOption] = useState(false);

  const handleEarning = (event, param1) => {
    if (event.target.value === "others") {
      setShowOption(true);
      setErrorOpt({});
    } else {
      const filteredEarn = allowanceList[param1];

      filteredEarn.earn = parseInt(event.target.value);

      allowancestate.ddlEarning = filteredEarn.earn;
      
    }
  };

  const handleAmount = (event, param1) => {
    const filteredEarn = allowanceList[param1];

    filteredEarn.amount = Number(
      event.target.value.replace(/[^0-9.-]+/g, "")
    );

    allowancestate.txtamount = filteredEarn.amount;
  };

  const handleCurrency = (event, param1) => {
    const filteredEarn = allowanceList[param1];

    filteredEarn.currency = event.target.value;

    allowancestate.ddlcurrencyallowance = filteredEarn.currency;

    // console.log("filteredEarn", filteredEarn);
  };

  const headers = [
    {
      Header: "Earning",
      accessor: (row) => `${row.earn}`,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            <Form.Select
              name="ddlEarning"
              size="sm"
              onChange={(event) => handleEarning(event, row.index)}
              defaultValue={data.earn}
            >
              <option value="">Select</option>
              {dataAllowance.map((earn, index) => (
                <option key={index} value={earn.id}>
                  {earn.value}
                </option>
              ))}
              <option value="others">Others</option>
            </Form.Select>
          </div>
        );
      },
    },
    {
      Header: "Amount",
      accessor: (row) => `${row.amount}`,
      Cell: ({ row }) => {
        const data = row.original;
        return (
          <div>
            <TextFieldFormat
              name="txtamount"
              onChange={(event) => handleAmount(event, row.index)}
              value={data.amount}
              thousandSeparator={true}
            ></TextFieldFormat>
          </div>
        );
      },
    },
    {
      Header: "Currency",
      accessor: (row) => `${row.currency}`,
      Cell: ({ row }) => {
        const data = row.original;
        return (
          <div>
            <Form.Select
              name="ddlcurrencyallowance"
              size="sm"
              onChange={(event) => handleCurrency(event, row.index)}
              defaultValue={data.currency}
            >
              <option value="">Select</option>
              {dataCurrency.map((currency, index) => (
                <option key={index} value={currency.value}>
                  {currency.value}
                </option>
              ))}
            </Form.Select>
          </div>
        );
      },
    },
    {
      Header: "Action",
      accessor: (row) => `${row.id}`,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            <Button
              variant="link"
              className="delete-link"
              onClick={() => btnDelAllowance(row.index)}
            >
              <Icon path={mdiDelete} />
            </Button>
          </div>
        );
      },
    },
  ];

  const [educationList, setEducationList] = useState([]);
  const [experienceList, setExperienceList] = useState([]);
  const [miscellaneousList, setMiscellaneousList] = useState([]);
  const [refereeList, setRefereeList] = useState([]);
  const [allowanceList, setAllowanceList] = useState([]);
  const [experienceTemp, setExperienceTemp] = useState([]);

  const [errorsBasic, setErrorsBasic] = useState({});
  const [errorsEdu, setErrorsEdu] = useState({});
  const [errorsExp, setErrorsExp] = useState({});

  const [eduStatus, setEduStatus] = useState("");
  const [expStatus, setExpStatus] = useState("");
  const [refStatus, setRefStatus] = useState("");
  const [miscStatus, setMiscStatus] = useState("");

  const [characterLimit] = useState(500);

  const selectedCrumb = (crumb) => {
    if (crumb === "Potential Clients") {
      navigate("/clients/listPotentialC");
    } else if (crumb === "Clients") {
      navigate("/clients/listActiveC");
    } else {
      navigate(-1);
    }
  };

  // Save Candidate
  const findFormErrorsAll = (object) => {
    const newBasicErrors = {};

    // console.log("object", arraySelected);
    // const regEmail = /\S+@\S+\.\S+/;

    // if (!object.ddlSalutation || object.ddlSalutation === "")
    //   newBasicErrors.ddlSalutation = "Please select!";

    if (!object.txtfirstname || object.txtfirstname === "")
      newBasicErrors.txtfirstname = "Fill in the blank!";

    if (!object.txtlastname || object.txtlastname === "")
      newBasicErrors.txtlastname = "Fill in the blank!";

    if (!object.txtjobtitle || object.txtjobtitle === "")
      newBasicErrors.txtjobtitle = "Fill in the blank!";

    // if (!object.txtdirectline || object.txtdirectline === "")
    //   newBasicErrors.txtdirectline = "Fill in the blank!";

    // if (!object.txtemail || object.txtemail === "")
    //   newBasicErrors.txtemail = "Fill in the blank!";
    // else
    // if (!regEmail.test(object.txtemail))
    //   newBasicErrors.txtemail = "Invalid Email Address";

    // if (!object.txtcountrycode || object.txtcountrycode === "")
    //   newBasicErrors.txtcountrycode = "Please select!";

    // if (!object.txtphone || object.txtphone === "")
    //   newBasicErrors.txtphone = "Fill in the blank!";

    // if (!object.txtcountry || object.txtcountry === "")
    //   newBasicErrors.txtcountry = "Please select!";

    // if (arraySelected.length === 0)
    //   newBasicErrors.ddllang = "Please select atleast one!";

    return newBasicErrors;
  };

  const formErrorsEmail = (object) => {
    const emailErrors = {};

    if (object === "Email Already Exist!")
      emailErrors.txtemail = "Email Already Exist";

    return emailErrors;
  };

  const btnSaveCandidate = (e) => {
    setExperienceTemp([]);

    e.preventDefault();

    const data = new FormData(e.target);
    const dataInfor = Object.fromEntries(data.entries());

    experienceTemp.push({
      id: 1,
      title: dataInfor.txtjobtitle,
      company_name: clientDetails.name,
      location: dataInfor.txtcountry,
      start_date_year: "",
      start_date_month: "",
      desc_start_date_month: "",
      end_date_year: "",
      end_date_month: "",
      desc_end_date_month: "",
      current_working: "true",
      present_month: "",
      present_year: "",
      total_experience: 0,
      desc_present_month: "",
      office_number: "",
      office_email: dataInfor.txtcompemail,
      notice_period: "",
      industry: [],
      job_function: [],
      job_responsibility: "",
      salary: 0,
      currency: "",
      other_benefits: "",
      reason_leaving: "",
      allowance: [],
    });

    // console.log("selectedLang", selectedLang);
    // console.log("educationList", educationList);

    // console.log("experinceTemp", experienceTemp);
    // console.log("refereeList", refereeList);
    // console.log("miscellaneousList", miscellaneousList);

    const newErrors = findFormErrorsAll(dataInfor);

    if (Object.keys(newErrors).length > 0) {
      setErrorsBasic(newErrors);

      for (var name in newErrors) {
        // console.log(name);

        const input = document.querySelector(`input[name=${name}]`);
        const inp_select = document.querySelector(`select[name=${name}]`);

        if (input !== null) {
          input.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
          });
        } else {
          inp_select.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
          });
        }

        // console.log("input", input, inp_select);
      }
    } else {
      // // Save Staff

      let linkedInCheck = !dataInfor.txtlinked.substring(
        0,
        dataInfor.txtlinked.indexOf(":")
      )
        ? !dataInfor.txtlinked
          ? ""
          : "http://" + dataInfor.txtlinked
        : dataInfor.txtlinked;
      let instaCheck = !dataInfor.txtinsta.substring(
        0,
        dataInfor.txtinsta.indexOf(":")
      )
        ? !dataInfor.txtinsta
          ? ""
          : "http://" + dataInfor.txtinsta
        : dataInfor.txtinsta;
      let facebookCheck = !dataInfor.txtfacebook.substring(
        0,
        dataInfor.txtfacebook.indexOf(":")
      )
        ? !dataInfor.txtfacebook
          ? ""
          : "http://" + dataInfor.txtfacebook
        : dataInfor.txtfacebook;
      let othwebCheck = !dataInfor.txtothweb.substring(
        0,
        dataInfor.txtothweb.indexOf(":")
      )
        ? !dataInfor.txtothweb
          ? ""
          : "http://" + dataInfor.txtothweb
        : dataInfor.txtothweb;

      customSwal
        .fire({
          title: "Do you want to save new staff?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();

            formData.append("salutation", dataInfor.ddlSalutation);
            formData.append("first_name", dataInfor.txtfirstname);
            formData.append("last_name", dataInfor.txtlastname);

            if (selectedLang.length > 0) {
              selectedLang.forEach((item, index) => {
                formData.append(`languages[${index}]`, item);
              });
            } else {
              formData.append(`languages[0]`, []);
            }
            formData.append("mobile_no", dataInfor.txtphone);
            // formData.append("mobile_no_code", dataInfor.txtcountrycode);
            formData.append("email", dataInfor.txtemail);
            formData.append("comp_email", dataInfor.txtcompemail);
            formData.append("citizenship_main", dataInfor.txtcountry);
            formData.append("direct_line_no", dataInfor.txtdirectline);
            formData.append("job_title", dataInfor.txtjobtitle);
            formData.append("file", dataInfor.txtprofile);

            formData.append("linkedln_url", linkedInCheck);
            formData.append("instagram_url", instaCheck);
            formData.append("facebook_url", facebookCheck);
            formData.append("website_url", othwebCheck);

            if (educationList.length > 0) {
              educationList.forEach((item, index) => {
                formData.append(`education[${index}][name]`, item.name);
                formData.append(
                  `education[${index}][qualification]`,
                  item.qualification
                );
                formData.append(
                  `education[${index}][start_year]`,
                  item.start_year
                );
                formData.append(`education[${index}][end_year]`, item.end_year);
                formData.append(
                  `education[${index}][certificate_attachment]`,
                  item.certificate_attachment
                );
              });
            } else {
              formData.append("education", []);
            }

            if (experienceList.length > 0) {
              let currentWorkTrue = experienceList.filter(function (exp) {
                return exp.current_working === true;
              });

              // console.log("currentWorkTrue", currentWorkTrue.length)

              if (currentWorkTrue.length === 0) {
                Array.prototype.push.apply(experienceList, experienceTemp);

                experienceList.forEach((exp, index) => {
                  formData.append(`experience[${index}][title]`, exp.title);
                  formData.append(
                    `experience[${index}][company_name]`,
                    exp.company_name
                  );
                  formData.append(
                    `experience[${index}][location]`,
                    exp.location
                  );
                  formData.append(
                    `experience[${index}][start_date_year]`,
                    exp.start_date_year
                  );
                  formData.append(
                    `experience[${index}][start_date_month]`,
                    exp.start_date_month
                  );
                  formData.append(
                    `experience[${index}][end_date_year]`,
                    exp.end_date_year
                  );
                  formData.append(
                    `experience[${index}][end_date_month]`,
                    exp.end_date_month
                  );
                  formData.append(
                    `experience[${index}][total_experience]`,
                    exp.total_experience
                  );
                  formData.append(
                    `experience[${index}][current_working]`,
                    exp.current_working.toString()
                  );
                  formData.append(
                    `experience[${index}][present_month]`,
                    exp.present_month
                  );
                  formData.append(
                    `experience[${index}][present_year]`,
                    exp.present_year
                  );
                  formData.append(
                    `experience[${index}][office_number]`,
                    exp.office_number
                  );
                  formData.append(
                    `experience[${index}][office_email]`,
                    exp.office_email
                  );
                  formData.append(
                    `experience[${index}][notice_period]`,
                    exp.notice_period
                  );

                  if (exp.industry.length > 0) {
                    exp.industry.forEach((ind, index2) => {
                      formData.append(
                        `experience[${index}][industry][${index2}]`,
                        ind
                      );
                    });
                  } else {
                    formData.append(`experience[${index}][industry]`, []);
                  }

                  if (exp.job_function.length > 0) {
                    exp.job_function.forEach((job, index2) => {
                      formData.append(
                        `experience[${index}][job_function][${index2}]`,
                        job
                      );
                    });
                  } else {
                    formData.append(`experience[${index}][job_function]`, []);
                  }

                  formData.append(
                    `experience[${index}][job_responsibility]`,
                    exp.job_responsibility
                  );
                  formData.append(`experience[${index}][salary]`, exp.salary);
                  formData.append(
                    `experience[${index}][currency]`,
                    exp.currency
                  );
                  formData.append(
                    `experience[${index}][other_benefit]`,
                    exp.other_benefits
                  );
                  formData.append(
                    `experience[${index}][reason_leaving]`,
                    exp.reason_leaving
                  );

                  if (exp.allowance.length > 0) {
                    exp.allowance.forEach((allow, index2) => {
                      formData.append(
                        `experience[${index}][allowance][${index2}][earning]`,
                        allow.earn
                      );
                      formData.append(
                        `experience[${index}][allowance][${index2}][amount]`,
                        allow.amount
                      );
                      formData.append(
                        `experience[${index}][allowance][${index2}][currency]`,
                        allow.currency
                      );
                    });
                  } else {
                    formData.append(`experience[${index}][allowance]`, []);
                  }
                });
              } else {
                experienceList.forEach((exp, index) => {
                  formData.append(`experience[${index}][title]`, exp.title);
                  formData.append(
                    `experience[${index}][company_name]`,
                    exp.company_name
                  );
                  formData.append(
                    `experience[${index}][location]`,
                    exp.location
                  );
                  formData.append(
                    `experience[${index}][start_date_year]`,
                    exp.start_date_year
                  );
                  formData.append(
                    `experience[${index}][start_date_month]`,
                    exp.start_date_month
                  );
                  formData.append(
                    `experience[${index}][end_date_year]`,
                    exp.end_date_year
                  );
                  formData.append(
                    `experience[${index}][end_date_month]`,
                    exp.end_date_month
                  );
                  formData.append(
                    `experience[${index}][current_working]`,
                    exp.current_working.toString()
                  );
                  formData.append(
                    `experience[${index}][present_month]`,
                    exp.present_month
                  );
                  formData.append(
                    `experience[${index}][present_year]`,
                    exp.present_year
                  );
                  formData.append(
                    `experience[${index}][office_number]`,
                    exp.office_number
                  );
                  formData.append(
                    `experience[${index}][office_email]`,
                    exp.office_email
                  );
                  formData.append(
                    `experience[${index}][notice_period]`,
                    exp.notice_period
                  );

                  if (exp.industry.length > 0) {
                    exp.industry.forEach((ind, index2) => {
                      formData.append(
                        `experience[${index}][industry][${index2}]`,
                        ind
                      );
                    });
                  } else {
                    formData.append(`experience[${index}][industry]`, []);
                  }

                  if (exp.job_function.length > 0) {
                    exp.job_function.forEach((job, index2) => {
                      formData.append(
                        `experience[${index}][job_function][${index2}]`,
                        job
                      );
                    });
                  } else {
                    formData.append(`experience[${index}][job_function]`, []);
                  }

                  formData.append(
                    `experience[${index}][job_responsibility]`,
                    exp.job_responsibility
                  );
                  formData.append(`experience[${index}][salary]`, exp.salary);
                  formData.append(
                    `experience[${index}][currency]`,
                    exp.currency
                  );
                  formData.append(
                    `experience[${index}][other_benefit]`,
                    exp.other_benefits
                  );
                  formData.append(
                    `experience[${index}][reason_leaving]`,
                    exp.reason_leaving
                  );

                  if (exp.allowance.length > 0) {
                    exp.allowance.forEach((allow, index2) => {
                      formData.append(
                        `experience[${index}][allowance][${index2}][earning]`,
                        allow.earn
                      );
                      formData.append(
                        `experience[${index}][allowance][${index2}][amount]`,
                        allow.amount
                      );
                      formData.append(
                        `experience[${index}][allowance][${index2}][currency]`,
                        allow.currency
                      );
                    });
                  } else {
                    formData.append(`experience[${index}][allowance]`, []);
                  }
                });
              }
            } else {
              experienceTemp.forEach((exptemp, index) => {
                formData.append(`experience[${index}][title]`, exptemp.title);
                formData.append(
                  `experience[${index}][company_name]`,
                  exptemp.company_name
                );
                formData.append(
                  `experience[${index}][location]`,
                  exptemp.location
                );
                formData.append(
                  `experience[${index}][start_date_year]`,
                  exptemp.start_date_year
                );
                formData.append(
                  `experience[${index}][start_date_month]`,
                  exptemp.start_date_month
                );
                formData.append(
                  `experience[${index}][end_date_year]`,
                  exptemp.end_date_year
                );
                formData.append(
                  `experience[${index}][end_date_month]`,
                  exptemp.end_date_month
                );
                formData.append(
                  `experience[${index}][current_working]`,
                  exptemp.current_working
                );
                formData.append(
                  `experience[${index}][present_month]`,
                  exptemp.present_month
                );
                formData.append(
                  `experience[${index}][present_year]`,
                  exptemp.present_year
                );
                formData.append(
                  `experience[${index}][office_number]`,
                  exptemp.office_number
                );
                formData.append(
                  `experience[${index}][office_email]`,
                  exptemp.office_email
                );
                formData.append(
                  `experience[${index}][notice_period]`,
                  exptemp.notice_period
                );
                formData.append(`experience[${index}][industry]`, []);
                formData.append(`experience[${index}][job_function]`, []);

                formData.append(
                  `experience[${index}][job_responsibility]`,
                  exptemp.job_responsibility
                );
                formData.append(`experience[${index}][salary]`, exptemp.salary);
                formData.append(
                  `experience[${index}][currency]`,
                  exptemp.currency
                );
                formData.append(
                  `experience[${index}][other_benefit]`,
                  exptemp.other_benefits
                );
                formData.append(
                  `experience[${index}][reason_leaving]`,
                  exptemp.reason_leaving
                );

                formData.append(`experience[${index}][allowance]`, []);
              });
            }

            if (refereeList.length > 0) {
              refereeList.forEach((ref, index) => {
                formData.append(`reference[${index}][name]`, ref.name);
                formData.append(
                  `reference[${index}][job_title]`,
                  ref.job_title
                );
                formData.append(`reference[${index}][email]`, ref.email);
                formData.append(`reference[${index}][phone]`, ref.phone);
                formData.append(
                  `reference[${index}][current_company]`,
                  ref.current_company
                );
                formData.append(
                  `reference[${index}][relationship]`,
                  ref.relationship
                );
              });
            } else {
              formData.append("reference", []);
            }

            if (miscellaneousList.length > 0) {
              miscellaneousList.forEach((miscell, index) => {
                formData.append(`miscellaneous[${index}][misc]`, miscell.misc);
              });
            } else {
              formData.append("miscellaneous", []);
            }

            Apiform.post("client/" + params.id + "/staff/create", formData)
              .then((response) => {
                if (params.page === "active") {
                  navigate("/clients/viewActiveC/" + params.id);
                } else if (params.page === "potential") {
                  navigate("/clients/view-potential-client/" + params.id);
                } else {
                  navigate("/clients/viewAllClients/" + params.id);
                }

                customSwal.fire({
                  title: "Saved Successfully!!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("error create staff", error.response.data);

                const newErrors = formErrorsEmail(error.response.data.message);
                // console.log("newErrors", newErrors)
                if (Object.keys(newErrors).length > 0) {
                  setErrorsBasic(newErrors);
                }
              });
          }
        });
    }
  };
  //End Save Candidate

  // Education Function

  const findFormErrors = (object) => {
    const newErrors = {};
    // school errors
    if (!object.txtschool || object.txtschool === "")
      newErrors.txtschool = "Fill in the blank!";

    // degree errors
    // if (!object.txtdegree || object.txtdegree === "")
    //   newErrors.txtdegree = "Fill in the blank!";

    // from year errors
    // if (!object.txtfromyear || object.txtfromyear === "")
    //   newErrors.txtfromyear = "Fill in the blank!";

    // to year errors
    // if (!object.txttoyear || object.txttoyear === "")
    //   newErrors.txttoyear = "Fill in the blank!";

    return newErrors;
  };

  const btnAddEdu = (index) => {
    if (clickedEdu === index) {
      //if clicked is already active, then close
      return setClickedEdu(null);
    }

    btnResetEdu();
    setEduStatus("New");
    setErrorsEdu({});

    setClickedEdu(index);
  };

  const btnSaveEdu = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dataEducation = Object.fromEntries(data.entries());

    // console.log("dataEducation", dataEducation);

    const newErrors = findFormErrors(dataEducation);

    if (Object.keys(newErrors).length > 0) {
      setErrorsEdu(newErrors);
    } else {
      if (eduStatus === "New") {
        const eduLength = educationList.length;

        setEducationList((items) => [
          ...items,
          {
            id: eduLength + 1,
            name: dataEducation.txtschool,
            qualification: dataEducation.txtdegree,
            start_year: dataEducation.txtfromyear,
            end_year: dataEducation.txttoyear,
            certificate_attachment: dataEducation.txtcertificate,
          },
        ]);

        setClickedEdu(false);
      } else {
        const filteredEdu = educationList[eduStatus];

        filteredEdu.name = dataEducation.txtschool;
        filteredEdu.qualification = dataEducation.txtdegree;
        filteredEdu.start_year = dataEducation.txtfromyear;
        filteredEdu.end_year = dataEducation.txttoyear;
        filteredEdu.certificate_attachment = dataEducation.txtcertificate;

        setClickedEdu(false);
      }
    }
  };

  const btnEditEdu = (id) => (e) => {
    const filteredEdu = educationList[id];

    edustate.txtschool = filteredEdu.name;
    edustate.txtdegree = filteredEdu.qualification;
    edustate.txtfromyear = filteredEdu.start_year;
    edustate.txttoyear = filteredEdu.end_year;
    edustate.txtcertificate = filteredEdu.certificate_attachment;

    setEduStatus(id);
    setClickedEdu(true);
  };

  const btnResetEdu = (event) => {
    edustate.txtschool = null;
    edustate.txtdegree = null;
    edustate.txtfromyear = null;
    edustate.txttoyear = null;
  };

  const btnDelEdu = (index) => {
    let data = [...educationList];
    data.splice(index, 1);
    setEducationList(data);
  };

  const btnDownload = (id, file) => (e) => {
    FileSaver.saveAs(file);
  };

  // End Education Function

  // Experience Function

  const [isReadMore, setReadMore] = useState({
    id: "",
    more: false,
  });

  const findFormErrorsExp = (object) => {
    const newErrors = {};
    // school errors
    if (!object.txtjobtitle || object.txtjobtitle === "")
      newErrors.txtjobtitle = "Fill in the blank!";

    if (!object.txtcompanyname || object.txtcompanyname === "")
      newErrors.txtcompanyname = "Fill in the blank!";

    if (!object.ddlCountry || object.ddlCountry === "")
      newErrors.ddlCountry = "Fill in the blank!";

    // if (checked === true) {
    //   if (!object.ddlpreyear || object.ddlpreyear === "")
    //     newErrors.ddlpreyear = "Fill in the blank!";

    //   if (!object.ddlpremonth || object.ddlpremonth === "")
    //     newErrors.ddlpremonth = "Fill in the blank!";
    // } else {
    //   if (!object.ddlstartyear || object.ddlstartyear === "")
    //     newErrors.ddlstartyear = "Fill in the blank!";

    //   if (!object.ddlstartmonth || object.ddlstartmonth === "")
    //     newErrors.ddlstartmonth = "Fill in the blank!";

    //   if (!object.ddlendyear || object.ddlendyear === "")
    //     newErrors.ddlendyear = "Fill in the blank!";

    //   if (!object.ddlendmonth || object.ddlendmonth === "")
    //     newErrors.ddlendmonth = "Fill in the blank!";
    // }

    // if (!object.txtofficeemail || object.txtofficeemail === "")
    //   newErrors.txtofficeemail = "Fill in the blank!";

    // if (!object.ddlcurrency || object.ddlcurrency === "")
    //   newErrors.ddlcurrency = "Fill in the blank!";

    // if (!object.txtsalary || object.txtsalary === "")
    //   newErrors.txtsalary = "Fill in the blank!";

    return newErrors;
  };

  const btnAddExp = (index) => {
    if (clickedExp === index) {
      //if clicked is already active, then close
      return setClickedExp(null);
    }

    btnResetExp();
    setChecked(false);
    setAllowanceList([]);
    setErrorsExp({});
    setExpStatus("New");
    setClickedExp(index);
  };

  function getMonthDifference(startDate, endDate) {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }

  const btnSaveExp = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dataExperience = Object.fromEntries(data.entries());

    var monthDuration = 0;

    const newErrors = findFormErrorsExp(dataExperience);

    const filteredAllowance = allowanceList.filter((allow) => {
      return allow.earn !== 0;
    });

    if (expStatus === "New") {
      if (Object.keys(newErrors).length > 0) {
        setErrorsExp(newErrors);

        for (var name in newErrors) {
          // console.log(name);

          const input = document.querySelector(`input[name=${name}]`);

          input.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
          });

          // console.log("input", input, inp_txtarea);
        }
      } else {
        const expLength = experienceList.length;

        let startMonthDate = "";
        let endMonthDate = "";
        let startYearDate = "";
        let endYearDate = "";

        let preMonth = "";
        let preYear = "";

        if (checked === true) {
          preMonth = dataExperience.ddlpremonth;
          preYear = dataExperience.ddlpreyear;
          startMonthDate = "";
          endMonthDate = "";
          startYearDate = "";
          endYearDate = "";

          monthDuration = getMonthDifference(
            new Date(
              dataExperience.ddlpreyear + "-" + dataExperience.ddlpremonth
            ),
            new Date()
          );
        } else {
          preMonth = "";
          preYear = "";
          startMonthDate = dataExperience.ddlstartmonth;
          endMonthDate = dataExperience.ddlendmonth;
          startYearDate = dataExperience.ddlstartyear;
          endYearDate = dataExperience.ddlendyear;

          monthDuration = getMonthDifference(
            new Date(
              dataExperience.ddlstartyear + "-" + dataExperience.ddlstartmonth
            ),
            new Date(
              dataExperience.ddlendyear + "-" + dataExperience.ddlendmonth
            )
          );
        }

        // setSalaryTxt(
        //   Number(dataExperience.txtsalary.replace(/[^0-9.-]+/g, ""))
        // );

        setExperienceList((items) => [
          ...items,
          {
            id: expLength + 1,
            title: dataExperience.txtjobtitle,
            company_name: dataExperience.txtcompanyname,
            location: dataExperience.ddlCountry,
            start_date_year: startYearDate,
            start_date_month: startMonthDate,
            desc_start_date_month: startMon,
            end_date_year: endYearDate,
            end_date_month: endMonthDate,
            desc_end_date_month: endMon,
            current_working: checked,
            present_month: preMonth,
            present_year: preYear,
            total_experience: monthDuration,
            desc_present_month: preDescMon,
            office_number: dataExperience.txtofficephone,
            office_email: dataExperience.txtofficeemail,
            notice_period: dataExperience.ddlperiod,
            industry: dataExperience.ddlIndustry !== "" ? selectedInd : [],
            job_function:
              dataExperience.ddljobfunction !== "" ? selectedJob : [],
            job_responsibility: dataExperience.txtjobresponsibility
              ? dataExperience.txtjobresponsibility
              : null,
            salary: salaryTxt.current
              ? Number(salaryTxt.current.replace(/[^0-9.-]+/g, ""))
              : 0,
            currency: dataExperience.ddlcurrency,
            other_benefits: dataExperience.txtothbenefit
              ? dataExperience.txtothbenefit
              : null,
            reason_leaving: dataExperience.txtreason
              ? dataExperience.txtreason
              : null,
            allowance: filteredAllowance,
          },
        ]);

        // console.log("setExperienceList", experienceList);
        // getExperienceList(experienceList);

        setClickedExp(false);
        // }
      }
    } else {
      const filteredExp = experienceList[expStatus];

      let startMonthDate = "";
      let endMonthDate = "";
      let startYearDate = "";
      let endYearDate = "";

      let preMonth = "";
      let preYear = "";

      // var monthDuration = 0;

      if (checked === true) {
        preMonth = dataExperience.ddlpremonth;
        preYear = dataExperience.ddlpreyear;
        startMonthDate = "";
        endMonthDate = "";
        startYearDate = "";
        endYearDate = "";

        monthDuration = getMonthDifference(
          new Date(
            dataExperience.ddlpreyear + "-" + dataExperience.ddlpremonth
          ),
          new Date()
        );
      } else {
        preMonth = "";
        preYear = "";
        startMonthDate = dataExperience.ddlstartmonth;
        endMonthDate = dataExperience.ddlendmonth;
        startYearDate = dataExperience.ddlstartyear;
        endYearDate = dataExperience.ddlendyear;

        monthDuration = getMonthDifference(
          new Date(
            dataExperience.ddlstartyear + "-" + dataExperience.ddlstartmonth
          ),
          new Date(dataExperience.ddlendyear + "-" + dataExperience.ddlendmonth)
        );
      }

      filteredExp.title = dataExperience.txtjobtitle;
      filteredExp.job_function =
        dataExperience.ddljobfunction !== "" ? selectedJob : [];
      filteredExp.company_name = dataExperience.txtcompanyname;
      filteredExp.industry =
        dataExperience.ddlIndustry !== "" ? selectedInd : [];
      filteredExp.start_date_year = startYearDate;
      filteredExp.start_date_month = startMonthDate;
      filteredExp.desc_start_date_month = startMon;

      filteredExp.end_date_year = endYearDate;
      filteredExp.end_date_month = endMonthDate;
      filteredExp.desc_end_date_month = endMon;

      filteredExp.current_working = checked;
      filteredExp.present_month = preMonth;
      filteredExp.present_year = preYear;
      filteredExp.desc_present_month = preDescMon;
      filteredExp.total_experience = monthDuration;

      filteredExp.office_number = dataExperience.txtofficephone;
      filteredExp.office_email = dataExperience.txtofficeemail;
      filteredExp.notice_period = dataExperience.ddlperiod;
      filteredExp.job_responsibility = dataExperience.txtjobresponsibility;

      filteredExp.salary = dataExperience.txtsalary
        ? Number(dataExperience.txtsalary.replace(/[^0-9.-]+/g, ""))
        : 0;
      filteredExp.currency = dataExperience.ddlcurrency;
      filteredExp.other_benefits = dataExperience.txtothbenefit;
      filteredExp.reason_leaving = dataExperience.txtreason;
      filteredExp.allowance = filteredAllowance;

      setClickedExp(false);
    }
  };

  const btnEditExp = (id) => (e) => {
    setErrorsExp({});

    const filteredExp = experienceList[id];
    // console.log("filteredExp", filteredExp);

    // setSalaryTxt(filteredExp[0].salary);

    expstate.txtjobtitle = filteredExp.title;
    expstate.ddljobfunction = filteredExp.job_function;
    expstate.txtcompanyname = filteredExp.company_name;
    expstate.ddlIndustry = filteredExp.industry;
    expstate.ddlCountry = {
      label: filteredExp.location,
      value: filteredExp.location,
    };

    expstate.ddlstartmonth = filteredExp.start_date_month;
    expstate.ddlstartyear = filteredExp.start_date_year;
    expstate.ddlendmonth = filteredExp.end_date_month;
    expstate.ddlendyear = filteredExp.end_date_year;

    expstate.ddlpremonth = filteredExp.present_month;
    expstate.ddlpreyear = filteredExp.present_year;
    expstate.total_experience = filteredExp.total_experience;

    expstate.cbWorkingCurrent = filteredExp.current_working;
    expstate.txtofficephone = filteredExp.office_number;
    expstate.txtofficeemail = filteredExp.office_email;
    expstate.ddlperiod = filteredExp.notice_period;
    expstate.txtjobresponsibility = filteredExp.job_responsibility;
    expstate.ddlcurrency = filteredExp.currency;
    salaryTxt.current = filteredExp.salary;
    expstate.txtothbenefit = filteredExp.other_benefits;
    expstate.txtreason = filteredExp.reason_leaving;

    setAllowanceList(filteredExp.allowance);

    setChecked(filteredExp.current_working);
    setExpStatus(id);
    setClickedExp(true);
  };

  const btnResetExp = (event) => {
    expstate.txtjobtitle = "";
    expstate.txtcompanyname = "";
    expstate.ddljobfunction = "";
    expstate.ddlIndustry = "";
    expstate.ddlCountry = {};

    expstate.ddlstartmonth = "";
    expstate.ddlstartyear = "";
    expstate.ddlendmonth = "";
    expstate.ddlendyear = "";

    expstate.ddlpremonth = "";
    expstate.ddlpreyear = "";

    expstate.cbWorkingCurrent = false;
    expstate.txtofficephone = "";
    expstate.txtofficeemail = "";
    expstate.ddlperiod = "";
    expstate.txtjobresponsibility = "";
    expstate.ddlcurrency = "";
    // expstate.txtsalary = "";
    expstate.txtothbenefit = "";
    expstate.txtreason = "";
    salaryTxt.current = "";

    setChecked(false);
  };

  const [startMon, setStartMon] = useState("");

  const handleStartMonth = (e) => {
    let index = e.nativeEvent.target.selectedIndex;
    let label = e.nativeEvent.target[index].text;

    setStartMon(label);
  };

  const [endMon, setEndMon] = useState("");

  const handleEndMonth = (e) => {
    let index = e.nativeEvent.target.selectedIndex;
    let label = e.nativeEvent.target[index].text;

    setEndMon(label);
  };

  const [preDescMon, setPreDescMon] = useState("");

  const handlePreMonth = (e) => {
    let index = e.nativeEvent.target.selectedIndex;
    let label = e.nativeEvent.target[index].text;

    setPreDescMon(label);
  };

  const btnDelExp = (index) => {
    // console.log("idExp", index);

    let data = [...experienceList];
    data.splice(index, 1);
    setExperienceList(data);

  };

  const btnDelAllowance = (index) => {

    let data = [...allowanceList];
    data.splice(index, 1);
    setAllowanceList(data);
  };

  const btnAddNewAllowance = (e) => {
    const allowanceLength = allowanceList.length + 1;

    setAllowanceList((items) => [
      ...items,
      {
        id: allowanceLength,
        earn: 0,
        amount: 0,
        currency: "",
      },
    ]);
  };

  function toMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", {
      month: "long",
    });
  }

  function toAllowanceDesc(idallow) {
    let nameAllow = "";

    let array_allow = dataAllowance.filter((grp) => {
      return grp.id === Number(idallow);
    });

    if (array_allow.length > 0) {
      nameAllow = array_allow[0].value;
    }

    return nameAllow;
  }

  const toggleBtnReadMore = (index, bool) => (e) => {
    setReadMore({
      id: index,
      more: bool,
    });
  };

  const btnCloseOption = () => {
    setShowOption(false);
  };

  const [errorOpt, setErrorOpt] = useState({});

  const formExpOption = (object) => {
    const newErrors = {};

    if (!object.txtAllowDesc || object.txtAllowDesc === "")
      newErrors.txtAllowDesc = "Fill in the blank!";

    return newErrors;
  };

  const btnNewOption = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dataOpt = Object.fromEntries(data.entries());

    // console.log("dataOpt",dataOpt)
    const newErrors = formExpOption(dataOpt);

    if (Object.keys(newErrors).length > 0) {
      setErrorOpt(newErrors);
    } else {
      // console.log("dataOpt.txtAllowDesc", allowList)

      // const allowCode = allowList.find(e => e.value === dataOpt.txtAllowDesc.trim());

      var allowCode = dataAllowance.filter(function (allow) {
        return (
          allow.value.toLowerCase() ===
          dataOpt.txtAllowDesc.trim().toLowerCase()
        );
      });

      // console.log("allowCode", allowCode)

      if (allowCode.length > 0) {
        customSwal.fire({
          title: "This allowance description duplicate!",
          icon: "error",
          confirmButtonText: "Okay",
        });

        // setShowOption(false);
      } else {
        customSwal
          .fire({
            title: "Do you want to save new allowance?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post("option/store", {
                group: "Allowance",
                code: dataOpt.txtAllowDesc,
                value: dataOpt.txtAllowDesc,
                status: "active",
              })
                .then((response) => {
                  // console.log("option/store", response.data);
                  dispatch(getListOptions());
                  setShowOption(false);
                })
                .catch((error) => {
                  console.log("option/update", error);
                });
            }
          });
      }
    }
  };

  // End Experience Function

  // Referee Function
  const btnAddRef = (index) => {
    if (clickedRef === index) {
      //if clicked is already active, then close
      return setClickedRef(null);
    }

    setRefStatus("New");
    btnResetRef();
    setClickedRef(index);
  };

  const btnSaveRef = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);

    const dataReferee = Object.fromEntries(data.entries());

    if (refStatus === "New") {
      const refLength = refereeList.length + 1;

      setRefereeList((items) => [
        ...items,
        {
          id: refLength,
          name: dataReferee.txtrefname,
          job_title: dataReferee.txtrefjobtitle,
          email: dataReferee.txtrefemail,
          phone: dataReferee.txtrefphone,
          current_company: dataReferee.txtrefcompany,
          relationship: dataReferee.txtrefrelationship,
        },
      ]);

      setClickedRef(false);
    } else {
      const filteredRef = refereeList[refStatus];

      filteredRef.name = dataReferee.txtrefname;
      filteredRef.job_title = dataReferee.txtrefjobtitle;
      filteredRef.email = dataReferee.txtrefemail;
      filteredRef.phone = dataReferee.txtrefphone;
      filteredRef.current_company = dataReferee.txtrefcompany;
      filteredRef.relationship = dataReferee.txtrefrelationship;

      setClickedRef(false);
    }
  };

  const btnEditRef = (id) => (e) => {
    const filteredRef = refereeList[id];

    refstate.txtrefname = filteredRef.name;
    refstate.txtrefjobtitle = filteredRef.job_title;
    refstate.txtrefemail = filteredRef.email;
    refstate.txtrefphone = filteredRef.phone;
    refstate.txtrefcompany = filteredRef.current_company;
    refstate.txtrefrelationship = filteredRef.relationship;

    setRefStatus(id);
    setClickedRef(true);
  };

  const btnResetRef = (event) => {
    refstate.txtrefname = null;
    refstate.txtrefjobtitle = null;
    refstate.txtrefemail = null;
    refstate.txtrefphone = null;
    refstate.txtrefrelationship = null;
    refstate.txtrefcompany = null;
  };

  const btnDelRef = (index) => {
    // console.log("idref", id);
    let data = [...refereeList];
    data.splice(index, 1);
    setRefereeList(data);
  };
  // End Referee Function

  // Miscellaneous Function
  const btnAddMisc = (index) => {
    if (clickedMisc === index) {
      //if clicked is already active, then close
      return setClickedMisc(null);
    }

    setMiscStatus("New");
    btnResetMisc();
    setClickedMisc(index);
  };

  const btnSaveMisc = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);

    const dataMisc = Object.fromEntries(data.entries());

    if (miscStatus === "New") {
      const miscellLength = miscellaneousList.length + 1;

      setMiscellaneousList((items) => [
        ...items,
        {
          id: miscellLength,
          misc: dataMisc.txtmiscdesc,
        },
      ]);

      setClickedMisc(false);
    } else {
      const filteredMisc = miscellaneousList[miscStatus]

      filteredMisc.misc = dataMisc.txtmiscdesc;

      setClickedMisc(false);
    }
  };

  const btnEditMisc = (id) => (e) => {
    const filteredMisc = miscellaneousList[id];

    miscstate.txtmiscdesc = filteredMisc.misc;

    setMiscStatus(id);
    setClickedMisc(true);
  };

  const btnResetMisc = (event) => {
    miscstate.txtmiscdesc = null;
  };

  const btnDelMisc = (index) => {
    let data = [...miscellaneousList];
    data.splice(index, 1);
    setMiscellaneousList(data);
  };
  // End Miscellaneous Function

  const [selectedLang, setSelectedLang] = useState([]);

  const handleLanguages = (e) => {
    setSelectedLang(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const [selectedInd, setSelectedInd] = useState([]);

  const handleIndustry = (e) => {
    setSelectedInd(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const [selectedJob, setSelectedJob] = useState([]);

  const handleJobFunc = (e) => {
    setSelectedJob(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const uploadSingleFile = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setProfileImg(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  // Function Event

  useEffect(() => {
    // getListOption();

    dispatch(getClientDetails(params.id));

    if (params.page === "active") {
      dispatch(storeTitle({ title: "Clients", icon: mdiAccountGroup }));
      setCrumbs(["Clients", clientDetails.name, "Add Staff"]);
    } else if (params.page === "potential") {
      dispatch(
        storeTitle({ title: "Potential Clients", icon: mdiAccountGroup })
      );
      setCrumbs(["Potential Clients", clientDetails.name, "Add Staff"]);
    } else {
      dispatch(storeTitle({ title: "Companies", icon: mdiAccountGroup }));
      setCrumbs(["Companies", clientDetails.name, "Add Staff"]);
    }
  }, [clientDetails.name, dispatch, params.id, params.page]);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const salaryTxt = useRef(null);

  const handleSalary = useCallback((event) => {
    const value = event.target.value;

    salaryTxt.current = value;
  }, []);

  return (
    <>
      {/* <Prompt message="You have unsaved changes, are you sure you want to leave?" /> */}
      <div className="main-div create-st">
        <div className="container-div both-side">
          <BreadCrumb crumbs={crumbs} selected={selectedCrumb}></BreadCrumb>
          <Button variant="secondary" onClick={() => navigate(-1)}>
            <Icon path={mdiArrowLeft} />
            Back
          </Button>
        </div>

        <div className="top-title-div">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Basic Information</Accordion.Header>
              <Accordion.Body>
                <Form id="basic-form" onSubmit={btnSaveCandidate}>
                  {/* 1 */}
                  <Row className="row-upload-pic mb-3">
                    <div className="img-box">
                      <img
                        src={profileImg}
                        alt=""
                        width="140px"
                        height="137px"
                        size="cover"
                      />
                    </div>
                    <Form.Group controlId="formFile" className="mb-3 col-4">
                      <Form.Control
                        onChange={uploadSingleFile}
                        name="txtprofile"
                        type="file"
                        accept=".png,.jpg,.jpeg"
                      />
                      <Form.Text className="text-muted">
                        Allowed file types: jpg, jpeg, png.
                      </Form.Text>
                    </Form.Group>
                  </Row>
                  {/* 2 */}
                  <Row className="create-st-form">
                    <Form.Group className="mb-3 col-6">
                      <Form.Label>Salutation</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="ddlSalutation"
                        defaultValue={staffState.ddlSalutation}
                        // isInvalid={!!errorsBasic.ddlSalutation}
                        ref={formRef}
                      >
                        <option value="">Select</option>
                        {dataSalute.map((salute, index) => (
                          <option key={index} value={salute.value}>
                            {salute.value}
                          </option>
                        ))}
                      </Form.Select>
                      {/* <Form.Control.Feedback type="invalid">
                        {errorsBasic.ddlSalutation}
                      </Form.Control.Feedback> */}
                    </Form.Group>

                    <Form.Group className="mb-3 col-6">
                      <Form.Label>
                        Job Title <label className="validation-star">*</label>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="txtjobtitle"
                        placeholder="Please enter job title"
                        isInvalid={!!errorsBasic.txtjobtitle}
                        ref={formRef}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorsBasic.txtjobtitle}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  {/* 3 */}
                  <Row className="create-st-form">
                    <Form.Group className="mb-3 col-6">
                      <Form.Label>
                        First Name <label className="validation-star">*</label>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Please enter your first name"
                        name="txtfirstname"
                        isInvalid={!!errorsBasic.txtfirstname}
                        ref={formRef}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorsBasic.txtfirstname}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3 col-6">
                      <Form.Label>
                        Last Name <label className="validation-star">*</label>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Please enter your last name"
                        name="txtlastname"
                        isInvalid={!!errorsBasic.txtlastname}
                        ref={formRef}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorsBasic.txtlastname}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  {/* 4 */}
                  <Row className="create-st-form">
                    <Form.Group className="mb-3 col-6">
                      <Form.Label>Company Email Address </Form.Label>
                      <Form.Control
                        name="txtcompemail"
                        type="email"
                        placeholder="Please enter your company email address"

                        // isInvalid={!!errorsBasic.txtjobtitle}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                        {errorsBasic.txtjobtitle}
                      </Form.Control.Feedback> */}
                    </Form.Group>

                    <Form.Group className="mb-3 col-6">
                      <Form.Label>
                        Personal Email Address{" "}
                        {/* <label className="validation-star">*</label> */}
                      </Form.Label>
                      <Form.Control
                        name="txtemail"
                        type="email"
                        placeholder="Please enter your personal email address"
                        // isInvalid={!!errorsBasic.txtemail}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                        {errorsBasic.txtemail}
                      </Form.Control.Feedback> */}
                    </Form.Group>
                  </Row>
                  {/* 5 */}
                  <Row className="create-st-form">
                    <Form.Group className="mb-3 col-6">
                      <Form.Label>Direct Line Number</Form.Label>
                      <Form.Control
                        type="text"
                        name="txtdirectline"
                        placeholder="Please enter your direct line number"
                        // isInvalid={!!errorsBasic.txtdirectline}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                        {errorsBasic.txtdirectline}
                      </Form.Control.Feedback> */}
                    </Form.Group>

                    <Form.Group className="mb-3 col-6">
                      <Form.Label>
                        Mobile Number{" "}
                        {/* <label className="validation-star">*</label> */}
                      </Form.Label>
                      <Form.Control
                        name="txtphone"
                        as="textarea"
                        rows={1}
                        placeholder="Please enter your mobile number"
                      />
                      {/* <div className="col-3">
                          <Form.Select
                            name="txtcountrycode"
                            isInvalid={!!errorsBasic.txtcountrycode}
                          >
                            <option value="">Select</option>
                            {phoneCodeList.map((pcode, index) => (
                              <option key={index} value={pcode.value}>
                                {pcode.value}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errorsBasic.txtcountrycode}
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-9">
                          <Form.Control
                            name="txtphone"
                            type="number"
                            isInvalid={!!errorsBasic.txtphone}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errorsBasic.txtphone}
                          </Form.Control.Feedback>
                        </div> */}
                    </Form.Group>
                  </Row>
                  {/* 6 */}
                  <Row className="create-st-form">
                    <Form.Group className="mb-3 col-6">
                      <Form.Label>Country</Form.Label>
                      <Searchable
                        name="txtcountry"
                        isMulti={false}
                        options={dataCountry}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.value}
                      ></Searchable>
                    </Form.Group>

                    <Form.Group
                      className="mb-3 col-6"
                      controlId="formBasicPassword"
                    >
                      <Form.Label>Languages</Form.Label>
                      <Searchable
                        name="txtlanguages"
                        isMulti={true}
                        options={dataLanguages}
                        isInvalid={!!errorsBasic.ddllang}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.value}
                        // value={langList.filter((obj) =>
                        //   selectedValue.includes(obj.value)
                        // )}
                        onChange={handleLanguages}
                        // errorText={errorsBasic.ddllang}
                      ></Searchable>
                    </Form.Group>
                  </Row>
                  {/* 7 */}
                  <Row className="create-st-form">
                    <Form.Group className="mb-3 col-6">
                      <Form.Label>LinkedIn Profile</Form.Label>
                      <Form.Control
                        name="txtlinked"
                        type="text"
                        placeholder="http://"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3 col-6">
                      <Form.Label>Instagram Profile</Form.Label>
                      <Form.Control
                        name="txtinsta"
                        type="text"
                        placeholder="http://"
                      />
                    </Form.Group>
                  </Row>
                  {/* 8 */}
                  <Row className="create-st-form">
                    <Form.Group className="mb-3 col-6">
                      <Form.Label>Facebook Profile</Form.Label>
                      <Form.Control
                        name="txtfacebook"
                        type="text"
                        placeholder="http://"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3 col-6">
                      <Form.Label>Others Website</Form.Label>
                      <Form.Control
                        name="txtothweb"
                        type="text"
                        placeholder="http://"
                      />
                    </Form.Group>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>Education Information</Accordion.Header>
              <Accordion.Body>
                <div className="container-info">
                  {clickedEdu === false ? (
                    <Button
                      onClick={() => btnAddEdu(true)}
                      variant="link"
                      className="modal-link"
                    >
                      <Icon path={mdiPlusCircle} />
                      Add Education
                    </Button>
                  ) : (
                    <div
                      className={
                        "add-edu-container" + (clickedEdu ? " in" : "")
                      }
                    >
                      {/* 1 */}
                      <div className="container-bg-col-div">
                        <div className="container-form-div">
                          <Form noValidate onSubmit={btnSaveEdu}>
                            {/* 1.1  */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                School{" "}
                                <label className="validation-star">*</label>
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  name="txtschool"
                                  type="text"
                                  defaultValue={edustate.txtschool}
                                  isInvalid={!!errorsEdu.txtschool}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errorsEdu.txtschool}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                            {/* 1.2 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Degree
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  name="txtdegree"
                                  type="text"
                                  defaultValue={edustate.txtdegree}
                                  // isInvalid={!!errorsEdu.txtdegree}
                                />
                                {/* <Form.Control.Feedback type="invalid">
                                  {errorsEdu.txtdegree}
                                </Form.Control.Feedback> */}
                              </Col>
                            </Form.Group>
                            {/* 1.3 */}
                            <Row>
                              <Col xs={6}>
                                <Form.Group as={Row} className="mb-3">
                                  <Form.Label column lg={4}>
                                    Duration
                                  </Form.Label>
                                  <Col>
                                    <Form.Select
                                      name="txtfromyear"
                                      defaultValue={edustate.txtfromyear}
                                      // isInvalid={!!errorsEdu.txtfromyear}
                                    >
                                      <option value="">
                                        Select Start Year
                                      </option>
                                      {dataYear.map((year, index) => (
                                        <option key={index} value={year.value}>
                                          {year.label}
                                        </option>
                                      ))}
                                    </Form.Select>
                                    {/* <Form.Control.Feedback type="invalid">
                                      {errorsEdu.txtfromyear}
                                    </Form.Control.Feedback> */}
                                  </Col>
                                </Form.Group>
                              </Col>
                              <Col xs={6}>
                                <Form.Group as={Row} className="mb-3">
                                  <Form.Label
                                    column
                                    lg={4}
                                    style={{ textAlign: "center" }}
                                    className="edu-duration"
                                  >
                                    to
                                  </Form.Label>
                                  <Col>
                                    <Form.Select
                                      name="txttoyear"
                                      defaultValue={edustate.txttoyear}
                                      isInvalid={!!errorsEdu.txttoyear}
                                    >
                                      <option value="">Select End Year</option>
                                      {dataYear.map((year, index) => (
                                        <option key={index} value={year.value}>
                                          {year.label}
                                        </option>
                                      ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                      {errorsEdu.txttoyear}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>
                            {/* 1.4 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Certificates
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  name="txtcertificate"
                                  type="file"
                                />
                                <Form.Control.Feedback type="invalid">
                                  Fiil in the blank!
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>

                            <div className="row-btn-bottom">
                              <Button
                                onClick={() => btnAddEdu(false)}
                                variant="secondary"
                              >
                                Cancel
                              </Button>
                              <Button variant="primary" type="submit">
                                Save
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="container-info edu-added">
                  {clickedEdu === false ? (
                    <div className="column-card">
                      {educationList.map((education, index) => (
                        <Card key={index}>
                          <div className="card-edu-row">
                            <div className="card-edu-info">
                              <div>{education.name}</div>
                              <div>{education.qualification}</div>
                              <div>
                                {education.start_year} - {education.end_year}
                              </div>
                            </div>
                            <div className="card-edu-show-cert">
                              <div>
                                <Button
                                  variant="link"
                                  className="no-underline"
                                  onClick={btnDownload(
                                    education.id,
                                    education.certificate_attachment
                                  )}
                                >
                                  <Icon path={mdiFile} />
                                  Show Certificate
                                </Button>
                              </div>
                            </div>
                            <div className="card-edu-btn">
                              <Button
                                onClick={btnEditEdu(index)}
                                variant="link"
                                className="modal-link"
                              >
                                <Icon path={mdiPencil} />
                              </Button>
                              <Button
                                variant="link"
                                className="delete-link"
                                onClick={() => btnDelEdu(index)}
                              >
                                <Icon path={mdiDelete} />
                              </Button>
                            </div>
                          </div>
                        </Card>
                      ))}
                    </div>
                  ) : null}
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>Experience Information</Accordion.Header>
              <Accordion.Body>
                <div className="container-info">
                  {clickedExp === false ? (
                    <Button
                      onClick={() => btnAddExp(true)}
                      variant="link"
                      className="modal-link"
                    >
                      <Icon path={mdiPlusCircle} />
                      Add Work Experience
                    </Button>
                  ) : (
                    <div
                      className={
                        "add-edu-container" + (clickedExp ? " in" : "")
                      }
                    >
                      {/* 2 */}
                      <div className="container-bg-col-div">
                        <div className="container-form-div">
                          {/* onSubmit={btnSaveExp} */}
                          <Form noValidate onSubmit={btnSaveExp}>
                            {/* 2.1 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Job Title{" "}
                                <label className="validation-star">*</label>
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  name="txtjobtitle"
                                  type="text"
                                  defaultValue={expstate.txtjobtitle}
                                  isInvalid={!!errorsExp.txtjobtitle}
                                  ref={formRef}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errorsExp.txtjobtitle}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                            {/* 2.2 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Job Function
                              </Form.Label>
                              <Col>
                                <Searchable
                                  name="ddljobfunction"
                                  isMulti={true}
                                  options={dataJobFunction}
                                  getOptionValue={(option) => option.value}
                                  getOptionLabel={(option) => option.value}
                                  onChange={handleJobFunc}
                                  defaultValue={dataJobFunction.filter((obj) =>
                                    expstate.ddljobfunction.includes(obj.value)
                                  )}
                                ></Searchable>
                              </Col>
                            </Form.Group>
                            {/* 2.3 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Company Name{" "}
                                <label className="validation-star">*</label>
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  name="txtcompanyname"
                                  type="text"
                                  defaultValue={expstate.txtcompanyname}
                                  isInvalid={!!errorsExp.txtcompanyname}
                                  ref={formRef}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errorsExp.txtcompanyname}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                            {/* 2.4 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Industry
                              </Form.Label>
                              <Col>
                                <Searchable
                                  name="ddlIndustry"
                                  isMulti={true}
                                  options={dataIndustry}
                                  getOptionValue={(option) => option.value}
                                  getOptionLabel={(option) => option.value}
                                  onChange={handleIndustry}
                                  defaultValue={dataIndustry.filter((obj) =>
                                    expstate.ddlIndustry.includes(obj.value)
                                  )}
                                ></Searchable>
                              </Col>
                            </Form.Group>
                            {/* 2.5 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Country{" "}
                                <label className="validation-star">*</label>
                              </Form.Label>
                              <Col>
                                <Searchable
                                  name="ddlCountry"
                                  isMulti={false}
                                  options={dataCountry}
                                  getOptionValue={(option) => option.value}
                                  getOptionLabel={(option) => option.value}
                                  isInvalid={!!errorsExp.ddlCountry}
                                  defaultValue={expstate.ddlCountry}
                                  errorText={errorsExp.ddlCountry}
                                  ref={formRef}
                                ></Searchable>
                              </Col>
                            </Form.Group>
                            {checked === true ? (
                              <Form.Group as={Row} className="mb-3">
                                <Form.Label column lg={2}>
                                  Present
                                </Form.Label>
                                <Col>
                                  <Form.Select
                                    name="ddlpremonth"
                                    onChange={handlePreMonth}
                                    defaultValue={expstate.ddlpremonth}
                                    // isInvalid={!!errorsExp.ddlpremonth}
                                  >
                                    <option value="">Select</option>
                                    {monthList.map((month, index) => (
                                      <option key={index} value={month.value}>
                                        {month.label}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  {/* <Form.Control.Feedback type="invalid">
                                    {errorsExp.ddlpremonth}
                                  </Form.Control.Feedback> */}
                                </Col>
                                <Col>
                                  <Form.Select
                                    name="ddlpreyear"
                                    defaultValue={expstate.ddlpreyear}
                                    // isInvalid={!!errorsExp.ddlpreyear}
                                  >
                                    <option value="">Select</option>
                                    {dataYear.map((year, index) => (
                                      <option key={index} value={year.value}>
                                        {year.label}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  {/* <Form.Control.Feedback type="invalid">
                                    {errorsExp.ddlpreyear}
                                  </Form.Control.Feedback> */}
                                </Col>
                              </Form.Group>
                            ) : (
                              <Form.Group as={Row} className="mb-1">
                                <Form.Label column lg={2}>
                                  Joined Date
                                </Form.Label>
                                <Col>
                                  <Form.Select
                                    name="ddlstartmonth"
                                    onChange={handleStartMonth}
                                    defaultValue={expstate.ddlstartmonth}
                                    // isInvalid={!!errorsExp.ddlstartmonth}
                                  >
                                    <option value="">Select</option>
                                    {monthList.map((month, index) => (
                                      <option key={index} value={month.value}>
                                        {month.label}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  {/* <Form.Control.Feedback type="invalid">
                                    {errorsExp.ddlstartmonth}
                                  </Form.Control.Feedback> */}
                                </Col>
                                <Col>
                                  <Form.Select
                                    name="ddlstartyear"
                                    defaultValue={expstate.ddlstartyear}
                                    // isInvalid={!!errorsExp.ddlstartyear}
                                  >
                                    <option value="">Select</option>
                                    {dataYear.map((year, index) => (
                                      <option key={index} value={year.value}>
                                        {year.label}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  {/* <Form.Control.Feedback type="invalid">
                                    {errorsExp.ddlstartyear}
                                  </Form.Control.Feedback> */}
                                </Col>
                              </Form.Group>
                            )}
                            {checked === true ? (
                              ""
                            ) : (
                              <Form.Group as={Row} className="mb-1">
                                <Form.Label column lg={2}></Form.Label>
                                <Col
                                  className="exp-duration"
                                  style={{ marginLeft: "15px" }}
                                >
                                  to
                                </Col>
                              </Form.Group>
                            )}
                            {checked === true ? (
                              ""
                            ) : (
                              <Form.Group as={Row} className="mb-1">
                                <Form.Label column lg={2}>
                                  End Date
                                </Form.Label>
                                <Col>
                                  <Form.Select
                                    name="ddlendmonth"
                                    onChange={handleEndMonth}
                                    defaultValue={expstate.ddlendmonth}
                                    // isInvalid={!!errorsExp.ddlendmonth}
                                  >
                                    <option value="">Select</option>
                                    {monthList.map((month, index) => (
                                      <option key={index} value={month.value}>
                                        {month.label}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  {/* <Form.Control.Feedback type="invalid">
                                    {errorsExp.ddlendmonth}
                                  </Form.Control.Feedback> */}
                                </Col>
                                <Col>
                                  <Form.Select
                                    name="ddlendyear"
                                    defaultValue={expstate.ddlendyear}
                                    // isInvalid={!!errorsExp.ddlendyear}
                                  >
                                    <option value="">Select</option>
                                    {dataYear.map((year, index) => (
                                      <option key={index} value={year.value}>
                                        {year.label}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  {/* <Form.Control.Feedback type="invalid">
                                    {errorsExp.ddlendyear}
                                  </Form.Control.Feedback> */}
                                </Col>
                              </Form.Group>
                            )}

                            {/* 2.6 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}></Form.Label>
                              <Col>
                                <Form.Check
                                  className="current-cb"
                                  type="checkbox"
                                  id="default-checkbox"
                                  label="Currently Working"
                                  defaultChecked={expstate.cbWorkingCurrent}
                                  onChange={(e) =>
                                    setChecked(e.currentTarget.checked)
                                  }
                                />
                              </Col>
                            </Form.Group>

                            {/* 2.7 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Office Number
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  name="txtofficephone"
                                  type="number"
                                  defaultValue={expstate.txtofficephone}
                                ></Form.Control>
                              </Col>
                            </Form.Group>

                            {/* 2.8 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Office Email
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  name="txtofficeemail"
                                  type="email"
                                  defaultValue={expstate.txtofficeemail}
                                  isInvalid={!!errorsExp.txtofficeemail}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errorsExp.txtofficeemail}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>

                            {/* 2.9 */}
                            {checked === true ? (
                              <Form.Group as={Row} className="mb-3">
                                <Form.Label column lg={2}>
                                  Notice Period
                                </Form.Label>
                                <Col>
                                  <Form.Select
                                    name="ddlperiod"
                                    defaultValue={expstate.ddlperiod}
                                  >
                                    <option value="">Select</option>
                                    {noticeList.map((notice, index) => (
                                      <option key={index} value={notice.label}>
                                        {notice.label}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Col>
                              </Form.Group>
                            ) : null}

                            {/* 2.10 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Job Responsibility
                              </Form.Label>
                              <Col>
                                {/* <RichTextQuill
                                  value={txtRichJobresponsibility.current}
                                  onChange={handleJobresponsibility}
                                ></RichTextQuill> */}
                                <RichTextEditor
                                  name="txtjobresponsibility"
                                  htmlContent={expstate.txtjobresponsibility}
                                ></RichTextEditor>
                              </Col>
                            </Form.Group>

                            {/* 2.11 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Salary
                              </Form.Label>
                              <Col>
                                <Form.Select
                                  name="ddlcurrency"
                                  defaultValue={expstate.ddlcurrency}
                                  // isInvalid={!!errorsExp.ddlcurrency}
                                >
                                  <option value="">Select</option>
                                  {dataCurrency.map((currency, index) => (
                                    <option key={index} value={currency.value}>
                                      {currency.value}
                                    </option>
                                  ))}
                                </Form.Select>
                                {/* <Form.Control.Feedback type="invalid">
                                  {errorsExp.ddlcurrency}
                                </Form.Control.Feedback> */}
                              </Col>
                              <Col>
                                <TextFieldFormat
                                  name="txtsalary"
                                  value={salaryTxt.current}
                                  onChange={handleSalary}
                                  thousandSeparator={true}
                                ></TextFieldFormat>
                               
                                {/* <Form.Control.Feedback type="invalid">
                                  {errorsExp.txtsalary}
                                </Form.Control.Feedback> */}
                              </Col>
                            </Form.Group>

                            {/* 2.12 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Allowance
                              </Form.Label>
                              <Col>
                                <CustomTable
                                  headers={headers}
                                  items={allowanceList}
                                ></CustomTable>
                              </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}></Form.Label>
                              <Col>
                                <Button
                                  onClick={btnAddNewAllowance}
                                  variant="primary"
                                >
                                  Add New Allowance
                                </Button>
                              </Col>
                            </Form.Group>

                            {/* 2.13 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Other Benefits
                              </Form.Label>
                              <Col>
                                {/* <RichTextQuill
                                  value={txtRichOthbenefit.current}
                                  onChange={handleOthbenefit}
                                ></RichTextQuill> */}
                                <RichTextEditor
                                  name="txtothbenefit"
                                  htmlContent={expstate.txtothbenefit}
                                ></RichTextEditor>
                              </Col>
                            </Form.Group>

                            {/* 2.14 */}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column lg={2}>
                                Reason for leaving
                              </Form.Label>
                              <Col>
                                {/* <RichTextQuill
                                  value={txtRichReason.current}
                                  onChange={handleReason}
                                ></RichTextQuill> */}
                                <RichTextEditor
                                  name="txtreason"
                                  htmlContent={expstate.txtreason}
                                ></RichTextEditor>
                              </Col>
                            </Form.Group>

                            {/* button Experience */}
                            <div className="row-btn-bottom">
                              <Button
                                onClick={() => btnAddExp(false)}
                                variant="secondary"
                              >
                                Cancel
                              </Button>
                              <Button type="submit" variant="primary">
                                Save
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="container-info">
                  {clickedExp === false ? (
                    <div className="column-card">
                      {experienceList.map((experience, index) => (
                        <div key={index}>
                          <div className="cdt-row">
                            <div className="col-5">
                              <div className="big-text">{experience.title}</div>
                              <div className="sub-text">
                                {experience.company_name}
                              </div>
                              {experience.current_working ? (
                                <div
                                  className="sub-text"
                                  style={{ fontWeight: 400 }}
                                >
                                  {experience.present_month
                                    ? toMonthName(experience.present_month)
                                    : ""}{" "}
                                  {experience.present_year
                                    ? experience.present_year
                                    : ""}{" "}
                                  - Current
                                </div>
                              ) : (
                                <div
                                  className="sub-text"
                                  style={{ fontWeight: 400 }}
                                >
                                  {experience.start_date_month
                                    ? toMonthName(experience.start_date_month)
                                    : ""}{" "}
                                  {experience.start_date_year
                                    ? experience.start_date_year
                                    : ""}{" "}
                                  -{" "}
                                  {experience.end_date_month
                                    ? toMonthName(experience.end_date_month)
                                    : ""}{" "}
                                  {experience.end_date_year
                                    ? experience.end_date_year
                                    : ""}
                                </div>
                              )}
                            </div>
                            <div className="col-6" style={{ display: "flex" }}>
                              <div className="col-11">
                                <div className="more-section-row">
                                  <div className="mini-text flex-left">
                                    Salary
                                  </div>
                                  <div className="sub-text flex-right">
                                    {experience.currency}{" "}
                                    {Number(experience.salary)
                                      .toFixed(2)
                                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                  </div>
                                </div>
                                <div className="more-section-row">
                                  <div className="mini-text flex-left">
                                    Direct Line
                                  </div>
                                  <div className="sub-text flex-right">
                                    {experience.office_number
                                      ? experience.office_number
                                      : "-"}
                                  </div>
                                </div>
                                <div className="more-section-row">
                                  <div className="mini-text flex-left">
                                    Office Email
                                  </div>
                                  <div className="sub-text flex-right">
                                    {experience.office_email
                                      ? experience.office_email
                                      : "-"}
                                  </div>
                                </div>

                                {experience.current_working ? (
                                  <div className="more-section-row">
                                    <div className="mini-text flex-left">
                                      Notice Period
                                    </div>
                                    <div className="sub-text flex-right">
                                      {experience.notice_period
                                        ? experience.notice_period
                                        : "-"}
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-1">
                                {/* {editExp === false ? null : ( */}
                                <div className="exp-button">
                                  <Button
                                    variant="link"
                                    className="modal-link"
                                    onClick={btnEditExp(index)}
                                  >
                                    <Icon path={mdiPencil} />
                                  </Button>
                                  <Button
                                    variant="link"
                                    className="delete-link"
                                    style={{ margin: 0 }}
                                    onClick={() => btnDelExp(index)}
                                  >
                                    <Icon path={mdiDelete} />
                                  </Button>
                                </div>
                                {/* )} */}
                              </div>
                            </div>
                          </div>

                          {/* Display if Read More button is clicked */}
                          {isReadMore.more === false &&
                          isReadMore.id === index ? (
                            <div className="cdt-row">
                              <div className="col-5">
                                {experience.reason_leaving ? (
                                  <>
                                    <div className="text">
                                      Reason for leaving
                                    </div>
                                    <div
                                      className="mini-text"
                                      dangerouslySetInnerHTML={{
                                        __html: experience.reason_leaving,
                                      }}
                                    ></div>
                                  </>
                                ) : null}

                                {experience.job_responsibility ? (
                                  <>
                                    <div className="text">
                                      Job Responsiblity
                                    </div>
                                    <div
                                      className="mini-text"
                                      dangerouslySetInnerHTML={{
                                        __html: experience.job_responsibility,
                                      }}
                                    ></div>
                                  </>
                                ) : null}
                              </div>
                              <div className="col-6">
                                <div className="col-11">
                                  {experience.allowance.length > 0 ? (
                                    <div className="more-section-row">
                                      <div className="mini-text flex-left">
                                        Allowance
                                      </div>
                                      <div style={{ flex: "1 1 60%" }}>
                                        {experience.allowance.map(
                                          (allow, index) => (
                                            <div
                                              className="sub-text flex-right"
                                              key={index}
                                            >
                                              {toAllowanceDesc(allow.earn)} -{" "}
                                              {allow.currency}{" "}
                                              {Number(allow.amount)
                                                .toFixed(2)
                                                .replace(
                                                  /\d(?=(\d{3})+\.)/g,
                                                  "$&,"
                                                )}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  ) : null}

                                  {experience.other_benefits ? (
                                    <div className="more-section-row">
                                      <div className="mini-text flex-left">
                                        Other Benefits
                                      </div>
                                      <div
                                        className="sub-text flex-right"
                                        dangerouslySetInnerHTML={{
                                          __html: experience.other_benefits
                                            ? experience.other_benefits
                                            : "-",
                                        }}
                                      ></div>
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-1"></div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {experience.other_benefits ||
                          experience.job_responsibility ||
                          experience.reason_leaving ||
                          experience.allowance.length > 0 ? (
                            <div className="cdt-row">
                              <div className="col-6"></div>
                              <div className="col-6">
                                <div className="col-11">
                                  <div className="more-section-row">
                                    <div className="mini-text flex-left">
                                      {""}
                                    </div>
                                    <div className="sub-text flex-right">
                                      {isReadMore.more === false &&
                                      isReadMore.id === index ? (
                                        <Button
                                          variant="link"
                                          className="read-btn"
                                          onClick={toggleBtnReadMore(
                                            index,
                                            true
                                          )}
                                        >
                                          Less details
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="link"
                                          className="read-btn"
                                          onClick={toggleBtnReadMore(
                                            index,
                                            false
                                          )}
                                        >
                                          More details
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-1"></div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>Reference Information</Accordion.Header>
              <Accordion.Body>
                <div className="container-info">
                  {clickedRef === false ? (
                    <Button
                      onClick={() => btnAddRef(true)}
                      variant="link"
                      className="modal-link"
                    >
                      <Icon path={mdiPlusCircle} />
                      Add Reference
                    </Button>
                  ) : (
                    <div
                      className={
                        "add-edu-container" + (clickedRef ? " in" : "")
                      }
                    >
                      {/* 3 */}
                      <div className="container-bg-col-div">
                        <div className="container-form-div">
                          <Form id="add-ref" onSubmit={btnSaveRef}>
                            {/* 3.1 */}
                            <Row className="add-reference">
                              <Col xs={6}>
                                <Form.Group as={Row} className="mb-3">
                                  <Form.Label column lg={4}>
                                    Referee Name
                                  </Form.Label>
                                  <Col xs={8}>
                                    <Form.Control
                                      name="txtrefname"
                                      type="text"
                                      defaultValue={refstate.txtrefname}
                                    />
                                  </Col>
                                </Form.Group>
                              </Col>
                              <Col xs={6}>
                                <Form.Group as={Row} className="mb-3">
                                  <Form.Label column lg={4}>
                                    Referee Job Title
                                  </Form.Label>
                                  <Col>
                                    <Form.Control
                                      name="txtrefjobtitle"
                                      type="text"
                                      defaultValue={refstate.txtrefjobtitle}
                                    />
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>
                            {/* 3.2 */}
                            <Row className="add-reference">
                              <Col xs={6}>
                                <Form.Group as={Row} className="mb-3">
                                  <Form.Label column lg={4}>
                                    Email Address
                                  </Form.Label>
                                  <Col xs={8}>
                                    <Form.Control
                                      name="txtrefemail"
                                      type="text"
                                      defaultValue={refstate.txtrefemail}
                                    />
                                  </Col>
                                </Form.Group>
                              </Col>
                              <Col xs={6}>
                                <Form.Group as={Row} className="mb-3">
                                  <Form.Label column lg={4}>
                                    Contact Number
                                  </Form.Label>
                                  <Col>
                                    <Form.Control
                                      name="txtrefphone"
                                      type="text"
                                      defaultValue={refstate.txtrefphone}
                                    />
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>
                            {/* 3.3 */}
                            <Row className="add-reference">
                              <Col xs={6}>
                                <Form.Group as={Row} className="mb-3">
                                  <Form.Label column lg={4}>
                                    Current Company
                                  </Form.Label>
                                  <Col xs={8}>
                                    <Form.Control
                                      name="txtrefcompany"
                                      type="text"
                                      defaultValue={refstate.txtrefcompany}
                                    />
                                  </Col>
                                </Form.Group>
                              </Col>
                              <Col xs={6}>
                                <Form.Group as={Row} className="mb-3">
                                  <Form.Label column lg={4}>
                                    Relationship
                                  </Form.Label>
                                  <Col>
                                    <Form.Control
                                      name="txtrefrelationship"
                                      type="text"
                                      defaultValue={refstate.txtrefrelationship}
                                    />
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>

                            {/* button Reference */}
                            <div className="row-btn-bottom">
                              <Button
                                onClick={() => btnAddRef(false)}
                                variant="secondary"
                              >
                                Cancel
                              </Button>
                              <Button variant="primary" type="submit">
                                Save
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="container-info">
                  {clickedRef === false ? (
                    <div className="column-card">
                      {refereeList.map((referee, index) => (
                        <Card className="ref-card" key={index}>
                          {/* <div className="card-ref-row"> */}
                          <div className="card-ref-box col-4">
                            <div className="card-referee">
                              Referee {index + 1}
                              <div style={{ display: "flex" }}>
                                <Button
                                  onClick={btnEditRef(index)}
                                  variant="link"
                                  className="modal-link"
                                >
                                  <Icon path={mdiPencil} />
                                </Button>
                                <Button
                                  onClick={() => btnDelRef(index)}
                                  variant="link"
                                  className="delete-link"
                                >
                                  <Icon path={mdiDelete} />
                                </Button>
                              </div>
                            </div>
                            <div className="card-text-style">
                              {referee.name}
                            </div>
                            <div className="card-text-style">
                              {referee.job_title}, {referee.current_company}
                            </div>
                            <div className="card-text-style">
                              {referee.email}
                            </div>
                            <div className="card-text-style">
                              {referee.phone}
                            </div>
                          </div>
                          {/* </div> */}
                        </Card>
                      ))}
                    </div>
                  ) : null}
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>Miscellaneous Information</Accordion.Header>
              <Accordion.Body>
                <div className="container-info">
                  {clickedMisc === false ? (
                    <Button
                      onClick={() => btnAddMisc(true)}
                      variant="link"
                      className="modal-link"
                    >
                      <Icon path={mdiPlusCircle} />
                      Add Miscellaneous
                    </Button>
                  ) : (
                    <div
                      className={
                        "add-edu-container" + (clickedMisc ? " in" : "")
                      }
                    >
                      {/* 4 */}
                      <div className="container-bg-col-div">
                        <div className="container-form-div">
                          <Form onSubmit={btnSaveMisc}>
                            {/* 4.1 */}
                            <Form.Group as={Row} className="mb-3">
                              <Col xs={12}>
                                <Form.Control
                                  name="txtmiscdesc"
                                  as="textarea"
                                  rows={5}
                                  placeholder="Must be less than 500 characters"
                                  maxLength={characterLimit}
                                  defaultValue={miscstate.txtmiscdesc}
                                />
                              </Col>
                            </Form.Group>

                            {/* button Reference */}
                            <div className="row-btn-bottom">
                              <Button
                                onClick={() => btnAddMisc(false)}
                                variant="secondary"
                              >
                                Cancel
                              </Button>
                              <Button variant="primary" type="submit">
                                Save
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* {console.log("sdsd", miscellaneousList)} */}
                <div className="container-info">
                  {clickedMisc === false ? (
                    <div className="column-card">
                      {miscellaneousList.map((miscell, index) => (
                        <Card key={index}>
                          <div className="card-misc-row">
                            <div className="card-misc">
                              <div>{miscell.misc} </div>
                            </div>
                            <div className="card-misc-btn">
                              <Button
                                onClick={btnEditMisc(index)}
                                variant="link"
                                className="modal-link"
                              >
                                <Icon path={mdiPencil} />
                              </Button>
                              <Button
                                onClick={() => btnDelMisc(index)}
                                variant="link"
                                className="delete-link"
                              >
                                <Icon path={mdiDelete} />
                              </Button>
                            </div>
                          </div>
                        </Card>
                      ))}
                    </div>
                  ) : null}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div className="row-btn-center">
            <Button variant="secondary" onClick={backToClientView}>
              Discard
            </Button>
            <Button variant="primary" type="submit" form="basic-form">
              Create New Staff
            </Button>
          </div>
        </div>
      </div>

      {/* Modal Allowance */}
      <Popup
        show={showOption}
        size="md"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseOption}
        title="Add Allowance"
        content={
          <>
            <Form id="option-form" onSubmit={btnNewOption}>
              {/* 1 */}
              <Form.Group as={Row} className="mb-3">
                <Form.Label>Allowance Description</Form.Label>
                <Col>
                  <Form.Control
                    name="txtAllowDesc"
                    type="text"
                    // defaultValue={expState.txtjobtitle}
                    isInvalid={!!errorOpt.txtAllowDesc}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorOpt.txtAllowDesc}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Form>
          </>
        }
        button={
          <>
            <Button onClick={btnCloseOption} variant="secondary">
              Cancel
            </Button>
            <Button type="submit" variant="primary" form="option-form">
              Save
            </Button>
          </>
        }
      />
    </>
  );
};

export default CreateStaff;
