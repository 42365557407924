import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Col, Row, Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../../core/services/api.service";

import Icon from "@mdi/react";
import { mdiArrowLeft, mdiAccountGroup } from "@mdi/js";

import BreadCrumb from "../../../components/Breadcrumb/BreadCrumb";
import RichTextEditor from "../../../components/RichTextArea/RichTextEditor";
import SwalAlert from "../../../components/Swal/SwalAlert";

import { storeTitle } from "../../../store/actions/headerTitleSlice";
import { getClientDetails } from "../../../store/actions/clientSlice";
import { getAssignmentDetails } from "../../../store/actions/assignmentSlice";

const CreateJD = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const customSwal = SwalAlert();
  const formRef = useRef();

  const { clientDetails } = useSelector((state) => state.client);
  const { assignDetails } = useSelector((state) => state.assignment);

  const [errorForm, setErrorForm] = useState({});

  const selectedCrumb = (crumb) => {
    if(crumb === "Clients"){
        navigate("/clients/listActiveC");
    }else if(crumb === "Potential Clients"){
        navigate("/clients/listPotentialC");
    }else if(crumb === clientDetails.name){
      if(params.page === "active"){
        navigate("/clients/viewActiveC/" + params.id);
      }else{
        navigate("/clients/view-potential-client/" + params.id);
      }
    }else if(crumb === assignDetails.title){
        navigate("/clients/view-assignment/" + params.page + "/" + params.id + "/" + params.idassign)
    }
  };

  const btnCancel = () => {
    navigate("/clients/view-assignment/" + params.page + "/" + params.id + "/" + params.idassign);
  };

  // Checking Error Form
  const formError = (object) => {
    const newErrors = {};

    if (!object.txtFileName || object.txtFileName === "")
      newErrors.txtFileName = "Fill in the blank!";

    if (!object.txtJobTitle || object.txtJobTitle === "")
      newErrors.txtJobTitle = "Fill in the blank!";

    return newErrors;
  };

  const btnSaveJd = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dataJD = Object.fromEntries(data.entries());

    const newErrors = formError(dataJD);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);

      for (var name in newErrors) {
        // console.log(name);

        const input = document.querySelector(`input[name=${name}]`);
        const inp_txtarea = document.querySelector(`textarea[name=${name}]`);

        if (input !== null) {
          input.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
          });
        } else {
          inp_txtarea.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
          });
        }

        // console.log("input", input, inp_txtarea);
      }
    } else {
      customSwal
        .fire({
          title: "Do you want to save new Job Description?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Api.post(
              "client/" +
                params.id +
                "/assignment/" +
                params.idassign +
                "/jd/create",
              {
                file_name: dataJD.txtFileName,
                job_title: dataJD.txtJobTitle,
                company_background: dataJD.txtCompanyBg,
                purpose_job: dataJD.txtPurposeJob,
                key_accountibility: dataJD.txtKeyaccess,
                skill_experience: dataJD.txtKnowledge,
                compensation: dataJD.txtCompensation,
                contact_person: dataJD.txtContact,
                status: "draft",
              }
            )
              .then((response) => {
                navigate(
                  "/clients/view-assignment/" +
                    params.page + "/" +
                    params.id +
                    "/" +
                    params.idassign
                );

                customSwal.fire({
                  title: "Job Description Saved Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("btnSaveJd", error);
              });
          }
        });
    }
  };

  useEffect(() => {
    dispatch(getClientDetails(params.id));
    dispatch(getAssignmentDetails(params.id, params.idassign));
    dispatch(storeTitle({title: params.page === "active" ? "Clients" : "Potential Clients", icon: mdiAccountGroup}));
  }, [dispatch, params.id, params.idassign]);

  return (
    <div className="main-div create-props">
      <div className="top-title-div both-side">
        <BreadCrumb
          crumbs={[
            params.page === "active" ? "Clients" : "Potential Clients",
            clientDetails.name,
            assignDetails.title,
            "Add Job Description",
          ]}
          selected={selectedCrumb}
        ></BreadCrumb>
        <Button variant="secondary" onClick={() => navigate(-1)}>
          <Icon path={mdiArrowLeft} color="#212529" />
          Back
        </Button>
      </div>
      <Card>
        <Card.Body>
          <Form id="create-jd-form" onSubmit={btnSaveJd}>
            {/* 1 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                File name <label className="validation-star">*</label>
              </Form.Label>
              <Col>
                <Form.Control
                  name="txtFileName"
                  type="text"
                  isInvalid={!!errorForm.txtFileName}
                  ref={formRef}
                />
                <Form.Control.Feedback type="invalid">
                  {errorForm.txtFileName}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            {/* 2 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Job title <label className="validation-star">*</label>
              </Form.Label>
              <Col>
                <Form.Control
                  name="txtJobTitle"
                  type="text"
                  isInvalid={!!errorForm.txtJobTitle}
                  ref={formRef}
                />
                <Form.Control.Feedback type="invalid">
                  {errorForm.txtJobTitle}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            {/* 3 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Company background
              </Form.Label>
              <Col>
                {/* <Form.Control name="txtCompanyBg" as="textarea" rows={3} /> */}
                <RichTextEditor name="txtCompanyBg" />
              </Col>
            </Form.Group>
            {/* 4 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Purpose of the job
              </Form.Label>
              <Col>
                {/* <Form.Control name="txtPurposeJob" as="textarea" rows={3} /> */}
                <RichTextEditor name="txtPurposeJob" />
              </Col>
            </Form.Group>
            {/* 5 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Key accountabilities
              </Form.Label>
              <Col>
                <RichTextEditor name="txtKeyaccess"></RichTextEditor>
              </Col>
            </Form.Group>
            {/* 6 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Desired knowledge, skills and experience
              </Form.Label>
              <Col>
                <RichTextEditor name="txtKnowledge"></RichTextEditor>
              </Col>
            </Form.Group>
            {/* 7 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Compensation
              </Form.Label>
              <Col>
                <Form.Control
                  as="textarea"
                  rows={2}
                  name="txtCompensation"
                  defaultValue={
                    "The remuneration package will be sufficient to attract, retain and motivate an outstanding candidate and will include company shares and business incentives."
                  }
                />
              </Col>
            </Form.Group>
            {/* 8 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Contact Person
              </Form.Label>
              <Col>
                <RichTextEditor name="txtContact"></RichTextEditor>
              </Col>
            </Form.Group>
          </Form>

          <div className="row-btn-center">
            <Button variant="secondary" onClick={btnCancel}>
              Cancel
            </Button>

            <Button variant="primary" type="submit" form="create-jd-form">
              Save Job Description
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CreateJD;
