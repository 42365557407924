import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Form, Button } from "react-bootstrap";
import Api from "../../../core/services/api.service";

import { mdiCardAccountDetails } from "@mdi/js";

import BreadCrumb from "../../../components/Breadcrumb/BreadCrumb";
import ProfileImage from "../../../assets/no-profile-photo.jpg";
import "./staff.css";

import { storeTitle } from "../../../store/actions/headerTitleSlice";

const ViewStaff = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { dataDepartment, dataSalute, dataPhoneCode } = useSelector((state) => state.option);

  const [profileImg, setProfileImg] = useState("");
  const [crumbs] = useState(["List of Staff", "View Staff"]);

  const [selectedStaff, setSelectedStaff] = useState({});
  // const [createdDate, setCreatedDate] = useState("");
  const [salute, setSalute] = useState("");
  const [status, setStatus] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [department, setDepartment] = useState(0);
  const [role, setRole] = useState("");

  const [listRole, setListRole] = useState([]);

  const selectedCrumb = (crumb) => {
    if (crumb === "List of Staff") {
      navigate("/staff/");
    }
  };

  // event button

  const btnGoEdit = (e) => {
    navigate("/editStaff/view/" + params.id);
  };

  function getListUserAccess() {
    Api.get("role")
      .then((response) => {
        let data = response.data;
        setListRole(data);
      })
      .catch((error) => {
        console.log("error getListUserAccess", error);
      });
  }

  // First Load
  useEffect(() => {

    dispatch(storeTitle({title: "List of Staff", icon: mdiCardAccountDetails}));

    Api.get("user/" + params.id + "/view")
      .then((response) => {
        // console.log("user/view", response.data);
        var data = response.data;
        // console.log("data", data);
        setSelectedStaff(data);

        setSalute(data.salutation);
        setStatus(data.status);
        setPhoneCode(data.phone_no_code);
        setDepartment(data.department_id);
        setRole(data.user_has_roles[0].roles.id);

        data.profile_picture === null
          ? setProfileImg(ProfileImage)
          : setProfileImg(data.profile_picture);
      })
      .catch((error) => {
        console.log("user/view", error);
      });
    
    getListUserAccess();
  }, [params.id, dispatch]);

  return (
    <div className="main-div">
      <div className="top-title-div">
        <BreadCrumb crumbs={crumbs} selected={selectedCrumb}></BreadCrumb>
      </div>
      <div className="bg-white-container">
        <Form>
          {/* 1 */}
          <Row className="row-upload-pic mb-3">
            <div className="img-box">
              <img src={profileImg} alt="Profile" />
            </div>
          </Row>
          {/* 2 */}
          <Row>
            <Form.Group className="mb-3 col-6">
              <Form.Label>
                Role <label className="validation-star">*</label>
              </Form.Label>
              <Form.Select
                name="ddlRole"
                value={role}
                onChange={(e) => {
                  setRole(e.target.value);
                }}
                disabled
              >
                <option value="">Select</option>
                {listRole.map((role, index) => (
                  <option key={index} value={role.id}>
                    {role.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3 col-6">
              <Form.Label>Status</Form.Label>
              <Form.Select
                name="ddlStatus"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
                disabled
              >
                <option value="">Select</option>
                <option value="active">Active</option>
                <option value="inactive">Not Active</option>
              </Form.Select>
            </Form.Group>
          </Row>
          {/* 3 */}
          <Row>
            <Form.Group className="mb-3 col-6">
              <Form.Label>Staff Number</Form.Label>
              <Form.Control
                name="txtstaffno"
                type="text"
                placeholder="123456"
                defaultValue={selectedStaff.staff_no}
                disabled
              />
            </Form.Group>

            <Form.Group className="mb-3 col-6">
              <Form.Label>Department</Form.Label>
              <Form.Select
                name="ddlDepartment"
                value={department}
                onChange={(e) => {
                  setDepartment(e.target.value);
                }}
                disabled
              >
                <option value="">Select</option>
                {dataDepartment.map((depart, index) => (
                  <option key={index} value={depart.id}>
                    {depart.value}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>
          {/* 4 */}
          <Row>
            <Form.Group className="mb-3 col-6">
              <Form.Label>Job Title</Form.Label>
              <Form.Control
                name="txtjobtitle"
                type="text"
                placeholder="Please enter job title"
                defaultValue={selectedStaff.job_title}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3 col-6">
              <Form.Label>Signature</Form.Label>
              {selectedStaff.signature ? 
                <div className="img-box">
                  <img src={selectedStaff.signature} alt="signature" style={{height: "100%", width: "100%", objectFit: "contain"}} />
                </div>:""
              }
              
            </Form.Group>
            {/* <Form.Group className="mb-3 col-6">
              <Form.Label>
                Salutation <label className="validation-star">*</label>
              </Form.Label>
              <Form.Select
                name="ddlSalute"
                value={salute}
                onChange={(e) => {
                  setSalute(e.target.value);
                }}
                disabled
              >
                <option value="">Select</option>
                {dataSalute.map((salute, index) => (
                  <option key={index} value={salute.value}>
                    {salute.value}
                  </option>
                ))}
              </Form.Select>
            </Form.Group> */}
          </Row>
          {/* 5 */}
          <Row>
            {/* <Form.Group className="mb-3 col-6">
              <Form.Label>Job Title</Form.Label>
              <Form.Control
                name="txtjobtitle"
                type="text"
                placeholder="Please enter job title"
                defaultValue={selectedStaff.job_title}
                disabled
              />
            </Form.Group> */}
            <Form.Group className="mb-3 col-6">
              <Form.Label>
                Salutation <label className="validation-star">*</label>
              </Form.Label>
              <Form.Select
                name="ddlSalute"
                value={salute}
                onChange={(e) => {
                  setSalute(e.target.value);
                }}
                disabled
              >
                <option value="">Select</option>
                {dataSalute.map((salute, index) => (
                  <option key={index} value={salute.value}>
                    {salute.value}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>
          {/* 6 */}
          <Row>
            <Form.Group className="mb-3 col-6">
              <Form.Label>
                First Name <label className="validation-star">*</label>
              </Form.Label>
              <Form.Control
                name="txtfirstname"
                type="text"
                placeholder="Please enter first name"
                defaultValue={selectedStaff.first_name}
                disabled
              />
            </Form.Group>

            <Form.Group className="mb-3 col-6">
              <Form.Label>
                Last Name <label className="validation-star">*</label>
              </Form.Label>
              <Form.Control
                name="txtlastname"
                type="text"
                placeholder="Please enter last name"
                defaultValue={selectedStaff.last_name}
                disabled
              />
            </Form.Group>
          </Row>
          {/* 7 */}
          <Row>
            <Form.Group className="mb-3 col-6">
              <Form.Label>
                Email Address <label className="validation-star">*</label>
              </Form.Label>
              <Form.Control
                name="txtemail"
                type="email"
                placeholder="Please enter email address"
                defaultValue={selectedStaff.email}
                disabled
              />
            </Form.Group>

            <Form.Group className="mb-3 col-6">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                name="txtphone"
                // className="read-num"
                as="textarea"
                defaultValue={selectedStaff.phone_no}
                rows={1}
                readOnly
                disabled
              />
              {/* <Row>
                <div className="col-3">
                  <Form.Select
                    name="ddlPhoneCode"
                    value={phoneCode}
                    onChange={(e) => {
                      setPhoneCode(e.target.value);
                    }}
                    disabled
                  >
                    <option value="">Select</option>
                    {dataPhoneCode.map((pcode, index) => (
                      <option key={index} value={pcode.value}>
                        {pcode.value}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div className="col-9">
                  <Form.Control
                    name="txtphone"
                    type="number"
                    placeholder="Please enter phone number"
                    defaultValue={selectedStaff.phone_no}
                    disabled
                  />
                </div>
              </Row> */}
            </Form.Group>
          </Row>

          <div className="row-btn-bottom">
            <Button onClick={() => navigate("/staff")} variant="secondary">
              Back to List
            </Button>
            <Button onClick={btnGoEdit} variant="primary" type="submit">
              Edit Staff
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ViewStaff;
