import { createSlice } from "@reduxjs/toolkit";
import Api from "../../core/services/api.service";

export const optionSlice = createSlice({
  name: "option",
  initialState: {
    dataCountry: [],
    dataCitizenship: [],
    dataIndustry: [],
    dataStage: [],
    dataDuration: [],
    dataLanguages: [],
    dataPhoneCode: [],
    dataSalute: [],
    dataJobFunction: [],
    dataCurrency: [],
    dataAllowance: [],
    dataDepartment: [],
    dataGender: [],
    dataMarital: [],
    dataYear: [],
    dataStockExchanges: [],
    dataTypeNote: [],
    dataReminder: [
      { value: "5", label: "5 minutes before" },
      { value: "10", label: "10 minutes before" },
      { value: "15", label: "15 minutes before" },
      { value: "30", label: "30 minutes before" },
      { value: "60", label: "1 hour before" },
      { value: "1440", label: "1 day before" },
    ],
  },
  reducers: {
    getCountry: (state, action) => {
      state.dataCountry = action.payload;
    },
    getCitizenship: (state, action) => {
      state.dataCitizenship = action.payload;
    },
    getIndustry: (state, action) => {
      state.dataIndustry = action.payload;
    },
    getStage: (state, action) => {
      state.dataStage = action.payload;
    },
    getDuration: (state, action) => {
      state.dataDuration = action.payload;
    },
    getLanguages: (state, action) => {
      state.dataLanguages = action.payload;
    },
    getPhoneCode: (state, action) => {
      state.dataPhoneCode = action.payload;
    },
    getSalute: (state, action) => {
      state.dataSalute = action.payload;
    },
    getJobFunction: (state, action) => {
      state.dataJobFunction = action.payload;
    },
    getCurrency: (state, action) => {
      state.dataCurrency = action.payload;
    },
    getAllowance: (state, action) => {
      state.dataAllowance = action.payload;
    },
    getDepartment: (state, action) => {
      state.dataDepartment = action.payload;
    },
    getGender: (state, action) => {
      state.dataGender = action.payload;
    },
    getMarital: (state, action) => {
      state.dataMarital = action.payload;
    },
    getStockExchanges: (state, action) => {
      state.dataStockExchanges = action.payload;
    },
    getYear: (state, action) => {
      state.dataYear = action.payload;
    },
    getTypeNote: (state, action) => {
      state.dataTypeNote = action.payload;
    },

  },
});

export const getListOptions = () => async (dispatch, rejectWithValue) => {
  try {
    const response = await Api.get("option/list");
    // console.log("res", response)

    let listCountry = response.data.filter((grp) =>
      grp.group.includes("Country")
    );

    let sortListCountry = listCountry.sort(function (a, b) {
      return a.value.localeCompare(b.value);
    });

    dispatch(getCountry(sortListCountry));

    let listCitizenship = response.data.filter((ctz) =>
      ctz.group.includes("Citizenship")
    );

    let sortListCitizenship = listCitizenship.sort(function (a, b) {
      return a.value.localeCompare(b.value);
    });

    dispatch(getCitizenship(sortListCitizenship));

    let listIndustries = response.data.filter((grp) =>
      grp.group.includes("Industry")
    );

    let sortListIndustries = listIndustries.sort(function (a, b) {
      return a.value.localeCompare(b.value);
    });

    dispatch(getIndustry(sortListIndustries));

    let listStage = response.data.filter((grp) =>
      grp.group.includes("Interview")
    );

    let sortListStage = listStage.sort((a, b) => a.id - b.id);

    dispatch(getStage(sortListStage));

    let listDuration = response.data.filter((grp) =>
      grp.group.includes("Duration")
    );

    let sortListDuration = listDuration.sort(
      (a, b) => Number(a.code) - Number(b.code)
    );

    dispatch(getDuration(sortListDuration));

    let listLang = response.data.filter((grp) =>
      grp.group.includes("Languages")
    );

    let sortListLang = listLang.sort(function (a, b) {
      return a.value.localeCompare(b.value);
    });

    dispatch(getLanguages(sortListLang));

    let listPhoneCode = response.data.filter((grp) =>
      grp.group.includes("Phone Code")
    );

    let sortListPhoneCode = listPhoneCode.sort(function (a, b) {
      return a.value.localeCompare(b.value);
    });

    dispatch(getPhoneCode(sortListPhoneCode));

    let listSalute = response.data.filter((grp) =>
      grp.group.includes("Salutation")
    );

    let sortListSalute = listSalute.sort(function (a, b) {
      return a.value.localeCompare(b.value);
    });

    dispatch(getSalute(sortListSalute));

    let listJobFunction = response.data.filter((grp) =>
      grp.group.includes("Job Function")
    );

    let sortListJobFunction = listJobFunction.sort(function (a, b) {
      return a.value.localeCompare(b.value);
    });

    dispatch(getJobFunction(sortListJobFunction));

    let listCurrency = response.data.filter((grp) =>
      grp.group.includes("Currency")
    );

    let sortListCurrency = listCurrency.sort(function (a, b) {
      return a.value.localeCompare(b.value);
    });

    dispatch(getCurrency(sortListCurrency));

    let listAllowance = response.data.filter((grp) =>
      grp.group.includes("Allowance")
    );

    let sortListAllowance = listAllowance.sort(function (a, b) {
      return a.value.localeCompare(b.value);
    });

    dispatch(getAllowance(sortListAllowance));

    let listDepart = response.data.filter((grp) =>
      grp.group.includes("Department")
    );

    dispatch(getDepartment(listDepart));

    let listGender = response.data.filter((grp) =>
      grp.group.includes("Gender")
    );

    dispatch(getGender(listGender));

    let listMarital = response.data.filter((grp) =>
      grp.group.includes("Marital")
    );

    dispatch(getMarital(listMarital));

    let listStockExchanges = response.data.filter((grp) =>
      grp.group.includes("Stock Exchange")
    );

    dispatch(getStockExchanges(listStockExchanges));
  } catch (err) {
    throw rejectWithValue(err.message);
  }
};

export const getListYears = () => async (dispatch) => {
  let currentYear = new Date().getFullYear();
  let earliestYear = 1970;
  let yearList = [];

  while (currentYear >= earliestYear) {
    yearList.push({
      value: currentYear,
      label: currentYear,
    });

    currentYear -= 1;
  }

  dispatch(getYear(yearList));
};

export const getListTypeNote = (id) => async (dispatch, rejectWithValue) => {
  try {
    const response = await Api.get("candidate/" + id + "/ddl/notes");

    dispatch(getTypeNote(response.data));
  } catch (err) {
    throw rejectWithValue(err.message);
  }
};

export const {
  addTodo,
  getCountry,
  getCitizenship,
  getIndustry,
  getStage,
  getDuration,
  getLanguages,
  getPhoneCode,
  getSalute,
  getJobFunction,
  getCurrency,
  getAllowance,
  getDepartment,
  getGender,
  getMarital,
  getYear,
  getTypeNote,
  getStockExchanges
} = optionSlice.actions;
export const showTodo = (state) => state.todo.data;
export default optionSlice.reducer;
