import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import SubMenu from "./SubMenu";
import logo from "../../assets/logo.png";
import squareLogo from "../../assets/square-logo.jpeg";
import "./header.css";

import Icon from "@mdi/react";
import {
  mdiHome,
  mdiCalendar,
  mdiAccountSearch,
  mdiCog,
  mdiChevronUp,
  mdiChevronDown,
  mdiShieldLock,
  mdiCardAccountDetails,
  mdiArrowRightBox,
  mdiArrowLeftBox,
  mdiMessagePlus,
  mdiClose,
  mdiAccountVoice,
} from "@mdi/js";

const Nav = styled.div`
  /* background: #15171c; */
  /* height: 80px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const NavIcon = styled(Link)`
  margin-left: 1rem;
  /* font-size: 2rem; */
  /* height: 80px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #ec782f;

  &:hover {
    color: #bdbdbd;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const SidebarNav = styled.nav`
  background: #fff;
  width: ${({ sidebar }) => (sidebar ? "300px" : "62px")};
  min-height: 100vh;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  box-shadow: 0px 4px 5px 1px rgba(0, 0, 0, 0.25);
  /* border-radius: 0px 20px 20px 0px; */
  position: fixed;
  top: 0;
  /* left: ${({ sidebar }) => (sidebar ? "0" : "-100%")}; */
  transition: 350ms;
  z-index: 10;
  white-space: nowrap;
  overflow-x: hidden;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const NavScroll = styled.div`
  /* background: red; */
  height: calc(100vh - 7vh);
  overflow: scroll;
`;

const Sidebar = ({
  setHeader,
  setIconH,
  sidebar,
  setSidebar,
  setAdmin,
  onCollapse,
}) => {
  // const [sidebar, setSidebar] = useState(false);

  // const dispatch = useDispatch();

  const { usrAbilities } = useSelector((state) => state.user);

  const showSidebar = () => setSidebar(!sidebar);

  const toggleSidebarIn = () => {
    if (!sidebar) {
      // console.log("sidebar besar");
      document.getElementById("sidebar-nav").style.width = "300px";
      document.getElementById("nav").style.display = "block";
      document.getElementById("imgSquare").style.display = "none";
      document.getElementById("imgLogo").style.display = "block";
    }
  };

  const toggleSidebarOut = () => {
    if (!sidebar) {
      // console.log("sidebar kecik");
      document.getElementById("sidebar-nav").style.width = "62px";
      document.getElementById("nav").style.display = "none";
      document.getElementById("imgSquare").style.display = "block";
      document.getElementById("imgLogo").style.display = "none";
    }
  };

  const handleMouseLeave = () => {
    if (!sidebar) {
      document.querySelectorAll(".sub-menu").forEach((el) => {
        el.classList.remove("active");
      });
    }
  };

  let menuItems = [
    {
      name: "Home",
      exact: true,
      to: "/",
      iconClassName: mdiHome,
      iconDrop: mdiChevronDown,
      hidden: true,
    },
    {
      name: "Calendar",
      exact: true,
      to: "/calendar",
      iconClassName: mdiCalendar,
      iconDrop: mdiChevronDown,
      hidden: true,
    },
    {
      name: "Meeting",
      to: "/meeting",
      iconClassName: mdiMessagePlus,
      iconDrop: mdiChevronDown,
      hidden: true,
    },
    {
      name: "Interview",
      to: "/interview",
      iconClassName: mdiAccountVoice,
      iconDrop: mdiChevronDown,
      hidden: true,
    },
    // {
    //   name: "Clients",
    //   exact: true,
    //   to: "",
    //   iconClassName: mdiAccountGroup,
    //   iconDrop: mdiChevronDown,
    //   iconClosed: mdiChevronUp,
    //   hidden: true,
    //   subMenus: [
    //     {
    //       name: "Clients",
    //       to: "/clients/listActiveC",
    //       iconClassName: mdiAccountGroup,
    //       hidden: usrAbilities.includes("list clients"),
    //     },
    //     {
    //       name: "Potential Clients",
    //       to: "/clients/listPotentialC",
    //       iconClassName: mdiAccountGroup,
    //       hidden: usrAbilities.includes("list potential"),
    //     },
    //     {
    //       name: "Companies",
    //       to: "/clients/listAllClients",
    //       iconClassName: mdiAccountSearch,
    //       hidden: true,
    //     },
    //   ],
    // },
    {
      name: "Jesper Database",
      exact: true,
      to: "",
      iconClassName: mdiAccountSearch,
      iconDrop: mdiChevronDown,
      iconClosed: mdiChevronUp,
      hidden: true,
      subMenus: [
        {
          name: "Clients",
          to: "/clients/listActiveC",
          iconClassName: mdiAccountSearch,
          hidden: usrAbilities.includes("list clients"),
        },
        {
          name: "Potential Clients",
          to: "/clients/listPotentialC",
          iconClassName: mdiAccountSearch,
          hidden: usrAbilities.includes("list potential"),
        },
        {
          name: "Assignments",
          to: "/clients/listAssignments",
          iconClassName: mdiAccountSearch,
          hidden: usrAbilities.includes("list assignment"),
        },
        {
          name: "Candidates",
          to: "/candidates/listCandidates",
          iconClassName: mdiAccountSearch,
          hidden: usrAbilities.includes("list candidate"),
        },
        {
          name: "Companies",
          to: "/clients/listAllClients",
          iconClassName: mdiAccountSearch,
          hidden: true,
        },
        {
          name: "Placements",
          to: "/candidates/placement-list",
          iconClassName: mdiAccountSearch,
          hidden: usrAbilities.includes("list placement"),
        },
        {
          name: "Proposal",
          to: "/clients/listProposal",
          iconClassName: mdiAccountSearch,
          hidden: usrAbilities.includes("list proposal approval"),
        },
      ],
    },
    {
      name: "Settings",
      to: "/settings",
      iconClassName: mdiCog,
      iconDrop: mdiChevronDown,
      hidden: true,
    },
    {
      name: "List of Staff",
      to: "/staff",
      iconClassName: mdiCardAccountDetails,
      iconDrop: mdiChevronDown,
      hidden: setAdmin === 1 ? true : false,
    },
    {
      name: "Administration",
      exact: true,
      to: "",
      iconClassName: mdiShieldLock,
      iconDrop: mdiChevronDown,
      iconClosed: mdiChevronUp,
      hidden: setAdmin === 1 ? true : false,
      subMenus: [
        {
          name: "User Access Management",
          to: "/userAccess",
          iconClassName: mdiShieldLock,
          hidden: true,
        },
        {
          name: "System Option Management",
          to: "/systemOption",
          iconClassName: mdiShieldLock,
          hidden: true,
        },
        {
          name: "Notification Management",
          to: "/notification",
          iconClassName: mdiShieldLock,
          hidden: true,
        },
        {
          name: "Page Management",
          to: "/pages",
          iconClassName: mdiShieldLock,
          hidden: true,
        },
      ],
    },
  ];

  useEffect(() => {
    if (!sidebar) {
      removeActiveClassFromSubMenu();
    }

    onCollapse(sidebar);
  }, [sidebar, onCollapse]);

  //just an improvment and it is not recorded in video :(
  const removeActiveClassFromSubMenu = () => {
    document.querySelectorAll(".sub-menu").forEach((el) => {
      el.classList.remove("active");
    });
  };

  const closeMenu = () => {
    let responsive = document.querySelectorAll("#sidebar-nav");

    responsive.forEach((el) => el.classList.remove("active"));
  };

  useEffect(() => {
    let menuItems = document.querySelectorAll(".menu-item");
    let sidebar = document.querySelectorAll(".dd-sidebar");

    sidebar.forEach((sb) => {
      sb.addEventListener("click", (e) => {
        sidebar.forEach((sb) => {
          sb.classList.remove("active");
        });

        sb.classList.toggle("active");
        closeMenu();
      });
    });

    menuItems.forEach((el) => {
      el.addEventListener("click", (e) => {
        const next = el.nextElementSibling;

        removeActiveClassFromSubMenu();

        menuItems.forEach((el) => {
          el.classList.remove("active");
        });

        el.classList.toggle("active");

        if (next !== null) {
          next.classList.toggle("active");
        } else {
          closeMenu();
        }
      });
    });
  }, []);

  return (
    <>
      {/* <IconContext.Provider value={{ color: "#fff" }}> */}
      <SidebarNav
        id="sidebar-nav"
        className="resp-sidebar"
        sidebar={sidebar}
        onMouseOver={toggleSidebarIn}
        onMouseOut={toggleSidebarOut}
        onMouseLeave={handleMouseLeave}
      >
        <SidebarWrap>
          {/* Close button for responsive */}
          <div className="close-responsive" onClick={closeMenu}>
            <Icon path={mdiClose} size={1} color="#54595F" />
          </div>
          {/* Close button for responsive */}

          <Logo className="llm-logo-sidebar">
            {sidebar ? (
              <>
                <img
                  id="imgSquare"
                  src={squareLogo}
                  alt="Lim-Loges Masters Company Logo"
                  height={45}
                />
                <img
                  id="imgLogo"
                  src={logo}
                  alt="Lim-Loges Masters Company Logo"
                  height={45}
                />
              </>
            ) : (
              <>
                <img
                  id="imgSquare"
                  src={squareLogo}
                  alt="Lim-Loges Masters Company Logo"
                  height={45}
                />
                <img
                  id="imgLogo"
                  src={logo}
                  alt="Lim-Loges Masters Company Logo"
                  height={45}
                  style={{ display: !sidebar ? "none" : "block" }}
                />
              </>
            )}

            <Nav id="nav" style={{ display: !sidebar ? "none" : "block" }}>
              <NavIcon to="#">
                <Icon
                  path={sidebar ? mdiArrowLeftBox : mdiArrowRightBox}
                  size={1}
                  onClick={showSidebar}
                />
              </NavIcon>
            </Nav>
          </Logo>

          <NavScroll className="main-menu">
            <ul className="sidebar-ul">
              {menuItems.map((item, index) => {
                return (
                  <SubMenu
                    key={index}
                    item={item}
                    index={index}
                    // props={props}
                    setHeader={setHeader}
                    setIconH={setIconH}
                    sidebar={sidebar}
                    // onClick={(e) => {
                    //   if (sidebar) {
                    //     setSidebar(false);
                    //   }
                    // }}
                  />
                );
              })}
            </ul>
          </NavScroll>
        </SidebarWrap>
      </SidebarNav>
      {/* </IconContext.Provider> */}
    </>
  );
};

export default Sidebar;
