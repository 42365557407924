import React from "react";
import { Route, Routes } from "react-router-dom";
import Account from "../../pages/Client/Account";
import Assignment from "../../pages/Client/Assignment";
import ClientLayout from "../../pages/Client/Layout/Layout";
import Meeting from "../../pages/Client/Meeting";
import ViewAssignment from "../../pages/Client/ViewAssignment";
import ViewCandidate from "../../pages/Client/ViewCandidate";
import ViewAllNoti from "../../pages/Client/AllNotificationsClient";

const RouteClient = () => {
  return (
    <ClientLayout>
      <Routes>
        <Route path="/" element={<Assignment />}></Route>
        <Route path="/client-assignment/:idassign" element={<ViewAssignment />}></Route>
        <Route path="/client-candidate/:idassign/:idcand" element={<ViewCandidate />}></Route>
        <Route path="/client-meeting" element={<Meeting />}></Route>
        <Route path="/account-client" element={<Account />}></Route>
        <Route path="/all-notifications" element={<ViewAllNoti />}></Route>
      </Routes>
    </ClientLayout>
  );
};

export default RouteClient;
