import React from "react";
import "./TextField.css";
import Icon from "@mdi/react";
import { Form } from "react-bootstrap";

const SIZES = ["input--large", "input--medium", "input--small"];

function TextFieldIcon({
  type,
  placeholder,
  name,
  inputSize,
  value,
  onChange,
  icon,
}) {
  // const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];

  const checkInputSize = SIZES.includes(inputSize) ? inputSize : SIZES[0];

  return (
    <div>
      <div className="inputWithIcon">
        <Form.Control
          type={type}
          placeholder={placeholder}
          className={`input ${checkInputSize}`}
          name={name}
          value={value}
          onChange={onChange}
        />
        <div className="inputWithIconMdi">
          <Icon path={icon} size={"1.2rem"} />
        </div>
      </div>
    </div>
  );
}

export default TextFieldIcon;
