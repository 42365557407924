import React, { useEffect, useCallback, useState } from "react";
import { Card, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Api from "../../../core/services/api.service";
import CardInfo from "../../Card/CardInfo";
import { CustomTable } from "../../Table/Table";

export const TabCandidatesClient = () => {
  const params = useParams();
  const { user } = useSelector((state) => state.login);

  const [listCandiate, setListCandidate] = useState([]);

  const headerCandidate = [
    {
      Header: "Candidate Name",
      maxWidth: 250,
      minWidth: 150,
      width: 250,
      Cell: ({ row }) => {
        const data = row.original;

        // let status_cand = data.assignment_candidates[0].status;

        const navigate = useNavigate();

        function viewCandidate() {
          navigate("/client-candidate/" + params.idassign + "/" + data.id);
        }

        return (
          <div className="container-div list-cli">
            {data.assignment_candidates[0].status === "placed_other" ? (
              <>
                {data.first_name} {data.last_name}
              </>
            ) : (
              <Button
                variant="link"
                className="no-underline"
                onClick={viewCandidate}
                style={{ textTransform: "capitalize" }}
              >
                {data.first_name} {data.last_name}
              </Button>
            )}
          </div>
        );
      },
    },
    {
      Header: "Current Job Title",
      maxWidth: 250,
      minWidth: 150,
      width: 250,
      Cell: ({ row }) => {
        const data = row.original;

        let cur_company = "";

        if (data.candidate_experiences.length > 0) {
          cur_company = data.candidate_experiences[0].title;
        } else {
          cur_company = "-";
        }

        return <>{cur_company}</>;
      },
    },
    {
      Header: "Current Company",
      maxWidth: 250,
      minWidth: 150,
      width: 250,
      Cell: ({ row }) => {
        const data = row.original;

        let cur_company = "";

        if (data.candidate_experiences.length > 0) {
          cur_company = data.candidate_experiences[0].company_name;
        } else {
          cur_company = "-";
        }

        return <>{cur_company}</>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      maxWidth: 150,
      minWidth: 150,
      width: 150,
      Cell: ({ row }) => {
        const data = row.original;

        let styleCard = "";
        let viewStatus = "";
        let status_cand = data.assignment_candidates.length > 0 ? data.assignment_candidates[0].status : "-";

        if(status_cand === "placed_other"){
          styleCard = "card_text--danger--solid";
          viewStatus = "Placed (Other)";
        }else{
          styleCard = "card_text--primary--solid";

          if (!status_cand || status_cand === ""){
            viewStatus = "-";
          }
          else if (status_cand.indexOf('_') > -1)
          {
            viewStatus = status_cand.replace(/_/g, " ");
          }else{
            viewStatus = status_cand;
          }
        }

        return (
          <>
            <CardInfo
              cardTextSize="card_text--small"
              cardTextStyle={styleCard}
            >
              {viewStatus}
            </CardInfo>
          </>
        );
      },
    },
  ];

  const getListCandidate = useCallback(() => {
    Api.get(
      "client/" +
        user.clientid +
        "/assignment/" +
        params.idassign +
        "/candidate/all"
    )
      .then((response) => {
        let data = response.data;
        // console.log("getListCandidate", data)
        if (data !== "no data") {
          setListCandidate(data);
        }
      })
      .catch((error) => {
        console.log("error getListCandidate", error);
      });
  }, [params, user.clientid]);

  useEffect(() => {
    getListCandidate();
  }, [getListCandidate]);

  return (
    <>
      <Card>
        <Card.Body>
          <CustomTable
            headers={headerCandidate}
            items={listCandiate}
            paginate={listCandiate.length}
            maxRows={10}
          />
        </Card.Body>
      </Card>
    </>
  );
};
