import React from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import {
  loginPending,
  loginSuccess,
  loginFail,
} from "../../../../store/actions/loginSlice";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./login.css";
import logo from "../../../../assets/logo.png";
import { Form, Button, Spinner, Alert } from "react-bootstrap";

import { userLogin } from "../../../../routes/Api/userApi";

const AdminLogin = () => {
  const { handleSubmit, register } = useForm();
  // const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { isLoading, error } = useSelector((state) => state.login);

  function forgotPassword() {
    navigate("/forgot-password/staff");
  }

  const btnLogin = async (index) => {
    
    const email = index.txtemail;
    const password = index.txtpassword;

    // if (!email || !password) {
    //   return alert("Fill up all the form!");
    // }

    dispatch(loginPending());

    try {
      const isAuthen = await userLogin({ email, password });

      if (isAuthen.status === "error") {
        return dispatch(loginFail(error.message));
      }

      const tokenid = isAuthen.access_token;
      const decoded = jwt_decode(tokenid);

      // console.log("decoded", decoded)

      setTimeout(
        () =>
          dispatch(
            loginSuccess({
              sub: decoded.sub,
              email: index.txtemail,
              role: decoded.user_has_roles[0].role_id,
              iat: decoded.iat,
              exp: decoded.exp,
            })
          ),
        3500
      );

      navigate("/home");

      localStorage.removeItem("id_token");
      localStorage.removeItem("menu_title");
      localStorage.removeItem("menu_icon");

      // localStorage.setItem("menu_title", "Home");
      // localStorage.setItem(
      //   "menu_icon",
      //   "M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z"
      // );
    } catch (error) {
      dispatch(loginFail("Invalid Email or Password"));
    }
  };

  return (
    <div className="main-div admin-login" style={{ height: "100vh" }}>
      <div className="auth-container admin-login">
        <div className="login-container" style={{ background: "#FFF" }}>
          <div className="left-welcome-div" style={{ background: "#FFF" }}>
            <div className="mid-section">
              <img src={logo} alt="" height="50" />
            </div>
          </div>

          <div className="right-login-form-div admin-login">
            <div className="top-login-section">
              <div className="title-form-section">
                {/* Display for responsive */}
                <div className="auth-logo-resp">
                  <img src={logo} alt="" />
                </div>
                {/* Display for responsive */}
                <div className="main-title">Admin Login</div>
                <div className="small-text" style={{ color: "#54595F" }}>
                  Welcome to Jesper Research and Business Development Tool{" "}
                  <br /> by Lim-Loges & Masters. <br />
                </div>
                <div style={{ marginTop: "20px" }}></div>
              </div>

              {error && <Alert variant="danger">{error}</Alert>}
              <Form id="login-form" onSubmit={handleSubmit(btnLogin)}>
                <Form.Group className="mb-3">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    {...register("txtemail")}
                    type="email"
                    placeholder="Enter your email"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    {...register("txtpassword")}
                    type="password"
                    placeholder="Enter your password"
                  />
                </Form.Group>
              </Form>

              <div className="row-link-button">
                <Button
                  variant="link"
                  className="no-underline"
                  onClick={forgotPassword}
                >
                  Forgot Password?
                </Button>
                {/* <div> */}
                <Button form="login-form" variant="primary" type="submit">
                  Sign In
                  {isLoading && (
                    <Spinner variant="primary" animation="border" />
                  )}
                </Button>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
