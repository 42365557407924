import React from "react";
import Creatable from 'react-select/creatable';
import "./DropDown.css";

function CreateableSelect({
    onChange,
    options,
    value,
    isDisabled,
    isInvalid,
    errorText,
    getOptionValue,
    getOptionLabel
}) {

    const colourStyles = {
        control: (provided, state) => ({
          ...provided,
          "*": {
            boxShadow: "none !important",
          },
          width: '100%',
          minHeight: '30px',
          // minHeight: 'calc(1.5em + 0.5rem + 2px)',
          boxShadow: state.isFocused ? '0 0 3px #EC782F' : provided.borderColor,
          borderColor: state.isFocused ? '#EC782F' : provided.borderColor,
          borderRadius: '10px',
          fontSize: "14px",
          
          '&:hover': {
            borderColor: state.isFocused ? '#EC782F' : provided.borderColor,
          }
        }),
        placeholder: (provided) => ({
          ...provided,
          color: "#212529",
        }),
        option: (provided, state) => ({
          ...provided,
          background: state.isFocused ? '#467FD0' : 'white',
          color: state.isFocused ? 'white' : 'black',
          zIndex: 1,
          fontSize: "14px",
          '&:hover': {
            backgroundColor: state.isSelected ? 'none' : '#467FD0',
            color:  'white'
          }
        }),
        dropdownIndicator: base => ({
          ...base,
          padding: '5px',
          color: "#212529",
        }),
        indicatorSeparator: () => ({
          display: "none",
        }),
    }

    const colourStylesError = {
        control: (base , state) => ({
          ...base ,
          "*": {
            boxShadow: "none !important",
          },
          width: '100%',
          // height: 10,
          boxShadow: state.isFocused ? '0 0 0px #dc3545' : null,
          borderColor: state.isFocused ? '#dc3545' : '#dc3545',
          borderRadius: '10px',
          
          '&:hover': {
            borderColor: state.isFocused ? '#EC782F' : null,
          }
        }),
        option: (provided, state) => ({
          ...provided,
          background: state.isFocused ? '#467FD0' : 'white',
          color: state.isFocused ? 'white' : 'black',
          zIndex: 1,
          '&:hover': {
            backgroundColor: state.isSelected ? 'none' : '#467FD0',
            color:  'white'
          }
        }),
        dropdownIndicator: base => ({
          ...base,
          padding: '5px',
        })
  }

    return (
        <div>
            {
              isInvalid === true ? 
                <Creatable
                    isClearable
                    onChange={onChange}
                    options={options}
                    value={value}
                    styles={colourStylesError}
                    isDisabled={isDisabled}
                    getOptionValue={getOptionValue}
                    getOptionLabel={getOptionLabel}
                />
                :
                <Creatable
                    isClearable
                    onChange={onChange}
                    options={options}
                    value={value}
                    styles={colourStyles}
                    isDisabled={isDisabled}
                    getOptionValue={getOptionValue}
                    getOptionLabel={getOptionLabel}
                />
            }
            {isInvalid === true ? <div className="invalid-dropdown">{errorText}</div> : null }
        </div>
    )
    
}

export default CreateableSelect