import React from "react";

function ViewPolicy() {
  return (
    <div
      className="main-div"
      style={{ height: "100vh", background: "#fdf2ea" }}
    >
      <div className="policy-box">
        <div className="policy-title">Privacy Policy</div>
        <div className="policy-text">
          This Privacy Statement explains our practices regarding the
          collection, use, and disclosure of your data, including any personal
          data we collect and process in connection with our website and any
          applications, software, products, and services provided by Lim-Loges &
          Masters, including any Beta Previews (collectively “Service”).
          <br />
          <br />
          The personal data we collect depends on how you interact with us, the
          services you use, and the choices you make. We collect information
          about you from different sources and in various ways when you use our
          Service, including information you provide directly, information
          collected automatically, third-party data sources, and data we infer
          or generate from other data.
        </div>
      </div>
    </div>
  );
}

export default ViewPolicy;
