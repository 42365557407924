import { configureStore } from "@reduxjs/toolkit";
import headerTitleReducer from "../store/actions/headerTitleSlice";
import loginReducer from "../store/actions/loginSlice";
import userReducer from "../store/actions/userProfileSlice";
import breadReducer from "../store/actions/breadSlice";
import breadAsgnReducer from "../store/actions/breadAsgnSlice";
import tabActiveReducer from "../store/actions/tabActiveSlice";
import tabAssignReducer from "../store/actions/tabAssignSlice";
import companyReducer from "../store/actions/companySlice";
import interviewReducer from "../store/actions/interviewSlice";
import candidateReducer from "../store/actions/candidateSlice";
import optionReducer from "../store/actions/optionSlice";
import staffReducer from "../store/actions/staffSlice";
import clientReducer from "../store/actions/clientSlice";
import assignmentReducer from "../store/actions/assignmentSlice";
import meetingReducer from "../store/actions/meetingSlice";
import registerReducer from "../store/actions/registerSlice";
import registerCandReducer from "../store/actions/registerCandidateSlice";
import notificationReducer from "../store/actions/notificationSlice";
import proposalReducer from "../store/actions/proposalSlice";
import roleReducer from "../store/actions/roleSlice";
import dashboardReducer from "../store/actions/dashboardSlice";
import mergeReducer from "../store/actions/mergeSlice";
import statisticsReducer from "../store/actions/statisticsSlice";
import pagingReducer from "../store/actions/paginationSlice";
import placementReducer from "../store/actions/placementSlice";
import consentReducer from "../store/actions/consentSlice";

const store = configureStore({
    reducer: {
        assignment: assignmentReducer,
        bread: breadReducer,
        breadAsgn: breadAsgnReducer,
        client: clientReducer,
        company: companyReducer,
        consent: consentReducer,
        candidate: candidateReducer,
        dashboard: dashboardReducer,
        headerTitle: headerTitleReducer,
        interview: interviewReducer,
        login: loginReducer,
        meeting: meetingReducer,
        merge: mergeReducer,
        notification: notificationReducer,
        option: optionReducer,
        paging: pagingReducer,
        proposal: proposalReducer,
        placement: placementReducer,
        register: registerReducer,
        registerCand: registerCandReducer,
        role: roleReducer,
        staff: staffReducer,
        statistics: statisticsReducer,
        tabActive: tabActiveReducer,
        tabAssignment: tabAssignReducer,
        user: userReducer,        
    },
    devTools: false,
});

export default store;