import React from "react";
// import styled from "styled-components";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import { useSelector, useDispatch } from "react-redux";
import "./Table.css";
import { Table, Button } from "react-bootstrap";
import Api from "../../core/services/api.service";

import SwalAlert from "../Swal/SwalAlert";
import Empty from "../Empty/Empty";

import { getListNotification } from "../../store/actions/notificationSlice";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

export const TableNotification = ({
  columns,
  data,
  site,
  paginate,
  maxRows,
}) => {
  const customSwal = SwalAlert();

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.login);
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: maxRows ? maxRows : 15 },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div style={{ textAlign: "center" }}>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          minWidth: 50,
          width: 50,
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "40px",
              }}
            >
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const btnMarkAs = (e) => {
    // console.log("eee", selectedFlatRows.map((s) => s.original.id))
    customSwal
      .fire({
        title: "Do you want to mark as read?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.post("notification/markAllAsRead", {
            id: selectedFlatRows.map((s) => s.original.id),
          })
            .then((response) => {
              // console.log("response", response)
              //   setShowAssignment(false);
              //   getListAssignCandidate();
              dispatch(getListNotification(user.sub, site));

              // customSwal.fire({
              // title: "Mark as read successful!",
              // icon: "success",
              // confirmButtonText: "Okay",
              // });
            })
            .catch((error) => {
              console.log("error notification mark as read", error);
            });
        }
      });
  };

  const btnDeleteNoti = (e) => {
    // console.log("selectedFlatRows.map((s) => s.original.id)", selectedFlatRows.map((s) => s.original.id))
    customSwal
      .fire({
        title: "Do you want to delete notification?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.post("notification/delete", {
            id: selectedFlatRows.map((s) => s.original.id),
          })
            .then((response) => {
              dispatch(getListNotification(user.sub, site));

              customSwal.fire({
                title: "Deleted Successful!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error notification delete", error);
            });
        }
      });
  };

  // Render the UI for your table
  return (
    <>
      <div
        className="top-title-div"
        style={{ marginLeft: "10px", marginBottom: 0 }}
      >
        <div className="noti-title">All Notifications</div>
      </div>
      {/* <div
        style={{
          display: "flex",
          alignItems: "baseline",
          fontSize: "14px",
          marginRight: "10px",
          marginLeft: "-10px",
          paddingTop: "20px",
          justifyContent: "flex-start",
        }}
      >
        {selectedFlatRows.length > 0 ? (
          <>
            <Button variant="link" onClick={btnMarkAs}>
              Mark as read
            </Button>{" "}
            |
            <Button
              variant="link"
              onClick={btnDeleteNoti}
              className="delete-link"
            >
              Delete
            </Button>
          </>
        ) : (
          <></>
        )}
      </div> */}
      <div
        style={{ display: "flex", alignItems: "center", paddingTop: "20px" }}
      >
        <div style={{ marginLeft: "10px", fontSize: "14px" }}>
          {selectedFlatRows.length > 0
            ? selectedFlatRows.length + " selected"
            : ""}{" "}
        </div>
        <div>
          {selectedFlatRows.length > 0 ? (
            <>
              <Button variant="link" onClick={btnMarkAs}>
                Mark as read
              </Button>{" "}
              |
              <Button
                variant="link"
                onClick={btnDeleteNoti}
                className="delete-link"
              >
                Delete
              </Button>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>

      {data.length > 0 ? (
        <>
          <Table className="table-list" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return column.hideHeader === false ? null : (
                      <th
                        className="header_text"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps({
                            style: {
                              minWidth: column.minWidth,
                              width: column.width,
                            },
                          })
                        )}
                      >
                        {column.render("Header")}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    className="trow allNoti"
                    {...row.getRowProps()}
                    style={{
                      background: row.original.read_at
                        ? "white"
                        : "rgba(236, 120, 47, 0.3)",
                    }}
                    // onClick={clickNotification(row.original.id)}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {/* 
                    Pagination can be built however you'd like. 
                    This is just a very basic UI implementation:
                */}
          {paginate > pageSize ? (
            <div className="pagination page-cont">
              <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {"<<"}
              </button>{" "}
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                {">"}
              </button>{" "}
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
              <span>
                Page {pageIndex + 1} of {pageOptions.length}
                {/* <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "} */}
              </span>
              <span className="go-to-page">| Go to page: </span>{" "}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                // style={{ width: "100px" }}
              />
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[15, 25, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
              <pre hidden>
                <code>
                  {JSON.stringify(
                    {
                      selectedRowIds: selectedRowIds,
                      "selectedFlatRows[].original": selectedFlatRows.map(
                        (d) => d.original
                      ),
                    },
                    null,
                    2
                  )}
                </code>
              </pre>
            </div>
          ) : null}
        </>
      ) : (
        <Empty />
      )}
    </>
  );
};

export default TableNotification;

// function App() {
//   const columns = React.useMemo(
//     () => [
//       {
//         Header: 'Name',
//         columns: [
//           {
//             Header: 'First Name',
//             accessor: 'firstName',
//           },
//           {
//             Header: 'Last Name',
//             accessor: 'lastName',
//           },
//         ],
//       },
//       {
//         Header: 'Info',
//         columns: [
//           {
//             Header: 'Age',
//             accessor: 'age',
//           },
//           {
//             Header: 'Visits',
//             accessor: 'visits',
//           },
//           {
//             Header: 'Status',
//             accessor: 'status',
//           },
//           {
//             Header: 'Profile Progress',
//             accessor: 'progress',
//           },
//         ],
//       },
//     ],
//     []
//   )

//   const data = React.useMemo(() => makeData(100000), [])

//   return (
//     <Styles>
//       <Table columns={columns} data={data} />
//     </Styles>
//   )
// }

// export default App
