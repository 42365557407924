import React, { useState, useEffect, useCallback, useRef } from "react";
import { Row, Col, Form, Button, Card } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../../core/services/api.service";
import Apiform from "../../../core/services/apiform.service";
import AuthAbilities from "../Auth/AuthAbilities";

import FileSaver from "file-saver";

import SwalAlert from "../../../components/Swal/SwalAlert";
import BreadCrumb from "../../../components/Breadcrumb/BreadCrumb";
import DefaultPhoto from "../../../assets/no-profile-photo.jpg";
import Searchable from "../../../components/DropDown/SearchableSelect";
import Popup from "../../../components/Popup/ModalPopup";
import CardCandidate from "../../../components/Card/CardCandidate";
import TextFieldFormat from "../../../components/TextField/TextFieldFormat";
import RichTextEditor from "../../../components/RichTextArea/RichTextEditor";
import CustomTable from "../../../components/Table/TableFunction";

import { getClientDetails } from "../../../store/actions/clientSlice";
import { storeTitle } from "../../../store/actions/headerTitleSlice";
import { getListOptions } from "../../../store/actions/optionSlice";

import { monthList, noticeList } from "../dataList";

import {
  mdiFile,
  mdiPlusCircle,
  mdiPencil,
  mdiDelete,
  mdiArrowLeft,
  mdiAccountGroup,
} from "@mdi/js";
import Icon from "@mdi/react";

const ViewStaffClient = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const customSwal = SwalAlert();
  const abilities = AuthAbilities();

  const { clientDetails } = useSelector((state) => state.client);
  const {
    dataYear,
    dataSalute,
    dataCountry,
    dataLanguages,
    dataJobFunction,
    dataIndustry,
    dataAllowance,
    dataCurrency,
  } = useSelector((state) => state.option);

  const [crumbs, setCrumbs] = useState([]);

  const selectedCrumb = (crumb) => {
    // console.log("crumb", crumb)
    if (crumb === "Potential Clients") {
      navigate("/clients/listPotentialC");
    } else if (crumb === "Clients") {
      navigate("/clients/listActiveC");
    } else {
      navigate(-1);
    }
  };

  const [isReadMore, setReadMore] = useState({
    id: "",
    more: false,
  });
  const [editMode, setEditMode] = useState(false);
  const [editBasic, setEditBasic] = useState(false);
  const [editEdu, setEditEdu] = useState(false);
  const [editExp, setEditExp] = useState(false);
  const [editRef, setEditRef] = useState(false);
  const [editMisc, setEditMisc] = useState(false);

  // Button Event
  const btnEditMode = (index, type) => {
    if (editMode === index) {
      //if clicked is already active, then close
      return setEditMode(null);
    }

    if (type === "first") {
      setEditBasic(true);
      setErrorsBasic({});
    } else if (type === "second") {
      setEditEdu(true);
    } else if (type === "third") {
      setEditExp(true);
    } else if (type === "fourth") {
      setEditRef(true);
    } else if (type === "fifth") {
      setEditMisc(true);
    }

    setEditMode(index);
  };

  // Basic Personal Information
  const [errorsBasic, setErrorsBasic] = useState({});
  const [selectedLang, setSelectedLang] = useState([]);

  const formErrorsBasic = (object) => {
    const newBasicErrors = {};

    // const regEmail = /\S+@\S+\.\S+/;

    // if (!object.ddlSalutation || object.ddlSalutation === "")
    //   newBasicErrors.ddlSalutation = "Fill in the blank!";

    if (!object.txtfirstname || object.txtfirstname === "")
      newBasicErrors.txtfirstname = "Fill in the blank!";

    if (!object.txtlastname || object.txtlastname === "")
      newBasicErrors.txtlastname = "Fill in the blank!";

    if (!object.txtjobtitle || object.txtjobtitle === "")
      newBasicErrors.txtjobtitle = "Fill in the blank!";

    // if (!object.txtemail || object.txtemail === "")
    //   newBasicErrors.txtemail = "Fill in the blank!";
    // else if (!regEmail.test(object.txtemail))
    //   newBasicErrors.txtemail = "Invalid Email Address";

    // if (!object.txtdirectline || object.txtdirectline === "")
    //   newBasicErrors.txtdirectline = "Fill in the blank!";

    // if (!object.txtcountrycode || object.txtcountrycode === "")
    //   newBasicErrors.txtcountrycode = "Fill in the blank!";

    // if (!object.txtphone || object.txtphone === "")
    //   newBasicErrors.txtphone = "Fill in the blank!";

    // if (!object.txtcountry || object.txtcountry === "")
    //   newBasicErrors.txtcountry = "Fill in the blank!";

    // if (!object.txtlanguages || object.txtlanguages === "")
    //   newBasicErrors.txtlanguages = "Fill in the blank!";

    return newBasicErrors;
  };

  const btnUpdateBasic = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtBasic = Object.fromEntries(data.entries());

    console.log("dtBasic", dtBasic);
    const newErrors = formErrorsBasic(dtBasic);
    // console.log("setSelectedLang", selectedLang);

    if (Object.keys(newErrors).length > 0) {
      setErrorsBasic(newErrors);
    } else {
      let linkedInCheck = !dtBasic.txtlinked.substring(
        0,
        dtBasic.txtlinked.indexOf(":")
      )
        ? !dtBasic.txtlinked
          ? ""
          : "http://" + dtBasic.txtlinked
        : dtBasic.txtlinked;
      let instaCheck = !dtBasic.txtinsta.substring(
        0,
        dtBasic.txtinsta.indexOf(":")
      )
        ? !dtBasic.txtinsta
          ? ""
          : "http://" + dtBasic.txtinsta
        : dtBasic.txtinsta;
      let facebookCheck = !dtBasic.txtfacebook.substring(
        0,
        dtBasic.txtfacebook.indexOf(":")
      )
        ? !dtBasic.txtfacebook
          ? ""
          : "http://" + dtBasic.txtfacebook
        : dtBasic.txtfacebook;
      let othwebCheck = !dtBasic.txtothweb.substring(
        0,
        dtBasic.txtothweb.indexOf(":")
      )
        ? !dtBasic.txtothweb
          ? ""
          : "http://" + dtBasic.txtothweb
        : dtBasic.txtothweb;

      customSwal
        .fire({
          title: "Do you want to update staff profile?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();

            formData.append("profile_pic", dtBasic.txtprofile);
            formData.append("salutation", dtBasic.ddlSalutation);
            formData.append("first_name", dtBasic.txtfirstname);
            formData.append("last_name", dtBasic.txtlastname);
            if (selectedLang.length > 0) {
              selectedLang.forEach((item, index) => {
                formData.append(`languages[${index}]`, item);
              });
            } else {
              loadLang.forEach((item, index) => {
                formData.append(`languages[${index}]`, item);
              });
            }

            formData.append("mobile_no", dtBasic.txtphone);
            // formData.append("mobile_no_code", dtBasic.txtcountrycode);
            formData.append("email", dtBasic.txtemail);
            formData.append("comp_email", dtBasic.txtcompemail);
            formData.append("citizenship_main", dtBasic.txtcountry);
            formData.append("direct_line_no", dtBasic.txtdirectline);
            formData.append("job_title", dtBasic.txtjobtitle);

            formData.append("linkedln_url", linkedInCheck);
            formData.append("instagram_url", instaCheck);
            formData.append("facebook_url", facebookCheck);
            formData.append("website_url", othwebCheck);

            Apiform.patch(
              "client/" + params.id + "/staff/" + params.idsta,
              formData
            )
              .then((response) => {
                getSelectedStaff();
                setEditBasic(false);
                setEditMode(false);

                customSwal.fire({
                  title: "Profile Updated Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("error btnUpdateBasic", error);
              });
          }
        });
    }
  };

  const btnCancelBasic = (index) => {
    setEditBasic(index);
    setEditMode(index);
  };

  const handleLanguages = (e) => {
    setSelectedLang(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  // End Basic Personal Information

  //Start Education Information
  const [eduState] = useState({
    txtschool: "",
    txtdegree: "",
    txtfromyear: "",
    txttoyear: "",
    txtcertificate: "",
  });
  const [modalEdu, setModalEdu] = useState({
    title: "",
    button: "",
  });
  const [showEdu, setShowEdu] = useState(false);
  const [idEdu, setIdEdu] = useState("");
  const [errorsEdu, setErrorsEdu] = useState({});

  const btnCancelEdu = (index) => {
    setEditEdu(index);
    setEditMode(index);
  };

  const btnAddEdu = () => {
    eduState.txtschool = null;
    eduState.txtdegree = null;
    eduState.txtfromyear = null;
    eduState.txttoyear = null;

    setModalEdu({
      title: "Add Education",
      button: "Save",
    });

    setIdEdu("New");
    setErrorsEdu({});
    setShowEdu(true);
  };

  const btnEditEdu = (id) => (e) => {
    const filteredEdu = selStaff.candidate_education.filter((education) => {
      return education.id === id;
    });
    // console.log("filteredEdu", filteredEdu);

    eduState.txtschool = filteredEdu[0].name;
    eduState.txtdegree = filteredEdu[0].qualification;
    eduState.txtfromyear = filteredEdu[0].start_year;
    eduState.txttoyear = filteredEdu[0].end_year;
    eduState.txtcertificate = filteredEdu[0].certificate_attachment;

    setModalEdu({
      title: "Edit Education",
      button: "Update",
    });

    setErrorsEdu({});
    setIdEdu(id);
    setShowEdu(true);
  };

  const btnCloseEdu = () => setShowEdu(false);

  const btnSaveUpdateEdu = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtEdu = Object.fromEntries(data.entries());

    // console.log("dtEdu", dtEdu);
    const newErrors = formEduErrors(dtEdu);

    if (Object.keys(newErrors).length > 0) {
      setErrorsEdu(newErrors);
    } else {
      if (idEdu === "New") {
        customSwal
          .fire({
            title: "Do you want to add new education?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              const formData = new FormData();

              formData.append("name", dtEdu.txtschool);
              formData.append("qualification", dtEdu.txtdegree);
              formData.append("start_year", dtEdu.txtfromyear);
              formData.append("end_year", dtEdu.txttoyear);
              formData.append("certificate_attachment", dtEdu.txtcertificate);

              Apiform.post(
                "candidate/" + params.idsta + "/education/store",
                formData
              )
                .then((response) => {
                  getSelectedStaff();
                  setShowEdu(false);
                })
                .catch((error) => {
                  console.log("error education ", error.response.data);
                });
            }
          });
      } else {
        customSwal
          .fire({
            title: "Do you want to update education?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              const formData = new FormData();

              formData.append("name", dtEdu.txtschool);
              formData.append("qualification", dtEdu.txtdegree);
              formData.append("start_year", dtEdu.txtfromyear);
              formData.append("end_year", dtEdu.txttoyear);
              formData.append("certificate_attachment", dtEdu.txtcertificate);

              Apiform.patch(
                "candidate/" + params.idsta + "/education/" + idEdu + "/update",
                formData
              )
                .then((response) => {
                  getSelectedStaff();
                  setShowEdu(false);
                })
                .catch((error) => {
                  console.log("error education ", error.response.data);
                });
            }
          });
      }
    }
  };

  const formEduErrors = (object) => {
    const newErrors = {};
    // school errors
    if (!object.txtschool || object.txtschool === "")
      newErrors.txtschool = "Fill in the blank!";

    // degree errors
    // if (!object.txtdegree || object.txtdegree === "")
    //   newErrors.txtdegree = "Fill in the blank!";

    // from year errors
    // if (!object.txtfromyear || object.txtfromyear === "")
    //   newErrors.txtfromyear = "Fill in the blank!";

    // to year errors
    // if (!object.txttoyear || object.txttoyear === "")
    //   newErrors.txttoyear = "Fill in the blank!";

    return newErrors;
  };

  const btnDownload = (id, file) => (e) => {
    // console.log("id", id);
    // console.log("file", file);
    FileSaver.saveAs(file);
  };

  const btnDelEdu = (idedu) => (e) => {
    customSwal
      .fire({
        title: "Do you want to remove this education?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("candidate/" + params.idsta + "/education/" + idedu)
            .then((response) => {
              getSelectedStaff();
            })
            .catch((error) => {
              console.log("btnDelEdu", error);
            });
        }
      });
  };

  // End Education Information

  // Start Experience Information

  const [modalExp, setModalExp] = useState({
    title: "",
    button: "",
  });
  const [expState] = useState({
    txtjobtitle: "",
    ddljobfunction: "",
    txtcompanyname: "",
    ddlIndustry: "",

    ddlCountry: {},
    cbWorkingCurrent: false,
    ddlpremonth: "",
    ddlpreyear: "",

    ddlstartmonth: "",
    ddlendmonth: "",
    ddlstartyear: "",
    ddlendyear: "",

    txtofficephone: "",
    txtofficeemail: "",
    ddlperiod: "",
    txtjobresponsibility: "",

    ddlcurrency: "",
    txtsalary: "",
    txtothbenefit: "",
    txtreason: "",
  });

  const [idExp, setIdExp] = useState("");
  const [errorsExp, setErrorsExp] = useState({});
  const [allowancestate] = useState([
    {
      ddlEarning: "",
      txtamount: "",
      ddlcurrencyallowance: "",
    },
  ]);

  const [allowanceList, setAllowanceList] = useState([]);
  const [showExp, setShowExp] = useState(false);
  const [checked, setChecked] = useState(false);

  const headers = [
    {
      Header: "Earning",
      accessor: (row) => `${row.earn}`,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            <Form.Select
              name="ddlEarning"
              onChange={(event) => handleEarning(event, data.id)}
              defaultValue={data.earning}
            >
              <option value="">Select</option>
              {dataAllowance.map((earn, index) => (
                <option key={index} value={earn.id}>
                  {earn.value}
                </option>
              ))}
              <option value="others">Others</option>
            </Form.Select>
          </div>
        );
      },
    },
    {
      Header: "Amount",
      accessor: (row) => `${row.amount}`,
      Cell: ({ row }) => {
        const data = row.original;
        return (
          <div>
            <TextFieldFormat
              name="txtamount"
              type="number"
              onChange={(event) => handleAmount(event, data.id)}
              value={data.amount}
              thousandSeparator={true}
            ></TextFieldFormat>
          </div>
        );
      },
    },
    {
      Header: "Currency",
      accessor: (row) => `${row.currency}`,
      Cell: ({ row }) => {
        const data = row.original;
        return (
          <div>
            <Form.Select
              name="ddlcurrencyallowance"
              onChange={(event) => handleCurrency(event, data.id)}
              defaultValue={data.currency}
            >
              <option value="">Select</option>
              {dataCurrency.map((currency, index) => (
                <option key={index} value={currency.value}>
                  {currency.value}
                </option>
              ))}
            </Form.Select>
          </div>
        );
      },
    },
    {
      Header: "Action",
      accessor: (row) => `${row.id}`,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            <Button
              variant="link"
              className="delete-link"
              onClick={() => btnDelAllowance(data.id)}
            >
              <Icon path={mdiDelete} size={0.8} />
            </Button>
          </div>
        );
      },
    },
  ];

  const [showOption, setShowOption] = useState(false);

  const handleEarning = (event, param1) => {
    if (event.target.value === "others") {
      setShowOption(true);
      setErrorOpt({});
    } else {
      const filteredEarn = allowanceList.filter((earn) => {
        return earn.id === param1;
      });

      filteredEarn[0].earning = Number(
        event.target.value.replace(/[^0-9.-]+/g, "")
      );

      allowancestate.ddlEarning = filteredEarn[0].earning;
    }
  };

  const handleAmount = (event, param1) => {
    const filteredEarn = allowanceList.filter((earn) => {
      return earn.id === param1;
    });

    filteredEarn[0].amount = Number(
      event.target.value.replace(/[^0-9.-]+/g, "")
    );

    allowancestate.txtamount = filteredEarn[0].amount;
  };

  const handleCurrency = (event, param1) => {
    const filteredEarn = allowanceList.filter((earn) => {
      return earn.id === param1;
    });

    filteredEarn[0].currency = event.target.value;

    allowancestate.ddlcurrencyallowance = filteredEarn[0].currency;
  };

  const [selectedInd, setSelectedInd] = useState([]);

  const handleIndustry = (e) => {
    setSelectedInd(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const [selectedJob, setSelectedJob] = useState([]);

  const handleJobFunc = (e) => {
    setSelectedJob(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  function btnDelAllowance(id) {
    setAllowanceList((current) =>
      current.filter((employee) => {
        // 👇️ remove object that has id equal to 2
        return employee.id !== id;
      })
    );
  }

  const btnAddNewAllowance = (e) => {
    const allowanceLength = allowanceList.length + 1;

    setAllowanceList((items) => [
      ...items,
      {
        id: allowanceLength,
        earning: 0,
        amount: 0,
        currency: "",
      },
    ]);
  };

  const formExpErrors = (object) => {
    const newErrors = {};

    if (!object.txtjobtitle || object.txtjobtitle === "")
      newErrors.txtjobtitle = "Fill in the blank!";

    if (!object.txtcompanyname || object.txtcompanyname === "")
      newErrors.txtcompanyname = "Fill in the blank!";

    if (!object.ddlCountry || object.ddlCountry === "")
      newErrors.ddlCountry = "Fill in the blank!";

    // if (checked === true) {
    //   // if (!object.ddlpreyear || object.ddlpreyear === "")
    //   //   newErrors.ddlpreyear = "Fill in the blank!";
    //   // if (!object.ddlpremonth || object.ddlpremonth === "")
    //   //   newErrors.ddlpremonth = "Fill in the blank!";
    // } else {
    //   if (!object.ddlstartyear || object.ddlstartyear === "")
    //     newErrors.ddlstartyear = "Fill in the blank!";

    //   if (!object.ddlstartmonth || object.ddlstartmonth === "")
    //     newErrors.ddlstartmonth = "Fill in the blank!";

    //   if (!object.ddlendyear || object.ddlendyear === "")
    //     newErrors.ddlendyear = "Fill in the blank!";

    //   if (!object.ddlendmonth || object.ddlendmonth === "")
    //     newErrors.ddlendmonth = "Fill in the blank!";
    // }

    // if (!object.txtofficeemail || object.txtofficeemail === "")
    //   newErrors.txtofficeemail = "Fill in the blank!";

    // if (!object.ddlcurrency || object.ddlcurrency === "")
    //   newErrors.ddlcurrency = "Fill in the blank!";

    // if (!object.txtsalary || object.txtsalary === "")
    //   newErrors.txtsalary = "Fill in the blank!";

    return newErrors;
  };

  const btnCancelExp = (index) => {
    setEditExp(index);
    setEditMode(index);
  };

  const btnCloseExp = () => {
    setShowExp(false);
  };

  const btnAddExp = () => {
    btnResetExp();

    setModalExp({
      title: "Add Experience",
      button: "Add",
    });

    setIdExp("New");
    setErrorsExp({});
    setShowExp(true);
  };

  const toggleBtnReadMore = (index, bool) => (e) => {
    setReadMore({
      id: index,
      more: bool,
    });
  };

  const salaryTxt = useRef(0.0);

  const handleSalary = useCallback((event) => {
    const value = event.target.value;

    salaryTxt.current = value;
  }, []);

  const btnEditExp = (id) => (e) => {
    const filteredExp = selStaff.candidate_experiences.filter((experience) => {
      return experience.id === id;
    });

    // console.log("filteredExp", filteredExp);
    // setSalaryTxt(filteredExp[0].salary);
    setSelectedJob(filteredExp[0].job_function);
    setSelectedInd(filteredExp[0].industry);

    expState.txtjobtitle = filteredExp[0].title;
    expState.ddljobfunction = filteredExp[0].job_function;
    expState.txtcompanyname = filteredExp[0].company_name;
    expState.ddlIndustry = filteredExp[0].industry;

    expState.ddlCountry = {
      label: filteredExp[0].location,
      value: filteredExp[0].location,
    };

    expState.ddlstartmonth = filteredExp[0].start_date_month;
    expState.ddlstartyear = filteredExp[0].start_date_year;
    expState.ddlendmonth = filteredExp[0].end_date_month;

    expState.ddlendyear = filteredExp[0].end_date_year;
    expState.ddlpremonth = filteredExp[0].present_month;
    expState.ddlpreyear = filteredExp[0].present_year;
    expState.cbWorkingCurrent = filteredExp[0].current_working;

    expState.txtofficephone = filteredExp[0].office_number;
    expState.txtofficeemail = filteredExp[0].office_email;
    expState.ddlperiod = filteredExp[0].notice_period;
    expState.txtjobresponsibility = filteredExp[0].job_responsibility;

    expState.ddlcurrency = filteredExp[0].currency;
    // expState.txtsalary = filteredExp[0].salary;
    salaryTxt.current = filteredExp[0].salary ? filteredExp[0].salary : 0.0;
    expState.txtothbenefit = filteredExp[0].other_benefits;
    expState.txtreason = filteredExp[0].reason_leaving;

    setAllowanceList(filteredExp[0].candidate_exp_allowance);
    setChecked(filteredExp[0].current_working);

    setModalExp({
      title: "Edit Experience",
      button: "Update",
    });

    setErrorsExp({});
    setIdExp(id);
    setShowExp(true);
  };

  function getMonthDifference(startDate, endDate) {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }

  const btnSaveUpdateExp = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtExp = Object.fromEntries(data.entries());

    // console.log("dataExperience", dtExp);

    var monthDuration = 0;

    if (checked === true) {
      monthDuration = getMonthDifference(
        new Date(dtExp.ddlpreyear + "-" + dtExp.ddlpremonth),
        new Date()
      );
    } else {
      monthDuration = getMonthDifference(
        new Date(dtExp.ddlstartyear + "-" + dtExp.ddlstartmonth),
        new Date(dtExp.ddlendyear + "-" + dtExp.ddlendmonth)
      );
    }

    const newErrors = formExpErrors(dtExp);

    const filteredAllowance = allowanceList.filter((allow) => {
      return allow.earning !== 0;
    });

    // console.log("exp newError", filteredAllowance);

    if (Object.keys(newErrors).length > 0) {
      setErrorsExp(newErrors);
    } else {
      if (idExp === "New") {
        customSwal
          .fire({
            title: "Do you want to add new experience?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post("candidate/" + params.idsta + "/experience/store", {
                title: dtExp.txtjobtitle,
                company_name: dtExp.txtcompanyname,
                location: dtExp.ddlCountry,
                start_date_year: checked ? "" : dtExp.ddlstartyear,
                start_date_month: checked ? "" : dtExp.ddlstartmonth,
                end_date_year: checked ? "" : dtExp.ddlendyear,
                end_date_month: checked ? "" : dtExp.ddlendmonth,
                current_working: checked.toString(),
                present_month: checked ? dtExp.ddlpremonth : "",
                present_year: checked ? dtExp.ddlpreyear : "",
                total_experience: monthDuration,
                office_number: dtExp.txtofficephone,
                office_email: dtExp.txtofficeemail,
                notice_period: dtExp.ddlperiod,
                industry: selectedInd,
                job_function: selectedJob,
                job_responsibility: dtExp.txtjobresponsibility,
                salary:
                  salaryTxt.current === 0.0
                    ? 0.0
                    : Number(salaryTxt.current.replace(/[^0-9.-]+/g, "")),
                currency: dtExp.ddlcurrency,
                other_benefits: dtExp.txtothbenefit,
                reason_leaving:
                  dtExp.txtreason === "<p><br></p>" ? "" : dtExp.txtreason,
                allowance: filteredAllowance,
              })
                .then((response) => {
                  // console.log(response);

                  getSelectedStaff();
                  setShowExp(false);
                })
                .catch((error) => {
                  console.log("save Experience", error);
                });
            }
          });
      } else {
        customSwal
          .fire({
            title: "Do you want to update this experience?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.patch(
                "candidate/" +
                  params.idsta +
                  "/experience/" +
                  idExp +
                  "/update",
                {
                  title: dtExp.txtjobtitle,
                  company_name: dtExp.txtcompanyname,
                  location: dtExp.ddlCountry,
                  start_date_year: checked ? "" : dtExp.ddlstartyear,
                  start_date_month: checked ? "" : dtExp.ddlstartmonth,
                  end_date_year: checked ? "" : dtExp.ddlendyear,
                  end_date_month: checked ? "" : dtExp.ddlendmonth,
                  current_working: checked.toString(),
                  present_month: checked ? dtExp.ddlpremonth : "",
                  present_year: checked ? dtExp.ddlpreyear : "",
                  total_experience: monthDuration,
                  office_number: dtExp.txtofficephone,
                  office_email: dtExp.txtofficeemail,
                  notice_period: dtExp.ddlperiod,
                  industry: selectedInd,
                  job_function: selectedJob,
                  job_responsibility:
                    dtExp.txtjobresponsibility === "<p><br></p>"
                      ? ""
                      : dtExp.txtjobresponsibility,
                  salary:
                    salaryTxt.current === 0.0
                      ? 0.0
                      : Number(salaryTxt.current.replace(/[^0-9.-]+/g, "")),
                  currency: dtExp.ddlcurrency,
                  other_benefits:
                    dtExp.txtothbenefit === "<p><br></p>"
                      ? ""
                      : dtExp.txtothbenefit,
                  reason_leaving:
                    dtExp.txtreason === "<p><br></p>" ? "" : dtExp.txtreason,
                  allowance: filteredAllowance,
                }
              )
                .then((response) => {
                  getSelectedStaff();
                  setShowExp(false);
                })
                .catch((error) => {
                  console.log("update Experience", error);
                });
            }
          });
      }
    }
  };

  const btnDelExp = (idexp) => (e) => {
    customSwal
      .fire({
        title: "Do you want to remove this experience?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("candidate/" + params.idsta + "/experience/" + idexp)
            .then((response) => {
              getSelectedStaff();
            })
            .catch((error) => {
              console.log("btnDelExp", error);
            });
        }
      });
  };

  function toMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", {
      month: "long",
    });
  }

  function toAllowanceDesc(idallow) {
    let nameAllow = "";

    let array_allow = dataAllowance.filter((grp) => {
      return grp.id === idallow;
    });

    if (array_allow.length > 0) {
      nameAllow = array_allow[0].value;
    }

    return nameAllow;
  }

  const btnResetExp = (event) => {
    expState.txtjobtitle = null;
    expState.txtcompanyname = null;
    expState.ddljobfunction = "";
    expState.ddlIndustry = "";
    expState.ddlCountry = {};

    expState.ddlstartmonth = "";
    expState.ddlstartyear = "";
    expState.ddlendmonth = "";
    expState.ddlendyear = "";

    expState.ddlpremonth = "";
    expState.ddlpreyear = "";

    expState.cbWorkingCurrent = false;
    expState.txtofficephone = "";
    expState.txtofficeemail = "";
    expState.ddlperiod = "";
    expState.txtjobresponsibility = "";
    expState.ddlcurrency = "";
    salaryTxt.current = 0.0;
    // setSalaryTxt(0.0);
    expState.txtothbenefit = "";
    expState.txtreason = "";
    setAllowanceList([]);

    setChecked(false);
  };

  const btnCloseOption = () => {
    setShowOption(false);
  };

  const [errorOpt, setErrorOpt] = useState({});

  const formExpOption = (object) => {
    const newErrors = {};

    if (!object.txtAllowDesc || object.txtAllowDesc === "")
      newErrors.txtAllowDesc = "Fill in the blank!";

    return newErrors;
  };

  const btnNewOption = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dataOpt = Object.fromEntries(data.entries());

    // console.log("dataOpt",dataOpt)
    const newErrors = formExpOption(dataOpt);

    if (Object.keys(newErrors).length > 0) {
      setErrorOpt(newErrors);
    } else {
      // console.log("dataOpt.txtAllowDesc", allowList)

      // const allowCode = allowList.find(e => e.value === dataOpt.txtAllowDesc.trim());

      var allowCode = dataAllowance.filter(function (allow) {
        return (
          allow.value.toLowerCase() ===
          dataOpt.txtAllowDesc.trim().toLowerCase()
        );
      });

      // console.log("allowCode", allowCode)

      if (allowCode.length > 0) {
        customSwal.fire({
          title: "This allowance description duplicate!",
          icon: "error",
          confirmButtonText: "Okay",
        });

        // setShowOption(false);
      } else {
        customSwal
          .fire({
            title: "Do you want to add new allowance?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post("option/store", {
                group: "Allowance",
                code: dataOpt.txtAllowDesc,
                value: dataOpt.txtAllowDesc,
                status: "active",
              })
                .then((response) => {
                  dispatch(getListOptions());
                  setShowOption(false);
                })
                .catch((error) => {
                  console.log("option/update", error);
                });
            }
          });
      }
    }
  };
  // End Experience Information

  // Start Reference Information
  const [modalRef, setModalRef] = useState({
    title: "",
    button: "",
  });

  const [refState] = useState({
    txtrefname: "",
    txtrefjobtitle: "",
    txtrefemail: "",
    txtrefphone: "",
    txtrefcompany: "",
    txtrefrelationship: "",
  });

  const [errorsRef, setErrorsRef] = useState({});
  const [idRef, setIdRef] = useState("");
  const [showRef, setShowRef] = useState(false);

  const btnCancelRef = (index) => {
    setEditRef(index);
    setEditMode(index);
  };

  const btnAddRef = (e) => {
    refState.txtrefname = null;
    refState.txtrefjobtitle = null;
    refState.txtrefemail = null;
    refState.txtrefphone = null;
    refState.txtrefcompany = null;
    refState.txtrefrelationship = null;

    setModalRef({
      title: "Add Reference",
      button: "Add",
    });

    setIdRef("New");
    setErrorsRef({});
    setShowRef(true);
  };

  const btnEditRef = (id) => (e) => {
    const filteredRef = selStaff.candidate_reference.filter((reference) => {
      return reference.id === id;
    });
    // console.log("filteredRef", filteredRef);

    refState.txtrefname = filteredRef[0].name;
    refState.txtrefjobtitle = filteredRef[0].job_title;
    refState.txtrefemail = filteredRef[0].email;
    refState.txtrefphone = filteredRef[0].phone;
    refState.txtrefcompany = filteredRef[0].current_company;
    refState.txtrefrelationship = filteredRef[0].relationship;

    setModalRef({
      title: "Edit Education",
      button: "Update",
    });

    setErrorsRef({});
    setIdRef(id);
    setShowRef(true);
  };

  const btnCloseReferee = () => {
    setShowRef(false);
  };

  const btnSaveUpdateRef = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtRef = Object.fromEntries(data.entries());

    // console.log("dtRef", dtRef);
    const newErrors = formRefErrors(dtRef);

    if (Object.keys(newErrors).length > 0) {
      setErrorsRef(newErrors);
    } else {
      if (idRef === "New") {
        customSwal
          .fire({
            title: "Do you want to add new reference?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post("candidate/" + params.idsta + "/reference/store", {
                name: dtRef.txtrefname,
                job_title: dtRef.txtrefjobtitle,
                email: dtRef.txtrefemail,
                phone: dtRef.txtrefphone,
                current_company: dtRef.txtrefcompany,
                relationship: dtRef.txtrefrelationship,
              })
                .then((response) => {
                  // console.log(response);

                  getSelectedStaff();
                  setShowRef(false);
                })
                .catch((error) => {
                  console.log("save Referee", error);
                });
            }
          });
      } else {
        customSwal
          .fire({
            title: "Do you want to update this reference?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.patch(
                "candidate/" + params.idsta + "/reference/" + idRef + "/update",
                {
                  name: dtRef.txtrefname,
                  job_title: dtRef.txtrefjobtitle,
                  email: dtRef.txtrefemail,
                  phone: dtRef.txtrefphone,
                  current_company: dtRef.txtrefcompany,
                  relationship: dtRef.txtrefrelationship,
                }
              )
                .then((response) => {
                  // console.log(response);

                  getSelectedStaff();
                  setShowRef(false);
                })
                .catch((error) => {
                  console.log("update Referee", error);
                });
            }
          });
      }
    }
  };

  const formRefErrors = (object) => {
    const newErrors = {};

    if (!object.txtrefname || object.txtrefname === "")
      newErrors.txtrefname = "Fill in the blank!";

    return newErrors;
  };

  const btnDelRef = (idref) => (e) => {
    // console.log("idref", idref);
    customSwal
      .fire({
        title: "Do you want to remove this reference?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("candidate/" + params.idsta + "/reference/" + idref)
            .then((response) => {
              getSelectedStaff();
            })
            .catch((error) => {
              console.log("btnDelEdu", error);
            });
        }
      });
  };
  // End Reference Information

  // Start Miscellaneous Information
  const [modalMis, setModalMis] = useState({
    title: "",
    button: "",
  });

  const [misState] = useState({
    txtmiscell: "",
  });

  const [idMis, setIdMis] = useState("");
  const [showMisc, setShowMisc] = useState(false);
  const [errorsMis, setErrorsMis] = useState({});

  const btnCancelMisc = (index) => {
    setEditMisc(index);
    setEditMode(index);
  };

  const btnCloseMisc = () => {
    setShowMisc(false);
  };

  const btnAddMisc = (e) => {
    misState.txtmiscell = null;

    setModalMis({
      title: "Add Miscellaneous",
      button: "Add",
    });

    setIdMis("New");
    setErrorsMis({});
    setShowMisc(true);
  };

  const btnEditMisc = (id) => (e) => {
    const filteredMis = selStaff.candidate_miscellaneous.filter((miscell) => {
      return miscell.id === id;
    });
    // console.log("filteredMis", filteredMis);

    misState.txtmiscell = filteredMis[0].misc;

    setModalMis({
      title: "Edit Miscellaneous",
      button: "Update",
    });

    setErrorsMis({});
    setIdMis(id);
    setShowMisc(true);
  };

  const btnSaveUpdateMisc = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtMis = Object.fromEntries(data.entries());

    // console.log("dtMis", dtMis);
    const newErrors = formMisErrors(dtMis);

    if (Object.keys(newErrors).length > 0) {
      setErrorsMis(newErrors);
    } else {
      if (idMis === "New") {
        customSwal
          .fire({
            title: "Do you want to add new miscellaneous?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post("candidate/" + params.idsta + "/miscellaneous/store", {
                misc: dtMis.txtmiscell,
                status: null,
              })
                .then((response) => {
                  // console.log(response);

                  getSelectedStaff();
                  setShowMisc(false);
                })
                .catch((error) => {
                  console.log("save miscellaneous", error);
                });
            }
          });
      } else {
        customSwal
          .fire({
            title: "Do you want to update miscellaneous?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.patch(
                "candidate/" +
                  params.idsta +
                  "/miscellaneous/" +
                  idMis +
                  "/update",
                {
                  misc: dtMis.txtmiscell,
                  status: null,
                }
              )
                .then((response) => {
                  // console.log(response);

                  getSelectedStaff();
                  setShowMisc(false);
                })
                .catch((error) => {
                  console.log("Update miscellaneous", error);
                });
            }
          });
      }
    }
  };

  const formMisErrors = (object) => {
    const newErrors = {};

    if (!object.txtmiscell || object.txtmiscell === "")
      newErrors.txtmiscell = "Fill in the blank!";

    return newErrors;
  };

  const btnDelMis = (idmis) => (e) => {
    // console.log("idmis", idmis);
    customSwal
      .fire({
        title: "Do you want to remove this miscellaneous?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("candidate/" + params.id + "/miscellaneous/" + idmis)
            .then((response) => {
              getSelectedStaff();
            })
            .catch((error) => {
              console.log("delete miscellaneous", error);
            });
        }
      });
  };
  // End Miscellaneous Information

  // Function load staff
  const [selStaff, setSelStaff] = useState({});
  const [profileImg, setProfileImg] = useState("");
  const [showLang, setShowLang] = useState("");
  const [showLoc, setShowLoc] = useState({});
  const [loadLang, setLoadLang] = useState([]);

  const [showEducation, setShowEducation] = useState([]);
  const [showExperience, setShowExperience] = useState([]);
  const [showReferee, setShowReferee] = useState([]);
  const [showMiscellaneous, setShowMiscellaneous] = useState([]);

  const getSelectedStaff = useCallback(() => {
    Api.get("client/" + params.id + "/staff/" + params.idsta + "/view")
      .then((response) => {
        let data = response.data;

        setSelStaff(data);
        setProfileImg(data.profile_pic);
        setLoadLang(data.languages);

        setShowLang(data.languages.toString());
        setShowLoc({
          label: data.citizenship_main,
          value: data.citizenship_main,
        });

        // Education
        if (data.candidate_education.length > 0) {
          setShowEducation(data.candidate_education);
        } else {
          setShowEducation([]);
        }

        // Experience
        if (data.candidate_experiences.length > 0) {
          setShowExperience(data.candidate_experiences);
        } else {
          setShowExperience([]);
        }

        // Referee
        if (data.candidate_reference.length > 0) {
          setShowReferee(data.candidate_reference);
        } else {
          setShowReferee([]);
        }

        // Miscellaneous
        if (data.candidate_miscellaneous.length > 0) {
          setShowMiscellaneous(data.candidate_miscellaneous);
        } else {
          setShowMiscellaneous([]);
        }
      })
      .catch((error) => {
        console.log("error getSelectedStaff", error);
      });
  }, [params]);

  useEffect(() => {
    dispatch(getClientDetails(params.id));

    getSelectedStaff();

    if (params.page === "active") {
      setCrumbs(["Clients", clientDetails.name, "Edit Staff"]);
      dispatch(storeTitle({ title: "Clients", icon: mdiAccountGroup }));
    } else {
      setCrumbs(["Potential Clients", clientDetails.name, "Edit Staff"]);
      dispatch(
        storeTitle({ title: "Potential Clients", icon: mdiAccountGroup })
      );
    }
  }, [getSelectedStaff, params, clientDetails.name, dispatch]);

  return (
    <>
      <div className="main-div">
        <div className="container-div both-side">
          <BreadCrumb crumbs={crumbs} selected={selectedCrumb}></BreadCrumb>
          <Button variant="secondary" onClick={() => navigate(-1)}>
            <Icon path={mdiArrowLeft} size={0.8} />
            Back
          </Button>
        </div>

        <Card>
          {/* Basic Information Staff */}
          <div className="tab-header-div">
            <div className="left-div">
              <div className="tab-header-font">
                About {selStaff.first_name} {selStaff.last_name}
              </div>
            </div>
            <div className="right-div">
              {editMode === false ? (
                <Button
                  onClick={() => btnEditMode(true, "first")}
                  variant="link"
                  className="modal-link"
                >
                  <Icon path={mdiPencil} size={0.8} />
                  Edit Information
                </Button>
              ) : null}
            </div>
          </div>

          <div className="content-div">
            <Form id="basic-staff-form" onSubmit={btnUpdateBasic}>
              {/* 1 */}
              <Row>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column lg={4}>
                      Profile Picture
                    </Form.Label>
                    <Col xs={3}>
                      <div className="icon-box-lg">
                        {selStaff.profile_pic ? (
                          <img src={profileImg} alt="User Profile" />
                        ) : (
                          <img src={DefaultPhoto} alt="Profile by Client" />
                        )}
                      </div>
                    </Col>
                    {editBasic ? (
                      <Col xs={5}>
                        <Form.Control
                          // onChange={uploadSingleFile}
                          name="txtprofile"
                          type="file"
                          accept=".png,.jpg,.jpeg"
                        />
                        <Form.Text className="text-muted">
                          Allowed file types: jpg, jpeg, png.
                        </Form.Text>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={6}></Col>
              </Row>
              {/* 2 */}
              <Row>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Salutation{" "}
                      {/* {editBasic ? (
                        <label className="validation-star">*</label>
                      ) : null} */}
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <>
                          <Form.Select
                            name="ddlSalutation"
                            defaultValue={selStaff.salutation}
                            // isInvalid={!!errorsBasic.ddlSalutation}
                          >
                            <option value="">Select</option>
                            {dataSalute.map((salute, index) => (
                              <option key={index} value={salute.code}>
                                {salute.value}
                              </option>
                            ))}
                          </Form.Select>
                          {/* <Form.Control.Feedback type="invalid">
                            {errorsBasic.ddlSalutation}
                          </Form.Control.Feedback> */}
                        </>
                      ) : (
                        <div className="text-col">{selStaff.salutation}</div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Job Title{" "}
                      {editBasic ? (
                        <label className="validation-star">*</label>
                      ) : null}
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <>
                          <Form.Control
                            type="text"
                            name="txtjobtitle"
                            defaultValue={selStaff.job_title}
                            isInvalid={!!errorsBasic.txtjobtitle}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errorsBasic.txtjobtitle}
                          </Form.Control.Feedback>
                        </>
                      ) : (
                        <div className="text-col">{selStaff.job_title}</div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              {/* 3 */}
              <Row>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      First Name{" "}
                      {editBasic ? (
                        <label className="validation-star">*</label>
                      ) : null}
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <>
                          <Form.Control
                            type="text"
                            name="txtfirstname"
                            defaultValue={selStaff.first_name}
                            isInvalid={!!errorsBasic.txtfirstname}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errorsBasic.txtfirstname}
                          </Form.Control.Feedback>
                        </>
                      ) : (
                        <div className="text-col">{selStaff.first_name}</div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Last Name{" "}
                      {editBasic ? (
                        <label className="validation-star">*</label>
                      ) : null}
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <>
                          <Form.Control
                            type="text"
                            name="txtlastname"
                            defaultValue={selStaff.last_name}
                            isInvalid={!!errorsBasic.txtlastname}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errorsBasic.txtlastname}
                          </Form.Control.Feedback>
                        </>
                      ) : (
                        <div className="text-col">{selStaff.last_name}</div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              {/* 4 */}
              <Row>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Company Email Address
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <>
                          <Form.Control
                            type="email"
                            name="txtcompemail"
                            defaultValue={selStaff.comp_email}
                            // isInvalid={!!errorsBasic.txtemail}
                          />
                          {/* <Form.Control.Feedback type="invalid">
                            {errorsBasic.txtemail}
                          </Form.Control.Feedback> */}
                        </>
                      ) : (
                        <div
                          className="text-col"
                          style={{ textTransform: "none" }}
                        >
                          {selStaff.comp_email ? selStaff.comp_email : "-"}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Personal Email Address{" "}
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <>
                          <Form.Control
                            type="email"
                            name="txtemail"
                            defaultValue={selStaff.email}
                            // isInvalid={!!errorsBasic.txtemail}
                          />
                          {/* <Form.Control.Feedback type="invalid">
                            {errorsBasic.txtemail}
                          </Form.Control.Feedback> */}
                        </>
                      ) : (
                        <div
                          className="text-col"
                          style={{ textTransform: "none" }}
                        >
                          {selStaff.email ? selStaff.email : "-"}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              {/* 5 */}
              <Row>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Direct Line Number
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <>
                          <Form.Control
                            type="number"
                            name="txtdirectline"
                            defaultValue={selStaff.direct_line_no}
                            // isInvalid={!!errorsBasic.txtdirectline}
                          />
                          {/* <Form.Control.Feedback type="invalid">
                            {errorsBasic.txtdirectline}
                          </Form.Control.Feedback> */}
                        </>
                      ) : (
                        <div className="text-col">
                          {selStaff.direct_line_no
                            ? selStaff.direct_line_no
                            : "-"}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Mobile Number
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <>
                          <Form.Control
                            name="txtphone"
                            as="textarea"
                            rows={1}
                            defaultValue={selStaff.mobile_no}
                          />
                        </>
                      ) : (
                        <div className="text-col">
                          {/* {selStaff.mobile_no_code} */}
                          {selStaff.mobile_no ? selStaff.mobile_no : "-"}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              {/* 6 */}
              <Row>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Country
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <>
                          <Searchable
                            name="txtcountry"
                            isMulti={false}
                            options={dataCountry}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                            // isInvalid={!!errorsBasic.txtcountry}
                            // errorText={errorsBasic.txtcountry}
                            defaultValue={showLoc}
                          ></Searchable>
                        </>
                      ) : (
                        <div className="text-col">
                          {selStaff.citizenship_main
                            ? selStaff.citizenship_main
                            : "-"}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Languages
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <>
                          <Searchable
                            name="txtlanguages"
                            isMulti={true}
                            options={dataLanguages}
                            // isInvalid={!!errorsBasic.txtlanguages}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                            defaultValue={dataLanguages.filter((obj) =>
                              selStaff.languages.includes(obj.value)
                            )}
                            onChange={handleLanguages}
                            // errorText={errorsBasic.txtlanguages}
                          ></Searchable>
                        </>
                      ) : (
                        <div className="text-col">
                          {showLang ? showLang : "-"}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              {/* 7 */}
              <Row>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      LinkedIn Profile
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <>
                          <Form.Control
                            name="txtlinked"
                            type="text"
                            placeholder="http://"
                            defaultValue={selStaff.linkedln_url}
                          />
                        </>
                      ) : (
                        <div
                          className="text-col"
                          style={{ textTransform: "none" }}
                        >
                          {selStaff.linkedln_url ? (
                            <a
                              href={selStaff.linkedln_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {selStaff.linkedln_url}
                            </a>
                          ) : (
                            "-"
                          )}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Instagram Profile
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <>
                          <Form.Control
                            name="txtinsta"
                            type="text"
                            placeholder="http://"
                            defaultValue={selStaff.instagram_url}
                          />
                        </>
                      ) : (
                        <div
                          className="text-col"
                          style={{ textTransform: "none" }}
                        >
                          {selStaff.instagram_url ? (
                            <a
                              href={selStaff.instagram_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {selStaff.instagram_url}
                            </a>
                          ) : (
                            "-"
                          )}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              {/* 8 */}
              <Row>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Facebook Profile
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <>
                          <Form.Control
                            name="txtfacebook"
                            type="text"
                            placeholder="http://"
                            defaultValue={selStaff.facebook_url}
                          />
                        </>
                      ) : (
                        <div
                          className="text-col"
                          style={{ textTransform: "none" }}
                        >
                          {selStaff.facebook_url ? (
                            <a
                              href={selStaff.facebook_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {selStaff.facebook_url}
                            </a>
                          ) : (
                            "-"
                          )}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Others Website
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <>
                          <Form.Control
                            name="txtothweb"
                            type="text"
                            placeholder="http://"
                            defaultValue={selStaff.website_url}
                          />
                        </>
                      ) : (
                        <div
                          className="text-col"
                          style={{ textTransform: "none" }}
                        >
                          {selStaff.website_url ? (
                            <a
                              href={selStaff.website_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {selStaff.website_url}
                            </a>
                          ) : (
                            "-"
                          )}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>

          {editBasic === false ? null : (
            <div className="container-form-div">
              <div className="row-btn-center">
                <Button
                  onClick={() => btnCancelBasic(false)}
                  variant="secondary"
                >
                  Cancel
                </Button>
                <Button form="basic-staff-form" type="submit" variant="primary">
                  Save
                </Button>
              </div>
            </div>
          )}
          {/* End Basic Information Staff */}

          {/* Start Education */}
          <div className="tab-header-div">
            <div className="left-div">
              <div className="tab-header-font">Educations</div>
            </div>
            <div className="right-div">
              {editMode === false ? (
                <Button
                  onClick={() => btnEditMode(true, "second")}
                  variant="link"
                  className="modal-link"
                >
                  <Icon path={mdiPencil} size={0.8} />
                  Edit Educations
                </Button>
              ) : null}
            </div>
          </div>

          <div className="content-div">
            {editEdu === false ? null : (
              <div className="left-div" style={{ margin: "20px 0px" }}>
                <Button
                  onClick={btnAddEdu}
                  variant="link"
                  className="modal-link"
                >
                  <Icon path={mdiPlusCircle} size={0.8} />
                  Add Education
                </Button>
              </div>
            )}
            <div className="column-card">
              {showEducation.length > 0
                ? selStaff.candidate_education.map((education, index) => (
                    <CardCandidate key={index}>
                      <div className="card-education__row-flex5">
                        <div className="sub-text">{education.name}</div>
                        <div className="sub-text">
                          {education.qualification}
                        </div>
                        <div className="sub-text" style={{ fontWeight: 400 }}>
                          {education.start_year} - {education.end_year}
                        </div>
                      </div>
                      {editEdu === true ? (
                        <div>
                          <div>
                            {education.certificate_attachment ? (
                              <Button
                                onClick={btnDownload(
                                  education.id,
                                  education.certificate_attachment
                                )}
                                variant="link"
                                className="no-underline"
                              >
                                <Icon path={mdiFile} size={0.8} />
                                Show Certificate
                              </Button>
                            ) : (
                              <Button variant="link" className="no-underline">
                                No Certificate
                              </Button>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="card-education__row-flex1"></div>
                      )}

                      {editEdu === true ? (
                        <div className="card-education__row-flex1">
                          <div className="card-edu-btn">
                            <Button
                              onClick={btnEditEdu(education.id)}
                              variant="link"
                              className="modal-link"
                            >
                              <Icon path={mdiPencil} size={0.8} />
                            </Button>
                            <Button
                              onClick={btnDelEdu(education.id)}
                              variant="link"
                              className="delete-link"
                            >
                              <Icon path={mdiDelete} size={0.8} />
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div>
                            {education.certificate_attachment ? (
                              <Button
                                onClick={btnDownload(
                                  education.id,
                                  education.certificate_attachment
                                )}
                                variant="link"
                                className="no-underline"
                              >
                                <Icon path={mdiFile} size={0.8} />
                                Show Certificate
                              </Button>
                            ) : (
                              <Button variant="link" className="no-underline">
                                No Certificate
                              </Button>
                            )}
                          </div>
                        </div>
                      )}
                    </CardCandidate>
                  ))
                : "-"}
              {/* {} */}
            </div>
          </div>

          {editEdu === false ? null : (
            <div className="container-form-div">
              <div className="row-btn-center">
                <Button onClick={() => btnCancelEdu(false)} variant="secondary">
                  Close
                </Button>
              </div>
            </div>
          )}

          {/* Modal Education */}
          <Popup
            show={showEdu}
            size="lg"
            backdrop="static"
            keyboard={false}
            onHide={btnCloseEdu}
            title={modalEdu.title}
            content={
              <>
                <Form id="education-form" onSubmit={btnSaveUpdateEdu}>
                  {/* 1 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>
                      School <label className="validation-star">*</label>
                    </Form.Label>
                    <Col>
                      <Form.Control
                        name="txtschool"
                        type="text"
                        defaultValue={eduState.txtschool}
                        isInvalid={!!errorsEdu.txtschool}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorsEdu.txtschool}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  {/* 2 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Degree</Form.Label>
                    <Col>
                      <Form.Control
                        name="txtdegree"
                        type="text"
                        defaultValue={eduState.txtdegree}
                        // isInvalid={!!errorsEdu.txtdegree}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                        {errorsEdu.txtdegree}
                      </Form.Control.Feedback> */}
                    </Col>
                  </Form.Group>
                  {/* 3 */}
                  <Row>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label>Start year</Form.Label>
                        <Col>
                          <Form.Select
                            name="txtfromyear"
                            defaultValue={eduState.txtfromyear}
                          >
                            <option value="">Select</option>
                            {dataYear.map((year, index) => (
                              <option key={index} value={year.value}>
                                {year.label}
                              </option>
                            ))}
                          </Form.Select>
                          {/* <Form.Control.Feedback type="invalid">
                            {errorsEdu.txtfromyear}
                          </Form.Control.Feedback> */}
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label>End year</Form.Label>
                        <Col>
                          <Form.Select
                            name="txttoyear"
                            defaultValue={eduState.txttoyear}
                          >
                            <option value="">Select</option>
                            {dataYear.map((year, index) => (
                              <option key={index} value={year.value}>
                                {year.label}
                              </option>
                            ))}
                          </Form.Select>
                          {/* <Form.Control.Feedback type="invalid">
                            {errorsEdu.txttoyear}
                          </Form.Control.Feedback> */}
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* 4 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Certificates</Form.Label>
                    <Col>
                      <Form.Control type="file" name="txtcertificate" />
                    </Col>
                  </Form.Group>
                </Form>
              </>
            }
            button={
              <>
                <Button onClick={btnCloseEdu} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit" variant="primary" form="education-form">
                  {modalEdu.button}
                </Button>
              </>
            }
          />
          {/* End Education */}

          {/* Start Experience */}
          <div className="tab-header-div">
            <div className="left-div">
              <div className="tab-header-font">Experiences</div>
            </div>
            <div className="right-div">
              {editMode === false ? (
                <Button
                  onClick={() => btnEditMode(true, "third")}
                  variant="link"
                  className="modal-link"
                >
                  <Icon path={mdiPencil} size={0.8} />
                  Edit Experiences
                </Button>
              ) : null}
            </div>
          </div>

          <div className="content-div">
            {editExp === false ? null : (
              <div className="left-div" style={{ margin: "10px 0 0" }}>
                <Button
                  onClick={btnAddExp}
                  variant="link"
                  className="modal-link"
                >
                  <Icon path={mdiPlusCircle} size={0.8} />
                  Add Experience
                </Button>
              </div>
            )}

            {showExperience.length > 0
              ? selStaff.candidate_experiences.map((experience, index) => (
                  <div key={index}>
                    <div className="cdt-row">
                      <div className="col-5">
                        <div className="big-text">{experience.title}</div>
                        <div className="sub-text">
                          {experience.company_name}
                        </div>

                        {experience.current_working ? (
                          <div className="sub-text" style={{ fontWeight: 400 }}>
                            {experience.present_month
                              ? toMonthName(experience.present_month)
                              : " "}{" "}
                            {experience.present_year} - Current
                          </div>
                        ) : (
                          <>
                            {experience.start_date_month === "" &&
                            experience.end_date_month === "" &&
                            experience.start_date_year === "" &&
                            experience.end_date_year === "" ? null : (
                              <div
                                className="sub-text"
                                style={{ fontWeight: 400 }}
                              >
                                {toMonthName(experience.start_date_month)}{" "}
                                {experience.start_date_year} -{" "}
                                {toMonthName(experience.end_date_month)}{" "}
                                {experience.end_date_year}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      <div className="col-6" style={{ display: "flex" }}>
                        <div className="col-11">
                          <div
                            className="more-section-row"
                            hidden={!abilities.includes("view salary")}
                          >
                            <div className="mini-text flex-left">Salary</div>
                            <div className="sub-text flex-right">
                              {experience.currency}{" "}
                              {Number(experience.salary)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                            </div>
                          </div>
                          <div className="more-section-row">
                            <div className="mini-text flex-left">
                              Direct Line
                            </div>
                            <div className="sub-text flex-right">
                              {experience.office_number
                                ? experience.office_number
                                : "-"}
                            </div>
                          </div>
                          <div className="more-section-row">
                            <div className="mini-text flex-left">
                              Office Email
                            </div>
                            <div className="sub-text flex-right">
                              {experience.office_email
                                ? experience.office_email
                                : "-"}
                            </div>
                          </div>

                          {experience.current_working ? (
                            <div className="more-section-row">
                              <div className="mini-text flex-left">
                                Notice Period
                              </div>
                              <div className="sub-text flex-right">
                                {experience.notice_period
                                  ? experience.notice_period
                                  : "-"}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="col-1">
                          {editExp === false ? null : (
                            <div className="exp-button">
                              <Button
                                variant="link"
                                className="modal-link"
                                onClick={btnEditExp(experience.id)}
                              >
                                <Icon path={mdiPencil} size={0.8} />
                              </Button>
                              <Button
                                variant="link"
                                className="delete-link"
                                style={{ margin: 0 }}
                                onClick={btnDelExp(experience.id)}
                              >
                                <Icon path={mdiDelete} size={0.8} />
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Display if Read More button is clicked */}
                    {isReadMore.more === false && isReadMore.id === index ? (
                      <div className="cdt-row">
                        <div className="col-5">
                          {experience.reason_leaving ? (
                            <>
                              <div className="text">Reason for leaving</div>
                              <div
                                className="mini-text"
                                dangerouslySetInnerHTML={{
                                  __html: experience.reason_leaving
                                    ? experience.reason_leaving
                                    : "-",
                                }}
                              ></div>
                            </>
                          ) : null}

                          {experience.job_responsibility ? (
                            <>
                              <div className="text">Job Responsiblity</div>
                              <div
                                className="mini-text"
                                dangerouslySetInnerHTML={{
                                  __html: experience.job_responsibility,
                                }}
                              ></div>
                            </>
                          ) : null}
                        </div>
                        <div className="col-6">
                          <div className="col-11">
                            {experience.candidate_exp_allowance.length > 0 ? (
                              <div className="more-section-row">
                                <div className="mini-text flex-left">
                                  Allowance
                                </div>
                                <div style={{ flex: "1 1 60%" }}>
                                  {experience.candidate_exp_allowance.map(
                                    (allowance, index) => (
                                      <div
                                        className="sub-text flex-right"
                                        key={index}
                                      >
                                        {toAllowanceDesc(allowance.earning)} -{" "}
                                        {allowance.currency}{" "}
                                        {Number(allowance.amount)
                                          .toFixed(2)
                                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            ) : null}

                            {experience.other_benefits ? (
                              <div className="more-section-row">
                                <div className="mini-text flex-left">
                                  Other Benefits
                                </div>
                                <div
                                  className="sub-text flex-right"
                                  dangerouslySetInnerHTML={{
                                    __html: experience.other_benefits
                                      ? experience.other_benefits
                                      : "-",
                                  }}
                                ></div>
                              </div>
                            ) : null}
                          </div>
                          <div className="col-1"></div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {experience.other_benefits ||
                    experience.job_responsibility ||
                    experience.reason_leaving ||
                    experience.candidate_exp_allowance.length > 0 ? (
                      <div className="cdt-row">
                        <div className="col-6"></div>
                        <div className="col-6">
                          <div className="col-11">
                            <div className="more-section-row">
                              <div className="mini-text flex-left">{""}</div>
                              <div className="sub-text flex-right">
                                {isReadMore.more === false &&
                                isReadMore.id === index ? (
                                  <Button
                                    variant="link"
                                    className="read-btn"
                                    onClick={toggleBtnReadMore(index, true)}
                                  >
                                    Less details
                                  </Button>
                                ) : (
                                  <Button
                                    variant="link"
                                    className="read-btn"
                                    onClick={toggleBtnReadMore(index, false)}
                                  >
                                    More details
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-1"></div>
                        </div>
                      </div>
                    ) : null}

                    {/* */}
                  </div>
                ))
              : null}
          </div>

          {editExp === false ? null : (
            <div className="container-form-div">
              <div className="row-btn-center">
                <Button onClick={() => btnCancelExp(false)} variant="secondary">
                  Close
                </Button>
              </div>
            </div>
          )}

          {/* Modal Experience */}
          <Popup
            show={showExp}
            size="lg"
            backdrop="static"
            keyboard={false}
            onHide={btnCloseExp}
            title={modalExp.title}
            content={
              <>
                <Form id="experience-form" onSubmit={btnSaveUpdateExp}>
                  {/* 1 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>
                      Job Title <label className="validation-star">*</label>
                    </Form.Label>
                    <Col>
                      <Form.Control
                        name="txtjobtitle"
                        type="text"
                        defaultValue={expState.txtjobtitle}
                        isInvalid={!!errorsExp.txtjobtitle}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorsExp.txtjobtitle}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  {/* 2 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Job Function</Form.Label>
                    <Col>
                      <Searchable
                        name="ddljobfunction"
                        isMulti={true}
                        options={dataJobFunction}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.value}
                        onChange={handleJobFunc}
                        defaultValue={dataJobFunction.filter((obj) =>
                          expState.ddljobfunction.includes(obj.value)
                        )}
                      ></Searchable>
                    </Col>
                  </Form.Group>
                  {/* 3 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>
                      Company Name <label className="validation-star">*</label>
                    </Form.Label>
                    <Col>
                      <Form.Control
                        name="txtcompanyname"
                        type="text"
                        defaultValue={expState.txtcompanyname}
                        isInvalid={!!errorsExp.txtcompanyname}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorsExp.txtcompanyname}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  {/* 4 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Industry</Form.Label>
                    <Col>
                      <Searchable
                        name="ddlIndustry"
                        isMulti={true}
                        options={dataIndustry}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.value}
                        onChange={handleIndustry}
                        defaultValue={dataIndustry.filter((obj) =>
                          expState.ddlIndustry.includes(obj.value)
                        )}
                      ></Searchable>
                    </Col>
                  </Form.Group>
                  {/* 5 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>
                      Country <label className="validation-star">*</label>
                    </Form.Label>
                    <Col>
                      <Searchable
                        name="ddlCountry"
                        isMulti={false}
                        options={dataCountry}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.value}
                        isInvalid={!!errorsExp.ddlCountry}
                        errorText={errorsExp.ddlCountry}
                        defaultValue={expState.ddlCountry}
                      ></Searchable>
                    </Col>
                  </Form.Group>
                  {/* 6 */}
                  {checked === true ? (
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label>
                        Present
                        {/* <label className="validation-star">*</label> */}
                      </Form.Label>
                      <Col>
                        <Form.Select
                          name="ddlpremonth"
                          // onChange={handlePreMonth}
                          defaultValue={expState.ddlpremonth}
                          // isInvalid={!!errorsExp.ddlpremonth}
                        >
                          <option value="">Select</option>
                          {monthList.map((month, index) => (
                            <option key={index} value={month.value}>
                              {month.label}
                            </option>
                          ))}
                        </Form.Select>
                        {/* <Form.Control.Feedback type="invalid">
                          {errorsExp.ddlpremonth}
                        </Form.Control.Feedback> */}
                      </Col>
                      <Col>
                        <Form.Select
                          name="ddlpreyear"
                          defaultValue={expState.ddlpreyear}
                          // isInvalid={!!errorsExp.ddlpreyear}
                        >
                          <option value="">Select</option>
                          {dataYear.map((year, index) => (
                            <option key={index} value={year.value}>
                              {year.label}
                            </option>
                          ))}
                        </Form.Select>
                        {/* <Form.Control.Feedback type="invalid">
                          {errorsExp.ddlpreyear}
                        </Form.Control.Feedback> */}
                      </Col>
                    </Form.Group>
                  ) : (
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label>Start Date</Form.Label>
                      <Col>
                        <Form.Select
                          name="ddlstartmonth"
                          // onChange={handleStartMonth}
                          defaultValue={expState.ddlstartmonth}
                          // isInvalid={!!errorsExp.ddlstartmonth}
                        >
                          <option value="">Select</option>
                          {monthList.map((month, index) => (
                            <option key={index} value={month.value}>
                              {month.label}
                            </option>
                          ))}
                        </Form.Select>
                        {/* <Form.Control.Feedback type="invalid">
                          {errorsExp.ddlstartmonth}
                        </Form.Control.Feedback> */}
                      </Col>
                      <Col>
                        <Form.Select
                          name="ddlstartyear"
                          defaultValue={expState.ddlstartyear}
                          // isInvalid={!!errorsExp.ddlstartyear}
                        >
                          <option value="">Select</option>
                          {dataYear.map((year, index) => (
                            <option key={index} value={year.value}>
                              {year.label}
                            </option>
                          ))}
                        </Form.Select>
                        {/* <Form.Control.Feedback type="invalid">
                          {errorsExp.ddlstartyear}
                        </Form.Control.Feedback> */}
                      </Col>
                    </Form.Group>
                  )}

                  {/* 7 */}
                  {checked === true ? (
                    ""
                  ) : (
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label>End Date</Form.Label>
                      <Col>
                        <Form.Select
                          name="ddlendmonth"
                          // onChange={handleEndMonth}
                          defaultValue={expState.ddlendmonth}
                          // isInvalid={!!errorsExp.ddlendmonth}
                        >
                          <option value="">Select</option>
                          {monthList.map((month, index) => (
                            <option key={index} value={month.value}>
                              {month.label}
                            </option>
                          ))}
                        </Form.Select>
                        {/* <Form.Control.Feedback type="invalid">
                          {errorsExp.ddlendmonth}
                        </Form.Control.Feedback> */}
                      </Col>
                      <Col>
                        <Form.Select
                          name="ddlendyear"
                          defaultValue={expState.ddlendyear}
                          // isInvalid={!!errorsExp.ddlendyear}
                        >
                          <option value="">Select</option>
                          {dataYear.map((year, index) => (
                            <option key={index} value={year.value}>
                              {year.label}
                            </option>
                          ))}
                        </Form.Select>
                        {/* <Form.Control.Feedback type="invalid">
                          {errorsExp.ddlendyear}
                        </Form.Control.Feedback> */}
                      </Col>
                    </Form.Group>
                  )}

                  {/* 8 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label></Form.Label>
                    <Col>
                      <Form.Check
                        type="checkbox"
                        id="default-checkbox"
                        label="Currently Working"
                        defaultChecked={expState.cbWorkingCurrent}
                        onChange={(e) => setChecked(e.currentTarget.checked)}
                      />
                    </Col>
                  </Form.Group>
                  {/* 9 */}
                  <Row>
                    <Form.Group className="mb-3 col-6">
                      <Form.Label>Office Number</Form.Label>
                      <Col>
                        <Form.Control
                          name="txtofficephone"
                          type="number"
                          min="0"
                          defaultValue={expState.txtofficephone}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 col-6">
                      <Form.Label>Office Email</Form.Label>
                      <Col>
                        <Form.Control
                          name="txtofficeemail"
                          type="email"
                          defaultValue={expState.txtofficeemail}
                          // isInvalid={!!errorsExp.txtofficeemail}
                        ></Form.Control>
                        {/* <Form.Control.Feedback type="invalid">
                          {errorsExp.txtofficeemail}
                        </Form.Control.Feedback> */}
                      </Col>
                    </Form.Group>
                  </Row>
                  {/* 10 */}
                  {checked === true ? (
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label>Notice Period</Form.Label>
                      <Col>
                        <Form.Select
                          name="ddlperiod"
                          defaultValue={expState.ddlperiod}
                        >
                          <option value="">Select</option>
                          {noticeList.map((notice, index) => (
                            <option key={index} value={notice.label}>
                              {notice.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  ) : null}
                  {/* 11 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Job Responsibility</Form.Label>
                    <Col>
                      <RichTextEditor
                        name="txtjobresponsibility"
                        htmlContent={expState.txtjobresponsibility}
                      />
                    </Col>
                  </Form.Group>
                  {/* 12 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>
                      Salary
                      {/* <label className="validation-star">*</label> */}
                    </Form.Label>
                    <Col>
                      <Form.Select
                        name="ddlcurrency"
                        defaultValue={expState.ddlcurrency}
                        // isInvalid={!!errorsExp.ddlcurrency}
                      >
                        <option value="">Select</option>
                        {dataCurrency.map((currency, index) => (
                          <option key={index} value={currency.value}>
                            {currency.value}
                          </option>
                        ))}
                      </Form.Select>
                      {/* <Form.Control.Feedback type="invalid">
                        {errorsExp.ddlcurrency}
                      </Form.Control.Feedback> */}
                    </Col>
                    <Col>
                      <TextFieldFormat
                        name="txtsalary"
                        value={salaryTxt.current}
                        onChange={handleSalary}
                        thousandSeparator={true}
                      ></TextFieldFormat>
                      {/* <Form.Control.Feedback type="invalid">
                        {errorsExp.txtsalary}
                      </Form.Control.Feedback> */}
                    </Col>
                  </Form.Group>
                  {/* 13 */}
                  <Form.Group className="mb-3">
                    <Form.Label column lg={2}>
                      Allowance
                    </Form.Label>
                    <Col>
                      <CustomTable
                        headers={headers}
                        items={allowanceList}
                      ></CustomTable>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3">
                    <Col>
                      <Button onClick={btnAddNewAllowance} variant="primary">
                        Add New Allowance
                      </Button>
                    </Col>
                  </Form.Group>
                  {/* 14 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Other Benefits</Form.Label>
                    <Col>
                      <RichTextEditor
                        name="txtothbenefit"
                        htmlContent={expState.txtothbenefit}
                      />
                    </Col>
                  </Form.Group>
                  {/* 15 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Reason for leaving</Form.Label>
                    <Col>
                      <RichTextEditor
                        name="txtreason"
                        htmlContent={expState.txtreason}
                      />
                    </Col>
                  </Form.Group>
                </Form>
              </>
            }
            button={
              <>
                <Button onClick={btnCloseExp} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit" variant="primary" form="experience-form">
                  {modalExp.button}
                </Button>
              </>
            }
          />

          {/* Modal Allowance */}
          <Popup
            show={showOption}
            size="md"
            backdrop="static"
            keyboard={false}
            onHide={btnCloseOption}
            title="Add Allowance"
            content={
              <>
                <Form id="option-form" onSubmit={btnNewOption}>
                  {/* 1 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Allowance Description</Form.Label>
                    <Col>
                      <Form.Control
                        name="txtAllowDesc"
                        type="text"
                        // defaultValue={expState.txtjobtitle}
                        isInvalid={!!errorOpt.txtAllowDesc}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorOpt.txtAllowDesc}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Form>
              </>
            }
            button={
              <>
                <Button onClick={btnCloseOption} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit" variant="primary" form="option-form">
                  Save
                </Button>
              </>
            }
          />
          {/* End Experience */}

          {/* Start Referee */}
          <div className="tab-header-div">
            <div className="left-div">
              <div className="tab-header-font">References</div>
            </div>
            <div className="right-div">
              {editMode === false ? (
                <Button
                  onClick={() => btnEditMode(true, "fourth")}
                  variant="link"
                  className="modal-link"
                >
                  <Icon path={mdiPencil} size={0.8} />
                  Edit Reference
                </Button>
              ) : null}
            </div>
          </div>

          <div className="content-div">
            {editRef === false ? null : (
              <div className="left-div" style={{ margin: "20px 0px" }}>
                <Button
                  onClick={btnAddRef}
                  variant="link"
                  className="modal-link"
                >
                  <Icon path={mdiPlusCircle} size={0.8} />
                  Add Reference
                </Button>
              </div>
            )}

            <div className="column-card">
              {showReferee.length > 0
                ? selStaff.candidate_reference.map((referee, index) => (
                    <>
                      <Card key={index} style={{ display: "inline" }}>
                        <div className="card-ref-box col-4">
                          <div className="card-referee">
                            <div className="big-text">Referee {index + 1}</div>
                            {editRef === false ? null : (
                              <div style={{ display: "flex" }}>
                                <Button
                                  onClick={btnEditRef(referee.id)}
                                  variant="link"
                                  className="modal-link"
                                >
                                  <Icon path={mdiPencil} size={0.8} />
                                </Button>
                                <Button
                                  onClick={btnDelRef(referee.id)}
                                  variant="link"
                                  className="delete-link"
                                  style={{ marginLeft: 0 }}
                                >
                                  <Icon path={mdiDelete} size={0.8} />
                                </Button>
                              </div>
                            )}
                          </div>
                          <div className="card-text-style">{referee.name}</div>
                          <div className="card-text-style">
                            {referee.job_title}, {referee.current_company}
                          </div>
                          <div className="card-text-style">{referee.email}</div>
                          <div className="card-text-style">{referee.phone}</div>
                        </div>
                      </Card>
                    </>
                  ))
                : "-"}
            </div>
          </div>

          {editRef === false ? null : (
            <div className="container-form-div">
              <div className="row-btn-center">
                <Button onClick={() => btnCancelRef(false)} variant="secondary">
                  Close
                </Button>
              </div>
            </div>
          )}

          {/* Modal Referee */}
          <Popup
            show={showRef}
            size="lg"
            backdrop="static"
            keyboard={false}
            onHide={btnCloseReferee}
            title={modalRef.title}
            content={
              <>
                <Form id="referee-form" onSubmit={btnSaveUpdateRef}>
                  {/* 1 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>
                      Referee Name <label className="validation-star">*</label>
                    </Form.Label>
                    <Col>
                      <Form.Control
                        name="txtrefname"
                        type="text"
                        isInvalid={!!errorsRef.txtrefname}
                        defaultValue={refState.txtrefname}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorsRef.txtrefname}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  {/* 2 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Referee Job Title</Form.Label>
                    <Col>
                      <Form.Control
                        name="txtrefjobtitle"
                        type="text"
                        defaultValue={refState.txtrefjobtitle}
                      />
                    </Col>
                  </Form.Group>
                  {/* 3 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Email Address</Form.Label>
                    <Col>
                      <Form.Control
                        name="txtrefemail"
                        type="text"
                        defaultValue={refState.txtrefemail}
                      />
                    </Col>
                  </Form.Group>
                  {/* 4 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Contact Number</Form.Label>
                    <Col>
                      <Form.Control
                        name="txtrefphone"
                        type="number"
                        defaultValue={refState.txtrefphone}
                      />
                    </Col>
                  </Form.Group>
                  {/* 5 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Current Company</Form.Label>
                    <Col>
                      <Form.Control
                        name="txtrefcompany"
                        type="text"
                        defaultValue={refState.txtrefcompany}
                      />
                    </Col>
                  </Form.Group>
                  {/* 6 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Relationship</Form.Label>
                    <Col>
                      <Form.Control
                        name="txtrefrelationship"
                        type="text"
                        defaultValue={refState.txtrefrelationship}
                      />
                    </Col>
                  </Form.Group>
                </Form>
              </>
            }
            button={
              <>
                <Button onClick={btnCloseReferee} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit" variant="primary" form="referee-form">
                  {modalRef.button}
                </Button>
              </>
            }
          />
          {/* End Referee */}

          {/* Start Miscellaneous */}
          <div className="tab-header-div">
            <div className="left-div">
              <div className="tab-header-font">Miscellaneous Informations</div>
            </div>
            <div className="right-div">
              {editMode === false ? (
                <Button
                  onClick={() => btnEditMode(true, "fifth")}
                  variant="link"
                  className="modal-link"
                >
                  <Icon path={mdiPencil} size={0.8} />
                  Edit Miscellaneous Informations
                </Button>
              ) : null}
            </div>
          </div>

          <div className="content-div">
            {editMisc === false ? null : (
              <div className="left-div" style={{ margin: "20px 0px" }}>
                <Button
                  variant="link"
                  className="modal-link"
                  onClick={btnAddMisc}
                >
                  <Icon path={mdiPlusCircle} size={0.8} />
                  Add Miscellaneous Informations
                </Button>
              </div>
            )}

            <div className="column-card">
              {showMiscellaneous.length > 0
                ? selStaff.candidate_miscellaneous.map((miscell, index) => (
                    <>
                      <CardCandidate key={index}>
                        <div className="card-education__row-flex5">
                          <div className="exp-row">
                            <div className="exp-left">
                              <div className="sub-text">{miscell.misc}</div>
                            </div>
                            {editMisc === false ? null : (
                              <div className="exp-right">
                                <Button
                                  variant="link"
                                  className="modal-link"
                                  onClick={btnEditMisc(miscell.id)}
                                >
                                  <Icon path={mdiPencil} size={0.8} />
                                </Button>
                                <Button
                                  variant="link"
                                  className="delete-link"
                                  onClick={btnDelMis(miscell.id)}
                                >
                                  <Icon path={mdiDelete} size={0.8} />
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      </CardCandidate>
                    </>
                  ))
                : "-"}
            </div>
          </div>

          {editMisc === false ? null : (
            <div className="container-form-div">
              <div className="row-btn-center">
                <Button
                  onClick={() => btnCancelMisc(false)}
                  variant="secondary"
                >
                  Close
                </Button>
              </div>
            </div>
          )}

          {/* Modal Miscellaneous */}
          <Popup
            show={showMisc}
            size="lg"
            backdrop="static"
            keyboard={false}
            onHide={btnCloseMisc}
            title={modalMis.title}
            content={
              <>
                <Form id="miscell-form" onSubmit={btnSaveUpdateMisc}>
                  {/* 1 */}
                  <Form.Group as={Row} className="mb-3">
                    <Col>
                      <Form.Control
                        name="txtmiscell"
                        as="textarea"
                        rows={5}
                        isInvalid={!!errorsMis.txtmiscell}
                        defaultValue={misState.txtmiscell}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorsMis.txtmiscell}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Form>
              </>
            }
            button={
              <>
                <Button onClick={btnCloseMisc} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit" variant="primary" form="miscell-form">
                  {modalMis.button}
                </Button>
              </>
            }
          />
          {/* End Miscellaneous */}
        </Card>
      </div>
    </>
  );
};

export default ViewStaffClient;
