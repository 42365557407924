import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button, Table, Form } from "react-bootstrap";
import Api from "../../../core/services/api.service";
import Apiform from "../../../core/services/apiform.service";

import SwalAlert from "../../../components/Swal/SwalAlert";
import moment from "moment";

import Icon from "@mdi/react";
import { mdiArrowLeft, mdiAccountGroup } from "@mdi/js";

import RichTextEditor from "../../../components/RichTextArea/RichTextEditor";
import Searchable from "../../../components/DropDown/SearchableSelect";
import BreadCrumb from "../../../components/Breadcrumb/BreadCrumb";

import { storeTitle } from "../../../store/actions/headerTitleSlice";
import { getClientDetails, getClientStaff, getClientCandidate } from "../../../store/actions/clientSlice";
import { getAssignmentDetails } from "../../../store/actions/assignmentSlice";
import { getListStaff } from "../../../store/actions/staffSlice";


const ViewProgress = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const { clientDetails, clientListStaff, clientListCandidate } = useSelector((state) => state.client);
    const { assignDetails } = useSelector((state) => state.assignment);
    const { dataStaff } = useSelector((state) => state.staff);
    const { usrProfile } = useSelector((state) => state.user);

    const customSwal = SwalAlert();

    const [selProgress, setSelProgress] = useState({});

    const [selStaffClient, setSelStaffClient] = useState([]);
    const [selStaffLLM, setSelStaffLLM] = useState([]);
    const [selCandidate, setSelCandidate] = useState([]);
    const [checkProgress, setCheckProgress] = useState(false);

    const [errorProgress, setErrorProgress] = useState({});

    const selectedCrumb = (crumb) => {
        if(crumb === "Clients"){
            navigate("/clients/listActiveC");
        }else if(crumb === "Potential Clients"){
            navigate("/clients/listPotentialC");
        }else if(crumb === clientDetails.name){
          if(params.page === "active"){
            navigate("/clients/viewActiveC/" + params.id);
          }else{
            navigate("/clients/view-potential-client/" + params.id);
          }
        }else if(crumb === assignDetails.title){
            navigate("/clients/view-assignment/" + params.page + "/" + params.id + "/" + params.idassign)
        }
    };

    const handleStaffClient = (e) => {
        // console.log("e", e)
        setSelStaffClient(e);
    };

    const handleStaffLLM = (e) => {
        setSelStaffLLM(e);
        // setSelStaffLLM(Array.isArray(e) ? e.map((x) => x.id.toString()) : []);
    };

    const handleCandidate = (e) => {
        setSelCandidate(e);
    };
    
    const handleCheckProgress = (e) => {
        setCheckProgress(e.target.checked);
    };

    const getSelectedProgress = useCallback(() => {

        setSelStaffClient([]);
        setSelStaffLLM([]);
        setSelCandidate([]);

        Api.get(
          "client/" + params.id + "/assignment/" + params.idassign + "/progress/" + params.idpro + "/view"
        )
          .then((response) => {
            let data = response.data;
    
            // console.log("getSelectedProgress", data);
            setSelProgress(data);

            if(data.client_involve.length > 0){
                data.progress_has_staff.map((staffclient) => {
                    return setSelStaffClient(oldArray => [...oldArray,{
                        id: staffclient.candidate.id,
                        first_name: staffclient.candidate.first_name,
                        last_name: staffclient.candidate.last_name
                    }]);
                
                });
            }

            if(data.staff_involve.length > 0){
                data.progress_has_admin.map((staffllm) => {
                    return setSelStaffLLM(oldArray => [...oldArray,{
                        id: staffllm.user.id,
                        first_name: staffllm.user.first_name,
                        last_name: staffllm.user.last_name
                    }]);
                
                });
            }

            if(data.candidate_profile.length > 0){
                data.progress_has_candidate.map((cand) => {
                    return setSelCandidate(oldArray => [...oldArray,{
                        id: cand.candidate.id,
                        first_name: cand.candidate.first_name,
                        last_name: cand.candidate.last_name
                    }]);
                
                });
            }

          })
          .catch((error) => {
            console.log("error getSelectedProgress", error);
          });
    }, [params]);

    useEffect(() => {
        getSelectedProgress();

        dispatch(storeTitle({title: params.page === "active" ? "Clients" : "Potential Clients", icon: mdiAccountGroup}));
        dispatch(getClientDetails(params.id));
        dispatch(getAssignmentDetails(params.id, params.idassign));

        dispatch(getListStaff());
        dispatch(getClientStaff(params.id));
        dispatch(getClientCandidate(params.id, params.idassign));

    }, [getSelectedProgress, dispatch, params.id, params.idassign]);

    const formErrorPro = (object) => {
        const newErrors = {};
    
        if (!object.txtReportName || object.txtReportName === "")
          newErrors.txtReportName = "Fill in the blank!";
    
        return newErrors;
      };

    const btnUpdateProgress = (e) => {
        e.preventDefault();
    
        const data = new FormData(e.target);
        const dtPro = Object.fromEntries(data.entries());

        var staff_client = selStaffClient.map((s) => s.id);
        var staff_llm = selStaffLLM.map((s) => s.id);
        var candidate_list = selCandidate.map((s) => s.id);

        // console.log("selStaClient", candidate_list)

        const newErrors = formErrorPro(dtPro);

        if (Object.keys(newErrors).length > 0) {
            setErrorProgress(newErrors);
        } else {
            setErrorProgress({});

            customSwal
            .fire({
              title: "Do you want to update progress review agenda?",
              icon: "warning",
              showCancelButton: true,
              reverseButtons: true,
              confirmButtonText: "Yes",
            })
            .then((result) => {
              if (result.isConfirmed) {
                const formData = new FormData();
    
                if(staff_client.length > 0){
                    staff_client.forEach((item, index) => {
                      formData.append(`client_involve[${index}]`, item);
                    });
                }else{
                    formData.append('client_involve', []);
                }

                if(staff_llm.length > 0){
                    staff_llm.forEach((item, index) => {
                      formData.append(`staff_involve[${index}]`, item);
                    });
                }else{
                    formData.append('staff_involve', []);
                }

                formData.append("key_milestone", dtPro.txtKeymiles);
                formData.append("progress", dtPro.txtProgress);
                formData.append("market_feedback", dtPro.txtMarket);

                formData.append("attachment", dtPro.txtfile);
                formData.append("include_statistic", checkProgress === true ? "true": "false");
                

                if(candidate_list.length > 0){
                    candidate_list.forEach((item, index) => {
                      formData.append(`candidate_profile[${index}]`, item);
                    });
                }else{
                    formData.append('candidate_profile', []);
                }
                formData.append("report_name", dtPro.txtReportName);
                formData.append("updated_by", usrProfile.id);

                Apiform.patch("client/" + params.id + "/assignment/" + params.idassign +"/progress/" + params.idpro + "/update", formData)
                .then((response) => {
                    // console.log("user/store", response);
                    navigate(
                        "/clients/view-assignment/" +
                          params.page + "/" +
                          params.id +
                          "/" +
                          params.idassign
                      );

                    customSwal.fire({
                        title: "Updated Successfully!",
                        icon: "success",
                        confirmButtonText: "Okay",
                    });
                })
                .catch((error) => {
                    console.log("error btnUpdateProgress", error);
                });
              }
            });
        }
      

        
    }

    return (
        <>
            <div className="main-div">
                <div className="top-title-div both-side">
                    <BreadCrumb crumbs={[params.page === "active" ? "Clients" : "Potential Clients",  clientDetails.name, assignDetails.title, "Edit Progress Report"]} selected={selectedCrumb}></BreadCrumb>
                    <Button variant="secondary" onClick={() => navigate(-1)}>
                        <Icon path={mdiArrowLeft} size={0.7} color="#212529" />
                        Back
                    </Button>
                </div>
                <div className="bg-white-container">
                    <div className="outer-report-section mb-3">
                        <div style={{ fontSize: "20px" }}>Progress Review Agenda</div>
                        <div>Date : {moment(selProgress.created_at).format("DD MMMM YYYY")}</div>
                    </div>

                    <Form id="progress-form" onSubmit={btnUpdateProgress}>
                        <Table bordered>
                            <tbody>
                                <tr>
                                    <th width="20%" className="report-label">
                                        Report Name <label className="validation-star">*</label>
                                    </th>
                                    <td>
                                        <Form.Control
                                            name="txtReportName"
                                            type="text"
                                            defaultValue={selProgress.report_name}
                                            isInvalid={!!errorProgress.txtReportName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                        {errorProgress.txtReportName}
                                        </Form.Control.Feedback>
                                    </td>
                                </tr>
                                <tr>
                                    <th rowSpan={2}>Parties Involved</th>
                                    <td>
                                        <div className="text mb-3">Client:</div>
                                        <Searchable
                                        name="ddlClientStaff"
                                        isMulti={true}
                                        options={clientListStaff}
                                        getOptionValue={option => option.id}
                                        getOptionLabel={option => option.first_name + " " + option.last_name}
                                        onChange={handleStaffClient}
                                        value={selStaffClient}
                                        errorText="Fill in the blank!"
                                        ></Searchable>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="text mb-3">Lim-Loges & Masters:</div>
                                        <Searchable
                                        name="ddlStaffLLM"
                                        isMulti={true}
                                        options={dataStaff}
                                        getOptionValue={option => option.id}
                                        getOptionLabel={option => option.first_name + " " + option.last_name}
                                        onChange={handleStaffLLM}
                                        value={selStaffLLM}
                                        errorText="Fill in the blank!"
                                        ></Searchable>
                                    </td>
                                </tr>
                                <tr>
                                    <th width="20%">Key Milestones</th>
                                    <td>
                                        <RichTextEditor
                                            name="txtKeymiles"
                                            htmlContent={selProgress.key_milestone}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Progress</th>
                                    <td>
                                        <RichTextEditor
                                            name="txtProgress"
                                            htmlContent={selProgress.progress}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Market Feedback</th>
                                    <td>
                                        <RichTextEditor
                                            name="txtMarket"
                                            htmlContent={selProgress.market_feedback}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Candidate Profiles <br />
                                        (information provided as additional documents)
                                    </th>
                                    <td>
                                        <Searchable
                                            name="ddlCandidate"
                                            isMulti={true}
                                            options={clientListCandidate}
                                            getOptionValue={option => option.id}
                                            getOptionLabel={option => option.first_name + " " + option.last_name}
                                            onChange={handleCandidate}
                                            value={selCandidate}
                                            errorText="Fill in the blank!"
                                        ></Searchable>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Attachment</th>
                                    <td>
                                        <Form.Control name="txtfile" type="file" />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>

                        <div className="checkbox-list" style={{margin: "0px 10px"}}>
                            <Form.Check
                            name="cbProgress"
                            type="checkbox"
                            onChange={handleCheckProgress}
                            defaultChecked={selProgress.include_statistic}
                            />
                            <div className="list-title" style={{fontSize: "14px", fontWeight: "600"}}>
                            Do you want to include statistics into this progress report?
                            </div>
                        </div>
                    </Form>

                    <div className="row-btn-center">
                        <Button onClick={() => navigate(-1)} variant="secondary">Cancel</Button>
                        <Button 
                            variant="primary" 
                            type="submit"
                            form="progress-form"
                        >
                            Update Report
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewProgress;
