import React, { useEffect, useState, useCallback } from "react";
import { Tab, Nav, Button, Dropdown, Form, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Api from "../../../../core/services/api.service";

import { useParams, useNavigate } from "react-router-dom";

import Icon from "@mdi/react";
import {
  mdiArrowLeft,
  mdiDotsHorizontal,
  mdiPencil,
  mdiDelete,
  mdiPlusCircle,
  mdiDownload,
  mdiCloseCircle,
  mdiAccountSearch,
} from "@mdi/js";

import moment from "moment";
import AuthAbilities from "../../Auth/AuthAbilities";
import SwalAlert from "../../../../components/Swal/SwalAlert";

import Table from "../../../../components/Table/Table";
import CardInfo from "../../../../components/Card/CardInfo";
import Popup from "../../../../components/Popup/ModalPopup";
import Searchable from "../../../../components/DropDown/SearchableSelect";
import BreadCrumb from "../../../../components/Breadcrumb/BreadCrumb";
import { ECPReport } from "../../../../components/Report/Template/ECPReport";
import { QCPReport } from "../../../../components/Report/Template/QCPReport";

import { getListAllInterview } from "../../../../store/actions/interviewSlice";
import { getCandidateDetail } from "../../../../store/actions/candidateSlice";
import { getCompanySelected } from "../../../../store/actions/companySlice";
import { getListStaff } from "../../../../store/actions/staffSlice";
import { getClientStaff } from "../../../../store/actions/clientSlice";
import { storeTitle } from "../../../../store/actions/headerTitleSlice";
import { getAssignmentEcpQcp } from "../../../../store/actions/assignmentSlice";

const ViewCandidateAssg = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const abilities = AuthAbilities();

  const { candidateDetails } = useSelector((state) => state.candidate);
  const { dataInterview } = useSelector((state) => state.interview);
  const { dataComp } = useSelector((state) => state.company);
  const { dataStage, dataDuration } = useSelector((state) => state.option);
  const { dataStaff } = useSelector((state) => state.staff);
  const { clientListStaff } = useSelector((state) => state.client);
  const { assignEcpQcp } = useSelector((state) => state.assignment);

  const [crumbs, setCrumbs] = useState([]);

  const [errorForm, setErrorForm] = useState({});
  const [idInv, setIdInv] = useState("");

  const [showInterview, setShowInterview] = useState(false);
  const [modeModal, setModeModal] = useState({
    title: null,
    button: null,
  });

  const [showNote, setShowNote] = useState(false);

  const [invState] = useState({
    ddlStage: "",
    txtlocation: "",
    interview_date: "",
    interview_time: "",
    ddlInterview_Client: "",
    ddlInterview_LLM: "",
    txtaddnote: "",
    ddlDuration: "",
  });

  const customSwal = SwalAlert();

  // useState for tab reports

  const selectedCrumb = (crumb) => {
    const name = candidateDetails.last_name
      ? candidateDetails.first_name + " " + candidateDetails.last_name
      : candidateDetails.first_name;

    if (crumb === "List of Candidates") {
      navigate("/candidates/listCandidates");
    } else if (crumb === name) {
      navigate("/candidates/viewCandidate/" + params.id);
    }
  };

  // Header Event

  const headerReport = [
    {
      Header: "Report Name",
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        let nameRpt = "";

        if (data.type === "ecp") {
          nameRpt = "Emerging Candidate Profile";
        } else {
          nameRpt = "Qualified Candidate Profile";
        }

        return (
          <>
            <div>{nameRpt}</div>
          </>
        );
      },
    },
    {
      Header: "Created Date",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{moment(data.created_at).format("DD MMMM YYYY")}</div>;
      },
    },
    {
      Header: "",
      accessor: "actions",
      maxWidth: 100,
      minWidth: 80,
      width: 80,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={btnDownload(data)}>
                  <Icon path={mdiDownload} color="#467FD0" />
                  Download Report
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={btnEditReport(data.type, data.id)}
                  // hidden={!abilities.includes("edit candidate")}
                >
                  <Icon path={mdiPencil} color="#467FD0" />
                  Edit Report
                </Dropdown.Item>
                <Dropdown.Item
                hidden={!abilities.includes("delete qcp")}
                >
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete Report
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  // Interviews Tab

  const [noteInterview, setNoteInterview] = useState("");

  const headerInterview = [
    {
      Header: "Round of Interview",
      accessor: "stage",
      maxWidth: 300,
      minWidth: 100,
      width: 300,
    },
    {
      Header: "Date & Time",
      maxWidth: 400,
      minWidth: 100,
      width: 270,
      Cell: ({ row }) => {
        const data = row.original;

        const date = new Date(data.interview_date);
        const longMonth = date.toLocaleString("en-us", { month: "long" });

        const hms = data.interview_time;
        const target = new Date("1970-01-01 " + hms);

        const timeToDuration = new Date(
          target.getTime() + Number(data.duration) * 60000
        );

        const convertToTime = timeToDuration.toLocaleString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
        });

        const timeList = data.interview_time
          ? moment(data.interview_time, ["HH:mm"]).format("hh:mm A") +
            " - " +
            moment(convertToTime, ["HH:mm"]).format("hh:mm A")
          : "-";

        const columnDateTime =
          "<b>" +
          ("0" + date.getDate()).slice(-2) +
          " " +
          longMonth +
          " " +
          date.getFullYear() +
          "</b>" +
          "<br>" +
          timeList;

        return (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: data.interview_date
                  ? columnDateTime
                  : "Multiple interview times",
              }}
            ></div>
          </>
        );
      },
    },
    {
      Header: "Interviewer Staff Client Name",
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        var array = data.interview_has_staff.map((obj, index) => {
          return obj.candidate.first_name + " " + obj.candidate.last_name + ",";
        });

        var staff_client = array.join(" ");

        return <div>{staff_client ? staff_client.slice(0, -1) : "-"}</div>;
      },
    },
    {
      Header: "Interviewer Staff LLM Name",
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        var array = data.interview_has_admin.map((obj, index) => {
          return obj.user.first_name + " " + obj.user.last_name + ",";
        });

        var staff_admin = array.join(" ");

        return <div>{staff_admin ? staff_admin.slice(0, -1) : "-"}</div>;
      },
    },
    {
      Header: "Note",
      accessor: "notes",
      maxWidth: 380,
      minWidth: 80,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        let interviewnote = data.add_note;

        const btnShowNote = () => {
          setNoteInterview(interviewnote);
          setShowNote(true);
        };

        return (
          <>
            {interviewnote ? (
              <div className="container-div list-cli">
                <Button
                  variant="link"
                  className="reminder-btn set-rem"
                  onClick={btnShowNote}
                >
                  View Note
                </Button>
              </div>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      maxWidth: 150,
      minWidth: 150,
      width: 120,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <>
            {data.status === "declined" ? (
              <CardInfo
                cardTextSize="card_text--small"
                cardTextStyle="card_text--danger--solid"
              >
                {data.status}
              </CardInfo>
            ) : data.status === "created" ? (
              <CardInfo
                cardTextSize="card_text--small"
                cardTextStyle="card_text--primary--solid"
              >
                {data.status}
              </CardInfo>
            ) : (
              <CardInfo
                cardTextSize="card_text--small"
                cardTextStyle="card_text--completed--solid"
              >
                {data.status}
              </CardInfo>
            )}
          </>
        );
      },
    },
    {
      Header: "",
      accessor: "actions",
      maxWidth: 100,
      minWidth: 80,
      width: 80,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {data.status === "accepted" || data.status === "declined" ? (
                  <></>
                ) : (
                  <Dropdown.Item
                    onClick={btnEditInterview(data.id)}
                    hidden={!abilities.includes("edit interview")}
                  >
                    <Icon path={mdiPencil} color="#467FD0" />
                    Edit Interview
                  </Dropdown.Item>
                )}

                <Dropdown.Item hidden={!abilities.includes("delete interview")}>
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete Interview
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const [rowDateTime, setRowDateTime] = useState([
    { 
      interview_id: 1,
      interview_date: "",
      interview_time: "",
    },
  ]);

  const btnAddRowsDateTime = () => {
    const totLength = rowDateTime.length + 1;

    setRowDateTime((items) => [
      ...items,
      {
        interview_id: totLength,
        interview_date: "",
        interview_time: "",
      },
    ]);
  };

  const btnDelRowsDateTime = (index) => {

    let data = [...rowDateTime];
    data.splice(index, 1);
    setRowDateTime(data);

  };

  // Checking Form Error
  const formError = (object) => {
    const newErrors = {};

    // console.log("object", object);

    if (!object.ddlStage || object.ddlStage === "")
      newErrors.ddlStage = "Fill in the blank!";

    if (!object.interview_time || object.interview_time === "")
      newErrors.interview_time = "Fill in the blank!";

    if (!object.interview_date || object.interview_date === "")
      newErrors.interview_date = "Fill in the blank!";

    if (!object.ddlDuration || object.ddlDuration === "")
      newErrors.ddlDuration = "Fill in the blank!";

    return newErrors;
  };

  // Button Event
  const btnCloseInterview = () => {
    setShowInterview(false);
  };

  const btnAddInterview = () => {
    invState.ddlStage = null;
    invState.txtlocation = null;
    invState.interview_date = null;
    invState.interview_time = null;
    invState.txtaddnote = null;
    invState.ddlDuration = null;
    setSelectedClientStaff([]);
    setSelectedLLMStaff([]);
    // setNewInview([]);

    setErrorForm({});
    setModeModal({
      title: "Add Interview",
      button: "Add",
    });
    setIdInv("New");
    setRowDateTime([
      {
        interview_id: 1,
        interview_date: "",
        interview_time: "",
      },
    ]);
    setShowInterview(true);
  };

  const [selectedLLMStaff, setSelectedLLMStaff] = useState([]);
  const [selectedClientStaff, setSelectedClientStaff] = useState([]);

  const btnEditInterview = (id) => (e) => {
    setSelectedClientStaff([]);
    setSelectedLLMStaff([]);
    setRowDateTime([]);

    const filteredInv = dataInterview.filter((interview) => {
      return interview.id === id;
    });
    // console.log("filteredInv", filteredInv[0]);

    invState.ddlStage = filteredInv[0].stage;
    invState.txtlocation = filteredInv[0].location;
    invState.ddlDuration = filteredInv[0].duration;
    invState.txtaddnote = filteredInv[0].add_note;
    // invState.interview_date = filteredInv[0].interview_date ? filteredInv[0].interview_date.substring(0, 10): "";
    // invState.interview_time = filteredInv[0].interview_time;

    // setRowDateTime(filteredInv[0].interview_date_time);
    if(filteredInv[0].interview_date_time.length > 0){
      filteredInv[0].interview_date_time.map((inter) => {
        return setRowDateTime((items) => [
          ...items,
          {
            interview_id: inter.id,
            interview_date: inter.interview_date.substring(0, 10),
            interview_time: inter.interview_time,
          },
        ]);
      });
    }else{
      setRowDateTime((items) => [
        ...items,
        {
          interview_id: 1,
          interview_date: filteredInv[0].interview_date ? filteredInv[0].interview_date.substring(0, 10): "",
          interview_time: filteredInv[0].interview_time
        },
      ])
    }
    

    filteredInv[0].interview_has_staff.map((cliSta) => {
      return setSelectedClientStaff((items) => [
        ...items,
        {
          id: cliSta.candidate_id,
          first_name: cliSta.candidate.first_name,
          last_name: cliSta.candidate.last_name,
        },
      ]);
    });

    filteredInv[0].interview_has_admin.map((llmSta) => {
      return setSelectedLLMStaff((items) => [
        ...items,
        {
          id: llmSta.user_id,
          first_name: llmSta.user.first_name,
          last_name: llmSta.user.last_name,
        },
      ]);
    });

    const StaffClient = filteredInv[0].interview_has_staff.map(
      (option) => option.candidate_id
    );
    const LLMClient = filteredInv[0].interview_has_admin.map(
      (option) => option.user_id
    );

    setSelStaffLLM(LLMClient);
    setSelStaffClient(StaffClient);

    setErrorForm({});
    setModeModal({
      title: "Edit Interview",
      button: "Update",
    });
    setIdInv(id);
    setShowInterview(true);
  };

  const btnSaveUpdateInv = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtInv = Object.fromEntries(data.entries());

    // console.log("row", rowDateTime)

    const newErrors = formError(dtInv);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      if (idInv === "New") {
        customSwal
          .fire({
            title: "Do you want to save new interview?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post(
                "candidate/" +
                  params.id +
                  "/assignment/" +
                  params.idassign +
                  "/interview/create",
                {
                  stage: dtInv.ddlStage,
                  location: dtInv.txtlocation,
                  duration: dtInv.ddlDuration,
                  iv_date_time: rowDateTime.length > 1 ? rowDateTime : [],
                  interview_date: rowDateTime.length > 1 ? "" : dtInv.interview_date,
                  interview_time: rowDateTime.length > 1 ? "" : dtInv.interview_time,
                  staff_involve: selStaffLLM,
                  client_involve: selStaffClient,
                  add_note: dtInv.txtaddnote,
                }
              )
                .then((response) => {
                  // console.log(response);

                  getListInterview();
                  setShowInterview(false);
                  customSwal.fire({
                    title: "Interview Saved Successfully!",
                    icon: "success",
                    confirmButtonText: "Okay",
                  });
                })
                .catch((error) => {
                  console.log("save Referee", error);
                });
            }
          });
      } else {
        // console.log("id", idInv);
        // console.log("dtInv", dtInv);
        // console.log("selStaffLLM", selStaffLLM);
        // console.log("selStaffClient", selStaffClient);
        // console.log("setRowDateTime", rowDateTime)
        customSwal
          .fire({
            title: "Do you want to update interview?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.patch(
                "candidate/" +
                  params.id +
                  "/assignment/" +
                  params.idassign +
                  "/interview/" +
                  idInv +
                  "/update",
                {
                  stage: dtInv.ddlStage,
                  location: dtInv.txtlocation,
                  duration: dtInv.ddlDuration,
                  iv_date_time: rowDateTime.length > 1 ? rowDateTime : [],
                  interview_date: rowDateTime.length > 1 ? "" : dtInv.interview_date,
                  interview_time: rowDateTime.length > 1 ? "" : dtInv.interview_time,
                  staff_involve: selStaffLLM,
                  client_involve: selStaffClient,
                  add_note: dtInv.txtaddnote,
                }
              )
                .then((response) => {
                  // console.log(response);

                  getListInterview();
                  setShowInterview(false);
                  customSwal.fire({
                    title: "Interview Saved Successfully!",
                    icon: "success",
                    confirmButtonText: "Okay",
                  });
                })
                .catch((error) => {
                  console.log("save Referee", error);
                });
            }
          });
      }
    }
  };

  const btnDownload = (data) => () => {
    // console.log("data", data);
    if (data.type === "ecp") {
      Api.get(
        "candidate/" +
          data.candidate_id +
          "/assignment/" +
          data.assignment_id +
          "/ecpqcp/ecp/" +
          data.id
      )
        .then((response) => {
          let data = response.data;
          // console.log("ecp data", data);

          ECPReport(data);
        })
        .catch((error) => {
          console.log("error ecp data", error);
        });
    } else {
      Api.get(
        "candidate/" +
          data.candidate_id +
          "/assignment/" +
          data.assignment_id +
          "/ecpqcp/qcp/" +
          data.id
      )
        .then((response) => {
          let data = response.data;
          // console.log("qcp data", data);

          QCPReport(data);
        })
        .catch((error) => {
          console.log("error qcp data", error);
        });
    }
    // ECPReport(data);
  };

  const btnEditReport = (type, id) => () => {
    // console.log("type", id)
    if (type === "qcp") {
      navigate(
        "/candidates/qcp/" + params.id + "/" + params.idassign + "/" + id
      );
    } else {
      navigate(
        "/candidates/ecp/" + params.id + "/" + params.idassign + "/" + id
      );
    }
  };

  const btnCloseNote = () => {
    setShowNote(false);
  };

  const [selStaffLLM, setSelStaffLLM] = useState([]);
  const [selStaffClient, setSelStaffClient] = useState([]);

  const handleStaffLLM = (e) => {
    setSelStaffLLM(Array.isArray(e) ? e.map((x) => x.id) : []);
  };

  const handleStaffClient = (e) => {
    setSelStaffClient(Array.isArray(e) ? e.map((x) => x.id) : []);
  };

  const handleFormChange = (index, event) => {
    // console.log("invState.txtdateinv", event.target)

    let data = [...rowDateTime];
    data[index][event.target.name] = event.target.value;

    setRowDateTime(data);
  };

  const getListInterview = useCallback(() => {
    dispatch(getListAllInterview(params.id, params.idassign));
    dispatch(getCandidateDetail(params.id));
    dispatch(getCompanySelected(params.idclient));
  }, [params, dispatch]);

  useEffect(() => {
    dispatch(getListStaff());
    dispatch(getClientStaff(params.idclient));

    getListInterview();

    const name = candidateDetails.last_name
      ? candidateDetails.first_name + " " + candidateDetails.last_name
      : candidateDetails.first_name;
    setCrumbs(["List of Candidates", name, dataComp.name]);

    dispatch(
      storeTitle({ title: "List of Candidates", icon: mdiAccountSearch })
    );
    dispatch(getAssignmentEcpQcp(params.id, params.idassign));

    // dispatch(getListAllInterview(params.id,params.idassign));

    // if (dataInterview.length > 0) {
    // setListInterview(dataInterview);
    // }
  }, [
    candidateDetails.first_name,
    candidateDetails.last_name,
    dataComp.name,
    dispatch,
    getListInterview,
    params.idclient,
    params.id,
    params.idassign,
  ]);

  return (
    <div className="main-div list-cli">
      <div className="container-div both-side">
        <BreadCrumb crumbs={crumbs} selected={selectedCrumb}></BreadCrumb>
        <Button variant="secondary" onClick={() => navigate(-1)}>
          <Icon path={mdiArrowLeft} color="#212529" />
          Back
        </Button>
      </div>
      <Tab.Container defaultActiveKey="first">
        <div className="nav-container-nf">
          <Nav variant="tabs" justify>
            <Nav.Item>
              <Nav.Link eventKey="first">Interviews</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">Reports</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>

        <Tab.Content>
          <Tab.Pane eventKey="first">
            <div className="tab-pane-div">
              <div className="row-div">
                <div className="left-div"></div>
                <div className="right-div">
                  <Button
                    variant="link"
                    className="modal-link"
                    onClick={btnAddInterview}
                    hidden={!abilities.includes("add interview")}
                  >
                    <Icon path={mdiPlusCircle} />
                    Add Interview Round
                  </Button>
                </div>
              </div>

              {/* Modal Add and Edit Interview */}
              <Popup
                show={showInterview}
                size="lg"
                backdrop="static"
                keyboard={false}
                onHide={btnCloseInterview}
                title={modeModal.title}
                content={
                  <>
                    <Form id="inv-form" onSubmit={btnSaveUpdateInv}>
                      {/* 1 */}
                      <Row>
                        <Form.Group className="mb-3 col-6">
                          <Form.Label>
                            Round of Interview{" "}
                            <label className="validation-star">*</label>
                          </Form.Label>
                          <Form.Select
                            name="ddlStage"
                            isInvalid={!!errorForm.ddlStage}
                            defaultValue={invState.ddlStage}
                          >
                            <option value="">Please select</option>
                            {dataStage.map((stage, index) => (
                              <option key={index} value={stage.value}>
                                {stage.value}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errorForm.ddlStage}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3 col-6">
                          <Form.Label>Interview Location</Form.Label>
                          <Form.Control
                            type="text"
                            name="txtlocation"
                            placeholder="Please enter interview location"
                            defaultValue={invState.txtlocation}
                          />
                        </Form.Group>
                      </Row>
                      {/* 2 */}
                      <Row>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Duration{" "}
                            <label className="validation-star">*</label>
                          </Form.Label>
                          <Form.Select
                            name="ddlDuration"
                            isInvalid={!!errorForm.ddlDuration}
                            defaultValue={invState.ddlDuration}
                          >
                            <option value="">Please select</option>
                            {dataDuration.map((dur, index) => (
                              <option key={index} value={dur.code}>
                                {dur.value}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errorForm.ddlDuration}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      {/* 3 */}
                      {rowDateTime.map((row, index) => {
                        return(
                          <Row key={index}>
                             <Form.Group className="mb-3 col-6">
                                <Form.Label>
                                  Interview Date {index + 1}{" "}
                                  <label className="validation-star">*</label>
                                </Form.Label>
                                <Form.Control
                                  type="date"
                                  name="interview_date"
                                  value={row.interview_date}
                                  onChange={(event) =>
                                    handleFormChange(index, event)
                                  }
                                  isInvalid={!!errorForm.interview_date}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errorForm.interview_date}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className={rowDateTime.length > 1 ? "mb-3 col-5" : "mb-3 col-6"}>
                                <Form.Label>
                                  Interview Time {index + 1}{" "}
                                  <label className="validation-star">*</label>
                                </Form.Label>
                                <Form.Control
                                  type="time"
                                  name="interview_time"
                                  value={row.interview_time}
                                  onChange={(event) =>
                                    handleFormChange(index, event)
                                  }
                                  isInvalid={!!errorForm.interview_time}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errorForm.interview_time}
                                </Form.Control.Feedback>
                              </Form.Group>
                              {rowDateTime.length > 1 ? 
                                <Form.Group className="mb-3 col-1">
                                  <Form.Label></Form.Label>
                                  <div className="mt-3">
                                  
                                      <Button
                                      variant="link"
                                      className="modal-link"
                                      onClick={() => btnDelRowsDateTime(index)}
                                    >
                                      <Icon path={mdiCloseCircle} />
                                    </Button>
                                    
                                  </div>
                                </Form.Group> : ""}
                          </Row>
                        )
                      })}

                      {rowDateTime.length < 3 ? (
                        <div className="mb-2">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              variant="link"
                              className="modal-link"
                              onClick={btnAddRowsDateTime}
                            >
                              <Icon path={mdiPlusCircle} />
                              Alternate Interview Date & Time
                            </Button>
                          </div>
                        </div>
                      ) : null}
                      
                      {/* 4 */}
                      <Form.Group className="mb-3">
                        <Form.Label>Interviewer Staff Client Name</Form.Label>
                        <Col>
                          <Searchable
                            name="ddlInterview_Client"
                            isMulti={true}
                            options={clientListStaff}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) =>
                              option.first_name + " " + option.last_name
                            }
                            onChange={handleStaffClient}
                            defaultValue={selectedClientStaff}
                            errorText="Fill in the blank!"
                          ></Searchable>
                        </Col>
                      </Form.Group>
                      {/* 5 */}
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Interviewer Staff Lim-Loges & Masters Name
                        </Form.Label>
                        <Col>
                          <Searchable
                            name="ddlInterview_LLM"
                            isMulti={true}
                            options={dataStaff}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) =>
                              option.first_name + " " + option.last_name
                            }
                            onChange={handleStaffLLM}
                            defaultValue={selectedLLMStaff}
                            errorText="Fill in the blank!"
                          ></Searchable>
                        </Col>
                      </Form.Group>
                      {/* 6 */}
                      <Form.Group className="mb-3">
                        <Form.Label>Additional Notes</Form.Label>
                        <Form.Control
                          name="txtaddnote"
                          as="textarea"
                          rows={3}
                          defaultValue={invState.txtaddnote}
                        />
                      </Form.Group>
                    </Form>
                  </>
                }
                button={
                  <>
                    <Button variant="secondary" onClick={btnCloseInterview}>
                      Cancel
                    </Button>
                    <Button variant="primary" type="submit" form="inv-form">
                      {modeModal.button}
                    </Button>
                  </>
                }
              />

              {/* Modal View Note */}
              <Popup
                show={showNote}
                size="md"
                backdrop="static"
                keyboard={false}
                onHide={btnCloseNote}
                title="Notes"
                content={
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label>Additional Notes</Form.Label>
                      <Form.Control
                        name="txtfollownote"
                        as="textarea"
                        rows={5}
                        defaultValue={noteInterview}
                        readOnly
                      />
                    </Form.Group>
                  </>
                }
                button={
                  <>
                    <Button variant="secondary" onClick={btnCloseNote}>
                      Close
                    </Button>
                  </>
                }
              />

              <Table headers={headerInterview} items={dataInterview}></Table>
            </div>
          </Tab.Pane>

          <Tab.Pane eventKey="second">
            <div className="tab-pane-div">
              <Table headers={headerReport} items={assignEcpQcp}></Table>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default ViewCandidateAssg;
