import React, { useState, useEffect, useCallback } from "react";
import Api from "../../core/services/api.service";
import { Card, Dropdown } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import AuthAbilities from "../../pages/Admin/Auth/AuthAbilities";
import moment from "moment";
import SwalAlert from "../Swal/SwalAlert";

import Icon from "@mdi/react";
import {
  mdiDelete,
  mdiDotsHorizontal,
  mdiDownload,
  mdiPencil,
  mdiEmail,
} from "@mdi/js";

import { CustomTable } from "../Table/Table";
import { ECPReport } from "../Report/Template/ECPReport";
import { QCPReport } from "../Report/Template/QCPReport";

export const TabFileAssignments = (props) => {
  const params = useParams();
  const abilities = AuthAbilities();

  const customSwal = SwalAlert();
  const navigate = useNavigate();

  const btnEditReport = (type, data) => () => {
    if (type === "qcp") {
      navigate(
        "/clients/qcp/" + props.page + "/" +
          data.candidate_id +
          "/" +
          data.assignment_id +
          "/" +
          data.id
      );
    } else {
      navigate(
        "/clients/ecp/" + props.page + "/" +
          data.candidate_id +
          "/" +
          data.assignment_id +
          "/" +
          data.id
      );
    }
  };

  const btnDeleteECP = (objEcp) => (e) => {

    const name = objEcp.candidates.first_name + " " +  objEcp.candidates.last_name
    customSwal
      .fire({
        title: "Do you want to delete this ECP " + name + "?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("candidate/" + objEcp.candidate_id + "/assignment/" + objEcp.assignment_id + "/ecpqcp/ecp/" + objEcp.id)
            .then((response) => {
              // let data = response.data;

              getListDocumentQCPECP();

              customSwal.fire({
                title: "Delete Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error delete ecp", error);
            });
        }
      });
  };

  const btnDeleteQcp = (objQcp) => (e) => {
    
    const name = objQcp.candidates.first_name + " " +  objQcp.candidates.last_name
    customSwal
      .fire({
        title: "Do you want to delete this QCP " + name + "?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("candidate/" + objQcp.candidate_id + "/assignment/" + objQcp.assignment_id + "/ecpqcp/qcp/" + objQcp.id)
            .then((response) => {
              // let data = response.data;

              getListDocumentQCPECP();

              customSwal.fire({
                title: "Delete Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error delete qcp", error);
            });
        }
      });
  };

  const headerFilesECP = [
    {
      Header: "No",
      maxWidth: 50,
      minWidth: 10,
      width: 40,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "File Name",
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            ECP {data.candidates ? data.candidates.first_name + " " + data.candidates.last_name : "-"}
          </div>
        );
      },
    },
    {
      Header: "Date Created",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{moment(data.created_at).format("DD MMMM YYYY")}</div>;
      },
    },
    {
      Header: "",
      accessor: "actions",
      maxWidth: 100,
      minWidth: 80,
      width: 80,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={btnDownload(data, "ecp")}>
                  <Icon path={mdiDownload} color="#467FD0" />
                  Download File
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={btnEditReport("ecp", data)}
                  hidden={!abilities.includes("edit ecp")}
                >
                  <Icon path={mdiPencil} color="#467FD0" />
                  Edit File
                </Dropdown.Item>
                <Dropdown.Item onClick={btnDeleteECP(data)} hidden={!abilities.includes("delete ecp")}>
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete File
                </Dropdown.Item>
                <Dropdown.Item>
                  <Icon path={mdiEmail} color="#42BA96" />
                  Email File
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const headerFilesQCP = [
    {
      Header: "No",
      maxWidth: 50,
      minWidth: 10,
      width: 40,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "File Name",
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            QCP {data.candidates ? data.candidates.first_name + " " + data.candidates.last_name : "-"}
          </div>
        );
      },
    },
    {
      Header: "Date Created",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{moment(data.created_at).format("DD MMMM YYYY")}</div>;
      },
    },
    {
      Header: "",
      accessor: "actions",
      maxWidth: 100,
      minWidth: 80,
      width: 80,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="dropdown-container">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={btnDownload(data, "qcp")}>
                  <Icon path={mdiDownload} color="#467FD0" />
                  Download File
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={btnEditReport("qcp", data)}
                  hidden={!abilities.includes("edit qcp")}
                >
                  <Icon path={mdiPencil} color="#467FD0" />
                  Edit File
                </Dropdown.Item>
                <Dropdown.Item onClick={btnDeleteQcp(data)} hidden={!abilities.includes("delete qcp")}>
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete File
                </Dropdown.Item>
                <Dropdown.Item>
                  <Icon path={mdiEmail} color="#42BA96" />
                  Email File
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const btnDownload = (data, type) => () => {
    if (type === "ecp") {
      Api.get(
        "candidate/" +
          data.candidate_id +
          "/assignment/" +
          data.assignment_id +
          "/ecpqcp/ecp/" +
          data.id
      )
        .then((response) => {
          let data = response.data;

          ECPReport(data);
        })
        .catch((error) => {
          console.log("error ecp data", error);
        });
    } else {
      Api.get(
        "candidate/" +
          data.candidate_id +
          "/assignment/" +
          data.assignment_id +
          "/ecpqcp/qcp/" +
          data.id
      )
        .then((response) => {
          let data = response.data;

          QCPReport(data);
        })
        .catch((error) => {
          console.log("error qcp data", error);
        });
    }
  };

  const [listDocEcp, setListDocECP] = useState([]);
  const [listDocQcp, setListDocQCP] = useState([]);

  const getListDocumentQCPECP = useCallback(() => {
    Api.get(
      "client/" +
        params.id +
        "/assignment/" +
        params.idassign +
        "/listAllEcpQcp"
    )
      .then((response) => {
        let data = response.data;

        // console.log("data getListDocumentQCPECP", data);

        if (data.ecp.length > 0) {
          let sortJDData = data.ecp.sort(function (a, b) {
            return new Date(a.created_at) - new Date(b.created_at);
          });

          setListDocECP(sortJDData);
        }

        if (data.qcp.length > 0) {
          let sortJDData = data.qcp.sort(function (a, b) {
            return new Date(a.created_at) - new Date(b.created_at);
          });

          setListDocQCP(sortJDData);
        }
      })
      .catch((error) => {
        console.log("error getListDocumentQCPECP", error);
      });
  }, [params]);

  useEffect(() => {
    getListDocumentQCPECP();
  }, [getListDocumentQCPECP]);

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <Card.Body>
          <div className="title-text report">
            Emerging Candidate Profile (ECP)
          </div>
          <CustomTable
            headers={headerFilesECP}
            items={listDocEcp}
            paginate={listDocEcp.length}
            maxRows={15}
          ></CustomTable>
        </Card.Body>
      </Card>

      <Card style={{ marginBottom: "20px" }}>
        <Card.Body>
          <div className="title-text report">
            Qualified Candidate Profile (QCP)
          </div>
          <CustomTable
            headers={headerFilesQCP}
            items={listDocQcp}
            paginate={listDocQcp.length}
            maxRows={15}
          ></CustomTable>
        </Card.Body>
      </Card>
    </>
  );
};
