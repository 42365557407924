import React from "react";
import { useNavigate } from "react-router-dom";
import AccessImg from "../assets/access.jpg";
import { Button } from "react-bootstrap";

function Access() {
  const navigate = useNavigate();
  
  return (
    <div className="main-div" style={{ height: "100vh" }}>
      <div className="verify-container" style={{ boxShadow: "none" }}>
        <img src={AccessImg} alt="No Access" width={500} />
        <div className="verify-text">
          <span style={{ fontSize: "24px", fontWeight: 700 }}>
            We are sorry,{" "}
          </span>
          <br />
          but you don't have permission to access this page or resource.
        </div>
        <Button variant="primary" onClick={() => navigate(-1)}>
          Go to previous page
        </Button>
      </div>
    </div>
  );
}

export default Access;
