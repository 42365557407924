import { createSlice } from "@reduxjs/toolkit";
import Api from "../../core/services/api.service";

export const statisticsSlice = createSlice({
  name: "statistics",
  initialState: {
    staGender: [],
    staIndustries: [],
    staExperiences: [],
    staCountries: [],
  },
  reducers: {
    getStatisticsGender: (state, action) => {
      state.staGender = action.payload;
    },
    getStatisticsIndustries: (state, action) => {
      state.staIndustries = action.payload;
    },
    getStatisticsExperience: (state, action) => {
      state.staExperiences = action.payload;
    },
    getStatisticsCountries: (state, action) => {
      state.staCountries = action.payload;
    },
    
  },
});


export const getListStatistics = (id, idassign) => async (dispatch, rejectWithValue) => {
  try{
    const response = await Api.get("client/" + id + "/assignment/" + idassign + "/statistic/getAllStat");

    const dtGen = response.data.gender.filter(obj => {
        return obj.name !== "";
    });

    dispatch(getStatisticsGender(dtGen));

    const dtInd = response.data.industry.filter(obj => {
        return obj.name !== "";
    });

    dispatch(getStatisticsIndustries(dtInd));

    const dtCou = response.data.country.filter(obj => {
        return obj.name !== "";
    });
    
    dispatch(getStatisticsCountries(dtCou))

    const dtExp = response.data.experience.filter(obj => {
        return obj.y > 0;
    });

    dispatch(getStatisticsExperience(dtExp));

  } catch (err) {
    throw rejectWithValue(err.message);
  }
};

export const {getStatisticsGender, getStatisticsIndustries, getStatisticsExperience, getStatisticsCountries} = statisticsSlice.actions;
export default statisticsSlice.reducer;
