import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button, Tab, Nav, Badge, Form } from "react-bootstrap";
import Api from "../../../core/services/api.service";
import axios from "axios";

import moment from "moment";
import SwalAlert from "../../../components/Swal/SwalAlert";
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';

import Icon from "@mdi/react";
import {
  mdiCalendar,
  mdiCircleSmall,
  mdiClockOutline,
  mdiMapMarkerOutline,
  mdiPhone,
  mdiVideo,
  mdiAccountMultiple,
  mdiTextBoxMultipleOutline,
  mdiMessagePlus,
} from "@mdi/js";

import "./meeting.css";
import Modal from "../../../components/Popup/ModalPopup";

import {
  // getListOfMeetingAdmin,
  getListOfMeetingByUser,
  // updateMeetingStatus,
  // updateMeetingReadAt,
} from "../../../store/actions/meetingSlice";
import { storeTitle } from "../../../store/actions/headerTitleSlice";

const Meeting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customSwal = SwalAlert();

  const { meetingList, meetingListPast } = useSelector(
    (state) => state.meeting
  );
  const { usrProfile } = useSelector((state) => state.user);

  const [meetState, setMeetState] = useState({
    meet_id: 0,
    meet_idclient: 0,
    meet_name: "",
    meet_date: "",
    meet_time: "",
    meet_type: "",
    meet_icon: "",
    meet_location: "",
    meet_company_name: "",
    meet_company_id: 0,
    meet_requester: "",
    meet_requester_id: 0,
    meet_person_involved: [],
    meet_objective: "",
    meet_status: "",
    meet_type_tab: "",
    meet_reason: ""
  });

  const [showDecline, setShowDecline] = useState(false);

  const btnCloseDecline = (e) => {
    setShowDecline(false);
  };

  const btnShowMeeting = (id, idclient, roleID) => (e) => {

    Api.patch("client-staff/" + idclient + "/request-meeting/" + id + "/updateReadAt",
      {
        user_id: decodedSub
      }
    )
    .then((response) => {
      // console.log("response", response)

      dispatch(getListOfMeetingByUser(decodedSub));

      const filteredMeet = meetingList.find((meet) => meet.request_meeting_id === id);

      var meetType = "";
      var meetIcon = "";

      if (filteredMeet.request_meeting.type === "phone") {
        meetType = "Phone Discussion";
        meetIcon = mdiPhone;
      } else if (filteredMeet.request_meeting.type === "online") {
        meetType = "Online";
        meetIcon = mdiVideo;
      } else {
        meetType = "Face-to-Face";
        meetIcon = mdiAccountMultiple;
      }

      setMeetState({
        meet_id: filteredMeet.request_meeting_id,
        meet_idclient: filteredMeet.request_meeting.client_id,
        meet_name: filteredMeet.request_meeting.name,
        meet_date: moment(filteredMeet.request_meeting.date_meeting).format("DD MMMM YYYY"),
        meet_time: moment(filteredMeet.request_meeting.time_meeting, ["HH:mm"]).format("hh:mm A"),
        meet_type: meetType,
        meet_icon: meetIcon,
        meet_location: filteredMeet.request_meeting.location,
        meet_company_name: filteredMeet.request_meeting.clients.name,
        meet_company_id: filteredMeet.request_meeting.clients.id,
        meet_type_tab: "upcoming",
        meet_status: filteredMeet.status,
        meet_requester: filteredMeet.request_meeting.created_by_candidate.last_name ? filteredMeet.request_meeting.created_by_candidate.first_name + " " + filteredMeet.request_meeting.created_by_candidate.last_name : filteredMeet.request_meeting.created_by_candidate.first_name,
        meet_requester_id: filteredMeet.request_meeting.created_by_candidate.id,
        meet_person_involved: filteredMeet.request_meeting.user_has_request_meeting,
        meet_objective: filteredMeet.request_meeting.objective_meeting,
        meet_reason: filteredMeet.reason_reject
      });
    })
    .catch((error) => {
      console.log("error readt at", error);
    });
  
   
  };

  const btnShowMeetingPast = (id) => (e) => {
    const filteredMeet = meetingListPast.find((meet) => meet.request_meeting_id === id);

    var meetType = "";
    var meetIcon = "";

    if (filteredMeet.request_meeting.type === "phone") {
      meetType = "Phone Discussion";
      meetIcon = mdiPhone;
    } else if (filteredMeet.request_meeting.type === "online") {
      meetType = "Online";
      meetIcon = mdiVideo;
    } else {
      meetType = "Face-to-Face";
      meetIcon = mdiAccountMultiple;
    }

    // var array_staff = filteredMeet.user_has_request_meeting.map((obj) => {
    //   return obj.user.first_name + " " + obj.user.last_name + ",";
    // });

    // var cons_name = array_staff.join(" ");

    setMeetState({
      meet_id: filteredMeet.request_meeting_id,
      meet_idclient: filteredMeet.request_meeting.client_id,
      meet_name: filteredMeet.request_meeting.name,
      meet_date: moment(filteredMeet.request_meeting.date_meeting).format("DD MMMM YYYY"),
      meet_time: moment(filteredMeet.request_meeting.time_meeting, ["HH:mm"]).format("hh:mm A"),
      meet_type: meetType,
      meet_icon: meetIcon,
      meet_location: filteredMeet.request_meeting.location,
      meet_company_name: filteredMeet.request_meeting.clients.name,
      meet_company_id: filteredMeet.request_meeting.clients.id,
      meet_requester: filteredMeet.request_meeting.created_by_candidate.last_name ? filteredMeet.request_meeting.created_by_candidate.first_name + " " + filteredMeet.request_meeting.created_by_candidate.last_name : filteredMeet.request_meeting.created_by_candidate.first_name,
      meet_requester_id: filteredMeet.request_meeting.created_by_candidate.id,
      meet_person_involved: filteredMeet.request_meeting.user_has_request_meeting,
      meet_objective: filteredMeet.request_meeting.objective_meeting,
      meet_status: filteredMeet.status,
      meet_type_tab: "past",
      meet_reason: filteredMeet.reason_reject,
    });
  };

  const btnLinkClient = (id) => (e) => {
    navigate("/clients/viewActiveC/" + id);
  };

  const btnLinkCandidate = (id) => (e) => {
    navigate("/candidates/viewCandidate/" + id);
  };

  const btnUpdateStatus = (statusType, id, idclient) => async (e) => {
    if (statusType === "accepted") {
      const tokenEmailId = localStorage.getItem("id_token_email");

      if (tokenEmailId !== null) {
        const filteredMeet = meetingList.find((meet) => meet.request_meeting_id === id);

        const dateTimeMeeting =
          moment(filteredMeet.request_meeting.date_meeting).format("YYYY-MM-DD") +
          "T" +
          moment(filteredMeet.request_meeting.time_meeting, "HHmmss").format("HH:mm:ss");

        const person_involve = [];

        filteredMeet.request_meeting.user_has_request_meeting.length > 0 &&
          filteredMeet.request_meeting.user_has_request_meeting.map((item) =>
            person_involve.push({
              emailAddress: {
                address: item.user.email,
                name: item.user.first_name + " " + item.user.last_name,
              },
              type: "required",
            })
          );

        const event = {
          subject: filteredMeet.request_meeting.name,
          body: {
            contentType: "HTML",
            content: filteredMeet.request_meeting.objective_meeting,
          },
          start: {
            dateTime: dateTimeMeeting,
            timeZone: "Singapore Standard Time",
          },
          end: {
            dateTime: dateTimeMeeting,
            timeZone: "Singapore Standard Time",
          },
          location: {
            displayName: filteredMeet.request_meeting.location,
          },
          attendees: person_involve,
        };
        // console.log("roleID", roleID)
        // console.log("decodedSub", decodedSub)

        customSwal
          .fire({
            title: "Do you want to accept this meeting?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.patch("client-staff/" + idclient + "/request-meeting/" + id + "/updateStatus",
                {
                  status: statusType,
                  user_id: usrProfile.id,
                  reason_reject: null
                }
              )
                .then((response) => {
                  // console.log("response", response)

                  dispatch(getListOfMeetingByUser(decodedSub));

                  // if (roleID === 1) {
                  //   dispatch(getListOfMeetingAdmin());
                  // } else {
                  //   dispatch(getListOfMeetingByUser(decodedSub));
                  // }

                  const filteredMeet = meetingList.find((meet) => meet.request_meeting_id === id);

                  var meetType = "";
                  var meetIcon = "";

                  if (filteredMeet.request_meeting.type === "phone") {
                    meetType = "Phone Discussion";
                    meetIcon = mdiPhone;
                  } else if (filteredMeet.request_meeting.type === "online") {
                    meetType = "Online";
                    meetIcon = mdiVideo;
                  } else {
                    meetType = "Face-to-Face";
                    meetIcon = mdiAccountMultiple;
                  }

                  setMeetState({
                    meet_id: filteredMeet.request_meeting_id,
                    meet_idclient: filteredMeet.request_meeting.client_id,
                    meet_name: filteredMeet.request_meeting.name,
                    meet_date: moment(filteredMeet.request_meeting.date_meeting).format("DD MMMM YYYY"),
                    meet_time: moment(filteredMeet.request_meeting.time_meeting, ["HH:mm"]).format("hh:mm A"),
                    meet_type: meetType,
                    meet_icon: meetIcon,
                    meet_location: filteredMeet.request_meeting.location,
                    meet_company_name: filteredMeet.request_meeting.clients.name,
                    meet_company_id: filteredMeet.request_meeting.clients.id,
                    meet_type_tab: "upcoming",
                    meet_status: "accepted",
                    meet_requester: filteredMeet.request_meeting.created_by_candidate.last_name ? filteredMeet.request_meeting.created_by_candidate.first_name + " " + filteredMeet.request_meeting.created_by_candidate.last_name : filteredMeet.request_meeting.created_by_candidate.first_name,
                    meet_requester_id: filteredMeet.request_meeting.created_by_candidate.id,
                    meet_person_involved: response.data.user_has_request_meeting,
                    meet_objective: filteredMeet.request_meeting.objective_meeting,
                  });

                 
                })
                .catch((error) => {
                  console.log("error update status meeting", error);
                });
              

              // console.log("meet_person_involved", meetState)
            }
          });

          // fazmin disable
        try {
          return await axios.post(
            `https://graph.microsoft.com/v1.0/me/events`,
            event,
            {
              headers: {
                Authorization: `Bearer ${tokenEmailId}`,
                "Content-Type": "application/json",
                Prefer:
                  'outlook.body-content-type="text", outlook.timezone="Singapore Standard Time"',
              },
            }
          );
        } catch (error) {
          console.error("error createEvent", error.response);
        }
      } else {

        customSwal
          .fire({
            title: "Do you want to sync into your email?",
            text: "Please login your email at calendar",
            icon: "warning",
            showDenyButton: true,
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
            denyButtonText: "No",
            cancelButtonText: "Cancel",
          })
          .then((result) => {
            if (result.isConfirmed) {
              navigate("/calendar");
            } else if(result.isDenied) {
              Api.patch("client-staff/" + idclient + "/request-meeting/" + id + "/updateStatus",
                {
                  status: statusType,
                  user_id: usrProfile.id,
                  reason_reject: null
                }
              )
                .then((response) => {
                  // console.log("response", response)
                  dispatch(getListOfMeetingByUser(decodedSub));

                  // if (roleID === 1) {
                  //   dispatch(getListOfMeetingAdmin());
                  // } else {
                  //   dispatch(getListOfMeetingByUser(decodedSub));
                  // }

                  const filteredMeet = meetingList.find((meet) => meet.request_meeting_id === id);

                  var meetType = "";
                  var meetIcon = "";

                  if (filteredMeet.request_meeting.type === "phone") {
                    meetType = "Phone Discussion";
                    meetIcon = mdiPhone;
                  } else if (filteredMeet.request_meeting.type === "online") {
                    meetType = "Online";
                    meetIcon = mdiVideo;
                  } else {
                    meetType = "Face-to-Face";
                    meetIcon = mdiAccountMultiple;
                  }

                  setMeetState({
                    meet_id: filteredMeet.request_meeting_id,
                    meet_idclient: filteredMeet.request_meeting.client_id,
                    meet_name: filteredMeet.request_meeting.name,
                    meet_date: moment(filteredMeet.request_meeting.date_meeting).format("DD MMMM YYYY"),
                    meet_time: moment(filteredMeet.request_meeting.time_meeting, ["HH:mm"]).format("hh:mm A"),
                    meet_type: meetType,
                    meet_icon: meetIcon,
                    meet_location: filteredMeet.request_meeting.location,
                    meet_company_name: filteredMeet.request_meeting.clients.name,
                    meet_company_id: filteredMeet.request_meeting.clients.id,
                    meet_type_tab: "upcoming",
                    meet_status: "accepted",
                    meet_requester: filteredMeet.request_meeting.created_by_candidate.last_name ? filteredMeet.request_meeting.created_by_candidate.first_name + " " + filteredMeet.request_meeting.created_by_candidate.last_name : filteredMeet.request_meeting.created_by_candidate.first_name,
                    meet_requester_id: filteredMeet.request_meeting.created_by_candidate.id,
                    meet_person_involved: response.data.user_has_request_meeting,
                    meet_objective: filteredMeet.request_meeting.objective_meeting,
                  });

                  customSwal.fire({
                    title: "Meeting request " + statusType + "!",
                    icon: "success",
                    confirmButtonText: "Okay",
                  });
                })
                .catch((error) => {
                  console.log("error update status meeting", error);
                });

              
            }
          });
      }
    } else {
      // console.log("statusType", statusType, id, idclient)
      e.preventDefault();

      const data = new FormData(e.target);
      const dtDecline = Object.fromEntries(data.entries());

      // console.log("dtDecline", dtDecline.txtdecline)
      customSwal
        .fire({
          title: "Do you want to decline this meeting?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Api.patch("client-staff/" + idclient + "/request-meeting/" + id + "/updateStatus",
            {
              status: statusType,
              user_id: usrProfile.id,
              reason_reject: dtDecline.txtdecline
            }
          )
            .then((response) => {
              // console.log("response", response)
              setShowDecline(false);

              dispatch(getListOfMeetingByUser(decodedSub));

              // if (roleID === 1) {
              //   dispatch(getListOfMeetingAdmin());
              // } else {
              //   dispatch(getListOfMeetingByUser(decodedSub));
              // }

              const filteredMeet = meetingList.find((meet) => meet.request_meeting_id === id);

              const findUserID = response.data.user_has_request_meeting.find((user) => user.user_id === decodedSub);

              var meetType = "";
              var meetIcon = "";

              if (filteredMeet.request_meeting.type === "phone") {
                meetType = "Phone Discussion";
                meetIcon = mdiPhone;
              } else if (filteredMeet.request_meeting.type === "online") {
                meetType = "Online";
                meetIcon = mdiVideo;
              } else {
                meetType = "Face-to-Face";
                meetIcon = mdiAccountMultiple;
              }

              setMeetState({
                meet_id: filteredMeet.request_meeting_id,
                meet_idclient: filteredMeet.request_meeting.client_id,
                meet_name: filteredMeet.request_meeting.name,
                meet_date: moment(filteredMeet.request_meeting.date_meeting).format("DD MMMM YYYY"),
                meet_time: moment(filteredMeet.request_meeting.time_meeting, ["HH:mm"]).format("hh:mm A"),
                meet_type: meetType,
                meet_icon: meetIcon,
                meet_location: filteredMeet.request_meeting.location,
                meet_company_name: filteredMeet.request_meeting.clients.name,
                meet_company_id: filteredMeet.request_meeting.clients.id,
                meet_type_tab: "upcoming",
                meet_status: "declined",
                meet_requester: filteredMeet.request_meeting.created_by_candidate.last_name ? filteredMeet.request_meeting.created_by_candidate.first_name + " " + filteredMeet.request_meeting.created_by_candidate.last_name : filteredMeet.request_meeting.created_by_candidate.first_name,
                meet_requester_id: filteredMeet.request_meeting.created_by_candidate.id,
                meet_person_involved: response.data.user_has_request_meeting,
                meet_objective: filteredMeet.request_meeting.objective_meeting,
                meet_reason: findUserID.reason_reject
              });

              
            })
            .catch((error) => {
              console.log("error update status meeting", error);
            });
           
          }
        });
    }
  };

  const [staType, setStaType] = useState("");
  const [idType, setIdType] = useState(0);
  const [idClientType, setIdClientType] = useState(0);

  const btnOpenDecline = (statusType, id, idclient) => (e) => {
    setShowDecline(true);

    setStaType(statusType);
    setIdType(id);
    setIdClientType(idclient);
  };

  const removeActiveClass = () => {
    document.querySelectorAll(".box-click-container").forEach((el) => {
      el.classList.remove("active");
    });
  };

  useEffect(() => {
    let menuItems = document.querySelectorAll(".box-click-container");

    menuItems.forEach((el) => {
      el.addEventListener("click", (e) => {
        // const next = el.nextElementSibling;
        // console.log("next", next);
        removeActiveClass();
        menuItems.forEach((el) => el.classList.remove("active"));
        el.classList.toggle("active");
      });
    });
  });

  const [roleID, setRoleID] = useState("");
  const [decodedSub, setDecodedSub] = useState("");

  const getLoadListMeetingByRole = useCallback(() => {
    const tokenid = Cookies.get("id_token");

    if (tokenid !== null) {
      const decoded = jwt_decode(tokenid);

      let role_id = decoded.user_has_roles[0].role_id;

      setRoleID(role_id);
      setDecodedSub(decoded.sub);

      dispatch(getListOfMeetingByUser(decoded.sub));

      // if (role_id === 1) {
      //   dispatch(getListOfMeetingAdmin());
      // } else {
      //   dispatch(getListOfMeetingByUser(decoded.sub));
      // }
    }
  }, [dispatch]);

  useEffect(() => {
    //
    getLoadListMeetingByRole();
    dispatch(storeTitle({ title: "Meeting", icon: mdiMessagePlus }));
  }, [getLoadListMeetingByRole, dispatch]);

  return (
    <div className="main-div meeting">
      <div className="split-container">
        <div className="left-split">
          <Tab.Container defaultActiveKey="upcoming">
            <Nav variant="tabs" justify className="dashboard-tab meeting">
              <Nav.Item>
                <Nav.Link eventKey="upcoming">Upcoming</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="past">Past</Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content className="tab-content-meeting">
              <Tab.Pane eventKey="upcoming">
                {meetingList.length > 0 ? (
                  <>
                    {meetingList.map((meeting, index) => {
                      var meetType = "";

                      if (meeting.request_meeting.type === "phone") {
                        meetType = "Phone Discussion";
                      } else if (meeting.request_meeting.type === "online") {
                        meetType = "Online";
                      } else {
                        meetType = "Face-to-Face";
                      }

                      return (
                        <div
                          className="box-click-container"
                          key={index}
                          onClick={btnShowMeeting(
                            meeting.request_meeting_id,
                            meeting.request_meeting.client_id,
                            roleID
                          )}
                        >
                          <div className="box-click-meeting">
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <div className="meeting-title">
                                {meeting.request_meeting.name}
                              </div>
                              {meeting.read_at ? (
                                <></>
                              ) : (
                                <span
                                  className="bg-blink"
                                  style={{ marginLeft: "10px" }}
                                >
                                  <Badge bg="danger">New Request!</Badge>
                                </span>
                              )}
                            </div>
                            <div className="meeting-date">
                              <b>
                                {moment(meeting.request_meeting.date_meeting).format(
                                  "DD MMMM YYYY"
                                )}
                                ,
                              </b>{" "}
                              {moment(meeting.request_meeting.time_meeting, ["HH:mm"]).format(
                                "hh:mm A"
                              )}
                            </div>
                            <div className="meeting-desc">
                              {meetType} <Icon path={mdiCircleSmall} size={1} />
                              {meeting.request_meeting.created_by_candidate.first_name}{" "}
                              {meeting.request_meeting.created_by_candidate.last_name} from{" "}
                              {meeting.request_meeting.clients.name}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="box-empty-container">
                    You have no upcoming meeting!
                  </div>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="past">
                {meetingListPast.length > 0 ? (
                  <>
                    {meetingListPast.map((meeting, index) => {
                      var meetType = "";

                      if (meeting.request_meeting.type === "phone") {
                        meetType = "Phone Discussion";
                      } else if (meeting.request_meeting.type === "online") {
                        meetType = "Online";
                      } else {
                        meetType = "Face-to-Face";
                      }

                      return (
                        <div
                          className="box-click-container"
                          key={index}
                          onClick={btnShowMeetingPast(meeting.request_meeting_id)}
                        >
                          <div className="box-click-meeting">
                            <div className="meeting-title">{meeting.request_meeting.name}</div>
                            <div className="meeting-date">
                              <b>
                                {moment(meeting.request_meeting.date_meeting).format(
                                  "DD MMMM YYYY"
                                )}
                                ,
                              </b>{" "}
                              {moment(meeting.request_meeting.time_meeting, ["HH:mm"]).format(
                                "hh:mm A"
                              )}
                            </div>
                            <div className="meeting-desc">
                              {meetType} <Icon path={mdiCircleSmall} size={1} />
                              {meeting.request_meeting.created_by_candidate.first_name}{" "}
                              {meeting.request_meeting.created_by_candidate.last_name} from{" "}
                              {meeting.request_meeting.clients.name}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="box-empty-container">
                    You have no past meeting!
                  </div>
                )}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        
       
        {meetState.meet_name ? (
          <div className="right-split">
            <div className="meeting-title">{meetState.meet_name}</div>
            <div className="meeting-icon">
              <div>
                <Icon path={mdiCalendar} color="#54595F" />
                {meetState.meet_date}
              </div>
              <div>
                <Icon path={mdiClockOutline} color="#54595F" />
                {meetState.meet_time}
              </div>
              <div>
                <Icon path={meetState.meet_icon} color="#54595F" />
                {meetState.meet_type}
              </div>
            </div>
            {meetState.meet_type !== "Phone Discussion" ? (
              <div className="meeting-location">
                <Icon path={mdiMapMarkerOutline} color="#54595F" />
                {meetState.meet_location !== "" ? (
                  <div>{meetState.meet_location}</div>
                ) : (
                  <div>No location provided</div>
                )}
              </div>
            ) : (
              <></>
            )}
            <div className="interview-column meeting">
              {meetState.meet_status === 'created' ? (
                meetState.meet_type_tab !== "past" ? (
                  <div className="interview-button">
                    <Button
                      variant="info"
                      style={{ boxShadow: "none" }}
                      onClick={btnUpdateStatus(
                        "accepted",
                        meetState.meet_id,
                        meetState.meet_idclient
                      )}
                    >
                      Accept
                    </Button>
                    <Button
                      variant="danger"
                      onClick={btnOpenDecline(
                        "declined",
                        meetState.meet_id,
                        meetState.meet_idclient
                      )}
                    >
                      Decline
                    </Button>
                  </div>
                ) : (
                  <div className="meeting-status" style={{ color: "#DF4759" }}>
                    This meeting has no response.
                  </div>
                )
              ) : (
                <>
                  <div
                    className="meeting-status"
                    style={{
                      color:
                        meetState.meet_status === "declined"
                          ? "#DF4759"
                          : "#42BA96",
                    }}
                  >
                    {meetState.meet_status === "declined"
                      ? "Meeting request declined!"
                      : "Meeting request accepted!"}
                  </div>
                  <div style={{fontSize: "14px"}}>
                    {meetState.meet_status === "declined"
                      ? "Reason: " + meetState.meet_reason
                      : ""}
                  </div>
                </>
                
              )}
            </div>
            <hr />
            <div className="meeting-overflow">
              <div className="meeting-main-cont">
                <div className="meeting-subheader">Company Name</div>
                <div className="meeting-sub-info">
                  <Button
                    variant="link"
                    className="modal-link"
                    onClick={btnLinkClient(meetState.meet_company_id)}
                  >
                    {meetState.meet_company_name}
                  </Button>
                </div>
              </div>
              <div
                className="meeting-main-cont"
                style={{ paddingBottom: "10px" }}
              >
                <div className="meeting-subheader">Meeting Requestor</div>
                <div className="meeting-sub-info">
                  <Button
                    variant="link"
                    className="modal-link"
                    onClick={btnLinkCandidate(meetState.meet_requester_id)}
                  >
                    {meetState.meet_requester}
                  </Button>
                </div>
              </div>
              <hr />
              <div className="meeting-main-cont">
                <div className="meeting-subheader">Person Involved</div>
                <div className="meeting-person">
                  {meetState.meet_person_involved.map((person, index) => {
                    return (
                      <div key={index} className="meeting-icon">
                        {index + 1}. {person.user.first_name} {person.user.last_name} - 
                        <div style={{color: "white" , background: person.status === "accepted" ? "#42BA96": person.status === "declined" ? "#DF4759" : "#467FD0", borderRadius: "3px", paddingLeft: "3px", paddingRight: "3px"}}>{person.status !== "created" ? person.status: "pending"}</div>
                      </div>
                    )
                  })}
                </div>
                <div className="meeting-main-cont">
                  <div className="meeting-subheader">Purpose of the Meeting</div>
                  <div className="meeting-sub-info">
                    <div className="meeting-purpose">
                      {meetState.meet_objective !== ""
                        ? meetState.meet_objective
                        : "-"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ): (
          <div className="right-split">
            {/* Content If Empty */}
            <div className="meeting-empty">
              <Icon
                path={mdiTextBoxMultipleOutline}
                // size={4}
                color={"#E5E5E5"}
              />
              <div className="meeting-empty-wording">
                It looks like you have not select any meetings.
                <br />
                Please select any upcoming/past meeting to view the details.
              </div>
            </div>
          </div>
        )}
      </div>

       {/* Modal Note */}
       <Modal
        show={showDecline}
        size="md"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseDecline}
        title="Reason Decline"
        content={
          <>
            <Form id="decline-form" onSubmit={btnUpdateStatus(staType, idType, idClientType)}>
              <Form.Control
                name="txtdecline"
                as="textarea"
                placeholder="Type your message here"
                rows={5}
                // defaultValue={noteState.txtnote}
                // isInvalid={!!errorNote.txtnote}
              />
              {/* <Form.Control.Feedback type="invalid">
                {errorNote.txtnote}
              </Form.Control.Feedback> */}
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseDecline}>
              Cancel
            </Button>
            <Button type="submit" form="decline-form" variant="primary">
              Yes
            </Button>
          </>
        }
      ></Modal>
    </div>
  );
};

export default Meeting;

// const headers = [
//   {
//     Header: "No",
//     accessor: "id",
//     maxWidth: 60,
//     minWidth: 10,
//     width: 40,
//   },
//   {
//     Header: "Meeting Name",
//     accessor: "meeting_name",
//     maxWidth: 400,
//     minWidth: 100,
//     width: 300,
//   },
//   {
//     Header: "Meeting Date",
//     accessor: "meeting_date",
//     maxWidth: 400,
//     minWidth: 100,
//     width: 300,
//   },
//   {
//     Header: "Meeting Time",
//     accessor: "meeting_time",
//   },
//   {
//     Header: "Status",
//     accessor: "status",
//     Cell: ({ row }) => {
//       const data = row.original;

//       if (data.status === "Requested") {
//         return (
//           <CardInfo
//             cardTextSize="card_text--medium"
//             cardTextStyle="card_text--progress--solid"
//           >
//             {data.status}
//           </CardInfo>
//         );
//       } else if (data.status === "Accepted") {
//         return (
//           <CardInfo
//             cardTextSize="card_text--medium"
//             cardTextStyle="card_text--completed--solid"
//           >
//             {data.status}
//           </CardInfo>
//         );
//       }
//     },
//   },
// ];

// <div className="main-div">
//   <div
//     style={{
//       display: "flex",
//     }}
//   >
//     <div style={{ flex: 2, paddingRight: getRequest ? "20px" : "0" }}>
//       <Accordion defaultActiveKey="0">
//         <Accordion.Item eventKey="0">
//           <Accordion.Header>Request a meeting</Accordion.Header>
//           <Accordion.Body>
//             <Form>
//               {/* 1 */}
//               <Form.Group as={Row} className="mb-3">
//                 <Form.Label column lg={2}>
//                   Meeting Name
//                 </Form.Label>
//                 <Col>
//                   <Form.Control
//                     type="text"
//                     placeholder="Enter meeting name"
//                   />
//                 </Col>
//               </Form.Group>

//               {/* 2 */}
//               <Form.Group as={Row} className="mb-3">
//                 <Form.Label column lg={2}>
//                   Person Involved
//                 </Form.Label>
//                 <Col>
//                   <Form.Control type="text" />
//                 </Col>
//               </Form.Group>

//               {/* 3 */}
//               <Row>
//                 <Col xs={6}>
//                   <Form.Group as={Row} className="mb-3">
//                     <Form.Label column lg={4}>
//                       Date
//                     </Form.Label>
//                     <Col>
//                       <Form.Control name="dateofmeeting" type="date" />
//                     </Col>
//                   </Form.Group>
//                 </Col>
//                 <Col xs={6}>
//                   <Form.Group as={Row} className="mb-3">
//                     <Form.Label column lg={4}>
//                       Time
//                     </Form.Label>
//                     <Col>
//                       <Form.Control name="timeofmeeting" type="time" />
//                     </Col>
//                   </Form.Group>
//                 </Col>
//               </Row>

//               {/* 4 */}
//               <Form.Group as={Row} className="mb-3">
//                 <Form.Label column lg={2}>
//                   Purpose of the meeting
//                 </Form.Label>
//                 <Col>
//                   <Form.Control
//                     as="textarea"
//                     name="purposemeeting"
//                     rows={3}
//                   />
//                 </Col>
//               </Form.Group>

//               <div className="row-btn-bottom">
//                 <Button variant="primary" type="submit">
//                   Send Request
//                 </Button>
//               </div>
//             </Form>
//           </Accordion.Body>
//         </Accordion.Item>
//       </Accordion>
//     </div>

//     {getRequest ? (
//       <div style={{ flex: 1 }}>
//         <Card style={{ height: "100%" }}>
//           <Card.Body>
//             {/* Meeting Header */}
//             <div className="meeting-header">
//               <Icon path={mdiAlertBox} size={1} color="#DF4759" />
//               <div className="sub-text" style={{ color: "#DF4759" }}>
//                 You have <b>3</b> meeting request!
//               </div>
//             </div>

//             {/* Meeting Info */}
//             <div className="meeting-info">
//               {meetingList.map((meeting, index) => {
//                 return (
//                   <div>
//                     <div className="sub-text" style={{ color: "#54595F" }}>
//                       Shahrul from Microsoft
//                     </div>
//                     <div className="sub-text" style={{ color: "#54595F" }}>
//                       {meeting.meeting_date}, {meeting.meeting_time}
//                     </div>
//                     <div
//                       className="sub-text"
//                       style={{ paddingTop: "10px" }}
//                     >
//                       {meeting.meeting_name}
//                     </div>
//                     <div
//                       className="sub-text"
//                       style={{
//                         color: "#54595F",
//                         textAlign: "justify",
//                         fontWeight: 400,
//                       }}
//                     >
//                       This is the purpose of the meeting description. Using
//                       free text, description need to be less than 500
//                       characters.
//                     </div>

//                     {/* Meeting Response Button */}
//                     <div className="meeting-btn-row">
//                       <div
//                         className="sub-text"
//                         style={{
//                           fontWeight: 600,
//                           display: "flex",
//                           alignItems: "center",
//                         }}
//                       >
//                         Are you attending?
//                       </div>
//                       <div className="meeting-btn">
//                         <Button
//                           variant="info"
//                           style={{ boxShadow: "none" }}
//                         >
//                           Accept
//                         </Button>
//                         <Button variant="danger">Decline</Button>
//                       </div>
//                     </div>

//                     <hr style={{ marginBottom: 0 }}></hr>
//                   </div>
//                 );
//               })}
//             </div>
//           </Card.Body>
//         </Card>
//       </div>
//     ) : null}
//   </div>

//   <div style={{ marginTop: "30px" }}>
//     <CustomTable headers={headers} items={meetingList}></CustomTable>
//   </div>
// </div>
