import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Form, Table } from "react-bootstrap";
import Api from "../../../../core/services/api.service";

import SwalAlert from "../../../../components/Swal/SwalAlert";
import moment from "moment";

import { mdiArrowLeft, mdiAccountSearch, mdiAccountGroup } from "@mdi/js";
import Icon from "@mdi/react";

import BreadCrumb from "../../../../components/Breadcrumb/BreadCrumb";
import RichTextEditor from "../../../../components/RichTextArea/RichTextEditor";

import { storeTitle } from "../../../../store/actions/headerTitleSlice";

const QualifiedCP = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const customSwal = SwalAlert();

  const { dataAllowance } = useSelector((state) => state.option);

  const selectedCrumb = (crumb) => {
    const nameCand = selQcpCand.last_name ? selQcpCand.first_name + " " + selQcpCand.last_name : selQcpCand.first_name;

    if(crumb === "Clients"){
      navigate("/clients/listActiveC");
    }else if(crumb === "Potential Clients"){
      navigate("/clients/listPotentialC");
    }else if(crumb === nameCand){
      navigate(-1);
    }
  };

  function toMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", {
      month: "long",
    });
  }

  function toMoneyFormat(value) {
    let salary = "";

    let convertNum = Number(value);

    salary = convertNum.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");

    return salary;
  }

  function toAllowanceDesc(idallow) {
    let nameAllow = "";

    let array_allow = dataAllowance.filter((grp) => {
      return grp.id === idallow;
    });

    if (array_allow.length > 0) {
      nameAllow = array_allow[0].value;
    }

    return nameAllow;
  }

  const btnUpdateQcp = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtQcp = Object.fromEntries(data.entries());
    // console.log("dtQcp", dtQcp);
    // console.log("selQcpCompetency", selQcpCompetency);

    customSwal
      .fire({
        title: "Do you want to update qualified candidate profile?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.patch(
            "candidate/" +
              params.id +
              "/assignment/" +
              params.idassign +
              "/ecpqcp/qcp/" +
              params.idrpt,
            {
              motivation: dtQcp.txtmotivation,
              recommendation: dtQcp.txtrecommend,
              competency: selQcpCompetency,
            }
          )
            .then((response) => {
              navigate(-1);

              customSwal.fire({
                title: "Updated Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error btnUpdateQcp", error);
            });
        }
      });
  };

  const updateFieldChanged = (index) => (e) => {
    let newArr = [...selQcpCompetency]; // copying the old datas array
    // // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
    newArr[index].detail = e.target.value; // replace e.target.value with whatever you want to change it to

    setSelQcpCompetency(newArr);
  };

  const [selQcp, setSelQcp] = useState({});
  const [selQcpClient, setSelQcpClient] = useState({});
  const [selQcpAssign, setSelQcpAssign] = useState({});
  const [selQcpCand, setSelQcpCand] = useState({});

  const [selQcpCandExp, setSelQcpCandExp] = useState([]);
  const [selCurQcpCandExp, setSelCurQcpCandExp] = useState({});
  const [selAllowCand, setSelAllowCand] = useState([]);

  const [selLang, setSelLang] = useState("");
  const [selQcpCompetency, setSelQcpCompetency] = useState([]);
  const [selQcpEducation, setSelQcpEducation] = useState({});

  const getSelectedReport = useCallback(() => {
    Api.get(
      "candidate/" +
        params.id +
        "/assignment/" +
        params.idassign +
        "/ecpqcp/qcp/" +
        params.idrpt
    )
      .then((response) => {
        let data = response.data;

        // console.log("getSelectedReportQcp", data);
        if (data !== "no data") {
          setSelQcp(data);
          setSelQcpClient(data.assignments.clients);
          setSelQcpAssign(data.assignments);
          setSelQcpCand(data.candidates);

          if (data.qcp_competencies.length > 0) {
            // console.log("data.qcp_competencies", data.qcp_competencies)
            setSelQcpCompetency(data.qcp_competencies);
          }

          if (data.candidates.candidate_education.length > 0) {
            setSelQcpEducation(data.candidates.candidate_education[0]);
          }

          if (data.candidates.candidate_experiences.length > 0) {
            setSelQcpCandExp(data.candidates.candidate_experiences);

            setSelCurQcpCandExp(data.candidates.candidate_experiences[0]);

            if (
              data.candidates.candidate_experiences[0].candidate_exp_allowance
                .length > 0
            ) {
              setSelAllowCand(
                data.candidates.candidate_experiences[0].candidate_exp_allowance
              );
            }
          }

          if (data.candidates.languages.length > 0) {
            let array = [];

            array = data.candidates.languages.map((obj) => {
              return obj + ",";
            });

            let lang = array.join(" ");

            setSelLang(lang.slice(0, -1));
          } else {
            setSelLang("-");
          }
        }
      })
      .catch((error) => {
        console.log("error getSelectedReportQcp", error);
      });
  }, [params]);

  const [mainCrumb, setMainCrumb] = useState("");

  useEffect(() => {
    getSelectedReport();

    var pathArray = window.location.pathname.split("/");

    var firstPath = pathArray[1];

    setMainCrumb(firstPath);

    if (firstPath === "clients") {
      dispatch(storeTitle({ title: params.page === "active" ? "Clients" : "Potential Clients", icon: mdiAccountGroup }));
    } else {
      dispatch(
        storeTitle({ title: "List of Candidates", icon: mdiAccountSearch })
      );
    }
  }, [getSelectedReport, dispatch]);

  return (
    <div className="main-div ecp-report">
      <div className="container-div both-side">
        <BreadCrumb
          crumbs={[
            params.page === "active" && mainCrumb === "clients" ? "Clients" : params.page === "potential" ? "Potential Clients" : "List of Candidates",
            selQcpCand.last_name
              ? selQcpCand.first_name + " " + selQcpCand.last_name
              : selQcpCand.first_name,
            "Qualified Candidate Profile",
          ]}
          selected={selectedCrumb}
        ></BreadCrumb>
        <Button variant="secondary" onClick={() => navigate(-1)}>
          <Icon path={mdiArrowLeft} size={0.7} />
          Back
        </Button>
      </div>

      <div className="bg-white-container">
        <div className="outer-report-section">
          <div>Client : {selQcpClient.name}</div>
          <div>Position : {selQcpAssign.title} </div>
        </div>
        <Form id="qcp-form" onSubmit={btnUpdateQcp}>
          <Table bordered>
            <thead className="tbl-report">
              <tr>
                <th colSpan={4}>
                  <div className="top-report-section">
                    <div>
                      Candidate Profile :{" "}
                      {selQcpCand.salutation + " " + selQcpCand.last_name
                        ? selQcpCand.first_name + " " + selQcpCand.last_name
                        : selQcpCand.first_name}
                    </div>
                    <div>
                      Date : {moment(selQcp.created_at).format("DD MMMM YYYY")}{" "}
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <thead className="tbl-report">
              <tr>
                <th colSpan={4} className="row-th-center">
                  Career Progression
                </th>
              </tr>
            </thead>
            <thead className="tbl-report">
              <tr>
                <th>Employer</th>
                <th>Date</th>
                <th>Designation</th>
                <th>Reason for leaving</th>
              </tr>
            </thead>
            <tbody>
              {selQcpCandExp.map((i, index) => (
                <tr key={index}>
                  <td>{i.company_name}</td>
                  <td>
                    {i.current_working
                      ? toMonthName(i.present_month) +
                        " " +
                        i.present_year +
                        " - Current"
                      : toMonthName(i.start_date_month) +
                        " " +
                        i.start_date_year +
                        " - " +
                        toMonthName(i.end_date_month) +
                        " " +
                        i.end_date_year}
                  </td>
                  <td>{i.title}</td>
                  <td>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: i.reason_leaving,
                      }}
                    ></div>
                  </td>
                </tr>
              ))}
            </tbody>
            <thead className="tbl-report">
              <tr>
                <th colSpan={4} className="row-th-center">
                  Critical Competencies
                </th>
              </tr>
            </thead>
            <tbody>
              {selQcpCompetency.map((i, index) => (
                <>
                  <tr key={index}>
                    <th colSpan={4}>{i.assignment_competency.competencies}</th>
                  </tr>
                  <tr>
                    <td colSpan={4}>
                      <Form.Control
                        name="txtcompetency"
                        as="textarea"
                        rows={2}
                        defaultValue={i.detail}
                        onChange={updateFieldChanged(index)}
                      />
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
            <thead className="tbl-report">
              <tr>
                <th colSpan={4} className="row-th-center">
                  Personal Information
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th width="25%">Education</th>
                <td colSpan={3}>
                  <tr>
                    <td>School</td>
                    <td>:</td>
                    <td>{selQcpEducation.name ? selQcpEducation.name : "-"}</td>
                  </tr>
                  <tr>
                    <td>Qualification</td>
                    <td>:</td>
                    <td>{selQcpEducation.qualification}</td>
                  </tr>
                  <tr>
                    <td>Year</td>
                    <td>:</td>
                    <td>
                      {selQcpEducation.start_year +
                        " - " +
                        selQcpEducation.end_year}
                    </td>
                  </tr>
                </td>
              </tr>
              <tr>
                <th width="25%">
                  Current compensation: <br />
                  (not verified at time of presentation)
                </th>
                <td colSpan={3}>
                  <tr>
                    <td>Salary</td>
                    <td>:</td>
                    <td>
                      {selCurQcpCandExp.currency +
                        " " +
                        toMoneyFormat(selCurQcpCandExp.salary)}
                    </td>
                  </tr>
                  <tr>
                    <td>Bonus</td>
                    <td>:</td>
                    <td>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: selCurQcpCandExp.other_benefits,
                        }}
                      ></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Allowance</td>
                    <td>:</td>
                    <td>
                      {selAllowCand.map((allow, index) => (
                        <div key={index}>
                          {toAllowanceDesc(allow.earning) +
                            " - " +
                            allow.currency +
                            " " +
                            toMoneyFormat(allow.amount)}
                        </div>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td>Notice</td>
                    <td>:</td>
                    <td>{selCurQcpCandExp.notice_period}</td>
                  </tr>
                </td>
              </tr>
              <tr>
                <th>Citizenship</th>
                <td width="25%">{selQcpCand.citizenship_main}</td>
                <th width="25%">Languages</th>
                <td>{selLang}</td>
              </tr>
              <tr>
                <th>Location</th>
                <td>{selQcpCand.citizenship_main}</td>
                <th>Willingness to travel</th>
                <td>{selQcpCand.willing_travel ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <th colSpan={1}>Motivation</th>
                <th colSpan={3}>
                  <Form.Control
                    name="txtmotivation"
                    as="textarea"
                    rows={3}
                    defaultValue={selQcp.motivation}
                  />
                </th>
              </tr>
            </tbody>
            <thead className="tbl-report">
              <tr>
                <th colSpan={4} className="row-th-center">
                  Recommendation
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={4}>
                  <RichTextEditor
                    name="txtrecommend"
                    htmlContent={selQcp.recommendation}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </Form>

        <div className="row-btn-center">
          <Button variant="primary" type="submit" form="qcp-form">
            Save Qualified Candidate Profile
          </Button>
        </div>
      </div>
    </div>
  );
};

export default QualifiedCP;
