import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Dropdown, Card, Row, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Api from "../../core/services/api.service";
import Apiform from "../../core/services/apiform.service";

import SwalAlert from "../Swal/SwalAlert";
import FileSaver from "file-saver";
import AuthAbilities from "../../pages/Admin/Auth/AuthAbilities";
import moment from "moment";

import rptFooter from "../Report/Template_Report/rptFooter";
import rptHeader from "../Report/Template_Report/rptHeader";
import rptJobDescription from "../Report/Template_Report/rptJobDescription";

import Icon from "@mdi/react";
import {
  mdiFilePlus,
  mdiDotsHorizontal,
  mdiDownload,
  mdiPencil,
  mdiDelete,
  mdiFileSend,
  mdiEmail,
  mdiViewAgenda,
} from "@mdi/js";

// import { JDForm } from "../Report/Template/JDForm";
import Table from "../Table/Table";
import Modal from "../Popup/ModalPopup";
import CardInfo from "../Card/CardInfo";
import { TabProgress } from "../Tab/TabProgress";
import Viewer from "../Viewer/Viewer";

import { getClientStaff } from "../../store/actions/clientSlice";
import HTMLtoDOCX from "html-to-docx";
import { TabFileAssignments } from "./TabFileAssignments";

export const TabJobDescription = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const { usrProfile } = useSelector((state) => state.user);
  const { clientListStaff } = useSelector((state) => state.client);

  const abilities = AuthAbilities();

  const [listJobDesc, setListJobDesc] = useState([]);
  const [listJobDescRaw, setListJobDescRaw] = useState([]);
  const [listDocOthers, setListDocOthers] = useState([]);

  const [showFile, setShowFile] = useState(false);
  const [showOthers, setShowOthers] = useState(false);
  const [errorForm, setErrorForm] = useState({});

  // PDF useState
  const [pageNumber, setPageNumber] = useState(1);
  const [showPdf, setShowPdf] = useState(false);
  const [filePath, setFilePath] = useState("");
  const [modeModal, setModeModal] = useState({
    title: "",
    Button: "",
  });

  var email = clientListStaff.map((list) => {
    return list.comp_email + ";";
  });

  var arr_email = email.join(" ");

  const customSwal = SwalAlert();

  // Button Evenet
  const createJD = () => {
    navigate("/clients/create-jd/" + props.page + "/" + params.id + "/" + params.idassign);
  };

  const btnDownload = (data) => async () => {
    // console.log("data", data);
    // JDForm(data);
    const dataRpt = {
      job_title: data.job_title,
      company_background: data.company_background,
      purpose_job: data.purpose_job,
      key_accountibility: data.key_accountibility,
      skill_experience: data.skill_experience,
      compensation: data.compensation,
      contact_person: data.contact_person,
    };

    const htmlString = rptJobDescription(dataRpt);
    const headerString = rptHeader();
    const footerHTMLString = rptFooter();

    // uncomment the following
    const fileBuffer = await HTMLtoDOCX(
      htmlString,
      headerString,
      {
        table: { row: { cantSplit: true } },
        header: true,
        footer: true,
        pageNumber: true,
        margins: {
          top: 500,
          right: 1500,
          bottom: 500,
          left: 1500,
          header: 720,
          footer: 720,
          gutter: 0,
        },
        font: "Calibri (Body)",
      },
      footerHTMLString
    );

    FileSaver.saveAs(fileBuffer, data.file_name + ".docx");
  };

  const btnCloseFile = () => {
    setShowFile(false);
  };

  const btnAddFile = (e) => {
    setShowFile(true);
    setErrorForm({});
  };

  const btnSaveFile = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dataFile = Object.fromEntries(data.entries());

    const newErrors = formError(dataFile);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      setErrorForm({});
      customSwal
        .fire({
          title: "Do you want to upload file ?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();

            formData.append("file", dataFile.txtfile);
            formData.append("file_name", dataFile.txtfilename);
            formData.append("upload_by", usrProfile.id);

            Apiform.post(
              "client/" +
                params.id +
                "/assignment/" +
                params.idassign +
                "/jd/uploadJd",
              formData
            )
              .then((response) => {
                setShowFile(false);
                getListJDRaw();
                customSwal.fire({
                  title: "Upload Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("user/store", error);
              });
          }
        });
    }
  };

  // Checking Form Error
  const formError = (object) => {
    const newErrors = {};

    if (!object.txtfilename || object.txtfilename === "")
      newErrors.txtfilename = "Fill in the blank!";

    if (!object.txtfile.name || object.txtfile.name === "")
      newErrors.txtfile = "Fill in the blank!";

    return newErrors;
  };

  const btnCloseOther = () => {
    setShowOthers(false);
  };

  const btnAddOther = (e) => {
    setShowOthers(true);
    setErrorForm({});
  };

  const btnSaveOther = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dataFile = Object.fromEntries(data.entries());

    const newErrors = formError(dataFile);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      setErrorForm({});
      customSwal
        .fire({
          title: "Do you want to upload document ?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();

            formData.append("file", dataFile.txtfile);
            formData.append("file_name", dataFile.txtfilename);
            formData.append("upload_by", usrProfile.id);

            Apiform.post(
              "client/" +
                params.id +
                "/assignment/" +
                params.idassign +
                "/attachment/upload",
              formData
            )
              .then((response) => {
                setShowOthers(false);
                getListDocOthers();
                customSwal.fire({
                  title: "Upload Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("error upload others doc", error);
              });
          }
        });
    }
  };

  const btnClosePdf = () => {
    setShowPdf(false);
    setPageNumber(1);
  };

  const btnDeleteJDRaw = (idjd) => (e) => {
    customSwal
      .fire({
        title: "Do you want to delete raw jd?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("client/" + params.id + "/assignment/" + params.idassign + "/jd/raw/" + idjd)
            .then((response) => {
              // let data = response.data;

              getListJDRaw();

              customSwal.fire({
                title: "Delete Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error delete jd raw", error);
            });
        }
      });
  };

  const btnDeleteJD = (idjd) => (e) => {
    customSwal
      .fire({
        title: "Do you want to delete job description?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("client/" + params.id + "/assignment/" + params.idassign + "/jd/" + idjd)
            .then((response) => {
              // let data = response.data;

              getListJDRaw();

              customSwal.fire({
                title: "Delete Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error delete job description", error);
            });
        }
      });
  };

  const btnDeleteOther = (idoth) => (e) => {
    customSwal
      .fire({
        title: "Do you want to delete miscellaneous document?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("client/" + params.id + "/assignment/" + params.idassign + "/attachment/" + idoth)
            .then((response) => {
              // let data = response.data;

              getListDocOthers();

              customSwal.fire({
                title: "Delete Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error delete others", error);
            });
        }
      });
  };

  // Header Table
  const headerJD = [
    {
      Header: "No",
      maxWidth: 50,
      minWidth: 10,
      width: 40,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "File Name",
      accessor: "file_name",
      maxWidth: 400,
      minWidth: 100,
      width: 400,
    },
    {
      Header: "Date Created",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{moment(data.created_at).format("DD MMMM YYYY")}</div>;
      },
    },
    {
      Header: "Status",
      Cell: ({ row }) => {
        const data = row.original;

        return data.status === "completed" ? (
          <CardInfo
            cardTextSize="card_text--small"
            cardTextStyle="card_text--completed--solid"
          >
            {data.status.replace(/_/g, " ")}
          </CardInfo>
        ) : (
          <CardInfo
            cardTextSize="card_text--small"
            cardTextStyle="card_text--primary--solid"
          >
            {data.status.replace(/_/g, " ")}
          </CardInfo>
        );
      },
    },
    {
      Header: "",
      accessor: "actions",
      maxWidth: 100,
      minWidth: 80,
      width: 80,
      Cell: ({ row }) => {
        const data = row.original;

        const btnEditJD = () => {
          navigate(
            "/clients/view-jd/" +
              props.page + "/" +
              params.id +
              "/" +
              params.idassign +
              "/" +
              data.id
          );
        };

        const btnSendJD = () => {
          customSwal
            .fire({
              title: "Do you want to send this job description to client?",
              icon: "warning",
              showCancelButton: true,
              reverseButtons: true,
              confirmButtonText: "Yes",
            })
            .then((result) => {
              if (result.isConfirmed) {
                Api.patch(
                  "client/" +
                    params.id +
                    "/assignment/" +
                    params.idassign +
                    "/jd/" +
                    data.id +
                    "/update",
                  {
                    status: "completed",
                  }
                )
                  .then((response) => {
                    getListJD();
                  })
                  .catch((error) => {
                    console.log("btnUpdateJD", error);
                  });
              }
            });
        };

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={btnDownload(data)}>
                  <Icon path={mdiDownload} color="#467FD0" />
                  Download File
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={btnEditJD}
                  hidden={!abilities.includes("edit jd")}
                >
                  <Icon path={mdiPencil} color="#467FD0" />
                  Edit File
                </Dropdown.Item>
                {data.status === "draft" ? (
                  <Dropdown.Item onClick={btnSendJD}>
                    <Icon path={mdiFileSend} color="#42BA96" />
                    Send File to Client
                  </Dropdown.Item>
                ) : (
                  ""
                )}
                <Dropdown.Item
                  onClick={() => (window.location = "mailto:" + arr_email)}
                >
                  <Icon path={mdiEmail} color="#42BA96" />
                  Send to Email
                </Dropdown.Item>
                <Dropdown.Item onClick={btnDeleteJD(data.id)} hidden={!abilities.includes("delete jd")}>
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete File
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const headerUploadJD = [
    {
      Header: "No",
      maxWidth: 50,
      minWidth: 10,
      width: 40,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "File Name",
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      accessor: "file_name",
    },
    {
      Header: "Date Uploaded",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{moment(data.created_at).format("DD MMMM YYYY")}</div>;
      },
    },
    {
      Header: "Uploaded By",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            {data.created_by_jd_raw.first_name}{" "}
            {data.created_by_jd_raw.last_name}
          </div>
        );
      },
    },
    {
      Header: "",
      accessor: "actions",
      maxWidth: 100,
      minWidth: 80,
      width: 80,
      Cell: ({ row }) => {
        const data = row.original;

        function btnDownloadFile() {
          FileSaver.saveAs(data.path, "download.pdf");
        }

        function btnPreview() {
          setFilePath(data.path);
          setShowPdf(true);

          setModeModal({
            title: "View Job Description",
            button: "",
          });
        }

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={btnPreview}>
                  <Icon path={mdiViewAgenda} color="#467FD0" />
                  Preview File
                </Dropdown.Item>
                <Dropdown.Item onClick={btnDownloadFile}>
                  <Icon path={mdiDownload} color="#467FD0" />
                  Download File
                </Dropdown.Item>
                <Dropdown.Item onClick={btnDeleteJDRaw(data.id)} hidden={!abilities.includes("delete jd")}>
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete File
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const headersOthers = [
    {
      Header: "No",
      maxWidth: 50,
      minWidth: 10,
      width: 40,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "File Name",
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      accessor: "file_name",
    },
    {
      Header: "Date Uploaded",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{moment(data.created_at).format("DD MMMM YYYY")}</div>;
      },
    },
    // {
    //   Header: "Uploaded By",
    //   maxWidth: 400,
    //   minWidth: 100,
    //   width: 200,
    //   Cell: ({ row }) => {
    //     const data = row.original;

    //     return (
    //       <div>
    //         {data.created_by_jd_raw.first_name}{" "}
    //         {data.created_by_jd_raw.last_name}
    //       </div>
    //     );
    //   },
    // },
    {
      Header: "",
      accessor: "actions",
      maxWidth: 100,
      minWidth: 80,
      width: 80,
      Cell: ({ row }) => {
        const data = row.original;

        function btnDownloadFile() {
          FileSaver.saveAs(data.file_path, "download.pdf");
        }

        function btnPreview() {
          setFilePath(data.file_path);
          setShowPdf(true);

          setModeModal({
            title: "View Others",
            button: "",
          });
        }

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={btnPreview}>
                  <Icon path={mdiViewAgenda} color="#467FD0" />
                  Preview File
                </Dropdown.Item>
                <Dropdown.Item onClick={btnDownloadFile}>
                  <Icon path={mdiDownload} color="#467FD0" />
                  Download File
                </Dropdown.Item>
                <Dropdown.Item onClick={btnDeleteOther(data.id)} hidden={!abilities.includes("delete document")}>
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete File
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const getListJD = useCallback(() => {
    Api.get("client/" + params.id + "/assignment/" + params.idassign + "/jd")
      .then((response) => {
        let data = response.data;

        let sortJDData = data.sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        });

        setListJobDesc(sortJDData);
      })
      .catch((error) => {
        console.log("error getListJD", error);
      });
  }, [params]);

  const getListJDRaw = useCallback(() => {
    Api.get(
      "client/" + params.id + "/assignment/" + params.idassign + "/jd/raw"
    )
      .then((response) => {
        let data = response.data;

        let sortJDRawData = data.sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        });

        setListJobDescRaw(sortJDRawData);
      })
      .catch((error) => {
        console.log("error getListJDRaw", error);
      });
  }, [params]);

  const getListDocOthers = useCallback(() => {
    Api.get(
      "/client/" +
        params.id +
        "/assignment/" +
        params.idassign +
        "/attachment/all"
    )
      .then((response) => {
        let data = response.data;
        // console.log("data doc", data)

        let sortDocList = data.sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        });

        setListDocOthers(sortDocList);
      })
      .catch((error) => {
        console.log("error getListDocOthers", error);
      });
  }, [params]);

  useEffect(() => {
    dispatch(getClientStaff(params.id));
    getListJD();
    getListJDRaw();
    getListDocOthers();
  }, [dispatch, params.id, getListJD, getListJDRaw]);

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <Card.Body>
          <div className="row-div">
            <div className="left-div">
              <div className="title-text report">Job Description (JD)</div>
            </div>
            <div className="right-div">
              <Button
                variant="link"
                className="modal-link"
                onClick={createJD}
                hidden={!abilities.includes("add jd")}
              >
                <Icon path={mdiFilePlus} />
                <div>Add Job Description</div>
              </Button>
            </div>
          </div>

          <Table
            headers={headerJD}
            items={listJobDesc}
            paginate={listJobDesc.length}
            maxRows={15}
          ></Table>
        </Card.Body>
      </Card>

      <Card style={{ marginBottom: "20px" }}>
        <Card.Body>
          <div className="row-div">
            <div className="left-div">
              <div className="title-text report">Job Description (RAW JD)</div>
            </div>
            <div className="right-div">
              <Button
                variant="link"
                className="modal-link"
                onClick={btnAddFile}
                hidden={!abilities.includes("upload jd")}
              >
                <Icon path={mdiFilePlus} />
                <div>Upload Job Description</div>
              </Button>
            </div>
          </div>
          <Table
            headers={headerUploadJD}
            items={listJobDescRaw}
            paginate={listJobDescRaw.length}
            maxRows={15}
          ></Table>
        </Card.Body>
      </Card>

      <TabProgress page={props.page}/>

      <TabFileAssignments page={props.page} />

      <Card>
        <Card.Body>
          <div className="row-div">
            <div className="left-div">
              <div className="title-text report">Others</div>
            </div>
            <div className="right-div">
              <Button
                variant="link"
                className="modal-link"
                onClick={btnAddOther}
                hidden={!abilities.includes("upload document")}
              >
                <Icon path={mdiFilePlus} />
                <div>Upload Miscellaneous Document</div>
              </Button>
            </div>
          </div>
          <Table
            headers={headersOthers}
            items={listDocOthers}
            paginate={listDocOthers.length}
            maxRows={15}
          ></Table>
        </Card.Body>
      </Card>

      {/* Modal Upload JD */}
      <Modal
        show={showFile}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseFile}
        title="Upload Job Description"
        content={
          <>
            <Form id="upload-jd" onSubmit={btnSaveFile}>
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label className="col-3">File Name</Form.Label>
                  <div className="col-9" style={{ display: "inline-flex" }}>
                    <div style={{ display: "flex", flexDirection: "column", width:"100%"}}>
                      <Form.Control
                        name="txtfilename"
                        type="text"
                        placeholder="Please enter file name"
                        isInvalid={!!errorForm.txtfilename}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorForm.txtfilename}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label className="col-3">Upload File</Form.Label>
                  <div className="col-9" style={{ display: "inline-flex" }}>
                    <div style={{ display: "flex", flexDirection: "column", width:"100%"}}>
                      <Form.Control
                        name="txtfile"
                        type="file"
                        isInvalid={!!errorForm.txtfile}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorForm.txtfile}
                      </Form.Control.Feedback>
                    </div>                    
                  </div>
                </Form.Group>
              </Row>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseFile}>
              Cancel
            </Button>
            <Button type="submit" variant="primary" form="upload-jd">
              Upload File
            </Button>
          </>
        }
      ></Modal>

      {/* Modal Upload Other */}
      <Modal
        show={showOthers}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseOther}
        title="Upload Miscellaneous Document"
        content={
          <>
            <Form id="upload-misc-doc" onSubmit={btnSaveOther}>
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label className="col-3">Document Name</Form.Label>
                  <div className="col-9" style={{ display: "inline-flex" }}>
                    <div style={{ display: "flex", flexDirection: "column", width:"100%"}}>
                      <Form.Control
                        name="txtfilename"
                        type="text"
                        placeholder="Please enter file name"
                        isInvalid={!!errorForm.txtfilename}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorForm.txtfilename}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label className="col-3">Upload Document</Form.Label>
                  <div className="col-9" style={{ display: "inline-flex" }}>
                    <div style={{ display: "flex", flexDirection: "column", width:"100%"}}>
                      <Form.Control
                        name="txtfile"
                        type="file"
                        isInvalid={!!errorForm.txtfile}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorForm.txtfile}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </Form.Group>
              </Row>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseOther}>
              Cancel
            </Button>
            <Button type="submit" variant="primary" form="upload-misc-doc">
              Upload
            </Button>
          </>
        }
      ></Modal>

      {/* Modal Pdf */}
      <Viewer
        showPdf={showPdf}
        btnClosePdf={btnClosePdf}
        modalTitle={modeModal.title}
        filePath={filePath}
      />
    </>
  );
};
