import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  Header,
  Footer,
  AlignmentType,
  Table,
  TableRow,
  TableCell,
  WidthType,
  convertInchesToTwip,
} from "docx";
import { saveAs } from "file-saver";

export const SummaryReport = async (data) => {
  console.log("datafromsummaryreportpage", data);

  const fontType = "Calibri";
  const fontColor = "#EC782F";
  const fontSizeTitle = 28;
  const fontSizeTitle2 = 18;
  const fontSizeTitle3 = 16;
  const fontSizeTitle4 = 20;
  const tableWidth1 = 3000;
  const fontSize11 = 22;
  const fontSize14 = 28;

  let experienceList = [];
  let assignmentList = [];
  // let array = [];

  if (data.candidate_experiences.length > 0) {
    experienceList = data.candidate_experiences;
  }

  const candidateName = data.first_name + " " + data.last_name;
  const email = data.email;
  const mobile = data.mobile_no_code + " " + data.mobile_no;
  const linkedin = data.linkedln_url;

  if (data.assignment_candidates.length > 0) {
    assignmentList = data.assignment_candidates;
  }

  function toMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", {
      month: "long",
    });
  }

  function convertToPlain(html) {
    // Create a new div element
    var tempDivElement = document.createElement("div");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }

  const tableExperience = new Table({
    rows: [
      new TableRow({
        tableHeader: true,
        children: [
          new TableCell({
            shading: {
              fill: "#BFBFBF",
            },
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Employer",
                    bold: true,
                    size: fontSizeTitle4,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            shading: {
              fill: "#BFBFBF",
            },
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Dates",
                    bold: true,
                    size: fontSizeTitle4,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            shading: {
              fill: "#BFBFBF",
            },
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Designation",
                    bold: true,
                    size: fontSizeTitle4,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            shading: {
              fill: "#BFBFBF",
            },
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Reason for Leaving",
                    bold: true,
                    size: fontSizeTitle4,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    ],
  });

  experienceList.map((exp) => {
    return tableExperience.root.push(
      new TableRow({
        tableHeader: true,
        children: [
          // Employer Column
          new TableCell({
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: exp.company_name,
                    size: fontSizeTitle4,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
          // Date Column
          new TableCell({
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text:
                      exp.current_working === true
                        ? toMonthName(exp.present_month) +
                          " " +
                          exp.present_year +
                          " - Current"
                        : toMonthName(exp.start_date_month) +
                          " " +
                          exp.start_date_year +
                          " - " +
                          toMonthName(exp.end_date_month) +
                          " " +
                          exp.end_date_year,
                    size: fontSizeTitle4,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
          // Designation Column
          new TableCell({
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: exp.title,
                    size: fontSizeTitle4,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
          // Reason Leaving Column
          new TableCell({
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: convertToPlain(exp.reason_leaving),
                    size: fontSizeTitle4,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
        ],
      })
    );
  });

  const tableAssignment = new Table({
    rows: [
      new TableRow({
        tableHeader: true,
        children: [
          new TableCell({
            shading: {
              fill: "#BFBFBF",
            },
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Company Name",
                    bold: true,
                    size: fontSizeTitle4,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            shading: {
              fill: "#BFBFBF",
            },
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Assignment Name",
                    bold: true,
                    size: fontSizeTitle4,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            shading: {
              fill: "#BFBFBF",
            },
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Consultant Name",
                    bold: true,
                    size: fontSizeTitle4,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            shading: {
              fill: "#BFBFBF",
            },
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Status",
                    bold: true,
                    size: fontSizeTitle4,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    ],
  });

  let tableFillAssg = assignmentList.map((assg) => {
    var array = assg.assignments.assignment_has_user.map(
      (consultant, index) => {
        return (
          consultant.user.first_name + " " + consultant.user.last_name + ","
        );
      }
    );

    var cons_name = array.join(" ");

    tableAssignment.root.push(
      new TableRow({
        tableHeader: true,
        children: [
          // Employer Column
          new TableCell({
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: assg.assignments.clients.name,
                    size: fontSizeTitle4,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
          // Date Column
          new TableCell({
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: assg.assignments.title,
                    size: fontSizeTitle4,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
          // Designation Column
          new TableCell({
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: cons_name.slice(0, -1),
                    size: fontSizeTitle4,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
          // Reason Leaving Column
          new TableCell({
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: assg.assignments.status.replace(/_/g, " "),
                    size: fontSizeTitle4,
                    font: fontType,
                    allCaps: true,
                    bold: true,
                  }),
                ],
              }),
            ],
          }),
        ],
      })
    );
  });

  const doc = new Document({
    sections: [
      {
        properties: {},
        headers: {
          default: new Header({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Lim-Loges",
                    bold: true,
                    size: fontSizeTitle,
                    font: fontType,
                  }),
                  new TextRun({
                    text: " & ",
                    bold: true,
                    size: fontSizeTitle,
                    font: fontType,
                    color: fontColor,
                  }),
                  new TextRun({
                    text: "Masters",
                    bold: true,
                    size: fontSizeTitle,
                    font: fontType,
                  }),
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "transforming companies, transforming career",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                    color: fontColor,
                  }),
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Disruption Management",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: " . ",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                    color: fontColor,
                  }),
                  new TextRun({
                    text: "Executive Search",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: " . ",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                    color: fontColor,
                  }),
                  new TextRun({
                    text: "Transition Management",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                ],
              }),
              // new ImageRun({
              //   data: fs.readFileSync("./report_logo.jpg"),
              //   transformation: {
              //     width: 100,
              //     height: 100,
              //   },
              // }),
              new Paragraph({
                children: [],
              }),
            ],
          }),
        },
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "Lim-Loges & Masters Pte Ltd (Company Reg. No. 201614475K) Address: 6 Battery Road, #30-01, Singapore 049909 Tel: +65 6725 6341",
                    bold: true,
                    size: fontSizeTitle3,
                    font: fontType,
                  }),
                ],
              }),
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "www.LimLogesMasters.com",
                    bold: true,
                    underline: true,
                    size: fontSizeTitle2,
                    font: fontType,
                    color: fontColor,
                  }),
                  new TextRun({
                    text: " China",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: " . ",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: "Hong Kong",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: " . ",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: "India",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: " . ",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: "Malaysia",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: " . ",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: "Singapore",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
        },
        children: [
          new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
              new TextRun({
                text: "Report Summary",
                bold: true,
                size: fontSize14,
                font: fontType,
                color: fontColor,
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                size: fontSize14,
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: "Name: ",
                bold: true,
                size: fontSize11,
                font: fontType,
              }),
              new TextRun({
                text: candidateName,
                size: fontSize11,
                font: fontType,
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: "Mobile Number: ",
                bold: true,
                size: fontSize11,
                font: fontType,
              }),
              new TextRun({
                text: mobile,
                size: fontSize11,
                font: fontType,
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: "Email Address: ",
                bold: true,
                size: fontSize11,
                font: fontType,
              }),
              new TextRun({
                text: email,
                size: fontSize11,
                font: fontType,
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: "LinkedIn Profile: ",
                bold: true,
                size: fontSize11,
                font: fontType,
              }),
              new TextRun({
                text: linkedin,
                size: fontSize11,
                font: fontType,
              }),
            ],
          }),
          new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
              new TextRun({
                text: "Work Experience",
                bold: true,
                size: fontSize14,
                font: fontType,
                break: 1,
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                size: fontSize14,
              }),
            ],
          }),
          tableExperience,
          new Paragraph({
            children: [
              new TextRun({
                size: fontSize14,
              }),
            ],
          }),
          new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
              new TextRun({
                text: "Assignment/s",
                bold: true,
                size: fontSize14,
                font: fontType,
                break: 1,
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                size: fontSize14,
              }),
            ],
          }),
          tableAssignment,
        ],
      },
    ],
  });

  Packer.toBlob(doc).then((blob) => {
    console.log(blob);
    saveAs(blob, "Summary_Report.docx");
    console.log("Document created successfully");
  });

  return doc;
};
