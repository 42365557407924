import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tab, Nav } from "react-bootstrap";

import { TabPersonalDetailClient } from "../../components/Tab/TabClients/TabPersonalDetailClient";
import { TabPasswordClient } from "../../components/Tab/TabClients/TabPasswordClient";

import { getCandidateDetail } from "../../store/actions/candidateSlice";
import { getListOptions, getListYears } from "../../store/actions/optionSlice";
import { storeIndexTab } from "../../store/actions/tabActiveSlice";
import { storeTitle } from "../../store/actions/headerTitleSlice";
import { mdiAccount } from "@mdi/js";

const Account = () => {
  const dispatch = useDispatch();

  const { indexTab } = useSelector((state) => state.tabActive);
  const { user } = useSelector((state) => state.login);

  const handleSelectTab = (key) => {
    // console.log("keyTab", key)
    dispatch(
      storeIndexTab({
        id: user.sub,
        index: key,
      })
    );
  };

  // useEffect(() => {
  //   window.addEventListener("beforeunload", alertUser);
  //   return () => {
  //     window.removeEventListener("beforeunload", alertUser);
  //   };
  // }, []);

  // const alertUser = (e) => {
  //   e.preventDefault();
  //   e.returnValue = "";
  // };

  useEffect(() => {
    dispatch(getCandidateDetail(user.sub));
    dispatch(getListOptions());
    dispatch(getListYears());
    dispatch(storeTitle({title: "Account", icon: mdiAccount}));
  }, [dispatch, user.sub]);

  return (
    <div className="main-div create-st">
      <Tab.Container
        defaultActiveKey={indexTab ? indexTab.index : "1"}
        transition={true}
        onSelect={handleSelectTab}
      >
        <div className="nav-container-nf">
          <Nav variant="tabs" justify>
            <Nav.Item>
              <Nav.Link eventKey="1">Personal Details</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="2">Change Password</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>

        <Tab.Content style={{ background: "none" }}>
          <Tab.Pane eventKey="1">
            <TabPersonalDetailClient></TabPersonalDetailClient>
          </Tab.Pane>

          <Tab.Pane eventKey="2">
            <TabPasswordClient></TabPasswordClient>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default Account;
