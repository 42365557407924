import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../../core/services/api.service";
import AuthAbilities from "../../Auth/AuthAbilities";
import { useNavigate } from "react-router-dom";
import { Dropdown, Button, Accordion, Table, Form, Spinner, Col } from "react-bootstrap";

import moment from "moment";
import SwalAlert from "../../../../components/Swal/SwalAlert";

import Icon from "@mdi/react";
import {
  mdiPlus,
  mdiFilter,
  mdiDelete,
  mdiMerge,
  mdiMagnify,
  mdiDotsHorizontal,
  mdiAccountSearch,
} from "@mdi/js";

import TextboxIcon from "../../../../components/TextField/TextFieldIcon";
import { CustomTable } from "../../../../components/Table/Table";
import CardInfo from "../../../../components/Card/CardInfo";
import Popup from "../../../../components/Popup/ModalPopup";
import "./candidate.css";

import { storeTitle } from "../../../../store/actions/headerTitleSlice";
import { getMergeCandidate } from "../../../../store/actions/mergeSlice";
import { indCandTab } from "../../../../store/actions/tabActiveSlice";
import { storePage } from "../../../../store/actions/paginationSlice";
import {
  getCandidateList, setStoreCandFilter, setAllListCandidate, setTempCandidateData, setTempCandidateFilData,
  getEmptyCandidate, getDataCandidate_languages, getDataCandidate_education, getDataCandidate_experience, getDataCandidate_reference, getDataCandidate_miscellaneous
} from "../../../../store/actions/candidateSlice";

import StepWizard from "react-step-wizard";

import { statusCandidate } from "../../dataList";

function ListCandidates() {
  const valueCandIdChange = (e) => {
    var selectedID = parseInt(e.target.value);
    var candid = checkCandidate.map((s) => s.id);
    // console.log("candOne", candid[0]);
    // console.log("candTwo", parseInt(e.target.value));

    if (selectedID === candid[0]) {
      Object.assign(combineCand, { id: selectedID, id_remove: candid[1] });
    } else {
      Object.assign(combineCand, { id: selectedID, id_remove: candid[0] });
    }

    console.log("combineCand", combineCand);
  };

  const NavDots = (props) => {
    const dots = [];

    for (let i = 1; i <= props.totalSteps; i += 1) {
      const isActive = props.currentStep === i;
      dots.push(
        <span
          key={`step-${i}`}
          onClick={() => props.goToStep(i)}
          className={isActive ? "dot-wizards active-wizards" : "dot-wizards"}
        >
          &bull;
        </span>
      );
    }
    return (
      <>
        <div className="mini-text">
          Select the master record, and then select the fields to merge into the
          master record.
        </div>
        {/* <div className="nav-wizards">{dots}</div> */}
        <div className="sub-text mb-1">
          Please select the primary candidate you want to keep.
        </div>
        <div>
          <Form
            style={{ display: "flex" }}
            className="merge-cb mb-3"
            id="my_form_2"
          >
            <Form.Check
              type="radio"
              label={candOne.cand_name}
              value={candOne.cand_id}
              name="grpMaster"
              onChange={valueCandIdChange}
              required
            />
            <Form.Check
              type="radio"
              label={candTwo.cand_name}
              value={candTwo.cand_id}
              name="grpMaster"
              onChange={valueCandIdChange}
              required
            />
          </Form>
        </div>
      </>
    );
  };

  const About = (props) => {
    const cancel = () => {
      setShowMerge(false);
    };

    const btnAbout = (e) => {
      e.preventDefault();
      const data = new FormData(e.target);
      Object.fromEntries(data.entries());

      props.goToStep(2);

      const element = document.getElementById("modal-box");

      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });

      // console.log("amin");
      // setStepNext(2)
    };

    return (
      <div>
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Similar Fields (Auto Merging)</Accordion.Header>
            <Accordion.Body>
              <Table>
                <tbody>
                  {Object.keys(mergeCandSimilar).map((key, index) => {
                    var nameObj = "";
                    var dataObj = "";

                    if (key === "salutation") {
                      nameObj = "Salutation";
                      dataObj = mergeCandSimilar[key]
                        ? mergeCandSimilar[key]
                        : "-";
                    } else if (key === "first_name") {
                      nameObj = "First Name";
                      dataObj = mergeCandSimilar[key]
                        ? mergeCandSimilar[key]
                        : "-";
                    } else if (key === "last_name") {
                      nameObj = "Last Name";
                      dataObj = mergeCandSimilar[key]
                        ? mergeCandSimilar[key]
                        : "-";
                    } else if (key === "nickname") {
                      nameObj = "Nickname/Alias";
                      dataObj = mergeCandSimilar[key]
                        ? mergeCandSimilar[key]
                        : "-";
                    } else if (key === "gender") {
                      nameObj = "Gender";
                      dataObj = mergeCandSimilar[key]
                        ? mergeCandSimilar[key]
                        : "-";
                    } else if (key === "dob") {
                      nameObj = "Date of Birth";
                      dataObj = mergeCandSimilar[key]
                        ? mergeCandSimilar[key]
                        : "-";
                    } else if (key === "languages") {
                      nameObj = "Languages";
                      dataObj = mergeCandSimilar[key]
                        ? mergeCandSimilar[key]
                        : "-";
                    } else if (key === "mobile_no_code") {
                      nameObj = "Mobile No Country";
                      dataObj = mergeCandSimilar[key]
                        ? mergeCandSimilar[key]
                        : "-";
                    } else if (key === "mobile_no") {
                      nameObj = "Mobile No";
                      dataObj = mergeCandSimilar[key]
                        ? mergeCandSimilar[key]
                        : "-";
                    } else if (key === "email") {
                      nameObj = "Email";
                      dataObj = mergeCandSimilar[key]
                        ? mergeCandSimilar[key]
                        : "-";
                    } else if (key === "permanent_addr") {
                      nameObj = "Permanent Address";
                      dataObj = mergeCandSimilar[key]
                        ? mergeCandSimilar[key]
                        : "-";
                    } else if (key === "posting_addr") {
                      nameObj = "Posting Address";
                      dataObj = mergeCandSimilar[key]
                        ? mergeCandSimilar[key]
                        : "-";
                    } else if (key === "citizenship_main") {
                      nameObj = "Citizenship (Main)";
                      dataObj = mergeCandSimilar[key]
                        ? mergeCandSimilar[key]
                        : "-";
                    } else if (key === "citizenship_other") {
                      nameObj = "Citizenship (Others)";
                      dataObj = mergeCandSimilar[key]
                        ? mergeCandSimilar[key]
                        : "-";
                    } else if (key === "marital_status") {
                      nameObj = "Marital Status";
                      dataObj = mergeCandSimilar[key]
                        ? mergeCandSimilar[key]
                        : "-";
                    } else if (key === "no_of_child") {
                      nameObj = "Number of Children";
                      dataObj = mergeCandSimilar[key]
                        ? mergeCandSimilar[key]
                        : "-";
                    } else if (key === "interest_hobby") {
                      nameObj = "Interest/Hobbies";
                      dataObj = mergeCandSimilar[key]
                        ? mergeCandSimilar[key]
                        : "-";
                    } else if (key === "expected_salary_curr") {
                      nameObj = "Currency";
                      dataObj = mergeCandSimilar[key]
                        ? mergeCandSimilar[key]
                        : "-";
                    } else if (key === "willing_relocate") {
                      nameObj = "Willing to relocate";
                      dataObj = mergeCandSimilar[key] ? "Yes" : "No";
                    } else if (key === "willing_travel") {
                      nameObj = "Willing to travel";
                      dataObj = mergeCandSimilar[key] ? "Yes" : "No";
                    } else if (key === "prefer_location") {
                      nameObj = "Preferred Locations";
                      dataObj = mergeCandSimilar[key]
                        ? mergeCandSimilar[key]
                        : "-";
                    } else if (key === "linkedln_url") {
                      nameObj = "LinkedIn Profile";
                      dataObj = mergeCandSimilar[key]
                        ? mergeCandSimilar[key]
                        : "-";
                    } else if (key === "expected_salary") {
                      nameObj = "Expected Salary";
                      dataObj = mergeCandSimilar[key]
                        ? mergeCandSimilar[key]
                        : "-";
                    } else if (key === "instagram_url") {
                      nameObj = "Instagram Profile";
                      dataObj = mergeCandSimilar[key]
                        ? mergeCandSimilar[key]
                        : "-";
                    } else if (key === "facebook_url") {
                      nameObj = "Facebook Profile";
                      dataObj = mergeCandSimilar[key]
                        ? mergeCandSimilar[key]
                        : "-";
                    } else if (key === "website_url") {
                      nameObj = "Others Website";
                      dataObj = mergeCandSimilar[key]
                        ? mergeCandSimilar[key]
                        : "-";
                    } else {
                      nameObj = "Status";
                      dataObj = mergeCandSimilar[key]
                        ? mergeCandSimilar[key]
                        : "-";
                    }

                    return (
                      <div key={index}>
                        <tr>
                          <th>{nameObj}</th>
                          <td>{dataObj}</td>
                        </tr>
                      </div>
                    );
                  })}
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Field Selection</Accordion.Header>
            <Accordion.Body>
              <Form id="my_form" onSubmit={btnAbout}>
                <Table>
                  <tbody>
                    {mergeCandNoSimilar.map((data, index) => {
                      var nameObj = "";
                      var dataCandOne = "";
                      var dataCandTwo = "";

                      if (data.name === "salutation") {
                        nameObj = "Salutation";
                        dataCandOne = data.candOne ? data.candOne : "-";
                        dataCandTwo = data.candTwo ? data.candTwo : "-";
                      } else if (data.name === "first_name") {
                        nameObj = "First Name";
                        dataCandOne = data.candOne ? data.candOne : "-";
                        dataCandTwo = data.candTwo ? data.candTwo : "-";
                      } else if (data.name === "last_name") {
                        nameObj = "Last Name";
                        dataCandOne = data.candOne ? data.candOne : "-";
                        dataCandTwo = data.candTwo ? data.candTwo : "-";
                      } else if (data.name === "nickname") {
                        nameObj = "Nickname/Alias";
                        dataCandOne = data.candOne ? data.candOne : "-";
                        dataCandTwo = data.candTwo ? data.candTwo : "-";
                      } else if (data.name === "gender") {
                        nameObj = "Gender";
                        dataCandOne = data.candOne ? data.candOne : "-";
                        dataCandTwo = data.candTwo ? data.candTwo : "-";
                      } else if (data.name === "dob") {
                        nameObj = "Date of Birth";
                        dataCandOne = data.candOne
                          ? moment(data.candOne).format("DD MMMM YYYY")
                          : "-";
                        dataCandTwo = data.candTwo
                          ? moment(data.candTwo).format("DD MMMM YYYY")
                          : "-";
                      } else if (data.name === "languages") {
                        nameObj = "Languages";
                        dataCandOne =
                          data.candOne.length > 0
                            ? data.candOne.toString()
                            : "-";
                        dataCandTwo =
                          data.candTwo.length > 0
                            ? data.candTwo.toString()
                            : "-";
                      } else if (data.name === "mobile_no_code") {
                        nameObj = "Mobile No Country";
                        dataCandOne = data.candOne ? data.candOne : "-";
                        dataCandTwo = data.candTwo ? data.candTwo : "-";
                      } else if (data.name === "mobile_no") {
                        nameObj = "Mobile No";
                        dataCandOne = data.candOne ? data.candOne : "-";
                        dataCandTwo = data.candTwo ? data.candTwo : "-";
                      } else if (data.name === "email") {
                        nameObj = "Email";
                        dataCandOne = data.candOne ? data.candOne : "-";
                        dataCandTwo = data.candTwo ? data.candTwo : "-";
                      } else if (data.name === "permanent_addr") {
                        nameObj = "Permanent Address";
                        dataCandOne = data.candOne ? data.candOne : "-";
                        dataCandTwo = data.candTwo ? data.candTwo : "-";
                      } else if (data.name === "posting_addr") {
                        nameObj = "Posting Address";
                        dataCandOne = data.candOne ? data.candOne : "-";
                        dataCandTwo = data.candTwo ? data.candTwo : "-";
                      } else if (data.name === "citizenship_main") {
                        nameObj = "Citizenship (Main)";
                        dataCandOne = data.candOne ? data.candOne : "-";
                        dataCandTwo = data.candTwo ? data.candTwo : "-";
                      } else if (data.name === "citizenship_other") {
                        nameObj = "Citizenship (Others)";
                        dataCandOne = data.candOne ? data.candOne : "-";
                        dataCandTwo = data.candTwo ? data.candTwo : "-";
                      } else if (data.name === "marital_status") {
                        nameObj = "Marital Status";
                        dataCandOne = data.candOne ? data.candOne : "-";
                        dataCandTwo = data.candTwo ? data.candTwo : "-";
                      } else if (data.name === "no_of_child") {
                        nameObj = "Number of Children";
                        dataCandOne = data.candOne ? data.candOne : "-";
                        dataCandTwo = data.candTwo ? data.candTwo : "-";
                      } else if (data.name === "interest_hobby") {
                        nameObj = "Interest/Hobbies";
                        dataCandOne = data.candOne ? data.candOne : "-";
                        dataCandTwo = data.candTwo ? data.candTwo : "-";
                      } else if (data.name === "expected_salary_curr") {
                        nameObj = "Currency";
                        dataCandOne = data.candOne ? data.candOne : "-";
                        dataCandTwo = data.candTwo ? data.candTwo : "-";
                      } else if (data.name === "willing_relocate") {
                        nameObj = "Willing to relocate";
                        dataCandOne = data.candOne ? "Yes" : "No";
                        dataCandTwo = data.candTwo ? "Yes" : "No";
                      } else if (data.name === "willing_travel") {
                        nameObj = "Willing to travel";
                        dataCandOne = data.candOne ? "Yes" : "No";
                        dataCandTwo = data.candTwo ? "Yes" : "No";
                      } else if (data.name === "prefer_location") {
                        nameObj = "Preferred Locations";
                        dataCandOne = data.candOne ? data.candOne : "-";
                        dataCandTwo = data.candTwo ? data.candTwo : "-";
                      } else if (data.name === "linkedln_url") {
                        nameObj = "LinkedIn Profile";
                        dataCandOne = data.candOne ? data.candOne : "-";
                        dataCandTwo = data.candTwo ? data.candTwo : "-";
                      } else if (data.name === "expected_salary") {
                        nameObj = "Expected Salary";
                        dataCandOne = data.candOne
                          ? Number(data.candOne)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                          : "-";
                        dataCandTwo = data.candTwo
                          ? Number(data.candTwo)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                          : "-";
                      } else if (data.name === "instagram_url") {
                        nameObj = "Instagram Profile";
                        dataCandOne = data.candOne ? data.candOne : "-";
                        dataCandTwo = data.candTwo ? data.candTwo : "-";
                      } else if (data.name === "facebook_url") {
                        nameObj = "Facebook Profile";
                        dataCandOne = data.candOne ? data.candOne : "-";
                        dataCandTwo = data.candTwo ? data.candTwo : "-";
                      } else if (data.name === "website_url") {
                        nameObj = "Others Website";
                        dataCandOne = data.candOne ? data.candOne : "-";
                        dataCandTwo = data.candTwo ? data.candTwo : "-";
                      } else {
                        nameObj = "Status";
                        dataCandOne = data.candOne ? data.candOne : "-";
                        dataCandTwo = data.candTwo ? data.candTwo : "-";
                      }

                      const valueCandObject = (data) => (e) => {
                        const str = e.target.value;
                        // console.log("data", data)

                        const property = str.substring(0, str.indexOf(" ")); // "72"

                        if (
                          property === "languages" ||
                          property === "prefer_location" ||
                          property === "interest_hobby"
                        ) {
                          Object.assign(combineCand, { [property]: data });
                        } else {
                          const dataValue = str.substring(str.indexOf(" ") + 1);

                          console.log("dataValue", dataValue);

                          Object.assign(combineCand, {
                            [property]: dataValue !== "null" ? dataValue : null,
                          });
                        }

                        // const property = str.substring(0, str.indexOf(' ')); // "72"
                        // const dataValue = str.substring(str.indexOf(' ') + 1); // "tocirah sneab"

                        // Object.assign(combineCand, {[property]: dataValue});
                        console.log("setCombineCand", combineCand);
                      };

                      return (
                        <tr key={index}>
                          <th>{nameObj}</th>
                          <td>
                            <Form.Check
                              type="radio"
                              label={dataCandOne}
                              name={index}
                              value={data.name + " " + data.candOne}
                              onChange={valueCandObject(data.candOne)}
                              required
                            />
                          </td>
                          <td>
                            <Form.Check
                              type="radio"
                              label={dataCandTwo}
                              name={index}
                              value={data.name + " " + data.candTwo}
                              onChange={valueCandObject(data.candTwo)}
                              required
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Stats
          step={1}
          {...props}
          previousStep={cancel}
          form="my_form"
          goToStep={2}
        />
      </div>
    );
  };

  const Education = (props) => {
    const [checkEducation] = useState([]);

    const btnEducation = (e) => {
      if (mergeCandEduOne.length === 0 && mergeCandEduTwo.length === 0) {
        props.goToStep(3);

        const element = document.getElementById("modal-box");

        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      } else {
        if (combineCand.education.length > 0) {
          props.goToStep(3);

          const element = document.getElementById("modal-box");

          element.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start",
          });
        }
      }
    };

    return (
      <div>
        <div>
          <b>Education</b>
        </div>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <Table>
            <tbody>
              {mergeCandEduOne.map((edu, index) => {
                const valueCandEdu = (data) => (event) => {
                  if (event.target.checked === false) {
                    const index = checkEducation.findIndex(function (chck) {
                      return chck.id === data.id;
                    });

                    checkEducation.splice(index, 1);
                  } else {
                    checkEducation.push(data);
                  }

                  Object.assign(combineCand, { education: checkEducation });
                  // console.log("setCombineCand", combineCand);
                };

                return (
                  <div key={index}>
                    <tr>
                      <td width="50%">
                        <div className="merge-cb" style={{ display: "flex" }}>
                          <Form.Check
                            type="checkbox"
                            onChange={valueCandEdu(edu)}
                          />
                          <div>
                            <div className="text">{edu.name}</div>
                            <div className="sub-text">{edu.qualification}</div>
                            <div className="mini-text">
                              {edu.start_year} - {edu.end_year}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </div>
                );
              })}
            </tbody>
          </Table>

          <Table>
            <tbody>
              {mergeCandEduTwo.map((edu, index) => {
                const valueCandEdu = (data) => (event) => {
                  if (event.target.checked === false) {
                    const index = checkEducation.findIndex(function (chck) {
                      return chck.id === data.id;
                    });

                    checkEducation.splice(index, 1);
                  } else {
                    checkEducation.push(data);
                  }

                  Object.assign(combineCand, { education: checkEducation });
                  // console.log("setCombineCand", combineCand);
                };

                return (
                  <div key={index}>
                    <tr>
                      <td width="50%">
                        <div className="merge-cb" style={{ display: "flex" }}>
                          <Form.Check
                            type="checkbox"
                            onChange={valueCandEdu(edu)}
                          />
                          <div>
                            <div className="text">{edu.name}</div>
                            <div className="sub-text">{edu.qualification}</div>
                            <div className="mini-text">
                              {edu.start_year} - {edu.end_year}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </div>
                );
              })}
            </tbody>
          </Table>
        </div>

        <Stats step={2} {...props} nextStep={btnEducation} />
      </div>
    );
  };

  const Experience = (props) => {
    const [checkExperience] = useState([]);

    const btnExperience = (e) => {
      if (mergeCandExpOne.length === 0 && mergeCandExpTwo.length === 0) {
        props.goToStep(4);

        const element = document.getElementById("modal-box");

        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      } else {
        if (combineCand.experience.length > 0) {
          props.goToStep(4);

          const element = document.getElementById("modal-box");

          element.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start",
          });
        }
      }
    };

    return (
      <div>
        <div>
          <b>Experience</b>
        </div>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <Table>
            <tbody>
              {mergeCandExpOne.map((exp, index) => {
                const valueCandExp = (data) => (event) => {
                  if (event.target.checked === false) {
                    const index = checkExperience.findIndex(function (chck) {
                      return chck.id === data.id;
                    });

                    checkExperience.splice(index, 1);
                  } else {
                    checkExperience.push(data);
                  }

                  Object.assign(combineCand, { experience: checkExperience });
                  // console.log("setCombineCand", combineCand);
                };

                return (
                  <div key={index}>
                    <tr>
                      <td width="50%">
                        <div className="merge-cb" style={{ display: "flex" }}>
                          <Form.Check
                            type="checkbox"
                            onChange={valueCandExp(exp)}
                          />
                          <div>
                            <div className="text">{exp.title}</div>
                            <div className="sub-text">{exp.company_name} </div>
                            <div className="mini-text">
                              {exp.current_working
                                ? moment(exp.present_month).format("MMMM") +
                                " " +
                                exp.present_year +
                                "- Current"
                                : moment(exp.start_date_month).format("MMMM") +
                                " " +
                                exp.start_date_year +
                                " - " +
                                moment(exp.end_date_month).format("MMMM") +
                                " " +
                                exp.end_date_year}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </div>
                );
              })}
            </tbody>
          </Table>

          <Table>
            <tbody>
              {mergeCandExpTwo.map((exp, index) => {
                const valueCandExp = (data) => (event) => {
                  if (event.target.checked === false) {
                    const index = checkExperience.findIndex(function (chck) {
                      return chck.id === data.id;
                    });

                    checkExperience.splice(index, 1);
                  } else {
                    checkExperience.push(data);
                  }

                  Object.assign(combineCand, { experience: checkExperience });
                  // console.log("setCombineCand", combineCand);
                };

                return (
                  <div key={index}>
                    <tr>
                      <td width="50%">
                        <div className="merge-cb" style={{ display: "flex" }}>
                          <Form.Check
                            type="checkbox"
                            onChange={valueCandExp(exp)}
                          />
                          <div>
                            <div className="text">{exp.title}</div>
                            <div className="sub-text">{exp.company_name} </div>
                            <div className="mini-text">
                              {exp.current_working
                                ? moment(exp.present_month).format("MMMM") +
                                " " +
                                exp.present_year +
                                "- Current"
                                : moment(exp.start_date_month).format("MMMM") +
                                " " +
                                exp.start_date_year +
                                " - " +
                                moment(exp.end_date_month).format("MMMM") +
                                " " +
                                exp.end_date_year}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </div>
                );
              })}
            </tbody>
          </Table>
        </div>

        <Stats step={3} {...props} nextStep={btnExperience} />
      </div>
    );
  };

  const References = (props) => {
    const [checkReferee] = useState([]);

    const btnReferee = (e) => {
      if (mergeCandRefOne.length === 0 && mergeCandRefTwo.length === 0) {
        props.goToStep(5);

        const element = document.getElementById("modal-box");

        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      } else {
        if (combineCand.reference.length > 0) {
          props.goToStep(5);

          const element = document.getElementById("modal-box");

          element.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start",
          });
        }
      }
    };

    return (
      <div>
        <div>
          <b>Reference</b>
        </div>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <Table>
            <tbody>
              {mergeCandRefOne.map((ref, index) => {
                const valueCandRef = (data) => (event) => {
                  if (event.target.checked === false) {
                    const index = checkReferee.findIndex(function (chck) {
                      return chck.id === data.id;
                    });

                    checkReferee.splice(index, 1);
                  } else {
                    checkReferee.push(data);
                  }

                  Object.assign(combineCand, { reference: checkReferee });
                  // console.log("setCombineCand", combineCand);
                };

                return (
                  <div key={index}>
                    <tr>
                      <td width="50%">
                        <div className="merge-cb" style={{ display: "flex" }}>
                          <Form.Check
                            type="checkbox"
                            onChange={valueCandRef(ref)}
                          />
                          <div>
                            <div className="text">{ref.name}</div>
                            <div className="sub-text">
                              {ref.job_title}{" "}
                              {ref.current_company && ref.job_title ? "," : ""}{" "}
                              {ref.current_company}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </div>
                );
              })}
            </tbody>
          </Table>

          <Table>
            <tbody>
              {mergeCandRefTwo.map((ref, index) => {
                const valueCandRef = (data) => (event) => {
                  if (event.target.checked === false) {
                    const index = checkReferee.findIndex(function (chck) {
                      return chck.id === data.id;
                    });

                    checkReferee.splice(index, 1);
                  } else {
                    checkReferee.push(data);
                  }

                  Object.assign(combineCand, { reference: checkReferee });
                  // console.log("setCombineCand", combineCand);
                };

                return (
                  <div key={index}>
                    <tr>
                      <td width="50%">
                        <div className="merge-cb" style={{ display: "flex" }}>
                          <Form.Check
                            type="checkbox"
                            onChange={valueCandRef(ref)}
                          />
                          <div>
                            <div className="text">{ref.name}</div>
                            <div className="sub-text">
                              {ref.job_title}{" "}
                              {ref.current_company && ref.job_title ? "," : ""}{" "}
                              {ref.current_company}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </div>
                );
              })}
            </tbody>
          </Table>
        </div>
        <Stats step={4} {...props} nextStep={btnReferee} />
      </div>
    );
  };

  const Additional = (props) => {
    const [checkMiscell] = useState([]);

    const btnMiscell = (e) => {
      // console.log("mergeCandMisOne", mergeCandMisOne)
      if (mergeCandMisOne.length === 0 && mergeCandMisTwo.length === 0) {
        // console.log("combineCand1", combineCand);

        let finalize = { ...mergeCandSimilar, ...combineCand };

        customSwal
          .fire({
            title: "Do you want to merge these candidate?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post("candidate/updateMergeCandidate", finalize)
                .then((response) => {
                  // let data = response.data;

                  // console.log("data list candidate", data);
                  dispatch(
                    getCandidateList(
                      candFilter.txtsearchCand,
                      candFilter.ddlStatus,
                      candFilter.txtcompName,
                      candFilter.txtjobtitle
                    )
                  );
                  setShowMerge(false);
                  setCheckCandidate([]);

                  customSwal.fire({
                    title: "Merge Successful!",
                    icon: "success",
                    confirmButtonText: "Okay",
                  });
                })
                .catch((error) => {
                  console.log("error merge candidate", error);
                });
            }
          });
      } else {
        if (combineCand.miscellaneous.length > 0) {
          let finalize = { ...mergeCandSimilar, ...combineCand };

        customSwal
          .fire({
            title: "Do you want to merge these candidate?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post("candidate/updateMergeCandidate", finalize)
                .then((response) => {
                  // let data = response.data;

                  // console.log("data list candidate", data);
                  dispatch(
                    getCandidateList(
                      candFilter.txtsearchCand,
                      candFilter.ddlStatus,
                      candFilter.txtcompName,
                      candFilter.txtjobtitle
                    )
                  );
                  setShowMerge(false);
                  setCheckCandidate([]);

                  customSwal.fire({
                    title: "Merge Successful!",
                    icon: "success",
                    confirmButtonText: "Okay",
                  });
                })
                .catch((error) => {
                  console.log("error merge1 candidate", error);
                });
            }
          });
        }
      }
      // alert("You did it! Yay!"); // eslint-disable-line
    };

    return (
      <div>
        <div>
          <b>Miscellaneous Information</b>
        </div>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <Table>
            <tbody>
              {mergeCandMisOne.map((mis, index) => {
                const valueCandMisc = (data) => (event) => {
                  if (event.target.checked === false) {
                    const index = checkMiscell.findIndex(function (chck) {
                      return chck.id === data.id;
                    });

                    checkMiscell.splice(index, 1);
                  } else {
                    checkMiscell.push(data);
                  }

                  Object.assign(combineCand, { miscellaneous: checkMiscell });
                  // console.log("setCombineCand", combineCand);
                };

                return (
                  <div key={index}>
                    <tr>
                      <td width="50%">
                        <div className="merge-cb" style={{ display: "flex" }}>
                          <Form.Check
                            type="checkbox"
                            onChange={valueCandMisc(mis)}
                          />
                          <div>
                            <div className="text">{mis.misc}</div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </div>
                );
              })}
            </tbody>
          </Table>

          <Table>
            <tbody>
              {mergeCandMisTwo.map((mis, index) => {
                const valueCandMisc = (data) => (event) => {
                  if (event.target.checked === false) {
                    const index = checkMiscell.findIndex(function (chck) {
                      return chck.id === data.id;
                    });

                    checkMiscell.splice(index, 1);
                  } else {
                    checkMiscell.push(data);
                  }

                  Object.assign(combineCand, { miscellaneous: checkMiscell });
                  // console.log("setCombineCand", combineCand);
                };

                return (
                  <div key={index}>
                    <tr>
                      <td width="50%">
                        <div className="merge-cb" style={{ display: "flex" }}>
                          <Form.Check
                            type="checkbox"
                            onChange={valueCandMisc(mis)}
                          />
                          <div>
                            <div className="text">{mis.misc}</div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </div>
                );
              })}
            </tbody>
          </Table>
        </div>
        <Stats step={5} {...props} nextStep={btnMiscell} />
      </div>
    );
  };

  const Stats = ({ nextStep, previousStep, totalSteps, step, form }) => {
    return (
      <>
        <div className="row-btn-bottom">
          {step === 1 && (
            <Button variant="secondary" onClick={previousStep}>
              Cancel
            </Button>
          )}
          {step > 1 && (
            <Button variant="secondary" onClick={previousStep}>
              Back
            </Button>
          )}
          {step < totalSteps ? (
            step === 1 ? (
              <Button variant="primary" type="submit" form={form}>
                Continue
              </Button>
            ) : (
              <Button variant="primary" onClick={nextStep}>
                Continue
              </Button>
            )
          ) : (
            <Button variant="primary" onClick={nextStep}>
              Merge
            </Button>
          )}
        </div>
      </>
    );
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { candidateList, candidateAllList, candFilter, candidateTempData, candidateTempFilData } = useSelector((state) => state.candidate);
  const {
    mergeCandSimilar,
    mergeCandNoSimilar,
    mergeCandEduOne,
    mergeCandEduTwo,
    mergeCandExpOne,
    mergeCandExpTwo,
    mergeCandRefOne,
    mergeCandRefTwo,
    mergeCandMisOne,
    mergeCandMisTwo,
  } = useSelector((state) => state.merge);

  const { dataLanguages, dataIndustry, dataJobFunction } = useSelector((state) => state.option);

  const abilities = AuthAbilities();
  const customSwal = SwalAlert();

  const [clicked, setClicked] = useState(false);
  const [showMerge, setShowMerge] = useState(false);

  // const [tempCandidateData, setTempCandidateData] = useState([]);
  // const [tempCandidateFilData, setTempCandidateFilData] = useState([]);

  const [checkCandidate, setCheckCandidate] = useState([]);
  const [candSelected, setCandSelected] = useState(0);

  const [candOne, setCandOne] = useState({});
  const [candTwo, setCandTwo] = useState({});
  const [combineCand, setCombineCand] = useState({
    education: [],
    experience: [],
    reference: [],
    miscellaneous: [],
  });

  // Merge Function
  const btnOpenMerge = (e) => {
    setCombineCand({
      education: [],
      experience: [],
      reference: [],
      miscellaneous: [],
    });

    setCandOne({
      cand_id: checkCandidate[0].id,
      cand_name: checkCandidate[0].last_name
        ? checkCandidate[0].first_name + " " + checkCandidate[0].last_name
        : checkCandidate[0].first_name,
    });

    setCandTwo({
      cand_id: checkCandidate[1].id,
      cand_name: checkCandidate[1].last_name
        ? checkCandidate[1].first_name + " " + checkCandidate[1].last_name
        : checkCandidate[1].first_name,
    });

    var candid = checkCandidate.map((s) => s.id);

    dispatch(getMergeCandidate(candid[0], candid[1]));

    // setTotAttObj(Object.keys(mergeCandOne).length);

    setShowMerge(true);
  };

  const btnCloseMerge = () => {
    setShowMerge(false);
  };

  const btnDelete = (id) => (e) => {
    customSwal
      .fire({
        title: "Do you want to delete this candidate?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("candidate/" + id)
            .then((response) => {
              // setSelectedIndustry([]);

              //close modal after update
              dispatch(getCandidateList(candFilter.txtsearchCand, candFilter.txtcompName, candFilter.txtjobtitle, candFilter.ddlStatus, candFilter.ddlJobFunction, candFilter.ddlLanguage, candFilter.ddlIndustry));
              customSwal.fire({
                title: "Deleted Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error delete candidate", error);
            });
        }
      });
  };

  const btnDeleteMultiple = () => (e) =>{
    customSwal
    .fire({
      title: checkCandidate.length > 1 ? "Do you want to delete multiple selected candidate?" : "Do you want to delete selected candidate?",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Yes",
    })
    .then((result) => {
      if (result.isConfirmed) {

        checkCandidate.map((cand, index) => {
          Api.delete("candidate/" + cand.id)
          .then((response) => {
            dispatch(getCandidateList(candFilter.txtsearchCand, candFilter.txtcompName, candFilter.txtjobtitle, candFilter.ddlStatus, candFilter.ddlJobFunction, candFilter.ddlLanguage, candFilter.ddlIndustry));
          })
          .catch((error) => {
            console.log("error delete multiple candidate", error);
          });
        });

        //close modal after update
       
        customSwal.fire({
          title: "Deleted Successfully!",
          icon: "success",
          confirmButtonText: "Okay",
        });
        
      }
    });
  };

  const headers = [
    {
      Header: "",
      accessor: "selection",
      disableSortBy: true,
      maxWidth: 50,
      minWidth: 10,
      width: 50,
      Cell: ({ row }) => {
        const data = row.original;

        const onItemCheck = (data) => (event) => {
          if (checkCandidate.length > 0) {
            if (event.target.checked === false) {
              const index = checkCandidate.findIndex(function (chck) {
                return chck.id === data.id;
              });

              checkCandidate.splice(index, 1);
            } else {
              checkCandidate.push(data);
            }
          } else {
            checkCandidate.push(data);
          }

          setCandSelected(checkCandidate.length);

          // console.log("checkCandidate", checkCandidate);
        };

        const checkedItem = (id) => {
          let cnd = [];

          const checkCand = checkCandidate.filter((chec) => {
            return chec.id === id;
          });

          cnd = checkCand[0];

          return cnd;
        };

        return (
          <div className="checkbox-container">
            <input
              type="checkbox"
              checked={checkedItem(data.id)}
              id={"row" + data.id}
              onChange={onItemCheck(data)}
            />
          </div>
        );
      },
    },
    {
      Header: "Name",
      accessor: (row) => `${row.first_name}`,
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        const navigate = useNavigate();

        let nameCandi = "";

        if (data.last_name !== null) {
          nameCandi = data.first_name + " " + data.last_name;
        } else {
          nameCandi = data.first_name;
        }

        function handleClick() {
          dispatch(
            indCandTab({
              id: data.id,
              index: "1",
            })
          );
          dispatch(getEmptyCandidate({}));
          dispatch(getDataCandidate_languages([]));
          dispatch(getDataCandidate_education([]));
          dispatch(getDataCandidate_experience([]));
          dispatch(getDataCandidate_reference([]));
          dispatch(getDataCandidate_miscellaneous([]));

          navigate("/candidates/viewCandidate/" + data.id);
        }

        return (
          <Button
            variant="link"
            className="no-underline"
            onClick={handleClick}
            style={{ textTransform: "capitalize" }}
          >
            {nameCandi}
          </Button>
        );
      },
    },
    {
      Header: "Company",
      accessor: (row) => {
        // const data = row.original;

        let companyName = "";

        if (row.candidate_experiences.length > 0) {
          companyName = row.candidate_experiences[0].company_name;
        } else {
          companyName = "Z";
        }

        return `${companyName}`;
      },
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        let companyName = "";

        if (data.candidate_experiences.length > 0) {
          companyName = data.candidate_experiences[0].company_name;
        } else {
          companyName = "-";
        }

        return (
          <>
            {data.candidate_experiences.length > 0 ? (
              <Button variant="link" className="no-underline">
                {companyName}
              </Button>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      Header: "Job Title",
      accessor: (row) => {
        // const data = row.original;

        let jobTitle = "";

        if (row.candidate_experiences.length > 0) {
          jobTitle = row.candidate_experiences[0].title;
        } else {
          jobTitle = row.job_title ? row.job_title : "Z";
        }

        return `${jobTitle}`;
      },
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        let jobTitle = "";

        if (data.candidate_experiences.length > 0) {
          jobTitle = data.candidate_experiences[0].title;
        } else {
          jobTitle = data.job_title ? data.job_title : "-";
        }

        return <>{jobTitle}</>;
      },
    },
    {
      Header: "Mobile Number",
      maxWidth: 200,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <>
            <div>{data.mobile_no ? data.mobile_no : "-"}</div>
          </>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      maxWidth: 150,
      minWidth: 150,
      width: 150,
      Cell: ({ row }) => {
        const data = row.original;

        let colorCard = "";

        if (data.status === "active") {
          colorCard = "card_text--primary--solid";
        } else if (data.status === "available") {
          colorCard = "card_text--primary--solid";
        } else if (data.status === "client") {
          colorCard = "card_text--warning--solid";
        } else if (data.status === "placed") {
          colorCard = "card_text--danger--solid";
        } else {
          colorCard = "card_text--discussion--solid";
        }

        return (
          <>
            {/* {data.status === "placed" ?  */}
            <CardInfo cardTextSize="card_text--small" cardTextStyle={colorCard}>
              {data.status}
            </CardInfo>
            {/* :
              <CardInfo
                cardTextSize="card_text--small"
                cardTextStyle="card_text--primary--solid"
              >
                {data.status}
              </CardInfo>
            
            } */}
          </>
        );
      },
    },
    {
      Header: "",
      accessor: "client_info",
      disableSortBy: true,
      maxWidth: 70,
      minWidth: 10,
      width: 70,
      Cell: ({ row }) => {
        const data = row.original;

        //   const navigate = useNavigate();

        //   function handleAdd() {
        //     navigate("/addProposalC/" + data.sno);
        //   }

        return (
          <>
            {abilities.includes("delete candidate") ? (
              <div className="dropdown-container list-cand">
                <Dropdown>
                  <Dropdown.Toggle variant="light" id="dropdown-basic">
                    <Icon path={mdiDotsHorizontal} size={1} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      hidden={!abilities.includes("delete candidate")}
                      onClick={btnDelete(data.id)}
                    >
                      <Icon path={mdiDelete} color="#DF4759" />
                      Delete Candidate
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];

  const btnAddCandidate = () => {
    navigate("/candidates/addCandidate");
  };

  const handleSearch = (event) => {
    let searchVal = event.target.value.toLowerCase();

    dispatch(setStoreCandFilter({ txtsearchCand: searchVal }));
    dispatch(storePage(0));

    filterListing(
      searchVal, 
      candFilter.txtcompName, 
      candFilter.txtjobtitle, 
      candFilter.ddlStatus,
      candFilter.ddlJobFunction,
      candFilter.ddlLanguage,
      candFilter.ddlIndustry
    );
  };

  const btnFilter = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtFilter = Object.fromEntries(data.entries());

    dispatch(storePage(0));
    dispatch(setStoreCandFilter(
      { ddlStatus: dtFilter.ddlStatus, 
        txtcompName: dtFilter.txtcompName, 
        txtjobtitle: dtFilter.txtjobtitle, 
        ddlJobFunction: dtFilter.ddlJobFunction,
        ddlLanguage: dtFilter.ddlLanguage,
        ddlIndustry: dtFilter.ddlIndustry,
      }
    ));

    filterListing(
      candFilter.txtsearchCand, 
      dtFilter.txtcompName,
      dtFilter.txtjobtitle, 
      dtFilter.ddlStatus,
      dtFilter.ddlJobFunction,
      dtFilter.ddlLanguage,
      dtFilter.ddlIndustry
    );

  };

  function filterListing(searchVal, compName, jobTitle, statusCand, jobFunction, language, industryCand) {
    // console.log("hi amin", searchVal)

    let newList = [];

    const vowelARegex = /[à,æ,ã,å,ā,á,â,ä]/gi;
    const vowelERegex = /[ê,é,è,ë,ē]/gi;
    const vowelIRegex = /[ì,ī,ï,í,î]/gi;
    const vowelORegex = /[œ,œ,ø,ō,õ,ô,ö,ò]/gi;
    const vowelURegex = /[ū,ü,ú,û,ù]/gi;
    

           if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language === "" && industryCand ==="") {
      newList = candidateList.filter((candidate) => {
        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase())
        );
      });
    } else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language === "" && industryCand ==="") {
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase()))
        );
      });
    } else if (searchVal !== "" && compName !== "" && jobTitle !== "" && statusCand === "" && jobFunction === "" && language === "" && industryCand ==="") {
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase()))
        );
      });
    } else if (searchVal !== "" && compName !== "" && jobTitle !== "" && statusCand !== "" && jobFunction === "" && language === "" && industryCand ==="") {
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand)
        );
      });
    } else if (searchVal !== "" && compName !== "" && jobTitle !== "" && statusCand !== "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find(
            (e) => e.job_function.length !== 0 ? 
              e.job_function.includes(jobFunction)
            :[] 
          )
        );
      });
    } else if (searchVal !== "" && compName !== "" && jobTitle !== "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find(
            (e) => e.job_function.length !== 0 ? 
              e.job_function.includes(jobFunction)
            :[] 
          ) && 
          candidate.languages.includes(language)
        );
      });
    } else if (searchVal !== "" && compName !== "" && jobTitle !== "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } 
    
      else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language === "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand)
        );
      });
    } else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction))
        );
      });
    } else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language)
        );
      });
    } else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } 
      else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language !== "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.languages.includes(language)
        );
      });
    } else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language !== "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language === "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction))
        );
      });
    } else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language)
        );
      });
    } else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language === "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } 
      else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language !== "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.languages.includes(language)
        );
      });
    } else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language !== "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal !== "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language === "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal !== "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction === "" && language === "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase()))
        );
      });
    } else if (searchVal !== "" && compName === "" && jobTitle !== "" && statusCand !== "" && jobFunction === "" && language === "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand)
        );
      });
    } else if (searchVal !== "" && compName === "" && jobTitle !== "" && statusCand !== "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction))
        );
      });
    } 
      
      else if (searchVal !== "" && compName === "" && jobTitle !== "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language)
        );
      });
    } else if (searchVal !== "" && compName === "" && jobTitle !== "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal !== "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction))
        );
      });
    } else if (searchVal !== "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language)
        );
      });
    } else if (searchVal !== "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal !== "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction !== "" && language === "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } 
    
      else if (searchVal !== "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction === "" && language !== "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.languages.includes(language)
        );
      });
    } else if (searchVal !== "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction === "" && language !== "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal !== "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction === "" && language === "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language === "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.status.toLowerCase().includes(statusCand)
        );
      });
    } else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) 
        );
      });
    } else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language)
        );
      });
    } 
    
      else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language !== "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.languages.includes(language)
        );
      });
    } else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language !== "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language === "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction))
        );
      });
    } else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) 
        );
      });
    } 
      
      else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language === "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language !== "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.languages.includes(language)
        );
      });
    } else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language !== "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal !== "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language === "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        let candName = "";

        if(!candidate.last_name || candidate.last_name === ""){
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }else{
          candName = candidate.first_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase() + " " 
          + candidate.last_name.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase();
        }

        return(
          candName.toLowerCase().includes(searchVal.replace(vowelARegex, 'a').replace(vowelERegex, 'e').replace(vowelIRegex, 'i').replace(vowelORegex, 'o').replace(vowelURegex, 'u').toLowerCase()) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } 
    // Next
      else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language === "" && industryCand ==="") {
      newList = candidateList.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase()))
        );
      });
    } else if (searchVal === "" && compName !== "" && jobTitle !== "" && statusCand === "" && jobFunction === "" && language === "" && industryCand ==="") {
      newList = candidateList.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase()))
        );
      });
    } else if (searchVal === "" && compName !== "" && jobTitle !== "" && statusCand !== "" && jobFunction === "" && language === "" && industryCand ==="") {
      newList = candidateList.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand)
        );
      });
    } else if (searchVal === "" && compName !== "" && jobTitle !== "" && statusCand !== "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find(
            (e) => e.job_function.length !== 0 ? 
              e.job_function.includes(jobFunction)
            :[] 
          )
        );
      });
    } else if (searchVal === "" && compName !== "" && jobTitle !== "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find(
            (e) => e.job_function.length !== 0 ? 
              e.job_function.includes(jobFunction)
            :[] 
          ) && 
          candidate.languages.includes(language)
        );
      });
    } else if (searchVal === "" && compName !== "" && jobTitle !== "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } 
    
      else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language === "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand)
        );
      });
    } else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction))
        );
      });
    } else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language)
        );
      });
    } else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language !== "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.languages.includes(language)
        );
      });
    } 
      else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language !== "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language === "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction))
        );
      });
    } else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {
        return(
          
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language)
        );
      });
    } else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {
        return(
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language === "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {
        return(
          
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } 
      else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language !== "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {
        return(
          
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.languages.includes(language)
        );
      });
    } else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language !== "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal === "" && compName !== "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language === "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.candidate_experiences.find((e) => e.company_name.toLowerCase().includes(compName.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal === "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction === "" && language === "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase()))
        );
      });
    } else if (searchVal === "" && compName === "" && jobTitle !== "" && statusCand !== "" && jobFunction === "" && language === "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand)
        );
      });
    } else if (searchVal === "" && compName === "" && jobTitle !== "" && statusCand !== "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction))
        );
      });
    } 
      
      else if (searchVal === "" && compName === "" && jobTitle !== "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language)
        );
      });
    } else if (searchVal === "" && compName === "" && jobTitle !== "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal === "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction))
        );
      });
    } else if (searchVal === "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language)
        );
      });
    } else if (searchVal === "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal === "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction !== "" && language === "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } 
    
      else if (searchVal === "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction === "" && language !== "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.languages.includes(language)
        );
      });
    } else if (searchVal === "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction === "" && language !== "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal === "" && compName === "" && jobTitle !== "" && statusCand === "" && jobFunction === "" && language === "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.candidate_experiences.find((e) => e.title.toLowerCase().includes(jobTitle.toLowerCase())) && 
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language === "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.status.toLowerCase().includes(statusCand)
        );
      });
    } else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
           
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) 
        );
      });
    } else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language)
        );
      });
    } 
    
      else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language !== "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.languages.includes(language)
        );
      });
    } else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language !== "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand !== "" && jobFunction === "" && language === "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.status.toLowerCase().includes(statusCand) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language === "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction))
        );
      });
    } else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language !== "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) 
        );
      });
    } 
      
      else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language !== "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) && 
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction !== "" && language === "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.candidate_experiences.find((e) => e.job_function.includes(jobFunction)) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language !== "" && industryCand ==="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.languages.includes(language)
        );
      });
    } else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language !== "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.languages.includes(language) &&
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    } else if (searchVal === "" && compName === "" && jobTitle === "" && statusCand === "" && jobFunction === "" && language === "" && industryCand !=="") {
     
      newList = candidateList.filter((candidate) => {

        

        return(
          
          candidate.candidate_experiences.find((e) => e.industry.includes(industryCand))
        );
      });
    }    
    else{
      newList = candidateList;
    }

    dispatch(setAllListCandidate(newList));
  }

  const handleChangeFilter = (type) => (e) => {
    // console.log("e", e.target.value)
    // dispatch(storePage(0));

    if (type === "status") {
      dispatch(setStoreCandFilter({
        ddlStatus: e.target.value,
      }));
    } else if (type === "job") {
      dispatch(setStoreCandFilter({
        txtjobtitle: e.target.value,
      }));
    } else if (type === "company") {
      dispatch(setStoreCandFilter({
        txtcompName: e.target.value,
      }));
    } else if (type === "jobfunc") {
      dispatch(setStoreCandFilter({
        ddlJobFunction: e.target.value,
      }));
    } else if (type === "language") {
      dispatch(setStoreCandFilter({
        ddlLanguage: e.target.value,
      }));
    } else if (type === "industry") {
      dispatch(setStoreCandFilter({
        ddlIndustry: e.target.value,
      }));
    }

  };

  // button event
  const btnCollapse = (index) => {
    if (clicked === index) {
      //if clicked is already active, then close
      return setClicked(null);
    }

    setClicked(index);
  };

  const btnReset = () => {
    document.getElementById("filter-cand-form").reset();
    dispatch(
      setStoreCandFilter({
        ddlStatus: "",
        txtcompName: "",
        txtjobtitle: "",
        ddlJobFunction: "",
        ddlLanguage: "",
        ddlIndustry: ""
      })
    );
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {

    if (candFilter.txtcompName !== "" || candFilter.txtjobtitle !== "" || candFilter.ddlStatus !== "") {
      setClicked(true);
    } else {
      setClicked(false);
    }
    // searchVal, compName, jobTitle, statusCand, jobFunction, language, industryCand

    dispatch(getCandidateList(candFilter.txtsearchCand, candFilter.txtcompName, candFilter.txtjobtitle, candFilter.ddlStatus, candFilter.ddlJobFunction, candFilter.ddlLanguage, candFilter.ddlIndustry));
    dispatch(storeTitle({ title: "List of Candidates", icon: mdiAccountSearch }));

    setTimeout(() => setLoading(false), 2000);
  }, [dispatch]);

  return (
    <>
      {candidateList.length === 0 ?
        loading ?
          <div id="main-overlay" className="main-overlay">
            <Spinner animation="border" variant="light" />
          </div> : ""
        : ""}

      <div className="main-div">
        <div className="top-title-div cdt-page">
          <div className="filter_container">
            <div className="filter_container_search">
              <div className="filter_container_search-1">
                <TextboxIcon
                  onChange={(event) => handleSearch(event)}
                  name="txtsearchCand"
                  type="text"
                  placeholder="Search by candidate name"
                  inputSize="input--medium"
                  icon={mdiMagnify}
                  value={candFilter.txtsearchCand}
                ></TextboxIcon>
              </div>
              <div className="filter_container_search-1"></div>
              <div className="filter_container_search-1"></div>
            </div>

            <div className="filter_container_typelist">
              <div className="form-container-filter-button">
                {candSelected === 0 ? (
                  <Button
                    variant="primary"
                    className="btn-plus"
                    onClick={btnAddCandidate}
                    hidden={!abilities.includes("add candidate")}
                  >
                    <Icon path={mdiPlus} />
                  </Button>
                ) : (
                  ""
                )}
                {/* {candSelected === 0 ? (
                  <Button variant="secondary" onClick={() => btnCollapse(true)}>
                    <Icon path={mdiFilter} size={0.8} />
                    Filter
                  </Button>
                ) : (
                  ""
                )} */}
                {candSelected === 2 ? (
                  <Button
                    variant="secondary"
                    onClick={btnOpenMerge}
                    hidden={!abilities.includes("merge candidate")}
                  >
                    <Icon path={mdiMerge} />
                    <div>Merge</div>
                  </Button>
                ) : (
                  ""
                )}

                {candSelected > 0 ? (
                  <Button
                    variant="danger"
                    hidden={!abilities.includes("delete candidate")}
                    onClick={btnDeleteMultiple()}
                  >
                    <Icon path={mdiDelete} />
                    <div>Delete</div>
                  </Button>
                ) : (
                  ""
                )}

                <Button variant="secondary" onClick={() => btnCollapse(true)}>
                  <Icon path={mdiFilter} />
                  <div>Filter</div>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div id="demo" className={"collapses" + (clicked ? " in" : "")}>
          <Form id="filter-cand-form" onSubmit={btnFilter}>
            <div className="form-container-filter">
              <Col xs={4}>
                <Form.Group>
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    name="txtcompName"
                    type="text"
                    value={candFilter.txtcompName}
                    onChange={handleChangeFilter("company")}
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group>
                  <Form.Label>Job Title</Form.Label>
                  <Form.Control
                    name="txtjobtitle"
                    type="text"
                    value={candFilter.txtjobtitle}
                    onChange={handleChangeFilter("job")}
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group>
                  <Form.Label>Job Function</Form.Label>
                  <Form.Select
                    name="ddlJobFunction"
                    value={candFilter.ddlJobFunction}
                    onChange={handleChangeFilter("jobfunc")}
                  >
                    <option value="">Select</option>
                    {dataJobFunction.map((jobfunc, index) => (
                      <option key={index} value={jobfunc.value}>
                        {jobfunc.value}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group>
                  <Form.Label>Language</Form.Label>
                  <Form.Select
                    name="ddlLanguage"
                    value={candFilter.ddlLanguage}
                    onChange={handleChangeFilter("language")}
                  >
                    <option value="">Select</option>
                    {dataLanguages.map((lang, index) => (
                      <option key={index} value={lang.value}>
                        {lang.value}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group>
                  <Form.Label>Industry</Form.Label>
                  <Form.Select
                    name="ddlIndustry"
                    value={candFilter.ddlIndustry}
                    onChange={handleChangeFilter("industry")}
                  >
                    <option value="">Select</option>
                    {dataIndustry.map((indus, index) => (
                      <option key={index} value={indus.value}>
                        {indus.value}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Select name="ddlStatus" value={candFilter.ddlStatus} onChange={handleChangeFilter("status")}>
                    <option value="">Select</option>
                    {statusCandidate.map((status, index) => (
                      <option key={index} value={status.value}>
                        {status.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </div>
          </Form>
          <div className="row-btn-bottom">
            <Button variant="secondary" onClick={btnReset}>
              Reset
            </Button>
            <Button variant="primary" type="submit" form="filter-cand-form">
              Search
            </Button>
          </div>
        </div>

        <div className="container-div list-cand">
          <CustomTable
            headers={headers}
            items={candidateAllList}
            paginate={candidateAllList.length}
            maxRows={15}
            pindex={true}
          ></CustomTable>
        </div>

        {/* Modal Merge */}
        <Popup
          show={showMerge}
          size="lg"
          backdrop="static"
          keyboard={false}
          onHide={btnCloseMerge}
          title="Merge Candidate"
          content={
            <div id="modal-box">
              <StepWizard nav={<NavDots />}>
                <About />
                <Education />
                <Experience />
                <References />
                <Additional />
              </StepWizard>
            </div>
          }
        />
      </div>
    </>
  );
}

export default ListCandidates;
