import React, { useState, useEffect, useCallback } from "react";
import { Button, Row, Form, Dropdown, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import AuthAbilities from "../../pages/Admin/Auth/AuthAbilities";
import Swal from "sweetalert2";
import FileSaver from "file-saver";
import Api from "../../core/services/api.service";
import Apiform from "../../core/services/apiform.service";

import Modal from "../Popup/ModalPopup";
import Table from "../Table/Table";

import {
  mdiFilePlus,
  mdiDotsHorizontal,
  mdiDownload,
  mdiDelete,
  mdiViewAgenda,
  mdiChevronLeft,
  mdiChevronRight,
} from "@mdi/js";
import Icon from "@mdi/react";

import GoogleDocsViewer from "react-google-docs-viewer";

// import { Document, Page, pdfjs } from "react-pdf";
// pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.min.js";
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export const TabResume = (props) => {
  const params = useParams();

  const abilities = AuthAbilities();

  const [showModal, setShowModal] = useState(false);
  const [modeModal, setModeModal] = useState({
    title: "",
    Button: "",
  });
  const [errorForm, setErrorForm] = useState({});
  const [listResume, setListResume] = useState([]);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [showPdf, setShowPdf] = useState(false);

  const [filePath, setFilePath] = useState("");

  const customSwal = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-okay",
      cancelButton: "btn btn-cancel",
      title: "swal-title",
      popup: "swal-popup",
    },
    buttonsStyling: false,
  });

  // Header Event
  const headerResume = [
    {
      Header: "No",
      maxWidth: 50,
      minWidth: 10,
      width: 40,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Resume",
      accessor: "file_name",
      maxWidth: 400,
      minWidth: 100,
      width: 400,
    },
    {
      Header: "Uploaded Date",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        const date = new Date(data.created_at);
        const longMonth = date.toLocaleString("en-us", { month: "long" });

        return (
          <div>
            {date.getDate()} {longMonth} {date.getFullYear()}
          </div>
        );
      },
    },
    {
      Header: "",
      accessor: "actions",
      maxWidth: 100,
      minWidth: 80,
      width: 80,
      Cell: ({ row }) => {
        const data = row.original;

        function btnDownload() {
          const format = data.file_path.split(".").splice(-1);
          FileSaver.saveAs(data.file_path, data.file_name + "." + format);
        }

        function btnPreview() {
          setFilePath(data.file_path);
          setShowPdf(true);

          setModeModal({
            title: "View Resume",
            button: "",
          });
        }

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={btnPreview}>
                  <Icon path={mdiViewAgenda} color="#467FD0" />
                  Preview Document
                </Dropdown.Item>
                <Dropdown.Item onClick={btnDownload}>
                  <Icon path={mdiDownload} color="#467FD0" />
                  Download Document
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={btnDelResume(data.id)}
                  hidden={!abilities.includes("delete resume")}
                >
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete Document
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];
  // End Header

  // Checking Form Error
  const formError = (object) => {
    const newErrors = {};

    // console.log("object", object);

    if (!object.txtfilename || object.txtfilename === "")
      newErrors.txtfilename = "Fill in the blank!";

    if (!object.txtfile.name || object.txtfile.name === "")
      newErrors.txtfile = "Fill in the blank!";

    return newErrors;
  };

  // Button Event
  const btnAddResume = (e) => {
    setModeModal({
      title: "Upload Resume",
      button: "Upload",
    });
    setShowModal(true);
    setErrorForm({});
  };

  const btnCloseResume = (e) => {
    setShowModal(false);
  };

  const btnClosePdf = () => {
    setShowPdf(false);
    setPageNumber(1);
  };

  const btnSaveUpload = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtRes = Object.fromEntries(data.entries());

    const newErrors = formError(dtRes);
    // console.log("sss",  dtRes.txtfile)

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      setErrorForm({});
      customSwal
        .fire({
          title: "Do you want to upload resume ?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();

            formData.append("file", dtRes.txtfile);
            formData.append("type_doc", "resume");
            formData.append("file_name", dtRes.txtfilename);

            Apiform.post("candidate/" + params.id + "/upload", formData)
              .then((response) => {
                getListDocuments();
                setShowModal(false);

                customSwal.fire({
                  title: "Upload Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("error upload resume", error);
              });
          }
        });
    }
  };

  const btnDelResume = (idres) => (e) => {
    // console.log("idres", idres)
    customSwal
      .fire({
        title: "Do you want to delete resume?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("candidate/resume/" + idres)
            .then((response) => {
              // let data = response.data;

              getListDocuments();

              customSwal.fire({
                title: "Delete Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error delete note", error);
            });
        }
      });
  };
  // End Button

  const getListDocuments = useCallback(() => {
    Api.get("candidate/" + params.id + "/listDocument")
      .then((response) => {
        let data = response.data;
        // console.log("data", data)

        // if (data.resume.length > 0) {
        setListResume(data.resume);
        // }
      })
      .catch((error) => {
        console.log("error getListDocuments", error);
      });
  }, [params]);

  useEffect(() => {
    getListDocuments();
  }, [getListDocuments]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function handleFile(event) {
    if (event.target.files[0].type !== "application/pdf") {
      event.target.value = null;
      setErrorForm({
        txtfile: "PDF only!",
      });
    }
  }

  return (
    <>
      <Card className="list-cli">
        <Card.Body>
          <div className="row-div">
            <div className="left-div"></div>
            <div className="right-div">
              <Button
                variant="link"
                className="modal-link"
                onClick={btnAddResume}
                hidden={!abilities.includes("upload resume")}
              >
                <Icon path={mdiFilePlus} />
                Upload Resume
              </Button>
            </div>
          </div>

          <Table headers={headerResume} items={listResume}></Table>
        </Card.Body>

        {/* Modal Resume */}
        <Modal
          show={showModal}
          size="lg"
          backdrop="static"
          keyboard={false}
          onHide={btnCloseResume}
          title={modeModal.title}
          content={
            <>
              <Form id="upload-res-form" onSubmit={btnSaveUpload}>
                <Row>
                  <Form.Group className="mb-3">
                    <Form.Label className="col-3">File Name</Form.Label>
                    <div className="col-9" style={{ display: "inline-flex" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <Form.Control
                          type="text"
                          name="txtfilename"
                          placeholder="Please enter file name"
                          isInvalid={!!errorForm.txtfilename}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorForm.txtfilename}
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3">
                    <Form.Label className="col-3">Upload File</Form.Label>

                    <div className="col-9" style={{ display: "inline-flex" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <Form.Control
                          name="txtfile"
                          type="file"
                          // accept="application/pdf"
                          // onChange={handleFile}
                          isInvalid={!!errorForm.txtfile}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorForm.txtfile}
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </Form.Group>
                </Row>
              </Form>
            </>
          }
          button={
            <>
              <Button variant="secondary" onClick={btnCloseResume}>
                Cancel
              </Button>
              <Button variant="primary" form="upload-res-form" type="submit">
                {modeModal.button}
              </Button>
            </>
          }
        />

        {/* Modal Pdf */}
        <Modal
          show={showPdf}
          // size="lg"
          backdrop="static"
          keyboard={false}
          onHide={btnClosePdf}
          title={modeModal.title}
          content={
            <div className="pdf-container">
              {/* <Document
                file={filePath}
                onLoadSuccess={onDocumentLoadSuccess}
                options={{
                  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
                  cMapPacked: true,
                }}
              >
                <Page pageNumber={pageNumber} />
              </Document>

              <div className="page-controls">
                <button
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                >
                  <Icon path={mdiChevronLeft} />
                </button>
                <span>
                  {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                </span>
                <button
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                >
                  <Icon path={mdiChevronRight} />
                </button>
              </div> */}

              <div style={{ width: "40vw", height: "75vh" }}>
                <GoogleDocsViewer
                  width="100%"
                  height="75vh"
                  fileUrl={filePath}
                />
              </div>
            </div>
          }
        />
      </Card>
    </>
  );
};
