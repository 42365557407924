import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector } from "react-redux";
import { Card } from "react-bootstrap";

require("highcharts/modules/exporting.js")(Highcharts);
require("highcharts/modules/export-data.js")(Highcharts);

export const TabStatistics = () => {

  const { staGender, staIndustries, staExperiences, staCountries } = useSelector((state) => state.statistics);

  const options = {
    chart: {
      type: "pie",
      borderRadius: 20,
      marginLeft: 0,
      marginRight: 0,
      spacingBottom: 20, // The space between the bottom edge of the chart and the content (plot area, axis title and labels, title, subtitle or legend in top position).
      spacingLeft: 0, // The space between the left edge of the chart and the content (plot area, axis title and labels, title, subtitle or legend in top position).
      spacingRight: 20, // The space between the right edge of the chart and the content (plot area, axis title and labels, title, subtitle or legend in top position).
      spacingTop: 20,
      // width: 560,
    },
    title: {
      align: "center", // The horizontal alignment of the title. Can be one of "left", "center" and "right".
      margin: 10, // The margin between the title and the plot area, or if a subtitle is present, the margin between the subtitle and the plot area.
      style: {
        fontSize: "24px",
        fontFamily: "Quicksand",
        fontWeight: "600",
        lineHeight: "30px",
      },
      text: "Gender",
    },
    series: [
      {
        name: "Gender",
        colorByPoint: true,
        data: staGender
      },
    ],
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          style: {
            fontFamily: "Quicksand",
            fontWeight: "500",
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
  };

  const industry = {
    chart: {
      type: "pie",
      borderRadius: 20,
      marginLeft: 0,
      marginRight: 0,
      spacingBottom: 20, // The space between the bottom edge of the chart and the content (plot area, axis title and labels, title, subtitle or legend in top position).
      spacingLeft: 0, // The space between the left edge of the chart and the content (plot area, axis title and labels, title, subtitle or legend in top position).
      spacingRight: 20, // The space between the right edge of the chart and the content (plot area, axis title and labels, title, subtitle or legend in top position).
      spacingTop: 20,
      // width: 560,
    },
    title: {
      align: "center", // The horizontal alignment of the title. Can be one of "left", "center" and "right".
      margin: 10, // The margin between the title and the plot area, or if a subtitle is present, the margin between the subtitle and the plot area.
      style: {
        fontSize: "24px",
        fontFamily: "Quicksand",
        fontWeight: "600",
        lineHeight: "30px",
      },
      text: "Industries",
    },
    series: [
      {
        name: "Industry",
        colorByPoint: true,
        data: staIndustries
      },
    ],
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          style: {
            fontFamily: "Quicksand",
            fontWeight: "500",
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
  };

  const experience = {
    chart: {
      type: "pie",
      borderRadius: 20,
      marginLeft: 0,
      marginRight: 0,
      spacingBottom: 20, // The space between the bottom edge of the chart and the content (plot area, axis title and labels, title, subtitle or legend in top position).
      spacingLeft: 0, // The space between the left edge of the chart and the content (plot area, axis title and labels, title, subtitle or legend in top position).
      spacingRight: 20, // The space between the right edge of the chart and the content (plot area, axis title and labels, title, subtitle or legend in top position).
      spacingTop: 20,
      // width: 560,
    },
    title: {
      align: "center", // The horizontal alignment of the title. Can be one of "left", "center" and "right".
      margin: 10, // The margin between the title and the plot area, or if a subtitle is present, the margin between the subtitle and the plot area.
      style: {
        fontSize: "24px",
        fontFamily: "Quicksand",
        fontWeight: "600",
        lineHeight: "30px",
      },
      text: "Years of Experience",
    },
    series: [
      {
        name: "Years of Experience",
        colorByPoint: true,
        data: staExperiences,
      },
    ],
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          style: {
            fontFamily: "Quicksand",
            fontWeight: "500",
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
  };

  const country = {
    chart: {
      type: "pie",
      borderRadius: 20,
      marginLeft: 0,
      marginRight: 0,
      spacingBottom: 20, // The space between the bottom edge of the chart and the content (plot area, axis title and labels, title, subtitle or legend in top position).
      spacingLeft: 0, // The space between the left edge of the chart and the content (plot area, axis title and labels, title, subtitle or legend in top position).
      spacingRight: 20, // The space between the right edge of the chart and the content (plot area, axis title and labels, title, subtitle or legend in top position).
      spacingTop: 20,
      // width: 560,
    },
    title: {
      align: "center", // The horizontal alignment of the title. Can be one of "left", "center" and "right".
      margin: 10, // The margin between the title and the plot area, or if a subtitle is present, the margin between the subtitle and the plot area.
      style: {
        fontSize: "24px",
        fontFamily: "Quicksand",
        fontWeight: "600",
        lineHeight: "30px",
      },
      text: "Countries",
    },
    series: [
      {
        name: "Country",
        colorByPoint: true,
        data: staCountries,
      },
    ],
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          style: {
            fontFamily: "Quicksand",
            fontWeight: "500",
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <Card>
      <Card.Body>
        <div className="chart-wrapper">
          <div className="chart-box">
            <div className="chart-container">
              <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
            <div className="chart-container">
              <HighchartsReact highcharts={Highcharts} options={industry} />
            </div>
          </div>
          <div className="chart-box">
            <div className="chart-container">
              <HighchartsReact highcharts={Highcharts} options={experience} />
            </div>
            <div className="chart-container">
              <HighchartsReact highcharts={Highcharts} options={country} />
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

// const combineChart = {
//   chart: {
//     defaultSeriesType: "pie",
//     // width: 600,
//     height: "65%",
//   },
//   title: {
//     text: "",
//   },
//   series: [
//     {
//       // type: "pie",
//       name: "Gender",
//       colorByPoint: true,
//       data: [
//         {
//           name: "Male",
//           y: 74.77,
//         },
//         {
//           name: "Female",
//           y: 12.82,
//         },
//         {
//           name: "Others",
//           y: 12.82,
//         },
//       ],
//       size: 200,
//       center: [220, 150],
//     },
//     {
//       // type: "pie",
//       name: "Industry",
//       colorByPoint: true,
//       data: [
//         {
//           name: "Accounting",
//           y: 20,
//         },
//         {
//           name: "Human Resource",
//           y: 20,
//         },
//         {
//           name: "Agriculture",
//           y: 20,
//         },
//         {
//           name: "Oil & Gas",
//           y: 20,
//         },
//         {
//           name: "Information Technology",
//           y: 20,
//         },
//       ],
//       size: 200,
//       center: [800, 150],
//     },
//     {
//       // type: "pie",
//       name: "Experience",
//       colorByPoint: true,
//       data: [
//         {
//           name: "Less than 5 years",
//           y: 20,
//         },
//         {
//           name: "5 - 10 years",
//           y: 20,
//         },
//         {
//           name: "10 - 15 years",
//           y: 20,
//         },
//         {
//           name: "15 - 20 years",
//           y: 20,
//         },
//         {
//           name: "More than 20 years",
//           y: 20,
//         },
//       ],
//       size: 200,
//       center: [220, 550],
//     },
//     {
//       // type: "pie",
//       name: "Country",
//       colorByPoint: true,
//       data: [
//         {
//           name: "Singapore",
//           y: 20,
//         },
//         {
//           name: "Malaysia",
//           y: 20,
//         },
//         {
//           name: "China",
//           y: 20,
//         },
//         {
//           name: "India",
//           y: 20,
//         },
//         {
//           name: "United States",
//           y: 20,
//         },
//       ],
//       size: 200,
//       center: [800, 550],
//     },
//   ],
//   credits: {
//     enabled: false,
//   },
// };
