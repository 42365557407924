import React from "react";
import "./BreadCrumb.css";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";


function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return (

    <li className={`breadcrumb-btn ${props.disabled}`}>
      <button onClick={() => props.selected(props.value)}>{props.value}</button>
      {props.disabled ? null : <><Icon path={mdiChevronRight} /></>}
    </li>
    
  );
}

function BreadCrumb(props) {
  function isLast(index) {
    return index === props.crumbs.length - 1;
  }

  const desccrumb = props.crumbs;

  const listItems = desccrumb.map((crmb, ci) =>{
    const disabled = isLast(ci) ? "disabled" : "";
    // Correct! Key should be specified inside the array.
    return(
      <div key={ci}>
        <ListItem key={crmb} value={crmb} selected={props.selected} disabled={disabled}  />
      </div>
      
    );
  }
  );


  return (
    <nav>
      <ol className="breadcrumb-div">
        {listItems}
      </ol>
    </nav>
   
  );
}

export default BreadCrumb;