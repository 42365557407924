import React from "react";
import { useNavigate } from "react-router-dom";
import AccessImg from "../../assets/access.jpg";

function Error() {
  
  return (
    <div className="main-div" style={{ height: "100vh" }}>
      <div className="verify-container" style={{ boxShadow: "none" }}>
        <img src={AccessImg} alt="No Access" width={500} />
        <div className="verify-text">
          <span style={{ fontSize: "24px", fontWeight: 700 }}>
            We are sorry,{" "}
          </span>
          <br />
          Network Error
        </div>
      </div>
    </div>
  );
}

export default Error