import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Row, Button, Col } from "react-bootstrap";
import BreadCrumb from "../../../../components/Breadcrumb/BreadCrumb";
import RichTextEditor from "../../../../components/RichTextArea/RichTextEditor";

import Icon from "@mdi/react";
import { mdiArrowLeft } from "@mdi/js";

function ViewPageManagement() {
  const navigate = useNavigate();

  const [crumbs] = useState([]);

  return (
    <div className="main-div view-pagemgt">
      <div className="container-div both-side">
        <BreadCrumb crumbs={crumbs}></BreadCrumb>
        <Button variant="secondary" onClick={() => navigate(-1)}>
          <Icon path={mdiArrowLeft} />
          Back
        </Button>
      </div>

      <div className="bg-white-container">
        <Form id="update-page-form">
          <Row>
            <Form.Group as={Row} className="mb-3">
              <Form.Label className="col-2" style={{ marginTop: "10px" }}>
                Page Name
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  name="pagename"
                  placeholder="Please enter page name"
                />
              </Col>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Row} className="mb-3">
              <Form.Label className="col-2" style={{ marginTop: "10px" }}>
                Content
              </Form.Label>
              <Col sm="10">
                <RichTextEditor name="page-content" />
              </Col>
            </Form.Group>
          </Row>

          <div className="row-btn-bottom">
            {/* <Button variant="secondary">Deselect All</Button> */}
            <Button variant="primary" type="submit" form="update-page-form">
              Update Page
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default ViewPageManagement;
