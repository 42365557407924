import {createSlice} from '@reduxjs/toolkit';


const initialState = {
    menuAsgnCrumb:null
}

const breadAsgnSlice = createSlice({
    name: 'breadcrumb',
    initialState,
    reducers:{
        storeAsgnMenu: (state, action) => {
            state.menuAsgnCrumb = action.payload;
        },
    },
})

const {reducer, actions} = breadAsgnSlice;

export const { storeAsgnMenu } = actions;

export default reducer;