import React, { useState, useEffect, useCallback } from "react";
import { Card, Button, Form, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import FileSaver from "file-saver";
import Api from "../../core/services/api.service";
import Apiform from "../../core/services/api.service";

import SwalAlert from "../../components/Swal/SwalAlert";

import Icon from "@mdi/react";
import { mdiDownload, mdiFilePlus } from "@mdi/js";

import { CustomTable } from "../../components/Table/Table";
import Popup from "../../components/Popup/ModalPopup";
import Modal from "../../components/Popup/ModalPopup";

const Document = () => {
  const { user } = useSelector((state) => state.login);

  const customSwal = SwalAlert();

  const [showModalDoc, setShowModalDoc] = useState(false);
  const [typeDoc, setTypeDoc] = useState("");
  const [modeModal, setModeModal] = useState({
    title: "",
    Button: "",
  });
  const [errorForm, setErrorForm] = useState({});

  const [showUpload, setShowUpload] = useState(false);
  const [showUploadDoc, setShowUploadDoc] = useState(false);

  const btnCloseUpload = () => {
    setShowUpload(false);
  };

  const btnCloseUploadDoc = () => {
    setShowUploadDoc(false);
  };

  const btnAddDoc = (type) => (e) => {
    setShowModalDoc(true);
    setCbAgree(false);
    setErrorCbAgree(false);
    setTypeDoc(type);

    if (type === "payslip") {
      setModeModal({
        title: "Upload Payslips",
        button: "Upload",
      });
    } else if (type === "supporting") {
      setModeModal({
        title: "Upload Supporting Document",
        button: "Upload",
      });
    } else if (type === "resume") {
      setModeModal({
        title: "Upload Resume",
        button: "Upload",
      });
    }

    setErrorForm({});
  };

  const btnCloseResume = (e) => {
    setShowModalDoc(false);
    setCbAgree(false);
    setErrorCbAgree(false);
  };

  const [cbAgree, setCbAgree] = useState(false);
  const [errorCbAgree, setErrorCbAgree] = useState(false);

  const handleCheckAgree = (e) => {
    let checkag = e.target.checked;

    setCbAgree(e.target.checked);

    checkag ? setErrorCbAgree(false) : setErrorCbAgree(true);
  };

  const btnSaveUpload = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtRes = Object.fromEntries(data.entries());

    // console.log("cbagree", cbAgree)

    const newErrors = formError(dtRes, cbAgree);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else if(cbAgree === false){
      setErrorCbAgree(true);
    }else {
      customSwal
        .fire({
          title: "Do you want to " + modeModal.title.toLocaleLowerCase() +"?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();

            formData.append("file", dtRes.txtfile);
            formData.append("type_doc", typeDoc);
            formData.append("file_name", dtRes.txtfilename);

            Apiform.post("candidate/" + user.sub + "/upload", formData)
              .then((response) => {
                getListDocuments();
                setShowModalDoc(false);

                customSwal.fire({
                  title: "Upload Successful!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("error upload resume", error);
              });
          }
        });
    }
  };

  // Checking Form Error
  const formError = (object, tickagree) => {
    const newErrors = {};

    if (!object.txtfilename || object.txtfilename === "")
      newErrors.txtfilename = "Fill in the blank!";

    if (!object.txtfile.name || object.txtfile.name === "")
      newErrors.txtfile = "Fill in the blank!";

    if(tickagree === false)
      setErrorCbAgree(true);

    return newErrors;
  };

  const headerResume = [
    {
      Header: "No",
      maxWidth: 50,
      minWidth: 10,
      width: 40,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "File Name",
      accessor: "file_name",
      maxWidth: 400,
      minWidth: 100,
      width: 300,
    },
    {
      Header: "Upload Date",
      Cell: ({ row }) => {
        const data = row.original;

        const date = new Date(data.created_at);
        const longMonth = date.toLocaleString("en-us", { month: "long" });

        return (
          <div>
            {date.getDate()} {longMonth} {date.getFullYear()}
          </div>
        );
      },
    },
    {
      Header: "Actions",
      maxWidth: 200,
      minWidth: 160,
      width: 160,
      Cell: ({ row }) => {
        const data = row.original;

        function btnDownload() {
          FileSaver.saveAs(data.file_path, "download.pdf");
        }

        return (
          <>
            <Button
              variant="link"
              className="reminder-btn set-rem"
              onClick={btnDownload}
            >
              <Icon path={mdiDownload} color="#467FD0" />
              Download
            </Button>
          </>
        );
      },
    },
  ];

  const headerPayslip = [
    {
      Header: "No",
      maxWidth: 50,
      minWidth: 10,
      width: 40,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "File Name",
      accessor: "file_name",
      maxWidth: 400,
      minWidth: 100,
      width: 300,
    },
    {
      Header: "Upload Date",
      Cell: ({ row }) => {
        const data = row.original;

        const date = new Date(data.created_at);
        const longMonth = date.toLocaleString("en-us", { month: "long" });

        return (
          <div>
            {date.getDate()} {longMonth} {date.getFullYear()}
          </div>
        );
      },
    },
    {
      Header: "Actions",
      maxWidth: 200,
      minWidth: 160,
      width: 160,
      Cell: ({ row }) => {
        const data = row.original;

        function btnDownPayslip() {
          FileSaver.saveAs(data.file_path_upload);
        }

        return (
          <>
            <Button
              variant="link"
              className="reminder-btn set-rem"
              onClick={btnDownPayslip}
            >
              <Icon path={mdiDownload} color="#467FD0" />
              Download
            </Button>
          </>
        );
      },
    },
  ];

  const headerDocument = [
    {
      Header: "No",
      maxWidth: 50,
      minWidth: 10,
      width: 40,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "File Name",
      accessor: "file_name",
      maxWidth: 400,
      minWidth: 100,
      width: 300,
    },
    {
      Header: "Upload Date",
      Cell: ({ row }) => {
        const data = row.original;

        const date = new Date(data.created_at);
        const longMonth = date.toLocaleString("en-us", { month: "long" });

        return (
          <div>
            {date.getDate()} {longMonth} {date.getFullYear()}
          </div>
        );
      },
    },
    {
      Header: "Actions",
      maxWidth: 200,
      minWidth: 160,
      width: 160,
      Cell: ({ row }) => {
        const data = row.original;

        function btnDownSupport() {
          FileSaver.saveAs(data.file_path_upload);
        }

        return (
          <>
            <Button
              variant="link"
              className="reminder-btn set-rem"
              onClick={btnDownSupport}
            >
              <Icon path={mdiDownload} color="#467FD0" />
              Download
            </Button>
          </>
        );
      },
    },
  ];

  const [listResume, setListResume] = useState([]);
  const [listPayslip, setListPayslip] = useState([]);
  const [listSupporting, setListSupporting] = useState([]);

  const getListDocuments = useCallback(() => {
    Api.get("candidate/" + user.sub + "/listDocument")
      .then((response) => {
        let data = response.data;

        // Resume List
        if (data.resume.length > 0) {
          let sortResume = data.resume.sort(function (a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
          });

          setListResume(sortResume.slice(0, 1));
        } else {
          setListResume([]);
        }

        // Payslip List
        if (data.payslip.length > 0) {
          let sortPaySlip = data.payslip.sort(function (a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
          });

          setListPayslip(sortPaySlip);
        } else {
          setListPayslip([]);
        }

        // Supporting List
        if (data.supporting.length > 0) {
          let sortSupporting = data.supporting.sort(function (a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
          });

          setListSupporting(sortSupporting);
        } else {
          setListSupporting([]);
        }
      })
      .catch((error) => {
        console.log("error getListDocuments", error);
      });
  }, [user.sub]);

  useEffect(() => {
    getListDocuments();
  }, [getListDocuments]);

  return (
    <div className="main-div list-cli">
      {/* <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <div id="cal-overlay" className="calendar-overlay">
          {" "}
      </div> */}
      <Card style={{ marginBottom: "20px" }}>
        <Card.Body>
          {listResume.length === 0 ? (
            <>
              <div className="title-text">Resume</div>
              <div className="resume-section">
                <div className="sub-text">
                  You have not upload your resume yet.
                </div>
                <Button variant="info" onClick={btnAddDoc("resume")}>
                  Upload Now
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="both-side">
                <div className="title-text">Resume</div>
                <Button
                  variant="link"
                  className="modal-link"
                  onClick={btnAddDoc("resume")}
                >
                  <Icon path={mdiFilePlus} />
                  Upload Resume
                </Button>
              </div>

              <CustomTable
                headers={headerResume}
                items={listResume}
                paginate={listResume.length}
                maxRows={3}
              ></CustomTable>
            </>
          )}
        </Card.Body>
      </Card>

      <Card style={{ marginBottom: "20px" }}>
        <Card.Body>
          <div className="both-side">
            <div className="title-text">Payslips</div>
            <Button
              variant="link"
              className="modal-link"
              onClick={btnAddDoc("payslip")}
            >
              <Icon path={mdiFilePlus} />
              Upload Payslip
            </Button>
          </div>

          <Popup
            show={showUpload}
            size="lg"
            backdrop="static"
            keyboard={false}
            onHide={btnCloseUpload}
            title={modeModal.title}
            content={
              <>
                <Row>
                  <Form.Group className="mb-3 col-6">
                    <Form.Label>
                      Payslip Name <label className="validation-star">*</label>
                    </Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>
                  <Form.Group className="mb-3 col-6">
                    <Form.Label>Upload Date</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="14 July 2022"
                      disabled
                      readOnly
                    />
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Upload Payslip{" "}
                      <label className="validation-star">*</label>
                    </Form.Label>
                    <Form.Control type="file" />
                  </Form.Group>
                </Row>
              </>
            }
            button={
              <>
                <Button variant="secondary" onClick={btnCloseUpload}>
                  Cancel
                </Button>
                <Button variant="primary">{modeModal.button}</Button>
              </>
            }
          />

          {/* {listPayslip.length > 0 ? ( */}
          <CustomTable
            headers={headerPayslip}
            items={listPayslip}
            paginate={listPayslip.length}
            maxRows={3}
          ></CustomTable>
          {/* ) : (
            <Empty />
          )} */}
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <div className="both-side">
            <div className="title-text">Supporting Documents</div>
            <Button
              variant="link"
              className="modal-link"
              onClick={btnAddDoc("supporting")}
            >
              <Icon path={mdiFilePlus} />
              Upload Document
            </Button>
          </div>

          <Popup
            show={showUploadDoc}
            size="lg"
            backdrop="static"
            keyboard={false}
            onHide={btnCloseUploadDoc}
            title={modeModal.title}
            content={
              <>
                <Row>
                  <Form.Group className="mb-3 col-6">
                    <Form.Label>
                      Document Name <label className="validation-star">*</label>
                    </Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>
                  <Form.Group className="mb-3 col-6">
                    <Form.Label>Upload Date</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="14 July 2022"
                      disabled
                      readOnly
                    />
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Upload Document
                      <label className="validation-star">*</label>
                    </Form.Label>
                    <Form.Control type="file" />
                  </Form.Group>
                </Row>
              </>
            }
            button={
              <>
                <Button variant="secondary" onClick={btnCloseUploadDoc}>
                  Cancel
                </Button>
                <Button variant="primary">{modeModal.button}</Button>
              </>
            }
          />

          {/* {listSupporting.length > 0 ? ( */}
          <CustomTable
            headers={headerDocument}
            items={listSupporting}
            paginate={listSupporting.length}
            maxRows={3}
          ></CustomTable>
          {/* ) : (
            <Empty />
          )} */}
        </Card.Body>
      </Card>

      {/* Modal Resume */}
      <Modal
        show={showModalDoc}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseResume}
        title={modeModal.title}
        content={
          <>
            <Form id="upload-res-form" onSubmit={btnSaveUpload}>
              <Row>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="col-3">File Name</Form.Label>
                  <Col sm="9">
                    <Form.Control
                      type="text"
                      name="txtfilename"
                      placeholder="Please enter file name"
                      isInvalid={!!errorForm.txtfilename}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorForm.txtfilename}
                    </Form.Control.Feedback>
                  </Col>
                  
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="col-3">Upload File</Form.Label>
                  <Col sm="9">
                    <Form.Control
                      name="txtfile"
                      type="file"
                      isInvalid={!!errorForm.txtfile}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorForm.txtfile}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  as={Row}
                  className="mt-3 consent-check"
                  style={{
                    textAlign: "justify",
                    wordBreak: "break-word",
                  }}
                >
                  <Form.Label className="col-3"></Form.Label>
                  <Col sm="9">
                    <Form.Check
                      name="cbConsent"
                      type="checkbox"
                      label="By selecting this checkbox, I agree that I have read the privacy policy and consent to the given information being used by Lim-Loges & Masters."
                      onChange={handleCheckAgree}
                    />
                    {errorCbAgree ? <div style={{fontSize: "12px", color: "red"}}>* Please tick agreement</div> : <div></div>}
                  </Col>
                </Form.Group>
              </Row>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseResume}>
              Cancel
            </Button>
            <Button variant="primary" form="upload-res-form" type="submit">
              {modeModal.button}
            </Button>
          </>
        }
      />
      
    </div>
  );
};

export default Document;
