import React, { useState, useEffect, useCallback } from "react";
import { Button, Card, Form, Row, Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Api from "../../core/services/api.service";
import Apiform from "../../core/services/apiform.service";

import AuthAbilities from "../../pages/Admin/Auth/AuthAbilities";
import FileSaver from "file-saver";
import SwalAlert from "../Swal/SwalAlert";

import Icon from "@mdi/react";
import {
  mdiFilePlus,
  mdiDownload,
  mdiUpload,
  mdiViewAgenda,
  mdiDotsHorizontal,
  mdiDelete
} from "@mdi/js";

import Table from "../Table/Table";
import Modal from "../Popup/ModalPopup";
import Viewer from "../Viewer/Viewer";

export const TabDocuments = () => {
  const params = useParams();

  const abilities = AuthAbilities();

  const [showModalDoc, setShowModalDoc] = useState(false);
  const [errorForm, setErrorForm] = useState({});
  const [typeDoc, setTypeDoc] = useState("");
  const [modeModal, setModeModal] = useState({
    title: null,
    button: null,
  });

  const [listPayslip, setListPayslip] = useState([]);
  const [listSupporting, setListSupporting] = useState([]);
  const [listAttachment, setListAttachment] = useState([]);

  // PDF useState
  const [pageNumber, setPageNumber] = useState(1);
  const [showPdf, setShowPdf] = useState(false);
  const [filePath, setFilePath] = useState("");

  const customSwal = SwalAlert();

  // Header Event
  const headerPayslip = [
    {
      Header: "No",
      maxWidth: 50,
      minWidth: 10,
      width: 40,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Document Name",
      accessor: "file_name",
      maxWidth: 400,
      minWidth: 100,
      width: 400,
    },
    {
      Header: "Received Date",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        const date = new Date(data.created_at);
        const longMonth = date.toLocaleString("en-us", { month: "long" });

        return (
          <div>
            {date.getDate()} {longMonth} {date.getFullYear()}
          </div>
        );
      },
    },
    {
      Header: " ",
      minWidth: 80,
      width: 80,
      Cell: ({ row }) => {
        const data = row.original;

        function btnDownPayslip() {
          FileSaver.saveAs(data.file_path_upload);
        }

        function btnPreview() {
          setFilePath(data.file_path_upload);
          setShowPdf(true);

          setModeModal({
            title: "View Payslips",
            button: "",
          });
        }

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={btnPreview}>
                  <Icon path={mdiViewAgenda} color="#467FD0" />
                  Preview Document
                </Dropdown.Item>
                <Dropdown.Item onClick={btnDownPayslip}>
                  <Icon path={mdiDownload} color="#467FD0" />
                  Download File
                </Dropdown.Item>
                <Dropdown.Item>
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete File
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const headerDocument = [
    {
      Header: "No",
      maxWidth: 50,
      minWidth: 10,
      width: 40,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Document Name",
      accessor: "file_name",
      maxWidth: 400,
      minWidth: 100,
      width: 400,
    },
    {
      Header: "Received Date",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        const date = new Date(data.created_at);
        const longMonth = date.toLocaleString("en-us", { month: "long" });

        return (
          <div>
            {date.getDate()} {longMonth} {date.getFullYear()}
          </div>
        );
      },
    },
    {
      Header: " ",
      minWidth: 80,
      width: 80,
      Cell: ({ row }) => {
        const data = row.original;

        function btnDownPayslip() {
          FileSaver.saveAs(data.file_path_upload);
        }

        function btnPreview() {
          setFilePath(data.file_path_upload);
          setShowPdf(true);

          setModeModal({
            title: "View Supporting Documents",
            button: "",
          });
        }

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={btnPreview}>
                  <Icon path={mdiViewAgenda} color="#467FD0" />
                  Preview Document
                </Dropdown.Item>
                <Dropdown.Item onClick={btnDownPayslip}>
                  <Icon path={mdiDownload} color="#467FD0" />
                  Download File
                </Dropdown.Item>
                <Dropdown.Item>
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete File
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const headerAttachment = [
    {
      Header: "No",
      minWidth: 10,
      width: 40,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Document Name",
      accessor: "file_name",
      minWidth: 100,
      width: 400,
    },
    {
      Header: "Upload Attachment",
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        function btnDownPayslip() {
          FileSaver.saveAs(data.file_path_upload);
        }

        return (
          <>
            <Button
              variant="link"
              className="reminder-btn set-rem"
              onClick={btnDownPayslip}
            >
              <Icon path={mdiDownload} color="#467FD0" />
              Download
            </Button>
          </>
        );
      },
    },
    {
      Header: "Upload Date",
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        const date = new Date(data.created_at);
        const longMonth = date.toLocaleString("en-us", { month: "long" });

        return (
          <div>
            {date.getDate()} {longMonth} {date.getFullYear()}
          </div>
        );
      },
    },
    {
      Header: "Received Attachment",
      minWidth: 100,
      width: 220,
      Cell: ({ row }) => {
        const data = row.original;

        function btnDownAttach() {
          FileSaver.saveAs(data.file_path_received);
        }

        return (
          <>
            {data.file_path_received ? (
              <>
                <Button
                  variant="link"
                  className="reminder-btn set-rem"
                  onClick={btnDownAttach}
                >
                  <Icon path={mdiDownload} color="#467FD0" />
                  Download
                </Button>
              </>
            ) : (
              <div className="has-file-upload">
                <Button variant="link" className="reminder-btn set-rem">
                  <Icon path={mdiUpload} color="#467FD0" />
                  Upload
                </Button>
              </div>
            )}
          </>
        );
      },
    },
    {
      Header: "Received Date",
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        const date = new Date(data.received_date);
        const longMonth = date.toLocaleString("en-us", { month: "long" });

        return (
          <div>
            {data.received_date
              ? date.getDate() + " " + longMonth + " " + date.getFullYear()
              : "-"}
          </div>
        );
      },
    },
  ];

  const btnClosePdf = () => {
    setShowPdf(false);
    setPageNumber(1);
  };

  // Checking Form Error
  const formError = (object) => {
    const newErrors = {};

    if (!object.txtfilename || object.txtfilename === "")
      newErrors.txtfilename = "Fill in the blank!";

    if (!object.txtfile.name || object.txtfile.name === "")
      newErrors.txtfile = "Fill in the blank!";

    return newErrors;
  };

  // Button Event
  const btnAddDoc = (type) => (e) => {
    setShowModalDoc(true);
    setTypeDoc(type);

    if (type === "payslip") {
      setModeModal({
        title: "Upload Payslips",
        button: "Upload",
      });
    } else if (type === "supporting") {
      setModeModal({
        title: "Upload Supporting Document",
        button: "Upload",
      });
    } else if (type === "attachment") {
      setModeModal({
        title: "Upload Attachment",
        button: "Upload",
      });
    }

    setErrorForm({});
  };

  const btnCloseDoc = () => {
    setShowModalDoc(false);
  };

  const btnSaveUpload = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtDoc = Object.fromEntries(data.entries());

    const newErrors = formError(dtDoc);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      setErrorForm({});
      customSwal
        .fire({
          title: "Do you want to " + modeModal.title + " ?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();

            formData.append("file", dtDoc.txtfile);
            formData.append("type_doc", typeDoc);
            formData.append("file_name", dtDoc.txtfilename);

            Apiform.post("candidate/" + params.id + "/upload", formData)
              .then((response) => {
                setShowModalDoc(false);

                getListDocuments();

                customSwal.fire({
                  title: "Upload Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("error upload payslip", error);
              });
          }
        });
    }
  };

  const getListDocuments = useCallback(() => {
    Api.get("candidate/" + params.id + "/listDocument")
      .then((response) => {
        let data = response.data;

        // console.log("getListDocuments", data);

        setListPayslip(data.payslip);
        setListSupporting(data.supporting);

        // Attachment List
        if (data.attachment.length > 0) {
          let sortAttachment = data.attachment.sort(function (a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
          });

          setListAttachment(sortAttachment);
        } else {
          setListAttachment([]);
        }
      })
      .catch((error) => {
        console.log("error getListDocuments", error);
      });
  }, [params]);

  useEffect(() => {
    getListDocuments();
  }, [getListDocuments]);

  return (
    <>
      {/* <div className="tab-pane-div"> */}
      {/* PaySlip */}
      <Card style={{ marginBottom: "20px" }}>
        <Card.Body>
          <div className="both-side">
            <div className="title-text report">Payslips</div>
            <Button
              variant="link"
              className="modal-link"
              onClick={btnAddDoc("payslip")}
              hidden={!abilities.includes("upload payslip")}
            >
              <Icon path={mdiFilePlus} />
              Upload Payslip
            </Button>
          </div>

          <Table headers={headerPayslip} items={listPayslip}></Table>
        </Card.Body>
      </Card>

      {/* Support Document */}
      <Card style={{ marginBottom: "20px" }}>
        <Card.Body>
          <div className="both-side">
            <div className="title-text report">Supporting Documents</div>
            <Button
              variant="link"
              className="modal-link"
              onClick={btnAddDoc("supporting")}
              hidden={!abilities.includes("upload supporting document")}
            >
              <Icon path={mdiFilePlus} />
              Upload Document
            </Button>
          </div>

          <Table headers={headerDocument} items={listSupporting}></Table>
        </Card.Body>
      </Card>

      {/* Attachment */}
      <Card>
        <Card.Body>
          <div className="both-side">
            <div className="title-text report">Attachments</div>
            <Button
              variant="link"
              className="modal-link"
              onClick={btnAddDoc("attachment")}
              hidden={!abilities.includes("upload attachment")}
            >
              <Icon path={mdiFilePlus} />
              Upload Attachment
            </Button>
          </div>

          <Table headers={headerAttachment} items={listAttachment}></Table>
        </Card.Body>
      </Card>
      {/* </div> */}

      {/* Modal Resume */}
      <Modal
        show={showModalDoc}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseDoc}
        title={modeModal.title}
        content={
          <>
            <Form id="upload-doc-form" onSubmit={btnSaveUpload}>
              <Row>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="col-3">File Name</Form.Label>
                  <div className="col-9" style={{ display: "inline-flex" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Form.Control
                        type="text"
                        name="txtfilename"
                        placeholder="Please enter file name"
                        isInvalid={!!errorForm.txtfilename}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorForm.txtfilename}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="col-3">Upload File</Form.Label>
                  <div className="col-9" style={{ display: "inline-flex" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Form.Control
                        name="txtfile"
                        type="file"
                        isInvalid={!!errorForm.txtfile}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorForm.txtfile}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </Form.Group>
              </Row>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseDoc}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" form="upload-doc-form">
              {modeModal.button}
            </Button>
          </>
        }
      />

      {/* Modal Pdf */}
      <Viewer
        showPdf={showPdf}
        btnClosePdf={btnClosePdf}
        modalTitle={modeModal.title}
        filePath={filePath}
      />
    </>
  );
};
