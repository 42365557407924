import {createSlice} from '@reduxjs/toolkit';
// import axios from "axios";
// import JwtService from "../jwt.services";

const initialState = {
  isLoading:false,
  isAuth:false,
  error:'',
  user:null
}

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        loginPending: (state) => {
            state.isLoading=true
        },
        loginSuccess: (state, action) => {
            state.isLoading=false;
            state.isAuth=true;
            state.error='';
            state.user=action.payload;
        },
        loginFail: (state, {payload}) => {
            state.isLoading=false;
            state.error=payload;
        }

    }
})

const {reducer, actions} = loginSlice;

export const { loginPending, loginSuccess, loginFail } = actions;

export default reducer;

// export const loginSlice = createSlice({
//     name: 'user',
//     initialState: {
//         user:null
//     },
//     reducers:{
//         login: (state, action) => {
//             state.user = action.payload;

//             console.log("state.user", state.user)

//              axios.post('http://localhost:3333/auth/login', {
//               email: state.user.email,
//               password: state.user.password
//             })
//             .then(response => {
//               console.log("Response", response.data.access_token)
//               JwtService.saveToken(response.data.access_token)
//             })
//             .catch(err => {
//               console.log(err);
//             });
//         },
//         logout: (state) => {
//             state.user = null;
//         }
//     },
    
// });

// export const { login, logout } = loginSlice.actions;

// export const selectUser = (state) => state.user.user;

// export default loginSlice.reducer;