import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button, Tab, Nav, Card, Form, Row } from "react-bootstrap";
import Api from "../../../core/services/api.service";
import AuthAbilities from "../Auth/AuthAbilities";

import moment from "moment";
import SwalAlert from "../../../components/Swal/SwalAlert";

import Icon from "@mdi/react";
import { mdiFileDocumentMultiple, mdiAccountGroup } from "@mdi/js";

import BreadCrumb from "../../../components/Breadcrumb/BreadCrumb";
import DropDown from "../../../components/DropDown/DropDown";
import Modal from "../../../components/Popup/ModalPopup";

// import { TabCandidates } from "../../../components/Tab/TabCandidates";
// import { TabJobDescription } from "../../../components/Tab/TabJobDescription";
// import { TabNotes } from "../../../components/Tab/TabNotes";
// import { TabProgress } from "../../../components/Tab/TabProgress";
// import { TabStatistics } from "../../../components/Tab/TabStatistics";
// import { TabFileAssignments } from "../../../components/Tab/TabFileAssignments";
// import TabCompetency from "../../../components/Tab/TabCompetency";
import TabParticipant from "../../../components/Tab/TabParticipant";
import TabTraining from "../../../components/Tab/TabTraining";
import TabForm from "../../../components/Tab/TabForm";
import TabCertificate from "../../../components/Tab/TabCertificate";

import { statusAssignmentTDM } from "./dataList";

import { storeIndAssignTab } from "../../../store/actions/tabAssignSlice";
import { getClientDetails } from "../../../store/actions/clientSlice";
import { getAssignmentDetails } from "../../../store/actions/assignmentSlice";
import { storeTitle } from "../../../store/actions/headerTitleSlice";
// import { getListStatistics } from "../../../store/actions/statisticsSlice";

const ViewAssignmentTDM = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const customSwal = SwalAlert();
  // const abilities = AuthAbilities();

  const { indAsgnTab } = useSelector((state) => state.tabAssignment);
  const { clientDetails } = useSelector((state) => state.client);
  const { assignDetails, assignCountry, assignStaff } = useSelector(
    (state) => state.assignment
  );

  const [status, setStatus] = useState("");
  const [showRemark, setShowRemark] = useState(false);
  const [errorForm, setErrorForm] = useState({});
  const [statusRem, setStatusRem] = useState("");

  const selectedCrumb = (crumb) => {
    // console.log("crumb", crumb)
    if(crumb === "Clients"){
      navigate("/clients/listActiveC");
    }else if(crumb === "Potential Clients"){
      navigate("/clients/listPotentialC");
    } else if (crumb === clientDetails.name) {
      if(params.page === "active"){
        navigate("/clients/viewActiveC/" + params.id);
      }else{
        navigate("/clients/view-potential-client/" + params.id);
      }
    }
  };

  const changeStatus = (event) => {
    let statusChange = event.target.value;
    // setTxtStatus(statusChage);
    // console.log("stass", statusChange)

    if(statusChange === "cancelled" || statusChange === "on_hold"){
      setShowRemark(true);
      setErrorForm({});
      setStatusRem(statusChange);
    }else{
      customSwal
      .fire({
        title:"Do you want to update status to " + statusChange.replace(/_/g, " ") + "?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.patch("client/" + params.id + "/assignment/" + params.idassign + "/tmdm/updateStatusAssignment",
            {
              status: statusChange,
              remark: null
            }
          )
            .then((response) => {
              dispatch(getAssignmentDetails(params.id, params.idassign));

              customSwal.fire({
                title: "Status Updated Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error update status", error);
            });
        } else {
          // getSelectedClient();
        }
      });
    }

    setStatus("primary-color");

    
  };

  const formError = (object) => {
    const newErrors = {};

    if (!object.txtremark || object.txtremark === "")
      newErrors.txtremark = "Fill in the blank!";

    return newErrors;
  };

  const btnUpdateRemark = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtRemark = Object.fromEntries(data.entries());

    // console.log("dtRemakt", dtRemark)
    const newErrors = formError(dtRemark);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      setErrorForm({});

      customSwal
      .fire({
        title:"Do you want to update status to " + statusRem.replace(/_/g, " ") + "?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.patch("client/" + params.id + "/assignment/" + params.idassign + "/tmdm/updateStatusAssignment",
            {
              status: statusRem,
              remark: dtRemark.txtremark
            }
          )
            .then((response) => {
              dispatch(getAssignmentDetails(params.id, params.idassign));
              setShowRemark(false);
              setStatusRem("");
              customSwal.fire({
                title: "Status Updated Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error update status", error);
            });
        } else {
          // getSelectedClient();
        }
      });
    }
  };

  const btnCloseRemark = () => {
    setShowRemark(false);
  };

  // const viewProposal = (id) => () => {
  //   // console.log("id", id);
  //   navigate("/clients/view-proposal/active/" + params.id + "/" + id);
  // };

  const handleSelectTab = (key) => {
    dispatch(
      storeIndAssignTab({
        id: params.idassign,
        index: key,
      })
    );
  };

  useEffect(() => {
    dispatch(getAssignmentDetails(params.id, params.idassign));
    dispatch(getClientDetails(params.id));
    dispatch(storeTitle({ title: params.page === "active" ? "Clients" : "Potential Clients", icon: mdiAccountGroup }));
    // dispatch(getAssignmentDetails(params.id, params.idassign));
    // dispatch(getListStatistics(params.id, params.idassign));
    // dispatch(storeTitle({ title: "Clients", icon: mdiAccountGroup }));

    setStatus("primary-color");
  }, [dispatch, params.id, params.idassign
  ]);

  return (
    <div className="main-div list-cli">
      <div className="container-div both-side">
        <BreadCrumb
          crumbs={[params.page === "active" ? "Clients" : "Potential Clients", clientDetails.name, assignDetails.title]}
          selected={selectedCrumb}
        ></BreadCrumb>
        {assignDetails.proposal_id ? (
          <Button
            variant="primary"
            // onClick={viewProposal(assignDetails.proposal_id)}
            // hidden={!abilities.includes("list proposal")}
          >
            <Icon path={mdiFileDocumentMultiple} color="#FFF" />
            <div>View Proposal</div>
          </Button>
        ) : (
          ""
        )}
      </div>

      {/* TM/DM Screens */}
      <Card className="mb-3">
        <Card.Body>
          <div className="top-card">
            <div className="top-left-card">
              <div className="big-text">{assignDetails.title}</div>
              <div>&bull;</div>
              <div className="mini-text no-mb">
                {assignCountry ? assignCountry : "-"}
              </div>
              <div>&bull;</div>
              <div className="mini-text no-mb">
                {moment(assignDetails.created_at).format("DD MMMM YYYY")}
              </div>
            </div>
            <div className="top-right-card">
              <div className="mini-text no-mb">
                Assign to:{" "}
                <span className="text">
                  {assignStaff.map((obj, index) => {
                    let nameStaff = obj.first_name + " " + obj.last_name + ",";
                    return nameStaff;
                  })}
                </span>
              </div>
              {assignDetails.status === "cancelled" || assignDetails.status === "on_hold"?
                <div className="mini-text no-mb" style={{ width: "300px" }}>
                  Remarks: <br />
                  <span className="text">
                    {assignDetails.remark ? assignDetails.remark : "-"}
                  </span>
                </div>:""
              }
              
              <DropDown
                options={statusAssignmentTDM}
                styleDrop="select--status--style"
                sizeDrop="select--medium"
                value={assignDetails.status}
                onChange={changeStatus}
                colorDrop={status}
              />
            </div>
          </div>
        </Card.Body>
      </Card>

      {/* TM/DM Screens */}
      <Tab.Container
        defaultActiveKey={indAsgnTab ? indAsgnTab.index : "1"}
        transition={true}
        onSelect={handleSelectTab}
      >
        <div className="nav-container scrollable">
          <Nav variant="tabs" justify>
            <Nav.Item>
              <Nav.Link eventKey="1">Participants List</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="2">Training Material</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="3">Form</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="4">Certificate of Attendance</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>

        <Tab.Content style={{ background: "none" }}>
          <Tab.Pane eventKey="1">
            <TabParticipant />
          </Tab.Pane>
          <Tab.Pane eventKey="2">
            <TabTraining />
          </Tab.Pane>
          <Tab.Pane eventKey="3">
            <TabForm />
          </Tab.Pane>
          <Tab.Pane eventKey="4">
            <TabCertificate />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>

      {/* Modal Upload Other */}
      <Modal
        show={showRemark}
        size="md"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseRemark}
        title="Remark"
        content={
          <>
            <Form id="remark-form" onSubmit={btnUpdateRemark}>
              <Form.Group as={Row} className="mb-1">
                <Form.Control name="txtremark" as="textarea" rows={3} isInvalid={!!errorForm.txtremark} />
                <Form.Control.Feedback type="invalid">
                    {errorForm.txtremark}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseRemark}>
              Cancel
            </Button>
            <Button type="submit" variant="primary" form="remark-form">
              Yes
            </Button>
          </>
        }
      ></Modal>
    </div>
  );
};

export default ViewAssignmentTDM;
