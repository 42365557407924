import LogoHeader from '../../../assets/report_logo_xxsmall.jpg';

export const rptHeader = () => {
  const reportHeader = `
  <table border=0 style="width:100%;">
    <tr height="20%">
        <td width=264 style='border-left:none; border-right:none;border-top:none; border-bottom:none; margin:0cm;'>
            <p style='font-size:14.0pt; font-weight:bold; margin:0cm;'>Lim-Loges <span style='color: #EC782F'>&amp;</span> Masters<br><span style='color: #EC782F; font-size:9.0pt; font-weight:bold;'>transforming companies, transforming career</span><br><span style='font-size:10.0pt; font-weight:bold;'>Disruption Management . Executive Search . Transition Management</span></p>
           
        </td>
        <td width=50 style='border-left:none; border-right:none; border-top:none; border-bottom:none;'>
            <p style='text-align:right'>
                <img src="`+ LogoHeader +`" alt="" width="21"></img>
            </p>
           
        </td>
    </tr>
    </table>
  
  `

  return reportHeader
}

export default rptHeader;



