import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Dropdown, Form, Accordion, Spinner } from "react-bootstrap";
import Api from "../../../../core/services/api.service";

import AuthAbilities from "../../Auth/AuthAbilities";
import ReactTooltip from "react-tooltip";
import SwalAlert from "../../../../components/Swal/SwalAlert";

import Icon from "@mdi/react";
import {
  mdiFilter,
  mdiMagnify,
  mdiDelete,
  mdiInformationOutline,
  mdiDotsHorizontal,
  mdiFilePlus,
  mdiFile,
  mdiAccountGroup,
  mdiMerge
} from "@mdi/js";

import DefaultPhoto from "../../../../assets/no-profile-photo.jpg";
import TextboxIcon from "../../../../components/TextField/TextFieldIcon";
import CustomTable from "../../../../components/Table/Table";
import CardInfo from "../../../../components/Card/CardInfo";
import Popup from "../../../../components/Popup/ModalPopup";
import { statusClient } from "../../dataList";
import "../client.css";

import { storeTitle } from "../../../../store/actions/headerTitleSlice";
import { indCliActTab } from "../../../../store/actions/tabActiveSlice";
import { getListStaff } from "../../../../store/actions/staffSlice";
import { getMergeClient } from "../../../../store/actions/mergeSlice";
import { getClientView, getListClientActive, setStoreActFilter, setAllListActiveClient} from "../../../../store/actions/clientSlice";
import { storePage } from "../../../../store/actions/paginationSlice";

function ListActiveClients() {
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const abilities = AuthAbilities();
  const customSwal = SwalAlert();

  const { dataCountry } = useSelector((state) => state.option);
  const { dataStaff } = useSelector((state) => state.staff);
  const { mergeClientSimilar, mergeClientNoSimilar } = useSelector(
    (state) => state.merge
  );
  const { clientListAct, clientAllListAct, clientActFilter } = useSelector((state) => state.client);

  // const [clientList, setClientList] = useState([]);

  const [clicked, setClicked] = useState(false);
  const [showMerge, setShowMerge] = useState(false);
    // const [allData, setAllData] = useState(clientList);

  const [checkClients] = useState([]);
  const [cliSelected, setCliSelected] = useState(0);

  const [combineClient, setCombineClient] = useState({
    staffs: false,
    subsidiaries: false,
    assingments: false,
    meetings: false,
    documents: false,
  });

  const valueClientIdChange = (e) => {
    var selectedID = parseInt(e.target.value);
    var cliID = checkClients.map((s) => s.id);
    // // console.log("candOne", candid[0]);
    // // console.log("candTwo", parseInt(e.target.value));

    if (selectedID === cliID[0]) {
      Object.assign(combineClient, { id: selectedID, id_remove: cliID[1] });
    } else {
      Object.assign(combineClient, { id: selectedID, id_remove: cliID[0] });
    }

    console.log("combineCand", combineClient);
  };

  const valueClientCheck = (e) => {
    Object.assign(combineClient, { [e.target.value]: e.currentTarget.checked });

    console.log("setCombineCand", combineClient);
  };

  const btnMergeClient = (e) => {
    e.preventDefault();

    // const data = new FormData(e.target);
    // const dtMCli = Object.fromEntries(data.entries());

    // console.log("dtMCli", combineClient);
  };

  const btnCloseMerge = () => {
    setShowMerge(false);
  };

  const [clientOne, setClientOne] = useState({});
  const [clientTwo, setClientTwo] = useState({});

  const btnOpenMerge = (e) => {
    setShowMerge(true);

    var cliId = checkClients.map((s) => s.id);

    dispatch(getMergeClient(cliId[0], cliId[1]));

    setClientOne({
      client_id: checkClients[0].id,
      client_name: checkClients[0].name,
    });

    setClientTwo({
      client_id: checkClients[1].id,
      client_name: checkClients[1].name,
    });

    setCombineClient({
      staffs: false,
      subsidiaries: false,
      assingments: false,
      meetings: false,
      documents: false,
    });
  };

  // Function Event

  // function getListActiveClients() {
  //   Api.get("client")
  //     .then((response) => {
  //       let data = response.data;

  //       let listActive = data.filter(
  //         (act) =>
  //           act.is_potential === false &&
  //           act.status !== null &&
  //           act.status !== "closed"
  //       );

  //       // console.log("listActive", listActive);

  //       setClientList(listActive);
  //       setAllData(listActive);
  //     })
  //     .catch((error) => {
  //       console.log("client", error);
  //     });
  // }

  // button event
  const btnCollapse = (index) => {
    if (clicked === index) {
      //if clicked is already active, then close
      return setClicked(null);
    }

    setClicked(index);
  };

  const handleSearch = (event) => {
    let searchVal = event.target.value.toLowerCase();

    dispatch(storePage(0));
    dispatch(setStoreActFilter({ txtsearchactive: searchVal }));

    let idConsult = Number(clientActFilter.ddlConsultant);

    let newList = [];

          if (searchVal !== "" && clientActFilter.ddlCountry === "" && clientActFilter.ddlConsultant === "" && clientActFilter.ddlStatus === ""){
      newList = clientListAct.filter((client) => {
        return client.name.toLowerCase().includes(searchVal.toLowerCase());
      });
    }else if (searchVal !== "" && clientActFilter.ddlCountry !== "" && clientActFilter.ddlConsultant === "" && clientActFilter.ddlStatus === ""){
      newList = clientListAct.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          client.name.toLowerCase().includes(searchVal.toLowerCase()) &&
          cntry.includes(clientActFilter.ddlCountry)
        );
      });
    }else if (searchVal !== "" && clientActFilter.ddlCountry !== "" && clientActFilter.ddlConsultant !== "" && clientActFilter.ddlStatus === ""){
      newList = clientListAct.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          client.name.toLowerCase().includes(searchVal.toLowerCase()) &&
          cntry.includes(clientActFilter.ddlCountry) &&
          client.consultant_name.find((e) => e === idConsult)
        );
      });
    }else if (searchVal !== "" && clientActFilter.ddlCountry !== "" && clientActFilter.ddlConsultant !== "" && clientActFilter.ddlStatus !== ""){
      newList = clientListAct.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          client.name.toLowerCase().includes(searchVal.toLowerCase()) &&
          cntry.includes(clientActFilter.ddlCountry) &&
          client.consultant_name.find((e) => e === idConsult) &&
          client.status.toLowerCase().includes(clientActFilter.ddlStatus)
        );
      });
    }else if (searchVal !== "" && clientActFilter.ddlCountry !== "" && clientActFilter.ddlConsultant === "" && clientActFilter.ddlStatus !== ""){
      newList = clientListAct.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          client.name.toLowerCase().includes(searchVal.toLowerCase()) &&
          cntry.includes(clientActFilter.ddlCountry) &&
          client.status.toLowerCase().includes(clientActFilter.ddlStatus)
        );
      });
    }else if (searchVal !== "" && clientActFilter.ddlCountry === "" && clientActFilter.ddlConsultant !== "" && clientActFilter.ddlStatus === ""){
      newList = clientListAct.filter((client) => {
        return (
          client.name.toLowerCase().includes(searchVal.toLowerCase()) &&
          client.consultant_name.find((e) => e === idConsult)
        );
      });
    }else if (searchVal !== "" && clientActFilter.ddlCountry === "" && clientActFilter.ddlConsultant !== "" && clientActFilter.ddlStatus !== ""){
      newList = clientListAct.filter((client) => {
        return (
          client.name.toLowerCase().includes(searchVal.toLowerCase()) &&
          client.consultant_name.find((e) => e === idConsult) &&
          client.status.toLowerCase().includes(clientActFilter.ddlStatus)
        );
      });
    }else if (searchVal !== "" && clientActFilter.ddlCountry === "" && clientActFilter.ddlConsultant === "" && clientActFilter.ddlStatus !== ""){
      newList = clientListAct.filter((client) => {
        return (
          client.name.toLowerCase().includes(searchVal.toLowerCase()) &&
          client.status.toLowerCase().includes(clientActFilter.ddlStatus)
        );
      });
    }else if (searchVal === "" && clientActFilter.ddlCountry !== "" && clientActFilter.ddlConsultant === "" && clientActFilter.ddlStatus === ""){
      newList = clientListAct.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          cntry.includes(clientActFilter.ddlCountry) 
        );
      });
    }else if (searchVal === "" && clientActFilter.ddlCountry !== "" && clientActFilter.ddlConsultant !== "" && clientActFilter.ddlStatus === ""){
      newList = clientListAct.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          cntry.includes(clientActFilter.ddlCountry) &&
          client.consultant_name.find((e) => e === idConsult)
        );
      });
    }else if (searchVal === "" && clientActFilter.ddlCountry !== "" && clientActFilter.ddlConsultant !== "" && clientActFilter.ddlStatus !== ""){
      newList = clientListAct.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          cntry.includes(clientActFilter.ddlCountry) &&
          client.consultant_name.find((e) => e === idConsult) &&
          client.status.toLowerCase().includes(clientActFilter.ddlStatus)
        );
      });
    }else if (searchVal === "" && clientActFilter.ddlCountry !== "" && clientActFilter.ddlConsultant === "" && clientActFilter.ddlStatus !== ""){
      newList = clientListAct.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          cntry.includes(clientActFilter.ddlCountry) &&
          client.status.toLowerCase().includes(clientActFilter.ddlStatus)
        );
      });
    }else if (searchVal === "" && clientActFilter.ddlCountry === "" && clientActFilter.ddlConsultant !== "" && clientActFilter.ddlStatus === ""){
      newList = clientListAct.filter((client) => {
        return (
          client.consultant_name.find((e) => e === idConsult)
        );
      });
    }else if (searchVal === "" && clientActFilter.ddlCountry === "" && clientActFilter.ddlConsultant !== "" && clientActFilter.ddlStatus !== ""){
      newList = clientListAct.filter((client) => {
        return (
          client.consultant_name.find((e) => e === idConsult) &&
          client.status.toLowerCase().includes(clientActFilter.ddlStatus)
        );
      });
    }else if (searchVal === "" && clientActFilter.ddlCountry === "" && clientActFilter.ddlConsultant === "" && clientActFilter.ddlStatus !== ""){
      newList = clientListAct.filter((client) => {
        return (
          client.status.toLowerCase().includes(clientActFilter.ddlStatus)
        );
      });
    }else{
      newList = clientListAct;
    }

    dispatch(setAllListActiveClient(newList));

  };

  const btnFilter = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtFilter = Object.fromEntries(data.entries());
    
    dispatch(storePage(0));
    dispatch(setStoreActFilter({ddlCountry: dtFilter.ddlCountry, ddlConsultant: dtFilter.ddlConsultant,ddlStatus: dtFilter.ddlStatus,}));

    let idConsult = Number(dtFilter.ddlConsultant);

    let newList = [];

          if (clientActFilter.txtsearchactive !== "" && dtFilter.ddlCountry === "" && dtFilter.ddlConsultant === "" && dtFilter.ddlStatus === ""){
      newList = clientListAct.filter((client) => {
        return client.name.toLowerCase().includes(clientActFilter.txtsearchactive.toLowerCase());
      });
    }else if (clientActFilter.txtsearchactive !== "" && dtFilter.ddlCountry !== "" && dtFilter.ddlConsultant === "" && dtFilter.ddlStatus === ""){
      newList = clientListAct.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          client.name.toLowerCase().includes(clientActFilter.txtsearchactive.toLowerCase()) &&
          cntry.includes(dtFilter.ddlCountry)
        );
      });
    }else if (clientActFilter.txtsearchactive !== "" && dtFilter.ddlCountry !== "" && dtFilter.ddlConsultant !== "" && dtFilter.ddlStatus === ""){
      newList = clientListAct.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          client.name.toLowerCase().includes(clientActFilter.txtsearchactive.toLowerCase()) &&
          cntry.includes(dtFilter.ddlCountry) &&
          client.consultant_name.find((e) => e === idConsult)
        );
      });
    }else if (clientActFilter.txtsearchactive !== "" && dtFilter.ddlCountry !== "" && dtFilter.ddlConsultant !== "" && dtFilter.ddlStatus !== ""){
      newList = clientListAct.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          client.name.toLowerCase().includes(clientActFilter.txtsearchactive.toLowerCase()) &&
          cntry.includes(dtFilter.ddlCountry) &&
          client.consultant_name.find((e) => e === idConsult) &&
          client.status.toLowerCase().includes(dtFilter.ddlStatus)
        );
      });
    }else if (clientActFilter.txtsearchactive !== "" && dtFilter.ddlCountry !== "" && dtFilter.ddlConsultant === "" && dtFilter.ddlStatus !== ""){
      newList = clientListAct.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          client.name.toLowerCase().includes(clientActFilter.txtsearchactive.toLowerCase()) &&
          cntry.includes(dtFilter.ddlCountry) &&
          client.status.toLowerCase().includes(dtFilter.ddlStatus)
        );
      });
    }else if (clientActFilter.txtsearchactive !== "" && dtFilter.ddlCountry === "" && dtFilter.ddlConsultant !== "" && dtFilter.ddlStatus === ""){
      newList = clientListAct.filter((client) => {
        return (
          client.name.toLowerCase().includes(clientActFilter.txtsearchactive.toLowerCase()) &&
          client.consultant_name.find((e) => e === idConsult)
        );
      });
    }else if (clientActFilter.txtsearchactive !== "" && dtFilter.ddlCountry === "" && dtFilter.ddlConsultant !== "" && dtFilter.ddlStatus !== ""){
      newList = clientListAct.filter((client) => {
        return (
          client.name.toLowerCase().includes(clientActFilter.txtsearchactive.toLowerCase()) &&
          client.consultant_name.find((e) => e === idConsult) &&
          client.status.toLowerCase().includes(dtFilter.ddlStatus)
        );
      });
    }else if (clientActFilter.txtsearchactive !== "" && dtFilter.ddlCountry === "" && dtFilter.ddlConsultant === "" && dtFilter.ddlStatus !== ""){
      newList = clientListAct.filter((client) => {
        return (
          client.name.toLowerCase().includes(clientActFilter.txtsearchactive.toLowerCase()) &&
          client.status.toLowerCase().includes(dtFilter.ddlStatus)
        );
      });
    }else if (clientActFilter.txtsearchactive === "" && dtFilter.ddlCountry !== "" && dtFilter.ddlConsultant === "" && dtFilter.ddlStatus === ""){
      newList = clientListAct.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          cntry.includes(dtFilter.ddlCountry) 
        );
      });
    }else if (clientActFilter.txtsearchactive === "" && dtFilter.ddlCountry !== "" && dtFilter.ddlConsultant !== "" && dtFilter.ddlStatus === ""){
      newList = clientListAct.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          cntry.includes(dtFilter.ddlCountry) &&
          client.consultant_name.find((e) => e === idConsult)
        );
      });
    }else if (clientActFilter.txtsearchactive === "" && dtFilter.ddlCountry !== "" && dtFilter.ddlConsultant !== "" && dtFilter.ddlStatus !== ""){
      newList = clientListAct.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          cntry.includes(dtFilter.ddlCountry) &&
          client.consultant_name.find((e) => e === idConsult) &&
          client.status.toLowerCase().includes(dtFilter.ddlStatus)
        );
      });
    }else if (clientActFilter.txtsearchactive === "" && dtFilter.ddlCountry !== "" && dtFilter.ddlConsultant === "" && dtFilter.ddlStatus !== ""){
      newList = clientListAct.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          cntry.includes(dtFilter.ddlCountry) &&
          client.status.toLowerCase().includes(dtFilter.ddlStatus)
        );
      });
    }else if (clientActFilter.txtsearchactive === "" && dtFilter.ddlCountry === "" && dtFilter.ddlConsultant !== "" && dtFilter.ddlStatus === ""){
      newList = clientListAct.filter((client) => {
        return (
          client.consultant_name.find((e) => e === idConsult)
        );
      });
    }else if (clientActFilter.txtsearchactive === "" && dtFilter.ddlCountry === "" && dtFilter.ddlConsultant !== "" && dtFilter.ddlStatus !== ""){
      newList = clientListAct.filter((client) => {
        return (
          client.consultant_name.find((e) => e === idConsult) &&
          client.status.toLowerCase().includes(dtFilter.ddlStatus)
        );
      });
    }else if (clientActFilter.txtsearchactive === "" && dtFilter.ddlCountry === "" && dtFilter.ddlConsultant === "" && dtFilter.ddlStatus !== ""){
      newList = clientListAct.filter((client) => {
        return (
          client.status.toLowerCase().includes(dtFilter.ddlStatus)
        );
      });
    }else{
      newList = clientListAct;
    }

    dispatch(setAllListActiveClient(newList));
  };

  const handleChangeDDL = (type) => (e) => {

    if(type === "country"){
      dispatch(setStoreActFilter({
        ddlCountry: e.target.value,
      }));
    }else if(type === "consultant"){
      dispatch(setStoreActFilter({
        ddlConsultant: e.target.value,
      }));
    }else{
      dispatch(setStoreActFilter({
        ddlStatus: e.target.value,
      }));
    }
   
  };

  const btnReset = () => {
    document.getElementById("filter-active").reset();
    dispatch(
      setStoreActFilter({
        ddlCountry: "",
        ddlConsultant: "",
        ddlStatus: "",
      })
    );
  };

  const btnDelete = (id) => (e) => {

    customSwal
      .fire({
        title: "Do you want to delete this client?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("client/" + id)
            .then((response) => {
              // setSelectedIndustry([]);

              //close modal after update
              dispatch(getListClientActive(
                clientActFilter.txtsearchactive,
                clientActFilter.ddlCountry,
                clientActFilter.ddlConsultant,
                clientActFilter.ddlStatus
              ));
              customSwal.fire({
                title: "Deleted Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error delete active client", error);
            });
        }
      });
  };

  const btnDeleteMultiple = () => (e) =>{
    customSwal
    .fire({
      title: checkClients.length > 1 ? "Do you want to delete multiple selected client?" : "Do you want to delete selected client?",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Yes",
    })
    .then((result) => {
      if (result.isConfirmed) {

        checkClients.map((client, index) => {
          Api.delete("client/" + client.id)
          .then((response) => {
            dispatch(getListClientActive(
              clientActFilter.txtsearchactive,
              clientActFilter.ddlCountry,
              clientActFilter.ddlConsultant,
              clientActFilter.ddlStatus
            ));
          })
          .catch((error) => {
            console.log("error delete active client", error);
          });
        });

        //close modal after update
       
        customSwal.fire({
          title: "Deleted Successfully!",
          icon: "success",
          confirmButtonText: "Okay",
        });
        
      }
    });
  };

  // Headers and value table
  const headers = [
    {
      Header: "",
      accessor: "selection",
      disableSortBy: true,
      maxWidth: 50,
      minWidth: 10,
      width: 50,
      Cell: ({ row }) => {
        const data = row.original;

        const onItemCheck = (data) => (event) => {
          if (checkClients.length > 0) {
            if (event.target.checked === false) {
              const index = checkClients.findIndex(function (chck) {
                return chck.id === data.id;
              });

              checkClients.splice(index, 1);
            } else {
              checkClients.push(data);
            }
          } else {
            checkClients.push(data);
          }

          setCliSelected(checkClients.length);
        };

        const checkedItem = (id) => {
          let actCli = [];

          const checkCli = checkClients.filter((chec) => {
            return chec.id === id;
          });

          actCli = checkCli[0];

          return actCli;
        };

        return (
          <div className="checkbox-container">
            <input
              type="checkbox"
              checked={checkedItem(data.id)}
              id={"row" + data.id}
              onChange={onItemCheck(data)}
            />
          </div>
        );
      },
    },
    {
      Header: "Client Info",
      accessor: (row) => `${row.name}`,
      minWidth: 200,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        const navigate = useNavigate();

        function handleClick() {
          dispatch(indCliActTab({id: data.id, index: "1" }));
          dispatch(getClientView({}));

          navigate("/clients/viewActiveC/" + data.id);
        }

        return (
          <div className="form-container-default">
            <div className="form-container-default-1">
              {data.logo ? (
                <img src={data.logo} alt="Client Logo" />
              ) : (
                <img src={DefaultPhoto} alt="Client Logo" />
              )}
            </div>
            <div
              className="form-container-default-2"
              style={{ paddingLeft: "10px" }}
            >
              <Button
                variant="link"
                className="no-underline"
                onClick={handleClick}
              >
                {data.name}
              </Button>
            </div>
          </div>
        );
      },
    },
    {
      Header: "Country",
      accessor: (row) => `${row.options ? row.options.value : ""}`,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        let country = "";
        !data.options || data.options === ""
          ? (country = "-")
          : (country = data.options.value);

        return <div>{country}</div>;
      },
    },
    {
      Header: "Open Position",
      accessor: (row) => `${row.assignments.length}`,
      minWidth: 100,
      width: 140,
      Cell: ({ row }) => {
        const data = row.original;

        var array = data.assignments.map((obj, index) => {
          // return obj.sno + ".  " + obj.title + "<br>";
          let sno = index + 1;
          return sno + ".  " + obj.title + "<br>";
        });

        var tooltiptest = array.join("");

        return (
          // <div>{data.assignments[0].title}</div>
          <div className="form-container-default">
            <div
              className="form-container-default-1"
              style={{ width: "100px" }}
            >
              {data.assignments.length === 1 ||
              data.assignments.length === 0 ? (
                <div>{data.assignments.length} Position</div>
              ) : (
                <div>{data.assignments.length} Positions</div>
              )}
            </div>

            <div className="form-container-default-2">
              <div data-tip={tooltiptest.toString()}>
                <Icon path={mdiInformationOutline} />
                <ReactTooltip
                  place="right"
                  type="dark"
                  effect="solid"
                  html={true}
                  backgroundColor="#E5E5E5"
                  textColor="#212529"
                />
              </div>
            </div>
          </div>
        );
      },
    },
    {
      Header: "Consultant Name",
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        var array = data.client_has_user.map((obj) => {
          return obj.user.first_name + " " + obj.user.last_name + ",";
        });

        var cons_name = array.join(" ");

        return <div>{cons_name ? cons_name.slice(0, -1) : "-"}</div>;
      },
    },
    {
      Header: "Status",
      accessor: (row) => `${row.status}`,
      minWidth: 150,
      width: 150,
      Cell: ({ row }) => {
        const data = row.original;

        return data.status === "Blacklisted" ? (
          <CardInfo
            cardTextSize="card_text--small"
            cardTextStyle="card_text--danger--solid"
          >
            {data.status}
          </CardInfo>
        ) : (
          <CardInfo
            cardTextSize="card_text--small"
            cardTextStyle="card_text--primary--solid"
          >
            {data.status.replace(/_/g, " ")}
          </CardInfo>
        );
      },
    },
    {
      Header: "",
      accessor: "client_info",
      disableSortBy: true,
      minWidth: 10,
      width: 70,
      Cell: ({ row }) => {
        const data = row.original;

        const navigate = useNavigate();

        function handleAdd() {
          navigate("/clients/create-proposal/active/" + data.id);
        }

        function viewProposal() {
          navigate("/clients/view-list-proposal/" + data.id);
        }

        return (
          <>
            {abilities.includes("add proposal") &&
            abilities.includes("list proposal") &&
            abilities.includes("delete clients") ? (
              <div className="dropdown-container list-cli">
                <Dropdown>
                  <Dropdown.Toggle variant="light" id="dropdown-basic">
                    <Icon path={mdiDotsHorizontal} size={1} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={handleAdd}
                      hidden={!abilities.includes("add proposal")}
                    >
                      <Icon path={mdiFilePlus} color="#EC782F" /> Add Client
                      Proposal
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={viewProposal}
                      hidden={!abilities.includes("list proposal")}
                    >
                      <Icon path={mdiFile} color="#467FD0" /> View Client
                      Proposal
                    </Dropdown.Item>
                    <Dropdown.Item
                      hidden={!abilities.includes("delete clients")}
                      onClick={btnDelete(data.id)}
                    >
                      <Icon path={mdiDelete} color="#DF4759" />
                      Delete Client
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // getListActiveClients();
    if(clientActFilter.ddlCountry !== "" || clientActFilter.ddlConsultant !== "" || clientActFilter.ddlStatus !== ""){
      setClicked(true);
    }else{
      setClicked(false);
    }

    dispatch(getListClientActive(
      clientActFilter.txtsearchactive,
      clientActFilter.ddlCountry,
      clientActFilter.ddlConsultant,
      clientActFilter.ddlStatus
    ));

    dispatch(getListStaff());
    dispatch(storeTitle({ title: "Clients", icon: mdiAccountGroup }));

    setTimeout(() => setLoading(false), 2000);
  }, [dispatch]);

  return (
    <>
      {clientListAct.length === 0 ? 
        loading ? 
          <div id="main-overlay" className="main-overlay">
            <Spinner animation="border" variant="light" />
          </div> : ""
      :""}
      <div className="main-div list-cli">
        <div className="top-title-div cli-page">
          <div className="filter_container">
            <div className="filter_container_totalrow">
              {clientAllListAct.length === 0 || clientAllListAct.length === 1 ? (
                <div className="form-container-default">
                  <div className="total_client_text">{clientAllListAct.length}</div>
                  <div className="client_text">client</div>
                </div>
              ) : (
                <div className="form-container-default">
                  <div className="total_client_text">{clientAllListAct.length}</div>
                  <div className="client_text">clients</div>
                </div>
              )}
            </div>

            <div className="upper-divider"></div>

            <div className="filter_container_search">
              <div className="filter_container_search-1">
                <TextboxIcon
                  onChange={(event) => handleSearch(event)}
                  name="txtsearchactive"
                  type="text"
                  placeholder="Search by client name"
                  inputSize="input--medium"
                  icon={mdiMagnify}
                  value={clientActFilter.txtsearchactive}
                ></TextboxIcon>
              </div>
            </div>

            <div className="filter_container_typelist">
              <div className="form-container-filter-button">
                {cliSelected === 2 ? (
                  <Button
                    variant="secondary"
                    onClick={btnOpenMerge}
                    hidden={true}
                    // hidden={!abilities.includes("merge clients")}
                  >
                    <Icon path={mdiMerge} />
                    <div> Merge</div>
                  </Button>
                ) : (
                  ""
                )}

                {cliSelected > 0 ? (
                  <Button
                    variant="danger"
                    hidden={!abilities.includes("delete clients")}
                    onClick={btnDeleteMultiple()}
                  >
                    <Icon path={mdiDelete} />
                    <div>Delete</div>
                  </Button>
                ) : (
                  ""
                )}

                <Button onClick={() => btnCollapse(true)} variant="secondary">
                  <Icon path={mdiFilter} />
                  <div>Filter</div>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div id="demo" className={"collapses" + (clicked ? " in" : "")}>
          <Form id="filter-active" onSubmit={btnFilter}>
            <div className="form-container-filter">
              <div className="form-container-filter-1">
                <Form.Group>
                  <Form.Label>Country</Form.Label>
                  <Form.Select name="ddlCountry" value={clientActFilter.ddlCountry} onChange={handleChangeDDL("country")}>
                    <option value="">Select</option>
                    {dataCountry.map((cntry, index) => (
                      <option key={index} value={cntry.value}>
                        {cntry.value}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="form-container-filter-1">
                <Form.Group>
                  <Form.Label>Consultant Name</Form.Label>
                  <Form.Select name="ddlConsultant" value={clientActFilter.ddlConsultant} onChange={handleChangeDDL("consultant")}>
                    <option value="">Select</option>
                    {dataStaff.map((staf, index) => (
                      <option key={index} value={staf.id}>
                        {staf.first_name} {staf.last_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="form-container-filter-1">
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Select name="ddlStatus" value={clientActFilter.ddlStatus} onChange={handleChangeDDL("status")}>
                    <option value="">Select</option>
                    {statusClient.map((status, index) => (
                      <option key={index} value={status.value}>
                        {status.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
            </div>
            <div className="row-btn-bottom">
              <Button variant="secondary" onClick={btnReset}>
                Reset
              </Button>
              <Button variant="primary" type="submit" form="filter-active">
                Search
              </Button>
            </div>
          </Form>
        </div>

        <div className="container-div list-cli">
          {/* <div className="content-tabs_toggle"> */}
          <CustomTable
            headers={headers}
            items={clientAllListAct}
            paginate={clientAllListAct.length}
            maxRows={15}
            pindex={true}
          ></CustomTable>
          {/* </div> */}
        </div>

        {/* Modal Merge */}
        <Popup
          show={showMerge}
          size="xl"
          backdrop="static"
          keyboard={false}
          onHide={btnCloseMerge}
          title="Merge Client"
          content={
            <>
              <Form id="client_form" onSubmit={btnMergeClient}>
                <Table>
                  <thead className="merge-table-thead">
                    <tr>
                      <th colSpan={3}>Master Record and Field Selection</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th width="20%">Master Record</th>
                      <td width="40%">
                        <Form.Check
                          type="radio"
                          label={clientOne.client_name}
                          value={clientOne.client_id}
                          onChange={valueClientIdChange}
                          name="grpMaster"
                          required
                        />
                      </td>
                      <td width="40%">
                        <Form.Check
                          type="radio"
                          label={clientTwo.client_name}
                          value={clientTwo.client_id}
                          onChange={valueClientIdChange}
                          name="grpMaster"
                          required
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div>
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Similar Fields (Auto Merging)
                      </Accordion.Header>
                      <Accordion.Body>
                        <Table>
                          <tbody>
                            {Object.keys(mergeClientSimilar).map((key, index) => {
                              var nameObj = "";
                              var dataObj = "";

                              if (key === "name") {
                                nameObj = "Company Name";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "incorporated") {
                                nameObj = "Incorporated";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "headquarters") {
                                nameObj = "Corporate HQ";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "client_has_user") {
                                nameObj = "Consultant Name";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "industry_detail") {
                                nameObj = "Industry";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "address") {
                                nameObj = "Address";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "general_telephone") {
                                nameObj = "General Telephone";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "website_url") {
                                nameObj = "Website";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "linkedln_url") {
                                nameObj = "LinkedIn Profile";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "revenue") {
                                nameObj = "Revenue";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "total_employees") {
                                nameObj = "Employees";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "overall_background") {
                                nameObj = "Overall Background";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "important_note") {
                                nameObj = "Important Notes";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "status") {
                                nameObj = "Status";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "finance_certificate") {
                                nameObj = "Finance Certificate";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "is_parent") {
                                nameObj = "Parent";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "parent_id") {
                                nameObj = "Parent Company";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else {
                                nameObj = "Reason Blacklist";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              }

                              return (
                                <div key={index}>
                                  <tr>
                                    <th>{nameObj}</th>
                                    <td>{dataObj}</td>
                                  </tr>
                                </div>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Field Selection</Accordion.Header>
                      <Accordion.Body>
                        <Table>
                          <tbody>
                            {mergeClientNoSimilar.map((data, index) => {
                              var nameObj = "";
                              var dataCandOne = "";
                              var dataCandTwo = "";

                              if (data.name === "name") {
                                nameObj = "Company Name";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "incorporated") {
                                nameObj = "Incorporated";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "headquarters") {
                                nameObj = "Corporate HQ";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "consultant_detail") {
                                nameObj = "Consultant Name";
                                dataCandOne =
                                  data.candOne.length > 0
                                    ? data.candOne
                                        .map((s) =>
                                          s.last_name
                                            ? s.first_name + " " + s.last_name
                                            : s.first_name
                                        )
                                        .toString()
                                    : "-";
                                dataCandTwo =
                                  data.candTwo.length > 0
                                    ? data.candTwo
                                        .map((s) =>
                                          s.last_name
                                            ? s.first_name + " " + s.last_name
                                            : s.first_name
                                        )
                                        .toString()
                                    : "-";
                                // dataValueOne = data.candOne.length > 0 ? data.candOne.map((s) => s.user.id) : "-";
                              } else if (data.name === "industry_detail") {
                                nameObj = "Industry";
                                dataCandOne =
                                  data.candOne.length > 0
                                    ? data.candOne.map((s) => s.code).toString()
                                    : "-";
                                dataCandTwo =
                                  data.candTwo.length > 0
                                    ? data.candTwo.map((s) => s.code).toString()
                                    : "-";
                              } else if (data.name === "address") {
                                nameObj = "Address";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "general_telephone") {
                                nameObj = "General Telephone";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "website_url") {
                                nameObj = "Website";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "linkedln_url") {
                                nameObj = "LinkedIn Profile";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "revenue") {
                                nameObj = "Revenue";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "total_employees") {
                                nameObj = "Employees";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "overall_background") {
                                nameObj = "Overall Background";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "important_note") {
                                nameObj = "Important Notes";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "status") {
                                nameObj = "Status";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "is_parent") {
                                nameObj = "Parent";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "finance_certificate") {
                                nameObj = "Finance Certificate";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "reason_blacklist") {
                                nameObj = "Reason Blacklist";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "parent_id") {
                                nameObj = "Parent Company";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              }
                              // else{
                              //   nameObj = "No";
                              //   dataCandOne = data.candOne ? data.candOne : "-";
                              //   dataCandTwo = data.candTwo ? data.candTwo : "-";
                              // }

                              const valueClientObject = (data) => (e) => {
                                const str = e.target.value;

                                const property = str.substring(
                                  0,
                                  str.indexOf(" ")
                                ); // "72"

                                if (property === "industry_detail") {
                                  const industryID = data.map((s) => s.id);

                                  Object.assign(combineClient, {
                                    industry: industryID,
                                  });
                                } else if (property === "consultant_detail") {
                                  const consultID = data.map((s) => s.id);

                                  Object.assign(combineClient, {
                                    consultant_name: consultID,
                                  });
                                } else {
                                  const dataValue = str.substring(
                                    str.indexOf(" ") + 1
                                  );

                                  Object.assign(combineClient, {
                                    [property]: dataValue,
                                  });
                                }

                                console.log("setCombineCand", combineClient);
                              };

                              return (
                                <tr key={index}>
                                  <th>{nameObj}</th>
                                  <td>
                                    <Form.Check
                                      type="radio"
                                      label={dataCandOne}
                                      name={index}
                                      value={data.name + " " + data.candOne}
                                      onChange={valueClientObject(data.candOne)}
                                      required
                                    />
                                  </td>
                                  <td>
                                    <Form.Check
                                      type="radio"
                                      label={dataCandTwo}
                                      name={index}
                                      value={data.name + " " + data.candTwo}
                                      onChange={valueClientObject(data.candTwo)}
                                      required
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <Table>
                  <tbody>
                    <tr>
                      <td colSpan={3}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="Staff"
                            value="staffs"
                            onChange={valueClientCheck}
                          />
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="Subsidiaries"
                            value="subsidiaries"
                            onChange={valueClientCheck}
                          />
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="Assignments"
                            value="assignments"
                            onChange={valueClientCheck}
                          />
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="Meetings"
                            value="meetings"
                            onChange={valueClientCheck}
                          />
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="Files/Documents"
                            value="documents"
                            onChange={valueClientCheck}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                {/*  */}
              </Form>
            </>
          }
          button={
            <>
              <Button variant="secondary" onClick={btnCloseMerge}>
                Cancel
              </Button>
              <Button variant="primary" type="submit" form="client_form">
                Merge
              </Button>
            </>
          }
        />
      </div>
    </>
  );
}

export default ListActiveClients;
