import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Card, Button, Form, Row, Dropdown } from "react-bootstrap";
import Api from "../../../core/services/api.service";
import ReactTooltip from "react-tooltip";

import moment from "moment";
import SwalAlert from "../../Swal/SwalAlert";

import Icon from "@mdi/react";
import {
  mdiPlusCircle,
  mdiCloseCircle,
  mdiDotsHorizontal,
  mdiPencil,
  mdiFileDocument,
} from "@mdi/js";

// Customize components
import Popup from "../../Popup/ModalPopup";
import Searchable from "../../DropDown/SearchableSelect";
import CustomTable from "../../Table/Table";
import CardInfo from "../../Card/CardInfo";

// Call Redux Api
import { getClientStaff } from "../../../store/actions/clientSlice";
import { getListAllInterview, getListFeedback } from "../../../store/actions/interviewSlice";

export const TabHiringClient = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const customSwal = SwalAlert();

  // Call Data From Redux
  const { user } = useSelector((state) => state.login);
  const { dataStage, dataDuration } = useSelector((state) => state.option);
  const { clientListStaff } = useSelector((state) => state.client);
  const { dataInterview, interFeedback } = useSelector((state) => state.interview);

  // Declare useState
  const [errorForm, setErrorForm] = useState({});
  const [showInterview, setShowInterview] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const [modeModal, setModeModal] = useState({ title: null, button: null });
  const [idInv, setIdInv] = useState("");
  const [noteInterview, setNoteInterview] = useState("");
  // const [selectedClientStaff, setSelectedClientStaff] = useState([]);

  const [invState] = useState({
    ddlStage: "",
    txtlocation: "",
    interview_date: "",
    interview_time: "",
    ddlInterview_Client: "",
    txtaddnote: "",
    ddlDuration: "",
  });

  const [rowDateTime, setRowDateTime] = useState([
    {
      interview_id: 1,
      interview_date: "",
      interview_time: "",
    },
  ]);
  const [selStaffClient, setSelStaffClient] = useState([]);
  const [getFeedback, setFeedback] = useState(false);
  const [showViewFeedback, setShowViewFeedback] = useState(false);

  const [errorFormFeed, setErrorFormFeed] = useState({});
  const [idMeet, setIdMeet] = useState(0);

  const btnCloseFeedback = (e) => {
    setFeedback(false);
  };

  const btnAddFeedback = (id) => (e) => {
    // console.log("id", id)
    setFeedback(true);
    setErrorFormFeed({});
    setIdMeet(id);

  };

  const btnSaveFeedBack = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtFeed = Object.fromEntries(data.entries());

    const newErrors = formErrorFeed(dtFeed);

    if (Object.keys(newErrors).length > 0) {
      setErrorFormFeed(newErrors);
    } else {
      setErrorFormFeed({});

      // console.log("idclient", dtFeed)

      customSwal
        .fire({
          title: "Do you want to add feedback?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Api.post("client-staff/" + user.sub + "/interview-feedback/" + idMeet + "/store",
              {
                type: dtFeed.ddlType,
                feedback: dtFeed.txtfeedback
              }
            )
              .then((response) => {
                // console.log(response);
                setFeedback(false);
                setShowViewFeedback(true);
                dispatch(getListAllInterview(params.idcand, params.idassign));
                dispatch(getListFeedback(user.sub, idMeet));
              })
              .catch((error) => {
                console.log("error add feedback", error);
              });
          }
        });
    }
  };

  const formErrorFeed = (object) => {
    const newErrors = {};

    if (!object.ddlType || object.ddlType === "")
      newErrors.ddlType = "Fill in the blank!";

    if (!object.txtfeedback || object.txtfeedback === "")
      newErrors.txtfeedback = "Fill in the blank!";

    return newErrors;
  };

  const btnCloseViewFeedback = (e) => {
    setShowViewFeedback(false);
  };

  const btnViewFeedback = (idInter) => (e) => {
    setShowViewFeedback(true);

    dispatch(getListFeedback(user.sub, idInter));

  };

  // Start Header Table
  const headerInterview = [
    {
      Header: "Round of Interview",
      accessor: "stage",
      minWidth: 100,
      width: 200,
    },
    {
      Header: "Date & Time",
      minWidth: 100,
      width: 270,
      Cell: ({ row }) => {
        const data = row.original;

        const timeList = data.interview_time
          ? moment(data.interview_time, ["HH:mm"]).format("hh:mm A") +
            " - " +
            moment(new Date("1970-01-01 " + data.interview_time))
              .add(Number(data.duration), "minutes")
              .format("hh:mm A")
          : "-";

        const columnDateTime =
          "<b>" +
          moment(data.interview_date).format("DD MMMM YYYY") +
          "</b>" +
          "<br>" +
          timeList;

        return (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: data.interview_date
                  ? columnDateTime
                  : "Multiple interview times",
              }}
            ></div>
          </>
        );
      },
    },
    {
      Header: "Interviewer Name",
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        var array_combine = [];

        var array_staff = data.interview_has_admin.map((obj, index) => {
          return obj.user.first_name + " " + obj.user.last_name + ",";
        });

        var array_client = data.interview_has_staff.map((obj, index) => {
          return obj.candidate.first_name + " " + obj.candidate.last_name + ",";
        });

        var array_all = array_combine.concat(array_staff, array_client);

        var cons_name = array_all.join(" ");

        return <div>{cons_name.slice(0, -1)}</div>;
      },
    },
    {
      Header: "Note",
      accessor: "notes",
      minWidth: 80,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        let interviewnote = data.add_note;

        const btnShowNote = () => {
          setNoteInterview(interviewnote);
          setShowNote(true);
        };

        return (
          <>
            {interviewnote ? (
              <div className="container-div list-cli">
                <Button
                  variant="link"
                  className="reminder-btn"
                  onClick={btnShowNote}
                >
                  View Note
                </Button>
              </div>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      Header: "Status",
      minWidth: 150,
      width: 120,
      Cell: ({ row }) => {
        const data = row.original;

        let styleCard = "";

        if(data.status === "declined"){
          styleCard = "card_text--danger--solid";
        }else if(data.status === "created"){
          styleCard = "card_text--primary--solid";
        }else{
          styleCard = "card_text--completed--solid";
        }

        return (
          <>
            <CardInfo
                cardTextSize="card_text--small"
                cardTextStyle={styleCard}
              >
                {data.status}
              </CardInfo>
          </>
        );
      },
    },
    {
      Header: "Feedback",
      minWidth: 80,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <>
            {data.status === "accepted" ? (
              data.interview_feedback.length > 0 ? (
                <div className="container-div list-cli">
                  <Button
                    variant="link"
                    className="modal-link"
                    onClick={btnViewFeedback(data.id)}
                  >
                    <Icon path={mdiFileDocument} />
                    View Feedback
                  </Button>
                </div>
              ): (
                <div className="container-div list-cli">
                  <Button
                    variant="link"
                    className="modal-link"
                    onClick={btnAddFeedback(data.id)}
                  >
                    <Icon path={mdiPlusCircle} />
                    Feedback
                  </Button>
                </div>
              )
                
            ) : (
              <div
                data-tip={
                  "Feedback can only be added " +
                  " <br/> " +
                  " after interview has been accepted."
                }
              >
                <div style={{textAlign: "center"}}>
                  N/A
                  <ReactTooltip
                    place="bottom"
                    type="dark"
                    effect="solid"
                    html={true}
                    backgroundColor="#E5E5E5"
                    textColor="#212529"
                  />
                </div>
              </div>
            )}
          </>
        );
      },
    },
    {
      Header: "",
      accessor: "actions",
      maxWidth: 100,
      minWidth: 80,
      width: 80,
      Cell: ({ row }) => {
        const data = row.original;

        const client = data.client_involve;
        const hasStaff = data.interview_has_staff;

        const findSameId = hasStaff.map((staff, index) => {
          return client.includes(staff.candidate_id);
        });

        // console.log(findSameId);

        return (
          <div className="dropdown-container list-cli">
            {data.status === "accepted" || data.status === "declined" ? (<></>) :
               <Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  <Icon path={mdiDotsHorizontal} size={1} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {findSameId ? (
                    
                      <Dropdown.Item
                        onClick={btnEditInterview(data.id)}
                        // hidden={!abilities.includes("edit interview")}
                      >
                        <Icon path={mdiPencil} color="#467FD0" />
                        Edit Interview
                      </Dropdown.Item>
                    
                  ) : (
                    <></>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            }
           
          </div>
        );
      },
    },
  ];
  // End Header Table

  const headerFeedback = [
    {
      Header: "Interviewer Name",
      accessor: "name",
      minWidth: 100,
      width: 150,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <>
            <div>{data.candidate.last_name ? data.candidate.first_name + " " + data.candidate.last_name : data.candidate.first_name}</div>
          </>
        );
      },
    },
    {
      Header: "Feedback Type",
      accessor: "type",
      minWidth: 100,
      width: 150,
      Cell: ({ row }) => {
        const data = row.original;

        let viewType = "";

        if(data.type === "to_progress"){
          viewType = "To progress";
        }else if(data.type === "to_reject"){
          viewType = "To reject";
        }else{
          viewType = "For consideration";
        }

        return (
          <>
            <div>{viewType}</div>
          </>
        );
      },
    },
    {
      Header: "Feedback",
      accessor: "feedback",
      minWidth: 100,
      width: 400,
    },
    {
      Header: "Date & Time",
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        const viewDate = moment(data.created_at).format("DD MMMM YYYY hh:mm A");
        return (
          <>
            <div>{viewDate}</div>
          </>
        );
      },
    },
  ];


  // Start On Change Events
  const handleFormChange = (index, event) => {
    let data = [...rowDateTime];
    data[index][event.target.name] = event.target.value;

    setRowDateTime(data);
  };

  const handleStaffClient = (e) => {
    setSelStaffClient(
      Array.isArray(e)
        ? e.map((x) => ({
            id: x.id,
            first_name: x.first_name,
            last_name: x.last_name,
          }))
        : []
    );
  };
  // End On Change Events

  // Start Checking Error Form
  const formError = (object) => {
    const newErrors = {};

    if (!object.ddlStage || object.ddlStage === "")
      newErrors.ddlStage = "Fill in the blank!";

    if (!object.interview_time || object.interview_time === "")
      newErrors.interview_time = "Fill in the blank!";

    if (!object.interview_date || object.interview_date === "")
      newErrors.interview_date = "Fill in the blank!";

    if (!object.ddlDuration || object.ddlDuration === "")
      newErrors.ddlDuration = "Fill in the blank!";

    return newErrors;
  };
  // End Checking Error Form

  // Start Button Events
  const btnAddInterview = () => {
    invState.ddlStage = null;
    invState.txtlocation = null;
    invState.interview_date = null;
    invState.interview_time = null;
    invState.txtaddnote = null;
    invState.ddlDuration = null;
    setSelStaffClient([]);

    setErrorForm({});
    setModeModal({
      title: "Add Interview",
      button: "Add",
    });
    setIdInv("New");
    setRowDateTime([
      {
        interview_id: 1,
        interview_date: "",
        interview_time: "",
      },
    ]);
    setShowInterview(true);
  };

  const btnEditInterview = (id) => (e) => {
    setSelStaffClient([]);
    setRowDateTime([]);

    const filteredInv = dataInterview.find((interview) => interview.id === id);

    // console.log("filteredInv", filteredInv)

    invState.ddlStage = filteredInv.stage;
    invState.txtlocation = filteredInv.location;
    invState.ddlDuration = filteredInv.duration;
    invState.txtaddnote = filteredInv.add_note;

    if(filteredInv.interview_date_time.length > 0){
      filteredInv.interview_date_time.map((inter) => {
        return setRowDateTime((items) => [
          ...items,
          {
            interview_id: inter.id,
            interview_date: inter.interview_date.substring(0, 10),
            interview_time: inter.interview_time,
          },
        ]);
      });
    }else{
      setRowDateTime((items) => [
        ...items,
        {
          interview_id: 1,
          interview_date: filteredInv.interview_date ? filteredInv.interview_date.substring(0, 10): "",
          interview_time: filteredInv.interview_time
        },
      ])
    }

    filteredInv.interview_has_staff.map((cliSta) => {
      return setSelStaffClient((items) => [
        ...items,
        {
          id: cliSta.candidate_id,
          first_name: cliSta.candidate.first_name,
          last_name: cliSta.candidate.last_name,
        },
      ]);
    });

    setErrorForm({});
    setModeModal({
      title: "Edit Interview",
      button: "Update",
    });
    setIdInv(id);
    setShowInterview(true);
  };

  const btnCloseInterview = () => {
    setShowInterview(false);
  };

  const btnSaveUpdateInv = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtInv = Object.fromEntries(data.entries());

    const newErrors = formError(dtInv);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      if (idInv === "New") {
        customSwal
          .fire({
            title: "Do you want to save new interview?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post(
                "candidate/" +
                  params.idcand +
                  "/assignment/" +
                  params.idassign +
                  "/interview/create",
                {
                  stage: dtInv.ddlStage,
                  location: dtInv.txtlocation,
                  duration: dtInv.ddlDuration,
                  iv_date_time: rowDateTime.length > 1 ? rowDateTime : [],
                  interview_date:
                    rowDateTime.length > 1 ? "" : dtInv.interview_date,
                  interview_time:
                    rowDateTime.length > 1 ? "" : dtInv.interview_time,
                  staff_involve: [],
                  client_involve: selStaffClient.map((el) => el.id),
                  add_note: dtInv.txtaddnote,
                }
              )
                .then((response) => {
                  dispatch(getListAllInterview(params.idcand, params.idassign));
                  setShowInterview(false);
                  customSwal.fire({
                    title: "Interview Saved Successfully!",
                    icon: "success",
                    confirmButtonText: "Okay",
                  });
                })
                .catch((error) => {
                  console.log("save Referee", error);
                });
            }
          });
      } else {
        customSwal
          .fire({
            title: "Do you want to update interview?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.patch(
                "candidate/" +
                  params.idcand +
                  "/assignment/" +
                  params.idassign +
                  "/interview/" +
                  idInv +
                  "/update",
                {
                  stage: dtInv.ddlStage,
                  location: dtInv.txtlocation,
                  duration: dtInv.ddlDuration,
                  iv_date_time: rowDateTime.length > 1 ? rowDateTime : [],
                  interview_date:
                    rowDateTime.length > 1 ? "" : dtInv.interview_date,
                  interview_time:
                    rowDateTime.length > 1 ? "" : dtInv.interview_time,
                  staff_involve: [],
                  client_involve: selStaffClient.map((el) => el.id),
                  add_note: dtInv.txtaddnote,
                }
              )
                .then((response) => {
                  // console.log(response);

                  dispatch(getListAllInterview(params.idcand, params.idassign));
                  setShowInterview(false);
                  customSwal.fire({
                    title: "Interview Saved Successfully!",
                    icon: "success",
                    confirmButtonText: "Okay",
                  });
                })
                .catch((error) => {
                  console.log("save Referee", error);
                });
            }
          });
      }
    }
  };

  const btnAddRowsDateTime = () => {
    const totLength = rowDateTime.length + 1;

    setRowDateTime((items) => [
      ...items,
      {
        interview_id: totLength,
        interview_date: "",
        interview_time: "",
      },
    ]);
  };

  const btnDelRowsDateTime = (index) => {
    let data = [...rowDateTime];
    data.splice(index, 1);
    setRowDateTime(data);
  };

  const btnCloseNote = () => {
    setShowNote(false);
  };
  // End Button Events

  useEffect(() => {
    dispatch(getClientStaff(user.clientid));
    dispatch(getListAllInterview(params.idcand, params.idassign));
  }, [dispatch, user.clientid, params.idcand, params.idassign]);

  return (
    <>
      <Card>
        <Card.Body>
          <div className="row-div">
            <div className="left-div"></div>
            <div className="right-div">
              <Button
                variant="link"
                className="modal-link"
                onClick={btnAddInterview}
              >
                <Icon path={mdiPlusCircle} />
                Add Interview Round
              </Button>
            </div>
          </div>

          <CustomTable
            headers={headerInterview}
            items={dataInterview}
            paginate={dataInterview.length}
            maxRows={15}
          />
        </Card.Body>
      </Card>

      {/* Modal add interview */}
      <Popup
        show={showInterview}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseInterview}
        title={modeModal.title}
        content={
          <>
            <Form id="inv-form" onSubmit={btnSaveUpdateInv}>
              {/* 1 */}
              <Row>
                <Form.Group className="mb-3 col-6">
                  <Form.Label>
                    Round of Interview{" "}
                    <label className="validation-star">*</label>
                  </Form.Label>
                  <Form.Select
                    name="ddlStage"
                    isInvalid={!!errorForm.ddlStage}
                    defaultValue={invState.ddlStage}
                  >
                    <option value="">Please select</option>
                    {dataStage.map((stage, index) => (
                      <option key={index} value={stage.value}>
                        {stage.value}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errorForm.ddlStage}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3 col-6">
                  <Form.Label>Interview Location</Form.Label>
                  <Form.Control
                    type="text"
                    name="txtlocation"
                    placeholder="Please enter interview location"
                    defaultValue={invState.txtlocation}
                  />
                </Form.Group>
              </Row>
              {/* 2 */}
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Duration <label className="validation-star">*</label>
                  </Form.Label>
                  <Form.Select
                    name="ddlDuration"
                    isInvalid={!!errorForm.ddlDuration}
                    defaultValue={invState.ddlDuration}
                  >
                    <option value="">Please select</option>
                    {dataDuration.map((dur, index) => (
                      <option key={index} value={dur.code}>
                        {dur.value}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errorForm.ddlDuration}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              {/* 3 */}
              {rowDateTime.map((row, index) => {
                return(
                  <Row key={index}>
                      <Form.Group className="mb-3 col-6">
                        <Form.Label>
                          Interview Date {index + 1}{" "}
                          <label className="validation-star">*</label>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          name="interview_date"
                          value={row.interview_date}
                          onChange={(event) =>
                            handleFormChange(index, event)
                          }
                          isInvalid={!!errorForm.interview_date}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorForm.interview_date}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className={rowDateTime.length > 1 ? "mb-3 col-5" : "mb-3 col-6"}>
                        <Form.Label>
                          Interview Time {index + 1}{" "}
                          <label className="validation-star">*</label>
                        </Form.Label>
                        <Form.Control
                          type="time"
                          name="interview_time"
                          value={row.interview_time}
                          onChange={(event) =>
                            handleFormChange(index, event)
                          }
                          isInvalid={!!errorForm.interview_time}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorForm.interview_time}
                        </Form.Control.Feedback>
                      </Form.Group>
                      {rowDateTime.length > 1 ? 
                        <Form.Group className="mb-3 col-1">
                          <Form.Label></Form.Label>
                          <div className="mt-3">
                          
                              <Button
                              variant="link"
                              className="modal-link"
                              onClick={() => btnDelRowsDateTime(index)}
                            >
                              <Icon path={mdiCloseCircle} />
                            </Button>
                            
                          </div>
                        </Form.Group> : ""}
                  </Row>
                )
              })}
              {rowDateTime.length < 3 ? (
                <div className="mb-2">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="link"
                      className="modal-link"
                      onClick={btnAddRowsDateTime}
                    >
                      <Icon path={mdiPlusCircle} />
                      Alternate Interview Date & Time
                    </Button>
                  </div>
                </div>
              ) : null}
              {/* 4 */}
              <Form.Group className="mb-3">
                <Form.Label>Interview By</Form.Label>
                <Searchable
                  isMulti={true}
                  options={clientListStaff}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) =>
                    option.first_name + " " + option.last_name
                  }
                  onChange={handleStaffClient}
                  defaultValue={selStaffClient}
                ></Searchable>
              </Form.Group>
              {/* 5 */}
              <Form.Group className="mb-3">
                <Form.Label>Additional Notes</Form.Label>
                <Form.Control
                  name="txtaddnote"
                  as="textarea"
                  rows={3}
                  defaultValue={invState.txtaddnote}
                />
              </Form.Group>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseInterview}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" form="inv-form">
              {modeModal.button}
            </Button>
          </>
        }
      />

      {/* Modal show notes interview */}
      <Popup
        show={showNote}
        size="md"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseNote}
        title="Notes"
        content={
          <>
            <Form.Group className="mb-3">
              <Form.Label>Additional Notes</Form.Label>
              <Form.Control
                name="txtfollownote"
                as="textarea"
                rows={5}
                defaultValue={noteInterview}
                readOnly
              />
            </Form.Group>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseNote}>
              Close
            </Button>
          </>
        }
      />

      {/* Modal Add Feedback */}
      <Popup
        show={getFeedback}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseFeedback}
        title="Add Feedback"
        content={
          <>
            <Form id="feedback-form" onSubmit={btnSaveFeedBack}>
              <Form.Group className="mb-3">
                <Form.Label>Type of Feedback</Form.Label>
                <Form.Select 
                  name="ddlType"
                  isInvalid={!!errorFormFeed.ddlType}
                >
                  <option value="">Select</option>
                  <option value="to_progress">To progress</option>
                  <option value="to_reject">To reject</option>
                  <option value="for_consideration">For consideration</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errorFormFeed.ddlType}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  name="txtfeedback"
                  as="textarea"
                  rows={3}
                  placeholder="Please leave your feedback here..."
                  isInvalid={!!errorFormFeed.txtfeedback}
                />
                <Form.Control.Feedback type="invalid">
                  {errorFormFeed.txtfeedback}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseFeedback}>
              Cancel
            </Button>
            <Button type="submit" variant="primary" form="feedback-form">
              Add
            </Button>
          </>
        }
      />

      {/* Modal show feedback */}
      <Popup
        show={showViewFeedback}
        size="xl"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseViewFeedback}
        title="View Feedback"
        content={
          <>
            <CustomTable
              headers={headerFeedback}
              items={interFeedback}
              paginate={interFeedback.length}
              maxRows={15}
            />
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseViewFeedback}>
              Close
            </Button>
          </>
        }
      />
    </>
  );
};
