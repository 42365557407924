import {createSlice} from '@reduxjs/toolkit';
import Api from '../../core/services/api.service';

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
      isLoading:true,
      dashTotSummary: {
        totalAssign: 0,
        totalActiveCand: 0,
        totalCand: 0,
        totalPlaced: 0,
        totalClient: 0,
      },
      dashTopCandRegister: [],
      dashTopCandCountry: [],
      dashTopClientCountry: [],
      dashTopClientIndustry: [],
      dashTodoList: [],
      dashTodoListComplete: []
    },
    reducers: {
      getPending: (state) => {
        state.isLoading=true
      },
      getDashTopCandRegister: (state, action) => {
        state.isLoading=false;
        state.dashTopCandRegister = action.payload;
      },
      getDashTopCandCountry: (state, action) => {
        state.isLoading=false;
        state.dashTopCandCountry = action.payload;
      },
      getDashTopClientCountry: (state, action) => {
        state.isLoading=false;
        state.dashTopClientCountry = action.payload;
      },
      getDashTopClientIndustry: (state, action) => {
        state.isLoading=false;
        state.dashTopClientIndustry = action.payload;
      },
      storeTotalSummary: (state, action) => {
        state.isLoading=false;
        state.dashTotSummary = { ...state.dashTotSummary, ...action.payload };
      },
      storeTodoList: (state, action) => {
        state.isLoading=false;
        state.dashTodoList = action.payload;
      },
      storeTodoListComplete: (state, action) => {
        state.isLoading=false;
        state.dashTodoListComplete = action.payload;
      },
    },
});

export const getDashboardTopCandRegister = () => async (dispatch, rejectWithValue) => {
  try {
    const response = await Api.get("dashboard/topCandidateRegister");

    if (response.data !== "no data") {
      dispatch(getDashTopCandRegister(response.data));
    }
    

  } catch (err) {
    throw rejectWithValue(err.message);
  }
};

export const getTopCountry = () => async (dispatch, rejectWithValue) => {
  try {
    const response = await Api.get("dashboard/topCountry");

    if (response.data.candidate !== "no data") {
      dispatch(getDashTopCandCountry(response.data.candidate));
    }

    if (response.data.client !== "no data") {
      dispatch(getDashTopClientCountry(response.data.client));
    }

    if (response.data.industry !== "no data") {
      dispatch(getDashTopClientIndustry(response.data.industry));
    }
    

  } catch (err) {
    throw rejectWithValue(err.message);
  }
};

export const getTotalSummary = () => async (dispatch, rejectWithValue) => {
  try {
    const response = await Api.get("dashboard/getTotalSummary");

    if (response.data !== "no data") {
      // console.log("resp", response.data)

      dispatch(storeTotalSummary({
        totalAssign: response.data[0].assignment._count.id,
        totalActiveCand: response.data[0].active_candidate._count.id,
        totalCand: response.data[0].total_candidate._count.id,
        totalPlaced: response.data[0].placed_candidate._count.id,
        totalClient: response.data[0].total_client._count.id,
      }));
    }

   

  } catch (err) {
    throw rejectWithValue(err.message);
  }
};

export const getTodoList = () => async (dispatch, rejectWithValue) => {
  try {
    const response = await Api.get("user/todo");

    if (response.data !== "no data") {
      let listTaskActive = response.data.filter((grp) =>
        grp.status.includes("active")
      );

      let sortListTaskActive = listTaskActive.sort(
        (a, b) => Number(b.id) - Number(a.id)
      );

      dispatch(storeTodoList(sortListTaskActive));

      let listTaskCompleted = response.data.filter((grp) =>
        grp.status.includes("completed")
      );

      dispatch(storeTodoListComplete(listTaskCompleted));
    }

   

  } catch (err) {
    throw rejectWithValue(err.message);
  }
};

export const { getPending, getDashTopCandRegister, getDashTopCandCountry, getDashTopClientCountry, getDashTopClientIndustry, storeTotalSummary, storeTodoList, storeTodoListComplete } = dashboardSlice.actions;
export default dashboardSlice.reducer;