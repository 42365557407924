import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Button, Form, Nav, Tab, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "./home.css";

import Icon from "@mdi/react";
import {
  mdiAccountGroup,
  mdiClipboardText,
  mdiMapMarker,
  mdiHome,
} from "@mdi/js";

import { MiniLoader } from "../../../components/Loading/loader";
import { CustomTable } from "../../../components/Table/Table";

import {
  getPending,
  getDashboardTopCandRegister,
  getTopCountry,
  getTotalSummary
} from "../../../store/actions/dashboardSlice";
import { storeTitle } from "../../../store/actions/headerTitleSlice";
import { setStoreCandFilter } from "../../../store/actions/candidateSlice";
import { getListDataAssignments } from "../../../store/actions/assignmentSlice";
import { indCandTab } from "../../../store/actions/tabActiveSlice";

function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    isLoading,
    dashTopCandRegister,
    dashTopCandCountry,
    dashTopClientCountry,
    dashTopClientIndustry,
    dashTotSummary,
  } = useSelector((state) => state.dashboard);

  const { assignListData } = useSelector((state) => state.assignment);

  // type column table
  const headersAssignments = [
    {
      Header: "No",
      width: 10,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Company Name",
      accessor: (row) => `${row.clients ? row.clients.name : ""}`,
      width: 900,
      Cell: ({ row }) => {
        const data = row.original;

        const status = data.clients.is_potential ? "potential" : "active";

        function viewAssignment() {
          if (data.type === "ES") {
            navigate("/clients/view-assignment/" + status + "/" + data.client_id + "/" + data.id);
          } else {
            navigate("/clients/view-assignment-tdm/tdm/" + status + "/" + data.client_id + "/" + data.id);
          }

        }

        return (
          <div className="candidate-row">
            <div className="todo-name">
              <Button
                variant="link"
                className="no-underline"
                onClick={viewAssignment}
                style={{ textTransform: "capitalize" }}
              >
                {data.clients ? data.clients.name : "-"}
              </Button>
            </div>
          </div>
        );
      },
    },
    {
      Header: "Assignment Name",
      accessor: "title",
      width: 900,
      // Cell: ({ row }) => {
      //   const data = row.original;

      //   return (
      //     <div className="candidate-row">
      //       <div className="todo-name">
      //         <Button
      //           variant="link"
      //           className="no-underline"
      //           onClick={handleClick}
      //           style={{ textTransform: "capitalize" }}
      //         >
      //           {nameCandi}
      //         </Button>
      //       </div>
      //     </div>
      //   );
      // },
    },
  ];

  const headers = [
    {
      Header: "No",
      width: 10,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Name",
      width: 900,
      Cell: ({ row }) => {
        const data = row.original;

        const navigate = useNavigate();

        let nameCandi = "";

        if (data.last_name !== null) {
          nameCandi = data.first_name + " " + data.last_name;
        } else {
          nameCandi = data.first_name;
        }

        function handleClick() {
          // uncomment
          dispatch(
            indCandTab({
              id: data.id,
              index: "1",
            })
          );

          navigate("/candidates/viewCandidate/" + data.id);
        }

        return (
          <div className="candidate-row">
            <div className="todo-name">
              <Button
                variant="link"
                className="no-underline"
                onClick={handleClick}
                style={{ textTransform: "capitalize" }}
              >
                {nameCandi}
              </Button>
            </div>
          </div>
        );
      },
    },
    {
      Header: "Created Date",
      width: 900,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{moment(data.created_at).format("DD MMMM YYYY")}</div>;
      },
    },
    {
      Header: "Created By",
      width: 900,
      Cell: ({ row }) => {
        const data = row.original;

        const result4 = !data.created_by_users ? '-' : data.created_by_users.first_name + ' ' + data.created_by_users.last_name;

        return <div>{result4}</div>;
      },
    },
  ];

  const headersCandTopCountry = [
    {
      Header: "No",
      width: 10,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Country Name",
      width: 900,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="candidate-row">
            <div className="todo-name">{data.country}</div>
          </div>
        );
      },
    },

    {
      Header: "Total",
      width: 10,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="candidate-row">
            <div className="todo-date other">{data.total}</div>
          </div>
        );
      },
    },
  ];

  const headersIndustries = [
    {
      Header: "No",
      width: 10,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Industry",
      width: 900,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="candidate-row">
            <div className="todo-name">{data.industry}</div>
          </div>
        );
      },
    },

    {
      Header: "Total",
      width: 10,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="candidate-row">
            <div className="todo-date other">{data.total}</div>
          </div>
        );
      },
    },
  ];

  const headersClientTopCountry = [
    {
      Header: "No",
      width: 10,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Country Name",
      width: 900,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="candidate-row">
            <div className="todo-name">{data.country}</div>
          </div>
        );
      },
    },

    {
      Header: "Total",
      width: 10,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="candidate-row">
            <div className="todo-date other">{data.total}</div>
          </div>
        );
      },
    },
  ];

  //  end type column table

  const btnOpenNavi = (type) => (e) => {
    if (type === "assign") {
      navigate("/clients/listAssignments");
    } else if (type === "active") {
      dispatch(
        setStoreCandFilter({ ddlStatus: "active" })
      );

      navigate("/candidates/listCandidates");
    } else if (type === "client") {
      navigate("/clients/listActiveC");
    } else if (type === "totCand") {

      dispatch(
        setStoreCandFilter({ ddlStatus: "" })
      );

      navigate("/candidates/listCandidates");
    }
  };

  useEffect(() => {
    dispatch(getPending());
    dispatch(getDashboardTopCandRegister());
    dispatch(getTopCountry());
    dispatch(getTotalSummary());
    // dispatch(getTodoList());

    dispatch(getListDataAssignments('', '', '', ''));

    dispatch(
      storeTitle({
        title: "Home",
        icon: mdiHome,
      })
    );
  }, [dispatch]);

  return(
    <div className="main-div">
      <div className="db-summary">
        {/* 1 */}
        <Card
          className="dashboard-card col-3"
          style={{ cursor: "pointer" }}
          onClick={btnOpenNavi("assign")}
        >
          <div className="db-summary-left">
            <div className="dashboard-card-num">
              {dashTotSummary.totalAssign}
            </div>
            <div className="dashboard-card-title">Assignments</div>
          </div>
          <div className="db-summary-right">
            <Icon path={mdiClipboardText} size={2.5} />
          </div>
        </Card>

        {/* 2 */}
        <Card
          className="dashboard-card col-3"
          style={{ cursor: "pointer" }}
          onClick={btnOpenNavi("active")}
        >
          <div className="db-summary-left">
            <div className="dashboard-card-num">
              {dashTotSummary.totalActiveCand}
            </div>
            <div className="dashboard-card-title">Active Candidates</div>
          </div>
          <div className="db-summary-right">
            <Icon path={mdiAccountGroup} size={2.5} />
          </div>
        </Card>

        {/* 3 */}
        <Card
          className="dashboard-card col-3"
          style={{ cursor: "pointer" }}
          onClick={btnOpenNavi("totCand")}
        >
          <div className="db-summary-left">
            <div className="dashboard-card-num">{dashTotSummary.totalCand}</div>
            <div className="dashboard-card-title">Total Candidates</div>
          </div>
          <div className="db-summary-right">
            <Icon path={mdiAccountGroup} size={2.5} />
          </div>
        </Card>

        {/* 4 */}
        <Card
          className="dashboard-card col-3"
          style={{ cursor: "pointer" }}
          onClick={btnOpenNavi("client")}
        >
          <div className="db-summary-left">
            <div className="dashboard-card-num">
              {dashTotSummary.totalClient}
            </div>
            <div className="dashboard-card-title">
              {dashTotSummary.totalClient > 1 ? "Clients" : "Client"}
            </div>
          </div>
          <div className="db-summary-right">
            <Icon path={mdiMapMarker} size={2.5} />
          </div>
        </Card>

        <div className="db-stats-row">
          {/* List of assignments */}
          <div className="div-todo">
            <Card className="dashboard-card-padding">
              <div className="dashboard-card-heading">List of Assignments</div>

              {isLoading ? (
                <MiniLoader />
              ) : (
                <>
                  <div className="new-cdt-reg">
                    <CustomTable
                      headers={headersAssignments}
                      items={assignListData}
                    ></CustomTable>
                  </div>
                </>
              )}
            </Card>
          </div>
          {/* New Candidate register */}
          <div className="div-cdt">
            <Card className="dashboard-card-padding">
              <div className="dashboard-card-heading">
                New Candidates Registered
              </div>
              {isLoading ? (
                <MiniLoader />
              ) : (
                <>
                  <div className="new-cdt-reg">
                    <CustomTable
                      headers={headers}
                      items={dashTopCandRegister}
                    ></CustomTable>
                  </div>
                </>
              )}
            </Card>
          </div>
        </div>

        <div className="db-stats-row">
          <div className="same-dim">
            <Card className="dashboard-card-padding one">
              <div className="dashboard-card-heading one">
                Candidate Top Countries
              </div>
              {isLoading ? (
                <MiniLoader />
              ) : (
                <div className="top">
                  <CustomTable
                    headers={headersCandTopCountry}
                    items={dashTopCandCountry}
                  ></CustomTable>
                </div>
              )}
            </Card>
          </div>
          <div className="same-dim">
            <Card className="dashboard-card-padding two">
              <div className="dashboard-card-heading two">
                Client Top Countries
              </div>
              {isLoading ? (
                <MiniLoader />
              ) : (
                <div className="top">
                  <CustomTable
                    headers={headersClientTopCountry}
                    items={dashTopClientCountry}
                  ></CustomTable>
                </div>
              )}
            </Card>
          </div>
          <div className="same-dim">
            <Card className="dashboard-card-padding tri">
              <div className="dashboard-card-heading tri">
                Client Top Industries
              </div>
              {isLoading ? (
                <MiniLoader />
              ) : (
                <div className="top">
                  <CustomTable
                    headers={headersIndustries}
                    items={dashTopClientIndustry}
                  ></CustomTable>
                </div>
              )}
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home;