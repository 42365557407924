import React, { useEffect, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Dropdown, Card } from "react-bootstrap";
import Api from "../../core/services/api.service";

import SwalAlert from "../Swal/SwalAlert";
import FileSaver from "file-saver";
import AuthAbilities from "../../pages/Admin/Auth/AuthAbilities";
import moment from "moment";
import ReactTooltip from "react-tooltip";

import {
  mdiFilePlus,
  mdiDotsHorizontal,
  mdiDownload,
  mdiPencil,
  mdiDelete,
  mdiEmail,
  mdiFileSend,
  mdiMessagePlus,
  mdiInformationOutline,
} from "@mdi/js";
import Icon from "@mdi/react";

import Table from "../Table/Table";
import CardInfo from "../Card/CardInfo";

// import { ProgressReport } from "../Report/Template/ProgressReport";
import rptFooter from "../Report/Template_Report/rptFooter";
import rptHeader from "../Report/Template_Report/rptHeader";
import rptProgress from "../Report/Template_Report/rptProgress";

import HTMLtoDOCX from 'html-to-docx';

export const TabProgress = (props) => {
  const navigate = useNavigate();
  const params = useParams();

  const abilities = AuthAbilities();

  const [progressList, setProgressList] = useState([]);

  const customSwal = SwalAlert();

  function btnAddProgress() {
    navigate(
      "/clients/create-progress/" + props.page + "/new/" + params.id + "/" + params.idassign
    );
  }

  const btnEditProgress = (idpro) => () => {
    navigate(
      "/clients/view-progress/" +
      params.page + "/" +
      params.id +
      "/" +
      params.idassign +
      "/" +
      idpro
    );
  };

  function btnCreateMeeting() {
    navigate("/clients/create-meeting/" + params.id);
  }

  const btnDownload = (data) => async () => {
    const llmList = data.progress_has_admin.map((item) => {
      const container = {};

      container.name = item.user.last_name
        ? item.user.first_name + " " + item.user.last_name
        : item.user.first_name;

      return container;
    });

    const clientList = data.progress_has_staff.map((item) => {
      const container = {};

      container.name = item.candidate.last_name
        ? item.candidate.first_name + " " + item.candidate.last_name
        : item.candidate.first_name;
      container.job_title =
        item.candidate.candidate_experiences.length > 0
          ? item.candidate.candidate_experiences[0].title
          : "";
      container.company =
        item.candidate.candidate_experiences.length > 0
          ? item.candidate.candidate_experiences[0].company_name
          : "";

      return container;
    });

    const candidateList = data.progress_has_candidate.map((item) => {
      const container = {};

      container.name = item.candidate.last_name
        ? item.candidate.first_name + " " + item.candidate.last_name
        : item.candidate.first_name;
      container.job_title =
        item.candidate.candidate_experiences.length > 0
          ? item.candidate.candidate_experiences[0].title
          : "";
      container.company =
        item.candidate.candidate_experiences.length > 0
          ? item.candidate.candidate_experiences[0].company_name
          : "";
      container.location =
        item.candidate.candidate_experiences.length > 0
          ? item.candidate.candidate_experiences[0].location
          : "";

      return container;
    });

    const dataRpt = {
      created_at: moment(data.created_at).format("DD MMMM YYYY"),
      clientList: clientList,
      llmList: llmList,
      key_milestone: data.key_milestone,
      progress: data.progress,
      market_feedback: data.market_feedback,
      candidateList: candidateList,
    };

    const htmlString = rptProgress(dataRpt);
    const headerString = rptHeader();
    const footerHTMLString = rptFooter();

    // uncomment the following
    const fileBuffer = await HTMLtoDOCX(
      htmlString,
      headerString,
      {
        table: { row: { cantSplit: true } },
        header: true,
        footer: true,
        pageNumber: true,
        margins: {
          top: 500,
          right: 1500,
          bottom: 500,
          left: 1500,
          header: 720,
          footer: 720,
          gutter: 0,
        },
        font: "Calibri (Body)",
      },
      footerHTMLString
    );

    // console.log("dataRpt", dataRpt);
    FileSaver.saveAs(fileBuffer, data.report_name + ".docx");
    // ProgressReport(data);
  };

  const btnDeleteProgress = (idpro) => (e) => {
    customSwal
      .fire({
        title: "Do you want to delete progress/reference report?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("client/" + params.id + "/assignment/" + params.idassign + "/progress/" + idpro)
            .then((response) => {
              // let data = response.data;

              getListProgress();

              customSwal.fire({
                title: "Delete Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error delete others", error);
            });
        }
      });
  };

  // Header
  const headerReport = [
    {
      Header: "No",
      minWidth: 10,
      width: 40,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Report Name",
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{data.report_name}</div>;
      },
    },
    {
      Header: "Created By",
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        let createdBy = "";

        createdBy = data.created_by_progress_report
          ? data.created_by_progress_report.first_name +
          " " +
          data.created_by_progress_report.last_name
          : "";

        return <div>{createdBy}</div>;
      },
    },
    {
      Header: "Date Created",
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{moment(data.created_at).format("DD MMMM YYYY")}</div>;
      },
    },
    {
      Header: "Latest Edit",
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        var array = data.progress_report_log.map((obj, index) => {
          // return obj.sno + ".  " + obj.title + "<br>";
          let sno = index + 1;
          return (
            sno +
            ".  " +
            obj.updated_by_progress_report.first_name +
            " " +
            obj.updated_by_progress_report.last_name +
            " (" +
            moment(obj.updated_at).format("DD/MM/YYYY hh:mm A") +
            ")<br>"
          );
        });

        var tooltiptest = array.join("");

        return (
          // <div>{data.assignments[0].title}</div>
          <div className="form-container-default">
            {tooltiptest ? (
              <div className="form-container-default-2">
                <div data-tip={tooltiptest.toString()}>
                  <Icon path={mdiInformationOutline} />
                  <ReactTooltip
                    place="right"
                    type="dark"
                    effect="solid"
                    html={true}
                    backgroundColor="#E5E5E5"
                    textColor="#212529"
                  />
                </div>
              </div>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      Header: "Status",
      Cell: ({ row }) => {
        const data = row.original;

        return data.status === "completed" ? (
          <CardInfo
            cardTextSize="card_text--small"
            cardTextStyle="card_text--completed--solid"
          >
            {data.status.replace(/_/g, " ")}
          </CardInfo>
        ) : (
          <CardInfo
            cardTextSize="card_text--small"
            cardTextStyle="card_text--primary--solid"
          >
            {data.status.replace(/_/g, " ")}
          </CardInfo>
        );
      },
    },
    {
      Header: "",
      accessor: "actions",
      maxWidth: 100,
      minWidth: 80,
      width: 80,
      Cell: ({ row }) => {
        const data = row.original;
        // console.log(data)
        const btnSendProgress = () => {
          customSwal
            .fire({
              title: "Do you want to send this progress to client?",
              icon: "warning",
              showCancelButton: true,
              reverseButtons: true,
              confirmButtonText: "Yes",
            })
            .then((result) => {
              if (result.isConfirmed) {
                Api.patch(
                  "client/" +
                  params.id +
                  "/assignment/" +
                  params.idassign +
                  "/progress/" +
                  data.id +
                  "/updateStatus",
                  {
                    status: "completed",
                  }
                )
                  .then((response) => {
                    getListProgress();
                  })
                  .catch((error) => {
                    console.log("btnUpdateJD", error);
                  });
              }
            });
        };

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={btnDownload(data)}>
                  <Icon path={mdiDownload} size={0.8} color="#467FD0" />
                  Download Report
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={btnEditProgress(data.id)}
                  hidden={!abilities.includes("edit report")}
                >
                  <Icon path={mdiPencil} color="#467FD0" />
                  Edit Report
                </Dropdown.Item>
                {data.status === "draft" ? (
                  <Dropdown.Item onClick={btnSendProgress}>
                    <Icon path={mdiFileSend} color="#4CAF50" />
                    Send Report to Client
                  </Dropdown.Item>
                ) : (
                  ""
                )}
                <Dropdown.Item onClick={btnDeleteProgress(data.id)} hidden={!abilities.includes("delete report")}>
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete Report
                </Dropdown.Item>
                <Dropdown.Item onClick={() =>
                  (window.location = "mailto:?subject=" + data.report_name)
                }>
                  <Icon path={mdiEmail} color="#42BA96" />
                  Email Report
                </Dropdown.Item>
                <Dropdown.Item onClick={btnCreateMeeting}>
                  <Icon path={mdiMessagePlus} color="#467FD0" />
                  Create Meeting
                </Dropdown.Item>
                <Dropdown.Item>
                  <Icon path={mdiEmail} color="#467FD0" />
                  Close Report
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const getListProgress = useCallback(() => {
    Api.get(
      "client/" + params.id + "/assignment/" + params.idassign + "/progress/all"
    )
      .then((response) => {
        let data = response.data;
        // console.log("data", data);

        if (data !== "no data") {
          let sortProgressData = data.sort(function (a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
          });

          setProgressList(sortProgressData);
        }
      })
      .catch((error) => {
        console.log("error getListProgress", error);
      });
  }, [params]);

  useEffect(() => {
    getListProgress();
  }, [getListProgress]);

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <Card.Body>
          <div className="row-div">
            <div className="left-div">
              <div className="title-text report">
                Progress / Reference Report
              </div>
            </div>
            <div className="right-div">
              <Button
                variant="link"
                className="modal-link"
                onClick={btnAddProgress}
                hidden={!abilities.includes("create report")}
              >
                <Icon path={mdiFilePlus} />
                <div>Create Progress Report</div>
              </Button>
            </div>
          </div>

          <Table
            headers={headerReport}
            items={progressList}
            paginate={progressList.length}
            maxRows={15}
          ></Table>
        </Card.Body>
      </Card>
    </>
  );
};
