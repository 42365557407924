import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Accordion, Row, Col, Form, Button } from "react-bootstrap";

// import ReactTooltip from "react-tooltip";
import SwalAlert from "../../components/Swal/SwalAlert";
import moment from "moment";

import Icon from "@mdi/react";
import { mdiMessage, mdiMessagePlus } from "@mdi/js";

// Custome Component
import CustomTable from "../../components/Table/Table";
import Modal from "../../components/Popup/ModalPopup";
import Searchable from "../../components/DropDown/SearchableSelect";

import { getListStaff } from "../../store/actions/staffSlice";
import { storeTitle } from "../../store/actions/headerTitleSlice";
import {
  createMeeting,
  getListOfMeeting,
} from "../../store/actions/meetingSlice";
import Empty from "../../components/Empty/Empty";

const Meeting = () => {
  const dispatch = useDispatch();
  const customSwal = SwalAlert();

  const { dataStaff } = useSelector((state) => state.staff);
  const { user } = useSelector((state) => state.login);
  const { meetingList } = useSelector((state) => state.meeting);

  const [errorForm, setErrorForm] = useState({});
  const [selPersonInvolved, setSelPersonInvolved] = useState([]);

  const [typeMeet, setTypeMeet] = useState("");
  const [showObjective, setShowObjective] = useState(false);
  const [meetState, setMeetState] = useState({
    txtnotes: ""
  });

  // const [getRequest] = useState(true);

  const headers = [
    {
      Header: "No",
      accessor: (row) => `${row.index}`,
      maxWidth: 60,
      minWidth: 10,
      width: 40,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Meeting Name",
      accessor: "name",
      maxWidth: 400,
      minWidth: 100,
      width: 300,
    },
    {
      Header: "Meeting Type",
      accessor: "type",
      maxWidth: 140,
      minWidth: 140,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        let typeMeet = "";

        if (data.type === "phone") {
          typeMeet = "Phone Discussion";
        } else if (data.type === "online") {
          typeMeet = "Online";
        } else {
          typeMeet = "Face-to-Face";
        }

        // var tooltiptest = data.location;

        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>{typeMeet}</div>
            {/* <div data-tip={tooltiptest.toString()}>
              <Icon path={mdiInformationOutline} />
              <ReactTooltip
                place="right"
                type="dark"
                effect="solid"
                html={true}
                backgroundColor="#E5E5E5"
                textColor="#212529"
              />
            </div> */}
          </div>
        );
      },
    },
    {
      Header: "Location",
      accessor: "location",
      maxWidth: 140,
      minWidth: 140,
      width: 200,
    },
    {
      Header: "Meeting Date & Time",
      accessor: "date_meeting",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        const timeList = data.time_meeting
          ? moment(data.time_meeting, ["HH:mm"]).format("hh:mm A")
          : "-";

        const columnDateTime =
          "<b>" +
          moment(data.date_meeting).format("DD MMMM YYYY") +
          "</b>" +
          "<br>" +
          timeList;

        return (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: data.time_meeting ? columnDateTime : "-",
              }}
            ></div>
          </>
        );
      },
    },
    {
      Header: "Person Involved",
      maxWidth: 400,
      minWidth: 100,
      width: 300,
      Cell: ({ row }) => {
        
        const data = row.original;

        var array = data.user_has_request_meeting.map((obj) => {
          return obj.user.first_name + " " + obj.user.last_name + ",";
        });

        var cons_name = array.join(" ");

        return <div>{cons_name ? cons_name.slice(0, -1) : "-"}</div>;
      },
    },
    {
      Header: "Actions",
      maxWidth: 70,
      minWidth: 50,
      width: 70,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>
          {/* <Icon path={mdiBriefcase} size={1} /> */}
          <Button variant="link" onClick={btnShowPurpose(data)} >
            <Icon path={mdiMessage} />
          </Button>
        </div>;
      },
    }
  ];

  const btnShowPurpose = (obj) => () => {
    // console.log("obj", obj)
    setShowObjective(true);

    meetState.txtnotes = obj.objective_meeting;
  };

  const btnCloseObjective = () => {
    setShowObjective(false);
    setMeetState({
      txtnotes: ""
    });
  };

  // Start handle events
  const handlePersonInvolved = (e) => {
    setSelPersonInvolved(
      Array.isArray(e)
        ? e.map((x) => ({
            id: x.id,
            first_name: x.first_name,
            last_name: x.last_name,
          }))
        : []
    );
  };

  const handleMeetingType = (e) => {
    setTypeMeet(e.target.value);
  };

  // End handle events

  // start Checking Error Form
  const formErrorsMeeting = (object, array_person) => {
    const newErrors = {};

    if (!object.txtMeetingName || object.txtMeetingName === "")
      newErrors.txtMeetingName = "Fill in the blank!";

    if (!object.ddlMeetingType || object.ddlMeetingType === "")
      newErrors.ddlMeetingType = "Fill in the blank!";

    if (!object.txtMeetingDate || object.txtMeetingDate === "")
      newErrors.txtMeetingDate = "Fill in the blank!";

    if (!object.txtMeetingTime || object.txtMeetingTime === "")
      newErrors.txtMeetingTime = "Fill in the blank!";

    if (array_person.length === 0) {
      newErrors.ddlPersonInv = "Fill in the blank!";
    }

    return newErrors;
  };
  // end Checking Error Form

  // start button events
  const btnSaveMeeting = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtMeeting = Object.fromEntries(data.entries());

    const newErrors = formErrorsMeeting(dtMeeting, selPersonInvolved);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      customSwal
        .fire({
          title: "Do you want to request new meeting?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const object = {
              name: dtMeeting.txtMeetingName,
              type: dtMeeting.ddlMeetingType,
              date_meeting: dtMeeting.txtMeetingDate,
              time_meeting: dtMeeting.txtMeetingTime,
              location:
                dtMeeting.ddlMeetingType !== "phone"
                  ? dtMeeting.txtMeetingLocation
                  : "",
              staff_involve: selPersonInvolved.map((el) => el.id),
              objective_meeting: dtMeeting.txtMeetingObjective,
            };

            // console.log("object", user.clientid, user.sub, object)
            dispatch(createMeeting(user.clientid, user.sub, object));

            customSwal.fire({
              title: "Meeting Saved Successfully!",
              icon: "success",
              confirmButtonText: "Okay",
            });

            document.getElementById("meeting-form").reset();
            setSelPersonInvolved([]);
            setErrorForm({});
          }
        });
    }
  };
  //  end button events

  useEffect(() => {
    dispatch(getListStaff());
    dispatch(getListOfMeeting(user.clientid, user.sub));
    dispatch(storeTitle({title: "Meeting", icon: mdiMessagePlus}));
  }, [dispatch, user.clientid, user.sub]);

  return (
    <div className="main-div list-cli">
      <div
        style={{
          display: "flex",
        }}
      >
        <div style={{ flex: 2}}>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Request a meeting ?</Accordion.Header>
              <Accordion.Body>
                <Form id="meeting-form" onSubmit={btnSaveMeeting}>
                  {/* 1 */}
                  <Row>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Meeting Name
                        </Form.Label>
                        <Col>
                          <Form.Control
                            name="txtMeetingName"
                            type="text"
                            placeholder="Enter meeting name"
                            isInvalid={!!errorForm.txtMeetingName}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errorForm.txtMeetingName}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>

                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Meeting Type
                        </Form.Label>
                        <Col>
                          <Form.Select
                            name="ddlMeetingType"
                            isInvalid={!!errorForm.ddlMeetingType}
                            onChange={handleMeetingType}
                          >
                            <option value="">Please select</option>
                            <option value="phone">Phone discussion</option>
                            <option value="online">Online</option>
                            <option value="f2f">Face-to-face</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errorForm.ddlMeetingType}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* 2 */}
                  <Row>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Date
                        </Form.Label>
                        <Col>
                          <Form.Control
                            name="txtMeetingDate"
                            type="date"
                            isInvalid={!!errorForm.txtMeetingDate}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errorForm.txtMeetingDate}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          Time
                        </Form.Label>
                        <Col>
                          <Form.Control
                            name="txtMeetingTime"
                            type="time"
                            isInvalid={!!errorForm.txtMeetingTime}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errorForm.txtMeetingTime}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* 3 */}
                  {typeMeet !== "phone" ? (
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column lg={2}>
                        Location
                      </Form.Label>
                      <Col>
                        <Form.Control
                          as="textarea"
                          name="txtMeetingLocation"
                          type="text"
                          placeholder="Enter location (address, meeting url... etc.)"
                          rows={3}
                        />
                      </Col>
                    </Form.Group>
                  ) : (
                    ""
                  )}

                  {/* 4 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column lg={2}>
                      Person Involved
                    </Form.Label>
                    <Col>
                      <Searchable
                        isMulti={true}
                        name="ddlStaff"
                        options={dataStaff}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) =>
                          option.first_name + " " + option.last_name
                        }
                        errorText={errorForm.ddlPersonInv}
                        isInvalid={!!errorForm.ddlPersonInv}
                        onChange={handlePersonInvolved}
                        value={selPersonInvolved}
                      ></Searchable>
                    </Col>
                  </Form.Group>

                  {/* 5 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column lg={2}>
                      Purpose of the meeting
                    </Form.Label>
                    <Col>
                      <Form.Control
                        as="textarea"
                        name="txtMeetingObjective"
                        rows={3}
                      />
                    </Col>
                  </Form.Group>

                  <div className="row-btn-bottom">
                    <Button variant="primary" type="submit" form="meeting-form">
                      Send Request
                    </Button>
                  </div>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>

        {/* {getRequest ? (
          <div style={{ flex: 1 }}>
            <Card style={{ height: "100%" }}>
              <Card.Body>
                <div className="meeting-header">
                  <Icon path={mdiAlertBox} size={1} color="#DF4759" />
                  <div className="sub-text" style={{ color: "#DF4759" }}>
                    You have <b>3</b> meeting request!
                  </div>
                </div>

                <div className="meeting-info">
                  {meetingList.map((meeting, index) => {
                    return (
                      <div>
                        <div className="sub-text" style={{ color: "#54595F" }}>
                          Irine Tan
                        </div>
                        <div className="sub-text" style={{ color: "#54595F" }}>
                          {meeting.meeting_date}, {meeting.meeting_time}
                        </div>
                        <div
                          className="sub-text"
                          style={{ paddingTop: "10px" }}
                        >
                          {meeting.meeting_name}
                        </div>
                        <div
                          className="sub-text"
                          style={{
                            color: "#54595F",
                            textAlign: "justify",
                            fontWeight: 400,
                          }}
                        >
                          This is the purpose of the meeting description. Using
                          free text, description need to be less than 500
                          characters.
                        </div>

                        <div className="meeting-btn-row">
                          <div
                            className="sub-text"
                            style={{
                              fontWeight: 600,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Are you attending?
                          </div>
                          <div className="meeting-btn">
                            <Button
                              variant="info"
                              style={{ boxShadow: "none" }}
                            >
                              Accept
                            </Button>
                            <Button variant="danger">Decline</Button>
                          </div>
                        </div>

                        <hr style={{ marginBottom: 0 }}></hr>
                      </div>
                    );
                  })}
                </div>
              </Card.Body>
            </Card>
          </div>
        ) : null} */}
      </div>

      <div style={{ marginTop: "30px" }}>
        {meetingList.length > 0 ? (
          <CustomTable headers={headers} items={meetingList}></CustomTable>
        ) : (
          <Empty />
        )}
      </div>

      {/* Modal show purpose meeting */}
      <Modal
        show={showObjective}
        size="md"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseObjective}
        title="Purpose of the meeting"
        content={
          <>
              <Form.Group className="mb-3">
                <Form.Control
                  as="textarea"
                  name="txtnotes"
                  defaultValue={meetState.txtnotes}
                  rows={4}
                />
              </Form.Group>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseObjective}>
              Close
            </Button>
          </>
        }
      />
    </div>
  );
};

export default Meeting;
