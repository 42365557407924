import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Api from "../../../../core/services/api.service";
import moment from "moment";

import { Card, Row, Form, Col, Button, Dropdown } from "react-bootstrap";

import Icon from "@mdi/react";
import {
  mdiAccountPlus,
  mdiDotsHorizontal,
  mdiPencil,
  mdiDelete,
  mdiArrowLeft,
} from "@mdi/js";

import { CustomTable } from "../../../../components/Table/Table";
import Popup from "../../../../components/Popup/ModalPopup";
import BreadCrumb from "../../../../components/Breadcrumb/BreadCrumb";
import Searchable from "../../../../components/DropDown/SearchableSelect";
import SwalAlert from "../../../../components/Swal/SwalAlert";

import {
  getClientDetails,
  getClientStaff,
} from "../../../../store/actions/clientSlice";
import { getListStaff } from "../../../../store/actions/staffSlice";
import { getListCompanyNonParent } from "../../../../store/actions/companySlice";

const ConvertClient = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const { clientDetails, clientListStaff } = useSelector(
    (state) => state.client
  );
  const { dataStaff } = useSelector((state) => state.staff);
  const { dataCountry } = useSelector((state) => state.option);
  const { companyNonParent } = useSelector((state) => state.company);

  const customSwal = SwalAlert();

  const [crumbs, setCrumbs] = useState([]);

  const [selectedStaff, setSelectedStaff] = useState([]);
  const [selectedSubs, setSelectedSubs] = useState([]);
  const [selectedContact, setSelectedContact] = useState([]);
  const [parent, setParent] = useState(false);

  const [showOpt, setShowOpt] = useState(false);
  const [modeModal, setModeModal] = useState({
    title: null,
    button: null,
  });

  // Assign Variable
  const [assignStatus, setAssignStatus] = useState("");
  const [errorsAssign, setErrorsAssign] = useState({});
  const [assignList, setAssignList] = useState([]);
  const [txtAssign] = useState({
    txtService: "",
    txtAssignName: "",
    txtCountry: "",
  });

  const [errorsForm, setErrorsForm] = useState({});

  const selectedCrumb = (crumb) => {
    if(crumb === "Potential Clients"){
      navigate("/clients/listPotentialC");
    }else if(crumb === clientDetails.name){
      navigate("/clients/view-potential-client/" + params.id);
    }
  };

  // Form Error
  const formErrorAssign = (object) => {
    const newErrors = {};

    if (!object.txtService || object.txtService === "")
      newErrors.txtService = "Please select type of service!";

    if (!object.txtAssignName || object.txtAssignName === "")
      newErrors.txtAssignName = "Fill in the blank!";

    if (!object.txtCountry || object.txtCountry === "")
      newErrors.txtCountry = "Fill in the blank!";

    return newErrors;
  };

  const formError = (object) => {
    const newErrors = {};

    if (!object.ddlAssign || object.ddlAssign === "")
      newErrors.ddlAssign = "Fill in the blank!";

    if (!object.ddlContactPerson || object.ddlContactPerson === "")
      newErrors.ddlContactPerson = "Fill in the blank!";

    // if (object.grpParent === "true") {
    //   if (!object.ddlSubsidiary || object.ddlSubsidiary === "")
    //     newErrors.ddlSubsidiary = "Fill in the blank!";
    // }

    return newErrors;
  };

  // button event
  const btnCloseOpt = () => {
    setShowOpt(false);
  };

  const btnAssignment = (id) => (e) => {
    setShowOpt(true);
    setErrorsAssign({});

    if (id === "Add") {
      txtAssign.txtService = "";
      txtAssign.txtAssignName = "";
      txtAssign.txtCountry = "";
      setSelCountry();

      setModeModal({
        title: "Add Assignment",
        button: "Add",
      });

      setAssignStatus("New");
    } else {
      setModeModal({
        title: "Edit Assignment",
        button: "Update",
      });

      const filteredAssign = assignList.filter((assign) => {
        return assign.id === id;
      });

      // console.log("filteredAssign", filteredAssign)

      txtAssign.txtService = filteredAssign[0].type;
      txtAssign.txtAssignName = filteredAssign[0].title;
      txtAssign.txtCountry = filteredAssign[0].location;
      setSelCountry(filteredAssign[0].country);

      setAssignStatus(id);
    }
  };

  const btnSaveUpdateAssign = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dataAssign = Object.fromEntries(data.entries());

    const newErrors = formErrorAssign(dataAssign);

    // console.log("selCountry", selCountry)

    if (Object.keys(newErrors).length > 0) {
      setErrorsAssign(newErrors);
    } else {
      if (assignStatus === "New") {
        const assignLength = assignList.length;

        setAssignList((items) => [
          ...items,
          {
            id: assignLength + 1,
            title: dataAssign.txtAssignName,
            location: dataAssign.txtCountry,
            type: dataAssign.txtService,
            country: selCountry,
          },
        ]);

        setShowOpt(false);
      } else {
        const filteredAssign = assignList.filter((assign) => {
          return assign.id === assignStatus;
        });

        // console.log("filteredAssign", filteredAssign);

        filteredAssign[0].title = dataAssign.txtAssignName;
        filteredAssign[0].location = dataAssign.txtCountry;
        filteredAssign[0].type = dataAssign.txtService;
        filteredAssign[0].country = selCountry;

        setShowOpt(false);
      }
    }
  };

  const createAssign = (object) => {
    const assignLatest = [];

    object.forEach(function (assign) {
      assignLatest.push({
        title: assign.title,
        location: Number(assign.location),
        type: assign.type
      });
    });

    return assignLatest;
  };

  const btnConvert = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dataConvert = Object.fromEntries(data.entries());

    const newErrors = formError(dataConvert);

    // console.log("assignList", assignList)

    const newAssignArray = createAssign(assignList);

    // console.log("dataConvert", dataConvert);
    // console.log("selectedStaff", selectedStaff);
    // console.log("selectedSubs", selectedSubs);
    // console.log("selectedContatc", selectedContact);
    // console.log("newAssignArray", newAssignArray)

    let isParent = dataConvert.grpParent === "true";

    if (Object.keys(newErrors).length > 0) {
      setErrorsForm(newErrors);
    } else {
      if (assignList.length === 0) {
        customSwal.fire({
          title: "Please Fill In List of Assignment!",
          icon: "warning",
          confirmButtonText: "Okay",
        });
      } else {
        customSwal
          .fire({
            title: "Do you want to convert this client?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post("client/" + params.id + "/proposal/convert", {
                consultant_name: selectedStaff,
                is_parent: isParent,
                parent_id: isParent ? selectedSubs : [],
                is_potential: false,
                contact_person: selectedContact,
                assignment: newAssignArray,
              })
                .then((response) => {
                  // setSelectedIndustry([]);

                  // console.log("btnConvert", response);

                  //close modal after update
                  navigate("/clients/listActiveC");
                  customSwal.fire({
                    title: "Client Converted Successfully!",
                    icon: "success",
                    confirmButtonText: "Okay",
                  });
                })
                .catch((error) => {
                  console.log("error btnConvert", error);
                });
            }
          });
      }
    }
  };

  const btnBack = () => {
    navigate("/clients/view-potential-client/" + params.id);
  };
  //

  const changeStaff = (e) => {
    // let value = e;
    setSelectedStaff(Array.isArray(e) ? e.map((x) => x.id) : []);
  };

  const changeSubsidiary = (e) => {
    setSelectedSubs(Array.isArray(e) ? e.map((x) => x.id) : []);
  };

  const changeContact = (e) => {
    setSelectedContact(Array.isArray(e) ? e.map((x) => x.id) : []);
  };

  const handleParent = (e) => {
    setParent(e.target.value);
  };

  const [selCountry, setSelCountry] = useState({});

  const handleCountry = (e) => {
    // console.log("e", e)
    setSelCountry(e);
  };

  const headerAssignment = [
    {
      Header: "No",
      accessor: (row) => `${row.index}`,
      maxWidth: 50,
      minWidth: 10,
      width: 40,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Assignment Name",
      accessor: (row) => `${row.title}`,
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{data.title}</div>;
      },
    },
    {
      Header: "Assignment Type",
      accessor: (row) => `${row.type}`,
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        let nameType = "";

        if(data.type === "ES"){
          nameType = "Executive Search";
        }else if(data.type === "TM"){
          nameType = "Transition Management"
        }else{
          nameType = "Distruption Management"
        }

        return <div>{nameType}</div>;
      },
    },
    {
      Header: "Country",
      accessor: (row) => `${row.country.value}`,
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{data.country.value}</div>;
      },
    },
    {
      Header: "",
      accessor: "actions",
      maxWidth: 100,
      minWidth: 80,
      width: 80,
      Cell: ({ row }) => {
        const data = row.original;

        const btnDelAssign = (e) => {
          setAssignList((assign) =>
            assign.filter((assignList) => {
              // 👇️ remove object that has id equal to 2
              return assignList.id !== data.id;
            })
          );
        };

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={btnAssignment(data.id)}>
                  <Icon path={mdiPencil} color="#467FD0" />
                  Edit Assignment
                </Dropdown.Item>
                <Dropdown.Item onClick={btnDelAssign}>
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete Assignment
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getClientDetails(params.id));
    dispatch(getListStaff());

    setCrumbs(["Potential Clients", clientDetails.name, "Convert to Client"]);

    dispatch(getClientStaff(params.id));
    dispatch(getListCompanyNonParent(params.id));
  }, [clientDetails.name, dispatch, params.id]);

  return (
    <div className="main-div">
      <div className="container-div both-side">
        <BreadCrumb crumbs={crumbs} selected={selectedCrumb}></BreadCrumb>
        <Button variant="secondary" onClick={() => navigate(-1)}>
          <Icon path={mdiArrowLeft} />
          Back
        </Button>
      </div>
      <Card>
        <Card.Body>
          <Form id="convert-form" onSubmit={btnConvert}>
            <Row className="convert-cli">
              <Col xs={6}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={4}>
                    Creation Date
                  </Form.Label>
                  <Col>
                    <Form.Control
                      placeholder={moment().format("dddd, DD MMMM YYYY")}
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={4}>
                    Status
                  </Form.Label>
                  <Col>
                    <Form.Control placeholder="In Progress" readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Assign To <label className="validation-star">*</label>
              </Form.Label>
              <Col>
                <Searchable
                  name="ddlAssign"
                  isMulti={true}
                  options={dataStaff}
                  onChange={changeStaff}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) =>
                    option.first_name + " " + option.last_name
                  }
                  isInvalid={!!errorsForm.ddlAssign}
                  errorText={errorsForm.ddlAssign}
                ></Searchable>
              </Col>
            </Form.Group>

            <Row>
              <Col xs={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Form.Label column lg={4}>
                    Is this a parent company?{" "}
                    <label className="validation-star">*</label>
                  </Form.Label>
                  <Col>
                    <div className="radio-btn-row">
                      <Form.Check
                        reverse
                        label="Yes"
                        name="grpParent"
                        value={true}
                        type="radio"
                        onChange={handleParent}
                        required
                        // id={`reverse-${type}-1`}
                      />
                      <Form.Check
                        reverse
                        label="No"
                        name="grpParent"
                        type="radio"
                        value={false}
                        onChange={handleParent}
                        required
                        // id={`reverse-${type}-2`}
                      />
                    </div>
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={6}>
                {parent === "true" ? (
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Form.Label column lg={4}>
                      Choose subsidiary (if any)
                    </Form.Label>
                    <Col>
                      <Searchable
                        name="ddlSubsidiary"
                        isMulti={true}
                        options={companyNonParent}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        onChange={changeSubsidiary}
                        // isInvalid={!!errorsForm.ddlSubsidiary}
                        // errorText={errorsForm.ddlSubsidiary}
                      ></Searchable>
                    </Col>
                  </Form.Group>
                ) : null}
              </Col>
            </Row>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Contact Person <label className="validation-star">*</label>
              </Form.Label>
              <Col>
                <Searchable
                  name="ddlContactPerson"
                  isMulti={true}
                  options={clientListStaff}
                  onChange={changeContact}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) =>
                    option.first_name + " " + option.last_name
                  }
                  isInvalid={!!errorsForm.ddlContactPerson}
                  errorText={errorsForm.ddlContactPerson}
                ></Searchable>
              </Col>
            </Form.Group>
          </Form>

          <Card>
            <Card.Body className="card-in-card list-cli">
              <div className="both-side">
                <div className="title-text">
                  List of Assignment{" "}
                  <label className="validation-star">*</label>
                </div>
                <Button
                  variant="link"
                  className="modal-link"
                  onClick={btnAssignment("Add")}
                >
                  <Icon path={mdiAccountPlus} />
                  <div>Add Assignment</div>
                </Button>
              </div>

              {/* Modal Add and Edit Assignment */}
              <Popup
                show={showOpt}
                size="lg"
                backdrop="static"
                keyboard={false}
                onHide={btnCloseOpt}
                title={modeModal.title}
                content={
                  <>
                    <Form id="assignment-form" onSubmit={btnSaveUpdateAssign}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label className="col-3">
                          Service <label className="validation-star">*</label>
                        </Form.Label>
                        <Col sm="9">
                          <Form.Select
                            name="txtService"
                            defaultValue={txtAssign.txtService}
                            isInvalid={!!errorsAssign.txtService}
                          >
                            <option value="">Select</option>
                            <option value="ES">Executive Search</option>
                            <option value="TM">Transition Management</option>
                            <option value="DM">Distruption Management</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errorsAssign.txtService}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={3}>
                          Assignment Name{" "}
                          <label className="validation-star">*</label>
                        </Form.Label>
                        <Col>
                          <Form.Control
                            name="txtAssignName"
                            type="text"
                            defaultValue={txtAssign.txtAssignName}
                            placeholder="Please enter assignment name"
                            isInvalid={!!errorsAssign.txtAssignName}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errorsAssign.txtAssignName}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={3}>
                          Country <label className="validation-star">*</label>
                        </Form.Label>
                        <Col>
                          <Searchable
                            name="txtCountry"
                            options={dataCountry}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.value}
                            onChange={handleCountry}
                            value={selCountry}
                            isInvalid={!!errorsAssign.txtCountry}
                            errorText={errorsAssign.txtCountry}
                          ></Searchable>
                        </Col>
                      </Form.Group>
                    </Form>
                  </>
                }
                button={
                  <>
                    <Button variant="secondary" onClick={btnCloseOpt}>
                      Cancel
                    </Button>
                    <Button
                      form="assignment-form"
                      type="submit"
                      variant="primary"
                    >
                      {modeModal.button}
                    </Button>
                  </>
                }
              />
              {assignList.length > 0 ? (
                <CustomTable
                  headers={headerAssignment}
                  items={assignList}
                ></CustomTable>
              ) : null}
            </Card.Body>
          </Card>

          {/* <Card>
            <Card.Body className="card-in-card">
              <div className="both-side">
                <div className="title-text">
                  Critical Competencies{" "}
                  <label className="validation-star">*</label>
                </div>
                <Button
                  variant="link"
                  className="modal-link"
                  onClick={btnAddComp("Add")}
                >
                  <Icon path={mdiNotePlus} size={0.8} />
                  Add Competencies
                </Button>
              </div>

              <Popup
                show={showComp}
                size="lg"
                backdrop="static"
                keyboard={false}
                onHide={btnCloseComp}
                title={modeModal.title}
                content={
                  <>
                    <Form id="critical-form" onSubmit={btnSaveUpdateCritical}>
                      <Form.Control
                        as="textarea"
                        name="txtCritical"
                        rows={3}
                        isInvalid={!!errorsCritical.txtCritical}
                        defaultValue={convCritical.txtCritical}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorsCritical.txtCritical}
                      </Form.Control.Feedback>
                    </Form>
                  </>
                }
                button={
                  <>
                    <Button variant="secondary" onClick={btnCloseComp}>
                      Cancel
                    </Button>
                    <Button
                      form="critical-form"
                      type="submit"
                      variant="primary"
                    >
                      {modeModal.button}
                    </Button>
                  </>
                }
              />

              <Table headers={headerCompetencies} items={criticalList}></Table>
            </Card.Body>
          </Card> */}

          <div className="row-btn-center list-cli">
            <Button onClick={btnBack} variant="secondary">
              Cancel
            </Button>
            <Button form="convert-form" variant="primary" type="submit" disabled={assignList.length > 0 ? false : true}>
              Convert to client
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ConvertClient;
