import React from "react";
// import ReactDOM from "react-dom";
// import { BrowserRouter } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import App from "./App";
// import Login from '../src/pages/Admin/Auth/Login/Admin';

import { Provider } from "react-redux";
import store from "./app/store";

import { createRoot } from "react-dom/client";

const config = {
  auth: {
    clientId: "b319f54c-62ed-46b4-88e8-5e1fb372978e",
    authority:
      "https://login.microsoftonline.com/3c0e7389-dc77-4938-8b74-1fdef6857ff9",
    redirectUri: "https://jesper.limlogesmasters.com",
    postLogoutRedirectUri: "https://jesper.limlogesmasters.com/calendar",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// create PublicClientApplication instance
const publicClientApplication = new PublicClientApplication(config);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  // <React.StrictMode>
  <MsalProvider instance={publicClientApplication}>
    <Provider store={store}>
      <App />
    </Provider>
  </MsalProvider>
  // </React.StrictMode>
);

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>
//   ,
//   document.getElementById('root')
// );
