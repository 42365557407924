import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Card, Button, Dropdown, Form, Row } from "react-bootstrap";
import Apiform from "../../core/services/apiform.service";
import Api from "../../core/services/api.service";

import SwalAlert from "../Swal/SwalAlert";
import moment from "moment";
import FileSaver from "file-saver";

import Icon from "@mdi/react";
import {
  mdiFilePlus,
  mdiDotsHorizontal,
  mdiFileSend,
  mdiDelete,
  mdiDownload,
  mdiMagnify,
  mdiViewAgenda,
} from "@mdi/js";

import CustomTable from "../Table/Table";
import Modal from "../Popup/ModalPopup";
import TextboxIcon from "../TextField/TextFieldIcon";
import Viewer from "../Viewer/Viewer";

function TabTraining() {
  const customSwal = SwalAlert();
  const params = useParams();

  const [showMaterial, setShowMaterial] = useState(false);
  const [errorForm, setErrorForm] = useState({});

  const [listMaterial, setListMaterial] = useState([]);

  const [participantSelected, setParticipantSelected] = useState(0);
  const [checkParticipant, setCheckParticipant] = useState([]);
  const [listParticipant, setListParticipant] = useState([]);
  const [allListParticipant, setAllListParticipant] = useState([]);
  const [showSend, setShowSend] = useState(false);
  const [fileName, setFileName] = useState();

  // PDF useState
  const [pageNumber, setPageNumber] = useState(1);
  const [showPdf, setShowPdf] = useState(false);
  const [filePath, setFilePath] = useState("");
  const [modeModal, setModeModal] = useState({
    title: "",
    Button: "",
  });

  const btnCloseMaterial = () => {
    setShowMaterial(false);
  };

  const btnOpenMaterial = () => {
    setShowMaterial(true);
    setErrorForm({});
  };

  const btnClosePdf = () => {
    setShowPdf(false);
    setPageNumber(1);
  };

  const formError = (object) => {
    const newErrors = {};

    // console.log("object", object);

    if (!object.txtmaterial || object.txtmaterial === "")
      newErrors.txtmaterial = "Fill in the blank!";

    if (!object.txtfile.name || object.txtfile.name === "")
      newErrors.txtfile = "Fill in the blank!";

    return newErrors;
  };

  const btnSaveMaterial = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtMat = Object.fromEntries(data.entries());

    // console.log("dtMat", dtMat)
    const newErrors = formError(dtMat);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      setErrorForm({});

      customSwal
        .fire({
          title: "Do you want to upload training material ?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();

            formData.append("file_name", dtMat.txtmaterial);
            formData.append("file", dtMat.txtfile);

            Apiform.post(
              "client/" +
                params.id +
                "/assignment/" +
                params.idassign +
                "/tmdm/uploadMaterial",
              formData
            )
              .then((response) => {
                // dispatch(getClientDetails(params.id));
                getListMaterial();
                setShowMaterial(false);
                customSwal.fire({
                  title: "File Uploaded Successful!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("error upload training material", error);
              });
          }
        });
    }
  };

  const btnDelete = (obj) => (e) => {
    // console.log("obj", obj)
    customSwal
      .fire({
        title: "Do you want to delete this training material?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete(
            "client/" +
              params.id +
              "/assignment/" +
              params.idassign +
              "/tmdm/deleteMaterial/" +
              obj.id
          )
            .then((response) => {
              getListMaterial();
              customSwal.fire({
                title: "Deleted Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error delete training material", error);
            });
        }
      });
  };

  const header = [
    {
      Header: "Material Name",
      accessor: (row) => `${row.file_name}`,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return <div className="form-container-default">{data.file_name}</div>;
      },
    },
    {
      Header: "Upload Date",
      accessor: (row) => `${row.created_at}`,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="form-container-default">
            {moment(data.created_at).format("DD MMMM YYYY")}
          </div>
        );
      },
    },
    {
      Header: "",
      accessor: "actions",
      minWidth: 80,
      width: 80,
      Cell: ({ row }) => {
        const data = row.original;

        function btnDownload() {
          FileSaver.saveAs(data.file_path, "download.pdf");
        }

        function btnPreview() {
          setFilePath(data.file_path);
          setShowPdf(true);

          setModeModal({
            title: "View Material",
            button: "",
          });
        }

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={btnPreview}>
                  <Icon path={mdiViewAgenda} color="#467FD0" />
                  Preview File
                </Dropdown.Item>
                <Dropdown.Item onClick={btnDownload}>
                  <Icon path={mdiDownload} color="#467FD0" />
                  Download File
                </Dropdown.Item>
                <Dropdown.Item onClick={btnOpenSendParticipant(data.file_name)}>
                  <Icon path={mdiFileSend} color="#467FD0" />
                  Send to Participant
                </Dropdown.Item>
                <Dropdown.Item onClick={btnDelete(data)}>
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete Material
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const headerSendParticipant = [
    {
      Header: "Participant Name",
      minWidth: 100,
      width: 200,
      accessor: (row) => `${row.first_name}`,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="form-container-default">
            {data.last_name
              ? data.first_name + " " + data.last_name
              : data.first_name}
          </div>
        );
      },
    },
    {
      Header: "Country",
      accessor: (row) => `${row.citizenship_main}`,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="form-container-default">
            {data.citizenship_main ? data.citizenship_main : "-"}
          </div>
        );
      },
    },
    {
      Header: "Job Title",
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;
        // console.log("data", data);

        let jobTitle = "";

        if (data.candidate_experiences.length > 0) {
          jobTitle = data.candidate_experiences[0].title;
        } else {
          jobTitle = data.job_title ? data.job_title : "-";
        }

        return <>{jobTitle}</>;
      },
    },
    {
      Header: "",
      accessor: "selection",
      disableSortBy: true,
      minWidth: 10,
      width: 50,
      Cell: ({ row }) => {
        const data = row.original;

        const onItemCheck = (data) => (event) => {
          if (checkParticipant.length > 0) {
            if (event.target.checked === false) {
              const index = checkParticipant.findIndex(function (chck) {
                return chck.id === data.id;
              });

              checkParticipant.splice(index, 1);
            } else {
              checkParticipant.push(data);
            }
          } else {
            checkParticipant.push(data);
          }

          setParticipantSelected(checkParticipant.length);

          // console.log("checkParticipant", checkParticipant);
        };

        const checkedItem = (id) => {
          let ptp = [];

          const checkPtp = checkParticipant.filter((chec) => {
            return chec.id === id;
          });

          ptp = checkPtp[0];

          return ptp;
        };

        return (
          <div className="checkbox-container">
            <input
              type="checkbox"
              checked={checkedItem(data.id)}
              id={"row" + data.id}
              onChange={onItemCheck(data)}
            />
          </div>
        );
      },
    },
  ];

  const btnSendEmail = (e) => {
    let email = "";
    // let subject = fileName;

    // console.log("subject", subject);

    var participant = checkParticipant.map((p) => {
      // console.log(p);
      if (p.comp_email !== "" && p.comp_email !== null) {
        email = p.comp_email;
      } else if (p.email !== "" && p.email !== null) {
        email = p.email;
      }

      return email;
    });

    // console.log("participant", participant);

    var multi_email = participant.map((mail) => {
      // console.log(mail);
      return mail + ";";
    });

    var arr_email = multi_email.join("").slice(0, -1);
    // console.log("arr_email", arr_email);
    // console.log("mailto:" + arr_email + "?subject=" + fileName);

    customSwal
      .fire({
        title: "Send training material to selected participant?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          window.location = "mailto:" + arr_email + "?subject=" + fileName;

          setShowSend(false);
          getListParticipant();
          setCheckParticipant([]);
          setParticipantSelected(0);
        }
      });
  };

  const btnOpenSendParticipant = (file_name) => (e) => {
    setShowSend(true);
    setFileName(file_name);
    getListParticipant();
    setCheckParticipant([]);
    setParticipantSelected(0);
  };

  const btnCloseSendParticipant = () => {
    setShowSend(false);
  };

  const handleSearchParticipant = (event) => {
    let value = event.target.value.toLowerCase();

    let newList = [];

    if (value !== "") {
      // let newList = [];
      newList = listParticipant.filter((candidate) => {
        if (!candidate.last_name || candidate.last_name === "") {
          return candidate.first_name
            .toLowerCase()
            .includes(value.toLowerCase());
        } else {
          return (
            candidate.first_name.toLowerCase().includes(value.toLowerCase()) ||
            candidate.last_name.toLowerCase().includes(value.toLowerCase())
          );
        }
      });

      setAllListParticipant(newList);
    } else {
      setAllListParticipant(listParticipant);
    }
  };

  const getListParticipant = useCallback(() => {
    Api.get(
      "client/" +
        params.id +
        "/assignment/" +
        params.idassign +
        "/tmdm/participant/all"
    )
      .then((response) => {
        let data = response.data;
        // console.log("data", data);

        setListParticipant(data);
        setAllListParticipant(data);

        // if (data !== "no data") {
        //   setListProposal(data);
        // }
      })
      .catch((error) => {
        console.log("error getListParticipant", error);
      });
  }, [params]);

  const getListMaterial = useCallback(() => {
    Api.get(
      "client/" +
        params.id +
        "/assignment/" +
        params.idassign +
        "/tmdm/trainingMaterial"
    )
      .then((response) => {
        let data = response.data;
        // console.log("data", data);
        setListMaterial(data);
      })
      .catch((error) => {
        console.log("error getListMaterial", error);
      });
  }, [params]);

  useEffect(() => {
    getListMaterial();
    getListParticipant();
  }, [getListMaterial, getListParticipant]);

  return (
    <>
      <Card>
        <Card.Body>
          <div className="row-div">
            <div className="left-div"></div>
            <div className="right-div">
              <Button
                variant="link"
                className="modal-link"
                onClick={btnOpenMaterial}
              >
                <Icon path={mdiFilePlus} />
                Upload Material
              </Button>
            </div>
          </div>

          <CustomTable
            headers={header}
            items={listMaterial}
            paginate={listMaterial.length}
            maxRows={15}
          ></CustomTable>
        </Card.Body>
      </Card>

      {/* Modal Upload Other */}
      <Modal
        show={showMaterial}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseMaterial}
        title="Upload Training Material"
        content={
          <>
            <Form id="upload-material" onSubmit={btnSaveMaterial}>
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label className="col-3">Material Name</Form.Label>
                  <div className="col-9" style={{ display: "inline-flex" }}>
                    <Form.Control
                      name="txtmaterial"
                      type="text"
                      placeholder="Please enter material name"
                      isInvalid={!!errorForm.txtmaterial}
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {errorForm.txtmaterial}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label className="col-3">Upload Material</Form.Label>
                  <div className="col-9" style={{ display: "inline-flex" }}>
                    <Form.Control
                      name="txtfile"
                      type="file"
                      isInvalid={!!errorForm.txtfile}
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {errorForm.txtfile}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseMaterial}>
              Cancel
            </Button>
            <Button type="submit" variant="primary" form="upload-material">
              Upload
            </Button>
          </>
        }
      ></Modal>

      {/* Modal Send to Participant */}
      <Modal
        show={showSend}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseSendParticipant}
        title="Send to Participant"
        content={
          <>
            <div className="row-div">
              <div className="left-div">
                <TextboxIcon
                  name="txtsearchcandidate"
                  type="text"
                  placeholder="Search"
                  inputSize="input--medium"
                  onChange={(event) => handleSearchParticipant(event)}
                  icon={mdiMagnify}
                ></TextboxIcon>
              </div>
              <div className="right-div comp-selected">
                <div style={{ marginRight: "20px" }}>
                  {participantSelected} selected
                </div>
              </div>
            </div>
            <CustomTable
              headers={headerSendParticipant}
              items={allListParticipant}
              paginate={allListParticipant.length}
              maxRows={10}
            ></CustomTable>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseSendParticipant}>
              Cancel
            </Button>
            <Button
              type="submit"
              // form="infor-form"
              variant="primary"
              onClick={btnSendEmail}
              disabled={participantSelected > 0 ? false : true}
            >
              Send
            </Button>
          </>
        }
      ></Modal>

      {/* Modal Pdf */}
      <Viewer
        showPdf={showPdf}
        btnClosePdf={btnClosePdf}
        modalTitle={modeModal.title}
        filePath={filePath}
      />
    </>
  );
}

export default TabTraining;
