import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import Api from "../../../core/services/api.service";
import { useParams } from "react-router-dom";
import { Card, Button } from "react-bootstrap";

import moment from "moment";
import { jsPDF } from "jspdf";
// import HTMLtoDOCX from "html-to-docx";

import Icon from "@mdi/react";
import { mdiDownload } from "@mdi/js";

import { CustomTable } from "../../Table/Table";
import LogoHeader from "../../../assets/header_logo.jpg";

// import { JDForm } from "../../Report/Template/JDForm";
// import rptFooter from "../../Report/Template_Report/rptFooter";
// import rptHeader from "../../Report/Template_Report/rptHeader";
// import rptJobDescription from "../../Report/Template_Report/rptJobDescription";
// import font from "./calibri-regular-normal";
// import fontBold from "./calibri-bold-normal";

// import rptTestPdf from "../../Report/Template_Report/rptTestPdf";
// import { PDFDownloadLink } from "@react-pdf/renderer";

export const TabJDClient = () => {
  const params = useParams();
  const { user } = useSelector((state) => state.login);

  const [listJDCompleted, setListJDCompleted] = useState([]);

  const headerJD = [
    {
      Header: "No",
      maxWidth: 50,
      minWidth: 10,
      width: 40,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "File Name",
      accessor: "file_name",
      maxWidth: 400,
      minWidth: 100,
      width: 400,
    },
    {
      Header: "Date Created",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{moment(data.created_at).format("DD MMMM YYYY")}</div>;
      },
    },
    {
      Header: "",
      accessor: "actions",
      maxWidth: 180,
      minWidth: 80,
      width: 180,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <Button
            variant="link"
            onClick={btnDownload(data)}
            className="set-rem"
          >
            <Icon path={mdiDownload} />
            Download
          </Button>
        );
      },
    },
  ];

  const btnDownload = (data) => async () => {
    const doc = new jsPDF("p", "mm", [297, 210]);

    var pageHeight =
      doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    var pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    var getContent =
      `
      <div id="jd-content" style='width: 170px; height: 232px; font-family: sans-serif; word-spacing: normal;'>
    
        <div style='font-size: 4.5px; text-align:center;'><b>` +
      data.job_title +
      `</b></div>

        <div style='font-size:3.7px; padding-top:5px; text-align:left; text-transform: uppercase;'><b>Company Background</b></div>
        <div style='font-size:3.7px; padding:5px 0px; text-align:justify; text-align-last: left;'>` +
      data.company_background +
      `</div>

        <div style='font-size:3.7px; text-align:left; text-transform: uppercase;'><b>Description of Role</b></div>
        <div style='font-size:3.7px; padding:5px 0px; text-align:justify; text-align-last: left;'>` +
      data.purpose_job +
      `</div>

        <div style='font-size:3.7px; text-align:left; text-transform: uppercase;'><b>Key Accountabilities</b></div>
        <div style='font-size:3.7px; padding:5px 0px; text-align:justify; text-align-last: left;'>` +
      data.key_accountibility +
      `</div>

        <div style='font-size:3.7px; text-align:left; text-transform: uppercase;'><b>Desired Knowledge, Skills and Experience</b></div>
        <div style='font-size:3.7px; padding:5px 0px; text-align:justify; text-align-last: left;'>` +
      data.skill_experience +
      `</div>

        <div style='font-size:3.7px; text-align:left; text-transform: uppercase;'><b>Compensation</b></div>
        <div style='font-size:3.7px; padding:5px 0px; text-align:justify; text-align-last: left;'>` +
      data.compensation +
      `</div>

        <div style='font-size:3.7px; padding:5px 0px; text-align:justify; text-align-last: left;'>
        <b>For consideration as a candidate or to suggest a prospective candidate, please contact in confidence:</b> 
        <div style='padding-top: 5px'>` +
      data.contact_person +
      ` </div>
        </div>
      </div>
    `;

    // var totalPages = 10; // define total amount of pages

    doc.html(getContent, {
      margin: [35, 20, 25, 20],
      callback: function (doc) {
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          // Go to page i
          doc.setPage(i);
          doc.setFont("calibri-bold", "normal");

          //Print Page 1 of 4 for example
          doc.setFontSize(14);
          doc.text("Lim-Loges", 20, pageHeight - 280, "left");

          doc.setFontSize(14);
          doc.setTextColor(236, 120, 47);
          doc.text("&", 42, pageHeight - 280, "left");

          doc.setFontSize(14);
          doc.setTextColor(0, 0, 0);
          doc.text("Masters", 47, pageHeight - 280, "left");

          doc.setFontSize(9);
          doc.setTextColor(236, 120, 47);
          doc.text(
            "transforming companies, transforming career",
            20,
            pageHeight - 275,
            "left"
          );

          doc.setFontSize(8);
          doc.setTextColor(0, 0, 0);
          doc.text(
            "Disruption Management . Executive Search . Transition Management",
            20,
            pageHeight - 271,
            "left"
          );

          doc.addImage(LogoHeader, "JPEG", 170, pageHeight - 290, 27, 27);

          doc.setFontSize(8);
          doc.text(
            "Lim-Loges & Masters Pte Ltd (Company Reg. No. 201614475K) Address: 6 Battery Road, #30-01, Singapore 049909 Tel: +65 6725 6341",
            pageWidth / 2,
            pageHeight - 18,
            { align: "center" }
          );

          doc.setTextColor(236, 120, 47);
          doc.text(
            "www.LimLogesMasters.com China",
            pageWidth / 2 - 30,
            pageHeight - 14,
            { align: "center" }
          );

          doc.setTextColor(0, 0, 0);
          doc.text(
            "China . Hong Kong . India . Malaysia . Singapore",
            pageWidth / 2 + 20,
            pageHeight - 14,
            { align: "center" }
          );

          doc.text(String(i), pageWidth / 2, pageHeight - 10, {
            align: "center",
          });
        }

        doc.save(data.file_name + ".pdf");
      },
      html2canvas: {
        allowTaint: true,
        letterRendering: true,
        svgRendering: true,
      },
    });

    // // HEADER
    // // doc.setFontSize(20);//optional
    // // doc.setTextColor(40);//optional
    // // doc.setFontStyle('normal');//optional
    // doc.text("Some text", 74, 120);
    // // doc.addPage();
    // doc.text("Some text", 74, 150);
    // // doc.addPage();
    // doc.text("Some text", 74, 200);
    // // doc.addPage();
    // // doc.text("Last page", 74, 150);

    // const pageCount = doc.internal.getNumberOfPages();

    // // // FOOTER
    // for(var i = 1; i <= pageCount; i++) {
    //  // Go to page i
    //     doc.setPage(i);

    //     //Print Page 1 of 4 for example
    //     // doc.text("Centered text",{align: "center"},0,1);
    //     // doc.setTextColor(100);
    //     doc.setFontSize(8);
    //     doc.text("Lim-Loges & Masters Pte Ltd (Company Reg. No. 201614475K) Address: 6 Battery Road, #30-01, Singapore 049909 Tel: +65 6725 6341", pageWidth / 2, pageHeight  - 18, {align: 'center'},)
    //     doc.text("www.LimLogesMasters.com China . Hong Kong . India . Malaysia . Singapore", pageWidth / 2, pageHeight  - 14, {align: 'center'},);

    //     // doc.setFont("helvetica");
    //     // doc.setFontType("bold");
    //     // doc.text(20, 50, 'This is helvetica bold.');

    //     // doc.text("This is example paragraph", 11,13,).setFontSize(8).setFont(undefined, 'normal');
    //     // doc.text("Lim-Loges & Masters Pte Ltd (Company Reg. No. 201614475K) Address: 6 Battery Road, #30-01, Singapore 049909 Tel: +65 6725 6341", pageWidth / 2, pageHeight  - 15, {align: 'center'}).setFontSize(8);
    //     doc.text(String(i), pageWidth / 2, pageHeight  - 10, {align: 'center'});
    // }

    // doc.html(getContent, {
    //   callback: function (doc) {
    //     doc.save();
    //   },
    // });
    // doc.save( data.file_name + ".pdf");
    // const dataRpt = {
    //   job_title: data.job_title,
    //   company_background: data.company_background,
    //   purpose_job: data.purpose_job,
    //   key_accountibility: data.key_accountibility,
    //   skill_experience: data.skill_experience,
    //   compensation: data.compensation,
    //   contact_person: data.contact_person,
    // };

    // const htmlString = rptJobDescription(dataRpt);
    // const headerString = rptHeader();
    // const footerHTMLString = rptFooter();

    // const fileBuffer = await HTMLtoDOCX(
    //   htmlString,
    //   headerString,
    //   {
    //     table: { row: { cantSplit: true } },
    //     header: true,
    //     footer: true,
    //     pageNumber: true,
    //     margins: {
    //       top: 500,
    //       right: 1500,
    //       bottom: 500,
    //       left: 1500,
    //       header: 720,
    //       footer: 720,
    //       gutter: 0,
    //     },
    //     font: "Calibri (Body)",
    //   },
    //   footerHTMLString
    // );

    // console.log("fileConvert", fileBuffer)
    // var blob = new Blob([fileBuffer], {type: "application/pdf"});

    // // let blob = new Blob([htmlString], {type: 'text/html'});

    // console.log("fileConvert", blob)

    // FileSaver.saveAs(blob, data.file_name);
  };

  const getListJDComplete = useCallback(() => {
    Api.get(
      "client/" + user.clientid + "/assignment/" + params.idassign + "/jd"
    )
      .then((response) => {
        let data = response.data;

        if (data !== "no data") {
          let listJD = data.filter((grp) => grp.status.includes("completed"));

          let sortListJD = listJD.sort(function (a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
          });

          setListJDCompleted(sortListJD);
        }
      })
      .catch((error) => {
        console.log("error getListJDComplete", error);
      });
  }, [params, user.clientid]);

  useEffect(() => {
    getListJDComplete();

    // const interval=setInterval(()=>{
    //     getListJDComplete()
    //    },1000)

    // return()=>clearInterval(interval)
  }, [getListJDComplete]);

  return (
    <>
      {/* <PDFDownloadLink document={<rptTestPdf />} fileName="FORM">
        {({loading}) => (loading ? <button>download</button>:<button>download</button>)}
      </PDFDownloadLink> */}
      {/* <rptTestPdf/> */}
      <Card className="clientJD">
        <Card.Body>
          <CustomTable headers={headerJD} items={listJDCompleted} />
        </Card.Body>
      </Card>
    </>
  );
};
