import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Form, Col, Row, Button } from "react-bootstrap";
import Api from "../../../core/services/api.service";
import Apiform from "../../../core/services/apiform.service";

import moment from "moment";

import Icon from "@mdi/react";
import { mdiArrowLeft, mdiCalendar, mdiAccountGroup } from "@mdi/js";

import BreadCrumb from "../../../components/Breadcrumb/BreadCrumb";
import RichTextEditor from "../../../components/RichTextArea/RichTextEditor";
import Searchable from "../../../components/DropDown/SearchableSelect";
import SwalAlert from "../../../components/Swal/SwalAlert";
import Modal from "../../../components/Popup/ModalPopup";

import {
  getClientDetails,
  getClientStaff,
} from "../../../store/actions/clientSlice";
import { storeTitle } from "../../../store/actions/headerTitleSlice";
import { getListStaff } from "../../../store/actions/staffSlice";

const EditMeeting = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const customSwal = SwalAlert();

  const { clientDetails, clientListStaff } = useSelector(
    (state) => state.client
  );
  const { dataStaff } = useSelector((state) => state.staff);
  const { getTitle } = useSelector((state) => state.headerTitle);
  const { dataReminder, dataDuration } = useSelector((state) => state.option);

  const [errorForm, setErrorForm] = useState({});
  const [selectedMeet, setSelectedMeet] = useState({});
  const [dateMeeting, setDateMeeting] = useState("");
  const [duration, setDuration] = useState("");
  const [statusMeet, setStatusMeet] = useState("");

  const [selStaffClient, setSelStaffClient] = useState([]);
  const [selStaffLLM, setSelStaffLLM] = useState([]);

  // const [selClient, setSelClient] = useState([]);
  // const [selStaff, setSelStaff] = useState([]);

  const [showReminder, setShowReminder] = useState(false);
  const [errorUpload, setErrorUpload] = useState({});
  const [remindMeet, setRemindMeet] = useState({
    txtmeetName: "",
    ddlmeetDuration: "",
  });
  const [sentDateTime, setSentDateTime] = useState("");

  const [showFollowup, setShowFollowup] = useState(false);
  const [errorFollow, setErrorFollow] = useState({});
  const [followupMeet, setFollowupMeet] = useState({
    idFol: "",
    txtFolMeetName: "",
    ddlDate_follow: "",
    ddlTime_follow: ""
  });

  const selectedCrumb = (crumb) => {
    if(crumb === "Clients"){
      navigate("/clients/listActiveC");
    }else if(crumb === "Potential Clients"){
      navigate("/clients/listPotentialC");
    }else if(crumb === clientDetails.name){
      clientDetails.is_potential === false && (clientDetails.status !== null && clientDetails.status !== "closed") ? 
        navigate("/clients/viewActiveC/" + params.id) :
        clientDetails.is_potential === true && (clientDetails.status !== null && clientDetails.status !== "closed") ?
          navigate("/clients/view-potential-client/" + params.id) : 
          navigate("/clients/viewAllClients/" + params.id)
    }
  };

  const btnCloseReminder = () => {
    setShowReminder(false);
  };

  const btnReminder = (data) => (e) => {
    // console.log("data", data);
    setShowReminder(true);
    setRemindMeet({
      idmeet: data.id,
      txtmeetName: data.name,
      ddlmeetDuration: data.reminder,
    });

    if (!data.reminder || data.reminder === "") {
      setSentDateTime("");
    } else {
      var sentDateTimeC = moment()
        .add(data.reminder, "minutes")
        .format("DD MMMM YYYY, hh:mm A");
      setSentDateTime("Notification will be sent to you at " + sentDateTimeC);
    }

    setErrorUpload({});
  };

  const btnCloseFollowup = () => {
    setShowFollowup(false);
  };

  const btnFollowup = (data) => (e) => {

    setShowFollowup(true);

    setFollowupMeet({
      idFol: data.id,
      txtFolMeetName: data.name,
      ddlDate_follow: data.follow_up.substring(0, 10),
      ddlTime_follow: data.follow_up.substring(11, 16)
    });

    setErrorFollow({});
  };

  const handleChangeReminder = (e) => {
    var sentDateTimeC = moment()
      .add(e.target.value, "minutes")
      .format("DD MMMM YYYY, hh:mm A");
    setSentDateTime("Notification will be sent to you at " + sentDateTimeC);
  };

  const btnSaveReminder = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtRemind = Object.fromEntries(data.entries());

    const newErrors = formErrorRemind(dtRemind);

    if (Object.keys(newErrors).length > 0) {
      setErrorUpload(newErrors);
    } else {
      Api.post(
        "client/" +
          params.id +
          "/meeting/" +
          remindMeet.idmeet +
          "/addReminder",
        {
          reminder: dtRemind.ddlRemindOption,
        }
      )
        .then((response) => {
          // let data = response.data;

          getSelectedMeeting();

          customSwal.fire({
            title: "Reminder Updated Successfully!",
            icon: "success",
            confirmButtonText: "Okay",
          });

          setShowReminder(false);
        })
        .catch((error) => {
          console.log("error update meeting reminder", error);
        });
    }
  };

  const btnSaveFollow = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtFollow = Object.fromEntries(data.entries());

    const newErrors = formErrorFollow(dtFollow);

    if (Object.keys(newErrors).length > 0) {
      setErrorFollow(newErrors);
    } else {
      setErrorFollow({});

      const datetime_followup = dtFollow.date_followup + " " + dtFollow.time_followup;

      console.log("datetime_followup", datetime_followup);

      customSwal
        .fire({
          title: "Do you want to update follow up this meeting?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Api.post("client/" + params.id + "/meeting/" + followupMeet.idFol + "/addFollowUp",
              {
                follow_up: datetime_followup
              }
            )
            .then((response) => {
              // let data = response.data;
    
              getSelectedMeeting();
    
              customSwal.fire({
                title: "Follow Up Updated Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
    
              setShowFollowup(false);
            })
            .catch((error) => {
              console.log("error update followup meeting", error);
            });
          }
        });
    }
  };

  const formErrorRemind = (object) => {
    const newErrors = {};

    if (!object.ddlRemindOption || object.ddlRemindOption === "")
      newErrors.ddlRemindOption = "Fill in the blank!";

    return newErrors;
  };

  const formErrorFollow = (object) => {
    const newErrors = {};

    if (!object.date_followup || object.date_followup === "")
      newErrors.date_followup = "Fill in the blank!";

    if (!object.time_followup || object.time_followup === "")
      newErrors.time_followup = "Fill in the blank!";

    return newErrors;
  };

  const handleStaffClient = (e) => {
    setSelStaffClient(e);
  };

  const handleStaffLLM = (e) => {
    setSelStaffLLM(e);
  };

  const handleDuration = (e) => {
    let value = e.target.value;

    setDuration(value);
  };

  const handleStatus = (e) => {
    let value = e.target.value;

    setStatusMeet(value);
  };

  // Form Error
  // Checking Error Form
  const formError = (object) => {
    const newErrors = {};

    if (
      !object.txtDiscussion ||
      object.txtDiscussion === "" ||
      object.txtDiscussion === "<p><br></p>"
    )
      newErrors.txtDiscussion = "Fill in the blank!";

    if (
      !object.txtConclusion ||
      object.txtConclusion === "" ||
      object.txtConclusion === "<p><br></p>"
    )
      newErrors.txtConclusion = "Fill in the blank!";

    return newErrors;
  };

  // Button Event

  const btnUpdateMeeting = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const dataMeeting = Object.fromEntries(data.entries());

    var staff_client = selStaffClient.map((s) => s.id);
    var staff_llm = selStaffLLM.map((s) => s.id);

    const newErrors = formError(dataMeeting);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      customSwal
        .fire({
          title: "Do you want to update meeting?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();

            formData.append("file", dataMeeting.txtattachment);
            formData.append("name", dataMeeting.txtname);
            formData.append("date_meeting", dataMeeting.txtdatemeet);
            formData.append("time_meeting", dataMeeting.txttimemeet);
            formData.append("location", dataMeeting.txtlocation);
            formData.append("duration", dataMeeting.ddlDuration);

            if (staff_client.length > 0) {
              staff_client.forEach((item, index) => {
                formData.append(`client_involve[${index}]`, item);
              });
            } else {
                formData.append("client_involve", []);
              
            }

            if (staff_llm.length > 0) {
              staff_llm.forEach((item, index) => {
                formData.append(`staff_involve[${index}]`, item);
              });
            } else {
                formData.append("staff_involve", []);
            }
            formData.append("objective_meeting", dataMeeting.txtObjective);
            formData.append(
              "discusion_point_elaboration",
              dataMeeting.txtDiscussion
            );
            formData.append("conclusion_next_step", dataMeeting.txtConclusion);
            formData.append("suggession", dataMeeting.txtSuggestion);
            formData.append("status", dataMeeting.ddlStatus);

            if(dataMeeting.ddlMeetingType !== "BD" && dataMeeting.ddlMeetingType !== "GM"){
              formData.append("assignment_id", dataMeeting.ddlMeetingType);
            }

            if(dataMeeting.ddlMeetingType === "BD" && dataMeeting.ddlMeetingType === "GM"){
              formData.append("type", dataMeeting.ddlMeetingType);
            }

            Apiform.patch(
              "client/" + params.id + "/meeting/" + params.idrow,
              formData
            )
              .then((response) => {
                if (clientDetails.is_potential === false && (clientDetails.status !== null && clientDetails.status !== "closed")) {
                  navigate("/clients/viewActiveC/" + params.id);
                } else if (clientDetails.is_potential === true && (clientDetails.status !== null && clientDetails.status !== "closed")){
                  navigate("/clients/view-potential-client/" + params.id);
                } else {
                  navigate("/clients/viewAllClients/" + params.id);
                }
                customSwal.fire({
                  title: "Meeting Updated Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("error btnSaveMeeting", error);
              });
          }
        });
    }
  };

  // const getListStaffClient = useCallback(
  //   (idclient) => {
  //     setSelClient([]);

  //     idclient.map((staffclient) => {
  //       return selClient.push({
  //         id: staffclient.candidate.id,
  //         first_name: staffclient.candidate.first_name,
  //         last_name: staffclient.candidate.last_name,
  //       });
  //     });
  //   },
  //   [selClient]
  // );

  // const getListStaffLLM = useCallback(
  //   (idstaff) => {
  //     setSelStaff([]);

  //     idstaff.map((staffllm) => {
  //       return selStaff.push({
  //         id: staffllm.user.id,
  //         first_name: staffllm.user.first_name,
  //         last_name: staffllm.user.last_name,
  //       });
  //     });
  //   },
  //   [selStaff]
  // );

  const [typeMeeting, setTypeMeeting] = useState("");

  const getSelectedMeeting = useCallback(() => {

    setSelStaffClient([]);
    setSelStaffLLM([]);

    Api.get("client/" + params.id + "/meeting/" + params.idrow)
      .then((response) => {
        let data = response.data;
        setSelectedMeet(data);
        setTypeMeeting(data.assignment_id ? data.assignment_id : data.type);

        setDateMeeting(data.date_meeting.substring(0, 10));
        setDuration(data.duration);
        setStatusMeet(data.status);

        if(data.meeting_has_staff.length > 0){
            data.meeting_has_staff.map((staffclient) => {
                return setSelStaffClient(oldArray => [...oldArray,{
                    id: staffclient.candidate.id,
                    first_name: staffclient.candidate.first_name,
                    last_name: staffclient.candidate.last_name
                }]);
            
            });
        }

        if(data.meeting_has_admin.length > 0){
            data.meeting_has_admin.map((staffllm) => {
                return setSelStaffLLM(oldArray => [...oldArray,{
                    id: staffllm.user.id,
                    first_name: staffllm.user.first_name,
                    last_name: staffllm.user.last_name
                }]);
            
            });
        }

        // setSelClient(data.meeting_has_staff);
        // if (data.meeting_has_staff.length > 0) {
        //   getListStaffClient(data.meeting_has_staff);
        //   setOldClient(data.client_involve);
        // }

        // if (data.meeting_has_admin.length > 0) {
        //   getListStaffLLM(data.meeting_has_admin);
        //   setOldStaff(data.staff_involve);
        // }

        // data.person_involve.map((person, index) => {
        //   newPerson.push({
        //     value: person,
        //     label: person,
        //   })
        // });
      })
      .catch((error) => {
        console.log("error getSelectedMeeting", error);
      });
  }, [params]);

  const handleTypeMeeting = (e) => {
    // console.log("e", e.target.value)
    setTypeMeeting(e.target.value);
  };

  const [assignList, setAssignList] = useState([]);

  const getListAssignment = useCallback(() => {
    Api.get("client/" + params.id + "/assignment")
      .then((response) => {
        let data = response.data;

        // console.log("data assignment", data);

        if (data !== "no data") {
          setAssignList(data);
        }
      })
      .catch((error) => {
        console.log("error getListAssignment", error);
      });
  }, [params]);

  useEffect(() => {
    getSelectedMeeting();
    getListAssignment();

    dispatch(getClientDetails(params.id));
    dispatch(getListStaff());
    dispatch(getClientStaff(params.id));


    if (clientDetails.is_potential === false && (clientDetails.status !== null && clientDetails.status !== "closed")) {
      dispatch(storeTitle({ title: "Clients", icon: mdiAccountGroup }));
    } else if (clientDetails.is_potential === true && (clientDetails.status !== null && clientDetails.status !== "closed")){
      dispatch(
        storeTitle({ title: "Potential Clients", icon: mdiAccountGroup })
      );
    } else {
      dispatch(
        storeTitle({ title: "Companies", icon: mdiAccountGroup })
      );
    }
  }, [dispatch, params]);

  return (
    <div className="main-div">
      <div className="container-div both-side">
        <BreadCrumb
          crumbs={[getTitle.title, clientDetails.name, "Edit Meeting"]}
          selected={selectedCrumb}
        ></BreadCrumb>
        <Button variant="secondary" onClick={() => navigate(-1)}>
          <Icon path={mdiArrowLeft} size={0.7} color="#212529" />
          Back
        </Button>
      </div>

      <div className="bg-white-container">
        <div className="right-div reminder">
          <Button 
            variant="link" 
            onClick={btnFollowup(selectedMeet)}
          >
            <Icon path={mdiCalendar} size={0.8} color="#467FD0" />
            Follow Up Reminder
          </Button>
          <Button variant="link" onClick={btnReminder(selectedMeet)}>
            <Icon path={mdiCalendar} size={0.8} color="#467FD0" />
            Set Reminder
          </Button>
        </div>

        <Form onSubmit={btnUpdateMeeting}>
          {/* 1 */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={2}>
              Meeting Type
            </Form.Label>
            <Col>
              <Form.Select
                name="ddlMeetingType"
                value={typeMeeting}
                onChange={handleTypeMeeting}
              >
                {/* <option value="">Select</option> */}
                <option value="GM">General Meeting</option>
                <option value="BD">BD Meeting</option>
                {assignList.map((assign, index) => (
                  <option key={index} value={assign.id}>
                    {assign.title} Meeting
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Form.Group>

          {/* 2 */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={2}>
              Meeting Name
            </Form.Label>
            <Col>
              <Form.Control
                name="txtname"
                type="text"
                isInvalid={!!errorForm.txtname}
                defaultValue={selectedMeet.name}
              />
              <Form.Control.Feedback type="invalid">
                {errorForm.txtname}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          {/* 3 */}
          <Row>
            <Col xs={6}>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg={4}>
                  Date of call / meeting
                </Form.Label>
                <Col>
                  {/* {selectedMeet.date_meeting.substring(0,10)} */}
                  <Form.Control
                    name="txtdatemeet"
                    type="date"
                    //   min={new Date().toISOString().split("T")[0]}
                    isInvalid={!!errorForm.txtdatemeet}
                    defaultValue={dateMeeting}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorForm.txtdatemeet}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg={4}>
                  Time
                </Form.Label>
                <Col>
                  <Form.Control
                    name="txttimemeet"
                    type="time"
                    isInvalid={!!errorForm.txttimemeet}
                    defaultValue={selectedMeet.time_meeting}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorForm.txttimemeet}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Col>
          </Row>

          {/* 4 */}
          <Row>
            <Col xs={6}>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg={4}>
                  Location
                </Form.Label>
                <Col>
                  <Form.Control
                    name="txtlocation"
                    type="text"
                    defaultValue={selectedMeet.location}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg={4}>
                  Duration
                </Form.Label>
                <Col>
                  <Form.Select
                    name="ddlDuration"
                    value={duration}
                    onChange={handleDuration}
                  >
                    <option value="">Select</option>
                    {dataDuration.map((dur, index) => (
                      <option key={index} value={dur.code}>
                        {dur.value}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>
          </Row>

          {/* 5 */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={2}>
              Parties involved
            </Form.Label>
            <Col>
              <div className="text mb-3">Client:</div>
              <Searchable
                name="ddlClientStaff"
                isMulti={true}
                options={clientListStaff}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) =>
                  option.first_name + " " + option.last_name
                }
                onChange={handleStaffClient}
                value={selStaffClient}
                errorText="Fill in the blank!"
              ></Searchable>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={2}></Form.Label>
            <Col>
              <div className="text mb-3">Lim-Loges & Masters:</div>
              <Searchable
                name="ddlStaffLLM"
                isMulti={true}
                options={dataStaff}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) =>
                  option.first_name + " " + option.last_name
                }
                onChange={handleStaffLLM}
                value={selStaffLLM}
                errorText="Fill in the blank!"
              ></Searchable>
            </Col>
          </Form.Group>

          {/* 6 */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={2}>
              Objective of meeting
            </Form.Label>
            <Col>
              <RichTextEditor
                name="txtObjective"
                htmlContent={selectedMeet.objective_meeting}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={2}>
              Discussion points and elaboration{" "}
              <label className="validation-star">*</label>
            </Form.Label>
            <Col>
              <RichTextEditor
                name="txtDiscussion"
                isInvalid={!!errorForm.txtDiscussion}
                errorText={errorForm.txtDiscussion}
                htmlContent={selectedMeet.discusion_point_elaboration}
              />
            </Col>
          </Form.Group>

          {/* 7 */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={2}>
              Conclusion/next steps <label className="validation-star">*</label>
            </Form.Label>
            <Col>
              <RichTextEditor
                name="txtConclusion"
                isInvalid={!!errorForm.txtConclusion}
                errorText={errorForm.txtConclusion}
                htmlContent={selectedMeet.conclusion_next_step}
              />
            </Col>
          </Form.Group>

          {/* 8 */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={2}>
              Suggestion {"(if any)"}
            </Form.Label>
            <Col>
              <RichTextEditor
                name="txtSuggestion"
                htmlContent={selectedMeet.suggession}
              />
            </Col>
          </Form.Group>

          {/* 9 */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={2}>
              Attachment
            </Form.Label>
            <Col>
              <Form.Control name="txtattachment" type="file" />
            </Col>
          </Form.Group>

          {/* 10 */}
          {selectedMeet.attachment ? (
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}></Form.Label>
              <Col>
                <a href={selectedMeet.attachment} download="myFile">
                  Download File
                </a>
              </Col>
            </Form.Group>
          ) : null}

          {/* 11 */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={2}>
              Status
            </Form.Label>
            <Col>
              <Form.Select
                name="ddlStatus"
                value={statusMeet}
                onChange={handleStatus}
              >
                <option value="Created">Created</option>
                <option value="Completed">Completed</option>
              </Form.Select>
            </Col>
          </Form.Group>

          <div className="row-btn-center">
            <Button variant="secondary" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Save Meeting
            </Button>
          </div>
        </Form>
      </div>

      {/* Modal set reminder */}
      <Modal
        show={showReminder}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseReminder}
        title="Set Reminder"
        content={
          <>
            <Form id="remind-form" onSubmit={btnSaveReminder}>
              <Row>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="col-3">Meeting Name</Form.Label>
                  <Col sm="9">{remindMeet.txtmeetName}</Col>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="col-3">Reminder Options</Form.Label>
                  <Col sm="9">
                    <Form.Select
                      name="ddlRemindOption"
                      isInvalid={!!errorUpload.ddlRemindOption}
                      defaultValue={remindMeet.ddlmeetDuration}
                      onChange={handleChangeReminder}
                    >
                      <option value="">Select</option>
                      {dataReminder.map((remind, index) => (
                        <option key={index} value={remind.value}>
                          {remind.label}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errorUpload.ddlRemindOption}
                    </Form.Control.Feedback>
                    <Form.Text muted>{sentDateTime}</Form.Text>
                  </Col>
                </Form.Group>
              </Row>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseReminder}>
              Cancel
            </Button>
            <Button type="submit" variant="primary" form="remind-form">
              Set Reminder
            </Button>
          </>
        }
      ></Modal>

      {/* Modal set followup */}
      <Modal
        show={showFollowup}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseFollowup}
        title="Set Follow Up"
        content={
          <>
            <Form id="follow-form" onSubmit={btnSaveFollow}>
              <Row>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="col-3">Meeting Name</Form.Label>
                  <Col sm="9">{followupMeet.txtFolMeetName}</Col>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="col-3">Date</Form.Label>
                  <Col sm="9">
                    <Form.Control
                      type="date"
                      name="date_followup"
                      defaultValue={followupMeet.ddlDate_follow}
                      isInvalid={!!errorFollow.date_followup}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorFollow.date_followup}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="col-3">Time</Form.Label>
                  <Col sm="9">
                    <Form.Control
                      type="time"
                      name="time_followup"
                      defaultValue={followupMeet.ddlTime_follow}
                      isInvalid={!!errorFollow.time_followup}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorFollow.time_followup}
                    </Form.Control.Feedback>
                    {/* <Form.Text muted>{sentDateTime}</Form.Text> */}
                  </Col>
                </Form.Group>
              </Row>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseFollowup}>
              Cancel
            </Button>
            <Button type="submit" variant="primary" form="follow-form">
              Set Follow Up
            </Button>
          </>
        }
      ></Modal>
    </div>
  );
};

export default EditMeeting;
