import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Api from "../../../../core/services/api.service";

import SwalAlert from "../../../../components/Swal/SwalAlert";
import PasswordChecklist from "react-password-checklist";

import Icon from "@mdi/react";
import { mdiCheck } from "@mdi/js";

import { Form, Button } from "react-bootstrap";
import Searchable from "../../../../components/DropDown/SearchableSelect";

import logo from "../../../../assets/logo.png";
import "./register.css";

import { getListOptions } from "../../../../store/actions/optionSlice";
import {
  storeRegister,
  storeSuccess,
} from "../../../../store/actions/registerSlice";

const FirstPage = (props) => {
  // const dispatch = useDispatch();
  const handleLanguages = (e) => {
    props.dispatch(
      storeRegister({
        languages: e,
      })
    );
  };

  return (
    <>
      <Form id="basic-form">
        {/* 1 */}
        <Form.Group className="mb-3">
          <Form.Label>Salutation</Form.Label>
          <Form.Select
            name="ddlSalute"
            onChange={(e) =>
              props.dispatch(
                storeRegister({
                  salutation: e.currentTarget.value,
                })
              )
            }
            value={props.formRegister.salutation}
            isInvalid={!!props.errorsFirst.salutation}
          >
            <option value="">Select</option>
            {props.dataSalute.map((salute, index) => (
              <option key={index} value={salute.value}>
                {salute.value}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {props.errorsFirst.salutation}
          </Form.Control.Feedback>
        </Form.Group>
        {/* 2 */}
        <div className="name-row">
          <Form.Group className="mb-3 col-6">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your first name"
              value={props.formRegister.first_name}
              onChange={(e) =>
                props.dispatch(
                  storeRegister({
                    first_name: e.currentTarget.value,
                  })
                )
              }
              isInvalid={!!props.errorsFirst.first_name}
            />
            <Form.Control.Feedback type="invalid">
              {props.errorsFirst.first_name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3 col-6">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your last name"
              value={props.formRegister.last_name}
              onChange={(e) =>
                props.dispatch(
                  storeRegister({
                    last_name: e.currentTarget.value,
                  })
                )
              }
              isInvalid={!!props.errorsFirst.last_name}
            />
            <Form.Control.Feedback type="invalid">
              {props.errorsFirst.last_name}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        {/* 3 */}
        <Form.Group className="mb-3">
          <Form.Label>Mobile Number</Form.Label>
          <div className="name-row">
            <div className="col-3">
              <Form.Select
                name="ddlPhoneCode"
                onChange={(e) =>
                  props.dispatch(
                    storeRegister({
                      mobile_no_code: e.currentTarget.value,
                    })
                  )
                }
                value={props.formRegister.mobile_no_code}
                isInvalid={!!props.errorsFirst.mobile_no_code}
              >
                <option value="">Select</option>
                {props.dataPhoneCode.map((phone, index) => (
                  <option key={index} value={phone.value}>
                    {phone.value}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {props.errorsFirst.mobile_no_code}
              </Form.Control.Feedback>
            </div>
            <div className="col-9">
              <Form.Control
                type="number"
                placeholder="Enter your mobile number"
                value={props.formRegister.mobile_no}
                onChange={(e) =>
                  props.dispatch(
                    storeRegister({
                      mobile_no: e.currentTarget.value,
                    })
                  )
                }
                isInvalid={!!props.errorsFirst.mobile_no}
              />
              <Form.Control.Feedback type="invalid">
                {props.errorsFirst.mobile_no}
              </Form.Control.Feedback>
            </div>
          </div>
        </Form.Group>
        {/* 4 */}
        <Form.Group className="mb-3">
          <Form.Label>Country</Form.Label>
          <Form.Select
            name="ddlCountry"
            onChange={(e) =>
              props.dispatch(
                storeRegister({
                  citizenship_main: e.currentTarget.value,
                })
              )
            }
            value={props.formRegister.citizenship_main}
            isInvalid={!!props.errorsFirst.citizenship_main}
          >
            <option value="">Select</option>
            {props.dataCountry.map((cntry, index) => (
              <option key={index} value={cntry.value}>
                {cntry.value}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {props.errorsFirst.citizenship_main}
          </Form.Control.Feedback>
        </Form.Group>
        {/* 5 */}
        <Form.Group className="mb-3">
          <Form.Label>Languages</Form.Label>
          <Searchable
            name="ddlLanguages"
            isMulti={true}
            options={props.dataLanguages}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.value}
            onChange={handleLanguages}
            value={props.formRegister.languages}
            errorText={props.errorsFirst.languages}
            isInvalid={!!props.errorsFirst.languages}
          ></Searchable>
        </Form.Group>
      </Form>
    </>
  );
};

const SecondPage = (props) => {
  return (
    <>
      <Form id="job-form">
        {/* 1 */}
        <Form.Group className="mb-3">
          <Form.Label>Job Title</Form.Label>
          <Form.Control
            name="txtjobtitle"
            type="text"
            placeholder="Enter your job title"
            value={props.formRegister.job_title}
            onChange={(e) =>
              props.dispatch(
                storeRegister({
                  job_title: e.currentTarget.value,
                })
              )
            }
            isInvalid={!!props.errorsSecond.job_title}
          />
          <Form.Control.Feedback type="invalid">
            {props.errorsSecond.job_title}
          </Form.Control.Feedback>
        </Form.Group>

        {/* 2 */}
        <Form.Group className="mb-3">
          <Form.Label>Company Name</Form.Label>
          <Form.Control
            name="txtcompanyname"
            type="text"
            placeholder="Enter your company name"
            value={props.formRegister.company_name}
            onChange={(e) =>
              props.dispatch(
                storeRegister({
                  company_name: e.currentTarget.value,
                })
              )
            }
            isInvalid={!!props.errorsSecond.company_name}
          />
          <Form.Control.Feedback type="invalid">
            {props.errorsSecond.company_name}
          </Form.Control.Feedback>
        </Form.Group>

        {/* 3 */}
        <Form.Group className="mb-3">
          <Form.Label>Company Email</Form.Label>
          <Form.Control
            name="txtcompanyname"
            type="email"
            placeholder="Enter your company email address"
            value={props.formRegister.comp_email}
            onChange={(e) =>
              props.dispatch(
                storeRegister({
                  comp_email: e.currentTarget.value,
                })
              )
            }
            isInvalid={!!props.errorsSecond.comp_email}
          />
          <Form.Control.Feedback type="invalid">
            {props.errorsSecond.comp_email}
          </Form.Control.Feedback>
        </Form.Group>

        {/* 4 */}
        <Form.Group className="mb-3">
          <Form.Label>Direct Line Number</Form.Label>
          <Form.Control
            name="txtcompanyname"
            type="number"
            placeholder="Enter your direct line no."
            value={props.formRegister.direct_line_no}
            onChange={(e) =>
              props.dispatch(
                storeRegister({
                  direct_line_no: e.currentTarget.value,
                })
              )
            }
          />
        </Form.Group>
      </Form>
    </>
  );
};

const Password = (props) => {

  const [validPassword, setValidPassword] = useState(false);

  const handleChangeChecking = (isValid) => {
    console.log("🚀 ~ handleChange ~ isValid:", isValid)
    setValidPassword(isValid)
 
  };

  return (
    <>
      <Form id="pw-form">
        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            name="txtrgpassword"
            type="password"
            placeholder="Enter your password"
            value={props.formRegister.password}
            onChange={(e) =>
              props.dispatch(
                storeRegister({
                  password: e.currentTarget.value,
                  validPassword: validPassword
                })
              )
            }
            isInvalid={!!props.errorsPassword.password}
          />
          <Form.Control.Feedback type="invalid">
            {props.errorsPassword.password}
          </Form.Control.Feedback>
        </Form.Group>

        <div className="new-pw-note">
          In order to <b>protect your account</b>, make sure your password:
          <PasswordChecklist
            // className="pw-checklist"
            rules={[
              "minLength",
              "capital",
              "lowercase",
              "number",
              "specialChar",
            ]}
            minLength={8}
            value={props.formRegister.password}
            onChange={(isValid) => handleChangeChecking(isValid)} 
            // onChange={(isValid) => {
            //   props.dispatch(
            //     storeRegister({
            //       validPassword: isValid,
            //     })
            //   );
            // }}
            messages={{
              minLength: "has minimum of 8 characters.",
              capital: "include at least one uppercase letter (A-Z).",
              lowercase: "include at least one lowercase letter (a-z).",
              number: "include at least one number (1-9).",
              specialChar:
                "include at least one special character (e.g. !, @, #, $).",
            }}
          />
        </div>

        <Form.Group
          className="mb-3 mt-3 consent-check"
          style={{
            textAlign: "justify",
            wordBreak: "break-word",
          }}
        >
          <Form.Check
            name="consent"
            type="checkbox"
            label="By selecting this checkbox, I agree that I have read the privacy policy and consent to the given information being used by Lim-Loges & Masters."
            onChange={props.handleChange}
            // checked={validPassword}
          />
        </Form.Group>
      </Form>
    </>
  );
};

const ClientRegister = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const customSwal = SwalAlert();

  const { dataCountry, dataLanguages, dataPhoneCode, dataSalute } = useSelector(
    (state) => state.option
  );
  const { formRegister } = useSelector((state) => state.register);

  const [steps, setSteps] = useState([
    {
      key: "0",
      name: "Personal Details",
      label: "Fill in personal details",
      isDone: false,
      component: FirstPage,
    },
    {
      key: "1",
      name: "Company Details",
      label: "Fill in company details",
      isDone: false,
      component: SecondPage,
    },
    {
      key: "2",
      name: "Password",
      label: "Secure and create account",
      isDone: false,
      component: Password,
    },
  ]);

  const [errorsFirst, setErrorsFirst] = useState({});
  const [errorsSecond, setErrorsSecond] = useState({});
  const [errorsPassword, setErrorsPassword] = useState({});

  const [btnStatus, setBtnStatus] = useState(true);

  function handleChange(e) {
    if (btnStatus) {
      setBtnStatus(false);
    } else {
      setBtnStatus(true);
    }
  }

  const formErrorsFirst = (object) => {
    const newFirstErrors = {};

    if (!object.salutation || object.salutation === "")
      newFirstErrors.salutation = "Fill in the blank!";

    if (!object.first_name || object.first_name === "")
      newFirstErrors.first_name = "Fill in the blank!";

    if (!object.last_name || object.last_name === "")
      newFirstErrors.last_name = "Fill in the blank!";

    if (!object.mobile_no_code || object.mobile_no_code === "")
      newFirstErrors.mobile_no_code = "Fill in the blank!";

    if (!object.mobile_no || object.mobile_no === "")
      newFirstErrors.mobile_no = "Fill in the blank!";

    if (!object.citizenship_main || object.citizenship_main === "")
      newFirstErrors.citizenship_main = "Fill in the blank!";

    if (object.languages.length === 0) {
      newFirstErrors.languages = "Fill in the blank!";
    }

    return newFirstErrors;
  };

  const formErrorsSecond = (object) => {
    const newSecondErrors = {};

    if (!object.job_title || object.job_title === "")
      newSecondErrors.job_title = "Fill in the blank!";

    if (!object.company_name || object.company_name === "")
      newSecondErrors.company_name = "Fill in the blank!";

    if (!object.comp_email || object.comp_email === "")
      newSecondErrors.comp_email = "Fill in the blank!";

    return newSecondErrors;
  };

  const formErrorsPassword = (object) => {
    const newPasswordErrors = {};

    if (!object.password || object.password === "")
      newPasswordErrors.password = "Fill in the blank!";

    return newPasswordErrors;
  };

  const formErrorsEmail = (object) => {
    const emailErrors = {};

    if (object === "Email Already Exist!")
      emailErrors.comp_email = "Email Already Exist";

    return emailErrors;
  };

  const [activeStep, setActiveStep] = useState(steps[0]);

  const handleNext = () => {
    if (activeStep.key === "0") {
      const newErrors = formErrorsFirst(formRegister);

      if (Object.keys(newErrors).length > 0) {
        setErrorsFirst(newErrors);
      } else {
        const index = steps.findIndex((x) => x.key === activeStep.key);

        setSteps((prevStep) =>
          prevStep.map((x) => {
            if (x.key === activeStep.key) x.isDone = true;
            return x;
          })
        );
        setActiveStep(steps[index + 1]);
        setErrorsFirst({});
      }
    } else if (activeStep.key === "1") {
      const newErrors = formErrorsSecond(formRegister);

      if (Object.keys(newErrors).length > 0) {
        setErrorsSecond(newErrors);
      } else {
        const index = steps.findIndex((x) => x.key === activeStep.key);

        setSteps((prevStep) =>
          prevStep.map((x) => {
            if (x.key === activeStep.key) x.isDone = true;
            return x;
          })
        );
        setActiveStep(steps[index + 1]);
        setErrorsSecond({});
        setBtnStatus(true);
      }
    } else {
      if (steps[steps.length - 1].key === activeStep.key) {
        const newErrors = formErrorsPassword(formRegister);

        if (Object.keys(newErrors).length > 0) {
          setErrorsPassword(newErrors);
        } else {
          if (formRegister.validPassword === true) {
            customSwal
              .fire({
                title: "Continue with your registration?",
                icon: "warning",
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonText: "Confirm",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  const object = {
                    salutation: formRegister.salutation,
                    first_name: formRegister.first_name,
                    last_name: formRegister.last_name,
                    mobile_no_code: formRegister.mobile_no_code,
                    mobile_no: formRegister.mobile_no,
                    citizenship_main: formRegister.citizenship_main,
                    languages: formRegister.languages.map((el) => el.value),
                    job_title: formRegister.job_title,
                    company_name: formRegister.company_name,
                    comp_email: formRegister.comp_email,
                    direct_line_no: formRegister.direct_line_no,
                    password: formRegister.password,
                  };

                  Api.post("client/register", object)
                    .then((response) => {
                      navigate("/");
                      customSwal.fire({
                        title: "Registration Successful!",
                        text: "Please check your email for verification email.",
                        icon: "success",
                        confirmButtonText: "Okay",
                      });

                      dispatch(
                        storeSuccess({
                          success: true,
                        })
                      );

                      dispatch(
                        storeRegister({
                          salutation: null,
                          first_name: null,
                          last_name: null,
                          mobile_no_code: null,
                          mobile_no: null,
                          citizenship_main: null,
                          languages: [],
                          job_title: null,
                          company_name: null,
                          comp_email: null,
                          direct_line_no: null,
                          password: "",
                        })
                      );
                    })
                    .catch((error) => {
                      console.log("error client register", error);
                      setActiveStep(steps[1]);
                      const newErrors = formErrorsEmail(
                        error.response.data.message
                      );
                      // console.log("newErrors", newErrors)
                      if (Object.keys(newErrors).length > 0) {
                        setErrorsSecond(newErrors);
                      }
                    });
                }
              });

            setErrorsPassword({});
          }
        }
      }
    }
  };

  const handleBack = () => {
    const index = steps.findIndex((x) => x.key === activeStep.key);
    if (index === 0) return;

    setSteps((prevStep) =>
      prevStep.map((x) => {
        if (x.key === activeStep.key) x.isDone = false;
        return x;
      })
    );
    setActiveStep(steps[index - 1]);
  };

  function login() {
    navigate("/");
  }

  useEffect(() => {
    dispatch(getListOptions());
  }, [dispatch]);

  return (
    <div className="main-div" style={{ height: "100vh" }}>
      <div className="auth-logo-div">
        <img src={logo} alt="" />
      </div>
      <div className="auth-container">
        <div className="login-container">
          <div className="left-welcome-div">
            <div className="mid-section">
              {steps.map((step, i) => {
                return (
                  <div className="wizard-section" key={i}>
                    <div
                      className={`${
                        activeStep.key === step.key
                          ? "square-num active-square"
                          : "square-num"
                      } 
                      `}
                    >
                      {step.isDone && activeStep.key !== step.key ? (
                        <Icon path={mdiCheck} size={0.8} key={i} />
                      ) : (
                        `${i + 1}`
                      )}
                    </div>
                    <div className="wizard-right">
                      <div className="wizard-info">{step.name}</div>
                      <div className="wizard-subinfo">{step.label}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="right-form-div">
            <div className="top-section reg">
              <div className="title-form-section">
                <div className="main-title">Register</div>
                <div className="small-text" style={{ color: "#54595F" }}>
                  to create your account.
                </div>
              </div>
              {activeStep.key === "0" ? (
                <FirstPage
                  dispatch={dispatch}
                  dataSalute={dataSalute}
                  dataPhoneCode={dataPhoneCode}
                  dataCountry={dataCountry}
                  dataLanguages={dataLanguages}
                  formRegister={formRegister}
                  errorsFirst={errorsFirst}
                ></FirstPage>
              ) : activeStep.key === "1" ? (
                <SecondPage
                  dispatch={dispatch}
                  formRegister={formRegister}
                  errorsSecond={errorsSecond}
                ></SecondPage>
              ) : (
                <Password
                  dispatch={dispatch}
                  formRegister={formRegister}
                  errorsPassword={errorsPassword}
                  handleChange={handleChange}
                ></Password>
              )}
              {/* {activeStep.component()} */}
              <div className="row-link-button">
                {steps[0].key === activeStep.key && (
                  <Button
                    variant="link"
                    className="no-underline"
                    onClick={login}
                  >
                    Back to Sign In
                  </Button>
                )}
                {steps[0].key !== activeStep.key && (
                  <Button
                    variant="link"
                    className="no-underline"
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                )}
                {steps[steps.length - 1].key !== activeStep.key ? (
                  <Button variant="primary" onClick={handleNext}>
                    Continue
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    onClick={handleNext}
                    disabled={btnStatus}
                  >
                    Register
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientRegister;
