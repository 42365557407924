import Swal from 'sweetalert2';

export const SwalAlert = () => { 

    const customeSwal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-okay",
          cancelButton: "btn btn-cancel",
          denyButton: "btn btn-okay",
          title: "swal-title",
          popup: "swal-popup",
        },
        buttonsStyling: false,
    });
  
    return customeSwal
}

export default SwalAlert;