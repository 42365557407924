import { mdiCommentAlert } from "@mdi/js";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import React, { useState } from "react";
import Icon from "@mdi/react";
import "./Card.css";

const STYLES = [
  "card_text--active--solid",
  "card_text--warning--solid",
  "card_text--discussion--solid",
  "card_text--progress--solid",
  "card_text--infor--solid",
  "card_text--hold--solid",
  "card_text--completed--solid",
  "card_text--default--solid",
  "card_text--danger--solid",
  "card_text--primary--solid",
];

const SIZES = [
  "card_text--xsmall",
  "card_text--small",
  "card_text--medium",
  "card_text--large",
];

function CardInfo({ cardTextStyle, cardTextSize, children }) {
  const checkCardTextStyle = STYLES.includes(cardTextStyle)
    ? cardTextStyle
    : STYLES[0];

  const checkCardTextSize = SIZES.includes(cardTextSize)
    ? cardTextSize
    : SIZES[0];

  return (
    // <div className="card_text_container">
    <div className={`card_text ${checkCardTextStyle} ${checkCardTextSize}`}>
      {children}
    </div>
    // </div>
  );
}

export function CardInfoBtn({ cardTextStyle, cardTextSize, children, btn }) {
  const checkCardTextStyle = STYLES.includes(cardTextStyle)
    ? cardTextStyle
    : STYLES[0];

  const checkCardTextSize = SIZES.includes(cardTextSize)
    ? cardTextSize
    : SIZES[0];

  return (
    <div className={`card_text ${checkCardTextStyle} ${checkCardTextSize}`}>
      {children}
      <Button variant="link" className="modal-link" onClick={btn}>
        <Icon
          path={mdiCommentAlert}
          size={0.8}
          style={{ marginLeft: "10px", color: "white" }}
        />
      </Button>
    </div>
  );
}

export default CardInfo;
