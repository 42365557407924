import React, { useState, useEffect, useCallback } from "react";
import { Button, Dropdown, Form, Row, Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../core/services/api.service";
import AuthAbilities from "../../pages/Admin/Auth/AuthAbilities";
// import { useDispatch } from "react-redux";

import SwalAlert from "../Swal/SwalAlert";
import moment from "moment";

import Icon from "@mdi/react";
import {
  mdiMagnify,
  mdiFileDocumentMultiple,
  mdiMenuDown,
  mdiFile,
  mdiDelete,
  mdiAccountPlus,
} from "@mdi/js";

import DropDownStatus from "../DropDown/DropDownStatusCand";

import TextboxIcon from "../TextField/TextFieldIcon";
import Modal from "../Popup/ModalPopup";
import { CustomTable } from "../Table/Table";
import ButtonStage from "../Button/ButtonStage";
// import emergingCP from "../../pages/Admin/Clients/Reports/EmergingCP";
// import qualifiedCP from "../../pages/Admin/Clients/Reports/QualifiedCP"
import CardInfo from "../Card/CardInfo";

export const TabCandidates = () => {
  // const navigate = useNavigate();
  const params = useParams();
  const abilities = AuthAbilities();

  const [showCandidate, setShowCandidate] = useState(false);
  const [listAddCandidate, setListAddCandidate] = useState([]);
  const [allCandidateData, setAllCandidateData] = useState([]);

  const [listCandidate, setListCandidate] = useState([]);
  const [allListCandidate, setAllListCandidate] = useState([]);

  const [checkAddCandidate, setCheckAddCandidate] = useState([]);
  const [candSelected, setCandSelected] = useState(0);

  const [checkCand, setCheckCand] = useState([]);
  const [selCheckCand, setSelCheckCand] = useState(0);

  const [statusCandidate, setStatusCandidate] = useState([]);

  const [showPlaced, setShowPlaced] = useState(false);
  const [errorsJoin, setErrorsJoin] = useState([]);
  const [dtAssCand, setDtAssCand] = useState({});

  const customSwal = SwalAlert();

  const headerAddCandidate = [
    {
      Header: "Candidate Name",
      accessor: (row) => `${row.first_name}`,
      Cell: ({ row }) => {
        const data = row.original;

        let nameCandi = "";

        if (data.last_name !== null) {
          nameCandi = data.first_name + " " + data.last_name;
        } else {
          nameCandi = data.first_name;
        }

        return <div>{nameCandi}</div>;
      },
    },
    {
      Header: "Company",
      Cell: ({ row }) => {
        const data = row.original;

        let companyName = "";

        if (data.candidate_experiences.length > 0) {
          companyName = data.candidate_experiences[0].company_name;
        } else {
          companyName = "-";
        }

        return <>{companyName}</>;
      },
    },
    {
      Header: "Job Title",
      Cell: ({ row }) => {
        const data = row.original;

        let jobTitle = "";

        if (data.candidate_experiences.length > 0) {
          jobTitle = data.candidate_experiences[0].title;
        } else {
          jobTitle = data.job_title ? data.job_title : "-";
        }

        return <>{jobTitle}</>;
      },
    },
    {
      Header: "",
      accessor: "selection",
      disableSortBy: true,
      maxWidth: 50,
      minWidth: 10,
      width: 50,
      Cell: ({ row }) => {
        const data = row.original;

        const onItemCheck = (data) => (event) => {
          if (checkAddCandidate.length > 0) {
            if (event.target.checked === false) {
              const index = checkAddCandidate.findIndex(function (chck) {
                return chck.id === data.id;
              });

              checkAddCandidate.splice(index, 1);
            } else {
              checkAddCandidate.push(data);
            }
          } else {
            checkAddCandidate.push(data);
          }

          setCandSelected(checkAddCandidate.length);

          // console.log("checkAddCandidate", checkAddCandidate);
        };

        const checkedItem = (id) => {
          let cnd = [];

          const checkCand = checkAddCandidate.filter((chec) => {
            return chec.id === id;
          });

          cnd = checkCand[0];

          return cnd;
        };

        return (
          <div className="form-container-default-2">
            <input
              type="checkbox"
              checked={checkedItem(data.id)}
              id={"row" + data.id}
              onChange={onItemCheck(data)}
            />
          </div>
        );
      },
    },
  ];

  const headerCandidate = [
    {
      Header: "",
      accessor: "selection",
      disableSortBy: true,
      maxWidth: 50,
      minWidth: 10,
      width: 50,
      Cell: ({ row }) => {
        const data = row.original;

        const onCandCheck = (data) => (event) => {
          if (checkCand.length > 0) {
            if (event.target.checked === false) {
              const index = checkCand.findIndex(function (chck) {
                return chck.id === data.id;
              });

              checkCand.splice(index, 1);
            } else {
              checkCand.push(data);
            }
          } else {
            checkCand.push(data);
          }

          setSelCheckCand(checkCand.length);
        };

        const checkedCand = (id) => {
          let chkcnd = [];

          const checked = checkCand.filter((chec) => {
            return chec.id === id;
          });

          chkcnd = checked[0];

          return chkcnd;
        };

        return (
          <div className="form-container-default-2">
            <input
              type="checkbox"
              checked={checkedCand(data.id)}
              id={"row" + data.id}
              onChange={onCandCheck(data)}
            />
          </div>
        );
      },
    },
    {
      Header: "Candidate Name",
      accessor: (row) => `${row.first_name}`,
      maxWidth: 200,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        const navigate = useNavigate();

        let nameCandi = "";

        if (data.last_name !== null) {
          nameCandi = data.first_name + " " + data.last_name;
        } else {
          nameCandi = data.first_name;
        }

        function handleClick() {
          navigate("/candidates/viewCandidate/" + data.id);
        }

        return (
          <div className="container-div list-cli">
            <Button
              variant="link"
              className="no-underline"
              onClick={handleClick}
              style={{ textTransform: "capitalize" }}
            >
              {nameCandi}
            </Button>
          </div>
        );
      },
    },
    {
      Header: "Current Company",
      maxWidth: 200,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        let cur_company = "";

        if (data.candidate_experiences.length > 0) {
          cur_company = data.candidate_experiences[0].company_name;
        } else {
          cur_company = "-";
        }

        return <>{cur_company}</>;
      },
    },
    {
      Header: "Interview Stage",
      maxWidth: 300,
      minWidth: 100,
      width: 300,
      Cell: ({ row }) => {
        const data = row.original;

        let dtStage = [];

        if (data.interviews.length > 0) {
          dtStage = data.interviews;
        } else {
          dtStage = [];
        }

        return (
          <>
            <ButtonStage items={dtStage}></ButtonStage>
          </>
        );
      },
    },
    {
      Header: "Latest Interview Date",
      maxWidth: 200,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        let dateLatestInv = "";

        // console.log("data.interviews", data.interviews)

        if (data.interviews.length > 0) {

          // ✅ Get Max date
          const maxDate = new Date(
            Math.max(
              ...data.interviews.map(element => {
                return new Date(element.interview_date);
              }),
            ),
          );

          
          const formatDate = moment(maxDate).format(
            "DD MMMM YYYY"
          );

          if(formatDate === "01 January 1970"){
            dateLatestInv = "-";
          }else{
            dateLatestInv = formatDate;
          }

        } else {
          dateLatestInv = "-";
        }

        return (
          <>
            <div>{dateLatestInv}</div>
          </>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      maxWidth: 150,
      minWidth: 150,
      width: 150,
      Cell: ({ row }) => {
        const data = row.original;

        let idassgn_cand = data.assignment_candidates[0].id;

        function changeStatus(e) {
          let valuesta = e.target.value;

          if (valuesta === "placed") {
            setShowPlaced(true);
            setDtAssCand({
              id: idassgn_cand,
              first_name: data.first_name,
              last_name: data.last_name,
              status: valuesta,
            });
            setErrorsJoin([]);
          } else {
            Api.patch(
              "client/" +
                params.id +
                "/assignment/" +
                params.idassign +
                "/candidate/updateStatus/" +
                idassgn_cand,
              {
                status: valuesta,
              }
            )
              .then((response) => {
                getListCandidate();

                customSwal.fire({
                  title:
                    data.first_name + " " + data.last_name + " Status Change!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("error changeStatus candidate", error);
              });
          }
        }

        return (
          <>
            {data.assignment_candidates[0].status === "placed_other" ? (
              <CardInfo
                cardTextSize="card_text--small"
                cardTextStyle="card_text--danger--solid"
              >
                Placed (Other)
              </CardInfo>
            ) : (
              <DropDownStatus
                options={statusCandidate}
                styleDrop="select--status--style"
                sizeDrop="select--medium"
                colorDrop="primary-color"
                defaultValue={data.assignment_candidates[0].status}
                onChange={changeStatus}
              />
            )}
          </>
        );
      },
    },
  ];

  const findDataSearch = (object) => {
    let newList = [];

    newList = listAddCandidate.filter((candidate) =>
      candidate.first_name.toLowerCase().includes(object.toLowerCase())
    );

    return newList;
  };

  const handleSearch = (event) => {
    let value = event.target.value;

    const newAddListCandidate = findDataSearch(value);

    setAllCandidateData(newAddListCandidate);
  };

  const findDataSearchList = (object) => {
    let newList = [];

    newList = listCandidate.filter(
      (candidate) =>
        candidate.first_name.toLowerCase().includes(object.toLowerCase()) ||
        candidate.last_name.toLowerCase().includes(object.toLowerCase())
    );

    return newList;
  };

  const handleSearchList = (event) => {
    let value = event.target.value;

    const newListCandidate = findDataSearchList(value);

    // console.log(newListCandidate);

    setAllListCandidate(newListCandidate);
  };

  // Button Event
  // button close and open modal popup
  const btnClosePlaced = (e) => {
    setShowPlaced(false);
  };

  const findFormJoined = (object) => {
    const newJErrors = {};

    if (!object.txtdatejoined || object.txtdatejoined === "")
      newJErrors.txtdatejoined = "Please select date!";

    return newJErrors;
  };

  const btnSaveJoined = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtJoin = Object.fromEntries(data.entries());

    // console.log("dtJoin", dtJoin);
    // console.log("dtAssCand", dtAssCand);

    const newErrors = findFormJoined(dtJoin);

    if (Object.keys(newErrors).length > 0) {
      setErrorsJoin(newErrors);
    } else {
      Api.patch(
        "client/" +
          params.id +
          "/assignment/" +
          params.idassign +
          "/candidate/updateStatus/" +
          dtAssCand.id,
        {
          status: dtAssCand.status,
          joined_date: dtJoin.txtdatejoined,
        }
      )
        .then((response) => {
          getListCandidate();
          setShowPlaced(false);

          customSwal.fire({
            title:
              dtAssCand.first_name +
              " " +
              dtAssCand.last_name +
              " Status Change!",
            icon: "success",
            confirmButtonText: "Okay",
          });
        })
        .catch((error) => {
          console.log("error changeStatus candidate", error);
        });
    }
  };

  const btnAddCandidate = (e) => {
    setCandSelected(0);
    setCheckAddCandidate([]);
    getAddListCandidate();
    setShowCandidate(true);
  };

  const btnCloseCandidate = (e) => {
    setShowCandidate(false);
  };

  const formNewArrayCandidate = (object) => {
    let newArray;
    newArray = Array.isArray(object) ? object.map((x) => x.id) : [];

    return newArray;
  };

  const btnAssign = () => {
    const newAssignCandidate = formNewArrayCandidate(checkAddCandidate);

    // console.log("newAssignCandidate", newAssignCandidate);
    // console.log("params.id", params.id);
    // console.log("params.idass", params.idassign);

    if (newAssignCandidate.length > 0) {
      customSwal
        .fire({
          title: "Do you want to add candidate in this assignment?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Api.post(
              "client/" +
                params.id +
                "/assignment/" +
                params.idassign +
                "/candidate/create",
              {
                candidate_id: newAssignCandidate,
              }
            )
              .then((response) => {
                //   let data = response.data;

                setShowCandidate(false);
                getListCandidate();
                customSwal.fire({
                  title: "Saved Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("client", error);
              });
          }
        });
    } else {
      customSwal.fire({
        title: "Please select candidate to assign",
        icon: "warning",
        confirmButtonText: "Okay",
      });
    }
  };

  const btnEmergingCP = (e) => {
    let arrayCand = [];

    arrayCand = checkCand.map(function (obj) {
      return obj.id;
    });

    // console.log("arrayCand", arrayCand);

    customSwal
      .fire({
        title: "Do you want to generate emerging candidate profile?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.post(
            "client/" +
              params.id +
              "/assignment/" +
              params.idassign +
              "/candidate/generate/ecp",
            {
              candidate_id: arrayCand,
            }
          )
            .then((response) => {
              // let data = response.data;

              customSwal.fire({
                title: "Generated Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });

              setCheckCand([]);
            })
            .catch((error) => {
              console.log("client", error);
            });
        }
      });
  };

  const btnQualifiedCP = (e) => {
    let arrayCand = [];

    arrayCand = checkCand.map(function (obj) {
      return obj.id;
    });

    // console.log("arrayCand", arrayCand);

    customSwal
      .fire({
        title: "Do you want to generate qualified candidate profile?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.post(
            "client/" +
              params.id +
              "/assignment/" +
              params.idassign +
              "/candidate/generate/qcp",
            {
              candidate_id: arrayCand,
            }
          )
            .then((response) => {
              // let data = response.data;

              customSwal.fire({
                title: "Generated Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });

              setCheckCand([]);
            })
            .catch((error) => {
              console.log("client", error);
            });
        }
      });
  };

  // function qualifiedCP() {
  //   navigate("/qcp");
  // }

  function getListOption() {
    Api.get("option/list")
      .then((response) => {
        let listStatus = response.data.filter((grp) =>
          grp.group.includes("Status Candidate")
        );

        setStatusCandidate(listStatus);
      })
      .catch((error) => {
        console.log("option/list", error);
      });
  }

  const getAddListCandidate = useCallback(() => {
    Api.get(
      "client/" +
        params.id +
        "/assignment/" +
        params.idassign +
        "/candidate/ddl/candidate"
    )
      .then((response) => {
        let data = response.data;

        // console.log("data getAddListCandidate", data);
        setListAddCandidate(data);
        setAllCandidateData(data);
      })
      .catch((error) => {
        console.log("getAddListCandidate", error);
      });
  }, [params]);

  const getListCandidate = useCallback(() => {
    Api.get(
      "client/" +
        params.id +
        "/assignment/" +
        params.idassign +
        "/candidate/all"
    )
      .then((response) => {
        let data = response.data;

        // console.log("data getListCandidate", data);
        setListCandidate(data);
        setAllListCandidate(data);
      })
      .catch((error) => {
        console.log("getListCandidate", error);
      });
  }, [params]);

  useEffect(() => {
    getAddListCandidate();
    getListCandidate();
    getListOption();
  }, [getAddListCandidate, getListCandidate]);

  return (
    <>
      <Card className="list-cli">
        <Card.Body>
          <div className="row-div">
            <div className="left-div">
              <TextboxIcon
                name="txtcandassign"
                type="text"
                placeholder="Search"
                inputSize="input--medium"
                onChange={(event) => handleSearchList(event)}
                icon={mdiMagnify}
              ></TextboxIcon>
            </div>
            <div className="right-div two-btn">
              {selCheckCand === 0 ? (
                <>
                  <Button
                    variant="link"
                    className="modal-link"
                    onClick={btnAddCandidate}
                    hidden={!abilities.includes("assign candidate")}
                  >
                    <Icon path={mdiAccountPlus} />
                    <div>Add Candidate</div>
                  </Button>
                </>
              ) : (
                ""
              )}

              {selCheckCand > 0 ? (
                <>
                  {!abilities.includes("generate ecp") &&
                  !abilities.includes("generate qcp") ? null : (
                    <Dropdown>
                      <Dropdown.Toggle variant="primary">
                        <Icon
                          path={mdiFileDocumentMultiple}
                          className="generate"
                        />
                        <div>Generate Report</div>
                        <Icon
                          path={mdiMenuDown}
                          style={{ margin: "0px 0px 0px 8px" }}
                          className="down-arrow"
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {/* onClick={emergingCP} */}
                        <Dropdown.Item
                          onClick={btnEmergingCP}
                          hidden={!abilities.includes("generate ecp")}
                        >
                          <Icon path={mdiFile} className="var-reports" />
                          Emerging Candidate Profile
                        </Dropdown.Item>

                        <Dropdown.Item
                          onClick={btnQualifiedCP}
                          hidden={!abilities.includes("generate qcp")}
                        >
                          <Icon path={mdiFile} className="var-reports" />
                          Qualified Candidate Profile
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </>
              ) : (
                ""
              )}

              {selCheckCand > 0 ? (
                <Button
                  variant="danger"
                  hidden={!abilities.includes("remove candidate")}
                >
                  <Icon path={mdiDelete} />
                  <div>Delete</div>
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
          <CustomTable
            headers={headerCandidate}
            items={allListCandidate}
            paginate={allListCandidate.length}
            maxRows={15}
          ></CustomTable>

          {/* Modal List of Candidate */}
          <Modal
            show={showCandidate}
            size="lg"
            backdrop="static"
            keyboard={false}
            onHide={btnCloseCandidate}
            title="Assign candidate to assignment"
            content={
              <div className="list-cli">
                <div className="row-div">
                  <div className="left-div">
                    <TextboxIcon
                      name="txtTabCandi"
                      type="text"
                      placeholder="Search"
                      inputSize="input--medium"
                      onChange={(event) => handleSearch(event)}
                      icon={mdiMagnify}
                    ></TextboxIcon>
                  </div>
                  <div className="right-div comp-selected">
                    {candSelected} selected
                    <div className="right-div two-btn"></div>
                  </div>
                </div>
                <CustomTable
                  headers={headerAddCandidate}
                  items={allCandidateData}
                  paginate={allCandidateData.length}
                  maxRows={15}
                ></CustomTable>
              </div>
            }
            button={
              <>
                <Button variant="secondary" onClick={btnCloseCandidate}>
                  Cancel
                </Button>
                <Button type="submit" onClick={btnAssign} variant="primary">
                  Assign
                </Button>
              </>
            }
          />

          {/* Modal if status placed */}
          <Modal
            show={showPlaced}
            size="md"
            backdrop="static"
            keyboard={false}
            onHide={btnClosePlaced}
            title="Add Joined Date"
            content={
              <>
                <Form id="joined-form" onSubmit={btnSaveJoined}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Control
                      name="txtdatejoined"
                      type="date"
                      isInvalid={!!errorsJoin.txtdatejoined}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorsJoin.txtdatejoined}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form>
              </>
            }
            button={
              <>
                <Button variant="secondary" onClick={btnClosePlaced}>
                  Cancel
                </Button>
                <Button type="submit" form="joined-form" variant="primary">
                  Confirm
                </Button>
              </>
            }
          />
        </Card.Body>
      </Card>
    </>
  );
};
