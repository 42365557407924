import {createSlice} from '@reduxjs/toolkit';
import Api from '../../core/services/api.service';

export const placementSlice = createSlice({
    name: "placement",
    initialState: {
        placeList: [],
        placeAllList: [],
        placeFilter: {
          txtsearchactive: "",
          ddlCountry: "",
          ddlConsultant: "",
          ddlStatus: "",
        },
    },
    reducers: {
        getPlacementList: (state, action) => {
            state.placeList = action.payload;
        },
        getPlacementAllList: (state, action) => {
            state.placeAllList = action.payload;
        },
        setPlacementStoreFilter: (state, action) => {
            state.placeFilter = { ...state.placeFilter, ...action.payload };
          },
        
    },
});


export const getListPlacement = () => async (dispatch, rejectWithValue) => {
    try {
        const response = await Api.get("candidate/placement/placementList");

        // let listPotential = response.data.filter((pot) => pot.is_potential === true); 
        // console.log("listPotential", listPotential)
        dispatch(getPlacementAllList(response.data));
        dispatch(getPlacementList(response.data));

        // let listActive = response.data.filter((act) => act.is_potential === false && act.status !== null);

        // let clientList = [];

        // listActive.map((cli) => {
        //   clientList.push({
        //     value: cli.id,
        //     label: cli.name,
        //   });
        // });

        // dispatch(getClientActiveList(clientList))
        // console.log("sss")

    } catch (err) {
      throw rejectWithValue(err.message);
    }
};




export const { getPlacementList, getPlacementAllList, setPlacementStoreFilter} = placementSlice.actions;
export default placementSlice.reducer;