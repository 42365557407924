import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";

import { Form, Row, Dropdown, Button } from "react-bootstrap";
import Api from "../../../../core/services/api.service";

import SwalAlert from "../../../../components/Swal/SwalAlert";

import Icon from "@mdi/react";
import {
  mdiPlus,
  mdiDelete,
  mdiPencil,
  mdiDotsHorizontal,
  mdiCheckCircle,
  mdiShieldLock,
} from "@mdi/js";

import CustomTable from "../../../../components/Table/Table";
import Popup from "../../../../components/Popup/ModalPopup";
import CardInfo from "../../../../components/Card/CardInfo";
import Creatable from "../../../../components/DropDown/CreateableSelect";
import "./systemopt.css";

import { storeTitle } from "../../../../store/actions/headerTitleSlice";
import { getListOptions } from "../../../../store/actions/optionSlice";

const SystemOption = () => {
  const dispatch = useDispatch();

  const [optionData, setOptionData] = useState([]);

  const [allData, setAllData] = useState(optionData);
  const [showOpt, setShowOpt] = useState(false);

  const customSwal = SwalAlert();
  const [optState, setOptState] = useState({
    id: null,
    txtoptgroup: null,
    txtoptvalue: null,
    txtoptcode: null,
  });

  const [roleValue, setRoleValue] = useState();
  const [disableGroup, setDisableGroup] = useState(false);
  const [errors, setErrors] = useState({});

  const [groupName, setGroupName] = useState([]);
  const [modeModal, setModeModal] = useState({
    title: null,
    button: null,
  });

  // Headers and value table
  const headers = [
    {
      Header: "Option Group",
      accessor: "group",
      maxWidth: 900,
      minWidth: 300,
      width: 900,
    },
    {
      Header: "Option Value",
      accessor: "value",
      maxWidth: 900,
      minWidth: 300,
      width: 900,
    },
    {
      Header: "Option Code",
      accessor: "code",
      maxWidth: 400,
      minWidth: 200,
      width: 400,
    },
    {
      Header: "Status",
      accessor: "status",
      maxWidth: 150,
      minWidth: 150,
      width: 150,
      Cell: ({ row }) => {
        const data = row.original;

        return data.status === "Inactive" ? (
          <CardInfo
            cardTextSize="card_text--small"
            cardTextStyle="card_text--danger--solid"
          >
            {data.status}
          </CardInfo>
        ) : (
          <CardInfo
            cardTextSize="card_text--small"
            cardTextStyle="card_text--primary--solid"
          >
            {data.status}
          </CardInfo>
        );
      },
    },
    {
      Header: " ",
      // accessor: "group",
      disableSortBy: true,
      maxWidth: 70,
      minWidth: 10,
      width: 70,
      Cell: ({ row }) => {
        const data = row.original;
        return (
          <div className="dropdown-container list-opt">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={btnAddEditOpt(data.id)}>
                  <Icon path={mdiPencil} color={"#467FD0"} />
                  Edit Option
                </Dropdown.Item>

                <Dropdown.Item onClick={btnDeleteOpt(data.id)}>
                    <Icon path={mdiDelete} color={"#DF4759"} />
                    Delete Option
                </Dropdown.Item>

                {/* {data.status === "active" ? (
                  <Dropdown.Item onClick={btnDeleteOpt(data.id)}>
                    <Icon path={mdiDelete} color={"#DF4759"} />
                    Delete Option
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item onClick={btnActiveOpt(data.id)}>
                    <Icon path={mdiCheckCircle} color={"#4CAF50"} />
                    Active Option
                  </Dropdown.Item>
                )} */}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  //Form error
  const findFormErrors = (object, group) => {
    const newErrors = {};

    if (!object.txtoptvalue || object.txtoptvalue === "")
      newErrors.txtoptvalue = "Fill in the blank!";

    if (!object.txtoptcode || object.txtoptcode === "")
      newErrors.txtoptcode = "Fill in the blank!";

    if (!group || group === "") newErrors.txtoptgroup = "Fill in the blank!";

    return newErrors;
  };

  // onchange handle
  const handleChange = useCallback((inputValue) => {
    console.log("inputValue", inputValue);
    setRoleValue(inputValue);
  }, []);

  const handleValue = (event) => {
    event.preventDefault();
    optState.txtoptvalue = event.target.value;
    // 👇️ value of input field
    // console.log('handleValue 👉️', optState.txtoptvalue);
  };

  const handleCode = (event) => {
    event.preventDefault();
    optState.txtoptcode = event.target.value;
  };

  const [selGroup, setSelGroup] = useState("");

  const handleGroup = (e) => {
    let value = e.target.value;

    setSelGroup(value);
    setRoleValue({ label: value, value: value });

    let newList = [];
    newList = optionData.filter((option) => option.group.includes(value));

    setAllData(newList);
  };
  // end onchange handle

  // Function Event
  function getListAfterSaveOption() {
    Api.get("option/list")
      .then((response) => {
        // console.log("option/list", response.data);
        let newList = [];
        newList = response.data.filter((option) =>
          option.group.includes(selGroup)
        );
        // setOptionData(newList);
        setAllData(newList);
      })
      .catch((error) => {
        console.log("option/list", error);
      });
  }

  function getListOption() {
    Api.get("option/list")
      .then((response) => {
        // console.log("option/list", response.data);
        setOptionData(response.data);
        setAllData(response.data);
      })
      .catch((error) => {
        console.log("option/list", error);
      });
  }

  function getListGroup() {
    Api.get("option/groupList")
      .then((response) => {
        // console.log("option/groupList", response.data);
        setGroupName(response.data);
      })
      .catch((error) => {
        console.log("option/groupList", error);
      });
  }
  //end function event

  // button event
  const btnCloseOpt = () => {
    setShowOpt(false);
  };

  const btnAddEditOpt = (id) => (e) => {
    setOptState({});

    if (id === "Add") {
      setModeModal({
        title: "Add System Option",
        button: "Create",
      });

      setOptState({
        txtoptgroup: null,
        txtoptvalue: null,
        txtoptcode: null,
      });

      setErrors({});
      setDisableGroup(false);
      // setRoleValue();
    } else {
      setModeModal({
        title: "Edit System Option",
        button: "Update",
      });
      // console.log("id", id);
      Api.get("option/edit/" + id)
        .then((response) => {
          let optObject = response.data;

          // console.log("optObject", optObject);
          setOptState({
            id: optObject.id,
            txtoptgroup: optObject.group,
            txtoptvalue: optObject.value,
            txtoptcode: optObject.code,
          });
          setErrors({});
          setDisableGroup(true);
          dispatch(getListOptions());
          setRoleValue({ label: optObject.group, value: optObject.group });
        })
        .catch((error) => {
          console.log("option/edit", error);
        });
    }

    setShowOpt(true);
  };

  const btnSaveUpdate = (e) => {
    // console.log("optState", optState);

    const newErrors = findFormErrors(optState, roleValue);

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      if (modeModal.button === "Create") {
        Api.post("option/store", {
          group: roleValue.value,
          code: optState.txtoptcode,
          value: optState.txtoptvalue,
          status: "active",
        })
          .then((response) => {
            // console.log("option/store", response.data);
            getListAfterSaveOption();
            setShowOpt(false);
            dispatch(getListOptions());
          })
          .catch((error) => {
            console.log("option/update", error);
          });
      } else {
        Api.patch("option/update/" + optState.id, {
          code: optState.txtoptcode,
          value: optState.txtoptvalue,
        })
          .then((response) => {
            // console.log("option/update", response.data);

            getListAfterSaveOption();
            setShowOpt(false);
            dispatch(getListOptions());
          })
          .catch((error) => {
            console.log("option/update", error);
          });
      }
    }
  };

  const btnDeleteOpt = (id) => (e) => {
    // console.log(id);

    customSwal
      .fire({
        title: "Do you want to delete option code?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("/option/" + id)
            .then((response) => {
              // console.log("option/update", response.data);
              dispatch(getListOptions());
              getListOption();
            })
            .catch((error) => {
              console.log("error delete option", error);
            });
        }
      });
  };

  const btnActiveOpt = (id) => (e) => {
    // console.log(id);
    customSwal
      .fire({
        title: "Do you want to active back this option code?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.patch("option/update/" + id, {
            status: "active",
          })
            .then((response) => {
              // console.log("option/update", response.data);
              dispatch(getListOptions());
              getListOption();
            })
            .catch((error) => {
              console.log("option/update", error);
            });
        }
      });
  };
  ////// end button event

  // First Load
  useEffect(() => {
    dispatch(
      storeTitle({ title: "System Option Management", icon: mdiShieldLock })
    );
    getListOption();
    getListGroup();
  }, [dispatch]);

  return (
    <div className="main-div list-opt">
      <div className="container-div">
        <div className="filter_container">
          <div className="filter-container-dropdown">
            <Form.Group className="mb-3">
              <Form.Label>Option Group</Form.Label>
              <Form.Select onChange={handleGroup}>
                <option value="">Select</option>
                {groupName.map((group, index) => (
                  <option key={index} value={group.value}>
                    {group.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>

          <Button variant="primary" onClick={btnAddEditOpt("Add")}>
            <Icon path={mdiPlus} />
            <div>Add Option Group</div>
          </Button>
        </div>
      </div>

      <div className="container-div list-opt">
        <CustomTable
          headers={headers}
          items={allData}
          paginate={allData.length}
          maxRows={15}
        ></CustomTable>
      </div>

      {/* Modal Add and Edit Option Group */}
      <Popup
        show={showOpt}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseOpt}
        title={modeModal.title}
        content={
          <>
            <Form>
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label>Option Group Name</Form.Label>
                  <Creatable
                    onChange={handleChange}
                    options={groupName}
                    value={roleValue}
                    isDisabled={disableGroup}
                    isInvalid={!!errors.txtoptgroup}
                    errorText={errors.txtoptgroup}
                  />
                </Form.Group>
              </Row>

              <Row>
                <Form.Group className="mb-3 col-6">
                  <Form.Label>Option Value</Form.Label>
                  <Form.Control
                    // name="txtoptvalue"
                    // {...register("txtoptvalue")}
                    onChange={handleValue}
                    type="text"
                    placeholder="Example: Accounting"
                    defaultValue={optState.txtoptvalue}
                    isInvalid={!!errors.txtoptvalue}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.txtoptvalue}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3 col-6">
                  <Form.Label>Option Code</Form.Label>
                  <Form.Control
                    // name="txtoptcode"
                    // {...register("txtoptcode")}
                    onChange={handleCode}
                    type="text"
                    placeholder="Example: Acc"
                    defaultValue={optState.txtoptcode}
                    isInvalid={!!errors.txtoptcode}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.txtoptcode}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseOpt}>
              Cancel
            </Button>
            <Button
              onClick={btnSaveUpdate}
              type="submit"
              form="option-form"
              variant="primary"
            >
              {modeModal.button}
            </Button>
          </>
        }
      />
    </div>
  );
};

export default SystemOption;
