export const statusCustomerList = [
  {
    label: "BD",
    value: "BD",
  },
  {
    label: "Proposal",
    value: "proposal",
  },
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Inactive",
    value: "inactive",
  },
  {
    label: "Closed",
    value: "closed",
  },
  {
    label: "On Hold",
    value: "on_hold",
  },
  {
    label: "Cancelled",
    value: "cancelled",
  },
  {
    label: "Blacklisted",
    value: "blacklisted",
  },
];

export const statusAssignList = [
  {
    label: "BD",
    value: "BD",
  },
  {
    label: "Proposal",
    value: "proposal",
  },
  {
    label: "Confirmed",
    value: "confirmed",
  },
  {
    label: "Mapping",
    value: "mapping",
  },
  {
    label: "In Progress",
    value: "in_progress",
  },
  {
    label: "Shortlisted",
    value: "shortlisted",
  },
  {
    label: "Closed",
    value: "closed",
  },
  {
    label: "On Hold",
    value: "on_hold",
  },
  {
    label: "Cancelled",
    value: "cancelled",
  },
];

export const statusAssignmentTDM = [
  {
    label: "BD",
    value: "BD",
  },
  {
    label: "Proposal",
    value: "proposal",
  },
  {
    label: "Confirmed",
    value: "confirmed",
  },
  {
    label: "On Hold",
    value: "on_hold",
  },
  {
    label: "Cancelled",
    value: "cancelled",
  },
  {
    label: "Closed",
    value: "closed",
  },
];

export const statusParticipant = [
  {
    label: "Pre-survey",
    value: "Pre-survey",
  },
  {
    label: "Attended",
    value: "Attended",
  },
  {
    label: "Absent",
    value: "Absent",
  },
  {
    label: "Feedback",
    value: "Feedback",
  },
  {
    label: "Certificate",
    value: "Certificate",
  },
];


export const statusAssignListFilter = [
  {
    label: "BD",
    value: "BD",
  },
  {
    label: "Proposal",
    value: "proposal",
  },
  {
    label: "Confirmed",
    value: "confirmed",
  },
  {
    label: "Mapping",
    value: "mapping",
  },
  {
    label: "In Progress",
    value: "in_progress",
  },
  {
    label: "In Discussion",
    value: "in_discussion",
  },
  {
    label: "Shortlisted",
    value: "shortlisted",
  },
  {
    label: "Closed",
    value: "closed",
  },
  {
    label: "On Hold",
    value: "on_hold",
  },
  {
    label: "Cancelled",
    value: "cancelled",
  },
];
