import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Form, Card, Row, Button, Tab, Nav, Spinner } from "react-bootstrap";
import Api from "../../../../core/services/api.service";
import Apiform from "../../../../core/services/apiform.service";

import AuthAbilities from "../../Auth/AuthAbilities";
import SwalAlert from "../../../../components/Swal/SwalAlert";

import Icon from "@mdi/react";
import {
  mdiPencil,
  mdiCheckDecagram,
  mdiContentSaveMove,
  mdiAccountGroup,
} from "@mdi/js";

// Component Customize
import BreadCrumb from "../../../../components/Breadcrumb/BreadCrumb";
import CardInfo from "../../../../components/Card/CardInfo";
import { ColumnInformationForm } from "../../../../components/Form/Form2";
import { InformationForm } from "../../../../components/Form/Form2";
import Modal from "../../../../components/Popup/ModalPopup";
import Searchable from "../../../../components/DropDown/SearchableSelect";
import TextFieldFormat from "../../../../components/TextField/TextFieldFormat";
// import DropDown from "../../../../components/DropDown/DropDown";

// Default Photo
// import iconNYSE from "../../../../assets/nyse.png";
import DefaultPhoto from "../../../../assets/no-profile-photo.jpg";

// Component Type Tab
import { TabStaff } from "../../../../components/Tab/TabStaff";
import { TabFiles } from "../../../../components/Tab/TabFiles";
import { TabMeeting } from "../../../../components/Tab/TabMeeting";
import { TabSubsidiary } from "../../../../components/Tab/TabSubsidiary";
// import { TabProposal } from "../../../../components/Tab/TabProposal";

import { storeIndexTab } from "../../../../store/actions/tabActiveSlice";
import {
  getClientDetails,
  getUpdateClientDetails,
} from "../../../../store/actions/clientSlice";
import { getListStaff } from "../../../../store/actions/staffSlice";
import { storeTitle } from "../../../../store/actions/headerTitleSlice";
import ImagesSE from "../../../../components/Images/ImagesSE";
// import { getListOptions } from "../../../../store/actions/optionSlice";

function ViewAllClients() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const abilities = AuthAbilities();
  const customSwal = SwalAlert();

  const { indexTab } = useSelector((state) => state.tabActive);
  const { clientDetails, clientDetailsIndustry, clientStockExchange } =
    useSelector((state) => state.client);
  const { dataCountry, dataIndustry, dataStockExchanges } = useSelector((state) => state.option);

  const [errorForm, setErrorForm] = useState({});

  const [showInfor, setShowInfor] = useState(false);
  // const [showBlacklist, setShowBlacklist] = useState(false);
  const [showUpload, setShowUpload] = useState(false);

  // const [consulName, setConsulName] = useState("");
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selIndNew, setSelIndNew] = useState([]);

  // const [status, setStatus] = useState("");
  const [isOpenStatus, setIsOpenStatus] = useState(false);

  const [checkListed, setCheckListed] = useState(false);
  const [crumbs, setCrumbs] = useState([]);

  const selectedCrumb = (crumb) => {
    if (crumb === "Companies") {
      navigate("/clients/listAllClients");
    }
  };

  const handleChange = (e) => {
    setSelIndNew(Array.isArray(e) ? e.map((x) => x.id) : []);
  };

  // function convertClient() {
  //   navigate("/clients/convert-client/" + params.id);
  // }

  const handleSelectTab = (key) => {
    dispatch(
      storeIndexTab({
        id: params.id,
        index: key,
      })
    );
  };

  const handleCheck = (e) => {
    // console.log(e.target.checked)
    setCheckListed(e.target.checked);
  };

  // Checking Form Error
  const formError = (object) => {
    const newErrors = {};

    // console.log("object", object);

    if (!object.txtcompanyname || object.txtcompanyname === "")
      newErrors.txtcompanyname = "Fill in the blank!";

    // if (!object.txtwebsite || object.txtwebsite === "")
    //   newErrors.txtwebsite = "Fill in the blank!";

    return newErrors;
  };

  // Button Event
  // button close and open modal popup
  const btnEditInfor = (e) => {
    setErrorForm({});
    setShowInfor(true);

    // setConsulName(clientDetails.consultant_name);
    setSelectedIndustry(clientDetailsIndustry);
    setSelIndNew(clientDetails.industry);
    setCheckListed(clientDetails.finance_certificate);
  };

  const btnCloseInfor = () => {
    setSelIndNew([]);
    setShowInfor(false);
  };

  const btnSaveUpdate = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtCom = Object.fromEntries(data.entries());

    // console.log("dtCom", dtCom);

    const newErrors = formError(dtCom);

    let websiteCheck = !dtCom.txtwebsite.substring(0, dtCom.txtwebsite.indexOf(':')) ? !dtCom.txtwebsite ? "" : "http://" + dtCom.txtwebsite : dtCom.txtwebsite;
    let linkedInCheck = !dtCom.txtlinkedln.substring(0, dtCom.txtlinkedln.indexOf(':')) ? !dtCom.txtlinkedln ? "" : "http://" + dtCom.txtlinkedln : dtCom.txtlinkedln;

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      customSwal
        .fire({
          title: "Do you want to update the information?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const objClient = {
              name: dtCom.txtcompanyname,
              incorporated: dtCom.txtincorporate,
              consultant_name: [],
              industry: selIndNew.length > 0 ? selIndNew : [],
              headquarters: dtCom.ddlcorporate ? Number(dtCom.ddlcorporate) : null,
              address: dtCom.txtcompanyaddr,
              general_telephone: dtCom.txtphone,
              website_url: websiteCheck,
              linkedln_url: linkedInCheck,
              revenue: dtCom.txtrevenue,
              total_employees: dtCom.txtcompanyEmp,
              finance_certificate: checkListed,
              stock_exchange: dtCom.ddlStockExchanges ? Number(dtCom.ddlStockExchanges) : null,
              overall_background: dtCom.txtoverall,
              important_note: dtCom.txtnotes,
            };

            dispatch(getUpdateClientDetails(params.id, objClient));

            setShowInfor(false);
            customSwal.fire({
              title: "Information Updated Successfully!",
              icon: "success",
              confirmButtonText: "Okay",
            });
          }
        });
    }
  };

  const btnConvertPot = (e) =>{
    customSwal
      .fire({
        title: "Do you want to convert potential client?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {

          Api.post("client/" + params.id + "/convertToPotential")
            .then((response) => {

              navigate("/clients/listPotentialC");
              customSwal.fire({
                title: "Converted Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("user/store", error);
            });

        }
      });
  };

  const btnCloseUpload = () => {
    setShowUpload(false);
  };

  const btnEditImages = () => {
    if (!abilities.includes("edit potential")) {
    } else {
      setShowUpload(true);
    }
  };

  const btnSaveImage = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtUpload = Object.fromEntries(data.entries());

    // console.log("dtUpload", dtUpload);
    customSwal
      .fire({
        title: "Do you want to update image client?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          const formData = new FormData();

          formData.append("file", dtUpload.txtimages);

          Apiform.post(
            "client/" + params.id + "/updateProfilePicture",
            formData
          )
            .then((response) => {
              dispatch(getClientDetails(params.id));

              setShowUpload(false);

              customSwal.fire({
                title: "Profile Updated Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error btnSaveImage", error);
            });
        }
      });
  };

  const handleParentClick = (id, isPotential, status) => (e) => {
    if (isPotential === true && status !== null && status !== "closed") {
      window.open(process.env.REACT_APP_BASE_URL + "clients/view-potential-client/" + id);
    } else if (isPotential === false && status !== null && status !== "closed") {
      window.open(process.env.REACT_APP_BASE_URL + "clients/viewActiveC/" + id);
    } else{
      window.open(process.env.REACT_APP_BASE_URL + "clients/viewAllClients/" + id);
    }
  };

  // End Button Event

  // Load View Potential
  useEffect(() => {
    dispatch(getClientDetails(params.id));
    dispatch(getListStaff());

    const name = clientDetails.name;
    setCrumbs(["Companies", name]);
    dispatch(storeTitle({ title: "Companies", icon: mdiAccountGroup }));

    if (clientDetails.status === "blacklisted") {
      // setStatus("error-color");
      setIsOpenStatus(!isOpenStatus);
    } else {
      // setStatus("primary-color");
    }
  }, [
    clientDetails.name,
    dispatch,
    clientDetails.status,
    params.id,
    isOpenStatus,
  ]);

  return (
    <>
      {Object.keys(clientDetails).length === 0 ? 
        <div id="main-overlay" className="main-overlay">
          <Spinner animation="border" variant="light" />
        </div>:""}
      <div className="main-div">
        <div className="container-div both-side">
          <BreadCrumb crumbs={crumbs} selected={selectedCrumb}></BreadCrumb>
          <Button
            variant="primary"
            onClick={btnConvertPot}
            // hidden={!abilities.includes("convert potential")}
          >
            <Icon path={mdiContentSaveMove} />
            <div>Convert to potential client</div>
          </Button>
        </div>

        <div className="top-title-div">
          <Card>
            <Card.Body className="card-body">
              <div className="container-between-div">
                <div className="center-flex1-div">
                  <span className="textUpload" onClick={btnEditImages}>
                    Upload
                  </span>
                  {clientDetails.logo ? (
                    <img
                      src={clientDetails.logo}
                      onClick={btnEditImages}
                      style={{ cursor: "pointer" }}
                      alt=""
                      width="140px"
                      height="137px"
                    />
                  ) : (
                    <img
                      src={DefaultPhoto}
                      onClick={btnEditImages}
                      style={{ cursor: "pointer" }}
                      alt=""
                      width="140px"
                      height="137px"
                    />
                  )}
                </div>
                <div className="left-flex9-div">
                  <div className="title-div">
                    {clientDetails.name}{" "}
                    {clientDetails.finance_certificate ? (
                      <Icon path={mdiCheckDecagram} />
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        marginLeft: "5px",
                        marginTop: "5px",
                        fontSize: "10px",
                      }}
                    >
                      {clientDetails.client ? "Owned By : " : ""}
                    </div>
                    <div
                      style={{
                        marginLeft: "5px",
                        marginTop: "5px",
                        fontSize: "10px",
                      }}
                    >
                      {clientDetails.client ? (
                        <>
                          <Button
                            variant="link"
                            className="no-underline"
                            style={{ fontSize: "10px" }}
                            onClick={handleParentClick(
                              clientDetails.client.id,
                              clientDetails.client.is_potential,
                              clientDetails.client.status
                            )}
                          >
                            {clientDetails.client.name}
                          </Button>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="right-div" style={{ flex: 1 }}>
                      <Button
                        variant="link"
                        className="modal-link"
                        onClick={btnEditInfor}
                        hidden={!abilities.includes("edit potential")}
                      >
                        <Icon path={mdiPencil} color={"#EC782F"} />
                        <div>Edit Information</div>
                      </Button>
                    </div>
                  </div>
                  <Row>
                    <div className="container-info-column col-4">
                      <ColumnInformationForm label="Corporate HQ">
                        {clientDetails.options
                          ? clientDetails.options.value
                          : "-"}
                      </ColumnInformationForm>
                      <ColumnInformationForm label="Industry">
                        {/* <FormInfo> */}
                        <div className="card_text_container">
                          {clientDetailsIndustry.length > 0
                            ? clientDetailsIndustry.map((industryLabel, i) => (
                                <CardInfo
                                  key={i}
                                  cardTextSize="card_text--xsmall"
                                  cardTextStyle="card_text--default--solid"
                                >
                                  {industryLabel.value}
                                </CardInfo>
                              ))
                            : "-"}
                        </div>

                        {/* </FormInfo> */}
                      </ColumnInformationForm>
                    </div>
                    <div className="container-info-column col-3">
                      <ColumnInformationForm label="Incorporated">
                        {clientDetails.incorporated
                          ? clientDetails.incorporated
                          : "-"}
                      </ColumnInformationForm>
                    </div>
                  </Row>
                </div>
              </div>
              <div>
                <Row>
                  <div className="column-right-div col">
                    {clientDetails.finance_certificate ? (
                      clientStockExchange ? 
                      <ImagesSE type={clientStockExchange.code}></ImagesSE> : null
                    ) : null}
                  </div>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </div>

        <div className="container-div">
          <div className="container-tabs">
            <Tab.Container
              defaultActiveKey={indexTab ? indexTab.index : "1"}
              transition={true}
              onSelect={handleSelectTab}
            >
              <div className="nav-container scrollable">
                <Nav variant="tabs" justify>
                  <Nav.Item>
                    <Nav.Link eventKey="1">Company Information</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="2">Subsidiaries</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="3">Meetings</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="4">Staff</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="5">Files / Documents</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>

              <Tab.Content>
                <Tab.Pane eventKey="1">
                  <div className="tab-pane-div">
                    <div className="right-div">
                      <Button
                        variant="link"
                        className="modal-link"
                        onClick={btnEditInfor}
                        hidden={!abilities.includes("edit potential")}
                      >
                        <Icon path={mdiPencil} />
                        <div>Edit Information</div>
                      </Button>
                    </div>

                    <div>
                      <div className="container-form-div">
                        {/* 1 */}
                        <Row className="client-dets">
                          <div className="col-6">
                            <InformationForm
                              label="Address"
                              sizeCol1Style="col-30"
                              sizeCol2Style="col-70"
                            >
                              <div
                                style={{
                                  textAlign: "justify",
                                  wordBreak: "break-word",
                                }}
                              >
                                {clientDetails.address
                                  ? clientDetails.address
                                  : "-"}
                              </div>
                            </InformationForm>
                          </div>
                          <div className="col-6">
                            <InformationForm
                              label="General Telephone"
                              sizeCol1Style="col-30"
                              sizeCol2Style="col-70"
                            >
                              {clientDetails.general_telephone
                                ? clientDetails.general_telephone
                                : "-"}
                            </InformationForm>
                          </div>
                        </Row>
                        {/* 2 */}
                        <Row className="client-dets">
                          <div className="col-6">
                            <InformationForm
                              label="Website"
                              sizeCol1Style="col-30"
                              sizeCol2Style="col-70"
                            >
                              {clientDetails.website_url ? (
                                <a
                                  href={clientDetails.website_url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {clientDetails.website_url}
                                </a>
                              ) : (
                                "-"
                              )}
                            </InformationForm>
                          </div>
                          <div className="col-6">
                            <InformationForm
                              label="LinkedIn Profile"
                              sizeCol1Style="col-30"
                              sizeCol2Style="col-70"
                            >
                              {clientDetails.linkedln_url ? (
                                <a
                                  href={clientDetails.linkedln_url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {clientDetails.linkedln_url}
                                </a>
                              ) : (
                                "-"
                              )}
                            </InformationForm>
                          </div>
                        </Row>
                        {/* 3 */}
                        <Row className="client-dets">
                          <div className="col-6">
                            <InformationForm
                              label="Revenue"
                              sizeCol1Style="col-30"
                              sizeCol2Style="col-70"
                            >
                              {clientDetails.revenue
                                ? clientDetails.revenue
                                : "-"}
                            </InformationForm>
                          </div>
                          <div className="col-6">
                            <InformationForm
                              label="Employees"
                              sizeCol1Style="col-30"
                              sizeCol2Style="col-70"
                            >
                              {clientDetails.total_employees
                                ? clientDetails.total_employees
                                : "-"}
                            </InformationForm>
                          </div>
                        </Row>
                        {/* 4 */}
                        <Row className="client-dets">
                          <InformationForm
                            label="Overall Background"
                            sizeCol1Style="col-30"
                            sizeCol2Style="col-70"
                          >
                            <div
                              style={{
                                textAlign: "justify",
                                wordBreak: "break-word",
                                whiteSpace: "pre-wrap"
                              }}
                            >
                              {clientDetails.overall_background
                                ? clientDetails.overall_background
                                : "-"}
                            </div>
                          </InformationForm>
                        </Row>
                        {/* 5 */}
                        <Row className="client-dets">
                          <InformationForm
                            label="Important Notes"
                            sizeCol1Style="col-30"
                            sizeCol2Style="col-70"
                          >
                            <div
                              style={{
                                textAlign: "justify",
                                wordBreak: "break-word",
                                whiteSpace: "pre-wrap"
                              }}
                            >
                              {clientDetails.important_note
                                ? clientDetails.important_note
                                : "-"}
                            </div>
                          </InformationForm>
                        </Row>
                      </div>
                    </div>

                    <Modal
                      show={showInfor}
                      size="lg"
                      backdrop="static"
                      keyboard={false}
                      onHide={btnCloseInfor}
                      title="Edit Information"
                      content={
                        <>
                          <Form id="infor-form" onSubmit={btnSaveUpdate}>
                            {/* 1 */}
                            <Row>
                              <Form.Group className="mb-3">
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control
                                  name="txtcompanyname"
                                  type="text"
                                  defaultValue={clientDetails.name}
                                  isInvalid={!!errorForm.txtcompanyname}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errorForm.txtcompanyname}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            {/* 2 */}
                            <Row>
                              <Form.Group className="mb-3 col-6">
                                <Form.Label>Incorporated</Form.Label>
                                <Form.Control
                                  name="txtincorporate"
                                  type="text"
                                  defaultValue={clientDetails.incorporated}
                                />
                              </Form.Group>
                              <Form.Group className="mb-3 col-6">
                                <Form.Label>Corporate HQ</Form.Label>
                                <Form.Select
                                  name="ddlcorporate"
                                  defaultValue={clientDetails.headquarters}
                                >
                                  <option value="">Select</option>
                                  {dataCountry.map((cntry, index) => (
                                    <option key={index} value={cntry.id}>
                                      {cntry.value}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                            </Row>
                            {/* 3 */}
                            <Row>
                              <Form.Group className="mb-3">
                                <Form.Label>Industry</Form.Label>
                                <Searchable
                                  isMulti={true}
                                  options={dataIndustry}
                                  defaultValue={selectedIndustry}
                                  onChange={handleChange}
                                  getOptionValue={(option) => option.id}
                                  getOptionLabel={(option) => option.value}
                                ></Searchable>
                              </Form.Group>
                            </Row>
                            {/* 4 */}
                            <Row>
                              <Form.Group className="mb-3">
                                <Form.Label>Company Address</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  name="txtcompanyaddr"
                                  rows={3}
                                  defaultValue={clientDetails.address}
                                />
                              </Form.Group>
                            </Row>
                            {/* 5 */}
                            <Row>
                              <Form.Group className="mb-3">
                                <Form.Label>Company General Telephone</Form.Label>
                                <TextFieldFormat
                                  value={
                                    clientDetails.general_telephone
                                      ? clientDetails.general_telephone
                                      : ""
                                  }
                                  format="+### ### ######## ######"
                                  name="txtphone"
                                ></TextFieldFormat>
                              </Form.Group>
                            </Row>
                            {/* 6 */}
                            <Row>
                              <Form.Group className="mb-3 col-6">
                                <Form.Label>Company Website</Form.Label>
                                <Form.Control
                                  name="txtwebsite"
                                  type="text"
                                  defaultValue={clientDetails.website_url}
                                  placeholder="http://"
                                  // isInvalid={!!errorForm.txtwebsite}
                                />
                                {/* <Form.Control.Feedback type="invalid">
                                  {errorForm.txtwebsite}
                                </Form.Control.Feedback> */}
                              </Form.Group>

                              <Form.Group className="mb-3 col-6">
                                <Form.Label>Company LinkedIn Profile</Form.Label>
                                <Form.Control
                                  name="txtlinkedln"
                                  type="text"
                                  defaultValue={clientDetails.linkedln_url}
                                  placeholder="http://"
                                />
                              </Form.Group>
                            </Row>
                            {/* 7 */}
                            <Row>
                              <Form.Group className="mb-3 col-6">
                                <Form.Label>Company Revenue</Form.Label>
                                <Form.Control
                                  name="txtrevenue"
                                  type="text"
                                  defaultValue={clientDetails.revenue}
                                />
                              </Form.Group>

                              <Form.Group className="mb-3 col-6">
                                <Form.Label>Company Employees</Form.Label>
                                <Form.Control
                                  name="txtcompanyEmp"
                                  type="text"
                                  defaultValue={clientDetails.total_employees}
                                />
                              </Form.Group>
                            </Row>
                            {/* 8 */}
                            <Row>
                              <Form.Group className="mb-3">
                                <Form.Check
                                  name="cbListed"
                                  type="checkbox"
                                  onChange={handleCheck}
                                  defaultChecked={
                                    clientDetails.finance_certificate
                                  }
                                  label="Is this a listed company?"
                                />
                              </Form.Group>
                            </Row>
                            {/* 10 */}
                            {checkListed ? 
                              <Row>
                                <Form.Group className="mb-3">
                                  <Form.Label>Stock Exchanges</Form.Label>
                                  <Form.Select 
                                    name="ddlStockExchanges"
                                    defaultValue={clientStockExchange ? clientStockExchange.id : ""}
                                    >
                                    <option value="">Select</option>
                                    {dataStockExchanges.map((stock, index) => (
                                      <option key={index} value={stock.id}>
                                        {stock.value}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              </Row> : ""
                            }
                            {/* 9 */}
                            <Row>
                              <Form.Group className="mb-3">
                                <Form.Label>Overall Background</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  name="txtoverall"
                                  rows={3}
                                  defaultValue={clientDetails.overall_background}
                                />
                              </Form.Group>
                            </Row>
                            {/* 10 */}
                            <Row>
                              <Form.Group className="mb-3">
                                <Form.Label>Important Notes</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  name="txtnotes"
                                  rows={3}
                                  defaultValue={clientDetails.important_note}
                                />
                              </Form.Group>
                            </Row>
                          </Form>
                        </>
                      }
                      button={
                        <>
                          <Button variant="secondary" onClick={btnCloseInfor}>
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            form="infor-form"
                            variant="primary"
                          >
                            Update
                          </Button>
                        </>
                      }
                    ></Modal>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="2">
                  <TabSubsidiary></TabSubsidiary>
                </Tab.Pane>
                <Tab.Pane eventKey="3">
                  <TabMeeting id={params.id} page="companies"></TabMeeting>
                </Tab.Pane>
                <Tab.Pane eventKey="4">
                  <TabStaff id={params.id} page="companies"></TabStaff>
                </Tab.Pane>
                <Tab.Pane eventKey="5">
                  <TabFiles id={params.id}></TabFiles>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>

        {/* Modal Upload Images */}
        <Modal
          show={showUpload}
          size="xs"
          backdrop="static"
          keyboard={false}
          onHide={btnCloseUpload}
          title="Upload Image"
          content={
            <>
              <Form id="upload-form" onSubmit={btnSaveImage}>
                <Form.Group as={Row} className="mb-1">
                  <Form.Control
                    type="file"
                    name="txtimages"
                    accept=".png,.jpg,.jpeg"
                  />
                  <Form.Text className="text-muted">
                    Allowed file types: jpg, jpeg, png.
                  </Form.Text>
                </Form.Group>
              </Form>
            </>
          }
          button={
            <>
              <Button variant="secondary" onClick={btnCloseUpload}>
                Cancel
              </Button>
              <Button type="submit" form="upload-form" variant="primary">
                Upload
              </Button>
            </>
          }
        ></Modal>
      </div>
    </>
  );
}

export default ViewAllClients;
