import axios from 'axios';
import Cookies from 'js-cookie';

const fetchClient = () => {
    const defaultOptions = {
      baseURL: process.env.REACT_APP_BASE_URL_API,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
  
    // Create instance
    let instance = axios.create(defaultOptions);
  
    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config) {
      const token = Cookies.get('id_token');
      config.headers.Authorization =  token ? `Bearer ${token}` : '';
      return config;
    });
  
    return instance;
  };
  
  export default fetchClient();

