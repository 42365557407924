import React from 'react'

function CardCandidate({
  children
}) {
  return (
    <div className='card-education'>
      {children}
    </div>
    
  )
}

export default CardCandidate