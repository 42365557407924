import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import Api from "../../../../core/services/api.service";

import PasswordChecklist from "react-password-checklist";
import SwalAlert from "../../../../components/Swal/SwalAlert";
import jwt_decode from "jwt-decode";

import logo from "../../../../assets/logo.png";

function NewPassword() {

  const navigate = useNavigate();
  const params = useParams();

  const customSwal = SwalAlert();

  const [errorsPass, setErrorsPass] = useState({});

  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [validPassword, setValidPassword] = useState(false);

  const formErrorsPassword = (object) => {
    const newPassErrors = {};

    if (!object.txtNewPassword || object.txtNewPassword === "")
      newPassErrors.txtNewPassword = "Fill in the blank!";

    if (!object.txtConfirmPassword || object.txtConfirmPassword === "")
      newPassErrors.txtConfirmPassword = "Fill in the blank!";

    return newPassErrors;
  };

  const btnResetPassword = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtReset = Object.fromEntries(data.entries());

    const decoded = jwt_decode(params.id);

    const newErrors = formErrorsPassword(dtReset);

    if (Object.keys(newErrors).length > 0) {
      setErrorsPass(newErrors);
    } else {
      if (validPassword === true) {
        customSwal
          .fire({
            title: "Do you want to reset new password?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post("resetPassword/update", {
                id: decoded.id,
                password: dtReset.txtNewPassword,
                type: decoded.type,
              })
              .then((response) => {
            
                if(decoded.type === "client"){
                  navigate("/");
                }else if(decoded.type === "candidate"){
                  navigate("/candidate-login");
                }else{
                  navigate("/admin");
                }

                customSwal.fire({
                  title: "Reset Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                  console.log("Error reset password", error);
              });
            }
          });
      }
    }

    //   setErrorForm({});

    //   Api.post("resetPassword",{
    //     email: dtReset.txtemail
    //   })
    //   .then((response) => {

    //     console.log("response", response);

    //     if(response.data === "no email"){
    //       const newErrorsExist = fromErrorNoExistEmail(response.data);

    //       setErrorForm(newErrorsExist);
    //     }else{
    //       setEmailSend("We have sent reset password email to your email address!")
    //       setErrorForm({});
    //       document.getElementById("reset-form").reset();
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("error reset paswword", error);
    //   });
    // }
  };

  return (
    <div className="main-div" style={{ height: "100vh" }}>
      <div className="auth-logo-div">
        <img src={logo} alt="" />
      </div>
      <div className="reset-container">
        <div className="reset-form-container">
          <div className="title-form-section" style={{ margin: 0 }}>
            <div className="main-title">Reset New Password</div>
          </div>

          <div className="new-pw-note">
            In order to <b>protect your account</b>, make sure your password:
            <PasswordChecklist
              className="pw-checklist"
              rules={[
                "minLength",
                "capital",
                "lowercase",
                "number",
                "specialChar",
                "match",
              ]}
              minLength={8}
              value={password}
              valueAgain={passwordAgain}
              onChange={(isValid) => {
                setValidPassword(isValid);
              }}
              messages={{
                minLength: "has minimum of 8 characters.",
                capital: "include at least one uppercase letter (A-Z).",
                lowercase: "include at least one lowercase letter (a-z).",
                number: "include at least one number (1-9).",
                specialChar:
                  "include at least one special character (e.g. !, @, #, $).",
                match: "is match with confirm password.",
              }}
            />
          </div>

          <Form id="reset-password-form" onSubmit={btnResetPassword}>
            <Form.Group className="mb-3">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                name="txtNewPassword"
                type="password"
                placeholder="Enter your new password"
                onChange={(e) => setPassword(e.target.value)}
                isInvalid={!!errorsPass.txtNewPassword}
              />
              <Form.Control.Feedback type="invalid">
                {errorsPass.txtNewPassword}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                name="txtConfirmPassword"
                type="password"
                placeholder="Re-enter your new password"
                onChange={(e) => setPasswordAgain(e.target.value)}
                isInvalid={!!errorsPass.txtConfirmPassword}
              />
              <Form.Control.Feedback type="invalid">
                {errorsPass.txtConfirmPassword}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>

          <div className="row-btn-bottom">
            <Button variant="primary" type="submit" form="reset-password-form">
              Reset Password
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewPassword;
