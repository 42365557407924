import React from "react";
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import { useDispatch, useSelector } from "react-redux";

import Icon from "@mdi/react";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";

import "./Table.css";
import Empty from "../Empty/Empty";

import { storePage } from "../../store/actions/paginationSlice";

export const CustomTable = ({ headers, items, paginate, maxRows, pindex }) => {

  const { pageindex } = useSelector((state) => state.paging);

  const dispatch = useDispatch();

  const columns = headers;
  // useMemo(() => headers, [])
  const data = items;

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: maxRows ? maxRows : 15, pageIndex: pindex ? pageindex : 0 },
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    // setSortBy,
    state: { pageIndex, pageSize, selectedRowIds },
  } = tableInstance;

  return (
    <>
      {data.length > 0 ? (
        <>
          {/* <div className="container_table"> */}
          <table className="table-list" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index2) => {
                    return column.hideHeader === true ? (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps({
                            style: {
                              minWidth: column.minWidth,
                              width: column.width,
                            },
                          })
                        )}
                      >
                        {/* {column.render("Header")} */}
                      </th>
                    ) : (
                      <th
                        key={index2}
                        className="header_text"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps({
                            style: {
                              minWidth: column.minWidth,
                              width: column.width,
                            },
                          })
                        )}
                      >
                        {column.render("Header")}
                        {/* Add a sort direction indicator */}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Icon path={mdiChevronDown} size={1} />
                            ) : (
                              // ' 🔽'
                              <Icon path={mdiChevronUp} size={1} />
                            )
                          ) : (
                            // ' 🔼'
                            ""
                          )}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody className="tbody" {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr className="trow" {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        className="body_text"
                        data-label={cell.column.Header}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {paginate > pageSize ? (
            <div className="pagination page-cont">
              <button onClick={() => {gotoPage(0); pindex ? dispatch(storePage(0)) : <></> }} disabled={!canPreviousPage}>
                {"<<"}
              </button>{" "}
              <button
                onClick={() => {previousPage(); pindex ? dispatch(storePage(pageIndex - 1)) : <></>}}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button onClick={() => {nextPage(); pindex ? dispatch(storePage(pageIndex + 1)) : <></> }} disabled={!canNextPage}>
                {">"}
              </button>{" "}
              <button
                onClick={() =>{gotoPage(pageCount - 1); pindex ? dispatch(storePage(pageCount - 1)) : <></>}}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
              <span>
                Page{"  "}
                {pageIndex + 1} of {pageOptions.length}
                {/* <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "} */}
              </span>
              <span className="go-to-page">| Go to page: </span>{" "}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                min="1"
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                // style={{ width: "100px" }}
              />
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[15, 25, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          ) : null}

          <pre hidden>
            <code>
              {JSON.stringify(
                {
                  selectedRowIds: selectedRowIds,
                  "selectedFlatRows[].original": selectedFlatRows.map(
                    (d) => d.original
                  ),
                },
                null,
                2
              )}
            </code>
          </pre>
        </>
      ) : (
        <Empty />
      )}
    </>
  );
};

export default CustomTable;
