import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Form, Button } from "react-bootstrap";
import Api from "../../../core/services/api.service";
import Apiform from "../../../core/services/apiform.service";

import SwalAlert from "../../../components/Swal/SwalAlert";

import { mdiCardAccountDetails } from "@mdi/js";

import ProfileImage from "../../../assets/no-profile-photo.jpg";
import "./staff.css";
import BreadCrumb from "../../../components/Breadcrumb/BreadCrumb";

import { storeTitle } from "../../../store/actions/headerTitleSlice";

const EditStaff = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { dataDepartment, dataSalute, dataPhoneCode } = useSelector(
    (state) => state.option
  );

  const [profileImg, setProfileImg] = useState("");

  const [crumbs, setCrumbs] = useState([]);

  const [selectedStaff, setSelectedStaff] = useState({});
  const [errorForm, setErrorForm] = useState({});
  // const [createdDate, setCreatedDate] = useState("");
  const [salute, setSalute] = useState("");
  const [status, setStatus] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [department, setDepartment] = useState(0);
  const [role, setRole] = useState("");
  const [convBase64, setConvBase64] = useState("");

  const [listRole, setListRole] = useState([]);

  const selectedCrumb = (crumb) => {
    if (crumb === "List of Staff") {
      navigate("/staff");
    }
    if (crumb === "View Staff") {
      navigate("/viewStaff/" + params.id);
    }
  };

  const customSwal = SwalAlert();

  const formError = (object) => {
    const newErrors = {};

    const regEmail = /\S+@\S+\.\S+/;

    if (!object.ddlRole || object.ddlRole === "")
      newErrors.ddlRole = "Fill in the blank!";

    if (!object.ddlSalute || object.ddlSalute === "")
      newErrors.ddlSalute = "Fill in the blank!";

    if (!object.txtfirstname || object.txtfirstname === "")
      newErrors.txtfirstname = "Fill in the blank!";

    if (!object.txtlastname || object.txtlastname === "")
      newErrors.txtlastname = "Fill in the blank!";

    if (!object.txtemail || object.txtemail === "")
      newErrors.txtemail = "Fill in the blank!";
    else if (!regEmail.test(object.txtemail))
      newErrors.txtemail = "Invalid Email Address";

    return newErrors;
  };

  // event button
  const btnUpdateStaff = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dataStaff = Object.fromEntries(data.entries());

    const newErrors = formError(dataStaff);

    // console.log("dataStaff", convBase64);
    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      // update staff
      customSwal
        .fire({
          title: "Do you want to update staff?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();

            formData.append("file", dataStaff.txtprofile);
            formData.append("salutation", dataStaff.ddlSalute);
            formData.append("first_name", dataStaff.txtfirstname);
            formData.append("last_name", dataStaff.txtlastname);

            formData.append("email", dataStaff.txtemail);
            formData.append("staff_no", dataStaff.txtstaffno);
            formData.append("phone_no_code","");
            formData.append("phone_no", dataStaff.txtphone);

            formData.append("status", dataStaff.ddlStatus);
            formData.append("role", dataStaff.ddlRole);
            formData.append("department_id", dataStaff.ddlDepartment);

            formData.append("job_title", dataStaff.txtjobtitle);
            formData.append("signature", convBase64);

            Apiform.patch("user/" + params.id + "/update", formData)
              .then((response) => {
                // console.log("user/view", response);
                if (params.page === "view") {
                  navigate("/viewStaff/" + params.id);
                } else {
                  navigate("/staff");
                }
                customSwal.fire({
                  title: "Staff Updated Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("user/update", error);
              });
          }
        });
    }
  };

  const btnDiscard = (e) => {
    // console.log("page", params.page)
    if (params.page === "view") {
      navigate("/viewStaff/" + params.id);
    } else {
      navigate("/staff");
    }
  };

  // event onChange
  const uploadSingleFile = (e) => {
    // console.log("onChnage", URL.createObjectURL(e.target.files[0]))
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setProfileImg(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
  
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
  
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const uploadImage = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    // console.log("base64", base64)
    setConvBase64(base64);
    // avatar.src = base64;
    // textArea.innerText = base64;
  };

  const uploadSignature = (e) => {
    uploadImage(e);
  };

  function getListUserAccess() {
    Api.get("role")
      .then((response) => {
        let data = response.data;
        setListRole(data);
      })
      .catch((error) => {
        console.log("error getListUserAccess", error);
      });
  }

  const getSelectedStaff = useCallback(() => {
    Api.get("user/" + params.id + "/view")
      .then((response) => {
        let data = response.data;
        // console.log("data", data.profile_picture);
        setSelectedStaff(data);

        setSalute(data.salutation);
        setStatus(data.status);
        setPhoneCode(data.phone_no_code);
        setDepartment(data.department_id);
        setRole(data.user_has_roles[0].roles.id);

        data.profile_picture === null
          ? setProfileImg(ProfileImage)
          : setProfileImg(data.profile_picture);
      })
      .catch((error) => {
        console.log("user/view", error);
      });
  }, [params]);

  // First Load
  useEffect(() => {
    dispatch(
      storeTitle({ title: "List of Staff", icon: mdiCardAccountDetails })
    );

    if (params.page === "view") {
      setCrumbs(["List of Staff", "View Staff", "Edit Staff"]);
    } else {
      setCrumbs(["List of Staff", "Edit Staff"]);
    }

    getSelectedStaff();
    getListUserAccess();
  }, [getSelectedStaff, params.page, dispatch]);

  return (
    <div className="main-div">
      <div className="top-title-div">
        <BreadCrumb crumbs={crumbs} selected={selectedCrumb}></BreadCrumb>
      </div>
      <div className="bg-white-container">
        <Form onSubmit={btnUpdateStaff}>
          {/* 1 */}
          <Row className="row-upload-pic mb-3">
            <div className="img-box">
              <img src={profileImg} alt="Profile" />
            </div>
            <Form.Group className="mb-3 col-4">
              <Form.Control
                onChange={uploadSingleFile}
                name="txtprofile"
                type="file"
                accept=".png,.jpg,.jpeg"
              />
              <Form.Text className="text-muted">
                Allowed file types: jpg, jpeg, png.
              </Form.Text>
            </Form.Group>
          </Row>
          {/* 2 */}
          <Row>
            <Form.Group className="mb-3 col-6">
              <Form.Label>
                Role <label className="validation-star">*</label>
              </Form.Label>
              <Form.Select
                name="ddlRole"
                isInvalid={!!errorForm.ddlRole}
                value={role}
                onChange={(e) => {
                  setRole(e.target.value);
                }}
              >
                <option value="">Select</option>
                {listRole.map((role, index) => (
                  <option key={index} value={role.id}>
                    {role.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errorForm.ddlRole}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 col-6">
              <Form.Label>Status</Form.Label>
              <Form.Select
                name="ddlStatus"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
              >
                <option value="">Select</option>
                <option value="active">Active</option>
                <option value="inactive">Not Active</option>
              </Form.Select>
            </Form.Group>
          </Row>
          {/* 3 */}
          <Row>
            <Form.Group className="mb-3 col-6">
              <Form.Label>Staff Number</Form.Label>
              <Form.Control
                name="txtstaffno"
                type="text"
                placeholder="123456"
                defaultValue={selectedStaff.staff_no}
              />
            </Form.Group>

            <Form.Group className="mb-3 col-6">
              <Form.Label>Department</Form.Label>
              <Form.Select
                name="ddlDepartment"
                value={department}
                onChange={(e) => {
                  setDepartment(e.target.value);
                }}
              >
                <option value="">Select</option>
                {dataDepartment.map((depart, index) => (
                  <option key={index} value={depart.id}>
                    {depart.value}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>
          {/* 4 */}
          <Row>
            <Form.Group className="mb-3 col-6">
              <Form.Label>Job Title</Form.Label>
              <Form.Control
                name="txtjobtitle"
                type="text"
                placeholder="Please enter job title"
                defaultValue={selectedStaff.job_title}
              />
            </Form.Group>

            <Form.Group className="mb-3 col-6">
              <Form.Label>Signature</Form.Label>
              <Form.Control
                onChange={uploadSignature}
                name="txtsignature"
                id="txtsignature"
                type="file"
                accept=".png,.jpg,.jpeg"
              />
              <Form.Text className="text-muted">
                Allowed file types: jpg, jpeg, png.
              </Form.Text>
            </Form.Group>
          </Row>
          {/* 5 */}
          <Row>
            <Form.Group className="mb-3 col-6">
              <Form.Label>
                Salutation <label className="validation-star">*</label>
              </Form.Label>
              <Form.Select
                name="ddlSalute"
                value={salute}
                isInvalid={!!errorForm.ddlSalute}
                onChange={(e) => {
                  // console.log("e.target.value", e.target.value);
                  setSalute(e.target.value);
                }}
              >
                <option value="">Select</option>
                {dataSalute.map((salute, index) => (
                  <option key={index} value={salute.value}>
                    {salute.value}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errorForm.ddlSalute}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          {/* 6 */}
          <Row>
            <Form.Group className="mb-3 col-6">
              <Form.Label>
                First Name <label className="validation-star">*</label>
              </Form.Label>
              <Form.Control
                name="txtfirstname"
                type="text"
                placeholder="Please enter first name"
                defaultValue={selectedStaff.first_name}
                isInvalid={!!errorForm.txtfirstname}
              />
              <Form.Control.Feedback type="invalid">
                {errorForm.txtfirstname}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 col-6">
              <Form.Label>
                Last Name <label className="validation-star">*</label>
              </Form.Label>
              <Form.Control
                name="txtlastname"
                type="text"
                placeholder="Please enter last name"
                defaultValue={selectedStaff.last_name}
                isInvalid={!!errorForm.txtlastname}
              />
              <Form.Control.Feedback type="invalid">
                {errorForm.txtlastname}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          {/* 7 */}
          <Row>
            <Form.Group className="mb-3 col-6">
              <Form.Label>
                Email Address <label className="validation-star">*</label>
              </Form.Label>
              <Form.Control
                name="txtemail"
                type="email"
                placeholder="Please enter email address"
                defaultValue={selectedStaff.email}
                isInvalid={!!errorForm.txtemail}
              />
              <Form.Control.Feedback type="invalid">
                {errorForm.txtemail}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 col-6">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                name="txtphone"
                // className="read-num"
                as="textarea"
                defaultValue={selectedStaff.phone_no}
                rows={1}
              />
              {/* <Row>
                <div className="col-3">
                  <Form.Select
                    name="ddlPhoneCode"
                    value={phoneCode}
                    onChange={(e) => {
                      setPhoneCode(e.target.value);
                    }}
                  >
                    <option value="">Select</option>
                    {dataPhoneCode.map((pcode, index) => (
                      <option key={index} value={pcode.value}>
                        {pcode.value}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div className="col-9">
                  <Form.Control
                    name="txtphone"
                    type="number"
                    placeholder="Please enter phone number"
                    defaultValue={selectedStaff.phone_no}
                  />
                </div>
              </Row> */}
            </Form.Group>
          </Row>

          <div className="row-btn-bottom">
            <Button onClick={btnDiscard} variant="secondary">
              Discard
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default EditStaff;
