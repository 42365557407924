import {createSlice} from '@reduxjs/toolkit';
import Api from '../../core/services/api.service';

export const assignmentSlice = createSlice({
    name: "assignment",
    initialState: {
        assignDetails: {},
        assignCountry: null,
        assignStaff: [],
        assignEcpQcp: [],
        assignList: [],
        assignListES: [],
        assignListTM: [],
        assignListDM: [],

        assignListData: [],
        assignAllListData: [],
        assignFilter: {
          txtsearchassign: "",
          txtassignname: "",
          ddltypeassign: "",
          ddlStatus: ""
        },
    },
    reducers: {
        getAssignDetails: (state, action) => {
            state.assignDetails = action.payload;
        },
        getAssignCountry: (state, action) => {
            state.assignCountry = action.payload;
        },
        getAssignStaff: (state, action) => {
            state.assignStaff = action.payload;
        },
        getAssignECPQCP: (state, action) => {
            state.assignEcpQcp = action.payload;
        },
        getAssignList: (state, action) => {
          state.assignList = action.payload;
        },
        getAssignListES: (state, action) => {
          state.assignListES = action.payload;
        },
        getAssignListTM: (state, action) => {
          state.assignListTM = action.payload;
        },
        getAssignListDM: (state, action) => {
          state.assignListDM = action.payload;
        },
        // All Assignment List
        setListAssignments: (state, action) => {
          state.assignListData = action.payload;
        },
        setAllListAssignments: (state, action) => {
          state.assignAllListData = action.payload;
        },
        setStoreAssignFilter: (state, action) => {
          state.assignFilter = { ...state.assignFilter, ...action.payload };
        },
    
    },
});

export const getAssignmentDetails = (id, idassign) => async (dispatch, rejectWithValue) => {
    try {
      const response = await Api.get("client/" + id + "/assignment/" + idassign);
      dispatch(getAssignDetails(response.data));
      dispatch(getAssignCountry(response.data.location.value));
      dispatch(getAssignStaff(response.data.assign_to_detail));
    } catch (err) {
      throw rejectWithValue(err.message);
    }
};

export const getAssignmentEcpQcp = (id, idassign) => async (dispatch) => {
    try {
      const response = await Api.get("candidate/" + id + "/assignment/" + idassign + "/ecpqcp");

      if (response.data.ecp.length !== 0 && response.data.qcp.length !== 0) {
        dispatch(getAssignECPQCP([
            {
                id: response.data.ecp[0].id,
                assignment_id: response.data.ecp[0].assignment_id,
                candidate_id: response.data.ecp[0].candidate_id,
                created_at: response.data.ecp[0].created_at,
                created_by: response.data.ecp[0].created_by,
                motivation: response.data.ecp[0].motivation,
                recommendation: response.data.ecp[0].recommendation,
                status: response.data.ecp[0].status,
                type: "ecp",
            },
            {
                id: response.data.qcp[0].id,
                assignment_id: response.data.qcp[0].assignment_id,
                candidate_id: response.data.qcp[0].candidate_id,
                created_at: response.data.qcp[0].created_at,
                created_by: response.data.qcp[0].created_by,
                motivation: response.data.qcp[0].motivation,
                recommendation: response.data.qcp[0].recommendation,
                status: response.data.qcp[0].status,
                type: "qcp", 
            }
        
        ]));
      }else if(response.data.ecp.length !== 0 && response.data.qcp.length === 0){
        dispatch(getAssignECPQCP([
            {
                id: response.data.ecp[0].id,
                assignment_id: response.data.ecp[0].assignment_id,
                candidate_id: response.data.ecp[0].candidate_id,
                created_at: response.data.ecp[0].created_at,
                created_by: response.data.ecp[0].created_by,
                motivation: response.data.ecp[0].motivation,
                recommendation: response.data.ecp[0].recommendation,
                status: response.data.ecp[0].status,
                type: "ecp",
            },
        ]));
      }else if(response.data.ecp.length === 0 && response.data.qcp.length !== 0){
        dispatch(getAssignECPQCP([
            {
                id: response.data.qcp[0].id,
                assignment_id: response.data.qcp[0].assignment_id,
                candidate_id: response.data.qcp[0].candidate_id,
                created_at: response.data.qcp[0].created_at,
                created_by: response.data.qcp[0].created_by,
                motivation: response.data.qcp[0].motivation,
                recommendation: response.data.qcp[0].recommendation,
                status: response.data.qcp[0].status,
                type: "qcp",
            },
        ]));
      }else{
        dispatch(getAssignECPQCP([]));
      }
      
    } catch (err) {
      throw new Error(err);
    }
};

export const getAssignmentList = (id) => async (dispatch, rejectWithValue) => {
  try {
    const response = await Api.get("client/" + id + "/assignment");

    if (response.data !== "no data") {

      dispatch(getAssignList(response.data));

      let listES = response.data.filter(
        (assign) => assign.type === "ES"
      );

      dispatch(getAssignListES(listES));

      let listTM = response.data.filter(
        (assign) => assign.type === "TM"
      );

      dispatch(getAssignListTM(listTM));

      let listDM = response.data.filter(
        (assign) => assign.type === "DM"
      );

      dispatch(getAssignListDM(listDM));
    }

    
    
  } catch (err) {
    throw rejectWithValue(err.message);
  }
};

export const getListDataAssignments = (assCompany, assName, assStatus, assType) => async (dispatch, rejectWithValue) => {
  try {
    const response = await Api.get("assignment");

    let data_array = response.data;
    let newList = [];

          if(assCompany !== "" && assName === "" && assStatus === "" && assType === ""){
      newList = data_array.filter((assign) => {
        return assign.clients ? assign.clients.name.toLowerCase().includes(assCompany.toLowerCase()) : "";
      });

      dispatch(setAllListAssignments(newList));
    }else if(assCompany !== "" && assName !== "" && assStatus === "" && assType === ""){
      newList = data_array.filter((assign) => {

        let assignName = "";

        if (assign.clients !== null) {
          assignName = assign.clients.name;
        } else {
          assignName = "";
        };

        return (
          assignName.toLowerCase().includes(assCompany.toLowerCase()) && 
          assign.title.toLowerCase().includes(assName.toLowerCase())
        )
      });

      dispatch(setAllListAssignments(newList));
    }else if(assCompany !== "" && assName !== "" && assStatus !== "" && assType === ""){
      newList = data_array.filter((assign) => {

        let assignName = "";

        if (assign.clients !== null) {
          assignName = assign.clients.name;
        } else {
          assignName = "";
        };

        return (
          assignName.toLowerCase().includes(assCompany.toLowerCase()) && 
          assign.title.toLowerCase().includes(assName.toLowerCase()) &&
          assign.status.toLowerCase().includes(assStatus.toLowerCase())
        );
      });

      dispatch(setAllListAssignments(newList));
    }else if(assCompany !== "" && assName !== "" && assStatus !== "" && assType !== ""){
      newList = data_array.filter((assign) => {

        let assignName = "";

        if (assign.clients !== null) {
          assignName = assign.clients.name;
        } else {
          assignName = "";
        };

        return (
          assignName.toLowerCase().includes(assCompany.toLowerCase()) && 
          assign.title.toLowerCase().includes(assName.toLowerCase()) &&
          assign.status.toLowerCase().includes(assStatus.toLowerCase()) &&
          assign.type.toLowerCase().includes(assType.toLowerCase())
        );
      });

      dispatch(setAllListAssignments(newList));
    }

     else if(assCompany !== "" && assName !== "" && assStatus === "" && assType !== ""){
      newList = data_array.filter((assign) => {

        let assignName = "";

        if (assign.clients !== null) {
          assignName = assign.clients.name;
        } else {
          assignName = "";
        };

        return (
          assignName.toLowerCase().includes(assCompany.toLowerCase()) && 
          assign.title.toLowerCase().includes(assName.toLowerCase()) &&
          assign.type.toLowerCase().includes(assType.toLowerCase())
        );
      });

      dispatch(setAllListAssignments(newList));
    }else if(assCompany !== "" && assName === "" && assStatus !== "" && assType === ""){
      newList = data_array.filter((assign) => {

        let assignName = "";

        if (assign.clients !== null) {
          assignName = assign.clients.name;
        } else {
          assignName = "";
        };

        return (
          assignName.toLowerCase().includes(assCompany.toLowerCase()) && 
          assign.status.toLowerCase().includes(assStatus.toLowerCase())
        );
      });

      dispatch(setAllListAssignments(newList));
    }else if(assCompany !== "" && assName === "" && assStatus !== "" && assType !== ""){
      newList = data_array.filter((assign) => {

        let assignName = "";

        if (assign.clients !== null) {
          assignName = assign.clients.name;
        } else {
          assignName = "";
        };

        return (
          assignName.toLowerCase().includes(assCompany.toLowerCase()) && 
          assign.status.toLowerCase().includes(assStatus.toLowerCase()) &&
          assign.type.toLowerCase().includes(assType.toLowerCase())
        );
      });

      dispatch(setAllListAssignments(newList));
    }else if(assCompany !== "" && assName === "" && assStatus === "" && assType !== ""){
      newList = data_array.filter((assign) => {

        let assignName = "";

        if (assign.clients !== null) {
          assignName = assign.clients.name;
        } else {
          assignName = "";
        };

        return (
          assignName.toLowerCase().includes(assCompany.toLowerCase()) && 
          assign.type.toLowerCase().includes(assType.toLowerCase())
        );
      });

      dispatch(setAllListAssignments(newList));
    }
    
    
     else if(assCompany === "" && assName !== "" && assStatus === "" && assType === ""){
      newList = data_array.filter((assign) => {
        return (
          assign.title.toLowerCase().includes(assName.toLowerCase())
        );
      });

      dispatch(setAllListAssignments(newList));
    }else if(assCompany === "" && assName !== "" && assStatus !== "" && assType === ""){
      newList = data_array.filter((assign) => {
        return (
          assign.title.toLowerCase().includes(assName.toLowerCase()) &&
          assign.status.toLowerCase().includes(assStatus.toLowerCase())
        );
      });

      dispatch(setAllListAssignments(newList));
    }else if(assCompany === "" && assName !== "" && assStatus !== "" && assType !== ""){
      newList = data_array.filter((assign) => {
        return (
          assign.title.toLowerCase().includes(assName.toLowerCase()) &&
          assign.status.toLowerCase().includes(assStatus.toLowerCase()) &&
          assign.type.toLowerCase().includes(assType.toLowerCase())
        );
      });

      dispatch(setAllListAssignments(newList));
    }else if(assCompany === "" && assName !== "" && assStatus === "" && assType !== ""){
      newList = data_array.filter((assign) => {
        return (
          assign.title.toLowerCase().includes(assName.toLowerCase()) &&
          assign.type.toLowerCase().includes(assType.toLowerCase())
        );
      });

      dispatch(setAllListAssignments(newList));
    }else if(assCompany === "" && assName === "" && assStatus !== "" && assType === ""){
      newList = data_array.filter((assign) => {
        return (
          assign.status.toLowerCase().includes(assStatus.toLowerCase()) 
        );
      });

      dispatch(setAllListAssignments(newList));
    }else if(assCompany === "" && assName === "" && assStatus !== "" && assType !== ""){
      newList = data_array.filter((assign) => {
        return (
          assign.status.toLowerCase().includes(assStatus.toLowerCase()) &&
          assign.type.toLowerCase().includes(assType.toLowerCase())
        );
      });

      dispatch(setAllListAssignments(newList));
    }else if(assCompany === "" && assName === "" && assStatus === "" && assType !== ""){
      newList = data_array.filter((assign) => {
        return (
          assign.type.toLowerCase().includes(assType.toLowerCase())
        );
      });

      dispatch(setAllListAssignments(newList));
    }
    else{
      dispatch(setListAssignments(data_array));
      dispatch(setAllListAssignments(data_array));
    }
    
  } catch (err) {
    throw rejectWithValue(err.message);
  }
};

export const { getAssignDetails, getAssignCountry, getAssignStaff, getAssignECPQCP, getAssignList, getAssignListES, getAssignListTM, getAssignListDM,
  setListAssignments, setAllListAssignments, setStoreAssignFilter
} = assignmentSlice.actions;
// export const showTodo = (state) => state.todo.data;
export default assignmentSlice.reducer;