// import React from 'react';

export const rptFooter = () => {
  const reportFooter = `
    
    <p style='font-size:7.5pt; font-weight:bold; text-align:center;'>Lim-Loges & Masters Pte Ltd (Company Reg. No. 201614475K) Address: 6 Battery Road, #30-01, Singapore 049909 Tel: +65 6725 6341<br><span style='color: #EC782F; font-size:9.0pt;'>www.LimLogesMasters.com</span><span style='font-size:9.0pt;'> China . Hong Kong . India . Malaysia . Singapore</span>
      <br><span style='margin-left:100cm;text-align:right;'>                                                       </span>
    </p> 
  `

  return reportFooter
}

export default rptFooter;
