import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card, Form, Col, Row, Button } from "react-bootstrap";
import moment from "moment";
import Api from "../../../core/services/api.service";

import Icon from "@mdi/react";
import { mdiArrowLeft, mdiAccountGroup } from "@mdi/js";

import Searchable from "../../../components/DropDown/SearchableSelect";
import RichTextEditor from "../../../components/RichTextArea/RichTextEditor";
import BreadCrumb from "../../../components/Breadcrumb/BreadCrumb";
import SwalAlert from "../../../components/Swal/SwalAlert";

import { getClientDetails } from "../../../store/actions/clientSlice";
import { getListStaff } from "../../../store/actions/staffSlice";
import { storeTitle } from "../../../store/actions/headerTitleSlice";

function CreateProposal() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const customSwal = SwalAlert();
  // const formRef = useRef();

  const { getTitle } = useSelector((state) => state.headerTitle);
  const { usrProfile } = useSelector((state) => state.user);
  const { clientDetails } = useSelector((state) => state.client);
  const { dataStaff } = useSelector((state) => state.staff);

  const [errorForm, setErrorForm] = useState({});

  const [selectedStaff, setSelectedStaff] = useState([]);

  const selectedCrumb = (crumb) => {
    if(crumb === "Clients"){
      navigate("/clients/listActiveC");
    }else if(crumb === "Potential Clients"){
      navigate("/clients/listPotentialC");
    }else if(crumb === clientDetails.name){
      params.page === "active" ? 
        navigate("/clients/viewActiveC/" + params.id) :
        navigate("/clients/view-potential-client/" + params.id);
    }
  };

  const changeStaff = (e) => {
    setSelectedStaff(Array.isArray(e) ? e.map((x) => x.id) : []);
  };

  const btnCancel = () => {
    navigate(-1);
  };

  // Checking Error Form
  const formError = (object) => {
    const newErrors = {};

    // console.log("object", txtrequirement);

    if (!object.txtfilename || object.txtfilename === "")
      newErrors.txtfilename = "Fill in the blank!";

    if (!object.txtjobtitle || object.txtjobtitle === "")
      newErrors.txtjobtitle = "Fill in the blank!";

    // if (!object.txtoverview || object.txtoverview === "")
    //   newErrors.txtoverview = "Fill in the blank!";

    // if (
    //   !object.txtrequirement ||
    //   object.txtrequirement === "" ||
    //   object.txtrequirement === "<p><br></p>"
    // )
    //   newErrors.txtrequirement = "Fill in the blank!";

    // if (!object.ddlcontact || object.ddlcontact === "")
    //   newErrors.ddlcontact = "Fill in the blank!";

    // if (!object.txtregional || object.txtregional === "")
    //   newErrors.txtregional = "Fill in the blank!";

    // if (!object.txtdomestic || object.txtdomestic === "")
    //   newErrors.txtdomestic = "Fill in the blank!";

    // if (!object.txtcompensation || object.txtcompensation === "")
    //   newErrors.txtcompensation = "Fill in the blank!";

    // if (!object.txtpayterms || object.txtpayterms === "")
    //   newErrors.txtpayterms = "Fill in the blank!";

    // if (!object.txtindirect || object.txtindirect === "")
    //   newErrors.txtindirect = "Fill in the blank!";

    // if (!object.txtsubsequence || object.txtsubsequence === "")
    //   newErrors.txtsubsequence = "Fill in the blank!";

    // if (!object.txtinterest || object.txtinterest === "")
    //   newErrors.txtinterest = "Fill in the blank!";

    // if (!object.txtrestrictions || object.txtrestrictions === "")
    //   newErrors.txtrestrictions = "Fill in the blank!";

    // if (!object.txtperiod || object.txtperiod === "")
    //   newErrors.txtperiod = "Fill in the blank!";

    // if (
    //   !object.txtguarantee ||
    //   object.txtguarantee === "" ||
    //   object.txtguarantee === "<p><br></p>"
    // )
    //   newErrors.txtguarantee = "Fill in the blank!";

    return newErrors;
  };

  // Button Event
  // Save Proposal Button
  const btnSubmit = (e) => {

    const buttonType = window.event.submitter.name;

    e.preventDefault();
    const data = new FormData(e.target);

    const dataProposal = Object.fromEntries(data.entries());

    const newErrors = formError(dataProposal);

    // console.log("selectedStaff", buttonType)

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);

      for (var name in newErrors) {
        const input = document.querySelector(`input[name=${name}]`);
        const inp_txtarea = document.querySelector(`textarea[name=${name}]`);

        if (input !== null) {
          input.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
          });
        } else {
          inp_txtarea.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
          });
        }

        // console.log("input", input, inp_txtarea);
      }
    } else {
      customSwal
        .fire({
          title: buttonType === "draft" ? "Do you want to save " + buttonType +" proposal?" : "Do you want to submit proposal for approval?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Api.post("client/" + params.id + "/proposal/create", {
              client_id: Number(params.id),
              file_name: dataProposal.txtfilename,
              job_title: dataProposal.txtjobtitle,
              company_overview: dataProposal.txtoverview,
              requirement: dataProposal.txtrequirement,
              main_contact_llm: selectedStaff,
              domestic_fee: Number(dataProposal.txtdomestic),
              regional_fee: Number(dataProposal.txtregional),
              expected_compensation: dataProposal.txtcompensation,
              payment_term: dataProposal.txtpayterms,
              indirect_expenses: Number(dataProposal.txtindirect),
              compound_interest: Number(dataProposal.txtinterest),
              subseq_cand_fee: Number(dataProposal.txtsubsequence),
              off_limit_restriction: dataProposal.txtrestrictions,
              guaranty_period: dataProposal.txtperiod,
              guaranty_condition: dataProposal.txtguarantee,
              status: buttonType === "draft" ? "draft" : "proposal",
              created_by: Number(usrProfile.id),
            })
              .then((response) => {
                // let data = response.data;

                if (params.page === "active") {
                  navigate("/clients/viewActiveC/" + params.id);
                } else {
                  navigate("/clients/view-potential-client/" + params.id);
                }

                customSwal.fire({
                  title: "Proposal Saved Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("client", error);
              });
          }
        });
    }
  };
  //////////////////

  useEffect(() => {
    dispatch(getClientDetails(params.id));
    dispatch(getListStaff());

    if (params.page === "active") {
      dispatch(storeTitle({ title: "Clients", icon: mdiAccountGroup }));
    } else {
      dispatch(
        storeTitle({ title: "Potential Clients", icon: mdiAccountGroup })
      );
    }
  }, [dispatch, params.id, params.page]);

  return (
    <div className="main-div create-props">
      <div className="top-title-div both-side">
        <BreadCrumb
          crumbs={[getTitle.title, clientDetails.name, "Add Proposal"]}
          selected={selectedCrumb}
        ></BreadCrumb>
        <Button variant="secondary" onClick={() => navigate(-1)}>
          <Icon path={mdiArrowLeft} />
          Back
        </Button>
      </div>

      {/* Form Create Proposal */}
      <Card>
        <Card.Body>
          <Form id="proposal-form" 
          onSubmit={btnSubmit}
         
          >
            {/* 1 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                File Name <label className="validation-star">*</label>
              </Form.Label>
              <Col>
                <Form.Control
                  name="txtfilename"
                  type="text"
                  isInvalid={!!errorForm.txtfilename}
                  // ref={formRef}
                />
                <Form.Control.Feedback type="invalid">
                  {errorForm.txtfilename}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            {/* 2 */}
            <Row className="create-props">
              <Col xs={6}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={4}>
                    Creation Date
                  </Form.Label>
                  <Col>
                    <Form.Control
                      placeholder={moment().format("dddd, DD MMMM YYYY")}
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={4}>
                    Created By
                  </Form.Label>
                  <Col>
                    <Form.Control
                      placeholder={usrProfile.first_name}
                      style={{ textTransform: "capitalize" }}
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            {/* 3 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Job Title <label className="validation-star">*</label>
              </Form.Label>
              <Col>
                <Form.Control
                  name="txtjobtitle"
                  type="text"
                  isInvalid={!!errorForm.txtjobtitle}
                  // ref={formRef}
                />
                <Form.Control.Feedback type="invalid">
                  {errorForm.txtjobtitle}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            {/* 4 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Company Overview 
                {/* <label className="validation-star">*</label> */}
              </Form.Label>
              <Col>
                <Form.Control
                  name="txtoverview"
                  as="textarea"
                  rows={3}
                  // isInvalid={!!errorForm.txtoverview}
                  // ref={formRef}
                />
                {/* <Form.Control.Feedback type="invalid">
                  {errorForm.txtoverview}
                </Form.Control.Feedback> */}
              </Col>
            </Form.Group>

            {/* 5 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Requirement 
                {/* <label className="validation-star">*</label> */}
              </Form.Label>
              <Col>
                <RichTextEditor
                  name="txtrequirement"
                  // isInvalid={!!errorForm.txtrequirement}
                  // errorText={errorForm.txtrequirement}
                  // ref={formRef}
                />
              </Col>
            </Form.Group>

            {/* 6 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Main Contact Details{" "}
                {/* <label className="validation-star">*</label> */}
              </Form.Label>
              <Col>
                <Searchable
                  name="ddlcontact"
                  isMulti={true}
                  options={dataStaff}
                  onChange={changeStaff}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) =>
                    option.first_name + " " + option.last_name
                  }
                  // isInvalid={!!errorForm.ddlcontact}
                  // errorText={errorForm.ddlcontact}
                  // ref={formRef}
                ></Searchable>
              </Col>
            </Form.Group>

            {/* 7 */}
            <Row className="create-props">
              <Col xs={6}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={4}>
                    Professional Domestic Fees(%){" "}
                    {/* <label className="validation-star">*</label> */}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      name="txtdomestic"
                      type="number"
                      defaultValue="28"
                      max={100}
                      // isInvalid={!!errorForm.txtdomestic}
                      // ref={formRef}
                    />
                    {/* <Form.Control.Feedback type="invalid">
                      {errorForm.txtdomestic}
                    </Form.Control.Feedback> */}
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={4}>
                    Professional Regional Fees(%){" "}
                    {/* <label className="validation-star">*</label> */}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      name="txtregional"
                      type="number"
                      defaultValue="30"
                      max={100}
                      // isInvalid={!!errorForm.txtregional}
                      // ref={formRef}
                    />
                    {/* <Form.Control.Feedback type="invalid">
                      {errorForm.txtregional}
                    </Form.Control.Feedback> */}
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            {/* 8 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Expected Compensation Range{" "}
                {/* <label className="validation-star">*</label> */}
              </Form.Label>
              <Col>
                <RichTextEditor
                  name="txtcompensation"
                  // isInvalid={!!errorForm.txtcompensation}
                  // errorText={errorForm.txtcompensation}
                  // ref={formRef}
                />
                {/* <Form.Control
                  name="txtcompensation"
                  as="textarea"
                  rows={3}
                  isInvalid={!!errorForm.txtcompensation}
                />
                <Form.Control.Feedback type="invalid">
                  {errorForm.txtcompensation}
                </Form.Control.Feedback> */}
              </Col>
            </Form.Group>

            {/* 9 */}
            <Row className="create-props">
              <Col xs={6}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={4}>
                    Payment Terms 
                    {/* <label className="validation-star">*</label> */}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      name="txtpayterms"
                      type="text"
                      // isInvalid={!!errorForm.txtpayterms}
                      // ref={formRef}
                    />
                    {/* <Form.Control.Feedback type="invalid">
                      {errorForm.txtpayterms}
                    </Form.Control.Feedback> */}
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={4}>
                    Indirect Expenses (%){" "}
                    {/* <label className="validation-star">*</label> */}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      name="txtindirect"
                      type="number"
                      defaultValue="4"
                      // isInvalid={!!errorForm.txtindirect}
                      // ref={formRef}
                    />
                    {/* <Form.Control.Feedback type="invalid">
                      {errorForm.txtindirect}
                    </Form.Control.Feedback> */}
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            {/* 10 */}
            <Row className="create-props">
              <Col xs={6}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={4}>
                    Compound Interest (%){" "}
                    {/* <label className="validation-star">*</label> */}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      name="txtinterest"
                      type="number"
                      defaultValue="1.5"
                      // isInvalid={!!errorForm.txtinterest}
                      // ref={formRef}
                    />
                    {/* <Form.Control.Feedback type="invalid">
                      {errorForm.txtinterest}
                    </Form.Control.Feedback> */}
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={4}>
                    Subsequence Candidate Fees (%){" "}
                    {/* <label className="validation-star">*</label> */}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      name="txtsubsequence"
                      type="number"
                      defaultValue="25"
                      // isInvalid={!!errorForm.txtsubsequence}
                      // ref={formRef}
                    />
                    {/* <Form.Control.Feedback type="invalid">
                      {errorForm.txtsubsequence}
                    </Form.Control.Feedback> */}
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            {/* 11 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Off-limits Restrictions{" "}
                {/* <label className="validation-star">*</label> */}
              </Form.Label>
              <Col>
                <RichTextEditor
                  name="txtrestrictions"
                  // isInvalid={!!errorForm.txtrestrictions}
                  // errorText={errorForm.txtrestrictions}
                  // ref={formRef}
                />
                {/* <Form.Control
                  name="txtrestrictions"
                  as="textarea"
                  rows={3}
                  isInvalid={!!errorForm.txtrestrictions}
                />
                <Form.Control.Feedback type="invalid">
                  {errorForm.txtrestrictions}
                </Form.Control.Feedback> */}
              </Col>
            </Form.Group>

            {/* 12 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Guarantee Period 
                {/* <label className="validation-star">*</label> */}
              </Form.Label>
              <Col>
                <Form.Control
                  name="txtperiod"
                  type="text"
                  defaultValue="9 months"
                  // isInvalid={!!errorForm.txtperiod}
                  // ref={formRef}
                />
                {/* <Form.Control.Feedback type="invalid">
                  {errorForm.txtperiod}
                </Form.Control.Feedback> */}
              </Col>
            </Form.Group>

            {/* 13 */}
            <Form.Group as={Row} className="mb-3" controlId="formFileName">
              <Form.Label column lg={2}>
                Guarantee Conditions{" "}
                {/* <label className="validation-star">*</label> */}
              </Form.Label>
              <Col>
                <RichTextEditor
                  name="txtguarantee"
                  // isInvalid={!!errorForm.txtguarantee}
                  // errorText={errorForm.txtguarantee}
                  // ref={formRef}
                />
              </Col>
            </Form.Group>

            <div className="row-btn-center">
              <Button onClick={btnCancel} variant="secondary">
                Cancel
              </Button>
              <Button variant="info" type="submit" name="draft" form="proposal-form">
                Save Draft
              </Button>
              <Button variant="primary" type="submit" name="submit" form="proposal-form">
                Submit for Approval
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default CreateProposal;

// const [selectedValue, setSelectedValue] = useState([]);
// const [values, setValues] = useState({
//   filename: "",
//   jobtitle: "",
//   com_overview: "",
//   requirement: "",
//   contact_details: [],
//   domestic_fees: "",
//   regional_fees: "",
//   compensation: "",
//   pay_terms: "",
//   ind_exp: "",
//   interest: "",
//   candidate_fees: "",
//   restrictions: "",
//   guar_period: "",
//   guar_conditions: "",
// });
