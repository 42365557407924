import React, { useState, useEffect, useCallback } from "react";
import ReactTooltip from "react-tooltip";
// import Popup from "../Popup/ModalPopup";
// import Swal from "sweetalert2";
// import Api from "../../core/services/api.service";
// import { Form, Row, Button } from "react-bootstrap";

function ButtonNumberClient({ idClient, idAssign, items, button }) {
  // const [showInvoice, setShowInvoice] = useState(false);
  // const [errorFormInv, setErrorFormInv] = useState({});
  // const [modeModal, setModeModal] = useState({
  //   title: null,
  //   button: null,
  //   id: 0,
  //   refno: null,
  //   status: null,
  // });

  const totalBox = 6;

  const numberBox = totalBox - items.length;

  const [listInvoices, setListInvoices] = useState([]);

  const getListInvoices = useCallback(() => {
    setListInvoices(items);
  }, [items]);

  useEffect(() => {
    getListInvoices();
  }, [getListInvoices]);

  return (
    <>
      <div className="btn-number-container">
        {listInvoices.map((i, index) => (
          <div className="btn-number-each-div" key={index}>
            <div className="btn-number-each">
              <div>
                {(() => {
                  if (i.status === "paid") {
                    return (
                      <div
                        data-tip={
                          "Reference: " +
                          i.reference +
                          "<br> Status: " +
                          i.status
                        }
                      >
                        <button
                          className="btn--inv--paid--solid"
                        >
                          {index + 1}
                        </button>
                        <ReactTooltip
                          place="bottom"
                          type="dark"
                          effect="solid"
                          html={true}
                          backgroundColor="#E5E5E5"
                          textColor="#212529"
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div
                        data-tip={
                          "Reference: " +
                          i.reference +
                          "<br> Status: " +
                          i.status
                        }
                      >
                        <button
                          className="btn--inv--unpaid--solid"
                        >
                          {index + 1}
                        </button>
                        <ReactTooltip
                          place="bottom"
                          type="dark"
                          effect="solid"
                          html={true}
                          backgroundColor="#E5E5E5"
                          textColor="#212529"
                        />
                      </div>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        ))}
        
        {new Array(numberBox).fill("", 0, numberBox).map(
          (p, index3) => (
            <div className="btn-number-each-div" key={index3}>
              <div className="btn-number-each-text">
                {/* <div data-tip="Status"> */}
                {/* <button>{index3}</button> */}
                {/* <ReactTooltip place="bottom" type="dark" effect="solid" html={true} /> */}
                {/* </div> */}
              </div>
            </div>
          )
          // <div>{index}</div>
        )}
      </div>

      
    </>
  );
}

export default ButtonNumberClient;
