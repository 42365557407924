import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Card, Button, Form, Row } from "react-bootstrap";
import Api from "../../../core/services/api.service";
import Apiform from "../../../core/services/apiform.service";

import SwalAlert from "../../Swal/SwalAlert";
import FileSaver from "file-saver";
import HTMLtoDOCX from 'html-to-docx';
import moment from "moment";

import Icon from "@mdi/react";
import { mdiDownload, mdiUpload } from "@mdi/js";

import { CustomTable } from "../../Table/Table";
// import { rptProposal } from "../../Report/Template/rptProposal";
import rptProposal from "../../Report/Template_Report/rptProposal";
import rptHeader from "../../Report/Template_Report/rptHeader";
import rptFooter from "../../Report/Template_Report/rptFooter";
import Modal from "../../Popup/ModalPopup";

import {
  getClientDetails,
} from "../../../store/actions/clientSlice";

export const TabProposalClient = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { user } = useSelector((state) => state.login);
  const { clientDetails } = useSelector((state) => state.client);

  const [proposalList, setProposalList] = useState([]);

  const [errorForm, setErrorForm] = useState({});
  const [showUpload, setShowUpload] = useState(false);
  const [selRowProposal, setSelRowProposal] = useState({});

  const customSwal = SwalAlert();

  const btnDownload = (data) => async () => {

    // console.log("data", data)
    const dataRpt = {
      date_rpt: moment(data.created_at).format("DD MMMM YYYY"),
      company_overview: data.company_overview,
      requirement: data.requirement,
      job_title: data.job_title,
      client_proposal_has_user: data.client_proposal_has_user,
      domestic_fee: data.domestic_fee,
      regional_fee: data.regional_fee,
      expected_compensation: data.expected_compensation,
      payment_term: data.payment_term,
      indirect_expenses: data.indirect_expenses,
      compound_interest: data.compound_interest,
      subseq_cand_fee: data.subseq_cand_fee,
      off_limit_restriction: data.off_limit_restriction,
      guaranty_period: data.guaranty_period,
      guaranty_condition: data.guaranty_condition,
      company_name: clientDetails.name,
      sign_name:
        data.client_proposal_has_user[0].user.first_name +
        " " +
        data.client_proposal_has_user[0].user.last_name,
    };

    const htmlString = rptProposal(dataRpt);
    const headerString = rptHeader();
    const footerHTMLString = rptFooter();

    // uncomment the following
    const fileBuffer = await HTMLtoDOCX(
      htmlString,
      headerString,
      {
        table: { row: { cantSplit: true } },
        header: true,
        footer: true,
        pageNumber: true,
        margins: {
          top: 500,
          right: 1500,
          bottom: 500,
          left: 1500,
          header: 720,
          footer: 720,
          gutter: 0,
        },
        font: "Calibri (Body)",
      },
      footerHTMLString
    );

    // console.log("fileBuffer", fileBuffer)
    FileSaver.saveAs(fileBuffer, data.file_name + ".docx");
  };

  const btnUploadProposal = (data) => (e) => {
    // console.log("data", data)
    setErrorForm({});
    setSelRowProposal(data);
    setShowUpload(true);
  };

  const btnCloseUpload = () => {
    setShowUpload(false);
  };

  const btnSaveUpload = (idPro) => (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtUpload = Object.fromEntries(data.entries());

    // console.log("dtUpload", idPro);
    const newErrors = formErrorUpload(dtUpload);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      customSwal
        .fire({
          title: "Do you want to upload signed proposal ?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();

            formData.append("file", dtUpload.txtfile);

            Apiform.post(
              "client/" +
                params.id +
                "/proposal/" +
                idPro +
                "/uploadSignedProposal",
              formData
            )
              .then((response) => {
                getListProposal();

                setShowUpload(false);
                customSwal.fire({
                  title: "File Uploaded Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("user/store", error);
              });
          }
        });
    }
  };

  const formErrorUpload = (object) => {
    const newErrors = {};

    // console.log("object", object);

    if (!object.txtfile.name || object.txtfile.name === "")
      newErrors.txtfile = "Fill in the blank!";

    return newErrors;
  };

  const headerAttachment = [
    {
      Header: "No",
      maxWidth: 50,
      minWidth: 10,
      width: 40,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Document Name",
      accessor: "file_name",
      maxWidth: 400,
      minWidth: 100,
      width: 300,
    },
    {
      Header: "Upload Attachment",
      accessor: "upload_att",
      maxWidth: 200,
      minWidth: 160,
      width: 160,
      Cell: ({ row }) => {
        const data = row.original;

        function btnDownloadSigned() {
          FileSaver.saveAs(data.signed_proposal, "download.pdf");
        }

        return (
          <>
            {data.signed_proposal ? (
              <div className="has-file-upload">
                <Button
                  variant="link"
                  className="reminder-btn"
                  onClick={btnDownloadSigned}
                >
                  <Icon path={mdiDownload} size={0.8} color="#467FD0" />
                  Download
                </Button>
              </div>
            ) : (
              <>
                <Button
                  variant="link"
                  className="reminder-btn"
                  onClick={btnUploadProposal(data)}
                >
                  <Icon path={mdiUpload} size={0.8} color="#467FD0" />
                  Upload
                </Button>
              </>
            )}
          </>
        );
      },
    },
    {
      Header: "Upload Date",
      Cell: ({ row }) => {
        const data = row.original;

        const date = new Date(data.signed_proposal_at);
        const longMonth = date.toLocaleString("en-us", { month: "long" });

        return (
          <div>
            {data.signed_proposal_at
              ? date.getDate() + " " + longMonth + " " + date.getFullYear()
              : "-"}
          </div>
        );
      },
    },
    {
      Header: "Received Attachment",
      maxWidth: 200,
      minWidth: 160,
      width: 160,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <>
            <Button
              variant="link"
              className="reminder-btn"
              onClick={btnDownload(data)}
            >
              <Icon path={mdiDownload} size={0.8} color="#467FD0" />
              Download
            </Button>
          </>
        );
      },
    },
    {
      Header: "Received Date",
      Cell: ({ row }) => {
        const data = row.original;

        const date = new Date(data.created_at);
        const longMonth = date.toLocaleString("en-us", { month: "long" });

        return (
          <div>
            {date.getDate()} {longMonth} {date.getFullYear()}
          </div>
        );
      },
    },
  ];

  const getListProposal = useCallback(() => {
    Api.get("client/" + user.clientid + "/proposal/list")
      .then((response) => {
        let data = response.data;

        // console.log("data getListProposal", data);

        if (data !== "no data") {
          let listProposal = data.filter((grp) =>
            grp.status.includes("completed")
          );

          let sortListProposal = listProposal.sort(function (a, b) {
            return new Date(a.created_at) - new Date(b.created_at);
          });

          setProposalList(sortListProposal);
        }
      })
      .catch((error) => {
        console.log("error getListProposal", error);
      });
  }, [user.clientid]);

  useEffect(() => {
    getListProposal();
    dispatch(getClientDetails(user.clientid));
  }, [getListProposal, dispatch]);

  return (
    <>
      <Card>
        <Card.Body>
          <CustomTable
            headers={headerAttachment}
            items={proposalList}
            paginate={proposalList.length}
            maxRows={15}
          />
        </Card.Body>
      </Card>

      {/* Modal Upload Proposal */}
      <Modal
        show={showUpload}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseUpload}
        title="Upload Proposal"
        content={
          <>
            <Form
              id="upload-pro-form"
              onSubmit={btnSaveUpload(selRowProposal.id)}
            >
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label className="col-3">File Name</Form.Label>
                  <div className="col-9" style={{ display: "inline-flex" }}>
                    {selRowProposal.file_name}
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label className="col-3">Signed Proposal</Form.Label>
                  <div className="col-9" style={{ display: "inline-flex" }}>
                    <Form.Control
                      name="txtfile"
                      type="file"
                      isInvalid={!!errorForm.txtfile}
                    />
                  </div>
                </Form.Group>
              </Row>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseUpload}>
              Cancel
            </Button>
            <Button type="submit" form="upload-pro-form" variant="primary">
              Upload
            </Button>
          </>
        }
      ></Modal>
    </>
  );
};
