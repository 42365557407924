import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import {
  loginPending,
  loginSuccess,
  loginFail,
} from "./store/actions/loginSlice";

import { BrowserRouter as Router } from "react-router-dom";

import RouteAuth from "./routes/Router/route_auth";
import RouteAdmin from "./routes/Router/route_admin";

import Loader from "./components/Loading/loader";

import RouteCandidate from "./routes/Router/route_candidate";
import RouteClient from "./routes/Router/route_client";

function App() {
  const dispatch = useDispatch();
  const { isLoading, isAuth, user } = useSelector((state) => state.login);

  useEffect(() => {
    try {
      const tokenid = Cookies.get("id_token");

      if (tokenid === null || tokenid === undefined) {
        // setTimeout(() => setLoading, 3500);
        // setResetUrl("/");
      } else {
        const decoded = jwt_decode(tokenid);

        // console.log("decoded app", decoded)
        dispatch(loginPending());

        setTimeout(
          () =>
            dispatch(
              loginSuccess({
                sub: decoded.sub,
                clientid: decoded.user_has_roles.id,
                email: decoded.email,
                type: decoded.type,
                iat: decoded.iat,
                exp: decoded.exp,
              })
            ),
          3500
        );
      }
    } catch (error) {
      dispatch(loginFail("Invalid Email or Password"));
    }
  }, [dispatch]);

  return (
    <Router>
      {isAuth === false ? (
        isLoading === false ? (
          <RouteAuth />
        ) : (
          <Loader />
        )
      ) : null}
      {isAuth ? (
        isLoading === false ? (
          user.type === "candidate" ? (
            <RouteCandidate />
          ) : user.type === "client" ? (
            <RouteClient />
          ) : (
            <RouteAdmin></RouteAdmin>
          )
        ) : (
          <Loader />
        )
      ) : null}
    </Router>
  );
}

export default App;
