import axios from 'axios';
import Cookies from 'js-cookie';

const rootUrl = process.env.REACT_APP_BASE_URL_API;
const loginUrl = rootUrl + "auth/staff/login";
const loginClientUrl = rootUrl + "auth/client/login";
const loginCandidateUrl = rootUrl + "auth/candidate/login";

export const userLogin = frmData=>{
    return new Promise(async (resolve, reject)=> {

        try {
            const res = await axios.post(loginUrl, frmData);

            resolve(res.data);

            if(res.data.access_token !== null){
                // sessionStorage.setItem('accessJWT', res.data.access_token);
                // localStorage.setItem(
                //     'id_token',
                //     res.data.access_token
                //     //  JSON.stringify({refreshJWT: res.data.access_token})
                // );
                Cookies.set('id_token', res.data.access_token, { expires: 7 });
            }

        } catch (error) {
            console.log("error login",error.message)
            reject(error);
        }

    })
}

export const userLoginClient = frmData=>{
    return new Promise(async (resolve, reject)=> {

        try {
            const res = await axios.post(loginClientUrl, frmData);

            resolve(res.data);

            if(res.data.access_token !== null){
                // sessionStorage.setItem('accessJWT', res.data.access_token);
                // localStorage.setItem(
                //     'id_token',
                //     res.data.access_token
                //     //  JSON.stringify({refreshJWT: res.data.access_token})
                // );
                Cookies.set('id_token', res.data.access_token, { expires: 7 });
            }

        } catch (error) {
            // console.log("client error",error)
            reject(error);
        }

    })
}

export const userLoginCandidate = frmData=>{
    return new Promise(async (resolve, reject)=> {

        try {
            const res = await axios.post(loginCandidateUrl, frmData);

            resolve(res.data);

            if(res.data.access_token !== null){
                // sessionStorage.setItem('accessJWT', res.data.access_token);
                // localStorage.setItem(
                //     'id_token',
                //     res.data.access_token
                //     //  JSON.stringify({refreshJWT: res.data.access_token})
                // );
                Cookies.set('id_token', res.data.access_token, { expires: 7 });
            }

        } catch (error) {
            console.log(error.message)
            reject(error);
        }

    })
}



// export const fetchNewAccessJWT = () => {
//     return new Promise(async (resolve, reject) => {
//       try {
//         const { refreshJWT } = JSON.parse(localStorage.getItem("crmSite"));
        
//         console.log("refreshJWT", refreshJWT)
//         if (!refreshJWT) {
//           reject("Token not found!");
//         }
  
//         const res = await axios.get(newAccessJWT, {
//           headers: {
//             Authorization: refreshJWT,
//           },
//         });
  
//         if (res.data.status === "success") {
//           sessionStorage.setItem("accessJWT", res.data.accessJWT);
//         }
  
//         resolve(true);
//       } catch (error) {
//         if (error.message === "Request failed with status code 403") {
//           localStorage.removeItem("id_token");
//         }
  
//         reject(false);
//       }
//     });
//   };