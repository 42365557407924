import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  Header,
  Footer,
  AlignmentType,
  Table,
  TableRow,
  TableCell,
  WidthType,
  convertInchesToTwip,
} from "docx";
import { saveAs } from "file-saver";

export const MeetingForm = async (data) => {
  const fontType = "Calibri";
  const fontColor = "#EC782F";
  const fontSizeTitle = 28;
  const fontSizeTitle2 = 18;
  const fontSizeTitle3 = 16;
  const fontSizeContent = 22;
  const tableWidth1 = 3000;
  const tableWidth2 = 8000;

  function decodeHTMLEntities(text) {
    var textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.innerText;
  }

  const doc = new Document({
    sections: [
      {
        properties: {},
        headers: {
          default: new Header({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Lim-Loges",
                    bold: true,
                    size: fontSizeTitle,
                    font: fontType,
                  }),
                  new TextRun({
                    text: " & ",
                    bold: true,
                    size: fontSizeTitle,
                    font: fontType,
                    color: fontColor,
                  }),
                  new TextRun({
                    text: "Masters",
                    bold: true,
                    size: fontSizeTitle,
                    font: fontType,
                  }),
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "transforming companies, transforming career",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                    color: fontColor,
                  }),
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Disruption Management",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: " . ",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                    color: fontColor,
                  }),
                  new TextRun({
                    text: "Executive Search",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: " . ",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                    color: fontColor,
                  }),
                  new TextRun({
                    text: "Transition Management",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                ],
              }),
              // new ImageRun({
              //   data: fs.readFileSync("./report_logo.jpg"),
              //   transformation: {
              //     width: 100,
              //     height: 100,
              //   },
              // }),
              new Paragraph({
                children: [],
              }),
            ],
          }),
        },
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "Lim-Loges & Masters Pte Ltd (Company Reg. No. 201614475K) Address: 6 Battery Road, #30-01, Singapore 049909 Tel: +65 6725 6341",
                    bold: true,
                    size: fontSizeTitle3,
                    font: fontType,
                  }),
                ],
              }),
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "www.LimLogesMasters.com",
                    bold: true,
                    underline: true,
                    size: fontSizeTitle2,
                    font: fontType,
                    color: fontColor,
                  }),
                  new TextRun({
                    text: " China",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: " . ",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: "Hong Kong",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: " . ",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: "India",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: " . ",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: "Malaysia",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: " . ",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: "Singapore",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
        },
        children: [
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                text: "Meeting Status Update Form",
                underline: true,
                bold: true,
                size: fontSizeContent,
                font: fontType,
              }),
            ],
            spacing: {
              before: 500,
              after: 300,
            },
          }),
          new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Date of Call/Meeting",
                            bold: true,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: tableWidth2,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: data.date_meeting,
                            bold: true,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Time",
                            bold: true,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: tableWidth2,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: data.time_meeting,
                            bold: true,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Location",
                            bold: true,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: tableWidth2,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: data.location,
                            bold: true,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Parties involved",
                            bold: true,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: tableWidth2,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "",
                            bold: true,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "",
                            bold: true,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: tableWidth2,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "",
                            bold: true,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "",
                            bold: true,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: tableWidth2,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "",
                            bold: true,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "",
                            bold: true,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: tableWidth2,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "",
                            bold: true,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "",
                            bold: true,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: tableWidth2,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "",
                            bold: true,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Duration",
                            bold: true,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: tableWidth2,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: data.duration,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "",
                            bold: true,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: tableWidth2,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Sandra Tai",
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Client's name/Title",
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Objective of meeting",
                            bold: true,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                        spacing: {
                          after: 500,
                        },
                      }),
                      new Paragraph({
                        children: [],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: tableWidth2,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: decodeHTMLEntities(data.objective_meeting),
                            size: fontSizeContent,
                            font: fontType,
                            subScript: true,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Discussion points and elaboration",
                            bold: true,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                        spacing: {
                          after: 500,
                        },
                      }),
                      new Paragraph({
                        children: [],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: tableWidth2,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "",
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Conclusion / next steps",
                            bold: true,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                        spacing: {
                          after: 500,
                        },
                      }),
                      new Paragraph({
                        children: [],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: tableWidth2,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "",
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Suggestion (if any: introduce further services to client)",
                            bold: true,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: tableWidth2,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "",
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
          new Paragraph({
            children: [],
          }),
          new Paragraph({
            children: [],
          }),
          new Paragraph({
            alignment: AlignmentType.JUSTIFIED,
            children: [
              new TextRun({
                text: "Updated by [who update this]",
                size: fontSizeContent,
                font: fontType,
              }),
            ],
          }),
          new Paragraph({
            alignment: AlignmentType.JUSTIFIED,
            children: [
              new TextRun({
                text: "Date: ",
                size: fontSizeContent,
                font: fontType,
              }),
            ],
          }),
        ],
      },
    ],
  });

  Packer.toBlob(doc).then((blob) => {
    console.log(blob);
    saveAs(blob, "meeting.docx");
    console.log("Document created successfully");
  });

  return doc;
};
