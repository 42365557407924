import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button, Form, Col, Row } from "react-bootstrap";
// import BreadCrumb from "../../../components/Breadcrumb/BreadCrumb";
import Icon from "@mdi/react";
import { mdiArrowLeft } from "@mdi/js";
import moment from "moment";

function CreateForm() {
  const navigate = useNavigate();

  const [errorForm, setErrorForm] = useState({});

  // Checking Error Form
  const formError = (object) => {
    const newErrors = {};

    if (!object.formname || object.formname === "")
      newErrors.formname = "Fill in the blank!";

    if (!object.formtype || object.formtype === "")
      newErrors.formtype = "Choose form type!";

    if (!object.googleform || object.googleform === "")
      newErrors.googleform = "Fill in the blank!";

    if (!object.responseURL || object.responseURL === "")
      newErrors.responseURL = "Fill in the blank!";

    return newErrors;
  };

  const btnSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);

    const dataProposal = Object.fromEntries(data.entries());

    const newErrors = formError(dataProposal);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    }
  };

  return (
    <div className="main-div create-props">
      <div className="top-title-div both-side">
        {/* <BreadCrumb
          crumbs={[getTitle.title, clientDetails.name, "Add Proposal"]}
          selected={selectedCrumb}
        ></BreadCrumb> */}
        <Button variant="secondary" onClick={() => navigate(-1)}>
          <Icon path={mdiArrowLeft} />
          Back
        </Button>
      </div>

      {/* Form Create Proposal */}
      <Card>
        <Card.Body>
          <Form id="form" onSubmit={btnSubmit}>
            {/* 1 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Form Type <label className="validation-star">*</label>
              </Form.Label>
              <Col>
                <Form.Select name="formtype" isInvalid={!!errorForm.formtype}>
                  <option value="1">Select Form</option>
                  <option value="2">Survey Form</option>
                  <option value="3">Feedback Form</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errorForm.formtype}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            {/* 2 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Form Name <label className="validation-star">*</label>
              </Form.Label>
              <Col>
                <Form.Control
                  name="formname"
                  type="text"
                  isInvalid={!!errorForm.formname}
                />
                <Form.Control.Feedback type="invalid">
                  {errorForm.formname}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            {/* 3 */}
            <Row className="create-props">
              <Col xs={6}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={4}>
                    Creation Date
                  </Form.Label>
                  <Col>
                    <Form.Control
                      placeholder={moment().format("dddd, DD MMMM YYYY")}
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={4}>
                    Created By
                  </Form.Label>
                  <Col>
                    <Form.Control
                      // placeholder={usrProfile.first_name}
                      style={{ textTransform: "capitalize" }}
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            {/* 4 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Google Form URL <label className="validation-star">*</label>
              </Form.Label>
              <Col>
                <Form.Control
                  name="googleform"
                  type="text"
                  isInvalid={!!errorForm.googleform}
                />
                <Form.Control.Feedback type="invalid">
                  {errorForm.googleform}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            {/* 5 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Response URL <label className="validation-star">*</label>
              </Form.Label>
              <Col>
                <Form.Control
                  name="responseURL"
                  type="text"
                  isInvalid={!!errorForm.responseURL}
                />
                <Form.Control.Feedback type="invalid">
                  {errorForm.responseURL}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <div className="row-btn-center">
              <Button
                //   onClick={btnCancel}
                variant="secondary"
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit" form="form">
                Save Form
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default CreateForm;
