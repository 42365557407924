import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import Api from "../../../core/services/api.service";

import TextboxIcon from "../../../components/TextField/TextFieldIcon";
import CustomTable from "../../../components/Table/Table";
import CardInfo from "../../../components/Card/CardInfo";

import Icon from "@mdi/react";
import { mdiMagnify, mdiFilter, mdiAccountSearch } from "@mdi/js";
import { Button, Form, Spinner } from "react-bootstrap";
import moment from "moment";

// import { storeIndAssignTab } from "../../../store/actions/tabAssignSlice";
// import { storeAsgnMenu } from "../../../store/actions/breadAsgnSlice";
import { storeTitle } from "../../../store/actions/headerTitleSlice";
import { storePage } from "../../../store/actions/paginationSlice";
import { getListDataAssignments, setAllListAssignments, setStoreAssignFilter } from "../../../store/actions/assignmentSlice";

import { statusAssignListFilter } from "../../Admin/Clients/dataList";

function ListAssignments() {
  // const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { assignListData, assignAllListData, assignFilter } = useSelector((state) => state.assignment);

  const [clicked, setClicked] = useState(false);

  const btnCollapse = (index) => {
    if (clicked === index) {
      //if clicked is already active, then close
      return setClicked(null);
    }
    setClicked(index);
  };

  const handleSearch = (event) => {
    let searchVal = event.target.value.toLowerCase();

    // setValueSearch(value);
    dispatch(setStoreAssignFilter({ txtsearchassign: searchVal }));
    dispatch(storePage(0));

    let newList = [];

           if (searchVal !== "" && assignFilter.txtassignname === "" && assignFilter.ddlStatus === "" && assignFilter.ddltypeassign === "") {
      newList = assignListData.filter((assign) => {
        return assign.clients ? assign.clients.name.toLowerCase().includes(searchVal.toLowerCase()) : "";
      });
    } else if (searchVal !== "" && assignFilter.txtassignname !== "" && assignFilter.ddlStatus === "" && assignFilter.ddltypeassign === "") {
      newList = assignListData.filter((assign) => {
        let assignName = "";

        if (assign.clients !== null) {
          assignName = assign.clients.name;
        } else {
          assignName = "";
        };

        return (
          assignName.toLowerCase().includes(searchVal.toLowerCase()) &&
          assign.title.toLowerCase().includes(assignFilter.txtassignname.toLowerCase())
        );
      });
    } else if (searchVal !== "" && assignFilter.txtassignname !== "" && assignFilter.ddlStatus !== "" && assignFilter.ddltypeassign === "") {
      newList = assignListData.filter((assign) => {

        let assignName = "";

        if (assign.clients !== null) {
          assignName = assign.clients.name;
        } else {
          assignName = "";
        };

        return (
          assignName.toLowerCase().includes(searchVal.toLowerCase()) &&
          assign.title.toLowerCase().includes(assignFilter.txtassignname.toLowerCase()) &&
          assign.status.toLowerCase().includes(assignFilter.ddlStatus.toLowerCase())
        );
      });
    } else if (searchVal !== "" && assignFilter.txtassignname !== "" && assignFilter.ddlStatus !== "" && assignFilter.ddltypeassign !== "") {
      newList = assignListData.filter((assign) => {

        let assignName = "";

        if (assign.clients !== null) {
          assignName = assign.clients.name;
        } else {
          assignName = "";
        };

        return (
          assignName.toLowerCase().includes(searchVal.toLowerCase()) &&
          assign.title.toLowerCase().includes(assignFilter.txtassignname.toLowerCase()) &&
          assign.status.toLowerCase().includes(assignFilter.ddlStatus.toLowerCase()) &&
          assign.type.toLowerCase().includes(assignFilter.ddltypeassign.toLowerCase())
        );
      });
    } 
    
      else if (searchVal !== "" && assignFilter.txtassignname !== "" && assignFilter.ddlStatus === "" && assignFilter.ddltypeassign !== "") {
      newList = assignListData.filter((assign) => {

        let assignName = "";

        if (assign.clients !== null) {
          assignName = assign.clients.name;
        } else {
          assignName = "";
        };

        return (
          assignName.toLowerCase().includes(searchVal.toLowerCase()) &&
          assign.title.toLowerCase().includes(assignFilter.txtassignname.toLowerCase()) &&
          assign.type.toLowerCase().includes(assignFilter.ddltypeassign.toLowerCase())
        );
      });
    } else if (searchVal !== "" && assignFilter.txtassignname === "" && assignFilter.ddlStatus !== "" && assignFilter.ddltypeassign === "") {
      newList = assignListData.filter((assign) => {

        let assignName = "";

        if (assign.clients !== null) {
          assignName = assign.clients.name;
        } else {
          assignName = "";
        };

        return (
          assignName.toLowerCase().includes(searchVal.toLowerCase()) &&
          assign.status.toLowerCase().includes(assignFilter.ddlStatus.toLowerCase())
        );
      });
    } else if (searchVal !== "" && assignFilter.txtassignname === "" && assignFilter.ddlStatus !== "" && assignFilter.ddltypeassign !== "") {
      newList = assignListData.filter((assign) => {

        let assignName = "";

        if (assign.clients !== null) {
          assignName = assign.clients.name;
        } else {
          assignName = "";
        };

        return (
          assignName.toLowerCase().includes(searchVal.toLowerCase()) &&
          assign.status.toLowerCase().includes(assignFilter.ddlStatus.toLowerCase()) &&
          assign.type.toLowerCase().includes(assignFilter.ddltypeassign.toLowerCase())
        );
      });
    } else if (searchVal !== "" && assignFilter.txtassignname === "" && assignFilter.ddlStatus === "" && assignFilter.ddltypeassign !== "") {
      newList = assignListData.filter((assign) => {

        let assignName = "";

        if (assign.clients !== null) {
          assignName = assign.clients.name;
        } else {
          assignName = "";
        };

        return (
          assignName.toLowerCase().includes(searchVal.toLowerCase()) &&
          assign.type.toLowerCase().includes(assignFilter.ddltypeassign.toLowerCase())
        );
      });
    } 
    
      else if (searchVal === "" && assignFilter.txtassignname !== "" && assignFilter.ddlStatus === "" && assignFilter.ddltypeassign === "") {
      newList = assignListData.filter((assign) => {
        return (
          assign.title.toLowerCase().includes(assignFilter.txtassignname.toLowerCase())
        );
      });
    } else if (searchVal === "" && assignFilter.txtassignname !== "" && assignFilter.ddlStatus !== "" && assignFilter.ddltypeassign === "") {
      newList = assignListData.filter((assign) => {
        return (
          assign.title.toLowerCase().includes(assignFilter.txtassignname.toLowerCase()) &&
          assign.status.toLowerCase().includes(assignFilter.ddlStatus.toLowerCase())
        );
      });
    } else if (searchVal === "" && assignFilter.txtassignname !== "" && assignFilter.ddlStatus !== "" && assignFilter.ddltypeassign !== "") {
      newList = assignListData.filter((assign) => {
        return (
          assign.title.toLowerCase().includes(assignFilter.txtassignname.toLowerCase()) &&
          assign.status.toLowerCase().includes(assignFilter.ddlStatus.toLowerCase()) &&
          assign.type.toLowerCase().includes(assignFilter.ddltypeassign.toLowerCase())
        );
      });
    } else if (searchVal === "" && assignFilter.txtassignname !== "" && assignFilter.ddlStatus === "" && assignFilter.ddltypeassign !== "") {
      newList = assignListData.filter((assign) => {
        return (
          assign.title.toLowerCase().includes(assignFilter.txtassignname.toLowerCase()) &&
          assign.type.toLowerCase().includes(assignFilter.ddltypeassign.toLowerCase())
        );
      });
    } else if (searchVal === "" && assignFilter.txtassignname === "" && assignFilter.ddlStatus !== "" && assignFilter.ddltypeassign === "") {
      newList = assignListData.filter((assign) => {
        return (
          assign.status.toLowerCase().includes(assignFilter.ddlStatus.toLowerCase())
        );
      });
    } else if (searchVal === "" && assignFilter.txtassignname === "" && assignFilter.ddlStatus !== "" && assignFilter.ddltypeassign !== "") {
      newList = assignListData.filter((assign) => {
        return (
          assign.status.toLowerCase().includes(assignFilter.ddlStatus.toLowerCase()) &&
          assign.type.toLowerCase().includes(assignFilter.ddltypeassign.toLowerCase())
        );
      });
    } else if (searchVal === "" && assignFilter.txtassignname === "" && assignFilter.ddlStatus === "" && assignFilter.ddltypeassign !== "") {
      newList = assignListData.filter((assign) => {
        return (
          assign.type.toLowerCase().includes(assignFilter.ddltypeassign.toLowerCase())
        );
      });
    } 
    
    else {
      newList = assignListData;

    }

    dispatch(setAllListAssignments(newList));
  };

  const btnFilter = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtFilter = Object.fromEntries(data.entries());

    // console.log("amin", dtFilter.ddltypeassign);
    dispatch(storePage(0));

    dispatch(setStoreAssignFilter({ txtassignname: dtFilter.txtassignname, ddltypeassign: dtFilter.ddltypeassign ,  ddlStatus: dtFilter.ddlStatus }));

    let newList = [];

           if (assignFilter.txtsearchassign !== "" && dtFilter.txtassignname === "" && dtFilter.ddlStatus === "" && dtFilter.ddltypeassign === "") {
      newList = assignListData.filter((assign) => {
        return assign.clients ? assign.clients.name.toLowerCase().includes(assignFilter.txtsearchassign.toLowerCase()) : "";
      });
    } else if (assignFilter.txtsearchassign !== "" && dtFilter.txtassignname !== "" && dtFilter.ddlStatus === "" && dtFilter.ddltypeassign === "") {
      newList = assignListData.filter((assign) => {

        let assignName = "";

        if (assign.clients !== null) {
          assignName = assign.clients.name;
        } else {
          assignName = "";
        };

        return (
          assignName.toLowerCase().includes(assignFilter.txtsearchassign.toLowerCase()) &&
          assign.title.toLowerCase().includes(dtFilter.txtassignname.toLowerCase())
        );
      });
    } else if (assignFilter.txtsearchassign !== "" && dtFilter.txtassignname !== "" && dtFilter.ddlStatus !== "" && dtFilter.ddltypeassign === "") {
      newList = assignListData.filter((assign) => {

        let assignName = "";

        if (assign.clients !== null) {
          assignName = assign.clients.name;
        } else {
          assignName = "";
        };

        return (
          assignName.toLowerCase().includes(assignFilter.txtsearchassign.toLowerCase()) &&
          assign.title.toLowerCase().includes(dtFilter.txtassignname.toLowerCase()) &&
          assign.status.toLowerCase().includes(dtFilter.ddlStatus.toLowerCase())
        );
      });
    } else if (assignFilter.txtsearchassign !== "" && dtFilter.txtassignname !== "" && dtFilter.ddlStatus !== "" && dtFilter.ddltypeassign !== "") {
      newList = assignListData.filter((assign) => {

        let assignName = "";

        if (assign.clients !== null) {
          assignName = assign.clients.name;
        } else {
          assignName = "";
        };

        return (
          assignName.toLowerCase().includes(assignFilter.txtsearchassign.toLowerCase()) &&
          assign.title.toLowerCase().includes(dtFilter.txtassignname.toLowerCase()) &&
          assign.status.toLowerCase().includes(dtFilter.ddlStatus.toLowerCase()) &&
          assign.type.toLowerCase().includes(dtFilter.ddltypeassign.toLowerCase())
        );
      });
    } 
    
      else if (assignFilter.txtsearchassign !== "" && dtFilter.txtassignname !== "" && dtFilter.ddlStatus === "" && dtFilter.ddltypeassign !== "") {
      newList = assignListData.filter((assign) => {

        let assignName = "";

        if (assign.clients !== null) {
          assignName = assign.clients.name;
        } else {
          assignName = "";
        };

        return (
          assignName.toLowerCase().includes(assignFilter.txtsearchassign.toLowerCase()) &&
          assign.title.toLowerCase().includes(dtFilter.txtassignname.toLowerCase()) &&
          assign.type.toLowerCase().includes(dtFilter.ddltypeassign.toLowerCase())
        );
      });
    } else if (assignFilter.txtsearchassign !== "" && dtFilter.txtassignname === "" && dtFilter.ddlStatus !== "" && dtFilter.ddltypeassign === "") {
      newList = assignListData.filter((assign) => {

        let assignName = "";

        if (assign.clients !== null) {
          assignName = assign.clients.name;
        } else {
          assignName = "";
        };

        return (
          assignName.toLowerCase().includes(assignFilter.txtsearchassign.toLowerCase()) &&
          assign.status.toLowerCase().includes(dtFilter.ddlStatus.toLowerCase()) 
        );
      });
    } else if (assignFilter.txtsearchassign !== "" && dtFilter.txtassignname === "" && dtFilter.ddlStatus !== "" && dtFilter.ddltypeassign !== "") {
      newList = assignListData.filter((assign) => {

        let assignName = "";

        if (assign.clients !== null) {
          assignName = assign.clients.name;
        } else {
          assignName = "";
        };

        return (
          assignName.toLowerCase().includes(assignFilter.txtsearchassign.toLowerCase()) &&
          assign.status.toLowerCase().includes(dtFilter.ddlStatus.toLowerCase()) &&
          assign.type.includes(dtFilter.ddltypeassign)
        );
      });
    } else if (assignFilter.txtsearchassign !== "" && dtFilter.txtassignname === "" && dtFilter.ddlStatus === "" && dtFilter.ddltypeassign !== "") {
      newList = assignListData.filter((assign) => {

        let assignName = "";

        if (assign.clients !== null) {
          assignName = assign.clients.name;
        } else {
          assignName = "";
        };

        return (
          assignName.toLowerCase().includes(assignFilter.txtsearchassign.toLowerCase()) &&
          assign.type.toLowerCase().includes(dtFilter.ddltypeassign.toLowerCase())
        );
      });
    }

      else if (assignFilter.txtsearchassign === "" && dtFilter.txtassignname !== "" && dtFilter.ddlStatus === "" && dtFilter.ddltypeassign === "") {

      newList = assignListData.filter((assign) => {
        return (
          assign.title.toLowerCase().includes(dtFilter.txtassignname.toLowerCase())
        );
      });
    } else if (assignFilter.txtsearchassign === "" && dtFilter.txtassignname !== "" && dtFilter.ddlStatus !== "" && dtFilter.ddltypeassign === "") {

      newList = assignListData.filter((assign) => {
        return (
          assign.title.toLowerCase().includes(dtFilter.txtassignname.toLowerCase()) &&
          assign.status.toLowerCase().includes(dtFilter.ddlStatus.toLowerCase())
        );
      });
    } else if (assignFilter.txtsearchassign === "" && dtFilter.txtassignname !== "" && dtFilter.ddlStatus !== "" && dtFilter.ddltypeassign !== "") {

      newList = assignListData.filter((assign) => {
        return (
          assign.title.toLowerCase().includes(dtFilter.txtassignname.toLowerCase()) &&
          assign.status.toLowerCase().includes(dtFilter.ddlStatus.toLowerCase()) &&
          assign.type.toLowerCase().includes(dtFilter.ddltypeassign.toLowerCase())
        );
      });
    } else if (assignFilter.txtsearchassign === "" && dtFilter.txtassignname !== "" && dtFilter.ddlStatus === "" && dtFilter.ddltypeassign !== "") {

      newList = assignListData.filter((assign) => {
        return (
          assign.title.toLowerCase().includes(dtFilter.txtassignname.toLowerCase()) &&
          assign.type.toLowerCase().includes(dtFilter.ddltypeassign.toLowerCase())
        );
      });
    } else if (assignFilter.txtsearchassign === "" && dtFilter.txtassignname === "" && dtFilter.ddlStatus !== "" && dtFilter.ddltypeassign === "") {

      newList = assignListData.filter((assign) => {
        return (
          assign.status.toLowerCase().includes(dtFilter.ddlStatus.toLowerCase())
        );
      });
    } else if (assignFilter.txtsearchassign === "" && dtFilter.txtassignname === "" && dtFilter.ddlStatus !== "" && dtFilter.ddltypeassign !== "") {

      newList = assignListData.filter((assign) => {
        return (
          assign.status.toLowerCase().includes(dtFilter.ddlStatus.toLowerCase()) &&
          assign.type.toLowerCase().includes(dtFilter.ddltypeassign.toLowerCase())
        );
      });
    } else if (assignFilter.txtsearchassign === "" && dtFilter.txtassignname === "" && dtFilter.ddlStatus === "" && dtFilter.ddltypeassign !== "") {
      newList = assignListData.filter((assign) => {
        return (
          assign.type.toLowerCase().includes(dtFilter.ddltypeassign.toLowerCase())
        );
      });
    } else {
      newList = assignListData;
    }

    dispatch(setAllListAssignments(newList));
  };

  const handleChangeDDL = (type) => (e) => {

    if (type === "status") {
      dispatch(setStoreAssignFilter({
        ddlStatus: e.target.value,
      }));
    }else if(type ==="type"){
      dispatch(setStoreAssignFilter({
        ddltypeassign: e.target.value,
      }));
    }
  };

  const btnReset = () => {
    document.getElementById("filter-assignment").reset();
    dispatch(
      setStoreAssignFilter({
        txtassignname: "",
        ddlStatus: "",
        ddltypeassign: ""
      })
    );
  };

  const headers = [
    {
      Header: "No",
      accessor: (row) => `${row.index}`,
      maxWidth: 70,
      minWidth: 70,
      width: 70,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Company Name",
      accessor: (row) => `${row.clients ? row.clients.name : ""}`,
      maxWidth: 400,
      minWidth: 100,
      width: 350,
      Cell: ({ row }) => {
        const data = row.original;

        const status = data.clients.is_potential ? "potential" : "active";

        function viewAssignment() {
          if (data.type === "ES") {
            navigate("/clients/view-assignment/" + status + "/" + data.client_id + "/" + data.id);
          } else {
            navigate("/clients/view-assignment-tdm/tdm/" + status + "/" + data.client_id + "/" + data.id);
          }

        }

        return (
          <div className="container-div list-cli">
            <Button
              variant="link"
              className="no-underline"
              onClick={viewAssignment}
            >
              {data.clients ? data.clients.name : "-"}
            </Button>
          </div>
        );
      },
    },
    {
      Header: "Assignment Name",
      accessor: (row) => `${row.title}`,
      maxWidth: 400,
      minWidth: 100,
      width: 350,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            {data.title}
          </div>
        );
      },
    },
    {
      Header: "Created Date",
      accessor: (row) => `${row.created_at}`,
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{moment(data.created_at).format("DD MMMM YYYY")}</div>;
      },
    },
    {
      Header: "No. of Candidate",
      accessor: (row) => `${row._count.assignment_candidates}`,
      maxWidth: 400,
      minWidth: 100,
      width: 250,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <>
            <div>
              {data._count.assignment_candidates === 0
                ? "-"
                : data._count.assignment_candidates === 1 ? data._count.assignment_candidates + " Candidate"
                  : data._count.assignment_candidates + " Candidates"
              }
            </div>
          </>
        );
      },
    },
    {
      Header: "Status",
      accessor: (row) => `${row.status}`,
      maxWidth: 250,
      minWidth: 100,
      width: 250,
      Cell: ({ row }) => {
        const data = row.original;

        if (data.status === "in_discussion") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--discussion--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else if (data.status === "in_progress") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--progress--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else if (data.status === "on_hold") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--hold--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else if (data.status === "completed") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--completed--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else if (data.status === "proposal") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--completed--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else if (data.status === "deleted") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--hold--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--warning--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        }
      },
    },
  ];

  // function getListAssignments() {
  //   Api.get("assignment")
  //     .then((response) => {
  //       let data = response.data;

  //       // console.log("data assignmet", data);

  //       setAssignmentList(data);
  //       setAllDataAssign(data);
  //     })
  //     .catch((error) => {
  //       console.log("error list assignments", error);
  //     });
  // };
  const [loading, setLoading] = useState(true);
  // First Load page
  useEffect(() => {

    if (assignFilter.txtassignname !== "" || assignFilter.ddlStatus !== "") {
      setClicked(true);
    } else {
      setClicked(false);
    }

    dispatch(getListDataAssignments(assignFilter.txtsearchassign, assignFilter.txtassignname, assignFilter.ddlStatus, assignFilter.ddltypeassign));

    dispatch(storeTitle({ title: "Assignments", icon: mdiAccountSearch }));

    setTimeout(() => setLoading(false), 2000);
  }, [dispatch]);

  return (
    <>
      {assignListData.length === 0 ?
        loading ?
          <div id="main-overlay" className="main-overlay">
            <Spinner animation="border" variant="light" />
          </div> : ""
        : ""}
      <div className="main-div list-cli">
        <div className="top-title-div cli-page">
          <div className="filter_container">
            <div className="filter_container_totalrow">
              {assignAllListData.length === 0 || assignAllListData.length === 1 ? (
                <div className="form-container-default">
                  <div className="total_client_text">{assignAllListData.length}</div>
                  <div className="client_text">assignment</div>
                </div>
              ) : (
                <div className="form-container-default">
                  <div className="total_client_text">{assignAllListData.length}</div>
                  <div className="client_text">assignments</div>
                </div>
              )}
            </div>

            <div className="upper-divider"></div>

            <div className="filter_container_search">
              <div className="filter_container_search-1">
                <TextboxIcon
                  name="txtsearchassign"
                  type="text"
                  onChange={(event) => handleSearch(event)}
                  placeholder="Search by company name"
                  inputSize="input--medium"
                  icon={mdiMagnify}
                  value={assignFilter.txtsearchassign}
                ></TextboxIcon>
              </div>
            </div>

            <div className="filter_container_typelist">
              <div className="form-container-filter-button">
                {/* {cliSelected === 0 ? (
                  <>
                    <Button
                      variant="primary"
                      className="btn-plus"
                      onClick={btnOpenAddClient}
                      hidden={!abilities.includes("add potential")}
                    >
                      <Icon path={mdiPlus} />
                    </Button>
                  </>
                ) : (
                  ""
                )} */}

                <Button onClick={() => btnCollapse(true)} variant="secondary">
                  <Icon path={mdiFilter} />
                  <div>Filter</div>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div id="demo" className={"collapses" + (clicked ? " in" : "")}>
          <Form id="filter-assignment" onSubmit={btnFilter}>
            <div className="form-container-filter">
              <div className="form-container-filter-1">
                <Form.Group>
                  <Form.Label>Assignment Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="txtassignname"
                    defaultValue={assignFilter.txtassignname}
                  />
                </Form.Group>
              </div>

              <div className="form-container-filter-1">
                <Form.Group>
                  <Form.Label>Assignment Type</Form.Label>
                  <Form.Select
                    name="ddltypeassign"
                    value={assignFilter.ddltypeassign}
                    onChange={handleChangeDDL("type")}
                  >
                    <option value="">Select</option>
                    {/* {statusAssignListFilter.map((assign, index) => (
                      <option key={index} value={assign.value}>
                        {assign.label}
                      </option>
                    ))} */}
                    <option value="ES">Executive Search</option>
                    <option value="DM">Distruption Management</option>
                    <option value="TM">Transition Management</option>
                  </Form.Select>
                </Form.Group>
              </div>

              <div className="form-container-filter-1">
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Select name="ddlStatus" value={assignFilter.ddlStatus} onChange={handleChangeDDL("status")}>
                    <option value="">Select</option>
                    {statusAssignListFilter.map((assign, index) => (
                      <option key={index} value={assign.value}>
                        {assign.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
            </div>
            <div className="row-btn-bottom">
              <Button variant="secondary"
                onClick={btnReset}
              >
                Reset
              </Button>
              <Button variant="primary" type="submit" form="filter-assignment">
                Search
              </Button>
            </div>
          </Form>
        </div>

        <div className="container-div list-cli">
          <CustomTable
            headers={headers}
            items={assignAllListData}
            paginate={assignAllListData.length}
            maxRows={15}
            pindex={true}
          ></CustomTable>
        </div>
      </div>
    </>
  );
}

export default ListAssignments;
