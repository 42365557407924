import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CandidateLayout from "../../pages/Candidate/Layout/Layout";
import Profile from "../../pages/Candidate/Profile";
import Document from "../../pages/Candidate/Document";
import Attachment from "../../pages/Candidate/Attachment";
import ChangePassword from "../../pages/Candidate/ChangePassword";
import ViewAllNoti from "../../pages/Candidate/AllNotificationsCandidate";
import Consent from "../../pages/Candidate/Consent";

const RouteCandidate = () => {
  return (
    <CandidateLayout>
      <Routes>
        <Route path="/profile" element={<Profile />}></Route>
        
        <Route
          path="/verified/:id"
          element={<Navigate replace to="/profile" /> }
        />
        <Route
          path="/"
          element={<Navigate replace to="/profile" /> }
        />
        <Route path="/document" element={<Document />}></Route>
        <Route path="/attachment" element={<Attachment />}></Route>
        <Route path="/consent" element={<Consent />}></Route>
        <Route path="/candidate-password" element={<ChangePassword />}></Route>
        <Route path="/all-notifications" element={<ViewAllNoti />}></Route>
      </Routes>
    </CandidateLayout>
  );
};

export default RouteCandidate;
