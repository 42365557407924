import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Table, Dropdown, Row, Accordion, Spinner } from "react-bootstrap";
import Api from "../../../../core/services/api.service";

import AuthAbilities from "../../Auth/AuthAbilities";
import SwalAlert from "../../../../components/Swal/SwalAlert";

import Icon from "@mdi/react";
import {
  mdiMagnify,
  mdiFilter,
  mdiMerge,
  mdiDelete,
  mdiDotsHorizontal,
  mdiPlus,
  mdiAccountGroup,
} from "@mdi/js";

import Popup from "../../../../components/Popup/ModalPopup";
import CustomTable from "../../../../components/Table/Table";
import CardInfo from "../../../../components/Card/CardInfo";
import TextFieldFormat from "../../../../components/TextField/TextFieldFormat";
import TextboxIcon from "../../../../components/TextField/TextFieldIcon";
import Searchable from "../../../../components/DropDown/SearchableSelect";
import DefaultPhoto from "../../../../assets/no-profile-photo.jpg";

import { getListStaff } from "../../../../store/actions/staffSlice";
import { getClientView, getListClientPotential, setStorePotFilter, setAllListPotentialClient } from "../../../../store/actions/clientSlice";
import { storeTitle } from "../../../../store/actions/headerTitleSlice";
import { indCliPotTab } from "../../../../store/actions/tabActiveSlice";
import { getMergeClient } from "../../../../store/actions/mergeSlice";
import { storePage } from "../../../../store/actions/paginationSlice";

import { statusClient } from "../../dataList";
import "../client.css";

function ListPotentialClients() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const abilities = AuthAbilities();
  const customSwal = SwalAlert();
  const formRef = useRef();

  const { dataCountry, dataIndustry, dataStockExchanges } = useSelector((state) => state.option);
  const { dataStaff } = useSelector((state) => state.staff);
  const { clientListPot, clientAllListPot, clientPotFilter } = useSelector((state) => state.client);
  const { mergeClientSimilar, mergeClientNoSimilar } = useSelector(
    (state) => state.merge
  );

  const [errorForm, setErrorForm] = useState({});

  const [selIndNew, setSelIndNew] = useState([]);

  const [clicked, setClicked] = useState(false);
  const [allData, setAllData] = useState([]);
  const [showMerge, setShowMerge] = useState(false);
  const [showAddClient, setShowAddClient] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [checkedList, setCheckedList] = useState(false);

  const [selConsult, setSelConsult] = useState([]);

  const [checkClients] = useState([]);
  const [cliSelected, setCliSelected] = useState(0);

  const btnOpenAddClient = (e) => {
    setShowAddClient(true);
    setErrorForm({});
    setCheckedList(false);
  };

  const btnCloseAddClient = () => {
    setShowAddClient(false);
  };

  const btnCloseMerge = () => {
    setShowMerge(false);
  };

  const [clientOne, setClientOne] = useState({});
  const [clientTwo, setClientTwo] = useState({});

  const btnOpenMerge = (e) => {
    var cliId = checkClients.map((s) => s.id);

    dispatch(getMergeClient(cliId[0], cliId[1]));

    setClientOne({
      client_id: checkClients[0].id,
      client_name: checkClients[0].name,
    });

    setClientTwo({
      client_id: checkClients[1].id,
      client_name: checkClients[1].name,
    });

    setCombineClient({
      proposal: false,
      meetings: false,
      staffs: false,
      documents: false,
    });

    setShowMerge(true);
  };

  const btnCollapse = (index) => {
    if (clicked === index) {
      //if clicked is already active, then close
      return setClicked(null);
    }

    setClicked(index);
  };

  const handleSearch = (event) => {
    let searchVal = event.target.value.toLowerCase();

    dispatch(storePage(0));
    dispatch(setStorePotFilter({ txtsearchpot: searchVal }));

    let idConsult = Number(clientPotFilter.ddlConsultant);

    let newList = [];
    
    if (searchVal !== "" && clientPotFilter.ddlCountry === "" && clientPotFilter.ddlConsultant === "" && clientPotFilter.ddlStatus === ""){
      newList = clientListPot.filter((client) => {
        return client.name.toLowerCase().includes(searchVal.toLowerCase());
      });
    }else if (searchVal !== "" && clientPotFilter.ddlCountry !== "" && clientPotFilter.ddlConsultant === "" && clientPotFilter.ddlStatus === ""){
      newList = clientListPot.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          client.name.toLowerCase().includes(searchVal.toLowerCase()) &&
          cntry.includes(clientPotFilter.ddlCountry)
        );
      });
    }else if (searchVal !== "" && clientPotFilter.ddlCountry !== "" && clientPotFilter.ddlConsultant !== "" && clientPotFilter.ddlStatus === ""){
      newList = clientListPot.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          client.name.toLowerCase().includes(searchVal.toLowerCase()) &&
          cntry.includes(clientPotFilter.ddlCountry) &&
          client.consultant_name.find((e) => e === idConsult)
        );
      });
    }else if (searchVal !== "" && clientPotFilter.ddlCountry !== "" && clientPotFilter.ddlConsultant !== "" && clientPotFilter.ddlStatus !== ""){
      newList = clientListPot.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          client.name.toLowerCase().includes(searchVal.toLowerCase()) &&
          cntry.includes(clientPotFilter.ddlCountry) &&
          client.consultant_name.find((e) => e === idConsult) &&
          client.status.toLowerCase().includes(clientPotFilter.ddlStatus)
        );
      });
    }else if (searchVal !== "" && clientPotFilter.ddlCountry !== "" && clientPotFilter.ddlConsultant === "" && clientPotFilter.ddlStatus !== ""){
      newList = clientListPot.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          client.name.toLowerCase().includes(searchVal.toLowerCase()) &&
          cntry.includes(clientPotFilter.ddlCountry) &&
          client.status.toLowerCase().includes(clientPotFilter.ddlStatus)
        );
      });
    }else if (searchVal !== "" && clientPotFilter.ddlCountry === "" && clientPotFilter.ddlConsultant !== "" && clientPotFilter.ddlStatus === ""){
      newList = clientListPot.filter((client) => {
        return (
          client.name.toLowerCase().includes(searchVal.toLowerCase()) &&
          client.consultant_name.find((e) => e === idConsult)
        );
      });
    }else if (searchVal !== "" && clientPotFilter.ddlCountry === "" && clientPotFilter.ddlConsultant !== "" && clientPotFilter.ddlStatus !== ""){
      newList = clientListPot.filter((client) => {
        return (
          client.name.toLowerCase().includes(searchVal.toLowerCase()) &&
          client.consultant_name.find((e) => e === idConsult) &&
          client.status.toLowerCase().includes(clientPotFilter.ddlStatus)
        );
      });
    }else if (searchVal !== "" && clientPotFilter.ddlCountry === "" && clientPotFilter.ddlConsultant === "" && clientPotFilter.ddlStatus !== ""){
      newList = clientListPot.filter((client) => {
        return (
          client.name.toLowerCase().includes(searchVal.toLowerCase()) &&
          client.status.toLowerCase().includes(clientPotFilter.ddlStatus)
        );
      });
    }else if (searchVal === "" && clientPotFilter.ddlCountry !== "" && clientPotFilter.ddlConsultant === "" && clientPotFilter.ddlStatus === ""){
      newList = clientListPot.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          cntry.includes(clientPotFilter.ddlCountry) 
        );
      });
    }else if (searchVal === "" && clientPotFilter.ddlCountry !== "" && clientPotFilter.ddlConsultant !== "" && clientPotFilter.ddlStatus === ""){
      newList = clientListPot.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          cntry.includes(clientPotFilter.ddlCountry) &&
          client.consultant_name.find((e) => e === idConsult)
        );
      });
    }else if (searchVal === "" && clientPotFilter.ddlCountry !== "" && clientPotFilter.ddlConsultant !== "" && clientPotFilter.ddlStatus !== ""){
      newList = clientListPot.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          cntry.includes(clientPotFilter.ddlCountry) &&
          client.consultant_name.find((e) => e === idConsult) &&
          client.status.toLowerCase().includes(clientPotFilter.ddlStatus)
        );
      });
    }else if (searchVal === "" && clientPotFilter.ddlCountry !== "" && clientPotFilter.ddlConsultant === "" && clientPotFilter.ddlStatus !== ""){
      newList = clientListPot.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          cntry.includes(clientPotFilter.ddlCountry) &&
          client.status.toLowerCase().includes(clientPotFilter.ddlStatus)
        );
      });
    }else if (searchVal === "" && clientPotFilter.ddlCountry === "" && clientPotFilter.ddlConsultant !== "" && clientPotFilter.ddlStatus === ""){
      newList = clientListPot.filter((client) => {
        return (
          client.consultant_name.find((e) => e === idConsult)
        );
      });
    }else if (searchVal === "" && clientPotFilter.ddlCountry === "" && clientPotFilter.ddlConsultant !== "" && clientPotFilter.ddlStatus !== ""){
      newList = clientListPot.filter((client) => {
        return (
          client.consultant_name.find((e) => e === idConsult) &&
          client.status.toLowerCase().includes(clientPotFilter.ddlStatus)
        );
      });
    }else if (searchVal === "" && clientPotFilter.ddlCountry === "" && clientPotFilter.ddlConsultant === "" && clientPotFilter.ddlStatus !== ""){
      newList = clientListPot.filter((client) => {
        return (
          client.status.toLowerCase().includes(clientPotFilter.ddlStatus)
        );
      });
    }else{
      newList = clientListPot;
    }

    dispatch(setAllListPotentialClient(newList));

    
  };

  const btnFilter = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtFilter = Object.fromEntries(data.entries());

    dispatch(storePage(0));
    dispatch(setStorePotFilter({ddlCountry: dtFilter.ddlCountry, ddlConsultant: dtFilter.ddlConsultant,ddlStatus: dtFilter.ddlStatus,}));

    let idConsult = Number(dtFilter.ddlConsultant);
    let newList = [];

    if (clientPotFilter.txtsearchpot !== "" && dtFilter.ddlCountry === "" && dtFilter.ddlConsultant === "" && dtFilter.ddlStatus === ""){
      newList = clientListPot.filter((client) => {
        return client.name.toLowerCase().includes(clientPotFilter.txtsearchpot.toLowerCase());
      });
    }else if (clientPotFilter.txtsearchpot !== "" && dtFilter.ddlCountry !== "" && dtFilter.ddlConsultant === "" && dtFilter.ddlStatus === ""){
      newList = clientListPot.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          client.name.toLowerCase().includes(clientPotFilter.txtsearchpot.toLowerCase()) &&
          cntry.includes(dtFilter.ddlCountry)
        );
      });
    }else if (clientPotFilter.txtsearchpot !== "" && dtFilter.ddlCountry !== "" && dtFilter.ddlConsultant !== "" && dtFilter.ddlStatus === ""){
      newList = clientListPot.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          client.name.toLowerCase().includes(clientPotFilter.txtsearchpot.toLowerCase()) &&
          cntry.includes(dtFilter.ddlCountry) &&
          client.consultant_name.find((e) => e === idConsult)
        );
      });
    }else if (clientPotFilter.txtsearchpot !== "" && dtFilter.ddlCountry !== "" && dtFilter.ddlConsultant !== "" && dtFilter.ddlStatus !== ""){
      newList = clientListPot.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          client.name.toLowerCase().includes(clientPotFilter.txtsearchpot.toLowerCase()) &&
          cntry.includes(dtFilter.ddlCountry) &&
          client.consultant_name.find((e) => e === idConsult) &&
          client.status.toLowerCase().includes(dtFilter.ddlStatus)
        );
      });
    }else if (clientPotFilter.txtsearchpot !== "" && dtFilter.ddlCountry !== "" && dtFilter.ddlConsultant === "" && dtFilter.ddlStatus !== ""){
      newList = clientListPot.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          client.name.toLowerCase().includes(clientPotFilter.txtsearchpot.toLowerCase()) &&
          cntry.includes(dtFilter.ddlCountry) &&
          client.status.toLowerCase().includes(dtFilter.ddlStatus)
        );
      });
    }else if (clientPotFilter.txtsearchpot !== "" && dtFilter.ddlCountry === "" && dtFilter.ddlConsultant !== "" && dtFilter.ddlStatus === ""){
      newList = clientListPot.filter((client) => {
        return (
          client.name.toLowerCase().includes(clientPotFilter.txtsearchpot.toLowerCase()) &&
          client.consultant_name.find((e) => e === idConsult)
        );
      });
    }else if (clientPotFilter.txtsearchpot !== "" && dtFilter.ddlCountry === "" && dtFilter.ddlConsultant !== "" && dtFilter.ddlStatus !== ""){
      newList = clientListPot.filter((client) => {
        return (
          client.name.toLowerCase().includes(clientPotFilter.txtsearchpot.toLowerCase()) &&
          client.consultant_name.find((e) => e === idConsult) &&
          client.status.toLowerCase().includes(dtFilter.ddlStatus)
        );
      });
    }else if (clientPotFilter.txtsearchpot !== "" && dtFilter.ddlCountry === "" && dtFilter.ddlConsultant === "" && dtFilter.ddlStatus !== ""){
      newList = clientListPot.filter((client) => {
        return (
          client.name.toLowerCase().includes(clientPotFilter.txtsearchpot.toLowerCase()) &&
          client.status.toLowerCase().includes(dtFilter.ddlStatus)
        );
      });
    }else if (clientPotFilter.txtsearchpot === "" && dtFilter.ddlCountry !== "" && dtFilter.ddlConsultant === "" && dtFilter.ddlStatus === ""){
      newList = clientListPot.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          cntry.includes(dtFilter.ddlCountry) 
        );
      });
    }else if (clientPotFilter.txtsearchpot === "" && dtFilter.ddlCountry !== "" && dtFilter.ddlConsultant !== "" && dtFilter.ddlStatus === ""){
      newList = clientListPot.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          cntry.includes(dtFilter.ddlCountry) &&
          client.consultant_name.find((e) => e === idConsult)
        );
      });
    }else if (clientPotFilter.txtsearchpot === "" && dtFilter.ddlCountry !== "" && dtFilter.ddlConsultant !== "" && dtFilter.ddlStatus !== ""){
      newList = clientListPot.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          cntry.includes(dtFilter.ddlCountry) &&
          client.consultant_name.find((e) => e === idConsult) &&
          client.status.toLowerCase().includes(dtFilter.ddlStatus)
        );
      });
    }else if (clientPotFilter.txtsearchpot === "" && dtFilter.ddlCountry !== "" && dtFilter.ddlConsultant === "" && dtFilter.ddlStatus !== ""){
      newList = clientListPot.filter((client) => {
        let cntry = "";

        if (client.options !== null) {
          cntry = client.options.value;
        } else {
          cntry = "";
        }

        return (
          cntry.includes(dtFilter.ddlCountry) &&
          client.status.toLowerCase().includes(dtFilter.ddlStatus)
        );
      });
    }else if (clientPotFilter.txtsearchpot === "" && dtFilter.ddlCountry === "" && dtFilter.ddlConsultant !== "" && dtFilter.ddlStatus === ""){
      newList = clientListPot.filter((client) => {
        return (
          client.consultant_name.find((e) => e === idConsult)
        );
      });
    }else if (clientPotFilter.txtsearchpot === "" && dtFilter.ddlCountry === "" && dtFilter.ddlConsultant !== "" && dtFilter.ddlStatus !== ""){
      newList = clientListPot.filter((client) => {
        return (
          client.consultant_name.find((e) => e === idConsult) &&
          client.status.toLowerCase().includes(dtFilter.ddlStatus)
        );
      });
    }else if (clientPotFilter.txtsearchpot === "" && dtFilter.ddlCountry === "" && dtFilter.ddlConsultant === "" && dtFilter.ddlStatus !== ""){
      newList = clientListPot.filter((client) => {
        return (
          client.status.toLowerCase().includes(dtFilter.ddlStatus)
        );
      });
    }else{
      newList = clientListPot;
    }

    dispatch(setAllListPotentialClient(newList));
  };

  const handleChangeDDL = (type) => (e) => {

    if(type === "country"){
      dispatch(setStorePotFilter({
        ddlCountry: e.target.value,
      }));
    }else if(type === "consultant"){
      dispatch(setStorePotFilter({
        ddlConsultant: e.target.value,
      }));
    }else{
      dispatch(setStorePotFilter({
        ddlStatus: e.target.value,
      }));
    }
   
  };

  const btnReset = () => {
    document.getElementById("filter-potential").reset();
    dispatch(
      setStorePotFilter({
        ddlCountry: "",
        ddlConsultant: "",
        ddlStatus: "",
      })
    );
  };

  const handleCheck = (event) => {
    // console.log("ssss", event.target.checked);
    setCheckedList(event.target.checked);
  };

  const handleChange = (e) => {
    setSelIndNew(Array.isArray(e) ? e.map((x) => x.id) : []);
  };

  const [combineClient, setCombineClient] = useState({
    proposal: false,
    meetings: false,
    staffs: false,
    documents: false,
  });

  const valueClientIdChange = (e) => {
    var selectedID = parseInt(e.target.value);
    var cliID = checkClients.map((s) => s.id);
    // // console.log("candOne", candid[0]);
    // // console.log("candTwo", parseInt(e.target.value));

    if (selectedID === cliID[0]) {
      Object.assign(combineClient, { id: selectedID, id_remove: cliID[1] });
    } else {
      Object.assign(combineClient, { id: selectedID, id_remove: cliID[0] });
    }

    // console.log("combineCand", combineClient);
  };

  const valueClientCheck = (e) => {
    Object.assign(combineClient, { [e.target.value]: e.currentTarget.checked });

    // console.log("setCombineCand", combineClient);
  };

  const btnDelete = (id) => (e) => {

    customSwal
      .fire({
        title: "Do you want to delete this potential client?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("client/" + id)
            .then((response) => {
              // setSelectedIndustry([]);

              //close modal after update
              dispatch(getListClientPotential(
                clientPotFilter.txtsearchpot,
                clientPotFilter.ddlCountry,
                clientPotFilter.ddlConsultant,
                clientPotFilter.ddlStatus
              ));
              customSwal.fire({
                title: "Deleted Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error delete potential client", error);
            });
        }
      });
  };

  const btnDeleteMultiple = () => (e) =>{
    customSwal
    .fire({
      title: checkClients.length > 1 ? "Do you want to delete multiple selected client?" : "Do you want to delete selected client?",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Yes",
    })
    .then((result) => {
      if (result.isConfirmed) {

        checkClients.map((client, index) => {
          Api.delete("client/" + client.id)
          .then((response) => {
            dispatch(getListClientPotential(
              clientPotFilter.txtsearchpot,
              clientPotFilter.ddlCountry,
              clientPotFilter.ddlConsultant,
              clientPotFilter.ddlStatus
            ));
          })
          .catch((error) => {
            console.log("error delete multiple potential client", error);
          });
        });

        //close modal after update
       
        customSwal.fire({
          title: "Deleted Successfully!",
          icon: "success",
          confirmButtonText: "Okay",
        });
        
      }
    });
  };

  const headers = [
    {
      Header: "",
      accessor: "selection",
      disableSortBy: true,
      maxWidth: 50,
      minWidth: 10,
      width: 50,
      Cell: ({ row }) => {
        const data = row.original;

        const onItemCheck = (data) => (event) => {
          if (checkClients.length > 0) {
            if (event.target.checked === false) {
              const index = checkClients.findIndex(function (chck) {
                return chck.id === data.id;
              });

              checkClients.splice(index, 1);
            } else {
              checkClients.push(data);
            }
          } else {
            checkClients.push(data);
          }

          setCliSelected(checkClients.length);
        };

        const checkedItem = (id) => {
          let potCli = [];

          const checkCli = checkClients.filter((chec) => {
            return chec.id === id;
          });

          potCli = checkCli[0];

          return potCli;
        };

        return (
          <div className="checkbox-container">
            <input
              type="checkbox"
              checked={checkedItem(data.id)}
              id={"row" + data.id}
              onChange={onItemCheck(data)}
            />
          </div>
        );
      },
    },
    {
      Header: "Client Info",
      accessor: (row) => `${row.name}`,
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        function handleClick() {
          dispatch(
            indCliPotTab({
              id: data.id,
              index: "1",
            })
          );

          dispatch(getClientView({}));

          navigate("/clients/view-potential-client/" + data.id);
        }

        return (
          <div className="form-container-default">
            <div className="form-container-default-1">
              {data.logo ? (
                <img src={data.logo} alt="Client Logo" />
              ) : (
                <img src={DefaultPhoto} alt="Client Logo" />
              )}
            </div>
            <div
              className="form-container-default-2"
              style={{ paddingLeft: "10px" }}
            >
              <Button
                variant="link"
                className="no-underline"
                onClick={handleClick}
              >
                {data.name}
              </Button>
            </div>
          </div>
        );
      },
    },
    {
      Header: "Headquarter (HQ)",
      accessor: (row) => `${row.options ? row.options.value : ""}`,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;
        // console.log(data)

        let country = "";
        !data.options || data.options === ""
          ? (country = "-")
          : (country = data.options.value);

        return <div>{country}</div>;
      },
      // maxWidth: 200,
      // minWidth: 100,
      // width: 200,
      // Cell: ({ row }) => {
      //   const data = row.original;

      //   const result = (data.client_staff || []).length;

      //   let country = "";

      //   if (result > 0) {
      //     country = data.client_staff[0].candidates.citizenship_main;
      //   } else {
      //     country = "-";
      //   }

      //   return <div>{country}</div>;
      // },
    },
    {
      Header: "Contact Person",
      maxWidth: 200,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        const client_arr = data.client_staff;
        let contactStaff = "";

        const result = client_arr.filter(
          (client) => client.candidates.status === "client"
        );
        // console.log(result);

        if (result.length > 0) {
          contactStaff =
            result[0].candidates.first_name +
            " " +
            result[0].candidates.last_name;
        } else {
          contactStaff = "-";
        }

        return <div>{contactStaff}</div>;
      },
    },
    {
      Header: "Job Title",
      maxWidth: 200,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        const client_arr = data.client_staff;
        let jobtitle = "";

        const result = client_arr.filter(
          (client) => client.candidates.status === "client"
        );

        // console.log(result);

        if (result.length > 0) {
          jobtitle = result[0].candidates.job_title;
        } else {
          jobtitle = "-";
        }

        return (
          <>
            <div>{jobtitle}</div>
          </>
        );
      },
    },

    {
      Header: "Consultant Name",
      maxWidth: 200,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        var array = data.client_has_user.map((obj) => {
          return obj.user.first_name + " " + obj.user.last_name + ",";
        });

        var cons_name = array.join(" ");

        return <div>{cons_name ? cons_name.slice(0, -1) : "-"}</div>;
        // const data = row.original;

        // const result4 = (data.client_has_user || []).length;

        // let consultantName = "";

        // if (result4 > 0) {
        //   consultantName =
        //     data.client_has_user[0].user.first_name +
        //     " " +
        //     data.client_has_user[0].user.last_name;
        // } else {
        //   consultantName = "-";
        // }

        // return (
        //   <>
        //     <div>{consultantName}</div>
        //   </>
        // );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      maxWidth: 150,
      minWidth: 150,
      width: 150,
      Cell: ({ row }) => {
        const data = row.original;

        return data.status === "Blacklisted" ? (
          <CardInfo
            cardTextSize="card_text--small"
            cardTextStyle="card_text--danger--solid"
          >
            {data.status}
          </CardInfo>
        ) : (
          <CardInfo
            cardTextSize="card_text--small"
            cardTextStyle="card_text--primary--solid"
          >
            {data.status.replace(/_/g, " ")}
          </CardInfo>
        );
      },
    },
    {
      Header: "",
      accessor: "client_info",
      disableSortBy: true,
      maxWidth: 70,
      minWidth: 10,
      width: 70,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="dropdown-container list-cli">
            {abilities.includes("delete potential") ? (
              <Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  <Icon path={mdiDotsHorizontal} size={1} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    hidden={!abilities.includes("delete potential")}
                    onClick={btnDelete(data.id)}
                  >
                    <Icon path={mdiDelete} color="#DF4759" />
                    Delete Client
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
  ];

  const changeStaff = (e) => {
    setSelConsult(Array.isArray(e) ? e.map((x) => x.id) : []);
  };

  // Checking Form Error
  const formError = (object) => {
    const newErrors = {};

    // console.log("object", object);

    if (!object.txtComName || object.txtComName === "")
      newErrors.txtComName = "Fill in the blank!";

    // if (!object.txtComWebsite || object.txtComWebsite === "")
    //   newErrors.txtComWebsite = "Fill in the blank!";

    return newErrors;
  };

  // Call Function
  // const getListPotential = useCallback(() => {

  //   // setAllData(clientListPotential);
  //   Api.get("client")
  //     .then((response) => {
  //       let data = response.data;

  //       let listPotential = data.filter(
  //         (pot) =>
  //           pot.is_potential === true &&
  //           pot.status !== null &&
  //           pot.status !== "closed"
  //       );

  //       // console.log("listPotential", listPotential);

  //       setClientList(listPotential);
  //       setAllData(listPotential);
  //     })
  //     .catch((error) => {
  //       console.log("client", error);
  //     });
  // }, [dispatch]);

  // Button Event
  const btnSaveClient = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtCli = Object.fromEntries(data.entries());
    const newErrors = formError(dtCli);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);

      for (var name in newErrors) {
        // console.log(name);

        const input = document.querySelector(`input[name=${name}]`);

        input.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });

        // console.log("input", input, inp_txtarea);
      }
    } else {
      customSwal
        .fire({
          title: "Do you want to save this client?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Api.post("client", {
              name: dtCli.txtComName,
              incorporated: dtCli.txtincorporate,
              consultant_name: selConsult,
              // dtCli.ddlConsultant
              //   ? [Number(dtCli.ddlConsultant)]
              //   : [],
              industry: selIndNew.length > 0 ? selIndNew : [],
              headquarters: dtCli.ddlCorporate
                ? Number(dtCli.ddlCorporate)
                : null,
              address: dtCli.txtComAddr,
              general_telephone: dtCli.txtphone,
              website_url: dtCli.txtComWebsite,
              linkedln_url: dtCli.txtlinkedln,
              revenue: dtCli.txtrevenue,
              total_employees: dtCli.txtcompanyEmp,
              overall_background: dtCli.txtoverall,
              important_note: dtCli.txtnotes,
              status: "BD",
              is_parent: false,
              finance_certificate: checkedList,
              stock_exchange: dtCli.ddlStockExchanges ? Number(dtCli.ddlStockExchanges) : null,
              is_longterm: false,
              is_potential: true,
              reason_blacklist: "",
            })
              .then((response) => {
                // setSelectedIndustry([]);

                //close modal after update
                dispatch(getListClientPotential(
                  clientPotFilter.txtsearchpot,
                  clientPotFilter.ddlCountry,
                  clientPotFilter.ddlConsultant,
                  clientPotFilter.ddlStatus
                ));

                setShowAddClient(false);
                customSwal.fire({
                  title: "Client Saved Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("client/store", error);
              });
          }
        });
    }
  };

  const btnMergeClient = (e) => {
    e.preventDefault();

    // const data = new FormData(e.target);
    // const dtMCli = Object.fromEntries(data.entries());

    // console.log("dtMCli", combineClient);
  };

  const [loading, setLoading] = useState(true);

  // First Load page
  useEffect(() => {

    if(clientPotFilter.ddlCountry !== "" || clientPotFilter.ddlConsultant !== "" || clientPotFilter.ddlStatus !== ""){
      setClicked(true);
    }else{
      setClicked(false);
    }

    dispatch(getListClientPotential(
      clientPotFilter.txtsearchpot,
      clientPotFilter.ddlCountry,
      clientPotFilter.ddlConsultant,
      clientPotFilter.ddlStatus
    ));

    dispatch(getListStaff());
    dispatch(storeTitle({ title: "Potential Clients", icon: mdiAccountGroup }));

    setTimeout(() => setLoading(false), 2000);
  }, [dispatch]);

  return (
    <>
      {clientListPot.length === 0 ? 
        loading ? 
          <div id="main-overlay" className="main-overlay">
            <Spinner animation="border" variant="light" />
          </div> : ""
      :""}
      <div className="main-div list-cli">
        <div className="top-title-div cli-page">
          <div className="filter_container">
            <div className="filter_container_totalrow">
              {clientAllListPot.length === 0 || clientAllListPot.length === 1 ? (
                <div className="form-container-default">
                  <div className="total_client_text">{clientAllListPot.length}</div>
                  <div className="client_text">client</div>
                </div>
              ) : (
                <div className="form-container-default">
                  <div className="total_client_text">{clientAllListPot.length}</div>
                  <div className="client_text">clients</div>
                </div>
              )}
            </div>

            <div className="upper-divider"></div>

            <div className="filter_container_search">
              <div className="filter_container_search-1">
                <TextboxIcon
                  name="txtsearchpot"
                  type="text"
                  onChange={(event) => handleSearch(event)}
                  placeholder="Search by client name"
                  inputSize="input--medium"
                  icon={mdiMagnify}
                  value={clientPotFilter.txtsearchpot}
                ></TextboxIcon>
              </div>
            </div>

            <div className="filter_container_typelist">
              <div className="form-container-filter-button">
                {cliSelected === 0 ? (
                  <>
                    <Button
                      variant="primary"
                      className="btn-plus"
                      onClick={btnOpenAddClient}
                      hidden={!abilities.includes("add potential")}
                    >
                      <Icon path={mdiPlus} />
                    </Button>
                  </>
                ) : (
                  ""
                )}

                {/* Modal Popup Add Potential Client */}
                <Popup
                  show={showAddClient}
                  size="lg"
                  backdrop="static"
                  keyboard={false}
                  onHide={btnCloseAddClient}
                  title="Add Potential Client"
                  content={
                    <>
                      <Form id="create-client-form" onSubmit={btnSaveClient}>
                        {/* 1 */}
                        <Row>
                          <Form.Group className="mb-3">
                            <Form.Label>
                              Company Name{" "}
                              <label className="validation-star">*</label>
                            </Form.Label>
                            <Form.Control
                              name="txtComName"
                              type="text"
                              isInvalid={!!errorForm.txtComName}
                              ref={formRef}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errorForm.txtComName}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        {/* 2 */}
                        <Row>
                          <Form.Group className="mb-3 col-6">
                            <Form.Label>Incorporated</Form.Label>
                            <Form.Control name="txtincorporate" type="text" />
                          </Form.Group>

                          <Form.Group className="mb-3 col-6">
                            <Form.Label>Consultant Name</Form.Label>
                            <Searchable
                              name="ddlConsultant"
                              isMulti={true}
                              options={dataStaff}
                              getOptionValue={(option) => option.id}
                              getOptionLabel={(option) =>
                                option.first_name + " " + option.last_name
                              }
                              // defaultValue={consulName}
                              onChange={changeStaff}
                              // isInvalid={!!errorForm.ddlcontact}
                              // errorText={errorForm.ddlcontact}
                            ></Searchable>
                            {/* <Form.Select name="ddlConsultant">
                              <option value="">Select</option>
                              {dataStaff.map((staf, index) => (
                                <option key={index} value={staf.id}>
                                  {staf.first_name + " " + staf.last_name}
                                </option>
                              ))}
                            </Form.Select> */}
                          </Form.Group>
                        </Row>
                        {/* 3 */}
                        <Row>
                          <Form.Group className="mb-3 col-6">
                            <Form.Label>Corporate HQ</Form.Label>
                            <Form.Select name="ddlCorporate">
                              <option value="">Select</option>
                              {dataCountry.map((cntry, index) => (
                                <option key={index} value={cntry.id}>
                                  {cntry.value}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>

                          <Form.Group className="mb-3 col-6">
                            <Form.Label>Industry</Form.Label>
                            <Searchable
                              isMulti={true}
                              options={dataIndustry}
                              onChange={handleChange}
                              getOptionValue={(option) => option.id}
                              getOptionLabel={(option) => option.value}
                            ></Searchable>
                          </Form.Group>
                        </Row>
                        {/* 4 */}
                        <Row>
                          <Form.Group className="mb-3">
                            <Form.Label>Company Address</Form.Label>
                            <Form.Control
                              as="textarea"
                              name="txtComAddr"
                              rows={3}
                            />
                          </Form.Group>
                        </Row>
                        {/* 5 */}
                        <Row>
                          <Form.Group className="mb-3">
                            <Form.Label>Company General Telephone</Form.Label>
                            <TextFieldFormat
                              name="txtphone"
                              format="+### ### ######## ######"
                            ></TextFieldFormat>
                          </Form.Group>
                        </Row>
                        {/* 6 */}
                        <Row>
                          <Form.Group className="mb-3 col-6">
                            <Form.Label>Company Website</Form.Label>
                            <Form.Control
                              name="txtComWebsite"
                              type="text"
                              placeholder="https://"
                            />
                          </Form.Group>

                          <Form.Group className="mb-3 col-6">
                            <Form.Label>Company LinkedIn Profile</Form.Label>
                            <Form.Control
                              name="txtlinkedln"
                              type="text"
                              placeholder="https://"
                            />
                          </Form.Group>
                        </Row>
                        {/* 7 */}
                        <Row>
                          <Form.Group className="mb-3 col-6">
                            <Form.Label>Company Revenue</Form.Label>
                            <Form.Control name="txtrevenue" type="text" />
                          </Form.Group>

                          <Form.Group className="mb-3 col-6">
                            <Form.Label>Company Employees</Form.Label>
                            <Form.Control name="txtcompanyEmp" type="text" />
                          </Form.Group>
                        </Row>
                        {/* 8 */}
                        <Row>
                          <Form.Group className="mb-3">
                            <Form.Check
                              name="cbListed"
                              type="checkbox"
                              onChange={handleCheck}
                              label="Is this a listed company?"
                            />
                          </Form.Group>
                        </Row>
                        {/* 10 */}
                        {checkedList ? 
                          <Row>
                            <Form.Group className="mb-3">
                              <Form.Label>Stock Exchanges</Form.Label>
                              <Form.Select name="ddlStockExchanges">
                                <option value="">Select</option>
                                {dataStockExchanges.map((stock, index) => (
                                  <option key={index} value={stock.id}>
                                    {stock.value}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </Row> : ""
                        }
                        {/* 9 */}
                        <Row>
                          <Form.Group className="mb-3">
                            <Form.Label>Overall Background</Form.Label>
                            <Form.Control
                              as="textarea"
                              name="txtoverall"
                              rows={3}
                            />
                          </Form.Group>
                        </Row>
                        {/* 10 */}
                        <Row>
                          <Form.Group className="mb-3">
                            <Form.Label>Important Notes</Form.Label>
                            <Form.Control
                              as="textarea"
                              name="txtnotes"
                              rows={2}
                            />
                          </Form.Group>
                        </Row>
                      </Form>
                    </>
                  }
                  button={
                    <>
                      <Button variant="secondary" onClick={btnCloseAddClient}>
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        form="create-client-form"
                      >
                        Create
                      </Button>
                    </>
                  }
                />

                {cliSelected === 2 ? (
                  <Button
                    variant="secondary"
                    onClick={btnOpenMerge}
                    hidden={true}
                    // hidden={!abilities.includes("merge potential")}
                  >
                    <Icon path={mdiMerge} />
                    <div>Merge</div>
                  </Button>
                ) : (
                  ""
                )}

                {cliSelected > 0 ? (
                  <Button
                    variant="danger"
                    hidden={!abilities.includes("delete potential")}
                    onClick={btnDeleteMultiple()}
                  >
                    <Icon path={mdiDelete} />
                    <div>Delete</div>
                  </Button>
                ) : (
                  ""
                )}

                <Button onClick={() => btnCollapse(true)} variant="secondary">
                  <Icon path={mdiFilter} />
                  <div>Filter</div>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div id="demo" className={"collapses" + (clicked ? " in" : "")}>
          <Form id="filter-potential" onSubmit={btnFilter}>
            <div className="form-container-filter">
              <div className="form-container-filter-1">
                <Form.Group>
                  <Form.Label>Country</Form.Label>
                  <Form.Select name="ddlCountry" value={clientPotFilter.ddlCountry} onChange={handleChangeDDL("country")}>
                    <option value="">Select</option>
                    {dataCountry.map((cntry, index) => (
                      <option key={index} value={cntry.value}>
                        {cntry.value}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="form-container-filter-1">
                <Form.Group>
                  <Form.Label>Consultant Name</Form.Label>
                  <Form.Select name="ddlConsultant" value={clientPotFilter.ddlConsultant} onChange={handleChangeDDL("consultant")}>
                    <option value="">Select</option>
                    {dataStaff.map((staf, index) => (
                      <option key={index} value={staf.id}>
                        {staf.first_name} {staf.last_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="form-container-filter-1">
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Select name="ddlStatus" value={clientPotFilter.ddlStatus} onChange={handleChangeDDL("status")}>
                    <option value="">Select</option>
                    {statusClient.map((status, index) => (
                      <option key={index} value={status.value}>
                        {status.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
            </div>
            <div className="row-btn-bottom">
              <Button variant="secondary" onClick={btnReset}>
                Reset
              </Button>
              <Button variant="primary" type="submit" form="filter-potential">
                Search
              </Button>
            </div>
          </Form>
        </div>

        <div className="container-div list-cli">
          {/* <div className="content-tabs_toggle"> */}
          <CustomTable
            headers={headers}
            items={clientAllListPot}
            paginate={clientAllListPot.length}
            maxRows={15}
            pindex={true}
          ></CustomTable>
          {/* </div> */}
        </div>

        {/* Modal Merge */}
        <Popup
          show={showMerge}
          size="xl"
          backdrop="static"
          keyboard={false}
          onHide={btnCloseMerge}
          title="Merge Client"
          content={
            <>
              <Form id="client_form" onSubmit={btnMergeClient}>
                <Table>
                  <thead className="merge-table-thead">
                    <tr>
                      <th colSpan={3}>Master Record and Field Selection</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th width="20%">Master Record</th>
                      <td width="40%">
                        <Form.Check
                          type="radio"
                          label={clientOne.client_name}
                          value={clientOne.client_id}
                          onChange={valueClientIdChange}
                          name="grpMaster"
                          required
                        />
                      </td>
                      <td width="40%">
                        <Form.Check
                          type="radio"
                          label={clientTwo.client_name}
                          value={clientTwo.client_id}
                          onChange={valueClientIdChange}
                          name="grpMaster"
                          required
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div>
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Similar Fields (Auto Merging)
                      </Accordion.Header>
                      <Accordion.Body>
                        <Table>
                          <tbody>
                            {Object.keys(mergeClientSimilar).map((key, index) => {
                              var nameObj = "";
                              var dataObj = "";

                              if (key === "name") {
                                nameObj = "Company Name";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "incorporated") {
                                nameObj = "Incorporated";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "headquarters") {
                                nameObj = "Corporate HQ";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "client_has_user") {
                                nameObj = "Consultant Name";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "industry_detail") {
                                nameObj = "Industry";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "address") {
                                nameObj = "Address";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "general_telephone") {
                                nameObj = "General Telephone";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "website_url") {
                                nameObj = "Website";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "linkedln_url") {
                                nameObj = "LinkedIn Profile";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "revenue") {
                                nameObj = "Revenue";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "total_employees") {
                                nameObj = "Employees";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "overall_background") {
                                nameObj = "Overall Background";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "important_note") {
                                nameObj = "Important Notes";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "status") {
                                nameObj = "Status";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "finance_certificate") {
                                nameObj = "Finance Certificate";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "is_parent") {
                                nameObj = "Parent";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else if (key === "parent_id") {
                                nameObj = "Parent Company";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              } else {
                                nameObj = "Reason Blacklist";
                                dataObj = mergeClientSimilar[key]
                                  ? mergeClientSimilar[key]
                                  : "-";
                              }

                              return (
                                <div key={index}>
                                  <tr>
                                    <th>{nameObj}</th>
                                    <td>{dataObj}</td>
                                  </tr>
                                </div>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Field Selection</Accordion.Header>
                      <Accordion.Body>
                        <Table>
                          <tbody>
                            {mergeClientNoSimilar.map((data, index) => {
                              var nameObj = "";
                              var dataCandOne = "";
                              var dataCandTwo = "";

                              if (data.name === "name") {
                                nameObj = "Company Name";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "incorporated") {
                                nameObj = "Incorporated";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "headquarters") {
                                nameObj = "Corporate HQ";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "consultant_detail") {
                                nameObj = "Consultant Name";
                                dataCandOne =
                                  data.candOne.length > 0
                                    ? data.candOne.map((s) =>
                                        s.last_name
                                          ? s.first_name + " " + s.last_name
                                          : s.first_name
                                      )
                                    : "-";
                                dataCandTwo =
                                  data.candTwo.length > 0
                                    ? data.candTwo.map((s) =>
                                        s.last_name
                                          ? s.first_name + " " + s.last_name
                                          : s.first_name
                                      )
                                    : "-";
                                // dataValueOne = data.candOne.length > 0 ? data.candOne.map((s) => s.user.id) : "-";
                              } else if (data.name === "industry_detail") {
                                nameObj = "Industry";
                                dataCandOne =
                                  data.candOne.length > 0
                                    ? data.candOne.map((s) => s.code).toString()
                                    : "-";
                                dataCandTwo =
                                  data.candTwo.length > 0
                                    ? data.candTwo.map((s) => s.code).toString()
                                    : "-";
                              } else if (data.name === "address") {
                                nameObj = "Address";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "general_telephone") {
                                nameObj = "General Telephone";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "website_url") {
                                nameObj = "Website";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "linkedln_url") {
                                nameObj = "LinkedIn Profile";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "revenue") {
                                nameObj = "Revenue";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "total_employees") {
                                nameObj = "Employees";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "overall_background") {
                                nameObj = "Overall Background";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "important_note") {
                                nameObj = "Important Notes";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "status") {
                                nameObj = "Status";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "is_parent") {
                                nameObj = "Parent";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "finance_certificate") {
                                nameObj = "Finance Certificate";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "reason_blacklist") {
                                nameObj = "Reason Blacklist";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              } else if (data.name === "parent_id") {
                                nameObj = "Parent Company";
                                dataCandOne = data.candOne ? data.candOne : "-";
                                dataCandTwo = data.candTwo ? data.candTwo : "-";
                              }
                              // else{
                              //   nameObj = "No";
                              //   dataCandOne = data.candOne ? data.candOne : "-";
                              //   dataCandTwo = data.candTwo ? data.candTwo : "-";
                              // }

                              const valueClientObject = (data) => (e) => {
                                const str = e.target.value;

                                const property = str.substring(
                                  0,
                                  str.indexOf(" ")
                                ); // "72"

                                if (property === "industry_detail") {
                                  const industryID = data.map((s) => s.id);

                                  Object.assign(combineClient, {
                                    industry: industryID,
                                  });
                                } else if (property === "consultant_detail") {
                                  const consultID = data.map((s) => s.id);

                                  Object.assign(combineClient, {
                                    consultant_name: consultID,
                                  });
                                } else {
                                  const dataValue = str.substring(
                                    str.indexOf(" ") + 1
                                  );

                                  Object.assign(combineClient, {
                                    [property]: dataValue,
                                  });
                                }

                                console.log("setCombineCand", combineClient);
                              };

                              return (
                                <tr key={index}>
                                  <th>{nameObj}</th>
                                  <td>
                                    <Form.Check
                                      type="radio"
                                      label={dataCandOne}
                                      name={index}
                                      value={data.name + " " + data.candOne}
                                      onChange={valueClientObject(data.candOne)}
                                      required
                                    />
                                  </td>
                                  <td>
                                    <Form.Check
                                      type="radio"
                                      label={dataCandTwo}
                                      name={index}
                                      value={data.name + " " + data.candTwo}
                                      onChange={valueClientObject(data.candTwo)}
                                      required
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <Table>
                  <tbody>
                    <tr>
                      <td colSpan={3}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="Proposal"
                            value="proposal"
                            onChange={valueClientCheck}
                          />
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="Meetings"
                            value="meetings"
                            onChange={valueClientCheck}
                          />
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="Staff"
                            value="staffs"
                            onChange={valueClientCheck}
                          />
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="Files/Documents"
                            value="documents"
                            onChange={valueClientCheck}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                {/*  */}
              </Form>
            </>
          }
          button={
            <>
              <Button variant="secondary" onClick={btnCloseMerge}>
                Cancel
              </Button>
              <Button variant="primary" type="submit" form="client_form">
                Merge
              </Button>
            </>
          }
        />
      </div>
    </>
  );
}

export default ListPotentialClients;
