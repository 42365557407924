import React from 'react';
// import CurrencyFormat from 'react-currency-format';
import { NumericFormat } from 'react-number-format';

function TextFieldFormat({
    value,
    thousandSeparator,
    format,
    onChange,
    defaultValue,
    name, 
    renderText,
    onValueChange,
    isInvalid,
    errorText,
}) {

    return (
        <>
            {
                isInvalid === true ? 
                    <NumericFormat
                        className="input-currency-error"
                        value={value}
                        defaultValue={defaultValue}
                        thousandSeparator={thousandSeparator} 
                        onChange={onChange}
                        onValueChange={onValueChange}
                        name={name}
                        renderText={renderText}
                        format={format}
                    />

                    // <CurrencyFormat 
                    //     className='input-currency-error' 
                    //     value={value} 
                    //     defaultValue={defaultValue}
                    //     format={format}
                    //     thousandSeparator={thousandSeparator} 
                    //     onChange={onChange}
                    //     onValueChange={onValueChange}
                    //     name={name}
                    //     renderText={renderText}
                    // />
                    :
                    <NumericFormat
                        className="input-currency"
                        value={value}
                        defaultValue={defaultValue}
                        thousandSeparator={thousandSeparator} 
                        onChange={onChange}
                        onValueChange={onValueChange}
                        name={name}
                        renderText={renderText}
                        format={format}
                    />
                    // <CurrencyFormat 
                    //     className='input-currency' 
                    //     value={value} 
                    //     defaultValue={defaultValue}
                    //     format={format}
                    //     thousandSeparator={thousandSeparator} 
                    //     onChange={onChange}
                    //     onValueChange={onValueChange}
                    //     name={name}
                    //     renderText={renderText}
                    // />
            }
            
            {isInvalid === true ? <div className="invalid-dropdown">{errorText}</div> : null }
        </>
        
    )
}

export default TextFieldFormat