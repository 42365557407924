import React, {useEffect} from "react";
import { useDispatch } from "react-redux";
import { Tab, Nav } from "react-bootstrap";
// import Api from "../../../core/services/api.service";

import { mdiCog } from "@mdi/js";

import {getUserProfile} from "../../../store/actions/userProfileSlice";
import {storeTitle} from "../../../store/actions/headerTitleSlice";

import { TabChangePassword } from "../../../components/Tab/TabChangePassword";
import { TabPersonalDetail } from "../../../components/Tab/TabPersonalDetail";


function Settings() {

  const dispatch = useDispatch();

  const handleSelectTab = () => {
    dispatch(getUserProfile());
  };

  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(storeTitle({title: "Settings", icon: mdiCog}));
  },[dispatch]);

  return (
    <div className="main-div">
      <div className="top-title-div">
        <Tab.Container id="left-tabs-example" 
          defaultActiveKey="first"
          onSelect={handleSelectTab}
        >
          <div className="nav-container-nf">
            <Nav variant="tabs" justify>
              <Nav.Item>
                <Nav.Link eventKey="first">Personal Details</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Change Password</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          <Tab.Content>
            <Tab.Pane eventKey="first">
              <TabPersonalDetail></TabPersonalDetail>
            </Tab.Pane>

            <Tab.Pane eventKey="second">
              <TabChangePassword></TabChangePassword>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
}

export default Settings;
