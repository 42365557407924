import React from "react";
import Select from "react-select";
import "./DropDown.css";
// import styled from 'styled-components';
// const SIZES = ["select--large","select--medium", "select--small"];

function MultiSelect({
  options,
  isMulti,
  value,
  onChange,
  placeholder,
  errorText,
  name,
  isInvalid,
  defaultValue,
  isSearchable,
  getOptionValue,
  getOptionLabel,
  isReadOnly,
  menuIsOpen
}) {
  // const [error, setError] =useState(required);

  const colourStyles = {
    control: (provided, state) => ({
      ...provided,
      "*": {
        boxShadow: "none !important",
      },
      width: "100%",
      minHeight: "30px",
      // minHeight: 'calc(1.5em + 0.5rem + 2px)',
      boxShadow: state.isFocused ? "0 0 3px #EC782F" : provided.borderColor,
      borderColor: state.isFocused ? "#EC782F" : provided.borderColor,
      borderRadius: "10px",
      fontSize: "14px",

      "&:hover": {
        borderColor: state.isFocused ? "#EC782F" : provided.borderColor,
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#212529",
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isFocused ? "#467FD0" : "white",
      color: state.isFocused ? "white" : "black",
      fontSize: "14px",

      "&:hover": {
        backgroundColor: state.isSelected ? "none" : "#467FD0",
        color: "white",
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: "5px 10px",
      color: "#212529",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };

  const colourStylesError = {
    control: (base, state) => ({
      ...base,
      "*": {
        boxShadow: "none !important",
      },
      width: "100%",
      // height: 10,
      minHeight: "30px",
      boxShadow: state.isFocused ? "0 0 0px #dc3545" : null,
      borderColor: state.isFocused ? "#dc3545" : "#dc3545",
      borderRadius: "10px",
      fontSize: "14px",

      "&:hover": {
        borderColor: state.isFocused ? "#EC782F" : null,
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#212529",
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isFocused ? "#467FD0" : "white",
      color: state.isFocused ? "white" : "black",
      fontSize: "14px",
      zIndex: 1,
      "&:hover": {
        backgroundColor: state.isSelected ? "none" : "#467FD0",
        color: "white",
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: "5px 10px",
      color: "#212529",
    }),
    indicatorSeparator: () => ({
      display: "none",
    })
  };

  // const [colorStyle, setColorStyle] = useState(colourStylesError);

  const menuIsOpenIt = isReadOnly ? false : menuIsOpen;

  return (
    <div>
      {isInvalid === true ? (
        <Select
          name={name}
          isMulti={isMulti}
          styles={colourStylesError}
          options={options}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          isSearchable={isSearchable}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          menuIsOpen={menuIsOpenIt}
        />
      ) : (
        <Select
          name={name}
          isMulti={isMulti}
          styles={colourStyles}
          options={options}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          isSearchable={isSearchable}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          menuIsOpen={menuIsOpenIt}
        />
      )}

      {isInvalid === true ? (
        <div className="invalid-dropdown">{errorText}</div>
      ) : null}
      {/* {required === "valid" ? 
              <Select 
                  name={name}
                  isMulti={isMulti} 
                  styles={colourStylesValid} 
                  options={options}
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange}
              />
            : required === "invalid" ? 
                <Select 
                    name={name}
                    isMulti={isMulti} 
                    styles={colourStylesError} 
                    options={options}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                />

      {required === "invalid" ? (
        <div className="invalid-dropdown">{errorText}</div>
      ) : null}

                <Select 
                    name={name}
                    isMulti={isMulti} 
                    styles={colourStyles} 
                    options={options}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                />
            }
            
            {required === "invalid" ? <div className="invalid-dropdown">{errorText}</div> : null } */}

      {/* {error && <span className="was-validated">{error === true ? `Please select ${props.title}.` : props.errorMsg}</span>} */}
      {/* <span className="was-validated">{error}</span> */}
    </div>
  );
}

export default MultiSelect;
