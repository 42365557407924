import React from 'react';
import { useTable, usePagination, useSortBy, useRowSelect } from 'react-table';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import './Table.css';

export const TableFunction = ({headers, items}) => {

    const columns = headers;
    // useMemo(() => headers, [])
    const data = items ;

    const tableInstance = useTable(
        {
            columns,
            data,
        },
        useSortBy,
        usePagination,
        useRowSelect,
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        // rows,
        prepareRow,
        page,
        // canPreviousPage,
        // canNextPage,
        // pageOptions,
        // pageCount,
        // gotoPage,
        // nextPage,
        // previousPage,
        // setPageSize,
        // setSortBy,
        // state: { pageIndex, pageSize, selectedRowIds },
    } = tableInstance
    
    return(
        <>
                <table className='table-list_tblfunction' {...getTableProps()} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                // Add the sorting props to control sorting. For this example
                                // we can add them into the header props
                                <th className='header_text_tblfunction' {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                {/* Add a sort direction indicator */}
                                <span>
                                    {column.isSorted
                                    ? column.isSortedDesc
                                        ? <Icon path={mdiChevronDown} size={1}/>
                                        // ' 🔽'
                                        : <Icon path={mdiChevronUp} size={1}/>
                                        // ' 🔼'
                                    : ''}
                                </span>
                                </th>
                            ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody className="tbody" {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                            <tr  className='trow_tblfunction' {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td className="body_text_tblfunction" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                            )
                        })}
                    </tbody>
                </table>
                
              
           
        </>
    )


}

export default TableFunction
