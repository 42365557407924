export const rptProgress = (props) => {

    const progressBody = `
    <html>
        <head></head>
        <body>
            <div>  
                <p style="text-align:center; font-size:14.0pt"><b>PROGRESS REVIEW AGENDA</b></p>
                <br>
                <p style='margin-bottom:0cm;line-height:0.2pt;text-autospace:none; font-size:11.0pt;'>Date: ` + props.created_at + `</p>
                <br>
                <p style='margin-bottom:0cm;line-height:0.2pt;text-autospace:none; font-size:11.0pt;'>Parties Involved</p>
                <br>
                <p style='margin-bottom:0cm;line-height:0.2pt;text-autospace:none; font-size:11.0pt; font-weight:bold;'><u>Client</u></p>
                <br>
                ` + props.clientList.map((client) => 
                    `<p>`+ client.name + `<br>` + client.job_title + `<br>` + client.company + `</p>`
                )
                + `
                <br>
                <p style='margin-bottom:0cm;line-height:0.2pt;text-autospace:none; font-size:11.0pt; font-weight:bold;'><u>Lim-Loges & Masters</u></p>
                <br>`+ props.llmList.map((admin) => 
                    `<p>`+ admin.name + `</p>`
                )
                + `
                <br>
                <p style='margin-bottom:0cm;line-height:0.2pt;text-autospace:none; font-size:11.0pt; font-weight:bold;'>Key Milestones</p>
                <br>`+ props.key_milestone +`
                <br>
                <p style='margin-bottom:0cm;line-height:0.2pt;text-autospace:none; font-size:11.0pt; font-weight:bold;'>Progress</p>
                <br>` + props.progress + `
                <br>
                <p style='margin-bottom:0cm;line-height:0.2pt;text-autospace:none; font-size:11.0pt; font-weight:bold;'>Market Feedback</p>
                <br>` + props.market_feedback + `
                <br>
                <p style='margin-bottom:0cm;line-height:0.2pt;text-autospace:none; font-size:11.0pt; font-weight:bold;'>Candidate profiles (information provided as additional documents)</p>
                <br>` + props.candidateList.map((candidate) => 
                `<p>`+ candidate.name + `<br>` + candidate.job_title + `<br>` + candidate.company + `, `+  candidate.location + `</p>`
                )+ `
                <br>
                <p style='margin-bottom:0cm;line-height:0.2pt;text-autospace:none; font-size:11.0pt; font-weight:bold;'>Objectives of the call:</p>
                <ul>
                    <li>Align targets and objectives of the search.</li>
                    <li>Refine target strategies and confirm criteria.</li>
                    <li>Calibrate profiles presented and discuss emerging profiles.</li>
                    <li>Discuss process and timelines.</li>
                    <li>Any other comments.</li>
                </ul>
                <p style='margin-bottom:0cm;line-height:0.2pt;text-autospace:none; font-size:11.0pt;'>We do not reveal profiles which are not qualified against your requirements.</p>
                <br>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                margin-left:183.0pt;line-height:99%;text-autospace:none'><b><span
                style='font-size:11.0pt;line-height:99%'>*****************</span></b></p>

            </div>
        </body>
    
    </html>
    `
    
    return progressBody
}

export default rptProgress;