import React, { useState } from "react";
import { Form, Row, Button } from "react-bootstrap";
import Api from "../../core/services/api.service";

import PasswordChecklist from "react-password-checklist";
import SwalAlert from "../Swal/SwalAlert";

export const TabChangePassword = () => {
  const customSwal = SwalAlert();

  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [validPassword, setValidPassword] = useState(false);

  const [errorsPass, setErrorsPass] = useState({});

  const formErrorsPassword = (object) => {
    const newPassErrors = {};

    // console.log("object", object);

    if (object === "Old password incorrect!")
      newPassErrors.txtCurrentPassword = "Fill in the blank!";

    if (!object.txtCurrentPassword || object.txtCurrentPassword === "")
      newPassErrors.txtCurrentPassword = "Fill in the blank!";

    if (!object.txtNewPassword || object.txtNewPassword === "")
      newPassErrors.txtNewPassword = "Fill in the blank!";

    if (!object.txtConfirmPassword || object.txtConfirmPassword === "")
      newPassErrors.txtConfirmPassword = "Fill in the blank!";

    return newPassErrors;
  };

  const formErrorsCurrent = (object) => {
    const newPassErrors = {};

    // console.log("object", object);

    if (object === "Old password incorrect!")
      newPassErrors.txtCurrentPassword = "Current Password Incorrect!";

    return newPassErrors;
  };

  const btnUpdatePassword = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtPassword = Object.fromEntries(data.entries());

    const newErrors = formErrorsPassword(dtPassword);

    if (Object.keys(newErrors).length > 0) {
      setErrorsPass(newErrors);
    } else {
      if (validPassword === true) {
        customSwal
          .fire({
            title: "Do you want to update new password?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post("user/updatePassword", {
                old_password: dtPassword.txtCurrentPassword,
                new_password: dtPassword.txtNewPassword,
              })
                .then((response) => {
                  customSwal.fire({
                    title: "Update Successfully!",
                    icon: "success",
                    confirmButtonText: "Okay",
                  });
                  setErrorsPass({});
                  document.getElementById("password-staf-form").reset();
                  setValidPassword(false);
                  setPassword("");
                  setPasswordAgain("");
                })
                .catch((error) => {
                  console.log("Error update password", error);

                  const newErrorsCurrent = formErrorsCurrent(
                    error.response.data.message
                  );

                  // console.log("newErrorsCurrent", newErrorsCurrent)
                  setErrorsPass(newErrorsCurrent);
                  // customSwal.fire({
                  //     title: "Current Password Incorrect",
                  //     icon: "error",
                  //     confirmButtonText: "Okay",
                  // });
                });
            }
          });
      }
    }
  };

  const btnDiscard = (e) => {
    setErrorsPass({});
    document.getElementById("password-staf-form").reset();
    setValidPassword(false);
    setPassword("");
    setPasswordAgain("");
  };

  return (
    <>
      <div className="info-container">
        <Form id="password-staf-form" onSubmit={btnUpdatePassword}>
          <Row className="chg-pw">
            <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
              <Form.Label className="form-setting-label">
                Current Password
              </Form.Label>
              <Form.Control
                name="txtCurrentPassword"
                type="password"
                placeholder="Please enter your current password"
                isInvalid={!!errorsPass.txtCurrentPassword}
              />
              <Form.Control.Feedback type="invalid">
                {errorsPass.txtCurrentPassword}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="chg-pw">
            <Form.Group className="mb-3 col-6" controlId="formBasicPassword">
              <Form.Label className="form-setting-label">
                New Password
              </Form.Label>
              <Form.Control
                name="txtNewPassword"
                type="password"
                placeholder="Please enter your new password"
                onChange={(e) => setPassword(e.target.value)}
                isInvalid={!!errorsPass.txtNewPassword}
              />
              <Form.Control.Feedback type="invalid">
                {errorsPass.txtNewPassword}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 col-6" controlId="formBasicPassword">
              <Form.Label className="form-setting-label">
                Confirm Password
              </Form.Label>
              <Form.Control
                name="txtConfirmPassword"
                type="password"
                placeholder="Please confirm your new password"
                onChange={(e) => setPasswordAgain(e.target.value)}
                isInvalid={!!errorsPass.txtConfirmPassword}
              />
              <Form.Control.Feedback type="invalid">
                {errorsPass.txtConfirmPassword}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <div className="new-pw-note">
            In order to <b>protect your account</b>, make sure your password:
            <PasswordChecklist
              className="pw-checklist"
              rules={[
                "minLength",
                "capital",
                "lowercase",
                "number",
                "specialChar",
                "match",
              ]}
              minLength={8}
              value={password}
              valueAgain={passwordAgain}
              onChange={(isValid) => {
                setValidPassword(isValid);
              }}
              messages={{
                minLength: "has minimum of 8 characters.",
                capital: "include at least one uppercase letter (A-Z).",
                lowercase: "include at least one lowercase letter (a-z).",
                number: "include at least one number (1-9).",
                specialChar:
                  "include at least one special character (e.g. !, @, #, $).",
                match: "is match with confirm password.",
              }}
            />
          </div>

          <div className="row-btn-bottom">
            <Button variant="secondary" onClick={btnDiscard}>
              Discard
            </Button>
            <Button variant="primary" type="submit" form="password-staf-form">
              Save Changes
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};
