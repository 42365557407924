import React, { useState, useEffect, useCallback } from "react";
import { Card, Form, Button, Dropdown } from "react-bootstrap";
import Api from "../../../core/services/api.service";
import { useSelector } from "react-redux";

import SwalAlert from "../../Swal/SwalAlert";
import moment from "moment";

import Icon from "@mdi/react";
import {
  mdiAlertBox,
  mdiCalendar,
  mdiDotsHorizontal,
  mdiEye,
  mdiBriefcase,
  mdiOfficeBuilding,
  mdiAccount,
  mdiEmail,
  mdiClock,
} from "@mdi/js";

import CustomTable from "../../Table/Table";
import CardInfo from "../../Card/CardInfo";
import Popup from "../../Popup/ModalPopup";
// import Empty from "../../Empty/Empty";

export const TabInterview = () => {
  const { user } = useSelector((state) => state.login);

  const customSwal = SwalAlert();

  const [viewDetails, setViewDetails] = useState({
    title: "",
    comp_name: "",
    date_inv: "",
    time_inv: "",
    consultant_name: "",
    consultant_email: "",
    interviewer_name: "",
    interviewer_email: "",
  });

  const [showRescheduleTable, setShowRescheduleTable] = useState(false);
  const [errorFormTable, setErrorFormTable] = useState({});

  const headerInterview = [
    {
      Header: "Job Title",
      accessor: "job_title",
      maxWidth: 300,
      minWidth: 100,
      width: 300,
      Cell: ({ row }) => {
        const data = row.original;

        const job_title = data.assignments.title;

        return <>{job_title}</>;
      },
    },
    {
      Header: "Company Name",
      accessor: "comp_name",
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        const comp_name = data.assignments.clients.name;

        return <>{comp_name}</>;
      },
    },
    {
      Header: "Date & Time",
      accessor: "date",
      maxWidth: 450,
      minWidth: 100,
      width: 450,
      Cell: ({ row }) => {
        const data = row.original;

        const date_inv = data.interview_date
          ? moment(data.interview_date).format("DD MMMM YYYY")
          : "-";

        const time_inv = data.interview_time
          ? moment(data.interview_time, ["HH:mm"]).format("hh:mm A") +
            " - " +
            moment(new Date("1970-01-01 " + data.interview_time))
              .add(Number(data.duration), "minutes")
              .format("hh:mm A")
          : "-";

        return (
          <>
            <b>{date_inv}</b> <br /> {time_inv}
          </>
        );
      },
    },
    // {
    //   Header: "Time",
    //   accessor: "time",
    //   maxWidth: 400,
    //   minWidth: 100,
    //   width: 200,
    //   Cell: ({ row }) => {
    //     const data = row.original;

    //     const time_inv = data.interview_time
    //       ? moment(data.interview_time, ["HH:mm"]).format("hh:mm A") +
    //         " - " +
    //         moment(new Date("1970-01-01 " + data.interview_time))
    //           .add(Number(data.duration), "minutes")
    //           .format("hh:mm A")
    //       : "-";

    //     return <>{time_inv}</>;
    //   },
    // },
    {
      Header: "Interviewer Staff Name",
      accessor: "interviewer",
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        var array = data.interview_has_staff.map((obj, index) => {
          return obj.candidate.first_name + " " + obj.candidate.last_name + ",";
        });

        var staff_client = array.join(" ");

        return <div>{staff_client ? staff_client.slice(0, -1) : "-"}</div>;
      },
    },
    {
      Header: "Interviewer Staff LLM Name",
      accessor: "interviewer_2",
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        var array = data.interview_has_admin.map((obj, index) => {
          return obj.user.first_name + " " + obj.user.last_name + ",";
        });

        var staff_admin = array.join(" ");

        return <div>{staff_admin ? staff_admin.slice(0, -1) : "-"}</div>;
      },
    },
    {
      Header: "Status",
      maxWidth: 150,
      minWidth: 150,
      width: 120,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <>
            {data.status === "rejected" ? (
              <CardInfo
                cardTextSize="card_text--small"
                cardTextStyle="card_text--danger--solid"
              >
                {data.status}
              </CardInfo>
            ) : (
              <CardInfo
                cardTextSize="card_text--small"
                cardTextStyle="card_text--completed--solid"
              >
                {data.status}
              </CardInfo>
            )}
          </>
        );
      },
    },
    {
      Header: "",
      accessor: "actions",
      maxWidth: 100,
      minWidth: 80,
      width: 80,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={btnViewDetails(data.id)}>
                  <Icon path={mdiEye} size={0.8} color="#467FD0" />
                  View Details
                </Dropdown.Item>
                {/* <Dropdown.Item
                  onClick={btnRescheduleTable(data.id, data.assignment_id)}
                >
                  <Icon path={mdiPencil} size={0.8} color="#467FD0" />
                  Reschedule Interview
                </Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const btnViewDetails = (id) => (e) => {
    const filteredInv = tableListInterviews.filter((interview) => {
      return interview.id === id;
    });
    // console.log("filteredInv", filteredInv);

    var array = filteredInv[0].interview_has_admin.map((obj, index) => {
      return obj.user.first_name + " " + obj.user.last_name + ",";
    });

    var staff_admin = array.join(" ");

    var array_email = filteredInv[0].interview_has_admin.map((obj, index) => {
      return obj.user.email + ",";
    });

    var staff_admin_email = array_email.join(" ");

    var array_staff = filteredInv[0].interview_has_staff.map((obj, index) => {
      return obj.candidate.first_name + " " + obj.candidate.last_name + ",";
    });

    var array_staff_email = filteredInv[0].interview_has_staff.map(
      (obj, index) => {
        return obj.candidate.comp_email + ",";
      }
    );

    var staff_candidate = array_staff.join(" ");

    var staff_candidate_email = array_staff_email.join(" ");

    setViewDetails({
      title: filteredInv[0].assignments.title,
      comp_name: filteredInv[0].assignments.clients.name,
      date_inv: filteredInv[0].interview_date
        ? moment(filteredInv[0].interview_date).format("DD MMMM YYYY")
        : "-",
      time_inv: filteredInv[0].interview_time
        ? moment(filteredInv[0].interview_time, ["HH:mm"]).format("hh:mm A") +
          " - " +
          moment(new Date("1970-01-01 " + filteredInv[0].interview_time))
            .add(Number(filteredInv[0].duration), "minutes")
            .format("hh:mm A")
        : "-",
      consultant_name: staff_admin ? staff_admin.slice(0, -1) : "-",
      consultant_email: staff_admin_email
        ? staff_admin_email.slice(0, -1)
        : "-",
      interviewer_name: staff_candidate ? staff_candidate.slice(0, -1) : "-",
      interviewer_email: staff_candidate_email
        ? staff_candidate_email.slice(0, -1)
        : "-",
    });

    setShowDetails(true);
  };

  const btnCloseView = () => {
    setShowDetails(false);
  };

  // const btnRescheduleTable = (id, idassign) => (e) => {
  //   setErrorFormTable({});
  //   setShowRescheduleTable(true);
  //   setIdAssignInv(idassign);
  //   setIdInv(id);
  // };

  const btnCloseRescheduleTable = (e) => {
    setShowRescheduleTable(false);
  };

  const formErrorTable = (object) => {
    const newErrors = {};

    if (!object.txtCommentRescTable || object.txtCommentRescTable === "")
      newErrors.txtCommentRescTable = "Fill in the blank!";

    return newErrors;
  };

  const btnUpdateRescheduleTable = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtPro = Object.fromEntries(data.entries());
    const newErrors = formErrorTable(dtPro);

    if (Object.keys(newErrors).length > 0) {
      setErrorFormTable(newErrors);
    } else {
      customSwal
        .fire({
          title: "Do you want to reschedule this interview request?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Api.patch(
              "candidate/" +
                user.sub +
                "/assignment/" +
                idAssignInv +
                "/interview/" +
                idInv +
                "/updateStatusIv",
              {
                status: "reschedule",
                interview_date: "",
                interview_time: "",
                reason: dtPro.txtCommentRescTable,
              }
            )
              .then((response) => {
                // console.log("option/store", response.data);
                setShowRescheduleTable(false);
                getListInterviews();
              })
              .catch((error) => {
                console.log("error btnConfirmDate", error);
              });
          }
        });
    }
  };

  const [listInterviews, setListInterviews] = useState([]);
  const [tableListInterviews, setTableListInterviews] = useState([]);

  const [showReschedule, setShowReschedule] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const [idAssignInv, setIdAssignInv] = useState(0);
  const [idInv, setIdInv] = useState(0);
  const [errorForm, setErrorForm] = useState({});

  const getListInterviews = useCallback(() => {
    Api.get("candidate/" + user.sub + "/assignment/5/interview/list/created")
      .then((response) => {
        let data = response.data;

        if (data.length > 0) {
          let listInvCreated = data.filter((inv) => inv.status === "created");

          setListInterviews(listInvCreated);

          let listInvTable = data.filter((inv) => inv.status !== "created");

          setTableListInterviews(listInvTable);
        }
      })
      .catch((error) => {
        console.log("error getListInterviews Candidate Site", error);
      });
  }, [user.sub]);

  useEffect(() => {
    getListInterviews();
    // setInterval(() => {
    //   getListInterviews();
    // }, 5000);
  }, [getListInterviews]);

  return (
    <>
      {listInterviews.length > 0 ? (
        <Card
          style={{ marginBottom: "20px", maxHeight: "310px", overflow: "auto" }}
          className="inv-cand"
        >
          <Card.Body>
            <div className="meeting-header">
              <Icon path={mdiAlertBox} size={1} color="#DF4759" />
              <div className="sub-text" style={{ color: "#DF4759" }}>
                You have <b>{listInterviews.length}</b> interview request!
              </div>
            </div>

            <div className="iv-scroll">
              {listInterviews.map((interv, index) => {
                let selectedDate = "";
                let selectedTime = "";
                let errorDate = "";

                const btnConfirmDate = (idassign, id, date, time) => (e) => {
                  if (interv.interview_date_time.length > 0) {
                    if (!selectedDate || selectedDate === "") {
                    } else {
                      customSwal
                        .fire({
                          title:
                            "Do you want to accept this interview request?",
                          icon: "warning",
                          showCancelButton: true,
                          reverseButtons: true,
                          confirmButtonText: "Yes",
                        })
                        .then((result) => {
                          if (result.isConfirmed) {
                            Api.patch(
                              "candidate/" +
                                user.sub +
                                "/assignment/" +
                                idassign +
                                "/interview/" +
                                id +
                                "/updateStatusIv",
                              {
                                status: "accepted",
                                interview_date: selectedDate,
                                interview_time: selectedTime,
                                reason: "",
                              }
                            )
                              .then((response) => {
                                // console.log("option/store", response.data);
                                getListInterviews();
                              })
                              .catch((error) => {
                                console.log("error btnConfirmDate", error);
                              });
                          }
                        });
                    }
                  } else {
                    customSwal
                      .fire({
                        title: "Do you want to accept this interview request?",
                        icon: "warning",
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: "Yes",
                      })
                      .then((result) => {
                        if (result.isConfirmed) {
                          Api.patch(
                            "candidate/" +
                              user.sub +
                              "/assignment/" +
                              idassign +
                              "/interview/" +
                              id +
                              "/updateStatusIv",
                            {
                              status: "accepted",
                              interview_date: moment(date).format("YYYY-MM-DD"),
                              interview_time: time,
                              reason: "",
                            }
                          )
                            .then((response) => {
                              // console.log("option/store", response.data);
                              getListInterviews();
                            })
                            .catch((error) => {
                              console.log("error btnConfirmDate", error);
                            });
                        }
                      });
                  }

                  // const newErrors = formError(dtPro);
                };

                const btnDecline = (idassign, id) => (e) => {
                  customSwal
                    .fire({
                      title: "Do you want to decline this interview request?",
                      icon: "warning",
                      showCancelButton: true,
                      reverseButtons: true,
                      confirmButtonText: "Yes",
                    })
                    .then((result) => {
                      if (result.isConfirmed) {
                        Api.patch(
                          "candidate/" +
                            user.sub +
                            "/assignment/" +
                            idassign +
                            "/interview/" +
                            id +
                            "/updateStatusIv",
                          {
                            status: "rejected",
                            interview_date: "",
                            interview_time: "",
                            reason: "",
                          }
                        )
                          .then((response) => {
                            // console.log("option/store", response.data);
                            getListInterviews();
                          })
                          .catch((error) => {
                            console.log("error btnConfirmDate", error);
                          });
                      }
                    });
                };

                const formError = (object) => {
                  const newErrors = {};

                  if (
                    !object.txtCommentReschedule ||
                    object.txtCommentReschedule === ""
                  )
                    newErrors.txtCommentReschedule = "Fill in the blank!";

                  return newErrors;
                };

                const btnUpdateReschedule = (e) => {
                  e.preventDefault();

                  const data = new FormData(e.target);
                  const dtPro = Object.fromEntries(data.entries());
                  const newErrors = formError(dtPro);

                  // console.log("user.sub", user.sub);
                  if (Object.keys(newErrors).length > 0) {
                    setErrorForm(newErrors);
                  } else {
                    customSwal
                      .fire({
                        title:
                          "Do you want to reschedule this interview request?",
                        icon: "warning",
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: "Yes",
                      })
                      .then((result) => {
                        if (result.isConfirmed) {
                          Api.patch(
                            "candidate/" +
                              user.sub +
                              "/assignment/" +
                              idAssignInv +
                              "/interview/" +
                              idInv +
                              "/updateStatusIv",
                            {
                              status: "reschedule",
                              interview_date: "",
                              interview_time: "",
                              reason: dtPro.txtCommentReschedule,
                            }
                          )
                            .then((response) => {
                              // console.log("option/store", response.data);
                              setShowReschedule(false);
                              getListInterviews();
                            })
                            .catch((error) => {
                              console.log("error btnConfirmDate", error);
                            });
                        }
                      });
                  }
                };

                const btnReschedule = (idassign, id) => (e) => {
                  setShowReschedule(true);
                  setErrorForm({});
                  setIdAssignInv(idassign);
                  setIdInv(id);
                };

                const btnCloseReschedule = () => {
                  setShowReschedule(false);
                };

                return (
                  <div key={index} className="iv-cdt">
                    <div className="interview-section">
                      <div className="interview-column">
                        <div className="text">
                          <Icon path={mdiBriefcase} color="#EC782F" />
                          {interv.assignments.title}
                        </div>
                        <div className="sub-text">
                          <Icon path={mdiOfficeBuilding} color="#EC782F" />
                          {interv.assignments.clients.name}
                        </div>

                        {interv.interview_has_admin.map((llmSta, index) => (
                          <div key={index}>
                            <div className="text mt-3">
                              Consultant Name: {llmSta.user.first_name}{" "}
                              {llmSta.user.last_name}
                            </div>
                            <div className="sub-text">
                              {llmSta.user.email ? llmSta.user.email : ""}
                            </div>
                          </div>
                        ))}

                        {interv.interview_has_staff.map((staff, index) => (
                          <div key={index}>
                            <div className="text mt-3">
                              Interviewer Name: {staff.candidate.first_name}{" "}
                              {staff.candidate.last_name}
                            </div>
                            <div className="sub-text">
                              {staff.candidate.comp_email
                                ? staff.candidate.comp_email
                                : ""}
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="interview-column">
                        <div className="sub-text mb-2">
                          <Icon path={mdiCalendar} color="#EC782F" />
                          Date & Time:
                        </div>
                        {interv.interview_date_time.length > 0 ? (
                          <>
                            <div>
                              {interv.interview_date_time.map(
                                (intDate, index) => {
                                  const hms = intDate.interview_time;
                                  const target = new Date("1970-01-01 " + hms);

                                  // const selectedDate = "";

                                  const timeToDuration = new Date(
                                    target.getTime() +
                                      Number(interv.duration) * 60000
                                  );

                                  const convertToTime =
                                    timeToDuration.toLocaleString("en-GB", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    });

                                  const handleRBDate = (event, param1) => {
                                    let value = event.target.value;
                                    // console.log("event.", event.target.value)
                                    selectedDate = value.substr(0, 10);
                                    selectedTime = value.substr(
                                      value.length - 5
                                    );
                                  };

                                  return (
                                    <>
                                      <div key={index}>
                                        {/* <Form id="dateTime-form"> */}
                                        <Form.Check
                                          inline
                                          label={
                                            moment(
                                              intDate.interview_date
                                            ).format("DD MMMM YYYY") +
                                            ", " +
                                            moment(intDate.interview_time, [
                                              "HH:mm",
                                            ]).format("hh:mm A") +
                                            " - " +
                                            moment(convertToTime, [
                                              "HH:mm",
                                            ]).format("hh:mm A")
                                          }
                                          name={
                                            "grpRbSelectedDate" +
                                            intDate.interview_id
                                          }
                                          value={
                                            intDate.interview_date +
                                            " " +
                                            intDate.interview_time
                                          }
                                          type="radio"
                                          onChange={(event) =>
                                            handleRBDate(event)
                                          }

                                          // checked={selectedDateTime === "2023-04-24T00:00:00.000Z 02:30"}
                                          // checked={rbRelocate === "Yes"}
                                        />
                                        {/* </Form> */}
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </div>

                            {/* ? <div className="invalid-richtext">*Please select date & time</div>: "" */}
                          </>
                        ) : (
                          <div className="inv-datetime">
                            {moment(interv.interview_date).format(
                              "DD MMMM YYYY"
                            ) +
                              ", " +
                              moment(interv.interview_time, ["HH:mm"]).format(
                                "hh:mm A"
                              ) +
                              " - " +
                              moment(
                                new Date("1970-01-01 " + interv.interview_time)
                              )
                                .add(Number(interv.duration), "minutes")
                                .format("hh:mm A")}
                          </div>
                        )}
                        <div className="invalid-richtext">{errorDate}</div>
                      </div>

                      <div className="interview-column">
                        <div className="interview-button">
                          <Button
                            variant="info"
                            style={{ boxShadow: "none" }}
                            onClick={btnConfirmDate(
                              interv.assignment_id,
                              interv.id,
                              interv.interview_date,
                              interv.interview_time
                            )}
                          >
                            Accept
                          </Button>
                          <Button
                            variant="danger"
                            onClick={btnDecline(
                              interv.assignment_id,
                              interv.id
                            )}
                          >
                            Decline
                          </Button>
                          <Button
                            style={{
                              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                            }}
                            variant="light"
                            onClick={btnReschedule(
                              interv.assignment_id,
                              interv.id
                            )}
                          >
                            Reschedule
                          </Button>
                        </div>
                      </div>
                    </div>
                    <hr />

                    <Popup
                      show={showReschedule}
                      size="md"
                      backdrop="static"
                      keyboard={false}
                      onHide={btnCloseReschedule}
                      title="Reschedule Interview"
                      content={
                        <>
                          <Form
                            id="reschedule-form"
                            onSubmit={btnUpdateReschedule}
                          >
                            <Form.Group className="mb-2">
                              <Form.Control
                                as="textarea"
                                name="txtCommentReschedule"
                                rows={4}
                                placeholder={
                                  "Please suggest few timing when will you be available for the interview."
                                }
                                isInvalid={!!errorForm.txtCommentReschedule}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errorForm.txtCommentReschedule}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Form>
                        </>
                      }
                      button={
                        <>
                          <Button
                            variant="secondary"
                            onClick={btnCloseReschedule}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="primary"
                            type="submit"
                            form="reschedule-form"
                          >
                            Submit
                          </Button>
                        </>
                      }
                    />
                  </div>
                );
              })}
            </div>
          </Card.Body>
        </Card>
      ) : (
        ""
      )}

      <Card>
        <Card.Body>
          <CustomTable
            headers={headerInterview}
            items={tableListInterviews}
            paginate={tableListInterviews.length}
            maxRows={15}
          ></CustomTable>
        </Card.Body>
      </Card>

      {/* Modal view details interview */}
      <Popup
        show={showDetails}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseView}
        title="Interview Details"
        content={
          <>
            <div className="interview-section">
              <div className="interview-column">
                <div className="text" style={{ color: "#54595F" }}>
                  <Icon path={mdiBriefcase} color="#EC782F" />
                  Job Title
                </div>
                <div className="sub-text mb-3">{viewDetails.title}</div>

                <div className="text" style={{ color: "#54595F" }}>
                  <Icon path={mdiOfficeBuilding} color="#EC782F" />
                  Company Name
                </div>
                <div className="sub-text mb-3">{viewDetails.comp_name}</div>

                <div className="text" style={{ color: "#54595F" }}>
                  <Icon path={mdiAccount} color="#EC782F" />
                  Consultant Name
                </div>
                <div className="sub-text mb-3">
                  <div>{viewDetails.consultant_name}</div>
                </div>

                <div className="text" style={{ color: "#54595F" }}>
                  <Icon path={mdiEmail} color="#EC782F" />
                  Consultant Email
                </div>
                <div className="sub-text">
                  <div>{viewDetails.consultant_email}</div>
                </div>
              </div>

              <div className="interview-column">
                <div className="text" style={{ color: "#54595F" }}>
                  <Icon path={mdiCalendar} color="#EC782F" />
                  Date:
                </div>
                <div className="inv-dt sub-text mb-3">
                  <div>{viewDetails.date_inv}</div>
                </div>

                <div className="text" style={{ color: "#54595F" }}>
                  <Icon path={mdiClock} color="#EC782F" />
                  Time:
                </div>
                <div className="inv-dt sub-text mb-3">
                  <div>{viewDetails.time_inv}</div>
                </div>

                <div className="text" style={{ color: "#54595F" }}>
                  <Icon path={mdiAccount} color="#EC782F" />
                  Interviewer Name:
                </div>
                <div className="inv-dt sub-text mb-3">
                  <div>{viewDetails.interviewer_name}</div>
                </div>

                <div className="text" style={{ color: "#54595F" }}>
                  <Icon path={mdiEmail} color="#EC782F" />
                  Interviewer Email:
                </div>
                <div className="inv-dt sub-text">
                  <div>{viewDetails.interviewer_email}</div>
                </div>
              </div>
            </div>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseView}>
              Close
            </Button>
          </>
        }
      />

      {/* Modal reschedule table */}
      <Popup
        show={showRescheduleTable}
        size="md"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseRescheduleTable}
        title="Reschedule Interview"
        content={
          <>
            <Form id="rescheduleTable-form" onSubmit={btnUpdateRescheduleTable}>
              {/* // > */}
              <Form.Group className="mb-2">
                <Form.Control
                  as="textarea"
                  name="txtCommentRescTable"
                  rows={4}
                  placeholder={
                    "Please suggest few timing when will you be available for the interview."
                  }
                  isInvalid={!!errorFormTable.txtCommentRescTable}
                />
                <Form.Control.Feedback type="invalid">
                  {errorFormTable.txtCommentRescTable}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseRescheduleTable}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" form="rescheduleTable-form">
              Submit
            </Button>
          </>
        }
      />
    </>
  );
};
