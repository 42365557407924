import React, { useEffect, useState } from "react";
import { Row, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../core/services/api.service";
import Apiform from "../../../core/services/apiform.service";

import { mdiCardAccountDetails } from "@mdi/js";

import SwalAlert from "../../../components/Swal/SwalAlert";

import BreadCrumb from "../../../components/Breadcrumb/BreadCrumb";

import ProfileImage from "../../../assets/no-profile-photo.jpg";
import { storeTitle } from "../../../store/actions/headerTitleSlice";
import "./staff.css";

const AddStaff = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [crumbs] = useState(["List of Staff", "Add New Staff"]);
  const [errorForm, setErrorForm] = useState({});
  const [profileImg, setProfileImg] = useState(ProfileImage);

  const [departList, setDepartList] = useState([]);
  const [saluteList, setSaluteList] = useState([]);
  const [phoneCodeList, setPhoneCodeList] = useState([]);
  const [listRole, setListRole] = useState([]);

  const [convBase64, setConvBase64] = useState("");

  const customSwal = SwalAlert();

  const selectedCrumb = (crumb) => {
    if (crumb === "List of Staff") {
      navigate("/staff/");
    }
  };
  // const [weekday] = useState(["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]);
  // const [curDate] = useState(new Date());
  // const [formatDate, setFormatDate] = useState("");

  // Error Form Event
  const formError = (object) => {
    const newErrors = {};

    const regEmail = /\S+@\S+\.\S+/;

    if (!object.ddlRole || object.ddlRole === "")
      newErrors.ddlRole = "Fill in the blank!";

    if (!object.ddlSalute || object.ddlSalute === "")
      newErrors.ddlSalute = "Fill in the blank!";

    if (!object.txtfirstname || object.txtfirstname === "")
      newErrors.txtfirstname = "Fill in the blank!";

    if (!object.txtlastname || object.txtlastname === "")
      newErrors.txtlastname = "Fill in the blank!";

    if (!object.txtemail || object.txtemail === "")
      newErrors.txtemail = "Fill in the blank!";
    else if (!regEmail.test(object.txtemail))
      newErrors.txtemail = "Invalid Email Address";

    return newErrors;
  };
  //End Error Form event

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
  
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
  
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };


  const uploadImage = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    // console.log("base64", base64)
    setConvBase64(base64);
    // avatar.src = base64;
    // textArea.innerText = base64;
  };

  const uploadSignature = (e) => {
    uploadImage(e);
  };

  // event button
  const btnSaveStaff = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dataStaff = Object.fromEntries(data.entries());

    // console.log("dataStaff", dataStaff);
    const newErrors = formError(dataStaff);

    // console.log("newErrors", newErrors);
    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      customSwal
        .fire({
          title: "Do you want to save new staff?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();

            formData.append("file", dataStaff.txtprofile);
            formData.append("salutation", dataStaff.ddlSalute);
            formData.append("first_name", dataStaff.txtfirstname);
            formData.append("last_name", dataStaff.txtlastname);

            formData.append("email", dataStaff.txtemail);
            formData.append("staff_no", dataStaff.txtstaffno);
            formData.append("phone_no_code", "");
            formData.append("phone_no", dataStaff.txphone);

            formData.append("status", dataStaff.ddlStatus);
            formData.append("role", dataStaff.ddlRole);
            formData.append("department_id", dataStaff.ddlDepartment);

            formData.append("job_title", dataStaff.txtjobtitle);
            formData.append("signature", convBase64);

            Apiform.post("user/store", formData)
              .then((response) => {
                // console.log("user/store", response);
                navigate("/staff");
                customSwal.fire({
                  title: "Staff Saved Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("user/store", error);
              });
          }
        });
    }
  };
  //  End Button event

  // event onChange
  const uploadSingleFile = (e) => {
    // console.log("onChnage", URL.createObjectURL(e.target.files[0]))
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setProfileImg(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  // Function Event
  function getListOption() {
    Api.get("option/list")
      .then((response) => {
        let listDepart = response.data.filter((grp) =>
          grp.group.includes("Department")
        );

        let listSalute = response.data.filter((grp) =>
          grp.group.includes("Salutation")
        );

        let listPhoneCode = response.data.filter((grp) =>
          grp.group.includes("Phone Code")
        );

        let sortListPhoneCode = listPhoneCode.sort(function (a, b) {
          return a.value.localeCompare(b.value);
        });

        setPhoneCodeList(sortListPhoneCode);

        setDepartList(listDepart);
        setSaluteList(listSalute);

        // console.log("newList", newList)
      })
      .catch((error) => {
        console.log("option/list", error);
      });
  }

  function getListUserAccess() {
    Api.get("role")
      .then((response) => {
        let data = response.data;
        setListRole(data);
      })
      .catch((error) => {
        console.log("error getListUserAccess", error);
      });
  }

  const btnDiscard = (e) => {
    navigate("/staff");
  };
  

  // First Load
  useEffect(() => {
    dispatch(storeTitle({title: "List of Staff", icon: mdiCardAccountDetails}));

    getListOption();
    getListUserAccess();
  }, [dispatch]);

  return (
    <div className="main-div">
      <div className="top-title-div">
        <BreadCrumb crumbs={crumbs} selected={selectedCrumb}></BreadCrumb>
      </div>
      <div className="bg-white-container">
        <Form onSubmit={btnSaveStaff}>
          {/* 1 */}
          <Row className="row-upload-pic mb-3">
            <div className="img-box">
              <img src={profileImg} alt="" />
            </div>
            <Form.Group className="mb-3 col-4">
              <Form.Control
                onChange={uploadSingleFile}
                name="txtprofile"
                type="file"
                accept=".png,.jpg,.jpeg"
              />
              <Form.Text className="text-muted">
                Allowed file types: jpg, jpeg, png.
              </Form.Text>
            </Form.Group>
          </Row>
          {/* 2 */}
          <Row className="add-staff">
            <Form.Group className="mb-3 col-6">
              <Form.Label>
                Role <label className="validation-star">*</label>
              </Form.Label>
              <Form.Select name="ddlRole" isInvalid={!!errorForm.ddlRole}>
                <option value="">Select</option>
                {listRole.map((role, index) => (
                  <option key={index} value={role.id}>
                    {role.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errorForm.ddlRole}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 col-6">
              <Form.Label>Status</Form.Label>
              <Form.Select name="ddlStatus">
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Select>
            </Form.Group>
          </Row>
          {/* 3 */}
          <Row className="add-staff">
            <Form.Group className="mb-3 col-6">
              <Form.Label>Staff Number</Form.Label>
              <Form.Control
                name="txtstaffno"
                type="text"
                placeholder="123456"
              />
            </Form.Group>

            <Form.Group className="mb-3 col-6">
              <Form.Label>Department</Form.Label>
              <Form.Select name="ddlDepartment">
                <option value="">Select</option>
                {departList.map((depart, index) => (
                  <option key={index} value={depart.id}>
                    {depart.value}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>
          {/* 4 */}
          <Row className="add-staff">
            <Form.Group className="mb-3 col-6">
              <Form.Label>Job Title</Form.Label>
              <Form.Control
                name="txtjobtitle"
                type="text"
                placeholder="Please enter job title"
              />
            </Form.Group>

            <Form.Group className="mb-3 col-6">
              <Form.Label>Signature</Form.Label>
              <Form.Control
                onChange={uploadSignature}
                name="txtsignature"
                type="file"
                accept=".png,.jpg,.jpeg"
              />
              <Form.Text className="text-muted">
                Allowed file types: jpg, jpeg, png.
              </Form.Text>
            </Form.Group>
          </Row>
          {/* 5 */}
          <Row className="add-staff">
            <Form.Group className="mb-3 col-6">
              <Form.Label>
                Salutation <label className="validation-star">*</label>
              </Form.Label>
              <Form.Select name="ddlSalute" isInvalid={!!errorForm.ddlSalute}>
                <option value="">Select</option>
                {saluteList.map((salute, index) => (
                  <option key={index} value={salute.value}>
                    {salute.value}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errorForm.ddlSalute}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          {/* 6 */}
          <Row className="add-staff">
            <Form.Group className="mb-3 col-6">
              <Form.Label>
                First Name <label className="validation-star">*</label>
              </Form.Label>
              <Form.Control
                name="txtfirstname"
                type="text"
                placeholder="Please enter first name"
                isInvalid={!!errorForm.txtfirstname}
              />
              <Form.Control.Feedback type="invalid">
                {errorForm.txtfirstname}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 col-6">
              <Form.Label>
                Last Name <label className="validation-star">*</label>
              </Form.Label>
              <Form.Control
                name="txtlastname"
                type="text"
                placeholder="Please enter last name"
                isInvalid={!!errorForm.txtlastname}
              />
              <Form.Control.Feedback type="invalid">
                {errorForm.txtlastname}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          {/* 7 */}
          <Row className="add-staff">
            <Form.Group className="mb-3 col-6">
              <Form.Label>
                Email Address <label className="validation-star">*</label>
              </Form.Label>
              <Form.Control
                name="txtemail"
                type="text"
                placeholder="Please enter email address"
                isInvalid={!!errorForm.txtemail}
              />
              <Form.Control.Feedback type="invalid">
                {errorForm.txtemail}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 col-6">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                name="txtphone"
                as="textarea"
                rows={1}
              />
              {/* <Row>
                <div className="col-3">
                  <Form.Select name="ddltypephone">
                    <option value="">Select</option>
                    {phoneCodeList.map((pcode, index) => (
                      <option key={index} value={pcode.value}>
                        {pcode.value}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div className="col-9">
                  <Form.Control
                    name="txphone"
                    type="number"
                    placeholder="Please enter your phone number"
                  />
                </div>
              </Row> */}
            </Form.Group>
          </Row>

          <div className="row-btn-bottom">
            <Button onClick={btnDiscard} variant="secondary">
              Discard
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddStaff;
