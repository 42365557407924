import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import Api from "../../core/services/api.service";
// import { useSelector, useDispatch } from "react-redux";

import SwalAlert from "../Swal/SwalAlert";
// import AuthAbilities from "../../pages/Admin/Auth/AuthAbilities";

import Icon from "@mdi/react";
import { mdiAccountPlus, mdiMagnify } from "@mdi/js";

import TextboxIcon from "../TextField/TextFieldIcon";
import { CustomTable } from "../Table/Table";
import Modal from "../Popup/ModalPopup";
import DropDownStatus from "../DropDown/DropDownStatusCand";

// import { storeTitle } from "../../store/actions/headerTitleSlice";
import { statusParticipant } from "../../pages/Admin/Clients/dataList";

function TabParticipant() {
  const [listCandidate, setListCandidate] = useState([]);
  const [allListCandidate, setAllListCandidate] = useState([]);

  const [listParticipant, setListParticipant] = useState([]);
  const [allListParticipant, setAllListParticipant] = useState([]);

  const [checkCandidate, setCheckCandidate] = useState([]);
  const [candSelected, setCandSelected] = useState(0);
  const [showParticipant, setShowParticipant] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  const customSwal = SwalAlert();

  const btnAddCandidate = () => {
    navigate("/candidates/addCandidate");
  };

  const btnOpenParticipant = () => {
    setShowParticipant(true);
    setCheckCandidate([]);
    setCandSelected(0);
    getListCandidate();
  };

  const btnCloseParticipant = () => {
    setShowParticipant(false);
  };

  const btnSaveStaff = () => {
    var candidate_list = checkCandidate.map((s) => s.id);

    customSwal
      .fire({
        title: "Do you want to add participant in this assignment?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.post(
            "client/" +
              params.id +
              "/assignment/" +
              params.idassign +
              "/tmdm/participant/create",
            {
              candidate_id: candidate_list,
            }
          )
            .then((response) => {
              //   let data = response.data;

              setShowParticipant(false);
              getListParticipant();
              customSwal.fire({
                title: "Saved Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error add participant", error);
            });
        }
      });
  };

  // const noData = [{}];

  const headers = [
    {
      Header: "Name",
      minWidth: 100,
      width: 400,
      accessor: (row) => `${row.first_name}`,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="form-container-default">
            {data.last_name
              ? data.first_name + " " + data.last_name
              : data.first_name}
          </div>
        );
      },
    },
    {
      Header: "Company",
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;
        // console.log("data", data);

        let comName = "";

        if (data.candidate_experiences.length > 0) {
          comName = data.candidate_experiences[0].company_name;
        } else {
          comName = "-";
        }

        return <>{comName}</>;
      },
    },
    {
      Header: "Job Title",
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;
        // console.log("data", data);

        let jobTitle = "";

        if (data.candidate_experiences.length > 0) {
          jobTitle = data.candidate_experiences[0].title;
        } else {
          jobTitle = data.job_title ? data.job_title : "-";
        }

        return <>{jobTitle}</>;
      },
    },
    {
      Header: "Mobile Number",
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return <>{data.mobile_no ? data.mobile_no : "-"}</>;
      },
    },
    {
      Header: "Status",
      minWidth: 150,
      width: 150,
      Cell: ({ row }) => {
        const data = row.original;

        function changeStatus(e) {
          let valuesta = e.target.value;

          let idassgn_cand = data.assignment_participant[0].id;

          Api.patch("client/" + params.id + "/assignment/" + params.idassign + "/tmdm/updateStatusParticipant/" + idassgn_cand,
            {
              status: valuesta,
            }
          )
            .then((response) => {
              getListParticipant();

              customSwal.fire({
                title: data.first_name + " " + data.last_name + " Status Change!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error changeStatus candidate", error);
            });
        };

        return (
          <>
            <DropDownStatus
              options={statusParticipant}
              styleDrop="select--status--style"
              sizeDrop="select--medium"
              colorDrop="primary-color"
              onChange={changeStatus}
              defaultValue={data.assignment_participant[0].status}
            />
          </>
        );
      },
    },
  ];

  const headerAddParticipant = [
    {
      Header: "Candidate Name",
      minWidth: 100,
      width: 200,
      accessor: (row) => `${row.first_name}`,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="form-container-default">
            {data.last_name
              ? data.first_name + " " + data.last_name
              : data.first_name}
          </div>
        );
      },
    },
    {
      Header: "Country",
      accessor: (row) => `${row.citizenship_main}`,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="form-container-default">
            {data.citizenship_main ? data.citizenship_main : "-"}
          </div>
        );
      },
    },
    {
      Header: "Job Title",
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;
        // console.log("data", data);

        let jobTitle = "";

        if (data.candidate_experiences.length > 0) {
          jobTitle = data.candidate_experiences[0].title;
        } else {
          jobTitle = data.job_title ? data.job_title : "-";
        }

        return <>{jobTitle}</>;
      },
    },
    {
      Header: "",
      accessor: "selection",
      disableSortBy: true,
      minWidth: 10,
      width: 50,
      Cell: ({ row }) => {
        const data = row.original;

        const onItemCheck = (data) => (event) => {
          if (checkCandidate.length > 0) {
            if (event.target.checked === false) {
              const index = checkCandidate.findIndex(function (chck) {
                return chck.id === data.id;
              });

              checkCandidate.splice(index, 1);
            } else {
              checkCandidate.push(data);
            }
          } else {
            checkCandidate.push(data);
          }

          setCandSelected(checkCandidate.length);

          // console.log("checkCandidate", checkCandidate);
        };

        const checkedItem = (id) => {
          let cnd = [];

          const checkCand = checkCandidate.filter((chec) => {
            return chec.id === id;
          });

          cnd = checkCand[0];

          return cnd;
        };

        return (
          <div className="checkbox-container">
            <input
              type="checkbox"
              checked={checkedItem(data.id)}
              id={"row" + data.id}
              onChange={onItemCheck(data)}
            />
          </div>
        );
      },
    },
  ];

  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();

    let newList = [];

    if (value !== "") {
      // let newList = [];
      newList = listCandidate.filter((candidate) => {
        if (!candidate.last_name || candidate.last_name === "") {
          return candidate.first_name
            .toLowerCase()
            .includes(value.toLowerCase());
        } else {
          return (
            candidate.first_name.toLowerCase().includes(value.toLowerCase()) ||
            candidate.last_name.toLowerCase().includes(value.toLowerCase())
          );
        }
      });

      setAllListCandidate(newList);
    } else {
      setAllListCandidate(listCandidate);
    }
  };

  const handleSearchParticipant = (event) => {
    let value = event.target.value.toLowerCase();

    let newList = [];

    if (value !== "") {
      // let newList = [];
      newList = listParticipant.filter((candidate) => {
        if (!candidate.last_name || candidate.last_name === "") {
          return candidate.first_name
            .toLowerCase()
            .includes(value.toLowerCase());
        } else {
          return (
            candidate.first_name.toLowerCase().includes(value.toLowerCase()) ||
            candidate.last_name.toLowerCase().includes(value.toLowerCase())
          );
        }
      });

      setAllListParticipant(newList);
    } else {
      setAllListParticipant(listParticipant);
    }
  };

  const getListParticipant = useCallback(() => {
    Api.get(
      "client/" +
        params.id +
        "/assignment/" +
        params.idassign +
        "/tmdm/participant/all"
    )
      .then((response) => {
        let data = response.data;
        // console.log("data", data);

        setListParticipant(data);
        setAllListParticipant(data);

        // if (data !== "no data") {
        //   setListProposal(data);
        // }
      })
      .catch((error) => {
        console.log("error getListParticipant", error);
      });
  }, [params]);

  const getListCandidate = useCallback(() => {
    Api.get("candidate/all")
      .then((response) => {
        let data = response.data;

        // console.log("data list candidate", data);
        setListCandidate(data);
        setAllListCandidate(data);
      })
      .catch((error) => {
        console.log("error getListCandidate", error);
      });
  }, []);

  useEffect(() => {
    getListCandidate();
    getListParticipant();
  }, [getListCandidate, getListParticipant]);

  return (
    <>
      <Card className="list-cli">
        <Card.Body>
          <div className="row-div">
            <div className="left-div">
              <TextboxIcon
                onChange={(event) => handleSearchParticipant(event)}
                name="txtsearchParti"
                type="text"
                placeholder="Search by participant name"
                inputSize="input--medium"
                icon={mdiMagnify}
              ></TextboxIcon>
            </div>
            <div className="right-div">
              <Button
                variant="link"
                className="modal-link"
                onClick={btnOpenParticipant}
              >
                <Icon path={mdiAccountPlus} />
                Add Participant
              </Button>
            </div>
          </div>

          <CustomTable
            headers={headers}
            items={allListParticipant}
            paginate={allListParticipant.length}
            maxRows={10}
          ></CustomTable>
        </Card.Body>
      </Card>

      {/* Modal */}
      <Modal
        show={showParticipant}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseParticipant}
        title="Add Participant"
        content={
          <>
            <div className="row-div">
              <div className="left-div">
                <TextboxIcon
                  name="txtsearchcandidate"
                  type="text"
                  placeholder="Search"
                  inputSize="input--medium"
                  onChange={(event) => handleSearch(event)}
                  icon={mdiMagnify}
                ></TextboxIcon>
              </div>
              <div className="right-div comp-selected">
                <div style={{ marginRight: "20px" }}>
                  {candSelected} selected
                </div>
                <Button
                  variant="link"
                  className="modal-link"
                  onClick={btnAddCandidate}
                >
                  <Icon path={mdiAccountPlus} color="#EC782F" />
                  <div>Create New Candidate</div>
                </Button>
              </div>
            </div>
            <CustomTable
              headers={headerAddParticipant}
              items={allListCandidate}
              paginate={allListCandidate.length}
              maxRows={10}
            ></CustomTable>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseParticipant}>
              Cancel
            </Button>
            <Button
              type="submit"
              // form="infor-form"
              variant="primary"
              onClick={btnSaveStaff}
              disabled={candSelected > 0 ? false : true}
            >
              Add Participant
            </Button>
          </>
        }
      ></Modal>
    </>
  );
}

export default TabParticipant;
