import {createSlice} from '@reduxjs/toolkit';
import Api from '../../core/services/api.service';

const initialState = {
    usrProfile:{},
    usrLogProfile: {},
    usrAbilities: [],
    usrError: ""
}

const userProfileSlice = createSlice({
    name: 'user',
    initialState,
    reducers:{
        storeUser: (state, action) => {
            state.usrProfile = action.payload;
        },
        storeLogProfile: (state, action) => {
            state.usrLogProfile = { ...state.usrLogProfile, ...action.payload };
        },
        storeAbilities: (state, action) => {
            state.usrAbilities = action.payload;
        },
        storeError: (state, action) => {
            state.usrError = action.payload;
        },
    },
});

export const getUserProfile = () => async (dispatch, rejectWithValue) => {
    try {
        const response = await Api.get("user/profile");

        
        dispatch(storeLogProfile(response.data));

    } catch (err) {
    //   throw new Error(err);
        throw rejectWithValue(err.message);
    }
};

export const getUserProfileHeader = (idrole) => async (dispatch, rejectWithValue) => {
    try {
        const response = await Api.get("user/profile");

        const response2 = await Api.get("role/" + idrole + "/withPermission");

        // console.log("response", response);

        dispatch(
            storeUser({
              id: response.data.id,
              first_name: response.data.first_name,
              last_name: response.data.last_name,
              profile_picture: response.data.profile_picture,
              email: response.data.email,
              abilities: response2.data,
            })
        );

        dispatch(storeAbilities(response2.data))

    } catch (err) {
        // throw new Error(err);
        dispatch(storeError("network error"));
        throw rejectWithValue(err.message);
    }
};

  

export const getUserProfileHeaderClient = () => async (dispatch) => {
    try {
        const response = await Api.get("user/profile");

        dispatch(
            storeUser({
              id: response.data.id,
              first_name: response.data.first_name,
              last_name: response.data.last_name,
              comp_email: response.data.comp_email,
              profile_picture: response.data.profile_pic,
            })
        );

    } catch (err) {
      throw new Error(err);
    }
};

export const getUserProfileHeaderCandidate = () => async (dispatch) => {
    try {
        const response = await Api.get("user/profile");
        
        dispatch(
            storeUser({
              id: response.data.id,
              first_name: response.data.first_name,
              last_name: response.data.last_name,
              email: response.data.email,
              profile_picture: response.data.profile_pic,
            })
        );

    } catch (err) {
      throw new Error(err);
    }
};

const {reducer, actions} = userProfileSlice;

export const { storeUser, storeLogProfile, storeAbilities, storeError } = actions;

export default reducer;