import React from "react";
import {
  Tab,
  Nav,
} from "react-bootstrap";
import "./profileSite.css"

import {TabAboutMe} from "../../components/Tab/TabCandidates/TabAboutMe"
// import { TabAboutMeTest } from "../../components/Tab/TabCandidates/TabAboutMeTest";
import { TabInterview } from "../../components/Tab/TabCandidates/TabInterview";

const Profile = () => {


  // useEffect(() => {
  //   window.addEventListener("beforeunload", alertUser);
  //   return () => {
  //     window.removeEventListener("beforeunload", alertUser);
  //   };
  // }, []);
  // const alertUser = (e) => {
  //   e.preventDefault();
  //   e.returnValue = "";
  // };
 
  return (
    <div className="main-div list-cli">
      <div className="top-title-div">
        <Tab.Container defaultActiveKey="first">
          <div className="nav-container-nf">
            <Nav variant="tabs" justify>
              <Nav.Item>
                <Nav.Link eventKey="first">About Me</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Interviews</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          <Tab.Content style={{ background: "none" }}>
            <Tab.Pane eventKey="first">
              <TabAboutMe></TabAboutMe>
            </Tab.Pane>

            <Tab.Pane eventKey="second">
              <TabInterview></TabInterview>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

export default Profile;
