import {createSlice} from '@reduxjs/toolkit';
import Api from '../../core/services/api.service';
import moment from 'moment';

export const meetingSlice = createSlice({
    name: "meeting",
    initialState: {
        meetingList: [],
        meetingListPast: [],
        meetingPersonInvolved: [],
    },
    reducers: {
        addMeeting: (state, action) => {
            state.meetingList.push(action.payload);
        },
        getMeetingList: (state, action) => {
            state.meetingList = action.payload;
        },
        getMeetingListPast: (state, action) => {
            state.meetingListPast = action.payload;
        },
        getMeetingPersonInvolved: (state, action) => {
          state.meetingPersonInvolved = action.payload;
      },
    },
});

export const createMeeting = (idclient, iduser, data) => async (dispatch, rejectWithValue) => {
    try {
        // console.log(data);
        await Api.post("client-staff/" + idclient + "/request-meeting/" + iduser + "/store", data);

        const response = await Api.get("client-staff/" + idclient + "/request-meeting/" + iduser + "/getAllReqMeeting");
  
        dispatch(getMeetingList(response.data));
    } catch (err) {
      throw rejectWithValue(err.message);
    }
};

export const getListOfMeeting = (idclient, iduser) => async (dispatch, rejectWithValue) => {
    try {
      const response = await Api.get("client-staff/" + idclient + "/request-meeting/" + iduser + "/getAllReqMeeting");
        
      let sortListOfMeeting = response.data.sort(function(a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
  
      dispatch(getMeetingList(sortListOfMeeting));
  
    } catch (err) {
      throw rejectWithValue(err.message);
    }
};

export const getListOfMeetingAdmin = () => async (dispatch, rejectWithValue) => {
    try {
      const date = new Date();
      const dateFormat = moment(date).format("YYYY-MM-DD");

      const response = await Api.get("user/requestMeeting/getAllRequestMeeting");
    
      const upComingMeeting = response.data.filter(meet => meet.request_meeting.date_meeting > dateFormat);

      let sortupComingMeeting = upComingMeeting.sort(function(a, b) {
        return new Date(b.request_meeting.date_meeting) - new Date(a.request_meeting.date_meeting);
      });
  
      dispatch(getMeetingList(sortupComingMeeting));

      const pastMeeting = response.data.filter(meet => meet.date_meeting < dateFormat);

      let sortpastMeeting = pastMeeting.sort(function(a, b) {
        return new Date(b.request_meeting.date_meeting) - new Date(a.request_meeting.date_meeting);
      });

      dispatch(getMeetingListPast(sortpastMeeting));

  
    } catch (err) {
      throw rejectWithValue(err.message);
    }
};

export const getListOfMeetingByUser = (id) => async (dispatch, rejectWithValue) => {
    try {
      const date = new Date();
      const dateFormat = moment(date).format("YYYY-MM-DD");

      const response = await Api.get("user/" + id + "/getUserRequestMeeting");
    
      const upComingMeeting = response.data.filter(meet => meet.request_meeting.date_meeting > dateFormat);

      let sortupComingMeeting = upComingMeeting.sort(function(a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
  
      dispatch(getMeetingList(sortupComingMeeting));

      const pastMeeting = response.data.filter(meet => meet.request_meeting.date_meeting < dateFormat);

      let sortpastMeeting = pastMeeting.sort(function(a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });

      dispatch(getMeetingListPast(sortpastMeeting));

  
    } catch (err) {
      throw rejectWithValue(err.message);
    }
};

export const updateMeetingStatus = (idclient, id, data, roleID, decodedSub) => async (dispatch, rejectWithValue) => {
    try {

      await Api.patch("client-staff/" + idclient + "/request-meeting/" + id + "/updateStatus", data);

      if (roleID === 1) {
        dispatch(getListOfMeetingAdmin());
      } else {
        dispatch(getListOfMeetingByUser(decodedSub));
      }


  
    } catch (err) {
      throw err;
    }
};

export const updateMeetingReadAt = (idclient, id, roleID, decodedSub) => async (dispatch, rejectWithValue) => {
  try {

    await Api.patch("client-staff/" + idclient + "/request-meeting/" + id + "/updateReadAt", {
      user_id: decodedSub
    });

    if (roleID === 1) {
      dispatch(getListOfMeetingAdmin());
    } else {
      dispatch(getListOfMeetingByUser(decodedSub));
    }

  } catch (err) {
    throw rejectWithValue(err.message);
  }
};

export const { addMeeting, getMeetingList, getMeetingListPast } = meetingSlice.actions;
export default meetingSlice.reducer;