import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Card, Tab, Nav, Form, Button, Row, Spinner } from "react-bootstrap";
import Api from "../../../../core/services/api.service";
import Apiform from "../../../../core/services/apiform.service";

import AuthAbilities from "../../Auth/AuthAbilities";
import SwalAlert from "../../../../components/Swal/SwalAlert";

import Icon from "@mdi/react";
import { mdiPencil, mdiCheckDecagram, mdiAccountGroup } from "@mdi/js";
import "../../../../components/Tab/Tab.css";

// Component Customize
import { InformationForm } from "../../../../components/Form/Form2";
import Modal from "../../../../components/Popup/ModalPopup";
import BreadCrumb from "../../../../components/Breadcrumb/BreadCrumb";
import CardInfo from "../../../../components/Card/CardInfo";
import DropDown from "../../../../components/DropDown/DropDown";
import TextFieldFormat from "../../../../components/TextField/TextFieldFormat";
import Searchable from "../../../../components/DropDown/SearchableSelect";
import { ColumnInformationForm } from "../../../../components/Form/Form2";
import ImagesSE from "../../../../components/Images/ImagesSE";

// import iconNYSE from "../../../../assets/nyse.png";
import DefaultPhoto from "../../../../assets/no-profile-photo.jpg";

// Component Type Tab
import { TabSubsidiary } from "../../../../components/Tab/TabSubsidiary";
import { TabStaff } from "../../../../components/Tab/TabStaff";
import { TabAssignment } from "../../../../components/Tab/TabAssignment";
import { TabFiles } from "../../../../components/Tab/TabFiles";
import { TabMeeting } from "../../../../components/Tab/TabMeeting";
import { TabProposal } from "../../../../components/Tab/TabProposal";

import { statusCustomerList } from "../dataList";

import {
  getClientDetails,
  getUpdateClientDetails,
} from "../../../../store/actions/clientSlice";
import { getListStaff } from "../../../../store/actions/staffSlice";
import { storeTitle } from "../../../../store/actions/headerTitleSlice";
import { indCliActTab } from "../../../../store/actions/tabActiveSlice";
import { getAssignmentList } from "../../../../store/actions/assignmentSlice";

function ViewActiveClients() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const abilities = AuthAbilities();
  const customSwal = SwalAlert();
  const formRef = useRef();

  const { cliActiveTab } = useSelector((state) => state.tabActive);
  const { clientDetails, clientDetailsIndustry, clientDetailsConsultant, clientStockExchange } = useSelector((state) => state.client);
  const { dataCountry, dataIndustry, dataStockExchanges } = useSelector((state) => state.option);
  const { dataStaff } = useSelector((state) => state.staff);
  const { assignListES, assignListTM, assignListDM } = useSelector((state) => state.assignment);

  const [errorForm, setErrorForm] = useState({});

  const [showInfor, setShowInfor] = useState(false);
  const [showBlacklist, setShowBlacklist] = useState(false);
  const [showUpload, setShowUpload] = useState(false);

  const [consulName, setConsulName] = useState([]);

  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selIndNew, setSelIndNew] = useState([]);
  const [selConsult, setSelConsult] = useState([]);

  const [crumbs, setCrumbs] = useState([]);
  const [status, setStatus] = useState("");
  const [isOpenStatus, setIsOpenStatus] = useState(false);

  const [checkListed, setCheckListed] = useState(false);

  const selectedCrumb = (crumb) => {
    if (crumb === "Clients") {
      navigate("/clients/listActiveC");
    }
  };

  const changeStatus = (event) => {
    let statusChange = event.target.value;

    if (statusChange === "blacklisted") {
      // setStatus("error-color");
      setShowBlacklist(true);
    } else {
      // Update Status Client
      customSwal
        .fire({
          title: "Do you want to update the status to " + statusChange + " ?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Api.post("client/" + params.id + "/updateStatus", {
              status: statusChange,
            })
              .then((response) => {
                dispatch(getClientDetails(params.id));

                customSwal.fire({
                  title: "Status Updated Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("user/store", error);
              });
          } else {
            // getSelectedClient();
          }
        });
    }
  };

  const changeIndustry = (e) => {
    setSelIndNew(Array.isArray(e) ? e.map((x) => x.id) : []);
  };

  const changeStaff = (e) => {
    setSelConsult(Array.isArray(e) ? e.map((x) => x.id) : []);
  };

  const handleSelectTab = (key) => {
    // console.log("keyTab", key)
    dispatch(
      indCliActTab({
        id: params.id,
        index: key,
      })
    );
  };

  const handleCheck = (e) => {
    // console.log(e.target.checked);
    setCheckListed(e.target.checked);
  };

  // End function event

  // Checking Form Error
  const formError = (object) => {
    const newErrors = {};

    // console.log("object", object);

    if (!object.txtcompanyname || object.txtcompanyname === "")
      newErrors.txtcompanyname = "Fill in the blank!";

    // if (!object.txtwebsite || object.txtwebsite === "")
    //   newErrors.txtwebsite = "Fill in the blank!";

    return newErrors;
  };

  // button close and open modal popup
  const btnEditInfor = (e) => {
    setConsulName([]);
    setErrorForm({});
    setShowInfor(true);

    clientDetails.client_has_user.map((userStaff) => {
      return setConsulName((current) => [
        ...current,
        ...[
          {
            id: userStaff.user.id,
            first_name: userStaff.user.first_name,
            last_name: userStaff.user.last_name,
          },
        ],
      ]);
    });
    // setConsulName(clientDetails.client_has_user);

    let idConsultant = clientDetails.client_has_user.map((a) => a.user.id);

    setSelConsult(idConsultant);
    setSelectedIndustry(clientDetailsIndustry);
    setSelIndNew(clientDetails.industry);
    setCheckListed(clientDetails.finance_certificate);
  };

  const btnCloseInfor = () => {
    setShowInfor(false);
  };

  const btnCloseBlacklist = () => {
    // getSelectedClient();
    setShowBlacklist(false);
  };

  const btnCloseUpload = () => {
    setShowUpload(false);
  };

  const btnEditImages = () => {
    if (!abilities.includes("edit clients")) {
    } else {
      setShowUpload(true);
    }
  };

  const btnSaveImage = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtUpload = Object.fromEntries(data.entries());

    customSwal
      .fire({
        title: "Do you want to update image client?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          const formData = new FormData();

          formData.append("file", dtUpload.txtimages);

          Apiform.post(
            "client/" + params.id + "/updateProfilePicture",
            formData
          )
            .then((response) => {
              dispatch(getClientDetails(params.id));

              setShowUpload(false);

              customSwal.fire({
                title: "Profile Updated Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error btnSaveImage", error);
            });
        }
      });
  };

  const btnUpdateBlacklist = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dataBlacklist = Object.fromEntries(data.entries());

    // console.log("dataInfor", dataBlacklist);
    customSwal
      .fire({
        title: "Do you want to update the status?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.post("client/" + params.id + "/updateStatus", {
            status: "blacklisted",
            reason_blacklist: dataBlacklist.txtreason,
          })
            .then((response) => {
              dispatch(getClientDetails(params.id));
              btnCloseBlacklist();

              customSwal.fire({
                title: "Status Updated Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error active client blacklisted", error);
            });
        }
      });
  };
  ///

  const handleParentClick = (id, isPotential) => (e) => {
    // console.log("id", id, isPotential)
    if (isPotential === true) {
      window.open(
        process.env.REACT_APP_BASE_URL + "clients/view-potential-client/" + id
      );
    } else {
      window.open(process.env.REACT_APP_BASE_URL + "clients/viewActiveC/" + id);
    }
  };

  // button save information
  const btnSaveUpdate = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtCli = Object.fromEntries(data.entries());

    const newErrors = formError(dtCli);

    let websiteCheck = !dtCli.txtwebsite.substring(0,dtCli.txtwebsite.indexOf(":")) ? !dtCli.txtwebsite ? "" : "http://" + dtCli.txtwebsite : dtCli.txtwebsite;
    let linkedInCheck = !dtCli.txtlinkedln.substring(0,dtCli.txtlinkedln.indexOf(":"))? !dtCli.txtlinkedln ? "" : "http://" + dtCli.txtlinkedln : dtCli.txtlinkedln;

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);

      for (var name in newErrors) {
        // console.log(name);

        const input = document.querySelector(`input[name=${name}]`);

        input.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });

        // console.log("input", input, inp_txtarea);
      }
    } else {
      customSwal
        .fire({
          title: "Do you want to update information?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const objClient = {
              name: dtCli.txtcompanyname,
              incorporated: dtCli.txtincorporate,
              consultant_name: selConsult,
              industry: selIndNew,
              headquarters: dtCli.ddlcorporate
                ? Number(dtCli.ddlcorporate)
                : null,
              address: dtCli.txtcompanyaddr,
              general_telephone: dtCli.txtphone,
              website_url: websiteCheck,
              linkedln_url: linkedInCheck,
              revenue: dtCli.txtrevenue,
              total_employees: dtCli.txtcompanyEmp,
              finance_certificate: checkListed,
              stock_exchange: dtCli.ddlStockExchanges ? Number(dtCli.ddlStockExchanges) : null,
              overall_background: dtCli.txtoverall,
              important_note: dtCli.txtnotes,
            };

            dispatch(getUpdateClientDetails(params.id, objClient));

            setShowInfor(false);
            customSwal.fire({
              title: "Information Updated Successfully!",
              icon: "success",
              confirmButtonText: "Okay",
            });
          }
        });
    }
  };

  useEffect(() => {
    dispatch(getClientDetails(params.id));
    dispatch(getListStaff());
    dispatch(getAssignmentList(params.id));

    const name = clientDetails.name;
    setCrumbs(["Clients", name]);
    dispatch(storeTitle({ title: "Clients", icon: mdiAccountGroup }));

    if (clientDetails.status === "blacklisted") {
      setStatus("error-color");
      setIsOpenStatus(!isOpenStatus);
    } else {
      setStatus("primary-color");
    }
  }, [
    clientDetails.name,
    dispatch,
    clientDetails.status,
    params.id,
    isOpenStatus,
  ]);

  return (
    <>
      {Object.keys(clientDetails).length === 0 ? 
      <div id="main-overlay" className="main-overlay">
        <Spinner animation="border" variant="light" />
      </div>:""}
      
      <div className="main-div">
      <div className="top-title-div">
        <BreadCrumb crumbs={crumbs} selected={selectedCrumb}></BreadCrumb>
      </div>

      <div className="top-title-div">
        <Card>
          <Card.Body className="card-body">
            <div className="container-between-div">
              <div className="center-flex1-div">
                <span className="textUpload" onClick={btnEditImages}>
                  Upload
                </span>
                {clientDetails.logo ? (
                  <img
                    src={clientDetails.logo}
                    onClick={btnEditImages}
                    style={{ cursor: "pointer" }}
                    alt=""
                    width="140px"
                    height="137px"
                  />
                ) : (
                  <img
                    src={DefaultPhoto}
                    onClick={btnEditImages}
                    style={{ cursor: "pointer" }}
                    alt=""
                    width="140px"
                    height="137px"
                  />
                )}
              </div>
              <div className="left-flex9-div">
                <div className="title-div">
                  {clientDetails.name}{" "}
                  {clientDetails.finance_certificate ? (
                    <Icon path={mdiCheckDecagram} />
                  ) : (
                    ""
                  )}
                  <div
                    style={{
                      marginLeft: "5px",
                      marginTop: "5px",
                      fontSize: "10px",
                    }}
                  >
                    {clientDetails.client ? "Owned By : " : ""}
                  </div>
                  <div
                    style={{
                      marginLeft: "5px",
                      marginTop: "5px",
                      fontSize: "10px",
                    }}
                  >
                    {clientDetails.client ? (
                      <>
                        <Button
                          variant="link"
                          className="no-underline"
                          style={{ fontSize: "10px" }}
                          onClick={handleParentClick(
                            clientDetails.client.id,
                            clientDetails.client.is_potential
                          )}
                        >
                          {clientDetails.client.name}
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="right-div" style={{ flex: 1 }}>
                    <Button
                      variant="link"
                      className="modal-link"
                      onClick={btnEditInfor}
                      hidden={!abilities.includes("edit clients")}
                      // onClick={btnEditInfor(
                      //   selectedClient.consultant_name,
                      //   selectedClient.industry
                      // )}
                    >
                      <Icon path={mdiPencil} color={"#EC782F"} />
                      <div>Edit Information</div>
                    </Button>
                  </div>
                </div>

                <Row className="act-cli-info">
                  <div className="container-info-column col-4">
                    <ColumnInformationForm label="Corporate HQ">
                      {clientDetails.options
                        ? clientDetails.options.value
                        : "-"}
                    </ColumnInformationForm>
                    <ColumnInformationForm label="Industry">
                      <div className="card_text_container">
                        {clientDetailsIndustry.length > 0
                          ? clientDetailsIndustry.map((industryLabel, i) => (
                              <CardInfo
                                key={i}
                                cardTextSize="card_text--xsmall"
                                cardTextStyle="card_text--default--solid"
                              >
                                {industryLabel.value}
                              </CardInfo>
                            ))
                          : "-"}
                      </div>
                    </ColumnInformationForm>
                  </div>
                  <div className="container-info-column col-3">
                    <ColumnInformationForm label="Incorporated">
                      {clientDetails.incorporated
                        ? clientDetails.incorporated
                        : "-"}
                    </ColumnInformationForm>
                    <ColumnInformationForm
                      label="Status"
                      hidden={!abilities.includes("edit clients")}
                    >
                      <DropDown
                        options={statusCustomerList}
                        styleDrop="select--status--style"
                        sizeDrop="select--medium"
                        colorDrop={status}
                        value={clientDetails.status}
                        onChange={changeStatus}
                        disabled={!abilities.includes("edit clients")}
                      />
                    </ColumnInformationForm>
                  </div>
                  <div className="container-info-column col-4">
                    <ColumnInformationForm label="Consultant Name">
                      {clientDetailsConsultant}
                    </ColumnInformationForm>
                    {clientDetails.status === "blacklisted" ? (
                      <ColumnInformationForm label="Reason for blacklisted">
                        {clientDetails.reason_blacklist}
                      </ColumnInformationForm>
                    ) : null}

                    {/* TM/DM flow */}
                    <ColumnInformationForm label="Services">
                      <div className="card_text_container">
                        {assignListES.length > 0 ? <CardInfo
                          cardTextSize="card_text--xsmall"
                          cardTextStyle="card_text--default--solid"
                        >
                          Executive
                        </CardInfo> : ""}

                        {assignListTM.length > 0 ? <CardInfo
                          cardTextSize="card_text--xsmall"
                          cardTextStyle="card_text--default--solid"
                        >
                          Transition
                        </CardInfo> : ""}

                        {assignListDM.length > 0 ? <CardInfo
                          cardTextSize="card_text--xsmall"
                          cardTextStyle="card_text--default--solid"
                        >
                          Distruption
                        </CardInfo> : ""}

                        {assignListES.length === 0 && assignListDM.length === 0 && assignListTM.length === 0 ? "-": ""}
                        
                      </div>
                    </ColumnInformationForm>
                    {/* TM/DM flow */}
                  </div>
                </Row>
              </div>
            </div>
            <div>
              <Row>
                <div className="column-right-div col">
                  {/* {clientDetails.finance_certificate ? (
                    <img src={iconNYSE} alt="" width="60px" height="60px" />
                  ) : null} */}
                  {clientDetails.finance_certificate ? (
                    clientStockExchange ? 
                    <ImagesSE type={clientStockExchange.code}></ImagesSE> : null
                  ) : null}
                </div>
              </Row>
            </div>
          </Card.Body>
        </Card>
      </div>

      <div className="container-div list-cli">
        <div className="container-tabs">
          <Tab.Container
            defaultActiveKey={cliActiveTab ? cliActiveTab.index : "1"}
            transition={true}
            onSelect={handleSelectTab}
          >
            <div className="nav-container scrollable">
              <Nav variant="tabs" justify>
                <Nav.Item>
                  <Nav.Link eventKey="1">Company Information</Nav.Link>
                </Nav.Item>

                {!clientDetails.parent_id ? (
                  <Nav.Item>
                    <Nav.Link eventKey="2">Subsidiaries</Nav.Link>
                  </Nav.Item>
                ) : null}

                <Nav.Item hidden={!abilities.includes("list proposal")}>
                  <Nav.Link eventKey="3">Proposal</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="4">Staff</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="5">Assignments</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="6">Meetings</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="7">Files / Documents</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>

            <Tab.Content style={{ background: "none" }}>
              <Tab.Pane eventKey="1">
                <Card>
                  <Card.Body>
                    <div className="right-div">
                      <Button
                        variant="link"
                        className="modal-link"
                        hidden={!abilities.includes("edit clients")}
                        onClick={btnEditInfor}
                      >
                        <Icon path={mdiPencil} />
                        Edit Information
                      </Button>
                    </div>

                    <div>
                      <div className="container-form-div">
                        {/* 1 */}
                        <Row className="client-dets">
                          <div className="col-6">
                            <InformationForm
                              label="Address"
                              sizeCol1Style="col-30"
                              sizeCol2Style="col-70"
                            >
                              <div
                                style={{
                                  textAlign: "justify",
                                  wordBreak: "break-word",
                                }}
                              >
                                {clientDetails.address
                                  ? clientDetails.address
                                  : "-"}
                              </div>
                            </InformationForm>
                          </div>
                          <div className="col-6">
                            <InformationForm
                              label="General Telephone"
                              sizeCol1Style="col-30"
                              sizeCol2Style="col-70"
                            >
                              {clientDetails.general_telephone
                                ? clientDetails.general_telephone
                                : "-"}
                            </InformationForm>
                          </div>
                        </Row>
                        {/* 2 */}
                        <Row className="client-dets">
                          <div className="col-6">
                            <InformationForm
                              label="Website"
                              sizeCol1Style="col-30"
                              sizeCol2Style="col-70"
                            >
                              {clientDetails.website_url ? (
                                <a
                                  href={clientDetails.website_url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {clientDetails.website_url}
                                </a>
                              ) : (
                                "-"
                              )}
                            </InformationForm>
                          </div>
                          <div className="col-6">
                            <InformationForm
                              label="LinkedIn Profile"
                              sizeCol1Style="col-30"
                              sizeCol2Style="col-70"
                            >
                              {clientDetails.linkedln_url ? (
                                <a
                                  href={clientDetails.linkedln_url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {clientDetails.linkedln_url}
                                </a>
                              ) : (
                                "-"
                              )}
                            </InformationForm>
                          </div>
                        </Row>
                        {/* 3 */}
                        <Row className="client-dets">
                          <div className="col-6">
                            <InformationForm
                              label="Revenue"
                              sizeCol1Style="col-30"
                              sizeCol2Style="col-70"
                            >
                              {clientDetails.revenue
                                ? clientDetails.revenue
                                : "-"}
                            </InformationForm>
                          </div>
                          <div className="col-6">
                            <InformationForm
                              label="Employees"
                              sizeCol1Style="col-30"
                              sizeCol2Style="col-70"
                            >
                              {clientDetails.total_employees
                                ? clientDetails.total_employees
                                : "-"}
                            </InformationForm>
                          </div>
                        </Row>
                        {/* 4 */}
                        <Row className="client-dets">
                          <InformationForm
                            label="Overall Background"
                            sizeCol1Style="col-30"
                            sizeCol2Style="col-70"
                          >
                            <div
                              style={{
                                textAlign: "justify",
                                wordBreak: "break-word",
                                whiteSpace: "pre-wrap"
                              }}
                            >
                              {clientDetails.overall_background
                                ? clientDetails.overall_background
                                : "-"}
                            </div>
                          </InformationForm>
                        </Row>
                        {/* 5 */}
                        <Row className="client-dets">
                          <InformationForm
                            label="Important Notes"
                            sizeCol1Style="col-30"
                            sizeCol2Style="col-70"
                          >
                            <div
                              style={{
                                textAlign: "justify",
                                wordBreak: "break-word",
                                whiteSpace: "pre-wrap"
                              }}
                            >
                              {clientDetails.important_note
                                ? clientDetails.important_note
                                : "-"}
                            </div>
                          </InformationForm>
                        </Row>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="2">
                <TabSubsidiary></TabSubsidiary>
              </Tab.Pane>
              <Tab.Pane eventKey="3">
                <TabProposal id={params.id} page="active"></TabProposal>
              </Tab.Pane>
              <Tab.Pane eventKey="4">
                <TabStaff id={params.id} page="active"></TabStaff>
              </Tab.Pane>
              <Tab.Pane eventKey="5">
                <TabAssignment id={params.id} page="active"></TabAssignment>
              </Tab.Pane>
              <Tab.Pane eventKey="6">
                <TabMeeting id={params.id} page="active"></TabMeeting>
              </Tab.Pane>
              <Tab.Pane eventKey="7">
                <TabFiles id={params.id}></TabFiles>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>

      {/* Modal if status blacklist */}
      <Modal
        show={showBlacklist}
        size="xs"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseBlacklist}
        title="Blacklist Reason"
        content={
          <>
            <Form id="blacklist-form" onSubmit={btnUpdateBlacklist}>
              <Form.Group as={Row} className="mb-1">
                <Form.Control name="txtreason" as="textarea" rows={3} />
              </Form.Group>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseBlacklist}>
              Cancel
            </Button>
            <Button type="submit" form="blacklist-form" variant="primary">
              Confirm
            </Button>
          </>
        }
      ></Modal>

      {/* Modal Upload Images */}
      <Modal
        show={showUpload}
        size="xs"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseUpload}
        title="Upload Image"
        content={
          <>
            <Form id="upload-form" onSubmit={btnSaveImage}>
              <Form.Group as={Row} className="mb-1">
                <Form.Control
                  type="file"
                  name="txtimages"
                  accept=".png,.jpg,.jpeg"
                />
                <Form.Text className="text-muted">
                  Allowed file types: jpg, jpeg, png.
                </Form.Text>
              </Form.Group>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseUpload}>
              Cancel
            </Button>
            <Button type="submit" form="upload-form" variant="primary">
              Upload
            </Button>
          </>
        }
      ></Modal>

      {/* Modal company information */}
      <Modal
        show={showInfor}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseInfor}
        title="Edit Information"
        content={
          <>
            <Form id="infor-form" onSubmit={btnSaveUpdate}>
              {/* 1 */}
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Company Name <label className="validation-star">*</label>
                  </Form.Label>
                  <Form.Control
                    name="txtcompanyname"
                    type="text"
                    defaultValue={clientDetails.name}
                    isInvalid={!!errorForm.txtcompanyname}
                    ref={formRef}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorForm.txtcompanyname}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              {/* 2 */}
              <Row>
                <Form.Group className="mb-3 col-6">
                  <Form.Label>Incorporated</Form.Label>
                  <Form.Control
                    name="txtincorporate"
                    type="text"
                    defaultValue={clientDetails.incorporated}
                  />
                </Form.Group>

                <Form.Group className="mb-3 col-6">
                  <Form.Label>Consultant Name</Form.Label>
                  <Searchable
                    name="ddlConsultant"
                    isMulti={true}
                    options={dataStaff}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) =>
                      option.first_name + " " + option.last_name
                    }
                    defaultValue={consulName}
                    onChange={changeStaff}
                    // isInvalid={!!errorForm.ddlcontact}
                    // errorText={errorForm.ddlcontact}
                  ></Searchable>
                </Form.Group>
              </Row>
              {/* 3 */}
              <Row>
                <Form.Group className="mb-3 col-6">
                  <Form.Label>Corporate HQ</Form.Label>
                  <Form.Select
                    name="ddlcorporate"
                    defaultValue={clientDetails.headquarters}
                  >
                    <option value="">Select</option>
                    {dataCountry.map((cntry, index) => (
                      <option key={index} value={cntry.id}>
                        {cntry.value}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3 col-6">
                  <Form.Label>Industry</Form.Label>
                  <Searchable
                    isMulti={true}
                    options={dataIndustry}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.value}
                    defaultValue={selectedIndustry}
                    onChange={changeIndustry}
                  ></Searchable>
                </Form.Group>
              </Row>
              {/* 4 */}
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label>Company Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="txtcompanyaddr"
                    rows={3}
                    defaultValue={clientDetails.address}
                  />
                </Form.Group>
              </Row>
              {/* 5 */}
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label>Company General Telephone</Form.Label>
                  <TextFieldFormat
                    value={clientDetails.general_telephone}
                    format="+### ### ######## ######"
                    name="txtphone"
                  ></TextFieldFormat>
                </Form.Group>
              </Row>
              {/* 6 */}
              <Row>
                <Form.Group className="mb-3 col-6">
                  <Form.Label>Company Website</Form.Label>
                  <Form.Control
                    name="txtwebsite"
                    type="text"
                    defaultValue={clientDetails.website_url}
                    placeholder="http://"
                    // isInvalid={!!errorForm.txtwebsite}
                  />
                  {/* <Form.Control.Feedback type="invalid">
                    {errorForm.txtwebsite}
                  </Form.Control.Feedback> */}
                </Form.Group>

                <Form.Group className="mb-3 col-6">
                  <Form.Label>Company LinkedIn Profile</Form.Label>
                  <Form.Control
                    name="txtlinkedln"
                    type="text"
                    defaultValue={clientDetails.linkedln_url}
                    placeholder="http://"
                  />
                </Form.Group>
              </Row>
              {/* 7 */}
              <Row>
                <Form.Group className="mb-3 col-6">
                  <Form.Label>Company Revenue</Form.Label>
                  <Form.Control
                    name="txtrevenue"
                    type="text"
                    defaultValue={clientDetails.revenue}
                  />
                </Form.Group>

                <Form.Group className="mb-3 col-6">
                  <Form.Label>Company Employees</Form.Label>
                  <Form.Control
                    name="txtcompanyEmp"
                    type="number"
                    min={0}
                    defaultValue={clientDetails.total_employees}
                  />
                </Form.Group>
              </Row>
              {/* 8 */}
              <Row>
                <Form.Group className="mb-3">
                  <Form.Check
                    name="cbListed"
                    type="checkbox"
                    onChange={handleCheck}
                    defaultChecked={clientDetails.finance_certificate}
                    label="Is this a listed company?"
                  />
                </Form.Group>
              </Row>
              {/* 10 */}
              {checkListed ? 
                <Row>
                  <Form.Group className="mb-3">
                    <Form.Label>Stock Exchanges</Form.Label>
                    <Form.Select 
                      name="ddlStockExchanges"
                      defaultValue={clientStockExchange ? clientStockExchange.id : ""}
                      >
                      <option value="">Select</option>
                      {dataStockExchanges.map((stock, index) => (
                        <option key={index} value={stock.id}>
                          {stock.value}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Row> : ""
              }
              {/* 9 */}
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label>Overall Background</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="txtoverall"
                    rows={3}
                    defaultValue={clientDetails.overall_background}
                  />
                </Form.Group>
              </Row>
              {/* 10 */}
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label>Important Notes</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="txtnotes"
                    rows={3}
                    defaultValue={clientDetails.important_note}
                  />
                </Form.Group>
              </Row>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseInfor}>
              Cancel
            </Button>
            <Button type="submit" form="infor-form" variant="primary">
              Update
            </Button>
          </>
        }
      ></Modal>
    </div>
    </>
    
  )
}

export default ViewActiveClients;
