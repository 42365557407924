import React, { useRef } from "react";
import JoditEditor from "jodit-react";
import "./RichTextEditor.css";

const RichTextEditor = (props) => {
  const editor = useRef(null);
  // const [htmlContent] = React.useState("");
  // const [errorBox, setErrorBox] = React.useState(props.required);

  // Jodit.make('#editor', {
  //     controls: {
  //         lineHeight: {
  //             list: Jodit.atom([1, 2, 3, 3.5])
  //         }
  //     }
  // });

  const config = {
    readonly: props.readonly,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    autofocus: false,
    toolbarAdaptive: true,
    buttons: [
      "bold",
      "italic",
      "underline",
      "|",
      "ul",
      "ol",
      "|",
      "font",
      "fontsize",
      "brush",
      "paragraph",
      "|",
      "table",
      "link",
      "|",
      "left",
      "center",
      "right",
      "justify",
      "|",
      "undo",
      "redo",
      "|",
      "hr",
      "eraser",
      "fullsize",
    ],
    controls: {
      font: {
        list: {
          "": "Default",
          "Helvetica,sans-serif": "Helvetica",
          "Arial,Helvetica,sans-serif": "Arial",
          "Calibri" : "Calibri",
          "Georgia,serif": "Georgia",
          "Impact,Charcoal,sans-serif": "Impact",
          "Tahoma,Geneva,sans-serif": "Tahoma",
          "Verdana,Geneva,sans-serif": "Verdana",
        },
      },
    },
    disablePlugins: "add-new-line",
    uploader: { insertImageAsBase64URI: true },
    style: {
      background: "white",
      color: "black",
      // borderStyle: "solid",
      // borderColor: "Orange"
    },
    showTooltip: true,
    inline: false,
    placeholder: "",
    cursorAfterAutofocus: "end",
    saveSelectionOnBlur: true,
    styleValues: {
      colorText: "red",
      colorBorder: "black",
      colorPanel: "blue",
    },
  };

  // const handleEditor = (e) => {
  //     // console.log("handleEdiotr ", e.length)
  //     // if(e.length > 0){
  //     //     setErrorBox("valid");
  //     // }else{
  //     //     setErrorBox("invalid")
  //     // }

  // }

  return (
    // <button>
    <>
      <div
        className={`${
          props.isInvalid ? "richtext-div-invalid" : "richtext-div"
        }`}
      >
        {/* <div className={`${props.isInvalid ? "richtext-div-invalid" : props.required === "valid" ? "richtext-div-valid": "richtext-div"}`}> */}
        {/* <div className="richtext-div"> */}
        <JoditEditor
          name={props.name}
          ref={editor}
          tabIndex={1}
          config={config}
          value={props.htmlContent}
          onChange={props.onChange}
          className="editors"
        />
      </div>

      {props.isInvalid ? (
        <div className="invalid-richtext">{props.errorText}</div>
      ) : null}
    </>
    // </button>
  );
};

export default RichTextEditor;
