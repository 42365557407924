import React from "react";
import "./Button.css";
import Icon from "@mdi/react";

const STYLES = [
  "btn--primary--solid",
  "btn--warning--solid",
  "btn--danger--solid",
  "btn--success--solid",
  "btn--default--solid",
  "btn--primary--outline",
  "btn--warning--outline",
  "btn--danger--outline",
  "btn--success--outline",
  "btn--edit--outline",
  "btn--transparent--outline",
  "btn--default--outline",
  "btn--danger--noline"
];

const SIZES = ["btn--medium", "btn--small-14px", "btn--small", "btn--link"];

function ButtonText({
  children,
  type,
  onClick,
  icon,
  buttonStyle,
  buttonSize,
}) {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  return (
    <div className="btn-container">
      <button
        className={`btntxt ${checkButtonStyle} ${checkButtonSize}`}
        onClick={onClick}
        type={type}
      >
        {icon ? <Icon path={icon} size={"1.2rem"} /> : ""}
        {children}
      </button>
    </div>
  );
}

export default ButtonText;
