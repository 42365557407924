import React, { useState, useEffect, useCallback } from "react";
import { Button, Form, Dropdown, Card } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Api from "../../core/services/api.service";
import ProfileImage from "../../assets/no-profile-photo.jpg";

import SwalAlert from "../Swal/SwalAlert";
import AuthAbilities from "../../pages/Admin/Auth/AuthAbilities";
import moment from "moment";

import Icon from "@mdi/react";
import {
  mdiPlusCircle,
  mdiPinOutline,
  mdiPin,
  mdiPencil,
  mdiDotsVertical,
  mdiDelete,
} from "@mdi/js";

import TableNote from "../Table/TableNote";
import Modal from "../Popup/ModalPopup";

import { getListTypeNote } from "../../store/actions/optionSlice";

export const TabNoteCandidate = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const abilities = AuthAbilities();

  const { usrProfile } = useSelector((state) => state.user);
  const { dataTypeNote } = useSelector((state) => state.option);

  const [showNote, setShowNote] = useState(false);
  const [errorNote, setErrorNote] = useState({});
  const [modeModal, setModeModal] = useState({
    title: null,
    button: null,
  });
  const [listCandNotes, setListCandNotes] = useState([]);
  const [idNote, setIdNote] = useState("");
  const [noteState] = useState({
    txtnote: "",
    ddlTypeNote: "",
  });

  const customSwal = SwalAlert();

  // start Checking Error Form
  const formErrorsNote = (object) => {
    const newErrors = {};

    if (!object.txtnote || object.txtnote === "")
      newErrors.txtnote = "Fill in the blank!";

    if (!object.ddlTypeNote || object.ddlTypeNote === "")
      newErrors.ddlTypeNote = "Fill in the blank!";

    return newErrors;
  };
  // end Checking Error Form

  // Button Event
  const btnAddNote = (e) => {
    noteState.txtnote = "";
    noteState.ddlTypeNote = "";

    setModeModal({
      title: "Add Note",
      button: "Add",
    });

    setErrorNote({});
    setShowNote(true);
    setIdNote("New");
  };

  const btnEditNote = (id) => (e) => {
    const filteredNote = listCandNotes.filter((rolenote) => {
      return rolenote.id === id;
    });

    noteState.txtnote = filteredNote[0].note;
    noteState.ddlTypeNote = filteredNote[0].assignment_id;

    setShowNote(true);
    setModeModal({
      title: "Edit Note",
      button: "Update",
    });

    setErrorNote({});
    setIdNote(id);
  };

  const btnCloseNote = () => {
    setShowNote(false);
  };

  const btnSaveUpdateNote = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtNote = Object.fromEntries(data.entries());

    const newErrors = formErrorsNote(dtNote);

    if (Object.keys(newErrors).length > 0) {
      setErrorNote(newErrors);
    } else {
      if (idNote === "New") {
        customSwal
          .fire({
            title: "Do you want to save new note?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post("client/1/assignment/12/notes/create", {
                assignment_id:
                  dtNote.ddlTypeNote === "general"
                    ? null
                    : Number(dtNote.ddlTypeNote),
                candidate_id: Number(params.id),
                type: dtNote.ddlTypeNote === "general" ? "general" : "",
                note: dtNote.txtnote,
                status: "created",
                created_by: Number(usrProfile.id),
              })
                .then((response) => {
                  // let data = response.data;

                  getListRoleNotesCandidate();

                  setShowNote(false);

                  customSwal.fire({
                    title: "Note Saved Successfully!",
                    icon: "success",
                    confirmButtonText: "Okay",
                  });
                })
                .catch((error) => {
                  console.log("error save note", error);
                });
            }
          });
      } else {
        customSwal
          .fire({
            title: "Do you want to update note?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.patch(
                "client/" +
                  params.id +
                  "/assignment/" +
                  params.idassign +
                  "/notes/" +
                  idNote,
                {
                  assignment_id:
                    dtNote.ddlTypeNote === "general"
                      ? null
                      : Number(dtNote.ddlTypeNote),
                  candidate_id: Number(params.id),
                  type: dtNote.ddlTypeNote === "general" ? "general" : "",
                  note: dtNote.txtnote,
                  status: "created",
                  created_by: Number(usrProfile.id),
                }
              )
                .then((response) => {
                  // let data = response.data;

                  getListRoleNotesCandidate();

                  setShowNote(false);

                  customSwal.fire({
                    title: "Note Saved Successfully!",
                    icon: "success",
                    confirmButtonText: "Okay",
                  });
                })
                .catch((error) => {
                  console.log("error update note", error);
                });
            }
          });
      }
    }
  };

  const btnDelNote = (id) => (e) => {
    // console.log("id", id);
    customSwal
      .fire({
        title: "Do you want to delete note?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("client/1/assignment/27/notes/" + id)
            .then((response) => {
              // let data = response.data;

              getListRoleNotesCandidate();

              customSwal.fire({
                title: "Delete Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error delete note", error);
            });
        }
      });
  };

  const getListRoleNotesCandidate = useCallback(() => {
    Api.get("candidate/" + params.id + "/notes/list")
      .then((response) => {
        let data = response.data;

        // console.log("getListRoleNotesCandidate", data);
        if (data !== "no data") {
          let sortPinned = data.sort((a, b) => b.pinned - a.pinned);

          setListCandNotes(sortPinned);
        }
      })
      .catch((error) => {
        console.log("error getListRoleNotesCandidate", error);
      });
  }, [params]);

  useEffect(() => {
    getListRoleNotesCandidate();
    dispatch(getListTypeNote(params.id));

    // setUserProfileID(usrProfile.id);
  }, [getListRoleNotesCandidate, dispatch, params.id]);

  // Header Table
  const headers = [
    {
      Header: "1",
      hideHeader: true,
      minWidth: 50,
      width: 50,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="td-data-img">
            {data.created_by_notes ? (
              <img src={data.created_by_notes.profile_picture} alt="" />
            ) : (
              <img src={ProfileImage} alt="" />
            )}
          </div>
        );
      },
    },
    {
      Header: "2",
      hideHeader: true,
      minWidth: 200,
      width: 300,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="td-notes" style={{ paddingRight: "20px" }}>
            <div className="text">
              {data.created_by_notes ? data.created_by_notes.first_name + " " + data.created_by_notes.last_name : "-"}
            </div>

            <div>{data.note}</div>
          </div>
        );
      },
    },
    {
      Header: "3",
      hideHeader: true,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return <>{data.type ? "General Notes" : data.assignments ? data.assignments.title:"-"}</>;
      },
    },
    {
      Header: "4",
      hideHeader: true,
      minWidth: 180,
      width: 180,
      Cell: ({ row }) => {
        const data = row.original;

        return <>{moment(data.created_at).format("DD MMMM YYYY")}</>;
      },
    },
    {
      Header: "5",
      hideHeader: true,
      minWidth: 50,
      width: 50,
      Cell: ({ row }) => {
        const data = row.original;

        const btnPinned = (e) => {
          Api.get(
            "client/" +
              params.id +
              "/assignment/3/notes/" +
              data.id +
              "/updatePinned"
          )
            .then((response) => {
              getListRoleNotesCandidate();
            })
            .catch((error) => {
              console.log("error pinned", error);
            });
        };

        return (
          <>
            {data.pinned ? (
              <Icon path={mdiPin} rotate={270} onClick={btnPinned} />
            ) : (
              <Icon path={mdiPinOutline} onClick={btnPinned} />
            )}
          </>
        );
      },
    },
    {
      Header: "6",
      hideHeader: true,
      minWidth: 50,
      width: 50,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <>
            {data.created_by ? (
              <Dropdown>
                <Dropdown.Toggle variant="link" className="no-underline">
                  <Icon path={mdiDotsVertical} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={btnEditNote(data.id)}
                    hidden={!abilities.includes("edit candidate note")}
                  >
                    <Icon path={mdiPencil} color="#467FD0" />
                    Edit Note
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={btnDelNote(data.id)}
                    hidden={!abilities.includes("delete candidate note")}
                  >
                    <Icon path={mdiDelete} color="#DF4759" />
                    Delete Note
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card>
        <Card.Body>
          <div className="row-div">
            <div className="left-div"></div>
            <div className="right-div">
              <Button
                variant="link"
                className="modal-link"
                onClick={btnAddNote}
                hidden={!abilities.includes("add candidate note")}
              >
                <Icon path={mdiPlusCircle} />
                Add Note
              </Button>
            </div>
          </div>

          <TableNote
            headers={headers}
            items={listCandNotes}
            paginate={listCandNotes.length}
          ></TableNote>
        </Card.Body>

        {/* Modal Notes */}
        <Modal
          show={showNote}
          size="lg"
          backdrop="static"
          keyboard={false}
          onHide={btnCloseNote}
          title={modeModal.title}
          content={
            <>
              <Form id="note-form" onSubmit={btnSaveUpdateNote}>
                <Form.Group className="mb-3">
                  <Form.Label>Type of Note</Form.Label>
                  <Form.Select
                    name="ddlTypeNote"
                    defaultValue={noteState.ddlTypeNote}
                    isInvalid={!!errorNote.ddlTypeNote}
                  >
                    <option value="general">General Notes</option>
                    {dataTypeNote.map((typenote, index) => (
                      <option key={index} value={typenote.id}>
                        {typenote.title}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errorNote.ddlTypeNote}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Note</Form.Label>
                  <Form.Control
                    name="txtnote"
                    as="textarea"
                    rows={5}
                    defaultValue={noteState.txtnote}
                    isInvalid={!!errorNote.txtnote}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorNote.txtnote}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
            </>
          }
          button={
            <>
              <Button variant="secondary" onClick={btnCloseNote}>
                Cancel
              </Button>
              <Button type="submit" form="note-form" variant="primary">
                {modeModal.button}
              </Button>
            </>
          }
        />
      </Card>
    </>
  );
};
