import React, {useState} from 'react';
import iconASE from "../../assets/Images_Stock_Exchange/ASE.png";
import iconBBB from "../../assets/Images_Stock_Exchange/BBB.png";
import iconDBA from "../../assets/Images_Stock_Exchange/DBA.png";
import iconEuronext from "../../assets/Images_Stock_Exchange/Euronext.png";
import iconHKSE from "../../assets/Images_Stock_Exchange/HKSE.png";
import iconJEG from "../../assets/Images_Stock_Exchange/JEG.png";
import iconJSE from "../../assets/Images_Stock_Exchange/JSE.png";
import iconKE from "../../assets/Images_Stock_Exchange/KE.png";
import iconLSE from "../../assets/Images_Stock_Exchange/LSE.png";
import iconNasdaq from "../../assets/Images_Stock_Exchange/Nasdaq.png";
import iconNNBE from "../../assets/Images_Stock_Exchange/NNBE.png";
import iconNSE from "../../assets/Images_Stock_Exchange/NSE.png";
import iconNYSE from "../../assets/Images_Stock_Exchange/nyse.png";
import iconSASE from "../../assets/Images_Stock_Exchange/SASE.png";
import iconSHSE from "../../assets/Images_Stock_Exchange/SHSE.png";
import iconSSE from "../../assets/Images_Stock_Exchange/SSE.png";
import iconSWSE from "../../assets/Images_Stock_Exchange/SWSE.png";
import iconTESE from "../../assets/Images_Stock_Exchange/TESE.png";
import iconTOSE from "../../assets/Images_Stock_Exchange/TOSE.png";
import iconTSE from "../../assets/Images_Stock_Exchange/TSE.jpg";

function ImagesSE({
    type
}) {
    const widthI = "100px";
    const heightI = "40px";

    return (
        <div>
            {
                (() => {
                    if(type==='ASE') {
                        return (<img src={iconASE} alt="" width={widthI} height={heightI} />)
                    } 
                    else if(type==='BBB') {
                        return (<img src={iconBBB} alt="" width={widthI} height={heightI} />)
                    } 
                    else if(type==='DBA') {
                        return (<img src={iconDBA} alt="" width={widthI} height={heightI} />)
                    } 
                    else if(type==='Euronext') {
                        return (<img src={iconEuronext} alt="" width={widthI} height={heightI} />)
                    } 
                    else if(type==='HKSE') {
                        return (<img src={iconHKSE} alt="" width={widthI} height={heightI} />)
                    } 
                    else if(type==='JEG') {
                        return (<img src={iconJEG} alt="" width={widthI} height={heightI} />)
                    } 
                    else if(type==='JSE') {
                        return (<img src={iconJSE} alt="" width={widthI} height={heightI} />)
                    } 
                    else if(type==='KE') {
                        return (<img src={iconKE} alt="" width={widthI} height={heightI} />)
                    } 
                    else if(type==='LSE') {
                        return (<img src={iconLSE} alt="" width={widthI} height={heightI} />)
                    } 
                    else if(type==='Nasdaq') {
                        return (<img src={iconNasdaq} alt="" width={widthI} height={heightI} />)
                    } 
                    else if(type==='NNBE') {
                        return (<img src={iconNNBE} alt="" width={widthI} height={heightI} />)
                    } 
                    else if(type==='NSE') {
                        return (<img src={iconNSE} alt="" width={widthI} height={heightI} />)
                    } 
                    else if(type==='NYSE') {
                        return (<img src={iconNYSE} alt="" width={widthI} height={heightI} />)
                    } 
                    else if(type==='SASE') {
                        return (<img src={iconSASE} alt="" width={widthI} height={heightI} />)
                    } 
                    else if(type==='SHSE') {
                        return (<img src={iconSHSE} alt="" width={widthI} height={heightI} />)
                    } 
                    else if(type==='SSE') {
                        return (<img src={iconSSE} alt="" width={widthI} height={heightI} />)
                    } 
                    else if(type==='SWSE') {
                        return (<img src={iconSWSE} alt="" width={widthI} height={heightI} />)
                    } 
                    else if(type==='TESE') {
                        return (<img src={iconTESE} alt="" width={widthI} height={heightI} />)
                    } 
                    else if(type==='TOSE') {
                        return (<img src={iconTOSE} alt="" width={widthI} height={heightI} />)
                    } 
                    else {
                        return (<img src={iconTSE} alt="" width={widthI} height={heightI} />)
                    }
                })()  
            }  
            
        </div>
    )
}

export default ImagesSE