import {createSlice} from '@reduxjs/toolkit';
import Api from '../../core/services/api.service';

export const staffSlice = createSlice({
    name: "staff",
    initialState: {
        dataStaff: [],
    },
    reducers: {
        getStaff: (state, action) => {
            state.dataStaff = action.payload;
        },
    }
});

export const getListStaff = () => async (dispatch) => {
    try {
        const response = await Api.get("user/list");

        let sortListStaffName = response.data.sort(function(a, b) {
            return a.first_name.localeCompare(b.first_name);
        });
      
        dispatch(getStaff(sortListStaffName));

       

    } catch (err) {
      throw new Error(err);
    }
};
  

  
export const { addTodo, getStaff } = staffSlice.actions;
export const showTodo = (state) => state.todo.data;
export default staffSlice.reducer;