import {
  Document,
  Packer,
  Paragraph,
  ImageRun,
  TextRun,
  Header,
  Footer,
  AlignmentType,
  PageNumber,
  TabStopType,
  TabStopPosition,
  Table,
  TableRow,
  TableCell,
  WidthType,
  convertInchesToTwip,
  BorderStyle,
} from "docx";
import { saveAs } from "file-saver";
import LogoHeader from '../../../assets/logo_report.png';

export const QCPReport = async (data) => {

  const candName = data.candidates.salutation + " " + data.candidates.first_name + " " + data.candidates.last_name;
  const positionName = data.assignments.title;
  const clientName = data.assignments.clients.name;
  const dateCreated = data.created_at;
  const citizenShip = data.candidates.citizenship_main;
  const location = data.candidates.citizenship_main;
  const willingTravel = data.candidates.willing_travel ? "Yes" : "No";
  const motivation = data.motivation;
  const recommendation = data.recommendation;
  const logoHeader = await fetch(LogoHeader);
  
  let languages = "";

  if(data.candidates.languages.length > 0){
    let array = [];

    array = data.candidates.languages.map((obj) => {
      return obj+ ",";
    });

    let lang = array.join(" ");

    languages = lang.slice(0, -1);
  }else{
    languages = "-";
  }

  let experienceList = [];
  let allowanceList = [];
  let currency = "";
  let salary = "";
  let notice = "";
  let bonus = "";
  let qualificationtxt = "";
  let namePlace = "";
  let yearEducation = "";

  if(data.candidates.candidate_experiences.length > 0) {
    experienceList = data.candidates.candidate_experiences;

    currency = data.candidates.candidate_experiences[0].currency;
    salary = data.candidates.candidate_experiences[0].salary;
    bonus = data.candidates.candidate_experiences[0].other_benefits;
    notice = data.candidates.candidate_experiences[0].notice_period;

    if(data.candidates.candidate_education.length > 0){
      qualificationtxt = data.candidates.candidate_education[0].qualification;
      namePlace =  data.candidates.candidate_education[0].name;
      yearEducation = data.candidates.candidate_education[0].start_year + " - " + data.candidates.candidate_education[0].end_year;
    }

    if(data.candidates.candidate_experiences[0].candidate_exp_allowance.length > 0){
       allowanceList = data.candidates.candidate_experiences[0].candidate_exp_allowance;
    }

  }

  let competencyList = [];

  if(data.qcp_competencies.length > 0){
    competencyList = data.qcp_competencies;
  }

  function toDateFormat(dateF) {
    const date = new Date(dateF);
    const longMonth = date.toLocaleString("en-us", { month: "long" });

    const dateFormat =
      date.getDate() + " " + longMonth + " " + date.getFullYear();
    return dateFormat;
  }

  function toMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
  
    return date.toLocaleString('en-US', {
      month: 'long',
    });
  }

  function toMoneyFormat(value) {
    let salary = "";

    let convertNum = Number(value);

    salary = convertNum.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    
    return salary;
  }

  function convertToPlain(html){

    // Create a new div element
    var tempDivElement = document.createElement("div");
    
    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;
    
    // Retrieve the text property of the element 
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }

  const fontType = "Calibri";
  const fontColor = "#EC782F";
  const fontSizeTitle = 28;
  const fontSizeTitle2 = 18;
  const fontSizeTitle3 = 16;
  const fontSizeTitle4 = 20;
  const fontSizeTitle5 = 36;
  const fontSizeTitle6 = 42;
  const fontSizeContent = 22;
  const fontSizeContent2 = 24;
  const tableWidth1 = 10000;

  const tableToAddToDocument = new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            columnSpan: 4,
            shading: {
              fill: "#BFBFBF",
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Candidate Profile: " + candName,
                    bold: true,
                    size: fontSizeContent2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: "\t" + toDateFormat(dateCreated),
                    bold: true,
                    size: fontSizeTitle4,
                    font: fontType,
                  }),
                ],
                tabStops: [
                  {
                    type: TabStopType.RIGHT,
                    position: TabStopPosition.MAX,
                  },
                ],
              }),
            ],
          }),
        ],
      }),
      new TableRow({
        tableHeader: true,
        children: [
          new TableCell({
            shading: {
              fill: "#BFBFBF",
            },
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
            },
            columnSpan: 4,
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "Career Progression",
                    bold: true,
                    size: fontSizeContent2,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
      new TableRow({
        tableHeader: true,
        children: [
          new TableCell({
            shading: {
              fill: "#BFBFBF",
            },
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Employer",
                    bold: true,
                    size: fontSizeTitle4,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            shading: {
              fill: "#BFBFBF",
            },
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Dates",
                    bold: true,
                    size: fontSizeTitle4,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            shading: {
              fill: "#BFBFBF",
            },
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Designation",
                    bold: true,
                    size: fontSizeTitle4,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            shading: {
              fill: "#BFBFBF",
            },
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Reason for Leaving",
                    bold: true,
                    size: fontSizeTitle4,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
        ]
      
      })
    ]
  })

  experienceList.map((exp) =>{
    return tableToAddToDocument.root.push(
      new TableRow({
        tableHeader: true,
        children: [
          // Employer Column
          new TableCell({
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: exp.company_name,
                    size: fontSizeTitle4,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
          // Date Column
          new TableCell({
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: exp.current_working === true ? toMonthName(exp.present_month) + " " + exp.present_year + " - Current" : toMonthName(exp.start_date_month) + " " + exp.start_date_year + " - " + toMonthName(exp.end_date_month) + " " + exp.end_date_year,
                    size: fontSizeTitle4,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
          // Designation Column
          new TableCell({
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: exp.title,
                    size: fontSizeTitle4,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
          // Reason Leaving Column
          new TableCell({
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: convertToPlain(exp.reason_leaving),
                    size: fontSizeTitle4,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
        ]
      
      })
    )
  });

  const tableToAddCompetency = new Table({
    rows: [
      new TableRow({
        tableHeader: true,
        children: [
          new TableCell({
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            columnSpan: 4,
            shading: {
              fill: "#BFBFBF",
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "Critical Competencies",
                    bold: true,
                    size: fontSizeContent2,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    ]
  });

  competencyList.map((comp) => {
    return tableToAddCompetency.root.push(
      new TableRow({
        tableHeader: true,
        children: [
          new TableCell({
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            columnSpan: 4,
            shading: {
              fill: "#F79646",
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: comp.assignment_competency.competencies,
                    bold: true,
                    size: fontSizeContent2,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
      new TableRow({
        tableHeader: true,
        children: [
          new TableCell({
            width: {
              size: tableWidth1,
              type: WidthType.DXA,
            },
            columnSpan: 4,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: comp.detail,
                    size: fontSizeContent,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
      
    )
  });

  const tableAllowance = new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            borders: {
              top: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
              bottom: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
              left: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
              right: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Allowance:",
                    bold: false,
                    size: fontSizeContent,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
        ]
      
      })
    ]
  })

  allowanceList.map((allow) => {
    return tableAllowance.root.push(
      new TableRow({
        tableHeader: true,
        children: [
          // Employer Column
          new TableCell({
            borders: {
              top: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
              bottom: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
              left: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
              right: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: " - " + allow.currency + " " + toMoneyFormat(allow.amount),
                    size: fontSizeContent,
                    font: fontType,
                  }),
                ],
              }),
            ],
          }),
         
        ]
      
      })
    )
  });

  const doc = new Document({
    sections: [
      {
        properties: {},
        headers: {
          default: new Header({
            children: [
              new Table({
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        borders: {
                          top: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
                          bottom: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
                          left: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
                          right: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
                        },
                        width: {
                          size: 10000,
                          type: WidthType.DXA,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "Lim-Loges",
                                bold: true,
                                size: fontSizeTitle,
                                font: fontType,
                              }),
                              new TextRun({
                                text: " & ",
                                bold: true,
                                size: fontSizeTitle,
                                font: fontType,
                                color: fontColor,
                              }),
                              new TextRun({
                                text: "Masters",
                                bold: true,
                                size: fontSizeTitle,
                                font: fontType,
                              }),
                            ],
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "transforming companies, transforming career",
                                bold: true,
                                size: fontSizeTitle2,
                                font: fontType,
                                color: fontColor,
                              }),
                            ],
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "Disruption Management",
                                bold: true,
                                size: fontSizeTitle2,
                                font: fontType,
                              }),
                              new TextRun({
                                text: " . ",
                                bold: true,
                                size: fontSizeTitle2,
                                font: fontType,
                                color: fontColor,
                              }),
                              new TextRun({
                                text: "Executive Search",
                                bold: true,
                                size: fontSizeTitle2,
                                font: fontType,
                              }),
                              new TextRun({
                                text: " . ",
                                bold: true,
                                size: fontSizeTitle2,
                                font: fontType,
                                color: fontColor,
                              }),
                              new TextRun({
                                text: "Transition Management",
                                bold: true,
                                size: fontSizeTitle2,
                                font: fontType,
                              }),
                            ],
                          }),
                        ],
                      }),
                      new TableCell({
                        borders: {
                          top: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
                          bottom: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
                          left: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
                          right: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
                        },
                        children: [
                          new Paragraph({
                            children: [
                              new ImageRun({
                                data: await logoHeader.blob(),
                                transformation: {
                                  width: 100,
                                  height: 100
                                }
                              })
                            ],
                          }),
                        ],
                      }),
                    ]
                  
                  })
                ]
              })
            ],
          }),
        },
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "Lim-Loges & Masters Pte Ltd (Company Reg. No. 201614475K) Address: 6 Battery Road, #30-01, Singapore 049909 Tel: +65 6725 6341",
                    bold: true,
                    size: fontSizeTitle3,
                    font: fontType,
                  }),
                ],
              }),
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "www.LimLogesMasters.com",
                    bold: true,
                    underline: true,
                    size: fontSizeTitle2,
                    font: fontType,
                    color: fontColor,
                  }),
                  new TextRun({
                    text: " China",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: " . ",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: "Hong Kong",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: " . ",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: "India",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: " . ",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: "Malaysia",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: " . ",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                  new TextRun({
                    text: "Singapore",
                    bold: true,
                    size: fontSizeTitle2,
                    font: fontType,
                  }),
                ],
              }),
              new Paragraph({
                alignment: AlignmentType.RIGHT,
                children: [
                  new TextRun({
                    children: [PageNumber.CURRENT],
                  }),
                ],
              }),
            ],
          }),
        },
        children: [
          new Paragraph({
            children: [
              new TextRun({
                break: 11,
                size: fontSizeTitle3,
              }),
            ],
          }),
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                text: "Private & Confidential",
                allCaps: true,
                bold: true,
                size: fontSizeTitle6,
                font: fontType,
                color: fontColor,
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                size: fontSizeContent,
              }),
            ],
          }),
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                text: "Qualified Candidate",
                bold: true,
                size: fontSizeTitle5,
                font: fontType,
              }),
            ],
          }),
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                text: candName,
                bold: true,
                size: fontSizeTitle5,
                font: fontType,
                break: 1,
              }),
            ],
          }),
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                text: positionName,
                bold: true,
                size: fontSizeTitle5,
                font: fontType,
              }),
            ],
          }),
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                text: clientName,
                bold: true,
                size: fontSizeTitle5,
                font: fontType,
              }),
            ],
          }),
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                text: "Confidential Candidate Profile",
                bold: true,
                size: fontSizeTitle5,
                font: fontType,
                break: 1,
              }),
            ],
          }),
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                text: toDateFormat(dateCreated),
                bold: true,
                size: fontSizeTitle5,
                font: fontType,
                break: 1,
              }),
            ],
          }),
          new Paragraph({
            alignment: AlignmentType.LEFT,
            margins: {
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new TextRun({
                text: "Private & Confidential",
                allCaps: true,
                bold: true,
                size: fontSizeTitle,
                font: fontType,
                color: fontColor,
              }),
            ],
            pageBreakBefore: true,
          }),
          new Paragraph({
            alignment: AlignmentType.LEFT,
            margins: {
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
            children: [
              new TextRun({
                text: "Client: " + clientName,
                bold: true,
                size: fontSizeContent,
                font: fontType,
              }),
              new TextRun({
                text: "\tPosition: " + positionName,
                bold: true,
                size: fontSizeContent,
                font: fontType,
              }),
            ],
            tabStops: [
              {
                type: TabStopType.RIGHT,
                position: TabStopPosition.MAX,
              },
            ],
          }),
          tableToAddToDocument,
          tableToAddCompetency,
          new Table({
            tableHeader: true,
            rows: [
              new TableRow({
                tableHeader: true,
                children: [
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    columnSpan: 4,
                    shading: {
                      fill: "#BFBFBF",
                    },
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Personal Information",
                            bold: true,
                            size: fontSizeContent2,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                tableHeader: true,
                children: [
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      top: convertInchesToTwip(0.05),
                      bottom: convertInchesToTwip(0.05),
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Education",
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      top: convertInchesToTwip(0.05),
                      bottom: convertInchesToTwip(0.05),
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    columnSpan: 3,
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "School: " + namePlace,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Qualification: " + qualificationtxt,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Year: " + yearEducation,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                tableHeader: true,
                children: [
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      top: convertInchesToTwip(0.05),
                      bottom: convertInchesToTwip(0.05),
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Current Compensation: (not verified at time of presentation)",
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      top: convertInchesToTwip(0.05),
                      bottom: convertInchesToTwip(0.05),
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    columnSpan: 3,
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Salary: " + currency + " " + toMoneyFormat(salary),
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                      tableAllowance,
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Bonus: " + convertToPlain(bonus),
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Notice: " + notice,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                tableHeader: true,
                children: [
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      top: convertInchesToTwip(0.05),
                      bottom: convertInchesToTwip(0.15),
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Citizenship: ",
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      top: convertInchesToTwip(0.05),
                      bottom: convertInchesToTwip(0.05),
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: citizenShip,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      top: convertInchesToTwip(0.05),
                      bottom: convertInchesToTwip(0.15),
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Languages: ",
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      top: convertInchesToTwip(0.05),
                      bottom: convertInchesToTwip(0.05),
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: languages,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                tableHeader: true,
                children: [
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      top: convertInchesToTwip(0.05),
                      bottom: convertInchesToTwip(0.15),
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Location: ",
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      top: convertInchesToTwip(0.05),
                      bottom: convertInchesToTwip(0.05),
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: location,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      top: convertInchesToTwip(0.05),
                      bottom: convertInchesToTwip(0.15),
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Willingness to travel: ",
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      top: convertInchesToTwip(0.05),
                      bottom: convertInchesToTwip(0.05),
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: willingTravel,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                tableHeader: true,
                children: [
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      top: convertInchesToTwip(0.05),
                      bottom: convertInchesToTwip(0.1),
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Motivation: ",
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      top: convertInchesToTwip(0.05),
                      bottom: convertInchesToTwip(0.05),
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    columnSpan: 3,
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: motivation,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                tableHeader: true,
                children: [
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    columnSpan: 4,
                    shading: {
                      fill: "#BFBFBF",
                    },
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Recommendation",
                            bold: true,
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                tableHeader: true,
                children: [
                  new TableCell({
                    width: {
                      size: tableWidth1,
                      type: WidthType.DXA,
                    },
                    margins: {
                      right: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.1),
                    },
                    columnSpan: 4,
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: convertToPlain(recommendation),
                            size: fontSizeContent,
                            font: fontType,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
            ]
          }),
          // new Table({
          //   rows: [
          //     new TableRow({
          //       children: [
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           columnSpan: 4,
          //           shading: {
          //             fill: "#BFBFBF",
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "Candidate Profile: ",
          //                   bold: true,
          //                   size: fontSizeContent2,
          //                   font: fontType,
          //                 }),
          //                 new TextRun({
          //                   text: "\tDate: 1 August 2022 ",
          //                   bold: true,
          //                   size: fontSizeTitle4,
          //                   font: fontType,
          //                 }),
          //               ],
          //               tabStops: [
          //                 {
          //                   type: TabStopType.RIGHT,
          //                   position: TabStopPosition.MAX,
          //                 },
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       tableHeader: true,
          //       children: [
          //         new TableCell({
          //           shading: {
          //             fill: "#BFBFBF",
          //           },
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //           },
          //           columnSpan: 4,
          //           children: [
          //             new Paragraph({
          //               alignment: AlignmentType.CENTER,
          //               children: [
          //                 new TextRun({
          //                   text: "Career Progression",
          //                   bold: true,
          //                   size: fontSizeContent2,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       tableHeader: true,
          //       children: [
          //         new TableCell({
          //           shading: {
          //             fill: "#BFBFBF",
          //           },
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "Employer",
          //                   bold: true,
          //                   size: fontSizeTitle4,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           shading: {
          //             fill: "#BFBFBF",
          //           },
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "Dates",
          //                   bold: true,
          //                   size: fontSizeTitle4,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           shading: {
          //             fill: "#BFBFBF",
          //           },
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "Designation",
          //                   bold: true,
          //                   size: fontSizeTitle4,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           shading: {
          //             fill: "#BFBFBF",
          //           },
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "Reason for Leaving",
          //                   bold: true,
          //                   size: fontSizeTitle4,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       tableHeader: true,
          //       children: [
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeTitle4,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeTitle4,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeTitle4,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeTitle4,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       tableHeader: true,
          //       children: [
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeTitle4,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeTitle4,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeTitle4,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeTitle4,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       tableHeader: true,
          //       children: [
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeTitle4,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeTitle4,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeTitle4,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeTitle4,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       tableHeader: true,
          //       children: [
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeTitle4,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeTitle4,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeTitle4,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeTitle4,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       tableHeader: true,
          //       children: [
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           columnSpan: 4,
          //           shading: {
          //             fill: "#BFBFBF",
          //           },
          //           children: [
          //             new Paragraph({
          //               alignment: AlignmentType.CENTER,
          //               children: [
          //                 new TextRun({
          //                   text: "Critical Competencies",
          //                   bold: true,
          //                   size: fontSizeContent2,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       tableHeader: true,
          //       children: [
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           columnSpan: 4,
          //           shading: {
          //             fill: "#F79646",
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "[free text] Competency 1",
          //                   bold: true,
          //                   size: fontSizeContent2,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       tableHeader: true,
          //       children: [
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           columnSpan: 4,
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeContent,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       tableHeader: true,
          //       children: [
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           columnSpan: 4,
          //           shading: {
          //             fill: "#F79646",
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "[free text] Competency 2",
          //                   bold: true,
          //                   size: fontSizeContent2,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       tableHeader: true,
          //       children: [
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           columnSpan: 4,
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeContent,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       tableHeader: true,
          //       children: [
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           columnSpan: 4,
          //           shading: {
          //             fill: "#F79646",
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "[free text] Competency 3",
          //                   bold: true,
          //                   size: fontSizeContent2,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       tableHeader: true,
          //       children: [
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           columnSpan: 4,
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeContent,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       tableHeader: true,
          //       children: [
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           columnSpan: 4,
          //           shading: {
          //             fill: "#F79646",
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "[free text] Competency 4",
          //                   bold: true,
          //                   size: fontSizeContent2,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       tableHeader: true,
          //       children: [
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           columnSpan: 4,
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeContent,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       tableHeader: true,
          //       children: [
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           columnSpan: 4,
          //           shading: {
          //             fill: "#F79646",
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "[free text] Competency 5",
          //                   bold: true,
          //                   size: fontSizeContent2,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       tableHeader: true,
          //       children: [
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           columnSpan: 4,
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeContent,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       tableHeader: true,
          //       children: [
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           columnSpan: 4,
          //           shading: {
          //             fill: "#BFBFBF",
          //           },
          //           children: [
          //             new Paragraph({
          //               alignment: AlignmentType.CENTER,
          //               children: [
          //                 new TextRun({
          //                   text: "Personal Information",
          //                   bold: true,
          //                   size: fontSizeContent2,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       tableHeader: true,
          //       children: [
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.5),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "Education",
          //                   size: fontSizeContent,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           columnSpan: 3,
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeContent,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       tableHeader: true,
          //       children: [
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "Current Compensation: (not verified at time of presentation)",
          //                   size: fontSizeContent,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           columnSpan: 3,
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeContent,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       tableHeader: true,
          //       children: [
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.15),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "Citizenship: ",
          //                   size: fontSizeContent,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeContent,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.15),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "Languages: ",
          //                   size: fontSizeContent,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeContent,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       tableHeader: true,
          //       children: [
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.15),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "Location: ",
          //                   size: fontSizeContent,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeContent,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.15),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "Willingness to travel: ",
          //                   size: fontSizeContent,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeContent,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       tableHeader: true,
          //       children: [
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.1),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "Motivation: ",
          //                   size: fontSizeContent,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             top: convertInchesToTwip(0.05),
          //             bottom: convertInchesToTwip(0.05),
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           columnSpan: 3,
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "",
          //                   size: fontSizeContent,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       tableHeader: true,
          //       children: [
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           columnSpan: 4,
          //           shading: {
          //             fill: "#BFBFBF",
          //           },
          //           children: [
          //             new Paragraph({
          //               alignment: AlignmentType.CENTER,
          //               children: [
          //                 new TextRun({
          //                   text: "Recommendation",
          //                   bold: true,
          //                   size: fontSizeContent,
          //                   font: fontType,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       tableHeader: true,
          //       children: [
          //         new TableCell({
          //           width: {
          //             size: tableWidth1,
          //             type: WidthType.DXA,
          //           },
          //           margins: {
          //             right: convertInchesToTwip(0.1),
          //             left: convertInchesToTwip(0.1),
          //           },
          //           columnSpan: 4,
          //           children: [
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "Gap Analysis: ",
          //                   size: fontSizeContent,
          //                   font: fontType,
          //                   break: 1,
          //                 }),
          //               ],
          //             }),
          //             new Paragraph({
          //               children: [
          //                 new TextRun({
          //                   text: "Person: ",
          //                   size: fontSizeContent,
          //                   font: fontType,
          //                   break: 1,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //   ],
          // }),
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                text: "This information is highly confidential and should not be distributed to or discussed with anyone beyond its originally intended recipients.",
                bold: true,
                size: fontSizeTitle3,
                font: fontType,
                color: fontColor,
              }),
            ],
          }),
        ],
      },
    ],
  });

  Packer.toBlob(doc).then((blob) => {
   
    saveAs(blob, "QCP " + candName + ".docx");
  });

  return doc;
};
