import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Card, Form, Col, Row, Button } from "react-bootstrap";
import Api from "../../../core/services/api.service";

import SwalAlert from "../../../components/Swal/SwalAlert";
import moment from "moment";

import Icon from "@mdi/react";
import { mdiArrowLeft, mdiAccountGroup } from "@mdi/js";

import BreadCrumb from "../../../components/Breadcrumb/BreadCrumb";
import RichTextEditor from "../../../components/RichTextArea/RichTextEditor";
import Searchable from "../../../components/DropDown/SearchableSelect";

import { getClientDetails } from "../../../store/actions/clientSlice";
import {
  getProposalDetail,
  setProposalContact,
} from "../../../store/actions/proposalSlice";
import { getListStaff } from "../../../store/actions/staffSlice";
import { storeTitle } from "../../../store/actions/headerTitleSlice";

function EditProposal() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const formRef = useRef();

  const [selProposal, setSelProposal] = useState({});

  const [errorForm, setErrorForm] = useState({});

  const { getTitle } = useSelector((state) => state.headerTitle);
  const { usrProfile } = useSelector((state) => state.user);
  const { clientDetails } = useSelector((state) => state.client);
  const { dataStaff } = useSelector((state) => state.staff);
  const { proposal_detail_created, proposal_detail_contact } = useSelector(
    (state) => state.proposal
  );

  const selectedCrumb = (crumb) => {
    if(crumb === "Clients"){
      navigate("/clients/listActiveC");
    }else if(crumb === "Potential Clients"){
      navigate("/clients/listPotentialC");
    }else if(crumb === clientDetails.name){
      params.page === "active" ? 
        navigate("/clients/viewActiveC/" + params.id) :
        navigate("/clients/view-potential-client/" + params.id);
    }
  };

  const customSwal = SwalAlert();

  // Checking Error Form
  const formError = (object) => {
    const newErrors = {};

    // console.log("object", object);

    if (!object.txtfilename || object.txtfilename === "")
      newErrors.txtfilename = "Fill in the blank!";

    if (!object.txtjobtitle || object.txtjobtitle === "")
      newErrors.txtjobtitle = "Fill in the blank!";

    // if (!object.txtoverview || object.txtoverview === "")
    //   newErrors.txtoverview = "Fill in the blank!";

    // if (
    //   !object.txtrequirement ||
    //   object.txtrequirement === "" ||
    //   object.txtrequirement === "<p><br></p>"
    // )
    //   newErrors.txtrequirement = "Fill in the blank!";

    // if (!object.ddlcontact || object.ddlcontact === "")
    //   newErrors.ddlcontact = "Fill in the blank!";

    // if (!object.txtregional || object.txtregional === "")
    //   newErrors.txtregional = "Fill in the blank!";

    // if (!object.txtdomestic || object.txtdomestic === "")
    //   newErrors.txtdomestic = "Fill in the blank!";

    // if (!object.txtcompensation || object.txtcompensation === "")
    //   newErrors.txtcompensation = "Fill in the blank!";

    // if (!object.txtpayterms || object.txtpayterms === "")
    //   newErrors.txtpayterms = "Fill in the blank!";

    // if (!object.txtindirect || object.txtindirect === "")
    //   newErrors.txtindirect = "Fill in the blank!";

    // if (!object.txtsubsequence || object.txtsubsequence === "")
    //   newErrors.txtsubsequence = "Fill in the blank!";

    // if (!object.txtinterest || object.txtinterest === "")
    //   newErrors.txtinterest = "Fill in the blank!";

    // if (!object.txtrestrictions || object.txtrestrictions === "")
    //   newErrors.txtrestrictions = "Fill in the blank!";

    // if (!object.txtperiod || object.txtperiod === "")
    //   newErrors.txtperiod = "Fill in the blank!";

    // if (
    //   !object.txtguarantee ||
    //   object.txtguarantee === "" ||
    //   object.txtguarantee === "<p><br></p>"
    // )
    //   newErrors.txtguarantee = "Fill in the blank!";

    return newErrors;
  };

  // Button Event

  const btnUpdateProposal = (e) => {

    const buttonType = window.event.submitter.name;

    // console.log("buttonType", selProposal.status)
    e.preventDefault();

    const data = new FormData(e.target);
    const dtPro = Object.fromEntries(data.entries());

    const newErrors = formError(dtPro);

    var contact = proposal_detail_contact.map((s) => s.id);

    // console.log("contact", contact);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);

      for (var name in newErrors) {
        // console.log(name);

        const input = document.querySelector(`input[name=${name}]`);
        const inp_txtarea = document.querySelector(`textarea[name=${name}]`);

        if (input !== null) {
          input.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
          });
        } else {
          inp_txtarea.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
          });
        }

        // console.log("input", input, inp_txtarea);
      }
    } else {
      customSwal
        .fire({
          title: buttonType === "draft" ? "Do you want to save draft proposal?" : buttonType === "submit" ? "Do you want to submit proposal for approval?" : "Do you want to update proposal?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Api.patch(
              "client/ " + params.id + "/proposal/" + params.idpro + "/update",
              {
                client_id: Number(params.id),
                file_name: dtPro.txtfilename,
                job_title: dtPro.txtjobtitle,
                company_overview: dtPro.txtoverview,
                requirement: dtPro.txtrequirement,
                main_contact_llm: contact,
                domestic_fee: Number(dtPro.txtdomestic),
                regional_fee: Number(dtPro.txtregional),
                expected_compensation: dtPro.txtcompensation,
                payment_term: dtPro.txtpayterms,
                indirect_expenses: Number(dtPro.txtindirect),
                compound_interest: Number(dtPro.txtinterest),
                subseq_cand_fee: Number(dtPro.txtsubsequence),
                off_limit_restriction: dtPro.txtrestrictions,
                guaranty_period: dtPro.txtperiod,
                guaranty_condition: dtPro.txtguarantee,
                updated_by: Number(usrProfile.id),
                status: buttonType === "draft" ? "draft" : buttonType === "submit" ? "proposal" : selProposal.status              }
            )
              .then((response) => {
                if (params.page === "active") {
                  navigate("/clients/viewActiveC/" + params.id);
                } else {
                  navigate("/clients/view-potential-client/" + params.id);
                }

                customSwal.fire({
                  title: "Proposal Updated Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("error update proposal", error);
              });
          }
        });
    }
  };

  const hanldeChangeStaff = (e) => {
    const array_contact = e.map((x) => ({
      id: x.id,
      first_name: x.first_name,
      last_name: x.last_name,
    }));

    dispatch(setProposalContact(array_contact));
  };

  // Function Event
  // Load list main contact details

  const getSelectedProposal = useCallback(() => {
    Api.get("client/" + params.id + "/proposal/" + params.idpro + "/view")
      .then((response) => {
        let data = response.data;

        // console.log("data", data)

        setSelProposal(data);
      })
      .catch((error) => {
        console.log("getSelectedProposal", error);
      });
  }, [params]);

  useEffect(() => {
    getSelectedProposal();
    dispatch(getProposalDetail(params.id, params.idpro));

    dispatch(getClientDetails(params.id));
    dispatch(getListStaff());

    if (params.page === "active") {
      dispatch(storeTitle({ title: "Clients", icon: mdiAccountGroup }));
    } else {
      dispatch(
        storeTitle({ title: "Potential Clients", icon: mdiAccountGroup })
      );
    }
  }, [
    getSelectedProposal,
    params.id,
    params.idpro,
    params.page,
    clientDetails.name,
    dispatch,
  ]);

  return (
    <div className="main-div  create-props">
      <div className="top-title-div both-side">
        <BreadCrumb
          crumbs={[getTitle.title, clientDetails.name, "Edit Proposal"]}
          selected={selectedCrumb}
        ></BreadCrumb>
        <Button variant="secondary" onClick={() => navigate(-1)}>
          <Icon path={mdiArrowLeft} />
          Back
        </Button>
      </div>

      {/* Form Edit Proposal */}
      <Card>
        <Card.Body>
          <Form id="proposal-form" onSubmit={btnUpdateProposal}>
            {/* 1 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                File Name <label className="validation-star">*</label>
              </Form.Label>
              <Col>
                <Form.Control
                  name="txtfilename"
                  type="text"
                  defaultValue={selProposal.file_name}
                  isInvalid={!!errorForm.txtfilename}
                  // ref={formRef}
                />
                <Form.Control.Feedback type="invalid">
                  {errorForm.txtfilename}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            {/* 2 */}
            <Row>
              <Col xs={6}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={4}>
                    Creation Date
                  </Form.Label>
                  <Col>
                    <Form.Control
                      placeholder={moment().format("dddd, DD MMMM YYYY")}
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={4}>
                    Created By
                  </Form.Label>
                  <Col>
                    <Form.Control
                      placeholder={
                        proposal_detail_created.first_name +
                        " " +
                        proposal_detail_created.last_name
                      }
                      style={{ textTransform: "capitalize" }}
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            {/* 3 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Job Title <label className="validation-star">*</label>
              </Form.Label>
              <Col>
                <Form.Control
                  name="txtjobtitle"
                  type="text"
                  defaultValue={selProposal.job_title}
                  isInvalid={!!errorForm.txtjobtitle}
                  // ref={formRef}
                />
                <Form.Control.Feedback type="invalid">
                  {errorForm.txtjobtitle}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            {/* 4 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Company Overview 
                {/* <label className="validation-star">*</label> */}
              </Form.Label>
              <Col>
                <Form.Control
                  name="txtoverview"
                  as="textarea"
                  rows={3}
                  defaultValue={selProposal.company_overview}
                  // isInvalid={!!errorForm.txtoverview}
                  // ref={formRef}
                />
                {/* <Form.Control.Feedback type="invalid">
                  {errorForm.txtoverview}
                </Form.Control.Feedback> */}
              </Col>
            </Form.Group>

            {/* 5 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Requirement 
                {/* <label className="validation-star">*</label> */}
              </Form.Label>
              <Col>
                <RichTextEditor
                  name="txtrequirement"
                  htmlContent={selProposal.requirement}
                  // isInvalid={!!errorForm.txtrequirement}
                  // errorText={errorForm.txtrequirement}
                  // ref={formRef}
                />
              </Col>
            </Form.Group>

            {/* 6 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Main Contact Details{" "}
                {/* <label className="validation-star">*</label> */}
              </Form.Label>
              <Col>
                <Searchable
                  name="ddlcontact"
                  isMulti={true}
                  options={dataStaff}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) =>
                    option.first_name + " " + option.last_name
                  }
                  value={proposal_detail_contact}
                  onChange={hanldeChangeStaff}
                  // isInvalid={!!errorForm.ddlcontact}
                  // errorText={errorForm.ddlcontact}
                  // ref={formRef}
                ></Searchable>
              </Col>
            </Form.Group>

            {/* 7 */}
            <Row className="create-props">
              <Col xs={6}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={4}>
                    Professional Domestic Fees(%){" "}
                    {/* <label className="validation-star">*</label> */}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      name="txtdomestic"
                      type="number"
                      // max={100}
                      defaultValue={selProposal.domestic_fee}
                      // isInvalid={!!errorForm.txtdomestic}
                      // ref={formRef}
                    />
                    {/* <Form.Control.Feedback type="invalid">
                      {errorForm.txtdomestic}
                    </Form.Control.Feedback> */}
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={4}>
                    Professional Regional Fees(%){" "}
                    {/* <label className="validation-star">*</label> */}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      name="txtregional"
                      type="number"
                      max={100}
                      defaultValue={selProposal.regional_fee}
                      // isInvalid={!!errorForm.txtregional}
                      // ref={formRef}
                    />
                    {/* <Form.Control.Feedback type="invalid">
                      {errorForm.txtregional}
                    </Form.Control.Feedback> */}
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            {/* 8 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Expected Compensation Range{" "}
                {/* <label className="validation-star">*</label> */}
              </Form.Label>
              <Col>
                <RichTextEditor
                  name="txtcompensation"
                  htmlContent={selProposal.expected_compensation}
                  // isInvalid={!!errorForm.txtcompensation}
                  // errorText={errorForm.txtcompensation}
                  // ref={formRef}
                />
                {/* <Form.Control.Feedback type="invalid">
                  {errorForm.txtcompensation}
                </Form.Control.Feedback> */}
              </Col>
            </Form.Group>

            {/* 9 */}
            <Row className="create-props">
              <Col xs={6}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={4}>
                    Payment Terms 
                    {/* <label className="validation-star">*</label> */}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      name="txtpayterms"
                      type="text"
                      defaultValue={selProposal.payment_term}
                      // isInvalid={!!errorForm.txtpayterms}
                      // ref={formRef}
                    />
                    {/* <Form.Control.Feedback type="invalid">
                      {errorForm.txtpayterms}
                    </Form.Control.Feedback> */}
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={4}>
                    Indirect Expenses (%){" "}
                    {/* <label className="validation-star">*</label> */}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      name="txtindirect"
                      type="number"
                      defaultValue={selProposal.indirect_expenses}
                      // isInvalid={!!errorForm.txtindirect}
                      // ref={formRef}
                    />
                    {/* <Form.Control.Feedback type="invalid">
                      {errorForm.txtindirect}
                    </Form.Control.Feedback> */}
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            {/* 10 */}
            <Row className="create-props">
              <Col xs={6}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={4}>
                    Compound Interest (%){" "}
                    {/* <label className="validation-star">*</label> */}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      name="txtinterest"
                      type="number"
                      defaultValue={selProposal.compound_interest}
                      // isInvalid={!!errorForm.txtinterest}
                      // ref={formRef}
                    />
                    {/* <Form.Control.Feedback type="invalid">
                      {errorForm.txtinterest}
                    </Form.Control.Feedback> */}
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={4}>
                    Subsequence Candidate Fees (%){" "}
                    {/* <label className="validation-star">*</label> */}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      name="txtsubsequence"
                      type="number"
                      defaultValue={selProposal.subseq_cand_fee}
                      // isInvalid={!!errorForm.txtsubsequence}
                      // ref={formRef}
                    />
                    {/* <Form.Control.Feedback type="invalid">
                      {errorForm.txtsubsequence}
                    </Form.Control.Feedback> */}
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            {/* 11 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Off-limits Restrictions{" "}
                {/* <label className="validation-star">*</label> */}
              </Form.Label>
              <Col>
                <RichTextEditor
                  name="txtrestrictions"
                  // isInvalid={!!errorForm.txtrestrictions}
                  // errorText={errorForm.txtrestrictions}
                  htmlContent={selProposal.off_limit_restriction}
                  // ref={formRef}
                />
                {/* <Form.Control
                  name="txtrestrictions"
                  as="textarea"
                  rows={3}
                  defaultValue={selProposal.off_limit_restriction}
                  isInvalid={!!errorForm.txtrestrictions}
                /> */}
                {/* <Form.Control.Feedback type="invalid">
                  {errorForm.txtrestrictions}
                </Form.Control.Feedback> */}
              </Col>
            </Form.Group>

            {/* 12 */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={2}>
                Guarantee Period 
                {/* <label className="validation-star">*</label> */}
              </Form.Label>
              <Col>
                <Form.Control
                  name="txtperiod"
                  type="text"
                  defaultValue={selProposal.guaranty_period}
                  // isInvalid={!!errorForm.txtperiod}
                  // ref={formRef}
                />
                {/* <Form.Control.Feedback type="invalid">
                  {errorForm.txtperiod}
                </Form.Control.Feedback> */}
              </Col>
            </Form.Group>

            {/* 13 */}
            <Form.Group as={Row} className="mb-3" controlId="formFileName">
              <Form.Label column lg={2}>
                Guarantee Conditions{" "}
                {/* <label className="validation-star">*</label> */}
              </Form.Label>
              <Col>
                <RichTextEditor
                  name="txtguarantee"
                  htmlContent={selProposal.guaranty_condition}
                  // isInvalid={!!errorForm.txtguarantee}
                  // errorText={errorForm.txtguarantee}
                  // ref={formRef}
                />
              </Col>
            </Form.Group>

            <div className="row-btn-center">
              <Button onClick={() => navigate(-1)} variant="secondary">
                Cancel
              </Button>
              {selProposal.status === "draft" || selProposal.status === "rejected" ? 
                <Button variant="info" type="submit" name="draft" form="proposal-form">
                  Save Draft
                </Button> : 
                <Button variant="primary" type="submit" name="edit" form="proposal-form">
                  Edit Proposal
                </Button>
              }
              {selProposal.status === "draft" || selProposal.status === "rejected"? 
                <Button variant="primary" type="submit" name="submit" form="proposal-form">
                  Submit for Approval
                </Button> : 
               ""
              }
              {/* <Button variant="primary" type="submit" form="proposal-form">
                Edit Proposal
              </Button> */}
            </div>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default EditProposal;
