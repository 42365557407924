import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Tab, Nav, Badge, Row, Form, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Api from "../../../core/services/api.service";

import moment from "moment";
import SwalAlert from "../../../components/Swal/SwalAlert";
import "./interview.css";

import Icon from "@mdi/react";
import {
  mdiCalendar,
  mdiCircleSmall,
  mdiClockOutline,
  mdiMapMarkerOutline,
  mdiAccountVoice,
  mdiPlus,
  mdiCloseCircle,
  mdiPlusCircle,
  mdiTextBoxMultipleOutline
} from "@mdi/js";

import Popup from "../../../components/Popup/ModalPopup";
import Searchable from "../../../components/DropDown/SearchableSelect";

import { storeTitle } from "../../../store/actions/headerTitleSlice";
import { getListClient, getClientStaff, getListClientStaff } from "../../../store/actions/clientSlice";
import { getAssignmentList, getAssignList } from "../../../store/actions/assignmentSlice";
import { getCandidateAssignList, getListAssignCandidate } from "../../../store/actions/candidateSlice"
import { getListStaff } from "../../../store/actions/staffSlice";
import { getListInterviewRequest } from "../../../store/actions/interviewSlice";


const Interview = () => {

  const dispatch = useDispatch();
  const customSwal = SwalAlert();
  const navigate = useNavigate();

  const { clientActiveList, clientListStaff } = useSelector((state) => state.client);
  const { assignList } = useSelector((state) => state.assignment);
  const { candidateAssignList } = useSelector((state) => state.candidate);
  const { dataStage, dataDuration } = useSelector((state) => state.option);
  const { dataStaff } = useSelector((state) => state.staff);
  const { interReqList, interReqListPast } = useSelector((state) => state.interview);

  const [showInv, setShowInv] = useState(false);

  const [errorForm, setErrorForm] = useState({});

  const btnOpenAddInv = (e) => {
    dispatch(getAssignList([]));
    dispatch(getListAssignCandidate([]));
    dispatch(getListClientStaff([]));

    setValueJobTitle("");
    setValueCandName("");
    setShowInv(true);
    setErrorForm({});
  };

  const btnCloseAddInv = () => {
    setShowInv(false);
  };

  const [idCompany, setIdCompany] = useState(0);
  const [idAssign, setIdAssign] = useState(0);
  const [idCand, setIdCand] = useState(0);

  const [valueJobTitle, setValueJobTitle] = useState();
  const [valueCandName, setValueCandName] = useState();
  const [valueStaCliName, setValueStaCliName] = useState();

  const [selStaffLLM, setSelStaffLLM] = useState([]);
  const [selStaffClient, setSelStaffClient] = useState([]);

  const handleChangeCompany = (e) => {
    dispatch(getListAssignCandidate([]));

    dispatch(getAssignmentList(e.value));
    dispatch(getClientStaff(e.value));

    setIdCompany(e.value);
    setValueJobTitle("");
    setValueCandName("");
    setValueStaCliName("");
  };

  const handleChangeJob = (e) => {
    setValueJobTitle(e)

    dispatch(getCandidateAssignList(idCompany,e.id));
    setIdAssign(e.id);
    setValueCandName("");
  };

  const handleChangeCandName = (e) => {
    setIdCand(e.id);
    setValueCandName(e);
  };

  const handleStaffClient = (e) => {

    setValueStaCliName(e);
    setSelStaffClient(Array.isArray(e) ? e.map((x) => x.id) : []);
  };

  const handleStaffLLM = (e) => {
    setSelStaffLLM(Array.isArray(e) ? e.map((x) => x.id) : []);
  };

  const [rowDateTime, setRowDateTime] = useState([
    {
      interview_id: 1,
      interview_date: "",
      interview_time: "",
    },
  ]);

  const btnAddRowsDateTime = () => {
    const totLength = rowDateTime.length + 1;

    // console.log("totLength", totLength)

    setRowDateTime((items) => [
      ...items,
      {
        interview_id: totLength,
        interview_date: "",
        interview_time: "",
      },
    ]);
  };

  const btnDelRowsDateTime = (index) => {
    let data = [...rowDateTime];
    data.splice(index, 1);

    setRowDateTime(data);
   
  };

  const handleFormChange = (index, event) => {

    let data = [...rowDateTime];
    data[index][event.target.name] = event.target.value;

    setRowDateTime(data);
  };

  const [interviewState, setInterviewState] = useState({
    int_name: "",
    int_assignName: "",
    int_date: "",
    int_time: "",
    int_location: "",
    int_candName: "",
    int_candId: 0,
    int_compName:"",
    int_compId: 0,
    int_addNote: "",
    int_staffAdmin: "",
    int_staffClient: "",
    inv_status: "",
  });

  const btnShowInterview = (id) => (e) => {

    var staff_client = "";
    var staff_admin = "";
    
    const filterInterview = interReqList.find((interview) => interview.id === id);

    // console.log("filterInterview", filterInterview)

    if(filterInterview.interview_has_staff.length > 0){
      var array_staff_admin = filterInterview.interview_has_admin.map((obj) => {
        return obj.user.last_name ? obj.user.first_name + " " + obj.user.last_name + "," : obj.user.first_name + ",";
      });
  
      staff_admin = array_staff_admin.join(" ");
    }else{
      staff_admin = "";
    }

    if(filterInterview.interview_has_staff.length > 0){
      var array_staff_client = filterInterview.interview_has_staff.map((obj) => {
        return obj.candidate.last_name ? obj.candidate.first_name + " " + obj.candidate.last_name + "," : obj.candidate.first_name + ",";
      });
  
      staff_client = array_staff_client.join(" ");
    }else{
      staff_client = "-"
    }
    

    setInterviewState({
      int_name: filterInterview.stage,
      int_assignName: filterInterview.assignments.title,
      int_date: filterInterview.interview_date ? moment(filterInterview.interview_date).format("DD MMMM YYYY") : "",
      int_time: filterInterview.interview_time ? moment(filterInterview.interview_time, ["HH:mm"]).format("hh:mm A") : "",
      int_location: filterInterview.location,
      int_candName: filterInterview.candidates.last_name ? filterInterview.candidates.first_name + " " + filterInterview.candidates.last_name : filterInterview.candidates.first_name, 
      int_candId: filterInterview.candidates.id,
      int_compName: filterInterview.assignments.clients.name,
      int_compId: filterInterview.assignments.clients.id,
      int_addNote: filterInterview.add_note,
      int_staffAdmin: staff_admin,
      int_staffClient: staff_client,
      inv_status: filterInterview.status,
    });

  };

  const btnShowInterviewPast = (id) => (e) => {

    var staff_client = "";
    var staff_admin = "";
    
    const filterInterview = interReqListPast.find((interview) => interview.id === id);

    if(filterInterview.interview_has_staff.length > 0){
      var array_staff_admin = filterInterview.interview_has_admin.map((obj) => {
        return obj.user.last_name ? obj.user.first_name + " " + obj.user.last_name + "," : obj.user.first_name + ",";
      });
  
      staff_admin = array_staff_admin.join(" ");
    }else{
      staff_admin = "";
    }

    if(filterInterview.interview_has_staff.length > 0){
      var array_staff_client = filterInterview.interview_has_staff.map((obj) => {
        return obj.candidate.last_name ? obj.candidate.first_name + " " + obj.candidate.last_name + "," : obj.candidate.first_name + ",";
      });
  
      staff_client = array_staff_client.join(" ");
    }else{
      staff_client = "-"
    }

    setInterviewState({
      int_name: filterInterview.stage,
      int_assignName: filterInterview.assignments.title,
      int_date: moment(filterInterview.interview_date).format("DD MMMM YYYY"),
      int_time: moment(filterInterview.interview_time, ["HH:mm"]).format("hh:mm A"),
      int_location: filterInterview.location,
      int_candName: filterInterview.candidates.last_name ? filterInterview.candidates.first_name + " " + filterInterview.candidates.last_name : filterInterview.candidates.first_name, 
      int_candId: filterInterview.candidates.id,
      int_compName: filterInterview.assignments.clients.name,
      int_compId: filterInterview.assignments.clients.id,
      int_addNote: filterInterview.add_note,
      int_staffAdmin: staff_admin,
      int_staffClient: staff_client,
      inv_status: filterInterview.status,
    });

  };

  const btnLinkClient = (id) => (e) => {
    navigate("/clients/viewActiveC/" + id);
  };

  const btnLinkCandidate = (id) => (e) => {
    navigate("/candidates/viewCandidate/" + id);
  };

  const formError = (object, arrRowDateTime) => {
    const newErrors = {};

    // console.log("object", arrRowDateTime);

    if (!object.ddlCompanyName || object.ddlCompanyName === "")
      newErrors.ddlCompanyName = "Fill in the blank!";

    if (!object.ddlJobTitle || object.ddlJobTitle === "")
      newErrors.ddlJobTitle = "Fill in the blank!";
    
    if (!object.ddlCandidateName || object.ddlCandidateName === "")
      newErrors.ddlCandidateName = "Fill in the blank!";

    if (!object.ddlStage || object.ddlStage === "")
      newErrors.ddlStage = "Fill in the blank!";

    if (!object.ddlDuration || object.ddlDuration === "")
      newErrors.ddlDuration = "Fill in the blank!";

    arrRowDateTime.map((arr) => {
      if (!arr.interview_date || arr.interview_date === "")
        newErrors.interview_date = "Fill in the blank!";
      
      if (!arr.interview_time || arr.interview_time === "")
        newErrors.interview_time = "Fill in the blank!";

        return newErrors;
    });

    return newErrors;
  };

  const btnSaveUpdateInv = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtInv = Object.fromEntries(data.entries());

    const newErrors = formError(dtInv, rowDateTime);

    // console.log("dtInv", rowDateTime)
    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      // console.log("idCompany", idCompany)
      // console.log("idAssign", idAssign)
      // console.log("idCand", idCand)
      // console.log("selStaffLLM", selStaffLLM)
      // console.log("selStaffClient", selStaffClient)

      setErrorForm({});
      customSwal
        .fire({
          title: "Do you want to save new interview?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Api.post("candidate/" + idCand + "/assignment/" + idAssign + "/interview/create",
              {
                stage: dtInv.ddlStage,
                location: dtInv.txtlocation,
                duration: dtInv.ddlDuration,
                iv_date_time: rowDateTime.length > 1 ? rowDateTime : [],
                interview_date: rowDateTime.length > 1 ? "" : dtInv.interview_date,
                interview_time: rowDateTime.length > 1 ? "" : dtInv.interview_time,
                staff_involve: selStaffLLM,
                client_involve: selStaffClient,
                add_note: dtInv.txtaddnote,
              }
            )
              .then((response) => {
                // console.log(response);

                dispatch(getListInterviewRequest());
                setShowInv(false);
                customSwal.fire({
                  title: "Interview Saved Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("error interview", error);
              });
          }
        });
    }
  };

  const removeActiveClass = () => {
    document.querySelectorAll(".box-click-container").forEach((el) => {
      el.classList.remove("active");
    });
  };

  useEffect(() => {
    let menuItems = document.querySelectorAll(".box-click-container");

    menuItems.forEach((el) => {
      el.addEventListener("click", (e) => {
        // const next = el.nextElementSibling;
        // console.log("next", next);
        removeActiveClass();
        menuItems.forEach((el) => el.classList.remove("active"));
        el.classList.toggle("active");
      });
    });
  });

  useEffect(() => {
    //
    dispatch(getListInterviewRequest());
    dispatch(getListClient());
    dispatch(getListStaff());
    dispatch(storeTitle({ title: "Interview", icon: mdiAccountVoice }));
  }, [dispatch]);

  return (
    <div className="main-div meeting">
      <div className="top-title-div cli-page">
        <div className="filter_container">
          <div className="filter_container_typelist">
            <div className="form-container-filter-button">
              <Button
                variant="primary"
                className="btn-plus"
                onClick={btnOpenAddInv}
              >
                <Icon path={mdiPlus} />
                <div>Add Interview</div>
              </Button>

              {/* Modal Popup Add Interview */}
              <Popup
                show={showInv}
                size="xl"
                backdrop="static"
                keyboard={false}
                onHide={btnCloseAddInv}
                title="Add New Interview"
                content={
                  <>
                    <Form id="create-iv-form" onSubmit={btnSaveUpdateInv}>
                      {/* 1 */}
                      <Row>
                        <Form.Group className="mb-3 col-4">
                          <Form.Label>
                            Company Name
                            <label className="validation-star">*</label>
                          </Form.Label>
                          <Searchable
                            name="ddlCompanyName"
                            isMulti={false}
                            options={clientActiveList}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.label}
                            onChange={handleChangeCompany}
                            errorText={errorForm.ddlCompanyName}
                            isInvalid={!!errorForm.ddlCompanyName}
                          ></Searchable>
                        </Form.Group>

                        <Form.Group className="mb-3 col-4">
                          <Form.Label>
                            Assignment Name
                            <label className="validation-star">*</label>
                          </Form.Label>
                          <Searchable
                            name="ddlJobTitle"
                            isMulti={false}
                            options={assignList}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.title}
                            onChange={handleChangeJob}
                            value={valueJobTitle}
                            errorText={errorForm.ddlJobTitle}
                            isInvalid={!!errorForm.ddlJobTitle}
                          ></Searchable>
                        </Form.Group>

                        <Form.Group className="mb-3 col-4">
                          <Form.Label>
                            Candidate Name
                            <label className="validation-star">*</label>
                          </Form.Label>
                          <Searchable
                            name="ddlCandidateName"
                            isMulti={false}
                            options={candidateAssignList}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.last_name ? option.first_name + " " + option.last_name : option.first_name}
                            onChange={handleChangeCandName}
                            value={valueCandName}
                            errorText={errorForm.ddlCandidateName}
                            isInvalid={!!errorForm.ddlCandidateName}
                          ></Searchable>
                        </Form.Group>
                      </Row>

                      {/* 2 */}
                      <Row>
                        <Form.Group className="mb-3 col-4">
                          <Form.Label>
                            Round of Interview{" "}
                            <label className="validation-star">*</label>
                          </Form.Label>
                          <Form.Select 
                            name="ddlStage"
                            isInvalid={!!errorForm.ddlStage}
                          >
                            <option value="">Please select</option>
                            {dataStage.map((stage, index) => (
                              <option key={index} value={stage.value}>
                                {stage.value}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errorForm.ddlStage}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3 col-4">
                          <Form.Label>Interview Location</Form.Label>
                          <Form.Control
                            type="text"
                            name="txtlocation"
                            placeholder="Please enter interview location"
                          />
                        </Form.Group>

                        <Form.Group className="mb-3 col-4">
                          <Form.Label>
                            Duration{" "}
                            <label className="validation-star">*</label>
                          </Form.Label>
                          <Form.Select 
                            name="ddlDuration"
                            isInvalid={!!errorForm.ddlDuration}
                          >
                            <option value="">Please select</option>
                            {dataDuration.map((dur, index) => (
                              <option key={index} value={dur.code}>
                                {dur.value}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errorForm.ddlDuration}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>

                      {/* 3 */}
                      {rowDateTime.map((row, index) => {
                          return (
                            <Row key={index}>
                              <Form.Group className="mb-3 col-6">
                                <Form.Label>
                                  Interview Date {index + 1}{" "}
                                  <label className="validation-star">*</label>
                                </Form.Label>
                                <Form.Control
                                  type="date"
                                  name="interview_date"
                                  value={row.interview_date}
                                  onChange={(event) =>
                                    handleFormChange(index, event)
                                  }
                                  isInvalid={!!errorForm.interview_date}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errorForm.interview_date}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className={rowDateTime.length > 1 ? "mb-3 col-5" : "mb-3 col-6"}>
                                <Form.Label>
                                  Interview Time {index + 1}{" "}
                                  <label className="validation-star">*</label>
                                </Form.Label>
                                <Form.Control
                                  type="time"
                                  name="interview_time"
                                  value={row.interview_time}
                                  onChange={(event) =>
                                    handleFormChange(index, event)
                                  }
                                  isInvalid={!!errorForm.interview_time}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errorForm.interview_time}
                                </Form.Control.Feedback>
                              </Form.Group>
                              {rowDateTime.length > 1 ? 
                              <Form.Group className="mb-3 col-1">
                                <Form.Label></Form.Label>
                                <div className="mt-3">
                                 
                                    <Button
                                    variant="link"
                                    className="modal-link"
                                    onClick={() => btnDelRowsDateTime(index)}
                                  >
                                    <Icon path={mdiCloseCircle} />
                                  </Button>
                                  
                                </div>
                              </Form.Group> : ""}
                            </Row>
                          );
                      })}
                     
                      {rowDateTime.length < 3 ? (
                        <div className="mb-2">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              variant="link"
                              className="modal-link"
                              onClick={btnAddRowsDateTime}
                            >
                              <Icon path={mdiPlusCircle} />
                              Alternate Interview Date & Time
                            </Button>
                          </div>
                        </div>
                      ) : null}

                      {/* 4 */}
                      <Form.Group className="mb-3">
                        <Form.Label>Interviewer Staff Client Name</Form.Label>
                        <Col>
                          <Searchable
                            name="ddlInterview_Client"
                            isMulti={true}
                            options={clientListStaff}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) =>
                              option.first_name + " " + option.last_name
                            }
                            onChange={handleStaffClient}
                            value={valueStaCliName}
                            // defaultValue={selectedClientStaff}
                            errorText="Fill in the blank!"
                          ></Searchable>
                        </Col>
                      </Form.Group>

                      {/* 5 */}
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Interviewer Staff Lim-Loges & Masters Name
                        </Form.Label>
                        <Col>
                          <Searchable
                            name="ddlInterview_LLM"
                            isMulti={true}
                            options={dataStaff}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) =>
                              option.first_name + " " + option.last_name
                            }
                            onChange={handleStaffLLM}
                            // defaultValue={selectedLLMStaff}
                            // errorText="Fill in the blank!"
                          ></Searchable>
                        </Col>
                      </Form.Group>

                      {/* 6 */}
                      <Form.Group className="mb-3">
                        <Form.Label>Additional Notes</Form.Label>
                        <Form.Control
                          name="txtaddnote"
                          as="textarea"
                          rows={3}
                        />
                      </Form.Group>
                    </Form>
                  </>
                }
                button={
                  <>
                    <Button variant="secondary" onClick={btnCloseAddInv}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      form="create-iv-form"
                    >
                      Create
                    </Button>
                  </>
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="split-container">
        <div className="left-split">
          <Tab.Container defaultActiveKey="upcoming">
            <Nav variant="tabs" justify className="dashboard-tab meeting">
              <Nav.Item>
                <Nav.Link eventKey="upcoming">Upcoming</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="past">Past</Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content className="tab-content-meeting">
              <Tab.Pane eventKey="upcoming">
                {interReqList.length > 0 ? (
                  <>
                    {interReqList.map((interview, index) => {
                      return (
                        <div 
                          className="box-click-container"
                          key={index}
                          onClick={btnShowInterview(
                            interview.id,
                          )}  
                        >
                          <div className="box-click-meeting">
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div className="meeting-title">
                                {interview.stage}
                              </div>
                              <span style={{ marginLeft: "10px" }}>
                                {interview.status === "accepted" ? 
                                  <Badge bg="success">Accepted</Badge> : 
                                 interview.status === "declined" ? 
                                  <Badge bg="danger">Declined</Badge> : 
                                  <Badge bg="primary">Waiting</Badge>
                                }
                              </span>
                            </div>
                            <div className="meeting-date">
                              <b>{interview.interview_date ? 
                                moment(interview.interview_date).format("DD MMMM YYYY") + ", "
                              : "Multiple interview times"}</b>

                              {interview.interview_time ? 
                               moment(interview.interview_time, ["HH:mm"]).format("hh:mm A")
                              : ""}
                            </div>
                            <div className="meeting-desc">
                              {interview.candidates.last_name ? interview.candidates.first_name + " " + interview.candidates.last_name : interview.candidates.first_name}
                              <Icon path={mdiCircleSmall} size={1} />
                              {interview.assignments.title}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="box-empty-container">
                    You have no upcoming meeting!
                  </div>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="past">
                {interReqListPast.length > 0 ? (
                  <>
                    {interReqListPast.map((interview, index) => {
                      return (
                        <div 
                          className="box-click-container"
                          key={index}
                          onClick={btnShowInterviewPast(interview.id)}  
                        >
                          <div className="box-click-meeting">
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div className="meeting-title">
                                {interview.stage}
                              </div>
                              <span style={{ marginLeft: "10px" }}>
                                {interview.status === "accepted" ? 
                                  <Badge bg="success">Accepted</Badge> : 
                                 interview.status === "declined" ? 
                                  <Badge bg="danger">Declined</Badge> : 
                                  <Badge bg="primary">Waiting</Badge>
                                }
                              </span>
                            </div>
                            <div className="meeting-date">
                              <b>{interview.interview_date ? 
                                moment(interview.interview_date).format("DD MMMM YYYY") + ", "
                              : "Multiple interview times"}</b>

                              {interview.interview_time ? 
                               moment(interview.interview_time, ["HH:mm"]).format("hh:mm A")
                              : ""}
                            </div>
                            <div className="meeting-desc">
                              {interview.candidates.last_name ? interview.candidates.first_name + " " + interview.candidates.last_name : interview.candidates.first_name}
                              <Icon path={mdiCircleSmall} size={1} />
                              {interview.assignments.title}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="box-empty-container">
                    You have no upcoming meeting!
                  </div>
                )}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        
        {interviewState.int_name ? (
          <div className="right-split">
          <div className="meeting-title">{interviewState.int_name} - {interviewState.int_assignName}</div>
          <div className="meeting-icon">
            <div>
              <Icon path={mdiCalendar} color="#54595F" />
              {interviewState.int_date ? interviewState.int_date : "Multiple interview times"}
            </div>

            {interviewState.int_date ? <div>
              <Icon path={mdiClockOutline} color="#54595F" />
              {interviewState.int_time}
            </div>: ""}
            
          </div>
          <div className="meeting-location">
            <Icon path={mdiMapMarkerOutline} color="#54595F" />
            <div>{interviewState.int_location}</div>
          </div>

          <div className="meeting-location">
            {/* {interviewState.int_date ?  */}
              <div className="meeting-status"
                style={{color:
                    interviewState.inv_status === "created" ? "#467FD0" : interviewState.inv_status === "declined" ? "#DF4759" : "#42BA96",
                }}
              >
                      {interviewState.inv_status === "created" ? "Waiting response" : interviewState.inv_status === "declined" ?  "Interview request declined!" : "Interview request accepted!"}
              </div>
              {/* : ""} */}
            
                  {/* <div>
                    {interviewState.inv_status === "declined"
                      ? "Reason : " + meetState.meet_reason
                      : ""}
                  </div> */}
          </div>

          <hr />

          <div className="meeting-overflow">
            <div className="meeting-main-cont">
              <div className="meeting-subheader">Candidate Name</div>
              <div className="meeting-sub-info">
                <Button variant="link" className="modal-link" onClick={btnLinkCandidate(interviewState.int_candId)}>
                  {interviewState.int_candName}
                </Button>
              </div>
            </div>

            <div
              className="meeting-main-cont"
              style={{ paddingBottom: "10px" }}
            >
              <div className="meeting-subheader">Company Name</div>
              <div className="meeting-sub-info">
                <Button variant="link" className="modal-link" onClick={btnLinkClient(interviewState.int_compId)}>
                  {interviewState.int_compName}
                </Button>
              </div>
            </div>

            <hr />

            <div className="meeting-main-cont">
              <div className="meeting-subheader">
                Interviewer Name (Lim-Loges & Masters)
              </div>
              <div className="meeting-person">{interviewState.int_staffAdmin ? interviewState.int_staffAdmin : "-"}</div>
            </div>

            <div className="meeting-main-cont">
              <div className="meeting-subheader">
                Interviewer Name (Company Name)
              </div>
              <div className="meeting-person">{interviewState.int_staffClient ? interviewState.int_staffClient : "-"}</div>
            </div>

            <div className="meeting-main-cont">
              <div className="meeting-subheader">Additional Note</div>
              <div className="meeting-sub-info">
                <div className="meeting-purpose">
                  {interviewState.int_addNote ? interviewState.int_addNote : "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
        ): (
          <div className="right-split">
            {/* Content If Empty */}
            <div className="meeting-empty">
              <Icon
                path={mdiTextBoxMultipleOutline}
                // size={4}
                color={"#E5E5E5"}
              />
              <div className="meeting-empty-wording">
                It looks like you have not select any meetings.
                <br />
                Please select any upcoming/past meeting to view the details.
              </div>
            </div>
          </div>
        )}
        
      </div>
    </div>
  );
};

export default Interview;
