import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Button, Form } from "react-bootstrap";
import Api from "../../../core/services/api.service";

import {
  mdiFilter,
  mdiMagnify,
  mdiDelete,
  mdiDotsHorizontal,
  mdiPencil,
  mdiPlus,
  mdiCardAccountDetails,
} from "@mdi/js";
import Icon from "@mdi/react";

import TextboxIcon from "../../../components/TextField/TextFieldIcon";
import CardInfo from "../../../components/Card/CardInfo";
import CustomTable from "../../../components/Table/Table";

import { storeTitle } from "../../../store/actions/headerTitleSlice";
import { getListRole } from "../../../store/actions/roleSlice";
import { storePage } from "../../../store/actions/paginationSlice";

const Staff = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dataRole } = useSelector((state) => state.role);

  const [clicked, setClicked] = useState(false);
  const [listStaff, setListStaff] = useState([]);
  const [allData, setAllData] = useState(listStaff);

  // Button Event
  const btnCollapse = (index) => {
    if (clicked === index) {
      //if clicked is already active, then close
      return setClicked(null);
    }

    setClicked(index);
  };

  const btnReset = () => {
    document.getElementById("filter-staff-form").reset();
    // setTextStatus("");
  };

  const btnAddStaff = () => {
    navigate("/addStaff");
  };
  // End Button Event

  // handle event

  const [valueSearch, setValueSearch] = useState("");
  const [tempStaffData, setTempStaffData] = useState([]);
  // const [textStatus, setTextStatus] = useState("");

  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();

    dispatch(storePage(0));

    setValueSearch(value);

    if (value !== "") {
      let newList = [];
      newList = listStaff.filter((user) => {
        return (
          user.first_name.toLowerCase().includes(value.toLowerCase()) ||
          user.last_name.toLowerCase().includes(value.toLowerCase())
        );
      });

      setTempStaffData(newList);
      setAllData(newList);
    } else {
      setAllData(listStaff);
    }
    // if(textStatus !== ""){

    // }else{

    // }
  };

  const btnFilter = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtFilter = Object.fromEntries(data.entries());

    dispatch(storePage(0));

    if (valueSearch === "") {
      let newList = [];

      if (dtFilter.ddlRole === "") {
        newList = listStaff.filter((staff) => {
          return dtFilter.ddlStatus
            ? staff.status.toLowerCase() === dtFilter.ddlStatus
            : staff.status.toLowerCase().includes(dtFilter.ddlStatus);
        });
      } else {
        newList = listStaff.filter((staff) => {
          return (
            staff.status.toLowerCase().includes(dtFilter.ddlStatus) &&
            staff.user_has_roles.find((o) => o.roles.name === dtFilter.ddlRole)
          );
        });
      }

      setAllData(newList);
    } else {
      if (dtFilter.ddlStatus !== "" || dtFilter.ddlRole !== "") {
        let newList = [];

        if (dtFilter.ddlRole === "") {
          newList = tempStaffData.filter((staff) => {
            return dtFilter.ddlStatus
              ? staff.status.toLowerCase() === dtFilter.ddlStatus
              : staff.status.toLowerCase().includes(dtFilter.ddlStatus);
          });
        } else {
          newList = tempStaffData.filter((staff) => {
            return (
              staff.status.toLowerCase().includes(dtFilter.ddlStatus) &&
              staff.user_has_roles.find(
                (o) => o.roles.name === dtFilter.ddlRole
              )
            );
          });
        }

        setAllData(newList);
      } else {
        setAllData(tempStaffData);
      }
    }
  };

  // Load
  useEffect(() => {
    dispatch(
      storeTitle({ title: "List of Staff", icon: mdiCardAccountDetails })
    );
    dispatch(getListRole());

    Api.get("user/list")
      .then((response) => {
        // console.log("user/list", response.data);
        setListStaff(response.data);
        setAllData(response.data);
      })
      .catch((error) => {
        console.log("user/list", error);
      });
  }, [dispatch]);

  // Headers and value table
  const headers = [
    {
      Header: "Staff Name",
      accessor: (row) => `${row.first_name}`,
      maxWidth: 300,
      minWidth: 200,
      width: 350,
      Cell: ({ row }) => {
        const data = row.original;

        function handleClick() {
          navigate("/viewStaff/" + data.id);
        }

        return (
          <div className="form-container-default">
            {/* <div className="form-container-default-2"> */}
            <Button
              variant="link"
              className="no-underline"
              onClick={handleClick}
            >
              {data.first_name} {data.last_name}
            </Button>
            {/* </div> */}
          </div>
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",
      maxWidth: 300,
      minWidth: 200,
      width: 350,
    },
    {
      Header: "Phone Number",
      // accessor: "phone_no",
      maxWidth: 200,
      minWidth: 100,
      width: 180,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            {data.phone_no === "" || !!data.phone ? "-" : data.phone_no}
          </div>
        );
      },
    },
    {
      Header: "Role",
      accessor: (row) =>
        `${
          row.user_has_roles.length > 0 ? row.user_has_roles[0].roles.name : ""
        }`,
      maxWidth: 200,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            {data.user_has_roles.length > 0
              ? data.user_has_roles[0].roles.name
              : "-"}
          </div>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      maxWidth: 120,
      minWidth: 120,
      width: 120,
      Cell: ({ row }) => {
        const data = row.original;

        return data.status === "Not Active" ? (
          <CardInfo
            cardTextSize="card_text--small"
            cardTextStyle="card_text--danger--solid"
          >
            {data.status}
          </CardInfo>
        ) : (
          <CardInfo
            cardTextSize="card_text--small"
            cardTextStyle="card_text--primary--solid"
          >
            {data.status}
          </CardInfo>
        );
      },
    },
    {
      Header: "",
      accessor: "name",
      disableSortBy: true,
      maxWidth: 70,
      minWidth: 10,
      width: 70,
      Cell: ({ row }) => {
        const data = row.original;

        function handleClick() {
          navigate("/editStaff/list/" + data.id);
        }

        return (
          <div className="dropdown-container list-staff">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleClick}>
                  <Icon path={mdiPencil} color="#467FD0" />
                  Edit Staff
                </Dropdown.Item>
                <Dropdown.Item>
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete Staff
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];
  // End Headers and value

  return (
    <div className="main-div list-staff">
      <div className="top-title-div">
        <div className="filter_container">
          <div className="filter_container_totalrow">
            <div className="form-container-default">
              <div className="total_client_text">{allData.length}</div>
              <div className="client_text">staff</div>
            </div>
          </div>

          <div className="upper-divider"></div>

          <div className="filter_container_search">
            <div className="filter_container_search-1">
              <TextboxIcon
                name="name"
                type="text"
                onChange={(event) => handleSearch(event)}
                placeholder="Search by name"
                inputSize="input--medium"
                icon={mdiMagnify}
              ></TextboxIcon>
            </div>
          </div>

          <div className="filter_container_typelist">
            <div className="form-container-filter-button">
              <Button
                onClick={btnAddStaff}
                variant="primary"
                className="btn-plus"
              >
                <Icon path={mdiPlus} />
              </Button>
              <Button onClick={() => btnCollapse(true)} variant="secondary">
                <Icon path={mdiFilter} />
                <div>Filter</div>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div id="demo" className={"collapses" + (clicked ? " in" : "")}>
        <Form id="filter-staff-form" onSubmit={btnFilter}>
          <div className="form-container-filter">
            <div className="form-container-filter-1">
              <Form.Group>
                <Form.Label>Role</Form.Label>
                <Form.Select name="ddlRole">
                  <option value="">Select</option>
                  {dataRole.map((role, index) => (
                    <option key={index} value={role.name}>
                      {role.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="form-container-filter-1">
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Select
                  name="ddlStatus"
                  // onChange={(e) => setTextStatus(e.target.value)}
                >
                  <option value="">Select</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Form.Select>
              </Form.Group>
            </div>
          </div>
        </Form>
        <div className="row-btn-bottom">
          <Button variant="secondary" onClick={btnReset}>
            Reset
          </Button>
          <Button variant="primary" type="submit" form="filter-staff-form">
            Search
          </Button>
        </div>
      </div>

      <div className="container-div list-staff">
        <CustomTable
          headers={headers}
          items={allData}
          paginate={allData.length}
          maxRows={15}
          pindex={true}
        ></CustomTable>
      </div>
    </div>
  );
};

export default Staff;
