import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Api from "../../core/services/api.service";
import Apiform from "../../core/services/apiform.service";

import SwalAlert from "../Swal/SwalAlert";
import FileSaver from "file-saver";
import moment from "moment";
import AuthAbilities from "../../pages/Admin/Auth/AuthAbilities";
import HTMLtoDOCX from "html-to-docx";

import {
  mdiFilePlus,
  mdiDotsHorizontal,
  mdiDownload,
  mdiPencil,
  mdiDelete,
  mdiUpload,
  mdiEmail,
  mdiCircleMedium,
  mdiViewAgenda,
} from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Dropdown, Form, Row, Col, Card } from "react-bootstrap";

import Table from "../Table/Table";
import CardInfo, { CardInfoBtn } from "../Card/CardInfo";
import Modal from "../Popup/ModalPopup";
import CustomTable from "../Table/Table";
import rptProposal from "../Report/Template_Report/rptProposal";
import rptHeader from "../Report/Template_Report/rptHeader";
import rptFooter from "../Report/Template_Report/rptFooter";
import Viewer from "../Viewer/Viewer";
// import TextboxIcon from "../TextField/TextFieldIcon";
// import { rptProposal } from "../Report/Template/rptProposal";

export const TabProposal = (props) => {
  const navigate = useNavigate();
  const params = useParams();

  const abilities = AuthAbilities();

  const { clientDetails } = useSelector((state) => state.client);

  const [allListStaff, setAllListStaff] = useState([]);
  const [proposalList, setProposalList] = useState([]);
  const [showUpload, setShowUpload] = useState(false);

  const [selRowProposal, setSelRowProposal] = useState({});
  const [errorForm, setErrorForm] = useState({});

  const [modalProposal, setModalProposal] = useState(false);
  const [showUploadProposal, setShowUploadProposal] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  const [errorFormPro, setErrorFormPro] = useState({});

  const [modalReason, setModalReason] = useState(false);

  // PDF useState
  const [pageNumber, setPageNumber] = useState(1);
  const [showPdf, setShowPdf] = useState(false);
  const [filePath, setFilePath] = useState("");
  const [modeModal, setModeModal] = useState({
    title: "",
    Button: "",
  });

  const customSwal = SwalAlert();

  const createProposal = () => {
    navigate("/clients/create-proposal/" + props.page + "/" + props.id);
  };

  // Start Choose Service
  const btnOpenProposal = () => {
    setModalProposal(true);
    setBtnDisabled(true);
  };

  const btnCloseProposal = () => {
    setModalProposal(false);
    setShowUploadProposal(false);
  };

  const changeProposal = (e) => {
    // setTypeProposal(e.target.value);
    if (e.target.value === "TM" || e.target.value === "DM") {
      setShowUploadProposal(true);
    } else if (e.target.value === "ES") {
      setShowUploadProposal(false);
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
      setShowUploadProposal(false);
    }

    setErrorFormPro({});
  };

  const btnSaveTMDM = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtTDM = Object.fromEntries(data.entries());

    const newErrors = formErrorPro(dtTDM);

    // console.log("dtTDM", dtTDM)
    if (Object.keys(newErrors).length > 0) {
      setErrorFormPro(newErrors);
    } else {
      setErrorFormPro({});

      let titleType = "";

      if (dtTDM.ddlProposal === "TM") {
        titleType = "Transition Management";
      } else if (dtTDM.ddlProposal === "DM") {
        titleType = "Distruption Management";
      } else {
        titleType = "Executive Search";
      }

      customSwal
        .fire({
          title: "Do you want to add " + titleType + " ?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();

            formData.append("file", dtTDM.txtfile);
            formData.append("file_name", dtTDM.txtfilename);
            formData.append("type", dtTDM.ddlProposal);

            Apiform.post(
              "client/" + params.id + "/proposal/create/tmdm",
              formData
            )
              .then((response) => {
                // dispatch(getClientDetails(params.id));

                getListProposalTMDM();
                setModalProposal(false);
                customSwal.fire({
                  title: "Saved Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("error upload file", error);
              });
          }
        });
    }
  };

  // Checking Form Error
  const formErrorPro = (object) => {
    const newErrors = {};

    // console.log("object", object);

    if (!object.txtfilename || object.txtfilename === "")
      newErrors.txtfilename = "Fill in the blank!";

    if (!object.txtfile.name || object.txtfile.name === "")
      newErrors.txtfile = "Fill in the blank!";

    return newErrors;
  };
  // End Choose Service

  const downloadDocx = (data) => async () => {
    // console.log("data", data);
    // rptProposal(data);
    const dataRpt = {
      date_rpt: moment(data.created_at).format("DD MMMM YYYY"),
      company_overview: data.company_overview,
      requirement: data.requirement,
      job_title: data.job_title,
      client_proposal_has_user: data.client_proposal_has_user,
      domestic_fee: data.domestic_fee,
      regional_fee: data.regional_fee,
      expected_compensation: data.expected_compensation,
      payment_term: data.payment_term,
      indirect_expenses: data.indirect_expenses,
      compound_interest: data.compound_interest,
      subseq_cand_fee: data.subseq_cand_fee,
      off_limit_restriction: data.off_limit_restriction,
      guaranty_period: data.guaranty_period,
      guaranty_condition: data.guaranty_condition,
      company_name: clientDetails.name,
      sign_name:
        data.client_proposal_has_user[0].user.first_name +
        " " +
        data.client_proposal_has_user[0].user.last_name,
    };

    const htmlString = rptProposal(dataRpt);
    const headerString = rptHeader();
    const footerHTMLString = rptFooter();

    // uncomment the following
    const fileBuffer = await HTMLtoDOCX(
      htmlString,
      headerString,
      {
        table: { row: { cantSplit: true } },
        header: true,
        footer: true,
        pageNumber: true,
        margins: {
          top: 500,
          right: 1500,
          bottom: 500,
          left: 1500,
          header: 720,
          footer: 720,
          gutter: 0,
        },
        font: "Calibri (Body)",
      },
      footerHTMLString
    );

    // console.log("fileBuffer", fileBuffer)
    FileSaver.saveAs(fileBuffer, data.file_name + ".docx");
  };

  const btnEditProposal = (idrow) => () => {
    // console.log(idrow);
    navigate(
      "/clients/edit-proposal/" + props.page + "/" + props.id + "/" + idrow
    );
  };

  const btnUploadProposal = (data) => (e) => {
    // console.log("data", data)
    setErrorForm({});
    setSelRowProposal(data);
    setShowUpload(true);
  };

  const btnClosePdf = () => {
    setShowPdf(false);
    setPageNumber(1);
  };

  const btnCloseUpload = () => {
    setShowUpload(false);
  };

  const formErrorUpload = (object) => {
    const newErrors = {};

    // console.log("object", object);

    if (!object.txtfile.name || object.txtfile.name === "")
      newErrors.txtfile = "Fill in the blank!";

    return newErrors;
  };

  const btnSaveUpload = (idPro) => (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtUpload = Object.fromEntries(data.entries());

    // console.log("dtUpload", dtUpload);
    const newErrors = formErrorUpload(dtUpload);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      customSwal
        .fire({
          title: "Do you want to upload signed proposal ?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();

            formData.append("file", dtUpload.txtfile);

            Apiform.post(
              "client/" +
                params.id +
                "/proposal/" +
                idPro +
                "/uploadSignedProposal",
              formData
            )
              .then((response) => {
                getListProposal();

                setShowUpload(false);
                customSwal.fire({
                  title: "File Uploaded Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("error btnSaveUpload", error);
              });
          }
        });
    }
  };

  const [listReason, setListReason] = useState([]);

  const btnOpenReason = (data) => () => {
    // console.log("data", data)
    setModalReason(true);
    setListReason(data);
  };

  const btnCloseReason = () => {
    setModalReason(false);
  };

  const headerReason = [
    {
      Header: "No.",
      accessor: (row) => `${row.index}`,
      minWidth: 50,
      width: 50,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Date",
      accessor: (row) => `${row.created_at}`,
      minWidth: 120,
      width: 120,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{moment(data.created_at).format("DD MMMM YYYY")}</div>;
      },
    },
    {
      Header: "Reason for Rejection",
      minWidth: 200,
      width: 300,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{data.reason_rejected}</div>;
      },
    },
  ];

  const headerProposal = [
    {
      Header: "No",
      maxWidth: 50,
      minWidth: 10,
      width: 70,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "File Name",
      accessor: "file_name",
      maxWidth: 500,
      minWidth: 100,
      width: 500,
    },
    {
      Header: "Created Date",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{moment(data.created_at).format("DD MMMM YYYY")}</div>;
      },
    },
    {
      Header: "Created By",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            {data.created_by_users.first_name} {data.created_by_users.last_name}
          </div>
        );
      },
    },
    {
      Header: "Signed Proposal",
      maxWidth: 400,
      minWidth: 100,
      width: 250,
      Cell: ({ row }) => {
        const data = row.original;

        function btnDownSigned() {
          FileSaver.saveAs(data.signed_proposal);
        }

        return (
          <>
            {data.signed_proposal ? (
              <Button variant="link" onClick={btnDownSigned}>
                <Icon path={mdiDownload} color="#467FD0" />
                Download
              </Button>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      Header: "Status",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        let cardColor = "";

        if (data.status === "completed") {
          cardColor = "card_text--warning--solid";
        } else if (data.status === "draft") {
          cardColor = "card_text--default--solid";
        } else if (data.status === "rejected") {
          cardColor = "card_text--hold--solid";
        } else if (data.status === "approved") {
          cardColor = "card_text--completed--solid";
        } else {
          cardColor = "card_text--primary--solid";
        }

        return (
          <>
            {data.status === "rejected" ? (
              <CardInfoBtn
                cardTextSize="card_text--small"
                cardTextStyle="card_text--danger--solid"
                btn={btnOpenReason(data.client_proposal_logs)}
              >
                {data.status.replace(/_/g, " ")}
              </CardInfoBtn>
            ) : (
              <CardInfo
                cardTextSize="card_text--small"
                cardTextStyle={cardColor}
              >
                {data.status.replace(/_/g, " ")}
              </CardInfo>
            )}
          </>
        );
      },
    },
    {
      Header: " ",
      accessor: "actions",
      maxWidth: 100,
      minWidth: 10,
      width: 70,
      Cell: ({ row }) => {
        const data = row.original;

        // let proposal_user = data.client_proposal_has_user;
        // console.log("data", proposal_user);

        // var email = proposal_user.map((prs) => {
        //   // console.log(prs.user.email);
        //   return prs.user.email + ";";
        // });

        // var arr_email = email.join(" ");

        // const btnSendProposal = () => {
        //   customSwal
        //     .fire({
        //       title: "Do you want to send this proposal to client?",
        //       icon: "warning",
        //       showCancelButton: true,
        //       reverseButtons: true,
        //       confirmButtonText: "Yes, send it!",
        //     })
        //     .then((result) => {
        //       if (result.isConfirmed) {
        //         Api.patch(
        //           "client/ " + params.id + "/proposal/" + data.id + "/update",
        //           {
        //             status: "completed",
        //           }
        //         )
        //           .then((response) => {
        //             getListProposal();
        //           })
        //           .catch((error) => {
        //             console.log("error btnSendProposal", error);
        //           });
        //       }
        //     });
        // };

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle
                // disabled={data.status === "proposal" ? true : false}
                variant="light"
                id="dropdown-basic"
              >
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={downloadDocx(data)}>
                  <Icon path={mdiDownload} color="#467FD0" />
                  Download Proposal
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={data.status === "proposal" ? true : false}
                  onClick={btnEditProposal(data.id)}
                  hidden={
                    props.page === "active"
                      ? !abilities.includes("edit proposal")
                      : !abilities.includes("edit proposal")
                  }
                >
                  <Icon path={mdiPencil} color="#467FD0" />
                  Edit Proposal
                </Dropdown.Item>
                {/* {data.status === "proposal" ? (
                  <Dropdown.Item
                    onClick={btnSendProposal}
                    // onClick={() =>
                    //   (window.location = "mailto:yourmail@domain.com")
                    // }
                  >
                    <Icon path={mdiFileSend} color="#4CAF50" />
                    Send Proposal to Client
                  </Dropdown.Item>
                ) : (
                  ""
                )} */}
                <Dropdown.Item
                  disabled={data.status === "proposal" ? true : false}
                  hidden={!abilities.includes("delete proposal")}
                >
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete Proposal
                </Dropdown.Item>
                <Dropdown.Item
                  // onClick={() => (window.location = "mailto:" + arr_email)}
                  onClick={openEmail(data.file_name)}
                >
                  <Icon path={mdiEmail} color="#42BA96" />
                  Email Proposal
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  disabled={data.status === "proposal" ? true : false}
                  onClick={btnUploadProposal(data)}
                >
                  <Icon path={mdiUpload} color="#467FD0" />
                  Upload Proposal
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const headerProposalTM = [
    {
      Header: "No",
      maxWidth: 50,
      minWidth: 10,
      width: 70,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "File Name",
      accessor: "file_name",
      maxWidth: 500,
      minWidth: 100,
      width: 500,
    },
    {
      Header: "Created Date",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{moment(data.created_at).format("DD MMMM YYYY")}</div>;
      },
    },
    {
      Header: " ",
      accessor: "actions",
      maxWidth: 100,
      minWidth: 10,
      width: 70,
      Cell: ({ row }) => {
        const data = row.original;

        function btnDownloadTM() {
          FileSaver.saveAs(data.file_path, "download.pdf");
        }

        function btnPreview() {
          setFilePath(data.file_path);
          setShowPdf(true);

          setModeModal({
            title: "View Proposal",
            button: "",
          });
        }

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={btnPreview}>
                  <Icon path={mdiViewAgenda} color="#467FD0" />
                  Preview Proposal
                </Dropdown.Item>
                <Dropdown.Item onClick={btnDownloadTM}>
                  <Icon path={mdiDownload} color="#467FD0" />
                  Download Proposal
                </Dropdown.Item>
                {/* <Dropdown.Item
                  onClick={btnEditProposal(data.id)}
                  hidden={
                    props.page === "active"
                      ? !abilities.includes("edit proposal")
                      : !abilities.includes("edit proposal")
                  }
                >
                  <Icon path={mdiPencil} color="#467FD0" />
                  Edit Proposal
                </Dropdown.Item> */}
                {/* {data.status === "proposal" ? (
                  <Dropdown.Item
                    onClick={btnSendProposal}
                    // onClick={() =>
                    //   (window.location = "mailto:yourmail@domain.com")
                    // }
                  >
                    <Icon path={mdiFileSend} color="#4CAF50" />
                    Send Proposal to Client
                  </Dropdown.Item>
                ) : (
                  ""
                )} */}
                <Dropdown.Item hidden={!abilities.includes("delete proposal")}>
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete Proposal
                </Dropdown.Item>
                <Dropdown.Item
                  // onClick={() => (window.location = "mailto:" + arr_email)}
                  onClick={openEmail(data.file_name)}
                >
                  <Icon path={mdiEmail} color="#42BA96" />
                  Email Proposal
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const headerProposalDM = [
    {
      Header: "No",
      maxWidth: 50,
      minWidth: 10,
      width: 70,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "File Name",
      accessor: "file_name",
      maxWidth: 500,
      minWidth: 100,
      width: 500,
    },
    {
      Header: "Created Date",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{moment(data.created_at).format("DD MMMM YYYY")}</div>;
      },
    },
    {
      Header: " ",
      accessor: "actions",
      maxWidth: 100,
      minWidth: 10,
      width: 70,
      Cell: ({ row }) => {
        const data = row.original;

        function btnDownloadDM() {
          FileSaver.saveAs(data.file_path, "download.pdf");
        }

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={btnDownloadDM}>
                  <Icon path={mdiDownload} color="#467FD0" />
                  Download Proposal
                </Dropdown.Item>
                {/* <Dropdown.Item
                  onClick={btnEditProposal(data.id)}
                  // hidden={
                  //   props.page === "active"
                  //     ? !abilities.includes("edit proposal")
                  //     : !abilities.includes("edit proposal")
                  // }
                >
                  <Icon path={mdiPencil} color="#467FD0" />
                  Edit Proposal
                </Dropdown.Item> */}
                {/* {data.status === "proposal" ? (
                  <Dropdown.Item
                    onClick={btnSendProposal}
                    // onClick={() =>
                    //   (window.location = "mailto:yourmail@domain.com")
                    // }
                  >
                    <Icon path={mdiFileSend} color="#4CAF50" />
                    Send Proposal to Client
                  </Dropdown.Item>
                ) : (
                  ""
                )} */}
                <Dropdown.Item hidden={!abilities.includes("delete proposal")}>
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete Proposal
                </Dropdown.Item>
                <Dropdown.Item
                  // onClick={() => (window.location = "mailto:" + arr_email)}
                  onClick={openEmail(data.file_name)}
                >
                  <Icon path={mdiEmail} color="#42BA96" />
                  Email Proposal
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const getListProposal = useCallback(() => {
    Api.get("client/" + params.id + "/proposal/list")
      .then((response) => {
        let data = response.data;
        // console.log("data list proposal", data)
        if (data !== "no data") {
          setProposalList(data);
        }
      })
      .catch((error) => {
        console.log("error getListProposal", error);
      });
  }, [params.id]);

  const [listProposalTM, setListProposalTM] = useState([]);
  const [listProposalDM, setListProposalDM] = useState([]);

  const getListProposalTMDM = useCallback(() => {
    Api.get("client/" + params.id + "/proposal/list/tmdm/all")
      .then((response) => {
        let data = response.data;
        // console.log("data list proposal", data)
        if (data !== "no data") {
          let listProTM = data.filter((act) => act.type === "TM");

          setListProposalTM(listProTM);

          let listProDM = data.filter((act) => act.type === "DM");

          setListProposalDM(listProDM);
        }
      })
      .catch((error) => {
        console.log("error getListProposalTMDM", error);
      });
  }, [params.id]);

  const getListStaff = useCallback(() => {
    Api.get("client/" + params.id + "/staff/listStaff")
      .then((response) => {
        let data = response.data;

        setAllListStaff(data);
      })
      .catch((error) => {
        console.log("error list client", error);
      });
  }, [params]);

  const [showStaff, setShowStaff] = useState(false);
  const [staffSelected, setStaffSelected] = useState(0);
  const [checkStaff, setCheckStaff] = useState([]);
  const [fileName, setFileName] = useState();

  const openEmail = (file_name) => (e) => {
    setFileName(file_name);
    setShowStaff(true);
    getListStaff();
    setCheckStaff([]);
    setStaffSelected(0);
  };

  const closeEmail = () => {
    setShowStaff(false);
  };

  const headerSelect = [
    {
      Header: "Status",
      hideHeader: true,
      minWidth: 50,
      width: 50,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            <Icon
              path={mdiCircleMedium}
              color={data.status === "client" ? "#ffd028" : "#467FD0"}
              size={1}
            />
          </div>
        );
      },
    },
    {
      Header: "Name",
      minWidth: 100,
      width: 200,
      accessor: (row) => `${row.first_name}`,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="form-container-default">
            {data.last_name
              ? data.first_name + " " + data.last_name
              : data.last_name}
          </div>
        );
      },
    },
    {
      Header: "Country",
      accessor: (row) => `${row.citizenship_main}`,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="form-container-default">
            {data.citizenship_main ? data.citizenship_main : "-"}
          </div>
        );
      },
    },
    {
      Header: "Job Title",
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        return <>{data.job_title !== null ? data.job_title : "-"}</>;
      },
    },
    {
      Header: "",
      accessor: "selection",
      disableSortBy: true,
      minWidth: 10,
      width: 50,
      Cell: ({ row }) => {
        const data = row.original;

        const onItemCheck = (data) => (event) => {
          if (checkStaff.length > 0) {
            if (event.target.checked === false) {
              const index = checkStaff.findIndex(function (chck) {
                return chck.id === data.id;
              });

              checkStaff.splice(index, 1);
            } else {
              checkStaff.push(data);
            }
          } else {
            checkStaff.push(data);
          }

          setStaffSelected(checkStaff.length);
        };

        const checkedItem = (id) => {
          let cnd = [];

          const checkCand = checkStaff.filter((chec) => {
            return chec.id === id;
          });

          cnd = checkCand[0];

          return cnd;
        };

        return (
          <div className="checkbox-container">
            <input
              type="checkbox"
              checked={checkedItem(data.id)}
              id={"row" + data.id}
              onChange={onItemCheck(data)}
            />
          </div>
        );
      },
    },
  ];

  const btnSendEmail = (e) => {
    let email = "";
    // let subject = fileName;

    // console.log("subject", subject);

    var staff = checkStaff.map((s) => {
      if (s.comp_email !== "" && s.comp_email !== null) {
        email = s.comp_email;
      } else if (s.email !== "" && s.email !== null) {
        email = s.email;
      }

      return email;
    });

    // console.log("staff", staff);

    var multi_email = staff.map((mail) => {
      // console.log(mail);
      return mail + ";";
    });

    var arr_email = multi_email.join("").slice(0, -1);
    // console.log("arr_email", arr_email);
    // console.log("mailto:" + arr_email + "?subject=" + fileName);

    customSwal
      .fire({
        title: "Send proposal to selected email?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          window.location = "mailto:" + arr_email + "?subject=" + fileName;

          setShowStaff(false);
          getListStaff();
          setCheckStaff([]);
          setStaffSelected(0);
        }
      });
  };

  // Load View Potential
  useEffect(() => {
    getListProposal();
    getListStaff();
    getListProposalTMDM();
  }, [getListProposal, getListProposalTMDM, getListStaff]);

  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        {props.page === "active" ? (
          <div className="row-div">
            <div className="left-div"></div>
            <div className="right-div">
              <Button
                variant="primary"
                // className="modal-link"
                hidden={!abilities.includes("add proposal")}
                onClick={btnOpenProposal}
              >
                <Icon path={mdiFilePlus} />
                <div>Create Proposal</div>
              </Button>
            </div>
          </div>
        ) : null}
      </div>
      {clientDetails.is_potential === true &&
      clientDetails.status !== null &&
      clientDetails !== "closed" ? (
        <Card>
          <Card.Body>
            <div className="row-div">
              <div className="left-div">
                <div className="title-text report">Executive Service (ES)</div>
              </div>
              {/* {proposalList.length === 0 ? ( */}
              <div className="right-div">
                <Button
                  variant="link"
                  className="modal-link"
                  hidden={!abilities.includes("add proposal")}
                  onClick={createProposal}
                >
                  <Icon path={mdiFilePlus} />
                  <div>Create Proposal</div>
                </Button>
              </div>
              {/* ) : null} */}
            </div>

            <Table
              headers={headerProposal}
              items={proposalList}
              paginate={proposalList.length}
              maxRows={15}
            ></Table>
          </Card.Body>
        </Card>
      ) : (
        <>
          <Card style={{ marginBottom: "20px" }}>
            <Card.Body>
              <div className="row-div">
                <div className="left-div">
                  <div className="title-text report">
                    Executive Service (ES)
                  </div>
                </div>
              </div>

              <Table
                headers={headerProposal}
                items={proposalList}
                paginate={proposalList.length}
                maxRows={15}
              ></Table>
            </Card.Body>
          </Card>
          <Card style={{ marginBottom: "20px" }}>
            <Card.Body>
              <div className="row-div">
                <div className="left-div">
                  <div className="title-text report">
                    Transition Management (TM)
                  </div>
                </div>
              </div>

              <Table
                headers={headerProposalTM}
                items={listProposalTM}
                paginate={listProposalTM.length}
                maxRows={15}
              ></Table>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <div className="row-div">
                <div className="left-div">
                  <div className="title-text report">
                    Distruption Management (DM)
                  </div>
                </div>
              </div>

              <Table
                headers={headerProposalDM}
                items={listProposalDM}
                paginate={listProposalDM.length}
                maxRows={15}
              ></Table>
            </Card.Body>
          </Card>
        </>
      )}
      {/* Modal Upload Proposal */}
      <Modal
        show={showUpload}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseUpload}
        title="Upload Proposal"
        content={
          <>
            <Form
              id="upload-pro-form"
              onSubmit={btnSaveUpload(selRowProposal.id)}
            >
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label className="col-3">File Name</Form.Label>
                  <div className="col-9" style={{ display: "inline-flex" }}>
                    {selRowProposal.file_name}
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label className="col-3">Signed Proposal</Form.Label>
                  <div className="col-9" style={{ display: "inline-flex" }}>
                    <Form.Control
                      name="txtfile"
                      type="file"
                      isInvalid={!!errorForm.txtfile}
                    />
                  </div>
                </Form.Group>
              </Row>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseUpload}>
              Cancel
            </Button>
            <Button type="submit" form="upload-pro-form" variant="primary">
              Upload
            </Button>
          </>
        }
      ></Modal>
      {/* Modal Choose Proposal */}
      <Modal
        show={modalProposal}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseProposal}
        title="Select Service"
        content={
          <>
            <Form id="upload-proposal-form" onSubmit={btnSaveTMDM}>
              <Row>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="col-3"></Form.Label>
                  <Col sm="9">
                    <div className="sub-text">
                      Which service would you like to create this for?
                    </div>
                  </Col>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="col-3">
                    Service Name <label className="validation-star">*</label>
                  </Form.Label>
                  <Col sm="9">
                    <Form.Select name="ddlProposal" onChange={changeProposal}>
                      <option value="">Select</option>
                      <option value="ES">Executive Search</option>
                      <option value="TM">Transition Management</option>
                      <option value="DM">Distruption Management</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Row>
              {showUploadProposal && (
                <>
                  <Row>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label className="col-3">
                        File Name <label className="validation-star">*</label>
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          name="txtfilename"
                          type="text"
                          isInvalid={!!errorFormPro.txtfilename}
                        />
                      </Col>
                      <Form.Control.Feedback type="invalid">
                        {errorFormPro.txtfilename}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label className="col-3">
                        Upload Proposal{" "}
                        <label className="validation-star">*</label>
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          name="txtfile"
                          type="file"
                          isInvalid={!!errorFormPro.txtfile}
                        />
                      </Col>
                      <Form.Control.Feedback type="invalid">
                        {errorFormPro.txtfile}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                </>
              )}
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseProposal}>
              Cancel
            </Button>
            {showUploadProposal ? (
              <Button
                type="submit"
                variant="primary"
                form="upload-proposal-form"
              >
                Upload Proposal
              </Button>
            ) : (
              <Button
                type="submit"
                variant="primary"
                onClick={createProposal}
                disabled={btnDisabled}
              >
                Create Proposal
              </Button>
            )}
          </>
        }
      ></Modal>
      {/* Modal Email Proposal */}
      <Modal
        show={showStaff}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={closeEmail}
        title="Send Proposal To Email"
        content={
          <>
            <div className="row-div">
              <div className="left-div">
                {/* <TextboxIcon
                  name="txtsearchcandidate"
                  type="text"
                  placeholder="Search"
                  inputSize="input--medium"
                  onChange={(event) => handleSearchCandidate(event)}
                  icon={mdiMagnify}
                ></TextboxIcon> */}

                <div className="stats-indicator" style={{ marginLeft: "10px" }}>
                  Status:
                  <div>
                    <Icon path={mdiCircleMedium} color="#ffd028" size={1} />
                    Client
                  </div>
                  <div>
                    <Icon path={mdiCircleMedium} color="#467FD0" size={1} />
                    Candidate
                  </div>
                </div>
              </div>
              <div className="right-div comp-selected">
                <div style={{ marginRight: "20px" }}>
                  {staffSelected} selected
                </div>
              </div>
            </div>
            <CustomTable
              headers={headerSelect}
              items={allListStaff}
              paginate={allListStaff.length}
              maxRows={15}
            ></CustomTable>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={closeEmail}>
              Cancel
            </Button>
            <Button
              type="submit"
              form="email-form"
              variant="primary"
              onClick={btnSendEmail}
              disabled={staffSelected > 0 ? false : true}
            >
              Send Proposal
            </Button>
          </>
        }
      ></Modal>
      {/* Modal Open Reason */}
      <Modal
        show={modalReason}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseReason}
        title="Reason for Rejection"
        content={
          <>
            <CustomTable
              headers={headerReason}
              items={listReason}
              paginate={listReason.length}
              maxRows={15}
            ></CustomTable>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseReason}>
              Close
            </Button>
          </>
        }
      ></Modal>

      {/* Modal Pdf */}
      <Viewer
        showPdf={showPdf}
        btnClosePdf={btnClosePdf}
        modalTitle={modeModal.title}
        filePath={filePath}
      />
    </>
  );
};
