import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Form, Button } from "react-bootstrap";
import Api from "../../../../core/services/api.service";
import Swal from "sweetalert2";

import "./UserAccess.css";

// import { ListOfStaff } from "../../Staff/data";
import CustomTable from "../../../../components/Table/Table";
import Checkbox from "../../../../components/Button/CheckboxAccess";

import BreadCrumb from "../../../../components/Breadcrumb/BreadCrumb";

import Icon from "@mdi/react";
import { mdiArrowLeft } from "@mdi/js";

const ViewUserAccess = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [crumbs] = useState([]);

  // const [data, setData] = useState(ListOfStaff);

  const [roleDetail, setRoleDetail] = useState({});
  const [listGroupStaff, setListGroupStaff] = useState([]);

  // const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [oldAccess, setOldAccess] = useState([]);

  const [clientsAccess, setClientsAccess] = useState([]);
  const [candidateAccess, setCandidateAccess] = useState([]);
  const [potentialAccess, setPotentialAccess] = useState([]);
  const [proposalAccess, setProposalAccess] = useState([]);
  const [assignmentAccess, setAssignmentAccess] = useState([]);

  const [idAccess, setIdAccess] = useState([]);

  const customSwal = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-okay",
      cancelButton: "btn btn-cancel",
      title: "swal-title",
      popup: "swal-popup",
    },
    buttonsStyling: false,
  });

  const headers = [
    {
      Header: "No",
      maxWidth: 60,
      minWidth: 10,
      width: 40,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Name",
      maxWidth: 400,
      minWidth: 100,
      width: 300,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            {data.first_name} {data.last_name}
          </div>
        );
      },
    },
    {
      Header: "Email",
      maxWidth: 400,
      minWidth: 100,
      width: 300,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{data.email}</div>;
      },
    },
    {
      Header: "Phone Number",
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            {data.phone_no_code} {data.phone_no}
          </div>
        );
      },
    },
  ];

  // const handleSelectAll = e => {
  //   setIsCheckAll(!isCheckAll);
  //   setIsCheck(clientsAccess.map(li => li.id));
  //   if (isCheckAll) {
  //     setIsCheck([]);
  //   }
  // };

  const handleClick = (data) => (e) => {
    // const { id, checked } = e.target.checked;
    // let chckitem =  e.target.checked;
    // console.log("id", isCheck)

    if (isCheck.length > 0) {
      if (e.target.checked === false) {
        const index = isCheck.findIndex(function (chck) {
          return chck.id === data.id;
        });

        isCheck.splice(index, 1);
      } else {
        isCheck.push(data);
      }
    } else {
      isCheck.push(data);
    }

    // let idaccess = isCheck.map(el => el.map(obj => obj.id));
    const idaccess = isCheck.map((x) => x.id);

    setIdAccess(idaccess);

    // console.log("idaccess", test1);
  };

  const getViewUserAccess = useCallback(() => {
    Api.get("role/" + params.id)
      .then((response) => {
        let data = response.data;

        // setListRoleHasPermission(data.role_has_permissions);

        // console.log("getViewUserAccess", data);

        setRoleDetail(data.role);

        const oldidaccess = data.role_has_permissions.map((x) => x.id);
        setOldAccess(oldidaccess);

        if (data.user_has_roles.length > 0) {
          setListGroupStaff(data.user_has_roles);
        }

        const groupByCategory = data.all_permission.reduce((group, product) => {
          const { guard } = product;
          group[guard] = group[guard] ?? [];
          group[guard].push(product);
          return group;
        }, {});

        // console.log("groupByCategory", groupByCategory["Assignment"]);

        setClientsAccess(groupByCategory.Clients);
        setPotentialAccess(groupByCategory["Potential Clients"]);
        setCandidateAccess(groupByCategory["Candidate Database"]);
        setProposalAccess(groupByCategory["Proposal"]);
        setAssignmentAccess(groupByCategory["Assignment"]);
        setIsCheck(data.role_has_permissions);
      })
      .catch((error) => {
        console.log("error getViewUserAccess", error);
      });
  }, [params.id]);

  const checkedItem = (id) => {
    let cnd = [];

    const checkCand = isCheck.filter((chec) => {
      return chec.id === id;
    });

    cnd = checkCand[0];

    return cnd;
  };

  useEffect(() => {
    getViewUserAccess();
  }, [getViewUserAccess]);

  const btnUpdateAccess = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtAccess = Object.fromEntries(data.entries());
    // console.log("dtAccess", dtAccess);
    // console.log("idAccess", idAccess);

    customSwal
      .fire({
        title: "Do you want to update user access management?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.patch("role/ " + params.id, {
            description: dtAccess.txtdescgrp,
            permission: idAccess.length > 0 ? idAccess : oldAccess,
          })
            .then((response) => {
              navigate(-1);

              // window.location.replace("http://localhost:3000/userAccess");

              customSwal.fire({
                title: "User Access Management Updated Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error update proposal", error);
            });
        }
      });
  };

  return (
    <div className="main-div view-useracc">
      <div className="container-div both-side">
        <BreadCrumb crumbs={crumbs}></BreadCrumb>
        <Button variant="secondary" onClick={() => navigate(-1)}>
          <Icon path={mdiArrowLeft} />
          Back
        </Button>
      </div>
      <div className="bg-white-container">
        <Form id="user-access-form" onSubmit={btnUpdateAccess}>
          <Row>
            <Form.Group className="mb-3 col-6">
              <Form.Label>Name</Form.Label>
              <Form.Control
                name="txtnamegrp"
                type="text"
                placeholder="Enter name"
                defaultValue={roleDetail.name}
                readOnly
              />
            </Form.Group>

            <Form.Group className="mb-3 col-6">
              <Form.Label>Description</Form.Label>
              <Form.Control
                name="txtdescgrp"
                type="text"
                placeholder="Enter description name"
                defaultValue={roleDetail.description}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Label>Permissions</Form.Label>
          </Row>
          <div className="permissions-row">
            {/* Client Access */}
            <div className="permissions-content">
              <div className="checkbox-title-container">
                {/* <Checkbox 
                  type="checkbox"
                  name="selectAll"
                  id="selectAll"
                  handleClick={handleSelectAll}
                  isChecked={isCheckAll}
                /> */}
                <div className="checkbox-title">Clients</div>
              </div>

              {clientsAccess.map((client, index) => {
                return (
                  <div className="checkbox-list" key={index}>
                    <Checkbox
                      key={client.id}
                      type="checkbox"
                      name={index}
                      id={client.id}
                      handleClick={handleClick(client)}
                      isChecked={checkedItem(client.id)}
                    />
                    <div className="list-title">{client.name}</div>
                  </div>
                );
              })}
            </div>

            {/* Potential Client Access */}
            <div className="permissions-content">
              <div className="checkbox-title-container">
                {/* <Checkbox 
                  type="checkbox"
                  name="selectAll"
                  id="selectAll"
                  handleClick={handleSelectAll}
                  isChecked={isCheckAll}
                /> */}
                <div className="checkbox-title">Potential Clients</div>
              </div>

              {potentialAccess.map((potential, index) => {
                return (
                  <div className="checkbox-list" key={index}>
                    <Checkbox
                      key={potential.id}
                      type="checkbox"
                      name={index}
                      id={potential.id}
                      handleClick={handleClick(potential)}
                      isChecked={checkedItem(potential.id)}
                    />
                    <div className="list-title">{potential.name}</div>
                  </div>
                );
              })}
            </div>

            {/* Candidate Access */}
            <div className="permissions-content">
              <div className="checkbox-title-container">
                {/* <Checkbox 
                  type="checkbox"
                  name="selectAll"
                  id="selectAll"
                  handleClick={handleSelectAll}
                  isChecked={isCheckAll}
                /> */}
                <div className="checkbox-title">Candidate Database</div>
              </div>

              {candidateAccess.map((candidate, index) => {
                return (
                  <div className="checkbox-list" key={index}>
                    <Checkbox
                      key={candidate.id}
                      type="checkbox"
                      name={index}
                      id={candidate.id}
                      handleClick={handleClick(candidate)}
                      isChecked={checkedItem(candidate.id)}
                    />
                    <div className="list-title">{candidate.name}</div>
                  </div>
                );
              })}
            </div>

            {/* Proposal Access */}
            <div className="permissions-content">
              <div className="checkbox-title-container">
                <div className="checkbox-title">Proposal</div>
              </div>

              {proposalAccess.map((proposal, index) => {
                return (
                  <div className="checkbox-list" key={index}>
                    <Checkbox
                      key={proposal.id}
                      type="checkbox"
                      name={index}
                      id={proposal.id}
                      handleClick={handleClick(proposal)}
                      isChecked={checkedItem(proposal.id)}
                    />
                    <div className="list-title">{proposal.name}</div>
                  </div>
                );
              })}
            </div>

            {/* Assignment Access */}
            <div className="permissions-content">
              <div className="checkbox-title-container">
                <div className="checkbox-title">Assignment</div>
              </div>

              {assignmentAccess.map((assignment, index) => {
                return (
                  <div className="checkbox-list" key={index}>
                    <Checkbox
                      key={assignment.id}
                      type="checkbox"
                      name={index}
                      id={assignment.id}
                      handleClick={handleClick(assignment)}
                      isChecked={checkedItem(assignment.id)}
                    />
                    <div className="list-title">{assignment.name}</div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="row-btn-bottom">
            {/* <Button variant="secondary">Deselect All</Button> */}
            <Button variant="primary" type="submit" form="user-access-form">
              Update Role
            </Button>
          </div>
        </Form>
      </div>

      <div
        className="bg-white-container list-usercat"
        style={{ marginTop: "30px" }}
      >
        <CustomTable headers={headers} items={listGroupStaff}></CustomTable>
      </div>
    </div>
  );
};

export default ViewUserAccess;
