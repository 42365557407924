import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Button } from "react-bootstrap";
import Apiform from "../../core/services/apiform.service";

import SwalAlert from "../Swal/SwalAlert";
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';

import {
  getUserProfile,
  storeLogProfile,
  getUserProfileHeader,
} from "../../store/actions/userProfileSlice";
import "../../pages/Admin/Settings/settings.css";
// import ProfileImage from "../../assets/no-profile-photo.jpg";

export const TabPersonalDetail = () => {
  const dispatch = useDispatch();
  const customSwal = SwalAlert();

  const { user } = useSelector((state) => state.login);
  const { usrLogProfile } = useSelector((state) => state.user);
  const { dataDepartment, dataSalute, dataPhoneCode } = useSelector(
    (state) => state.option
  );

  const [profileImg, setProfileImg] = useState("");
  const [errorsPersonal, setErrorsPersonal] = useState({});

  // event onChange
  const uploadSingleFile = (e) => {
    // console.log("onChnage", URL.createObjectURL(e.target.files[0]))
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setProfileImg(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const btnDiscard = () => {
    dispatch(getUserProfile());
    setProfileImg("");
    setErrorsPersonal({});
  };

  const formErrorsFind = (object) => {
    const newBasicErrors = {};

    const regEmail = /\S+@\S+\.\S+/;

    if (!object.salutation || object.salutation === "")
      newBasicErrors.salutation = "Fill in the blank!";

    if (!object.first_name || object.first_name === "")
      newBasicErrors.first_name = "Fill in the blank!";

    if (!object.last_name || object.last_name === "")
      newBasicErrors.last_name = "Fill in the blank!";

    if (!object.email || object.email === "")
      newBasicErrors.email = "Fill in the blank!";
    else if (!regEmail.test(object.email))
      newBasicErrors.email = "Invalid Email Address";

    if (!object.phone_no_code || object.phone_no_code === "")
      newBasicErrors.phone_no_code = "Fill in the blank!";

    if (!object.phone_no || object.phone_no === "")
      newBasicErrors.phone_no = "Fill in the blank!";

    return newBasicErrors;
  };

  const btnUpdatePersonal = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtPersonal = Object.fromEntries(data.entries());

    const newErrors = formErrorsFind(usrLogProfile);

    if (Object.keys(newErrors).length > 0) {
      setErrorsPersonal(newErrors);
    } else {
      customSwal
        .fire({
          title: "Do you want to update your profile?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();

            formData.append("file", dtPersonal.txtprofile);
            formData.append("salutation", usrLogProfile.salutation);
            formData.append("first_name", usrLogProfile.first_name);
            formData.append("last_name", usrLogProfile.last_name);
            formData.append("email", usrLogProfile.email);
            formData.append("staff_no", usrLogProfile.staff_no);

            formData.append("phone_no", usrLogProfile.phone_no);
            formData.append("phone_no_code", usrLogProfile.phone_no_code);

            formData.append("status", usrLogProfile.status);
            formData.append("role", 1);
            formData.append("department_id", usrLogProfile.department_id);

            Apiform.patch("user/" + user.sub + "/update", formData)
              .then((response) => {
                const tokenid = Cookies.get("id_token");

                const decoded = jwt_decode(tokenid);

                let role_id = decoded.user_has_roles[0].role_id;

                dispatch(getUserProfile());
                dispatch(getUserProfileHeader(role_id));
                customSwal.fire({
                  title: "Profile Updated Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
                setErrorsPersonal({});
              })
              .catch((error) => {
                console.log("error btnUpdatePersonal", error);
              });
          }
        });
    }

    // console.log("dtPersonal", usrLogProfile);
  };

  return (
    <>
      <div className="info-container">
        <Form id="personal-form" onSubmit={btnUpdatePersonal}>
          {/* 1 */}
          <Row className="row-upload-pic mb-3">
            <div className="img-box">
              {profileImg ? (
                <img src={profileImg} alt="" />
              ) : (
                <img src={usrLogProfile.profile_picture} alt="" />
              )}
            </div>
            <Form.Group className="mb-3 col-4">
              <Form.Control
                onChange={uploadSingleFile}
                name="txtprofile"
                type="file"
                accept=".png,.jpg,.jpeg"
              />
              <Form.Text className="text-muted">
                Allowed file types: jpg, jpeg, png.
              </Form.Text>
            </Form.Group>
          </Row>
          {/* 2 */}
          <Row className="personal-details">
            <Form.Group className="mb-3 col-6">
              <Form.Label>Staff Number</Form.Label>
              <Form.Control value={usrLogProfile.staff_no} disabled />
            </Form.Group>

            <Form.Group className="mb-3 col-6">
              <Form.Label>Department</Form.Label>
              <Form.Select
                name="ddlDepartment"
                value={usrLogProfile.department_id}
                disabled
              >
                <option value="">Select</option>
                {dataDepartment.map((depart, index) => (
                  <option key={index} value={depart.id}>
                    {depart.value}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>
          {/* 3 */}
          <Row className="personal-details">
            <Form.Group className="mb-3 col-6">
              <Form.Label>
                Salutation <label className="validation-star">*</label>
              </Form.Label>
              <Form.Select
                name="ddlSalute"
                value={usrLogProfile.salutation}
                onChange={(e) =>
                  dispatch(
                    storeLogProfile({
                      salutation: e.currentTarget.value,
                    })
                  )
                }
                isInvalid={!!errorsPersonal.salutation}
              >
                <option value="">Select</option>
                {dataSalute.map((salute, index) => (
                  <option key={index} value={salute.value}>
                    {salute.value}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errorsPersonal.salutation}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          {/* 4 */}
          <Row className="personal-details">
            <Form.Group className="mb-3 col-6">
              <Form.Label>
                First Name <label className="validation-star">*</label>
              </Form.Label>
              <Form.Control
                name="txtfirstname"
                type="text"
                placeholder="Please enter your first name"
                value={usrLogProfile.first_name}
                onChange={(e) =>
                  dispatch(
                    storeLogProfile({
                      first_name: e.currentTarget.value,
                    })
                  )
                }
                isInvalid={!!errorsPersonal.first_name}
                // disabled
              />
              <Form.Control.Feedback type="invalid">
                {errorsPersonal.first_name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 col-6">
              <Form.Label>
                Last Name <label className="validation-star">*</label>
              </Form.Label>
              <Form.Control
                name="txtlastname"
                type="text"
                placeholder="Please enter your first name"
                value={usrLogProfile.last_name}
                onChange={(e) =>
                  dispatch(
                    storeLogProfile({
                      last_name: e.currentTarget.value,
                    })
                  )
                }
                isInvalid={!!errorsPersonal.last_name}
                // disabled
              />
              <Form.Control.Feedback type="invalid">
                {errorsPersonal.last_name}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          {/* 5 */}
          <Row className="personal-details">
            <Form.Group className="mb-3 col-6">
              <Form.Label>
                Email Address <label className="validation-star">*</label>
              </Form.Label>
              <Form.Control
                name="txtemail"
                type="text"
                placeholder="Please enter your first name"
                value={usrLogProfile.email}
                onChange={(e) =>
                  dispatch(
                    storeLogProfile({
                      email: e.currentTarget.value,
                    })
                  )
                }
                isInvalid={!!errorsPersonal.email}
                // disabled
              />
              <Form.Control.Feedback type="invalid">
                {errorsPersonal.email}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 col-6">
              <Form.Label>
                Phone Number <label className="validation-star">*</label>
              </Form.Label>
              <Row>
                <div className="col-3">
                  <Form.Select
                    name="ddlPhoneCode"
                    value={usrLogProfile.phone_no_code}
                    onChange={(e) =>
                      dispatch(
                        storeLogProfile({
                          phone_no_code: e.currentTarget.value,
                        })
                      )
                    }
                    isInvalid={!!errorsPersonal.phone_no_code}
                  >
                    <option value="">Select</option>
                    {dataPhoneCode.map((pcode, index) => (
                      <option key={index} value={pcode.value}>
                        {pcode.value}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errorsPersonal.phone_no_code}
                  </Form.Control.Feedback>
                </div>
                <div className="col-9">
                  <Form.Control
                    name="txtphone"
                    type="number"
                    placeholder="Please enter your phone number"
                    value={usrLogProfile.phone_no}
                    onChange={(e) =>
                      dispatch(
                        storeLogProfile({
                          phone_no: e.currentTarget.value,
                        })
                      )
                    }
                    isInvalid={!!errorsPersonal.phone_no}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorsPersonal.phone_no}
                  </Form.Control.Feedback>
                </div>
              </Row>
            </Form.Group>
          </Row>

          <div className="row-btn-bottom">
            <Button variant="secondary" onClick={btnDiscard}>
              Discard
            </Button>
            <Button variant="primary" type="submit" form="personal-form">
              Save Changes
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};
