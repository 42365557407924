import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Api from "../../../../core/services/api.service";

import SwalAlert from "../../../../components/Swal/SwalAlert";
import PasswordChecklist from "react-password-checklist";

import Icon from "@mdi/react";
import { mdiCheck } from "@mdi/js";
import ReCAPTCHA from "react-google-recaptcha";

import { Form, Button, Alert } from "react-bootstrap";
import Searchable from "../../../../components/DropDown/SearchableSelect";

import logo from "../../../../assets/logo.png";
import "../../../Admin/Auth/Register/register.css";

import { getListOptions } from "../../../../store/actions/optionSlice";
import {
  storeRegCand,
  storeSuccCand,
} from "../../../../store/actions/registerCandidateSlice";
import { loginFail } from "../../../../store/actions/loginSlice";

const FirstPage = (props) => {
  const handleLanguages = (e) => {
    props.dispatch(
      storeRegCand({
        languages: e,
      })
    );

  };

  return (
    <>
      <Form id="basic-form">
        <Form.Group className="mb-3">
          <Form.Label>Salutation</Form.Label>
          <Form.Select
            name="ddlSalute"
            onChange={(e) =>
              props.dispatch(
                storeRegCand({
                  salutation: e.currentTarget.value,
                })
              )
            }
            value={props.formRegister.salutation}
            isInvalid={!!props.errorsFirst.salutation}
          >
            <option value="">Select</option>
            {props.dataSalute.map((salute, index) => (
              <option key={index} value={salute.value}>
                {salute.value}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {props.errorsFirst.salutation}
          </Form.Control.Feedback>
        </Form.Group>
        <div className="name-row">
          <Form.Group className="mb-3 col-6">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your first name"
              value={props.formRegister.first_name}
              onChange={(e) =>
                props.dispatch(
                  storeRegCand({
                    first_name: e.currentTarget.value,
                  })
                )
              }
              isInvalid={!!props.errorsFirst.first_name}
            />
            <Form.Control.Feedback type="invalid">
              {props.errorsFirst.first_name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3 col-6">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your last name"
              value={props.formRegister.last_name}
              onChange={(e) =>
                props.dispatch(
                  storeRegCand({
                    last_name: e.currentTarget.value,
                  })
                )
              }
              isInvalid={!!props.errorsFirst.last_name}
            />
            <Form.Control.Feedback type="invalid">
              {props.errorsFirst.last_name}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <Form.Group className="mb-3">
          <Form.Label>Mobile Number</Form.Label>
          {/* <div className="name-row"> */}
          {/* <div className="col-3">
              <Form.Select
                name="ddlPhoneCode"
                onChange={(e) =>
                  props.dispatch(
                    storeRegCand({
                      mobile_no_code: e.currentTarget.value,
                    })
                  )
                }
                value={props.formRegister.mobile_no_code}
                isInvalid={!!props.errorsFirst.mobile_no_code}
              >
                <option value="">Select</option>
                {props.dataPhoneCode.map((phone, index) => (
                  <option key={index} value={phone.value}>
                    {phone.value}
                  </option>
                ))}
                <Form.Control.Feedback type="invalid">
                  {props.errorsFirst.mobile_no_code}
                </Form.Control.Feedback>
              </Form.Select>
            </div> */}
          {/* <div className="col-9"> */}
          <Form.Control
            type="text"
            placeholder="Example: +65 XXXX XXXX"
            value={props.formRegister.mobile_no}
            onChange={(e) =>
              props.dispatch(
                storeRegCand({
                  mobile_no: e.currentTarget.value,
                })
              )
            }
            isInvalid={!!props.errorsFirst.mobile_no}
          />
          <Form.Control.Feedback type="invalid">
            {props.errorsFirst.mobile_no}
          </Form.Control.Feedback>
          {/* </div> */}
          {/* </div> */}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter your personal email address"
            value={props.formRegister.email}
            onChange={(e) =>
              props.dispatch(
                storeRegCand({
                  email: e.currentTarget.value,
                })
              )
            }
            isInvalid={!!props.errorsFirst.email}
          />
          <Form.Control.Feedback type="invalid">
            {props.errorsFirst.email}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Languages</Form.Label>
          <Searchable
            name="ddlLanguages"
            isMulti={true}
            options={props.dataLanguages}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.value}
            onChange={handleLanguages}
            value={props.formRegister.languages}
            errorText={props.errorsFirst.languages}
            isInvalid={!!props.errorsFirst.languages}
          ></Searchable>
        </Form.Group>
      </Form>
    </>
  );
};

const Password = (props) => {
  // const navigate = useNavigate();

  const [validPassword, setValidPassword] = useState(false);

  // const btnPolicy = () => {
  //   navigate("/view-policy");
  // };

  function onChange(value) {

    props.dispatch(
      storeRegCand({
        captcha: value,
        validPassword: validPassword
      })
    )
  }

  const handleChangeChecking = (isValid) => {
    // console.log("🚀 ~ handleChange ~ isValid:", isValid)
    setValidPassword(isValid)
 
  };

  

  const url = `https://limlogesmasters.com/wp-content/uploads/2021/01/LLM_Code-of-Conduct-Data-Protection-Policy-Skyna19Jan21.pdf`;

  return (
    <>
      <Form id="pw-form">
        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            name="txtrgpassword"
            type="password"
            placeholder="Enter your password"
            value={props.formRegister.password}
            onChange={(e) =>
              props.dispatch(
                storeRegCand({
                  password: e.currentTarget.value,
                  validPassword: validPassword
                })
              )
            }
            isInvalid={!!props.errorsPassword.password}
          />
          <Form.Control.Feedback type="invalid">
            {props.errorsPassword.password}
          </Form.Control.Feedback>
        </Form.Group>

        <div className="new-pw-note">
          In order to <b>protect your account</b>, make sure your password:
          <PasswordChecklist
            // className="pw-checklist"
            rules={[
              "minLength",
              "capital",
              "lowercase",
              "number",
              "specialChar",
            ]}
            minLength={8}
            value={props.formRegister.password}
            valueAgain={props.formRegister.password}
            // onChange={(isValid) => {
            //   console.log("🚀 ~ Password ~ isValid:", isValid)
            //   if(isValid){
            //     props.dispatch(
            //       storeRegCand({
            //         validPassword: isValid,
            //       })
            //     );
            //   }
              
            // }}

            onChange={(isValid) => handleChangeChecking(isValid)} 
            messages={{
              minLength: "has minimum of 8 characters.",
              capital: "include at least one uppercase letter (A-Z).",
              lowercase: "include at least one lowercase letter (a-z).",
              number: "include at least one number (1-9).",
              specialChar:
                "include at least one special character (e.g. !, @, #, $).",
            }}
          />
        </div>

        <Form.Group
          className="mb-3 mt-3 consent-check"
          style={{
            textAlign: "justify",
            wordBreak: "break-word",
          }}
        >
          <Form.Check type="checkbox">
            <Form.Check.Input
              name="consent"
              type="checkbox"
              onChange={props.handleChange}
            />
            <Form.Check.Label>
              By selecting this checkbox, I agree that I have read the{" "}
              <span>
                <Button
                  variant="link"
                  style={{ padding: 0, margin: 0, transform: "unset" }}
                  onClick={() => window.open(url, '_blank')}
                >
                  privacy policy
                </Button>
              </span>{" "}
              and consent to the given information being used by Lim-Loges &
              Masters.
            </Form.Check.Label>
          </Form.Check>
        </Form.Group>

        <div style={{display:"flex", alignItems: "center", justifyContent:"space-between"}}>
          <ReCAPTCHA 
            sitekey="6LcKt-EkAAAAAP7JTd6Gx-SherWM_28IlI5dGAxu"
            onChange={onChange}
          />
          {!!props.errorsPassword.captchaCand ? 
            <Alert variant='danger'>
              Please check the captcha form.
            </Alert>: <></>
          }
          
        </div>
      </Form>
    </>
  );
};

const CandidateRegister = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const customSwal = SwalAlert();

  const { dataLanguages, dataPhoneCode, dataSalute } = useSelector(
    (state) => state.option
  );
  const { formRegCandidate } = useSelector((state) => state.registerCand);

  const [steps, setSteps] = useState([
    {
      key: "0",
      name: "Personal Details",
      label: "Fill in personal details",
      isDone: false,
      component: FirstPage,
    },
    {
      key: "1",
      name: "Password",
      label: "Secure and create account",
      isDone: false,
      component: Password,
    },
  ]);

  const [errorsFirst, setErrorsFirst] = useState({});
  const [errorsPassword, setErrorsPassword] = useState({});

  const [btnStatus, setBtnStatus] = useState(true);

  function handleChange(e) {
    if (btnStatus) {
      setBtnStatus(false);
    } else {
      setBtnStatus(true);
    }
  }

  const formErrorsFirst = (object) => {
    const newFirstErrors = {};

    if (!object.salutation || object.salutation === "")
      newFirstErrors.salutation = "Fill in the blank!";

    if (!object.first_name || object.first_name === "")
      newFirstErrors.first_name = "Fill in the blank!";

    if (!object.last_name || object.last_name === "")
      newFirstErrors.last_name = "Fill in the blank!";

    // if (!object.mobile_no_code || object.mobile_no_code === "")
    //   newFirstErrors.mobile_no_code = "Fill in the blank!";

    if (!object.mobile_no || object.mobile_no === "")
      newFirstErrors.mobile_no = "Fill in the blank!";

    if (!object.email || object.email === "")
      newFirstErrors.email = "Fill in the blank!";

    if (object.languages.length === 0) {
      newFirstErrors.languages = "Fill in the blank!";
    }

    return newFirstErrors;
  };

  const formErrorsPassword = (object) => {
    const newPasswordErrors = {};

    if (!object.password || object.password === "")
      newPasswordErrors.password = "Fill in the blank!";

    if (!object.captcha || object.captcha === "")
      newPasswordErrors.captchaCand = "Fill in the blank!";

    return newPasswordErrors;
  };

  const formErrorsEmail = (object) => {
    const emailErrors = {};

    if (object === "Email Already Exist!")
      emailErrors.email = "Email Already Exist";

    return emailErrors;
  };

  const [activeStep, setActiveStep] = useState(steps[0]);

  const handleNext = () => {
    if (activeStep.key === "0") {
      const newErrors = formErrorsFirst(formRegCandidate);

      if (Object.keys(newErrors).length > 0) {
        setErrorsFirst(newErrors);
      } else {
        const index = steps.findIndex((x) => x.key === activeStep.key);

        setSteps((prevStep) =>
          prevStep.map((x) => {
            if (x.key === activeStep.key) x.isDone = true;
            return x;
          })
        );
        setActiveStep(steps[index + 1]);
        setErrorsFirst({});
        setBtnStatus(true);
        setErrorsPassword({});
        dispatch(
          storeRegCand({
            captcha: "",
          })
        )
      }
    } else {
      if (steps[steps.length - 1].key === activeStep.key) {
        const newErrors = formErrorsPassword(formRegCandidate);

        if (Object.keys(newErrors).length > 0) {
          setErrorsPassword(newErrors);
        } else {
          if (formRegCandidate.validPassword === true) {
            customSwal
              .fire({
                title: "Continue with your registration?",
                icon: "warning",
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonText: "Confirm",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  // console.log("formRegister", formRegCandidate)
                  const object = {
                    salutation: formRegCandidate.salutation,
                    first_name: formRegCandidate.first_name,
                    last_name: formRegCandidate.last_name,
                    // mobile_no_code: formRegCandidate.mobile_no_code,
                    mobile_no: formRegCandidate.mobile_no,
                    languages: formRegCandidate.languages.map((el) => el.value),
                    email: formRegCandidate.email,
                    password: formRegCandidate.password,
                  };

                  Api.post("candidate/register", object)
                    .then((response) => {
                      navigate("/candidate-login");
                      customSwal.fire({
                        title: "Registration Successful!",
                        text: "Please check your email for verification email.",
                        icon: "success",
                        confirmButtonText: "Okay",
                      });

                      dispatch(
                        storeSuccCand({
                          success: true,
                        })
                      );

                      dispatch(
                        storeRegCand({
                          salutation: null,
                          first_name: null,
                          last_name: null,
                          // mobile_no_code: null,
                          mobile_no: null,
                          languages: [],
                          email: null,
                          password: "",
                        })
                      );
                    })
                    .catch((error) => {
                      console.log("error candidate register", error);
                      setActiveStep(steps[0]);
                      const newErrors = formErrorsEmail(
                        error.response.data.message
                      );
                      // console.log("newErrors", newErrors)
                      if (Object.keys(newErrors).length > 0) {
                        setErrorsFirst(newErrors);
                      }
                    });

                  // console.log("object", object)

                  // dispatch(createCandidateRegister(object));
                }
              });

            setErrorsPassword({});
          }
        }
      }
    }
  };

  const handleBack = () => {
    const index = steps.findIndex((x) => x.key === activeStep.key);
    if (index === 0) return;

    setSteps((prevStep) =>
      prevStep.map((x) => {
        if (x.key === activeStep.key) x.isDone = false;
        return x;
      })
    );
    setActiveStep(steps[index - 1]);
  };

  function signin() {
    navigate("/candidate-login");
    dispatch(loginFail(""));
  }

  useEffect(() => {
    dispatch(getListOptions());
  }, [dispatch]);

  return (
    <div className="main-div" style={{ height: "100vh" }}>
      <div className="auth-logo-div">
        <img src={logo} alt="" />
      </div>
      <div className="auth-container">
        <div className="login-container">
          <div className="left-welcome-div">
            <div className="mid-section">
              {steps.map((step, i) => {
                return (
                  <div className="wizard-section" key={i}>
                    <div
                      className={`${activeStep.key === step.key
                        ? "square-num active-square"
                        : "square-num"
                        } 
                      `}
                    >
                      {step.isDone && activeStep.key !== step.key ? (
                        <Icon path={mdiCheck} size={0.8} key={i} />
                      ) : (
                        `${i + 1}`
                      )}
                    </div>
                    <div className="wizard-right">
                      <div className="wizard-info">{step.name}</div>
                      <div className="wizard-subinfo">{step.label}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="right-form-div">
            <div className="top-section reg">
              <div className="title-form-section">
                <div className="main-title">Register</div>
                <div className="small-text" style={{ color: "#54595F" }}>
                  to create your account.
                </div>
              </div>

              {activeStep.key === "0" ? (
                <FirstPage
                  dispatch={dispatch}
                  dataSalute={dataSalute}
                  dataPhoneCode={dataPhoneCode}
                  dataLanguages={dataLanguages}
                  formRegister={formRegCandidate}
                  errorsFirst={errorsFirst}
                ></FirstPage>
              ) : (
                <Password
                dispatch={dispatch}
                formRegister={formRegCandidate}
                errorsPassword={errorsPassword}
                handleChange={handleChange}
              ></Password>
              
              )}
              <div className="row-link-button">
                {steps[0].key === activeStep.key && (
                  <Button
                    variant="link"
                    className="no-underline"
                    onClick={signin}
                  >
                    Back to Sign In
                  </Button>
                )}
                {steps[0].key !== activeStep.key && (
                  <Button
                    variant="link"
                    className="no-underline"
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                )}
                {steps[steps.length - 1].key !== activeStep.key ? (
                  <Button variant="primary" onClick={handleNext}>
                    Continue
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    onClick={handleNext}
                    disabled={btnStatus}
                  >
                    Register
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateRegister;
