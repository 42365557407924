import {createSlice} from '@reduxjs/toolkit';


const initialState = {
    menuCrumb:null
}

const breadSlice = createSlice({
    name: 'breadcrumb',
    initialState,
    reducers:{
        storeMenu: (state, action) => {
            state.menuCrumb = action.payload;
        },
    },
})

const {reducer, actions} = breadSlice;

export const { storeMenu } = actions;

export default reducer;