import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  AlignmentType,
  TabStopType,
} from "docx";
import { saveAs } from "file-saver";

export const ResumeTemplate = async (data) => {
  console.log("data", data);

  const fontType = "Calibri";
  const fontColorDark = "#004A67";
  const fontColorBright = "#1C6688";
  const fontColorGrey = "#404040";
  const fontSize20 = 40;
  const fontSize14 = 28;
  const fontSize11 = 22;

  let educationList = [];

  if (data.candidate_education.length > 0) {
    educationList = data.candidate_education;
  }

  const tableEducation = new Paragraph({
    children: [new TextRun({})],
  });

  educationList.map((edu) => {
    return tableEducation.root.push(
      new Paragraph({
        alignment: AlignmentType.LEFT,
        children: [
          new TextRun({
            text: edu.start_year + " - " + edu.end_year,
            size: fontSize11,
            font: fontType,
            color: fontColorGrey,
          }),
          new TextRun({
            text: "\t " + edu.qualification,
            size: fontSize14,
            font: fontType,
            color: fontColorDark,
            bold: true,
          }),
          new TextRun({
            size: fontSize11,
            break: 1,
          }),
          new TextRun({
            text: "\t " + edu.name,
            size: fontSize11,
            font: fontType,
            color: fontColorDark,
          }),
        ],
        tabStops: [
          {
            type: TabStopType.LEFT,
            position: 3000,
          },
        ],
      }),
      new Paragraph({
        children: [
          new TextRun({
            size: fontSize14,
          }),
        ],
      })
    );
  });

  let experienceList = [];

  function toMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", {
      month: "long",
    });
  }

  function convertToPlain(html) {
    // Create a new div element
    var tempDivElement = document.createElement("div");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }

  if (data.candidate_experiences.length > 0) {
    experienceList = data.candidate_experiences;
  }

  const tableExperience = new Paragraph({
    children: [new TextRun({})],
  });

  experienceList.map((exp) => {
    return tableExperience.root.push(
      new Paragraph({
        alignment: AlignmentType.LEFT,
        children: [
          new TextRun({
            text:
              exp.current_working === true
                ? toMonthName(exp.present_month) +
                  " " +
                  exp.present_year +
                  " - Current"
                : toMonthName(exp.start_date_month) +
                  " " +
                  exp.start_date_year +
                  " - " +
                  toMonthName(exp.end_date_month) +
                  " " +
                  exp.end_date_year,
            size: fontSize11,
            font: fontType,
            color: fontColorGrey,
          }),
          new TextRun({
            text: "\t " + exp.title,
            size: fontSize14,
            font: fontType,
            color: fontColorDark,
            bold: true,
          }),
          new TextRun({
            size: fontSize11,
            break: 1,
          }),
          new TextRun({
            text: "\t " + exp.company_name,
            size: fontSize11,
            font: fontType,
            color: fontColorDark,
          }),
          new TextRun({
            size: fontSize11,
            break: 1,
          }),
          new TextRun({
            text: "\t " + convertToPlain(exp.job_responsibility),
            size: fontSize11,
            font: fontType,
            color: fontColorGrey,
          }),
        ],
        tabStops: [
          {
            type: TabStopType.LEFT,
            position: 3000,
          },
        ],
      }),
      new Paragraph({
        children: [
          new TextRun({
            size: fontSize14,
          }),
        ],
      })
    );
  });

  const doc = new Document({
    sections: [
      {
        children: [
          new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
              new TextRun({
                text: data.first_name + " " + data.last_name,
                bold: true,
                size: fontSize20,
                font: fontType,
                color: fontColorDark,
              }),
            ],
            spacing: {
              after: 100,
            },
          }),
          new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
              new TextRun({
                text: experienceList[0].title,
                size: fontSize14,
                font: fontType,
                color: fontColorGrey,
              }),
            ],
            spacing: {
              after: 100,
            },
          }),
          new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
              new TextRun({
                text: "Mobile Number: ",
                size: fontSize11,
                font: fontType,
                color: fontColorGrey,
                bold: true,
              }),
              new TextRun({
                text: data.mobile_no_code + " " + data.mobile_no,
                size: fontSize11,
                font: fontType,
                color: fontColorGrey,
              }),
            ],
          }),
          new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
              new TextRun({
                text: "Email Address: ",
                size: fontSize11,
                font: fontType,
                color: fontColorGrey,
                bold: true,
              }),
              new TextRun({
                text: data.email,
                size: fontSize11,
                font: fontType,
                color: fontColorGrey,
              }),
            ],
          }),
          new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
              new TextRun({
                text: "Address: ",
                size: fontSize11,
                font: fontType,
                color: fontColorGrey,
                bold: true,
              }),
              new TextRun({
                text: data.permanent_addr,
                size: fontSize11,
                font: fontType,
                color: fontColorGrey,
              }),
            ],
          }),
          new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
              new TextRun({
                text: "Experience",
                size: fontSize14,
                font: fontType,
                color: fontColorBright,
              }),
            ],
            spacing: {
              before: 600,
              after: 100,
            },
          }),
          tableExperience,
          //   new Paragraph({
          //     alignment: AlignmentType.LEFT,
          //     children: [
          //       new TextRun({
          //         text: "Start Date - End Date",
          //         size: fontSize11,
          //         font: fontType,
          //         color: fontColorGrey,
          //       }),
          //       new TextRun({
          //         text: "\t Job Title",
          //         size: fontSize14,
          //         font: fontType,
          //         color: fontColorDark,
          //         bold: true,
          //       }),
          //       new TextRun({
          //         size: fontSize11,
          //         break: 1,
          //       }),
          //       new TextRun({
          //         text: "\t Company Name",
          //         size: fontSize11,
          //         font: fontType,
          //         color: fontColorDark,
          //       }),
          //       new TextRun({
          //         size: fontSize11,
          //         break: 1,
          //       }),
          //       new TextRun({
          //         text: "\t Job Description",
          //         size: fontSize11,
          //         font: fontType,
          //         color: fontColorDark,
          //       }),
          //     ],
          //     tabStops: [
          //       {
          //         type: TabStopType.LEFT,
          //         position: 3000,
          //       },
          //     ],
          //   }),
          new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
              new TextRun({
                text: "Education",
                size: fontSize14,
                font: fontType,
                color: fontColorBright,
              }),
            ],
            spacing: {
              before: 400,
              after: 100,
            },
          }),
          tableEducation,
        ],
      },
    ],
  });

  Packer.toBlob(doc).then((blob) => {
    console.log(blob);
    saveAs(blob, "Resume_.docx");
    console.log("Document created successfully");
  });

  return doc;
};
