import React, { useState, useEffect } from "react";
import Header from "./Header";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { Spinner } from "react-bootstrap";
// import { storeUser } from "../../store/actions/userProfileSlice";
// import SideMenu from "../layout/SideMenu";

import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
// import Api from "../../core/services/api.service";

import { getUserProfileHeader } from "../../store/actions/userProfileSlice";
import { getListOptions, getListYears } from "../../store/actions/optionSlice";

import styled from "styled-components";
import Sidebar from "./Sidebar";

const NavBarContainer = styled.div`
  /* width: 260px; */
  /* position: fixed; */
  z-index: 999;
  display: flex;
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
  margin-left: ${({ sidebar }) => (sidebar ? "300px" : "62px")};
  width: ${({ sidebar }) =>
    sidebar ? "calc(100vw - 300px)" : "calc(100vw - 62px)"};
  transition: 350ms;
`;

// const HeaderContainer = styled.div`
//   position: sticky;
//   height: 70px;
//   top: 0;
//   background-color: red;
// `;

const InfoContainer = styled.div`
  /* margin-top: -90px; */
  /* padding: 20px 10px 0px; */
  transition: 0.3s;
  height: 100%;
  background: var(--menu-background);
`;

function Layout(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const { usrProfile } = useSelector((state) => state.user);

  const [getHeader, setHeader] = React.useState("");
  const [getIconH, setIconH] = React.useState("");
  const [sidebar, setSidebar] = useState(false);

  const [roleSuperAdmin, setRoleSuperAdmin] = useState("");

  const toggleSidebarIn = () => {
    if (!sidebar) {
      // console.log(" besar");
      document.getElementById("content").style.width = "calc(100vw - 300px)";
      document.getElementById("content").style.marginLeft = "300px";
      // document.getElementById("main-overlay").style.width =
      //   "calc(100vw - 300px)";
    }
  };

  const toggleSidebarOut = () => {
    if (!sidebar) {
      // console.log(" kecik");
      document.getElementById("content").style.width = "calc(100vw - 62px)";
      document.getElementById("content").style.marginLeft = "62px";
      // document.getElementById("main-overlay").style.width =
      //   "calc(100vw - 62px)";
    }
  };

  // Function Event
  function getSelectUser() {
    const tokenid =  Cookies.get("id_token");
    if (tokenid === null) {
      // setTimeout(() => setLoading, 3500);
      // setResetUrl("/");
    } else {
      const decoded = jwt_decode(tokenid);

      let role_id = decoded.user_has_roles[0].role_id;

      setRoleSuperAdmin(role_id);

      dispatch(getUserProfileHeader(role_id))
        .then(() => {
          dispatch(getListOptions());
          dispatch(getListYears());
        })
        .catch((error) => {
          navigate("/error");
        });
    }
  }

  useEffect(() => {
    getSelectUser();

    // console.log("setRoleAccess", usrProfile)
  }, []);

  return (
    <div className="main_container">
      <NavBarContainer
        onMouseOver={toggleSidebarIn}
        onMouseOut={toggleSidebarOut}
        className="navbar-def"
      >
        {/* <SideMenu
            onCollapse={(menu) => {
            }}
            setHeader={setHeader}
            setIconH={setIconH}
          /> */}
        <Sidebar
          setHeader={setHeader}
          setIconH={setIconH}
          sidebar={sidebar}
          setSidebar={setSidebar}
          setAdmin={roleSuperAdmin}
          onCollapse={(sidebar) => {
            // console.log(sidebar);
            setSidebar(sidebar);
          }}
        />
      </NavBarContainer>

      <ContentContainer id="content">
        {/* <HeaderContainer> */}
        <Header
          title={getHeader}
          icon={getIconH}
          setHeader={setHeader}
          setIconH={setIconH}
        />
        {/* </HeaderContainer> */}
        <InfoContainer>
          {/* <div id="main-overlay" className="main-overlay">
            <Spinner animation="border" variant="light" />
          </div> */}
          <main>{props.children}</main>
        </InfoContainer>
      </ContentContainer>
    </div>
  );
}

export default Layout;
