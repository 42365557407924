import { createSlice } from '@reduxjs/toolkit'
import Api from '../../core/services/api.service'

export const proposalSlice = createSlice({
  name: 'proposal',
  initialState: {
    proposal_list: [],
    proposal_detail: {
      file_name: null,
    },
    proposal_detail_created: {},
    proposal_detail_contact: [],

    proposal_all_list: [],
    proposal_temp_all_list: [],
    proposal_filter: {
      txtsearchpro: '',
      txtfilename: '',
      txtjobtitle: '',
      ddldate: '',
    },

    // Approval
    proposal_detail_approval: {},
    proposal_detail_created_approval: {},
    proposal_detail_contact_approval: [],
  },
  reducers: {
    setProposalList: (state, action) => {
      state.proposal_list = action.payload
    },
    setProposalDetail: (state, action) => {
      state.proposal_detail = { ...state.proposal_detail, ...action.payload }
    },
    setProposalCreatedBy: (state, action) => {
      state.proposal_detail_created = action.payload
    },
    setProposalContact: (state, action) => {
      state.proposal_detail_contact = action.payload
    },
    setAllListProposal: (state, action) => {
      state.proposal_all_list = action.payload
    },
    setTempAllListProposal: (state, action) => {
      state.proposal_temp_all_list = action.payload
    },
    setStoreProposalFilter: (state, action) => {
      state.proposal_filter = { ...state.proposal_filter, ...action.payload }
    },
    // approval
    setProposalDetailApproval: (state, action) => {
        state.proposal_detail_approval = action.payload
    },
    setProposalCreatedByApproval: (state, action) => {
        state.proposal_detail_created_approval = action.payload
    },
    setProposalContactApproval: (state, action) => {
        state.proposal_detail_contact_approval = action.payload
    },
  },
})

export const getProposalList = (id) => async (dispatch) => {
  try {
    const response = await Api.get('client/' + id + '/proposal/list')

    //    let sortL = response.data.sort(function(a, b) {
    //     return new Date(b.created_at) - new Date(a.created_at);
    //   });

    dispatch(setProposalList(response.data))
  } catch (err) {
    throw new Error(err)
  }
}

export const getAllProposalList = (proCompany, proFile, proJob, proDate) => async (dispatch, rejectWithValue) => {
  try {
    const response = await Api.get('proposal/list');

    let data_array = response.data;
    let newList = [];

          if(proCompany !== "" && proFile === "" && proJob === "" && proDate === "" ){
        newList = data_array.filter((pro) => {
            return pro.clients ? pro.clients.name.toLowerCase().includes(proCompany.toLowerCase()) : "";
        });

        dispatch(setAllListProposal(newList))
    }else if(proCompany !== "" && proFile !== "" && proJob === "" && proDate === "" ){
        newList = data_array.filter((pro) => {
            let compName = "";
    
            if (pro.clients !== null) {
                compName = pro.clients.name;
            } else {
                compName = "";
            };
    
            return (
                compName.toLowerCase().includes(proCompany.toLowerCase()) && 
                pro.file_name.toLowerCase().includes(proFile.toLowerCase())
            );
        });

        dispatch(setAllListProposal(newList))
    }else if(proCompany !== "" && proFile !== "" && proJob !== "" && proDate === "" ){
        newList = data_array.filter((pro) => {
            let compName = "";
    
            if (pro.clients !== null) {
                compName = pro.clients.name;
            } else {
                compName = "";
            };
    
            return (
                compName.toLowerCase().includes(proCompany.toLowerCase()) && 
                pro.file_name.toLowerCase().includes(proFile.toLowerCase()) && 
                pro.job_title.toLowerCase().includes(proJob.toLowerCase())
            );
        });

        dispatch(setAllListProposal(newList))
    }
    // full filter
     else if(proCompany !== "" && proFile !== "" && proJob !== "" && proDate !== "" ){
        newList = data_array.filter((pro) => {
            let compName = "";
    
            if (pro.clients !== null) {
                compName = pro.clients.name;
            } else {
                compName = "";
            };
    
            return (
                compName.toLowerCase().includes(proCompany.toLowerCase()) && 
                pro.file_name.toLowerCase().includes(proFile.toLowerCase()) && 
                pro.job_title.toLowerCase().includes(proJob.toLowerCase()) &&
                pro.created_at.substring(0, 10) >= proDate
            );
        });

        dispatch(setAllListProposal(newList))
    }else if(proCompany !== "" && proFile !== "" && proJob === "" && proDate !== "" ){
        newList = data_array.filter((pro) => {
            let compName = "";
    
            if (pro.clients !== null) {
                compName = pro.clients.name;
            } else {
                compName = "";
            };
    
            return (
                compName.toLowerCase().includes(proCompany.toLowerCase()) && 
                pro.file_name.toLowerCase().includes(proFile.toLowerCase()) && 
                pro.created_at.substring(0, 10) >= proDate
            );
        });

        dispatch(setAllListProposal(newList))
    }else if(proCompany !== "" && proFile === "" && proJob !== "" && proDate === "" ){
        newList = data_array.filter((pro) => {
            let compName = "";
    
            if (pro.clients !== null) {
                compName = pro.clients.name;
            } else {
                compName = "";
            };
    
            return (
                compName.toLowerCase().includes(proCompany.toLowerCase()) && 
                pro.job_title.toLowerCase().includes(proJob.toLowerCase()) 
            );
        });

        dispatch(setAllListProposal(newList))
    }else if(proCompany !== "" && proFile === "" && proJob !== "" && proDate !== "" ){
        newList = data_array.filter((pro) => {
            let compName = "";
    
            if (pro.clients !== null) {
                compName = pro.clients.name;
            } else {
                compName = "";
            };
    
            return (
                compName.toLowerCase().includes(proCompany.toLowerCase()) && 
                pro.job_title.toLowerCase().includes(proJob.toLowerCase()) &&
                pro.created_at.substring(0, 10) >= proDate
            );
        });

        dispatch(setAllListProposal(newList))
    }else if(proCompany !== "" && proFile === "" && proJob === "" && proDate !== "" ){
        newList = data_array.filter((pro) => {
            let compName = "";
    
            if (pro.clients !== null) {
                compName = pro.clients.name;
            } else {
                compName = "";
            };
    
            return (
                compName.toLowerCase().includes(proCompany.toLowerCase()) && 
                pro.created_at.substring(0, 10) >= proDate
            );
        });

        dispatch(setAllListProposal(newList))
    }else if(proCompany === "" && proFile !== "" && proJob === "" && proDate === "" ){
        newList = data_array.filter((pro) => {
            return ( 
                pro.file_name.toLowerCase().includes(proFile.toLowerCase())
            );
        });

        dispatch(setAllListProposal(newList))
    }else if(proCompany === "" && proFile !== "" && proJob !== "" && proDate === "" ){
        newList = data_array.filter((pro) => {
            return (
                pro.file_name.toLowerCase().includes(proFile.toLowerCase()) && 
                pro.job_title.toLowerCase().includes(proJob.toLowerCase())
            );
        });

        dispatch(setAllListProposal(newList))
    }else if(proCompany === "" && proFile !== "" && proJob !== "" && proDate !== "" ){
        newList = data_array.filter((pro) => {    
            return ( 
                pro.file_name.toLowerCase().includes(proFile.toLowerCase()) && 
                pro.job_title.toLowerCase().includes(proJob.toLowerCase()) &&
                pro.created_at.substring(0, 10) >= proDate
            );
        });

        dispatch(setAllListProposal(newList))
    }else if(proCompany === "" && proFile !== "" && proJob === "" && proDate !== "" ){
        newList = data_array.filter((pro) => {    
            return (
                pro.file_name.toLowerCase().includes(proFile.toLowerCase()) && 
                pro.created_at.substring(0, 10) >= proDate
            );
        });

        dispatch(setAllListProposal(newList))
    }else if(proCompany === "" && proFile === "" && proJob !== "" && proDate === "" ){
        newList = data_array.filter((pro) => {
            return (
                pro.job_title.toLowerCase().includes(proJob.toLowerCase())
            );
        });

        dispatch(setAllListProposal(newList))
    }else if(proCompany === "" && proFile === "" && proJob !== "" && proDate !== "" ){
        newList = data_array.filter((pro) => {
            return (
                pro.job_title.toLowerCase().includes(proJob.toLowerCase()) &&
                pro.created_at.substring(0, 10) >= proDate
            );
        });

        dispatch(setAllListProposal(newList))
    }else if(proCompany === "" && proFile === "" && proJob === "" && proDate !== "" ){
        newList = data_array.filter((pro) => {
            return (
                pro.created_at.substring(0, 10) >= proDate
            );
        });

        dispatch(setAllListProposal(newList))
    }
    else{
        dispatch(setAllListProposal(data_array))
        dispatch(setTempAllListProposal(data_array))
    }

    
  } catch (err) {
    throw rejectWithValue(err.message)
  }
}

export const getProposalDetail = (id, idpro) => async (dispatch, rejectWithValue) => {
  try {
    const response = await Api.get(
      'client/' + id + '/proposal/' + idpro + '/view',
    )

    dispatch(setProposalDetail(response.data));
    dispatch(setProposalCreatedBy(response.data.created_by_users));
    dispatch(setProposalContact(response.data.main_contact_llm_detail));
  } catch (err) {
    throw rejectWithValue(err.message)
  }
}

export const getProposalDetailApproval = (id, idpro) => async (dispatch, rejectWithValue) => {
    try {
      const response = await Api.get(
        'client/' + id + '/proposal/' + idpro + '/view',
      )
  
      dispatch(setProposalDetailApproval(response.data));
      dispatch(setProposalCreatedByApproval(response.data.created_by_users));
      dispatch(setProposalContactApproval(response.data.main_contact_llm_detail));
      
    } catch (err) {
      throw rejectWithValue(err.message)
    }
  }

export const {
  setProposalList,
  setProposalDetail,
  setProposalCreatedBy,
  setProposalContact,
  setAllListProposal,
  setTempAllListProposal,
  setStoreProposalFilter,
  setProposalDetailApproval,
  setProposalCreatedByApproval,
  setProposalContactApproval
} = proposalSlice.actions
export default proposalSlice.reducer
