import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Api from "../../core/services/api.service";

import moment from "moment";

import { mdiBell, mdiCalendar } from "@mdi/js";
import Icon from "@mdi/react";

// import { Button } from "react-bootstrap";
import { TableNotification } from "../../components/Table/TableNotification";

import { getListNotification } from "../../store/actions/notificationSlice";
import { storeTitle } from "../../store/actions/headerTitleSlice";

const AllNotificationsCandidate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.login);
  const { notificationList } = useSelector((state) => state.notification);

  const btnClickNoti = (url, id) => (e) => {
    // console.log("id", id)
    Api.get("notification/" + id + "/markAsRead")
      .then((response) => {
        dispatch(getListNotification(user.sub, 'candidate'));

        navigate(url);
      })
      .catch((error) => {
        console.log("error btnClickNoti", error);
      });
  };

  const headers = [
    {
      Header: "Icon",
      hideHeader: false,
      minWidth: "50px",
      width: "50px",
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="icon-noti-card" onClick={btnClickNoti(data.data.url, data.id)}>
            <div className="box-icon">
              <Icon path={mdiCalendar} />
            </div>
          </div>
        );
      },
    },
    {
      Header: "Notification",
      accessor: (row) => `${row.notiTitle}`,
      hideHeader: false,
      minWidth: "200px",
      width: "600px",
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div
            className="info-noti-card"
            key={data.id}
            onClick={btnClickNoti(data.data.url,data.id)}
          >
            {/* <div className="noti-title">{data.notiTitle}</div> */}
            <div
              className="content-noti-card"
              dangerouslySetInnerHTML={{
                __html: data.data.message,
              }}
            ></div>
            <div className="noti-date">
              {moment(data.created_at).format("DD MMMM YYYY")}
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {

    dispatch(getListNotification(user.sub, 'candidate'));
    dispatch(storeTitle({title: "Notifications", icon: mdiBell}));
  }, [dispatch, user.sub]);

  return (
    <div className="main-div">
      <div className="top-title-div">
        <TableNotification columns={headers} data={notificationList} site="candidate" />
      </div>
    </div>
  );
};

export default AllNotificationsCandidate;
