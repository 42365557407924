import React, { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  mdiMagnify,
  mdiAccountPlus,
  mdiDotsHorizontal,
  mdiPencil,
  mdiDelete,
  mdiAccountKey,
  mdiCircleMedium,
} from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Dropdown, Card } from "react-bootstrap";
import Api from "../../core/services/api.service";

import AuthAbilities from "../../pages/Admin/Auth/AuthAbilities";
import SwalAlert from "../Swal/SwalAlert";

import TextboxIcon from "../../components/TextField/TextFieldIcon";
import Modal from "../Popup/ModalPopup";
import { CustomTable } from "../Table/Table";

// import { addStaff } from "../../pages/Admin/Clients/data";

// import {
//   // getCandidateList,
//   getCandidateFind,
// } from "../../store/actions/candidateSlice";

export const TabStaff = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const abilities = AuthAbilities();
  const customSwal = SwalAlert();

  // const { candidateList } = useSelector((state) => state.candidate);

  const [staffList, setStaffList] = useState([]);
  const [allListStaff, setAllListStaff] = useState([]);

  const [candidateList, setCandidateList] = useState([]);
  const [allListCandidate, setAllListCandidate] = useState([]);

  const [showStaff, setShowStaff] = useState(false);

  const [checkCandidate, setCheckCandidate] = useState([]);
  const [candSelected, setCandSelected] = useState(0);

  const btnOpenStaff = () => {
    setShowStaff(true);
    setCheckCandidate([]);
    setCandSelected(0);
    getListAllCandidate();
  };

  const btnCloseStaff = () => {
    setShowStaff(false);
  };

  const btnAddStaff = (e) => {
    // setShowStaf(true);
    navigate("/clients/create-staff/" + props.page + "/" + params.id);
  };

  const btnSaveStaff = (e) => {
    // console.log("params", params.id)

    var candid = checkCandidate.map((s) => s.id);

    // console.log("selected", candid)

    customSwal
      .fire({
        title: "Do you want to add candidate as staff?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.post("client/" + params.id + "/staff/addStaffFromList", {
            candidate_id: candid,
          })
            .then((response) => {
              // let data = response.data;

              setShowStaff(false);
              getListStaff();
              setCheckCandidate([]);
              setCandSelected(0);
              // console.log("data list candidate", data);
              // getListCandidate();
              // setShowMerge(false);

              customSwal.fire({
                title: "Saved Successful!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error addStaffFromList", error);
            });
        }
      });
  };

  const btnEditStaff = (idstaff, status) => (e) => {
  
    if(status === "client"){
      navigate("/clients/view-staff-client/" + props.page + "/" + params.id + "/" + idstaff);
    }else{
      navigate("/candidates/viewCandidate/" + idstaff);
    }
    
  };

  const headerAddStaff = [
    {
      Header: "Staff Name",
      minWidth: 100,
      width: 200,
      accessor: (row) => `${row.first_name}`,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="form-container-default">
            {data.last_name
              ? data.first_name + " " + data.last_name
              : data.last_name}
          </div>
        );
      },
    },
    {
      Header: "Country",
      accessor: (row) => `${row.citizenship_main}`,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="form-container-default">
            {data.citizenship_main ? data.citizenship_main : "-"}
          </div>
        );
      },
    },
    {
      Header: "Job Title",
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        let jobTitle = "";

        if (data.candidate_experiences.length > 0) {
          jobTitle = data.candidate_experiences[0].title;
        } else {
          jobTitle = data.job_title ? data.job_title : "-";
        }

        return <>{jobTitle}</>;
      },
    },
    {
      Header: "",
      accessor: "selection",
      disableSortBy: true,
      minWidth: 10,
      width: 50,
      Cell: ({ row }) => {
        const data = row.original;

        const onItemCheck = (data) => (event) => {
          if (checkCandidate.length > 0) {
            if (event.target.checked === false) {
              const index = checkCandidate.findIndex(function (chck) {
                return chck.id === data.id;
              });

              checkCandidate.splice(index, 1);
            } else {
              checkCandidate.push(data);
            }
          } else {
            checkCandidate.push(data);
          }

          setCandSelected(checkCandidate.length);

          // console.log("checkCandidate", checkCandidate);
        };

        const checkedItem = (id) => {
          let cnd = [];

          const checkCand = checkCandidate.filter((chec) => {
            return chec.id === id;
          });

          cnd = checkCand[0];

          return cnd;
        };

        return (
          <div className="checkbox-container">
            <input
              type="checkbox"
              checked={checkedItem(data.id)}
              id={"row" + data.id}
              onChange={onItemCheck(data)}
            />
          </div>
        );
      },
    },
  ];

  const headerStaff = [
    {
      Header: "No",
      minWidth: 50,
      width: 50,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Status",
      hideHeader: true,
      minWidth: 50,
      width: 50,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            <Icon
              path={mdiCircleMedium}
              color={data.status === "client" ? "#ffd028" : "#467FD0"}
              size={1}
            />
          </div>
        );
      },
    },
    {
      Header: "Staff Name",
      maxWidth: 400,
      minWidth: 100,
      width: 250,
      Cell: ({ row }) => {
        const data = row.original;
        // console.log("data", data)

        const navigate = useNavigate();

        function handleClick() {
          navigate("/candidates/viewCandidate/" + data.id);
        }

        return (
          <div className="container-div list-cli">
            <Button
              variant="link"
              className="no-underline"
              onClick={handleClick}
              style={{ textTransform: "capitalize" }}
            >
              {data.first_name ? data.first_name : "-"}{" "}
              {data.last_name ? data.last_name : "-"}
            </Button>
          </div>
        );
      },
    },
    {
      Header: "Job Title",
      maxWidth: 300,
      minWidth: 100,
      width: 300,
      Cell: ({ row }) => {
        const data = row.original;

        var array_experience = "";

        if(data.candidate_experiences.length === 1){
          var array = data.candidate_experiences.map((obj, index) => {
            return obj.title;
          });
  
          array_experience = array.join("");
        }else if(data.candidate_experiences.length > 1){
          var array = data.candidate_experiences.map((obj, index) => {
            // return obj.sno + ".  " + obj.title + "<br>";
            let sno = index + 1;
            return sno + ".  " + obj.title + "<br>";
          });
  
          array_experience = array.join("");

        }else{
          array_experience = "-";
        }

        var jobTitle =  data.job_title ? data.job_title : "-";

      
        return <div>{data.status === "client" ? jobTitle : array_experience ? <div dangerouslySetInnerHTML={{__html: array_experience}} /> : "-"}</div>;
      },
    },
    {
      Header: "Country",
      maxWidth: 200,
      minWidth: 100,
      width: 150,
      Cell: ({ row }) => {
        const data = row.original;

        var array_experience = "";

        if(data.candidate_experiences.length > 0){
          array_experience = data.candidate_experiences[0].location;
        }else{
          array_experience = "";
        }

        var citizen =  data.citizenship_main ? data.citizenship_main : "-";
        

        return <div>{data.status === "client" ? citizen : array_experience ? array_experience : "-"}</div>;
      },
    },
    {
      Header: "Mobile Number",
      maxWidth: 200,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            {/* {data.mobile_no_code ? data.mobile_no_code : "-"} */}
            {data.mobile_no ? data.mobile_no : "-"}
          </div>
        );
      },
    },
    {
      Header: "Email Address",
      accessor: (row) => `${row.comp_email}`,
      maxWidth: 350,
      minWidth: 100,
      width: 380,
      Cell: ({ row }) => {
        const data = row.original;

        var array_experience = "";

        if(data.candidate_experiences.length > 0){
          array_experience = data.candidate_experiences[0].office_email;
        }else{
          array_experience = "";
        }

        var compEmail = data.comp_email ? data.comp_email : "-";

        return <div>{data.status === "client" ? compEmail : array_experience ? array_experience : "-"}</div>;
      },
    },
    {
      Header: "Direct Line",
      maxWidth: 200,
      minWidth: 100,
      width: 150,
      Cell: ({ row }) => {
        const data = row.original;

        var array_experience = "";

        if(data.candidate_experiences.length > 0){
          array_experience = data.candidate_experiences[0].office_number;
        }else{
          array_experience = "";
        }

        var directLine = data.direct_line_no ? data.direct_line_no : "-";

        return <div>{data.status === "client" ? directLine : array_experience ? array_experience : "-"}</div>;
      },
    },
    {
      Header: "",
      // accessor: row => `${row.oposition} ${row.totPosition}`,
      accessor: "edit",
      disableSortBy: true,
      maxWidth: 100,
      minWidth: 10,
      width: 70,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={btnEditStaff(data.id, data.status)}
                  hidden={
                    props.page === "active"
                      ? !abilities.includes("edit staff")
                      : !abilities.includes("edit staff")
                  }
                >
                  <Icon path={mdiPencil} color="#467FD0" />
                  Edit Staff
                </Dropdown.Item>
                <Dropdown.Item
                  hidden={
                    props.page === "active"
                      ? !abilities.includes("generate password")
                      : !abilities.includes("generate password")
                  }
                >
                  <Icon path={mdiAccountKey} color="#42BA96" />
                  Generate Password
                </Dropdown.Item>
                <Dropdown.Item
                  hidden={
                    props.page === "active"
                      ? !abilities.includes("delete staff")
                      : !abilities.includes("delete staff")
                  }
                >
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete Staff
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const handleSearchList = (event) => {
    let value = event.target.value.toLowerCase();

    if (value !== "") {
      let newList = [];
      newList = staffList.filter((staff) => {
        if (!staff.last_name || staff.last_name === "") {
          return staff.first_name.toLowerCase().includes(value.toLowerCase());
        } else {
          return (
            staff.first_name.toLowerCase().includes(value.toLowerCase()) ||
            staff.last_name.toLowerCase().includes(value.toLowerCase())
          );
        }
      });

      setAllListStaff(newList);
    } else {
      setAllListStaff(staffList);
    }
  };

  const handleSearchCandidate = (event) => {
    let value = event.target.value.toLowerCase();

    if (value !== "") {
      let newList = [];
      newList = candidateList.filter((cand) => {
        if (!cand.last_name || cand.last_name === "") {
          return cand.first_name.toLowerCase().includes(value.toLowerCase());
        } else {
          return (
            cand.first_name.toLowerCase().includes(value.toLowerCase()) ||
            cand.last_name.toLowerCase().includes(value.toLowerCase())
          );
        }
      });

      setAllListCandidate(newList);
    } else {
      setAllListCandidate(candidateList);
    }
  };

  const getListStaff = useCallback(() => {
    Api.get("client/" + params.id + "/staff/listStaff")
      .then((response) => {
        let data = response.data;

        console.log("staff", data)

        setAllListStaff(data);
        setStaffList(data);
      })
      .catch((error) => {
        console.log("error list client", error);
      });
  }, [params]);

  const getListAllCandidate = useCallback(() => {
    Api.get("candidate/all")
      .then((response) => {
        let data = response.data;

        setAllListCandidate(data);
        setCandidateList(data);
      })
      .catch((error) => {
        console.log("error list all candidate", error);
      });
  }, []);

  // Load staff
  useEffect(() => {
    getListStaff();
    // dispatch(getCandidateList());
  }, [getListStaff, dispatch]);

  return (
    <>
      <Card>
        <Card.Body>
          <div className="row-div">
            <div className="left-div">
              <TextboxIcon
                name="txtstaffclient"
                type="text"
                placeholder="Search"
                inputSize="input--medium"
                onChange={(event) => handleSearchList(event)}
                icon={mdiMagnify}
              ></TextboxIcon>

              <div className="stats-indicator">
                Status:
                <div>
                  <Icon path={mdiCircleMedium} color="#ffd028" size={1} />
                  {props.page === "potential" ? 'Potential Client' : 'Client'}
                </div>
                <div>
                  <Icon path={mdiCircleMedium} color="#467FD0" size={1} />
                  Candidate
                </div>
              </div>
            </div>
            <div className="right-div tab-staff">
              <Button
                variant="link"
                className="modal-link"
                onClick={btnOpenStaff}
                hidden={
                  props.page === "active"
                    ? !abilities.includes("add staff")
                    : !abilities.includes("add staff")
                }
              >
                <Icon path={mdiAccountPlus} />
                <div>Add Staff</div>
              </Button>
            </div>
          </div>
          <CustomTable
            headers={headerStaff}
            items={allListStaff}
            paginate={allListStaff.length}
            maxRows={10}
          ></CustomTable>
        </Card.Body>
      </Card>

      {/* Modal */}
      <Modal
        show={showStaff}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseStaff}
        title="Add Staff"
        content={
          <>
            <div className="row-div">
              <div className="left-div">
                <TextboxIcon
                  name="txtsearchcandidate"
                  type="text"
                  placeholder="Search"
                  inputSize="input--medium"
                  onChange={(event) => handleSearchCandidate(event)}
                  icon={mdiMagnify}
                ></TextboxIcon>
              </div>
              <div className="right-div comp-selected">
                <div style={{ marginRight: "20px" }}>
                  {candSelected} selected
                </div>
                <Button
                  variant="link"
                  className="modal-link"
                  onClick={btnAddStaff}
                >
                  <Icon path={mdiAccountPlus} color="#EC782F" />
                  <div>Create New Staff</div>
                </Button>
              </div>
            </div>
            <CustomTable
              headers={headerAddStaff}
              items={allListCandidate}
              paginate={allListCandidate.length}
              maxRows={10}
            ></CustomTable>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseStaff}>
              Cancel
            </Button>
            <Button
              type="submit"
              form="infor-form"
              variant="primary"
              onClick={btnSaveStaff}
              disabled={candSelected > 0 ? false : true}
            >
              Add Staff
            </Button>
          </>
        }
      ></Modal>
    </>
  );
};
