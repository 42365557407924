import React, { useState, useEffect, useCallback } from "react";
import { Form, Row, Button } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import Popup from "../Popup/ModalPopup";
import Swal from "sweetalert2";
import Api from "../../core/services/api.service";

function ButtonNumber({ idClient, idAssign, items, button }) {
  const [showInvoice, setShowInvoice] = useState(false);
  const [errorFormInv, setErrorFormInv] = useState({});
  const [modeModal, setModeModal] = useState({
    title: null,
    button: null,
    id: 0,
    refno: null,
    status: null,
  });

  const totalBox = 6;
  const addBox = 1;

  const numberBox =
    items.length === 6 ? totalBox - items.length : totalBox - items.length - 1;

  const closeInvoice = () => {
    setShowInvoice(false);
  };

  const editInvoice = (id, refno, status) => () => {
    // console.log("idinvocie", id);
    setModeModal({
      title: "Edit Invoice",
      button: "Edit",
      id: id,
      refno: refno,
      status: status,
    });
    setShowInvoice(true);
  };

  // Checking Error Form Invoice
  const formErrorInv = (object) => {
    const newErrors = {};

    // console.log("object", object);

    if (!object.txtRefNo || object.txtRefNo === "")
      newErrors.txtRefNo = "Fill in the blank!";

    if (!object.ddlStatus || object.ddlStatus === "")
      newErrors.ddlStatus = "Fill in the blank!";

    return newErrors;
  };

  const customSwal = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-okay",
      cancelButton: "btn btn-cancel",
      title: "swal-title",
      popup: "swal-popup",
    },
    buttonsStyling: false,
  });

  const btnUpdateInvoice = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtInvoice = Object.fromEntries(data.entries());

    // console.log("dtInvoice", dtInvoice);

    const newErrors = formErrorInv(dtInvoice);

    // console.log("listInvoices", listInvoices);

    // console.log("newErros", newErrors);
    if (Object.keys(newErrors).length > 0) {
      setErrorFormInv(newErrors);
    } else {
      customSwal
        .fire({
          title: "Do you want to update invoice?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            // console.log("idClient", idClient);
            // console.log("idInvoice", modeModal.id);
            // console.log("idAssign", idAssign);
            // setShowInvoice(false);
            Api.patch(
              "client/" +
                idClient +
                "/assignment/" +
                idAssign +
                "/invoice/" +
                modeModal.id +
                "/update",
              {
                reference: dtInvoice.txtRefNo,
                status: dtInvoice.ddlStatus,
              }
            )
              .then((response) => {
                let data = response.data;

                const newState = listInvoices.map((obj) => {
                  // 👇️ if id equals 2, update country property
                  if (obj.id === data.id) {
                    return {
                      ...obj,
                      reference: data.reference,
                      status: data.status,
                    };
                  }

                  // 👇️ otherwise return object as is
                  return obj;
                });
                setListInvoices(newState);

                setShowInvoice(false);
                customSwal.fire({
                  title: "Saved Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("erorr btnUpdateInvoice", error);
              });
          }
        });
    }
  };

  const [listInvoices, setListInvoices] = useState([]);

  const getListInvoices = useCallback(() => {
    setListInvoices(items);
  }, [items]);

  useEffect(() => {
    getListInvoices();
  }, [getListInvoices]);

  return (
    <>
      <div className="btn-number-container">
        {listInvoices.map((i, index) => (
          <div className="btn-number-each-div" key={index}>
            <div className="btn-number-each">
              <div>
                {(() => {
                  if (i.status === "paid") {
                    return (
                      <div
                        data-tip={
                          "Reference: " +
                          i.reference +
                          "<br> Status: " +
                          i.status
                        }
                      >
                        <button
                          onClick={editInvoice(i.id, i.reference, i.status)}
                          className="btn--inv--paid--solid"
                        >
                          {index + 1}
                        </button>
                        <ReactTooltip
                          place="bottom"
                          type="dark"
                          effect="solid"
                          html={true}
                          backgroundColor="#E5E5E5"
                          textColor="#212529"
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div
                        data-tip={
                          "Reference: " +
                          i.reference +
                          "<br> Status: " +
                          i.status
                        }
                      >
                        <button
                          onClick={editInvoice(i.id, i.reference, i.status)}
                          className="btn--inv--unpaid--solid"
                        >
                          {index + 1}
                        </button>
                        <ReactTooltip
                          place="bottom"
                          type="dark"
                          effect="solid"
                          html={true}
                          backgroundColor="#E5E5E5"
                          textColor="#212529"
                        />
                      </div>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        ))}

        {items.length !== 6
          ? new Array(addBox).fill("", 0, addBox).map(
              (p, index2) => (
                <div className="btn-number-each-div" key={index2}>
                  <div className="btn-number-each">
                    <div data-tip="New Invoice">
                      {button}
                      {/* <button onClick={addInvoice("Add")}>+</button> */}
                      <ReactTooltip
                        place="bottom"
                        type="dark"
                        effect="solid"
                        html={true}
                        backgroundColor="#E5E5E5"
                        textColor="#212529"
                      />
                    </div>
                  </div>
                </div>
              )
              // <div>{index}</div>
            )
          : null}
        {new Array(numberBox).fill("", 0, numberBox).map((p, index3) => (
          <div className="btn-number-each-div" key={index3}>
            <div className="btn-number-each-text">
              <button>
                <div>{index3}</div>
              </button>
            </div>
          </div>
        ))}
      </div>

      <Popup
        show={showInvoice}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={closeInvoice}
        title={modeModal.title}
        content={
          <>
            <Form id="invoice-form" onSubmit={btnUpdateInvoice}>
              <Row>
                <Form.Group className="mb-3 col-6">
                  <Form.Label>Invoice Reference No.</Form.Label>
                  <Form.Control
                    name="txtRefNo"
                    type="text"
                    defaultValue={modeModal.refno}
                    placeholder="Example: 123456789"
                    isInvalid={!!errorFormInv.txtRefNo}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorFormInv.txtRefNo}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3 col-6">
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    name="ddlStatus"
                    defaultValue={modeModal.status}
                    isInvalid={!!errorFormInv.ddlStatus}
                  >
                    <option value="paid">Paid</option>
                    <option value="unpaid">Unpaid</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errorFormInv.ddlStatus}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={closeInvoice}>
              Cancel
            </Button>
            <Button type="submit" variant="primary" form="invoice-form">
              {modeModal.button}
            </Button>
          </>
        }
      />
    </>
  );
}

export default ButtonNumber;
