import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import styled from "styled-components";
import Icon from "@mdi/react";
// import { current } from "@reduxjs/toolkit";

import { storePage } from "../../store/actions/paginationSlice";

const SidebarLink = styled(Link)`
  display: flex;
  color: #54595f;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    /* background: #ec782f; */
    color: #ec782f;
    cursor: pointer;
  }

  &.menu-item.active {
    background: #ec782f;
    color: #fff;
  }
`;

const SidebarLabel = styled.div`
  margin-left: 16px;
  /* display: ${({ sidebar }) => (sidebar ? "none" : "normal")}; */
`;

const DropdownLink = styled(Link)`
  background: #e5e5e5;
  height: 45px;
  padding-left: 2.7rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #212529;
  font-size: 12px;
  font-weight: 500;

  &:hover {
    background: #bdbdbd;
    color: #212529;
    cursor: pointer;
  }

  &.sub-menu.active {
    background: #ec782f;
    color: #fff;
  }
`;

const SubMenu = ({ item, setHeader, setIconH, sidebar, index }) => {
  // const [subnav, setSubnav] = useState(false);
  const dispatch = useDispatch();
  const [expand] = useState(false);

  const btnLink = (to, name, iconClassName) => () => {
    if (to === window.location.pathname) {
      setHeader(name);
      setIconH(iconClassName);

      dispatch(storePage(0));

      // console.log("window.location.href", window.location.href)

      // localStorage.setItem('menu_title',name);
      // localStorage.setItem('menu_icon',iconClassName);
    }
  };

  useEffect(() => {
    if (!sidebar) {
      removeActiveClassFromSubMenu();
    }
    // props.onCollapse(sidebar);
  }, [sidebar]);

  //just an improvment and it is not recorded in video :(
  const removeActiveClassFromSubMenu = () => {
    document.querySelectorAll(".sub-menu").forEach((el) => {
      el.classList.remove("active");
    });
  };

  const notGo = (to, index) => (e) => {
    // console.log("index ind", index);

    if (to === "") {
      e.preventDefault();
    }
  };

  return (
    <>
      <li onClick={btnLink(item.to, item.name, item.iconClassName)}>
        <SidebarLink
          to={item.to}
          className={`menu-item`}
          onClick={notGo(item.to, index)}
          hidden={!item.hidden}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon path={item.iconClassName} size={1} />
            <SidebarLabel className="sidebar-label" sidebar={sidebar}>
              {item.name}
            </SidebarLabel>
          </div>
          {item.subMenus ? <Icon path={item.iconDrop} size={1} /> : null}
          
        </SidebarLink>

        {item.subMenus && item.subMenus.length > 0 ? (
          <ul className={`sub-menu ${expand ? "active" : ""}`}>
            {item.subMenus.map((item, index) => (
              <li
                key={index}
                onClick={btnLink(item.to, item.name, item.iconClassName)}
                hidden={!item.hidden}
                // className={`sub-nav`}
              >
                <DropdownLink to={item.to} className="dd-sidebar">
                  <SidebarLabel>{item.name}</SidebarLabel>
                </DropdownLink>
              </li>
            ))}
          </ul>
        ) : null}
      </li>
    </>
  );
};

export default SubMenu;
