import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Tab, Nav, Card } from "react-bootstrap";
import moment from "moment";

import CardInfo from "../../components/Card/CardInfo";
import BreadCrumb from "../../components/Breadcrumb/BreadCrumb";

import { mdiClipboardAccount } from "@mdi/js";

import { TabCandidatesClient } from "../../components/Tab/TabClients/TabCandidatesClient";
import { TabProgressClient } from "../../components/Tab/TabClients/TabProgressClient";
import { TabJDClient } from "../../components/Tab/TabClients/TabJDClient";
import { TabProposalClient } from "../../components/Tab/TabClients/TabProposalClient";

import { getAssignmentDetails } from "../../store/actions/assignmentSlice";
import { storeTitle } from "../../store/actions/headerTitleSlice";

// import rptTestPdf from '../../components/Report/Template_Report/rptTestPdf';
// import { PDFDownloadLink } from "@react-pdf/renderer";

const ViewAssignment = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.login);
  const { assignDetails, assignCountry, assignStaff } = useSelector((state) => state.assignment);

  const [crumbs, setCrumbs] = useState([]);

  const selectedCrumb = (crumb) => {
    if(crumb === "Assignments"){
      navigate("/");
    }
    // navigate(-1);
  };

  useEffect(() => {
    dispatch(getAssignmentDetails(user.clientid, params.idassign));
    dispatch(storeTitle({title: "Assignments", icon: mdiClipboardAccount}));
    const name = assignDetails.title;
    setCrumbs(["Assignments", name]);
  }, [assignDetails.title, dispatch, user.clientid, params.idassign]);

  return (
    <div className="main-div list-cli">

      
      <div className="top-title-div">
        <BreadCrumb crumbs={crumbs} selected={selectedCrumb}></BreadCrumb>
      </div>
      <div className="top-title-div">
        <Card className="mb-3">
          <Card.Body>
            <div className="top-card">
              <div className="top-left-card">
                <div className="big-text">{assignDetails.title}</div>
                <div>&bull;</div>
                <div className="mini-text no-mb">
                  {assignCountry ? assignCountry : "-"}
                </div>
                <div>&bull;</div>
                <div className="mini-text no-mb">
                  {moment(assignDetails.created_at).format("DD MMMM YYYY")}
                </div>
              </div>
              <div className="top-right-card">
                <div className="mini-text no-mb">
                  Consultant Name:{" "}
                  <span className="text">
                    {assignStaff.map((obj, index) => {
                      let nameStaff =
                        obj.first_name + " " + obj.last_name + ",";
                      return nameStaff;
                    })}
                  </span>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="mini-text no-mb"
                    style={{ display: "flex", marginRight: "10px" }}
                  >
                    Status:{" "}
                  </div>
                  <span>
                    <CardInfo
                      cardTextSize="card_text--medium"
                      cardTextStyle="card_text--primary--solid"
                    >
                      {assignDetails.status === "in_discussion" ||
                      assignDetails.status === "in_progress" ||
                      assignDetails.status === "on_hold"
                        ? assignDetails.status.replace(/_/g, " ")
                        : assignDetails.status}
                    </CardInfo>
                  </span>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>

      <Tab.Container defaultActiveKey="1">
        <div className="nav-container scrollable">
          <Nav variant="tabs" justify>
            <Nav.Item>
              <Nav.Link eventKey="1">Candidates</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="2">Job Descriptions</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="3">Progress / Reference Reports</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="4">Attachments</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>

        <Tab.Content>
          <Tab.Pane eventKey="1">
            <TabCandidatesClient></TabCandidatesClient>
          </Tab.Pane>
          <Tab.Pane eventKey="2">
            <TabJDClient></TabJDClient>
          </Tab.Pane>
          <Tab.Pane eventKey="3">
            <TabProgressClient></TabProgressClient>
          </Tab.Pane>
          <Tab.Pane eventKey="4">
            <TabProposalClient></TabProposalClient>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default ViewAssignment;
