import {createSlice} from '@reduxjs/toolkit';

export const headerTitleSlice = createSlice({
    name: "headerIcon",
    initialState: {
        getTitle: {
            title: "",
            icon: ""
        },
    },
    reducers: {
        storeTitle: (state, action) => {
            state.getTitle = { ...state.getTitle, ...action.payload };
        },
    }
});

export const { storeTitle } = headerTitleSlice.actions;
export default headerTitleSlice.reducer;