import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Card, Button, Dropdown, Form, Col, Row } from "react-bootstrap";
import Api from "../../core/services/api.service";

import jsPDF from "jspdf";
import toast, { Toaster } from "react-hot-toast";
import SwalAlert from "../Swal/SwalAlert";

import Icon from "@mdi/react";
import {
  mdiFilePlus,
  mdiDotsHorizontal,
  mdiPencil,
  mdiDelete,
  mdiFileRefresh,
  mdiMagnify,
  mdiAccount,
  mdiDownload,
} from "@mdi/js";

import CustomTable from "../Table/Table";
import Modal from "../Popup/ModalPopup";
import TextboxIcon from "../TextField/TextFieldIcon";
import Certificate from "../../assets/certificate.jpg";
import Cert2 from "../../assets/cert_2.jpg";

function TabCertificate() {
  const params = useParams();
  const customSwal = SwalAlert();

  const [errorForm, setErrorForm] = useState({});
  const [showCertForm, setShowCertForm] = useState(false);
  const [showSelectParticipant, setShowSelectParticipant] = useState(false);
  const [viewParticipant, setViewParticipant] = useState(false);
  const [modeModal, setModeModal] = useState({
    title: null,
    button: null,
  });

  const [certState, setCertState] = useState({
    idcert: "",
    txtrow1: "",
    txtrow2: "",
  });

  const [listCert, setListCert] = useState([]);
  const [listPartCert, setListPartCert] = useState([]);

  const [listParticipant, setListParticipant] = useState([]);
  const [allListParticipant, setAllListParticipant] = useState([]);

  const [checkParticipant, setCheckParticipant] = useState([]);
  const [participantSelected, setParticipantSelected] = useState(0);

  const header = [
    {
      Header: "Header",
      accessor: "row_1",
      minWidth: 200,
      width: 400,
    },
    {
      Header: "Subtitle",
      accessor: "row_2",
      minWidth: 200,
      width: 400,
    },
    {
      Header: "Generated Certificate",
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <>
            {data.certificate_has_candidate.length > 0 ? (
              <Button
                variant="link"
                className="reminder-btn set-rem"
                onClick={btnViewParticipant(data)}
              >
                <Icon path={mdiAccount} />
                Download
              </Button>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      Header: "",
      accessor: "actions",
      minWidth: 80,
      width: 80,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={btnEditCertForm(data)}>
                  <Icon path={mdiPencil} color="#467FD0" />
                  Edit Certificate
                </Dropdown.Item>
                <Dropdown.Item onClick={btnOpenSelectParticipant(data)}>
                  <Icon path={mdiFileRefresh} color="#467FD0" />
                  Generate Certificate
                </Dropdown.Item>
                <Dropdown.Item>
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete Certificate
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const headerParticipant = [
    {
      Header: "Participant Name",
      minWidth: 100,
      width: 200,
      accessor: (row) => `${row.first_name}`,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="form-container-default">
            {data.last_name
              ? data.first_name + " " + data.last_name
              : data.first_name}
          </div>
        );
      },
    },
    {
      Header: "Country",
      accessor: (row) => `${row.citizenship_main}`,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="form-container-default">
            {data.citizenship_main ? data.citizenship_main : "-"}
          </div>
        );
      },
    },
    {
      Header: "Job Title",
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;
        // console.log("data", data);

        let jobTitle = "";

        if (data.candidate_experiences.length > 0) {
          jobTitle = data.candidate_experiences[0].title;
        } else {
          jobTitle = data.job_title ? data.job_title : "-";
        }

        return <>{jobTitle}</>;
      },
    },
    {
      Header: "",
      accessor: "selection",
      disableSortBy: true,
      minWidth: 10,
      width: 50,
      Cell: ({ row }) => {
        const data = row.original;

        const onItemCheck = (data) => (event) => {
          if (checkParticipant.length > 0) {
            if (event.target.checked === false) {
              const index = checkParticipant.findIndex(function (chck) {
                return chck.id === data.id;
              });

              checkParticipant.splice(index, 1);
            } else {
              checkParticipant.push(data);
            }
          } else {
            checkParticipant.push(data);
          }

          setParticipantSelected(checkParticipant.length);

          // console.log("checkParticipant", checkParticipant);
        };

        const checkedItem = (id) => {
          let ptp = [];

          const checkPtp = checkParticipant.filter((chec) => {
            return chec.id === id;
          });

          ptp = checkPtp[0];

          return ptp;
        };

        return (
          <div className="checkbox-container">
            <input
              type="checkbox"
              checked={checkedItem(data.id)}
              id={"row" + data.id}
              onChange={onItemCheck(data)}
            />
          </div>
        );
      },
    },
  ];

  const headerGenerated = [
    {
      Header: "Participant Name",
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        let name = data.candidate.last_name
          ? data.candidate.first_name + " " + data.candidate.last_name
          : data.candidate.first_name;

        return <div>{name}</div>;
      },
    },
    {
      Header: "Header",
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        // const data = row.original;

        return <div>{certState.txtrow1}</div>;
      },
    },
    {
      Header: "",
      accessor: "actions",
      minWidth: 100,
      width: 100,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <>
            <Button
              variant="link"
              className="reminder-btn set-rem"
              onClick={downloadCertificate(
                data,
                certState.txtrow1,
                certState.txtrow2
              )}
            >
              <Icon path={mdiDownload} />
              Download
            </Button>
          </>
        );
      },
    },
  ];

  const btnOpenCertForm = () => {
    setShowCertForm(true);
    setErrorForm({});

    setCertState({
      idcert: "new",
      txtrow1: "",
      txtrow2: "",
    });

    setModeModal({
      title: "Create Certificate",
      button: "Create",
    });
  };

  const btnEditCertForm = (obj) => () => {
    setShowCertForm(true);
    setErrorForm({});

    setCertState({
      idcert: obj.id,
      txtrow1: obj.row_1,
      txtrow2: obj.row_2,
    });

    setModeModal({
      title: "Edit Certificate",
      button: "Update",
    });
  };

  const btnCloseCertForm = () => {
    setShowCertForm(false);
  };

  const formError = (object) => {
    const newErrors = {};

    if (!object.txtrow1 || object.txtrow1 === "")
      newErrors.txtrow1 = "Fill in the blank!";

    if (!object.txtrow2 || object.txtrow2 === "")
      newErrors.txtrow2 = "Fill in the blank!";

    return newErrors;
  };

  const btnSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);

    const dtCert = Object.fromEntries(data.entries());

    const newErrors = formError(dtCert);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      setErrorForm({});

      if (certState.idcert === "new") {
        customSwal
          .fire({
            title: "Do you want to add new certificate?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post(
                "client/" +
                  params.id +
                  "/assignment/" +
                  params.idassign +
                  "/tmdm/certificate/create",
                {
                  row_1: dtCert.txtrow1,
                  row_2: dtCert.txtrow2,
                }
              )
                .then((response) => {
                  //   let data = response.data;

                  setShowCertForm(false);
                  getListCertificate();
                  customSwal.fire({
                    title: "Saved Successfully!",
                    icon: "success",
                    confirmButtonText: "Okay",
                  });
                })
                .catch((error) => {
                  console.log("error add certificate", error);
                });
            }
          });
      } else {
        customSwal
          .fire({
            title: "Do you want to update this certificate?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.patch(
                "client/" +
                  params.id +
                  "/assignment/" +
                  params.idassign +
                  "/tmdm/certificate/" +
                  certState.idcert +
                  "/update",
                {
                  row_1: dtCert.txtrow1,
                  row_2: dtCert.txtrow2,
                }
              )
                .then((response) => {
                  //   let data = response.data;

                  setShowCertForm(false);
                  getListCertificate();
                  customSwal.fire({
                    title: "Updated Successfully!",
                    icon: "success",
                    confirmButtonText: "Okay",
                  });
                })
                .catch((error) => {
                  console.log("error update certificate", error);
                });
            }
          });
      }
    }
  };

  const btnOpenSelectParticipant = (obj) => () => {
    setShowSelectParticipant(true);

    setCertState({
      idcert: obj.id,
      txtrow1: obj.row_1,
      txtrow2: obj.row_2,
    });

    setCheckParticipant([]);
    setParticipantSelected(0);
  };

  const btnCloseSelectParticipant = () => {
    setShowSelectParticipant(false);
  };

  const btnGenerate = () => {
    var participantId = checkParticipant.map((s) => s.id);

    customSwal
      .fire({
        title: "Do you want to generate certificate?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.post(
            "client/" +
              params.id +
              "/assignment/" +
              params.idassign +
              "/tmdm/certificate/" +
              certState.idcert +
              "/addCandidate",
            {
              candidate_id: participantId,
            }
          )
            .then((response) => {
              //   let data = response.data;

              setShowSelectParticipant(false);
              getListCertificate();
              customSwal.fire({
                title: "Generated Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error add certificate", error);
            });
        }
      });
  };

  const handleSearchParticipant = (event) => {
    let value = event.target.value.toLowerCase();

    let newList = [];

    if (value !== "") {
      // let newList = [];
      newList = listParticipant.filter((candidate) => {
        if (!candidate.last_name || candidate.last_name === "") {
          return candidate.first_name
            .toLowerCase()
            .includes(value.toLowerCase());
        } else {
          return (
            candidate.first_name.toLowerCase().includes(value.toLowerCase()) ||
            candidate.last_name.toLowerCase().includes(value.toLowerCase())
          );
        }
      });

      setAllListParticipant(newList);
    } else {
      setAllListParticipant(listParticipant);
    }
  };

  const btnViewParticipant = (obj) => () => {
    // console.log("btnViewParticipant", obj)
    setViewParticipant(true);
    setListPartCert(obj.certificate_has_candidate);

    setCertState({
      idcert: obj.id,
      txtrow1: obj.row_1,
      txtrow2: obj.row_2,
    });

    setModeModal({
      title: "Participant with Generated Certificate",
    });
  };

  const btnCloseViewParticipant = () => {
    setViewParticipant(false);
  };

  const downloadCertificate = (obj, row1, row2) => () => {
    // console.log("obj", obj);
    // console.log("row1", row1);
    // console.log("row2", row2);
    
    Api.get(
      "client/" +
        params.id +
        "/assignment/" +
        params.idassign +
        "/tmdm/downloadCertificate/" +
        obj.id
    )
    .then((response) => {
      let data = response.data;
      // console.log("data resposnes", data);

      let array_user = data.assignment_certificate.assignment.assignment_has_user.length > 0  ? data.assignment_certificate.assignment.assignment_has_user :"";

      // console.log("array_user", array_user)

      var doc = new jsPDF("l", "mm", [297, 210]);

      var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

      if(array_user !== ""){
        if(array_user.length === 1){
          // var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
          // var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

          const imgProps = doc.getImageProperties(Certificate);
          const pdfWidth = doc.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

          doc.addImage(Cert2, "JPEG", 0, 0, pdfWidth, pdfHeight, "", "FAST");

          doc.setFontSize(50);
          doc.setFont("times", "normal");
          doc.setTextColor(0, 49, 140);
          doc.text(obj.candidate.last_name
            ? obj.candidate.first_name + " " + obj.candidate.last_name
            : obj.candidate.first_name, pageWidth / 2, pageHeight / 2 + 6, {
            maxWidth: 250,
            align: "center",
          });

          doc.setFontSize(16);
          doc.setFont("helvetica", "normal");
          doc.setTextColor(0, 49, 140);
          doc.text(
            row1 + " \n" + row2,
            pageWidth / 2,
            pageHeight / 2 + 24,
            { maxWidth: 250, align: "center" }
          );

          // Issue if title too long
          doc.setFontSize(13);
          doc.setFont("times", "bold");
          doc.setTextColor(0, 49, 140);
          doc.text(
            row2,
            pageWidth / 2 + 0.5,
            pageHeight / 2 + 54,
            { maxWidth: 27, align: "center" }
          );

          doc.setFontSize(10);
          doc.text("2022", pageWidth / 2 + 0.5, pageHeight / 2 + 69, "center");

          doc.setFontSize(15);
          doc.setFont("times", "bold");
          doc.setTextColor(0, 49, 140);
          doc.text(data.assignment_certificate.assignment.assignment_has_user[0].user.first_name + " " + data.assignment_certificate.assignment.assignment_has_user[0].user.last_name, pageWidth / 2 - 63, pageHeight / 2 + 66, {
            maxWidth: 65,
            align: "center",
          });

          doc.setFontSize(13);
          doc.setFont("helvetica", "normal");
          doc.setTextColor(212, 89, 0);
          doc.text(data.assignment_certificate.assignment.assignment_has_user[0].user.job_title, pageWidth / 2 - 63, 179, {
            maxWidth: 88,
            align: "center",
          });

          doc.setFontSize(13);
          doc.setFont("helvetica", "normal");
          doc.setTextColor(212, 89, 0);
          doc.text("Managing Partner, Asia Pacific", pageWidth / 2 + 65, 179, {
            maxWidth: 80,
            align: "center",
          });

          toast.promise(doc.save(obj.candidate.last_name
            ? obj.candidate.first_name + " " + obj.candidate.last_name
            : obj.candidate.first_name + ".pdf", { returnPromise: true }), {
            loading: "Downloading file...",
            error: "Download failed. Please try again.",
            success: "Download successful",
          });
        }else{
          // var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
          // var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

          const imgProps = doc.getImageProperties(Certificate);
          const pdfWidth = doc.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

          doc.addImage(
            Certificate,
            "JPEG",
            0,
            0,
            pdfWidth,
            pdfHeight,
            "",
            "FAST"
          );

          doc.setFontSize(50);
          doc.setFont("times", "normal");
          doc.setTextColor(0, 49, 140);
          doc.text(
            obj.candidate.last_name
              ? obj.candidate.first_name + " " + obj.candidate.last_name
              : obj.candidate.first_name,
            pageWidth / 2,
            pageHeight / 2 - 15,
            {
              maxWidth: 250,
              align: "center",
            }
          );

          doc.setFontSize(16);
          doc.setFont("helvetica", "normal");
          doc.setTextColor(0, 49, 140);
          doc.text(row1 + " \n" + row2, pageWidth / 2, pageHeight / 2 - 5, {
            maxWidth: 250,
            align: "center",
          });

          // Issue if title too long
          doc.setFontSize(13);
          doc.setFont("times", "bold");
          doc.setTextColor(0, 49, 140);
          doc.text(row2, pageWidth / 2, pageHeight / 2 + 12, {
            maxWidth: 27,
            align: "center",
          });

          doc.setFontSize(10);
          doc.text("2022", pageWidth / 2, pageHeight / 2 + 27, "center");

          doc.addImage(data.assignment_certificate.assignment.assignment_has_user[0].user.signature ?  data.assignment_certificate.assignment.assignment_has_user[0].user.signature : "", "JPEG",  pageWidth / 2 - 97, pageHeight / 2 + 39, 34,17,"", "FAST");

          doc.addImage(data.assignment_certificate.assignment.assignment_has_user[1].user.signature ?  data.assignment_certificate.assignment.assignment_has_user[1].user.signature : "", "JPEG",  pageWidth / 2 - 15, pageHeight / 2 + 39, 34,17,"", "FAST");

          doc.setFontSize(15);
          doc.setFont("times", "bold");
          doc.setTextColor(0, 49, 140);
          doc.text(data.assignment_certificate.assignment.assignment_has_user[1].user.first_name + " " + data.assignment_certificate.assignment.assignment_has_user[1].user.last_name, pageWidth / 2 + 8, 170, {
            maxWidth: 65,
            align: "center",
          });

          doc.setFontSize(13);
          doc.setFont("helvetica", "normal");
          doc.setTextColor(212, 89, 0);
          doc.text(
            data.assignment_certificate.assignment.assignment_has_user[1].user.job_title ?  data.assignment_certificate.assignment.assignment_has_user[1].user.job_title : "",
            pageWidth / 2 + 8,
            177,
            { maxWidth: 88, align: "center" }
          );

          doc.setFontSize(15);
          doc.setFont("times", "bold");
          doc.setTextColor(0, 49, 140);
          doc.text(data.assignment_certificate.assignment.assignment_has_user[0].user.first_name + " " + data.assignment_certificate.assignment.assignment_has_user[0].user.last_name, pageWidth / 2 - 83, 170, {
            maxWidth: 65,
            align: "center",
          });

          doc.setFontSize(13);
          doc.setFont("helvetica", "normal");
          doc.setTextColor(212, 89, 0);
          doc.text(data.assignment_certificate.assignment.assignment_has_user[0].user.job_title ?  data.assignment_certificate.assignment.assignment_has_user[0].user.job_title : "", pageWidth / 2 - 83, 177, {
            maxWidth: 88,
            align: "center",
          });

          doc.setFontSize(13);
          doc.setFont("helvetica", "normal");
          doc.setTextColor(212, 89, 0);
          doc.text("Managing Partner, Asia Pacific", pageWidth / 2 + 95, 177, {
            maxWidth: 80,
            align: "center",
          });

          // doc
          //   .save("demo.pdf", { returnPromise: true })
          //   .then(toast.success("Download successful!"));

          toast.promise(
            doc.save(
              obj.candidate.last_name
                ? obj.candidate.first_name + " " + obj.candidate.last_name
                : obj.candidate.first_name + ".pdf",
              { returnPromise: true }
            ),
            {
              loading: "Downloading file...",
              error: "Download failed. Please try again.",
              success: "Download successful",
            }
          );
        }
      }else{
          // var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
          // var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

          const imgProps = doc.getImageProperties(Certificate);
          const pdfWidth = doc.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

          doc.addImage(Cert2, "JPEG", 0, 0, pdfWidth, pdfHeight, "", "FAST");

          doc.setFontSize(50);
          doc.setFont("times", "normal");
          doc.setTextColor(0, 49, 140);
          doc.text(obj.candidate.last_name
            ? obj.candidate.first_name + " " + obj.candidate.last_name
            : obj.candidate.first_name, pageWidth / 2, pageHeight / 2 + 6, {
            maxWidth: 250,
            align: "center",
          });

          doc.setFontSize(16);
          doc.setFont("helvetica", "normal");
          doc.setTextColor(0, 49, 140);
          doc.text(
            row1 + " \n" + row2,
            pageWidth / 2,
            pageHeight / 2 + 24,
            { maxWidth: 250, align: "center" }
          );

          // Issue if title too long
          doc.setFontSize(13);
          doc.setFont("times", "bold");
          doc.setTextColor(0, 49, 140);
          doc.text(
            row2,
            pageWidth / 2 + 0.5,
            pageHeight / 2 + 54,
            { maxWidth: 27, align: "center" }
          );

          doc.setFontSize(10);
          doc.text("2022", pageWidth / 2 + 0.5, pageHeight / 2 + 69, "center");

          doc.setFontSize(15);
          doc.setFont("times", "bold");
          doc.setTextColor(0, 49, 140);
          doc.text("", pageWidth / 2 - 63, pageHeight / 2 + 66, {
            maxWidth: 65,
            align: "center",
          });

          doc.setFontSize(13);
          doc.setFont("helvetica", "normal");
          doc.setTextColor(212, 89, 0);
          doc.text("", pageWidth / 2 - 63, 179, {
            maxWidth: 88,
            align: "center",
          });

          doc.setFontSize(13);
          doc.setFont("helvetica", "normal");
          doc.setTextColor(212, 89, 0);
          doc.text("Managing Partner, Asia Pacific", pageWidth / 2 + 65, 179, {
            maxWidth: 80,
            align: "center",
          });

          toast.promise(doc.save(obj.candidate.last_name
            ? obj.candidate.first_name + " " + obj.candidate.last_name
            : obj.candidate.first_name + ".pdf", { returnPromise: true }), {
            loading: "Downloading file...",
            error: "Download failed. Please try again.",
            success: "Download successful",
          });
      }

      
    })
    .catch((error) => {
      console.log("error download certificate", error);
    });
  };


  const getListParticipant = useCallback(() => {
    Api.get(
      "client/" +
        params.id +
        "/assignment/" +
        params.idassign +
        "/tmdm/participant/all"
    )
      .then((response) => {
        let data = response.data;
        // console.log("data", data);

        setListParticipant(data);
        setAllListParticipant(data);

        // if (data !== "no data") {
        //   setListProposal(data);
        // }
      })
      .catch((error) => {
        console.log("error getListParticipant", error);
      });
  }, [params]);

  const getListCertificate = useCallback(() => {
    Api.get(
      "client/" +
        params.id +
        "/assignment/" +
        params.idassign +
        "/tmdm/listCertificate"
    )
      .then((response) => {
        let data = response.data;

        // console.log("data", data);
        setListCert(data);
      })
      .catch((error) => {
        console.log("error getListForm", error);
      });
  }, [params]);

  useEffect(() => {
    getListParticipant();
    getListCertificate();
  }, [getListParticipant, getListCertificate]);

  return (
    <>
      <Toaster />
      <Card>
        <Card.Body>
          <div className="row-div">
            <div className="left-div"></div>
            <div className="right-div">
              <Button
                variant="link"
                className="modal-link"
                onClick={btnOpenCertForm}
              >
                <Icon path={mdiFilePlus} />
                Create Certificate
              </Button>
            </div>
          </div>

          <CustomTable
            headers={header}
            items={listCert}
            paginate={listCert.length}
            maxRows={15}
          ></CustomTable>
        </Card.Body>
      </Card>

      {/* Modal Create Cert */}
      <Modal
        show={showCertForm}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseCertForm}
        title={modeModal.title}
        content={
          <>
            <Form id="cert-form" onSubmit={btnSubmit}>
              {/* 1 */}
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg={2}>
                  Header <label className="validation-star">*</label>
                </Form.Label>
                <Col>
                  <Form.Control
                    name="txtrow1"
                    type="text"
                    defaultValue={certState.txtrow1}
                    isInvalid={!!errorForm.txtrow1}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorForm.txtrow1}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              {/* 2 */}
              <Form.Group as={Row} className="mb-3">
                <Form.Label column lg={2}>
                  Subtitle <label className="validation-star">*</label>
                </Form.Label>
                <Col>
                  <Form.Control
                    name="txtrow2"
                    type="text"
                    defaultValue={certState.txtrow2}
                    isInvalid={!!errorForm.txtrow2}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorForm.txtrow2}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseCertForm}>
              Cancel
            </Button>
            <Button type="submit" form="cert-form" variant="primary">
              {modeModal.button}
            </Button>
          </>
        }
      ></Modal>

      {/* Modal Select Participant */}
      <Modal
        show={showSelectParticipant}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseSelectParticipant}
        title="Select Participant to Generate Certificate"
        content={
          <>
            <div className="row-div">
              <div className="left-div">
                <TextboxIcon
                  name="txtsearchcandidate"
                  type="text"
                  placeholder="Search"
                  inputSize="input--medium"
                  onChange={(event) => handleSearchParticipant(event)}
                  icon={mdiMagnify}
                ></TextboxIcon>
              </div>
              <div className="right-div comp-selected">
                <div style={{ marginRight: "20px" }}>
                  {participantSelected} selected
                </div>
              </div>
            </div>
            <CustomTable
              headers={headerParticipant}
              items={allListParticipant}
              paginate={allListParticipant.length}
              maxRows={10}
            ></CustomTable>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseSelectParticipant}>
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={participantSelected > 0 ? false : true}
              onClick={btnGenerate}
            >
              Generate
            </Button>
          </>
        }
      ></Modal>

      {/* Modal View Participant */}
      <Modal
        show={viewParticipant}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseViewParticipant}
        title={modeModal.title}
        content={
          <>
            <CustomTable
              headers={headerGenerated}
              items={listPartCert}
              paginate={listPartCert.length}
              maxRows={10}
            ></CustomTable>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseViewParticipant}>
              Close
            </Button>
          </>
        }
      ></Modal>
    </>
  );
}

export default TabCertificate;
