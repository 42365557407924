import React from "react";
import Icon from "@mdi/react";
import { mdiTableRemove } from "@mdi/js";
import "./empty.css";

function Empty() {
  return (
    <div className="share-empty">
      <div className="meeting-empty media">
        <Icon path={mdiTableRemove} color={"#EC782F"} />
        <div className="meeting-empty-wording" style={{ color: "#EC782F" }}>
          There is no data available right now!
        </div>
      </div>
    </div>
  );
}

export default Empty;
