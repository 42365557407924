import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Button } from "react-bootstrap";
import Api from "../../../core/services/api.service";

import SwalAlert from "../../../components/Swal/SwalAlert";
import moment from "moment";
import Cookies from 'js-cookie';

import Icon from "@mdi/react";
import { mdiBell, mdiCalendar, mdiMenu } from "@mdi/js";

import ProfileImage from "../../../assets/no-profile-photo.jpg";

import { getUserProfileHeaderCandidate } from "../../../store/actions/userProfileSlice";
import { getListNotificationBell } from "../../../store/actions/notificationSlice";

function Header(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { usrProfile } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.login);
  const { notificationListBell, notificationNotReadBell } = useSelector(
    (state) => state.notification
  );

  const locationName = () => {
    if (location.pathname === "/candidate-password") {
      props.setHeader("Change Password");
      props.setIconH(mdiBell);
    } else if (location.pathname === "/all-notifications") {
      props.setHeader("Notifications");
      props.setIconH(mdiBell);
    }
  };

  let current_title = localStorage.getItem("menu_title");
  let current_icon = localStorage.getItem("menu_icon");

  // const [profile, setProfile] = useState({});
  /* When the user clicks on the button,toggle between hiding and showing the dropdown content */
  // function notiFunction() {
  //   document.getElementById("notiDropDown").classList.toggle("show");
  // }

  // Close the dropdown if the user clicks outside of it
  window.onclick = function (event) {
    if (!event.target.matches(".dropbtn")) {
      var dropdowns = document.getElementsByClassName("dropdown-content");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains("show")) {
          openDropdown.classList.remove("show");
        }
      }
    }
  };

  // Function Event
  function getSelectUser() {
    dispatch(getUserProfileHeaderCandidate());
  }

  const viewNotification = () => {
    navigate("/all-notifications");

    props.setHeader("Notifications");
    props.setIconH(mdiBell);
  };

  const customSwal = SwalAlert();

  const btnSignOut = (e) => {
    customSwal
      .fire({
        title: "Are you sure you want to logout?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Log Out",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Cookies.remove('id_token');
          // localStorage.removeItem("id_token");
          // localStorage.removeItem("menu_title");
          // localStorage.removeItem("menu_icon");
          

          navigate("/candidate-login");
          window.location.reload(false);
        }
      });
  };

  const openMenu = () => {
    let responsive = document.querySelectorAll("#sidebar-nav");

    responsive.forEach((el) => el.classList.toggle("active"));
  };

  useEffect(() => {
    getSelectUser();
    locationName();

    const interval = setInterval(() => {
      dispatch(getListNotificationBell(user.sub, "candidate"));
    }, 5000);

    return () => clearInterval(interval);
  }, [dispatch, user.sub]);

  const btnChangePassword = (e) => {
    navigate("/candidate-password");

    props.setHeader("Change Password");
    props.setIconH(mdiBell);
  };

  const btnNavigate = (url, id) => (e) => {
    
    if(url === undefined){

    }else{
      Api.get("notification/" + id + "/markAsRead")
      .then((response) => {
        navigate(url);
      })
      .catch((error) => {
          console.log("error mark as read", error);
      });
    }
   
  }; 

  return (
    <>
      <div className="header">
        <div className="title-page">
          {/* Display for responsive */}
          <div className="hamburger-menu" onClick={openMenu}>
            <Icon path={mdiMenu} color="#54595F" />
          </div>
          {/* Display for responsive */}
          <div className="title-page-icon">
            <Icon path={props.icon ? props.icon : current_icon} />
          </div>
          <div className="title-page-text">
            {props.title ? props.title : current_title}
          </div>
        </div>

        <div className="profile-icon">
          <Dropdown>
            <Dropdown.Toggle variant="link">
              <div className="icon-box">
                {notificationNotReadBell.length > 0 ? (
                  <div className="counter"></div>
                ) : (
                  <></>
                )}
                <Icon path={mdiBell} size={0.8} />
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dd-menu" id="dropdown-id">
              <Dropdown.Header className="dropdown-content-header">
                Notifications
              </Dropdown.Header>
              {notificationListBell.map(function (obj, index) {
                return (
                  <Dropdown.Item className="dd-noti-item" key={index} onClick={btnNavigate(obj.data.url, obj.id)}>
                    <div className="dropdown-content-info" key={obj.id}>
                      {/* <div className="dropdown-content-info-date"> */}
                      <Icon path={mdiCalendar} size={0.7} />
                      <div className="dropdown-text">
                        <div className="noti-date">
                          {moment(obj.created_at).format("DD MMMM YYYY")}
                        </div>
                        <div
                          className="dropdown-content-info-text"
                          dangerouslySetInnerHTML={{
                            __html: obj.data.message,
                          }}
                        ></div>
                      </div>
                      {/* </div> */}
                    </div>
                  </Dropdown.Item>
                );
              })}

              {notificationListBell.length > 0 ? (
                <Dropdown.Item className="dd-all-noti">
                  {/* <div className="all-noti-row"> */}
                  <Button
                    variant="link"
                    className="reminder-btn"
                    onClick={viewNotification}
                    data-toggle="dropdown"
                  >
                    View all notifications
                  </Button>
                  {/* </div> */}
                </Dropdown.Item>
              ) : (
                <div className="no-new-noti">
                  <Icon path={mdiBell} size={3} color="rgba(236, 120, 47)" />
                  <div>You have no new notification!</div>
                  <Button
                    variant="link"
                    className="reminder-btn"
                    onClick={viewNotification}
                  >
                    View all notifications
                  </Button>
                </div>
              )}
            </Dropdown.Menu>
          </Dropdown>

          <div className="username-div">
            <Dropdown>
              <Dropdown.Toggle variant="link">
                <div className="acct-box">
                  <div className="username-box">
                    {usrProfile.first_name + " " + usrProfile.last_name}
                  </div>
                  {usrProfile.profile_picture ? (
                    <img src={usrProfile.profile_picture} alt="" />
                  ) : (
                    <img src={ProfileImage} alt="" />
                  )}
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className="pp-info">
                  {usrProfile.profile_picture ? (
                    <img src={usrProfile.profile_picture} alt="" />
                  ) : (
                    <img src={ProfileImage} alt="" />
                  )}

                  <div>
                    <div className="text">
                      {usrProfile.first_name} {usrProfile.last_name}
                    </div>
                    <div>{usrProfile.email}</div>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  onClick={btnChangePassword}
                  className="pp-settings"
                >
                  Change Password
                </Dropdown.Item>
                <Dropdown.Item onClick={btnSignOut} className="pp-settings">
                  Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
