import React from "react";
import "./Popup.css";
import Modal from "react-bootstrap/Modal";

function ModalPopup({
  show,
  onHide,
  size,
  title,
  content,
  button,
  backdrop,
  keyboard,
}) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size={size}
      backdrop={backdrop}
      keyboard={keyboard}
      centered
    >
      <Modal.Header closeButton={onHide}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>{button}</Modal.Footer>
    </Modal>
  );
}

export default ModalPopup;
