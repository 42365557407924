import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, Form, Row } from "react-bootstrap";
import Api from "../../../core/services/api.service";
import Apiform from "../../../core/services/apiform.service";

import FileSaver from "file-saver";
// import { Buffer } from 'buffer';
// import { saveAs } from 'file-saver';
// import { htmlToBuffer } from 'html-docx-js';
import moment from "moment";
import SwalAlert from "../../../components/Swal/SwalAlert";
import {rptProposal} from "../../../components/Report/Template_Report/rptProposal";
import {rptHeader} from "../../../components/Report/Template_Report/rptHeader";
import {rptFooter} from "../../../components/Report/Template_Report/rptFooter";

import Icon from "@mdi/react";
import {
  mdiArrowLeft,
  mdiFilePlus,
  mdiDotsHorizontal,
  mdiDownload,
  mdiPencil,
  mdiDelete,
  mdiUpload,
  mdiEmail,
  mdiFileSend,
  mdiAccountGroup,
} from "@mdi/js";

// import { rptProposal } from "../../../components/Report/Template/rptProposal";
import { CustomTable } from "../../../components/Table/Table";
import Modal from "../../../components/Popup/ModalPopup";
import CardInfo, { CardInfoBtn } from "../../../components/Card/CardInfo";
import BreadCrumb from "../../../components/Breadcrumb/BreadCrumb";
// import Document from '../../../assets/Report/template.docx'

import { storeTitle } from "../../../store/actions/headerTitleSlice";
import { getClientDetails } from "../../../store/actions/clientSlice";
import { getProposalList } from "../../../store/actions/proposalSlice";

import HTMLtoDOCX from 'html-to-docx';
// import * as htmlToDocx from 'html-to-docx/dist/html-to-docx.umd'

const ViewListProposal = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  // console.log("htmlString", htmlString)

  const { clientDetails } = useSelector((state) => state.client);
  const { proposal_list } = useSelector((state) => state.proposal);

  const [showUpload, setShowUpload] = useState(false);

  const [selRowProposal, setSelRowProposal] = useState({});
  const [errorForm, setErrorForm] = useState({});

  const [modalReason, setModalReason] = useState(false);

  const customSwal = SwalAlert();

  const selectedCrumb = (crumb) => {
    if (crumb === "Clients") {
      navigate("/clients/listActiveC");
    }
  };

  const downloadDocx = (data) => async () => {
    // console.log("data", data)

    // console.log("HTMLtoDOCX", HTMLtoDOCX)

    const dataRpt = {
      date_rpt: moment(data.created_at).format("DD MMMM YYYY"),
      company_overview: data.company_overview,
      requirement: data.requirement,
      job_title: data.job_title,
      client_proposal_has_user: data.client_proposal_has_user,
      domestic_fee: data.domestic_fee,
      regional_fee: data.regional_fee,
      expected_compensation: data.expected_compensation,
      payment_term: data.payment_term,
      indirect_expenses: data.indirect_expenses,
      compound_interest: data.compound_interest,
      subseq_cand_fee: data.subseq_cand_fee,
      off_limit_restriction: data.off_limit_restriction,
      guaranty_period: data.guaranty_period,
      guaranty_condition: data.guaranty_condition,
      company_name: clientDetails.name,
      sign_name:
        data.client_proposal_has_user[0].user.first_name +
        " " +
        data.client_proposal_has_user[0].user.last_name,
    };

    const htmlString = rptProposal(dataRpt);
    const headerString = rptHeader();
    const footerHTMLString = rptFooter();

    try {
      const fileBuffer =  await HTMLtoDOCX(
        htmlString,
        headerString,
        {
          table: { row: { cantSplit: true } },
          header: true,
          footer: true,
          pageNumber: true,
          margins: {
            top: 500,
            right: 1500,
            bottom: 500,
            left: 1500,
            header: 720,
            footer: 720,
            gutter: 0,
          },
          font: "Calibri (Body)",
        },
        footerHTMLString
        );
      
  
      
      FileSaver.saveAs(fileBuffer, data.file_name + ".docx");
    } catch (error) {
      console.log("🚀 ~ file: ViewListProposal.js:147 ~ downloadDocx ~ fileBuffer:", error)
    }

    
  };

  // async function downloadDocx(params) {

  // }
  const [listReason, setListReason] = useState([]);

  const btnOpenReason = (data) => () => {
    setModalReason(true);
    setListReason(data);
  };
  
  const btnCloseReason = () => {
    setModalReason(false);
  };

  const headerReason = [
    {
      Header: "No.",
      accessor: (row) => `${row.index}`,
      minWidth: 50,
      width: 50,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Date",
      accessor: (row) => `${row.created_at}`,
      minWidth: 120,
      width: 120,
      Cell: ({ row }) => {
        const data = row.original

        return <div>{moment(data.created_at).format('DD MMMM YYYY')}</div>
      },
    },
    {
      Header: "Reason for Rejection",
      minWidth: 200,
      width: 300,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{data.reason_rejected}</div>;
      },
    },
  ];



  const headerProposal = [
    {
      Header: "No",
      maxWidth: 50,
      minWidth: 10,
      width: 70,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "File Name",
      accessor: "file_name",
      maxWidth: 500,
      minWidth: 100,
      width: 500,
    },
    {
      Header: "Created Date",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{moment(data.created_at).format("DD MMMM YYYY")}</div>;
      },
    },
    {
      Header: "Created By",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            {data.created_by_users.first_name} {data.created_by_users.last_name}
          </div>
        );
      },
    },
    {
      Header: "Signed Proposal",
      maxWidth: 400,
      minWidth: 100,
      width: 250,
      Cell: ({ row }) => {
        const data = row.original;

        function btnDownSigned() {
          FileSaver.saveAs(data.signed_proposal);
        }

        return (
          <>
            {data.signed_proposal ? (
              <Button variant="link" onClick={btnDownSigned}>
                <Icon path={mdiDownload} color="#467FD0" />
                Download
              </Button>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      Header: "Status",
      Cell: ({ row }) => {
        const data = row.original;

        let cardColor = "";

        if(data.status === "completed"){
          cardColor = "card_text--warning--solid";
        }else if(data.status === "draft"){
          cardColor = "card_text--default--solid";
        }else if(data.status === "rejected"){
          cardColor = "card_text--hold--solid";
        }else if(data.status === "approved"){
          cardColor = "card_text--completed--solid";
        }
        else{
          cardColor = "card_text--primary--solid";
        }

        return (
          <>
            {data.status === "rejected" ? 
              <CardInfoBtn
                cardTextSize="card_text--small"
                cardTextStyle="card_text--danger--solid"
                btn={btnOpenReason(data.client_proposal_logs)}
              >
                {data.status.replace(/_/g, " ")}
              </CardInfoBtn> : 

              <CardInfo
                cardTextSize="card_text--small"
                cardTextStyle={cardColor}
              >
                {data.status.replace(/_/g, " ")}
              </CardInfo>
            }
          </>
        );
      },
    },
    {
      Header: " ",
      accessor: "actions",
      maxWidth: 100,
      minWidth: 10,
      width: 70,
      Cell: ({ row }) => {
        const data = row.original;

        const btnSendProposal = () => {
          customSwal
            .fire({
              title: "Do you want to send this proposal to client?",
              icon: "warning",
              showCancelButton: true,
              reverseButtons: true,
              confirmButtonText: "Yes",
            })
            .then((result) => {
              if (result.isConfirmed) {
                Api.patch(
                  "client/ " + params.id + "/proposal/" + data.id + "/update",
                  {
                    status: "completed",
                  }
                )
                  .then((response) => {
                    dispatch(getProposalList(params.id));
                  })
                  .catch((error) => {
                    console.log("error btnSendProposal", error);
                  });
              }
            });
        };

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle 
              // disabled={data.status === "proposal" ? true : false} 
              variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={downloadDocx(data)}
                  // onClick={btnDownload(data)}
                >
                  <Icon path={mdiDownload} color="#467FD0" />
                  Download Proposal
                </Dropdown.Item>
                <Dropdown.Item 
                disabled={data.status === "proposal" ? true : false} 
                onClick={btnEditProposal(data.id)}>
                  <Icon path={mdiPencil} color="#467FD0" />
                  Edit Proposal
                </Dropdown.Item>
                {/* {data.status === "proposal" ? (
                  <Dropdown.Item onClick={btnSendProposal}>
                    <Icon path={mdiFileSend} color="#4CAF50" />
                    Send Proposal to Client
                  </Dropdown.Item>
                ) : (
                  ""
                )} */}
                <Dropdown.Item
                  disabled={data.status === "proposal" ? true : false} 
                >
                  <Icon path={mdiDelete} color="#DF4759" />
                  Delete Proposal
                </Dropdown.Item>
                <Dropdown.Item>
                  <Icon path={mdiEmail} color="#42BA96" />
                  Email Proposal
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item 
                disabled={data.status === "proposal" ? true : false} 
                onClick={btnUploadProposal(data)}
                >
                  <Icon path={mdiUpload} color="#467FD0" />
                  Upload Proposal
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const btnEditProposal = (idrow) => () => {
    navigate("/clients/edit-proposal/active/" + params.id + "/" + idrow);
  };

  // Button Event
  // Button Back
  const btnBack = () => {
    navigate("/clients/listActiveC");
  };

  function createProposal() {
    navigate("/clients/create-proposal/active/" + params.id);
  }

  // const btnDownload = (data) => () => {
  //   console.log("data", data.requirement);
  //   // rptProposal(data);
  //   // navigate("/rptproposal");
  // };

  const btnUploadProposal = (data) => (e) => {
    // console.log("data", data)
    setErrorForm({});
    setSelRowProposal(data);
    setShowUpload(true);
  };

  const btnCloseUpload = () => {
    setShowUpload(false);
  };

  const formErrorUpload = (object) => {
    const newErrors = {};

    if (!object.txtfile.name || object.txtfile.name === "")
      newErrors.txtfile = "Fill in the blank!";

    return newErrors;
  };

  const btnSaveUpload = (idPro) => (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtUpload = Object.fromEntries(data.entries());

    const newErrors = formErrorUpload(dtUpload);

    if (Object.keys(newErrors).length > 0) {
      setErrorForm(newErrors);
    } else {
      customSwal
        .fire({
          title: "Do you want to upload signed proposal ?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();

            formData.append("file", dtUpload.txtfile);

            Apiform.post(
              "client/" +
                params.id +
                "/proposal/" +
                idPro +
                "/uploadSignedProposal",
              formData
            )
              .then((response) => {
                dispatch(getProposalList(params.id));

                setShowUpload(false);
                customSwal.fire({
                  title: "File Uploaded Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("error btnSaveUpload", error);
              });
          }
        });
    }
  };
  /////////////////////////////

  useEffect(() => {
    dispatch(getProposalList(params.id));
    dispatch(getClientDetails(params.id));
    dispatch(storeTitle({ title: "Clients", icon: mdiAccountGroup }));
  }, [dispatch, params.id]);

  return (
    <>
      <div className="main-div list-cli">
        <div className="container-div both-side">
          <BreadCrumb
            crumbs={["Clients", clientDetails.name]}
            selected={selectedCrumb}
          ></BreadCrumb>

          <Button onClick={btnBack} variant="secondary">
            <Icon path={mdiArrowLeft} size={0.7} />
            Back
          </Button>
        </div>

        <div className="bg-white-container">
          <div className="row-div">
            <div className="left-div"></div>
            <div className="right-div">
              <Button
                variant="link"
                className="modal-link"
                onClick={createProposal}
              >
                <Icon path={mdiFilePlus} />
                <div>Add Proposal</div>
              </Button>
            </div>
          </div>

          <CustomTable
            headers={headerProposal}
            items={proposal_list !== "no data" ? proposal_list : []}
            paginate={proposal_list.length}
            maxRows={15}
          ></CustomTable>
        </div>
      </div>

      {/* Modal Upload Proposal */}
      <Modal
        show={showUpload}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseUpload}
        title="Upload Proposal"
        content={
          <>
            <Form
              id="upload-pro-form"
              onSubmit={btnSaveUpload(selRowProposal.id)}
            >
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label className="col-3">File Name</Form.Label>
                  <div className="col-9" style={{ display: "inline-flex" }}>
                    {selRowProposal.file_name}
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label className="col-3">Signed Proposal</Form.Label>
                  <div className="col-9" style={{ display: "inline-flex" }}>
                    <Form.Control
                      name="txtfile"
                      type="file"
                      isInvalid={!!errorForm.txtfile}
                    />
                  </div>
                </Form.Group>
              </Row>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseUpload}>
              Cancel
            </Button>
            <Button type="submit" form="upload-pro-form" variant="primary">
              Upload
            </Button>
          </>
        }
      ></Modal>

      {/* Modal Open Reason */}
      <Modal
        show={modalReason}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseReason}
        title="Reason for Rejection"
        content={
          <>
            <CustomTable
              headers={headerReason}
              items={listReason}
              paginate={listReason.length}
              maxRows={15}
            ></CustomTable>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseReason}>
              Close
            </Button>
          </>
        }
      ></Modal>
    </>
  );
};

export default ViewListProposal;
