import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ReactTooltip from "react-tooltip";
import Icon from "@mdi/react";
import { mdiMagnify, mdiInformationOutline } from "@mdi/js";
import CustomTable from "../../components/Table/Table";
import TextboxIcon from "../../components/TextField/TextFieldIcon";
import DefaultPhoto from "../../assets/no-profile-photo.jpg";
import CardInfo from "../../components/Card/CardInfo";
import { Button } from "react-bootstrap";
import SwalAlert from "../../components/Swal/SwalAlert";
import Swal from "sweetalert2";
import Api from "../../core/services/api.service";

import { getListingConsentCand, setConsentAllList, setConsentStoreFilter } from "../../store/actions/consentSlice";

function Consent() {

  const dispatch = useDispatch();
  const { consentList, consentAllList, consentFilter } = useSelector((state) => state.consent);

  const { user } = useSelector((state) => state.login);

  const customSwal = SwalAlert();

  const headers = [
    {
      Header: "Company Name",
      accessor: "compName",
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="form-container-default">
            <div className="form-container-default-1">
              {data.logo ? (
                <img src={data.logo} alt="Client Logo" />
              ) : (
                <img src={DefaultPhoto} alt="Client Logo" />
              )}
            </div>
            <div
              className="form-container-default-2"
              style={{ paddingLeft: "10px" }}
            >
              {data.name}
            </div>
          </div>
        );
      },
    },
    {
      Header: "Job Title",
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;
        let job_data = "";

        var array = data.assignments.map((job, index) => {
          let sno = index + 1;
          job_data = job.title;
          return sno + ".  " + job_data + "<br>";
        });

        var tooltiptest = array.join(" ");

        return (
          <div
            className="form-container-default"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="form-container-default-1">
              {data.assignments.length === 1 || data.assignments.length === 0 ? (
                <div>{job_data.name}</div>
              ) : (
                <div>{data.assignments.length} Positions</div>
              )}
            </div>

            <div
              className="form-container-default-2"
              style={{
                paddingRight: "10px",
              }}
            >
              {data.assignments.length > 1 ? (
                <div data-tip={tooltiptest.toString()}>
                  <Icon path={mdiInformationOutline} />
                  <ReactTooltip
                    place="right"
                    type="dark"
                    effect="solid"
                    html={true}
                    backgroundColor="#E5E5E5"
                    textColor="#212529"
                  />
                </div>
              ) : null}
            </div>
          </div>
        );
      },
    },
    {
      Header: "Action",
      minWidth: 100,
      width: 250,
      Cell: ({ row }) => {
        const data = row.original;

        let action = "";
        let classLink = "";

        if(data.candidate_consent_client[0].status === 'denied'){
          action = "Grant Permission";
          classLink = "read-btn";
        }else{
          action = "Revoke Permission";
          classLink = "delete-link";
        }

        return (
          <>
            <Button
              variant="link"
              className={classLink}
              style={{ marginLeft: 0 }}
              onClick={btnAction(data.id, data.candidate_consent_client[0].status)}
            >
              {action}
            </Button>
          </>
        );
      },
    },
    {
      Header: "Status",
      minWidth: 150,
      width: 150,
      Cell: ({ row }) => {
        const data = row.original;

        let status = "";
        let styleType = "";

        if (data.candidate_consent_client[0].status === "denied") {
          status = "Denied";
          styleType = "card_text--warning--solid";
        } else {
          status = "Granted";
          styleType = "card_text--success--solid";
        }

        return (
          <CardInfo cardTextSize="card_text--small" cardTextStyle={styleType}>
            {status}
          </CardInfo>
        );
      },
    },
  ];

  const handleSearch = (event) => {

    let searchVal = event.target.value.toLowerCase();

    dispatch(setConsentStoreFilter({ txtSearchComp: searchVal }));

    let newList = [];

    if (searchVal !== ""){
      newList = consentList.filter((company) => {
        return company.name.toLowerCase().includes(searchVal.toLowerCase());
      });
    }else{
      newList = consentList;
    }

    dispatch(setConsentAllList(newList));
  };

  const btnAction = (idclient, statusCon) => (e) => {

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    const statusConsent = statusCon === "granted" ? "revoke" : "grant";
    const textConsent = statusCon === "granted" ? "will not" : "will";

    customSwal
      .fire({
        //Click grant permission
        title: "Are you sure you want to " + statusConsent + " permission to this company?",
        // title: "Are you sure you want to revoke permission to this company?",
        //Click revoke permission
        //"Are you sure you want to revoke permission to this company?

        //Click grant permission
        text: "By confirming this action, please be aware that the third party " + textConsent + " be able to view all your personal data.",
        // text: "By confirming this action, please be aware that the third party will not be able to view all your personal data.",
        //Click revoke permission
        //"By confirming this action, the third part will no longer be able to vie all your personal data provided until you wish to grant permission to them."

        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Confirm",
      })
      .then((result) => {
        if (result.isConfirmed) {

          Api.post("candidate/" + user.sub + "/appRejConsent", {
            client_id: idclient,
            status: statusCon === "granted" ? "denied" : "granted"
          })
          .then((response) => {
            // swalWithBootstrapButtons
            // .fire(
            //   "Request has been sent!",
            //   "Please wait for " + nameCand + " to grant you permission.",
            //   "success"
            // )
            // .then(function () {
            //   setShowConfirm(true);
            // });
            swalWithBootstrapButtons.fire(
              "Permission Granted!",
              "Your data has been shared to the third-party for reference.",
              "success"
            );

            dispatch(getListingConsentCand(user.sub, consentFilter.txtSearchComp));
          })
          .catch((error) => {
            console.log("error candidate/appRejConsent", error);
          });



          
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // swalWithBootstrapButtons.fire(
          //   "Cancelled",
          //   "Your personal data is not being shared to the third-party.",
          //   "error"
          // );
        }
      });
  };

  useEffect(() => {
    dispatch(getListingConsentCand(
      user.sub,
      consentFilter.txtSearchComp
    ));
   
  }, [dispatch]);

  return (
    <>
      <div className="main-div list-cli">
        <div className="top-title-div cli-page">
          <div className="filter_container">
            <div className="filter_container_totalrow">
              {consentAllList.length === 0 || consentAllList.length === 1 ? (
                <div className="form-container-default">
                  <div className="total_client_text">{consentAllList.length}</div>
                  <div className="client_text">Consent</div>
                </div>
              ) : (
                <div className="form-container-default">
                  <div className="total_client_text">{consentAllList.length}</div>
                  <div className="client_text">Consents</div>
                </div>
              )}
            </div>

            <div className="upper-divider"></div>

            <div className="filter_container_search">
              <div className="filter_container_search-1">
                <TextboxIcon
                  name="txtSearchComp"
                  type="text"
                  onChange={(event) => handleSearch(event)}
                  placeholder="Search by company name"
                  inputSize="input--medium"
                  icon={mdiMagnify}
                  value={consentFilter.txtSearchComp}
                ></TextboxIcon>
              </div>
            </div>
          </div>
        </div>

        <div className="container-div list-cli">
          <CustomTable
            headers={headers}
            items={consentAllList}
            paginate={consentAllList.length}
            maxRows={15}
            pindex={true}
          ></CustomTable>
        </div>
      </div>
      <div />
    </>
  );
}

export default Consent;
