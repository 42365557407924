import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Api from "../../../../core/services/api.service";
import { Button } from "react-bootstrap";

import { mdiMagnify, mdiShieldLock } from "@mdi/js";

import TextboxIcon from "../../../../components/TextField/TextFieldIcon";
import CardInfo from "../../../../components/Card/CardInfo";
import CustomTable from "../../../../components/Table/Table";

import { storeTitle } from "../../../../store/actions/headerTitleSlice";

const UserAcces = () => {
  const dispatch = useDispatch();
  const [listRole, setListRole] = useState([]);
  const [allListRole, setAllListRole] = useState([]);

  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();

    if (value !== "") {
      let newList = [];
      newList = listRole.filter((user) =>
        user.name.toLowerCase().includes(value.toLowerCase())
      );

      setAllListRole(newList);
    } else {
      setAllListRole(listRole);
    }
  };

  // Headers and value table
  const headers = [
    {
      Header: "User Access Name",
      maxWidth: 800,
      minWidth: 200,
      width: 800,
      Cell: ({ row }) => {
        const data = row.original;

        const navigate = useNavigate();

        function handleClick() {
          navigate("/viewUserAccess/" + data.id);
        }

        return (
          <div className="form-container-default">
            {/* <div className="form-container-default-2"> */}
            <Button
              variant="link"
              className="no-underline"
              onClick={handleClick}
            >
              {data.name}
            </Button>
            {/* </div> */}
          </div>
        );
      },
    },
    {
      Header: "Created Date",
      maxWidth: 800,
      minWidth: 200,
      width: 800,
      Cell: ({ row }) => {
        const data = row.original;

        const date = new Date(data.created_at);
        const longMonth = date.toLocaleString("en-us", { month: "long" });

        return (
          <div>
            {date.getDate()} {longMonth} {date.getFullYear()}
          </div>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      maxWidth: 150,
      minWidth: 150,
      width: 150,
      Cell: ({ row }) => {
        const data = row.original;

        return data.status === "Not Active" ? (
          <CardInfo
            cardTextSize="card_text--small"
            cardTextStyle="card_text--danger--solid"
          >
            {data.status}
          </CardInfo>
        ) : (
          <CardInfo
            cardTextSize="card_text--small"
            cardTextStyle="card_text--primary--solid"
          >
            {data.status}
          </CardInfo>
        );
      },
    },
    // {
    //   Header: "",
    //   accessor: "name",
    //   disableSortBy: true,
    //   maxWidth: 70,
    //   minWidth: 10,
    //   width: 70,
    //   Cell: ({ row }) => {
    //     const data = row.original;

    //     return (
    //       <div className="dropdown-container">
    //         <Dropdown>
    //           <Dropdown.Toggle variant="light" id="dropdown-basic">
    //             <Icon path={mdiDotsHorizontal} size={1} />
    //           </Dropdown.Toggle>
    //           <Dropdown.Menu>
    //             <Dropdown.Item>
    //               <Icon path={mdiPencil} size={0.8} color="#467FD0" />
    //               Edit User Access
    //             </Dropdown.Item>
    //             <Dropdown.Item>
    //               <Icon path={mdiDelete} size={0.8} color="#DF4759" />
    //               Delete User Access
    //             </Dropdown.Item>
    //           </Dropdown.Menu>
    //         </Dropdown>
    //       </div>
    //     );
    //   },
    // },
  ];

  const getListUserAccess = useCallback(() => {
    Api.get("role")
      .then((response) => {
        let data = response.data;
        setListRole(data);
        setAllListRole(data);
      })
      .catch((error) => {
        console.log("error getListUserAccess", error);
      });
  }, []);

  useEffect(() => {
    dispatch(
      storeTitle({ title: "User Access Management", icon: mdiShieldLock })
    );
    getListUserAccess();
    // localStorage.setItem("menu_title", "User Access Management")
  }, [getListUserAccess, dispatch]);

  return (
    <div className="main-div list-useracc">
      <div className="top-title-div">
        <div className="filter_container">
          <div className="filter_container_totalrow">
            {allListRole.length === 0 || allListRole.length === 1 ? (
              <div className="form-container-default">
                <div className="total_client_text">{allListRole.length}</div>
                <div className="client_text">role</div>
              </div>
            ) : (
              <div className="form-container-default">
                <div className="total_client_text">{allListRole.length}</div>
                <div className="client_text">roles</div>
              </div>
            )}
          </div>

          <div className="upper-divider"></div>

          <div className="filter_container_search">
            <div className="filter_container_search-1">
              <TextboxIcon
                name="name"
                type="text"
                onChange={(event) => handleSearch(event)}
                placeholder="Search by user access name"
                inputSize="input--medium"
                icon={mdiMagnify}
              ></TextboxIcon>
            </div>
          </div>
        </div>
      </div>

      <div className="container-div list-useracc">
        <CustomTable
          headers={headers}
          items={allListRole}
          paginate={allListRole.length}
          maxRows={15}
        ></CustomTable>
      </div>
    </div>
  );
};

export default UserAcces;
