export const monthList = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  export const noticeList = [
    { value: "1", label: "1 Month" },
    { value: "2", label: "2 Months" },
    { value: "3", label: "3 Months" },
    { value: "4", label: "4 Months" },
    { value: "5", label: "5 Months" },
    { value: "6", label: "6 Months" },
    { value: "7", label: "7 Months" },
    { value: "8", label: "8 Months" },
    { value: "9", label: "9 Months" },
    { value: "10", label: "10 Months" },
    { value: "11", label: "11 Months" },
    { value: "12", label: "12 Months" },
  ];

  export const statusCandidate = [
    { value: "active", label: "Active" },
    { value: "available", label: "Available" },
    { value: "client", label: "Client" },
    { value: "placed", label: "Placed" },
    { value: "blacklisted", label: "Blacklisted" },
  ];

  export const statusClient = [
    { value: "bd", label: "BD" },
    { value: "proposal", label: "Proposal" },
    { value: "closed", label: "Closed" },
    { value: "on_hold", label: "On Hold" },
    { value: "cancelled", label: "Cancelled" },
    { value: "blacklisted", label: "Blacklisted" },
  ];

