import {createSlice} from '@reduxjs/toolkit';
import Api from '../../core/services/api.service';
// import moment from 'moment';

export const notificationSlice = createSlice({
    name: "notification",
    initialState: {
        notificationList: [],
        notificationListBell: [],
        notificationNotRead: [],
        notificationNotReadBell:[],
        notificationSettingPro: [],
        notificationSettingProChek: {
            email_remainder: false,
            in_app_remainder: false
        }
    },
    reducers: {
        getNotificationList: (state, action) => {
            state.notificationList = action.payload;
        },
        getNotificationListBell: (state, action) => {
            state.notificationListBell = action.payload;
        },
        getNotificationNotRead: (state, action) => {
            state.notificationNotRead = action.payload;
        },
        getNotificationNotReadBell: (state, action) => {
            state.notificationNotReadBell = action.payload;
        },
        getNotiSettingProgress: (state, action) => {
            state.notificationSettingPro = action.payload;
        },
        getNotiSettingProgressCheck: (state, action) => {
            state.notificationSettingProChek = { ...state.notificationSettingProChek, ...action.payload };
        },
        // setReadNotification: (state, action) => {
        //     // console.log("action.payload", action.payload);
        //     action.payload.map((li) => {
        //       var foundIndex = state.notificationList.findIndex((x) => x.id === li);
      
        //       var item = state.notificationList[foundIndex];
        //       item.read_at = new Date();
        //     });
        // }
    },
});


export const getListNotification = (id, type) => async (dispatch, rejectWithValue) => {
    try {
        // console.log(data);
        const response = await Api.get("notification/llm/" + type + "/" + id + "/all");
  
        dispatch(getNotificationList(response.data));

        const filteredNotRead = response.data.filter(noti => {
            return !noti.read_at || noti.read_at === "";
        });

        dispatch(getNotificationNotRead(filteredNotRead));
        
    } catch (err) {
        throw rejectWithValue(err.message);
    }
};

export const getListNotificationBell = (id, type) => async (dispatch, rejectWithValue) => {
    try {
        // console.log(data);
        const response = await Api.get("notification/llm/" + type + "/" + id + "/all");
  
        dispatch(getNotificationListBell(response.data));

        const filteredNotRead = response.data.filter(noti => {
            return !noti.read_at || noti.read_at === "";
        });

        dispatch(getNotificationNotReadBell(filteredNotRead));
        
    } catch (err) {
        throw rejectWithValue(err.message);
    }
};


export const getNotificationSetting = () => async (dispatch, rejectWithValue) => {
    try {
        // console.log(data);
        const response = await Api.get("notification/setting/getAllSetting");

        let listprogress = response.data.filter((pro) => pro.type === "Progress"); 

        // console.log("ammm", listprogress[0].in_app_reminder)
  
        dispatch(getNotiSettingProgress(listprogress));

        dispatch(getNotiSettingProgressCheck({
            email_remainder: listprogress[0].email_reminder,
            in_app_remainder: listprogress[0].in_app_reminder,
        }));

       
    } catch (err) {
        throw rejectWithValue(err.message);
    }
};


export const { getNotificationList, getNotificationListBell, getNotificationNotRead, getNotificationNotReadBell, getNotiSettingProgress, getNotiSettingProgressCheck } = notificationSlice.actions;
export default notificationSlice.reducer;