import React, { useEffect, useState, useCallback } from "react";
import { Form, Button, Dropdown, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Api from "../../core/services/api.service";

import AuthAbilities from "../../pages/Admin/Auth/AuthAbilities";
import SwalAlert from "../Swal/SwalAlert";

import Icon from "@mdi/react";
import { mdiDotsHorizontal, mdiPencil, mdiDelete, mdiNotePlus } from "@mdi/js";

import Popup from "../Popup/ModalPopup";
import Table from "../Table/Table";

const TabCompetency = () => {
  const params = useParams();
  // const dispatch = useDispatch();

  const abilities = AuthAbilities();

  const customSwal = SwalAlert();

  const [competencyList, setCompetencyList] = useState([]);

  const [showComp, setShowComp] = useState(false);
  const [errorsCritical, setErrorsCritical] = useState({});
  const [criticalStatus, setCriticalStatus] = useState("");
  const [convCritical] = useState({
    txtCritical: "",
  });
  const [modeModal, setModeModal] = useState({
    title: null,
    button: null,
  });

  const headerCompetencies = [
    {
      Header: "No",
      maxWidth: 50,
      minWidth: 10,
      width: 40,
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Critical Competencies",
      maxWidth: 600,
      minWidth: 100,
      width: 600,
      Cell: ({ row }) => {
        const data = row.original;

        return <div>{data.competencies}</div>;
      },
    },
    {
      Header: "",
      accessor: "actions",
      maxWidth: 100,
      minWidth: 80,
      width: 80,
      Cell: ({ row }) => {
        const data = row.original;

        const btnDelComp = (e) => {
          customSwal
            .fire({
              title: "Do you want to delete this competency?",
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              reverseButtons: true,
              confirmButtonText: "Yes",
            })
            .then((result) => {
              if (result.isConfirmed) {
                Api.delete(
                  "client/" +
                    params.id +
                    "/assignment/" +
                    params.idassign +
                    "/competency/" +
                    data.id
                )
                  .then((response) => {
                    getListCompentency();

                    customSwal.fire({
                      title: "Deleted Successfully!",
                      icon: "success",
                      confirmButtonText: "Okay",
                    });
                  })
                  .catch((error) => {
                    console.log("error delete competency", error);
                  });
              }
            });
        };

        return (
          <div className="dropdown-container list-cli">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <Icon path={mdiDotsHorizontal} size={1} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={btnAddComp(data.id)}
                  hidden={!abilities.includes("edit competency")}
                >
                  <Icon path={mdiPencil} size={0.8} color="#467FD0" />
                  Edit Competencies
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={btnDelComp}
                  hidden={!abilities.includes("delete competency")}
                >
                  <Icon path={mdiDelete} size={0.8} color="#DF4759" />
                  Delete Competencies
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const formErrorCritical = (object) => {
    const newErrors = {};

    if (!object.txtCritical || object.txtCritical === "")
      newErrors.txtCritical = "Fill in the blank!";

    return newErrors;
  };

  const btnCloseComp = () => {
    setShowComp(false);
  };

  const btnAddComp = (id) => (e) => {
    setShowComp(true);
    setErrorsCritical({});

    if (id === "Add") {
      convCritical.txtCritical = "";

      setModeModal({
        title: "Add Competencies",
        button: "Add",
      });

      setCriticalStatus("New");
    } else {
      setModeModal({
        title: "Edit Competencies",
        button: "Update",
      });

      const filteredCritical = competencyList.filter((critical) => {
        return critical.id === id;
      });

      // console.log("filteredCritical", filteredCritical);
      convCritical.txtCritical = filteredCritical[0].competencies;

      setCriticalStatus(id);
    }
  };

  const btnSaveUpdateCompetency = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dataCritical = Object.fromEntries(data.entries());

    // console.log("dataCritical", dataCritical);

    const newErrors = formErrorCritical(dataCritical);

    if (Object.keys(newErrors).length > 0) {
      setErrorsCritical(newErrors);
    } else {
      if (criticalStatus === "New") {
        customSwal
          .fire({
            title: "Do you want to save competencies?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post(
                "client/" +
                  params.id +
                  "/assignment/" +
                  params.idassign +
                  "/competency/create",
                {
                  competency: dataCritical.txtCritical,
                }
              )
                .then((response) => {
                  // let data = response.data;
                  setShowComp(false);
                  getListCompentency();

                  customSwal.fire({
                    title: "Competencies Saved Successfully!",
                    icon: "success",
                    confirmButtonText: "Okay",
                  });
                })
                .catch((error) => {
                  console.log("error save competency", error);
                });
            }
          });
      } else {
        customSwal
          .fire({
            title: "Do you want to update competencies?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.patch(
                "client/" +
                  params.id +
                  "/assignment/" +
                  params.idassign +
                  "/competency/" +
                  criticalStatus,
                {
                  competency: dataCritical.txtCritical,
                }
              )
                .then((response) => {
                  // let data = response.data;
                  setShowComp(false);
                  getListCompentency();

                  customSwal.fire({
                    title: "Competencies Saved Successfully!",
                    icon: "success",
                    confirmButtonText: "Okay",
                  });
                })
                .catch((error) => {
                  console.log("error update competency", error);
                });
            }
          });
      }
    }
  };

  const getListCompentency = useCallback(() => {
    Api.get(
      "client/" + params.id + "/assignment/" + params.idassign + "/competency"
    )
      .then((response) => {
        let data = response.data;

        // console.log("getListCompentency", data);

        if (data !== "no data") {
          setCompetencyList(data);
        }
      })
      .catch((error) => {
        console.log("error getListCompentency", error);
      });
  }, [params]);

  useEffect(() => {
    getListCompentency();
  }, [getListCompentency]);

  return (
    <>
      <Card>
        <Card.Body>
          <div className="row-div">
            <div className="left-div"></div>
            <div className="right-div">
              <Button
                variant="link"
                className="modal-link"
                onClick={btnAddComp("Add")}
                hidden={!abilities.includes("add competency")}
              >
                <Icon path={mdiNotePlus} />
                Add Competency
              </Button>
            </div>
          </div>

          <Popup
            show={showComp}
            size="lg"
            backdrop="static"
            keyboard={false}
            onHide={btnCloseComp}
            title={modeModal.title}
            content={
              <>
                <Form id="critical-form" onSubmit={btnSaveUpdateCompetency}>
                  <Form.Control
                    as="textarea"
                    name="txtCritical"
                    rows={3}
                    isInvalid={!!errorsCritical.txtCritical}
                    defaultValue={convCritical.txtCritical}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorsCritical.txtCritical}
                  </Form.Control.Feedback>
                </Form>
              </>
            }
            button={
              <>
                <Button variant="secondary" onClick={btnCloseComp}>
                  Cancel
                </Button>
                <Button form="critical-form" type="submit" variant="primary">
                  {modeModal.button}
                </Button>
              </>
            }
          />

          <Table headers={headerCompetencies} items={competencyList}></Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default TabCompetency;
