import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Api from "../../../core/services/api.service";

import moment from "moment";

import { mdiBell, mdiCalendar } from "@mdi/js";
import Icon from "@mdi/react";

import { TableNotification } from "../../../components/Table/TableNotification";

import { getListNotification } from "../../../store/actions/notificationSlice";
import { storeTitle } from "../../../store/actions/headerTitleSlice";

const ViewAllNoti = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.login);
  const { notificationList } = useSelector((state) => state.notification);

  const btnClickNoti = (url, id) => (e) => {
    Api.get("notification/" + id + "/markAsRead")
      .then((response) => {
        dispatch(getListNotification(user.sub, "llm"));

        navigate(url);
      })
      .catch((error) => {
        console.log("error btnClickNoti", error);
      });
  };

  const headers = [
    {
      Header: " ",
      // hideHeader: false,
      minWidth: 50,
      width: 50,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div
            className="icon-noti-card"
            onClick={btnClickNoti(data.data.url, data.id)}
          >
            <div className="box-icon">
              <Icon path={mdiCalendar} />
            </div>
          </div>
        );
      },
    },
    {
      Header: "  ",
      accessor: (row) => `${row.notiTitle}`,
      // hideHeader: false,
      minWidth: 500,
      width: 1200,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div
            className="info-noti-card"
            key={data.id}
            onClick={btnClickNoti(data.data.url, data.id)}
          >
            {/* <div className="noti-title">{data.notiTitle}</div> */}
            <div
              className="content-noti-card"
              dangerouslySetInnerHTML={{
                __html: data.data.message,
              }}
            ></div>
            <div className="noti-date">
              {moment(data.created_at).format("DD MMMM YYYY")}
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getListNotification(user.sub, "llm"));
    dispatch(storeTitle({ title: "Notifications", icon: mdiBell }));
  }, [dispatch, user.sub]);

  // const { rows } = useTable({ columns, data })

  return (
    <div className="main-div">
      <div className="top-title-div">
        <TableNotification
          columns={headers}
          data={notificationList}
          site="llm"
          paginate={notificationList.length}
        />
      </div>
    </div>
  );
};

export default ViewAllNoti;
