import React, { useEffect, useState, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import Api from "../../core/services/api.service";
import Apiform from "../../core/services/apiform.service";

import FileSaver from "file-saver";
import SwalAlert from "../Swal/SwalAlert";
import AuthAbilities from "../../pages/Admin/Auth/AuthAbilities";
// import moment from "moment";

import Icon from "@mdi/react";
import {
  mdiPencil,
  mdiPlusCircle,
  mdiFile,
  mdiDelete,
  mdiPrinter,
  mdiFileAccount,
} from "@mdi/js";

import CardCandidate from "../Card/CardCandidate";
import Searchable from "../DropDown/SearchableSelect";
import TextFieldFormat from "../TextField/TextFieldFormat";
import Popup from "../Popup/ModalPopup";
import DropDown from "../DropDown/DropDown";
import DefaultPhoto from "../../assets/no-profile-photo.jpg";
import CustomTable from "../Table/TableFunction";
import RichTextEditor from "../RichTextArea/RichTextEditor";
import Creatable from "../DropDown/CreateableSelect";

import { monthList, noticeList } from "../../pages/Admin/dataList";
import { SummaryReport } from "../Report/Template/SummaryReport";
import { ResumeTemplate } from "../Report/Template/ResumeTemplate";

import { getListOptions } from "../../store/actions/optionSlice";
// import { getListClient } from "../../store/actions/clientSlice"

import "../../App.css";
import moment from "moment";

export const TabViewCandidate = () => {
  const params = useParams();
  const abilities = AuthAbilities();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const customSwal = SwalAlert();

  const {
    dataYear,
    dataSalute,
    dataGender,
    dataLanguages,
    // dataPhoneCode,
    dataCountry,
    dataCitizenship,
    dataMarital,
    dataCurrency,
    dataJobFunction,
    dataIndustry,
    dataAllowance,
  } = useSelector((state) => state.option);
  // const { clientList } = useSelector((state) => state.client);

  const [isReadMore, setReadMore] = useState({
    id: "",
    more: false,
  });
  const [editMode, setEditMode] = useState(false);
  const [editBasic, setEditBasic] = useState(false);
  const [editEdu, setEditEdu] = useState(false);
  const [editExp, setEditExp] = useState(false);
  const [editRef, setEditRef] = useState(false);
  const [editMisc, setEditMisc] = useState(false);
  const [profileImg, setProfileImg] = useState("");
  const [rbRelocate, setRbRelocate] = useState(false);

  // Basic Personal Information
  const [errorsBasic, setErrorsBasic] = useState({});
  const [selLang, setSelLang] = useState([]);
  const [selPreLoc, setSelPreLoc] = useState([]);

  const btnEditMode = (index, type) => {
    if (editMode === index) {
      //if clicked is already active, then close
      return setEditMode(null);
    }

    if (type === "first") {
      setEditBasic(true);
      setErrorsBasic({});
    } else if (type === "second") {
      setEditEdu(true);
    } else if (type === "third") {
      setEditExp(true);
    } else if (type === "fourth") {
      setEditRef(true);
    } else if (type === "fifth") {
      setEditMisc(true);
    }

    setEditMode(index);
  };

  const btnCancelBasic = (index) => {
    setEditBasic(index);
    setEditMode(index);
  };

  const handleLanguages = (e) => {
    setSelLang(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const handlePreferLoc = (e) => {
    setSelPreLoc(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const formErrorsBasic = (object, arraySelected) => {
    const newBasicErrors = {};

    // const regEmail = /\S+@\S+\.\S+/;

    // if (!object.ddlSalutation || object.ddlSalutation === "")
    //   newBasicErrors.ddlSalutation = "Fill in the blank!";

    if (!object.txtfirstname || object.txtfirstname === "")
      newBasicErrors.txtfirstname = "Fill in the blank!";

    if (!object.txtlastname || object.txtlastname === "")
      newBasicErrors.txtlastname = "Fill in the blank!";

    // if (arraySelected.length === 0)
    //   newBasicErrors.ddllang = "Fill in the blank!";

    // if (!object.txtcountrycode || object.txtcountrycode === "")
    //   newBasicErrors.txtcountrycode = "Fill in the blank!";

    // if (!object.txtemail || object.txtemail === "")
    //   newBasicErrors.txtemail = "Fill in the blank!";
    // else if (!regEmail.test(object.txtemail))
    //   newBasicErrors.txtemail = "Invalid Email Address";

    // if (!object.txtphone || object.txtphone === "")
    //   newBasicErrors.txtphone = "Fill in the blank!";

    return newBasicErrors;
  };

  const btnSaveBasic = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtBasic = Object.fromEntries(data.entries());

    const newErrors = formErrorsBasic(dtBasic, selLang);

    if (Object.keys(newErrors).length > 0) {
      setErrorsBasic(newErrors);
    } else {

      let linkedInCheck = !dtBasic.txtlinked.substring(0,dtBasic.txtlinked.indexOf(":"))? !dtBasic.txtlinked ? "" : "http://" + dtBasic.txtlinked : dtBasic.txtlinked;
      let instaCheck = !dtBasic.txtinsta.substring(0,dtBasic.txtinsta.indexOf(":"))? !dtBasic.txtinsta ? "" : "http://" + dtBasic.txtinsta : dtBasic.txtinsta;
      let facebookCheck = !dtBasic.txtfacebook.substring(0,dtBasic.txtfacebook.indexOf(":"))? !dtBasic.txtfacebook ? "" : "http://" + dtBasic.txtfacebook : dtBasic.txtfacebook;
      let othwebCheck = !dtBasic.txtothweb.substring(0,dtBasic.txtothweb.indexOf(":"))? !dtBasic.txtothweb ? "" : "http://" + dtBasic.txtothweb : dtBasic.txtothweb;

      customSwal
        .fire({
          title: "Do you want to update candidate profile?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();

            formData.append("profile_pic", dtBasic.txtprofile);
            formData.append("salutation", dtBasic.ddlSalutation);
            formData.append("first_name", dtBasic.txtfirstname);
            formData.append("last_name", dtBasic.txtlastname);

            formData.append("nickname", dtBasic.txtnickname);
            formData.append("gender", dtBasic.ddlgender);
            formData.append("dob", dtBasic.txtdob);

            if (selLang.length > 0) {
              selLang.forEach((item, index) => {
                formData.append(`languages[${index}]`, item);
              });
            } else {
              selCandidate.languages.forEach((item, index) => {
                formData.append(`languages[${index}]`, item);
              });
            }

            // formData.append("mobile_no_code", dtBasic.txtcountrycode);
            formData.append("mobile_no", dtBasic.txtphone);
            formData.append("email", dtBasic.txtemail);
            formData.append("comp_email", dtBasic.txtcompemail);
            formData.append("permanent_addr", dtBasic.txtpermanentaddr);

            formData.append("posting_addr", dtBasic.txtpostingaddr);
            formData.append("citizenship_main", dtBasic.ddlcitimain);
            formData.append("citizenship_other", dtBasic.ddlcitioth);
            formData.append("marital_status", dtBasic.ddlmartialsta);

            formData.append("no_of_child", Number(dtBasic.txtnochild));
            formData.append("interest_hobby[0]", dtBasic.txthobbie);
            formData.append("expected_salary_curr", dtBasic.ddlcurrency);
            formData.append(
              "expected_salary",
              Number(dtBasic.txtsalary.replace(/[^0-9.-]+/g, ""))
            );

            formData.append("willing_relocate", dtBasic.grpRelocate);

            if (dtBasic.grpRelocate === "false") {
              formData.append(`prefer_location[0]`, []);
            } else {
              if (selPreLoc.length > 0) {
                selPreLoc.forEach((item, index) => {
                  formData.append(`prefer_location[${index}]`, item);
                });
              } else {
                selCandidate.prefer_location.forEach((item, index) => {
                  formData.append(`prefer_location[${index}]`, item);
                });
              }
            }

            formData.append("willing_travel", dtBasic.grpTravel);

            formData.append("linkedln_url", linkedInCheck);
            formData.append("instagram_url", instaCheck);
            formData.append("facebook_url", facebookCheck);
            formData.append("website_url", othwebCheck);

            Apiform.patch("candidate/" + params.id, formData)
              .then((response) => {
                getSelectedCandidate();
                setEditBasic(false);
                setEditMode(false);

                customSwal.fire({
                  title: "Profile Updated Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("error btnSaveBasic", error);
              });
          }
        });
    }
  };

  const uploadSingleFile = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setProfileImg(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  // End Basic Personal Information

  //Start Education Information
  const [eduState] = useState({
    txtschool: "",
    txtdegree: "",
    txtfromyear: "",
    txttoyear: "",
    txtcertificate: "",
  });
  const [modalEdu, setModalEdu] = useState({
    title: "",
    button: "",
  });
  const [showEdu, setShowEdu] = useState(false);
  const [idEdu, setIdEdu] = useState("");
  const [errorsEdu, setErrorsEdu] = useState({});

  const btnCancelEdu = (index) => {
    setEditEdu(index);
    setEditMode(index);
  };

  const btnCloseEdu = () => setShowEdu(false);

  const btnAddEdu = () => {
    eduState.txtschool = null;
    eduState.txtdegree = null;
    eduState.txtfromyear = null;
    eduState.txttoyear = null;

    setModalEdu({
      title: "Add Education",
      button: "Save",
    });

    setIdEdu("New");
    setErrorsEdu({});
    setShowEdu(true);
  };

  const btnEditEdu = (id) => (e) => {
    const filteredEdu = selCandidate.candidate_education.filter((education) => {
      return education.id === id;
    });
    // console.log("filteredEdu", filteredEdu);

    eduState.txtschool = filteredEdu[0].name;
    eduState.txtdegree = filteredEdu[0].qualification;
    eduState.txtfromyear = filteredEdu[0].start_year;
    eduState.txttoyear = filteredEdu[0].end_year;
    eduState.txtcertificate = filteredEdu[0].certificate_attachment;

    setModalEdu({
      title: "Edit Education",
      button: "Update",
    });

    setErrorsEdu({});
    setIdEdu(id);
    setShowEdu(true);
  };

  const btnDelEdu = (idedu) => (e) => {
    customSwal
      .fire({
        title: "Do you want to remove this education?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("candidate/" + params.id + "/education/" + idedu)
            .then((response) => {
              getSelectedCandidate();
            })
            .catch((error) => {
              console.log("btnDelEdu", error);
            });
        }
      });
  };

  const btnSaveUpdateEdu = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtEdu = Object.fromEntries(data.entries());

    // console.log("dtEdu", dtEdu);
    const newErrors = formEduErrors(dtEdu);

    if (Object.keys(newErrors).length > 0) {
      setErrorsEdu(newErrors);
    } else {
      if (idEdu === "New") {
        customSwal
          .fire({
            title: "Do you want to add new education?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              const formData = new FormData();

              formData.append("name", dtEdu.txtschool);
              formData.append("qualification", dtEdu.txtdegree);
              formData.append("start_year", dtEdu.txtfromyear);
              formData.append("end_year", dtEdu.txttoyear);
              formData.append("certificate_attachment", dtEdu.txtcertificate);

              // const token = localStorage.getItem('id_token');

              Apiform.post(
                "candidate/" + params.id + "/education/store",
                formData
              )
                .then((response) => {
                  getSelectedCandidate();
                  setShowEdu(false);
                })
                .catch((error) => {
                  console.log("error btnSaveUpdateEdu", error);
                });
            }
          });
      } else {
        // console.log("Id", dtEdu.txtcertificate);
        customSwal
          .fire({
            title: "Do you want to update education?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              const formData = new FormData();

              formData.append("name", dtEdu.txtschool);
              formData.append("qualification", dtEdu.txtdegree);
              formData.append("start_year", dtEdu.txtfromyear);
              formData.append("end_year", dtEdu.txttoyear);
              formData.append("certificate_attachment", dtEdu.txtcertificate);

              Apiform.patch(
                "candidate/" + params.id + "/education/" + idEdu + "/update",
                formData
              )
                .then((response) => {
                  getSelectedCandidate();
                  setShowEdu(false);
                })
                .catch((error) => {
                  console.log("error btnSaveUpdateEdu", error);
                });
            }
          });
      }
    }
  };

  const btnDownload = (id, file) => (e) => {
    FileSaver.saveAs(file);
  };

  const formEduErrors = (object) => {
    const newErrors = {};
    // school errors
    if (!object.txtschool || object.txtschool === "")
      newErrors.txtschool = "Fill in the blank!";

    // // degree errors
    // if (!object.txtdegree || object.txtdegree === "")
    //   newErrors.txtdegree = "Fill in the blank!";

    // // from year errors
    // if (!object.txtfromyear || object.txtfromyear === "")
    //   newErrors.txtfromyear = "Fill in the blank!";

    // // to year errors
    // if (!object.txttoyear || object.txttoyear === "")
    //   newErrors.txttoyear = "Fill in the blank!";

    return newErrors;
  };

  // End Education Information

  // Start Experience Information
  const [modalExp, setModalExp] = useState({
    title: "",
    button: "",
  });
  const [expState] = useState({
    txtjobtitle: "",
    ddljobfunction: "",
    txtcompanyname: "",
    ddlIndustry: "",

    ddlCountry: {},
    cbWorkingCurrent: false,
    ddlpremonth: "",
    ddlpreyear: "",

    ddlstartmonth: "",
    ddlendmonth: "",
    ddlstartyear: "",
    ddlendyear: "",

    txtofficephone: "",
    txtofficeemail: "",
    ddlperiod: "",
    txtjobresponsibility: "",

    ddlcurrency: "",
    txtsalary: "",
    txtothbenefit: "",
    txtreason: "",
  });

  const [idExp, setIdExp] = useState("");
  const [errorsExp, setErrorsExp] = useState({});
  const [allowancestate] = useState([
    {
      ddlEarning: "",
      txtamount: "",
      ddlcurrencyallowance: "",
    },
  ]);

  const headers = [
    {
      Header: "Earning",
      accessor: (row) => `${row.earn}`,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            <Form.Select
              name="ddlEarning"
              onChange={(event) => handleEarning(event, data.id)}
              defaultValue={data.earning}
            >
              <option value="">Select</option>
              {dataAllowance.map((earn, index) => (
                <option key={index} value={earn.id}>
                  {earn.value}
                </option>
              ))}
              <option value="others">Others</option>
            </Form.Select>
          </div>
        );
      },
    },
    {
      Header: "Amount",
      accessor: (row) => `${row.amount}`,
      Cell: ({ row }) => {
        const data = row.original;
        return (
          <div>
            <TextFieldFormat
              name="txtamount"
              type="number"
              onChange={(event) => handleAmount(event, data.id)}
              value={data.amount}
              thousandSeparator={true}
            ></TextFieldFormat>
          </div>
        );
      },
    },
    {
      Header: "Currency",
      accessor: (row) => `${row.currency}`,
      Cell: ({ row }) => {
        const data = row.original;
        return (
          <div>
            <Form.Select
              name="ddlcurrencyallowance"
              onChange={(event) => handleCurrency(event, data.id)}
              defaultValue={data.currency}
            >
              <option value="">Select</option>
              {dataCurrency.map((currency, index) => (
                <option key={index} value={currency.value}>
                  {currency.value}
                </option>
              ))}
            </Form.Select>
          </div>
        );
      },
    },
    {
      Header: "Action",
      accessor: (row) => `${row.id}`,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div>
            <Button
              variant="link"
              className="delete-link"
              onClick={() => btnDelAllowance(data.id)}
            >
              <Icon path={mdiDelete} />
            </Button>
          </div>
        );
      },
    },
  ];

  const [showOption, setShowOption] = useState(false);

  const handleEarning = (event, param1) => {
    if (event.target.value === "others") {
      setShowOption(true);
      setErrorOpt({});
    } else {
      const filteredEarn = allowanceList.filter((earn) => {
        return earn.id === param1;
      });

      filteredEarn[0].earning = Number(
        event.target.value.replace(/[^0-9.-]+/g, "")
      );

      allowancestate.ddlEarning = filteredEarn[0].earning;
    }
  };

  const handleAmount = (event, param1) => {
    const filteredEarn = allowanceList.filter((earn) => {
      return earn.id === param1;
    });

    filteredEarn[0].amount = Number(
      event.target.value.replace(/[^0-9.-]+/g, "")
    );

    allowancestate.txtamount = filteredEarn[0].amount;
  };

  const handleCurrency = (event, param1) => {
    const filteredEarn = allowanceList.filter((earn) => {
      return earn.id === param1;
    });

    filteredEarn[0].currency = event.target.value;

    allowancestate.ddlcurrencyallowance = filteredEarn[0].currency;
  };

  const [selectedInd, setSelectedInd] = useState([]);

  const handleIndustry = (e) => {
    setSelectedInd(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const [selectedJob, setSelectedJob] = useState([]);

  const handleJobFunc = (e) => {
    setSelectedJob(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  function btnDelAllowance(id) {
    setAllowanceList((current) =>
      current.filter((employee) => {
        // 👇️ remove object that has id equal to 2
        return employee.id !== id;
      })
    );
  }

  const btnAddNewAllowance = (e) => {
    const allowanceLength = allowanceList.length + 1;

    setAllowanceList((items) => [
      ...items,
      {
        id: allowanceLength,
        earning: 0,
        amount: 0,
        currency: "",
      },
    ]);
  };

  const btnCloseOption = () => {
    setShowOption(false);
  };

  const [errorOpt, setErrorOpt] = useState({});

  const formExpOption = (object) => {
    const newErrors = {};

    if (!object.txtAllowDesc || object.txtAllowDesc === "")
      newErrors.txtAllowDesc = "Fill in the blank!";

    return newErrors;
  };

  const btnNewOption = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dataOpt = Object.fromEntries(data.entries());

    // console.log("dataOpt",dataOpt)
    const newErrors = formExpOption(dataOpt);

    if (Object.keys(newErrors).length > 0) {
      setErrorOpt(newErrors);
    } else {
      // console.log("dataOpt.txtAllowDesc", allowList)

      // const allowCode = allowList.find(e => e.value === dataOpt.txtAllowDesc.trim());

      var allowCode = dataAllowance.filter(function (allow) {
        return (
          allow.value.toLowerCase() ===
          dataOpt.txtAllowDesc.trim().toLowerCase()
        );
      });

      // console.log("allowCode", allowCode)

      if (allowCode.length > 0) {
        customSwal.fire({
          title: "This allowance description duplicate!",
          icon: "error",
          confirmButtonText: "Okay",
        });

        // setShowOption(false);
      } else {
        customSwal
          .fire({
            title: "Do you want to add new allowance?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post("option/store", {
                group: "Allowance",
                code: dataOpt.txtAllowDesc,
                value: dataOpt.txtAllowDesc,
                status: "active",
              })
                .then((response) => {
                  // console.log("option/store", response.data);
                  dispatch(getListOptions());
                  setShowOption(false);
                })
                .catch((error) => {
                  console.log("option/update", error);
                });
            }
          });
      }
    }
  };

  const [companyValue, setCompanyValue] = useState();

  const handleCompanyName = useCallback((inputValue) => {
    // console.log("inputValue", inputValue);
    setCompanyValue(inputValue);
  }, []);

  const [clientList, setClientList] = useState([]);

  function getListClients() {
    setClientList([]);

    Api.get("client")
      .then((response) => {
        let sortListClient = response.data.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });

        sortListClient.map((cli) => {
          return setClientList((items) => [
            ...items,
            {
              label: cli.name,
              value: cli.name,
            },
          ]);
        });
      })
      .catch((error) => {
        console.log("error getListClients", error);
      });
  }

  const formExpErrors = (object, company) => {
    const newErrors = {};

    // console.log("object", object);
    if (!object.txtjobtitle || object.txtjobtitle === "")
      newErrors.txtjobtitle = "Fill in the blank!";

    if (!company || company === "")
      newErrors.txtcompanyname = "Fill in the blank!";

    if (!object.ddlCountry || object.ddlCountry === "")
      newErrors.ddlCountry = "Fill in the blank!";

    // if (checked === true) {
    //   // if (!object.ddlpreyear || object.ddlpreyear === "")
    //   //   newErrors.ddlpreyear = "Fill in the blank!";

    //   // if (!object.ddlpremonth || object.ddlpremonth === "")
    //   //   newErrors.ddlpremonth = "Fill in the blank!";
    // } else {
    //   if (!object.ddlstartyear || object.ddlstartyear === "")
    //     newErrors.ddlstartyear = "Fill in the blank!";

    //   if (!object.ddlstartmonth || object.ddlstartmonth === "")
    //     newErrors.ddlstartmonth = "Fill in the blank!";

    //   if (!object.ddlendyear || object.ddlendyear === "")
    //     newErrors.ddlendyear = "Fill in the blank!";

    //   if (!object.ddlendmonth || object.ddlendmonth === "")
    //     newErrors.ddlendmonth = "Fill in the blank!";
    // }

    // if (!object.txtofficeemail || object.txtofficeemail === "")
    //   newErrors.txtofficeemail = "Fill in the blank!";

    // if (!object.ddlcurrency || object.ddlcurrency === "")
    //   newErrors.ddlcurrency = "Fill in the blank!";

    // if (!object.txtsalary || object.txtsalary === "")
    //   newErrors.txtsalary = "Fill in the blank!";

    return newErrors;
  };

  const btnCancelExp = (index) => {
    setEditExp(index);
    setEditMode(index);
  };

  const btnCloseExp = () => {
    setShowExp(false);
  };

  const btnAddExp = () => {
    btnResetExp();

    setModalExp({
      title: "Add Experience",
      button: "Add",
    });

    setIdExp("New");
    setCompanyValue({});
    setErrorsExp({});
    setShowExp(true);
  };

  const toggleBtnReadMore = (index, bool) => (e) => {
    setReadMore({
      id: index,
      more: bool,
    });
  };

  const salaryTxt = useRef(0.0);

  const handleSalary = useCallback((event) => {
    const value = event.target.value;

    salaryTxt.current = value;
  }, []);

  const btnEditExp = (id) => (e) => {
    const filteredExp = selCandidate.candidate_experiences.filter(
      (experience) => {
        return experience.id === id;
      }
    );

    // setSalaryTxt(filteredExp[0].salary);

    expState.txtjobtitle = filteredExp[0].title;
    expState.ddljobfunction = filteredExp[0].job_function;
    expState.txtcompanyname = filteredExp[0].company_name;
    setCompanyValue({
      label: filteredExp[0].company_name,
      value: filteredExp[0].company_name,
    });
    expState.ddlIndustry = filteredExp[0].industry;

    expState.ddlCountry = {
      label: filteredExp[0].location,
      value: filteredExp[0].location,
    };

    expState.ddlstartmonth = filteredExp[0].start_date_month;
    expState.ddlstartyear = filteredExp[0].start_date_year;
    expState.ddlendmonth = filteredExp[0].end_date_month;

    expState.ddlendyear = filteredExp[0].end_date_year;
    expState.ddlpremonth = filteredExp[0].present_month;
    expState.ddlpreyear = filteredExp[0].present_year;
    expState.cbWorkingCurrent = filteredExp[0].current_working;

    expState.txtofficephone = filteredExp[0].office_number;
    expState.txtofficeemail = filteredExp[0].office_email;
    expState.ddlperiod = filteredExp[0].notice_period;
    expState.txtjobresponsibility = filteredExp[0].job_responsibility;

    expState.ddlcurrency = filteredExp[0].currency;
    // expState.txtsalary = filteredExp[0].salary;
    salaryTxt.current = filteredExp[0].salary ? filteredExp[0].salary : 0;
    expState.txtothbenefit = filteredExp[0].other_benefits;
    expState.txtreason = filteredExp[0].reason_leaving;

    setAllowanceList(filteredExp[0].candidate_exp_allowance);
    setChecked(filteredExp[0].current_working);

    setModalExp({
      title: "Edit Experience",
      button: "Update",
    });

    setErrorsExp({});
    setIdExp(id);
    setShowExp(true);
  };

  function getMonthDifference(startDate, endDate) {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }

  const btnSaveUpdateExp = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtExp = Object.fromEntries(data.entries());

    var monthDuration = 0;

    if (checked === true) {
      monthDuration = getMonthDifference(
        new Date(dtExp.ddlpreyear + "-" + dtExp.ddlpremonth),
        new Date()
      );
    } else {
      monthDuration = getMonthDifference(
        new Date(dtExp.ddlstartyear + "-" + dtExp.ddlstartmonth),
        new Date(dtExp.ddlendyear + "-" + dtExp.ddlendmonth)
      );
    }

    const newErrors = formExpErrors(dtExp, companyValue);

    // console.log("exp newError", salaryTxt.current);

    const filteredAllowance = allowanceList.filter((allow) => {
      return allow.earning !== 0;
    });

    if (Object.keys(newErrors).length > 0) {
      setErrorsExp(newErrors);
    } else {
      if (idExp === "New") {
        customSwal
          .fire({
            title: "Do you want to add new experience?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post("candidate/" + params.id + "/experience/store", {
                title: dtExp.txtjobtitle,
                company_name: companyValue.value,
                location: dtExp.ddlCountry,
                start_date_year: checked ? null : dtExp.ddlstartyear,
                start_date_month: checked ? null : dtExp.ddlstartmonth,
                end_date_year: checked ? null : dtExp.ddlendyear,
                end_date_month: checked ? null : dtExp.ddlendmonth,
                current_working: checked.toString(),
                present_month: checked ? dtExp.ddlpremonth : null,
                present_year: checked ? dtExp.ddlpreyear : null,
                total_experience: monthDuration,
                office_number: dtExp.txtofficephone,
                office_email: dtExp.txtofficeemail,
                notice_period: dtExp.ddlperiod,
                industry: selectedInd,
                job_function: selectedJob,
                job_responsibility:
                  dtExp.txtjobresponsibility === "<p><br></p>"
                    ? ""
                    : dtExp.txtjobresponsibility,
                salary:
                  salaryTxt.current === 0
                    ? 0
                    : Number(salaryTxt.current.replace(/[^0-9.-]+/g, "")),
                currency: dtExp.ddlcurrency,
                other_benefits:
                  dtExp.txtothbenefit === "<p><br></p>"
                    ? ""
                    : dtExp.txtothbenefit,
                reason_leaving:
                  dtExp.txtreason === "<p><br></p>" ? "" : dtExp.txtreason,
                allowance: filteredAllowance,
              })
                .then((response) => {
                  // console.log(response);

                  getSelectedCandidate();
                  setShowExp(false);
                })
                .catch((error) => {
                  console.log("error save Experience", error);
                });
            }
          });
      } else {
        // console.log("csss", companyValue.value);
        customSwal
          .fire({
            title: "Do you want to update this experience?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.patch(
                "candidate/" + params.id + "/experience/" + idExp + "/update",
                {
                  title: dtExp.txtjobtitle,
                  company_name: companyValue.value,
                  location: dtExp.ddlCountry,
                  start_date_year: checked ? null : dtExp.ddlstartyear,
                  start_date_month: checked ? null : dtExp.ddlstartmonth,
                  end_date_year: checked ? null : dtExp.ddlendyear,
                  end_date_month: checked ? null : dtExp.ddlendmonth,
                  current_working: checked.toString(),
                  present_month: checked ? dtExp.ddlpremonth : null,
                  present_year: checked ? dtExp.ddlpreyear : null,
                  total_experience: monthDuration,
                  office_number: dtExp.txtofficephone,
                  office_email: dtExp.txtofficeemail,
                  notice_period: dtExp.ddlperiod,
                  industry: selectedInd,
                  job_function: selectedJob,
                  job_responsibility:
                    dtExp.txtjobresponsibility === "<p><br></p>"
                      ? ""
                      : dtExp.txtjobresponsibility,
                  salary:
                    salaryTxt.current === 0
                      ? 0
                      : Number(salaryTxt.current.replace(/[^0-9.-]+/g, "")),
                  currency: dtExp.ddlcurrency,
                  other_benefits:
                    dtExp.txtothbenefit === "<p><br></p>"
                      ? ""
                      : dtExp.txtothbenefit,
                  reason_leaving:
                    dtExp.txtreason === "<p><br></p>" ? "" : dtExp.txtreason,
                  allowance: filteredAllowance,
                }
              )
                .then((response) => {
                  getSelectedCandidate();
                  setShowExp(false);
                })
                .catch((error) => {
                  console.log("update Experience", error);
                });
            }
          });
      }
    }
  };

  const btnDelExp = (idexp) => (e) => {
    customSwal
      .fire({
        title: "Do you want to remove this experience?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("candidate/" + params.id + "/experience/" + idexp)
            .then((response) => {
              getSelectedCandidate();
            })
            .catch((error) => {
              console.log("btnDelExp", error);
            });
        }
      });
  };

  function toMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", {
      month: "long",
    });
  }

  function toAllowanceDesc(idallow) {
    let nameAllow = "";

    let array_allow = dataAllowance.filter((grp) => {
      return grp.id === idallow;
    });

    if (array_allow.length > 0) {
      nameAllow = array_allow[0].value;
    }

    return nameAllow;
  }

  const btnExpCompany = (nameComp) => (e) =>{
    Api.post("client/findByName",{
      name: nameComp
    })
    .then((response) => {
      const data = response.data;
      // potential
      if (data.is_potential === true &&  data.status !== null && data.status !== "closed") {
        // window.open(process.env.REACT_APP_BASE_URL + "clients/view-potential-client/" + data.id);
        navigate("/clients/view-potential-client/" + data.id);
      } 
      // active client
      else if (data.is_potential === false &&  data.status !== null && data.status !== "closed") {
        // window.open(process.env.REACT_APP_BASE_URL + "clients/viewActiveC/" + data.id);
        navigate("/clients/viewActiveC/" + data.id);
      } 
      // available client
      else{
        // window.open(process.env.REACT_APP_BASE_URL + "clients/viewAllClients/" + data.id);
        navigate("/clients/viewAllClients/" + data.id);
      }
    })
    .catch((error) => {
      console.log("error find name", error);
    });
  };

  const btnResetExp = (event) => {
    expState.txtjobtitle = null;
    expState.txtcompanyname = null;
    expState.ddljobfunction = "";
    expState.ddlIndustry = "";
    expState.ddlCountry = {};

    expState.ddlstartmonth = "";
    expState.ddlstartyear = "";
    expState.ddlendmonth = "";
    expState.ddlendyear = "";

    expState.ddlpremonth = "";
    expState.ddlpreyear = "";

    expState.cbWorkingCurrent = false;
    expState.txtofficephone = "";
    expState.txtofficeemail = "";
    expState.ddlperiod = "";
    expState.txtjobresponsibility = "";
    expState.ddlcurrency = "";
    salaryTxt.current = 0;
    // setSalaryTxt(0.0);
    expState.txtothbenefit = "";
    expState.txtreason = "";
    setAllowanceList([]);

    setChecked(false);
  };
  // End Experience Information

  // Start Referee Information
  const [modalRef, setModalRef] = useState({
    title: "",
    button: "",
  });

  const [refState] = useState({
    txtrefname: "",
    txtrefjobtitle: "",
    txtrefemail: "",
    txtrefphone: "",
    txtrefcompany: "",
    txtrefrelationship: "",
  });

  const [errorsRef, setErrorsRef] = useState({});
  const [idRef, setIdRef] = useState("");
  const [showRef, setShowRef] = useState(false);

  const btnCancelRef = (index) => {
    setEditRef(index);
    setEditMode(index);
  };

  const btnAddRef = (e) => {
    refState.txtrefname = null;
    refState.txtrefjobtitle = null;
    refState.txtrefemail = null;
    refState.txtrefphone = null;
    refState.txtrefcompany = null;
    refState.txtrefrelationship = null;

    setModalRef({
      title: "Add Reference",
      button: "Add",
    });

    setIdRef("New");
    setErrorsRef({});
    setShowRef(true);
  };

  const btnEditRef = (id) => (e) => {
    const filteredRef = selCandidate.candidate_reference.filter((reference) => {
      return reference.id === id;
    });

    refState.txtrefname = filteredRef[0].name;
    refState.txtrefjobtitle = filteredRef[0].job_title;
    refState.txtrefemail = filteredRef[0].email;
    refState.txtrefphone = filteredRef[0].phone;
    refState.txtrefcompany = filteredRef[0].current_company;
    refState.txtrefrelationship = filteredRef[0].relationship;

    setModalRef({
      title: "Edit Education",
      button: "Update",
    });

    setErrorsRef({});
    setIdRef(id);
    setShowRef(true);
  };

  const btnCloseReferee = () => {
    setShowRef(false);
  };

  const btnSaveUpdateRef = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtRef = Object.fromEntries(data.entries());

    const newErrors = formRefErrors(dtRef);

    if (Object.keys(newErrors).length > 0) {
      setErrorsRef(newErrors);
    } else {
      if (idRef === "New") {
        customSwal
          .fire({
            title: "Do you want to add new reference?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post("candidate/" + params.id + "/reference/store", {
                name: dtRef.txtrefname,
                job_title: dtRef.txtrefjobtitle,
                email: dtRef.txtrefemail,
                phone: dtRef.txtrefphone,
                current_company: dtRef.txtrefcompany,
                relationship: dtRef.txtrefrelationship,
              })
                .then((response) => {
                  getSelectedCandidate();
                  setShowRef(false);
                })
                .catch((error) => {
                  console.log("save Referee", error);
                });
            }
          });
      } else {
        customSwal
          .fire({
            title: "Do you want to update this reference?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.patch(
                "candidate/" + params.id + "/reference/" + idRef + "/update",
                {
                  name: dtRef.txtrefname,
                  job_title: dtRef.txtrefjobtitle,
                  email: dtRef.txtrefemail,
                  phone: dtRef.txtrefphone,
                  current_company: dtRef.txtrefcompany,
                  relationship: dtRef.txtrefrelationship,
                }
              )
                .then((response) => {
                  getSelectedCandidate();
                  setShowRef(false);
                })
                .catch((error) => {
                  console.log("update Referee", error);
                });
            }
          });
      }
    }
  };

  const formRefErrors = (object) => {
    const newErrors = {};

    if (!object.txtrefname || object.txtrefname === "")
      newErrors.txtrefname = "Fill in the blank!";

    return newErrors;
  };

  const btnDelRef = (idref) => (e) => {
    customSwal
      .fire({
        title: "Do you want to remove this reference?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("candidate/" + params.id + "/reference/" + idref)
            .then((response) => {
              getSelectedCandidate();
            })
            .catch((error) => {
              console.log("btnDelEdu", error);
            });
        }
      });
  };
  // End Referee Information

  // Start Miscellaneous Information
  const [modalMis, setModalMis] = useState({
    title: "",
    button: "",
  });

  const [misState] = useState({
    txtmiscell: "",
  });

  const [idMis, setIdMis] = useState("");
  const [showMisc, setShowMisc] = useState(false);
  const [errorsMis, setErrorsMis] = useState({});

  const btnCancelMisc = (index) => {
    setEditMisc(index);
    setEditMode(index);
  };

  const btnCloseMisc = () => {
    setShowMisc(false);
  };

  const btnAddMisc = (e) => {
    misState.txtmiscell = null;

    setModalMis({
      title: "Add Miscellaneous Information",
      button: "Add",
    });

    setIdMis("New");
    setErrorsMis({});
    setShowMisc(true);
  };

  const btnEditMisc = (id) => (e) => {
    const filteredMis = selCandidate.candidate_miscellaneous.filter(
      (miscell) => {
        return miscell.id === id;
      }
    );

    misState.txtmiscell = filteredMis[0].misc;

    setModalMis({
      title: "Edit Miscellaneous",
      button: "Update",
    });

    setErrorsMis({});
    setIdMis(id);
    setShowMisc(true);
  };

  const btnSaveUpdateMisc = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtMis = Object.fromEntries(data.entries());
    const newErrors = formMisErrors(dtMis);

    if (Object.keys(newErrors).length > 0) {
      setErrorsMis(newErrors);
    } else {
      if (idMis === "New") {
        customSwal
          .fire({
            title: "Do you want to add new miscellaneous information?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post("candidate/" + params.id + "/miscellaneous/store", {
                misc: dtMis.txtmiscell,
                status: null,
              })
                .then((response) => {
                  getSelectedCandidate();
                  setShowMisc(false);
                })
                .catch((error) => {
                  console.log("save miscellaneous", error);
                });
            }
          });
      } else {
        customSwal
          .fire({
            title: "Do you want to update miscellaneous?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.patch(
                "candidate/" +
                  params.id +
                  "/miscellaneous/" +
                  idMis +
                  "/update",
                {
                  misc: dtMis.txtmiscell,
                  status: null,
                }
              )
                .then((response) => {
                  getSelectedCandidate();
                  setShowMisc(false);
                })
                .catch((error) => {
                  console.log("Update miscellaneous", error);
                });
            }
          });
      }
    }
  };

  const formMisErrors = (object) => {
    const newErrors = {};

    if (!object.txtmiscell || object.txtmiscell === "" || object.txtmiscell === "<p><br></p>")
      newErrors.txtmiscell = "Fill in the blank!";

    return newErrors;
  };

  const btnDelMis = (idmis) => (e) => {
    customSwal
      .fire({
        title: "Do you want to remove this miscellaneous?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.delete("candidate/" + params.id + "/miscellaneous/" + idmis)
            .then((response) => {
              getSelectedCandidate();
            })
            .catch((error) => {
              console.log("delete miscellaneous", error);
            });
        }
      });
  };
  // End Miscellaneous Information

  const candidateStatus = [
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Available",
      value: "available",
    },
    {
      label: "Client",
      value: "client",
    },
    {
      label: "Placed",
      value: "placed",
    },
    {
      label: "Blacklisted",
      value: "blacklisted",
    },
  ];

  const [txtStatus, setTxtStatus] = useState("");
  const [status, setStatus] = useState("");
  const [showBlacklist, setShowBlacklist] = useState(false);
  const [showClient, setShowClient] = useState(false);

  const [errorsBlack, setErrorsBlack] = useState({});

  const changeStatus = (event) => {
    let statusChange = event.target.value;

    // console.log("statusChange", statusChange)
    setTxtStatus(statusChange);

    if (statusChange === "blacklisted") {
      setStatus("error-color");
      setErrorsBlack({});
      setShowBlacklist(true);
    } else if (statusChange === "client") {
      const listCurrentWork = showExperience.filter((exp) => {
        return exp.current_working === true;
      });

      setStatus("primary-color");

      customSwal
        .fire({
          title: "Do you want to update the status to " + statusChange + " ?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Api.post("candidate/" + params.id + "/updateStatusClient", {
              company_name: listCurrentWork[0].company_name,
            })
              .then((response) => {
                getSelectedCandidate();

                customSwal.fire({
                  title: "Status Updated Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("user/store", error);
              });
          } else {
            getSelectedCandidate();
          }
        });
    } else {
      setStatus("primary-color");

      customSwal
        .fire({
          title: "Do you want to update the status to " + statusChange + " ?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Api.post("candidate/" + params.id + "/updateStatus", {
              status: statusChange,
              reason_blacklist: null,
            })
              .then((response) => {
                getSelectedCandidate();

                customSwal.fire({
                  title: "Status Updated Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("user/store", error);
              });
          } else {
            getSelectedCandidate();
          }
        });
    }
  };

  const btnCloseBlacklist = () => {
    getSelectedCandidate();
    setShowBlacklist(false);
  };

  const btnCloseClient = () => {
    getSelectedCandidate();
    setShowClient(false);
  };

  const formFindErrorBlacklist = (object) => {
    const newErrors = {};

    if (!object.txtreasonblack || object.txtreasonblack === "")
      newErrors.txtreasonblack = "Fill in the blank!";

    return newErrors;
  };

  const btnUpdateBlacklist = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtBlack = Object.fromEntries(data.entries());
    const newErrors = formFindErrorBlacklist(dtBlack);

    if (Object.keys(newErrors).length > 0) {
      setErrorsBlack(newErrors);
    } else {
      customSwal
        .fire({
          title: "Do you want to change status blacklisted?",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Api.post("candidate/" + params.id + "/updateStatus/", {
              status: "blacklisted",
              reason_blacklist: dtBlack.txtreasonblack,
            })
              .then((response) => {
                setShowBlacklist(false);
                getSelectedCandidate();

                customSwal.fire({
                  title: "Updated Successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              })
              .catch((error) => {
                console.log("update status candidate", error);
              });
          }
        });
    }
  };

  const btnUpdateClient = (e) => {};

  const [selCandidate, setSelCandidate] = useState({});
  const [selDob, setSelDob] = useState("");
  const [showDob, setShowDob] = useState("");
  const [showLang, setShowLang] = useState("");
  const [showPreLoc, setShowPreLoc] = useState("");
  const [showSalary, setShowSalary] = useState("");
  const [showHobby, setShowHobby] = useState("");

  const [showEducation, setShowEducation] = useState([]);
  const [showExperience, setShowExperience] = useState([]);
  const [showReferee, setShowReferee] = useState([]);
  const [showMiscellaneous, setShowMiscellaneous] = useState([]);
  // const [resumeList, setResumeList] = useState([]);

  const getSelectedCandidate = useCallback(() => {
    Api.get("candidate/" + params.id)
      .then((response) => {
        let data = response.data;
        // let nameCrumb = data.first_name + " " + data.last_name;

        setSelCandidate(data);
        // setSelLang(data.languages);

        setRbRelocate(data.willing_relocate);
        setProfileImg(data.profile_pic);

        data.dob ? setSelDob(data.dob.substring(0, 10)) : setSelDob();

        const dateDob = new Date(data.dob);

        const formatDate =
          dateDob.getDate() +
          " " +
          dateDob.toLocaleString("en-us", { month: "long" }) +
          " " +
          dateDob.getFullYear();

        setShowDob(formatDate);
        setShowLang(data.languages.toString());
        setShowPreLoc(data.prefer_location.toString());

        const salaryNumber = Number(data.expected_salary);

        setShowSalary(
          salaryNumber.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
        );

        if (data.interest_hobby.length > 0) {
          setShowHobby(data.interest_hobby[0]);
        } else {
          setShowHobby("-");
        }

        setTxtStatus(data.status);

        if (data.status === "blacklisted") {
          setStatus("error-color");
        } else {
          setStatus("primary-color");
        }

        // Education
        if (data.candidate_education.length > 0) {
          setShowEducation(data.candidate_education);
        } else {
          setShowEducation([]);
        }

        // Experience
        if (data.candidate_experiences.length > 0) {
          setShowExperience(data.candidate_experiences);
        } else {
          setShowExperience([]);
        }

        // Referee
        if (data.candidate_reference.length > 0) {
          setShowReferee(data.candidate_reference);
        } else {
          setShowReferee([]);
        }

        // Miscellaneous
        if (data.candidate_miscellaneous.length > 0) {
          setShowMiscellaneous(data.candidate_miscellaneous);
        } else {
          setShowMiscellaneous([]);
        }

        // // Resume
        // if (data.candidate_resume_attachment.length > 0) {
        //   setResumeList(data.candidate_resume_attachment);
        // } else {
        //   setResumeList([]);
        // }
      })
      .catch((error) => {
        console.log("candidate/all", error);
      });
  }, [params]);

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    getSelectedCandidate();
    getListClients();
    // getListOption();
  }, [getSelectedCandidate]);

  const [allowanceList, setAllowanceList] = useState([]);
  const [showExp, setShowExp] = useState(false);

  const downloadSummary = () => {
    Api.get("candidate/" + params.id + "/print/summary")
      .then((response) => {
        let data = response.data;

        console.log("printSummary", data);
        SummaryReport(data);
      })
      .catch((error) => {
        console.log("candidate/all", error);
      });
  };

  const downloadResume = (data) => () => {
    // console.log("downloadResume", data);
    ResumeTemplate(data);
  };

  return (
    <>
      <Card className="list-cand">
        <div hidden={!abilities.includes("view basic information")}>
          {/* Basic Info Candidate */}
          <div className="tab-header-div">
            <div className="left-div">
              <div className="tab-header-font">
                About {selCandidate.first_name} {selCandidate.last_name}
              </div>
            </div>
            <div className="right-div two-btn">
              {editMode === false ? (
                <Button
                  variant="link"
                  className="modal-link"
                  onClick={downloadResume(selCandidate)}
                >
                  <Icon path={mdiFileAccount} />
                  <div>Generate Resume</div>
                </Button>
              ) : null}

              {editMode === false ? (
                <Button
                  variant="link"
                  className="modal-link"
                  onClick={downloadSummary}
                >
                  <Icon path={mdiPrinter} />
                  <div>Print Summary</div>
                </Button>
              ) : null}

              {editMode === false ? (
                <Button
                  onClick={() => btnEditMode(true, "first")}
                  variant="link"
                  className="modal-link"
                  hidden={!abilities.includes("edit candidate")}
                >
                  <Icon path={mdiPencil} />
                  <div>Edit Information</div>
                </Button>
              ) : null}

              {/* {editMode ? (
                <>
                  <Button
                    onClick={() => btnCancelBasic(false)}
                    variant="secondary">
                    Cancel
                  </Button>
                  <Button form="basic-form" type="submit">
                    Save
                  </Button>
                </>
              ) : null} */}
            </div>
          </div>

          <div className="content-div create-st">
            <Form id="basic-form" onSubmit={btnSaveBasic}>
              {/* 1 */}
              <Row>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column lg={4}>
                      Profile Picture
                    </Form.Label>
                    <Col xs={3}>
                      <div className="icon-box-lg">
                        {selCandidate.profile_pic ? (
                          <img src={profileImg} alt="" />
                        ) : (
                          <img src={DefaultPhoto} alt="" />
                        )}
                      </div>
                    </Col>
                    {editBasic ? (
                      <Col xs={5}>
                        <Form.Control
                          onChange={uploadSingleFile}
                          name="txtprofile"
                          type="file"
                          accept=".png,.jpg,.jpeg"
                        />
                        <Form.Text className="text-muted">
                          Allowed file types: jpg, jpeg, png.
                        </Form.Text>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={3} hidden={!abilities.includes("edit candidate")}>
                  <div className="candidate-status-div">
                    <div className="candidate-status">
                      Status:
                      <DropDown
                        options={candidateStatus}
                        styleDrop="select--status--style"
                        sizeDrop="select--candidate"
                        colorDrop={status}
                        value={txtStatus}
                        onChange={changeStatus}
                        disabled={!abilities.includes("edit candidate")}
                      />
                    </div>
                    <div>
                      {txtStatus === "blacklisted" ? (
                        <div className="status-reason">
                          <Col xs={4}>Reason for blacklist:</Col>
                          <Col style={{ color: "#212529" }}>
                            {selCandidate.reason_blacklist}
                          </Col>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col xs={3} hidden={!abilities.includes("edit candidate")}>
                  <div className="candidate-status-div">
                    <div className="candidate-status">
                      Last Edited
                      
                    </div>
                    <div>
                      {selCandidate.updated_by_users ? selCandidate.updated_by_users.first_name + " " + selCandidate.updated_by_users.last_name + " (" + moment(selCandidate.updated_at).format("DD MMMM YYYY")  + ")"  : "-"}  
                    </div>
                  </div>
                </Col>
              </Row>
              {/* 2 */}
              <Row>
                <Col>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Salutation{" "}
                      {/* {editBasic ? (
                        <label className="validation-star">*</label>
                      ) : null} */}
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <>
                          <Form.Select
                            name="ddlSalutation"
                            defaultValue={selCandidate.salutation}
                            // isInvalid={!!errorsBasic.ddlSalutation}
                          >
                            <option value="">Select</option>
                            {dataSalute.map((salute, index) => (
                              <option key={index} value={salute.code}>
                                {salute.value}
                              </option>
                            ))}
                          </Form.Select>
                          {/* <Form.Control.Feedback type="invalid">
                            {errorsBasic.ddlSalutation}
                          </Form.Control.Feedback> */}
                        </>
                      ) : (
                        <div className="text-col">
                          {selCandidate.salutation ? selCandidate.salutation : "-"}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Nickname/Alias
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <Form.Control
                          type="text"
                          defaultValue={selCandidate.nickname}
                          name="txtnickname"
                          // isInvalid={ !!errorsBasic.txtfirstname }
                          // onChange={ e => setField('txtfirstname', e.target.value) }
                        />
                      ) : (
                        <div className="text-col">
                          {selCandidate.nickname ? selCandidate.nickname : "-"}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              {/* 3 */}
              <Row>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      First Name{" "}
                      {editBasic ? (
                        <label className="validation-star">*</label>
                      ) : null}
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <>
                          <Form.Control
                            type="text"
                            name="txtfirstname"
                            defaultValue={selCandidate.first_name}
                            isInvalid={!!errorsBasic.txtfirstname}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errorsBasic.txtfirstname}
                          </Form.Control.Feedback>
                        </>
                      ) : (
                        <div className="text-col">
                          {selCandidate.first_name}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Last Name{" "}
                      {editBasic ? (
                        <label className="validation-star">*</label>
                      ) : null}
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <>
                          <Form.Control
                            type="text"
                            name="txtlastname"
                            defaultValue={selCandidate.last_name}
                            isInvalid={!!errorsBasic.txtlastname}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errorsBasic.txtlastname}
                          </Form.Control.Feedback>
                        </>
                      ) : (
                        <div className="text-col">{selCandidate.last_name}</div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              {/* 4 */}
              <Row>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Date of Birth
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <Form.Control
                          type="date"
                          name="txtdob"
                          defaultValue={selDob}
                        />
                      ) : (
                        <div className="text-col">
                          {selCandidate.dob ? showDob : "-"}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Gender
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <Form.Select
                          name="ddlgender"
                          defaultValue={selCandidate.gender}
                        >
                          <option value="">Select</option>
                          {dataGender.map((gndr, index) => (
                            <option key={index} value={gndr.code}>
                              {gndr.value}
                            </option>
                          ))}
                        </Form.Select>
                      ) : (
                        <div className="text-col">
                          {selCandidate.gender ? selCandidate.gender : "-"}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              {/* 5 */}
              <Row>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Mobile Number{" "}
                      {/* {editBasic ? (
                        <label className="validation-star">*</label>
                      ) : null} */}
                    </Form.Label>
                    {editBasic ? (
                      <Col>
                        <Form.Control
                          name="txtphone"
                          as="textarea"
                          defaultValue={selCandidate.mobile_no}
                          rows={3}
                        />
                        {/* <Form.Control.Feedback type="invalid">
                          {errorsBasic.txtcountrycode}
                        </Form.Control.Feedback> */}
                      </Col>
                    ) : (
                      <Col>
                        <div className="text-col">
                          <Form.Control
                            name="txtphone"
                            className="read-num"
                            as="textarea"
                            defaultValue={selCandidate.mobile_no}
                            rows={3}
                            readOnly
                          />
                        </div>
                      </Col>
                    )}
                    {/* {editBasic ? (
                      <Col>
                        <Form.Control
                          name="txtphone"
                          type="number"
                          defaultValue={selCandidate.mobile_no}
                          isInvalid={!!errorsBasic.txtphone}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorsBasic.txtphone}
                        </Form.Control.Feedback>
                      </Col>
                    ) : null} */}
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Languages{" "}
                      {/* {editBasic ? (
                        <label className="validation-star">*</label>
                      ) : null} */}
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <Searchable
                          isMulti={true}
                          getOptionValue={(option) => option.value}
                          getOptionLabel={(option) => option.value}
                          options={dataLanguages}
                          defaultValue={dataLanguages.filter((obj) =>
                            selCandidate.languages.includes(obj.value)
                          )}
                          onChange={handleLanguages}
                          // errorText={errorsBasic.ddllang}
                          // isInvalid={!!errorsBasic.ddllang}
                        ></Searchable>
                      ) : (
                        <div className="text-col">
                          {showLang.replaceAll(",", ", ")}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              {/* 6 */}
              <Row>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Personal Email Address
                      {/* {editBasic ? (
                        <label className="validation-star">*</label>
                      ) : null} */}
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <>
                          <Form.Control
                            name="txtemail"
                            type="email"
                            defaultValue={selCandidate.email}
                            // isInvalid={!!errorsBasic.txtemail}
                          />
                          {/* <Form.Control.Feedback type="invalid">
                            {errorsBasic.txtemail}
                          </Form.Control.Feedback> */}
                        </>
                      ) : (
                        <div
                          className="text-col"
                          style={{ textTransform: "none" }}
                        >
                          {selCandidate.email ? selCandidate.email : "-"}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Company Email Address
                      {/* {editBasic ? (
                        <label className="validation-star">*</label>
                      ) : null} */}
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <>
                          <Form.Control
                            name="txtcompemail"
                            type="email"
                            defaultValue={selCandidate.comp_email}
                            // isInvalid={!!errorsBasic.txtemail}
                          />
                          {/* <Form.Control.Feedback type="invalid">
                            {errorsBasic.txtemail}
                          </Form.Control.Feedback> */}
                        </>
                      ) : (
                        <div
                          className="text-col"
                          style={{ textTransform: "none" }}
                        >
                          {selCandidate.comp_email
                            ? selCandidate.comp_email
                            : "-"}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              {/* 7 */}
              <Row>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Permanent Address
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <Form.Control
                          name="txtpermanentaddr"
                          as="textarea"
                          defaultValue={selCandidate.permanent_addr}
                          rows={3}
                        />
                      ) : (
                        <div className="text-col">
                          {selCandidate.permanent_addr
                            ? selCandidate.permanent_addr
                            : "-"}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Posting Address
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <Form.Control
                          name="txtpostingaddr"
                          as="textarea"
                          defaultValue={selCandidate.posting_addr}
                          rows={3}
                        />
                      ) : (
                        <div className="text-col">
                          {selCandidate.posting_addr
                            ? selCandidate.posting_addr
                            : "-"}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              {/* 8*/}
              <Row>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Citizenship (Main)
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <Form.Select
                          name="ddlcitimain"
                          defaultValue={selCandidate.citizenship_main}
                        >
                          <option value="">Select</option>
                          {dataCitizenship.map((ctzn, index) => (
                            <option key={index} value={ctzn.value}>
                              {ctzn.value}
                            </option>
                          ))}
                        </Form.Select>
                      ) : (
                        <div className="text-col">
                          {selCandidate.citizenship_main
                            ? selCandidate.citizenship_main
                            : "-"}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Citizenship (Others)
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <Form.Select
                          name="ddlcitioth"
                          defaultValue={selCandidate.citizenship_other}
                        >
                          <option value="">Select</option>
                          {dataCitizenship.map((ctzn, index) => (
                            <option key={index} value={ctzn.value}>
                              {ctzn.value}
                            </option>
                          ))}
                        </Form.Select>
                      ) : (
                        <div className="text-col">
                          {selCandidate.citizenship_other
                            ? selCandidate.citizenship_other
                            : "-"}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              {/* 9 */}
              <Row>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Marital Status
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <Form.Select
                          name="ddlmartialsta"
                          defaultValue={selCandidate.marital_status}
                        >
                          <option value="">Select</option>
                          {dataMarital.map((mari, index) => (
                            <option key={index} value={mari.code}>
                              {mari.value}
                            </option>
                          ))}
                        </Form.Select>
                      ) : (
                        <div className="text-col">
                          {selCandidate.marital_status
                            ? selCandidate.marital_status
                            : "-"}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Number of Children
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <Form.Control
                          name="txtnochild"
                          type="number"
                          defaultValue={selCandidate.no_of_child}
                          // isInvalid={ !!errorsBasic.txtfirstname }
                          // onChange={ e => setField('txtfirstname', e.target.value) }
                        />
                      ) : (
                        <div className="text-col">
                          {selCandidate.no_of_child
                            ? selCandidate.no_of_child
                            : "-"}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              {/* 10 */}
              <Row>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Interest/Hobbies
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <Form.Control
                          name="txthobbie"
                          type="text"
                          defaultValue={showHobby}
                        />
                      ) : (
                        <div className="text-col">{showHobby}</div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Expected Salary
                    </Form.Label>

                    {editBasic ? (
                      <Col>
                        <Form.Select
                          defaultValue={selCandidate.expected_salary_curr}
                          name="ddlcurrency"
                        >
                          <option value="">Select</option>
                          {dataCurrency.map((currency, index) => (
                            <option key={index} value={currency.code}>
                              {currency.value}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    ) : (
                      <Col>
                        <div className="text-col">
                          {selCandidate.expected_salary_curr
                            ? selCandidate.expected_salary_curr
                            : ""}{" "}
                          {selCandidate.expected_salary ? showSalary : "-"}
                        </div>
                      </Col>
                    )}
                    {editBasic ? (
                      <Col>
                        <TextFieldFormat
                          name="txtsalary"
                          value={
                            selCandidate.expected_salary
                              ? selCandidate.expected_salary
                              : 0
                          }
                          thousandSeparator={true}
                          // onChange={handleExpectedSalary}
                        ></TextFieldFormat>
                      </Col>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>
              {/* 11 */}
              <Row>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Willing to relocate
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <div className="mb-1">
                          <Form.Check
                            inline
                            label="Yes"
                            name="grpRelocate"
                            type="radio"
                            value={true}
                            defaultChecked={
                              selCandidate.willing_relocate === true
                                ? "Yes"
                                : ""
                            }
                            onChange={() => setRbRelocate(true)}
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="grpRelocate"
                            type="radio"
                            value={false}
                            defaultChecked={
                              selCandidate.willing_relocate === false
                                ? "No"
                                : ""
                            }
                            onChange={() => setRbRelocate(false)}
                          />
                        </div>
                      ) : (
                        <div className="text-col">
                          {selCandidate.willing_relocate === false
                            ? "No"
                            : "Yes"}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Willing to travel
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <div className="mb-1">
                          <Form.Check
                            inline
                            label="Yes"
                            name="grpTravel"
                            type="radio"
                            // defaultChecked={selCandidate.travel === "Yes"}
                            // defaultValue={selectedCandidate.relocate}
                            // onClick={() => setRbRelocate('Yes')}
                            value={true}
                            defaultChecked={
                              selCandidate.willing_travel === true ? "Yes" : ""
                            }
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="grpTravel"
                            type="radio"
                            // defaultChecked={selCandidate.travel === "No"}
                            // onClick={() => setRbRelocate('No')}
                            value={false}
                            defaultChecked={
                              selCandidate.willing_travel === false ? "No" : ""
                            }
                          />
                        </div>
                      ) : (
                        <div className="text-col">
                          {selCandidate.willing_travel === false ? "No" : "Yes"}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              {/* 12 */}
              {rbRelocate === true ? (
                <Row>
                  <Col xs={6}>
                    <Form.Group as={Row} className="mb-1">
                      <Form.Label column lg={4}>
                        Preferred Locations
                      </Form.Label>
                      <Col>
                        {editBasic ? (
                          <Searchable
                            isMulti={true}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                            options={dataCountry}
                            defaultValue={dataCountry.filter((obj) =>
                              selCandidate.prefer_location.includes(obj.value)
                            )}
                            onChange={handlePreferLoc}
                          ></Searchable>
                        ) : (
                          <div className="text-col">
                            {showPreLoc.replaceAll(",", ", ")}
                          </div>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col xs={6}></Col>
                </Row>
              ) : null}
              {/* 13 */}
              <Row>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      LinkedIn Profile
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <Form.Control
                          name="txtlinked"
                          type="text"
                          placeholder="http://"
                          defaultValue={selCandidate.linkedln_url}
                        />
                      ) : (
                        <div
                          className="text-col"
                          style={{ textTransform: "none" }}
                        >
                          {selCandidate.linkedln_url ? (
                            <a
                              href={selCandidate.linkedln_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {selCandidate.linkedln_url}
                            </a>
                          ) : (
                            "-"
                          )}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Instagram Profile
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <Form.Control
                          name="txtinsta"
                          type="text"
                          placeholder="http://"
                          defaultValue={selCandidate.instagram_url}
                        />
                      ) : (
                        <div
                          className="text-col"
                          style={{ textTransform: "none" }}
                        >
                          {selCandidate.instagram_url ? (
                            <a
                              href={selCandidate.instagram_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {selCandidate.instagram_url}
                            </a>
                          ) : (
                            "-"
                          )}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              {/* 14 */}
              <Row>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Facebook Profile
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <Form.Control
                          name="txtfacebook"
                          type="text"
                          defaultValue={selCandidate.facebook_url}
                          placeholder="http://"
                        />
                      ) : (
                        <div
                          className="text-col"
                          style={{ textTransform: "none" }}
                        >
                          {selCandidate.facebook_url ? (
                            <a
                              href={selCandidate.facebook_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {selCandidate.facebook_url}
                            </a>
                          ) : (
                            "-"
                          )}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column lg={4}>
                      Others Website
                    </Form.Label>
                    <Col>
                      {editBasic ? (
                        <Form.Control
                          name="txtothweb"
                          type="text"
                          placeholder="http://"
                          defaultValue={selCandidate.website_url}
                        />
                      ) : (
                        <div
                          className="text-col"
                          style={{ textTransform: "none" }}
                        >
                          {selCandidate.website_url ? (
                            <a
                              href={selCandidate.website_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {selCandidate.website_url}
                            </a>
                          ) : (
                            "-"
                          )}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>

          {editBasic === false ? null : (
            <div className="container-form-div">
              <div className="row-btn-center">
                <Button
                  onClick={() => btnCancelBasic(false)}
                  variant="secondary"
                >
                  Cancel
                </Button>
                <Button form="basic-form" type="submit" variant="primary">
                  Save
                </Button>
              </div>
            </div>
          )}
          {/* End Basic Info Candidate */}
        </div>

        {/* Education Candidate */}
        <div hidden={!abilities.includes("view education")}>
          <div className="tab-header-div">
            <div className="left-div">
              <div className="tab-header-font">Educations</div>
            </div>
            <div className="right-div">
              {editMode === false ? (
                <Button
                  onClick={() => btnEditMode(true, "second")}
                  variant="link"
                  className="modal-link"
                  hidden={!abilities.includes("edit candidate")}
                >
                  <Icon path={mdiPencil} />
                  <div>Edit Education</div>
                </Button>
              ) : null}
            </div>
          </div>

          <div className="content-div create-st">
            {editEdu === false ? null : (
              <div className="left-div" style={{ margin: "20px 0px" }}>
                <Button
                  onClick={btnAddEdu}
                  variant="link"
                  className="modal-link"
                >
                  <Icon path={mdiPlusCircle} />
                  Add Education
                </Button>
              </div>
            )}

            <div className="column-card">
              {showEducation.length > 0
                ? selCandidate.candidate_education.map((education, index) => (
                    <CardCandidate key={index}>
                      <div className="card-education__row-flex5">
                        <div className="sub-text">{education.name}</div>
                        <div className="sub-text">
                          {education.qualification}
                        </div>
                        <div className="sub-text" style={{ fontWeight: 400 }}>
                          {education.start_year} - {education.end_year}
                        </div>
                      </div>
                      {editEdu === true ? (
                        <div>
                          <div>
                            {education.certificate_attachment ? (
                              <Button
                                onClick={btnDownload(
                                  education.id,
                                  education.certificate_attachment
                                )}
                                variant="link"
                                className="no-underline"
                              >
                                <Icon path={mdiFile} />
                                Show Certificate
                              </Button>
                            ) : (
                              <div className="mini-text">No Certificate</div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="card-education__row-flex1"></div>
                      )}

                      {editEdu === true ? (
                        <div className="card-education__row-flex1">
                          <div className="card-edu-btn">
                            <Button
                              onClick={btnEditEdu(education.id)}
                              variant="link"
                              className="modal-link"
                            >
                              <Icon path={mdiPencil} />
                            </Button>
                            <Button
                              onClick={btnDelEdu(education.id)}
                              variant="link"
                              className="delete-link"
                            >
                              <Icon path={mdiDelete} />
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div>
                            {education.certificate_attachment ? (
                              <Button
                                onClick={btnDownload(
                                  education.id,
                                  education.certificate_attachment
                                )}
                                variant="link"
                                className="no-underline"
                              >
                                <Icon path={mdiFile} />
                                Show Certificate
                              </Button>
                            ) : (
                              <div className="mini-text">No Certificate</div>
                            )}
                          </div>
                        </div>
                      )}
                    </CardCandidate>
                  ))
                : "-"}
              {/* {} */}
            </div>
          </div>

          {editEdu === false ? null : (
            <div className="container-form-div">
              <div className="row-btn-center">
                <Button onClick={() => btnCancelEdu(false)} variant="secondary">
                  Close
                </Button>
              </div>
            </div>
          )}

          {/* Modal Education */}
          <Popup
            show={showEdu}
            size="lg"
            backdrop="static"
            keyboard={false}
            onHide={btnCloseEdu}
            title={modalEdu.title}
            content={
              <>
                <Form id="education-form" onSubmit={btnSaveUpdateEdu}>
                  {/* 1 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>
                      School <label className="validation-star">*</label>
                    </Form.Label>
                    <Col>
                      <Form.Control
                        name="txtschool"
                        type="text"
                        defaultValue={eduState.txtschool}
                        isInvalid={!!errorsEdu.txtschool}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorsEdu.txtschool}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  {/* 2 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>
                      Degree
                      {/* <label className="validation-star">*</label> */}
                    </Form.Label>
                    <Col>
                      <Form.Control
                        name="txtdegree"
                        type="text"
                        defaultValue={eduState.txtdegree}
                        // isInvalid={!!errorsEdu.txtdegree}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                      {errorsEdu.txtdegree}
                    </Form.Control.Feedback> */}
                    </Col>
                  </Form.Group>
                  {/* 3 */}
                  <Row>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label>
                          Start year
                          {/* <label className="validation-star">*</label> */}
                        </Form.Label>
                        <Col>
                          <Form.Select
                            name="txtfromyear"
                            defaultValue={eduState.txtfromyear}
                            // isInvalid={!!errorsExp.ddlpreyear}
                          >
                            <option value="">Select</option>
                            {dataYear.map((year, index) => (
                              <option key={index} value={year.value}>
                                {year.label}
                              </option>
                            ))}
                          </Form.Select>
                          {/* <Form.Control.Feedback type="invalid">
                          {errorsEdu.txtfromyear}
                        </Form.Control.Feedback> */}
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label>
                          End year
                          {/* <label className="validation-star">*</label> */}
                        </Form.Label>
                        <Col>
                          <Form.Select
                            name="txttoyear"
                            defaultValue={eduState.txttoyear}
                            // isInvalid={!!errorsExp.ddlpreyear}
                          >
                            <option value="">Select</option>
                            {dataYear.map((year, index) => (
                              <option key={index} value={year.value}>
                                {year.label}
                              </option>
                            ))}
                          </Form.Select>
                          {/* <Form.Control.Feedback type="invalid">
                          {errorsEdu.txttoyear}
                        </Form.Control.Feedback> */}
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* 4 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Certificates</Form.Label>
                    <Col>
                      <Form.Control type="file" name="txtcertificate" />
                    </Col>
                  </Form.Group>
                </Form>
              </>
            }
            button={
              <>
                <Button onClick={btnCloseEdu} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit" variant="primary" form="education-form">
                  {modalEdu.button}
                </Button>
              </>
            }
          />
        </div>
        {/* End Education Candidate */}

        {/* Experience Candidate */}
        <div hidden={!abilities.includes("view experience")}>
          <div className="tab-header-div">
            <div className="left-div">
              <div className="tab-header-font">Experiences</div>
            </div>
            <div className="right-div">
              {editMode === false ? (
                <Button
                  onClick={() => btnEditMode(true, "third")}
                  variant="link"
                  className="modal-link"
                  hidden={!abilities.includes("edit candidate")}
                >
                  <Icon path={mdiPencil} />
                  <div>Edit Experiences</div>
                </Button>
              ) : null}
            </div>
          </div>

          <div className="content-div create-st">
            {editExp === false ? null : (
              <div className="left-div" style={{ margin: "10px 0 0" }}>
                <Button
                  onClick={btnAddExp}
                  variant="link"
                  className="modal-link"
                >
                  <Icon path={mdiPlusCircle} />
                  Add Experience
                </Button>
              </div>
            )}
            <div className="column-card">
              {showExperience.length > 0
                ? selCandidate.candidate_experiences.map(
                    (experience, index) => {
                      // console.log("exp", experience)
                      return (
                        <div key={index} className="cand-exp-loop">
                          <div className="cdt-row">
                            <div className="col-5">
                              <div className="big-text">{experience.title}</div>
                              <div className="sub-text">
                                <Button variant="link" onClick={btnExpCompany(experience.company_name)} className="modal-link">
                                  {experience.company_name}
                                </Button>
                              </div>
                              {experience.current_working ? (
                                <div
                                  className="sub-text"
                                  style={{ fontWeight: 400 }}
                                >
                                  {experience.present_month
                                    ? toMonthName(experience.present_month)
                                    : ""}{" "}
                                  {experience.present_year
                                    ? experience.present_year
                                    : ""}{" "}
                                  - Current
                                </div>
                              ) : (
                                <div
                                  className="sub-text"
                                  style={{ fontWeight: 400 }}
                                >
                                  {experience.start_date_month
                                    ? toMonthName(experience.start_date_month)
                                    : ""}{" "}
                                  {experience.start_date_year
                                    ? experience.start_date_year
                                    : ""}{" "}
                                  -{" "}
                                  {experience.end_date_month
                                    ? toMonthName(experience.end_date_month)
                                    : ""}{" "}
                                  {experience.end_date_year
                                    ? experience.end_date_year
                                    : ""}
                                </div>
                              )}
                            </div>
                            <div className="col-6" style={{ display: "flex" }}>
                              <div className="col-11">
                                <div
                                  className="more-section-row"
                                  hidden={!abilities.includes("view salary")}
                                >
                                  <div className="mini-text flex-left">
                                    Salary
                                  </div>
                                  <div className="sub-text flex-right">
                                    {experience.currency}{" "}
                                    {Number(experience.salary)
                                      .toFixed(2)
                                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                  </div>
                                </div>
                                <div className="more-section-row">
                                  <div className="mini-text flex-left">
                                    Direct Line
                                  </div>
                                  <div className="sub-text flex-right">
                                    {experience.office_number
                                      ? experience.office_number
                                      : "-"}
                                  </div>
                                </div>
                                <div className="more-section-row">
                                  <div className="mini-text flex-left">
                                    Office Email
                                  </div>
                                  <div className="sub-text flex-right">
                                    {experience.office_email
                                      ? experience.office_email
                                      : "-"}
                                  </div>
                                </div>

                                {experience.current_working ? (
                                  <div className="more-section-row">
                                    <div className="mini-text flex-left">
                                      Notice Period
                                    </div>
                                    <div className="sub-text flex-right">
                                      {experience.notice_period
                                        ? experience.notice_period
                                        : "-"}
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-1">
                                {editExp === false ? null : (
                                  <div className="exp-button">
                                    <Button
                                      variant="link"
                                      className="modal-link"
                                      onClick={btnEditExp(experience.id)}
                                    >
                                      <Icon path={mdiPencil} />
                                    </Button>
                                    <Button
                                      variant="link"
                                      className="delete-link"
                                      style={{ margin: 0 }}
                                      onClick={btnDelExp(experience.id)}
                                    >
                                      <Icon path={mdiDelete} />
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* Display if Read More button is clicked */}
                          {isReadMore.more === false &&
                          isReadMore.id === index ? (
                            <div className="cdt-row">
                              <div className="col-5">
                                {experience.reason_leaving ? (
                                  <>
                                    <div className="text">
                                      Reason for leaving
                                    </div>
                                    <div
                                      className="mini-text"
                                      dangerouslySetInnerHTML={{
                                        __html: experience.reason_leaving,
                                      }}
                                    ></div>
                                  </>
                                ) : null}

                                {experience.job_responsibility ? (
                                  <>
                                    <div className="text">
                                      Job Responsiblity
                                    </div>
                                    <div
                                      className="mini-text"
                                      dangerouslySetInnerHTML={{
                                        __html: experience.job_responsibility,
                                      }}
                                    ></div>
                                  </>
                                ) : null}
                              </div>
                              <div className="col-6">
                                <div className="col-11">
                                  {experience.candidate_exp_allowance.length >
                                  0 ? (
                                    <div className="more-section-row">
                                      <div className="mini-text flex-left">
                                        Allowance
                                      </div>
                                      <div style={{ flex: "1 1 60%" }}>
                                        {experience.candidate_exp_allowance.map(
                                          (allowance, index) => (
                                            <div
                                              className="sub-text flex-right"
                                              key={index}
                                            >
                                              {toAllowanceDesc(
                                                allowance.earning
                                              )}{" "}
                                              - {allowance.currency}{" "}
                                              {Number(allowance.amount)
                                                .toFixed(2)
                                                .replace(
                                                  /\d(?=(\d{3})+\.)/g,
                                                  "$&,"
                                                )}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  ) : null}

                                  {experience.other_benefits ? (
                                    <div className="more-section-row">
                                      <div className="mini-text flex-left">
                                        Other Benefits
                                      </div>
                                      <div
                                        className="sub-text flex-right"
                                        dangerouslySetInnerHTML={{
                                          __html: experience.other_benefits
                                            ? experience.other_benefits
                                            : "-",
                                        }}
                                      ></div>
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-1"></div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {experience.other_benefits ||
                          experience.job_responsibility ||
                          experience.reason_leaving ||
                          experience.candidate_exp_allowance.length > 0 ? (
                            <div className="cdt-row">
                              <div className="col-6"></div>
                              <div className="col-6">
                                <div className="col-11">
                                  <div className="more-section-row">
                                    <div className="mini-text flex-left">
                                      {""}
                                    </div>
                                    <div className="sub-text flex-right">
                                      {isReadMore.more === false &&
                                      isReadMore.id === index ? (
                                        <Button
                                          variant="link"
                                          className="read-btn"
                                          onClick={toggleBtnReadMore(
                                            index,
                                            true
                                          )}
                                        >
                                          Less details
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="link"
                                          className="read-btn"
                                          onClick={toggleBtnReadMore(
                                            index,
                                            false
                                          )}
                                        >
                                          More details
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-1"></div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      );
                    }
                  )
                : "-"}
            </div>
          </div>

          {editExp === false ? null : (
            <div className="container-form-div">
              <div className="row-btn-center">
                <Button onClick={() => btnCancelExp(false)} variant="secondary">
                  Close
                </Button>
              </div>
            </div>
          )}

          {/* Modal Experience */}
          <Popup
            show={showExp}
            size="lg"
            backdrop="static"
            keyboard={false}
            onHide={btnCloseExp}
            title={modalExp.title}
            content={
              <>
                <Form id="experience-form" onSubmit={btnSaveUpdateExp}>
                  {/* 1 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>
                      Job Title <label className="validation-star">*</label>
                    </Form.Label>
                    <Col>
                      <Form.Control
                        name="txtjobtitle"
                        type="text"
                        defaultValue={expState.txtjobtitle}
                        isInvalid={!!errorsExp.txtjobtitle}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorsExp.txtjobtitle}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  {/* 2 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Job Function</Form.Label>
                    <Col>
                      <Searchable
                        name="ddljobfunction"
                        isMulti={true}
                        options={dataJobFunction}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.value}
                        onChange={handleJobFunc}
                        defaultValue={dataJobFunction.filter((obj) =>
                          expState.ddljobfunction.includes(obj.value)
                        )}
                      ></Searchable>
                    </Col>
                  </Form.Group>
                  {/* 3 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>
                      Company Name <label className="validation-star">*</label>
                    </Form.Label>
                    <Col>
                      {/* <Form.Control
                        name="txtcompanyname"
                        type="text"
                        defaultValue={expState.txtcompanyname}
                        isInvalid={!!errorsExp.txtcompanyname}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorsExp.txtcompanyname}
                      </Form.Control.Feedback> */}
                      <Creatable
                        onChange={handleCompanyName}
                        options={clientList}
                        value={companyValue}
                        isInvalid={!!errorsExp.txtcompanyname}
                        errorText={errorsExp.txtcompanyname}
                      />
                    </Col>
                  </Form.Group>
                  {/* 4 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Industry</Form.Label>
                    <Col>
                      <Searchable
                        name="ddlIndustry"
                        isMulti={true}
                        options={dataIndustry}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.value}
                        onChange={handleIndustry}
                        defaultValue={dataIndustry.filter((obj) =>
                          expState.ddlIndustry.includes(obj.value)
                        )}
                      ></Searchable>
                    </Col>
                  </Form.Group>
                  {/* 5 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>
                      Country <label className="validation-star">*</label>
                    </Form.Label>
                    <Col>
                      <Searchable
                        name="ddlCountry"
                        isMulti={false}
                        options={dataCountry}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.value}
                        isInvalid={!!errorsExp.ddlCountry}
                        errorText={errorsExp.ddlCountry}
                        defaultValue={expState.ddlCountry}
                      ></Searchable>
                    </Col>
                  </Form.Group>
                  {/* 6 */}
                  {checked === true ? (
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label>Present</Form.Label>
                      <Col>
                        <Form.Select
                          name="ddlpremonth"
                          // onChange={handlePreMonth}
                          defaultValue={expState.ddlpremonth}
                          // isInvalid={!!errorsExp.ddlpremonth}
                        >
                          <option value="">Select</option>
                          {monthList.map((month, index) => (
                            <option key={index} value={month.value}>
                              {month.label}
                            </option>
                          ))}
                        </Form.Select>
                        {/* <Form.Control.Feedback type="invalid">
                        {errorsExp.ddlpremonth}
                      </Form.Control.Feedback> */}
                      </Col>
                      <Col>
                        <Form.Select
                          name="ddlpreyear"
                          defaultValue={expState.ddlpreyear}
                          // isInvalid={!!errorsExp.ddlpreyear}
                        >
                          <option value="">Select</option>
                          {dataYear.map((year, index) => (
                            <option key={index} value={year.value}>
                              {year.label}
                            </option>
                          ))}
                        </Form.Select>
                        {/* <Form.Control.Feedback type="invalid">
                        {errorsExp.ddlpreyear}
                      </Form.Control.Feedback> */}
                      </Col>
                    </Form.Group>
                  ) : (
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label>
                        Start Date
                        {/* <label className="validation-star">*</label> */}
                      </Form.Label>
                      <Col>
                        <Form.Select
                          name="ddlstartmonth"
                          // onChange={handleStartMonth}
                          defaultValue={expState.ddlstartmonth}
                          // isInvalid={!!errorsExp.ddlstartmonth}
                        >
                          <option value="">Select</option>
                          {monthList.map((month, index) => (
                            <option key={index} value={month.value}>
                              {month.label}
                            </option>
                          ))}
                        </Form.Select>
                        {/* <Form.Control.Feedback type="invalid">
                        {errorsExp.ddlstartmonth}
                      </Form.Control.Feedback> */}
                      </Col>
                      <Col>
                        <Form.Select
                          name="ddlstartyear"
                          defaultValue={expState.ddlstartyear}
                          // isInvalid={!!errorsExp.ddlstartyear}
                        >
                          <option value="">Select</option>
                          {dataYear.map((year, index) => (
                            <option key={index} value={year.value}>
                              {year.label}
                            </option>
                          ))}
                        </Form.Select>
                        {/* <Form.Control.Feedback type="invalid">
                        {errorsExp.ddlstartyear}
                      </Form.Control.Feedback> */}
                      </Col>
                    </Form.Group>
                  )}

                  {/* 7 */}
                  {checked === true ? (
                    ""
                  ) : (
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label>End Date</Form.Label>
                      <Col>
                        <Form.Select
                          name="ddlendmonth"
                          // onChange={handleEndMonth}
                          defaultValue={expState.ddlendmonth}
                          // isInvalid={!!errorsExp.ddlendmonth}
                        >
                          <option value="">Select</option>
                          {monthList.map((month, index) => (
                            <option key={index} value={month.value}>
                              {month.label}
                            </option>
                          ))}
                        </Form.Select>
                        {/* <Form.Control.Feedback type="invalid">
                        {errorsExp.ddlendmonth}
                      </Form.Control.Feedback> */}
                      </Col>
                      <Col>
                        <Form.Select
                          name="ddlendyear"
                          defaultValue={expState.ddlendyear}
                          // isInvalid={!!errorsExp.ddlendyear}
                        >
                          <option value="">Select</option>
                          {dataYear.map((year, index) => (
                            <option key={index} value={year.value}>
                              {year.label}
                            </option>
                          ))}
                        </Form.Select>
                        {/* <Form.Control.Feedback type="invalid">
                        {errorsExp.ddlendyear}
                      </Form.Control.Feedback> */}
                      </Col>
                    </Form.Group>
                  )}

                  {/* 8 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label></Form.Label>
                    <Col>
                      <Form.Check
                        type="checkbox"
                        id="default-checkbox"
                        label="Currently Working"
                        defaultChecked={expState.cbWorkingCurrent}
                        onChange={(e) => setChecked(e.currentTarget.checked)}
                      />
                    </Col>
                  </Form.Group>
                  {/* 9 */}
                  <Row>
                    <Form.Group className="mb-3 col-6">
                      <Form.Label>Office Number</Form.Label>
                      <Col>
                        <Form.Control
                          name="txtofficephone"
                          type="number"
                          min="0"
                          defaultValue={expState.txtofficephone}
                        />
                      </Col>
                    </Form.Group>
                    {/* {checked === true ? ( */}
                    <Form.Group className="mb-3 col-6">
                      <Form.Label>Office Email</Form.Label>
                      <Col>
                        <Form.Control
                          name="txtofficeemail"
                          type="text"
                          defaultValue={expState.txtofficeemail}
                          // isInvalid={!!errorsExp.txtofficeemail}
                        ></Form.Control>
                        {/* <Form.Control.Feedback type="invalid">
                          {errorsExp.txtofficeemail}
                        </Form.Control.Feedback> */}
                      </Col>
                    </Form.Group>
                    {/*) : null} */}
                  </Row>
                  {/* 10 */}
                  {checked === true ? (
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label>Notice Period</Form.Label>
                      <Col>
                        <Form.Select
                          name="ddlperiod"
                          defaultValue={expState.ddlperiod}
                        >
                          <option value="">Select</option>
                          {noticeList.map((notice, index) => (
                            <option key={index} value={notice.label}>
                              {notice.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  ) : null}

                  {/* 11 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Job Responsibility</Form.Label>
                    <Col>
                      <RichTextEditor
                        name="txtjobresponsibility"
                        htmlContent={expState.txtjobresponsibility}
                      />
                    </Col>
                  </Form.Group>
                  {/* 12 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Salary</Form.Label>
                    <Col>
                      <Form.Select
                        name="ddlcurrency"
                        defaultValue={expState.ddlcurrency}
                      >
                        <option value="">Select</option>
                        {dataCurrency.map((currency, index) => (
                          <option key={index} value={currency.value}>
                            {currency.value}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col>
                      <TextFieldFormat
                        name="txtsalary"
                        value={salaryTxt.current}
                        onChange={handleSalary}
                        thousandSeparator={true}
                      ></TextFieldFormat>
                      {/* <Form.Control.Feedback type="invalid">
                      {errorsExp.txtsalary}
                    </Form.Control.Feedback> */}
                    </Col>
                  </Form.Group>
                  {/* 13 */}
                  <Form.Group className="mb-3">
                    <Form.Label column lg={2}>
                      Allowance
                    </Form.Label>
                    <Col>
                      <CustomTable
                        headers={headers}
                        items={allowanceList}
                      ></CustomTable>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3">
                    <Col>
                      <Button onClick={btnAddNewAllowance} variant="primary">
                        Add New Allowance
                      </Button>
                    </Col>
                  </Form.Group>
                  {/* 14 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Other Benefits</Form.Label>
                    <Col>
                      <RichTextEditor
                        name="txtothbenefit"
                        htmlContent={expState.txtothbenefit}
                      />
                    </Col>
                  </Form.Group>
                  {/* 15 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Reason for leaving</Form.Label>
                    <Col>
                      <RichTextEditor
                        name="txtreason"
                        htmlContent={expState.txtreason}
                      />
                    </Col>
                  </Form.Group>
                </Form>
              </>
            }
            button={
              <>
                <Button onClick={btnCloseExp} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit" variant="primary" form="experience-form">
                  {modalExp.button}
                </Button>
              </>
            }
          />

          {/* Modal Allowance */}
          <Popup
            show={showOption}
            size="md"
            backdrop="static"
            keyboard={false}
            onHide={btnCloseOption}
            title="Add Allowance"
            content={
              <>
                <Form id="option-form" onSubmit={btnNewOption}>
                  {/* 1 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Allowance Description</Form.Label>
                    <Col>
                      <Form.Control
                        name="txtAllowDesc"
                        type="text"
                        // defaultValue={expState.txtjobtitle}
                        isInvalid={!!errorOpt.txtAllowDesc}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorOpt.txtAllowDesc}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Form>
              </>
            }
            button={
              <>
                <Button onClick={btnCloseOption} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit" variant="primary" form="option-form">
                  Save
                </Button>
              </>
            }
          />
        </div>
        {/* End Experience Candidate */}

        {/* Reference Candidate */}
        <div hidden={!abilities.includes("view reference")}>
          <div className="tab-header-div">
            <div className="left-div">
              <div className="tab-header-font">References</div>
            </div>
            <div className="right-div">
              {editMode === false ? (
                <Button
                  variant="link"
                  className="modal-link"
                  onClick={() => btnEditMode(true, "fourth")}
                  hidden={!abilities.includes("edit candidate")}
                >
                  <Icon path={mdiPencil} />
                  <div>Edit References</div>
                </Button>
              ) : null}
            </div>
          </div>

          <div className="content-div create-st">
            {editRef === false ? null : (
              <div className="left-div" style={{ margin: "20px 0px" }}>
                <Button
                  onClick={btnAddRef}
                  variant="link"
                  className="modal-link"
                >
                  <Icon path={mdiPlusCircle} />
                  Add Reference
                </Button>
              </div>
            )}

            <div className="column-card">
              {showReferee.length > 0
                ? selCandidate.candidate_reference.map((referee, index) => (
                    <>
                      <Card style={{ display: "inline" }} key={referee.id}>
                        <div className="card-ref-box col-4">
                          <div className="card-referee">
                            <div className="big-text">Referee {index + 1}</div>
                            {editRef === false ? null : (
                              <div style={{ display: "flex" }}>
                                <Button
                                  onClick={btnEditRef(referee.id)}
                                  variant="link"
                                  className="modal-link"
                                >
                                  <Icon path={mdiPencil} />
                                </Button>
                                <Button
                                  onClick={btnDelRef(referee.id)}
                                  variant="link"
                                  className="delete-link"
                                  style={{ marginLeft: 0 }}
                                >
                                  <Icon path={mdiDelete} />
                                </Button>
                              </div>
                            )}
                          </div>
                          <div className="card-text-style">{referee.name}</div>
                          <div className="card-text-style">
                            {referee.job_title}, {referee.current_company}
                          </div>
                          <div className="card-text-style">{referee.email}</div>
                          <div className="card-text-style">{referee.phone}</div>
                        </div>
                      </Card>
                    </>
                  ))
                : "-"}
            </div>
          </div>

          {editRef === false ? null : (
            <div className="container-form-div">
              <div className="row-btn-center">
                <Button onClick={() => btnCancelRef(false)} variant="secondary">
                  Close
                </Button>
              </div>
            </div>
          )}

          {/* Modal Referee */}
          <Popup
            show={showRef}
            size="lg"
            backdrop="static"
            keyboard={false}
            onHide={btnCloseReferee}
            title={modalRef.title}
            content={
              <>
                <Form id="referee-form" onSubmit={btnSaveUpdateRef}>
                  {/* 1 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>
                      Referee Name <label className="validation-star">*</label>
                    </Form.Label>
                    <Col>
                      <Form.Control
                        name="txtrefname"
                        type="text"
                        isInvalid={!!errorsRef.txtrefname}
                        defaultValue={refState.txtrefname}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorsRef.txtrefname}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  {/* 2 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Referee Job Title</Form.Label>
                    <Col>
                      <Form.Control
                        name="txtrefjobtitle"
                        type="text"
                        defaultValue={refState.txtrefjobtitle}
                      />
                    </Col>
                  </Form.Group>
                  {/* 3 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Email Address</Form.Label>
                    <Col>
                      <Form.Control
                        name="txtrefemail"
                        type="text"
                        defaultValue={refState.txtrefemail}
                      />
                    </Col>
                  </Form.Group>
                  {/* 4 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Contact Number</Form.Label>
                    <Col>
                      <Form.Control
                        name="txtrefphone"
                        type="number"
                        defaultValue={refState.txtrefphone}
                      />
                    </Col>
                  </Form.Group>
                  {/* 5 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Current Company</Form.Label>
                    <Col>
                      <Form.Control
                        name="txtrefcompany"
                        type="text"
                        defaultValue={refState.txtrefcompany}
                      />
                    </Col>
                  </Form.Group>
                  {/* 6 */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label>Relationship</Form.Label>
                    <Col>
                      <Form.Control
                        name="txtrefrelationship"
                        type="text"
                        defaultValue={refState.txtrefrelationship}
                      />
                    </Col>
                  </Form.Group>
                </Form>
              </>
            }
            button={
              <>
                <Button onClick={btnCloseReferee} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit" variant="primary" form="referee-form">
                  {modalRef.button}
                </Button>
              </>
            }
          />
        </div>
        {/* End Reference Candidate */}

        {/* Miscellaneous Candidate */}
        <div hidden={!abilities.includes("view miscellaneous")}>
          <div className="tab-header-div">
            <div className="left-div">
              <div className="tab-header-font">Miscellaneous Informations</div>
            </div>
            <div className="right-div">
              {editMode === false ? (
                <Button
                  variant="link"
                  className="modal-link"
                  onClick={() => btnEditMode(true, "fifth")}
                  hidden={!abilities.includes("edit candidate")}
                >
                  <Icon path={mdiPencil} />
                  <div>Edit Miscellaneous Informations</div>
                </Button>
              ) : null}
            </div>
          </div>

          <div className="content-div create-st">
            {editMisc === false ? null : (
              <div className="left-div" style={{ margin: "20px 0px" }}>
                <Button
                  variant="link"
                  className="modal-link"
                  onClick={btnAddMisc}
                >
                  <Icon path={mdiPlusCircle} />
                  Add Miscellaneous Informations
                </Button>
              </div>
            )}

            <div className="column-card">
              {showMiscellaneous.length > 0
                ? selCandidate.candidate_miscellaneous.map((miscell, index) => (
                    <>
                      <CardCandidate key={index}>
                        <div className="card-education__row-flex5">
                          <div className="exp-row" style={{ display: "flex" }}>
                            <div className="exp-left">
                              <div className="sub-text">
                              <div dangerouslySetInnerHTML={{__html: miscell.misc}}/>
                              {/* <Form.Group as={Row} className="mb-3">
                                <Col xs={12}>
                                  <Form.Control
                                    name="txtmiscview"
                                    as="textarea"
                                    className="read-num"
                                    rows={10}
                                    defaultValue={miscell.misc}
                                    readOnly
                                  />
                                </Col>
                              </Form.Group> */}
                              </div>
                              {/* <Text>{miscell.misc}</Text> */}
                            </div>
                            {editMisc === false ? null : (
                              <div className="exp-right">
                                <Button
                                  variant="link"
                                  className="modal-link"
                                  onClick={btnEditMisc(miscell.id)}
                                >
                                  <Icon path={mdiPencil} />
                                </Button>
                                <Button
                                  variant="link"
                                  className="delete-link"
                                  onClick={btnDelMis(miscell.id)}
                                >
                                  <Icon path={mdiDelete} />
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      </CardCandidate>
                    </>
                  ))
                : "-"}
            </div>
          </div>

          {editMisc === false ? null : (
            <div className="container-form-div">
              <div className="row-btn-center">
                <Button
                  onClick={() => btnCancelMisc(false)}
                  variant="secondary"
                >
                  Close
                </Button>
              </div>
            </div>
          )}

          {/* Modal Miscellaneous */}
          <Popup
            show={showMisc}
            size="lg"
            backdrop="static"
            keyboard={false}
            onHide={btnCloseMisc}
            title={modalMis.title}
            content={
              <>
                <Form id="miscell-form" onSubmit={btnSaveUpdateMisc}>
                  {/* 1 */}
                  <Form.Group as={Row} className="mb-3">
                    <Col>
                      <RichTextEditor
                        name="txtmiscell"
                        htmlContent={misState.txtmiscell}
                        isInvalid={!!errorsMis.txtmiscell}
                        errorText={errorsMis.txtmiscell}
                      />
                      {/* <Form.Control
                        name="txtmiscell"
                        as="textarea"
                        rows={5}
                        isInvalid={!!errorsMis.txtmiscell}
                        defaultValue={misState.txtmiscell}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorsMis.txtmiscell}
                      </Form.Control.Feedback> */}
                    </Col>
                  </Form.Group>
                </Form>
              </>
            }
            button={
              <>
                <Button onClick={btnCloseMisc} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit" variant="primary" form="miscell-form">
                  {modalMis.button}
                </Button>
              </>
            }
          />
        </div>
        {/* End Miscellaneous Candidate */}

        {/* Modal if status blacklist */}
        <Popup
          show={showBlacklist}
          size="xs"
          backdrop="static"
          keyboard={false}
          onHide={btnCloseBlacklist}
          title="Blacklist Reason"
          content={
            <>
              <Form id="blacklist-form" onSubmit={btnUpdateBlacklist}>
                <Form.Group as={Row} className="mb-1">
                  <Form.Control
                    name="txtreasonblack"
                    as="textarea"
                    rows={3}
                    isInvalid={errorsBlack.txtreasonblack}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorsBlack.txtreasonblack}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
            </>
          }
          button={
            <>
              <Button variant="secondary" onClick={btnCloseBlacklist}>
                Cancel
              </Button>
              <Button type="submit" form="blacklist-form" variant="primary">
                Confirm
              </Button>
            </>
          }
        ></Popup>

        {/* Modal if status client */}
        <Popup
          show={showClient}
          size="xs"
          backdrop="static"
          keyboard={false}
          onHide={btnCloseClient}
          title="Assign Candidate to Client"
          content={
            <>
              <Form id="tie-client-form" onSubmit={btnUpdateClient}>
                <Form.Group as={Row} className="mb-1">
                  <Searchable
                    name="clientList"
                    isMulti={false}
                    // options={countryList}
                  ></Searchable>
                  <Form.Control.Feedback type="invalid">
                    {errorsBlack.txtreasonblack}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
            </>
          }
          button={
            <>
              <Button variant="secondary" onClick={btnCloseClient}>
                Cancel
              </Button>
              <Button type="submit" form="tie-client-form" variant="primary">
                Confirm
              </Button>
            </>
          }
        ></Popup>
      </Card>
    </>
  );
};
