import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Api from "../../core/services/api.service";

import { Button } from "react-bootstrap";

import TableAssign from "../../components/Table/Table";
import CardInfo from "../../components/Card/CardInfo";
import ButtonNumberClient from "../../components/Button/ButtonNumberClient";
import TextboxIcon from "../../components/TextField/TextFieldIcon";
import { mdiMagnify, mdiClipboardAccount } from "@mdi/js";
import Empty from "../../components/Empty/Empty";
import "./clientSite.css";

import { storeTitle } from "../../store/actions/headerTitleSlice";

const Assignment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.login);

  const [listAssignment, setListAssignment] = useState([]);
  const [allAssignmentData, setAllAssignmentData] = useState([]);

  const headerAssignment = [
    {
      Header: "Assignment Name",
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        function viewAssignment() {
          navigate("/client-assignment/" + data.id);
        }

        return (
          <div className="container-div list-cli">
            <Button
              variant="link"
              className="no-underline"
              onClick={viewAssignment}
            >
              {data.title}
            </Button>
          </div>
        );
      },
    },
    {
      Header: "Date Signed",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        const date = new Date(data.created_at);
        const longMonth = date.toLocaleString("en-us", { month: "long" });

        return (
          <div>
            {date.getDate()} {longMonth} {date.getFullYear()}
          </div>
        );
      },
    },
    {
      Header: "Invoice",
      maxWidth: 400,
      minWidth: 100,
      width: 400,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <ButtonNumberClient
            idClient={data.client_id}
            idAssign={data.id}
            items={data.assignment_invoices}
            className="btn-add-inv"
          ></ButtonNumberClient>
        );
      },
    },
    {
      Header: "Point of contact",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        var array = data.assignment_has_user.map((obj, index) => {
          return obj.user.first_name + " " + obj.user.last_name + ",";
        });

        var cons_name = array.join(" ");

        return <div>{cons_name.slice(0, -1)}</div>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      maxWidth: 400,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        if (data.status === "in_discussion") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--discussion--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else if (data.status === "in_progress") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--progress--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else if (data.status === "on_hold") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--hold--solid"
            >
              {data.status.replace(/_/g, " ")}
            </CardInfo>
          );
        } else if (data.status === "completed") {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--completed--solid"
            >
              {data.status}
            </CardInfo>
          );
        } else {
          return (
            <CardInfo
              cardTextSize="card_text--medium"
              cardTextStyle="card_text--primary--solid"
            >
              {data.status}
            </CardInfo>
          );
        }
      },
    },
  ];

  // const [assignment, setAssignment] = useState(AssignmentList);

  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    if (value !== "") {
      let newList = [];
      newList = listAssignment.filter((assignment) =>
        assignment.title.toLowerCase().includes(value.toLowerCase())
      );

      setAllAssignmentData(newList);
    } else {
      setAllAssignmentData(listAssignment);
    }
  };

  const getListAssignment = useCallback(() => {
    Api.get("client-staff/" + user.clientid + "/getAllAssignment")
      .then((response) => {
        let data = response.data;

        setListAssignment(data);
        setAllAssignmentData(data);
      })
      .catch((error) => {
        console.log("getListAssignment", error);
      });
  }, [user.clientid]);

  useEffect(() => {
    getListAssignment();
    dispatch(storeTitle({title: "Assignments", icon: mdiClipboardAccount}));
  }, [getListAssignment, dispatch]);

  return (
    <div className="main-div list-cli">
      {allAssignmentData.length > 0 ? (
        <>
          <div className="top-title-div">
            <div className="filter_container">
              <div className="filter_container_totalrow">
                {listAssignment.length === 0 || listAssignment.length === 1 ? (
                  <div className="form-container-default">
                    <div className="total_client_text">
                      {listAssignment.length}
                    </div>
                    <div className="client_text">assignment</div>
                  </div>
                ) : (
                  <div className="form-container-default">
                    <div className="total_client_text">
                      {listAssignment.length}
                    </div>
                    <div className="client_text">assignments</div>
                  </div>
                )}
              </div>

              <div className="upper-divider"></div>

              <div className="filter_container_search">
                <div className="filter_container_search-1">
                  <TextboxIcon
                    name="name"
                    type="text"
                    onChange={(event) => handleSearch(event)}
                    placeholder="Search by job title"
                    inputSize="input--medium"
                    icon={mdiMagnify}
                  ></TextboxIcon>
                </div>
              </div>
            </div>
          </div>
          <TableAssign
            headers={headerAssignment}
            items={allAssignmentData}
            paginate={allAssignmentData.length}
            maxRows={10}
          ></TableAssign>
        </>
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default Assignment;
