import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Table, Form, Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
// import Api from "../../../core/services/api.service";
import Apiform from "../../../core/services/apiform.service";

import SwalAlert from "../../../components/Swal/SwalAlert";
import moment from "moment";

import Icon from "@mdi/react";
import { mdiArrowLeft, mdiAccountGroup } from "@mdi/js";

import RichTextEditor from "../../../components/RichTextArea/RichTextEditor";
import Searchable from "../../../components/DropDown/SearchableSelect";
import BreadCrumb from "../../../components/Breadcrumb/BreadCrumb";

import { storeTitle } from "../../../store/actions/headerTitleSlice";
import { getClientDetails, getClientStaff } from "../../../store/actions/clientSlice";
import { getAssignmentDetails } from "../../../store/actions/assignmentSlice";
import { getListStaff } from "../../../store/actions/staffSlice";
import { getCandidateAssignList } from "../../../store/actions/candidateSlice";

const CreateProgress = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const { clientDetails, clientListStaff } = useSelector((state) => state.client);
  const { dataStaff } = useSelector((state) => state.staff);
  const { candidateAssignList } = useSelector((state) => state.candidate);
  const { assignDetails } = useSelector((state) => state.assignment);
  const { usrProfile } = useSelector((state) => state.user);

  const customSwal = SwalAlert();

  const [selStaffClient, setSelStaffClient] = useState([]);
  const [selStaffLLM, setSelStaffLLM] = useState([]);
  const [selCandidate, setSelCandidate] = useState([]);
  const [checkProgress, setCheckProgress] = useState(false);

  // const [totalProgress, setTotalProgress] = useState(0);
  const [errorProgress, setErrorProgress] = useState({});

  const selectedCrumb = (crumb) => {
    if(crumb === "Clients"){
      navigate("/clients/listActiveC");
    }else if(crumb === "Potential Clients"){
        navigate("/clients/listPotentialC");
    }else if(crumb === clientDetails.name){
      if(params.page === "active"){
        navigate("/clients/viewActiveC/" + params.id);
      }else{
        navigate("/clients/view-potential-client/" + params.id);
      }
    }else if(crumb === assignDetails.title){
        navigate("/clients/view-assignment/" + params.page + "/" + params.id + "/" + params.idassign)
    }
  };

  const handleStaffClient = (e) => {
    setSelStaffClient(Array.isArray(e) ? e.map((x) => x.id.toString()) : []);
  };

  const handleStaffLLM = (e) => {
    setSelStaffLLM(Array.isArray(e) ? e.map((x) => x.id.toString()) : []);
  };

  const handleCandidate = (e) => {
    setSelCandidate(Array.isArray(e) ? e.map((x) => x.id.toString()) : []);
  };

  const handleCheckProgress = (e) => {
    setCheckProgress(e.target.checked);
  };

  const formErrorPro = (object) => {
    const newErrors = {};

    if (!object.txtReportName || object.txtReportName === "")
      newErrors.txtReportName = "Fill in the blank!";

    return newErrors;
  };

  const btnSaveProgress = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtPro = Object.fromEntries(data.entries());

    const newErrors = formErrorPro(dtPro);

    // console.log("txtRichKeymiles.current", dtPro)

    if (Object.keys(newErrors).length > 0) {
      setErrorProgress(newErrors);
    } else {

      setErrorProgress({});

      customSwal
      .fire({
        title: "Do you want to add new progress review agenda?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          const formData = new FormData();

          if (selStaffClient.length > 0) {
            selStaffClient.forEach((item, index) => {
              formData.append(`client_involve[${index}]`, item);
            });
          } else {
            formData.append("client_involve", []);
          }

          if (selStaffLLM.length > 0) {
            selStaffLLM.forEach((item, index) => {
              formData.append(`staff_involve[${index}]`, item);
            });
          } else {
            formData.append("staff_involve", []);
          }

          formData.append("key_milestone", dtPro.txtKeymiles);
          formData.append("progress", dtPro.txtProgress);
          formData.append("market_feedback", dtPro.txtMarket);

          formData.append("attachment", dtPro.txtfile);
          formData.append("include_statistic", checkProgress === true ? "true" : "false");

          if (selCandidate.length > 0) {
            selCandidate.forEach((item, index) => {
              formData.append(`candidate_profile[${index}]`, item);
            });
          } else {
            formData.append("candidate_profile", []);
          }

          formData.append("report_name", dtPro.txtReportName);
          formData.append("created_by", usrProfile.id);

          Apiform.post(
            "client/" +
              params.id +
              "/assignment/" +
              params.idassign +
              "/progress/create",
            formData
          )
            .then((response) => {
              // console.log("user/store", response);
              navigate(
                "/clients/view-assignment/" +
                  params.page + "/" +
                  params.id +
                  "/" +
                  params.idassign
              );

              customSwal.fire({
                title: "Saved Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error btnSaveProgress", error);
            });
        }
      });
    }

    
  };

  // const getTotalListProgress = useCallback(() => {
  //   Api.get(
  //     "client/" + params.id + "/assignment/" + params.idassign + "/progress/all"
  //   )
  //     .then((response) => {
  //       let data = response.data;

  //       setTotalProgress(data.length + 1);
  //     })
  //     .catch((error) => {
  //       console.log("error getTotalListProgress", error);
  //     });
  // }, [params]);

  useEffect(() => {
    // getTotalListProgress();

    dispatch(getClientStaff(params.id));
    dispatch(getListStaff());
    dispatch(getCandidateAssignList(params.id, params.idassign));

    dispatch(storeTitle({title: params.page === "active" ? "Clients" : "Potential Clients", icon: mdiAccountGroup}));
    dispatch(getClientDetails(params.id));
    dispatch(getAssignmentDetails(params.id, params.idassign));
  }, [dispatch, params.id, params.idassign]);

  return (
    <div className="main-div create-props">
      <div className="top-title-div both-side">
        <BreadCrumb
          crumbs={[
            params.page === "active" ? "Clients" : "Potential Clients",
            clientDetails.name,
            assignDetails.title,
            "Add Progress Report",
          ]}
          selected={selectedCrumb}
        ></BreadCrumb>
        <Button variant="secondary" onClick={() => navigate(-1)}>
          <Icon path={mdiArrowLeft} color="#212529" />
          Back
        </Button>
      </div>

      <Card>
        <Card.Body>
          <div className="outer-report-section mb-3">
            <div className="report-name">Progress Review Agenda</div>
            <div className="report-date">Date : {moment().format("DD MMMM YYYY")} </div>
          </div>
          <Form id="progress-form" onSubmit={btnSaveProgress}>
            <Table bordered>
              <tbody>
                <tr>
                  <th width="20%" className="report-label">
                    Report Name <label className="validation-star">*</label>
                  </th>
                  <td>
                    <Form.Control
                      name="txtReportName"
                      type="text"
                      isInvalid={!!errorProgress.txtReportName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorProgress.txtReportName}
                    </Form.Control.Feedback>
                  </td>
                </tr>
                <tr>
                  <th className="report-label" rowSpan={2}>
                    Parties Involved
                  </th>
                  <td>
                    <div className="text mb-3 report-label">Client:</div>
                    <Searchable
                      isMulti={true}
                      options={clientListStaff}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) =>
                        option.last_name ? option.first_name + " " + option.last_name : option.first_name
                      }
                      // required={error}
                      onChange={handleStaffClient}
                      errorText="Fill in the blank!"
                    ></Searchable>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="text mb-3 report-label">
                      Lim-Loges & Masters:
                    </div>
                    <Searchable
                      isMulti={true}
                      options={dataStaff}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) =>
                        option.first_name + " " + option.last_name
                      }
                      onChange={handleStaffLLM}
                      errorText="Fill in the blank!"
                    ></Searchable>
                  </td>
                </tr>
                <tr>
                  <th width="20%" className="report-label">
                    Key Milestones
                  </th>
                  <td>
                    <RichTextEditor name="txtKeymiles"></RichTextEditor>
                  </td>
                </tr>
                <tr>
                  <th className="report-label">Progress</th>
                  <td>
                    <RichTextEditor name="txtProgress"></RichTextEditor>
                  </td>
                </tr>
                <tr>
                  <th className="report-label">Market Feedback</th>
                  <td>
                    <RichTextEditor name="txtMarket"></RichTextEditor>
                  </td>
                </tr>
                <tr>
                  <th className="report-label">
                    Candidate Profiles <br />
                    (information provided as additional documents)
                  </th>
                  <td>
                    <Searchable
                      isMulti={true}
                      options={candidateAssignList}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) =>
                        option.last_name ? option.first_name + " " + option.last_name :  option.first_name
                      }
                      onChange={handleCandidate}
                      errorText="Fill in the blank!"
                    ></Searchable>
                  </td>
                </tr>
                <tr>
                  <th className="report-label">Attachment</th>
                  <td>
                    <Form.Control name="txtfile" type="file" />
                  </td>
                </tr>
              </tbody>
            </Table>

            <div className="checkbox-list" style={{ margin: "0px 10px" }}>
              <Form.Check
                name="cbProgress"
                type="checkbox"
                onChange={handleCheckProgress}
              />
              <div className="list-title">
                Do you want to include statistics into this progress report?
              </div>
            </div>
          </Form>

          <div className="row-btn-center">
            <Button onClick={() => navigate(-1)} variant="secondary">
              Cancel
            </Button>
            <Button variant="primary" type="submit" form="progress-form">
              Save Report
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CreateProgress;
