import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Table, Form } from "react-bootstrap";
import Api from "../../../../core/services/api.service";
import "./report.css";

import Icon from "@mdi/react";
import { mdiArrowLeft, mdiAccountSearch, mdiAccountGroup } from "@mdi/js";

import BreadCrumb from "../../../../components/Breadcrumb/BreadCrumb";
import SwalAlert from "../../../../components/Swal/SwalAlert";
import moment from "moment";

import { storeTitle } from "../../../../store/actions/headerTitleSlice";

const EmergingCP = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const customSwal = SwalAlert();

  const { dataAllowance } = useSelector((state) => state.option);

  const selectedCrumb = (crumb) => {

    const nameCand = selEcpCand.last_name ? selEcpCand.first_name + " " + selEcpCand.last_name : selEcpCand.first_name;

    if(crumb === "Clients"){
      navigate("/clients/listActiveC");
    }else if(crumb === "Potential Clients"){
      navigate("/clients/listPotentialC");
    }else if(crumb === nameCand){
      navigate(-1);
    }
  };

  const btnUpdateEcp = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtEcp = Object.fromEntries(data.entries());

    customSwal
      .fire({
        title: "Do you want to update emerging candidate profile?",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Api.patch(
            "candidate/" +
              params.id +
              "/assignment/" +
              params.idassign +
              "/ecpqcp/ecp/" +
              params.idrpt,
            {
              motivation: dtEcp.txtmotivation,
              recommendation: dtEcp.txtrecommend,
            }
          )
            .then((response) => {
              navigate(-1);

              customSwal.fire({
                title: "Updated Successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              });
            })
            .catch((error) => {
              console.log("error btnUpdateEcp", error);
            });
        }
      });
  };

  function toMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", {
      month: "long",
    });
  }

  function toMoneyFormat(value) {
    let salary = "";

    let convertNum = Number(value);

    salary = convertNum.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");

    return salary;
  }

  function toAllowanceDesc(idallow) {
    let nameAllow = "";

    let array_allow = dataAllowance.filter((grp) => {
      return grp.id === idallow;
    });

    if (array_allow.length > 0) {
      nameAllow = array_allow[0].value;
    }

    return nameAllow;
  }

  const [selEcp, setSelEcp] = useState({});
  const [selEcpClient, setSelEcpClient] = useState({});
  const [selEcpAssign, setSelEcpAssign] = useState({});
  const [selEcpCand, setSelEcpCand] = useState({});
  const [selEcpCandExp, setSelEcpCandExp] = useState([]);
  const [selCurEcpCandExp, setSelCurEcpCandExp] = useState({});
  const [selEcpEducation, setSelEcpEducation] = useState({});
  const [selAllowCand, setSelAllowCand] = useState([]);

  const [selLang, setSelLang] = useState("");

  const getSelectedReport = useCallback(() => {
    Api.get(
      "candidate/" +
        params.id +
        "/assignment/" +
        params.idassign +
        "/ecpqcp/ecp/" +
        params.idrpt
    )
      .then((response) => {
        let data = response.data;

        // console.log("getSelectedReport", data);
        if (data !== "no data") {
          setSelEcp(data);
          setSelEcpClient(data.assignments.clients);
          setSelEcpAssign(data.assignments);
          setSelEcpCand(data.candidates);

          if (data.candidates.candidate_experiences.length > 0) {
            setSelEcpCandExp(data.candidates.candidate_experiences);

            setSelCurEcpCandExp(data.candidates.candidate_experiences[0]);

            if (
              data.candidates.candidate_experiences[0].candidate_exp_allowance
                .length > 0
            ) {
              setSelAllowCand(
                data.candidates.candidate_experiences[0].candidate_exp_allowance
              );
            }
          }

          if (data.candidates.candidate_education.length > 0) {
            setSelEcpEducation(data.candidates.candidate_education[0]);
          }

          if (data.candidates.languages.length > 0) {
            let array = [];

            array = data.candidates.languages.map((obj) => {
              return obj + ",";
            });

            let lang = array.join(" ");

            setSelLang(lang.slice(0, -1));
          } else {
            setSelLang("-");
          }
        }
      })
      .catch((error) => {
        console.log("error getSelectedReport", error);
      });
  }, [params]);

  const [mainCrumb, setMainCrumb] = useState("");

  useEffect(() => {
    getSelectedReport();

    var pathArray = window.location.pathname.split("/");

    var firstPath = pathArray[1];

    setMainCrumb(firstPath);

    if (firstPath === "clients") {
      dispatch(storeTitle({ title: params.page === "active" ? "Clients" : "Potential Clients", icon: mdiAccountGroup }));
    } else {
      dispatch(
        storeTitle({ title: "List of Candidates", icon: mdiAccountSearch })
      );
    }
    // console.log("dsdsd",firstPath )
  }, [getSelectedReport, dispatch]);

  return (
    <div className="main-div ecp-report">
      <div className="container-div both-side">
        <BreadCrumb
          crumbs={[
            params.page === "active" && mainCrumb === "clients" ? "Clients" : params.page === "potential" ? "Potential Clients" : "List of Candidates",
            selEcpCand.last_name
              ? selEcpCand.first_name + " " + selEcpCand.last_name
              : selEcpCand.first_name,
            "Emerging Candidate Profile",
          ]}
          selected={selectedCrumb}
        ></BreadCrumb>
        <Button variant="secondary" onClick={() => navigate(-1)}>
          <Icon path={mdiArrowLeft} size={0.7} />
          Back
        </Button>
      </div>

      <div className="bg-white-container">
        <div className="outer-report-section">
          <div>Client : {selEcpClient.name}</div>
          <div>Position : {selEcpAssign.title}</div>
        </div>
        <Form id="ecp-form" onSubmit={btnUpdateEcp}>
          <Table bordered>
            <thead className="tbl-report">
              <tr>
                <th colSpan={4}>
                  <div className="top-report-section">
                    <div>
                      Candidate Profile : {selEcpCand.salutation}{" "}
                      {selEcpCand.first_name} {selEcpCand.last_name}
                    </div>
                    <div>
                      Date : {moment(selEcp.created_at).format("DD MMMM YYYY")}{" "}
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <thead className="tbl-report">
              <tr>
                <th colSpan={4} className="row-th-center">
                  Career Progression
                </th>
              </tr>
            </thead>
            <thead className="tbl-report">
              <tr>
                <th>Employer</th>
                <th>Date</th>
                <th>Designation</th>
                <th>Reason for leaving</th>
              </tr>
            </thead>
            <tbody>
              {selEcpCandExp.map((i, index) => (
                <tr key={index}>
                  <td>{i.company_name}</td>
                  <td>
                    {i.current_working
                      ? toMonthName(i.present_month) +
                        " " +
                        i.present_year +
                        " - Current"
                      : toMonthName(i.start_date_month) +
                        " " +
                        i.start_date_year +
                        " - " +
                        toMonthName(i.end_date_month) +
                        " " +
                        i.end_date_year}
                  </td>
                  <td>{i.title}</td>
                  <td>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: i.reason_leaving,
                      }}
                    ></div>
                  </td>
                </tr>
              ))}
            </tbody>
            <thead className="tbl-report">
              <tr>
                <th colSpan={4} className="row-th-center">
                  Personal Information
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th width="25%">Education</th>
                <td colSpan={3}>
                  <tr>
                    <td>School</td>
                    <td>:</td>
                    <td>{selEcpEducation.name ? selEcpEducation.name : "-"}</td>
                  </tr>
                  <tr>
                    <td>Qualification</td>
                    <td>:</td>
                    <td>
                      {selEcpEducation.qualification
                        ? selEcpEducation.qualification
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Year</td>
                    <td>:</td>
                    <td>
                      {selEcpEducation.start_year
                        ? selEcpEducation.start_year +
                          " - " +
                          selEcpEducation.end_year
                        : "-"}
                    </td>
                  </tr>
                </td>
              </tr>
              <tr>
                <th width="25%">
                  Current compensation: <br />
                  (not verified at time of presentation)
                </th>
                <td colSpan={3}>
                  <tr>
                    <td>Salary</td>
                    <td>:</td>
                    <td>
                      {selCurEcpCandExp.currency
                        ? selCurEcpCandExp.currency +
                          " " +
                          toMoneyFormat(selCurEcpCandExp.salary)
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Bonus</td>
                    <td>:</td>
                    <td>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: selCurEcpCandExp.other_benefits
                            ? selCurEcpCandExp.other_benefits
                            : "-",
                        }}
                      ></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Allowance</td>
                    <td>:</td>
                    <td>
                      {selAllowCand.length > 0
                        ? selAllowCand.map((allow, index) => (
                            <div key={index}>
                              {toAllowanceDesc(allow.earning) +
                                " - " +
                                allow.currency +
                                " " +
                                toMoneyFormat(allow.amount)}
                            </div>
                          ))
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Notice</td>
                    <td>:</td>
                    <td>
                      {selCurEcpCandExp.notice_period
                        ? selCurEcpCandExp.notice_period
                        : "-"}
                    </td>
                  </tr>
                </td>
              </tr>
              <tr>
                <th>Citizenship</th>
                <td width="25%">
                  {selEcpCand.citizenship_main
                    ? selEcpCand.citizenship_main
                    : "-"}
                </td>
                <th width="25%">Languages</th>
                <td>{selLang}</td>
              </tr>
              <tr>
                <th colSpan={1}>Location</th>
                <td>
                  {selEcpCand.citizenship_main
                    ? selEcpCand.citizenship_main
                    : "-"}
                </td>
                <th colSpan={1}>Willingness to travel</th>
                <td>{selEcpCand.willing_travel ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <th colSpan={1}>Motivation</th>
                <th colSpan={3}>
                  <Form.Control
                    name="txtmotivation"
                    as="textarea"
                    rows={3}
                    defaultValue={selEcp.motivation}
                  />
                </th>
              </tr>
            </tbody>
            <thead className="tbl-report">
              <tr>
                <th colSpan={4} className="row-th-center">
                  Recommendation
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={4}>
                  <Form.Control
                    name="txtrecommend"
                    as="textarea"
                    rows={3}
                    defaultValue={selEcp.recommendation}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </Form>

        <div className="row-btn-center">
          {/* <Button variant="secondary">Reset</Button> */}
          <Button variant="primary" type="submit" form="ecp-form">
            Save Emerging Candidate Profile
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EmergingCP;
