import React, { useState, useEffect, useCallback } from "react";
import { Button, Dropdown, Form, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Api from "../../core/services/api.service";
import ProfileImage from "../../assets/no-profile-photo.jpg";

import SwalAlert from "../Swal/SwalAlert";
import AuthAbilities from "../../pages/Admin/Auth/AuthAbilities";
import moment from "moment";

import Icon from "@mdi/react";
import {
  mdiPlusCircle,
  mdiPinOutline,
  mdiDotsVertical,
  mdiPencil,
  mdiDelete,
  mdiPin,
} from "@mdi/js";
import Modal from "../Popup/ModalPopup";
import TableNote from "../Table/TableNote";

export const TabNotes = () => {
  const params = useParams();
  const abilities = AuthAbilities();
  const { usrProfile } = useSelector((state) => state.user);

  const [showNote, setShowNote] = useState(false);
  const [listRoleNotes, setListRoleNotes] = useState([]);
  const [modeModal, setModeModal] = useState({
    title: null,
    button: null,
  });
  const [errorNote, setErrorNote] = useState({});
  const [idNote, setIdNote] = useState("");
  const [noteState, setNoteState] = useState({
    txtcandidateid: null,
    txtnote: "",
  });

  const customSwal = SwalAlert();

  // start Checking Error Form
  const formErrorsNote = (object) => {
    const newErrors = {};

    if (!object.txtnote || object.txtnote === "")
      newErrors.txtnote = "Fill in the blank!";

    return newErrors;
  };

  // end Checking Error Form

  // start button event
  const btnAddNote = (e) => {
    noteState.txtnote = null;
    noteState.txtcandidateid = null;

    setShowNote(true);
    setModeModal({
      title: "Add Note",
      button: "Add",
    });
    setErrorNote({});
    setIdNote("New");
  };

  const btnEditNote = (id) => (e) => {
    const filteredNote = listRoleNotes.find((rolenote) => rolenote.id === id);
    // console.log("filteredNote", filteredNote);

    setNoteState({
      txtcandidateid: filteredNote.candidate_id,
      txtnote: filteredNote.note,
    });

    setShowNote(true);
    setModeModal({
      title: "Edit Note",
      button: "Update",
    });
    setErrorNote({});
    setIdNote(id);
  };

  const btnCloseNote = (e) => {
    setShowNote(false);
  };

  const btnSaveUpdateNote = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const dtNote = Object.fromEntries(data.entries());

    // console.log("dtNote", noteState);
    const newErrors = formErrorsNote(dtNote);

    if (Object.keys(newErrors).length > 0) {
      setErrorNote(newErrors);
    } else {
      if (idNote === "New") {
        customSwal
          .fire({
            title: "Do you want to save new note?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.post(
                "client/" +
                  params.id +
                  "/assignment/" +
                  params.idassign +
                  "/notes/create",
                {
                  assignment_id: Number(params.idassign),
                  candidate_id: null,
                  type: "",
                  note: dtNote.txtnote,
                  status: "created",
                  created_by: Number(usrProfile.id),
                }
              )
                .then((response) => {
                  // let data = response.data;

                  getListRoleNotes();

                  setShowNote(false);

                  customSwal.fire({
                    title: "Note Saved Successfully!",
                    icon: "success",
                    confirmButtonText: "Okay",
                  });
                })
                .catch((error) => {
                  console.log("error save note", error);
                });
            }
          });
      } else {
        customSwal
          .fire({
            title: "Do you want to update note?",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              Api.patch(
                "client/" +
                  params.id +
                  "/assignment/" +
                  params.idassign +
                  "/notes/" +
                  idNote,
                {
                  assignment_id: Number(params.idassign),
                  candidate_id: noteState.txtcandidateid,
                  type: "",
                  note: dtNote.txtnote,
                  status: "created",
                }
              )
                .then((response) => {
                  // let data = response.data;

                  getListRoleNotes();

                  setShowNote(false);

                  customSwal.fire({
                    title: "Note Saved Successfully!",
                    icon: "success",
                    confirmButtonText: "Okay",
                  });
                })
                .catch((error) => {
                  console.log("error update note", error);
                });
            }
          });
      }
    }
  };
  //end button event

  // start function event

  const getListRoleNotes = useCallback(() => {
    Api.get(
      "client/" + params.id + "/assignment/" + params.idassign + "/notes/all"
    )
      .then((response) => {
        let data = response.data;

        if (data !== "no data") {
          let sortPinned = data.sort((a, b) => b.pinned - a.pinned);

          setListRoleNotes(sortPinned);
        }
      })
      .catch((error) => {
        console.log("error getListRoleNotes", error);
      });
  }, [params]);

  //end function event

  useEffect(() => {
    getListRoleNotes();
  }, [getListRoleNotes]);

  // Header Table
  const headers = [
    {
      Header: "1",
      hideHeader: true,
      minWidth: 50,
      width: 50,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="td-data-img">
            {data.created_by_notes.profile_picture ? (
              <img src={data.created_by_notes.profile_picture} alt="" />
            ) : (
              <img src={ProfileImage} alt="" />
            )}
          </div>
        );
      },
    },
    {
      Header: "2",
      hideHeader: true,
      minWidth: 200,
      width: 300,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <div className="td-notes" style={{ paddingRight: "100px" }}>
            <div className="text">
              {data.created_by_notes.first_name}{" "}
              {data.created_by_notes.last_name}
            </div>

            <div>{data.note}</div>
          </div>
        );
      },
    },
    {
      Header: "3",
      hideHeader: true,
      minWidth: 100,
      width: 200,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <>
            {data.candidates
              ? data.candidates.first_name + " " + data.candidates.last_name
              : "-"}
          </>
        );
      },
    },
    {
      Header: "4",
      hideHeader: true,
      minWidth: 180,
      width: 180,
      Cell: ({ row }) => {
        const data = row.original;

        return <>{moment(data.created_at).format("DD MMMM YYYY")}</>;
      },
    },
    {
      Header: "5",
      hideHeader: true,
      minWidth: 50,
      width: 50,
      Cell: ({ row }) => {
        const data = row.original;

        const btnPinned = (e) => {
          Api.get(
            "client/" +
              params.id +
              "/assignment/" +
              params.idassign +
              "/notes/" +
              data.id +
              "/updatePinned"
          )
            .then((response) => {
              getListRoleNotes();
            })
            .catch((error) => {
              console.log("error pinned", error);
            });
        };

        return (
          <>
            {data.pinned ? (
              <Icon path={mdiPin} rotate={270} onClick={btnPinned} />
            ) : (
              <Icon path={mdiPinOutline} onClick={btnPinned} />
            )}
          </>
        );
      },
    },
    {
      Header: "6",
      hideHeader: true,
      minWidth: 50,
      width: 50,
      Cell: ({ row }) => {
        const data = row.original;

        return (
          <>
            {usrProfile.id === data.created_by ? (
              <Dropdown>
                <Dropdown.Toggle variant="link" className="no-underline">
                  <Icon path={mdiDotsVertical} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={btnEditNote(data.id)}
                    hidden={!abilities.includes("edit note")}
                  >
                    <Icon path={mdiPencil} color="#467FD0" />
                    Edit Note
                  </Dropdown.Item>
                  <Dropdown.Item hidden={!abilities.includes("delete note")}>
                    <Icon path={mdiDelete} color="#DF4759" />
                    Delete Note
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card>
        <Card.Body>
          <div className="left-btn" style={{ paddingLeft: "10px" }}>
            <Button
              variant="link"
              className="modal-link"
              onClick={btnAddNote}
              hidden={!abilities.includes("add note")}
            >
              <Icon path={mdiPlusCircle} />
              Add Note
            </Button>
          </div>

          <TableNote
            headers={headers}
            items={listRoleNotes}
            paginate={listRoleNotes.length}
          ></TableNote>
        </Card.Body>
      </Card>

      {/* Modal Note */}
      <Modal
        show={showNote}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={btnCloseNote}
        title={modeModal.title}
        content={
          <>
            <Form id="note-form" onSubmit={btnSaveUpdateNote}>
              <Form.Control
                name="txtnote"
                as="textarea"
                rows={5}
                defaultValue={noteState.txtnote}
                isInvalid={!!errorNote.txtnote}
              />
              <Form.Control.Feedback type="invalid">
                {errorNote.txtnote}
              </Form.Control.Feedback>
            </Form>
          </>
        }
        button={
          <>
            <Button variant="secondary" onClick={btnCloseNote}>
              Cancel
            </Button>
            <Button type="submit" form="note-form" variant="primary">
              {modeModal.button}
            </Button>
          </>
        }
      ></Modal>
    </>
  );
};
